import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {getMostPreciseDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils" // maps [0..1] -> [-1..1]

// maps [0..1] -> [-1..1]

const SCOPE_NAME = "MapZeroOneToMinusOnePlusOne"

export type ParameterType = {
    sourceImage: ImageRef
}

export type ReturnType = ImageRef

export function mapZeroOneToMinusOnePlusOne(cmdQueue: ImageOpCommandQueue, {sourceImage}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)

    let result = math(cmdQueue, {
        operandA: sourceImage,
        operandB: 2,
        operator: "*",
        resultImageOrDataType: getMostPreciseDataType(sourceImage.descriptor.dataType, "float16"),
    })
    result = math(cmdQueue, {
        operandA: result,
        operandB: 1,
        operator: "-",
    })

    cmdQueue.endScope(SCOPE_NAME)

    return result
}
