import {Component} from "@angular/core"

@Component({
    selector: "cm-four-square-layout",
    standalone: true,
    imports: [],
    templateUrl: "./four-square-layout.component.html",
    styleUrl: "./four-square-layout.component.scss",
})
export class FourSquareLayoutComponent {}
