import {TextFieldModule} from "@angular/cdk/text-field"
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"

@Component({
    selector: "cm-native-input-text-area",
    standalone: true,
    templateUrl: "./native-input-text-area.component.html",
    styleUrls: ["./native-input-text-area.component.scss"],
    imports: [TextFieldModule],
})
export class NativeInputTextAreaComponent implements OnInit, AfterViewInit {
    @ViewChild("inputField") private inputElement!: ElementRef
    @Input() placeholder!: string
    @Input() initialValue!: string
    @Input() autoFocus = false
    @Output() valueChange = new EventEmitter<string>()
    @Output() keyUp = new EventEmitter<string>()
    @Input() disabled = false
    @Input() minRows = 0

    @ViewChild("inputField", {static: true}) input: ElementRef<HTMLTextAreaElement> | null = null

    value: string | null = null
    ngOnInit() {
        this.value = this.initialValue
    }

    ngAfterViewInit(): void {
        if (this.autoFocus) {
            this.inputElement.nativeElement.focus()
        }
    }

    onChange(_$event: Event) {
        this.value = this.inputElement.nativeElement.value
        this.valueChange.emit(this.value!)
    }

    onKeyUp(_$event: KeyboardEvent) {
        this.keyUp.emit(this.inputElement.nativeElement.value!)
    }

    updateValue(value: string) {
        if (this.input) {
            this.input.nativeElement.value = value
        }
        this.valueChange.emit(value)
    }

    focus() {
        if (this.input) {
            this.input.nativeElement.focus()
        }
    }
}
