import {map, Subscription} from "rxjs"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {IDataObject} from "@cm/material-nodes/interfaces/data-object"

const TD = TypeDescriptors

const loadMeshDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    drcDataObject: TD.inlet(TD.DataObject),
    plyDataObjectId: TD.inlet(TD.Number),
    displaySubdivisionLevel: TD.inlet(TD.Number),
    renderSubdivisionLevel: TD.inlet(TD.Number),
    meshData: TD.outlet(TD.MeshData),
}

export class LoadMesh implements NodeClassImpl<typeof loadMeshDescriptor, typeof LoadMesh> {
    static descriptor = loadMeshDescriptor
    static uniqueName = "LoadMesh"
    sceneManager!: Inlet<ISceneManager>
    drcDataObject!: Inlet<IDataObject>
    plyDataObjectId!: Inlet<number>
    displaySubdivisionLevel!: Inlet<number>
    renderSubdivisionLevel!: Inlet<number>
    meshData!: Outlet<MeshData>

    private _pending: Subscription | null = null

    run() {
        this._pending?.unsubscribe()
        this.meshData.emitIfChanged(NotReady)
        if (
            this.sceneManager === NotReady ||
            this.drcDataObject === NotReady ||
            this.plyDataObjectId === NotReady ||
            this.displaySubdivisionLevel === NotReady ||
            this.renderSubdivisionLevel === NotReady
        ) {
            return
        }
        this._pending = this.sceneManager.addTask(
            this.sceneManager
                .loadMeshData(this.drcDataObject, this.plyDataObjectId, {
                    displaySubdivisionLevel: this.displaySubdivisionLevel,
                    renderSubdivisionLevel: this.renderSubdivisionLevel,
                })
                .pipe(
                    map((meshData) => {
                        this._pending = null
                        this.meshData.emitIfChanged(meshData)
                    }),
                ),
        )
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
