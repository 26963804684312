<input
    class="cm-input"
    #inputField
    [type]="type"
    [required]="required"
    [disabled]="disabled"
    [class.cm-show-invalid]="showInvalid"
    [placeholder]="placeholder ? placeholder : ''"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    (change)="onChange($event)"
    (keyup)="onKeyUp($event)"
/>
