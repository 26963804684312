import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {MaterialLike, materialLike, imageLike, numberLike, NumberLike, ImageLike} from "#template-nodes/node-types"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {TransformColorOverlayNew} from "#template-nodes/runtime-graph/nodes/transform-color-overlay-new"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"

const overlayMaterialColorParameters = z.object({
    material: materialLike.nullable(),
    overlay: imageLike.nullable(),
    size: z.tuple([numberLike, numberLike]),
})
export type OverlayMaterialColorParameters = {
    material: MaterialLike | null
    overlay: ImageLike | null
    size: [NumberLike, NumberLike]
}

@registerNode
export class OverlayMaterialColor
    extends DeclareTemplateNodeTS<OverlayMaterialColorParameters>(
        {
            validation: {paramsSchema: overlayMaterialColorParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    if (parameters.material === null || parameters.overlay === null) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "OverlayMaterialColor"},
    )
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const material = evaluator.evaluateMaterial(scope, this.parameters.material)
        const [materialValid, materialInvalid] = scope.branch(material)

        const image = evaluator.evaluateImage(scope, this.parameters.overlay)
        const [imageValid, imageInvalid] = scope.branch(image)

        const [sizeXValid, sizeXInvalid] = scope.branch(evaluator.evaluateNumber(scope, this.parameters.size[0]))
        const [sizeYValid, sizeYInvalid] = scope.branch(evaluator.evaluateNumber(scope, this.parameters.size[1]))
        const sizeValid = scope.tuple(sizeXValid, sizeYValid)

        const result = scope.node(TransformColorOverlayNew, {
            material: materialValid,
            image: imageValid,
            size: sizeValid,
            useAlpha: true,
        }).outputMaterial
        return scope.phi(result, materialInvalid, imageInvalid, sizeXInvalid, sizeYInvalid)
    }
}

export type OverlayMaterialColorFwd = TemplateNode<OverlayMaterialColorParameters> & EvaluableTemplateNode<IMaterialGraph | null>
