import {JobState} from "@api"
import {BackgroundOperationItem, BackgroundOperationState} from "@app/common/models/background-operation"
import {Settings} from "@app/common/models/settings/settings"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {FilesService} from "@app/common/services/files/files.service"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {Utility} from "@cm/job-nodes/utility"
import {graphToJson} from "@cm/utils"
import {Subscription, timer} from "rxjs"

export class BatchDownloadOperation extends BackgroundOperationItem {
    private pollSubscription: Subscription | null = null
    private pollingIntervalMs = 3000
    private sdk: SdkService
    private organizationLegacyId: number
    private jobId: string | undefined
    private resultFileName: string

    constructor(description: string, resultFileName: string, sdk: SdkService, organizationLegacyId: number) {
        super("Download", "Preparing: " + description, true)
        this.abort = this.cancelExport.bind(this)
        this.sdk = sdk
        this.organizationLegacyId = organizationLegacyId
        this.resultFileName = resultFileName
    }

    async startOperation(dataObjectsForDownload: Map<string, number>) {
        this.state = BackgroundOperationState.Waiting

        await this.launchExportJob(dataObjectsForDownload)

        this.pollSubscription = timer(0, this.pollingIntervalMs).subscribe(() => this.pollOperationStatus())
    }

    private async launchExportJob(dataObjectsForDownload: Map<string, number>): Promise<void> {
        const filesForDownload = new Map<string, Utility.Zip.File>(
            Array.from(dataObjectsForDownload).map(([key, value]) => [
                key,
                {type: "utilityZipFile" as const, content: {type: "dataObjectReference" as const, dataObjectId: value}},
            ]),
        )

        const zipTaskInput = {
            root: {
                type: "utilityZipFolder" as const,
                content: Object.fromEntries(filesForDownload),
            },
            filename: this.resultFileName,
            destination: {type: "tmpDownloadData" as const, customerId: this.organizationLegacyId},
        }

        const jobGraph = JobNodes.jobGraph(
            JobNodes.task(Utility.Zip.task, {
                input: JobNodes.value(zipTaskInput),
            }),
            {
                platformVersion: Settings.APP_VERSION,
            },
        )

        const result = await this.sdk.gql.batchExportMaterialCreateJob({
            input: {
                name: "Batch download",
                organizationLegacyId: this.organizationLegacyId,
                graph: graphToJson(jobGraph),
            },
        })
        this.jobId = result.createJob.id
        if (this.jobId === undefined) throw new Error("Failed to create job")
    }

    private pollOperationStatus(): void {
        if (!this.jobId) throw new Error("Job ID is not set")
        this.sdk.gql.batchExportMaterialGetJobState({id: this.jobId}).then((result) => {
            if (!result.job) this.failOperation("Job not found")

            if (result.job.state === JobState.Complete) this.completeOperation()

            if (result.job.state === JobState.Cancelled) this.cancelledExternally()

            if (result.job.state === JobState.Running || result.job.state === JobState.Runnable) {
                this.state = BackgroundOperationState.InProgress
                this.progressSubject.next(-1) //Progress computation in the zip task is a bit imprecise, so we leave it at -1 for now for an infinite spinner
            }

            if ([JobState.Failed].includes(result.job.state)) this.failOperation("Job failed, state: " + result.job.state)
        })
    }

    private async completeOperation(): Promise<void> {
        if (!this.jobId) throw new Error("Job ID is not set")
        const result = await this.sdk.gql.batchExportMaterialGetJobDetails({id: this.jobId})

        this.cleanupSubscriptions()
        this.state = BackgroundOperationState.Completed
        this.progressSubject.complete()
        this.resultUrl = result.job.output?.downloadUrl
        this.resultTitle = "Download link"

        if (this.resultUrl) FilesService.downloadFile(this.resultFileName, this.resultUrl)
    }

    private cancelledExternally(): void {
        this.cleanupSubscriptions()
        this.state = BackgroundOperationState.Cancelled
    }

    failOperation(cause: string): void {
        this.cleanupSubscriptions()
        this.errorCause = cause
        this.state = BackgroundOperationState.Error
        this.progressSubject.error(new Error(cause))
    }

    private cancelExport(): void {
        if (!this.jobId) throw new Error("Job ID is not set")
        this.sdk.gql.batchExportMaterialCancelJob({id: this.jobId})
        this.cleanupSubscriptions()
        this.progressSubject.error(new Error("Operation was aborted"))
        this.state = BackgroundOperationState.Cancelled
    }

    private cleanupSubscriptions(): void {
        this.pollSubscription?.unsubscribe()
        this.pollSubscription = null
    }
}
