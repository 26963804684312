import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Box2Like, Size2Like, Vector2Like} from "@cm/math"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {
    createImagePyramid,
    ReturnType as ImagePyramidReturnType,
} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-image-pyramid"
import {gaussianBlur} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/gaussian-blur"
import {downSample, OddPixelStrategy} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/down-sample"

export const SCOPE_NAME = "CreateGaussianPyramid"

export type ParameterType = {
    sourceImage: ImageRef
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    sigma: Vector2Like | number // sigma for the gaussian blur (should be at least 1 to avoid aliasing)
    oddPixelStrategy?: OddPixelStrategy // default: "nearest"
    maxLevels?: number // defaults to full pyramid down to 1x1
    minResolution?: Size2Like // defaults to 1x1
    resultDataType?: DataType // default: sourceImage.dataType
}

export type ReturnType = ImagePyramidReturnType

export const createGaussianPyramid = (
    cmdQueue: ImageOpCommandQueue,
    {sourceImage, sourceRegion, sigma, oddPixelStrategy, maxLevels, minResolution, resultDataType}: ParameterType,
): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)

    const resultImagePyramid = createImagePyramid(cmdQueue, {
        sourceImage,
        sourceRegion,
        maxLevels,
        minResolution,
        downSamplingFn: (cmdQueue, sourceImage, _targetLevel) => {
            // TODO currently we compute the full-size blur and effectively drop every second pixel; this could be optimized
            const blurredSourceImage = gaussianBlur(cmdQueue, {
                sourceImage,
                sigma: sigma,
                borderMode: "renormalize",
            })
            return downSample(cmdQueue, {
                sourceImage: blurredSourceImage,
                interpolation: "cubic",
                oddPixelStrategy,
            })
        },
        resultDataType,
    })

    cmdQueue.endScope(SCOPE_NAME)
    return resultImagePyramid
}
