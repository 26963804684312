import {Component, computed, effect, inject, signal} from "@angular/core"
import {ActivatedRoute, ParamMap, Router} from "@angular/router"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {Nodes} from "@cm/template-nodes"
import {TemplateGraph} from "@cm/template-nodes"
import {collectReferences} from "@cm/template-nodes"
import {LoadingSpinnerComponent} from "@common/components/progress/loading-spinner/loading-spinner.component"
import {TemplateEditComponent} from "@app/template-editor/components/template-edit/template-edit.component"
import {getOldSystemRevision, isNewTemplateSystem, loadGraphForNewTemplateSystem, TemplateEditorType} from "@app/templates/helpers/editor-type"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {catchError, combineLatest, distinctUntilChanged, firstValueFrom, map, Observable, of, switchMap} from "rxjs"
import {createLinkToEditorFromPictures, createLinkToEditorFromTemplates} from "@app/common/helpers/routes"
import {RoutedDialogComponent} from "@app/common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@app/common/models/dialogs"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {z} from "zod"
import {GetTemplateDetailsForTemplateEditorQuery, TemplateState, TemplateType} from "@api"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"

const getUuidParam = (params: ParamMap, key: string) => {
    const result = z.string().uuid().safeParse(params.get(key))
    if (result.success) return result.data
    return null
}

@Component({
    selector: "cm-template-editor",
    standalone: true,
    templateUrl: "./template-editor.component.html",
    styleUrl: "./template-editor.component.scss",
    imports: [RoutedDialogComponent, LoadingSpinnerComponent, TemplateEditComponent],
})
export class TemplateEditorComponent {
    templateGraphModified = signal(false)
    dialogSizes = DialogSize

    private route = inject(ActivatedRoute)
    private router = inject(Router)
    private sdk = inject(SdkService)
    private dialog = inject(MatDialog)
    private notificationService = inject(NotificationsService)

    private routingData = toSignal(
        combineLatest([
            this.route.paramMap.pipe(
                map((params) => getUuidParam(params, "templateId")),
                distinctUntilChanged(),
            ),
            this.route.paramMap.pipe(
                map((params) => getUuidParam(params, "pictureId")),
                distinctUntilChanged(),
            ),
            this.route.paramMap.pipe(
                map((params) => getUuidParam(params, "templateRevisionId")),
                distinctUntilChanged(),
            ),
        ]).pipe(
            map(([templateId, pictureId, templateRevisionId]) => ({
                templateId,
                pictureId,
                templateRevisionId,
            })),
            catchError((error) => {
                console.error(error)
                return of(null)
            }),
        ),
        {
            initialValue: null,
        },
    )
    templateRevision = toSignal(
        toObservable(this.routingData).pipe(
            switchMap((routingData) => {
                if (!routingData) return of(null)

                const revisionId = routingData.templateRevisionId
                if (revisionId !== null) return this.sdk.gql.getTemplateRevisionDetailsForTemplateEditor({id: revisionId})
                else return of(null)
            }),
            map((result) => result?.templateRevision ?? null),
        ),
        {
            initialValue: null,
        },
    )
    protected templateGraph = computed(() => {
        const templateRevision = this.templateRevision()
        if (!templateRevision) return null

        const {graph} = templateRevision
        if (!graph) return new TemplateGraph({name: "Untitled Template", nodes: new Nodes({list: []})})
        return loadGraphForNewTemplateSystem(graph)
    })

    constructor() {
        effect(async () => {
            const routingData = this.routingData()
            if (!routingData) return

            const {templateId, pictureId, templateRevisionId} = routingData

            const getNewRouterPath = async () => {
                const getOrCreateLatestTemplateRevision = async (template: GetTemplateDetailsForTemplateEditorQuery["template"]) => {
                    const {revisions, id: templateId} = template
                    const latestRevision = revisions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).at(0)

                    if (latestRevision) return latestRevision.id
                    else {
                        const savedRevision = (
                            await this.sdk.gql.createTemplateRevisionForTemplateEditor({
                                input: {templateId: templateId, graph: null, completed: false},
                            })
                        ).createTemplateRevision
                        return savedRevision.id
                    }
                }

                if (templateId !== null && templateRevisionId === null) {
                    const {template} = await this.sdk.gql.getTemplateDetailsForTemplateEditor({id: templateId})
                    return createLinkToEditorFromTemplates(this.router, templateId, await getOrCreateLatestTemplateRevision(template), TemplateEditorType.New)
                } else if (pictureId !== null && templateRevisionId === null) {
                    const {picture} = await this.sdk.gql.getPictureDetailsForTemplateEditor({id: pictureId})
                    const {template} = picture

                    if (!template) {
                        const savedTemplate = (
                            await this.sdk.gql.createTemplateForTemplateEditor({
                                input: {
                                    organizationId: picture.organization.id,
                                    state: TemplateState.Draft,
                                    templateType: TemplateType.Scene,
                                    public: false,
                                    pictureId: picture.id,
                                },
                            })
                        ).createTemplate

                        const savedRevision = (
                            await this.sdk.gql.createTemplateRevisionForTemplateEditor({
                                input: {templateId: savedTemplate.id, graph: null, completed: false},
                            })
                        ).createTemplateRevision
                        return createLinkToEditorFromPictures(pictureId, savedRevision.id, TemplateEditorType.New)
                    } else {
                        return createLinkToEditorFromPictures(pictureId, await getOrCreateLatestTemplateRevision(template), TemplateEditorType.New)
                    }
                } else return null
            }

            const newRouterPath = await getNewRouterPath()
            if (newRouterPath !== null)
                this.router.navigate(newRouterPath, {
                    queryParamsHandling: "preserve",
                })
        })
    }

    handleTemplateGraphModified(modified: boolean) {
        this.templateGraphModified.set(modified)
    }

    private confirmSave(title: string, message: string): Observable<boolean | undefined> {
        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: title,
                message: message,
                confirmLabel: "Yes",
                cancelLabel: "No",
            },
        })

        return dialogRef.afterClosed()
    }

    async handleSaveInLibrary(templateGraph: TemplateGraph) {
        const currentRevision = this.templateRevision()
        if (!currentRevision) throw new Error("No template revision loaded")

        const savedTemplate = (
            await this.sdk.gql.createTemplateForTemplateEditor({
                input: {
                    organizationId: currentRevision.template.organization.id,
                    state: TemplateState.Draft,
                    templateType: TemplateType.Part,
                    public: false,
                    comment: `(Created from editor selection within template ${currentRevision.template.id})`,
                    name: templateGraph.parameters.name ?? "Untitled Template",
                },
            })
        ).createTemplate

        await this.sdk.gql.createTemplateRevisionForTemplateEditor({
            input: {templateId: savedTemplate.id, graph: templateGraph.serialize(), completed: false},
        })

        console.log(`New template ${templateGraph.parameters.name} saved, id: ${savedTemplate.id}`)
        this.notificationService.showInfo(`New template ${templateGraph.parameters.name} saved, id: ${savedTemplate.id}`)
    }

    async handleSave(onSaved?: () => void) {
        const templateRevision = this.templateRevision()
        if (!templateRevision) throw new Error("No template revision loaded")
        const templateGraph = this.templateGraph()
        if (!templateGraph) throw new Error("No template graph loaded")

        const useOrCreateTemplateRevision = async () => {
            if (templateRevision.graph && !isNewTemplateSystem(templateRevision.graph)) {
                const latestRevision = (await this.sdk.gql.getLatestTemplateRevisionDetailsForTemplateEditor({id: templateRevision.id})).templateRevision
                    .template.latestRevision!

                if (latestRevision.id !== templateRevision.id) {
                    if (!(await firstValueFrom(this.confirmSave("Overwrite?", "A revision for the new template editor already exists. Overwrite?"))))
                        return null
                    return latestRevision
                } else {
                    if (!(await firstValueFrom(this.confirmSave("Create new revision?", "The current template will be saved as new revision. Proceed?"))))
                        return null
                    const savedRevision = (
                        await this.sdk.gql.createTemplateRevisionForTemplateEditor({
                            input: {templateId: templateRevision.template.id, graph: null, completed: false},
                        })
                    ).createTemplateRevision

                    return savedRevision
                }
            } else return templateRevision
        }

        const revision = await useOrCreateTemplateRevision()
        if (!revision) return

        const references = collectReferences(templateGraph)

        await this.sdk.gql.updateTemplateRevisionNew({
            input: {
                id: revision.id,
                graph: templateGraph.serialize(),
                templateReferences: [...references.templateReferences],
                materialReferences: [...references.materialReferences],
                hdriReferences: [...references.hdriReferences],
                dataObjectAssignments: Array.from(references.dataObjectAssignments, ([key, value]) => ({
                    dataObjectId: key,
                    types: [...value],
                })),
            },
        })

        if (revision.id !== templateRevision.id) {
            this.notificationService.showInfo("Created new template revision")

            const getNewRouterPath = () => {
                const routingData = this.routingData()
                if (!routingData) return null

                const {templateId, pictureId} = routingData

                if (templateId) return createLinkToEditorFromTemplates(this.router, templateId, revision.id, TemplateEditorType.New)
                else if (pictureId) {
                    return createLinkToEditorFromPictures(pictureId, revision.id, TemplateEditorType.New)
                } else return null
            }

            const newRouterPath = getNewRouterPath()
            if (newRouterPath !== null)
                this.router.navigate(newRouterPath, {
                    queryParamsHandling: "preserve",
                })
        } else this.notificationService.showInfo("Template revision saved")

        onSaved?.()
    }

    async handleOpenInOldEditor() {
        const getNewRouterPath = async () => {
            const routingData = this.routingData()
            if (!routingData) return null

            const {templateId, pictureId} = routingData

            if (templateId)
                return createLinkToEditorFromTemplates(this.router, templateId, (await getOldSystemRevision(templateId, this.sdk)).id, TemplateEditorType.Old)
            else if (pictureId) {
                const {picture} = await this.sdk.gql.getPictureDetailsForTemplateEditor({id: pictureId})
                const {template} = picture
                if (template) return createLinkToEditorFromPictures(pictureId, (await getOldSystemRevision(template.id, this.sdk)).id, TemplateEditorType.Old)
                else return null
            } else return null
        }

        const newRouterPath = await getNewRouterPath()
        if (newRouterPath !== null)
            this.router.navigate(newRouterPath, {
                queryParamsHandling: "preserve",
            })
    }
}
