import {BoundsData, MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {Matrix4} from "@cm/math"

export class CentroidAccumulator {
    private centroidSum: [number, number, number] = [0, 0, 0]
    private totalArea = 0

    constructor() {}

    accumulate(data: MeshData | BoundsData, matrix?: Matrix4): void {
        if (!data) return
        let centroid: readonly [number, number, number, ...any[]] = data.centroid
        if (matrix) {
            centroid = matrix.multiplyVectorXYZW(centroid[0], centroid[1], centroid[2], 1)
        }
        for (let i = 0; i < 3; i++) this.centroidSum[i] += centroid[i] * data.surfaceArea
        this.totalArea += data.surfaceArea
    }

    finalize() {
        if (this.totalArea > 0) {
            for (let i = 0; i < 3; i++) this.centroidSum[i] *= 1 / this.totalArea
        }
        return {
            centroid: this.centroidSum,
            surfaceArea: this.totalArea,
        }
    }
}

type AABBData = [[number, number, number], [number, number, number]]

export class AABBAccumulator {
    private minX = Infinity
    private minY = Infinity
    private minZ = Infinity
    private maxX = -Infinity
    private maxY = -Infinity
    private maxZ = -Infinity

    accumulatePoint(x: number, y: number, z: number, matrix?: Matrix4): void {
        if (matrix) {
            ;[x, y, z] = matrix.multiplyVectorXYZW(x, y, z, 1)
        }
        this.minX = Math.min(this.minX, x)
        this.minY = Math.min(this.minY, y)
        this.minZ = Math.min(this.minZ, z)
        this.maxX = Math.max(this.maxX, x)
        this.maxY = Math.max(this.maxY, y)
        this.maxZ = Math.max(this.maxZ, z)
    }

    accumulate(aabb: AABBData) {
        const [[minX, minY, minZ], [maxX, maxY, maxZ]] = aabb
        this.minX = Math.min(this.minX, minX)
        this.minY = Math.min(this.minY, minY)
        this.minZ = Math.min(this.minZ, minZ)
        this.maxX = Math.max(this.maxX, maxX)
        this.maxY = Math.max(this.maxY, maxY)
        this.maxZ = Math.max(this.maxZ, maxZ)
    }

    finalize(): AABBData {
        return [
            [this.minX, this.minY, this.minZ],
            [this.maxX, this.maxY, this.maxZ],
        ]
    }
}

export function transformBounds(data: BoundsData, matrix: Matrix4): BoundsData {
    const [cx, cy, cz] = matrix.multiplyVectorXYZW(...data.centroid, 1)
    const [[minX, minY, minZ], [maxX, maxY, maxZ]] = data.aabb
    const aabb = new AABBAccumulator()
    aabb.accumulatePoint(minX, minY, minZ, matrix)
    aabb.accumulatePoint(maxX, minY, minZ, matrix)
    aabb.accumulatePoint(minX, maxY, minZ, matrix)
    aabb.accumulatePoint(maxX, maxY, minZ, matrix)
    aabb.accumulatePoint(minX, minY, maxZ, matrix)
    aabb.accumulatePoint(maxX, minY, maxZ, matrix)
    aabb.accumulatePoint(minX, maxY, maxZ, matrix)
    aabb.accumulatePoint(maxX, maxY, maxZ, matrix)
    return {
        centroid: [cx, cy, cz],
        surfaceArea: data.surfaceArea,
        aabb: aabb.finalize(),
        radii: {xy: 0, xz: 0, yz: 0, xyz: 0}, //TODO: transform bounding radii
    }
}

export class BoundsAccumulator {
    private acc = new CentroidAccumulator()
    private aabb = new AABBAccumulator()
    private count = 0

    constructor() {}

    accumulate(data: BoundsData, matrix?: Matrix4): void {
        if (!data) return
        ++this.count
        if (matrix) {
            data = transformBounds(data, matrix)
        }
        this.acc.accumulate(data)
        this.aabb.accumulate(data.aabb)
        //TODO: merge radii
    }

    finalize(): BoundsData {
        if (this.count === 0) {
            return {
                centroid: [0, 0, 0],
                surfaceArea: 0,
                aabb: [
                    [0, 0, 0],
                    [0, 0, 0],
                ],
                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
            }
        } else {
            return {
                ...this.acc.finalize(),
                aabb: this.aabb.finalize(),
                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
            }
        }
    }
}

export function mergeBounds(boundsList: BoundsData[]): BoundsData {
    const acc = new BoundsAccumulator()
    for (const bounds of boundsList) {
        acc.accumulate(bounds)
    }
    return acc.finalize()
}
