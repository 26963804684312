<textarea
    class="cm-text-area"
    #inputField
    [disabled]="disabled"
    [placeholder]="placeholder ? placeholder : ''"
    [value]="value"
    (change)="onChange($event)"
    (keyup)="onKeyUp($event)"
    cdkTextareaAutosize
    cdkAutosizeMinRows="{{ minRows }}"
></textarea>
