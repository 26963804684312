import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import paper from "paper"
import {Vector2Like} from "@cm/math"

export class VectorFieldItem extends CanvasBaseToolboxItemBase {
    constructor(parentItem: CanvasBaseToolboxItem) {
        super(parentItem)
    }

    resetAngleData() {
        this.group?.remove()
        this.group = new paper.Group()
    }

    addAngleData(
        width: number,
        height: number,
        angles: Float32Array,
        certainties: Float32Array,
        lengthScale: number,
        color: paper.Color,
        scale: number,
        offset?: Vector2Like,
    ) {
        this.beginPaperCreation()
        if (!this.group) {
            this.group = new paper.Group()
        }
        offset ??= {x: 0, y: 0}
        const MAX_WIDTH = 1024
        const MAX_HEIGHT = 50
        const widthToShow = Math.min(width, MAX_WIDTH)
        const heightToShow = Math.min(height, MAX_HEIGHT)
        for (let y = 0; y < heightToShow; y++) {
            for (let x = 0; x < widthToShow; x++) {
                const angle = angles[y * width + x]
                const certainty = certainties[y * width + x]
                const cx = offset.x + (x + 0.5) * scale
                const cy = offset.y + (y + 0.5) * scale
                const length = lengthScale * certainty
                const line = new paper.Path.Line(new paper.Point(cx, cy), new paper.Point(cx + Math.cos(angle) * length, cy + Math.sin(angle) * length))
                line.strokeColor = color
                line.strokeWidth = 1 / this.zoomLevel
                this.group.addChild(line)
            }
        }
    }

    private group?: paper.Group
}
