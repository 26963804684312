import {Component, computed, inject} from "@angular/core"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Settings} from "@app/common/models/settings/settings"
import {DialogService} from "@app/common/services/dialog/dialog.service"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {Labels} from "@app/platform/models/state-labels"
import {selectFile, uploadImage} from "@app/template-editor/helpers/upload"
import {DataObjectReference} from "@cm/template-nodes/nodes/data-object-reference"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CardComponent} from "@common/components/cards"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ActionPanelRowComponent} from "@common/components/menu"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {FilesizePipe} from "@common/pipes/filesize/filesize.pipe"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {from, map, switchMap} from "rxjs"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"

@Component({
    selector: "cm-data-object-reference-inspector",
    standalone: true,
    templateUrl: "./data-object-reference-inspector.component.html",
    styleUrl: "./data-object-reference-inspector.component.scss",
    imports: [
        InspectorSectionComponent,
        ValueSlotComponent,
        CardComponent,
        DataObjectThumbnailComponent,
        ThumbnailComponent,
        ActionPanelRowComponent,
        FilesizePipe,
        MatTooltipModule,
        TimeAgoPipe,
        FormatDatePipe,
    ],
})
export class DataObjectReferenceInspectorComponent extends BaseInspectorComponent<DataObjectReference> {
    private sdk = inject(SdkService)
    private uploadService = inject(UploadGqlService)
    dialogService = inject(DialogService)
    organizations = inject(OrganizationsService)
    private dialog = inject(MatDialog)
    dataObjectId = computed(() => this.parameters().dataObjectId)
    dataObject = toSignal(
        toObservable(this.dataObjectId).pipe(
            switchMap((dataObjectId) => from(this.sdk.gql.getDataObjectDetailsForDataObjectReferenceInspector({legacyId: dataObjectId}))),
            map(({dataObject}) => dataObject),
        ),
        {
            initialValue: null,
        },
    )

    thumbnailUrl = computed(() => {
        const dataObjectData = this.dataObject()
        if (!dataObjectData) return Settings.IMAGE_NOT_AVAILABLE_SMALL_URL
        return undefined
    })
    thumbnailObject = computed(() => {
        const dataObjectData = this.dataObject()
        if (dataObjectData) return dataObjectData
        return undefined
    })
    title = computed(() => {
        const dataObjectData = this.dataObject()
        if (!dataObjectData) return this.parameters().name

        const {legacyId, originalFileName} = dataObjectData

        return `${legacyId} - ${originalFileName}`
    })

    async onClick() {
        const file = await selectFile("Select an image file for upload", "image/jpeg, image/png, image/webp")
        if (file) {
            const image = await uploadImage(this.sdk, this.uploadService, this.sceneManagerService, this.dialog, file)
            if (!image) return
            this.sceneManagerService.modifyTemplateGraph(() => {
                this.node().updateParameters({dataObjectId: image.parameters.dataObjectId})
            })
        }
    }

    protected readonly Labels = Labels
}
