import {ConfigurationGroupNode} from "#pricing/nodes/configuration-group-node"
import {ConfigurationList} from "#pricing/nodes/configuration-list"
import {GroupId, PricedItem, PricingContext, PricingNodegraph, VariantId} from "#pricing/nodes/core"
import {PriceList} from "#pricing/nodes/price-list"
import {VariantConditionNode} from "#pricing/nodes/variant-condition-node"
import {DeclareNodeTS, NodeVersion, registerNode, versionChain} from "@cm/graph"
import {CircularRefNode} from "@cm/graph/node-graph"

const v0: NodeVersion<PricingContext, {}, PricedItemNodeParametersV0, {}, PricedItemNodePrimitiveParameters> = {
    toNextVersion: (parameters) => {
        return {
            description: parameters.description,
            uniqueId: parameters.uniqueId,
            sku: parameters.sku,
            condition: parameters.conditions
                ? (() => {
                      if (parameters.currentConfigurations instanceof CircularRefNode)
                          throw new Error("Cannot resolve circular references when going from v0 to v1 in priced item node")

                      return new VariantConditionNode({
                          condition: {variantIds: parameters.conditions, variantOperator: "AND", negated: false},
                          currentConfigurations: parameters.currentConfigurations ?? new ConfigurationList({list: []}),
                      })
                  })()
                : undefined,
            subPrices: parameters.subPrices,
            dependsOnSubprice: parameters.dependsOnSubprice,
            amount: parameters.amount,
        }
    },
}

export type PricedItemNodeParametersV0 = {
    description: string //human-readable name, usually derived from the customer's catalog
    uniqueId: string //unique id, which is usually derived from the customer's catalog. This must map to a price.
    sku: string | undefined //sku maintained by the customer. Sometimes, this is the same as the uniqueId
    conditions: VariantId[] | undefined //for now, this is supposed to be an array of configuration variant ids
    currentConfigurations: ConfigurationList | undefined //this will be populated from a set of ConfigurationGroupNodes, which serve as  interface to the configurator
    subPrices: PriceList | undefined //a list of related prices that depend on the current node
    conditionalPrice: boolean //if true, the price is only added, if the conditions are met. Allows to set up a conditional node that is not (yet) evaluated to make editing easier
    dependsOnSubprice: boolean //if true, the price is only added if a subprice is present. Can be used if the conditions on level of the pricing note are ambiguous, e.g. for darran's susu.
    amount?: number
}

export type PricedItemNodePrimitiveParameters = {
    description: string //human-readable name, usually derived from the customer's catalog
    uniqueId: string //unique id, which is usually derived from the customer's catalog. This must map to a price.
    sku: string | undefined //sku maintained by the customer. Sometimes, this is the same as the uniqueId
    condition: VariantConditionNode | undefined //for now, this is supposed to be an array of configuration variant ids
    subPrices: PriceList | undefined //a list of related prices that depend on the current node
    dependsOnSubprice: boolean //if true, the price is only added if a subprice is present. Can be used if the conditions on level of the pricing note are ambiguous, e.g. for darran's susu.
}

export type PricedItemNodeParameters = {
    amount?: number
}

@registerNode
export class PricedItemNode extends DeclareNodeTS<PricedItem[], PricingContext, PricedItemNodeParameters, PricedItemNodePrimitiveParameters>(
    {
        run: async ({get, parameters, context}) => {
            if (!parameters.dependsOnSubprice && parameters.condition) {
                const condition = await get(parameters.condition)
                if (!condition) return []
            }

            const subPrices = (await get(parameters.subPrices))?.flat()
            const result = subPrices ? subPrices : []

            if (parameters.dependsOnSubprice && (subPrices === undefined || subPrices.length === 0)) return []

            const amount = (await get(parameters.amount)) ?? 1

            result.unshift({
                description: parameters.description,
                price: context.getPrice(parameters.uniqueId),
                currency: context.getCurrency(),
                sku: parameters.sku,
                amount,
            })
            return result
        },
    },
    {
        nodeClass: "PricedItemNode",
        versionChain: versionChain([v0]),
    },
) {
    /**The functions below are used for constructing the graph in a convenient way and for displaying it in the ui. They are not required for evaluating the graph.*/
    getUniqueId(): string {
        return this.parameters.uniqueId
    }

    getDescription(): string {
        return this.parameters.description
    }

    getPrice(context: PricingContext): number {
        return context.getPrice(this.parameters.uniqueId)
    }

    getSubprices(): PricingNodegraph[] {
        if (!this.parameters.subPrices) return []
        return this.parameters.subPrices.parameters.list
    }

    hasSubprices(): boolean {
        return this.getSubprices().length > 0
    }

    equals(node: PricedItemNode): boolean {
        if (!node) return false
        return this.parameters.uniqueId === node.parameters.uniqueId
    }

    addDependency(configGroupNode: ConfigurationGroupNode, variantId: VariantId) {
        if (this.parameters.condition === undefined) throw new Error("Node does not accept conditions")
        this.parameters.condition.addDependency(configGroupNode, variantId)
    }

    removeDependency(variantId: VariantId) {
        if (this.parameters.condition === undefined) throw new Error("Node does not accept conditions")
        this.parameters.condition.removeDependency(variantId)
    }

    canAddDependency(groupId: GroupId, variantId: VariantId) {
        if (this.parameters.condition === undefined) return false
        return this.parameters.condition.canAddDependency(groupId, variantId)
    }

    getVariantIds(): VariantId[] {
        if (this.parameters.condition === undefined) return []
        return this.parameters.condition.getVariantIds()
    }
}
