import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeVec3Node} from "#material-nodes/three-utils"
import {vec3} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({
    camera: materialSlots,
    generated: materialSlots,
    normal: materialSlots,
    object: materialSlots,
    reflection: materialSlots,
    uv: materialSlots,
    window: materialSlots,
})
const InputTypeSchema = z.object({})
const ParametersTypeSchema = z.object({
    camera: vec3.optional(),
    generated: vec3.optional(),
    normal: vec3.optional(),
    object: vec3.optional(),
    reflection: vec3.optional(),
    uv: vec3.optional(),
    window: vec3.optional(),
})
export class TexCoord extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({parameters}) => {
            const camera = threeConvert(parameters.camera, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const generated = THREENodes.uv(0)
            const normal = threeConvert(parameters.normal, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const object = threeConvert(parameters.object, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const reflection = threeConvert(parameters.reflection, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const uv = THREENodes.uv(0)
            const window = threeConvert(parameters.window, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})

            return {camera, generated, normal, object, reflection, uv, window}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
