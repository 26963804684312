import {DatePipe} from "@angular/common"
import {Component, computed, inject, ViewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatIcon} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {RouterOutlet} from "@angular/router"
import {ContentTypeModel, JobDetailsFragment, JobState, MutationUpdateJobInput, DataObjectAssignmentType} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {IsLoadingDirective} from "@common/directives"
import {jobIsActive} from "@common/helpers/jobs/states"
import {makeHeartbeat} from "@common/helpers/utils/heartbeat"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {ExecutionPlanComponent} from "@platform/components/jobs/execution-plan/execution-plan.component"
import {JobTasksTableComponent} from "@platform/components/jobs/job-tasks-table/job-tasks-table.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {CopyValueToClipboardMenuComponent} from "@platform/components/shared/copy-value-to-clipboard-menu/copy-value-to-clipboard-menu.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {catchError, EMPTY, from, map, Observable, tap} from "rxjs"
import {TabStateService} from "@common/services/tab-state/tab-state.service"
import {MatTooltip} from "@angular/material/tooltip"

@Component({
    selector: "cm-job-details",
    templateUrl: "job-details.component.html",
    styleUrls: ["job-details.component.scss"],
    standalone: true,
    imports: [
        DetailsDialogLayoutComponent,
        SectionComponent,
        ExecutionPlanComponent,
        JobTasksTableComponent,
        MatInputModule,
        MatSelectModule,
        IsLoadingDirective,
        FormsModule,
        MatCheckboxModule,
        MatButtonModule,
        RoutedDialogComponent,
        RoutedDialogComponent,
        MatMenuModule,
        MatIcon,
        FilesSectionComponent,
        TitleSectionComponent,
        CopyValueToClipboardMenuComponent,
        OrganizationSelectComponent,
        RouterOutlet,
        DatePipe,
        MatTooltip,
        TimeAgoPipe,
    ],
})
export class JobDetailsComponent extends BaseDetailsComponent<JobDetailsFragment, Omit<MutationUpdateJobInput, "id">> {
    override _contentTypeModel = ContentTypeModel.JobTask
    override _fetchItem = this.sdk.gql.jobDetails
    override _updateItem = this.sdk.gql.updateJobDetails

    tabState = inject(TabStateService)

    @ViewChild("overlayDialogComponent") overlayDialogComponent!: RoutedDialogComponent

    // fires whenever the details view and its subcomponents should refresh their data
    // this might be triggered by an explicit refresh, a timer, the user returning to the app, etc.
    protected heartbeat$: Observable<string | null> = EMPTY

    override ngOnInit() {
        this.heartbeat$ = makeHeartbeat(
            this.itemId$,
            this.refresh,
            this.tabState,
            (variables) => from(this._fetchItem(variables).then(({item}) => item)),
            jobIsActive,
        ).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((item) => {
                this.item$.next(item)
                this.$loadError.set(null)
            }),
            catchError((error) => {
                this.item$.next(null)
                this.$loadError.set(error)
                return EMPTY
            }),
            map((item) => item.id),
        )
        this.debounceUpdates()
    }

    $copyItems = computed(() => {
        const result: {value: string | undefined | (() => string | undefined); displayText: string; icon: string}[] = [
            {value: this.$item()?.legacyId?.toString(), displayText: "Legacy ID", icon: "elderly"},
            {value: this.$item()?.id, displayText: "ID", icon: "badge"},
        ]
        if (this.$can().read.job(null, "jsonGraph")) {
            result.push({
                value: this.getGraph,
                displayText: "JSON Graph",
                icon: "data_object",
            })
        }
        return result
    })

    cancelJob() {
        void this.notifications.withUserFeedback(
            async () => {
                const item = this.$item()
                if (item) {
                    await this.sdk.gql.jobDetailsCancel({id: item.id})
                    this.refresh.item(item)
                } else {
                    throw new Error("No job to cancel")
                }
            },
            {
                success: "Job cancelled",
                error: "Failed to cancel job",
            },
        )
    }

    nudgeJob() {
        void this.notifications.withUserFeedback(
            async () => {
                const item = this.$item()
                if (item) {
                    await this.sdk.gql.jobDetailsNudge({id: item.id})
                    this.refresh.item(item)
                } else {
                    throw new Error("No job to cancel")
                }
            },
            {
                success: "Job nudged",
                error: "Failed to nudge job",
            },
        )
    }

    restartJob() {
        void this.notifications.withUserFeedback(
            async () => {
                const item = this.$item()
                if (item) {
                    await this.sdk.gql.jobDetailsRestart({id: item.id})
                    this.refresh.item(item)
                } else {
                    throw new Error("No job to restart")
                }
            },
            {
                success: "Job restarted",
                error: "Failed to restart job",
            },
        )
    }

    rerunAllTasksInJob() {
        void this.notifications.withUserFeedback(
            async () => {
                const item = this.$item()
                if (item) {
                    await this.sdk.gql.jobDetailsRerunAllTasksInJob({id: item.id})
                    this.refresh.item(item)
                } else {
                    throw new Error("No job found")
                }
            },
            {
                success: "Job cleaned and restarted",
                error: "Failed to clean job",
            },
        )
    }

    taskClick(task: {id: string}) {
        void this.router.navigate([task.id], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }

    togglePriority(highPriority: boolean) {
        void this.notifications.withUserFeedback(
            async () => {
                const item = this.$item()
                if (item) {
                    await this.sdk.gql.updateJobDetails({input: {id: item.id, priority: highPriority ? 50 : 0}})
                    this.refresh.item(item)
                } else {
                    throw new Error("No job to change priority of")
                }
            },
            {
                success: "Job priority changed",
                error: "Failed to change job priority",
            },
        )
    }

    getGraph = () => {
        const item = this.$item()
        return item ? JSON.stringify(item.graph ?? {}, null, 2) : ""
    }

    performDelete = async () => {
        const item = this.$item()
        if (item) {
            await this.sdk.gql.jobDetailsDeleteJob(item)
            await this.router.navigate(["/jobs"])
            this.refresh.item(item)
        }
    }

    deleteConfirmationMessage() {
        const item = this.$item()
        return (
            (item ? `The job '${item.name}'` : "This job") +
            " with all its assigned files will be deleted. This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?"
        )
    }

    override get title(): string | null {
        const item = this.$item()
        if (!item) {
            return null
        }
        return item?.name ?? `Job ${item?.legacyId}`
    }

    async closeDialog() {
        await this.router.navigate(["jobs"], {queryParamsHandling: "preserve"})
    }

    protected readonly DialogSize = DialogSize
    protected readonly JobState = JobState
    protected readonly ContentTypeModel = ContentTypeModel
    protected readonly DataObjectAssignmentType = DataObjectAssignmentType
}
