import {z} from "zod"
import {Nodes} from "#template-nodes/nodes/nodes"
import {TemplateNode} from "#template-nodes/types"
import {nodeInstance} from "@cm/graph/instance"

export const groupNodeParameters = z.object({nodes: nodeInstance(Nodes)}) //owns nodes

export type GroupNodeParameters = {
    nodes: Nodes
} //TODO: Type

export const isGroupNode = (instance: TemplateNode): instance is TemplateNode<GroupNodeParameters> => groupNodeParameters.safeParse(instance.parameters).success
