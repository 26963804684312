import {
    Operator,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import * as TextureEditNodes from "@app/textures/texture-editor/texture-edit-nodes"
import {assertNever, deepCopy} from "@cm/utils"
import {TestPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/test/panel/test-panel.component"
import {Color, Size2, Vector2} from "@cm/math"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {resize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {createGaussianPyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-gaussian-pyramid"
import {reshape} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/utils/reshape"
import {reduce} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-reduce"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {createView} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/utils/create-view"
import {convolve} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convolve"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import paper from "paper"
import {TestToolbox} from "@app/textures/texture-editor/operator-stack/operators/test/toolbox/test-toolbox"
import {extractChannel} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-extract-channel"
import {rasterizeGeometry} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-rasterize-geometry"
import {normalizedCrossCorrelation} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/normalized-cross-correlation"
import {Vector} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/edit-vector-item"
import {ArrowItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/arrow-item"

export type ParameterNumber = {
    readonly type: "number"
    readonly name: string
    readonly min: number
    readonly max: number
    readonly exponent: number
    value: number
}

export type ParameterBoolean = {
    readonly type: "boolean"
    readonly name: string
    value: boolean
}

export type ParameterEnum = {
    readonly type: "enum"
    readonly name: string
    readonly options: string[]
    value: string
}

export type Parameter = ParameterNumber | ParameterBoolean | ParameterEnum

export type TestMode = "flow-field" | "cast-feature-ray"

export class OperatorTest extends OperatorBase<TextureEditNodes.OperatorTest> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["no-clone", "apply-to-all-texture-types"])

    readonly panelComponentType?: OperatorPanelComponentType = TestPanelComponent
    readonly canvasToolbox: TestToolbox

    readonly type = "operator-test" as const

    readonly testMode: TestMode = "cast-feature-ray"

    private parameters: Parameter[] = []

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorTest | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-test",
                enabled: true,
            },
        )

        this.canvasToolbox = new TestToolbox(this)
    }

    override dispose() {
        super.dispose()
        this.canvasToolbox.remove()
    }

    getParameters() {
        return this.parameters
    }

    setParameterValue(index: number, value: number | boolean | string) {
        const parameter = this.parameters[index]
        switch (parameter.type) {
            case "number":
                if (typeof value !== "number") {
                    throw new Error("Invalid value")
                }
                parameter.value = value
                break
            case "boolean":
                if (typeof value !== "boolean") {
                    throw new Error("Invalid value")
                }
                parameter.value = value
                break
            case "enum":
                if (typeof value !== "string") {
                    throw new Error("Invalid value")
                }
                parameter.value = value
                break
            default:
                assertNever(parameter)
        }
        this.requestEval()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        throw new Error("Not supported")
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        switch (this.testMode) {
            case "flow-field":
                return this.testFlowField(cmdQueue, input, hints)
            case "cast-feature-ray":
                return this.testCastFeatureRay(cmdQueue, input, hints)
            default:
                assertNever(this.testMode)
        }
        //return this.testResize(cmdQueue, input, hints)
        // return this.testReduce(cmdQueue, input, hints)
        //return this.testView(cmdQueue, input, hints)
        //return this.testGaussianPyramid(cmdQueue, input, hints)
    }

    private _lastRayCastVector?: Vector

    private async testCastFeatureRay(cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        if (!this.canvasToolbox.editVectorItem.selected) {
            this.canvasToolbox.editVectorItem.selected = true
            this.canvasToolbox.editVectorItem.vectorChanged.subscribe((vector) => {
                this._lastRayCastVector = vector
                this.requestEval()
            })
        }

        if (!this._lastRayCastVector) {
            return {resultImage: input}
        }
        const rayOrigin = Vector2.fromVector2Like(this._lastRayCastVector.from)
        const rayEnd = Vector2.fromVector2Like(this._lastRayCastVector.to)
        const rayDelta = rayEnd.sub(rayOrigin)
        const rayDir = rayDelta.normalized()
        const rayLength = rayDelta.norm()

        // if (!this._rayVectorItem) {
        const color = new Color(Math.random(), Math.random(), Math.random())
        new ArrowItem(this.canvasToolbox, rayOrigin, rayEnd, 3, color)
        // }
        const rayDirPerp = rayDir.perp()
        const templateWidth = Math.round(rayLength)
        const templateHeight = Math.round(rayLength / 2)
        const templateImage = rasterizeGeometry(cmdQueue, {
            geometry: {
                topology: "triangleList",
                vertices: {
                    positions: [new Vector2(0, 0), new Vector2(templateWidth, 0), new Vector2(templateWidth, templateHeight), new Vector2(0, templateHeight)],
                    uvs: [
                        rayOrigin.add(rayDirPerp.mul(-templateHeight * 0.5)),
                        rayOrigin.add(rayDir.mul(templateWidth).add(rayDirPerp.mul(-templateHeight * 0.5))),
                        rayOrigin.add(rayDir.mul(templateWidth).add(rayDirPerp.mul(templateHeight * 0.5))),
                        rayOrigin.add(rayDirPerp.mul(templateHeight * 0.5)),
                    ],
                },
                indices: [0, 1, 2, 0, 2, 3],
            },
            textureImage: input,
            resultImageOrDescriptor: {
                ...input.descriptor,
                width: templateWidth,
                height: templateHeight,
            },
        })
        const sourceStartRatio = 0.5
        const sourceStart = rayOrigin.add(rayDir.mul(rayLength * sourceStartRatio))
        const sourceWidth = templateWidth + Math.round(templateWidth * (1 - sourceStartRatio))
        const sourceHeight = templateHeight
        const sourceImage = rasterizeGeometry(cmdQueue, {
            geometry: {
                topology: "triangleList",
                vertices: {
                    positions: [new Vector2(0, 0), new Vector2(sourceWidth, 0), new Vector2(sourceWidth, sourceHeight), new Vector2(0, sourceHeight)],
                    uvs: [
                        sourceStart.add(rayDirPerp.mul(-sourceHeight * 0.5)),
                        sourceStart.add(rayDir.mul(sourceWidth).add(rayDirPerp.mul(-sourceHeight * 0.5))),
                        sourceStart.add(rayDir.mul(sourceWidth).add(rayDirPerp.mul(sourceHeight * 0.5))),
                        sourceStart.add(rayDirPerp.mul(sourceHeight * 0.5)),
                    ],
                },
                indices: [0, 1, 2, 0, 2, 3],
            },
            textureImage: input,
            resultImageOrDescriptor: {
                ...input.descriptor,
                width: sourceWidth,
                height: sourceHeight,
            },
        })
        const correlation = normalizedCrossCorrelation(cmdQueue, {
            sourceImage: sourceImage,
            templateImage: templateImage,
        })
        if (cmdQueue instanceof ImageOpCommandQueueWebGL2) {
            cmdQueue.lambda({correlation}, async ({correlation}) => {
                const correlationImage = await cmdQueue.context.getImage(correlation)
                const correlationData = await correlationImage.ref.halImage.readRawImageData("float32")
                correlationImage.release()
                let lastPeakPos = 0
                let lastPeakMax = 0
                // let lastValue = correlationData[0]
                // let valleyPassed = false
                for (let i = correlationData.length - 1; i >= correlationData.length / 2; i--) {
                    const value = correlationData[i]
                    // const delta = value - lastValue
                    // if (value < 0) {
                    //     if (valleyPassed && lastPeakMax > 0) {
                    //         break
                    //     }
                    //     valleyPassed = true
                    // }
                    if (value > lastPeakMax) {
                        // rising
                        lastPeakPos = i
                        lastPeakMax = value
                    }
                }
                console.log(`Last peak at ${lastPeakPos} with value ${lastPeakMax}`)
                const newRayOrigin = sourceStart.add(rayDir.mul(lastPeakPos))
                this._lastRayCastVector = {
                    from: newRayOrigin,
                    to: newRayOrigin.add(rayDir.mul(rayLength)),
                }
                const angles = new Float32Array(correlationData.length).fill(Math.PI / 2)
                const certainties = correlationData
                this.canvasToolbox.vectorFieldItem.resetAngleData()
                this.canvasToolbox.vectorFieldItem.addAngleData(
                    correlation.descriptor.width,
                    correlation.descriptor.height,
                    angles,
                    certainties,
                    10,
                    new paper.Color(1, 1, 1),
                    1,
                    sourceStart,
                )
            })
        }

        let resultImage = copyRegion(cmdQueue, {
            sourceImage: input,
        })
        resultImage = copyRegion(cmdQueue, {
            sourceImage: templateImage,
            targetOffset: {x: 0, y: 0},
            resultImageOrDataType: resultImage,
        })
        resultImage = copyRegion(cmdQueue, {
            sourceImage: sourceImage,
            targetOffset: {x: 0, y: templateHeight},
            resultImageOrDataType: resultImage,
        })
        return {resultImage}
    }

    private paramViewMode: ParameterEnum = {
        type: "enum",
        name: "Show V Edges",
        options: ["Source", "EdgesV", "EdgesH"],
        value: "Source",
    }
    private paramLevel: ParameterNumber = {
        type: "number",
        name: "Level",
        min: 0,
        max: 10,
        exponent: 1,
        value: 0,
    }
    private paramLengthScale: ParameterNumber = {
        type: "number",
        name: "Level",
        min: 0,
        max: 100,
        exponent: 2,
        value: 1,
    }

    private vectorGroup?: paper.Group

    private async testFlowField(cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        if (this.parameters.length === 0) {
            this.parameters.push(this.paramLevel)
            this.parameters.push(this.paramViewMode)
            this.parameters.push(this.paramLengthScale)
        }
        const gaussianPyramid = createGaussianPyramid(cmdQueue, {
            sourceImage: input,
            sigma: 0,
            maxLevels: 10,
        })

        const makeVectorField = (sourceImage: ImageRef, color: paper.Color, scale: number) => {
            const getMaxChannelValue = (image: ImageRef) => {
                if (image.descriptor.channelLayout !== "R") {
                    const r = extractChannel(cmdQueue, {
                        sourceImage: image,
                        channel: "R",
                    })
                    const g = extractChannel(cmdQueue, {
                        sourceImage: image,
                        channel: "G",
                    })
                    const b = extractChannel(cmdQueue, {
                        sourceImage: image,
                        channel: "B",
                    })
                    const max = math(cmdQueue, {
                        operator: "max",
                        operandA: r,
                        operandB: math(cmdQueue, {
                            operator: "max",
                            operandA: g,
                            operandB: b,
                        }),
                    })
                    return max
                } else {
                    return image
                }
            }
            const edgesV = getMaxChannelValue(
                convolve(cmdQueue, {
                    sourceImage: sourceImage,
                    kernel: {
                        width: 3,
                        height: 1,
                        values: [-1, 0, 1],
                    },
                    resultImageOrDataType: "float16",
                }),
            )
            const edgesH = getMaxChannelValue(
                convolve(cmdQueue, {
                    sourceImage: sourceImage,
                    kernel: {
                        width: 1,
                        height: 3,
                        values: [1, 0, -1],
                    },
                    resultImageOrDataType: "float16",
                }),
            )

            const angle = math(cmdQueue, {
                operator: "atan2",
                operandA: edgesV,
                operandB: edgesH,
            })
            const certainty = math(cmdQueue, {
                operator: "sqrt",
                operand: math(cmdQueue, {
                    operator: "+",
                    operandA: math(cmdQueue, {
                        operator: "square",
                        operand: edgesV,
                    }),
                    operandB: math(cmdQueue, {
                        operator: "square",
                        operand: edgesH,
                    }),
                }),
            })
            if (cmdQueue instanceof ImageOpCommandQueueWebGL2) {
                cmdQueue.lambda({angle, certainty}, async ({angle, certainty}) => {
                    const angleImage = await cmdQueue.context.getImage(angle)
                    const angleData = await angleImage.ref.halImage.readRawImageData("float32")
                    const certaintyImage = await cmdQueue.context.getImage(certainty)
                    const certaintyData = await certaintyImage.ref.halImage.readRawImageData("float32")
                    this.canvasToolbox.vectorFieldItem.addAngleData(
                        angle.descriptor.width,
                        angle.descriptor.height,
                        angleData,
                        certaintyData,
                        this.paramLengthScale.value,
                        color,
                        scale,
                    )
                })
            }
        }

        this.canvasToolbox.vectorFieldItem.resetAngleData()
        const baseLevel = Math.round(this.paramLevel.value)
        const sourceImage0 = gaussianPyramid.resultImages[baseLevel + 0]
        const sourceImage1 = gaussianPyramid.resultImages[baseLevel + 1]
        const sourceImage2 = gaussianPyramid.resultImages[baseLevel + 2]
        makeVectorField(sourceImage0, new paper.Color(0, 1, 0), 1 << (baseLevel + 0))
        makeVectorField(sourceImage1, new paper.Color(1, 0, 0), 1 << (baseLevel + 1))
        makeVectorField(sourceImage2, new paper.Color(0, 0, 1), 1 << (baseLevel + 2))

        const resultImage = input
        // let resultImage: ImageRef
        // switch (this.paramViewMode.value) {
        //     case "Source":
        //         resultImage = sourceImage
        //         break
        //     case "EdgesV":
        //         resultImage = edgesV
        //         break
        //     case "EdgesH":
        //         resultImage = edgesH
        //         break
        //     default:
        //         throw new Error("Invalid view mode")
        // }
        return {resultImage}
    }

    private paramDownSamplingFactor: ParameterNumber = {
        type: "number",
        name: "DownSamplingFactor",
        min: 1,
        max: 1000,
        exponent: 4,
        value: 1,
    }

    private async testResize(cmdQueue: ImageOpCommandQueue, sourceImage: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        if (this.parameters.length === 0) {
            this.parameters.push(this.paramDownSamplingFactor)
        }
        const downSamplingFactor = this.paramDownSamplingFactor.value
        // const interpolation = "nearest"
        const interpolation = "cubic"
        const downSampledSourceImage = resize(cmdQueue, {
            sourceImage,
            interpolation,
            resultSize: {
                width: Math.round(sourceImage.descriptor.width / downSamplingFactor),
                height: Math.round(sourceImage.descriptor.height / downSamplingFactor),
            },
        })
        const resampledSource = resize(cmdQueue, {
            sourceImage: downSampledSourceImage,
            interpolation,
            resultSize: {
                width: sourceImage.descriptor.width,
                height: sourceImage.descriptor.height,
            },
        })
        return {resultImage: resampledSource}
    }

    private async testReduce(cmdQueue: ImageOpCommandQueue, _input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        const source = createImage(cmdQueue, {
            imageOrDescriptor: {
                width: 512,
                height: 512,
                dataType: "float32",
                channelLayout: "R",
            },
            fillColor: {r: 1},
        })
        const reshapedSource = reshape(
            source,
            {
                patchSize: {
                    width: 25,
                    height: 25,
                },
            },
            {
                allowPatchFill: true,
            },
        )
        const reducedSource = reduce(cmdQueue, {
            sourceImage: reshapedSource,
            operator: "mean",
        })
        if (cmdQueue instanceof ImageOpCommandQueueWebGL2) {
            cmdQueue.lambda({originalSourceImageRef: reshapedSource, reducedImageRef: reducedSource}, async ({originalSourceImageRef, reducedImageRef}) => {
                const originalSourceImage = await cmdQueue.context.getImage(originalSourceImageRef)
                const originalSourceImageData = await originalSourceImage.ref.halImage.readRawImageData("float32")
                const reducedImage = await cmdQueue.context.getImage(reducedImageRef)
                const reducedImageData = await reducedImage.ref.halImage.readRawImageData("float32")
                const patchSize = Vector2.fromSize2Like(originalSourceImageRef.descriptor.batching!.patchSize)
                let maxError = 0
                const maxErrorPos = new Vector2()
                for (let y = 0; y < reducedImage.ref.descriptor.height; y++) {
                    for (let x = 0; x < reducedImage.ref.descriptor.width; x++) {
                        const reducedPixel = reducedImageData[y * reducedImage.ref.descriptor.width + x]
                        let sum = 0
                        let count = 0
                        for (let dy = 0; dy < patchSize.y; dy++) {
                            for (let dx = 0; dx < patchSize.x; dx++) {
                                const sourceX = x * patchSize.x + dx
                                const sourceY = y * patchSize.y + dy
                                const isValidPixel = sourceX < originalSourceImage.ref.descriptor.width && sourceY < originalSourceImage.ref.descriptor.height
                                if (isValidPixel) {
                                    const sourcePixel = originalSourceImageData[sourceY * originalSourceImage.ref.descriptor.width + sourceX]
                                    sum += sourcePixel
                                    count++
                                }
                            }
                        }
                        const avg = sum / count
                        const delta = avg - reducedPixel
                        const error = Math.abs(delta)
                        if (error > maxError) {
                            maxError = error
                            maxErrorPos.set(x, y)
                        }
                    }
                }
                console.warn(`Downsampling error: ${maxError} at (${maxErrorPos.x}, ${maxErrorPos.y})`)
                originalSourceImage.release()
                reducedImage.release()
            })
        }
        return {resultImage: reducedSource}
    }

    private async testView(cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        const view = createView(input, {
            x: 50,
            y: 50,
            width: 100,
            height: 100,
        })
        const inputCopy = copyRegion(cmdQueue, {
            sourceImage: input,
        })
        const resultImage = copyRegion(cmdQueue, {
            sourceImage: view,
            targetOffset: {
                x: 150,
                y: 10,
            },
            resultImageOrDataType: inputCopy,
        })
        return {resultImage}
    }

    private async testGaussianPyramid(cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        const directResizeHeight = 32
        const downScaledImage = resize(cmdQueue, {
            sourceImage: input,
            interpolation: "cubic",
            resultSize: {
                width: Math.round((input.descriptor.width / input.descriptor.height) * directResizeHeight),
                height: directResizeHeight,
            },
        })
        const upScaledImage = resize(cmdQueue, {
            sourceImage: downScaledImage,
            interpolation: "cubic",
            resultSize: {
                width: input.descriptor.width,
                height: input.descriptor.height,
            },
        })
        const gaussianPyramid = createGaussianPyramid(cmdQueue, {
            sourceImage: input,
            sigma: 1,
        })
        const resultSize = new Size2()
        for (const image of gaussianPyramid.resultImages) {
            resultSize.width = Math.max(resultSize.width, image.descriptor.width)
            resultSize.height += image.descriptor.height
        }
        resultSize.width = Math.max(resultSize.width, downScaledImage.descriptor.width)
        resultSize.height += downScaledImage.descriptor.height
        resultSize.width = Math.max(resultSize.width, upScaledImage.descriptor.width)
        resultSize.height += upScaledImage.descriptor.height
        resultSize.height = 2048
        let resultImage = createImage(cmdQueue, {
            imageOrDescriptor: {
                ...input.descriptor,
                width: resultSize.width,
                height: resultSize.height,
            },
        })
        let y = 0
        for (const image of gaussianPyramid.resultImages) {
            resultImage = copyRegion(cmdQueue, {
                sourceImage: image,
                targetOffset: {x: 0, y},
                resultImageOrDataType: resultImage,
            })
            y += image.descriptor.height
        }
        const upsampleBaseIndex = gaussianPyramid.descriptor.levels - 4
        let lowRes = gaussianPyramid.resultImages[upsampleBaseIndex]
        for (let i = upsampleBaseIndex; i >= 1; i--) {
            const highRes = gaussianPyramid.resultImages[i - 1]
            const isOddX = (highRes.descriptor.width & 1) === 1
            const isOddY = (highRes.descriptor.height & 1) === 1
            let resizedLowRes = resize(cmdQueue, {
                sourceImage: lowRes,
                interpolation: "cubic",
                resultSize: {
                    width: highRes.descriptor.width + (isOddX ? 1 : 0),
                    height: highRes.descriptor.height + (isOddY ? 1 : 0),
                },
            })
            if (isOddX || isOddY) {
                resizedLowRes = copyRegion(cmdQueue, {
                    sourceImage: resizedLowRes,
                    sourceRegion: {
                        x: 0,
                        y: 0,
                        width: highRes.descriptor.width,
                        height: highRes.descriptor.height,
                    },
                })
            }
            lowRes = resizedLowRes
            resultImage = copyRegion(cmdQueue, {
                sourceImage: resizedLowRes,
                targetOffset: {x: 0, y},
                resultImageOrDataType: resultImage,
            })
            y += resizedLowRes.descriptor.height
        }

        resultImage = copyRegion(cmdQueue, {
            sourceImage: downScaledImage,
            targetOffset: {x: 0, y},
            resultImageOrDataType: resultImage,
        })
        y += downScaledImage.descriptor.height

        resultImage = copyRegion(cmdQueue, {
            sourceImage: upScaledImage,
            targetOffset: {x: 0, y},
            resultImageOrDataType: resultImage,
        })

        return {resultImage}
    }
}
