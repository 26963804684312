import {decompressMesh, WebAssemblyWorkerService} from "@app/editor/helpers/mesh-processing/mesh-processing"
import {MeshData, MeshDataGraph, MeshLoadSettings} from "@cm/template-nodes"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {Observable, of, switchMap} from "rxjs"

export class MeshDataCache {
    private cache: AsyncCacheMap<string, MeshData, [Uint8Array, number, MeshLoadSettings]>

    constructor(private workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, [data, plyId, settings]) => {
            return of(data).pipe(
                switchMap((buffer) => {
                    let graph: MeshDataGraph = {
                        type: "loadMesh",
                        data: {
                            type: "dataObjectReference",
                            dataObjectId: plyId,
                        },
                    }
                    // only add subdivision node if we're actually subdividing
                    if (settings.renderSubdivisionLevel > 0) {
                        graph = {
                            type: "subdivide",
                            levels: settings.renderSubdivisionLevel,
                            input: graph,
                        }
                    }
                    return decompressMesh(workerService, graph, buffer.buffer.slice(0) as ArrayBuffer, settings.displaySubdivisionLevel)
                }),
            )
        })
    }

    getMeshData(data: Uint8Array, plyDataObjectId: number, settings: MeshLoadSettings): Observable<MeshData> {
        const key = `${plyDataObjectId},${settings.displaySubdivisionLevel},${settings.renderSubdivisionLevel}`
        return this.cache.get(key, [data, plyDataObjectId, settings])
    }

    isCached(plyDataObjectId: number, settings: MeshLoadSettings) {
        const key = `${plyDataObjectId},${settings.displaySubdivisionLevel},${settings.renderSubdivisionLevel}`
        return this.cache.cached.has(key)
    }

    invalidate() {
        this.cache.clear()
    }
}
