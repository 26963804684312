<cm-list-item
    [disabled]="disabled()"
    [selected]="selected()"
    [highlighted]="isHighlighted()"
    (click)="onClickedNode($event)"
    [expandable]="expandable()"
    [expanded]="expandable() ? templateTree().treeControl.isExpanded(treeNode()) : false"
    (arrowToggle)="templateTree().treeControl.toggle(treeNode())"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (dblclick)="renameNode()"
    [draggable]="true"
    (dragstart)="dragStart($event)"
    (dragover)="dragOver($event)"
    (dragleave)="dragLeave($event)"
    (drop)="drag.drop($event)"
    (dragend)="drag.dragEnd($event)"
    [class.cm-drop-before]="isDropTarget('before')"
    [class.cm-drop-after]="isDropTarget('after')"
    [class.cm-drop-inside]="isDropTarget('inside')"
>
    <ng-container cm-pre-title>
        <div class="cm-title-icons-container">
            <i class="cm-node-icon" [class]="nodeIconClass()" [class.cm-icon-disabled]="disabled()" [matTooltip]="class()"></i>
        </div>
    </ng-container>
    <ng-container cm-title>
        <span class="cm-list-item-title-text cm-offset-icons" [class.cm-value-node]="isNodeValue()">
            <span [matTooltip]="label()">{{ label() }}</span>
            @if (secondaryNodeIconClass(); as secondaryIconClass) {
                <i class="cm-node-icon" [class]="secondaryIconClass" [matTooltip]="class()"></i>
            }
        </span>
    </ng-container>
    <ng-container cm-icons>
        @if (nodeOwnerNode() || switchNode()) {
            <i class="far fa-minus-square" matTooltip="Collapse All" (click)="collapseAll()"></i>
        }
        @if (parentSwitchNode()) {
            <i class="far fa-external-link" matTooltip="Node is a reference" (click)="gotoReference()"></i>
        }
        @if (storedMeshNode(); as mesh) {
            <i [matMenuTriggerFor]="meshDownloadMenu" (click)="$event.stopPropagation()" matTooltip="Download" class="far fa-arrow-to-bottom cm-action-icons">
                <mat-menu #meshDownloadMenu>
                    <cm-template-menu>
                        <cm-template-menu-section>
                            <ng-container cm-section-title>
                                <i class="far fa-arrow-to-bottom"></i>
                                Download
                            </ng-container>
                            <ng-container cm-section>
                                <cm-list-item (click)="files.downloadDataObjectByLegacyId(mesh.parameters.drcDataObject.parameters.dataObjectId)">
                                    <ng-container cm-pre-title><i class="far fa-arrow-to-bottom"></i></ng-container>
                                    <ng-container cm-title>DRC</ng-container>
                                </cm-list-item>
                                <cm-list-item (click)="files.downloadDataObjectByLegacyId(mesh.parameters.plyDataObject.parameters.dataObjectId)">
                                    <ng-container cm-pre-title><i class="far fa-arrow-to-bottom"></i></ng-container>
                                    <ng-container cm-title>PLY</ng-container>
                                </cm-list-item>
                            </ng-container>
                        </cm-template-menu-section>
                    </cm-template-menu>
                </mat-menu>
            </i>
        }
        @if (dataObjectReferenceNode(); as dataObjectReference) {
            <i
                (click)="$event.stopPropagation(); files.downloadDataObjectByLegacyId(dataObjectReference.parameters.dataObjectId)"
                matTooltip="Download"
                class="far fa-arrow-to-bottom cm-action-icons"
            ></i>
        }
        @if (objectNode(); as object) {
            <cm-template-tree-object-transform [object]="object" [processingNodes]="objectProcessingNodes()"></cm-template-tree-object-transform>
            <i
                [class]="object.parameters.visible ? 'far fa-eye' : 'far fa-eye-slash'"
                [matTooltip]="object.parameters.visible ? 'Visible' : 'Hidden'"
                (click)="$event.stopPropagation(); setVisibility(!object.parameters.visible)"
            >
            </i>
        }
        @if (templateReferenceNodeLink(); as templateReferenceNodeLink) {
            <a
                class="far fa-external-link"
                href="{{ templateReferenceNodeLink.join('/') }}"
                (click)="$event.preventDefault(); $event.stopPropagation(); openTemplateReference()"
                matTooltip="Go to template revision"
            >
            </a>
        }
        @if (materialReferenceNodeLink(); as materialReferenceNodeLink) {
            <a
                class="far fa-external-link"
                href="{{ materialReferenceNodeLink.join('/') }}"
                (click)="$event.preventDefault(); $event.stopPropagation(); openMaterialReference()"
                matTooltip="Go to material revision"
            >
            </a>
        }
        <i [matMenuTriggerFor]="nodeMenu" (click)="$event.stopPropagation()" matTooltip="Edit" class="far fa-pen cm-action-icons">
            <mat-menu #nodeMenu>
                <cm-template-menu>
                    <cm-template-menu-section>
                        <ng-container cm-section-title>
                            <i class="far fa-pen"></i>
                            Edit
                        </ng-container>
                        <ng-container cm-section>
                            @if (namedNode()) {
                                <cm-list-item (click)="renameNode()" [disabled]="node.name === undefined">
                                    <ng-container cm-pre-title><i class="far fa-pen"></i></ng-container>
                                    <ng-container cm-title>Rename</ng-container>
                                </cm-list-item>
                            }
                            <cm-list-item (click)="copySelection()">
                                <ng-container cm-pre-title><i class="far fa-copy"></i></ng-container>
                                <ng-container cm-title>Copy</ng-container>
                            </cm-list-item>
                            @if (nodeOwnerNode()) {
                                <cm-list-item (click)="pasteCopies()" [disabled]="!clipboardService.valid()">
                                    <ng-container cm-pre-title><i class="far fa-paste"></i></ng-container>
                                    <ng-container cm-title>Paste</ng-container>
                                </cm-list-item>
                            }
                            @if (switchNode(); as switchNode) {
                                <cm-list-item (click)="pasteReferences()" [disabled]="!clipboardService.valid()">
                                    <ng-container cm-pre-title><i class="far fa-paste"></i></ng-container>
                                    <ng-container cm-title>{{ "Paste " + getSwitchItemLabel(switchNode) + "s" }}</ng-container>
                                </cm-list-item>
                            }
                            @if (!isRootNode()) {
                                @if (!parentSwitchNode()) {
                                    <cm-list-item (click)="duplicateNode()">
                                        <ng-container cm-pre-title><i class="far fa-clone"></i></ng-container>
                                        <ng-container cm-title>Duplicate</ng-container>
                                    </cm-list-item>
                                }
                                @if (templateLikeNode()) {
                                    <cm-template-menu-item [nodeClass]="TemplateInstance.getNodeClass()" [label]="'Create Instance'" (click)="createInstance()">
                                    </cm-template-menu-item>
                                }
                                @if (templateGraphNode()) {
                                    <cm-list-item (click)="promoteToLibraryTemplate()">
                                        <ng-container cm-pre-title><i class="far fa-folder-plus"></i></ng-container>
                                        <ng-container cm-title>Promote to library</ng-container>
                                    </cm-list-item>
                                }
                                <cm-list-item (click)="moveSelectionToNewGroup()">
                                    <ng-container cm-pre-title><i class="far fa-object-group"></i></ng-container>
                                    <ng-container cm-title>Group</ng-container>
                                </cm-list-item>
                                @if (groupNode()) {
                                    <cm-list-item (click)="dissolveGroup()">
                                        <ng-container cm-pre-title><i class="far fa-object-ungroup"></i></ng-container>
                                        <ng-container cm-title>Dissolve group</ng-container>
                                    </cm-list-item>
                                }
                                @if (parentSwitchNode()) {
                                    <cm-list-item (click)="deleteReference()">
                                        <ng-container cm-pre-title><i class="far fa-trash"></i></ng-container>
                                        <ng-container cm-title>Remove</ng-container>
                                    </cm-list-item>
                                } @else {
                                    <cm-list-item class="cm-remove-icon" (click)="deleteNode()">
                                        <ng-container cm-pre-title><i class="far fa-trash"></i></ng-container>
                                        <ng-container cm-title>Delete</ng-container>
                                    </cm-list-item>
                                }
                                @if (meshNode()) {
                                    <cm-template-menu-item [nodeClass]="MeshDecal.getNodeClass()" [label]="'Add Decal'" (click)="addDecal()">
                                    </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="MeshCurve.getNodeClass()" [label]="'Add Curve'" (click)="addCurve()">
                                    </cm-template-menu-item>
                                }
                                @if (meshCurveNode()) {
                                    <cm-template-menu-item [nodeClass]="Seam.getNodeClass()" [label]="'Add Seam'" (click)="addSeam()"> </cm-template-menu-item>
                                }
                                @if (imageLikeNode()) {
                                    <cm-template-menu-item
                                        [nodeClass]="ImageOperator.getNodeClass()"
                                        [label]="'Add Image Operator'"
                                        (click)="addImageOperator()"
                                    >
                                    </cm-template-menu-item>
                                }
                            }
                        </ng-container>
                    </cm-template-menu-section>
                </cm-template-menu>
            </mat-menu>
        </i>
    </ng-container>
</cm-list-item>
<div #dragPlaceholder></div>
