import {ConfigurationGroupNode} from "#pricing/nodes/configuration-group-node"
import {GroupId, PricingContext, VariantId} from "#pricing/nodes/core"
import {VariantConditionNode} from "#pricing/nodes/variant-condition-node"
import {DeclareNodeGraphTS, registerNode} from "@cm/graph"

export type ConditionalAmountNodeParameters = {
    description: string
    amount: number
    condition: VariantConditionNode
}

@registerNode
export class ConditionalAmountNode extends DeclareNodeGraphTS<number, PricingContext, ConditionalAmountNodeParameters>(
    {
        run: async ({get, parameters, context}) => {
            if (parameters.condition.getVariantIds().length === 0) return 0 // this means that the condition was not set up yet. In contrast to the priced item node, this node is assumed to be always conditional.
            const condition = await get(parameters.condition)
            return condition ? parameters.amount : 0
        },
    },
    {
        nodeClass: "ConditionalAmountNode",
    },
) {
    addDependency(configGroupNode: ConfigurationGroupNode, variantId: VariantId) {
        this.parameters.condition.addDependency(configGroupNode, variantId)
    }

    removeDependency(variantId: VariantId) {
        this.parameters.condition.removeDependency(variantId)
    }

    canAddDependency(groupId: GroupId, variantId: VariantId) {
        return this.parameters.condition.canAddDependency(groupId, variantId)
    }

    getVariantIds(): VariantId[] {
        return this.parameters.condition.getVariantIds()
    }
}
