import {isBlobLike} from "#utils/misc"

type IdType = string | number
type ObjType = any
type ArrType = any
type ValueType = any
type JsonType = any
type KeyType = any
type FixupTargetType = ObjType | ArrType

const LOCAL_ID_KEY = "$_id"
const REFERENCE_ID_KEY = "$id"
const FALLBACK_ID_KEY = "id"

function traverseGraphToJson(value: ValueType, objectMap: Map<ObjType, ObjType>, visitedIdSet: Set<IdType>, makeLocalId: () => number): ValueType {
    if (typeof value === "object") {
        if (value === null) {
            return null
        } else if (Array.isArray(value)) {
            const jsonValue: JsonType[] = []
            for (const elem of value) {
                jsonValue.push(traverseGraphToJson(elem, objectMap, visitedIdSet, makeLocalId))
            }
            return jsonValue
        } else if (isBlobLike(value)) {
            //TODO: flag to disallow binary blobs
            return value
        } else {
            const existing = objectMap.get(value)
            if (existing) {
                let id = existing[LOCAL_ID_KEY]
                if (id === undefined) {
                    // assign local unique ID
                    existing[LOCAL_ID_KEY] = id = makeLocalId()
                } else {
                    const idType = typeof id
                    if (!(idType === "string" || idType === "number")) {
                        throw new Error(
                            `Object was referenced more than once while serializing, but it does not have an 'id' property with a string or number value: ${value}`,
                        )
                    }
                }
                return {[REFERENCE_ID_KEY]: id}
            } else {
                const id = value[LOCAL_ID_KEY]
                if (id !== undefined) {
                    const idType = typeof id
                    if (!(idType === "string" || idType === "number")) {
                        throw new Error(`Object with id found while serializing, but the id is not a string or number value: ${value}`)
                    } else if (visitedIdSet.has(id)) {
                        // throw new Error(`Object id ${id} appeared in more than one object while serializing`);
                        console.error(`Object id ${id} appeared in more than one object while serializing:`, value)
                        // allow this for now, but replace with reference...
                        return {[REFERENCE_ID_KEY]: id}
                    } else {
                        visitedIdSet.add(id)
                    }
                }
                const jsonValue: JsonType = {}
                objectMap.set(value, jsonValue)
                for (const key in value) {
                    if (!key.startsWith("$")) {
                        jsonValue[key] = traverseGraphToJson(value[key], objectMap, visitedIdSet, makeLocalId)
                    }
                }
                return jsonValue
            }
        }
    } else {
        return value
    }
}

export function graphToJson(root: ValueType): JsonType {
    const objectMap = new Map<ObjType, ObjType>()
    const visitedIdSet = new Set<IdType>()
    let localIdCounter = 0
    const makeLocalId = () => {
        return ++localIdCounter
    }
    return traverseGraphToJson(root, objectMap, visitedIdSet, makeLocalId)
}

function traversePrepareResolve(
    value: ValueType,
    valueParent: FixupTargetType,
    keyInParent: KeyType,
    objectMap: Map<IdType, ObjType>,
    fixupList: [FixupTargetType, KeyType, IdType][],
): ValueType {
    if (typeof value === "object") {
        if (value === null) {
            return null
        } else if (Array.isArray(value)) {
            const newValue: ValueType[] = []
            for (let idx = 0; idx < value.length; idx++) {
                newValue.push(traversePrepareResolve(value[idx], newValue, idx, objectMap, fixupList))
            }
            return newValue
        } else if (isBlobLike(value)) {
            //TODO: flag to disallow binary blobs
            return value
        } else {
            const refId = value[REFERENCE_ID_KEY]
            if (refId !== undefined) {
                const refIdType = typeof refId
                if (!(refIdType === "string" || refIdType === "number")) {
                    throw new Error(`Object with reference ${REFERENCE_ID_KEY} found while deserializing, but the id is not a string or number value: ${value}`)
                }
                if (valueParent === undefined) {
                    throw new Error(`Root value cannot be a reference!`)
                }
                fixupList.push([valueParent, keyInParent, refId])
                return undefined
            } else {
                const id = value[LOCAL_ID_KEY] ?? value[FALLBACK_ID_KEY]
                if (id !== undefined) {
                    const idType = typeof id
                    if (!(idType === "string" || idType === "number")) {
                        throw new Error(`Object with id found while deserializing, but the id is not a string or number value: ${value}`)
                    }
                    if (objectMap.has(id)) {
                        // throw new Error(`Object id ${id} appeared in more than one object while deserializing`);
                        console.error(`Object id ${id} appeared in more than one object while deserializing:`, value)
                        // allow this for now...
                    }
                }
                const newValue: ObjType = {}
                for (const key in value) {
                    if (!key.startsWith("$")) {
                        newValue[key] = traversePrepareResolve(value[key], newValue, key, objectMap, fixupList)
                    }
                }
                if (id !== undefined) {
                    objectMap.set(id, newValue)
                }
                return newValue
            }
        }
    } else {
        return value // not an object, no need to clone
    }
}

function applyFixupList(objectMap: Map<IdType, ObjType>, fixupList: [FixupTargetType, KeyType, IdType][]): void {
    for (const [target, key, id] of fixupList) {
        const obj = objectMap.get(id)
        if (obj === undefined) {
            throw new Error(`Unresolved reference to object with id '${id}'`)
        }
        target[key] = obj
    }
}

export function jsonToGraph(root: JsonType): ValueType {
    const objectMap = new Map<IdType, ObjType>()
    const fixupList: [FixupTargetType, KeyType, IdType][] = []
    const resolvedRoot = traversePrepareResolve(root, undefined, undefined, objectMap, fixupList) // this will do a deep copy of the structure, which will be modified by applyFixupList
    applyFixupList(objectMap, fixupList)
    return resolvedRoot
}

// function testGraphSerialization() {
//     const inputJson = {
//         [LOCAL_ID_KEY]: "rootId",
//         foo: 1234,
//         bar: null as any,
//         array: [1,2,3,{[LOCAL_ID_KEY]: "nestedInArray", value: 4, test: [1,2,3,4]}],
//         outer: {[REFERENCE_ID_KEY]: "nestedInArray"},
//         obj2: {
//             myObj: {[LOCAL_ID_KEY]: "nestedInObj2", stuff: "asdf", stuff2: {}},
//             aNull: null as any,
//             aUndef: undefined as any,
//             //circular: {[REFERENCE_ID_KEY]: "rootId"}
//         },
//         obj: {
//             x: 1,
//             y: 2,
//             z: {[REFERENCE_ID_KEY]: "nestedInObj2"}
//         }
//     };
//     const outputGraph = jsonToGraph(inputJson);
//     console.log("inputJson", JSON.stringify(inputJson, null, 2));
//     console.log("");
//     console.log("outputGraph", outputGraph); //JSON.stringify(outputGraph, null, 2));

//     console.log("");
//     console.log("*******");
//     console.log("");

//     const inputObj3 = {
//         name: "Object 3",
//         values: [1,2,3,4],
//         circular: null as any
//     };
//     const inputObj2 = {
//         stuff: "asdf",
//         deep: inputObj3
//     };
//     inputObj3.circular = inputObj2;
//     const inputObj1 = {
//         value: "asdf",
//         inside1: inputObj2,
//         io1arr: [inputObj2,{},inputObj2]
//     };
//     const inputGraph = {
//         arr: [inputObj1],
//         obj3: inputObj3,
//         aString: "foo",
//         aNull: null as any,
//         aUndef: undefined as any
//     };
//     const outputJson = JSON.stringify(graphToJson(inputGraph), null, 2);
//     console.log("inputGraph", inputGraph); //JSON.stringify(inputGraph, null, 2));
//     console.log("");
//     console.log("outputJson", outputJson);
//     console.log("");
//     console.log("reconGraph", jsonToGraph(JSON.parse(outputJson)));
// }
// testGraphSerialization();
