import {NgModule} from "@angular/core"
import {RouterModule, Routes} from "@angular/router"
import {PriceMappingComponent} from "@app/pricing/components/price-mapping/price-mapping.component"
import {TemplateDetailsComponent} from "@app/templates/template-details/template-details.component"
import {TemplateViewerDialogComponent} from "@common/components/dialogs/template-viewer-dialog/template-viewer-dialog.component"
import {AuthGuard, BackendAuthGuard, StaffGuard, PicturesAuthGuard} from "@common/services/guards/guards.service"
import {MaterialEditorComponent} from "@material-editor/components/material-editor/material-editor.component"
import {JobFarmInstancesPageComponent} from "@pages/job-farm-instances/job-farm-instances-page.component"
import {AssetDetailsComponent} from "@platform/components/assets/asset-details/asset-details.component"
import {FileDetailsComponent} from "@platform/components/files/file-details/file-details.component"
import {HdriDetailsComponent} from "@platform/components/hdris/hdri-details/hdri-details.component"
import {JobDetailsComponent} from "@platform/components/jobs/job-details/job-details.component"
import {JobTaskDetailsComponent} from "@platform/components/jobs/job-task-details/job-task-details.component"
import {MaterialDetailsComponent} from "@platform/components/materials/material-details/material-details.component"
import {MaterialExplorerComponent} from "@platform/components/materials/material-explorer/material-explorer.component"
import {ModelDetailsComponent} from "@platform/components/models/model-details/model-details.component"
import {OrganizationDetailsComponent} from "@platform/components/organizations/organization-details/organization-details.component"
import {PictureDetailsComponent} from "@platform/components/pictures/picture-details/picture-details.component"
import {PictureViewerDialogComponent} from "@platform/components/pictures/picture-viewer-dialog/picture-viewer-dialog.component"
import {PicturesGridComponent} from "@platform/components/pictures/pictures-grid/pictures-grid.component"
import {RenderJobViewerDialogComponent} from "@platform/components/pictures/render-job-viewer-dialog/render-job-viewer-dialog.component"
import {ScanningLogsComponent} from "@platform/components/scanning/scanning-logs/scanning-logs.component"
import {ScanningComponent} from "@platform/components/scanning/scanning/scanning.component"
import {TagDetailsComponent} from "@platform/components/tags/tag-details/tag-details.component"
import {TaskTypesTableComponent} from "@platform/components/task-types/task-types-table/task-types-table.component"
import {TaskDetailsComponent} from "@platform/components/tasks/task-details/task-details.component"
import {UserDetailsComponent} from "@platform/components/users/user-details/user-details.component"
import {AssetsPageComponent} from "@platform/pages/assets/assets-page.component"
import {FilesPageComponent} from "@platform/pages/files/files-page.component"
import {HdrisPageComponent} from "@platform/pages/hdris/hdris-page.component"
import {JobFarmGroupsPageComponent} from "@platform/pages/job-farm-groups/job-farm-groups-page.component"
import {JobsPageComponent} from "@platform/pages/jobs/jobs-page.component"
import {LoginPageComponent} from "@platform/pages/login/login-page.component"
import {MaterialsPageComponent} from "@platform/pages/materials/materials-page.component"
import {ModelsPageComponent} from "@platform/pages/models/models-page.component"
import {OrganizationsPageComponent} from "@platform/pages/organizations/organizations-page.component"
import {PageNotFoundComponent} from "@platform/pages/page-not-found/page-not-found.component"
import {StatisticsPageComponent} from "@platform/pages/statistics/statistics-page.component"
import {TagsPageComponent} from "@platform/pages/tags/tags-page.component"
import {UsersPageComponent} from "@platform/pages/users/users-page.component"
import {LegacyPictureResolve, LegacyPictureTemplateResolve, LegacyTemplateResolve} from "@platform/services/resolve/resolve.service"
import {TemplateEditorComponent} from "app/template-editor/components/template-editor/template-editor.component"
import {TemplatePublisherComponent} from "app/templates/template-publisher/template-publisher.component"
import {TemplatesComponent} from "app/templates/templates.component"
import {TextureEditorComponent} from "app/textures/texture-editor/texture-editor.component"
import {UnauthorizedComponent} from "@pages/unauthorized/unauthorized.component"
import {BackendArGenerationComponent} from "@app/platform/pages/ar-generation/backend-ar-generation.component"
import {TestBaseComponent} from "@app/test/manual-testing/test-base/test-base.component"
import {ConfiguratorTestComponentsComponent} from "@app/test/manual-testing/configurator-test-components/configurator-test-components.component"

export const routes: Routes = [
    {path: "", redirectTo: "/pictures", pathMatch: "full"},

    {
        path: "assets",
        title: "Assets - colormass",
        component: AssetsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":itemId",
                component: AssetDetailsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: "tasks/:taskId",
                        component: TaskDetailsComponent,
                        canActivate: [AuthGuard],
                        data: {closeNavigationPath: "../../"},
                    },
                    {
                        path: "template/:templateId",
                        component: TemplateViewerDialogComponent,
                        data: {closeNavigationPath: "../../"},
                        canActivate: [AuthGuard],
                    },
                ],
            },
        ],
    },

    {
        path: "backend-ar-generation",
        component: BackendArGenerationComponent,
        canActivate: [BackendAuthGuard],
    },

    {
        path: "configurator-components-testing",
        component: ConfiguratorTestComponentsComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "manual-testing",
        component: TestBaseComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "files",
        title: "Files - colormass",
        component: FilesPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":itemId",
                component: FileDetailsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },

    {
        path: "hdris",
        title: "HDRIs - colormass",
        component: HdrisPageComponent,
        canActivate: [StaffGuard],
        children: [
            {
                path: ":itemId",
                component: HdriDetailsComponent,
                canActivate: [StaffGuard],
            },
        ],
    },

    {
        path: "jobs",
        title: "Jobs - colormass",
        component: JobsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":itemId",
                component: JobDetailsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: ":jobTaskId",
                        component: JobTaskDetailsComponent,
                        canActivate: [AuthGuard],
                    },
                ],
            },
        ],
    },

    {
        path: "login",
        title: "Login - colormass",
        component: LoginPageComponent,
    },

    {
        path: "materials",
        title: "Materials - colormass",
        component: MaterialsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "explorer",
                component: MaterialExplorerComponent,
                canActivate: [AuthGuard],
            },
            {
                path: ":itemId",
                component: MaterialDetailsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: "edit/:revisionId",
                        component: MaterialEditorComponent,
                        data: {closeNavigationPath: "../../"},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: "setup/:textureSetId",
                        component: MaterialEditorComponent,
                        data: {closeNavigationPath: "../../"},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: "preview/:keyForKeyValueStore",
                        component: MaterialEditorComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: "tasks/:taskId",
                        component: TaskDetailsComponent,
                        canActivate: [AuthGuard],
                        data: {closeNavigationPath: "../../"},
                    },
                    {
                        path: "set-revisions/:textureSetRevisionId",
                        component: TextureEditorComponent,
                        data: {closeNavigationPath: "../../", closeQueryParamOverride: {textureType: null}},
                        canActivate: [AuthGuard],
                    },
                ],
            },
        ],
    },

    {
        path: "models",
        title: "Models - colormass",
        component: ModelsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":itemId",
                component: ModelDetailsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: "tasks/:taskId",
                        component: TaskDetailsComponent,
                        canActivate: [AuthGuard],
                        data: {closeNavigationPath: "../../"},
                    },
                ],
            },
        ],
    },

    {
        path: "organizations",
        title: "Organizations - colormass",
        component: OrganizationsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":itemId",
                component: OrganizationDetailsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },

    {
        path: "pictures",
        title: "Pictures - colormass",
        component: PicturesGridComponent,
        canActivate: [PicturesAuthGuard],
        children: [
            {
                path: ":itemId",
                redirectTo: ":itemId/revisions/latest",
                pathMatch: "full",
            },
            {
                path: ":itemId/revisions",
                redirectTo: ":itemId/revisions/latest",
                pathMatch: "full",
            },
            {
                path: ":itemId/revisions/new",
                component: PictureDetailsComponent,
                data: {closeNavigationPath: "../../../"},
                canActivate: [AuthGuard],
                resolve: {legacyPicture: LegacyPictureResolve},
                children: [
                    {
                        path: "tasks/:taskId",
                        component: TaskDetailsComponent,
                        canActivate: [AuthGuard],
                        data: {closeNavigationPath: "../../"},
                    },
                ],
            },
            {
                path: ":itemId/revisions/:revisionNumber",
                component: PictureDetailsComponent,
                canActivate: [AuthGuard],
                data: {closeNavigationPath: "../../../"},
                resolve: {legacyPicture: LegacyPictureResolve},
                children: [
                    {
                        path: "view",
                        data: {closeNavigationPath: "../"},
                        component: PictureViewerDialogComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: "tasks/:taskId",
                        component: TaskDetailsComponent,
                        canActivate: [AuthGuard],
                        data: {closeNavigationPath: "../../"},
                    },
                ],
            },
            {
                path: ":pictureId/template-revisions/add",
                component: TemplateEditorComponent,
                data: {closeNavigationPath: "../../../", ensureScenePropertiesNodeExists: true},
                canActivate: [AuthGuard],
            },
            {
                path: ":pictureId/template-revisions/:templateRevisionId/edit",
                component: TemplatePublisherComponent,
                resolve: {templateData: LegacyPictureTemplateResolve},
                data: {closeNavigationPath: "../../../../", ensureScenePropertiesNodeExists: true},
                canActivate: [AuthGuard],
            },
            {
                path: ":pictureId/template-revisions/:templateRevisionId/edit-new",
                component: TemplateEditorComponent,
                data: {closeNavigationPath: "../../../../"},
                canActivate: [AuthGuard],
            },
            {
                path: ":pictureId/view",
                component: TemplateViewerDialogComponent,
                canActivate: [AuthGuard],
            },
            {
                path: ":pictureId/render-jobs/:jobId",
                component: RenderJobViewerDialogComponent,
                canActivate: [AuthGuard],
            },
        ],
    },

    {
        path: "render-nodes",
        title: "Render Nodes - colormass",
        component: JobFarmInstancesPageComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "render-node-groups",
        title: "Render Node Groups - colormass",
        component: JobFarmGroupsPageComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "scanning",
        title: "Scanning - colormass",
        component: ScanningComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "logs",
                component: ScanningLogsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },

    {
        path: "statistics",
        title: "Statistics - colormass",
        component: StatisticsPageComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "tags",
        title: "Tags - colormass",
        component: TagsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":itemId",
                component: TagDetailsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },

    {
        path: "task-types",
        title: "Task Types - colormass",
        component: TaskTypesTableComponent,
        canActivate: [AuthGuard],
    },

    getTemplateRoute("templates", "Templates - colormass"),
    getTemplateRoute("scenes", "Scenes - colormass", "scenes"),
    getTemplateRoute("products", "Products - colormass", "products"),

    // TODO for now we wanna test if we really get away with removing "textures" and make those only accessable via "materials"
    // {
    //     path: "texture-groups",
    //     component: TexturesComponent,
    //     canActivate: [AuthGuard],
    //     children: [
    //         {
    //             path: ":itemId",
    //             component: TextureGroupDetailsComponent,
    //             canActivate: [AuthGuard],
    //             children: [
    //                 {
    //                     path: "set-revisions/:textureSetRevisionId",
    //                     component: TextureEditorComponent,
    //                     resolve: {textureData: TextureEditorResolve},
    //                     data: {closeNavigationPath: "../../", closeQueryParamOverride: {textureType: null}},
    //                     canActivate: [AuthGuard],
    //                 },
    //             ],
    //         },
    //     ],
    // },

    {
        path: "users",
        title: "Users - colormass",
        component: UsersPageComponent,
        canActivate: [StaffGuard],
        children: [
            {
                path: ":itemId",
                component: UserDetailsComponent,
                canActivate: [StaffGuard],
            },
        ],
    },

    {
        path: "price-mapping",
        title: "Price Mapping - colormass",
        component: PriceMappingComponent,
        canActivate: [BackendAuthGuard],
    },

    {
        path: "unauthorized",
        title: "Unauthorized - colormass",
        component: UnauthorizedComponent,
    },

    {
        path: "**",
        component: PageNotFoundComponent,
    },
]

function getTemplateRoute(path: string, pageTitle: string, viewMode?: "scenes" | "products") {
    return {
        path,
        title: pageTitle,
        component: TemplatesComponent,
        canActivate: [AuthGuard],
        data: {viewMode},
        children: [
            {path: ":itemId", component: TemplateDetailsComponent, canActivate: [AuthGuard]},
            {
                path: ":templateId/revisions/add",
                component: TemplateEditorComponent,
                data: {closeNavigationPath: "../../../"},
                canActivate: [AuthGuard],
            },
            {
                path: ":templateId/revisions/:templateRevisionId/edit",
                component: TemplatePublisherComponent,
                data: {closeNavigationPath: "../../../../"},
                resolve: {templateData: LegacyTemplateResolve},
                canActivate: [AuthGuard],
            },
            {
                path: ":templateId/revisions/:templateRevisionId/edit-new",
                component: TemplateEditorComponent,
                data: {closeNavigationPath: "../../../../"},
                canActivate: [AuthGuard],
            },
            {path: ":templateId/view", component: TemplateViewerDialogComponent, canActivate: [AuthGuard]},
        ],
    }
}

@NgModule({
    imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: "always", bindToComponentInputs: true})],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
