import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"

// maps [-1..1] -> [0..1]

const SCOPE_NAME = "MapMinusOnePlusOneToZeroOne"

export type ParameterType = {
    sourceImage: ImageRef
}

export type ReturnType = ImageRef

export function mapMinusOnePlusOneToZeroOne(cmdQueue: ImageOpCommandQueue, {sourceImage}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)

    let result = math(cmdQueue, {
        operandA: sourceImage,
        operandB: 0.5,
        operator: "*",
    })
    result = math(cmdQueue, {
        operandA: result,
        operandB: 0.5,
        operator: "+",
    })

    cmdQueue.endScope(SCOPE_NAME)

    return result
}
