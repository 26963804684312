@if (node) {
    <cm-inspector-section>
        <ng-container cm-title> Template Input ({{ node.inputType }})</ng-container>
        <ng-container cm-content>
            <cm-input-container>
                <ng-container cm-inline-label> ID:</ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.id" (valueChange)="updateNode(node)" [validate]="isValidId"></cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Name:</ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.name" (valueChange)="updateNode(node)"></cm-string-input>
                </ng-container>
            </cm-input-container>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section [$allowOverflow]="true">
        <ng-container cm-title> Default</ng-container>
        <ng-container cm-content>
            <cm-node-reference-slot [(node)]="$any(node).default" (nodeChange)="updateNode(node)"></cm-node-reference-slot>
            <div class="cm-filter-container">
                <div class="cm-filter-title" matTooltip="Add tags to define a range of inputs">Filter:</div>
                <div class="cm-filter-search">
                    <cm-search
                        [value]="$searchText() ?? ''"
                        (focus)="focusSearch = true"
                        (blur)="focusSearch = false"
                        (valueChange)="$searchText.set($event)"
                    ></cm-search>
                    <cm-dropdown [isOpen]="focusSearch && $searchText() !== undefined && $searchTags().length > 0">
                        <ng-container cm-dropdown>
                            <div class="cm-dropdown-list">
                                @for (tag of $searchTags() | slice: 0 : 5; track tag) {
                                    <div (mousedown)="$event.preventDefault()" (click)="$searchText.set(undefined); addTag(node, tag)" class="cm-list-item">
                                        <div [style.background]="Labels.TagType.get(tag.type)?.background" class="cm-tag-label">
                                            {{ Labels.TagType.get(tag.type)?.label }}
                                        </div>
                                        <div class="cm-tag-name">
                                            {{ tag.name }}
                                        </div>
                                    </div>
                                }
                            </div>
                        </ng-container>
                    </cm-dropdown>
                </div>
            </div>
            <div class="cm-selected-tag-list">
                @for (tag of $assignedTags(); track tag) {
                    <div (click)="removeTag(node, tag)" class="cm-selected-tag">
                        <i class="fa-solid fa-xmark"></i>
                        <div class="cm-selected-tag-name" matTooltip="{{ Labels.TagType.get(tag.type)?.label }}: {{ tag.name }}">
                            {{ tag.name }}
                        </div>
                    </div>
                }
            </div>
        </ng-container>
    </cm-inspector-section>
}
