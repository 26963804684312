import {Matrix4, Vector3, AABB, Quaternion} from "@cm/math"

export function calculateFOV(filmGauge: number, focalLength: number, width: number, height: number): [number, number] {
    if (width > height) {
        return [2 * Math.atan(filmGauge / (2 * focalLength)), 2 * Math.atan((filmGauge * (height / width)) / (2 * focalLength))]
    } else {
        return [2 * Math.atan((filmGauge * (width / height)) / (2 * focalLength)), 2 * Math.atan(filmGauge / (2 * focalLength))]
    }
}

export function cameraLookAt(position: Vector3, target: Vector3): Matrix4 {
    let vz = position.sub(target).normalized()
    const up = new Vector3(0, 1, 0)
    let vx = up.cross(vz)
    if (vx.norm() === 0) {
        // parallel! let's force one slightly off
        vz.z += 0.0001
        vz = vz.normalized()
        vx = up.cross(vz)
    }
    vx = vx.normalized()
    const vy = vz.cross(vx)
    return new Matrix4([vx.x, vx.y, vx.z, 0, vy.x, vy.y, vy.z, 0, vz.x, vz.y, vz.z, 0, position.x, position.y, position.z, 1])
}

export function cameraAutomaticTarget(
    position: Vector3,
    target: Vector3,
    aabb: AABB,
    focalLength: number,
    filmGauge: number,
    uiSize: [number, number],
): Matrix4 {
    // assumes that camera is never tilted, and can orbit 360deg around the target
    const [fovX, fovY] = calculateFOV(filmGauge, focalLength, ...uiSize)
    const [[minX, minY, minZ], [maxX, maxY, maxZ]] = aabb
    const sizeEps = 1e-3
    if (maxX - minX < sizeEps && maxY - minY < sizeEps && maxZ - minZ < sizeEps) {
        // use original positions if bounding box is zero size
    } else {
        const [cx, cy, cz] = target.toArray()
        // calculate the swept radii for orbits around the target
        const distTo = (x: number, y: number, z: number) => {
            const dx = x - cx
            const dy = y - cy
            const dz = z - cz
            return Math.sqrt(dx * dx + dy * dy + dz * dz)
        }
        const radiusXZ = Math.max(distTo(minX, cy, minZ), distTo(maxX, cy, minZ), distTo(minX, cy, maxZ), distTo(maxX, cy, maxZ))
        const radiusXYZ = Math.max(
            distTo(minX, minY, minZ),
            distTo(maxX, minY, minZ),
            distTo(minX, maxY, minZ),
            distTo(maxX, maxY, minZ),
            distTo(minX, minY, maxZ),
            distTo(maxX, minY, maxZ),
            distTo(minX, maxY, maxZ),
            distTo(maxX, maxY, maxZ),
        )
        // calculate the distance required to fit the radii within the FOV
        const distance = Math.max(radiusXZ / Math.tan(fovX / 2), radiusXYZ / Math.tan(fovY / 2))

        if (target.y > position.y) {
            // don't allow camera to look upwards
            position = new Vector3(position.x, target.y, position.z)
        }
        position = position.sub(target).normalized().mul(distance).add(target)
    }
    return cameraLookAt(position, target)
}

export function automaticVerticalTilt(
    cameraTransformIn: Matrix4,
    filmGauge: number,
    focalLength: number,
    width: number,
    height: number,
): readonly [Matrix4, number] {
    // calculate target in front of camera
    const {position: positionCamera, quaternion: quaternionCamera, scale: scaleCamera} = cameraTransformIn.decompose()

    const normal = quaternionCamera.multiplyVector(new Vector3(0, 0, -1))
    const positionTarget = positionCamera.add(normal.mul(100))

    // move target onto same y height as camera (y is up!)
    const targetAbove = positionTarget.y > positionCamera.y
    const positionNew = new Vector3(positionTarget.x, positionCamera.y, positionTarget.z)

    // calculate direction vectors to both points
    const directionTarget = positionTarget.sub(positionCamera).normalized()
    const directionNew = positionNew.sub(positionCamera).normalized()

    // calculate rotation quaternion between both points
    let rotationQuaternion = Quaternion.fromUnitVectors(directionTarget, directionNew)

    // rotate camera
    rotationQuaternion = rotationQuaternion.multiply(quaternionCamera)
    const cameraTransform = Matrix4.compose(positionCamera, rotationQuaternion, scaleCamera)

    // calculate rotation angle
    let rotationAngle = quaternionCamera.angleTo(rotationQuaternion)

    if (targetAbove) {
        rotationAngle = -rotationAngle
    }

    // camera shift amount is in units of camera FOV
    const [_fovX, fovY] = calculateFOV(filmGauge, focalLength, width, height)
    const additionalShiftY = -Math.tan(rotationAngle) / (2 * Math.tan(fovY / 2))

    return [cameraTransform, additionalShiftY]
}

export function targetFromTransform(value: Matrix4): Vector3 {
    const {position, quaternion} = value.decompose()
    const targetDirection = quaternion.multiplyVector(new Vector3(0, 0, -1))
    return position.add(targetDirection.mul(300))
}
