<cm-responsive-table-layout>
    <cm-add-tag-button cm-buttons></cm-add-tag-button>
    <cm-item-filters cm-filters>
        @if (permission.$to().read.menu("filterByOrganizationOnTagsPage")) {
            <cm-tag-search-filter></cm-tag-search-filter>
        } @else {
            <cm-search-filter></cm-search-filter>
        }
        <cm-checkboxes-filter label="Type" name="tagType" [options]="$tagTypes()"></cm-checkboxes-filter>
    </cm-item-filters>

    <ng-template cmTableContent>
        <cm-tags-table></cm-tags-table>
    </ng-template>
    <ng-template cmGridContent>
        <cm-tags-grid></cm-tags-grid>
    </ng-template>
</cm-responsive-table-layout>
<router-outlet></router-outlet>
