import {Vector2, Vector2Like} from "#math/vector2"
import {Size2Like} from "#math/size2"

export function isBox2Like(x: any): x is Box2Like {
    return typeof x === "object" && typeof x.x === "number" && typeof x.y === "number" && typeof x.width === "number" && typeof x.height === "number"
}

export type Box2Like = {x: number; y: number; width: number; height: number}

export class Box2 {
    constructor(
        public x = 0,
        public y = 0,
        public width = 0,
        public height = 0,
    ) {}

    set(x: number, y: number, width: number, height: number) {
        this.x = x
        this.y = y
        this.width = width
        this.height = height
        return this
    }

    setFromPositionAndSize(position: Vector2Like, size: Vector2Like) {
        this.x = position.x
        this.y = position.y
        this.width = size.x
        this.height = size.y
        return this
    }

    setFromMinMax(min: Vector2Like, max: Vector2Like) {
        this.x = min.x
        this.y = min.y
        this.width = max.x - min.x
        this.height = max.y - min.y
        return this
    }

    setFromBounds(x1: number, y1: number, x2: number, y2: number): Box2 {
        this.x = Math.min(x1, x2)
        this.y = Math.min(y1, y2)
        this.width = Math.max(x1, x2) - this.x
        this.height = Math.max(y1, y2) - this.y
        return this
    }

    setFromBox2Like(b: Box2Like): Box2 {
        this.x = b.x
        this.y = b.y
        this.width = b.width
        this.height = b.height
        return this
    }

    get min(): Vector2 {
        return this.leftUpper
    }

    get max(): Vector2 {
        return this.rightLower
    }

    get leftUpper(): Vector2 {
        return new Vector2(this.x, this.y)
    }

    get rightUpper(): Vector2 {
        return new Vector2(this.x + this.width, this.y)
    }

    get leftLower(): Vector2 {
        return new Vector2(this.x, this.y + this.height)
    }

    get rightLower(): Vector2 {
        return new Vector2(this.x + this.width, this.y + this.height)
    }

    get position(): Vector2 {
        return this.leftUpper
    }

    get size(): Vector2 {
        return new Vector2(this.width, this.height)
    }

    toArray(): [number, number, number, number] {
        return [this.x, this.y, this.width, this.height]
    }

    static fromArray(a: number[]) {
        return new this(a[0], a[1], a[2], a[3])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    static fromSize(size: Size2Like) {
        return new this().setFromBounds(0, 0, size.width, size.height)
    }

    static fromPositionAndSize(position: Vector2Like, size: Vector2Like) {
        return new this().setFromBounds(position.x, position.y, position.x + size.x, position.y + size.y)
    }

    static fromMinMax(min: Vector2Like, max: Vector2Like) {
        return new this().setFromMinMax(min, max)
    }

    static fromBounds(x1: number, y1: number, x2: number, y2: number) {
        return new this().setFromBounds(x1, y1, x2, y2)
    }

    static fromBox2Like(b: Box2Like) {
        return new this().setFromBox2Like(b)
    }

    equals(other: Box2Like) {
        return this.x === other.x && this.y === other.y && this.width === other.width && this.height === other.height
    }

    clone(): Box2 {
        return new Box2(this.x, this.y, this.width, this.height)
    }

    isEmpty(): boolean {
        return Box2.isEmpty(this)
    }

    static isEmpty(box: Box2Like) {
        return box.width <= 0 || box.height <= 0
    }

    makeEmpty() {
        this.x = this.y = 0
        this.width = this.height = 0
    }

    static containsPoint(box: Box2Like, point: Vector2Like): boolean {
        return point.x >= box.x && point.x <= box.x + box.width && point.y >= box.y && point.y <= box.y + box.height
    }

    containsPoint(point: Vector2Like): boolean {
        return Box2.containsPoint(this, point)
    }

    static intersect(box1: Box2Like, box2: Box2Like) {
        if (Box2.isEmpty(box1) || Box2.isEmpty(box2)) {
            return new Box2()
        } else {
            const x = Math.max(box1.x, box2.x)
            const y = Math.max(box1.y, box2.y)
            const width = Math.min(box1.x + box1.width, box2.x + box2.width) - x
            const height = Math.min(box1.y + box1.height, box2.y + box2.height) - y
            if (box1.width <= 0 || box1.height <= 0) {
                return new Box2()
            } else {
                return new Box2(x, y, width, height)
            }
        }
    }

    intersect(box: Box2Like) {
        return Box2.intersect(this, box)
    }

    intersectInPlace(box: Box2Like) {
        const intersection = Box2.intersect(this, box)
        this.x = intersection.x
        this.y = intersection.y
        this.width = intersection.width
        this.height = intersection.height
        return this
    }

    static expandByPoint(box: Box2Like, pointToExpandBy: Vector2Like) {
        let x, y, width, height: number
        if (Box2.isEmpty(box)) {
            x = pointToExpandBy.x
            y = pointToExpandBy.y
            width = height = 1 // TODO this might be an issue but in the current implementation is needed to avoid emptyness when expanding by points
        } else {
            x = Math.min(box.x, pointToExpandBy.x)
            y = Math.min(box.y, pointToExpandBy.y)
            width = Math.max(box.width, pointToExpandBy.x - box.x)
            height = Math.max(box.height, pointToExpandBy.y - box.y)
        }
        return new Box2(x, y, width, height)
    }

    expandByPointInPlace(point: Vector2Like) {
        const expanded = Box2.expandByPoint(this, point)
        this.x = expanded.x
        this.y = expanded.y
        this.width = expanded.width
        this.height = expanded.height
        return this
    }

    static expandBySize(box: Box2Like, sizeToExpandBy: Size2Like) {
        let width, height: number
        if (Box2.isEmpty(box)) {
            width = sizeToExpandBy.width
            height = sizeToExpandBy.height
        } else {
            width = Math.max(box.width, sizeToExpandBy.width)
            height = Math.max(box.height, sizeToExpandBy.height)
        }
        return new Box2(box.x, box.y, width, height)
    }

    expandBySizeInPlace(size: Size2Like) {
        const expanded = Box2.expandBySize(this, size)
        this.x = expanded.x
        this.y = expanded.y
        this.width = expanded.width
        this.height = expanded.height
        return this
    }

    static expandByBox(box: Box2Like, boxToExpandBy: Box2Like) {
        if (Box2.isEmpty(boxToExpandBy)) {
            return box
        }
        let x, y, width, height: number
        if (Box2.isEmpty(box)) {
            x = boxToExpandBy.x
            y = boxToExpandBy.y
            width = boxToExpandBy.width
            height = boxToExpandBy.height
        } else {
            x = Math.min(box.x, boxToExpandBy.x)
            y = Math.min(box.y, boxToExpandBy.y)
            width = Math.max(box.x + box.width, boxToExpandBy.x + boxToExpandBy.width) - x
            height = Math.max(box.y + box.height, boxToExpandBy.y + boxToExpandBy.height) - y
        }
        return new Box2(x, y, width, height)
    }

    expandByBoxInPlace(box: Box2Like) {
        const expanded = Box2.expandByBox(this, box)
        this.x = expanded.x
        this.y = expanded.y
        this.width = expanded.width
        this.height = expanded.height
        return this
    }

    static expandToIntegers(box: Box2Like) {
        if (!Box2.isEmpty(box)) {
            const x = Math.floor(box.x)
            const y = Math.floor(box.y)
            const width = Math.ceil(box.x + box.width) - x
            const height = Math.ceil(box.y + box.height) - y
            return new Box2(x, y, width, height)
        }
        return box
    }

    expandToIntegersInPlace() {
        const expanded = Box2.expandToIntegers(this)
        this.x = expanded.x
        this.y = expanded.y
        this.width = expanded.width
        this.height = expanded.height
        return this
    }
}
