import {takeUntil} from "rxjs"
import {assertNever} from "@cm/utils"
import {Vector2} from "@cm/math"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {ShiftableToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/shiftable-toolbox-item"
import {LayerEditMode, LayerMoveMode, OperatorLayerAndMask} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/operator-layer-and-mask"
import {DrawableImageBrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/drawable-image-brush-toolbox-item"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {HalImage} from "@app/common/models/hal/hal-image"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class LayerAndMaskToolbox extends OperatorToolboxBase<OperatorLayerAndMask> {
    constructor(operator: OperatorLayerAndMask) {
        super(operator, SelectionMode.None, true)

        this.brushToolboxItem = new DrawableImageBrushToolboxItem(this, operator.callback.drawableImageCache)
        this.brushToolboxItem.brushSettings = operator.brushSettings
        this.brushToolboxItem.drawableImageUpdated.subscribe(() => operator.requestEval())
        this.brushToolboxItem.brushStrokeUpdated.subscribe(() => operator.markEdited())

        this.shiftableMapToolboxItem = new ShiftableToolboxItem(this)
        this.shiftableMapToolboxItem.shiftInPixels = this.operator.mapOffsetInPixels
        this.shiftableMapToolboxItem.shiftInPixelsChanged.subscribe((shiftInPixels) => {
            const delta = shiftInPixels.sub(this.operator.mapOffsetInPixels)
            if (this.operator.layerMoveMode === LayerMoveMode.ImageAndMask) {
                // if we are moving both image and mask, we need to move the mask by the same amount
                this.shiftableMaskToolboxItem.shiftInPixels = Vector2.fromVector2Like(this.operator.maskOffsetInPixels).add(delta)
            }
            this.operator.mapOffsetInPixels = shiftInPixels
        })
        this.shiftableMapToolboxItem.showGuides = this.operator.showGuides
        this.operator.showGuidesChanged.pipe(takeUntil(this.unsubscribe)).subscribe((value) => (this.shiftableMapToolboxItem.showGuides = value))

        this.shiftableMaskToolboxItem = new ShiftableToolboxItem(this)
        this.shiftableMaskToolboxItem.shiftInPixels = this.operator.maskOffsetInPixels
        this.shiftableMaskToolboxItem.shiftInPixelsChanged.subscribe((shiftInPixels) => (this.operator.maskOffsetInPixels = shiftInPixels))
        this.shiftableMaskToolboxItem.showGuides = false

        this.updateItemSelection()
        this.operator.layerEditModeChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.updateItemSelection())
        this.operator.layerMoveModeChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.updateItemSelection())
    }

    setMaskReference(value: DrawableImageRef) {
        this.brushToolboxItem.setDrawableImageRef(value, true)
    }

    async setStrokeImage(value: HalImage) {
        await this.brushToolboxItem.setStrokeImage(value)
    }

    async getStrokeImage(): Promise<HalImage> {
        return this.brushToolboxItem.getStrokeImage()
    }

    override hitTest(point: Vector2): CanvasBaseToolboxItem | null {
        this.brushToolboxItem.brushCursorOffset.set(-this.operator.maskOffsetInPixels.x, -this.operator.maskOffsetInPixels.y) // TODO this is a hack to sync offsets; clean this up
        return super.hitTest(point)
    }

    private updateItemSelection() {
        switch (this.operator.layerEditMode) {
            case LayerEditMode.Move:
                this.brushToolboxItem.selected = false
                this.shiftableMapToolboxItem.selected =
                    this.operator.layerMoveMode === LayerMoveMode.ImageAndMask || this.operator.layerMoveMode === LayerMoveMode.ImageOnly
                this.shiftableMaskToolboxItem.selected = this.operator.layerMoveMode === LayerMoveMode.MaskOnly
                break
            case LayerEditMode.Draw:
                this.brushToolboxItem.selected = true
                this.shiftableMapToolboxItem.selected = false
                this.shiftableMaskToolboxItem.selected = false
                break
            default:
                assertNever(this.operator.layerEditMode)
        }
    }

    private brushToolboxItem: DrawableImageBrushToolboxItem
    private shiftableMapToolboxItem: ShiftableToolboxItem
    private shiftableMaskToolboxItem: ShiftableToolboxItem
}
