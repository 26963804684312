import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {LayerWeightInputs, LayerWeightOutputs} from "@material-editor/models/nodes/layer-weight"

@Component({
    selector: "cm-layer-weight-node",
    templateUrl: "./layer-weight-node.component.html",
    styleUrls: ["./layer-weight-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class LayerWeightNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = LayerWeightInputs
    outputs = LayerWeightOutputs
    typeInfo = LayerWeightNodeType
}

export const LayerWeightNodeType: MaterialNodeType<typeof LayerWeightNodeComponent> = {
    id: "layerWeight",
    label: "Layer Weight",
    color: "#4987af",
    name: "ShaderNodeLayerWeight",
    inputs: [LayerWeightInputs.blend, LayerWeightInputs.normal] as never[],
    outputs: [LayerWeightOutputs.fresnel, LayerWeightOutputs.facing],
    component: LayerWeightNodeComponent,
}
