import {Component} from "@angular/core"
import {AssetFilterInput, SelectAssetItemFragment} from "@api"
import {SelectDialogComponent} from "@common/components/dialogs/select-dialog/select-dialog.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"

@Component({
    selector: "cm-select-asset",
    standalone: true,
    imports: [SearchComponent, SelectDialogComponent],
    templateUrl: "./select-asset.component.html",
    styleUrl: "./select-asset.component.scss",
})
export class SelectAssetComponent extends BaseSelectComponent<SelectAssetItemFragment, AssetFilterInput> {
    $data = this.implementDataLoader(this.sdk.gql.selectAssetItems)
}
