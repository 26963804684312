import {Catalog} from "@cm/pricing/catalogs/catalog-interface"
import {parseXml} from "@cm/pricing/catalogs/darran/maincatalog"
import {parseXlsb} from "@cm/pricing/catalogs/vado/catalog"

export async function getCatalogFromFileInput(event: Event): Promise<Catalog> {
    const input = event.target as HTMLInputElement
    const file = input.files ? input.files[0] : undefined
    if (!file) throw new Error("No file selected")

    if (file.name.endsWith(".xml")) {
        const data = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = () => reject(reader.error)
            reader.readAsText(file)
        })
        return await parseXml(data)
    } else if (file.name.endsWith(".xlsb")) {
        const data = await new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result as ArrayBuffer)
            reader.onerror = () => reject(reader.error)
            reader.readAsArrayBuffer(file)
        })
        return parseXlsb(new Uint8Array(data), await import("xlsx"))
    }

    throw new Error("Unsupported file type")
}
