export type TestCaseEntry = {
    type: "info" | "test"
    name: string
    tooltip: string
    fct: () => void
}

export function sendMessage(iframe: HTMLIFrameElement, methodName: string, parameters?: unknown) {
    const origin = new URL(iframe.src).origin
    if (!iframe.contentWindow) throw new Error("iframe has no contentWindow")
    iframe.contentWindow.postMessage(
        {
            data: {
                method: methodName,
                parameters: parameters,
            },
            type: "colormass",
        },
        origin,
    )
}

export function setParameter(iframe: HTMLIFrameElement, id: string, type: string, value: unknown) {
    sendMessage(iframe, "parameter", {id: id, type: type, value: value})
}

export async function getImageParamDataFromUrl(imageUrl: string) {
    const response = await fetch(imageUrl)
    if (!response.ok) throw new Error("Failed to fetch image from URL")

    const blob = await response.blob()

    const arrayBuffer = await blob.arrayBuffer()
    const buffer = new Uint8Array(arrayBuffer)

    return {
        id: crypto.randomUUID(),
        contentType: blob.type,
        data: buffer,
    }
}

//Without this, loading a new configurator shows the menu of the old one - there is data remaining in the js context.
const resetIframe = async (iframe: HTMLIFrameElement) => {
    iframe.src = "about:blank"
    await new Promise((resolve) => (iframe.onload = resolve))
    iframe.src = ""
    await new Promise((resolve) => (iframe.onload = resolve))
}

export const updateIframe = async (iframe: HTMLIFrameElement, content: string) => {
    await resetIframe(iframe)

    const doc = iframe.contentDocument
    doc?.open()
    doc?.write(content)
    doc?.close()
}
