import {Component, inject, Input, OnInit, signal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule, MatOptionSelectionChange} from "@angular/material/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {BasicTagInfoFragment, ContentTypeModel, TagSelectTagFragment, TagType} from "@api"
import {IsLoadingDirective} from "@app/common/directives"
import {UtilsService} from "@legacy/helpers/utils"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsDefined} from "@cm/utils"

@Component({
    selector: "cm-tag-select",
    standalone: true,
    imports: [MatInputModule, MatSelectModule, MatFormFieldModule, MatOptionModule, FormsModule, IsLoadingDirective, MatButtonModule],
    templateUrl: "./tag-select.component.html",
    styleUrl: "./tag-select.component.scss",
})
export class TagSelectComponent implements OnInit {
    @Input({required: true}) assignedToId!: string
    @Input({required: true}) contentTypeModel!: ContentTypeModel
    @Input() organizationId?: string
    @Input() type?: TagType
    @Input() label = "Tags"
    @Input() canEdit = true
    @Input() includeTagsWithNoOrganization = false

    notifications = inject(NotificationsService)
    sdk = inject(SdkService)
    utils = inject(UtilsService)

    options?: TagSelectTagFragment[]
    $assigned = signal<{tagId: string; tagAssignmentId: string}[]>([])

    ngOnInit() {
        void this.refresh()
    }

    async refresh() {
        this.options = await this.sdk.gql
            .tagSelectTags({
                filter: {
                    tagType: this.type ? [this.type] : undefined,
                    // use undefined to show all, null to show only tags with no organization
                    organizationId: this.organizationIdFilter(this.organizationId),
                },
            })
            .then(({tags}) => tags.filter(IsDefined))
        if (this.organizationId && this.includeTagsWithNoOrganization) {
            const optionsWithoutOrganization = await this.sdk.gql
                .tagSelectTags({
                    filter: {tagType: this.type ? [this.type] : undefined, organizationId: null},
                })
                .then(({tags}) => tags.filter(IsDefined))
            this.options = [...this.options, ...optionsWithoutOrganization]
        }
        this.options.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        const assignments = await this.sdk.gql
            .tagSelectTagAssignments({filter: {objectId: this.assignedToId, contentTypeModel: this.contentTypeModel}})
            .then(({tagAssignments}) => tagAssignments.filter(IsDefined))
        const assignedOptions = assignments
            .map((assignment) => ({
                tagId: assignment.tag.id,
                tagAssignmentId: assignment.id,
            }))
            .filter((assigned) => this.options?.some((option) => option.id === assigned.tagId))
        this.$assigned.set(assignedOptions)
    }

    toggleTag(toggleEvent: MatOptionSelectionChange<TagSelectTagFragment>) {
        if (toggleEvent.isUserInput) {
            const tagId = toggleEvent.source.value.id
            this.notifications.withUserFeedback(
                async () => {
                    const assignment = this.$assigned().find((assigned) => assigned.tagId === tagId)
                    if (assignment) {
                        await this.sdk.gql.tagSelectUnassign({tagAssignmentId: assignment.tagAssignmentId})
                        this.$assigned.update((tags) => {
                            return tags.filter((assigned) => assigned.tagId !== tagId)
                        })
                    } else {
                        const {createTagAssignment: tagAssignment} = await this.sdk.gql.tagSelectAssign({
                            tagId,
                            contentTypeModel: this.contentTypeModel,
                            objectId: this.assignedToId,
                        })
                        this.$assigned.update((tags) => {
                            return [...tags, {tagAssignmentId: tagAssignment.id, tagId}]
                        })
                    }
                },
                {
                    success: "Tag updated",
                    error: "Failed to update tag",
                },
            )
        }
    }

    compareAssignments(option: BasicTagInfoFragment, selection: {tagId: string}): boolean {
        return option.id === selection.tagId
    }

    organizationIdFilter(organizationId: string | null | undefined) {
        switch (organizationId) {
            case undefined:
                return undefined
            case null:
                return null
            default:
                return {equals: organizationId}
        }
    }
}
