import {Component, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule} from "@angular/material/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {RouterLink} from "@angular/router"
import {ContentTypeModel, FileDetailsFragment, MutationUpdateDataObjectInput} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {IsLoadingDirective} from "@common/directives"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {FilesService} from "@common/services/files/files.service"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {MatDialog} from "@angular/material/dialog"

@Component({
    selector: "cm-file-details",
    standalone: true,
    imports: [
        DetailsDialogLayoutComponent,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        SectionComponent,
        IsLoadingDirective,
        MatButtonModule,
        RouterLink,
        ThumbnailComponent,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        TitleSectionComponent,
    ],
    templateUrl: "./file-details.component.html",
    styleUrl: "./file-details.component.scss",
})
export class FileDetailsComponent extends BaseDetailsComponent<FileDetailsFragment, Omit<MutationUpdateDataObjectInput, "id">> {
    override _contentTypeModel = ContentTypeModel.DataObject
    override _fetchItem = this.sdk.gql.fileDetails
    override _updateItem = this.sdk.gql.updateFileDetails

    matDialog = inject(MatDialog)
    organizations = inject(OrganizationsService)

    downloadFile(): void {
        const downloadUrl = this.$item()?.downloadUrl
        if (downloadUrl) {
            FilesService.downloadFile("test", downloadUrl)
        }
    }

    performDelete = async () => {
        const item = this.$item()
        if (item) {
            await this.sdk.gql.deleteFile(item)
            this.refresh.item(item)
            await this.closeModal()
        }
    }

    deleteConfirmationMessage() {
        const item = this.$item()
        return (
            (item ? `The file '${item.originalFileName}'` : "This job") +
            " will be deleted. This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?"
        )
    }

    protected readonly DialogSize = DialogSize
}
