@if (node) {
    <ng-content select="[cm-content]"> </ng-content>
    <cm-inspector-section>
        <ng-container cm-title> Template Instance </ng-container>
        <ng-container cm-content>
            <cm-input-container>
                <ng-container cm-inline-label> ID: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.id!" (valueChange)="updateNode(node)" [validate]="isValidId"> </cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Name: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.name!" (valueChange)="updateNode(node)"> </cm-string-input>
                </ng-container>
            </cm-input-container>
        </ng-container>
    </cm-inspector-section>
    <!--TODO: Add back graph as a node reference if there is a tutorial for the users how to use it.-->
    <!--<ng-container *ngIf="node.type === 'templateInstance'">-->
    <!--<cm-inspector-section>-->
    <!--<ng-container cm-title>-->
    <!--Graph-->
    <!--</ng-container>-->
    <!--<ng-container cm-content>-->
    <!--<cm-node-reference-slot [(node)]="node.template"-->
    <!--(nodeChange)="updateNode(node)"-->
    <!--[filter]="resolvesToTemplateDefinition"></cm-node-reference-slot>-->
    <!--</ng-container>-->
    <!--</cm-inspector-section>-->
    <!--</ng-container>-->
    @if (getInterfaceDescriptors(node); as descriptors) {
        <cm-inspector-section>
            <ng-container cm-title> Inputs </ng-container>
            <ng-container cm-content>
                <div class="cm-template-settings">
                    @for (desc of descriptors; track desc) {
                        @if (isConfigInput(desc)) {
                            @if (getControllingNode(node, desc.id); as ctrlNode) {
                                <span>{{ desc.name }}:</span>
                                <cm-node-reference-slot [node]="ctrlNode" (nodeChange)="setControllingNode(node, desc.id, $event)"></cm-node-reference-slot>
                            }
                            @if (!getControllingNode(node, desc.id)) {
                                <mat-form-field>
                                    <mat-label>{{ desc.name }}</mat-label>
                                    <mat-select
                                        [value]="getParameter(node, desc.id) ?? null"
                                        (selectionChange)="setParameter(node, desc.id, $event.value ?? undefined)"
                                        (cmNodeDrop)="setControllingNode(node, desc.id, $event)"
                                    >
                                        @for (variant of desc.variants; track variant) {
                                            <mat-option [value]="variant.id">
                                                {{ variant.name }}
                                                <button mat-button class="cm-copy-reference-button" (click)="copyVariantReference(node, variant)">
                                                    <i class="far fa-copy"></i>
                                                </button>
                                            </mat-option>
                                        }
                                        <mat-option [value]="null"><span style="font-style: italic">(not set)</span></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            }
                        }
                        @if (isMaterialInput(desc) || isTemplateInput(desc)) {
                            <div class="cm-material-input">
                                <span>{{ desc.name }}:</span>
                                <cm-node-reference-slot
                                    [node]="getControllingNode(node, desc.id)!"
                                    (nodeChange)="setControllingNode(node, desc.id, $event)"
                                    (chooseNode)="chooseMaterialNode.emit($event)"
                                ></cm-node-reference-slot>
                            </div>
                        }
                        @if (isBooleanInput(desc)) {
                            <div class="cm-material-input">
                                <span>{{ desc.name }}:</span>
                                <cm-node-reference-slot
                                    [node]="getControllingNode(node, desc.id)!"
                                    (nodeChange)="setControllingNode(node, desc.id, $event)"
                                ></cm-node-reference-slot>
                            </div>
                        }
                        @if (isNumberInput(desc)) {
                            <div class="cm-material-input">
                                <span>{{ desc.name }}:</span>
                                <cm-node-reference-slot
                                    fallbackText=""
                                    [node]="getControllingNode(node, desc.id)!"
                                    (nodeChange)="setControllingNode(node, desc.id, $event)"
                                >
                                    <ng-container cm-fallback>
                                        <!-- TODO: Focus is immediately lost when updating??? -->
                                        <cm-numeric-input
                                            [value]="getParameter(node, desc.id)"
                                            (valueChange)="setParameter(node, desc.id, $event)"
                                            [decimalPlaces]="2"
                                            [emptyValue]="undefined"
                                        >
                                        </cm-numeric-input>
                                    </ng-container>
                                </cm-node-reference-slot>
                            </div>
                        }
                    }
                </div>
            </ng-container>
            @if (getInvalidInputs(node, descriptors); as invalidInputs) {
                <div class="cm-section-label">Invalid Inputs</div>
                @for (entry of invalidInputs | keyvalue; track entry) {
                    <div>
                        @if (isNode(entry.value)) {
                            <span>{{ entry.key }}:</span>
                            <cm-node-reference-slot
                                [node]="entry.value"
                                (nodeChange)="setControllingNode(node, entry.key, $event ?? null)"
                            ></cm-node-reference-slot>
                        }
                        @if (!isNode(entry.value)) {
                            <span
                                >{{ entry.key }}: {{ entry.value }}
                                <i class="fas fa-times-circle" (click)="$event.stopPropagation(); setParameter(node, entry.key, undefined)"></i
                            ></span>
                        }
                    </div>
                }
            }
        </cm-inspector-section>
        <cm-inspector-section>
            <ng-container cm-title> Outputs </ng-container>
            <ng-container cm-content>
                @for (desc of descriptors; track desc) {
                    @if (isOutputDescriptor(desc)) {
                        <div>
                            <div class="cm-output" (cmNodeDrag)="$event.node = createOutputReference(node, desc)">
                                <i class="fas fa-sign-out"></i>
                                <span>Output: {{ desc.name }}</span>
                                <i (click)="copyOutputReference(node, desc)" class="cm-copy-reference-button" class="far fa-copy"></i>
                            </div>
                        </div>
                    }
                }
            </ng-container>
        </cm-inspector-section>
    }
}
