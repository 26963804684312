import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {IMaterialData, keyForMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {hashObject, removeUndefinedEntriesFromObject} from "@cm/utils"

export function keyForMeshMaterialData(materialData: IMaterialData, mesh: SceneNodes.Mesh) {
    const {meshRenderSettings} = mesh

    const {
        displacementImage,
        displacementUvChannel,
        displacementMin,
        displacementMax,
        displacementNormalStrength,
        displacementNormalSmoothness,
        displacementNormalOriginalResolution,
    } = meshRenderSettings

    if (displacementImage === undefined) {
        return keyForMaterialData(materialData)
    }

    return hashObject(
        removeUndefinedEntriesFromObject({
            materialMaterialData: keyForMaterialData(materialData),
            displacementImageNodeHash: displacementImage.imageNode.hash,
            displacementUvChannel,
            displacementMin,
            displacementMax,
            displacementNormalStrength,
            displacementNormalSmoothness,
            displacementNormalOriginalResolution,
        }),
    )
}
