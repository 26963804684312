import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"
import {debounceTime, Subject, takeUntil, tap} from "rxjs"

@Component({
    selector: "cm-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    standalone: true,
    imports: [InputContainerComponent, NativeInputTextComponent],
})
export class SearchComponent implements OnInit {
    @Input() value = ""
    @Input() placeholder = "Search..."
    @Output() inputStarted = new EventEmitter<string>()
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>()
    @Output() focus = new EventEmitter<FocusEvent>()
    @Output() blur = new EventEmitter<FocusEvent>()
    searchTextChanged: Subject<string> = new Subject<string>()
    unsubscribe = new Subject<void>()

    @ViewChild(NativeInputTextComponent) nativeInput!: NativeInputTextComponent

    ngOnInit(): void {
        this.searchTextChanged
            .pipe(
                tap((newValue) => {
                    this.value = newValue
                }),
                tap((newValue) => this.inputStarted.emit(newValue)),
                debounceTime(300),
                // we need to emit even if the old value is the same as the new value
                // because the content might already have been replaced by placeholders
                // e.g. when changing `text` to `text2` and then back to `text` within the debounce time
                // distinctUntilChanged(),
                takeUntil(this.unsubscribe),
            )
            .subscribe((text: string) => {
                this.valueChange.emit(text)
            })
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    updateValue(value: string) {
        this.nativeInput.updateValue(value)
    }
}
