import {inject, Injectable} from "@angular/core"
import {ContentTypeModel} from "@api"
import {IsDefined} from "@cm/utils"
import {ColormassAbilitySubject} from "@common/models/abilities"
import {
    catchError,
    EMPTY,
    exhaustMap,
    filter,
    from,
    identity,
    map,
    Observable,
    of,
    OperatorFunction,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    takeWhile,
} from "rxjs"
import {intervalBackoff} from "backoff-rxjs"
import {TabStateService} from "@common/services/tab-state/tab-state.service"

/**
 * Let components inform other components that an item has been updated.
 */
@Injectable({
    providedIn: "root",
})
export class RefreshService {
    // fires whenever an item needs to be refreshed
    public itemSubject = new Subject<{id: string; __typename: ContentTypeModel}>()
    // fires when all items of this content type should be reloaded
    public contentTypeModelSubject = new Subject<ContentTypeModel>()
    // true for reload, discarding previous data, false for just refreshing the data
    public allSubject = new Subject<boolean>()

    tabState = inject(TabStateService)

    /**
     * Inform other components and services that the item has changed and needs to be re-fetched.
     */
    item(item: {id: string | null | undefined; __typename: string} | null | undefined) {
        if (item?.id) {
            // extract only id and __typename
            // we don't want to pass on additional properties, as these should be reloaded
            // Note that __typename is typed as a string for convenience
            // (so that item loaded from GQL can be passed in directly),
            // but should always correspond to a valid ContentTypeModel value
            this.itemSubject.next({id: item.id, __typename: item.__typename as ContentTypeModel})
        }
    }

    /**
     * Refresh all items in the list.
     * If `discardPreviousData` is true, empty the page while the new data is loading
     */
    all(discardPreviousData = true) {
        this.allSubject.next(discardPreviousData)
    }

    /**
     * Refresh all items of a given content type.
     */
    contentTypeModel(contentTypeModel: ContentTypeModel) {
        this.contentTypeModelSubject.next(contentTypeModel)
    }

    get observeAll$(): Observable<boolean> {
        return this.allSubject.pipe(startWith(true))
    }

    observeAllContentTypeModel$(withContentTypeModel: ContentTypeModel): Observable<void> {
        return this.contentTypeModelSubject.pipe(
            filter((contentTypeModel) => contentTypeModel === withContentTypeModel),
            startWith(undefined),
            map(() => undefined as void),
        )
    }

    reEmitWhenItemIsRefreshed = (contentTypeModel?: ContentTypeModel): OperatorFunction<string, string> => {
        return switchMap((id) =>
            this.observeItem$({
                id,
                __typename: contentTypeModel,
            }).pipe(map(() => id)),
        )
    }

    observeItem$<EntityType extends {id: string; __typename?: string}>(
        item: EntityType | null | undefined | Observable<EntityType | null | undefined>,
        emitInitial = true,
    ): Observable<{id: string; __typename?: string} | null | undefined> {
        if (!item) {
            return EMPTY
        }
        if (item instanceof Observable) {
            return item.pipe(switchMap((item) => this.observeItem$(item)))
        }
        return this.itemSubject.pipe(
            filter((refreshedItem) => refreshedItem.id === item?.id),
            emitInitial ? startWith(item) : identity,
            filter(IsDefined),
            map(() => ({id: item.id, __typename: item.__typename})),
        )
    }

    // fetch an item by id
    // whenever the item is explicitly refreshed, it is re-fetched automatically
    keepFetched$ = <EntityType extends {id: string} & ColormassAbilitySubject>(
        id: string | null | undefined | Observable<string | null | undefined>,
        contentTypeModel: ContentTypeModel,
        fetch: (args: {id: string}) => Promise<{item: EntityType} | null>,
    ): Observable<EntityType | null | undefined> => {
        const idObservable = id instanceof Observable ? id : of(id)
        return idObservable.pipe(
            switchMap((id) => {
                switch (id) {
                    case null:
                        return of(null)
                    case undefined:
                        return of(undefined)
                    default:
                        return this.observeItem$({id, __typename: contentTypeModel}).pipe(
                            switchMap((item) => {
                                if (!item) {
                                    return of(null)
                                }
                                // fetch item to obtain legacy id as well as id
                                return from(fetch({id: item.id})).pipe(
                                    filter(IsDefined),
                                    map(({item}) => item),
                                )
                            }),
                        )
                }
            }),
        )
    }

    keepObservingByIdWhileTabIsActive$<Item>(
        refetch: (item: {id: string}) => Promise<Item>,
        keepFetchingWhile: (item: Item) => boolean = () => true,
    ): OperatorFunction<string, Item> {
        return switchMap((id: string) =>
            this.observeItem$({id}).pipe(
                switchMap(() =>
                    this.tabState.becomesActive$.pipe(
                        startWith(undefined),
                        map(() => undefined as void),
                    ),
                ),
                switchMap(() =>
                    intervalBackoff({
                        initialInterval: 1_000,
                        backoffDelay: (index) => Math.pow(1.1, index) * 1_000,
                    }).pipe(
                        map(() => undefined as void),
                        takeUntil(this.tabState.becomesInactive$),
                        exhaustMap(() => refetch({id})),
                        takeWhile(keepFetchingWhile, true),
                        catchError((error) => {
                            console.error(error)
                            return EMPTY
                        }),
                    ),
                ),
            ),
        )
    }

    keepObservingAllWhileTabIsActive$<Item>(refetch: () => Promise<Item>, keepFetchingWhile: (item: Item) => boolean = () => true): Observable<Item> {
        return this.observeAll$.pipe(
            switchMap(() =>
                this.tabState.becomesActive$.pipe(
                    startWith(undefined),
                    map(() => undefined as void),
                ),
            ),
            switchMap(() =>
                intervalBackoff({
                    initialInterval: 1000,
                    backoffDelay: (index) => Math.pow(1.1, index) * 1000,
                }).pipe(
                    map(() => undefined as void),
                    takeUntil(this.tabState.becomesInactive$),
                    exhaustMap(() => refetch()),
                    takeWhile(keepFetchingWhile, true),
                ),
            ),
        )
    }

    // Will emit the original the item's id and content type whenever a refresh has been triggered
    // Purposely does not return any other properties, as those need to be reloaded by the component that owns the actual fetch method
    triggerWhenNeeded = switchMap(
        (
            item: {id: string; __typename: string} | undefined | null,
        ): Observable<
            | {
                  id: string
                  __typename: string
              }
            | null
            | undefined
        > => {
            if (item) {
                return this.observeItem$(item).pipe(map(() => item))
            }
            return of(item)
        },
    )
}
