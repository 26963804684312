import {getAll} from "@cm/graph"
import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeHsvToRgbNode, threeRGBColorNode, threeRgbToHsvNode, threeValueNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const adjustFnNode = new THREENodes.FunctionNode(`
vec3 adjust(vec3 hsvIn, float h, float s, float v) {
    vec3 hsvOut;
    hsvOut.x = mod(hsvIn.x + h + 0.5, 1.0);
    hsvOut.y = max(0.0, min(1.0, hsvIn.y * s));
    hsvOut.z = hsvIn.z * v;
    return hsvOut;
}
`)

const facAndClampFnNode = new THREENodes.FunctionNode(`
vec3 facAndClamp(vec3 rgbIn, vec3 rgbAdj, float f) {
    vec3 rgbOut;
    rgbOut.x = f * rgbAdj.x + (1.0 - f) * rgbIn.x;
    rgbOut.y = f * rgbAdj.y + (1.0 - f) * rgbIn.y;
    rgbOut.z = f * rgbAdj.z + (1.0 - f) * rgbIn.z;

    rgbOut.x = max(rgbOut.x, 0.0);
    rgbOut.y = max(rgbOut.y, 0.0);
    rgbOut.z = max(rgbOut.z, 0.0);

    return rgbOut;
}
`)

class HSVNode extends THREENodes.TempNode {
    constructor(
        public rgbInput: THREENodes.Node,
        public hue: THREENodes.Node,
        public saturation: THREENodes.Node,
        public value: THREENodes.Node,
        public fac: THREENodes.Node,
    ) {
        super("vec3")
    }

    override generate(builder: THREENodes.NodeBuilder) {
        const type = this.getNodeType(builder)

        const hsvInput = threeRgbToHsvNode(this.rgbInput)
        const hsvOutput = THREENodes.call(adjustFnNode, {
            hsvIn: hsvInput,
            h: this.hue,
            s: this.saturation,
            v: this.value,
        })
        const rgbOutput = THREENodes.call(facAndClampFnNode, {rgbIn: this.rgbInput, rgbAdj: threeHsvToRgbNode(hsvOutput), f: this.fac})
        return rgbOutput.build(builder, type)
    }
}

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({
    color: materialSlots.optional(),
    hue: materialSlots.optional(),
    saturation: materialSlots.optional(),
    value: materialSlots.optional(),
    fac: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    color: color.optional(),
    hue: z.number().optional(),
    saturation: z.number().optional(),
    value: z.number().optional(),
    fac: z.number().optional(),
})
export class HSV extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, hue, saturation, value, fac} = await getAll(inputs, get)
            const colorValue = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const hueValue = hue ?? threeConvert(parameters.hue, threeValueNode) ?? threeValueNode(1)
            const saturationValue = saturation ?? threeConvert(parameters.saturation, threeValueNode) ?? threeValueNode(1)
            const valueValue = value ?? threeConvert(parameters.value, threeValueNode) ?? threeValueNode(1)
            const facValue = fac ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(1)

            return {color: new HSVNode(colorValue, hueValue, saturationValue, valueValue, facValue)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
