import {JobNodes, makeJobTaskDescriptor} from "#job-nodes/job-nodes"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {z} from "zod"

export const Vec2Schema = z.tuple([z.number(), z.number()])
export const RGBColorSchema = z.tuple([z.number(), z.number(), z.number()])
export const RGBAColorSchema = z.tuple([z.number(), z.number(), z.number(), z.number()])

export const GeometrySchema = z.object({
    type: z.literal("geometry"),
    topology: z.literal("triangleList"),
    vertices: z.object({
        positions: z.array(Vec2Schema),
        uvs: z.array(Vec2Schema).optional(),
        colors: z.union([z.array(RGBColorSchema), z.array(RGBAColorSchema)]).optional(),
    }),
    indices: z.array(z.number()).optional(),
})

export const Base64DataSchema = z.object({
    type: z.literal("base64"),
    data: z.string(),
})

export const GeometryBase64Schema = z.object({
    type: z.literal("geometry-base64"),
    topology: z.literal("triangleList"),
    vertices: z.object({
        positions: Base64DataSchema,
        uvs: Base64DataSchema.optional(),
        colors: Base64DataSchema.optional(),
    }),
    indices: Base64DataSchema.optional(),
})

type ImageProcessingGraphInput = {
    graph: ImageProcessingNodes.Output
    graphDataObjectReference?: never
}

type ImageProcessingGraphDataObjectInput = {
    graph?: never
    graphDataObjectReference: JobNodes.DataObjectReference
}

export type ImageProcessingInput = ImageProcessingGraphInput | ImageProcessingGraphDataObjectInput

export type ImageProcessingOutput = any //JobNodes.DataObjectReference | string | [] | {} // TODO this return type should be typed (but it's tricky)

export const imageProcessingTask = makeJobTaskDescriptor<ImageProcessingInput, ImageProcessingOutput>("ImageProcessing.imageProcessing", 1)
