import {DeclareMaterialNode, materialSlots} from "#material-nodes/declare-material-node"
import {z} from "zod"
import {threeRGBColorNode, threeRgbToHsvNode} from "#material-nodes/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"

export class SeparateHSV extends DeclareMaterialNode(
    {
        returns: z.object({h: materialSlots, s: materialSlots, v: materialSlots}),
        inputs: z.object({image: materialSlots.optional()}),
        parameters: z.object({r: z.number().optional(), g: z.number().optional(), b: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const color = (await get(inputs.image)) ?? threeRGBColorNode({r: parameters.r ?? 0, g: parameters.g ?? 0, b: parameters.b ?? 0})
            const hsv = threeRgbToHsvNode(color)
            return {h: new THREENodes.SplitNode(hsv, "r"), s: new THREENodes.SplitNode(hsv, "g"), v: new THREENodes.SplitNode(hsv, "b")}
        },
    },
) {}
