import {DeclareMaterialNode, materialSlots} from "#material-nodes/declare-material-node"
import {threeHsvToRgbNode, threeValueNode} from "#material-nodes/three-utils"
import {getAll} from "@cm/graph/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

export class CombineHSV extends DeclareMaterialNode(
    {
        returns: z.object({image: materialSlots}),
        inputs: z.object({h: materialSlots.optional(), s: materialSlots.optional(), v: materialSlots.optional()}),
        parameters: z.object({h: z.number().optional(), s: z.number().optional(), v: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {h, s, v} = await getAll(inputs, get)
            const hValue = h ?? threeValueNode(parameters.h ?? 0)
            const sValue = s ?? threeValueNode(parameters.s ?? 0)
            const vValue = v ?? threeValueNode(parameters.v ?? 0)

            return {image: threeHsvToRgbNode(new THREENodes.JoinNode([hValue, sValue, vValue]))}
        },
    },
) {}
