import {assertNever} from "@cm/utils"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {assertSameSizeAndBatchSize} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {getImgProcChannelLayout} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {HalPainterBlendMode} from "@common/models/hal/hal-painter/types"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export type ParameterType = {
    backgroundImage: ImageRef
    foregroundImage: ImageRef
    alpha?: ImageRef | number // for alpha blending, if supplied this will be used instead of alpha channel of foregroundImage
    premultipliedAlpha?: boolean // default to true
    blendMode: "normal" | "add" | "multiply" | "darken" | "lighten" | "screen"
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

const imageOpBlend: ImageOpType<ParameterType, ReturnType> = {
    name: "Blend",

    WebGL2: ({cmdQueue, parameters: {backgroundImage, foregroundImage, alpha, premultipliedAlpha, blendMode, resultImageOrDataType}}) => {
        const useAlphaImage = alpha && typeof alpha !== "number"
        const alphaConst = typeof alpha === "number" ? alpha : undefined
        const alphaImage = useAlphaImage ? alpha : undefined
        assertSameSizeAndBatchSize(backgroundImage.descriptor, foregroundImage.descriptor)
        if (alphaImage) {
            assertSameSizeAndBatchSize(backgroundImage.descriptor, alphaImage.descriptor)
        }
        if (backgroundImage === resultImageOrDataType) {
            // use actual hardware alpha blending
            const painter = cmdQueue.createPainter(
                "compositor",
                "blend",
                `
                vec4 computeColor(ivec2 targetPixel) {
                    vec4 foreground = texelFetch0(targetPixel);
                    float alpha = ${
                        needsAlphaChannel(blendMode)
                            ? useAlphaImage
                                ? "texelFetch1(targetPixel).r"
                                : alphaConst !== undefined
                                  ? `float(${alphaConst})`
                                  : "foreground.a"
                            : "0.0"
                    };
                    return vec4(foreground.xyz, alpha);
                }
            `,
            )
            const sourceImages = needsAlphaChannel(blendMode) && alphaImage ? [foregroundImage, alphaImage] : [foregroundImage]
            cmdQueue.paint(painter, {
                sourceImages,
                options: {blendMode: getHalBlendMode(blendMode)},
                resultImage: backgroundImage,
            })
        } else {
            // emulate alpha blending in shader
            const blendFunc = (mode: ParameterType["blendMode"], premultipliedAlpha: boolean) => {
                switch (mode) {
                    case "normal":
                        return `background * (1.0 - alpha) + foreground${premultipliedAlpha ? "" : " * alpha"}`
                    case "add":
                        return "vec4(background.rgb + foreground.rgb, background.a)"
                    case "multiply":
                        return "background * foreground"
                    case "darken":
                        return "min(background, foreground)"
                    case "lighten":
                        return "max(background, foreground)"
                    case "screen":
                        return "background * (1.0 - foreground) + foreground"
                    default:
                        assertNever(mode)
                }
            }
            const painter = cmdQueue.createPainter(
                "compositor",
                "blend",
                `
                vec4 blendFunc(vec4 background, vec4 foreground, float alpha) {
                    return ${blendFunc(blendMode, premultipliedAlpha ?? true)};
                }

                vec4 computeColor(ivec2 targetPixel) {
                    vec4 background = texelFetch0(targetPixel);
                    vec4 foreground = texelFetch1(targetPixel);
                    float alpha = ${
                        needsAlphaChannel(blendMode)
                            ? useAlphaImage
                                ? "texelFetch2(targetPixel).r"
                                : alphaConst !== undefined
                                  ? `float(${alphaConst})`
                                  : "foreground.a"
                            : "0.0"
                    };
                    return blendFunc(background, foreground, alpha);
                }
            `,
            )
            resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, backgroundImage.descriptor)
            const sourceImages =
                needsAlphaChannel(blendMode) && alphaImage ? [backgroundImage, foregroundImage, alphaImage] : [backgroundImage, foregroundImage]
            cmdQueue.paint(painter, {
                sourceImages,
                resultImage: resultImageOrDataType,
            })
        }
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {backgroundImage, foregroundImage, alpha, premultipliedAlpha, blendMode: mode, resultImageOrDataType}}) => {
        const useAlphaImage = alpha && typeof alpha !== "number"
        const alphaConst = typeof alpha === "number" ? alpha : undefined
        const alphaImage = useAlphaImage ? alpha : undefined
        assertSameSizeAndBatchSize(backgroundImage.descriptor, foregroundImage.descriptor)
        if (alphaImage) {
            assertSameSizeAndBatchSize(backgroundImage.descriptor, alphaImage.descriptor)
        }
        let foregroundNode: ImageRef
        if (needsAlphaChannel(mode)) {
            premultipliedAlpha = premultipliedAlpha ?? true
            let nodeAlpha = alphaImage
            if (!nodeAlpha) {
                if (alphaConst !== undefined) {
                    nodeAlpha = cmdQueue.createImage(foregroundImage.descriptor, {
                        type: "math",
                        operation: "constLike",
                        firstInput: foregroundImage,
                        secondInput: alphaConst,
                    })
                } else {
                    if (foregroundImage.descriptor.channelLayout != "RGBA") {
                        throw new Error("Alpha channel neither supplied in foreground image nor in alpha image")
                    }
                    nodeAlpha = cmdQueue.createImage(
                        {
                            ...foregroundImage.descriptor,
                            channelLayout: "R",
                        },
                        {
                            type: "extractChannel",
                            input: foregroundImage,
                            channel: 3,
                        },
                    )
                }
            }
            if (nodeAlpha.descriptor.channelLayout !== "R") {
                nodeAlpha = cmdQueue.createImage(
                    {
                        ...nodeAlpha.descriptor,
                        channelLayout: "R",
                    },
                    {
                        type: "extractChannel",
                        input: nodeAlpha,
                        channel: 0,
                    },
                )
            }
            let nodeR = cmdQueue.createImage(
                {
                    ...foregroundImage.descriptor,
                    channelLayout: "R",
                },
                {
                    type: "extractChannel",
                    input: foregroundImage,
                    channel: 0,
                },
            )
            if (!premultipliedAlpha) {
                nodeR = cmdQueue.createImage(nodeR.descriptor, {
                    type: "math",
                    operation: "*",
                    firstInput: nodeR,
                    secondInput: nodeAlpha,
                })
            }
            if (foregroundImage.descriptor.channelLayout != "R") {
                let nodeG = cmdQueue.createImage(
                    {
                        ...foregroundImage.descriptor,
                        channelLayout: "R",
                    },
                    {
                        type: "extractChannel",
                        input: foregroundImage,
                        channel: 1,
                    },
                )
                if (!premultipliedAlpha) {
                    nodeG = cmdQueue.createImage(nodeG.descriptor, {
                        type: "math",
                        operation: "*",
                        firstInput: nodeG,
                        secondInput: nodeAlpha,
                    })
                }
                let nodeB = cmdQueue.createImage(
                    {
                        ...foregroundImage.descriptor,
                        channelLayout: "R",
                    },
                    {
                        type: "extractChannel",
                        input: foregroundImage,
                        channel: 2,
                    },
                )
                if (!premultipliedAlpha) {
                    nodeB = cmdQueue.createImage(nodeB.descriptor, {
                        type: "math",
                        operation: "*",
                        firstInput: nodeB,
                        secondInput: nodeAlpha,
                    })
                }
                foregroundNode = cmdQueue.createImage(
                    {...foregroundImage.descriptor, channelLayout: "RGBA"},
                    {
                        type: "combineChannels",
                        input: [nodeR, nodeG, nodeB, nodeAlpha],
                        channelLayout: "RGBA",
                    },
                )
            } else {
                foregroundNode = cmdQueue.createImage(
                    {...foregroundImage.descriptor, channelLayout: "RGBA"},
                    {
                        type: "combineChannels",
                        input: [nodeR, nodeR, nodeR, nodeAlpha],
                        channelLayout: "RGBA",
                    },
                )
            }
        } else {
            foregroundNode = foregroundImage
        }
        let resultNode = cmdQueue.createImage(backgroundImage.descriptor, {
            type: "blend",
            background: backgroundImage,
            foreground: foregroundNode,
            mode,
        })
        resultNode = cmdQueue.createImage(backgroundImage, {
            type: "convert",
            input: resultNode,
            channelLayout: getImgProcChannelLayout(backgroundImage.descriptor.channelLayout),
            dataType: backgroundImage.descriptor.dataType,
        }) // since the blend node always returns RGBA/float32 we need to convert to backgroundImage's format for consistent behaviour
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

function needsAlphaChannel(mode: ParameterType["blendMode"]) {
    return mode === "normal"
}

function getHalBlendMode(blendMode: ParameterType["blendMode"]): HalPainterBlendMode {
    switch (blendMode) {
        case "normal":
            return "normal"
        case "add":
            return "add"
        case "multiply":
            return "mul"
        case "darken":
            return "min"
        case "lighten":
            return "max"
        case "screen":
            return "screen"
        default:
            assertNever(blendMode)
    }
}

export function blend(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpBlend, parameters)
}
