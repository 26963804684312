import {DecimalPipe, NgStyle} from "@angular/common"
import {Component} from "@angular/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {TextureType} from "@api"
import {ImageTextureSetInputs, ImageTextureSetOutputs} from "@app/material-editor/models/nodes/image-texture-set"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {BaseImageTextureSetComponent} from "@app/material-editor/components/nodes/base-image-texture-set/base-image-texture-set.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-image-texture-set-node",
    templateUrl: "./image-texture-set-node.component.html",
    styleUrls: ["./image-texture-set-node.component.scss"],
    standalone: true,
    imports: [
        NumericInputComponent,
        InputContainerComponent,
        MatMenuModule,
        DecimalPipe,
        NodeBaseComponent,
        MatFormFieldModule,
        MatSelectModule,
        NgStyle,
        DataObjectThumbnailComponent,
    ],
})
export class ImageTextureSetNodeComponent extends BaseImageTextureSetComponent<typeof ImageTextureSetNodeComponent> {
    outputs = ImageTextureSetOutputs
    inputs = ImageTextureSetInputs
    typeInfo = ImageTextureSetNodeType

    async changeTextureSetRevision(revisionId: string | undefined) {
        await this.loadTextureSetRevision(revisionId)
        this.setupTextureInfo()
        this.setThumbnail()
        this.setTextureSetRevisionVersion(revisionId)
        this.updateNodeParameters(true)
    }

    async changeTextureSet(textureSetLegacyId: number | undefined) {
        await this.loadTextureSet(textureSetLegacyId)
        await this.changeTextureSetRevision(this.textureSetRevisionInfos?.[0].id)
    }

    async fromNodeParameters() {
        const textureSetRevisionId = this.nodeBase.getParameter("TextureSetRevisionId")
        if (!textureSetRevisionId) {
            this.resetParameters()
            this.updateNodeParameters(true)
            return
        }

        await this.loadTextureSetRevision(textureSetRevisionId)
        await this.loadTextureSet(this.textureSetRevision?.textureSet?.legacyId)
        this.setTextureSetRevisionVersion(textureSetRevisionId)

        this.updateNodeParameters(false)

        this.setThumbnail()
    }

    get hasTransmissionTexture() {
        return this.textureSetRevision?.mapAssignments.some((mapAssignment) => mapAssignment.textureType === TextureType.Transmission)
    }

    updateNodeParameters(applyDefaults: boolean) {
        this.nodeBase.setParameterNoUpdate("TextureSetRevisionId", this.textureSetRevision?.id, "string")
        this.nodeBase.node.textureSetRevision = this.textureSetRevision
            ? {
                  id: this.textureSetRevision.id,
                  width: this.textureSetRevision.width,
                  height: this.textureSetRevision.height,
                  displacement: this.textureSetRevision.displacement ?? undefined,
                  mapAssignments: this.textureSetRevision.mapAssignments.map((assignment) => ({
                      textureType: assignment.textureType,
                      dataObjectLegacyId: assignment.dataObject.legacyId,
                  })),
              }
            : undefined

        this.nodeBase.addParamIfMissing("NormalStrength", 1, "scalar")

        if (applyDefaults) {
            const hasTransmission = this.hasTransmissionTexture
            this.nodeBase.setParameterNoUpdate("TransmissionMin", hasTransmission ? 0.2 : undefined, "scalar")
            this.nodeBase.setParameterNoUpdate("TransmissionMax", hasTransmission ? 0.9 : undefined, "scalar")
        }

        // TODO what is the right way of adding missing parameters ?
        this.triggerGraphUpdate()
    }

    resetNodeParameters() {
        this.nodeBase.setParameterNoUpdate("TextureSetRevisionId", undefined, "string")
        // this.nodeBase.setParameterNoUpdate("NormalStrength", undefined, "scalar")
    }

    resetParameters() {
        this.textureSetRevision = undefined
        this.textureSetLegacyId = undefined
        this.textureSetRevisionInfos = undefined
        this.textureSetRevisionVersion = undefined
        this.thumbnailId = undefined
    }

    setThumbnail() {
        const mapToShowForThumbnail =
            this.textureSetRevision?.mapAssignments.find((mapAssignment) => mapAssignment.textureType === TextureType.Diffuse) ??
            this.textureSetRevision?.mapAssignments[0]
        this.thumbnailId = mapToShowForThumbnail?.dataObject.id
    }
}

export const ImageTextureSetNodeType: MaterialNodeType<typeof ImageTextureSetNodeComponent> = {
    id: "imageTextureSet",
    label: "Image Texture Set",
    color: "#9e6034",
    name: "ShaderNodeTextureSet",
    inputs: [ImageTextureSetInputs.uv, ImageTextureSetInputs.normalStrength],
    outputs: [
        ImageTextureSetOutputs.baseColor,
        ImageTextureSetOutputs.metallic,
        ImageTextureSetOutputs.specular,
        ImageTextureSetOutputs.roughness,
        ImageTextureSetOutputs.anisotropic,
        ImageTextureSetOutputs.anisotropicRotation,
        ImageTextureSetOutputs.alpha,
        ImageTextureSetOutputs.normal,
        ImageTextureSetOutputs.displacement,
        ImageTextureSetOutputs.transmission,
    ],
    component: ImageTextureSetNodeComponent,
}
