import {cyclesNode, DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getDefaultMaterial} from "#material-nodes/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode, threeValueNode, threeVec3Node} from "#material-nodes/three-utils"
import {color, vec3} from "#material-nodes/types"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnsTypeSchema = z.object({bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)})
const InputTypeSchema = z.object({color: materialSlots.optional(), roughness: materialSlots.optional(), normal: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional(), roughness: z.number().optional(), normal: vec3.optional()})

export class BsdfDiffuse extends (DeclareMaterialNode(
    {
        returns: ReturnsTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, normal} = await getAll(inputs, get)
            const material = getDefaultMaterial()

            material.colorNode = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? material.colorNode
            material.roughnessNode = threeValueNode(1) // Always 1 for Lambertian diffuse (Oren-Nayar not supported yet)

            material.normalNode =
                normal ?? threeConvert(parameters.normal, threeVec3Node, (val) => val.x !== 0 || val.y !== 0 || val.z !== 0) ?? material.normalNode

            return {bsdf: material}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnsTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
