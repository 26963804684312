// Ported from threejs orbit controls
/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 * @author ScieCode / http://github.com/sciecode
 */

import {Three as THREE} from "@cm/material-nodes/three"

enum STATE {
    NONE = -1,
    ROTATE = 0,
    DOLLY = 1,
    PAN = 2,
    TOUCH_ROTATE = 3,
    TOUCH_PAN = 4,
    TOUCH_DOLLY_PAN = 5,
    TOUCH_DOLLY_ROTATE = 6,
}

function isPerspectiveCamera(x: THREE.Object3D): x is THREE.PerspectiveCamera {
    return (x as any)?.isPerspectiveCamera
}

function isOrthographicCamera(x: THREE.Object3D): x is THREE.OrthographicCamera {
    return (x as any)?.isOrthographicCamera
}

export interface OrbitControlsEventMap {
    change: {}
    start: {}
    end: {}
}

export class OrbitControls extends THREE.EventDispatcher<OrbitControlsEventMap> {
    focused = true

    up = new THREE.Vector3(0, 1, 0)
    enabled = true
    minDistance = 0
    maxDistance = Infinity
    minZoom = 0
    maxZoom = Infinity
    minPolarAngle = 0
    maxPolarAngle = Math.PI
    minAzimuthAngle = -Infinity
    maxAzimuthAngle = Infinity
    enableZoom = true
    zoomSpeed = 1.0
    enableRotate = true
    rotateSpeed = 1.0
    enablePan = true
    panSpeed = 1.0
    screenSpacePanning = true
    keyPanSpeed = 7.0
    enableKeys = true
    keys = {LEFT: 37, UP: 38, RIGHT: 39, DOWN: 40}
    mouseButtons = {LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.DOLLY, RIGHT: THREE.MOUSE.PAN}
    touches = {ONE: THREE.TOUCH.ROTATE, TWO: THREE.TOUCH.DOLLY_PAN}

    private _object!: THREE.Object3D
    private _target = new THREE.Vector3()
    private _position = new THREE.Vector3()

    private state = STATE.NONE

    private lastPosition = new THREE.Vector3()
    private lastTarget = new THREE.Vector3()

    private deltaPhi = 0
    private deltaTheta = 0
    private deltaRadiusScale = 1
    private deltaTarget = new THREE.Vector3()
    private zoomChanged = false

    private rotateStart = new THREE.Vector2()
    private rotateEnd = new THREE.Vector2()
    private rotateDelta = new THREE.Vector2()

    private panStart = new THREE.Vector2()
    private panEnd = new THREE.Vector2()
    private panDelta = new THREE.Vector2()

    private dollyStart = new THREE.Vector2()
    private dollyEnd = new THREE.Vector2()
    private dollyDelta = new THREE.Vector2()

    private listenerCleanup: () => void

    constructor(private domElement: HTMLElement) {
        super()

        const listeners: [HTMLElement | Document, string, any][] = []
        const addListener = (targ: HTMLElement | Document, name: string, fn: any) => {
            listeners.push([targ, name, fn])
            domElement.addEventListener(name, fn, false)
        }

        addListener(domElement, "contextmenu", this.onContextMenu.bind(this))
        addListener(domElement, "mousedown", this.onMouseDown.bind(this))
        addListener(domElement, "wheel", this.onMouseWheel.bind(this))
        addListener(domElement, "touchstart", this.onTouchStart.bind(this))
        addListener(domElement, "touchend", this.onTouchEnd.bind(this))
        addListener(domElement, "touchmove", this.onTouchMove.bind(this))
        addListener(domElement, "keydown", this.onKeyDown.bind(this))
        addListener(domElement.ownerDocument, "mousemove", this.onMouseMove.bind(this))
        addListener(domElement.ownerDocument, "mouseup", this.onMouseUp.bind(this))

        this.listenerCleanup = () => {
            for (const [targ, name, fn] of listeners) {
                targ.removeEventListener(name, fn, false)
            }
        }

        // make sure element can receive keys.
        if (domElement.tabIndex === -1) {
            domElement.tabIndex = 0
        }

        this.update()
    }

    get position(): THREE.Vector3 {
        return this._position
    }

    set position(position: THREE.Vector3) {
        this._position.copy(position)
        this.object.position.copy(position)
        //TODO:
    }

    get target(): THREE.Vector3 {
        return this._target
    }

    set target(target: THREE.Vector3) {
        this._target.copy(target)
        this.object.lookAt(target)
        //TODO:
    }

    get object(): THREE.Object3D {
        return this._object
    }

    set object(object: THREE.Object3D) {
        this._object = object
    }

    // getPolarAngle() {
    //     return this.spherical.phi;
    // }

    // getAzimuthalAngle() {
    //     return this.spherical.theta;
    // }

    private update() {
        const offset = new THREE.Vector3()
        const twoPI = 2 * Math.PI
        const spherical = new THREE.Spherical()
        const position = this.position
        const target = this.target

        offset.copy(position).sub(target)

        const upQuat = new THREE.Quaternion().setFromUnitVectors(this.up, new THREE.Vector3(0, 1, 0))
        const upQuatInverse = new THREE.Quaternion().copy(upQuat).invert()
        // rotate offset to "y-axis-is-up" space
        offset.applyQuaternion(upQuat)

        // angle from z-axis around y-axis
        spherical.setFromVector3(offset)

        spherical.theta += this.deltaTheta
        spherical.phi += this.deltaPhi

        // restrict theta to be between desired limits
        let min = this.minAzimuthAngle
        let max = this.maxAzimuthAngle

        if (isFinite(min) && isFinite(max)) {
            if (min < -Math.PI) min += twoPI
            else if (min > Math.PI) min -= twoPI
            if (max < -Math.PI) max += twoPI
            else if (max > Math.PI) max -= twoPI
            if (min < max) {
                spherical.theta = Math.max(min, Math.min(max, spherical.theta))
            } else {
                spherical.theta = spherical.theta > (min + max) / 2 ? Math.max(min, spherical.theta) : Math.min(max, spherical.theta)
            }
        }

        // restrict phi to be between desired limits
        spherical.phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, spherical.phi))
        spherical.makeSafe()

        spherical.radius *= this.deltaRadiusScale
        // restrict radius to be between desired limits
        spherical.radius = Math.max(this.minDistance, Math.min(this.maxDistance, spherical.radius))

        offset.setFromSpherical(spherical)
        // rotate offset back to "camera-up-vector-is-up" space
        offset.applyQuaternion(upQuatInverse)

        // move target to panned location
        target.add(this.deltaTarget)

        position.copy(target).add(offset)

        if (this.object) {
            this.object.position.copy(position)
            this.object.lookAt(target)
        }

        this.deltaTheta = 0
        this.deltaPhi = 0
        this.deltaTarget.set(0, 0, 0)
        this.deltaRadiusScale = 1

        if (this.zoomChanged || !this.lastPosition.equals(position) || !this.lastTarget.equals(target)) {
            this.lastPosition.copy(position)
            this.lastTarget.copy(target)
            this.zoomChanged = false

            this.dispatchEvent({type: "change"})

            return true
        }

        return false
    }

    dispose() {
        this.listenerCleanup()
    }

    zoomIn(amount: number): void {
        if (!this.enableZoom) {
            return
        }

        this.dispatchEvent({type: "start"})
        this.dollyIn(amount)
        this.dispatchEvent({type: "end"})

        this.update()
    }

    zoomOut(amount: number): void {
        if (!this.enableZoom) {
            return
        }

        this.dispatchEvent({type: "start"})
        this.dollyOut(amount)
        this.dispatchEvent({type: "end"})

        this.update()
    }

    private getZoomScale() {
        return Math.pow(0.95, this.zoomSpeed)
    }

    private rotateLeft(angle: number) {
        this.deltaTheta -= angle
    }

    private rotateUp(angle: number) {
        this.deltaPhi -= angle
    }

    private panLeft(distance: number) {
        const v = new THREE.Vector3()
        v.copy(this.target)
        v.sub(this.position)
        v.cross(this.up)
        v.normalize()
        v.multiplyScalar(-distance)
        this.deltaTarget.add(v)
    }

    private panUp(distance: number) {
        const v = new THREE.Vector3()
        v.copy(this.target)
        v.sub(this.position)
        if (this.screenSpacePanning === true) {
            const fwd = new THREE.Vector3()
            fwd.copy(v)
            v.cross(this.up)
            v.cross(fwd)
        }
        v.normalize()
        v.multiplyScalar(distance)
        this.deltaTarget.add(v)
    }

    // deltaX and deltaY are in pixels; right and down are positive
    private pan(deltaX: number, deltaY: number) {
        const offset = new THREE.Vector3()
        const element = this.domElement
        if (isPerspectiveCamera(this.object)) {
            // perspective
            const position = this.position
            offset.copy(position).sub(this.target)
            let targetDistance = offset.length()

            // half of the fov is center to top of screen
            targetDistance *= Math.tan(((this.object.fov / 2) * Math.PI) / 180.0)

            // we use only clientHeight here so aspect ratio does not distort speed
            this.panLeft((2 * deltaX * targetDistance) / element.clientHeight)
            this.panUp((2 * deltaY * targetDistance) / element.clientHeight)
        } else if (isOrthographicCamera(this.object)) {
            // orthographic
            this.panLeft((deltaX * (this.object.right - this.object.left)) / this.object.zoom / element.clientWidth)
            this.panUp((deltaY * (this.object.top - this.object.bottom)) / this.object.zoom / element.clientHeight)
        } else {
            // camera neither orthographic nor perspective
            console.warn("WARNING: OrbitControls.js encountered an unknown camera type")
        }
    }

    private dollyOut(dollyScale: number) {
        if (isOrthographicCamera(this.object)) {
            this.object.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.object.zoom * dollyScale))
            this.object.updateProjectionMatrix()
            this.zoomChanged = true
        } else {
            this.deltaRadiusScale /= dollyScale
        }
    }

    private dollyIn(dollyScale: number) {
        if (isOrthographicCamera(this.object)) {
            this.object.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.object.zoom / dollyScale))
            this.object.updateProjectionMatrix()
            this.zoomChanged = true
        } else {
            this.deltaRadiusScale *= dollyScale
        }
    }

    private handleMouseMoveRotate(event: MouseEvent) {
        event.preventDefault()
        this.rotateEnd.set(event.clientX, event.clientY)
        this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart).multiplyScalar(this.rotateSpeed)
        const element = this.domElement
        this.rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight) // yes, height
        this.rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight)
        this.rotateStart.copy(this.rotateEnd)
        this.update()
    }

    private handleMouseMoveDolly(event: MouseEvent) {
        event.preventDefault()
        this.dollyEnd.set(event.clientX, event.clientY)
        this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart)
        if (this.dollyDelta.y > 0) {
            this.dollyOut(this.getZoomScale())
        } else if (this.dollyDelta.y < 0) {
            this.dollyIn(this.getZoomScale())
        }
        this.dollyStart.copy(this.dollyEnd)
        this.update()
    }

    private handleMouseMovePan(event: MouseEvent) {
        event.preventDefault()
        this.panEnd.set(event.clientX, event.clientY)
        this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.panSpeed)
        this.pan(this.panDelta.x, this.panDelta.y)
        this.panStart.copy(this.panEnd)
        this.update()
    }

    private handleTouchStartRotate(event: TouchEvent) {
        if (event.touches.length == 1) {
            this.rotateStart.set(event.touches[0].pageX, event.touches[0].pageY)
        } else {
            const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX)
            const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY)

            this.rotateStart.set(x, y)
        }
    }

    private handleTouchStartPan(event: TouchEvent) {
        if (event.touches.length == 1) {
            this.panStart.set(event.touches[0].pageX, event.touches[0].pageY)
        } else {
            const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX)
            const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY)

            this.panStart.set(x, y)
        }
    }

    private handleTouchStartDolly(event: TouchEvent) {
        const dx = event.touches[0].pageX - event.touches[1].pageX
        const dy = event.touches[0].pageY - event.touches[1].pageY

        const distance = Math.sqrt(dx * dx + dy * dy)

        this.dollyStart.set(0, distance)
    }

    private handleTouchMoveRotate(event: TouchEvent) {
        if (event.touches.length == 1) {
            this.rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY)
        } else {
            const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX)
            const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY)

            this.rotateEnd.set(x, y)
        }

        this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart).multiplyScalar(this.rotateSpeed)

        const element = this.domElement

        this.rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight) // yes, height

        this.rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight)

        this.rotateStart.copy(this.rotateEnd)
    }

    private handleTouchMovePan(event: TouchEvent) {
        if (event.touches.length == 1) {
            this.panEnd.set(event.touches[0].pageX, event.touches[0].pageY)
        } else {
            const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX)
            const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY)

            this.panEnd.set(x, y)
        }

        this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.panSpeed)

        this.pan(this.panDelta.x, this.panDelta.y)

        this.panStart.copy(this.panEnd)
    }

    private handleTouchMoveDolly(event: TouchEvent) {
        const dx = event.touches[0].pageX - event.touches[1].pageX
        const dy = event.touches[0].pageY - event.touches[1].pageY
        const distance = Math.sqrt(dx * dx + dy * dy)
        this.dollyEnd.set(0, distance)
        this.dollyDelta.set(0, Math.pow(this.dollyEnd.y / this.dollyStart.y, this.zoomSpeed))
        this.dollyOut(this.dollyDelta.y)
        this.dollyStart.copy(this.dollyEnd)
    }

    private onMouseDown(event: MouseEvent) {
        if (this.enabled === false) return

        // Prevent the browser from scrolling.
        event.preventDefault()

        // Manually set the focus since calling preventDefault above
        // prevents the browser from setting it automatically.

        this.domElement.focus ? this.domElement.focus() : window.focus()

        let mouseAction: THREE.MOUSE | -1

        switch (event.button) {
            case 0:
                mouseAction = this.mouseButtons.LEFT
                break

            case 1:
                mouseAction = this.mouseButtons.MIDDLE
                break

            case 2:
                mouseAction = this.mouseButtons.RIGHT
                break

            default:
                mouseAction = -1
        }

        switch (mouseAction) {
            case THREE.MOUSE.DOLLY:
                if (this.enableZoom === false) return

                this.dollyStart.set(event.clientX, event.clientY)

                this.state = STATE.DOLLY

                break

            case THREE.MOUSE.ROTATE:
                if (event.ctrlKey || event.metaKey || event.shiftKey) {
                    if (this.enablePan === false) return

                    this.panStart.set(event.clientX, event.clientY)

                    this.state = STATE.PAN
                } else {
                    if (this.enableRotate === false) return

                    this.rotateStart.set(event.clientX, event.clientY)

                    this.state = STATE.ROTATE
                }

                break

            case THREE.MOUSE.PAN:
                if (event.ctrlKey || event.metaKey || event.shiftKey) {
                    if (this.enableRotate === false) return

                    this.rotateStart.set(event.clientX, event.clientY)

                    this.state = STATE.ROTATE
                } else {
                    if (this.enablePan === false) return

                    this.panStart.set(event.clientX, event.clientY)

                    this.state = STATE.PAN
                }

                break

            default:
                this.state = STATE.NONE
        }

        if (this.state !== STATE.NONE) {
            this.dispatchEvent({type: "start"})
        }
    }

    private onMouseMove(event: MouseEvent) {
        if (this.enabled === false) return

        switch (this.state) {
            case STATE.ROTATE:
                if (this.enableRotate === false) return
                this.handleMouseMoveRotate(event)
                break

            case STATE.DOLLY:
                if (this.enableZoom === false) return
                this.handleMouseMoveDolly(event)
                break

            case STATE.PAN:
                if (this.enablePan === false) return
                this.handleMouseMovePan(event)
                break
        }
    }

    private onMouseUp(event: MouseEvent) {
        if (this.state === STATE.NONE) return
        this.dispatchEvent({type: "end"})
        this.state = STATE.NONE
    }

    private onMouseWheel(event: WheelEvent) {
        if (!this.focused || !this.enabled || !this.enableZoom || (this.state !== STATE.NONE && this.state !== STATE.ROTATE)) return

        event.preventDefault()
        event.stopPropagation()

        this.dispatchEvent({type: "start"})

        if (event.deltaY < 0) {
            this.dollyIn(this.getZoomScale())
        } else if (event.deltaY > 0) {
            this.dollyOut(this.getZoomScale())
        }
        this.update()

        this.dispatchEvent({type: "end"})
    }

    private onKeyDown(event: KeyboardEvent) {
        if (this.enabled === false || this.enableKeys === false || this.enablePan === false) return

        let needsUpdate = true

        switch (event.keyCode) {
            case this.keys.UP:
                this.pan(0, this.keyPanSpeed)
                break

            case this.keys.DOWN:
                this.pan(0, -this.keyPanSpeed)
                break

            case this.keys.LEFT:
                this.pan(this.keyPanSpeed, 0)
                break

            case this.keys.RIGHT:
                this.pan(-this.keyPanSpeed, 0)
                break

            default:
                needsUpdate = false
                break
        }

        if (needsUpdate) {
            // prevent the browser from scrolling on cursor keys
            event.preventDefault()
            this.update()
        }
    }

    private onTouchStart(event: TouchEvent) {
        if (this.enabled === false) return

        event.preventDefault() // prevent scrolling

        switch (event.touches.length) {
            case 1:
                switch (this.touches.ONE) {
                    case THREE.TOUCH.ROTATE:
                        if (this.enableRotate === false) return

                        this.handleTouchStartRotate(event)

                        this.state = STATE.TOUCH_ROTATE

                        break

                    case THREE.TOUCH.PAN:
                        if (this.enablePan === false) return

                        this.handleTouchStartPan(event)

                        this.state = STATE.TOUCH_PAN

                        break

                    default:
                        this.state = STATE.NONE
                }

                break

            case 2:
                switch (this.touches.TWO) {
                    case THREE.TOUCH.DOLLY_PAN:
                        if (this.enableZoom === false && this.enablePan === false) return
                        if (this.enableZoom) this.handleTouchStartDolly(event)
                        if (this.enablePan) this.handleTouchStartPan(event)

                        this.state = STATE.TOUCH_DOLLY_PAN

                        break

                    case THREE.TOUCH.DOLLY_ROTATE:
                        if (this.enableZoom === false && this.enableRotate === false) return

                        if (this.enableZoom) this.handleTouchStartDolly(event)
                        if (this.enableRotate) this.handleTouchStartRotate(event)

                        this.state = STATE.TOUCH_DOLLY_ROTATE

                        break

                    default:
                        this.state = STATE.NONE
                }

                break

            default:
                this.state = STATE.NONE
        }

        if (this.state !== STATE.NONE) {
            this.dispatchEvent({type: "start"})
        }
    }

    private onTouchMove(event: TouchEvent) {
        if (this.enabled === false) return

        event.preventDefault() // prevent scrolling
        event.stopPropagation()

        switch (this.state) {
            case STATE.TOUCH_ROTATE:
                if (this.enableRotate === false) return
                this.handleTouchMoveRotate(event)
                this.update()

                break

            case STATE.TOUCH_PAN:
                if (this.enablePan === false) return
                this.handleTouchMovePan(event)
                this.update()
                break

            case STATE.TOUCH_DOLLY_PAN:
                if (this.enableZoom === false && this.enablePan === false) return
                if (this.enableZoom) this.handleTouchMoveDolly(event)
                if (this.enablePan) this.handleTouchMovePan(event)
                this.update()
                break

            case STATE.TOUCH_DOLLY_ROTATE:
                if (this.enableZoom === false && this.enableRotate === false) return
                if (this.enableZoom) this.handleTouchMoveDolly(event)
                if (this.enableRotate) this.handleTouchMoveRotate(event)
                this.update()
                break

            default:
                this.state = STATE.NONE
                break
        }
    }

    private onTouchEnd(event: TouchEvent) {
        if (this.enabled === false) return
        this.dispatchEvent({type: "end"})
        this.state = STATE.NONE
    }

    private onContextMenu(event: TouchEvent) {
        if (this.enabled === false) return
        event.preventDefault()
    }
}
