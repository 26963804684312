import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeVec3Node} from "#material-nodes/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({x: materialSlots, y: materialSlots, z: materialSlots})
const InputTypeSchema = z.object({vector: materialSlots.optional()})
const ParametersTypeSchema = z.object({x: z.number().optional(), y: z.number().optional(), z: z.number().optional()})

export class SeparateXYZ extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const vector = (await get(inputs.vector)) ?? threeVec3Node({x: parameters.x ?? 0, y: parameters.y ?? 0, z: parameters.z ?? 0})
            return {x: new THREENodes.SplitNode(vector, "x"), y: new THREENodes.SplitNode(vector, "y"), z: new THREENodes.SplitNode(vector, "z")}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
