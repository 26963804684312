import {MaterialSocket} from "@material-editor/models/material-socket"

export const SeparateHsvInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Image", type: "input", valueType: "color", label: "Image"},
}

export const SeparateHsvOutputs: Record<string, MaterialSocket> = {
    h: {paramType: "socket", id: "H", type: "output", valueType: "output", label: "H"},
    s: {paramType: "socket", id: "S", type: "output", valueType: "output", label: "S"},
    v: {paramType: "socket", id: "V", type: "output", valueType: "output", label: "V"},
}
