import {Component} from "@angular/core"

@Component({
    selector: "cm-table-layout",
    standalone: true,
    imports: [],
    templateUrl: "./table-layout.component.html",
    styleUrl: "./table-layout.component.scss",
})
export class TableLayoutComponent {}
