import {HalPaintable} from "@common/models/hal/hal-paintable"
import {ColorLike} from "@cm/math"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"

export async function clearPaintable(paintable: HalPaintable, color?: ColorLike): Promise<void> {
    const context = paintable.context
    if (!(context instanceof WebGl2Context)) {
        throw Error("Invalid context")
    }
    const gl = context.gl
    gl.clearColor(color?.r ?? 0, color?.g ?? 0, color?.b ?? 0, color?.a ?? 0)
    const region = {x: 0, y: 0, width: paintable.width, height: paintable.height}
    const numPasses = paintable.getNumDrawPasses(region)
    for (let pass = 0; pass < numPasses; pass++) {
        paintable.setDrawPass(region, pass)
        gl.clear(gl.COLOR_BUFFER_BIT)
    }
}
