<cm-node-base #nodeBase [typeInfo]="typeInfo" [inputs]="inputs" [outputs]="outputs">
    <ng-container cm-settings>
        <div class="cm-thumbnail-container">
            <cm-data-object-thumbnail [dataObjectId]="thumbnailId" [layout]="ThumbnailLayout.Cover"></cm-data-object-thumbnail>
            <i #textureIcon [ngStyle]="{visibility: thumbnailId ? 'visible' : 'hidden'}" class="cm-thumbnail-info far fa-circle-info"></i>
        </div>
        @if (isProcessing) {
            <div class="cm-revision-draft-only">
                <i class="far fa-warning"></i>
                Processing not completed !
            </div>
        }
        <cm-input-container>
            <ng-container cm-inline-label> ID:</ng-container>
            <ng-container cm-input>
                <div class="cm-texture-id-input">
                    <cm-numeric-input
                        [value]="textureSetLegacyId"
                        (mousewheel)="$any($event.target).blur()"
                        (valueChange)="changeTextureSet($event ?? undefined)"
                        [placeholder]="'Texture-set ID'"
                    >
                    </cm-numeric-input>
                </div>
            </ng-container>
        </cm-input-container>
        @if (textureSetRevision && (textureSetRevisionInfos?.length ?? 0) > 1) {
            <cm-input-container>
                <ng-container cm-inline-label>Rev.</ng-container>
                <ng-container cm-input>
                    <div [matMenuTriggerFor]="revisionMenu" class="cm-texture-revision">
                        {{ getRevisionTitle(textureSetRevision, textureSetRevisionVersion) }}
                    </div>
                    <mat-menu #revisionMenu="matMenu">
                        @for (revision of textureSetRevisionInfos; track revision) {
                            <button mat-menu-item (click)="changeTextureSetRevision(revision.id)">
                                {{ getRevisionTitle(revision, (textureSetRevisionInfos?.length ?? 0) - $index) }}
                            </button>
                        }
                    </mat-menu>
                </ng-container>
            </cm-input-container>
        }

        @if (hasTransmissionTexture) {
            <cm-input-container>
                <ng-container cm-inline-label>Opaque thr.:</ng-container>
                <ng-container cm-input>
                    <div class="cm-texture-id-input">
                        <cm-numeric-input
                            [value]="nodeBase.getParameter('TransmissionMin')"
                            (valueChange)="nodeBase.setParameter('TransmissionMin', $event, 'scalar')"
                        >
                        </cm-numeric-input>
                    </div>
                </ng-container>
            </cm-input-container>

            <cm-input-container>
                <ng-container cm-inline-label>Transparent thr.:</ng-container>
                <ng-container cm-input>
                    <div class="cm-texture-id-input">
                        <cm-numeric-input
                            [value]="nodeBase.getParameter('TransmissionMax')"
                            (valueChange)="nodeBase.setParameter('TransmissionMax', $event, 'scalar')"
                        >
                        </cm-numeric-input>
                    </div>
                </ng-container>
            </cm-input-container>
        }
    </ng-container>
</cm-node-base>
<div #textureInfoTemplate id="textureInfo" class="cm-texture-details">
    @if (textureSetRevision) {
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Width:</div>
            <div class="cm-texture-detail-value">{{ textureSetRevision.width | number: "1.1-3" }} cm</div>
        </div>
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Height:</div>
            <div class="cm-texture-detail-value">{{ textureSetRevision.height | number: "1.1-3" }} cm</div>
        </div>
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Displacement:</div>
            <div class="cm-texture-detail-value">
                {{ textureSetRevision.displacement != null ? (textureSetRevision.displacement | number: "1.1-3") + " cm" : "Not set" }}
            </div>
        </div>
    }
</div>
