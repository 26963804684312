import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeValueNode} from "#material-nodes/three-utils"
import {getAll} from "@cm/graph/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({fac: materialSlots})
const InputTypeSchema = z.object({ior: materialSlots.optional(), normal: materialSlots.optional()})
const ParametersTypeSchema = z.object({ior: z.number().optional(), fac: z.number().optional()})

export class Fresnel extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {ior} = await getAll(inputs, get)

            const iorValue = ior ?? threeConvert(parameters.ior, threeValueNode)
            const facValue = threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(0)

            if (!iorValue) return {fac: facValue}

            const n1 = threeValueNode(1.0)
            const r0_sqrt = THREENodes.div(THREENodes.sub(n1, iorValue), THREENodes.add(n1, iorValue))
            const r0 = THREENodes.mul(r0_sqrt, r0_sqrt)
            const NoV = new THREENodes.SplitNode(THREENodes.normalView, "z")
            const oneMinusNoV = THREENodes.sub(threeValueNode(1.0), NoV)
            const oneMinusNoV_2 = THREENodes.mul(oneMinusNoV, oneMinusNoV)
            const oneMinusNoV_3 = THREENodes.mul(oneMinusNoV_2, oneMinusNoV)
            const oneMinusNoV_5 = THREENodes.mul(oneMinusNoV_2, oneMinusNoV_3)
            const tmp1 = THREENodes.mul(THREENodes.sub(n1, r0), oneMinusNoV_5)
            const value = THREENodes.add(r0, tmp1)

            return {fac: value}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
