import {MaterialSocket} from "@material-editor/models/material-socket"

export const ValToRgbOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Value"},
}

export const ValToRgbInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "color", label: "Color"},
    alpha: {paramType: "socket", id: "Alpha", type: "input", valueType: "scalar", label: "Alpha"},
    fac: {paramType: "socket", id: "Fac", type: "input", valueType: "scalar", label: "Fac"},
}
