<div class="cm-navigation-bar-container">
    <div class="cm-navigation-bar" [style.background-color]="backgroundColor">
        <!--Large screen navigation-->
        <div class="cm-navigation-links-large-screen">
            @if (auth.$user()) {
            <a routerLink="/pictures" [queryParams]="{tab: 'projects', organizationId: this.organizations.$current()?.id}">
                <ng-container *ngTemplateOutlet="navigationLogo"></ng-container>
            </a>
            } @else {
            <a routerLink="/login">
                <ng-container *ngTemplateOutlet="navigationLogo"></ng-container>
            </a>
            }
            <ng-container *ngTemplateOutlet="navigationLinks"></ng-container>
        </div>
        <!--Small screen navigation-->
        <a class="cm-navigation-links-small-screen" (click)="sidenavOpen = !sidenavOpen">
            <ng-container *ngTemplateOutlet="navigationLogo"></ng-container>
        </a>
        <!--User-->
        @if (auth.$user()) {
        <div class="cm-right-container">
            <!-- Environment string -->
            @if (environment.style.navigationBar.label) {
            <span class="cm-environment-name">{{ environment.style.navigationBar.label }}</span>
            } @if ($can().update.menu("online")) {
            <cm-toggle [toggle]="navigationIsToggled" (toggleChange)="navigationIsToggled = !navigationIsToggled"></cm-toggle>
            }

            <div class="cm-user-menu" mat-button [cdkMenuTriggerFor]="menu">
                @if (sdk.$silo(); as activeSilo) {
                <span> {{ activeSilo.label }} </span>
                }
                <i class="far fa-chevron-down"></i>
            </div>

            <ng-template #menu>
                <div class="cm-overlay-container" cdkMenu>
                    <div class="cm-user-details">
                        <cm-user-thumbnail class="cm-user-icon-image" size="medium" [$user]="auth.$user()"></cm-user-thumbnail>
                        <div class="cm-user-details-name">
                            {{ auth.$user()?.name ?? "" }} @if (sdk.$silo(); as activeMembership) { ({{ activeMembership.label }}) }
                        </div>
                        <div class="cm-user-details-email">{{ auth.$user()?.email }}</div>
                    </div>
                    @if ($can().read.menu("authenticateAsStaff")) {
                    <button [cdkMenuTriggerFor]="systemRolesMenu" cdkMenuItem class="cm-user-menu-button-reset cm-user-menu-button-nested cm-user-menu-item">
                        <i class="fa-light fa-chevron-left"></i><span>System roles</span>
                    </button>
                    } @if (showOrganizationSwitcher) {
                    <button [cdkMenuTriggerFor]="organizationsMenu" cdkMenuItem class="cm-user-menu-button-reset cm-user-menu-button-nested cm-user-menu-item">
                        <i class="fa-light fa-chevron-left"></i><span>Organizations</span>
                    </button>
                    } @if ($can().read.menu("authenticateAsStaff") || showOrganizationSwitcher) {
                    <cm-line></cm-line>
                    } @if ($can().read.menu('scanning')) {
                    <a routerLink="/scanning" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-aperture cm-user-menu-icon"></i>
                        <span>Scanning</span>
                    </a>
                    } @if ($can().read.menu('organizations')) {
                    <a routerLink="/organizations" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-building cm-user-menu-icon"></i>
                        <span>Organizations</span>
                    </a>
                    } @if ($can().read.menu('users')) {
                    <a routerLink="/users" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-user-friends cm-user-menu-icon"></i>
                        <span>Users</span>
                    </a>
                    } @if ($can().read.menu('hdris')) {
                    <a routerLink="/hdris" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-lightbulb cm-user-menu-icon"></i>
                        <span>HDRIs</span>
                    </a>
                    } @if ($can().read.menu('jobs')) {
                    <a routerLink="/jobs" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-gears cm-user-menu-icon"></i>
                        <span>Jobs</span>
                    </a>
                    } @if ($can().read.menu('jobFarmInstances')) {
                    <a routerLink="/render-nodes" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-server cm-user-menu-icon"></i>
                        <span>Render Nodes</span>
                    </a>
                    } @if ($can().read.menu('jobFarmGroups')) {
                    <a routerLink="/render-node-groups" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-network-wired cm-user-menu-icon"></i>
                        <span>Render Node Groups</span>
                    </a>
                    } @if ($can().read.menu('taskTypes')) {
                    <a routerLink="/task-types" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-diagram-project cm-user-menu-icon"></i>
                        <span>Task Types</span>
                    </a>
                    } @if ($can().read.menu('tags')) {
                    <a
                        routerLink="/tags"
                        class="cm-user-menu-item"
                        cdkMenuItem
                        [queryParams]="!sdk.$silo()?.systemRole && organizations.$current() ? {organizationId: organizations.$current()?.id} : undefined"
                    >
                        <i class="far fa-tag cm-user-menu-icon"></i>
                        <span>Tags</span>
                    </a>
                    } @if ($can().read.menu('files')) {
                    <a [queryParams]="{isRelated: 0}" routerLink="/files" class="cm-user-menu-item" cdkMenuItem>
                        <i class="far fa-file cm-user-menu-icon"></i>
                        <span>Files</span>
                    </a>
                    }
                    <a cdkMenuItem (click)="auth.logOut()" routerLink="/login" class="cm-user-menu-item cm-log-out">
                        <i class="far fa-sign-out cm-user-menu-icon"></i>
                        <span>Log out</span>
                    </a>
                </div>
            </ng-template>
        </div>
        }
    </div>
</div>
<ng-template #navigationLogo>
    <div class="cm-logo-container">
        <img src="../../../../../assets/images/colormass-logo-cm-4.svg" />
    </div>
</ng-template>
<ng-template #navigationLinks>
    @if (auth.$user()) {
    <!--Online Links: Photographers, Fabric Manufacturers, Staff (with toggle on)-->
    @if ($can().read.menu('onlineLinks') && (!$can().update.menu("online") || navigationIsToggled)) {
    <ng-container *ngTemplateOutlet="templates; context: {title: 'Products', link: 'products'}"></ng-container>
    <ng-container *ngTemplateOutlet="materials"></ng-container>
    <ng-container *ngTemplateOutlet="templates; context: {title:  'Scenes', link: 'scenes'}"></ng-container>
    <ng-container *ngTemplateOutlet="pictures; context: {title: 'Pictures'}"></ng-container>
    } @else {
    <ng-container *ngTemplateOutlet="models"></ng-container>
    <ng-container *ngTemplateOutlet="materials"></ng-container>
    <ng-container *ngTemplateOutlet="assets"></ng-container>
    <ng-container *ngTemplateOutlet="pictures"></ng-container>
    }

    <!--Offline Links: Staff (with toggle off), regular customer accounts-->
    <ng-template #templates let-title="title" let-link="link">
        <cm-navigation-item [link]="[link ?? 'templates']" [active]="selectedLink === (link ?? 'templates')" [filterForCustomer]="true">
            <ng-container cm-title> {{ title ? title : 'Templates' }}</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #models>
        <cm-navigation-item [link]="['models']" [active]="selectedLink === 'models'" [filterForCustomer]="true">
            <ng-container cm-title> Models</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #materials>
        <cm-navigation-item [link]="['materials']" [active]="selectedLink === 'materials'" [filterForCustomer]="true">
            <ng-container cm-title> Materials</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #assets>
        <cm-navigation-item [title]="'Assets'" [link]="['assets']" [active]="selectedLink === 'assets'" [filterForCustomer]="true">
            <ng-container cm-title> Assets</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #pictures let-title="title">
        <cm-navigation-item [link]="['pictures']" [active]="selectedLink === 'pictures'" [filterForCustomer]="true">
            <ng-container cm-title> {{ title ? title : 'Pictures' }}</ng-container>
        </cm-navigation-item>
    </ng-template>

    <!--Misc-->
    @if ($can().read.menu("statistics")) {
    <cm-navigation-item [link]="['statistics']" [active]="selectedLink === 'statistics'">
        <ng-container cm-title> Statistics</ng-container>
    </cm-navigation-item>
    } @if (($can().read.menu('users')) && selectedLink === 'users') {
    <cm-navigation-item [link]="['users']" [active]="selectedLink === 'users'">
        <ng-container cm-title> Users</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('files') && selectedLink === 'files') {
    <cm-navigation-item [link]="['files']" [params]="{isRelated: 0}" [active]="selectedLink === 'files'">
        <ng-container cm-title> Files</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('hdris') && selectedLink === 'hdris') {
    <cm-navigation-item [link]="['hdris']" [active]="selectedLink === 'hdris'">
        <ng-container cm-title> HDRIs</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('jobs') && selectedLink === 'jobs') {
    <cm-navigation-item [link]="['jobs']" [active]="selectedLink === 'jobs'">
        <ng-container cm-title> Jobs</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('jobFarmInstances') && selectedLink === 'render-nodes') {
    <cm-navigation-item [link]="['render-nodes']" [active]="selectedLink === 'render-nodes'">
        <ng-container cm-title> Render Nodes</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('jobFarmGroups') && selectedLink === 'render-node-groups') {
    <cm-navigation-item [link]="['render-node-groups']" [active]="selectedLink === 'render-node-groups'">
        <ng-container cm-title> Render Node Groups</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('organizations') && selectedLink === 'organizations') {
    <cm-navigation-item [link]="['organizations']" [active]="selectedLink === 'organizations'">
        <ng-container cm-title> Organizations</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('scanning') && selectedLink === 'scanning') {
    <cm-navigation-item [link]="['scanning']" [active]="selectedLink === 'scanning'">
        <ng-container cm-title> Scanning</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('tags') && selectedLink === 'tags') {
    <cm-navigation-item [link]="['tags']" [active]="selectedLink === 'tags'">
        <ng-container cm-title> Tags</ng-container>
    </cm-navigation-item>
    } @if ($can().read.menu('taskTypes') && selectedLink === 'task-types') {
    <cm-navigation-item [link]="['taskTypes']" [active]="selectedLink === 'task-types'">
        <ng-container cm-title> Task types</ng-container>
    </cm-navigation-item>
    } }
</ng-template>
<cm-sidenav #sidenav [sidenavOpen]="sidenavOpen && !isBigScreen" (backgroundClick)="sidenavOpen = false">
    <ng-container cm-content>
        @if (!isBigScreen) {
        <div (click)="sidenavOpen = !sidenavOpen" class="cm-navigation-bar-sidenav">
            <ng-container *ngTemplateOutlet="navigationLinks"></ng-container>
        </div>
        }
    </ng-container>
</cm-sidenav>
<ng-template #systemRolesMenu>
    <div class="cm-overlay-container" cdkMenu>
        @if ($can().read.menu("authenticateAsStaff")) {
        <button cdkMenuItem (click)="activateSystemRole(SystemRole.Staff)" class="cm-user-menu-button-reset cm-user-menu-item">
            <span [class.cm-menu-item-selected]="sdk.$silo()?.systemRole === SystemRole.Staff">Staff</span>
        </button>
        } @if ($can().read.menu("authenticateAsSuperadmin")) {
        <button cdkMenuItem (click)="activateSystemRole(SystemRole.Superadmin)" class="cm-user-menu-button-reset cm-user-menu-item">
            <span [class.cm-menu-item-selected]="sdk.$silo()?.systemRole === SystemRole.Superadmin">Superadmin</span>
        </button>
        }
    </div>
</ng-template>
<ng-template #organizationsMenu>
    <div class="cm-overlay-container" cdkMenu>
        @for (organization of allOrganizations; track organization.id) {
        <button cdkMenuItem (click)="activateOrganization(organization)" class="cm-user-menu-button-reset cm-user-menu-item">
            <div class="cm-organization-menu-item-container">
                <div [class.cm-menu-item-selected]="sdk.$silo()?.organization?.id === organization.id" class="cm-organization-menu-item">
                    <span>{{ organization.name }}</span>
                    @if (auth.isStaff()) {
                    <span class="cm-organization-type">{{ organization.type }}</span>
                    }
                </div>
            </div>
        </button>
        }
    </div>
</ng-template>
