<cm-node-base #nodeBase [outputs]="outputs" [typeInfo]="typeInfo">
    <ng-container cm-settings>
        <cm-input-container>
            <ng-container cm-inline-label> Channel:</ng-container>
            <ng-container cm-input>
                <div class="cm-texture-id-input">
                    <cm-numeric-input [value]="uvChannel" (valueChange)="changeUvChannel($event ?? undefined)" [placeholder]="'UV Channel'"> </cm-numeric-input>
                </div>
            </ng-container> </cm-input-container
    ></ng-container>
</cm-node-base>
