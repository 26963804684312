import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode, colorValue} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {z} from "zod"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {ThisStructID} from "#template-nodes/runtime-graph/types"

export const textureResolution = z.enum(["500px", "1000px", "2000px", "original"])
export type TextureResolution = z.infer<typeof textureResolution>

const environmentMapMode = z.enum(["full", "specularOnly"])
export type EnvironmentMapMode = z.infer<typeof environmentMapMode>

const uiStyle = z.enum(["default", "icons", "hidden", "accordion"])
export type UiStyle = z.infer<typeof uiStyle>

const accordionStyle = z.enum(["autoClose", "manualClose"])
export type AccordionStyle = z.infer<typeof accordionStyle>

const scenePropertiesParameters = z.object({
    maxSubdivisionLevel: z.number(),
    maxSubdivisionLevelOnMobile: z.number().optional(),
    backgroundColor: colorValue.optional(),
    uiColor: colorValue,
    uiStyle: uiStyle,
    iconSize: z.number(),
    enableAr: z.boolean(),
    enableSalesEnquiry: z.boolean(),
    textureResolution: textureResolution,
    textureFiltering: z.boolean(),
    enableRealtimeShadows: z.boolean(),
    enableRealtimeLights: z.boolean(),
    enableRealtimeMaterials: z.boolean(),
    realtimeShadowMapOptions: z
        .object({
            resolution: z.number(),
            outerUpdateIterations: z.number(),
            innerUpdateIterations: z.number(),
            outerSmoothIterations: z.number(),
            innerSmoothIterations: z.number(),
        })
        .optional(),
    enableOnboardingHint: z.boolean(),
    enableGltfDownload: z.boolean(),
    enableStlDownload: z.boolean(),
    enablePdfGeneration: z.boolean(),
    enableSnapshot: z.boolean(),
    enableFullscreen: z.boolean(),
    environmentMapMode: environmentMapMode,
    showAnnotations: z.boolean(),
    shadowCatcherFalloff: z
        .object({
            sizeX: z.number(),
            sizeZ: z.number(),
            smoothness: z.number(),
            opacity: z.number(),
        })
        .optional(),
    enableAdaptiveSubdivision: z.boolean(),
})

export type ScenePropertiesParameters = z.infer<typeof scenePropertiesParameters>

@registerNode
export class SceneProperties extends DeclareTemplateNode(
    {parameters: scenePropertiesParameters},
    {
        onVisited: {
            onFilterActive: function (this: SceneProperties, {visit, context, parameters}) {
                const {onVisitedSceneProperties} = context

                if (!onVisitedSceneProperties(this)) return skipped

                return visitNone(parameters)
            },
            onCompile: function (this: SceneProperties, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {preDisplayList} = currentTemplate
                const {templateScope} = evaluator

                const {
                    backgroundColor,
                    textureResolution,
                    textureFiltering,
                    environmentMapMode,
                    shadowCatcherFalloff,
                    enableAdaptiveSubdivision,
                    enableRealtimeShadows,
                    enableRealtimeLights,
                    enableRealtimeMaterials,
                    realtimeShadowMapOptions,
                } = parameters

                const sceneOptions = templateScope.struct<SceneNodes.SceneOptions>("SceneOptions", {
                    type: "SceneOptions",
                    id: ThisStructID,
                    backgroundColor: backgroundColor ?? undefined,
                    textureResolution,
                    textureFiltering,
                    environmentMapMode,
                    shadowCatcherFalloff,
                    enableAdaptiveSubdivision,
                    enableRealtimeShadows,
                    enableRealtimeLights,
                    enableRealtimeMaterials,
                    realtimeShadowMapOptions,
                })
                preDisplayList.push(sceneOptions)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "SceneProperties"},
) {}

export type ScenePropertiesFwd = TemplateNode<ScenePropertiesParameters>
