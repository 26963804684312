<cm-inspector-section>
    <ng-container cm-title> Angle</ng-container>
    <ng-container cm-content>
        <div class="cm-angles">
            <cm-button class="cm-angle" (click)="setEdited()" [disabled]="disabled">
                <ng-container cm-text>Set Edited</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>
@for (parameter of parameters; track parameter; let parameterIndex = $index) {
    <cm-inspector-section>
        <ng-container cm-title>{{ parameter.name }}</ng-container>
        <ng-container cm-content>
            @if (parameter.type === "number") {
                <cm-slider
                    [min]="parameter.min"
                    [max]="parameter.max"
                    [exponent]="parameter.exponent"
                    [displayValuePrecision]="2"
                    [value]="parameter.value"
                    (valueChange)="setParameterValue(parameterIndex, $event)"
                ></cm-slider>
            } @else if (parameter.type === "boolean") {
                <cm-toggle [toggle]="parameter.value" (toggleChange)="setParameterValue(parameterIndex, $event)">
                    <ng-container cm-text>{{ parameter.name }}</ng-container>
                </cm-toggle>
            } @else if (parameter.type === "enum") {
                <mat-form-field>
                    <mat-label>{{ parameter.name }}</mat-label>
                    <mat-select [value]="parameter.value" (valueChange)="setParameterValue(parameterIndex, $event)">
                        @for (option of parameter.options; track option) {
                            <mat-option [value]="option">{{ option }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }
        </ng-container>
    </cm-inspector-section>
}
