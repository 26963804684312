import {Component, Input, OnDestroy, ViewChild} from "@angular/core"
import {LoadingSpinnerComponent} from "@common/components/progress/loading-spinner/loading-spinner.component"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {RenderOutputViewerControlsComponent} from "@editor/components/render-output-viewer-controls/render-output-viewer-controls.component"
import {DataObject, ImageColorSpaces} from "@legacy/api-model/data-object"
import {ImageProcessingService} from "@common/services/rendering/image-processing.service"
import {PictureRenderJobOutput} from "@cm/job-nodes/rendering"
import {isCryptomattePass} from "@cm/render-nodes"
import {JobNodes} from "@cm/job-nodes"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {postProcessingGraph, PostProcessingInputData, PostProcessingSettings} from "@cm/image-processing/render-post-processing"
import {CryptomatteId, CryptomatteManifest} from "@cm/image-processing/matte-processing"
import {filter, finalize, map, Observable, of as observableOf, Subject, switchMap, takeUntil} from "rxjs"
import {CanvasBaseComponent} from "@common/components/canvas/canvas-base/canvas-base.component"
import {join} from "@legacy/helpers/utils"
import {debounceFunction} from "@cm/utils"

// rxjs operator: PictureRenderJobOutput -> PostProcessingInputData
@Component({
    selector: "cm-render-output-viewer",
    templateUrl: "./render-output-viewer.component.html",
    styleUrls: ["./render-output-viewer.component.scss"],
    standalone: true,
    imports: [RenderOutputViewerControlsComponent, CanvasBaseComponent, LoadingSpinnerComponent],
})
export class RenderOutputViewerComponent implements OnDestroy {
    @ViewChild("canvasBase", {static: true}) canvasBase!: CanvasBaseComponent

    private _renderJobOutput?: PictureRenderJobOutput
    @Input() set renderJobOutput(jobOutput: PictureRenderJobOutput | undefined) {
        this._renderJobOutput = jobOutput
        this.loadData()
    }

    @Input() set settings(settings: PostProcessingSettings | undefined) {
        const needMasks = !!settings?.masks
        this._settings = settings
        if (needMasks !== this.needMasks) {
            this.needMasks = needMasks
            this.loadData()
        } else {
            this.updatePreview()
        }
    }

    private _selectedMask?: CryptomatteId
    @Input() set selectedMask(selectedMask: CryptomatteId | undefined) {
        this._selectedMask = selectedMask
        this.updatePreview()
    }

    _dataObject?: DataObject
    @Input() set dataObject(value: DataObject | undefined) {
        this._dataObject = value
        if (!value) return
        this.canvasBase.imageUrl = value.getJpegUrl()
    }

    imageData?: ImageData
    private isFirstPreview = true
    private needUpdate = false
    private maskOverlayColor = [1, 0.2, 0.2] as const

    private maskOverlayAlpha = 0.5
    loading = false
    processing = false
    private inputData?: PostProcessingInputData
    private unsubscribe = new Subject<void>()

    private needMasks = false
    private _settings?: PostProcessingSettings = {
        exposure: 1,
        whiteBalance: 6500,
        toneMapping: {mode: "linear"},
        lutUrl: undefined,
        transparent: false,
        composite: false,
        backgroundColor: [1, 1, 1] as const,
        processShadows: true,
        shadowInner: 0,
        shadowOuter: 0,
        shadowFalloff: 1,
        autoCrop: false,
        autoCropMargin: 50,
    }

    constructor(
        private imageProcessingSvc: ImageProcessingService,
        hotkeys: Hotkeys,
    ) {
        // register hotkeys
        hotkeys
            .addShortcut("Space")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const zoomLevel: number = this.canvasBase.navigation.getZoomLevel()
                // This is needed because of the rounding errors, since the zoom level at 100% is often 0.99999999 instead of 1.
                const epsilon: number = Math.abs(zoomLevel - 1)
                if (epsilon < 0.01) {
                    this.canvasBase.navigation.zoomToFitImage()
                } else {
                    this.canvasBase.navigation.zoomTo(1)
                }
            })
    }

    canvasLoadingComplete() {
        if (this.isFirstPreview) {
            this.isFirstPreview = false
            this.canvasBase.navigation.zoomToFitImage()
        }
    }

    private loadData = debounceFunction(() => {
        if (!this._renderJobOutput) {
            this.loading = false
            this.inputData = undefined
        } else {
            this.loading = true
            observableOf({jobOutput: this._renderJobOutput, needMasks: this.needMasks})
                .pipe(
                    filter(
                        (x) =>
                            (x.jobOutput.renderPasses !== null && x.jobOutput.renderPasses !== undefined) ||
                            (x.jobOutput.preview !== null && x.jobOutput.preview !== undefined),
                    ),
                    mapRenderOutputToPostProcessingInput({imageProcessingSvc: this.imageProcessingSvc}),
                    map((inputData) => {
                        this.inputData = inputData
                        this.updatePreview()
                    }),
                    finalize(() => (this.loading = false)),
                )
                .subscribe()
        }
    })

    private updatePreview() {
        const runUpdate = (update: Observable<ImageData | null>) => {
            this.processing = true
            update.subscribe((imageData) => {
                if (imageData) {
                    this.processing = false
                    this.canvasBase.imageData = imageData
                    this.imageData = imageData
                    if (this.needUpdate) {
                        this.needUpdate = false
                        this.updatePreview()
                    }
                }
            })
        }

        if (this.processing) {
            this.needUpdate = true
        } else if (this.inputData) {
            let {image, selectedMask: mask} = postProcessingGraph(this.inputData, this._settings ?? {}, this._selectedMask)
            if (mask) {
                image = {
                    type: "blend",
                    mode: "normal",
                    amount: this.maskOverlayAlpha,
                    background: image,
                    foreground: {
                        type: "applyMask",
                        input: {
                            type: "sRGB",
                            color: this.maskOverlayColor,
                        },
                        mask,
                    },
                }
            }
            image = {
                type: "convert",
                dataType: "uint8",
                channelLayout: "RGBA",
                sRGB: true,
                input: image,
            }
            runUpdate(this.imageProcessingSvc.evalGraph(image).pipe(switchMap((evaled) => this.imageProcessingSvc.toCanvasImageData(evaled.image))))
        } else {
            runUpdate(observableOf(null))
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}

export function mapRenderOutputToPostProcessingInput(options?: {imageProcessingSvc?: ImageProcessingService; resolve?: boolean}) {
    let loadImageFromRef: (ref: JobNodes.DataObjectReference) => Observable<ImageProcessingNodes.ImageNode>
    if (options?.imageProcessingSvc) {
        loadImageFromRef = (ref) => {
            return DataObject.get(ref.dataObjectId).pipe(
                switchMap((dataObject) => dataObject.download()),
                switchMap((fileData) => options.imageProcessingSvc!.decodeEXR(fileData!)),
                map((image) => ({type: "input", image})),
            )
        }
    } else if (options?.resolve) {
        loadImageFromRef = (ref) => {
            return DataObject.get(ref.dataObjectId).pipe(
                switchMap((dataObject) => join([dataObject.download(), observableOf(dataObject)])),
                map(([data, dataObject]) => {
                    const {imageColorSpace} = dataObject
                    if (imageColorSpace !== ImageColorSpaces.Linear && imageColorSpace !== ImageColorSpaces.sRgb) {
                        throw Error(`Cannot resolve external data of unsupported color space: ${imageColorSpace}`)
                    }
                    return {
                        type: "decode",
                        input: {
                            type: "externalData",
                            resolveTo: "encodedData",
                            sourceData: ref,
                            resolvedData: {
                                data: data!,
                                mediaType: dataObject.contentType,
                                colorSpace: imageColorSpace === ImageColorSpaces.Linear ? "linear" : "sRGB",
                            },
                        },
                    }
                }),
            )
        }
    } else {
        loadImageFromRef = (ref) => {
            return observableOf({type: "decode", input: {type: "externalData", resolveTo: "encodedData", sourceData: ref}})
        }
    }

    const loadJSONFromRef = (ref: JobNodes.DataObjectReference): Observable<any> => {
        return DataObject.get(ref.dataObjectId).pipe(switchMap((dataObject) => dataObject.downloadJSON()))
    }

    return (input$: Observable<{jobOutput: PictureRenderJobOutput; needMasks: boolean}>) =>
        input$.pipe(
            switchMap(({jobOutput, needMasks}) => {
                let usePreview = false
                if (!jobOutput.renderPasses) {
                    if (!jobOutput.preview) {
                        throw Error("Could not find preview image for render job")
                    }
                    usePreview = true
                }

                console.log("Loading render output")

                return join({
                    combinedPass: usePreview ? loadImageFromRef(jobOutput.preview!) : loadImageFromRef(jobOutput.renderPasses!["Combined"]!),
                    shadowCatcherPass:
                        usePreview || !("ShadowCatcher" in jobOutput.renderPasses!)
                            ? observableOf(null)
                            : loadImageFromRef(jobOutput.renderPasses!["ShadowCatcher"]!),
                    shadowMaskPass: usePreview || !jobOutput.aoShadowMaskPass ? observableOf(null) : loadImageFromRef(jobOutput.aoShadowMaskPass),
                    maskData:
                        usePreview || !needMasks
                            ? observableOf(undefined)
                            : (() => {
                                  if (!jobOutput.metadata) throw Error("Job output has no render metadata")

                                  //NOTE: all cryptomatte passes use a common ID space, so the list order and type (material, asset, etc.) does not matter

                                  const pendingCryptoPasses: ReturnType<typeof loadImageFromRef>[] = []
                                  for (const [passName, dataObjectRef] of Object.entries(jobOutput.renderPasses!)) {
                                      if (!isCryptomattePass(passName)) continue
                                      pendingCryptoPasses.push(loadImageFromRef(dataObjectRef!))
                                  }

                                  if (pendingCryptoPasses.length === 0) return observableOf(undefined)

                                  return join({
                                      cryptoPasses: join(pendingCryptoPasses),
                                      cryptoManifest: loadJSONFromRef(jobOutput.metadata).pipe(
                                          map((metadata) => metadata["cryptomatteManifest"] as CryptomatteManifest),
                                      ),
                                  })
                              })(),
                })
            }),
        )
}
