import {Component, computed, DestroyRef, inject, input} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ListInfoTagFragment, ListInfoUserFragment, NextActor, PaymentState} from "@api"
import {initializeMenuItems, MenuItem} from "@app/common/components/menu/multi-level-menu/menu-item"
import {MultiLevelMenu} from "@app/common/components/menu/multi-level-menu/multi-level-menu.component"
import {BatchDownloadOperation} from "@app/common/models/background-operation/batch-download-dataobject"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {BackgroundOperationService} from "@app/platform/services/background-operation/background-operation.service"
import {IsDefined, IsNonNull} from "@cm/utils"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {GenericItemList} from "@common/models/item/generic-item-list"
import {AdvancedAction, BatchDownloadData, BatchMenuItem, BatchUpdateProperty, emptyBatchEditInfo} from "@common/models/item/list-item"
import {AuthService} from "@common/services/auth/auth.service"
import {Labels, StateLabel} from "@labels"
import {firstValueFrom} from "rxjs"

@Component({
    selector: "cm-list-info",
    templateUrl: "./list-info.component.html",
    styleUrls: ["./list-info.component.scss"],
    standalone: true,
    imports: [MatTooltipModule, MatMenuModule, MatProgressBarModule, MultiLevelMenu, PlaceholderComponent],
})
export class ListInfoComponent<EntityType extends {id: string; legacyId: number}> {
    $count = input<number>(0, {alias: "count"})
    $parentList = input<GenericItemList<EntityType> | undefined>(undefined, {alias: "parentList"})
    $stateLabels = input<StateLabel<string>[]>([], {alias: "stateLabels"})
    $allowStandardUpdates = input<boolean>(false, {alias: "allowStandardUpdates"}) //Updates, that are always available: state, payment state, up next, assign user, add/remove tag
    $extraBatchActions = input<BatchMenuItem<EntityType>[]>([], {alias: "extraBatchActions"})
    $advancedActions = input<AdvancedAction[]>([], {alias: "advancedActions"})
    $batchDownloadData = input<BatchDownloadData<EntityType>[]>([], {alias: "batchDownloadData"})
    $createItem = input<(() => void) | undefined>(undefined, {alias: "createItem"})

    $downloadMenuItems = computed(() => initializeMenuItems(this.$batchDownloadData()))
    $showBatchButton = computed(() => {
        return this.$allowStandardUpdates() || this.$extraBatchActions().length > 0 || this.$batchDownloadData().length > 0
    })

    organizations = inject(OrganizationsService)

    maxBatchEditNr = 200
    maxBatchDownloadNr = this.maxBatchEditNr
    paymentStateLabels: StateLabel<PaymentState>[]
    nextActorLabels: StateLabel<NextActor>[]
    batchEditInfo = emptyBatchEditInfo
    visibleTags: ListInfoTagFragment[] | null | undefined = null
    visibleUsers: ListInfoUserFragment[] | null | undefined = null

    constructor(
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        protected destroyRef: DestroyRef,
        protected sdk: SdkService,
        protected organizationsService: OrganizationsService,
        protected backgroundOperationService: BackgroundOperationService,
        protected notifications: NotificationsService,
        protected authService: AuthService,
    ) {
        this.paymentStateLabels = Array.from(Labels.PaymentState.values())
        this.nextActorLabels = Array.from(Labels.NextActor.values())
    }

    loadUsersAndTags = () => {
        if (this.visibleTags === null) {
            this.visibleTags = undefined
            this.sdk.gql
                .listInfoVisibleTags({filter: this.authService.isStaff() ? {} : {organizationId: {in: this.organizations.$own()?.map(({id}) => id)}}})
                .then(({tags}) => {
                    this.visibleTags = tags.filter(IsDefined)
                })
                .catch((error) => {
                    this.visibleTags = null
                    throw error
                })
        }
        if (this.visibleUsers === null) {
            this.visibleUsers = undefined
            this.sdk.gql
                .listInfoVisibleUsers()
                .then(({users}) => {
                    this.visibleUsers = users.filter(IsDefined)
                })
                .catch((error) => {
                    this.visibleUsers = null
                    throw error
                })
        }
    }

    public batchUpdate = async (property: BatchUpdateProperty, value: string | boolean) => {
        const parentList = this.$parentList()
        if (!parentList || !parentList.batchUpdate) throw Error("No parentList or batchUpdate function is set")

        const confirmed = await this.confirmBatchEdit(this.$count())
        if (confirmed) return parentList.batchUpdate(property, value)
        else return undefined
    }

    private collectDownloadedDataObjects = async (data: BatchDownloadData<EntityType>): Promise<Map<string, number>> => {
        const downloadedDataObjects = new Map<string, number>()

        const batchItemsResult = await this.$parentList()?.getItems(0, this.maxBatchEditNr)
        const batchItems = batchItemsResult ? batchItemsResult.items : []

        const promises = batchItems.filter(IsNonNull).map((item) =>
            data
                .getDataObjectsForDownload(item)
                .then((paths) => {
                    paths.forEach(({path, dataObjectLegacyId}) => {
                        if (downloadedDataObjects.has(path)) throw new Error(`Duplicate path: ${path}`)
                        downloadedDataObjects.set(path, dataObjectLegacyId)
                    })
                })
                .catch((error) => {
                    console.log("Could not get data for download, ", error)
                }),
        )

        await Promise.allSettled(promises)

        return downloadedDataObjects
    }

    public startDownload = async (menuItem: MenuItem) => {
        const data = menuItem.context as BatchDownloadData<EntityType>

        const downloadedDataObjects = await this.collectDownloadedDataObjects(data)
        if (downloadedDataObjects.size === 0) {
            this.notifications.showError("No data for download found. Was everything created?")
            return
        }

        const confirmed = await this.confirmBatchDownload(downloadedDataObjects.size, this.$count())
        if (!confirmed) return

        const organizationDetails = await this.organizationsService.current
        if (!organizationDetails) throw new Error("Organization not found")

        const description = data.pathInMenu.replace(/\//g, ", ")

        const organizationWithLegacyId = await this.sdk.gql.listInfoOrganizationLegacyId({id: organizationDetails.id})

        const downloadOperation = new BatchDownloadOperation(description, description, this.sdk, organizationWithLegacyId.organization.legacyId)
        this.backgroundOperationService.registerBackgroundOperation(downloadOperation)

        downloadOperation.startOperation(downloadedDataObjects)
    }

    private confirmBatchDownload = (foundItems: number, allItems: number) => {
        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Batch download",
                message:
                    foundItems === allItems
                        ? `You are downloading ${foundItems} items.`
                        : `Not all data available. Do you want to download ${foundItems} of ${allItems} items?`,
                confirmLabel: "Submit",
                cancelLabel: "Cancel",
            },
        })
        return firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
    }

    private confirmBatchEdit = (count: number) => {
        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Batch edit",
                message: `You are submitting ${count} edits.<br>Are you sure you want to continue?`,
                confirmLabel: "Submit",
                cancelLabel: "Cancel",
            },
        })
        return firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
    }

    getBatchItems = async () => {
        if (this.$count() <= this.maxBatchEditNr) {
            const confirmed = await this.confirmBatchEdit(this.$count())
            if (confirmed) {
                const parentList = this.$parentList()
                if (!parentList) throw Error("No parentList is set")
                return (await parentList.getItems(0, this.maxBatchEditNr)).items
            }
        }

        return undefined
    }

    batchProcessSingleItems = async (operation: (item: EntityType) => Promise<boolean>, text?: string) => {
        if (this.batchEditInfo.running) throw new Error("Batch edit already running.")

        const parentList = this.$parentList()
        if (!parentList) throw Error("No parentList is set")

        this.batchEditInfo = {
            ...emptyBatchEditInfo,
            running: true,
        }

        try {
            const batchItems = await this.getBatchItems()

            if (batchItems === undefined) {
                return
            }

            this.batchEditInfo = {
                ...this.batchEditInfo,
                total: batchItems.length,
            }

            for (const item of batchItems.filter(IsNonNull)) {
                try {
                    const result = await operation(item)
                    if (result === false)
                        this.batchEditInfo = {
                            ...this.batchEditInfo,
                            numSkipped: this.batchEditInfo.numSkipped + 1,
                        }
                    else {
                        this.batchEditInfo = {
                            ...this.batchEditInfo,
                            numSucceeded: this.batchEditInfo.numSucceeded + 1,
                        }
                        await parentList.refreshItem(item)
                    }
                } catch (err) {
                    console.error(`Failed batch item:`, {item, error: err})
                    this.batchEditInfo = {
                        ...this.batchEditInfo,
                        numErrored: this.batchEditInfo.numErrored + 1,
                    }
                }
            }

            const {numSucceeded, numSkipped, numErrored} = this.batchEditInfo
            const snackBarText = `${numSucceeded} ${text ?? "changes saved"}${numSkipped > 0 ? ` (${numSkipped} skipped)` : ""}.${
                numErrored > 0 ? ` ${numErrored} failed (see console for details).` : ""
            }`
            this.snackBar.open(snackBarText, numErrored > 0 ? "Dismiss" : "", numErrored > 0 ? undefined : {duration: 3000})
        } finally {
            this.batchEditInfo = emptyBatchEditInfo
        }
    }

    readonly Labels = Labels
}
