import {KeyValuePipe} from "@angular/common"
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {AuthService} from "@common/services/auth/auth.service"
import {AnyStateType, StateLabel} from "@platform/models/state-labels/state-label"
import {UtilsService} from "@legacy/helpers/utils"
import {AssetState, MaterialState, ModelState, PictureState, TaskState, TemplateState} from "@api"

@Component({
    selector: "cm-state-label",
    templateUrl: "./state-label.component.html",
    styleUrls: ["./state-label.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatMenuModule, KeyValuePipe],
})
export class StateLabelComponent<StateType extends AnyStateType> {
    @Input({required: true}) state!: StateType
    @Input({required: true}) stateLabels!: Map<StateType, StateLabel<StateType>>
    @Input() editable: boolean = false
    @Input() backgroundColor?: string
    @Input() disableCompleted = false
    @Output() stateChange: EventEmitter<StateType> = new EventEmitter<StateType>()

    constructor(
        public auth: AuthService,
        public utils: UtilsService,
    ) {}

    public get data() {
        return this.stateLabels?.get(this.state)
    }

    public get isCompleted() {
        return (
            this.state === AssetState.Completed ||
            this.state === ModelState.Completed ||
            this.state === MaterialState.Completed ||
            this.state === TaskState.Archived ||
            this.state === TemplateState.Completed ||
            this.state === PictureState.Completed
        )
    }

    onStateSelected(state: StateType) {
        this.stateChange.emit(state)
    }
}
