import {map, Subscription} from "rxjs"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {RenderNodes} from "@cm/render-nodes"
import {commonGeometryGraphTable} from "#template-nodes/geometry-processing/common-geometry-graphs"
import {packStandardGeometryAttributes} from "#template-nodes/geometry-processing/geometry-graph"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"

const TD = TypeDescriptors

function getGeometryGraph(geometryGraph: any, parameters: any): RenderNodes.MeshData | null {
    const fn = commonGeometryGraphTable[geometryGraph]
    return fn ? {type: "geomGraph", graph: packStandardGeometryAttributes(fn(parameters ?? {}))} : null
}

const generateMeshDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    graphPresetName: TD.inlet(TD.String),
    parameters: TD.inlet(TD.JSON()),
    meshData: TD.outlet(TD.MeshData),
}

export class GenerateMesh implements NodeClassImpl<typeof generateMeshDescriptor, typeof GenerateMesh> {
    static descriptor = generateMeshDescriptor
    static uniqueName = "GenerateMesh"
    sceneManager!: Inlet<ISceneManager>
    graphPresetName!: Inlet<string>
    parameters: Inlet<any>
    meshData!: Outlet<MeshData>

    private _pending: Subscription | null = null

    run() {
        this._pending?.unsubscribe()
        this.meshData.emitIfChanged(NotReady)
        if (this.sceneManager === NotReady || this.graphPresetName === NotReady || this.parameters === NotReady) {
            return
        }
        const graph = getGeometryGraph(this.graphPresetName, this.parameters)
        if (graph) {
            this._pending = this.sceneManager.addTask(
                this.sceneManager.generateProceduralMeshData(graph).pipe(
                    map((meshData) => {
                        this._pending = null
                        this.meshData.emitIfChanged(meshData)
                    }),
                ),
            )
        } else {
            console.warn(`Invalid proceduralMesh graph name: '${this.graphPresetName}'`)
        }
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
