<div class="cm-entity-add cm-list-info" (click)="openDialog()">
    <i class="far fa-plus"></i>
    Add item
</div>
<ng-template #newItemDialog let-data>
    <h2 mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content [style.width]="'350px'">
        <ng-container [ngTemplateOutlet]="formTemplate ?? null" [ngTemplateOutletContext]="{item: data.name}"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button color="primary" [mat-dialog-close]="data" [disabled]="!canConfirm(data)">Create</button>
    </mat-dialog-actions>
</ng-template>
