import {AsyncPipe} from "@angular/common"
import {AfterViewInit, Component, computed, EventEmitter, inject, Input, OnInit, Output, signal, ViewChild} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterLink} from "@angular/router"
import {TagFilterInput, TagType} from "@api"
import {IsDefined} from "@cm/utils"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {FilterListComponent} from "@common/components/filters/filter-list/filter-list.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {AuthService} from "@common/services/auth/auth.service"
import {FiltersService} from "@common/services/filters/filters.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Labels, StateLabel} from "@labels"

@Component({
    selector: "cm-tag-search-filter",
    templateUrl: "./tag-search-filter.component.html",
    styleUrls: ["./tag-search-filter.component.scss"],
    standalone: true,
    imports: [MatTooltipModule, FilterListComponent, DropdownComponent, SearchComponent, RouterLink, AsyncPipe, PlaceholderComponent],
})
export class TagSearchFilterComponent implements OnInit, AfterViewInit {
    @Input() selectableTagTypes: TagType[] = []
    @Input() placeholder = "Search..."
    @Output() change = new EventEmitter<string>()

    @ViewChild(SearchComponent) searchComponent?: SearchComponent

    public focus = false
    private $searchText = signal("")
    public searchText$ = toObservable(this.$searchText)
    public $filteredTags = computed(() =>
        (this.$visibleTags() ?? [])
            .filter((tag) => tag.label.toLowerCase().search(this.$searchText().toLowerCase()) != -1)
            .filter((tag) => !(this.$selectedTags() ?? []).includes(tag))
            .slice(0, 5),
    )
    public $filteredOrganizations = computed(() => {
        if (this.$can().read.menu("filterByOrganization")) {
            return (this.$visibleOrganizations() ?? [])
                .filter(
                    (option) =>
                        option.label.toLowerCase().search(this.$searchText().toLowerCase()) != -1 &&
                        (this.$selectedOrganizations() ?? []).every((selectedOrganization) => option.state !== selectedOrganization.state),
                )
                .slice(0, 2)
        } else {
            return []
        }
    })
    public $selectedTagIds = signal<string[]>([])
    public $selectedTags = computed(() => (this.$visibleTags() ?? []).filter((tag) => this.$selectedTagIds().includes(tag.state)))
    public $selectedOrganizationIds = signal<string[]>([])
    public $selectedOrganizations = computed(() =>
        (this.$visibleOrganizations() ?? []).filter((organization) => this.$selectedOrganizationIds().includes(organization.state)),
    )

    public $showLoadingPlaceholders = computed(() => {
        return this.$visibleTags() === null || this.$visibleOrganizations() === null
    })

    private auth = inject(AuthService)
    private filtersService = inject(FiltersService)
    protected permission = inject(PermissionsService)
    public organizations = inject(OrganizationsService)
    private sdk = inject(SdkService)
    $can = this.permission.$to

    private filterStates$ = this.filtersService.states
    $visibleTags = signal<
        | {
              state: string
              label: string
              background?: string
              typeLabel?: string
          }[]
        | null
    >(null)
    $visibleOrganizations = signal<
        | {
              state: string
              label: string
              background?: string
              typeLabel?: string
          }[]
        | null
    >(null)
    public $showDropdown = computed(() => !!this.$searchText().length)
    public $hasData = computed(() => {
        return (
            this.$filteredTags() === null ||
            this.$filteredTags()?.length > 0 ||
            this.$filteredOrganizations() === null ||
            this.$filteredOrganizations()?.length > 0
        )
    })
    currentOrganizationId: string | null = null

    ngOnInit() {
        this.currentOrganizationId = this.organizations.current?.id ?? null
    }

    ngAfterViewInit() {
        const initialSearchText = this.filtersService.currentStates.search ?? ""
        this.$searchText.set(initialSearchText)
        this.searchComponent?.updateValue(initialSearchText)

        this.filtersService.states.subscribe((states) => {
            this.$searchText.set(states.search ?? "")

            const selectedOrganizationIds = Array.from(states.criteria["organizationId"]?.values() ?? [])
            this.$selectedOrganizationIds.set(selectedOrganizationIds)

            const selectedTagIds = Array.from(states.criteria["tagId"]?.values() ?? [])
            this.$selectedTagIds.set(selectedTagIds)

            if (selectedOrganizationIds.length) {
                void this.loadOrganizations()
            }
            if (selectedTagIds.length) {
                void this.loadTags()
            }
        })
    }

    private isLoadingTags = false
    async loadTags() {
        try {
            if (!this.selectableTagTypes.length) {
                this.$visibleTags.set([])
            }
            if (this.$visibleTags() === null && !this.isLoadingTags) {
                this.isLoadingTags = true
                const filter: TagFilterInput = {tagType: this.selectableTagTypes}
                if (!this.auth.isStaff()) {
                    const userId = this.auth.$user()?.id
                    if (userId) {
                        const {user} = await this.sdk.gql.tagSearchFilterUser({id: userId})
                        filter.organizationId = {
                            in: user.memberships.map((membership) => membership.organization?.id).filter(IsDefined),
                        }
                    }
                }
                const {tags} = await this.sdk.gql.tagSearchFilterTags({filter})
                this.$visibleTags.set(
                    tags.filter(IsDefined).map((tag) => {
                        const tagStateLabel = Labels.TagType.get(tag.type)
                        return {
                            state: tag.id,
                            label: tag.name,
                            background: tagStateLabel?.background,
                            typeLabel: tagStateLabel?.label,
                        }
                    }),
                )
            }
        } finally {
            this.isLoadingTags = false
        }
    }
    private isLoadingOrganizations = false
    async loadOrganizations() {
        try {
            if (this.$visibleOrganizations() === null && !this.isLoadingOrganizations) {
                this.isLoadingOrganizations = true
                const {organizations} = await this.sdk.gql.tagSearchFilterOrganizations({filter: {visibleInFilters: true}})
                // we might also have organizations in the URL that are not visible in the filters
                // these need to be loaded separately
                const invisibleOrganizationsInUrl = this.$selectedOrganizationIds().filter(
                    (id) => !organizations.some((organization) => organization?.id === id),
                )
                if (invisibleOrganizationsInUrl.length) {
                    const {organizations: organizationsInUrl} = await this.sdk.gql.tagSearchFilterOrganizations({
                        filter: {id: {in: invisibleOrganizationsInUrl}},
                    })
                    organizations.push(...organizationsInUrl)
                }
                this.$visibleOrganizations.set(
                    organizations
                        .filter(IsDefined)
                        .filter((organization) => !!organization.name)
                        .map((organization) => ({
                            state: organization.id,
                            label: organization.name!,
                        })),
                )
            }
        } finally {
            this.isLoadingOrganizations = false
        }
    }

    onFocus = async () => {
        this.focus = true
        void this.loadTags()
        void this.loadOrganizations()
    }

    onSearchTextChanged = async (text: string) => {
        await this.filtersService.updateSearchText(text)
        this.change.emit(text)
    }

    onSelectOrganization = async (organization: StateLabel<string>) => {
        await this.filtersService.updateCriteria(
            "organizationId",
            [...(this.$selectedOrganizations() ?? []), organization].map((stateLabel) => stateLabel.state),
        )
        this.searchComponent?.updateValue("")
    }

    onRemoveOrganization = async (organization: StateLabel<string>) => {
        const selectedOrganizations = (this.$selectedOrganizations() ?? []).filter((selectedOrganization) => selectedOrganization.state != organization.state)
        await this.filtersService.updateCriteria(
            "organizationId",
            selectedOrganizations.map((stateLabel) => stateLabel.state),
        )
        this.change.emit(this.$searchText())
    }

    onSelectTag = async (tag: StateLabel<string> & {typeLabel?: string}) => {
        await this.filtersService.updateCriteria(
            "tagId",
            [...(this.$selectedTags() ?? []), tag].map((stateLabel) => stateLabel.state),
        )
        this.searchComponent?.updateValue("")
    }

    onRemoveTag = async (tag: StateLabel<string> & {typeLabel?: string}) => {
        await this.filtersService.updateCriteria(
            "tagId",
            (this.$selectedTags() ?? []).filter((selectedTag) => selectedTag.state !== tag.state).map((stateLabel) => stateLabel.state),
        )
        this.change.emit(this.$searchText())
    }
}
