@if (node) {
    <ng-content select="[cm-content]"> </ng-content>
    <cm-inspector-section>
        <ng-container cm-title> Size </ng-container>
        <ng-container cm-content>
            <div class="cm-size">
                <cm-input-container>
                    <ng-container cm-inline-label> Width: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.width" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <div class="cm-multiplier">×</div>
                <cm-input-container>
                    <ng-container cm-inline-label> Height: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.height" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title>
            @if (isAreaLight(node)) {
                Area Light
            }
            @if (isLightPortal(node)) {
                Light Portal
            }
        </ng-container>
        <ng-container cm-content>
            <div class="cm-light-settings">
                @if (isAreaLight(node)) {
                    <cm-toggle [toggle]="node.on" (toggleChange)="node.on = $event; nodeChanged(node)" class="cm-span2">
                        <ng-container cm-text>On</ng-container>
                    </cm-toggle>
                }
                <cm-input-container class="cm-span2">
                    <ng-container cm-inline-label> Name: </ng-container>
                    <ng-container cm-input>
                        <cm-string-input [(value)]="node.name" (valueChange)="nodeChanged(node)"> </cm-string-input>
                    </ng-container>
                </cm-input-container>
                @if (isAreaLight(node)) {
                    <cm-input-container>
                        <ng-container cm-inline-label>
                            <span> Intensity: </span>
                        </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="node.intensity" (valueChange)="nodeChanged(node)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <!-- TODO: The 'tickInterval' property no longer exists -->
                    <mat-slider [max]="60" [min]="0" [step]="0.1" class="cm-slider"
                        ><input matSliderThumb [(ngModel)]="node.intensity" (ngModelChange)="nodeChanged(node)" />
                    </mat-slider>
                    <cm-input-container>
                        <ng-container cm-inline-label> Color: </ng-container>
                        <ng-container cm-input>
                            <cm-color-input [(value)]="node.color" (valueChange)="nodeChanged(node)"></cm-color-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label>
                            <span> Directionality: </span>
                        </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="node.directionality" (valueChange)="nodeChanged(node)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-toggle [toggle]="node.visibleDirectly ?? true" (toggleChange)="node.visibleDirectly = $event; nodeChanged(node)" class="cm-span2">
                        <ng-container cm-text>Visible to Camera</ng-container>
                    </cm-toggle>
                    <cm-toggle
                        [toggle]="node.visibleInReflections ?? true"
                        (toggleChange)="node.visibleInReflections = $event; nodeChanged(node)"
                        class="cm-span2"
                    >
                        <ng-container cm-text>Visible in Reflections</ng-container>
                    </cm-toggle>
                    <cm-toggle
                        [toggle]="node.visibleInRefractions ?? true"
                        (toggleChange)="node.visibleInRefractions = $event; nodeChanged(node)"
                        class="cm-span2"
                    >
                        <ng-container cm-text>Visible in Refractions</ng-container>
                    </cm-toggle>
                    <cm-toggle [(toggle)]="node.transparent!" (toggleChange)="nodeChanged(node)" class="cm-span2">
                        <ng-container cm-text>Transparent</ng-container>
                    </cm-toggle>
                }
            </div>
        </ng-container>
    </cm-inspector-section>
}
