import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {InterfaceDescriptor, InterfaceId, wrapInterfaceId} from "#template-nodes/interface-descriptors"

const TD = TypeDescriptors

const filterAndWrapInterfaceNewDescriptor = {
    interface: TD.inlet(TD.Array(TD.Identity<InterfaceDescriptor<unknown>>())),
    wrapWithId: TD.inlet(TD.Primitive<InterfaceId>()),
    claimedInputIds: TD.inlet(TD.Array(TD.Primitive<InterfaceId>())),
    includeClaimed: TD.inlet(TD.Boolean),
    output: TD.outlet(TD.Array(TD.Identity<InterfaceDescriptor<unknown>>())),
}

export class FilterAndWrapInterfaceNew implements NodeClassImpl<typeof filterAndWrapInterfaceNewDescriptor, typeof FilterAndWrapInterfaceNew> {
    static descriptor = filterAndWrapInterfaceNewDescriptor
    static uniqueName = "FilterAndWrapInterfaceNew"
    interface!: Inlet<InterfaceDescriptor<unknown>[]>
    wrapWithId!: Inlet<InterfaceId>
    claimedInputIds!: Inlet<InterfaceId[]>
    includeClaimed!: Inlet<boolean>
    output!: Outlet<InterfaceDescriptor<unknown>[]>

    run() {
        if (this.interface === NotReady || this.wrapWithId === NotReady || this.claimedInputIds === NotReady || this.includeClaimed === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const output: InterfaceDescriptor<unknown>[] = []
        for (const input of this.interface) {
            if (this.claimedInputIds.indexOf(input.props.id) == -1) {
                output.push(input.clone(wrapInterfaceId(this.wrapWithId, input.props.id)))
            } else if (this.includeClaimed) {
                output.push(input.clone(wrapInterfaceId(this.wrapWithId, input.props.id), true))
            }
        }
        this.output.emitIfChanged(output)
    }
}
