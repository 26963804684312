@if ($createItem(); as createItemAction) {
    <div class="cm-entity-add cm-list-info" (click)="createItemAction()">
        <i class="far fa-plus"></i>
        Add item
    </div>
}
@if ($count() === undefined) {
    <div class="cm-list-info cm-list-info__items">
        <div class="cm-placeholder"></div>
        Items
    </div>
} @else {
    <div class="cm-list-info">{{ $count() }} Item{{ $count() === 1 ? "" : "s" }}</div>
}
<ng-content></ng-content>
<!--TODO: Add when sorting is implemented-->
<!--<div class="cm-list-info">-->
<!--<i class="far fa-sort"></i> Sort by-->
<!-- </div>-->
@if ($showBatchButton()) {
    <div
        class="cm-batch-edit-button"
        [matTooltip]="
            batchEditInfo.running
                ? 'Batch processing is already running'
                : $count() > maxBatchEditNr || $count() === 0
                  ? 'Only works for item numbers between 1 and ' + maxBatchEditNr
                  : ''
        "
    >
        <div
            class="cm-list-info cm-list-edit"
            [style.pointer-events]="$count() > maxBatchEditNr || batchEditInfo.running ? 'none' : 'inherit'"
            [matMenuTriggerFor]="batchMenu"
            (click)="loadUsersAndTags()"
        >
            <i class="far fa-pen-to-square"></i>
            Batch edit
        </div>

        @if (batchEditInfo.running && batchEditInfo.total > 0) {
            <div class="cm-task-progress">
                Processing {{ batchEditInfo.numSucceeded + batchEditInfo.numSkipped + batchEditInfo.numErrored + 1 }} of {{ batchEditInfo.total }} tasks...
                <mat-progress-bar
                    mode="determinate"
                    [value]="100 * ((batchEditInfo.numSucceeded + batchEditInfo.numSkipped + batchEditInfo.numErrored + 1) / batchEditInfo.total)"
                ></mat-progress-bar>
            </div>
        }
    </div>
}
@if ($advancedActions().length > 0) {
    <div class="cm-list-info cm-list-edit" [matMenuTriggerFor]="advancedMenu">
        <i class="far fa-chevron-down"></i>
        Advanced
    </div>
}
<mat-menu #advancedMenu="matMenu">
    @for (advancedAction of $advancedActions(); track advancedAction) {
        <button mat-menu-item (click)="advancedAction.operation()" [matTooltip]="advancedAction.tooltip ?? ''">
            <span>{{ advancedAction.label }}</span>
        </button>
    }
</mat-menu>
<mat-menu #batchMenu="matMenu">
    <ng-template matMenuContent let-node="node">
        @if ($allowStandardUpdates()) {
            @if ($stateLabels().length > 0) {
                <button [matMenuTriggerFor]="state" mat-menu-item>
                    <span>State</span>
                </button>
            }
            <button [matMenuTriggerFor]="paymentState" mat-menu-item>
                <span>Payment state</span>
            </button>
            <button [matMenuTriggerFor]="nextActor" mat-menu-item>
                <span>Up next</span>
            </button>
            <button [matMenuTriggerFor]="assignUser" mat-menu-item>
                <span>Assign user</span>
            </button>
            <button [matMenuTriggerFor]="addTags" mat-menu-item>
                <span>Add tag</span>
            </button>
            <button [matMenuTriggerFor]="removeTags" mat-menu-item>
                <span>Remove tag</span>
            </button>
        }
        @for (extraBatchAction of $extraBatchActions(); track extraBatchAction) {
            <button [matMenuTriggerFor]="extraBatchActionMenu" [matMenuTriggerData]="{items: extraBatchAction.items}" mat-menu-item>
                <span>{{ extraBatchAction.groupLabel }}</span>
            </button>
        }
        @if ($downloadMenuItems().length > 0) {
            <button
                [disabled]="$count() > maxBatchDownloadNr"
                [matTooltip]="$count() > maxBatchDownloadNr ? 'Too many items to download' : ''"
                [matMenuTriggerFor]="downloadMenu.menuRef"
                mat-menu-item
            >
                <span>Download</span>
            </button>
        }
    </ng-template>
</mat-menu>
<mat-menu #state="matMenu">
    @if ($stateLabels()) {
        @for (stateLabel of $stateLabels(); track stateLabel) {
            <button mat-menu-item (click)="batchUpdate('state', stateLabel.state)">
                <span>{{ stateLabel.label }}</span>
            </button>
        }
    }
</mat-menu>
<mat-menu #paymentState="matMenu">
    @for (paymentStateLabel of paymentStateLabels; track paymentStateLabel) {
        <button mat-menu-item (click)="batchUpdate('paymentState', paymentStateLabel.state)">
            <span>{{ paymentStateLabel.label }}</span>
        </button>
    }
</mat-menu>
<mat-menu #nextActor="matMenu">
    @for (nextActorLabel of nextActorLabels; track nextActorLabel) {
        <button mat-menu-item (click)="batchUpdate('nextActor', nextActorLabel.state)">
            <span>{{ nextActorLabel.label }}</span>
        </button>
    }
</mat-menu>
<mat-menu #assignUser="matMenu">
    <button mat-menu-item (click)="batchUpdate('removeUserAssignment', true)">
        <span> Remove user </span>
    </button>
    @if (visibleUsers === null) {
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
    } @else {
        @for (user of visibleUsers; track user) {
            <button mat-menu-item (click)="batchUpdate('assignUserId', user.id)">
                <span>{{ user.name }}</span>
            </button>
        }
    }
</mat-menu>
<mat-menu #addTags="matMenu">
    @if (visibleTags === null) {
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
    } @else {
        @for (tag of visibleTags; track tag) {
            <button mat-menu-item (click)="batchUpdate('addTagId', tag.id)">
                <span>{{ tag.name }}</span>
            </button>
        }
    }
</mat-menu>
<mat-menu #removeTags="matMenu">
    @if (visibleTags === null) {
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
        <div mat-menu-item>
            <cm-placeholder class="cm-item-placeholder"></cm-placeholder>
        </div>
    } @else {
        @for (tag of visibleTags; track tag) {
            <button mat-menu-item (click)="batchUpdate('removeTagId', tag.id)">
                <span>{{ tag.name }}</span>
            </button>
        }
    }
</mat-menu>
<mat-menu #extraBatchActionMenu="matMenu">
    <ng-template matMenuContent let-items="items">
        @for (item of items; track item) {
            <button mat-menu-item (click)="batchProcessSingleItems(item.operation, item.text)" [matTooltip]="item.tooltip">
                <span>{{ item.label }}</span>
            </button>
        }
    </ng-template>
</mat-menu>
<cm-multilevel-menu #downloadMenu [items]="$downloadMenuItems()" (itemClicked)="startDownload($event)"></cm-multilevel-menu>
