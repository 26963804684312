import {ApiEntityClassMetadata, ApiFields, ApiModelBaseClassConstructor, EndpointUrls, MakeApiModelBase} from "@legacy/api-model/api-model"
import {DataObject} from "@legacy/api-model/data-object"
import {DataObjectAssignment, DataObjectAssignmentType as RestDataObjectAssignmentType} from "@legacy/api-model/data-object-assignment"
import {State} from "@legacy/models/state-labels/state"

import {Observable, of, tap} from "rxjs"

export class TaskInfos {
    public in_progress!: number
    public waiting_for_feedback!: number
    public completed!: number
    public archived!: number
}

export function MakeApiModelGenericBase<T, G extends ApiModelBaseClassConstructor>(g: G) {
    const base = MakeApiModelBase<T, G>(g)
    return base as typeof base & G
}

abstract class ApiModelExtendedData {
    // @ApiFields.manyRelated({name: "tags", model: Tag}) tags: Tag[] = []
    @ApiFields.manyRelated({name: "data_object_assignments", model: DataObjectAssignment}) dataObjectAssignments: DataObjectAssignment[] = []
    @ApiFields.json({name: "task_infos", readOnly: true}) taskInfos!: TaskInfos
    @ApiFields.number({name: "next_actor"}) nextActor!: number

    protected abstract readonly entityClassMetadata: ApiEntityClassMetadata<any>
    abstract readonly id: number

    abstract save(): Observable<void>

    abstract delete(): Observable<void>

    isFavorite?: boolean

    constructor(..._args: any[]) {}

    getThumbnailUrl(width = 500): string {
        const galleryImage: DataObject | null = this.getGalleryImage()
        if (!galleryImage) {
            return EndpointUrls.IMAGE_NOT_AVAILABLE_URL
        }
        if (galleryImage.available()) {
            return galleryImage.getThumbnailUrl(width)!
        }
        return EndpointUrls.DATA_OBJECT_PROCESSING_URL
    }

    getGalleryImage(): DataObject | null {
        for (const assignment of this.dataObjectAssignments) {
            if (assignment.type === RestDataObjectAssignmentType.GalleryImage) {
                return assignment.dataObject
            }
        }
        return null
    }

    getIcon(): DataObject | undefined {
        return this.dataObjectAssignments.find((item) => item.type === RestDataObjectAssignmentType.Icon)?.dataObject
    }

    setGalleryImage(image: DataObject): Observable<void> {
        const currentGalleryImage: DataObject | null = this.getGalleryImage()
        if (currentGalleryImage) {
            // This update is only needed locally. The API does not allow multiple gallery images and does this automatically.
            const currentGalleryImageAssignment: DataObjectAssignment = this.getDataObjectAssignment(currentGalleryImage)!
            currentGalleryImageAssignment.type = RestDataObjectAssignmentType.Attachment
        }
        const assignment: DataObjectAssignment | undefined = this.getDataObjectAssignment(image)
        if (assignment) {
            assignment.type = RestDataObjectAssignmentType.GalleryImage
            return assignment.save()
        } else {
            return this.assignDataObject(image, RestDataObjectAssignmentType.GalleryImage)
        }
    }

    removeGalleryImage(): Observable<void | null> {
        const currentImage: DataObject | null = this.getGalleryImage()
        if (!currentImage) {
            return of(null)
        }
        const assignment: DataObjectAssignment = this.getDataObjectAssignment(currentImage)!
        assignment.type = RestDataObjectAssignmentType.Attachment
        return assignment.save()
    }

    getDataObjectAssignment(dataObject: DataObject): DataObjectAssignment | undefined {
        for (const assignment of this.dataObjectAssignments) {
            if (assignment.dataObject.id == dataObject.id) {
                return assignment
            }
        }
        return undefined
    }

    removeDataObjectAssignmentLocally(dataObject: DataObject): void {
        const index: number = this.dataObjectAssignments.indexOf(this.getDataObjectAssignment(dataObject)!)
        if (index === -1) {
            console.warn("DataObject not found.")
            return
        }
        this.dataObjectAssignments.splice(index, 1)
    }

    assignDataObject(dataObject: DataObject, assignmentType: RestDataObjectAssignmentType): Observable<void> {
        const assignment: DataObjectAssignment = new DataObjectAssignment()
        assignment.dataObject = dataObject
        assignment.objectId = this.id
        assignment.objectEntityType = this.entityClassMetadata.entityType!
        assignment.type = assignmentType
        if (assignmentType === RestDataObjectAssignmentType.GalleryImage) {
            const currentGalleryImage: DataObject | null = this.getGalleryImage()
            if (currentGalleryImage) {
                // This update is only needed locally. The API does not allow multiple gallery images and does this automatically.
                const currentGalleryImageAssignment: DataObjectAssignment = this.getDataObjectAssignment(currentGalleryImage)!
                currentGalleryImageAssignment.type = RestDataObjectAssignmentType.Attachment
            }
        }
        return assignment.save().pipe(
            tap(() => {
                this.dataObjectAssignments.unshift(assignment)
            }),
        )
    }
}

export function MakeApiModelExtendedBase<T>() {
    return MakeApiModelGenericBase<T, typeof ApiModelExtendedData>(ApiModelExtendedData)
}

export abstract class ApiModelExtended extends MakeApiModelExtendedBase<ApiModelExtended>() {}
export type ApiModelExtendedClass = typeof ApiModelExtended
