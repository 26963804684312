import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {Subject, takeUntil} from "rxjs"
import {QueryMaterialForScanRegion, ScanRegion} from "@platform/models/scanning/scanning-canvas-toolbox/tool-items/scan-region"
import * as paper from "paper"
import {CanvasBaseComponent} from "@common/components/canvas/canvas-base/canvas-base.component"
import {ResolutionOption} from "@platform/models/scanning/types"
import {CanvasBaseToolboxRootItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-root-item"
import {Vector2Like} from "@cm/math"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class ScanningCanvasToolbox extends CanvasBaseToolboxRootItem {
    readonly selectionChange = new Subject<ScanRegion | null>()
    readonly scanRegionCreated = new Subject<ScanRegion>()
    readonly scanRegionDeleted = new Subject<ScanRegion>()

    defaultResolution: ResolutionOption | null = null
    scanRegions: ScanRegion[] = []

    private newRegionInProgress: ScanRegion | null = null

    constructor(
        canvasBase: CanvasBaseComponent,
        hotkeys: Hotkeys,
        private queryMaterialForScanRegion: QueryMaterialForScanRegion,
    ) {
        super(canvasBase)
        this.cursor = "crosshair"

        // register hotkeys
        hotkeys
            .addShortcut(["Delete", "Backspace"])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.deleteSelectedScanRegions())
    }

    override hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        const hitChild = super.hitTest(point)
        if (hitChild) {
            return hitChild
        }
        if (this.allowScanRegionCreation) {
            return this
        }
        return null
    }

    override remove(): void {
        super.remove()
        this.selectionChange.complete()
        this.scanRegionCreated.complete()
        this.scanRegionDeleted.complete()
    }

    set allowScanRegionCreation(value: boolean) {
        this._allowScanRegionCreation = value
    }

    get allowScanRegionCreation(): boolean {
        return this._allowScanRegionCreation
    }

    addScanRegion(x: number, y: number, width: number, height: number): ScanRegion {
        const newRegion = new ScanRegion(this, x, y, width, height, this.queryMaterialForScanRegion)
        this.scanRegions.push(newRegion)
        newRegion.selectedChange.pipe(takeUntil(this.unsubscribe)).subscribe((selected) => this.selectionChange.next(selected ? newRegion : null))
        newRegion.itemRemove.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.scanRegions.splice(this.scanRegions.indexOf(newRegion), 1)
        })
        return newRegion
    }

    deleteSelectedScanRegions(): void {
        this.scanRegions
            .slice()
            .reverse()
            .filter((item) => item.selected)
            .forEach((item) => {
                item.remove()
                this.scanRegionDeleted.next(item)
            })
    }

    override onMouseUp(event: paper.ToolEvent): boolean {
        if (this.newRegionInProgress) {
            this.scanRegionCreated.next(this.newRegionInProgress)
            this.newRegionInProgress = null
        }
        return super.onMouseUp(event)
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        if (this.newRegionInProgress || super.onMouseDrag(event)) {
            if (this.allowScanRegionCreation) {
                // we're not in scan-region creation mode and no existing item handled the event, let's consider this the start of a scan-region creation drag
                this.handleRectangleRegionCreation(event)
            }
        }
        return false
    }

    private handleRectangleRegionCreation(event: paper.ToolEvent) {
        // Only start creating a region if it reaches a certain minimum size in order to avoid unwanted creation of tiny regions by accident.
        const regionCreationTolerance = (20 / this.zoomLevel) * window.devicePixelRatio
        const offset = event.point.subtract(event.downPoint)
        const delta = Math.max(Math.abs(offset.x), Math.abs(offset.y)) // TODO probably better to thresh the area to avoid degenerate cases
        if (!this.newRegionInProgress && delta > regionCreationTolerance) {
            if (!this.defaultResolution) {
                throw Error("Attempting to create scan-region before setting a default resolution to the toolbox.")
            }
            this.newRegionInProgress = this.addScanRegion(Math.round(event.downPoint.x), Math.round(event.downPoint.y), 0, 0)
            this.newRegionInProgress.sampleRotation = 0
            this.newRegionInProgress.resolution = this.defaultResolution
            this.newRegionInProgress.state = "editing"
            this.newRegionInProgress.selected = true
        }
        if (this.newRegionInProgress) {
            const canvasMin = this.canvasBounds.min
            const canvasMax = this.canvasBounds.max
            const minPos = paper.Point.max(canvasMin, paper.Point.min(canvasMax, paper.Point.min(event.downPoint, event.point)))
            const maxPos = paper.Point.max(canvasMin, paper.Point.min(canvasMax, paper.Point.max(event.downPoint, event.point)))
            this.newRegionInProgress.setRect(minPos.x, minPos.y, maxPos.x, maxPos.y)
        }
    }

    private _allowScanRegionCreation = true
}
