import {LegacyTemplateNodes} from "@cm/template-nodes"
import {z} from "zod"

export const DataObjectReferenceSchema = z.object({
    type: z.literal("dataObjectReference"),
    dataObjectId: z.number(),
})

export type DataObjectReference = z.infer<typeof DataObjectReferenceSchema>

export type JsonObjectDataObjectReference = {
    type: "jsonObjectDataObjectReference"
    dataObjectId: number
    dataObjectAssignmentType: number
}

export const JobGraphDataObjectReferenceSchema = z.object({
    type: z.literal("dataObjectReference"),
    dataObjectId: z.number(),
})

export type JobGraphDataObjectReference = z.infer<typeof JobGraphDataObjectReferenceSchema>

type CryptomatteEntity = "object" | "material" | "asset"

export const CryptomatteIdSchema = z
    .string()
    .refine((value) => value.startsWith("object:") || value.startsWith("material:") || value.startsWith("asset:")) as z.ZodType<CryptomatteId>

export type CryptomatteId = `${CryptomatteEntity}:${string}`

export const CryptomatteManifestSchema = z.object({
    objects: z.record(z.string(), z.string()).optional(), // key is ID without entity tag, value is hex string
    materials: z.record(z.string(), z.string()).optional(),
    assets: z.record(z.string(), z.string()).optional(),
})

export type CryptomatteManifest = z.infer<typeof CryptomatteManifestSchema>

export type MultichannelLayouts = "RGB" | "BGR" | "ARGB" | "RGBA" | "ABGR" | "BGRA"
export interface TypedImageData<DataT = ArrayBufferView, ChannelLayouts = "L" | MultichannelLayouts, DataTypes = "uint8" | "uint16" | "float16" | "float32"> {
    readonly data: DataT
    readonly width: number
    readonly height: number
    readonly channelLayout: ChannelLayouts
    readonly dataType: DataTypes
    readonly colorSpace: "sRGB" | "linear"
    readonly dpi?: number
}

export type ToneMappingData = LegacyTemplateNodes.ToneMapping | undefined

export type ToneMappingFunction = (r: number, g: number, b: number) => readonly [number, number, number]

type CommonSettings = {
    exposure?: number
    whiteBalance?: number
    toneMapping?: ToneMappingData
    lutUrl?: string
}

export type MaskPostProcessingSettings = CommonSettings

export const Vec2Schema = z.tuple([z.number(), z.number()])
export const RGBColorSchema = z.tuple([z.number(), z.number(), z.number()])
export const RGBAColorSchema = z.tuple([z.number(), z.number(), z.number(), z.number()])

export const GeometrySchema = z.object({
    type: z.literal("geometry"),
    topology: z.literal("triangleList"),
    vertices: z.object({
        positions: z.array(Vec2Schema),
        uvs: z.array(Vec2Schema).optional(),
        colors: z.union([z.array(RGBColorSchema), z.array(RGBAColorSchema)]).optional(),
    }),
    indices: z.array(z.number()).optional(),
})

export const Base64DataSchema = z.object({
    type: z.literal("base64"),
    data: z.string(),
})

export const GeometryBase64Schema = z.object({
    type: z.literal("geometry-base64"),
    topology: z.literal("triangleList"),
    vertices: z.object({
        positions: Base64DataSchema,
        uvs: Base64DataSchema.optional(),
        colors: Base64DataSchema.optional(),
    }),
    indices: Base64DataSchema.optional(),
})
