import {HalImageChannelLayout, HalImageDataType} from "@common/models/hal/hal-image/types"
import {assertNever} from "@cm/utils"

export function getNumChannels(layout: HalImageChannelLayout): number {
    switch (layout) {
        case "RGBA":
            return 4
        case "RGB":
            return 3
        case "R":
            return 1
    }
    assertNever(layout)
}

export function getBytesPerChannel(dataType: HalImageDataType): number {
    switch (dataType) {
        case "uint8":
            return 1
        case "float16":
            return 2
        case "float32":
            return 4
    }
    assertNever(dataType)
}
