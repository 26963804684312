import {Component, computed, input, output} from "@angular/core"
import {PricingNode, ConditionalAmountGroupNode, ConditionalAmountNode, PricingContext, PriceContainer, PricedItemNode} from "@cm/pricing"
import {ConditionalAmountGroupNodeComponent} from "@pricing/components/node-visualizers/conditional-amount-group-node/conditional-amount-group-node.component"
import {ConditionalAmountNodeComponent} from "@pricing/components/node-visualizers/conditional-amount-node/conditional-amount-node.component"
import {PriceContainerNodeComponent} from "@pricing/components/node-visualizers/price-container-node/price-container-node.component"
import {PricedItemNodeComponent} from "@pricing/components/node-visualizers/priced-item-node/priced-item-node.component"

@Component({
    standalone: true,
    selector: "cm-price-node-visualizer",
    templateUrl: "./price-node-visualizer.component.html",
    styleUrls: ["./price-node-visualizer.component.scss"],
    imports: [ConditionalAmountGroupNodeComponent, ConditionalAmountNodeComponent, PriceContainerNodeComponent, PricedItemNodeComponent],
})
export class PriceNodeVisualizerComponent {
    $node = input.required<PricingNode>()
    $pricingContext = input.required<PricingContext>()
    $isPricedItemNode = computed<boolean>(() => this.$node() instanceof PricedItemNode)
    $isPriceContainerNode = computed<boolean>(() => this.$node() instanceof PriceContainer)
    $isAmountGroupNode = computed<boolean>(() => this.$node() instanceof ConditionalAmountGroupNode)
    $isAmountNode = computed<boolean>(() => this.$node() instanceof ConditionalAmountNode)
    $conditionRemoved = output<void>()
}
