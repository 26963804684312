import {SdkService} from "@app/common/services/sdk/sdk.service"
import {RENDERING_TASK_NAME} from "@cm/job-nodes"
import {IsTruthy} from "@cm/utils"
import {parseOtherInfo} from "@platform/models/job-farm-instance/other-info"

export async function formatRenderJobTaskClaimedByField<T extends {claimedBy?: string | null}>(renderJobTask: T, sdkService: SdkService) {
    if (!renderJobTask.claimedBy) return renderJobTask
    const maybeJobFarmInstance = (await sdkService.gql.renderJobTaskJobFarmInstance({hwFingerprint: renderJobTask.claimedBy})).jobFarmInstances?.find(IsTruthy)

    renderJobTask.claimedBy = parseOtherInfo(maybeJobFarmInstance?.otherInfo)?.hostName
    return renderJobTask
}

export function isClaimedRenderJobTask<T extends {taskType?: string | null; claimedBy?: string | null}>(jobTask: T) {
    // TODO once cloud rendering introduced, correctly narrow down to only render task claimed by desktop render nodes
    return jobTask.taskType === RENDERING_TASK_NAME && !!jobTask.claimedBy
}
