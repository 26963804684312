import {MaterialSocket} from "@material-editor/models/material-socket"

export const TexCoordOutputs: Record<string, MaterialSocket> = {
    generated: {paramType: "socket", id: "Generated", type: "output", valueType: "output", label: "Generated"},
    normal: {paramType: "socket", id: "Normal", type: "output", valueType: "output", label: "Normal"},
    uv: {paramType: "socket", id: "UV", type: "output", valueType: "output", label: "UV"},
    object: {paramType: "socket", id: "Object", type: "output", valueType: "output", label: "Object"},
    camera: {paramType: "socket", id: "Camera", type: "output", valueType: "output", label: "Camera"},
    reflection: {paramType: "socket", id: "Reflection", type: "output", valueType: "output", label: "Reflection"},
}

export const TexCoordInputs: Record<string, MaterialSocket> = {}
