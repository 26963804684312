import {inject, Injectable} from "@angular/core"
import {NamedConfiguratorVariant} from "@cm/pricing"
import {colorToString} from "@cm/utils"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {Dictionary} from "@legacy/helpers/utils"
import {SdkService} from "@common/services/sdk/sdk.service"
import {BehaviorSubject, from, Observable, of, Subject} from "rxjs"
import {
    ConfigInfo,
    InterfaceDescriptor,
    InterfaceDescriptor as InterfaceDescriptorNew,
    isConfigInput,
    MaterialInfo,
    UiStyle,
    VariantInfo,
} from "@cm/template-nodes"

export type MaterialSelectionEvent = {input: MaterialInfo; legacyId: number}
export type ConfigSelectionEvent = {config: ConfigInfo; variant: VariantInfo}

@Injectable({
    providedIn: "root",
})
export class ConfigMenuService {
    sdk = inject(SdkService)
    configVariantColors: Dictionary<string> = {}

    constructor() {}

    private dataObjectThumbnailCache = new AsyncCacheMap<number, string | null>((dataObjectLegacyId) =>
        from(
            (async () => {
                const {dataObject} = await this.sdk.gql.dataObjectThumbnailForConfigMenuService({legacyId: dataObjectLegacyId})
                return dataObject?.thumbnail?.downloadUrl ?? null
            })(),
        ),
    )

    private interfaceSubject = new BehaviorSubject<InterfaceDescriptorNew<unknown, object>[]>([])
    interfaceNew$ = this.interfaceSubject.asObservable()
    setInterface(interfaceDescriptors: InterfaceDescriptorNew<unknown, object>[]): void {
        this.interfaceSubject.next(interfaceDescriptors)
        this.updateConfigVariantColors()
    }

    private uiStyleSubject = new BehaviorSubject<UiStyle | undefined>(undefined)
    uiStyle$ = this.uiStyleSubject.asObservable()
    setUiStyle(uiStyle: UiStyle): void {
        this.uiStyleSubject.next(uiStyle)
    }

    private iconSizeSubject = new BehaviorSubject<number>(24)
    iconSize$ = this.iconSizeSubject.asObservable()
    setIconSize(iconSize: number): void {
        this.iconSizeSubject.next(iconSize)
    }

    private synchronizingSubject = new BehaviorSubject<boolean>(false)
    synchronizing$ = this.synchronizingSubject.asObservable()
    setSynchronizing(synchronizing: boolean): void {
        this.synchronizingSubject.next(synchronizing)
    }

    //Outputs of the menu
    private materialSelectedSubject = new Subject<MaterialSelectionEvent>()
    materialSelected$ = this.materialSelectedSubject.asObservable()
    emitMaterialSelected(configEvent: MaterialSelectionEvent): void {
        this.materialSelectedSubject.next(configEvent)
    }

    private configSelectedSubject = new Subject<ConfigSelectionEvent>()
    configSelected$ = this.configSelectedSubject.asObservable()
    emitConfigSelected(configEvent: ConfigSelectionEvent): void {
        this.configSelectedSubject.next(configEvent)
    }

    private pdfDownloadRequestedSubject = new Subject<void>()
    pdfDownloadRequested$ = this.pdfDownloadRequestedSubject.asObservable()
    emitPdfDownloadRequested(): void {
        this.pdfDownloadRequestedSubject.next()
    }

    private pdfDownloadFinishedSubject = new Subject<void>()

    emitPdfDownloadFinished(): void {
        this.pdfDownloadFinishedSubject.next()
    }

    pdfDownloadFinished$(): Observable<void> {
        return this.pdfDownloadFinishedSubject.asObservable()
    }

    getSelectedVariants(): NamedConfiguratorVariant[] {
        const result: NamedConfiguratorVariant[] = []

        this.interfaceSubject.getValue().forEach((desc) => {
            if (isConfigInput(desc) && desc.props.value) {
                result.push({
                    groupId: desc.props.id,
                    groupName: desc.props.name,
                    variantId: desc.props.value.id, //this is the value that is selected in the ui, i.e. the crucial part
                    variantName: desc.props.variants.find((v) => v.id === desc.props.value?.id)?.name ?? "unknown",
                })
            }
        })

        return result
    }

    getSelectedVariantForGroup(groupId: string): NamedConfiguratorVariant {
        const desc = this.interfaceSubject.getValue().find((desc) => desc.props.id === groupId)
        if (!desc) throw new Error(`Group ${groupId} not found`)
        if (!isConfigInput(desc)) throw new Error(`Group ${groupId} is not a config input`)

        const value = desc.props.value
        if (!value) throw new Error(`Group ${groupId} has no value selected`)

        const variant = desc.props.variants.find((v) => v.id === value.id)
        if (!variant) throw new Error(`Variant with id ${value.id} not found in group ${groupId}`)

        return {
            groupId: desc.props.id,
            groupName: desc.props.name,
            variantId: value.id,
            variantName: variant.name,
        }
    }

    getVariant(variantId: string): VariantInfo | undefined {
        for (const descriptor of this.interfaceSubject.getValue()) {
            if (!isConfigInput(descriptor)) continue
            for (const variant of descriptor.props.variants) {
                if (variant.id === variantId) return variant
            }
        }

        return undefined
    }

    getDescriptorForVariant(variantId: string): InterfaceDescriptor<unknown, object> | undefined {
        for (const descriptor of this.interfaceSubject.getValue()) {
            if (!isConfigInput(descriptor)) continue
            for (const variant of descriptor.props.variants) {
                if (variant.id === variantId) return descriptor
            }
        }

        return undefined
    }

    fetchThumbnailForDataObject(id?: number): Observable<string | null> {
        if (id != null) {
            return this.dataObjectThumbnailCache.get(id)
        } else {
            return of(null)
        }
    }

    updateConfigVariantColors(): void {
        for (const config of this.interfaceSubject.getValue()) {
            if (!isConfigInput(config)) continue
            for (const variant of config.props.variants) {
                let colorString = "#000000"
                if (variant.iconColor) colorString = colorToString(variant.iconColor as [number, number, number])
                this.configVariantColors[variant.id] = colorString
            }
        }
    }
}

// //Convenience method relevant for mapping prices programmatically, can be removed later.
// function createNode(config: {id: string; name: string; variants: {id: string}[]}): string {
//     const varName = config.name.replace(/\s+/g, "")
//     const groupId = unwrapInterfaceId(config.id)[1]
//     const currentVariantId = config.variants[0].id
//     const allVariantIds = config.variants.map((v) => `"${v.id}"`).join(", ")
//
//     return `let ${varName} = new ConfigurationGroupNode({groupId: "${groupId}", currentVariantId: "${currentVariantId}", allVariantIds: [${allVariantIds}]})`
// }

//The following is needed inside the service:
//public interfaceCache: Map<string, Array<Nodes.Meta.InterfaceDescriptor>>
//this.interfaceCache = new Map()
// printNodes() {
//     console.log("aa", this.interfaceCache)
//     let result = ""
//
//     this.interfaceCache.forEach((values, _key) => {
//         const prefix = values.length !== 1 ? "!" : ""
//
//         values.forEach((value) => {
//             result += prefix + createNode(value) + "\n\n"
//         })
//     })
//
//     console.log(result)
//
//     const nodesAsArray: Nodes.Meta.InterfaceDescriptor[] = Array.from(this.interfaceCache.values()).flat()
//     console.log(JSON.stringify(nodesAsArray))
// }
//
// //Convenience method relevant for mapping prices programmatically, can be removed later.
// cacheInterface(node: Nodes.Meta.InterfaceDescriptor[]): void {
//     node.forEach((desc) => {
//         if (Nodes.Meta.isConfigInput(desc)) {
//             const newVariantIds = desc.variants.map((v) => v.id)
//
//             const key = unwrapInterfaceId(desc.id)[1]
//             if (this.interfaceCache.has(key)) {
//                 const existingDescriptors = this.interfaceCache.get(key)!
//
//                 const exists = existingDescriptors.some((existingDesc) => {
//                     const existingVariantIds = existingDesc.variants.map((v) => v.id)
//                     return existingVariantIds.length === newVariantIds.length && newVariantIds.every((id) => existingVariantIds.includes(id))
//                 })
//
//                 if (!exists) existingDescriptors.push(desc)
//             } else {
//                 this.interfaceCache.set(key, [desc])
//             }
//         }
//     })
// }
