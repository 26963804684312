import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {SpatialMappingItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import paper from "paper"
import {ColorLike, Vector2Like} from "@cm/math"

export class AreaViz extends CanvasBaseToolboxItemBase<SpatialMappingItem> {
    constructor(readonly spatialMapping: SpatialMappingItem) {
        super(spatialMapping)

        this.sendToBack()
    }

    set color(color: ColorLike) {
        this._color = color
        if (this._area) {
            this._area.fillColor = new paper.Color(this._color.r, this._color.g, this._color.b, this._color.a)
        }
    }

    get color() {
        return this._color
    }

    setAreaPoints(positions: Vector2Like[]) {
        this.beginPaperCreation()
        this._area?.remove()
        this._area = undefined
        if (positions.length >= 4) {
            const halfBackgroundSize = 100000
            const backgroundArea = new paper.Path.Rectangle({
                point: [-halfBackgroundSize, -halfBackgroundSize],
                size: [2 * halfBackgroundSize, 2 * halfBackgroundSize],
            })
            const insideArea = new paper.Path(positions)
            this._area = backgroundArea.subtract(insideArea)
            insideArea.remove()
            backgroundArea.remove()
            this._area.fillColor = new paper.Color(this._color.r, this._color.g, this._color.b, this._color.a)
        }
    }

    private _area?: paper.PathItem
    private _color: ColorLike = {r: 0, g: 0, b: 0, a: 0.5}
}
