import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeValueNode} from "#material-nodes/three-utils"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({image: materialSlots})
const InputTypeSchema = z.object({r: materialSlots.optional(), g: materialSlots.optional(), b: materialSlots.optional()})
const ParametersTypeSchema = z.object({r: z.number().optional(), g: z.number().optional(), b: z.number().optional()})

export class CombineRGB extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {r, g, b} = await getAll(inputs, get)
            const rValue = r ?? threeValueNode(parameters.r ?? 0)
            const gValue = g ?? threeValueNode(parameters.g ?? 0)
            const bValue = b ?? threeValueNode(parameters.b ?? 0)

            return {image: THREENodes.color(new THREENodes.JoinNode([rValue, gValue, bValue]))}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
