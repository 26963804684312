import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"
import {getMostPreciseDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {wrap} from "@cm/utils"

const SCOPE_NAME = "RotateAngleMap"

export type ParameterType = {
    sourceImage: ImageRef
    angleInDegrees: number // angle in degrees to rotate by
    angleFactor?: number // factor by which a full rotation is accomplished; 1 for vectors which repeat in 360°; 2 for vectors which repeat in 180°; negative values reverse cw/ccw; default: 1
}

export type ReturnType = ImageRef

export function rotateAngleMap(cmdQueue: ImageOpCommandQueue, {sourceImage, angleInDegrees, angleFactor}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)

    angleFactor ??= 1
    let result = math(cmdQueue, {
        operandA: math(cmdQueue, {
            operandA: sourceImage,
            operandB: wrap((angleFactor * angleInDegrees) / 360, 1),
            operator: "+",
            resultImageOrDataType: getMostPreciseDataType(sourceImage.descriptor.dataType, "float16"),
        }),
        operandB: 0.5,
        operator: "mod",
    })
    result = convert(cmdQueue, {
        sourceImage: result,
        dataType: sourceImage.descriptor.dataType,
    })

    cmdQueue.endScope(SCOPE_NAME)

    return result
}
