import {Component, inject, OnInit} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, PinnedFilterComponent, PinnedFilterOptionComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {Labels, StateLabel} from "@labels"
import {BasePageComponent} from "@pages/base/base-page.component"
import {AssetsGridComponent} from "@platform/components/assets/assets-grid/assets-grid.component"
import {Enums} from "@enums"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsDefined} from "@cm/utils/filter"

@Component({
    selector: "cm-assets-page",
    standalone: true,
    imports: [
        CheckboxesFilterComponent,
        ItemFiltersComponent,
        RouterOutlet,
        SidebarLayoutComponent,
        PinnedFilterComponent,
        PinnedFilterOptionComponent,
        TagSearchFilterComponent,
        AssetsGridComponent,
    ],
    templateUrl: "./assets-page.component.html",
    styleUrl: "./assets-page.component.scss",
})
export class AssetsPageComponent extends BasePageComponent implements OnInit {
    readonly Enums = Enums
    readonly Labels = Labels

    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    userLabels: StateLabel<string>[] | null = null

    override ngOnInit() {
        super.ngOnInit()
        this.sdk.gql.modelsPageVisibleUsers().then(({users}) => {
            this.userLabels = users.filter(IsDefined).map((user) => ({
                label: user.name,
                state: user.id,
            }))
        })
    }
}
