import {Component, ElementRef, Input, OnDestroy, ViewChild, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Settings} from "@common/models/settings/settings"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {GridSize} from "@legacy/helpers/utils"
import {IEditor} from "@editor/models/editor"
import {EditorService} from "@editor/services/editor.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {DropFilesComponent} from "@common/components/files/drop-files/drop-files.component"
import {Subject, filter, take, interval, switchMap, from, tap, firstValueFrom, map} from "rxjs"
import {NodeUtils} from "@cm/template-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {colorToString, parseColor} from "@cm/utils"
import {ConfigVariantIconDataObjectFragment} from "@api"
import {NotificationsService} from "@common/services/notifications/notifications.service"

@Component({
    selector: "cm-config-variant-inspector",
    templateUrl: "./config-variant-inspector.component.html",
    styleUrls: ["./config-variant-inspector.component.scss"],
    standalone: true,
    imports: [StringInputComponent, InputContainerComponent, InspectorSectionComponent, MatTooltipModule, FormsModule, MatButtonModule, DropFilesComponent],
})
export class ConfigVariantInspectorComponent implements OnDestroy {
    @ViewChild("dropZone", {static: true}) dropZone!: ElementRef<HTMLElement>
    @Input() editorService!: EditorService
    @Input() editor!: IEditor
    gridSizes = GridSize
    isValidId = NodeUtils.isValidId

    organizations = inject(OrganizationsService)

    _node!: Nodes.ConfigVariant
    @Input() set node(value: Nodes.ConfigVariant) {
        this._node = value
        this.iconDataObject = null
        this.fetchIconDataObject()
    }

    get node(): Nodes.ConfigVariant {
        return this._node
    }

    dropZoneActive = {value: false}
    Settings = Settings
    iconDataObject: ConfigVariantIconDataObjectFragment | null = null
    private unsubscribe: Subject<void> = new Subject()

    constructor(
        private uploadGqlService: UploadGqlService,
        private notificationService: NotificationsService,
        private sdk: SdkService,
    ) {}

    private async fetchIconDataObject(refetchUntilThumbnailAvailable = false) {
        if (this.node.iconDataObjectId == null) {
            this.iconDataObject = null
            return this.iconDataObject
        }
        return firstValueFrom(
            interval(500).pipe(
                switchMap(() => from(this.sdk.gql.configVariantIconDataObject({legacyId: this.node.iconDataObjectId}))),
                tap((result) => {
                    this.iconDataObject = result?.dataObject ?? null
                }),
                filter((result) => !refetchUntilThumbnailAvailable || (result.dataObject != null && result.dataObject.thumbnailAvailable)),
                take(1),
                map((result) => this.iconDataObject),
            ),
        )
    }

    async handleDroppedIconFile(files: File[]) {
        if (files.length != 1) {
            this.notificationService.showError("Only one file can be dropped at a time.")
            return
        }
        if (this.editorService.organizationLegacyId == null) throw new Error("Organization ID is not set.")

        const dataObject = await this.uploadGqlService.createAndUploadDataObject(
            files[0],
            {organizationLegacyId: this.editorService.organizationLegacyId},
            {
                showUploadToolbar: true,
                processUpload: true,
            },
        )

        this.node.iconDataObjectId = dataObject.legacyId
        this.updateNode(this.node)
        await this.fetchIconDataObject(true)
    }

    get iconColor(): string {
        if (this.node) {
            return colorToString(this.node.iconColor)
        } else {
            return "#000"
        }
    }

    set iconColor(value: string) {
        if (this.node) {
            this.node.iconColor = parseColor(value)
            this.updateNode(this.node)
        }
    }

    deleteIcon(): void {
        delete this.node.iconDataObjectId
        this.updateNode(this.node)
        this.iconDataObject = null
    }

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
