import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {TexNoiseInputs, TexNoiseOutputs} from "@material-editor/models/nodes/tex-noise"

@Component({
    selector: "cm-tex-noise-node",
    templateUrl: "./tex-noise.component.html",
    styleUrls: ["./tex-noise.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class TexNoiseNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = TexNoiseInputs
    outputs = TexNoiseOutputs
    typeInfo = TexNoiseNodeType
}

export const TexNoiseNodeType: MaterialNodeType<typeof TexNoiseNodeComponent> = {
    id: "texNoise",
    label: "Noise Texture",
    color: "#4987af",
    name: "ShaderNodeTexNoise",
    inputs: [
        TexNoiseInputs.vector,
        TexNoiseInputs.scale,
        TexNoiseInputs.detail,
        // TexNoiseInputs.roughness,
        // TexNoiseInputs.lacunarit,
        TexNoiseInputs.distortion,
    ] as never[],
    outputs: [TexNoiseOutputs.color, TexNoiseOutputs.fac],
    component: TexNoiseNodeComponent,
}
