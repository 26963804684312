<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [$title]="$item()?.name"
                    [$iconClass]="'far fa-lightbulb'"
                    [$isLoaded]="$item() !== undefined"
                    [$canEdit]="$can().update.item($item())"
                    (onChanged)="updateItem({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>
            <ng-container cm-buttons>
                @if ($item(); as hdri) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <div mat-menu-item [style.cursor]="'pointer'" [matMenuTriggerFor]="copyItemsMenu">
                            <mat-icon>file_copy</mat-icon>
                            Copy
                        </div>
                        <cm-copy-value-to-clipboard-menu #copyItemsMenu [$copyItems]="$copyItems()"></cm-copy-value-to-clipboard-menu>
                        <button mat-menu-item (click)="requestDeleteConfirmation()" [disabled]="!hdri || !$can().delete.item(hdri)">Delete HDRI</button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-main>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-image"></i></ng-container>
                    <ng-container cm-title>Gallery image</ng-container>
                    <cm-drop-files cm-content [enableDrop]="$canAddHdri()" (filesDropped)="uploadHdri($event)">
                        <cm-data-object-thumbnail [dataObjectId]="$item()?.galleryImage?.id" [resolution]="DownloadResolution.High"></cm-data-object-thumbnail>
                    </cm-drop-files>
                </cm-section>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-paperclip"></i></ng-container>
                    <ng-container cm-title>Files</ng-container>
                    <ng-container cm-content>
                        <cm-attachments [item]="$item()" class="cm-files"></cm-attachments>
                    </ng-container>
                </cm-section>
            </ng-container>
            <ng-container cm-sidebar>
                <cm-organization-select
                    [cmIsLoading]="!$item()"
                    [updateOrganization]="updateItem.bind(this)"
                    [$organizationId]="$item()?.organization?.id ?? null"
                ></cm-organization-select>
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
