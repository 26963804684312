import {MaterialSocket} from "@material-editor/models/material-socket"

export const MixShaderOutputs: Record<string, MaterialSocket> = {
    shader: {paramType: "socket", id: "Shader", type: "output", valueType: "output", label: "Shader"},
}

export const MixShaderInputs: Record<string, MaterialSocket> = {
    shader: {paramType: "socket", id: "Shader", type: "input", valueType: "plain", label: "Shader"},
    shader_001: {paramType: "socket", id: "Shader_001", type: "input", valueType: "plain", label: "Shader"},
    fac: {paramType: "socket", id: "Fac", type: "input", valueType: "scalar", label: "Fac"},
}
