import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeValueNode, threeVec3Node} from "#material-nodes/three-utils"
import {vec3} from "#material-nodes/types"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({vector: materialSlots})
const InputTypeSchema = z.object({
    vector: materialSlots.optional(),
    vector_001: materialSlots.optional(),
    vector_002: materialSlots.optional(),
    scale: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    operation: z
        .enum([
            "ADD",
            "SUBTRACT",
            "MULTIPLY",
            "DIVIDE",
            "CROSS_PRODUCT",
            "PROJECT",
            "REFLECT",
            "REFRACT",
            "FACEFORWARD",
            "MULTIPLY_ADD",
            "DOT_PRODUCT",
            "DISTANCE",
            "LENGTH",
            "SCALE",
            "NORMALIZE",
            "SNAP",
            "FLOOR",
            "CEIL",
            "MODULO",
            "WRAP",
            "FRACTION",
            "ABSOLUTE",
            "MINIMUM",
            "MAXIMUM",
            "SINE",
            "COSINE",
            "TANGENT",
            "GREATER_THAN",
            "LESS_THAN",
        ])
        .optional(),
    vector: vec3.or(z.number()).optional(),
    vector_001: vec3.optional(),
    vector_002: vec3.optional(),
    scale: z.number().optional(),
    useClamp: z.boolean().optional(),
})

export class VectorMath extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {vector: valueA, vector_001: valueB, vector_002: valueC, scale} = await getAll(inputs, get)
            const inputA =
                valueA ??
                (typeof parameters.vector === "number"
                    ? threeVec3Node({x: parameters.vector, y: parameters.vector, z: parameters.vector})
                    : threeConvert(parameters.vector, threeVec3Node)) ??
                threeVec3Node({x: 0, y: 0, z: 0})
            const inputB = valueB ?? threeConvert(parameters.vector_001, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const inputC = valueC ?? threeConvert(parameters.vector_002, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const scaleValue = scale ?? threeConvert(parameters.scale, threeValueNode) ?? threeValueNode(1)
            const {useClamp} = parameters
            const operation = parameters.operation ?? "ADD"

            const getValue = () => {
                if (operation === "ADD") {
                    return THREENodes.add(inputA, inputB)
                } else if (operation === "SUBTRACT") {
                    return THREENodes.sub(inputA, inputB)
                } else if (operation === "MULTIPLY") {
                    return THREENodes.mul(inputA, inputB)
                } else if (operation === "DIVIDE") {
                    return THREENodes.div(inputA, inputB)
                } else if (operation === "DISTANCE") {
                    return THREENodes.distance(inputA, inputB)
                } else if (operation === "LENGTH") {
                    return THREENodes.length(inputA)
                } else if (operation === "NORMALIZE") {
                    return THREENodes.normalize(inputA)
                } else {
                    throw new Error(`Invalid operation: ${operation}`)
                }
            }

            const value = getValue()
            return {vector: useClamp ? THREENodes.clamp(value, threeValueNode(0), threeValueNode(1)) : value}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
