import {EventEmitter} from "@angular/core"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {gaussianBlur} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/gaussian-blur"
import {affineTransform} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-affine-transform"
import {blend} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-blend"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {Matrix3x2, Vector2Like} from "@cm/math"
import {deepCopy} from "@cm/utils"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {
    Operator,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {HalInvertMask} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/hal/hal-invert-mask"
import {LayerAndMaskPanelComponent} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/panel/layer-and-mask-panel.component"
import {LayerAndMaskToolbox} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/toolbox/layer-and-mask-toolbox"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"

export class OperatorLayerAndMask extends OperatorBase<TextureEditNodes.OperatorLayerAndMask> {
    readonly showGuidesChanged = new EventEmitter<boolean>()
    readonly layerEditModeChanged = new EventEmitter<LayerEditMode>()
    readonly layerMoveModeChanged = new EventEmitter<LayerMoveMode>()

    readonly panelComponentType: OperatorPanelComponentType = LayerAndMaskPanelComponent
    readonly canvasToolbox: LayerAndMaskToolbox

    readonly type = "operator-layer-and-mask" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorLayerAndMask | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-layer-and-mask",
                enabled: true,
                maskReference: {
                    type: "data-object-reference",
                    dataObjectId: "",
                },
                mapOffsetInPixels: {x: 0, y: 0},
                maskOffsetInPixels: {x: 0, y: 0},
                layerMinOpacity: 0,
                layerMaxOpacity: 1,
                maskFeathering: 0,
            },
        )

        this._maskDrawableImageRef = new DrawableImageRef(this.node.maskReference, this.callback.organization.legacyId)

        this.canvasToolbox = new LayerAndMaskToolbox(this)
        this.canvasToolbox.setMaskReference(this._maskDrawableImageRef)
        this.halInvertMask = new HalInvertMask(this.callback.halContext)
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.callback.drawableImageCache.removeDrawableImage(this._maskDrawableImageRef)
        this.canvasToolbox.remove()
        this.halInvertMask.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorLayerAndMask(this.callback, deepCopy(this.node))
        await this.callback.drawableImageCache.cloneImage(this._maskDrawableImageRef, clonedOperator._maskDrawableImageRef)
        return clonedOperator
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        cmdQueue.beginScope(this.type)
        const maskImage = drawableImage(cmdQueue, {
            drawableImageRef: this._maskDrawableImageRef,
            descriptor: {
                width: input.descriptor.width,
                height: input.descriptor.height,
                channelLayout: "R",
                dataType: "uint8",
            },
        })
        let shiftedMaskImage = affineTransform(cmdQueue, {
            sourceImage: maskImage,
            transform: new Matrix3x2().translate(this.maskOffsetInPixels),
        })
        if (this.maskFeathering > 0) {
            shiftedMaskImage = gaussianBlur(cmdQueue, {
                sourceImage: shiftedMaskImage,
                sigma: (this.maskFeathering + 1) / 3,
                borderMode: "wrap",
            })
        }
        const drawMaskOnly = cmdQueue.mode === "preview" && this._showMask && this.selected
        if (drawMaskOnly) {
            cmdQueue.endScope(this.type)
            return {
                resultImage: shiftedMaskImage,
                options: {
                    stopEvaluation: true,
                },
            }
        } else {
            if (this.layerMinOpacity !== 0 || this.layerMaxOpacity !== 1) {
                const offset = this.layerMinOpacity
                const scale = this.layerMaxOpacity - this.layerMinOpacity
                if (scale !== 1) {
                    shiftedMaskImage = math(cmdQueue, {
                        operandA: shiftedMaskImage,
                        operandB: scale,
                        operator: "*",
                    })
                }
                if (offset !== 0) {
                    shiftedMaskImage = math(cmdQueue, {
                        operandA: shiftedMaskImage,
                        operandB: offset,
                        operator: "+",
                    })
                }
            }
            const shiftedSourceImage = affineTransform(cmdQueue, {
                sourceImage: input,
                transform: new Matrix3x2().translate(this.mapOffsetInPixels),
            })
            const resultImage = blend(cmdQueue, {
                backgroundImage: input,
                foregroundImage: shiftedSourceImage,
                alpha: shiftedMaskImage,
                premultipliedAlpha: false,
                blendMode: "normal",
            })
            cmdQueue.endScope(this.type)
            return {
                resultImage,
            }
        }
    }

    get mapOffsetInPixels(): Vector2Like {
        return this.node.mapOffsetInPixels
    }

    set mapOffsetInPixels(value: Vector2Like) {
        if (this.node.mapOffsetInPixels.x === value.x && this.node.mapOffsetInPixels.y === value.y) {
            return
        }
        this.node.mapOffsetInPixels = {x: value.x, y: value.y}
        this.markEdited()
        this.requestEval()
    }

    get maskOffsetInPixels(): Vector2Like {
        return this.node.maskOffsetInPixels
    }

    set maskOffsetInPixels(value: Vector2Like) {
        if (this.node.maskOffsetInPixels.x === value.x && this.node.maskOffsetInPixels.y === value.y) {
            return
        }
        this.node.maskOffsetInPixels = {x: value.x, y: value.y}
        this.markEdited()
        this.requestEval()
    }

    get layerMinOpacity(): number {
        return this.node.layerMinOpacity ?? 0
    }

    set layerMinOpacity(value: number) {
        if (this.node.layerMinOpacity === value) {
            return
        }
        this.node.layerMinOpacity = value
        this.markEdited()
        this.requestEval()
    }

    get layerMaxOpacity(): number {
        return this.node.layerMaxOpacity ?? 1
    }

    set layerMaxOpacity(value: number) {
        if (this.node.layerMaxOpacity === value) {
            return
        }
        this.node.layerMaxOpacity = value
        this.markEdited()
        this.requestEval()
    }

    get maskFeathering(): number {
        return this.node.maskFeathering ?? 0
    }

    set maskFeathering(value: number) {
        if (this.node.maskFeathering === value) {
            return
        }
        this.node.maskFeathering = value
        this.markEdited()
        this.requestEval()
    }

    get showGuides(): boolean {
        return this._showGuides
    }

    set showGuides(value: boolean) {
        if (this._showGuides === value) {
            return
        }
        this._showGuides = value
        this.showGuidesChanged.emit(value)
    }

    set showMask(value: boolean) {
        if (this._showMask === value) {
            return
        }
        this._showMask = value
        this.requestEval()
    }

    get showMask(): boolean {
        return this._showMask
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    get layerEditMode(): LayerEditMode {
        return this._layerEditMode
    }

    set layerEditMode(value: LayerEditMode) {
        if (this._layerEditMode === value) {
            return
        }
        this._layerEditMode = value
        this.layerEditModeChanged.emit(value)
    }

    get layerMoveMode(): LayerMoveMode {
        return this._layerMoveSettings.layerMoveMode
    }

    set layerMoveMode(value: LayerMoveMode) {
        if (this._layerMoveSettings.layerMoveMode === value) {
            return
        }
        this._layerMoveSettings.layerMoveMode = value
        this.layerMoveModeChanged.emit(value)
    }

    async invertMask(): Promise<void> {
        const context = this.callback.imageOpContextWebGL2
        const cmdQueue = context.createCommandQueue()

        cmdQueue.beginScope("invert-mask")
        const painter = cmdQueue.createPainter(
            "compositor",
            "invert-mask",
            `
            vec4 computeColor(ivec2 targetPixel) {
                return 1.0 - texelFetch0(targetPixel);
            }
            `,
        )
        const strokeImage = await this.canvasToolbox.getStrokeImage()
        const maskImage = drawableImage(cmdQueue, {
            drawableImageRef: this._maskDrawableImageRef,
            descriptor: strokeImage.descriptor,
        })
        const invertedStrokeImage = cmdQueue.createImage(strokeImage.descriptor)
        cmdQueue.paint(painter, {
            sourceImages: maskImage,
            resultImage: invertedStrokeImage,
        })
        cmdQueue.endScope("invert-mask")

        const [evaluatedInvertedStrokeImage] = await cmdQueue.execute([invertedStrokeImage])
        await this.canvasToolbox.setStrokeImage(evaluatedInvertedStrokeImage.ref.halImageView.resource)
        evaluatedInvertedStrokeImage.release()

        this.markEdited()
        this.requestEval()
    }

    override async save(_processingJobId: string): Promise<TextureEditNodes.Operator> {
        this.resetEdited()
        return deepCopy(this.node) // make a copy to detach the maskReference from the drawableImageRef
    }

    private _layerEditMode = LayerEditMode.Move
    private _layerMoveSettings = new LayerMoveSettings()
    private _brushSettings = new BrushSettings()
    private _showGuides = true
    private _showMask = false
    private _maskDrawableImageRef: DrawableImageRef

    private halInvertMask: HalInvertMask // TODO replace by image-op
}

export enum LayerEditMode {
    Move = "move",
    Draw = "draw",
}

export class LayerMoveSettings {
    readonly changed = new EventEmitter<void>()

    set layerMoveMode(value: LayerMoveMode) {
        this._layerMoveMode = value
        this.changed.emit()
    }

    get layerMoveMode(): LayerMoveMode {
        return this._layerMoveMode
    }

    private _layerMoveMode = LayerMoveMode.ImageAndMask
}

export enum LayerMoveMode {
    ImageAndMask = "image-and-mask",
    ImageOnly = "image-only",
    MaskOnly = "mask-only",
}
