<mat-table [dataSource]="$data().items" #matTable="matTable">
    <ng-container matColumnDef="legacyId">
        <mat-header-cell *matHeaderCellDef class="legacy-id-column"> Legacy ID</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable" class="legacy-id-column">
            <cm-table-cell [item]="row">
                {{ row.data?.legacyId }}
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable">
            <cm-table-cell [item]="row">
                <cm-overflowable-text>
                    {{ row.data?.name }}
                </cm-overflowable-text>
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable">
            <cm-table-cell [item]="row">
                {{ row.data?.type }}
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="color">
        <mat-header-cell *matHeaderCellDef class="color-column"> Color</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable" class="color-column">
            <cm-table-cell [item]="row">
                <cm-tag-thumbnail [tag]="row.data"></cm-tag-thumbnail>
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef> Organization</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable">
            <cm-table-cell [item]="row">
                <cm-overflowable-text>
                    {{ row.data?.organization?.name ?? "-" }}
                </cm-overflowable-text>
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable">
            <cm-table-cell [item]="row">
                <cm-overflowable-text>
                    {{ row.data?.description }}
                </cm-overflowable-text>
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignmentsCount">
        <mat-header-cell *matHeaderCellDef> Assignments</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable">
            <cm-table-cell [item]="row">
                {{ row.data?.assignmentsCount }}
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
        <mat-cell *matCellDef="let row; table: matTable">
            <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" [disabled]="!row.data" aria-label="More actions">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                @if (row.data) {
                    <button
                        mat-menu-item
                        (click)="deleteItem(row.data)"
                        [disabled]="!!row.data.assignmentsCount"
                        [matTooltip]="!!row.data.assignmentsCount ? 'Tags with assignments cannot be deleted' : ''"
                    >
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                    </button>
                }
            </mat-menu>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="$columns()"></mat-header-row>
    <mat-row
        class="row-template"
        (click)="router.navigate(['/tags', row.data.id], {queryParamsHandling: 'preserve'})"
        [class.cm-disabled]="!row.data"
        *matRowDef="let row; columns: $columns()"
    ></mat-row>
</mat-table>
<cm-infinite-scroll-anchor (intersectionChange)="loadMore$.next($event)"></cm-infinite-scroll-anchor>
