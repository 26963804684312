<cm-input-container>
    <ng-container cm-top-label>{{ $label() }}</ng-container>
    <ng-container cm-input>
        <cm-native-input-text-area
            [disabled]="!$canEdit()"
            [initialValue]="$item()?.description ?? ''"
            [minRows]="$minRows()"
            (valueChange)="$onChanged.emit({description: $event})"
        >
        </cm-native-input-text-area>
    </ng-container>
</cm-input-container>
