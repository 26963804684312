import {createMeshBuffersForMaterialGroups} from "@cm/template-nodes"
import {SceneNodes} from "@cm/template-nodes"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Three as THREE} from "@cm/material-nodes/three"
import {MeshBuffersWireframGeometry} from "./mesh-buffers-wireframe-geometry"

export class ThreeWireframeMesh extends ThreeObject<SceneNodes.WireframeMesh> {
    protected override renderObject: THREE.Group = new THREE.Group()

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)
        setThreeObjectPart(this.renderObject, this)
    }

    override setup(sceneNode: SceneNodes.WireframeMesh) {
        const differenceDetected =
            objectFieldsDifferent(sceneNode.meshData, this.parameters?.meshData, ["uvs", "materialGroups", "vertices", "normals", "faceIDs"], undefined) ||
            objectFieldsDifferent(sceneNode, this.parameters, ["channel"], undefined)

        if (differenceDetected) {
            this.dispose(false)

            const {channel} = sceneNode
            const {uvs, materialGroups, vertices, normals, faceIDs} = sceneNode.meshData
            const meshBuffersByGroup = createMeshBuffersForMaterialGroups(vertices, normals, uvs, faceIDs, materialGroups)
            for (const meshBuffers of meshBuffersByGroup) {
                if (meshBuffers.indices.length === 0) continue

                const edges = new MeshBuffersWireframGeometry(meshBuffers, channel)
                const wireframe = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color: 0x000000, linewidth: 1}))
                wireframe.renderOrder = 1

                this.renderObject.add(wireframe)
            }
        }

        return anyDifference([
            differenceDetected,
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    updateTransform(transform, this.renderObject)
                },
            ),
        ])
    }

    override dispose(final: boolean) {
        const {materialManagerService} = this.threeSceneManagerService

        this.renderObject.children.forEach((child) => {
            if (child instanceof THREE.LineSegments) {
                const wireframe = child as THREE.LineSegments<MeshBuffersWireframGeometry, THREE.LineBasicMaterial, THREE.Object3DEventMap>
                wireframe.geometry.dispose()
                materialManagerService.releaseMaterial(wireframe.material)
            }
        })
        this.renderObject.clear()
    }
}
