import {Nodes} from "#template-nodes/legacy/template-nodes"

export type EvaluatedTemplateValueType = "material" | "template" | "object" | "image" | "string" | "number" | "boolean" | "unknown"
export type EvaluatedTemplateInput = {type: EvaluatedTemplateValueType; value: any}
export type EvaluatedTemplateInputs = {[inputId: string]: EvaluatedTemplateInput}
export type EvaluatedTemplateOutputs = {[outputId: string]: any}

export function getTemplateInput<T>(inputs: EvaluatedTemplateInputs, node: Nodes.TemplateInput | Nodes.ConfigGroup | Nodes.MaterialReference): T | undefined {
    const externalId = Nodes.getExternalId(node)
    if (externalId == undefined) return undefined
    const entry = inputs[externalId]
    if (!entry) return undefined
    if (node.type === "configGroup") {
        return entry.type === "string" ? (entry.value as T) : undefined
    } else if (node.type === "materialReference") {
        if (node.allowOverride) {
            return entry.type === "material" ? (entry.value as T) : undefined
        } else {
            return undefined
        }
    } else {
        return entry.type === node.inputType ? (entry.value as T) : undefined
    }
}
