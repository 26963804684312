import {
    DeclareNodeGraphTS,
    Evaluated,
    isNodeGraphInstance,
    NodeGraph,
    NodeGraphClass,
    NodeGraphMeta,
    NodeParameters,
    nodeParameters,
    NodeParamSyncEvaluator,
    Version,
} from "#graph/node-graph"
import {getAllSync} from "#graph/utils"
import {mapFields} from "@cm/utils"
import {z} from "zod"

export const skipped = "---unvisited---" as const

export type PartlyVisited<T> = T extends NodeGraph<any, any, {}> ? Evaluated<T> | typeof skipped : Evaluated<T>

export type PartlyVisitedResult<ParamTypes extends NodeParameters> = {
    [P in keyof ParamTypes]: PartlyVisited<ParamTypes[P]>
}

export type VisitorNodeContext<Context> = {skipNode?: () => boolean; onPostUnskippedNode?: () => void} & Context

export type VisitorNodeResult<ParamTypes extends NodeParameters> = PartlyVisitedResult<ParamTypes> | typeof skipped

export const visitorContext = (contextSchema: z.AnyZodObject | undefined) => {
    const schema = z.object({
        skipNode: z.function(z.tuple([]), z.boolean()).optional(),
        onPostUnskippedNode: z.function(z.tuple([]), z.void()).optional(),
    })

    return contextSchema ? schema.merge(contextSchema) : schema.passthrough()
}

export type VisitorNodeOnVisited<Context, ParamTypes extends NodeParameters> = (data: {
    visit: NodeParamSyncEvaluator
    context: Context
    parameters: ParamTypes
}) => VisitorNodeResult<ParamTypes>

export type VisitorNodeImplementation<Context, ParamTypes extends NodeParameters> = {
    onVisited?: VisitorNodeOnVisited<VisitorNodeContext<Context>, ParamTypes>
}

export type VisitorNodeTSImplementation<Context, ParamTypes extends NodeParameters> = VisitorNodeImplementation<Context, ParamTypes> & {
    validation?: {
        contextSchema?: z.AnyZodObject
        paramsSchema?: z.ZodType<NodeParameters>
    }
}

export type VisitorNodeMeta<ParamTypes extends NodeParameters> = NodeGraphMeta<ParamTypes>

export function DeclareVisitorNode<ZodContextType extends z.AnyZodObject, ZodParamTypes extends z.AnyZodObject>(
    definition: {
        context: ZodContextType
        parameters: ZodParamTypes
    },
    implementation: VisitorNodeImplementation<z.infer<typeof definition.context>, z.infer<typeof definition.parameters>>,
    meta?: VisitorNodeMeta<z.infer<typeof definition.parameters>>,
) {
    const {context: contextSchema, parameters: paramsSchema} = definition
    type Context = z.infer<typeof contextSchema>
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareVisitorNodeTS<Context, ParamTypes>({...implementation, validation: {contextSchema, paramsSchema}}, meta)
}

export function DeclareVisitorNodeTS<Context extends object, ParamTypes extends NodeParameters>(
    implementation: VisitorNodeTSImplementation<Context, ParamTypes>,
    meta?: VisitorNodeMeta<ParamTypes>,
) {
    const {onVisited, validation} = implementation
    return DeclareNodeGraphTS<VisitorNodeResult<ParamTypes>, VisitorNodeContext<Context>, ParamTypes>(
        {
            runSync: function (this, {get, context, parameters}) {
                if (context.skipNode?.bind(this)()) return skipped
                const result = onVisited ? onVisited.bind(this)({visit: get, context, parameters}) : visitAll(parameters, get)
                if (result !== skipped) context.onPostUnskippedNode?.bind(this)()
                return result
            },
            validation: {
                contextSchema: visitorContext(validation?.contextSchema),
                paramsSchema: validation?.paramsSchema,
                returnSchema: nodeParameters.or(z.literal(skipped)),
            },
        },
        meta,
    )
}

export type ModedNodeContext<VisitMode extends string, Context extends {[key in VisitMode]?: object}> = Context & {visitMode: VisitMode}

export const modedNodeContext = (contextSchema: z.AnyZodObject | undefined, visitModeSchema: z.ZodEnum<[string, ...string[]]> | undefined) => {
    const schema = z.object({visitMode: visitModeSchema ?? z.string()}).passthrough()
    return contextSchema ? schema.merge(contextSchema) : schema
}

export type ModedVisitorNodeImplementation<VisitMode extends string, Context extends {[key in VisitMode]?: object}, ParamTypes extends NodeParameters> = {
    onVisited?: {[key in VisitMode]?: VisitorNodeOnVisited<VisitorNodeContext<NonNullable<Context[key]>>, ParamTypes>}
} & {
    defaultVisitor?: VisitorNodeOnVisited<VisitorNodeContext<ModedNodeContext<VisitMode, Context>>, ParamTypes>
}

export type ModedVisitorNodeTSImplementation<
    VisitMode extends string,
    Context extends {[key in VisitMode]?: object},
    ParamTypes extends NodeParameters,
> = ModedVisitorNodeImplementation<VisitMode, Context, ParamTypes> & {
    validation?: {
        visitModeSchema?: z.ZodEnum<[string, ...string[]]>
        contextSchema?: z.AnyZodObject
        paramsSchema?: z.ZodType<NodeParameters>
    }
}

export function DeclareModedVisitorNode<
    ZodVisitMode extends z.ZodEnum<[string, ...string[]]>,
    ZodContextType extends z.ZodType<{[key in z.infer<ZodVisitMode>]?: object}>,
    ZodParamTypes extends z.ZodType<NodeParameters>,
>(
    definition: {
        visitMode: ZodVisitMode
        context: ZodContextType
        parameters: ZodParamTypes
    },
    implementation: ModedVisitorNodeImplementation<
        z.infer<typeof definition.visitMode>,
        z.infer<typeof definition.context>,
        z.infer<typeof definition.parameters>
    >,
    meta?: VisitorNodeMeta<z.infer<typeof definition.parameters>>,
) {
    const {visitMode: visitModeSchema, context: contextSchema, parameters: paramsSchema} = definition
    type VisitMode = z.infer<typeof visitModeSchema>
    type Context = z.infer<typeof contextSchema>
    type ParamTypes = z.infer<typeof paramsSchema>

    if (contextSchema && !(contextSchema instanceof z.ZodObject)) throw new Error("Context schema must be an object schema")

    return DeclareModedVisitorNodeTS<VisitMode, Context, ParamTypes>({...implementation, validation: {visitModeSchema, contextSchema, paramsSchema}}, meta)
}

export function DeclareModedVisitorNodeTS<VisitMode extends string, Context extends {[key in VisitMode]?: object}, ParamTypes extends NodeParameters>(
    implementation: ModedVisitorNodeTSImplementation<VisitMode, Context, ParamTypes>,
    meta?: VisitorNodeMeta<ParamTypes>,
) {
    const {onVisited: visitors, defaultVisitor, validation} = implementation
    return DeclareVisitorNodeTS<ModedNodeContext<VisitMode, Context>, ParamTypes>(
        {
            onVisited: function (this, {visit, context, parameters}) {
                const visitor = visitors?.[context.visitMode]
                if (visitor) {
                    const visitorContext = context[context.visitMode]
                    if (!visitorContext) throw new Error(`No suitable visitor context ${context.visitMode} provided`)
                    const {skipNode, onPostUnskippedNode} = context
                    return visitor.bind(this)({visit, context: {...visitorContext, skipNode, onPostUnskippedNode}, parameters})
                } else if (defaultVisitor) return defaultVisitor.bind(this)({visit, context, parameters})
                else return visitAll(parameters, visit)
            },
            validation: {
                contextSchema: modedNodeContext(validation?.contextSchema, validation?.visitModeSchema),
                paramsSchema: validation?.paramsSchema,
            },
        },
        meta,
    )
}

export type VisitorNode<Context, ParamTypes extends NodeParameters> = NodeGraph<VisitorNodeResult<ParamTypes>, VisitorNodeContext<Context>, ParamTypes>

export type VisitorNodeVersion<ParamTypesFrom extends NodeParameters, ParamTypesTo extends NodeParameters> = Version<ParamTypesFrom, ParamTypesTo>

export function visitAll<ParamTypes extends NodeParameters>(parameters: ParamTypes, visit: NodeParamSyncEvaluator): PartlyVisitedResult<ParamTypes> {
    return getAllSync(parameters, visit) as PartlyVisitedResult<ParamTypes>
}

export function visitNone<ParamTypes extends NodeParameters>(parameters: ParamTypes): PartlyVisitedResult<ParamTypes> {
    return mapFields(parameters, (value) => {
        if (isNodeGraphInstance(value)) return skipped
        return value
    }) as PartlyVisitedResult<ParamTypes>
}

export function DeclareVisiteableListNode<ZodContextType extends z.AnyZodObject, ZodItemType extends z.ZodType>(
    definition: {
        context: ZodContextType
        item: ZodItemType
    },
    meta?: VisitorNodeMeta<{list: z.infer<typeof definition.item>[]}>,
) {
    const {context: contextSchema, item: itemSchema} = definition
    type Context = z.infer<typeof contextSchema>
    type ItemType = z.infer<typeof itemSchema>

    return DeclareVisiteableListNodeTS<Context, ItemType>({contextSchema, itemSchema}, meta)
}

export function DeclareVisiteableListNodeTS<Context extends object, ItemType>(
    validation?: {
        contextSchema?: z.AnyZodObject
        itemSchema?: z.ZodType
    },
    meta?: VisitorNodeMeta<{list: ItemType[]}>,
): NodeGraphClass<VisiteableListNode<Context, ItemType>> {
    type ParamTypes = {list: ItemType[]}

    const itemTypeSchema = validation?.itemSchema ?? z.any()
    const paramsSchema = z.object({list: z.array(itemTypeSchema)})

    const retClass = class extends DeclareVisitorNodeTS<Context, ParamTypes>(
        {
            onVisited: ({visit, parameters}) => {
                return {list: parameters.list.map((entry) => visit(entry))} as PartlyVisitedResult<ParamTypes>
            },
            validation: {contextSchema: validation?.contextSchema, paramsSchema},
        },
        meta,
    ) {
        visit(context: VisitorNodeContext<unknown>, get: NodeParamSyncEvaluator, visit: (node: ItemType) => boolean) {
            if (context.skipNode?.bind(this)()) return skipped
            const result = {list: this.parameters.list.map((entry) => (visit(entry) ? get(entry) : skipped))} as PartlyVisitedResult<ParamTypes>
            context.onPostUnskippedNode?.bind(this)()
            return result
        }

        addEntry(entry: ItemType) {
            const newList = [...this.parameters.list, entry]
            this.replaceParameters({list: newList})
        }

        removeEntry(entry: ItemType) {
            const {list} = this.parameters

            const index = list.indexOf(entry)
            if (index === -1) return

            const newList = [...list]
            newList.splice(index, 1)
            this.replaceParameters({list: newList})
        }

        clear() {
            this.replaceParameters({list: []})
        }
    }

    return retClass
}

export type VisiteableListNode<Context, ItemType> = VisitorNode<Context, {list: ItemType[]}> & {
    visit(
        context: VisitorNodeContext<Context>,
        get: NodeParamSyncEvaluator,
        visit: (node: ItemType) => boolean,
    ): PartlyVisitedResult<{list: ItemType[]}> | typeof skipped
    addEntry(entry: ItemType): void
    removeEntry(entry: ItemType): void
    clear(): void
}
