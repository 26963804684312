import {CdkTreeModule, NestedTreeControl} from "@angular/cdk/tree"
import {AsyncPipe} from "@angular/common"
import {Component, DestroyRef, inject, Input, input, OnInit} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {FormControl, ReactiveFormsModule} from "@angular/forms"
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete"
import {MatDialog} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatTreeNestedDataSource} from "@angular/material/tree"
import {ActivatedRoute, Router, RouterModule} from "@angular/router"
import {
    BasicOrganizationInfoFragment,
    DownloadResolution,
    OrganizationDetailsFragment,
    ProjectTreeProjectFragment,
    ProjectTreeSetFragment,
    ProjectTreeUserFragment,
} from "@api"
import {IsNonNull, IsDefined} from "@cm/utils/filter"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DialogRenameComponent} from "@common/components/dialogs/dialog-rename/dialog-rename.component"
import {ListItemComponent} from "@common/components/item"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {Settings} from "@common/models/settings/settings"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UtilsService} from "@legacy/helpers/utils"
import {from, map, Observable, of, switchMap} from "rxjs"

@Component({
    selector: "cm-project-tree",
    templateUrl: "./project-tree.component.html",
    styleUrls: ["./project-tree.component.scss"],
    standalone: true,
    imports: [
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        CdkTreeModule,
        RouterModule,
        MatTooltipModule,
        MatMenuModule,
        AsyncPipe,
        ListItemComponent,
        DataObjectThumbnailComponent,
    ],
})
export class ProjectTreeComponent implements OnInit {
    settings = Settings
    @Input() currentProjectId?: string
    @Input() currentSetId?: string
    $organization = input<{id: string} | undefined>(undefined)
    dataSource?: MatTreeNestedDataSource<ProjectTreeProjectFragment | ProjectTreeSetFragment>
    projects?: ProjectTreeProjectFragment[]
    treeControl = new NestedTreeControl<ProjectTreeProjectFragment | ProjectTreeSetFragment>((node) => ("sets" in node ? node.sets : undefined))
    hasChild = (_: number, node: ProjectTreeProjectFragment | ProjectTreeSetFragment) => ("sets" in node ? !!node.sets : false)
    customerInputControl = new FormControl<string | Pick<BasicOrganizationInfoFragment, "name" | "id"> | null>(null)
    filteredOrganizations?: Observable<BasicOrganizationInfoFragment[]>
    visibleUsers: ProjectTreeUserFragment[] | null = null
    public userIsStaff = false

    organizations = inject(OrganizationsService)
    permission = inject(PermissionsService)
    refresh = inject(RefreshService)
    $can = this.permission.$to

    protected readonly DownloadResolution = DownloadResolution

    constructor(
        public dialog: MatDialog,
        public authService: AuthService,
        private route: ActivatedRoute,
        public router: Router,
        private snackBar: MatSnackBar,
        public utils: UtilsService,
        private sdk: SdkService,
        private destroyRef: DestroyRef,
    ) {
        toObservable(this.$organization)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((organization) => {
                    if (!organization) return of(null)
                    this.customerInputControl.setValue(organization)
                    return from(this.fetchProjects(organization.id))
                }),
                map((projects) => {
                    if (!projects) return
                    this.clearTree()
                    this.projects = projects
                    for (const project of this.projects) {
                        if (project.sets.some((set) => set.id === this.currentSetId)) {
                            this.currentProjectId = project.id
                        }
                    }
                    this.initTree(this.projects)
                }),
            )
            .subscribe()
    }

    ngOnInit() {
        this.sdk.gql.projectTreeVisibleUsers().then(({users}) => {
            this.visibleUsers = users.filter(IsDefined)
        })
        this.userIsStaff = this.authService.isStaff()
        this.filteredOrganizations = this.customerInputControl.valueChanges.pipe(
            map((value) => {
                return this.filterCustomers(typeof value === "string" ? value : "")
            }),
        )
    }

    private _isCustomerDropdownOpened = false

    public customerAutocompleteOpened() {
        this._isCustomerDropdownOpened = true
        if (!this._isCustomerAutocompleteInputInFocus) {
            this.customerInputControl.setValue("")
        }
    }

    public customerAutocompleteClosed() {
        if (!this._isCustomerAutocompleteInputInFocus) {
            this.customerInputControl.setValue(this.$organization() ?? "")
        }
        this._isCustomerDropdownOpened = false
    }

    private _isCustomerAutocompleteInputInFocus = false

    public customerAutocompleteInputFocusin() {
        if (!this._isCustomerDropdownOpened) {
            this.customerInputControl.setValue("")
        }
        this._isCustomerAutocompleteInputInFocus = true
    }

    public customerAutocompleteInputFocusout() {
        if (!this._isCustomerDropdownOpened) {
            this.customerInputControl.setValue(this.$organization() ?? "")
        }
        this._isCustomerAutocompleteInputInFocus = false
    }

    private filterCustomers(value: string): BasicOrganizationInfoFragment[] {
        const filterValue = value.toLocaleLowerCase()
        return this.organizations.$all()?.filter((organization) => organization.name?.toLocaleLowerCase()?.startsWith(filterValue)) ?? []
    }

    protected customerDisplayFunction(organization: OrganizationDetailsFragment): string {
        return organization?.name ?? ""
    }

    protected async customerSelected(event: MatAutocompleteSelectedEvent) {
        const organizationId = event.option.value.id
        if (this.$organization()?.id === organizationId) {
            return
        }
        const queryParams = {tab: "projects", organizationId}
        await this.router.navigate([], {queryParams: queryParams})
    }

    initTree(projects: ProjectTreeProjectFragment[]): void {
        this.dataSource = new MatTreeNestedDataSource()
        this.dataSource.data = projects
        this.treeControl.dataNodes = this.dataSource.data
        this.expandSelectedProject()
    }

    clearTree(): void {
        this.projects = []
        this.dataSource = new MatTreeNestedDataSource()
        this.dataSource.data = this.projects
        this.treeControl.dataNodes = this.dataSource.data
    }

    async expandSelectedProject() {
        if (!this.currentProjectId) {
            return
        }
        const project = this.getProjectNode(this.currentProjectId)
        if (project) {
            this.treeControl.expand(project)
            return
        }
        if (!this.currentSetId) return
        const {set} = await this.sdk.gql.projectTreeSet({id: this.currentSetId})
        const newProject = this.getProjectNode(set.project.id)
        if (newProject) {
            this.treeControl.expand(newProject)
        }
    }

    private async fetchProjects(organizationId: string | undefined) {
        if (!organizationId) return null
        try {
            return this.sdk.gql.projectTreeProjects({organizationId}).then((result) => result.projects.filter(IsNonNull))
        } catch {
            this.snackBar.open(`Cannot fetch project for organization id: ${organizationId}`, "", {duration: 3000})
        }
        return null
    }

    onToggleExpanded(item: ProjectTreeProjectFragment | ProjectTreeSetFragment, type: "project" | "set") {
        if (!this.treeControl.isExpanded(item)) {
            this.treeControl.toggleDescendants(item)
        }
        if (type === "set") {
            this.currentProjectId = undefined
            this.currentSetId = item.id
        } else {
            this.currentProjectId = item.id
            this.currentSetId = undefined
        }
    }

    getProjectNode(id: string): ProjectTreeProjectFragment | undefined {
        for (const node of this.treeControl.dataNodes) {
            if (node.id === id) {
                return node as ProjectTreeProjectFragment
            }
        }
        return undefined
    }

    updateProjectName(projectNode: ProjectTreeProjectFragment) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: projectNode.name,
            },
        })
        dialogRef.componentInstance.onConfirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (title: string) => {
            try {
                await this.sdk.gql.projectTreeUpdateProject({input: {id: projectNode.id, name: title}})
                projectNode.name = title
                this.snackBar.open("Changes saved.", "", {duration: 3000})
            } catch {
                this.snackBar.open("Cannot save changes.", "", {duration: 3000})
            }
        })
    }

    updateSetName(setNode: ProjectTreeSetFragment) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: setNode.name,
            },
        })
        dialogRef.componentInstance.onConfirm.subscribe(async (title: string) => {
            try {
                await this.sdk.gql.projectTreeUpdateSet({input: {id: setNode.id, name: title}})
                setNode.name = title
                this.snackBar.open("Changes saved.", "", {duration: 3000})
            } catch {
                this.snackBar.open("Cannot save changes.", "", {duration: 3000})
            }
        })
    }

    addProject() {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "",
            },
        })
        dialogRef.componentInstance.onConfirm.subscribe(async (title: string) => {
            try {
                const organizationId = this.$organization()?.id
                if (!organizationId) {
                    return
                }
                const {createProject: project} = await this.sdk.gql.projectTreeCreateProject({input: {name: title, organizationId}})
                this.projects = [project, ...(this.projects ?? [])]
                if (this.dataSource) {
                    // https://github.com/angular/components/issues/11381
                    this.dataSource.data = []
                    this.dataSource.data = this.projects
                }
                await this.router.navigate([], {
                    relativeTo: this.route,
                    queryParamsHandling: "merge",
                    queryParams: {organizationId: this.$organization()?.id, project: project.id},
                })
            } catch {
                this.snackBar.open("Cannot create project.", "", {duration: 3000})
            }
        })
    }

    addSet(projectId: string) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "",
            },
        })
        dialogRef.componentInstance.onConfirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (title: string) => {
            try {
                const {createSet: set} = await this.sdk.throwable.projectTreeCreateSet({input: {name: title, projectId: projectId}})
                this.projects =
                    this.projects?.map((project) => {
                        if (project.id === projectId) {
                            return {
                                ...project,
                                sets: [...project.sets, set],
                            }
                        }
                        return project
                    }) ?? []
                if (this.dataSource) {
                    this.dataSource.data = this.projects
                }
                const project = this.projects.find((project) => project.id === projectId)
                if (project) {
                    this.treeControl.expand(project)
                }
                await this.router.navigate([], {
                    relativeTo: this.route,
                    queryParamsHandling: "merge",
                    queryParams: {organizationId: this.$organization()?.id, setId: set.id},
                })
            } catch (error) {
                console.error(error)
                this.snackBar.open("Cannot create set.", "", {duration: 3000})
            }
        })
    }

    async deleteProject(projectNode: ProjectTreeProjectFragment) {
        if (projectNode.sets.length) {
            this.snackBar.open("Cannot delete a project with sets, please delete the sets first.", "", {duration: 3000})
            return
        }
        try {
            await this.sdk.throwable.projectTreeDeleteProject({id: projectNode.id})
            this.projects = this.projects?.filter((project) => project.id !== projectNode.id) ?? []
            if (this.dataSource) {
                this.dataSource.data = this.projects
            }
            this.snackBar.open("Project deleted.", "", {duration: 3000})
        } catch {
            this.snackBar.open("Cannot delete project.", "", {duration: 3000})
        }
    }

    async deleteSet(setNode: ProjectTreeSetFragment) {
        try {
            await this.sdk.throwable.projectTreeDeleteSet({id: setNode.id})
            this.projects =
                this.projects
                    ?.filter((project) => project.id !== setNode.id)
                    ?.map((node) => ({...node, sets: node.sets.filter((set) => set.id !== setNode.id)})) ?? []
            if (this.dataSource) {
                this.dataSource.data = this.projects
            }
            this.snackBar.open("Set deleted.", "", {duration: 3000})
        } catch {
            this.snackBar.open("Cannot delete set. Does it still contain pictures?", "", {duration: 3000})
        }
    }

    async setHighestPriority(setNode: ProjectTreeSetFragment) {
        try {
            const {pictures} = await this.sdk.throwable.projectTreePictures({take: 9999, filter: {setId: {equals: setNode.id}}})

            for (const picture of pictures.filter(IsNonNull)) {
                await this.sdk.throwable.projectTreeUpdatePicture({input: {id: picture.id, priorityOrder: 0}})
            }

            this.snackBar.open("Changes saved.", "", {duration: 3000})
        } catch {
            this.snackBar.open("Cannot save changes.", "", {duration: 3000})
        }
    }

    protected readonly ThumbnailLayout = ThumbnailLayout
}
