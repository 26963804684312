import {HalImageView} from "@common/models/hal/hal-image-view"
import {WebGl2Image} from "@common/models/webgl2/webgl2-image"
import {HalViewRegion} from "@common/models/hal/hal-view/types"

export class WebGl2ImageView<ImageClass extends WebGl2Image = WebGl2Image> implements HalImageView {
    constructor(
        readonly resource: ImageClass,
        readonly region: HalViewRegion = {x: 0, y: 0, width: resource.descriptor.width, height: resource.descriptor.height},
    ) {}
}

export type WebGl2ImageOrView = WebGl2Image | WebGl2ImageView
