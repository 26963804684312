import {TemplateThumbnailPostRenderStepInput, templateThumbnailPostRenderStepTask} from "@cm/job-nodes/asset-rendering"
import {CreateJobGraphData, JobNodes} from "@cm/job-nodes/job-nodes"
import {uploadProcessingThumbnailsTask} from "@cm/job-nodes/upload-processing"
import {createPostProcessingGraph, Utility} from "@cm/job-nodes/utility"
import {TemplateThumbnailRenderingFragment} from "@api"
import {PictureRenderJobOutput, RenderOutputForPassName} from "@cm/job-nodes/rendering"
import {SceneNodes} from "@cm/template-nodes"
import {Settings} from "@app/common/models/settings/settings"
import {imageProcessingTask} from "@cm/job-nodes/image-processing"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"

export function templateThumbnailJobGraph({
    template,
    combinedRenderData,
    mainRenderTask,
    shadowMaskRenderTask,
    postProcessingSettings,
}: {
    template: TemplateThumbnailRenderingFragment
    combinedRenderData: JobNodes.TypedDataNode<PictureRenderJobOutput>
    mainRenderTask: JobNodes.TypedTaskNode<RenderOutputForPassName>
    shadowMaskRenderTask?: JobNodes.TypedTaskNode<RenderOutputForPassName<"ShadowCatcher">> | null
    postProcessingSettings?: SceneNodes.RenderPostProcessingSettings | undefined
}): CreateJobGraphData {
    // task: create the post processing graph
    const createPostProcessingGraphTask = JobNodes.task(createPostProcessingGraph, {
        input: JobNodes.struct({
            data: combinedRenderData,
            settings: JobNodes.value(postProcessingSettings),
            cryptomatteManifest: JobNodes.struct({
                type: JobNodes.value("externalData" as const),
                resolveTo: JobNodes.value("cryptomatteManifest" as const),
                sourceData: JobNodes.get(combinedRenderData, "metadata"),
            }),
        }),
    })

    // task: image post processing
    const convertedImage: JobNodes.TypedDataNode<ImageProcessingNodes.Convert> = JobNodes.struct({
        type: JobNodes.value("convert" as const),
        input: JobNodes.get(createPostProcessingGraphTask, "image"),
        channelLayout: JobNodes.value("RGBA" as const),
        dataType: JobNodes.value("uint8" as const),
        sRGB: JobNodes.value(true),
    })

    const encodedImage: JobNodes.TypedDataNode<ImageProcessingNodes.Encode> = JobNodes.struct({
        type: JobNodes.value("encode" as const),
        input: convertedImage,
        mediaType: JobNodes.value("image/tiff"),
    })

    const imgProcessingTask = JobNodes.task(imageProcessingTask, {input: JobNodes.struct({graph: encodedImage})})

    // create scaled thumbnails for the image
    const uploadProcessingTaskNode = JobNodes.task(uploadProcessingThumbnailsTask, {
        input: Utility.DataObject.update(imgProcessingTask, {imageDataType: "COLOR" as const}),
    })

    // connect the image thumbnail to the template (as gallery image)
    const postRenderTask = JobNodes.task(templateThumbnailPostRenderStepTask, {
        input: JobNodes.struct({
            renderImage: JobNodes.get(uploadProcessingTaskNode, "dataObject"),
            templateId: JobNodes.value(template.legacyId),
            renderFileName: JobNodes.value("Thumbnail - " + template.name + ".tiff"),
            customerId: JobNodes.value(template.organization.legacyId),
        }) as JobNodes.TypedDataNode<TemplateThumbnailPostRenderStepInput>,
    })

    return JobNodes.jobGraph(postRenderTask, {
        progress: {
            type: "progressGroup",
            items: [
                {node: mainRenderTask, factor: 18},
                ...(shadowMaskRenderTask ? [{node: shadowMaskRenderTask, factor: 2}] : []),
                {node: uploadProcessingTaskNode, factor: 2},
                {node: postRenderTask, factor: 1},
            ],
        },
        input: {
            customerId: template.organization.legacyId,
        },
        platformVersion: Settings.APP_VERSION,
    })
}
