import {DEFAULT_FLOAT_TEXTURE_TYPE, IScene} from "@editor/helpers/scene/three-proxies/utils"
import {EnvironmentImageData, SceneNodes} from "@cm/template-nodes"
import {AsyncSubject, map, Observable, of as observableOf, ReplaySubject, switchMap, from} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {degToRad} from "@cm/material-nodes/three"
import {deepEqual} from "@cm/utils"
import {Vector3} from "@cm/math"
import {IHdriManager} from "@app/editor/services/hdri.service"

export class ThreeEnvironment {
    readonly destroySubject = new AsyncSubject<boolean>()
    private _envData?: EnvironmentImageData
    private _rotation: Vector3 = new Vector3(0, 0, 0)
    private _intensity: number = 1.0
    private _clamp = 1000.0
    private _mirror = false
    private rawEnvironmentTexture: THREE.Texture | null = null
    private processedEnvironmentTexture: THREE.Texture | null = null
    private cubeRenderTarget: THREE.WebGLCubeRenderTarget
    private cubeCamera: THREE.CubeCamera

    constructor(
        private scene: IScene,
        private hdriManager: IHdriManager,
    ) {
        this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
            format: THREE.RGBAFormat,
            type: DEFAULT_FLOAT_TEXTURE_TYPE,
            colorSpace: THREE.LinearSRGBColorSpace,
        })
        this.cubeCamera = new THREE.CubeCamera(1, 20000, this.cubeRenderTarget)
    }

    private getTransformedEnvMap(renderer: THREE.WebGLRenderer) {
        const scene = new THREE.Scene()
        const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 1000)

        const width = this.rawEnvironmentTexture?.image.width || 512
        const height = this.rawEnvironmentTexture?.image.height || 512

        const renderTarget = new THREE.WebGLRenderTarget(width, height, {
            format: THREE.RGBAFormat,
            type: DEFAULT_FLOAT_TEXTURE_TYPE,
            colorSpace: THREE.LinearSRGBColorSpace,
        })

        const material = new THREE.ShaderMaterial({
            uniforms: {
                inputTexture: {value: this.rawEnvironmentTexture},
                mirror: {value: this._mirror},
                intensity: {value: this._intensity},
                clamp: {value: this._clamp},
            },
            vertexShader: `varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }`,
            fragmentShader: `varying vec2 vUv;
                    uniform sampler2D inputTexture;
                    uniform bool mirror;
                    uniform float intensity;
                    uniform float clamp;

                    void main() {
                        vec2 uv = mirror ? vec2(1.0 - vUv.x, vUv.y) : vUv;
                        vec4 color = texture2D(inputTexture, uv) * intensity;
                        color = min(color, clamp);
                        gl_FragColor = color;
                    }`,
        })

        const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), material)
        scene.add(mesh)

        camera.position.z = 1

        const currentXrEnabled = renderer.xr.enabled

        renderer.xr.enabled = false
        const currentRenderTarget = renderer.getRenderTarget()
        const currentActiveCubeFace = renderer.getActiveCubeFace()
        const currentActiveMipmapLevel = renderer.getActiveMipmapLevel()

        renderer.setRenderTarget(renderTarget)
        renderer.render(scene, camera)
        renderer.setRenderTarget(currentRenderTarget, currentActiveCubeFace, currentActiveMipmapLevel)

        renderer.xr.enabled = currentXrEnabled

        const tex = renderTarget.texture
        tex.mapping = this.rawEnvironmentTexture!.mapping
        tex.colorSpace = this.rawEnvironmentTexture!.colorSpace
        tex.needsUpdate = true
        tex.minFilter = THREE.LinearFilter
        tex.magFilter = THREE.LinearFilter

        return renderTarget
    }

    update(env: SceneNodes.Environment, forceUpdate: boolean, onEnvMapUpdated?: (env: SceneNodes.Environment, texture: THREE.Texture | null) => void) {
        if (!env) return

        const updateEnvironmentProperties = (forceUpdate: boolean) => {
            if (!this.rawEnvironmentTexture) {
                onEnvMapUpdated?.(env, null)
                return
            }

            if (!(env.rotation instanceof Vector3)) throw new Error("env.rotation is not a Vector3")

            const clampValue = env.clampHighlights ?? 1000.0
            if (
                forceUpdate ||
                !this._rotation.equals(env.rotation) ||
                this._intensity !== env.intensity ||
                this._clamp !== clampValue ||
                this._mirror !== env.mirror
            ) {
                this._rotation = env.rotation.copy()
                this._intensity = env.intensity
                this._clamp = clampValue
                this._mirror = env.mirror

                const renderer = this.scene.getRenderer(true)
                const transformedEnvMapTexture = this.getTransformedEnvMap(renderer!)

                const scene = new THREE.Scene()
                scene.background = transformedEnvMapTexture.texture

                this.cubeCamera.rotation.copy(
                    new THREE.Euler(
                        degToRad(this._mirror ? -this._rotation.x : this._rotation.x),
                        degToRad(this._mirror ? 180.0 - this._rotation.y : this._rotation.y),
                        degToRad(this._mirror ? -this._rotation.z : this._rotation.z),
                    ),
                )
                this.cubeCamera.update(renderer!, scene)

                transformedEnvMapTexture.dispose()

                const rotatedTransformedEnvMapTexture = this.cubeCamera.renderTarget.texture

                const pmremGenerator = new THREE.PMREMGenerator(renderer!)
                const target = pmremGenerator.fromCubemap(rotatedTransformedEnvMapTexture)
                this.processedEnvironmentTexture = target.texture
            }
            onEnvMapUpdated?.(env, this.processedEnvironmentTexture)
        }

        const onLoaded = (texture: THREE.Texture | null) => {
            if (this.rawEnvironmentTexture) this.rawEnvironmentTexture.dispose()
            this.rawEnvironmentTexture = texture
            updateEnvironmentProperties(true)
        }

        if (forceUpdate || !deepEqual(env.envData, this._envData, 1)) {
            this._envData = {...env.envData}
            if (env.envData.type === "hdri") {
                this.scene.addTask(this.loadFromHDRI(env.envData.hdriID, onLoaded))
            } else {
                this.scene.addTask(this.loadFromURL(env.envData.url, env.envData.originalFileExtension, onLoaded))
            }
        } else updateEnvironmentProperties(false)
    }

    private loadFromHDRI(hdriID: number, onLoaded?: (texture: THREE.Texture | null) => void): Observable<THREE.Texture | null> {
        let tmpURL: string = ""
        return from(this.hdriManager.getHdriAsBufferAndExtension({legacyId: hdriID})).pipe(
            switchMap((data) => {
                if (data) {
                    tmpURL = URL.createObjectURL(new Blob([data.buffer]))
                    return this.loadFromURL(tmpURL, data.extension, onLoaded)
                } else return observableOf(null)
            }),
            map((retValue) => {
                if (tmpURL.length > 0) URL.revokeObjectURL(tmpURL)
                return retValue
            }),
        )
    }

    private loadFromURL(url: string, originalFileExtension: string, onLoaded?: (texture: THREE.Texture | null) => void): Observable<THREE.Texture> {
        const done$ = new ReplaySubject<THREE.Texture>(1)
        this.scene.loadHDRTexture(url, originalFileExtension, (texture: THREE.Texture) => {
            texture.mapping = THREE.EquirectangularReflectionMapping

            done$.next(texture)
            done$.complete()
            onLoaded?.(texture)
        })
        return done$
    }

    destroy() {
        this.destroySubject.next(true)
        this.destroySubject.complete()
    }
}
