import {getSelectionModifier, PrioritizedObservableStack} from "@legacy/helpers/utils"
import {IDisplayView, MaterialSlot, ObjectId, SurfacePointInfo} from "@cm/template-nodes"
import {finalize, fromEvent, Observable, of as observableOf, Subject, switchMap, take, takeUntil} from "rxjs"

export type SelectionEvent = {
    objectId?: ObjectId | null
    materialSlot?: MaterialSlot | null
    surfacePointInfo?: SurfacePointInfo | null
    extendSelection: boolean
}

export class Selection {
    private doubleClick$: Subject<SelectionEvent> = new Subject<SelectionEvent>()
    doubleClick = new PrioritizedObservableStack(this.doubleClick$)

    private selectionEvent$: Subject<SelectionEvent> = new Subject<SelectionEvent>()
    selectionEvent = new PrioritizedObservableStack(this.selectionEvent$)

    private hoverEvent$: Subject<SelectionEvent> = new Subject<SelectionEvent>()
    hoverEvent = new PrioritizedObservableStack(this.hoverEvent$)

    private unsubscribe: Subject<void> = new Subject()

    private mouseDownX = 0
    private mouseDownY = 0

    private _lastSelection: [ObjectId | null, MaterialSlot | null] = [null, null]
    private _filterFn: ((ids: ObjectId[]) => ObjectId | null | undefined) | null | undefined = null

    cycle = false

    constructor(private displayView: IDisplayView) {
        const viewport = this.displayView.getDOMElement()
        fromEvent<MouseEvent>(viewport, "mousedown")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
                this.mouseDownX = event.clientX
                this.mouseDownY = event.clientY
            })
        fromEvent<MouseEvent>(viewport, "mousemove")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
                if (this.hoverEvent.subscriptionCount > 0) {
                    const info = this.displayView.surfaceInfoAtPoint(event.clientX, event.clientY, this._filterFn ?? undefined)
                    this.hoverEvent$.next({
                        objectId: info?.objectId,
                        materialSlot: info?.materialSlot,
                        surfacePointInfo: info,
                        extendSelection: false,
                    })
                }
            })
        fromEvent<MouseEvent>(viewport, "mouseup")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
                if (event.clientX != this.mouseDownX || event.clientY != this.mouseDownY) {
                    // don't select anything if mouse was dragged
                    return
                } else if (this.selectionEvent.subscriptionCount > 0) {
                    let objectId: ObjectId | null = null
                    let materialSlot: MaterialSlot | null = null
                    const surfacePointInfo = this.displayView.surfaceInfoAtPoint(event.clientX, event.clientY, this._filterFn ?? undefined) //TODO: filter object list?
                    const extendSelection = !!getSelectionModifier(event)

                    if (this.cycle) {
                        const selectionCandidates: [ObjectId, MaterialSlot][] = this.displayView.findObjectsAtPoint(event.clientX, event.clientY)
                        if (selectionCandidates.length > 0) {
                            let selIdx = 0
                            if (this._lastSelection) {
                                // cycle through candidate selections
                                selIdx =
                                    (selectionCandidates.findIndex((x) => x[0] == this._lastSelection[0] && x[1] == this._lastSelection[1]) + 1) %
                                    selectionCandidates.length
                            }
                            ;[objectId, materialSlot] = selectionCandidates[selIdx]
                        }
                        this._lastSelection = [objectId, materialSlot]
                    } else if (surfacePointInfo) {
                        objectId = surfacePointInfo.objectId
                        materialSlot = surfacePointInfo.materialSlot
                    }

                    this.selectionEvent$.next({objectId, materialSlot, surfacePointInfo, extendSelection})
                }
            })
        fromEvent<MouseEvent>(viewport, "dblclick")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
                if (this.doubleClick.subscriptionCount > 0) {
                    const [objectId, materialSlot] = this._lastSelection
                    const surfacePointInfo: SurfacePointInfo | null = null
                    this.doubleClick$.next({objectId, materialSlot, surfacePointInfo, extendSelection: false})
                }
            })
    }

    destroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    singleSelection(filterFn?: (ids: ObjectId[]) => ObjectId | null | undefined): Observable<SelectionEvent> {
        return observableOf(null).pipe(
            switchMap(() => {
                this._filterFn = filterFn
                return this.selectionEvent.pipe(take(1))
            }),
            finalize(() => (this._filterFn = null)),
        )
    }
}
