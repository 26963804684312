import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay"
import {KeyValuePipe} from "@angular/common"
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {FilesService} from "@common/services/files/files.service"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {RenameDialogComponent} from "@common/components/dialogs/rename-dialog/rename-dialog.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {ListItemComponent} from "@common/components/item"
import {GridSize} from "@legacy/helpers/utils"
import {ImageDataObjectComponent} from "@editor/components/image-data-object/image-data-object.component"
import {JSONInputComponent} from "@editor/components/json-input/json-input.component"
import {MaterialAssignmentComponent} from "@editor/components/material-assignment/material-assignment.component"
import {IEditor} from "@editor/models/editor"
import {Material} from "@legacy/api-model/material"
import {MaterialRevision} from "@legacy/api-model/material-revision"
import {SelectionService} from "app/templates/template-publisher/selection.service"
import {Observable, of as observableOf, Subject, switchMap, takeUntil} from "rxjs"
import {ObjectId} from "@cm/template-nodes"
import {NodeUtils} from "@cm/template-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {removeFromArray} from "@cm/utils"

@Component({
    selector: "cm-mesh-inspector",
    templateUrl: "./mesh-inspector.component.html",
    styleUrls: ["./mesh-inspector.component.scss"],
    standalone: true,
    imports: [
        InputContainerComponent,
        InspectorSectionComponent,
        NumericInputComponent,
        JSONInputComponent,
        MaterialAssignmentComponent,
        KeyValuePipe,
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        ButtonComponent,
        ImageDataObjectComponent,
        MatInputModule,
        ToggleComponent,
        ListItemComponent,
        FormsModule,
    ],
})
export class MeshInspectorComponent implements OnInit, OnDestroy {
    @Input() editor!: IEditor
    @Input() meshNode: Nodes.MeshOrInstance | null = null

    @Output() chooseMaterialNode = new EventEmitter<(material: Nodes.Material | null) => void>()
    @Output() showSurface = new EventEmitter<[ObjectId, Nodes.MeshOrInstance, Nodes.MeshSurface]>()
    @Output() editSurface = new EventEmitter<[ObjectId, Nodes.MeshOrInstance, Nodes.MeshSurface]>()
    @Output() defineNewSurface = new EventEmitter<[ObjectId, Nodes.MeshOrInstance]>()

    FilesService = FilesService

    sceneObjectId: ObjectId | null = null
    selectedSurface: Nodes.MeshSurface | null = null

    gridSizes = GridSize

    isDisplacementSettingsOpen = false
    isMaterialSettingsOpen = false

    private unsubscribe: Subject<void> = new Subject<void>()

    constructor(
        private dialog: MatDialog,
        public selectionService: SelectionService,
    ) {}

    ngOnInit(): void {
        this.selectionService.surfaceSelected.pipe(takeUntil(this.unsubscribe)).subscribe((surface: Nodes.MeshSurface) => {
            this.selectedSurface = surface
            this.showSurface.emit([this.sceneObjectId!, this.meshNode!, this.selectedSurface])
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const change in changes) {
            if (change === "meshNode" && this.meshNode) {
                this.sceneObjectId = this.editor.sceneManager.getObjectIdForNode(this.meshNode)!
            }
        }
    }

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    getSurfaces(): Nodes.MeshSurface[] {
        return NodeUtils.resolveInstance(this.meshNode!).surfaces
    }

    getDRCDataObjectId(): number | undefined {
        const node = NodeUtils.resolveInstance(this.meshNode!)
        if (node.type === "mesh") {
            return node.drcDataObjectId
        }
        return undefined
    }

    getPLYDataObjectId(): number | undefined {
        const node = NodeUtils.resolveInstance(this.meshNode!)
        if (node.type === "mesh") {
            return node.plyDataObjectId
        }
        return undefined
    }

    getMaterialAssignments() {
        return this.meshNode!.materialAssignments || {}
    }

    getSlotName(slot: string) {
        return (this.meshNode!.materialSlotNames && this.meshNode!.materialSlotNames[slot]) ?? `Slot ${slot}`
    }

    addMaterialSlot(): void {
        const node = this.meshNode!
        if (!node.materialAssignments) {
            node.materialAssignments = {}
        }
        for (let slotIdx = 0; slotIdx < 100; slotIdx++) {
            const slot = slotIdx.toString()
            if (!(slot in node.materialAssignments)) {
                node.materialAssignments[slot] = null // create a new empty slot
                break
            }
        }
        this.updateNode(node)
    }

    removeEmptyMaterialSlots(): void {
        const node = this.meshNode!
        if (!node.materialAssignments) return
        const slots = Object.keys(node.materialAssignments)
        for (const slot of slots) {
            if (!node.materialAssignments[slot]) {
                delete node.materialAssignments[slot]
            }
        }
        this.updateNode(node)
    }

    addMaterialAssignment(slot: string, assignment: Nodes.MaterialAssignment): void {
        const node = this.meshNode!
        if (!node.materialAssignments) {
            node.materialAssignments = {}
        }
        node.materialAssignments[slot] = assignment
        this.updateNode(node)
    }

    removeMaterialAssignment(slot: string) {
        const node = this.meshNode!
        if (node.materialAssignments) {
            node.materialAssignments[slot] = null
            this.updateNode(node)
        }
    }

    fetchMaterialFromRevisionId(id: number | undefined): Observable<Material | null> {
        if (id !== undefined && id !== null) {
            return MaterialRevision.get(id).pipe(switchMap((materialRevision) => Material.get(materialRevision.material)))
        } else {
            return observableOf(null)
        }
    }

    deleteSurface(surface: Nodes.MeshSurface) {
        removeFromArray(NodeUtils.resolveInstance(this.meshNode!).surfaces, surface)
        this.selectedSurface = null
        this.updateNode(surface)
        // this.mesh.removeSurface(surface);
    }

    openRenameSurfaceDialog(surface: Nodes.MeshSurface): void {
        const dialogRef: MatDialogRef<RenameDialogComponent> = this.dialog.open(RenameDialogComponent, {
            width: "200px",
            data: {
                currentName: surface.name,
            },
        })

        dialogRef.afterClosed().subscribe((newName) => {
            if (newName) {
                surface.name = newName
                this.updateNode(surface)
            }
        })
    }

    highlightMaterialSlot(enable: boolean, slot: string | null) {
        if (enable) {
            const objId = this.editor.sceneManager.getObjectIdForNode(this.meshNode!)!
            this.editor.highlightOutlines = [[objId, Number(slot)]]
        } else {
            this.editor.highlightOutlines = null
        }
    }

    getBaseMeshNode(): Nodes.Mesh {
        let node = this.meshNode!
        while (node.type === "instance") {
            node = node.node
        }
        return node
    }

    hasGeometryGraph(mesh: Nodes.MeshOrInstance): mesh is Nodes.MeshOrInstance & {geometryGraph: Nodes.ProceduralMesh["geometryGraph"]} {
        return "geometryGraph" in mesh
    }
    hasParameters(mesh: Nodes.MeshOrInstance): mesh is Nodes.MeshOrInstance & {parameters: Nodes.ProceduralMesh["parameters"]} {
        return "parameters" in mesh
    }

    get isProcedural(): boolean {
        const node = this.getBaseMeshNode()
        return node.type === "proceduralMesh"
    }

    get subdivision(): number | undefined | null {
        const node = this.getBaseMeshNode()
        return node.subdivisionRenderIterations
    }

    set subdivision(value: number) {
        const node = this.getBaseMeshNode()
        node.subdivisionRenderIterations = value
        this.updateNode(node)
    }

    get displacementUvChannel(): number | undefined {
        const node = this.getBaseMeshNode()
        return node.displacementUvChannel
    }

    set displacementUvChannel(value: number) {
        const node = this.getBaseMeshNode()
        node.displacementUvChannel = value
        this.updateNode(node)
    }

    get displacementMin(): number | undefined {
        const node = this.getBaseMeshNode()
        return node.displacementMin
    }

    set displacementMin(value: number) {
        const node = this.getBaseMeshNode()
        node.displacementMin = value
        this.updateNode(node)
    }

    get displacementMax(): number | undefined {
        const node = this.getBaseMeshNode()
        return node.displacementMax
    }

    set displacementMax(value: number) {
        const node = this.getBaseMeshNode()
        node.displacementMax = value
        this.updateNode(node)
    }

    get displacementDataObjectId(): number | undefined | null {
        const node = this.getBaseMeshNode()
        const texture = node.displacementTexture
        if (texture && texture.type === "dataObjectReference") {
            return texture.dataObjectId
        } else {
            return undefined
        }
    }

    set displacementDataObjectId(id: number | undefined) {
        const node = this.getBaseMeshNode()
        if (id !== undefined && id !== null) {
            node.displacementTexture = Nodes.create<Nodes.DataObjectReference>({
                type: "dataObjectReference",
                dataObjectId: id,
            })
            node.displacementUvChannel ??= 1
            node.displacementMin ??= 0
            node.displacementMax ??= 1
        } else {
            delete node.displacementTexture
        }
        this.updateNode(node)
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
