import {BuilderInlet, BuilderOutlet, GraphBuilder} from "#template-nodes/runtime-graph/graph-builder"
import {Inlet, Outlet} from "#template-nodes/runtime-graph/slots"

type ValueCompareFn<T> = (a: T, b: T) => boolean
type ValueCopyFn<T> = (x: T) => T

export type ValueTypeDescriptor<T> = {
    deepCompare?: boolean | number | ValueCompareFn<T>
    deepCopy?: boolean | number | ValueCopyFn<T>
}

export type InletDescriptor<T> = {
    descType: "inlet"
    valueTypeDesc?: ValueTypeDescriptor<T>
}

export type OutletDescriptor<T> = {
    descType: "outlet"
    valueTypeDesc?: ValueTypeDescriptor<T>
}

export type ScopeDescriptor = {
    descType: "scope"
}

export type DescriptorEntry = InletDescriptor<any> | OutletDescriptor<any> | ScopeDescriptor

export function inletDescriptor<T>(valueTypeDesc: ValueTypeDescriptor<T> | undefined): InletDescriptor<T> {
    return {descType: "inlet", valueTypeDesc}
}

export function outletDescriptor<T>(valueTypeDesc: ValueTypeDescriptor<T> | undefined): OutletDescriptor<T> {
    return {descType: "outlet", valueTypeDesc}
}

export function scopeDescriptor(): ScopeDescriptor {
    return {descType: "scope"}
}

export type DescriptorToField<D> =
    D extends OutletDescriptor<infer T>
        ? BuilderOutlet<T>
        : D extends InletDescriptor<infer T>
          ? BuilderInlet<T>
          : D extends ScopeDescriptor
            ? GraphBuilder
            : void
export type DescriptorToProperty<D> =
    D extends OutletDescriptor<infer T> ? Outlet<T> : D extends InletDescriptor<infer T> ? Inlet<T> : D extends ScopeDescriptor ? GraphBuilder : void
export type NodeClassDescriptor = {[prop: string]: DescriptorEntry}
