import {ChangeDetectionStrategy, Component, inject, input} from "@angular/core"
import {MatSelectModule} from "@angular/material/select"
import {InterfaceDescriptor, isConfigInput, isMaterialInput} from "@cm/template-nodes"
import {SelectMaterialButton} from "@common/components/viewers/configurator/config-menu/select-material/select-material.component"
import {ConfigMenuService} from "@common/components/viewers/configurator/config-menu/services/config-menu.service"

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-default-menu",
    templateUrl: "./default-menu.component.html",
    styleUrls: ["./default-menu.component.scss"],
    imports: [MatSelectModule, SelectMaterialButton],
})
export class DefaultMenuComponent {
    $interfaceDescriptors = input.required<InterfaceDescriptor<unknown, object>[]>()

    isConfigInput = isConfigInput
    isMaterialInput = isMaterialInput

    configMenuService = inject(ConfigMenuService)

    constructor() {}
}
