<cm-node-base #nodeBase [typeInfo]="typeInfo" [inputs]="inputs" [outputs]="outputs">
    <ng-container cm-extra-content>
        <div class="cm-curve-toggle">
            <div class="cm-curve-toggle-item cm-rgb" [class.cm-active]="selectedCurve === rgbCurves.rgb" (click)="selectCurve(rgbCurves.rgb)">RGB</div>
            <div class="cm-curve-toggle-item cm-r" [class.cm-active]="selectedCurve === rgbCurves.r" (click)="selectCurve(rgbCurves.r)">R</div>
            <div class="cm-curve-toggle-item cm-g" [class.cm-active]="selectedCurve === rgbCurves.g" (click)="selectCurve(rgbCurves.g)">G</div>
            <div class="cm-curve-toggle-item cm-b" [class.cm-active]="selectedCurve === rgbCurves.b" (click)="selectCurve(rgbCurves.b)">B</div>
        </div>
        <div class="cm-curves" #curvesContainer>
            <!-- TODO: The curves should probably be moved to a standalone component. -->
            <svg
                [style.display]="selectedCurve === rgbCurves.rgb ? 'block' : 'none'"
                viewBox="0 0 100 100"
                width="100"
                height="100"
                #rgbCurve
                [attr.id]="'rgbCurve' + uniqueId"
                class="cm-curve-container"
                xmlns="http://www.w3.org/2000/svg"
                (document:mousemove)="onMouseMove($event)"
                (document:mouseup)="onMouseUp($event)"
            >
                <clipPath [attr.id]="'clipRgb' + uniqueId">
                    <rect width="100%" height="100%" />
                </clipPath>
                <path
                    (click)="addPoint($event)"
                    class="cm-curve"
                    [attr.clip-path]="'url(#clipRgb' + uniqueId + ')'"
                    stroke="black"
                    stroke-width="1"
                    fill="none"
                />
                <svg class="cm-background">
                    <path class="cm-curve" d="M 0 100 L 100 0" stroke="#6e6e6e" stroke-width="0.25" fill="none" />
                </svg>
                @for (rgbPoint of rgbCurves.rgb.points; track rgbPoint) {
                    <ng-container>
                        <circle
                            #circle
                            (mousedown)="onMouseDown($event, rgbPoint, circle)"
                            [attr.cx]="rgbPoint.x"
                            [attr.cy]="rgbPoint.y"
                            [class.cm-active-circle]="rgbPoint === selectedPoint"
                            class="cm-circle"
                            r="2"
                            fill="black"
                        />
                    </ng-container>
                }
            </svg>
            <svg
                [style.display]="selectedCurve === rgbCurves.r ? 'block' : 'none'"
                viewBox="0 0 100 100"
                width="100"
                height="100"
                #rCurve
                [attr.id]="'rCurve' + uniqueId"
                class="cm-curve-container"
                xmlns="http://www.w3.org/2000/svg"
                (document:mousemove)="onMouseMove($event)"
                (document:mouseup)="onMouseUp($event)"
            >
                <clipPath [attr.id]="'clipR' + uniqueId">
                    <rect width="100%" height="100%" />
                </clipPath>
                <path
                    (click)="addPoint($event)"
                    class="cm-curve"
                    [attr.clip-path]="'url(#clipR' + uniqueId + ')'"
                    stroke="black"
                    stroke-width="1"
                    fill="none"
                />
                <svg class="cm-background">
                    <path class="cm-curve" d="M 0 100 L 100 0" stroke="#6e6e6e" stroke-width="0.25" fill="none" />
                </svg>
                @for (rPoint of rgbCurves.r.points; track rPoint) {
                    <ng-container>
                        <circle
                            #circle
                            (mousedown)="onMouseDown($event, rPoint, circle)"
                            [attr.cx]="rPoint.x"
                            [attr.cy]="rPoint.y"
                            [class.cm-active-circle]="rPoint === selectedPoint"
                            class="cm-circle"
                            r="2"
                            fill="black"
                        />
                    </ng-container>
                }
            </svg>
            <svg
                [style.display]="selectedCurve === rgbCurves.g ? 'block' : 'none'"
                viewBox="0 0 100 100"
                width="100"
                height="100"
                #gCurve
                [attr.id]="'gCurve' + uniqueId"
                class="cm-curve-container"
                xmlns="http://www.w3.org/2000/svg"
                (document:mousemove)="onMouseMove($event)"
                (document:mouseup)="onMouseUp($event)"
            >
                <clipPath [attr.id]="'clipG' + uniqueId">
                    <rect width="100%" height="100%" />
                </clipPath>
                <path
                    (click)="addPoint($event)"
                    class="cm-curve"
                    [attr.clip-path]="'url(#clipG' + uniqueId + ')'"
                    stroke="black"
                    stroke-width="1"
                    fill="none"
                />
                <svg class="cm-background">
                    <path class="cm-curve" d="M 0 100 L 100 0" stroke="#6e6e6e" stroke-width="0.25" fill="none" />
                </svg>
                @for (gPoint of rgbCurves.g.points; track gPoint) {
                    <ng-container>
                        <circle
                            #circle
                            (mousedown)="onMouseDown($event, gPoint, circle)"
                            [attr.cx]="gPoint.x"
                            [attr.cy]="gPoint.y"
                            [class.cm-active-circle]="gPoint === selectedPoint"
                            class="cm-circle"
                            r="2"
                            fill="black"
                        />
                    </ng-container>
                }
            </svg>
            <svg
                [style.display]="selectedCurve === rgbCurves.b ? 'block' : 'none'"
                viewBox="0 0 100 100"
                width="100"
                height="100"
                #bCurve
                [attr.id]="'bCurve' + uniqueId"
                class="cm-curve-container"
                xmlns="http://www.w3.org/2000/svg"
                (document:mousemove)="onMouseMove($event)"
                (document:mouseup)="onMouseUp($event)"
            >
                <clipPath [attr.id]="'clipB' + uniqueId">
                    <rect width="100%" height="100%" />
                </clipPath>
                <path
                    (click)="addPoint($event)"
                    class="cm-curve"
                    [attr.clip-path]="'url(#clipB' + uniqueId + ')'"
                    stroke="black"
                    stroke-width="1"
                    fill="none"
                />
                <svg class="cm-background">
                    <path class="cm-curve" d="M 0 100 L 100 0" stroke="#6e6e6e" stroke-width="0.25" fill="none" />
                </svg>
                @for (bPoint of rgbCurves.b.points; track bPoint) {
                    <ng-container>
                        <circle
                            #circle
                            (mousedown)="onMouseDown($event, bPoint, circle)"
                            [attr.cx]="bPoint.x"
                            [attr.cy]="bPoint.y"
                            [class.cm-active-circle]="bPoint === selectedPoint"
                            class="cm-circle"
                            r="2"
                            fill="black"
                        />
                    </ng-container>
                }
            </svg>
        </div>
        @if (selectedPoint) {
            <div class="cm-coordinates">
                <div>x: {{ selectedPoint.getBlenderCoordinates().x | number: "1.2-2" }}</div>
                <div>y: {{ 1 - selectedPoint.getBlenderCoordinates().y | number: "1.2-2" }}</div>
                <i class="far fa-trash cm-remove" (click)="removePoint()"></i>
            </div>
        }
    </ng-container>
</cm-node-base>
