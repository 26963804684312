import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeVec3Node} from "#material-nodes/three-utils"
import {vec3} from "#material-nodes/types"
import {getAll} from "@cm/graph/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const blendNormals = new THREENodes.FunctionNode(`
vec3 blendNormals(vec3 color1, vec3 color2) {
    vec3 t = color1*vec3( 2.,  2., 2.) + vec3(-1., -1.,  0.);
    vec3 u = color2*vec3(-2., -2., 2.) + vec3( 1.,  1., -1.);
    return ((t*dot(t, u) - u*t.z) + 1.0) * 0.5;
}
`)

class BlendNormalsNode extends THREENodes.TempNode {
    constructor(
        public color1: THREENodes.Node,
        public color2: THREENodes.Node,
    ) {
        super("vec3")
    }

    override generate(builder: THREENodes.NodeBuilder) {
        const type = this.getNodeType(builder)

        return THREENodes.call(blendNormals, {
            color1: this.color1,
            color2: this.color2,
        }).build(builder, type)
    }
}

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({color1: materialSlots.optional(), color2: materialSlots.optional()})
const ParametersTypeSchema = z.object({color1: vec3.optional(), color2: vec3.optional()})

export class BlendNormals extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color1, color2} = await getAll(inputs, get)
            const color1Value = color1 ?? threeConvert(parameters.color1, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 1})
            const color2Value = color2 ?? threeConvert(parameters.color2, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 1})

            return {color: new BlendNormalsNode(color1Value, color2Value)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
