import {cyclesNode, DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getDefaultMaterial} from "#material-nodes/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode, threeVec3Node} from "#material-nodes/three-utils"
import {color, vec3} from "#material-nodes/types"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnsTypeSchema = z.object({bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)})
const InputTypeSchema = z.object({color: materialSlots.optional(), normal: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional(), normal: vec3.optional()})

export class BsdfTranslucent extends (DeclareMaterialNode(
    {
        returns: ReturnsTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, normal} = await getAll(inputs, get)
            const material = getDefaultMaterial()

            material.normalNode =
                normal ?? threeConvert(parameters.normal, threeVec3Node, (val) => val.x !== 0 || val.y !== 0 || val.z !== 0) ?? material.normalNode

            const transmissionValue =
                color ?? threeConvert(parameters.color, threeRGBColorNode, (val) => val.r > 0.05 || val.g > 0.05 || val.b > 0.05) ?? material.transmissionNode

            if (transmissionValue) {
                material.transparent = true
                material.transmissionNode = transmissionValue
                //@ts-ignore
                material.transmission = 0.01 //This is just to indicate to three that the material is translucent
            }

            return {bsdf: material}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnsTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
