import {TemplatePortal} from "@angular/cdk/portal"
import {Component, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from "@angular/core"
import {ActivatedRoute, Router, UrlTree} from "@angular/router"
import {DialogService} from "@common/services/dialog/dialog.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {DialogSize, ModalInfo} from "@common/models/dialogs"

/**
 * A dialog that is opened and closed by routing to a specific URL.
 * This component does not have a `closed` mode - it is closed by navigating to a different URL, destroying the component.
 *
 * Use the `triggered-dialog` component if you need a dialog that can be opened and closed on demand without routing.
 *
 * To enable the `needsConfirmationToClose` feature, you should use the `closeIfClean` method,
 * which will show a confirmation dialog if necessary (e.g. for unsaved changes).
 */
@Component({
    imports: [],
    selector: "cm-routed-dialog",
    standalone: true,
    styleUrls: ["./routed-dialog.component.scss"],
    templateUrl: "./routed-dialog.component.html",
})
export class RoutedDialogComponent implements OnInit, OnDestroy {
    // The width of the dialog.
    @Input() dialogSize = DialogSize.Medium
    // Makes the dialog's content (rather than the entire dialog) scrollable.
    @Input() dialogScroll = true
    // Request user confirmation before closing the dialog.
    @Input() needsConfirmationToClose = false
    // Optional URL to navigate to when the dialog is closed.
    // If not provided, will default to closeNavigationPath
    // from the current route's data (or "../") while preserving all parameters.
    @Input() closeRoute?: UrlTree | string

    // Emits when the dialog has been closed.
    @Output() close = new EventEmitter<void>()

    @ViewChild("overlayTemplate", {static: true}) overlayTemplate!: TemplateRef<HTMLElement>

    dialog = inject(DialogService)
    notifications = inject(NotificationsService)
    route = inject(ActivatedRoute)
    router = inject(Router)
    viewContainerRef = inject(ViewContainerRef)

    modalInfo?: ModalInfo

    ngOnInit(): void {
        this.openOverlay()
    }

    ngOnDestroy(): void {
        if (this.modalInfo) {
            this.dialog.closeModal(this.modalInfo)
        }
    }

    private openOverlay(): void {
        this.modalInfo = this.dialog.openModal(new TemplatePortal(this.overlayTemplate, this.viewContainerRef))
    }

    public async closeIfClean() {
        if (this.needsConfirmationToClose) {
            const confirmed = await this.notifications.confirmationDialog({
                title: "Leave page ?",
                message: "Changes you made may not be saved. Are you sure you want to leave?",
                confirm: "Leave",
                cancel: "Stay",
            })
            if (confirmed) {
                await this.closeOverlay()
            }
        } else {
            await this.closeOverlay()
        }
    }

    private async closeOverlay() {
        const route =
            this.closeRoute ??
            this.router.createUrlTree([this.route.snapshot.data.closeNavigationPath ?? "../"], {
                relativeTo: this.route,
                queryParams: {
                    ...(this.route.snapshot.data.closeQueryParamOverride ?? {}),
                },
                queryParamsHandling: "merge",
            })
        await this.router.navigateByUrl(route)
        this.close.emit()
    }

    @HostListener("window:beforeunload", ["$event"])
    private async unloadNotification($event: BeforeUnloadEvent) {
        if (this.needsConfirmationToClose) {
            $event.returnValue = true
            $event.preventDefault()
        }
    }

    protected readonly DialogSize = DialogSize
}
