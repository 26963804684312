import {ImageDataType, TextureType} from "@api"
import {assertNever} from "@cm/utils"

export const imageDataTypeFromTextureType = (textureType: TextureType): ImageDataType => {
    switch (textureType) {
        case TextureType.Diffuse:
        case TextureType.Transmission:
            return ImageDataType.Color
        case TextureType.Anisotropy:
        case TextureType.AnisotropyRotation:
        case TextureType.AnisotropyStrength:
        case TextureType.DiffuseRoughness:
        case TextureType.Displacement:
        case TextureType.Error:
        case TextureType.F0:
        case TextureType.Ior:
        case TextureType.Mask:
        case TextureType.Metalness:
        case TextureType.Normal:
        case TextureType.Roughness:
        case TextureType.SpecularStrength:
        case TextureType.SpecularTint:
            return ImageDataType.NonColor
    }
    assertNever(textureType)
}
