import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export type ParameterType = {
    sourceImage: ImageRef
    channel: "R" | "G" | "B" | "A"
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

const imageOpExtractChannel: ImageOpType<ParameterType, ReturnType> = {
    name: "ExtractChannel",

    WebGL2: ({cmdQueue, parameters: {sourceImage, channel, resultImageOrDataType}}) => {
        const painter = cmdQueue.createPainter(
            "compositor",
            "extractChannel",
            `
            vec4 computeColor(ivec2 targetPixel) {
                float r = texelFetch0(targetPixel).${channel.toLowerCase()};
                float g = 0.0;
                float b = 0.0;
                float a = 1.0;
                return vec4(r, g, b, a);
            }
        `,
        )
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, {
            ...sourceImage.descriptor,
            channelLayout: "R",
        })
        cmdQueue.paint(painter, {
            sourceImages: sourceImage,
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, channel, resultImageOrDataType}}) => {
        const getChannelIndex = (channel: ParameterType["channel"]) => {
            switch (channel) {
                case "R":
                    return 0
                case "G":
                    return 1
                case "B":
                    return 2
                case "A":
                    return 3
                default:
                    throw new Error(`Invalid channel: ${channel}`)
            }
        }
        const resultNode = cmdQueue.createImage(
            {
                ...sourceImage.descriptor,
                channelLayout: "R",
            },
            {
                type: "extractChannel",
                input: sourceImage,
                channel: getChannelIndex(channel),
            },
        )
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function extractChannel(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpExtractChannel, parameters)
}
