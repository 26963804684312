import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {lodType, TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {z} from "zod"

const lodTypeParameters = z.object({
    lodType,
})
export type LodTypeParameters = z.infer<typeof lodTypeParameters>

@registerNode
export class LodType extends DeclareTemplateNode({parameters: lodTypeParameters}, {}, {nodeClass: "LodType"}) implements EvaluableTemplateNode<boolean> {
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<boolean> {
        const {templateContext} = evaluator
        return templateContext.lodType === this.parameters.lodType
    }
}

export type LodTypeFwd = TemplateNode<LodTypeParameters> & EvaluableTemplateNode<boolean>
