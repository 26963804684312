import {updateLightDirectionsUniform} from "@editor/helpers/scene/three-proxies/light"
import {ThreeCamera} from "@editor/helpers/scene/three-proxies/camera"
import {createCanvasAndRenderer, DEFAULT_FLOAT_TEXTURE_TYPE, MeshUserData} from "@editor/helpers/scene/three-proxies/utils"
import {GlobalRenderConstants, ObjectId, SurfacePointInfo, ToneMappingData} from "@cm/template-nodes"
import {BehaviorSubject, Observable, of as observableOf} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {EffectComposer} from "@cm/material-nodes/three"
import {TAARenderPass} from "@editor/helpers/scene/three-proxies/taa-render-pass"
import {PatchedOutlinePass as OutlinePass} from "@editor/helpers/scene/three-proxies/patched-outline-pass"
import {TransformControls as THREETransformControls} from "@cm/material-nodes/three"
import {ToneMappingFunctions} from "@cm/image-processing/tone-mapping"
import {ThreeScene} from "@editor/helpers/scene/three-proxies/scene"
import {HTMLViewRenderer} from "@editor/helpers/scene/three-proxies/html-view-renderer"

export class RenderView {
    renderer: THREE.WebGLRenderer
    private containerDiv: HTMLElement | null = null
    canvas: HTMLCanvasElement
    composer: EffectComposer
    renderPass: TAARenderPass
    htmlRenderer?: HTMLViewRenderer
    private toneMapping?: ToneMappingData
    readonly camera$: BehaviorSubject<ThreeCamera | null>
    private outlinePass!: OutlinePass
    private defaultTAAFadeInTiming: [number, number]

    constructor(
        private scene: ThreeScene,
        camera: ThreeCamera | null,
    ) {
        this.camera$ = new BehaviorSubject<ThreeCamera | null>(camera)
        const [canvas, renderer] = createCanvasAndRenderer()

        renderer.autoClear = true
        renderer.shadowMap.enabled = true
        renderer.setClearColor(0x000000, 0)

        const size = renderer.getSize(new THREE.Vector2())
        const pixelRatio = renderer.getPixelRatio()
        const renderTarget = new THREE.WebGLRenderTarget(size.width * pixelRatio, size.height * pixelRatio, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            type: DEFAULT_FLOAT_TEXTURE_TYPE,
            stencilBuffer: true, // Normally this is not needed, but newer versions of Safari will fall back to a lower precision depth buffer if it is set to false, regardless of requested depth texture type.
        })
        renderTarget.texture.name = "EffectComposer.rt1"
        const composer = new EffectComposer(renderer, renderTarget)

        const renderPass = new TAARenderPass(scene.threeScene, null, 0, 0)
        renderPass.enabled = true
        renderPass.accumulate = false
        this.defaultTAAFadeInTiming = [...renderPass.fadeInTiming!]
        composer.addPass(renderPass)

        this.canvas = canvas
        this.renderer = renderer
        this.composer = composer
        this.renderPass = renderPass

        this.htmlRenderer = new HTMLViewRenderer()

        scene._views.add(this)
    }

    dispose(): void {
        this.scene._views.delete(this)
        this.renderer.forceContextLoss()
        this.renderer.renderLists.dispose()
        this.renderer.dispose()
        this.outlinePass?.dispose()
    }

    setOutlines(objs: THREE.Object3D[]) {
        if (!this.outlinePass) {
            this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), this.scene.threeScene, new THREE.PerspectiveCamera())
            this.outlinePass.visibleEdgeColor = new THREE.Color(0x03a9f4)
            this.outlinePass.hiddenEdgeColor = new THREE.Color(0x03a9f4)
            this.outlinePass.edgeStrength = 4
            this.outlinePass.edgeThickness = 1
            this.outlinePass.edgeGlow = 0.25
            this.outlinePass.overlayMaterial.blending = THREE.CustomBlending

            this.composer.addPass(this.outlinePass)
        }
        this.outlinePass.selectedObjects = objs
        this.update()
    }

    update(): void {
        this.scene.update()
    }

    private curWidth!: number
    private curHeight!: number
    updateSize(width: number, height: number): boolean {
        if (width <= 0 || height <= 0) {
            return false // ignore invalid size
        } else if (width === this.curWidth && height === this.curHeight) {
            return false // no change
        }

        this.curWidth = width
        this.curHeight = height

        const elem = this.getDOMElement()
        elem.style.width = width + "px"
        elem.style.height = height + "px"

        const pixelRatio = window.devicePixelRatio
        this.renderer.setPixelRatio(pixelRatio)
        this.renderer.setSize(width, height) // this will resize the canvas!
        this.composer.setSize(width * pixelRatio, height * pixelRatio)

        if (this.camera) {
            this.update()
        }

        return true
    }

    getSize() {
        return [this.curWidth, this.curHeight] as const
    }

    get camera(): ThreeCamera | null {
        return this.camera$.value
    }

    set camera(camera: ThreeCamera) {
        const prevCamera = this.camera$.value
        if (camera !== prevCamera) {
            this.camera$.next(camera)
            this.update()
        }
    }

    get threeCamera() {
        return this.camera!.threeCamera
    }

    mousePositionToNormalizedScreenCoords(x: number, y: number): [number, number] {
        const rect = this.canvas.getBoundingClientRect()
        const xNormalized = ((x - rect.left) / rect.width) * 2 - 1
        const yNormalized = -((y - rect.top) / rect.height) * 2 + 1
        return [xNormalized, yNormalized]
    }

    getDOMElement(): HTMLElement {
        if (this.containerDiv !== null) return this.containerDiv

        const hostDiv = document.createElement("div")
        hostDiv.style.position = "relative"
        hostDiv.style.zIndex = "0"
        hostDiv.style.outline = "0"

        const canvas = this.canvas
        canvas.style.position = "absolute"
        canvas.style.top = "0"
        canvas.style.left = "0"
        canvas.style.zIndex = "0"
        canvas.style.outline = "0"
        canvas.style.width = "100%"
        canvas.style.height = "100%"
        canvas.style.maxWidth = "100%"
        hostDiv.appendChild(canvas)

        const htmlViewElem = this.htmlRenderer?.domElement
        if (htmlViewElem) {
            htmlViewElem.style.position = "absolute"
            htmlViewElem.style.top = "0"
            htmlViewElem.style.left = "0"
            htmlViewElem.style.zIndex = "1"
            htmlViewElem.style.outline = "0"
            htmlViewElem.style.width = "100%"
            htmlViewElem.style.height = "100%"
            htmlViewElem.style.maxWidth = "100%"
            hostDiv.appendChild(htmlViewElem)
        }

        this.containerDiv = hostDiv
        return hostDiv
    }

    private getIntersectionsAtPoint(x: number, y: number): THREE.Intersection[] {
        const point = new THREE.Vector2(...this.mousePositionToNormalizedScreenCoords(x, y))
        this.scene.rayCaster.setFromCamera(point, this.threeCamera)
        const intersections: THREE.Intersection[] = this.scene.rayCaster
            .intersectObjects(this.scene.threeScene.children, true)
            .filter((intersection) => !intersection.object.userData.excludeFromHitTest)
        return intersections.filter((intersection) => !(this.scene._getRootObject(intersection.object) instanceof THREETransformControls))
    }

    findObjectsAtPoint(x: number, y: number): [ObjectId, number][] {
        const existing: {[key: string]: boolean} = {}
        const results: [ObjectId, number][] = []
        for (const intersection of this.getIntersectionsAtPoint(x, y)) {
            const object = intersection.object
            const userData = object.userData as MeshUserData
            const id = userData.threeSceneObject?.topLevelObjectId
            if (id) {
                const key: [ObjectId, number] = [id, userData.materialSlot]
                const keyStr = JSON.stringify(key)
                if (!existing[keyStr]) {
                    existing[keyStr] = true
                    results.push(key)
                }
            }
        }
        return results
    }

    surfaceInfoAtPoint(x: number, y: number, filterFn?: (ids: ObjectId[]) => ObjectId | null): SurfacePointInfo | null {
        const intersections = this.getIntersectionsAtPoint(x, y).filter((x) => x.face && (x.object.userData as MeshUserData).threeSceneObject?.topLevelObjectId)
        const ids: ObjectId[] = intersections.map((x) => (x.object.userData as MeshUserData).threeSceneObject.topLevelObjectId!)
        const id = filterFn ? filterFn(ids) : ids.length > 0 ? ids[0] : null
        const idx = id ? ids.indexOf(id) : null
        if (idx == null || idx < 0) return null
        const intersection = intersections[idx]
        const object = intersection.object
        const userData = object.userData as MeshUserData
        const triIndex = userData.triIndexOffset + intersection.face!.a / 3
        // store world-space point before calling worldToLocal
        const wx = intersection.point.x
        const wy = intersection.point.y
        const wz = intersection.point.z
        object.worldToLocal(intersection.point) // this modifies the vector!
        return {
            objectId: id!,
            materialSlot: userData.materialSlot,
            triIndex: triIndex,
            u: intersection.uv!.x,
            v: intersection.uv!.y,
            worldX: wx,
            worldY: wy,
            worldZ: wz,
            objX: intersection.point.x,
            objY: intersection.point.y,
            objZ: intersection.point.z,
            displayMeshToken: object,
        }
    }

    getFocusDepthForPoint(surfacePointInfo: SurfacePointInfo) {
        const point = new THREE.Vector3(surfacePointInfo.worldX, surfacePointInfo.worldY, surfacePointInfo.worldZ)
        const camPos = new THREE.Vector3()
        const camQuat = new THREE.Quaternion()
        const camScale = new THREE.Vector3()
        this.threeCamera.matrixWorld.decompose(camPos, camQuat, camScale)
        return camPos.distanceTo(point)
    }

    estimateFocusDepth(): number {
        const camPos = new THREE.Vector3()
        const camQuat = new THREE.Quaternion()
        const camScale = new THREE.Vector3()
        this.threeCamera.matrixWorld.decompose(camPos, camQuat, camScale)

        const gridSz = 4
        const range = 0.2
        const point = new THREE.Vector2()
        const distances: number[] = []

        for (let gy = 0; gy < gridSz; gy++) {
            for (let gx = 0; gx < gridSz; gx++) {
                point.x = ((gy - (gridSz - 1) / 2) / gridSz) * range
                point.y = ((gx - (gridSz - 1) / 2) / gridSz) * range
                this.scene.rayCaster.setFromCamera(point, this.threeCamera)
                const intersections: THREE.Intersection[] = this.scene.rayCaster
                    .intersectObjects(this.scene.threeScene.children, true)
                    .filter((intersection) => !intersection.object.userData.excludeFromHitTest)
                if (intersections.length > 0) {
                    distances.push(intersections[0].point.distanceTo(camPos))
                }
            }
        }

        const getMedian = (values: number[]) => {
            if (values.length === 0) return NaN
            values = [...values]
            values.sort((a, b) => a - b)
            const mid = Math.floor(values.length / 2)
            if (values.length % 2) return values[mid]
            return (values[mid - 1] + values[mid]) / 2.0
        }

        return distances.length > 0 ? getMedian(distances) : Infinity
    }

    private _overrideFocusDistance: number | null = null
    set overrideFocusDistance(distance: number | null) {
        this._overrideFocusDistance = distance
        this.update()
    }

    render(taaFrame: number, forceCompile: boolean) {
        this.renderPass.accumulate = taaFrame > 0
        this.renderPass.maxSamples = this.scene.maxTaaFrames

        const camera = this.camera
        if (!camera) {
            // console.warn(`Tried to render without camera! (${this.id})`);
            return
        }
        const threeCamera = camera.threeCamera

        const aspectRatio = this.curWidth > 0 && this.curHeight > 0 ? this.curWidth / this.curHeight : undefined

        this.editMode ? threeCamera.layers.enable(1) : threeCamera.layers.disable(1)

        const toneMapping = camera._toneMapping
        const depthOfField = camera._depthOfField
        this.renderPass.camera = threeCamera
        this.renderPass.exposure = (camera._exposure ?? 1.0) * GlobalRenderConstants.exposureScale // this is exposure applied during mesh rendering, not postprocessing!
        if (toneMapping !== this.toneMapping) {
            this.toneMapping = toneMapping
            // TODO: share LUTs
            this.renderPass.toneMapLUT.updateWithFunction(ToneMappingFunctions.createForToneMappingData(toneMapping))
        }
        if (depthOfField != this.renderPass.depthOfField) {
            this.renderPass.depthOfField = depthOfField
        }
        //TODO: move this to the depthOfField setter OR camera class
        if (this.renderPass.depthOfField && this._overrideFocusDistance != null) {
            this.renderPass.depthOfField.focusDistance = this._overrideFocusDistance
        }

        if (this.outlinePass) {
            this.outlinePass.renderCamera = threeCamera
        }

        // update directional point light uniforms
        updateLightDirectionsUniform(this.scene.threeScene, threeCamera)

        if (forceCompile) {
            this.renderer.compile(this.scene.threeScene, this.threeCamera)
        }

        if (aspectRatio !== undefined) {
            camera.updateCameraMatrix(aspectRatio)
            this.composer.render()
        }

        if (taaFrame === 0 && this.htmlRenderer) {
            this.htmlRenderer.render(this.scene.threeScene, this.threeCamera)
        }
    }

    renderCanvasToDataURL(mimeType: "image/png" | "image/jpeg", quality?: number): Observable<string> {
        const canvas = this.canvas
        const transparentBackground = false

        //TODO: hide grid, transform, outline, etc.
        this.scene._render()

        if (transparentBackground) {
            return observableOf(canvas.toDataURL(mimeType, quality))
        } else {
            const tmpCanvas = document.createElement("canvas")
            tmpCanvas.width = canvas.width
            tmpCanvas.height = canvas.height
            const context: CanvasRenderingContext2D = tmpCanvas.getContext("2d")!
            context.fillStyle = this.canvas.style.background
            context.fillRect(0, 0, canvas.width, canvas.height)
            context.drawImage(canvas, 0, 0)
            return observableOf(tmpCanvas.toDataURL(mimeType, quality))
        }
    }

    private editMode = false
    setEditMode(flag: boolean): void {
        this.editMode = flag
    }

    private disabledTAAFadeInTiming: [number, number] = [0.04, 0.05]
    setTAAFadeIn(flag: boolean): void {
        this.renderPass.fadeInTiming = flag ? this.defaultTAAFadeInTiming : this.disabledTAAFadeInTiming
    }
}
