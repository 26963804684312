import {HalContext} from "@common/models/hal/hal-context"
import {WebGl2Context, WebGl2Geometry} from "@common/models/webgl2"
import {HalGeometry} from "@common/models/hal/hal-geometry/index"

export const createHalGeometry = (context: HalContext): HalGeometry => {
    if (context instanceof WebGl2Context) {
        return new WebGl2Geometry(context)
    }
    throw Error("Unsupported context")
}
