// stringify JSON objects with sorted keys
export function sortedJSONStringify(value: any) {
    if (typeof value === "object") {
        if (value === null) {
            return JSON.stringify(value)
        } else if (Array.isArray(value)) {
            let str = "["
            for (let i = 0; i < value.length; i++) {
                if (i > 0) str += ","
                if (value[i] === undefined) {
                    str += JSON.stringify(null)
                } else {
                    str += sortedJSONStringify(value[i])
                }
            }
            str += "]"
            return str
        } else {
            let str = "{"
            let i = 0
            for (const key of Object.keys(value).sort()) {
                const v = value[key]
                if (v !== undefined) {
                    if (i > 0) str += ","
                    str += `${sortedJSONStringify(key)}:${sortedJSONStringify(v)}`
                    ++i
                }
            }
            str += "}"
            return str
        }
    } else {
        return JSON.stringify(value)
    }
}
