import {DescriptorToField, InletDescriptor, NodeClassDescriptor, OutletDescriptor, ValueTypeDescriptor} from "#template-nodes/runtime-graph/descriptors"
import {BuilderInlet, BuilderOutlet, GraphBuilder, isBuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {And} from "#template-nodes/runtime-graph/nodes/and"
import {Branch} from "#template-nodes/runtime-graph/nodes/branch"
import {EntriesToMap} from "#template-nodes/runtime-graph/nodes/entries-to-map"
import {Filter} from "#template-nodes/runtime-graph/nodes/filter"
import {Gate} from "#template-nodes/runtime-graph/nodes/gate"
import {GetField} from "#template-nodes/runtime-graph/nodes/get-field"
import {Group} from "#template-nodes/runtime-graph/nodes/group"
import {Lambda} from "#template-nodes/runtime-graph/nodes/lambda"
import {List} from "#template-nodes/runtime-graph/nodes/list"
import {Merge} from "#template-nodes/runtime-graph/nodes/merge"
import {Phi} from "#template-nodes/runtime-graph/nodes/phi"
import {PureLambda} from "#template-nodes/runtime-graph/nodes/pure-lambda"
import {SparseList} from "#template-nodes/runtime-graph/nodes/sparse-list"
import {State} from "#template-nodes/runtime-graph/nodes/state"
import {Struct} from "#template-nodes/runtime-graph/nodes/struct"
import {Tap} from "#template-nodes/runtime-graph/nodes/tap"
import {Trace} from "#template-nodes/runtime-graph/nodes/trace"
import {makeValueNodeClass} from "#template-nodes/runtime-graph/nodes/value"
import {NotReady} from "#template-nodes/runtime-graph/slots"
import {ConnectionData, NodeClass, NodeId, ResolveAlias, ResolveId} from "#template-nodes/runtime-graph/types"

type InletKeys<D extends NodeClassDescriptor> = {[K in keyof D]: D[K] extends InletDescriptor<infer T> ? K : never}[keyof D]
type OutletKeys<D extends NodeClassDescriptor> = {[K in keyof D]: D[K] extends OutletDescriptor<infer T> ? K : never}[keyof D]

type InletsForDescriptor<D extends NodeClassDescriptor> = {[K in InletKeys<D>]: DescriptorToField<D[K]>}
type OutletsForDescriptor<D extends NodeClassDescriptor> = {[K in OutletKeys<D>]: DescriptorToField<D[K]>}

export class GraphBuilderScope {
    private idCounter = 1
    private uniqueIdSet = new Set<NodeId>()

    constructor(
        private builder: GraphBuilder,
        private prefix: string,
    ) {}

    private fullId(id: string): NodeId {
        return `${this.prefix}:${id}` // use ':' to avoid conflict between namespace prefixes and node IDs
    }

    private genLocalId(typeName: string): NodeId {
        return `${typeName}@${this.idCounter++}` // NOTE: type name must be included, so that node instances can be matched!
    }

    private checkOrGenLocalId(localId: string | undefined, typeName: string): string {
        return localId != null ? this.ensureUnique(`${typeName}(${localId})`) : this.genLocalId(typeName)
    }

    private ensureUnique(uniqueId: NodeId) {
        if (this.uniqueIdSet.has(uniqueId)) {
            throw Error(`Runtime node ID is not unique: ${uniqueId}`)
        }
        this.uniqueIdSet.add(uniqueId)
        return uniqueId
    }

    genNodeId<D extends NodeClassDescriptor>(nodeClass: NodeClass<D>): NodeId {
        return this.fullId(this.genLocalId(nodeClass.uniqueName))
    }

    genStructId(typeName: string): NodeId {
        return this.fullId(this.genLocalId(typeName))
    }

    alias<T>(val: BuilderInlet<T>, id: string): void {
        this.builder.aliases.set(id, val)
    }

    resolve<T>(id: string): ResolveAlias<T> {
        return new ResolveAlias<T>(id)
    }

    scope(localId?: string): GraphBuilderScope {
        localId = this.checkOrGenLocalId(localId, "Scope")
        return new GraphBuilderScope(this.builder, `${this.prefix}/${localId}`)
    }

    state<T>(stateClass: {new (): T}, uniqueId: string): BuilderOutlet<T> {
        return this._node(State, {
            $id: this.fullId(this.ensureUnique(`State(${uniqueId})`)),
            stateClass,
        }).output
    }

    node<D extends NodeClassDescriptor>(nodeClass: NodeClass<D>, properties?: {$id?: NodeId} & InletsForDescriptor<D>): OutletsForDescriptor<D> {
        return this._node(nodeClass, properties)
    }

    private clearInletAssignments(connectionData: ConnectionData): void {
        const removeKeys: string[] = []
        for (const [key, value] of Object.entries(connectionData)) {
            if (key === "$info" || key === "$id" || key === "$debug") continue
            else if (isBuilderOutlet(value) && value.node === connectionData) continue
            removeKeys.push(key)
        }
        for (const key of removeKeys) {
            delete (connectionData as any)[key]
        }
    }

    private _node<D extends NodeClassDescriptor>(
        nodeClass: NodeClass<D>,
        properties?: {$id?: NodeId} & InletsForDescriptor<D>,
    ): InletsForDescriptor<D> & OutletsForDescriptor<D> {
        const id = properties?.$id ?? this.genNodeId(nodeClass)
        let connectionData = this.builder.connectionData.get(id)
        if (!connectionData) {
            connectionData = {
                $info: {
                    id,
                    nodeClass,
                },
            }
            for (const key in nodeClass.descriptor) {
                const propDesc = nodeClass.descriptor[key]
                if (propDesc.descType === "outlet") {
                    ;(connectionData as any)[key] = {
                        outlet: true,
                        node: connectionData,
                        key,
                        inst: undefined!,
                        valueTypeDesc: propDesc.valueTypeDesc,
                    } as BuilderOutlet<unknown>
                } else if (propDesc.descType === "scope") {
                    connectionData.$info.provideScopeAs = key
                }
            }
            this.builder.connectionData.set(id, connectionData)
        } else {
            this.clearInletAssignments(connectionData)
        }
        // disconnect all inlets
        for (const key in nodeClass.descriptor) {
            const propDesc = nodeClass.descriptor[key]
            if (propDesc.descType === "inlet") {
                ;(connectionData as any)[key] = undefined
            }
        }
        //console.log("connectionData", connectionData)
        this.builder.unvisitedNodes.delete(id)
        if (properties) {
            for (const [k, v] of Object.entries(properties)) {
                if (k !== "$id") {
                    ;(connectionData as any)[k] = v
                }
            }
        }
        return connectionData as any
    }

    private _struct<T>(typeName: string, properties?: {$id?: NodeId} & {[K in keyof T]?: BuilderInlet<T[K]> | (T[K] extends NodeId ? symbol : never)}) {
        // structs require all fields to be defined before emitting output
        const id = properties?.$id ?? this.genStructId(typeName)
        let connectionData = this.builder.connectionData.get(id)
        if (!connectionData) {
            connectionData = {
                $info: {
                    id,
                    nodeClass: Struct,
                },
            }
            ;(connectionData as any).__output_value = {outlet: true, node: connectionData, key: "__output_value"}
            this.builder.connectionData.set(id, connectionData)
        } else {
            this.clearInletAssignments(connectionData)
        }
        this.builder.unvisitedNodes.delete(id)
        if (properties) {
            for (let [k, v] of Object.entries(properties)) {
                if ((v as any) === ResolveId) {
                    v = id
                }
                if (k !== "$id") {
                    ;(connectionData as any)[k] = v
                }
            }
        }
        return connectionData as unknown as {__output_value: BuilderOutlet<T>; $debug?: true} & {[K in keyof T]: BuilderInlet<T[K]>}
    }

    struct<T>(typeName: string, properties: {$id?: NodeId} & {[K in keyof T]: BuilderInlet<T[K]> | (T[K] extends NodeId ? symbol : never)}): BuilderOutlet<T> {
        return this._struct<T>(typeName, properties).__output_value
    }

    group<T>(
        typeName: string,
        properties?: {$id?: NodeId} & {[K in keyof T]?: BuilderInlet<T[K]> | (T[K] extends NodeId ? symbol : never)},
    ): BuilderOutlet<{[K in keyof T]: T[K] | typeof NotReady}> {
        // groups do _not_ require all fields to be defined before emitting output
        const id = properties?.$id ?? this.genStructId(typeName)
        let connectionData = this.builder.connectionData.get(id)
        if (!connectionData) {
            connectionData = {
                $info: {
                    id,
                    nodeClass: Group,
                },
            }
            ;(connectionData as any).__output_value = {outlet: true, node: connectionData, key: "__output_value"}
            this.builder.connectionData.set(id, connectionData)
        } else {
            this.clearInletAssignments(connectionData)
        }
        //console.log("connectionData", connectionData);
        this.builder.unvisitedNodes.delete(id)
        if (properties) {
            for (let [k, v] of Object.entries(properties)) {
                if ((v as any) === ResolveId) {
                    v = id
                }
                if (k !== "$id") {
                    ;(connectionData as any)[k] = v
                }
            }
        }
        return (connectionData as any).__output_value
    }

    record<K extends string | number, V>(d: Record<K, BuilderInlet<V>>, localId?: string): BuilderOutlet<Record<K, V>> {
        const id = this.fullId(this.checkOrGenLocalId(localId, "Record"))
        const node = this._struct<Record<K, V>>("Record", {$id: id} as any)
        for (const k in d) {
            ;(node as any)[k] = d[k] //TODO: StructNode does not work with Record type ??
        }
        return node.__output_value
    }

    get<T, K extends keyof T>(x: BuilderInlet<T>, k: BuilderInlet<K>): BuilderOutlet<T[K]> {
        return this.node(GetField, {
            input: x,
            key: k,
        }).output
    }

    private _list<T>(list: BuilderInlet<T>[], localId?: string, sparse?: boolean): BuilderOutlet<T[]> {
        const id = this.fullId(this.checkOrGenLocalId(localId, sparse ? "SparseList" : "List"))
        let connectionData = this.builder.connectionData.get(id)
        if (!connectionData) {
            connectionData = {
                $info: {
                    id,
                    nodeClass: sparse ? SparseList : List,
                },
            }
            ;(connectionData as any).__output_value = {outlet: true, node: connectionData, key: "__output_value", inst: undefined, valueTypeDesc: null}
            this.builder.connectionData.set(id, connectionData)
        }
        const prevLength = (connectionData as any).length
        if (prevLength) {
            for (let n = 0; n < prevLength; n++) {
                ;(connectionData as any)[n] = undefined
            }
        }
        ;(connectionData as any).length = list.length
        for (let n = 0; n < list.length; n++) {
            ;(connectionData as any)[n] = list[n]
        }
        this.builder.unvisitedNodes.delete(id)
        return (connectionData as any).__output_value
    }

    list<T>(list: BuilderInlet<T>[], localId?: string): BuilderOutlet<T[]> {
        return this._list(list, localId)
    }

    sparseList<T>(list: BuilderInlet<T>[], localId?: string): BuilderOutlet<T[]> {
        return this._list(list, localId, true)
    }

    tuple<T extends unknown[]>(...ins: {[K in keyof T]: BuilderInlet<T[K]>}): BuilderOutlet<T> {
        return this.list(ins) as BuilderOutlet<T>
    }

    merge<T>(lists: BuilderInlet<T[]>[], localId?: string): BuilderOutlet<T[]> {
        localId = this.checkOrGenLocalId(localId, "Merge")
        const scope = this.scope(localId)
        return scope.node(Merge, {
            input: scope.list(lists),
        }).output
    }

    sparseMerge<T>(lists: BuilderInlet<T[]>[], localId?: string): BuilderOutlet<T[]> {
        localId = this.checkOrGenLocalId(localId, "Merge")
        const scope = this.scope(localId)
        return scope.node(Merge, {
            input: scope.sparseList(lists),
        }).output
    }

    tap<T>(input: BuilderInlet<T>, fn: (x: T | typeof NotReady) => void): BuilderOutlet<T> {
        return this.node(Tap, {
            input,
            fn,
        }).output
        //TODO: mark root?
    }

    trace<T>(input: BuilderInlet<T>, message?: string): BuilderOutlet<T> {
        let key: string | undefined
        if (!message) {
            if (isBuilderOutlet(input)) {
                key = `${input.node.$info.id}: ${input.key}`
            }
        } else if (isBuilderOutlet(input)) {
            key = `${input.node.$info.id}: ${input.key}: ${message}`
        }
        return this.node(Trace, {
            key: key!,
            input,
        }).output
    }

    input<T>(key: string): BuilderOutlet<T> {
        this.builder.unvisitedInputs.delete(key)
        let outlet = this.builder.inputs.get(key)
        if (!outlet) {
            outlet = {outlet: true, node: undefined!, key, inst: undefined!}
            this.builder.inputs.set(key, outlet)
        }
        return outlet as BuilderOutlet<T>
    }

    output<T>(key: string, from: BuilderOutlet<T>): void {
        if (from) {
            this.builder.unvisitedOutputs.delete(key)
            this.builder.outputs.set(key, from)
        }
    }

    and(input: BuilderInlet<boolean>[]): BuilderOutlet<boolean> {
        const scope = this.scope(this.genLocalId("And"))
        return scope.node(And, {
            input: scope.list(input),
        }).output
    }

    phi<T>(a: BuilderInlet<T>, b: BuilderInlet<T>, ...rest: BuilderInlet<T>[]): BuilderOutlet<T> {
        if (rest.length === 0) {
            return this.node(Phi, {
                a,
                b,
            }).output
        } else {
            const c = rest.shift()!
            return this.phi(a, this.phi(b, c, ...rest))
        }
    }

    branch<T>(
        input: BuilderInlet<T>,
    ): [BuilderOutlet<Exclude<T, false | null | undefined | 0 | "">>, BuilderOutlet<Extract<T, false | null | undefined | 0 | "">>] {
        const node = this.node(Branch, {
            input,
        })
        return [node.true, node.false]
    }

    gate<T>(input: BuilderInlet<T>, gate: BuilderInlet<boolean>): BuilderOutlet<T> {
        return this.node(Gate, {
            input,
            gate,
        }).output
    }

    // uniqueId _must_ be supplied for the current scope, because change detection does not work for closure objects and
    // it is assumed that lambda nodes can always be properly matched with previous instances using nodes IDs
    lambda<A, B>(input: BuilderInlet<A>, fn: (x: A) => B, uniqueId: NodeId): BuilderOutlet<B> {
        uniqueId = `Lambda(${uniqueId})`
        return this.node(Lambda, {
            $id: this.fullId(this.ensureUnique(uniqueId)),
            input: input,
            fn: fn,
        }).output
    }

    pureLambda<A, B>(input: BuilderInlet<A>, fn: (x: A) => B, uniqueId: NodeId): BuilderOutlet<B> {
        uniqueId = `PureLambda(${uniqueId})`
        return this.node(PureLambda, {
            $id: this.fullId(this.ensureUnique(uniqueId)),
            input: input,
            fn: fn,
        }).output
    }

    value<T>(input: BuilderInlet<T>, valueType: ValueTypeDescriptor<T> | undefined): BuilderOutlet<T> {
        const valueNodeClass = makeValueNodeClass(valueType)
        return this.node(valueNodeClass, {
            input,
        }).output
    }

    valueWithoutType<T>(input: BuilderInlet<T>): BuilderOutlet<T> {
        const valueNodeClass = makeValueNodeClass<T>(undefined)
        return this.node(valueNodeClass, {
            input,
        }).output
    }

    filter<T>(input: BuilderInlet<T[]>, pred?: BuilderInlet<(x: T) => boolean>): BuilderOutlet<T[]> {
        return this.node(Filter, {
            input,
            pred: pred,
        }).output
    }

    filterInvalid<T>(input: BuilderInlet<T[]>): BuilderOutlet<Exclude<T, false | null | undefined | 0 | "">[]> {
        return this.node(Filter, {
            input,
            pred: undefined,
        }).output
    }

    entriesToMap<K extends string | number, V>(input: BuilderInlet<[K, V][]>): BuilderOutlet<Map<K, V>> {
        return this.node(EntriesToMap, {
            input: input,
        }).output
    }
}
