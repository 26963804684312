import {Component, Input, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {defaultsForToneMapping} from "@cm/image-processing/tone-mapping"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"

@Component({
    selector: "cm-post-processing-inspector",
    templateUrl: "./post-processing-inspector.component.html",
    styleUrls: ["./post-processing-inspector.component.scss"],
    standalone: true,
    imports: [
        NodeReferenceSlotComponent,
        InspectorSectionComponent,
        InputContainerComponent,
        NumericInputComponent,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        ToggleComponent,
        FormsModule,
    ],
})
export class PostProcessingInspectorComponent implements OnInit {
    @Input({required: true}) editor!: IEditor
    @Input({required: true}) node!: Nodes.PostProcessRender

    hasHighlightCompression(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {highlightCompression: number} {
        return "highlightCompression" in toneMapping
    }
    hasContrast(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {contrast: number} {
        return "contrast" in toneMapping
    }
    hasSaturation(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {saturation: number} {
        return "saturation" in toneMapping
    }
    hasBalance(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {balance: number} {
        return "balance" in toneMapping
    }
    hasColorBalance(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {colorBalance: number} {
        return "colorBalance" in toneMapping
    }

    //FIXME: Temporary values
    showMasks = false
    mask01 = false
    mask02 = false

    luts = [
        {name: "Photographic 1", url: "https://storage.googleapis.com/cm-platform-prod-static/LUTs/Kim_Amland_Photographic_01.cube"},
        {name: "Photographic 2", url: "https://storage.googleapis.com/cm-platform-prod-static/LUTs/Kim_Amland_Photographic_02.cube"},
        {name: "Photographic 3", url: "https://storage.googleapis.com/cm-platform-prod-static/LUTs/Kim_Amland_Photographic_03.cube"},
    ]

    defaultsForToneMapping = defaultsForToneMapping

    constructor() {}

    ngOnInit(): void {}

    nodeChanged(node: Nodes.Node, evt?: any): void {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
