import {Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const groupDescriptor = {
    __output_value: TD.outlet(TD.Array(TD.Identity<any>())),
}

export class Group implements NodeClassImpl<typeof groupDescriptor, typeof Group> {
    static descriptor = groupDescriptor
    static uniqueName = "Group"
    __output_value!: Outlet<any>
    $inletKeys?: string[]
    $constKeys?: string[]

    run() {
        const data: any = {}
        if (this.$inletKeys) {
            for (const key of this.$inletKeys) {
                data[key] = (this as any)[key]
            }
        }
        if (this.$constKeys) {
            for (const key of this.$constKeys) {
                data[key] = (this as any)[key]
            }
        }
        this.__output_value.emitIfChanged(data)
    }
}
