import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {ObjectData} from "#template-nodes/interfaces/object-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {ObjectLike, objectLike} from "#template-nodes/node-types"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TemplateNode} from "#template-nodes/types"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {hashObject} from "@cm/utils"
import {z} from "zod"
import {LegacyMaterialConverter} from "@cm/material-nodes/legacy-material-converter"
import {UVMap} from "@cm/material-nodes/nodes/uv-map"
import {TraversalAction} from "@cm/graph/node-graph"
import {MaterialNode} from "@cm/material-nodes/declare-material-node"

const distanceTextureParameters = namedNodeParameters.merge(
    z.object({
        range: z.number(),
        width: z.number(),
        height: z.number(),
        target: objectLike.nullable(),
        innerValue: z.number().optional(),
    }),
)
export type DistanceTextureParameters = NamedNodeParameters & {
    range: number
    width: number
    height: number
    target: ObjectLike | null
    innerValue?: number
}

@registerNode
export class DistanceTexture
    extends DeclareTemplateNodeTS<DistanceTextureParameters>(
        {
            validation: {paramsSchema: distanceTextureParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    const {target} = parameters
                    if (target === null) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "DistanceTexture"},
    )
    implements EvaluableTemplateNode<ImageGenerator | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {target, ...rest} = this.parameters

        const inputTarget = evaluator.evaluateObject(scope.scope("object"), target)

        return scope.pureLambda<[ObjectData | null, number, number, number, number | undefined], ImageGenerator | null>(
            scope.tuple(inputTarget, rest.range, rest.width, rest.height, rest.innerValue),
            ([inputTarget, range, width, height, innerValue]) => {
                if (!inputTarget) return null

                const targets = [...inputTarget.preDisplayList, ...inputTarget.displayList]
                if (targets.length === 0) return null

                return {
                    imageNode: {
                        generator: ({uv, extension, interpolation, projection, forceOriginalResolution}) => {
                            const uvInput = new LegacyMaterialConverter().convertWrappedNode(uv)

                            const nodes = new Set<MaterialNode>()
                            uvInput.depthFirstTraversalPreorder(() => TraversalAction.Continue, nodes)

                            const uvNodes = [...nodes].filter((node): node is UVMap => node instanceof UVMap)
                            if (uvNodes.length > 1) throw new Error("Distance texture node expects a single UVMap node")

                            const uvNode = uvNodes.at(0)
                            const uvChannel = uvNode?.parameters.parameters.uvMapIndex

                            return {
                                color: wrapNodeOutput(
                                    {
                                        nodeType: "DistanceTexture",
                                        inputs: {
                                            Vector: uv,
                                        },
                                        parameters: {
                                            "internal.extension": extension,
                                            "internal.interpolation": interpolation,
                                            "internal.projection": projection,
                                            "internal.range": range,
                                            "internal.width": width,
                                            "internal.height": height,
                                            "internal.force_original_resolution": forceOriginalResolution,
                                            "internal.uvChannel": uvChannel,
                                            "internal.targets": targets,
                                            "internal.innerValue": innerValue,
                                        },
                                    },
                                    "Color",
                                ),
                                alpha: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeValue",
                                        parameters: {
                                            Value: 1,
                                        },
                                    },
                                    "Value",
                                ),
                            }
                        },
                        hash: hashObject({
                            type: "DistanceTexture",
                            targetMeshes: targets,
                            range,
                            width,
                            height,
                            innerValue,
                        }),
                    },
                    metadata: {
                        width,
                        height,
                    },
                }
            },
            "distanceTexture",
        )
    }
}

export type DistanceTextureFwd = TemplateNode<DistanceTextureParameters> & EvaluableTemplateNode<ImageGenerator | null>
