import {Component, computed, input, output} from "@angular/core"
import {NgClass} from "@angular/common"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {assertNever} from "@cm/utils"
import {Settings} from "@common/models/settings/settings"

@Component({
    selector: "cm-thumbnail",
    standalone: true,
    imports: [NgClass],
    templateUrl: "./thumbnail.component.html",
    styleUrl: "./thumbnail.component.scss",
})
export class ThumbnailComponent {
    $url = input<string | null>(null, {alias: "url"})
    $layout = input(ThumbnailLayout.Contain, {alias: "layout"})
    load = output<Event>()

    fallbackUrl = Settings.IMAGE_NOT_AVAILABLE_SMALL_URL

    protected $layoutClass = computed(() => {
        const layout = this.$layout()
        switch (layout) {
            case ThumbnailLayout.Contain:
                return "cm-thumbnail-contain"
            case ThumbnailLayout.Cover:
                return "cm-thumbnail-cover"
            default:
                assertNever(layout)
        }
    })
}
