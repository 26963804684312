import {Component, inject, input, OnDestroy, OnInit, signal, ViewChild} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {IMaterialGraph} from "@cm/material-nodes"
import {IsNonNull} from "@cm/utils"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {combineLatest} from "rxjs"

@Component({
    selector: "cm-preview-scene-new",
    templateUrl: "./preview-scene-new.component.html",
    styleUrls: ["./preview-scene-new.component.scss"],
    standalone: true,
    imports: [ConfiguratorComponent],
})
export class PreviewSceneNewComponent implements OnInit, OnDestroy {
    @ViewChild("configurator", {static: true}) configurator!: ConfiguratorComponent

    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)

    templateId = signal<string | undefined>(undefined)
    materialGraph = input.required<IMaterialGraph>()
    materialGraph$ = toObservable(this.materialGraph)

    async ngOnInit() {
        this.templateId.set(await this.getOrganizationTemplate())

        combineLatest([this.configurator.loadingCompleted, this.materialGraph$]).subscribe({
            next: ([_, materialGraph]) => {
                this.configurator.setMaterialGraphToFirstInput(materialGraph)
            },
        })
    }

    async getOrganizationTemplate() {
        const organizationIds = this.organizations.$own()?.map((org) => org.id) ?? []
        const {organizations} = await this.sdk.gql.organizationsWithNodeEditorTemplateId({organizationIds})
        const templateIds = organizations.map((org) => org?.nodeEditorTemplate?.id).filter(IsNonNull)
        return templateIds[0]
    }

    ngOnDestroy() {}
}
