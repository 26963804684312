import {Component, Input, OnInit} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {IEditor} from "@editor/models/editor"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {NodeUtils} from "@cm/template-nodes"

@Component({
    selector: "cm-annotation-inspector",
    templateUrl: "./annotation-inspector.component.html",
    styleUrls: ["./annotation-inspector.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, InputContainerComponent, StringInputComponent, NativeInputTextAreaComponent],
})
export class AnnotationInspectorComponent implements OnInit {
    @Input() editor!: IEditor
    @Input() node: Nodes.Annotation | null = null

    isValidId = NodeUtils.isValidId

    constructor() {}

    ngOnInit() {}

    nodeChanged(node: Nodes.Node) {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
