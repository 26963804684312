import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {reduce} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-reduce"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {getMostPreciseDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

const SCOPE_NAME = "Mean"

export type ParameterType = {
    sourceImage: ImageRef
    sourceWeightImage?: ImageRef // weight of each pixel [0..1] (sourceWeightImage must have the same size as sourceImage)
    resultDataType?: DataType // default: sourceImage.descriptor.dataType
    options?: {
        premultipliedImage?: boolean // default: false; if true, the image is assumed to be premultiplied by its weight
    }
}

export type ReturnType = {
    mean: ImageRef
    sourceWeightSum?: ImageRef
}

export const mean = (cmdQueue: ImageOpCommandQueue, {sourceImage, sourceWeightImage, resultDataType, options}: ParameterType): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)
    resultDataType ??= sourceImage.descriptor.dataType
    const intermediateDataType = getMostPreciseDataType(resultDataType, "float16")
    const premultipliedImage = options?.premultipliedImage ?? false
    let result: ReturnType
    if (sourceWeightImage) {
        const sourceWeightSum = reduce(cmdQueue, {
            sourceImage: sourceWeightImage,
            operator: "sum",
            resultDataType: intermediateDataType,
        })
        const sourceTimesWeight = premultipliedImage
            ? sourceImage
            : math(cmdQueue, {
                  operator: "*",
                  operandA: sourceImage,
                  operandB: sourceWeightImage,
                  resultImageOrDataType: intermediateDataType,
              })
        const sourceTimesWeightSum = reduce(cmdQueue, {
            sourceImage: sourceTimesWeight,
            operator: "sum",
            resultDataType: intermediateDataType,
        })
        const mean = math(cmdQueue, {
            operator: "/safe",
            operandA: sourceTimesWeightSum,
            operandB: sourceWeightSum,
            resultImageOrDataType: resultDataType,
        })
        result = {mean, sourceWeightSum}
    } else {
        const mean = reduce(cmdQueue, {
            sourceImage,
            operator: "mean",
            resultDataType,
        })
        result = {mean}
    }
    cmdQueue.endScope(SCOPE_NAME)
    return result
}
