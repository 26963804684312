import {JobState, JobTaskState} from "@api"

export const jobTaskStateColor = (dbState: JobTaskState | null) => {
    switch (dbState) {
        case JobTaskState.Init:
        case JobTaskState.Runnable:
            return "state--light"
        case JobTaskState.Queued:
            return "state--runnable"
        case JobTaskState.Running:
            return "state--running"
        case JobTaskState.Complete:
            return "state--success"
        case JobTaskState.Failed:
        case JobTaskState.Timeout:
        case JobTaskState.Fatal:
            return "state--error"
        case JobTaskState.Cancelled:
            return "state--cancelled"
        default:
            return "state--light"
    }
}

export const jobStateColor = (dbState: JobState | null) => {
    switch (dbState) {
        case JobState.Init:
        case JobState.Runnable:
            return "state--init"
        case JobState.Running:
            return "state--running"
        case JobState.Complete:
            return "state--success"
        case JobState.Failed:
            return "state--error"
        case JobState.Cancelled:
            return "state--cancelled"
        default:
            return "state--light"
    }
}
