import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {getInterfaceIdPrefix} from "#template-nodes/interface-descriptors"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {ObjectData} from "#template-nodes/interfaces/object-data"
import {ObjectId} from "#template-nodes/interfaces/scene-object"
import {
    BooleanLike,
    ImageLike,
    isBooleanLike,
    isImageLike,
    isJSONLike,
    isMaterialLike,
    isNumberLike,
    isObjectLike,
    isStringLike,
    isTemplateLike,
    JSONLike,
    MaterialLike,
    Mesh,
    NumberLike,
    ObjectLike,
    StringLike,
    TemplateLike,
} from "#template-nodes/node-types"
import {DataObjectReference} from "#template-nodes/nodes/data-object-reference"
import {MeshCurve} from "#template-nodes/nodes/mesh-curve"
import {TemplateParameterValue} from "#template-nodes/nodes/parameters"
import {TemplateInstance} from "#template-nodes/nodes/template-instance"
import {BuilderInlet, GraphBuilder, isBuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {GraphScheduler} from "#template-nodes/runtime-graph/graph-scheduler"
import {FetchDataObjectNew} from "#template-nodes/runtime-graph/nodes/fetch-data-object-new"
import {SampleCurveData} from "#template-nodes/runtime-graph/nodes/sample-curve"
import {NotReady} from "#template-nodes/runtime-graph/slots"
import {ResolveAlias} from "#template-nodes/runtime-graph/types"
import {
    AnyJSONValue,
    EvaluatedTemplateInput,
    EvaluatedTemplateInputs,
    EvaluatedTemplateValueType,
    isAnyJSONValue,
    isTemplateNode,
    TemplateContext,
    TemplateData,
    TemplateNode,
} from "#template-nodes/types"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {Matrix4} from "@cm/math"
import {CompactUIDTable} from "@cm/utils"

function cached(key: string) {
    function decorator(target: NodeEvaluator, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value

        descriptor.value = function (...args: any[]) {
            const node = args[1] as TemplateNode
            if (node === null) return originalMethod.apply(this, args) as BuilderInlet<unknown>

            const resultCache = (this as NodeEvaluator).resultCache

            const keyedCache = (() => {
                const result = resultCache.get(key)

                if (result === undefined) {
                    const newCache = new Map<TemplateNode, BuilderInlet<unknown>>()
                    resultCache.set(key, newCache)
                    return newCache
                }

                return result
            })()

            const cacheValue = keyedCache.get(node)
            if (cacheValue !== undefined) return cacheValue

            const result = originalMethod.apply(this, args) as BuilderInlet<unknown>
            keyedCache.set(node, result)

            return result
        }

        return descriptor
    }

    return decorator
}

export type MeshObjectData = {id: ObjectId; matrix: Matrix4; visible: boolean; meshData: MeshData}

export class NodeEvaluator {
    resultCache = new Map<string, Map<TemplateNode, BuilderInlet<unknown>>>()

    constructor(
        readonly idMap: CompactUIDTable<string>,
        readonly templateScope: GraphBuilderScope,
        readonly templateContext: TemplateContext,
        readonly ambientInputs: EvaluatedTemplateInputs,
        readonly activeNodeSet: Set<TemplateNode> | undefined,
    ) {}

    getLocalId(node: TemplateNode) {
        return this.idMap.intern(node.instanceId)
    }

    getScope(node: TemplateNode) {
        return this.templateScope.scope(`${node.getNodeLabel()}@${this.getLocalId(node)}`)
    }

    fetchDataObject(scope: GraphBuilderScope, dataObject: DataObjectReference) {
        return scope.node(FetchDataObjectNew, {
            sceneManager: this.templateContext.sceneManager,
            dataObjectId: dataObject.parameters.dataObjectId,
        }).dataObject
    }

    @cached("material")
    evaluateMaterial(scope: GraphBuilderScope, node: MaterialLike | null): BuilderInlet<IMaterialGraph | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    @cached("template")
    evaluateTemplate(scope: GraphBuilderScope, node: TemplateLike | null): BuilderInlet<TemplateData | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    @cached("object")
    evaluateObject(scope: GraphBuilderScope, node: ObjectLike | null): BuilderInlet<ObjectData | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    @cached("mesh")
    evaluateMesh(scope: GraphBuilderScope, node: Mesh): BuilderInlet<MeshObjectData> {
        return node.evaluateMeshObjectData(scope, this)
    }

    @cached("meshCurve")
    evaluateMeshCurve(scope: GraphBuilderScope, node: MeshCurve): BuilderInlet<ObjectData & SampleCurveData> {
        return node.evaluate(scope, this)
    }

    @cached("image")
    evaluateImage(scope: GraphBuilderScope, node: ImageLike | null): BuilderInlet<ImageGenerator | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    evaluateString(scope: GraphBuilderScope, node: StringLike | null): BuilderInlet<string | null> {
        if (node === null) return null
        if (typeof node === "string") return node
        return node.evaluate(scope, this)
    }

    evaluateNumber(scope: GraphBuilderScope, node: NumberLike | null): BuilderInlet<number | null> {
        if (node === null) return null
        if (typeof node === "number") return node
        return node.evaluate(scope, this)
    }

    evaluateBoolean(scope: GraphBuilderScope, node: BooleanLike | null): BuilderInlet<boolean | null> {
        if (node === null) return null
        if (typeof node === "boolean") return node
        return node.evaluate(scope, this)
    }

    evaluateActive(active: BooleanLike) {
        if (typeof active !== "boolean") {
            const scheduler = new GraphScheduler()
            const builder = new GraphBuilder("root", scheduler)
            const scope = new GraphBuilderScope(builder, "")

            const evaled = this.evaluateBoolean(scope, active)
            if (typeof evaled !== "boolean") throw new Error("Active flag must be an instantly evaluable boolean")

            return evaled
        } else return active
    }

    evaluateJSON(scope: GraphBuilderScope, node: JSONLike | null): BuilderInlet<AnyJSONValue | null> {
        if (node === null) return null
        if (isAnyJSONValue(node)) return node
        return node.evaluate(scope, this)
    }

    evaluateTemplateInputs(scope: GraphBuilderScope, node: TemplateInstance): [BuilderInlet<EvaluatedTemplateInputs>, string[]] {
        function setTemplateInput(
            scope: GraphBuilderScope,
            inputs: {[inputId: string]: BuilderInlet<EvaluatedTemplateInput> | typeof NotReady},
            type: EvaluatedTemplateValueType,
            id: string,
            value: BuilderInlet<EvaluatedTemplateInput["value"]>,
            origin?: TemplateParameterValue,
        ) {
            if (isBuilderOutlet(value) || value instanceof ResolveAlias) {
                inputs[id] = scope.struct<EvaluatedTemplateInput>("EvaluatedTemplateInput", {type, value, origin})
            } else {
                inputs[id] = {type, value, origin}
            }
        }

        const evaledInputs: {[inputId: string]: BuilderInlet<EvaluatedTemplateInput> | typeof NotReady} = {}
        const claimedInputIds: string[] = []

        for (const [inputId, value] of Object.entries(node.parameters.parameters.parameters)) {
            if (value === undefined || value === null) continue

            claimedInputIds.push(inputId)
            if (isTemplateNode(value)) {
                if (isMaterialLike(value)) {
                    setTemplateInput(scope, evaledInputs, "material", inputId, this.evaluateMaterial(scope, value), value)
                } else if (isTemplateLike(value)) {
                    setTemplateInput(scope, evaledInputs, "template", inputId, this.evaluateTemplate(scope, value), value)
                } else if (isObjectLike(value)) {
                    setTemplateInput(scope, evaledInputs, "object", inputId, this.evaluateObject(scope, value), value)
                } else if (isImageLike(value)) {
                    setTemplateInput(scope, evaledInputs, "image", inputId, this.evaluateImage(scope, value), value)
                } else if (isStringLike(value)) {
                    setTemplateInput(scope, evaledInputs, "string", inputId, this.evaluateString(scope, value), value)
                } else if (isNumberLike(value)) {
                    setTemplateInput(scope, evaledInputs, "number", inputId, this.evaluateNumber(scope, value), value)
                } else if (isBooleanLike(value)) {
                    setTemplateInput(scope, evaledInputs, "boolean", inputId, this.evaluateBoolean(scope, value), value)
                } else if (isJSONLike(value)) {
                    setTemplateInput(scope, evaledInputs, "json", inputId, this.evaluateJSON(scope, value), value)
                } else {
                    throw Error(`Cannot use node ${value.getNodeClass()} as input for a template`)
                }
            } else if (typeof value === "string") {
                setTemplateInput(scope, evaledInputs, "string", inputId, value, value)
            } else if (typeof value === "number") {
                setTemplateInput(scope, evaledInputs, "number", inputId, value, value)
            } else if (typeof value === "boolean") {
                setTemplateInput(scope, evaledInputs, "boolean", inputId, value, value)
            } else if (isAnyJSONValue(value)) {
                setTemplateInput(scope, evaledInputs, "json", inputId, value, value)
            } else {
                setTemplateInput(scope, evaledInputs, "unknown", inputId, value, value)
            }
        }

        for (const [inputId, entry] of Object.entries(this.ambientInputs)) {
            const [prefix, unwrappedId] = getInterfaceIdPrefix(inputId)
            if (prefix === node.parameters.id) {
                evaledInputs[unwrappedId] = entry
            }
        }

        return [scope.group("evaledInput", evaledInputs), claimedInputIds] as const
    }
}
