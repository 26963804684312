<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="false">
    <ng-container cm-overlay-content>
        <div class="cm-main-card">
            <div class="cm-test-selector">
                <cm-button
                    class="cm-select"
                    [class.cm-active]="viewType() === 'ConfiguratorIframe'"
                    (click)="viewType.set('ConfiguratorIframe')"
                    tp="Configurator is iframe-based"
                >
                    <ng-container cm-text> Configurator: Iframe </ng-container>
                </cm-button>
                <cm-button
                    class="cm-select"
                    [class.cm-active]="viewType() === 'ConfiguratorWebcomponents'"
                    (click)="viewType.set('ConfiguratorWebcomponents')"
                    tp="Configurator is web component based"
                >
                    <ng-container cm-text> Configurator: Web Components </ng-container>
                </cm-button>
                <cm-button
                    class="cm-select"
                    [class.cm-active]="viewType() === 'MaterialDownload'"
                    (click)="viewType.set('MaterialDownload')"
                    tp="Material download web component"
                >
                    <ng-container cm-text> Material Download </ng-container>
                </cm-button>
                <div class="cm-source-select cm-title">
                    <mat-form-field>
                        <mat-label>Source</mat-label>
                        <mat-select [value]="source()" (valueChange)="source.set($event)">
                            @for (src of sources; track src) {
                                <mat-option [value]="src">{{ src }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="cm-test-area">
                @switch (viewType()) {
                    @case ("ConfiguratorIframe") {
                        <cm-configurator-test-iframe [source]="source()"></cm-configurator-test-iframe>
                    }
                    @case ("ConfiguratorWebcomponents") {
                        <cm-configurator-test-wc [source]="source()"></cm-configurator-test-wc>
                    }
                    @case ("MaterialDownload") {
                        <cm-material-download-test [source]="source()"></cm-material-download-test>
                    }
                }
            </div>
        </div>
    </ng-container>
</cm-routed-dialog>
