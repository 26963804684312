<div class="cm-container">
    <cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
        <cm-list-info
            cm-list-header
            [parentList]="this"
            [allowStandardUpdates]="$can().update.material(null, 'batch')"
            [stateLabels]="stateLabels"
            [count]="totalCount ?? 0"
            [extraBatchActions]="extraBatchActions"
            [batchDownloadData]="batchDownloadData"
            [advancedActions]="advancedActions"
            [createItem]="openNewItemDialog.bind(this)"
        >
            @if (showMaterialExplorer) {
                <div [routerLink]="'explorer'" [queryParams]="route.snapshot.queryParams" (click)="$event.stopPropagation()" class="cm-material-explorer">
                    <i class="far fa-wand-magic-sparkles"></i> Explore Materials
                </div>
            }
        </cm-list-info>
        @for (item of items; track item) {
            <cm-materials-card
                [item]="item"
                (updateAssignedUser)="updateAssignedUser($event.contentTypeModel, $event.item, $event.user)"
                (updateItem)="updateItem($event)"
                (copyMaterial)="copyMaterial($event)"
            ></cm-materials-card>
        }
    </cm-infinite-list>
</div>
<ng-template #newItemDialog>
    <cm-add-material-dialog [addMaterial]="createItem.bind(this)" [isBatchMode]="newItemBatchMode"></cm-add-material-dialog>
</ng-template>

<input #fileInput type="file" class="cm-select-file" multiple (change)="batchCreateMaterials($event)" style="display: none" />
