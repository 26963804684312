import {MaterialSocket} from "@material-editor/models/material-socket"

export const LightPathInputs: Record<string, MaterialSocket> = {}

export const LightPathOutputs: Record<string, MaterialSocket> = {
    isGlossyRay: {paramType: "socket", id: "Is Glossy Ray", type: "output", valueType: "output", label: "Is Glossy Ray"},
    isShadowRay: {paramType: "socket", id: "Is Shadow Ray", type: "output", valueType: "output", label: "Is Shadow Ray"},
    isDiffuseRay: {paramType: "socket", id: "Is Diffuse Ray", type: "output", valueType: "output", label: "Is Diffuse Ray"},

    // diffuseDepth: materialSlots,
    // glossyDepth: materialSlots,
    // isCameraRay: materialSlots,
    // isReflectionRay: materialSlots,
    // isSingularRay: materialSlots,
    // isTransmissionRay: materialSlots,
    // rayDepth: materialSlots,
    // rayLength: materialSlots,
    // transmissionDepth: materialSlots,
    // transparentDepth: materialSlots,
}
