import {Component, computed, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ConditionalAmountGroupNode, PricingNode} from "@cm/pricing"

@Component({
    standalone: true,
    selector: "cm-amount-group",
    templateUrl: "./conditional-amount-group-node.component.html",
    styleUrls: ["./conditional-amount-group-node.component.scss", "../node-styles.scss"],
    imports: [FormsModule, MatTooltipModule],
})
export class ConditionalAmountGroupNodeComponent {
    $node = input.required<PricingNode>()

    $amountGroupNode = computed<ConditionalAmountGroupNode | undefined>(() => {
        const node = this.$node()
        if (node instanceof ConditionalAmountGroupNode) return node
        else return undefined
    })

    $description = computed<string>(() => {
        const node = this.$amountGroupNode()
        if (node === undefined) return ""
        return node.parameters.description.length > 0 ? node.parameters.description : "Amount group node"
    })
}
