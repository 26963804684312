export const getBasicPage = (webComponentSource: string): string => {
    return `
<!doctype html>
<html lang="en">
    <head>
        <script type="module" src="${webComponentSource}"></script>
        <meta charset="UTF-8" />
        <title>Material Download Example</title>
    </head>
    <body>
        <ul>
            <li>
                <cm-material-download
                    organization-id="1f0d7f93-4e0d-4400-b937-1ce75576f570"
                    article-id="3086101"
                    type="tile"
                    file-type="tiff"
                    resolution="high"
                >
                    <a href="#">Tileable asset .tif</a>
                </cm-material-download>
            </li>
            <li>
                <cm-material-download organization-id="1f0d7f93-4e0d-4400-b937-1ce75576f570" article-id="3086101" type="tile" file-type="jpg" resolution="low">
                    <a href="#">Tileable asset .jpg low resolution</a>
                </cm-material-download>
            </li>
            <li>
                <cm-material-download organization-id="1f0d7f93-4e0d-4400-b937-1ce75576f570" article-id="3086101" type="tile" file-type="jpg" resolution="high">
                    <a href="#">Tileable asset .jpg high resolution</a>
                </cm-material-download>
            </li>

            <li>
                <cm-material-download organization-id="1f0d7f93-4e0d-4400-b937-1ce75576f570" article-id="3086101" type="pbr" file-type="tiff" resolution="high">
                    <a href="#">PBR asset .tif</a>
                </cm-material-download>
            </li>
            <li>
                <cm-material-download organization-id="1f0d7f93-4e0d-4400-b937-1ce75576f570" article-id="3086101" type="pbr" file-type="jpg" resolution="low">
                    <a href="#">PBR asset .jpg low resolution</a>
                </cm-material-download>
            </li>
            <li>
                <cm-material-download organization-id="1f0d7f93-4e0d-4400-b937-1ce75576f570" article-id="3086101" type="pbr" file-type="jpg" resolution="high">
                    <a href="#">PBR asset .jpg high resolution</a>
                </cm-material-download>
            </li>
        </ul>
    </body>
</html>
`
}
