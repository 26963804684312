import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {ImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export class CachedImageRefBag {
    dispose() {
        for (const managedImageRefs of this.managedImageRefsByIdentifier.values()) {
            managedImageRefs.forEach((managedImageRef) => managedImageRef.release())
        }
        this.managedImageRefsByIdentifier.clear()
    }

    has(identifier = "") {
        return this.managedImageRefsByIdentifier.has(identifier)
    }

    addOrReplace(cmdQueue: ImageOpCommandQueueWebGL2, imageRefs: ImageRef | ImageRef[] | undefined, identifier = "") {
        let managedImageRefs: ManagedImageRef[] | undefined = undefined
        if (imageRefs) {
            if (!Array.isArray(imageRefs)) {
                imageRefs = [imageRefs]
            }
            managedImageRefs = imageRefs.map((imageRef) => cmdQueue.keepAlive(imageRef))
        }
        if (this.has(identifier)) {
            this.remove(identifier)
        }
        if (managedImageRefs) {
            this.managedImageRefsByIdentifier.set(identifier, managedImageRefs)
        }
    }

    remove(identifier = "") {
        const managedImageRefs = this.managedImageRefsByIdentifier.get(identifier)
        if (!managedImageRefs) {
            throw new Error(`ImageRefs with identifier ${identifier} not found in bag`)
        }
        managedImageRefs.forEach((managedImageRef) => managedImageRef.release())
        this.managedImageRefsByIdentifier.delete(identifier)
    }

    get(identifier = "") {
        const result = this.getIfExists(identifier)
        if (!result) {
            throw new Error(`ImageRefs with identifier ${identifier} not found in bag`)
        }
        return result
    }

    getIfExists(identifier = "") {
        const managedImageRefs = this.managedImageRefsByIdentifier.get(identifier)
        return managedImageRefs?.map((managedImageRef) => managedImageRef.ref)
    }

    private managedImageRefsByIdentifier = new Map<string, ManagedImageRef[]>()
}
