import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {MaterialThumbnailPostRenderStepInput, materialThumbnailPostRenderStepTask} from "@cm/job-nodes/asset-rendering"
import {imageProcessingTask} from "@cm/job-nodes/image-processing"
import {CreateJobGraphData, JobNodes} from "@cm/job-nodes/job-nodes"
import {cmRenderTaskForPassNames, renderTaskQueueDomain} from "@cm/job-nodes/rendering"
import {uploadProcessingThumbnailsTask} from "@cm/job-nodes/upload-processing"
import {Utility} from "@cm/job-nodes/utility"
import {CM_TO_INCH} from "@common/helpers/rendering/material/material-assets-rendering/common"
import {JobGraphMaterial} from "@common/models/material-assets-rendering/job-graph-material"
import {Settings} from "@common/models/settings/settings"
import {combinedPassFromRenderTask} from "@app/common/helpers/rendering/rendering"

export function jobGraphFn_thumbnail(
    renderGraphDataObjectId: number,
    materialDetails: JobGraphMaterial,
    size: number,
    sizeCm: number,
    filenames: MaterialThumbnailPostRenderStepInput["filenames"],
    useGpu: boolean,
    useCloud: boolean,
): CreateJobGraphData {
    const renderTask = JobNodes.task(cmRenderTaskForPassNames("Combined"), {input: JobNodes.input(), queueDomain: renderTaskQueueDomain(useGpu, useCloud)})

    const externalData: JobNodes.TypedDataNode<ImageProcessingNodes.ExternalEncodedData> = JobNodes.struct({
        type: JobNodes.value("externalData" as const),
        sourceData: combinedPassFromRenderTask(renderTask),
        resolveTo: JobNodes.value("encodedData" as const),
    })
    const decodedImage: JobNodes.TypedDataNode<ImageProcessingNodes.Decode> = JobNodes.struct({
        type: JobNodes.value("decode" as const),
        input: externalData,
    })

    const dpi = size / (sizeCm * CM_TO_INCH)
    const setDpi: JobNodes.TypedDataNode<ImageProcessingNodes.SetDpi> = JobNodes.struct({
        type: JobNodes.value("setDpi" as const),
        input: decodedImage,
        dpi: JobNodes.value(dpi),
    })
    const encodedImage: JobNodes.TypedDataNode<ImageProcessingNodes.Encode> = JobNodes.struct({
        type: JobNodes.value("encode" as const),
        input: setDpi,
        mediaType: JobNodes.value("image/x-exr"),
    })
    const imgProcTask = JobNodes.task(imageProcessingTask, {input: JobNodes.struct({graph: encodedImage})})

    const uploadProcessingTaskNode = JobNodes.task(uploadProcessingThumbnailsTask, {
        input: Utility.DataObject.update(imgProcTask, {imageDataType: "COLOR" as const}),
    })

    const postRenderTask = JobNodes.task(materialThumbnailPostRenderStepTask, {
        input: JobNodes.struct({
            renderImage: JobNodes.get(uploadProcessingTaskNode, "dataObject"),
            sizeCm: JobNodes.value(sizeCm),
            materialId: JobNodes.value(materialDetails.legacyId),
            filenames: JobNodes.value(filenames),
        }),
    })

    return JobNodes.jobGraph(postRenderTask, {
        progress: {
            type: "progressGroup",
            items: [
                {node: renderTask, factor: 18},
                {node: uploadProcessingTaskNode, factor: 2},
                {node: postRenderTask, factor: 1},
            ],
        },
        input: {
            renderGraph: JobNodes.dataObjectReference(renderGraphDataObjectId),
            customerId: materialDetails.organization.legacyId,
        },
        platformVersion: Settings.APP_VERSION,
    })
}
