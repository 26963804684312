import {Component, Input, OnInit} from "@angular/core"
import {RouterLink} from "@angular/router"
import {ContentTypeModel} from "@api"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {ChangeListItem} from "@platform/models/history/change-list-item"

@Component({
    selector: "cm-change-list",
    templateUrl: "./change-list.component.html",
    styleUrls: ["./change-list.component.scss"],
    standalone: true,
    imports: [RouterLink, PlaceholderComponent, FullPageFeedbackComponent, DataObjectThumbnailComponent],
})
export class ChangeListComponent implements OnInit {
    @Input() changeList?: ChangeListItem[]
    @Input() contentTypeModel?: ContentTypeModel
    entityUrl?: string

    ngOnInit() {
        if (this.contentTypeModel == ContentTypeModel.Picture) {
            this.entityUrl = "/pictures"
        } else if (this.contentTypeModel == ContentTypeModel.Asset) {
            this.entityUrl = "/assets"
        } else if (this.contentTypeModel == ContentTypeModel.Material) {
            this.entityUrl = "/materials"
        } else if (this.contentTypeModel == ContentTypeModel.Model) {
            this.entityUrl = "/models"
        }
    }
}
