import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {Vector2, Vector2Like} from "@cm/math"
import * as paper from "paper"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class SnapAreaItem extends CanvasBaseToolboxItemBase {
    constructor(parentItem: CanvasBaseToolboxItem) {
        super(parentItem)

        this._path = this.createSnapAreaPath(this._radius, this._distancePenalty)
    }

    get radius() {
        return this._radius
    }

    set radius(value: number) {
        if (this._radius === value) {
            return
        }
        this._radius = value
        this.updateSnapArea()
    }

    get distancePenalty() {
        return this._distancePenalty
    }

    set distancePenalty(value: number) {
        if (this._distancePenalty === value) {
            return
        }
        this._distancePenalty = value
        this.updateSnapArea()
    }

    get position() {
        return Vector2.fromVector2Like(this._path.position)
    }

    set position(value: Vector2Like) {
        this._path.position.set(value.x, value.y)
    }

    private updateSnapArea() {
        this._path?.remove()
        this._path = this.createSnapAreaPath(this._radius, this._distancePenalty)
    }

    private createSnapAreaPath(radius: number, distancePenalty: number) {
        this.beginPaperCreation()
        const position = new paper.Point(0, 0)
        const path = new paper.Path.Circle({
            center: position,
            radius: radius,
        })
        const stops: paper.GradientStop[] = []
        const numStops = 10
        for (let i = 0; i < numStops; i++) {
            const t = i / (numStops - 1)
            const alphaScale = 0.2
            const color = new paper.Color(SNAP_AREA_COLOR)
            color.alpha = alphaScale * Math.pow(1 - t, 1 + 4 * (distancePenalty - 0.2)) //distancePenalty > 0.5 ? 1 + 4 * (distancePenalty - 0.5) : )
            stops.push(new paper.GradientStop(color, t))
        }
        const gradient = new paper.Gradient()
        gradient.stops = stops
        gradient.radial = true
        path.fillColor = new paper.Color({
            gradient,
            origin: path.position,
            destination: path.bounds.rightCenter,
        })
        return path
    }

    private _radius = 10
    private _distancePenalty = 0.1
    private _path: paper.Path
}

const SNAP_AREA_COLOR = "darkgreen"
