import {animate, style, transition, trigger} from "@angular/animations"
import {Component, inject, Input, signal} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {MatButtonModule} from "@angular/material/button"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ContentTypeModel, DownloadResolution, TagAssignmentsItemFragment} from "@api"
import {PaginatorComponent} from "@common/components/data/paginator/paginator.component"
import {IsLoadingDirective} from "@common/directives"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PagingService} from "@common/services/paging/paging.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {DataLoaderService} from "@platform/services/data/data-loader/data-loader.service"
import {combineLatest, map, Subject, filter} from "rxjs"
import {IsNonNull} from "@cm/utils/filter"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-tag-assignments",
    standalone: true,
    imports: [MatButtonModule, MatTooltipModule, PaginatorComponent, IsLoadingDirective, DataObjectThumbnailComponent],
    templateUrl: "./tag-assignments.component.html",
    styleUrl: "./tag-assignments.component.scss",
    animations: [
        trigger("fadeInCard", [
            transition("void => *", [
                style({
                    opacity: 0.3,
                    scale: 0.98,
                }),
                animate("300ms", style({opacity: 1, scale: 1})),
            ]),
        ]),
    ],
})
export class TagAssignmentsComponent {
    notification = inject(NotificationsService)
    dataLoader = inject(DataLoaderService)
    paging = inject(PagingService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)

    @Input({required: true}) set tagId(tagId: string) {
        this.tagId$.next(tagId)
    }

    tagId$ = new Subject<string>()

    $paging = signal({pageSize: 5, page: 0})
    $data = this.dataLoader.$pagedData(
        combineLatest([this.tagId$, this.refresh.observeAllContentTypeModel$(ContentTypeModel.Tag)]).pipe(
            filter(([tagId]) => IsNonNull(tagId)),
            map(
                ([tagId]) =>
                    (skip: number, take: number) =>
                        this.sdk.gql.tagAssignmentsLoadPage({
                            filter: {tagId: {equals: tagId}},
                            take,
                            skip,
                        }),
            ),
        ),
        toObservable(this.$paging),
    )

    link = (assignment: TagAssignmentsItemFragment) => {
        if (!assignment?.assignedTo) {
            return ""
        }
        switch (assignment.contentTypeModel) {
            case ContentTypeModel.Asset:
                return `/assets/${assignment.assignedTo.id}`
            case ContentTypeModel.Hdri:
                return `/hdris/${assignment.assignedTo.id}`
            case ContentTypeModel.Material:
                return `/materials/${assignment.assignedTo.id}`
            case ContentTypeModel.Model:
                return `/models/${assignment.assignedTo.id}`
            case ContentTypeModel.Picture:
                return `/pictures/${assignment.assignedTo.id}`
            case ContentTypeModel.Project:
                return `/projects/${assignment.assignedTo.id}`
            case ContentTypeModel.Set:
                return `/sets/${assignment.assignedTo.id}`
            case ContentTypeModel.Template:
                return `/templates/${assignment.assignedTo.id}`
            default:
                return ""
        }
    }

    thumbnail = (assignment: TagAssignmentsItemFragment) => {
        const assignedTo = assignment?.assignedTo
        if (!assignedTo) {
            return null
        }
        switch (assignedTo.__typename) {
            case "Material":
            case "Model":
            case "Picture":
            case "Set":
            case "Template":
                return assignedTo.galleryImage?.id
            case "Asset":
            case "Hdri":
            case "Project":
            default:
                return null
        }
    }

    name = (assignment: TagAssignmentsItemFragment) => {
        const assignedTo = assignment?.assignedTo
        if (!assignedTo) {
            return null
        }
        switch (assignedTo.__typename) {
            case "Asset":
            case "Hdri":
            case "Material":
            case "Model":
            case "Template":
                return assignedTo.name ?? null
            case "Picture":
            case "Project":
            case "Set":
            default:
                return assignedTo.altName ?? null
        }
    }

    setPage = (page: number) => {
        this.$paging.update(({pageSize}) => ({pageSize, page}))
    }

    setPageSize = (pageSize: number) => {
        this.$paging.update(({page}) => ({pageSize, page}))
    }

    deleteAssignment = async (assignment: {id: string}) => {
        await this.notification.withUserFeedback(
            async () => {
                await this.sdk.gql.tagAssignmentsDeleteAssignment({id: assignment.id})
                this.refresh.contentTypeModel(ContentTypeModel.Tag)
            },
            {
                success: "Assignment deleted",
                error: "Failed to delete assignment",
            },
        )
    }

    protected DownloadResolution = DownloadResolution
}
