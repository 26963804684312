import {Component, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatTooltipModule} from "@angular/material/tooltip"
import {BooleanOperator, VariantConditionNode} from "@cm/pricing"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"

@Component({
    standalone: true,
    selector: "cm-variant-condition-node-settings",
    templateUrl: "./variant-condition-node.component.html",
    styleUrl: "./variant-condition-node.component.scss",
    imports: [ToggleComponent, FormsModule, MatTooltipModule],
})
export class VariantConditionNodeSettingsComponent {
    $node = input.required<VariantConditionNode>()
    booleanOperators: BooleanOperator[] = ["AND", "OR"]

    operatorChange(operator: BooleanOperator): void {
        this.$node().parameters.condition.variantOperator = operator
    }

    negationChanged(): void {
        this.$node().parameters.condition.negated = !this.$node().parameters.condition.negated
    }
}
