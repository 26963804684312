import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {SeparateHsvInputs, SeparateHsvOutputs} from "@material-editor/models/nodes/separate-hsv"

@Component({
    selector: "cm-separate-hsv-node",
    templateUrl: "./separate-hsv-node.component.html",
    styleUrls: ["./separate-hsv-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class SeparateHsvNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = SeparateHsvOutputs
    inputs = SeparateHsvInputs
    typeInfo = SeparateHsvNodeType
}

export const SeparateHsvNodeType: MaterialNodeType<typeof SeparateHsvNodeComponent> = {
    id: "separateHsv",
    label: "Separate HSV",
    color: "#4987af",
    name: "ShaderNodeSeparateHSV",
    inputs: [SeparateHsvInputs.image],
    outputs: [SeparateHsvOutputs.h, SeparateHsvOutputs.s, SeparateHsvOutputs.v],
    component: SeparateHsvNodeComponent,
}
