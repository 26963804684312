import {registerNode} from "@cm/graph/register-node"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {DeclareObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {z} from "zod"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {visitNone} from "@cm/graph/declare-visitor-node"

const pointGuideParameters = namedNodeParameters
export type PointGuideParameters = z.infer<typeof pointGuideParameters>

@registerNode
export class PointGuide extends DeclareObjectNode(
    {parameters: pointGuideParameters},
    {
        onVisited: {
            onCompile: function (this: PointGuideFwd, {context, parameters}) {
                const {evaluator} = context

                const scope = evaluator.getScope(this)

                this.setupObject(scope, context, "Point", undefined, undefined, (objectProps) => {
                    return scope.struct<SceneNodes.Point>("Point", {
                        type: "Point",
                        ...objectProps,
                        size: 1,
                    })
                })

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "PointGuide"},
) {}

export type PointGuideFwd = TemplateObjectNode<PointGuideParameters>
