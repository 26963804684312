import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {InterpolationType, resize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {Size2Like} from "@cm/math"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {OddPixelStrategy} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/down-sample"
import {assertNever} from "@cm/utils"

// Up-samples a previously down-sampled image (e.g. by a pyramid) to the original size. Considers odd sizes correctly.

const SCOPE_NAME = "UpSample"

export type ParameterType = {
    sourceImage: ImageRef
    higherLevelSize: Size2Like
    oddPixelInFront?: {x: boolean; y: boolean} // default: false; if set to true the odd pixel is assumed to be in front rather than back
    interpolation?: InterpolationType // default: "cubic"
    oddPixelStrategy?: OddPixelStrategy // default: "nearest"
}

export type ReturnType = ImageRef

export function upSample(
    cmdQueue: ImageOpCommandQueue,
    {sourceImage, higherLevelSize, oddPixelInFront, interpolation, oddPixelStrategy}: ParameterType,
): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)
    interpolation ??= "cubic"
    oddPixelStrategy ??= "nearest"
    const isOddX = (higherLevelSize.width & 1) == 1
    const isOddY = (higherLevelSize.height & 1) == 1
    let result = resize(cmdQueue, {
        sourceImage: sourceImage,
        interpolation,
        resultSize: {
            width: higherLevelSize.width + (isOddX ? 1 : 0),
            height: higherLevelSize.height + (isOddY ? 1 : 0),
        },
    })
    if (oddPixelStrategy !== "stretch" && (isOddX || isOddY)) {
        oddPixelInFront ??= {x: false, y: false}
        switch (oddPixelStrategy) {
            case "zero":
            case "nearest":
                result = copyRegion(cmdQueue, {
                    sourceImage: result,
                    sourceRegion: {
                        x: oddPixelInFront.x ? 1 : 0,
                        y: oddPixelInFront.y ? 1 : 0,
                        width: higherLevelSize.width,
                        height: higherLevelSize.height,
                    },
                })
                break
            default:
                assertNever(oddPixelStrategy)
        }
    }
    cmdQueue.endScope(SCOPE_NAME)
    return result
}
