import {evaluateProceduralGeometry, WebAssemblyWorkerService} from "@app/editor/helpers/mesh-processing/mesh-processing"
import {RenderNodes} from "@cm/render-nodes"
import {MeshData} from "@cm/template-nodes"
import {hashObject, IsDefined} from "@cm/utils"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {filter, Observable} from "rxjs"

export class ProceduralMeshDataCache {
    private cache: AsyncCacheMap<string, MeshData | null, RenderNodes.MeshData>

    constructor(private workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, graph) => {
            return evaluateProceduralGeometry(this.workerService, graph)
        })
    }

    getMeshData(graph: RenderNodes.MeshData): Observable<MeshData> {
        return this.cache.get(hashObject(graph), graph).pipe(filter(IsDefined))
    }

    invalidate() {
        this.cache.clear()
    }
}
