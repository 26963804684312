import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {TexCoordInputs, TexCoordOutputs} from "@material-editor/models/nodes/tex-coord"

@Component({
    selector: "cm-tex-coord-node",
    templateUrl: "./tex-coord-node.component.html",
    styleUrls: ["./tex-coord-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class TexCoordNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = TexCoordInputs
    outputs = TexCoordOutputs
    typeInfo = TexCoordNodeType
}

export const TexCoordNodeType: MaterialNodeType<typeof TexCoordNodeComponent> = {
    id: "texCoord",
    label: "Texture Coordinate",
    color: "#4987af",
    name: "ShaderNodeTexCoord",
    inputs: [] as never[],
    outputs: [
        TexCoordOutputs.generated,
        TexCoordOutputs.normal,
        TexCoordOutputs.uv,
        TexCoordOutputs.object,
        TexCoordOutputs.camera,
        TexCoordOutputs.reflection,
    ],
    component: TexCoordNodeComponent,
}
