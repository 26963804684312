import {inject, Injectable} from "@angular/core"
import {PdfDataForPdfServiceFragment} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {currencyToSymbol} from "@cm/pricing/nodes/core"
import fontkit from "@pdf-lib/fontkit"
import {PricingService} from "@pricing/services/pricing.service"
import {PDFDocument, PDFFont, PDFPage, RGB} from "pdf-lib"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {captureSnapshot} from "@app/template-editor/helpers/snapshot"
import {FilesService} from "@app/common/services/files/files.service"

export enum TextMode {
    Ellipsis,
    Wrap,
}

@Injectable()
export class PdfGenerationService {
    private sdk = inject(SdkService)
    private pricingService = inject(PricingService)

    constructor() {}

    async generatePdfOld(jpgImageBytes: string, customerId: number) {
        const pdfData = (await this.sdk.gql.getPdfDataForPdfServiceOrgaId({organizationLegacyId: customerId})).organization
        return this.generatePdf(jpgImageBytes, pdfData)
    }

    async generatedAndDownloadPdf(threeSceneManagerService: ThreeSceneManagerService, templateRevisionId: string) {
        const jpgImageBytes = captureSnapshot(threeSceneManagerService, "image/jpeg", 95)
        const pdfData = (await this.sdk.gql.getPdfDataForPdfServiceTemplateRevisionId({templateRevisionId: templateRevisionId})).templateRevision.template
            .organization
        const pdf = await this.generatePdf(jpgImageBytes, pdfData)
        FilesService.downloadFile("Configuration.pdf", pdf)
    }

    async generatePdf(jpgImageBytes: string, pdfTemplateData: PdfDataForPdfServiceFragment) {
        const pdfUrl = pdfTemplateData.pdfTemplate?.downloadUrl
        if (!pdfUrl) throw new Error("No PDF template found.")

        const fontUrl = pdfTemplateData.font?.downloadUrl
        if (!fontUrl) throw new Error("No font found.")

        const fontBoldUrl = pdfTemplateData.fontBold?.downloadUrl
        if (!fontBoldUrl) throw new Error("No bold font found.")

        const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())
        const fontBoldBytes = await fetch(fontBoldUrl).then((res) => res.arrayBuffer())

        const existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()
        if (pages.length != 1) throw Error(`The PDF template has to have exactly 1 page. Pages found: ${pages.length}.`)
        const page = pages[0]

        pdfDoc.registerFontkit(fontkit)

        const pageStyle = new PageStyle(page, 9, await pdfDoc.embedFont(fontBytes), await pdfDoc.embedFont(fontBoldBytes))

        const imageY = await PdfGenerationService.addScreenshot(pdfDoc, jpgImageBytes, pageStyle, page)
        await this.addPricingTable(page, pageStyle, page.getHeight() - imageY)

        return pdfDoc.saveAsBase64({dataUri: true})
    }

    async addPricingTable(page: PDFPage, pageStyle: PageStyle, startY: number) {
        const headers = ["DESCRIPTION", "SKU", "AMOUNT", "PRICE"]
        const columnsPercent = [50, 25, 15, 10]

        const prices = await this.pricingService.getPricesAsList()
        if (prices.length === 0) return

        const rows = (await prices).map((price) => [
            price.description,
            price.sku ? price.sku : "N/A",
            price.amount.toString(),
            `${currencyToSymbol(price.currency)} ${price.price}`,
        ])

        const nextLineY = await this.addTable(page, pageStyle, startY, headers, rows, columnsPercent, TextMode.Wrap)

        const tableWidth = pageStyle.pageWidth - 2 * pageStyle.pageMarginLeftRight

        const totalStart = pageStyle.pageMarginLeftRight + (tableWidth * columnsPercent.slice(0, 2).reduce((acc, val) => acc + val, 0)) / 100
        page.drawText("TOTAL:", {x: totalStart, y: nextLineY, font: pageStyle.fontBold, size: pageStyle.fontSize, color: pageStyle.fontColorBold})

        const sum = PricingService.computeTotalPrice(prices)
        const sumStart = pageStyle.pageMarginLeftRight + (tableWidth * columnsPercent.slice(0, 3).reduce((acc, val) => acc + val, 0)) / 100
        page.drawText(`${currencyToSymbol(prices[0].currency)} ${sum}`, {
            x: sumStart,
            y: nextLineY,
            font: pageStyle.fontBold,
            size: pageStyle.fontSize,
            color: pageStyle.fontColorBold,
        })
    }

    async addTable(
        page: PDFPage,
        pageStyle: PageStyle,
        startY: number,
        headers: string[],
        rows: string[][],
        columnWidthPercentages: number[],
        textMode: TextMode,
    ): Promise<number> {
        const totalAvailableWidth = pageStyle.pageWidth - 2 * pageStyle.pageMarginLeftRight
        const columnWidths = columnWidthPercentages.map((percent) => (totalAvailableWidth * percent) / 100)
        const startX = pageStyle.pageMarginLeftRight + (totalAvailableWidth - columnWidths.reduce((acc, width) => acc + width, 0)) / 2

        const lineHeight = pageStyle.fontSize * 1.8
        const avgCharWidth = pageStyle.fontSize * 0.5
        const rowPadding = textMode === TextMode.Wrap ? 8 : 0

        const drawTextWithEllipsis = (text: string, x: number, y: number, maxWidth: number): number => {
            let trimmedText = text
            const maxChars = Math.floor(maxWidth / avgCharWidth)
            if (text.length > maxChars) trimmedText = text.substring(0, maxChars - 1) + "..."
            page.drawText(trimmedText, {x, y, font: pageStyle.font, size: pageStyle.fontSize})
            return 1
        }

        const drawTextWrapped = (text: string, x: number, y: number, maxWidth: number): number => {
            const words = text.split(" ")
            let line = ""
            let linesDrawn = 0

            for (const word of words) {
                const testLine = line + word + " "
                const testWidth = testLine.length * avgCharWidth
                if (testWidth > maxWidth && line !== "") {
                    page.drawText(line, {x, y: y - linesDrawn * lineHeight, font: pageStyle.font, size: pageStyle.fontSize, color: pageStyle.fontColor})
                    line = word + " "
                    linesDrawn++
                } else {
                    line = testLine
                }
            }
            page.drawText(line, {x, y: y - linesDrawn * lineHeight, font: pageStyle.font, size: pageStyle.fontSize, color: pageStyle.fontColor})
            return linesDrawn + 1
        }

        const drawTextFunction = textMode === TextMode.Ellipsis ? drawTextWithEllipsis : drawTextWrapped

        headers.forEach((header, index) => {
            const headerX = startX + columnWidths.slice(0, index).reduce((acc, val) => acc + val, 0)
            page.drawText(header, {x: headerX, y: startY, font: pageStyle.fontBold, size: pageStyle.fontSize, color: pageStyle.fontColorBold})
        })

        let currentY = startY - lineHeight - rowPadding

        rows.forEach((row) => {
            let maxLinesInRow = 1
            row.forEach((cell, cellIndex) => {
                const cellX = startX + columnWidths.slice(0, cellIndex).reduce((acc, val) => acc + val, 0)
                const linesDrawn = drawTextFunction(cell, cellX, currentY, columnWidths[cellIndex])
                maxLinesInRow = Math.max(maxLinesInRow, linesDrawn)
            })
            currentY -= maxLinesInRow * lineHeight + rowPadding
        })

        return (currentY -= lineHeight + rowPadding)
    }

    private static async addScreenshot(pdfDoc: PDFDocument, jpgImageBytes: string, pageStyle: PageStyle, page: PDFPage) {
        const snapshotImage = await pdfDoc.embedJpg(jpgImageBytes)
        const snapshotSize = snapshotImage.scaleToFit(pageStyle.pageWidth - 2 * pageStyle.pageMarginLeftRight, pageStyle.screenshotMaxHeight)
        let imageX = pageStyle.pageMarginLeftRight
        if (snapshotSize.width < pageStyle.pageWidth - 2 * pageStyle.pageMarginLeftRight) {
            // Center image
            imageX = pageStyle.pageMarginLeftRight + (pageStyle.pageWidth - 2 * pageStyle.pageMarginLeftRight - snapshotSize.width) / 2
        }
        const imageY = pageStyle.pageHeight - (pageStyle.screenshotY + snapshotSize.height)
        page.drawImage(snapshotImage, {
            x: imageX,
            y: imageY,
            width: snapshotSize.width,
            height: snapshotSize.height,
        })
        return pageStyle.screenshotY + snapshotSize.height
    }
}

/*CS in pdf-lib: Origin bottom left, x right, y up. Standard size of an a4 page is 595 x 842
Numbers below assume image conventions, i.e. origin top left, x right, y down*/
class PageStyle {
    pageMarginTop = 25
    pageMarginLeftRight = 50 //72 = 1 in

    screenshotY = 95

    screenshotMaxHeight: number

    pageWidth: number
    pageHeight: number

    fontSize: number
    font: PDFFont
    fontBold: PDFFont

    fontColor = {type: "RGB", red: 65 / 255, green: 64 / 255, blue: 66 / 255} as RGB
    fontColorBold = {type: "RGB", red: 0 / 255, green: 140 / 255, blue: 153 / 255} as RGB

    constructor(page: PDFPage, fontSize: number, font: PDFFont, fontBold: PDFFont) {
        this.pageWidth = page.getWidth()
        this.pageHeight = page.getHeight()
        this.screenshotMaxHeight = 0.35 * this.pageHeight
        this.fontSize = fontSize
        this.font = font
        this.fontBold = fontBold
    }
}
