@for (operator of operators; track operator; let i = $index) {
    <div>
        <cm-operator-list-item
            [disabled]="disabled"
            [isInactive]="i > selectedOperatorIndex"
            [operator]="operator"
            (selectOperatorClick)="selectOperator($event)"
            (lockOperatorClick)="lockOperator($event)"
            (enableOperatorClick)="enableOperator($event)"
            (duplicateOperatorClick)="duplicateOperator($event)"
            (deleteOperatorClick)="deleteOperator($event)"
        ></cm-operator-list-item>
    </div>
}
@if (!disabled) {
    @if (canAddAutoTiling) {
        <div matTooltip="Add Tiling">
            <cm-list-item [matMenuTriggerFor]="addTilingMenu">
                <ng-container cm-pre-title>
                    <i class="far fa-plus"></i>
                </ng-container>
                <ng-container cm-title> Add Tiling</ng-container>
                @if ($can().read.menu("operator")) {
                    <ng-container cm-icons>
                        <i [matMenuTriggerFor]="operatorMenu" (click)="$event.stopPropagation()" class="fas fa-ellipsis-v cm-ext-operator-trigger"></i>
                    </ng-container>
                }
            </cm-list-item>
        </div>
    } @else {
        <div [matTooltip]="canAddOperator ? 'Add operator' : 'Unable to add operators until AutoTiling has completed.'">
            <cm-list-item [class.cm-disabled]="!canAddOperator" [matMenuTriggerFor]="operatorMenu">
                <ng-container cm-pre-title>
                    <i class="far fa-plus"></i>
                </ng-container>
                <ng-container cm-title> Add operator</ng-container>
            </cm-list-item>
        </div>
    }
    <mat-menu #addTilingMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="addOperator('operator-tiling')">Tiling</button>
        <button mat-menu-item (click)="addOperator('operator-auto-tiling')">Tiling (Legacy)</button>
    </mat-menu>
    <mat-menu #operatorMenu="matMenu" yPosition="below">
        @for (item of operatorDescByType | keyvalue; track item) {
            @if (item.value && isOperatorTypeAllowed(item.value.type)) {
                <button mat-menu-item (click)="addOperator(item.value.type)">
                    {{ item.value.label }}
                </button>
            }
        }
    </mat-menu>
}

<ng-content select="[cm-extra-content]"></ng-content>

@if (operatorStack.isDebugEnabled) {
    <cm-inspector-section>
        <ng-container cm-title>Cache</ng-container>
        <ng-container cm-content>
            <div>
                <cm-hint>
                    <ng-container cm-text>
                        Cache stats:<br />
                        {{ this.operatorStack.imageCacheWebGL2.contentStats }}<br />
                        {{ this.operatorStack.imageCacheWebGL2.memStats }}
                    </ng-container>
                </cm-hint>
                <span class="cm-label">Max cache size (GB)</span>
                <cm-slider [min]="1" [max]="64" [exponent]="4" [displayValuePrecision]="2" [(value)]="maxCacheSizeGB"></cm-slider>
            </div>
        </ng-container>
    </cm-inspector-section>
}
