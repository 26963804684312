import {IDataObject, ITransientDataObject} from "@cm/material-nodes/interfaces/data-object"
import {IMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {
    createImageTextureSetNodes,
    createScannedTransmissionNodes,
    createSetImageTextureNode,
    ImageTextureSetNodes,
} from "@cm/material-nodes/material-converter-utils"
import {
    getDataObjectFromImageResourceForFormatAndResolution,
    ImageResource,
    isImageNodeGenerator,
    isNodeOfType,
    isOutputWrapper,
    isResolvedResourceWithTransientDataObject,
    isShadowCatcherMaterial,
    MaterialGraphNode,
    unwrapNodeOutput,
    wrapNodeOutput,
    WrappedMaterialGraphNode,
} from "@cm/material-nodes/material-node-graph"
import {Matrix4, Vector3} from "@cm/math"
import {
    CyclesNodePropertyConversions,
    mapColormassToCyclesInputName,
    mapColormassToCyclesNodeParameterSet,
    mapColormassToCyclesNodeType,
    mapColormassToCyclesOutputName,
    mapColormassToCyclesProperty,
} from "@cm/render-nodes/material-converter"
import {RenderNodes} from "@cm/render-nodes/render-nodes"
import {Color, ShaderExpr} from "@cm/render-nodes/shader-expr"
import {GlobalRenderConstants, SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {ImageColorSpace} from "@cm/utils/data-object"

export type RenderGraphResolveFunctions = {
    getHdriDataObjectIdDetails(hdriIdDetails: IdDetails): Promise<IdDetails>
    mapLegacyDataObjectToImageResource(dataObject: IDataObject | ITransientDataObject): ImageResource
}

function getMeshGraphInfo(graph: RenderNodes.MeshData) {
    const dataObjectReferences: RenderNodes.DataObjectReference[] = []
    let maxSubdivLevel = 0
    const traverseMeshData = (node: RenderNodes.MeshData) => {
        if (node.type === "geomGraph") {
            traverseGeomExpr(node.graph)
        } else if (node.type === "loadMesh") {
            if (node.data.type === "dataObjectReference") {
                dataObjectReferences.push(node.data)
            }
        } else if (node.type === "subdivide") {
            maxSubdivLevel = Math.max(maxSubdivLevel, node.levels)
        }
    }
    const traverseGeomExpr = (node: RenderNodes.GeometryExpr) => {
        if (node.op === "meshToGeom") {
            traverseMeshData((node as RenderNodes.MeshDataToGeometry).args[0])
        } else {
            for (const arg of (node as RenderNodes.GeometryOperator).args) {
                if (typeof arg === "object") {
                    traverseGeomExpr(arg)
                }
            }
        }
    }
    traverseMeshData(graph)
    return {
        dataObjectReferences,
        maxSubdivLevel,
    }
}

function sanitizeCryptoMatteName(name: string | undefined): string | undefined {
    // Cycles does not properly escape quoted strings in the cryptomatte manifest, so we need to strip them here to avoid breaking the render engine JSON parser.
    // (This should be fixed in newer builds of the render server, so keep this here as a temporary workaround)
    return name ? name.replace(/["\\\t\n\r\f\b]/g, "") : name
}

function cached<S extends SceneNodes.SceneNode, R>(originalMethod: (node: S) => Promise<R>): (node: S) => Promise<R> {
    const circularRef = Symbol("circularRef")
    const cache = new Map<S, R | typeof circularRef>()

    return async function (node: S): Promise<R> {
        const cachedValue = cache.get(node)
        if (cachedValue !== undefined) {
            if (cachedValue === circularRef) throw Error("Circular reference detected")
            return cachedValue
        }

        cache.set(node, circularRef)

        const result = await originalMethod(node)
        cache.set(node, result)

        return result
    }
}

type IdDetails = {
    legacyId: number
}

type RenderGraphBuilderInput = {
    nodes: SceneNodes.SceneNode[]
    final: boolean
    aoMaskPass?: boolean
    verifySchema: boolean
    passes?: RenderNodes.PassName[]
}

type MeshGeometry = {
    meshData: RenderNodes.MeshData
    transform: number[]
}

class RenderGraphBuilder {
    constructor(private resolveFns: RenderGraphResolveFunctions) {}

    async build(data: RenderGraphBuilderInput): Promise<RenderNodes.Render> {
        const nodes = data.nodes
        const final = data.final
        const aoMaskPass = data.aoMaskPass
        const renderNode = nodes.find(SceneNodes.RenderSettings.is)
        if (!renderNode) throw Error("Cannot find render node")

        const rootRenderNode: RenderNodes.Render = {
            type: "render",
            schemaVersion: 1,
            session: {
                options: {
                    // threads: Math.floor(navigator.hardwareConcurrency * 0.9),
                    // use_path_guiding: false,
                    // use_light_tree: false,
                    cloud: renderNode.cloud,
                    gpu: renderNode.gpu,
                    transparent_background: true,
                    transparent_glass: true,
                    final_render: final,
                    use_denoising: true,
                    adaptive_subdivision_offscreen_dicing_scale: 256.0,
                    passes:
                        data.passes ??
                        (final
                            ? [
                                  "Combined",
                                  "CryptoObject00",
                                  // 'CryptoObject01',
                                  // 'CryptoObject02',
                                  "CryptoMaterial00",
                                  // 'CryptoMaterial01',
                                  // 'CryptoMaterial02',
                                  "CryptoAsset00",
                                  // 'CryptoAsset01',
                                  // 'CryptoAsset02',
                              ]
                            : ["Combined"]),
                },
            },
            scene: {
                camera: {
                    transform: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                    shiftX: 0,
                    shiftY: 0,
                    fStop: 128,
                    focalLength: 50,
                    focalDistance: 100,
                    sensorSize: 35,
                    exposure: 1,
                },
                objects: [],
                lights: [],
            },
            width: Math.round(renderNode.width),
            height: Math.round(renderNode.height),
            samples: Math.round(renderNode.samples),
        }

        const optionsNode = nodes.find((x): x is SceneNodes.SceneOptions => x.type === "SceneOptions")
        const scene = rootRenderNode.scene

        const createMeshGeometry = cached(async (node: SceneNodes.Mesh): Promise<MeshGeometry> => {
            let meshData: RenderNodes.MeshData
            if (node.meshData.graph) {
                //TODO: traverse graph looking for data object references, add them to the pending list
                meshData = node.meshData.graph
            } else {
                meshData = {type: "empty"}
            }

            return {
                meshData,
                transform: node.transform.toArray(),
            }
        })

        let shadowCatcherUsed = false
        const createMeshObject = cached(async (node: SceneNodes.Mesh): Promise<RenderNodes.Mesh | null> => {
            if (aoMaskPass && node.visibleDirectly == false) {
                // (default is true, so compare to explicit false value)
                // NOTE: just setting visibleToShadowRays to false for non-shadow catcher meshes doesn't work with environment lights.
                // (Cycles considers this case as a diffuse ray, apparently)
                // just skip this mesh as it should not contribute to the shadow mask
                return null
            }

            const {meshData, transform} = await createMeshGeometry(node)

            const meshDataInfo = getMeshGraphInfo(meshData)

            const shaders: RenderNodes.Mesh["shaders"] = {}

            const mesh: RenderNodes.Mesh = {
                type: "mesh",
                id: node.id,
                transform,
                meshData,
                shaders,
                visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                visibleInReflections: node.visibleInReflections ? undefined : false,
                visibleInRefractions: node.visibleInRefractions ? undefined : false,
                cryptomatteObjectName: sanitizeCryptoMatteName(node.meshRenderSettings.cryptoMatteObjectName),
                cryptomatteAssetName: sanitizeCryptoMatteName(node.meshRenderSettings.cryptoMatteAssetName),
            }
            if (aoMaskPass) {
                // Hide normal meshes from the camera so only the shadow catcher (mask) is visible
                mesh.visibleInCamera = false
            }

            for (const [slot, materialData] of node.materialMap?.entries() ?? []) {
                if (!materialData) continue
                //TODO: cache converted materials
                if (
                    optionsNode?.enableAdaptiveSubdivision &&
                    (this.materialHasDisplacement(materialData) || node.meshRenderSettings.displacementImage || node.meshRenderSettings.displacementDataObject)
                ) {
                    if (meshDataInfo.maxSubdivLevel == 0) {
                        console.warn("Enabling adaptive subdivision for mesh with subdivLevel = 0")
                    }
                    mesh.adaptiveSubdivisionRate = 1.0
                    console.log(`Enabling adaptive subdivision for material ${materialData.name}`)
                }
                // real materials must be used even for aoMask pass, because they may have transparency
                //TODO: check for mixed shadow catcher / non shadow catcher materials on mesh
                let shader = await this.convertMaterial(materialData, node, {meshData, transform}, getObjects, final)
                if (!shader) continue
                shader = convertCyclesMaterialBetweenVersions(shader)

                if (isShadowCatcherMaterial(materialData.materialGraph)) {
                    console.warn("TODO: deprecate shadow_catcher material parameter")
                    if (shader.parameters) delete shader.parameters["shadow_catcher"]
                    mesh.shadowCatcher = true
                    shadowCatcherUsed = true
                    if (aoMaskPass) {
                        mesh.visibleToShadowRays = false
                        mesh.visibleInCamera = true
                    }
                }
                if (!shader.parameters) shader.parameters = {}
                shader.parameters["name"] = sanitizeCryptoMatteName(materialData.name) // this is used as the cryptomatte material name
                shaders[slot] = shader
            }

            return mesh
        })

        const createSeamObjects = cached(async (node: SceneNodes.Seam): Promise<RenderNodes.MeshInstances[]> => {
            if (!node.curvePoints) return []
            const {points, normals, tangents, scales} = node.curvePoints

            const curvePoints = {
                points: Array.from(points),
                normals: Array.from(normals),
                tangents: Array.from(tangents),
                scales: Array.from(scales),
            }

            const meshCurveControl = nodes.find((x): x is SceneNodes.MeshCurveControl => SceneNodes.MeshCurveControl.is(x) && x.id === node.meshCurveControlId)
            if (!meshCurveControl) throw Error(`Curve control mesh not found for seam node ${node.id} (meshCurveControlId: ${node.meshCurveControlId})`)
            const meshNode = nodes.find((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x) && x.id === meshCurveControl.meshId)
            if (!meshNode) throw Error(`Mesh node found for seam node ${node.id} (meshId: ${meshCurveControl.meshId})`)

            const {meshData} = await createMeshGeometry(meshNode)

            const result: RenderNodes.MeshInstances[] = []
            for (const currentItem of node.item) {
                const currentItemNode = await createMeshObject(currentItem)
                if (!currentItemNode) continue

                result.push({
                    type: "meshInstances",
                    id: `${node.id}_${currentItem.id}`,
                    mesh: currentItemNode,
                    transform: node.transform.toArray(),
                    instances: {
                        type: "curveIntersections",
                        meshData,
                        curvePoints,
                    },
                })
            }

            return result
        })

        const getObjects = async (node: SceneNodes.SceneNode): Promise<RenderNodes.Object[]> => {
            if (SceneNodes.Mesh.is(node)) {
                const mesh = await createMeshObject(node)
                if (mesh) return [mesh]
                else return []
            } else if (SceneNodes.Seam.is(node)) {
                const meshInstances = await createSeamObjects(node)
                return meshInstances
            } else return []
        }

        let bestEnvNode: SceneNodes.Environment | null = null
        for (const node of nodes) {
            if (SceneNodes.Mesh.is(node)) {
                const mesh = await createMeshObject(node)
                if (mesh) scene.objects.push(mesh)
            } else if (SceneNodes.Seam.is(node)) {
                const meshInstances = await createSeamObjects(node)
                scene.objects.push(...meshInstances)
            } else if (SceneNodes.Camera.is(node)) {
                scene.camera.transform = node.transform.toArray()
                scene.camera.shiftX = node.shiftX
                scene.camera.shiftY = node.shiftY
                scene.camera.nearClip = node.nearClip
                scene.camera.farClip = node.farClip
                scene.camera.fStop = node.fStop
                scene.camera.focalLength = node.focalLength
                scene.camera.focalDistance = node.focalDistance
                scene.camera.sensorSize = node.filmGauge
                scene.camera.exposure = GlobalRenderConstants.exposureScale
            } else if (SceneNodes.AreaLight.is(node)) {
                // emissive mesh:
                //TODO: For an emissive mesh to work with shadow catcher objects, it must _also_ be set as a shadow catcher, and thus be invisible to the camera. This seems like a Cycles design issue...
                // scene.objects.push({
                //     id: node.id,
                //     transform: node.transform.toArray(),
                //     meshData: {
                //         type: 'plane',
                //         width: node.width,
                //         height: node.height,
                //         normalAxis: 'z-',
                //     },
                //     shaders: {
                //         0: this.createAreaLightShader(node.color, node.on ? (node.intensity * GlobalRenderConstants.lightIntensityScale) : 0, node.directionality, node.transparent)
                //     },
                //     visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                //     visibleInReflections: node.visibleInReflections ? undefined : false,
                //     visibleInRefractions: node.visibleInRefractions ? undefined : false,
                // });

                if (node.on && !aoMaskPass) {
                    scene.lights.push({
                        type: "area",
                        id: node.id,
                        transform: node.transform.toArray(),
                        width: node.width,
                        height: node.height,
                        strength: node.intensity * GlobalRenderConstants.lightIntensityScale,
                        directionality: node.directionality ?? 0,
                        visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                        visibleInReflections: node.visibleInReflections ? undefined : false,
                        visibleInRefractions: node.visibleInRefractions ? undefined : false,
                        shader: this.createEmissionShader(node.color),
                    })
                }
                if (!node.transparent && !aoMaskPass) {
                    scene.objects.push({
                        type: "mesh",
                        id: node.id + "-mesh",
                        transform: node.transform.multiply(Matrix4.translation(0, 0, 0.1)).toArray(), // offset 1mm along z+ axis
                        meshData: {
                            type: "plane",
                            width: node.width,
                            height: node.height,
                            normalAxis: "z-",
                        },
                        shaders: {
                            0: this.createNullShader(),
                        },
                        visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                        visibleInReflections: node.visibleInReflections ? undefined : false,
                        visibleInRefractions: node.visibleInRefractions ? undefined : false,
                    })
                }
            } else if (SceneNodes.LightPortal.is(node)) {
                if (!aoMaskPass) {
                    scene.lights.push({
                        type: "portal",
                        id: node.id,
                        transform: node.transform.toArray(),
                        width: node.width,
                        height: node.height,
                    })
                }
            } else if (SceneNodes.Environment.is(node)) {
                if (!bestEnvNode || node.priority <= bestEnvNode.priority) {
                    bestEnvNode = node
                }
            }
        }

        if (bestEnvNode) {
            const node = bestEnvNode
            //TODO: clampHighlights?
            if (!aoMaskPass) {
                if (node.envData?.type === "hdri") {
                    const hdriDataObjectIdDetails = await this.resolveFns.getHdriDataObjectIdDetails({legacyId: node.envData.hdriID})
                    const imageNode = RenderNodes.loadImage(hdriDataObjectIdDetails.legacyId)
                    scene.environment = this.createEnvironmentShader(imageNode, node.rotation, node.intensity, node.mirror)
                } else {
                    console.warn("TODO: create env map shader from URL")
                }
            }
        }

        if (aoMaskPass) {
            rootRenderNode.session.options["max_bounces"] = 0
            scene.environment = this.createUniformEnvironmentShader()
            rootRenderNode.session.options["passes"] = ["ShadowCatcher"]
        } else {
            const setPassEnabled = (name: RenderNodes.PassName, enabled: boolean) => {
                const list = rootRenderNode.session.options.passes ?? []
                const idx = list.indexOf(name)
                if (enabled && idx === -1) {
                    list.push(name)
                } else if (!enabled && idx !== -1) {
                    list.splice(idx, 1)
                }
            }
            setPassEnabled("ShadowCatcher", shadowCatcherUsed)
        }

        if (data.verifySchema) {
            // try to catch potentially invalid render graph early, before the job submission
            // this seems to be quite expensive... so allow for it to be disabled
            const parsedGraph = RenderNodes.RenderSchema.safeParse(rootRenderNode)
            if (!parsedGraph.success) throw new Error(`Failed to parse render graph: ${parsedGraph.error.errors.map((e) => e.message).join(", ")}`)
            return rootRenderNode //We should not use the parsed graph here, as identity of objects is important
        } else {
            return rootRenderNode
        }
    }

    private createNullShader(): RenderNodes.ShaderNode {
        return ShaderExpr.materialOutput(null).compile()
    }

    private createEmissionShader(color: [number, number, number], strength = 1.0) {
        return ShaderExpr.materialOutput(ShaderExpr.emissionBRDF(color, strength)).compile()
    }

    private createAreaLightShader(color: [number, number, number], intensity: number, directionality: number, transparent: boolean): RenderNodes.ShaderNode {
        // conversion table from Corona directonality to platform [directionality, scale factor]:
        // 0.0 -> [0.000, 1.000]
        // 0.1 -> [0.000, 1.000]
        // 0.2 -> [0.001, 1.000]
        // 0.3 -> [0.015, 1.025]
        // 0.4 -> [0.231, 1.182]
        // 0.5 -> [0.578, 1.724]
        // 0.6 -> [0.817, 1.837]
        // 0.7 -> [0.914, 1.853]
        // 0.8 -> [0.956, 1.890]
        // 0.9 -> [0.976, 1.798]
        const geom = ShaderExpr.geometryInfo()
        let strength = geom.backfacing.oneMinus().mul(intensity)
        if (directionality > 0) {
            // mimic behavior of Cycles area lights
            const minAngle = (1 * Math.PI) / 180
            const spreadAngle = Math.max(minAngle, Math.PI * (1 - directionality))
            const a = (Math.PI - spreadAngle) * 0.5
            const tanSpread = Math.tan(a)
            const normalizeSpread = 2 / (2 + (2 * a - Math.PI) * tanSpread)
            const cos_a = geom.incoming.dot(geom.normal)
            const sin_a = cos_a.square().oneMinus().sqrt()
            const tan_a = sin_a.div(cos_a)
            strength = strength.mul(tan_a.mul(tanSpread).oneMinus().max(0).mul(normalizeSpread))
        }
        let brdf = ShaderExpr.emissionBRDF(color, strength)
        if (transparent) {
            brdf = ShaderExpr.addBRDF(brdf, ShaderExpr.transparentBRDF())
        }
        return ShaderExpr.materialOutput(brdf, {use_mis: intensity > 0}).compile()
    }

    private createEnvironmentShader(image: RenderNodes.Image, rotation: Vector3, intensity: number, mirror?: boolean): RenderNodes.ShaderNode {
        //TODO: enabling importance sampling by default introduces extra noise when the env map is not the primary source of light. Perhaps use (intensity > 0) as the condition for enabling it?
        const degToRad = Math.PI / 180
        const map1 = ShaderExpr.mapping(ShaderExpr.geometryInfo().position, {
            rotation: [rotation.x * degToRad, rotation.y * degToRad, rotation.z * degToRad],
            scale: mirror ? [-1, 1, 1] : [1, 1, 1],
        })
        const map2 = ShaderExpr.mapping(map1, {rotation: [Math.PI / 2, 0.0, 0.0]})
        const tex = ShaderExpr.environmentTexture(map2, image)
        const brdf = ShaderExpr.backgroundBRDF(tex, intensity)
        return ShaderExpr.materialOutput(brdf, {use_mis: intensity > 0}).compile()
    }

    private createUniformEnvironmentShader(color: Color = [1, 1, 1], intensity = 1.0): RenderNodes.ShaderNode {
        const brdf = ShaderExpr.backgroundBRDF(ShaderExpr.color(color), intensity)
        return ShaderExpr.materialOutput(brdf).compile()
    }

    private materialHasDisplacement(materialData: IMaterialData, _final = false): boolean {
        const rootNode = materialData.materialGraph?.rootNode
        return !!rootNode.inputs && (!!rootNode.inputs["Displacement"] || !!rootNode.inputs["displacement"])
    }

    private async convertMaterial(
        materialData: IMaterialData,
        mesh: SceneNodes.Mesh,
        meshGeometry: MeshGeometry,
        getObjects: (node: SceneNodes.SceneNode) => Promise<RenderNodes.Object[]>,
        final = false,
    ): Promise<RenderNodes.ShaderNode | null> {
        if (!materialData?.materialGraph) return null

        const {meshRenderSettings} = mesh

        // TODO remove this once fetching of mesh related data also migrated to the new API
        const displacementImage =
            meshRenderSettings.displacementImage?.imageNode ??
            (meshRenderSettings.displacementDataObject
                ? this.resolveFns.mapLegacyDataObjectToImageResource(meshRenderSettings.displacementDataObject)
                : undefined)

        const textureFormatAndResolutionForPreview = {format: "jpg", resolution: "2000px"} as const

        const imageTextureSetNodesCache = new Map<MaterialGraphNode, ImageTextureSetNodes>()
        const setImageTextureNodesCache = new Map<MaterialGraphNode, WrappedMaterialGraphNode>()
        const nodeMap = new Map<MaterialGraphNode, RenderNodes.ShaderNode>()
        const evalNode = async (node: MaterialGraphNode): Promise<[RenderNodes.ShaderNode, string | undefined]> => {
            const [unwrappedNode, outputName] = unwrapNodeOutput(node)
            if (outputName) {
                // special handling of nodes which are defined by the platform but not part of Cycles
                if (isNodeOfType(unwrappedNode, "ShaderNodeTextureSet")) {
                    let imageTextureSetNodes = imageTextureSetNodesCache.get(unwrappedNode)
                    if (!imageTextureSetNodes) {
                        imageTextureSetNodes = createImageTextureSetNodes(unwrappedNode)
                        imageTextureSetNodesCache.set(unwrappedNode, imageTextureSetNodes)
                    }
                    switch (outputName) {
                        case "BaseColor":
                            return evalNode(imageTextureSetNodes.baseColor)
                        case "Metallic":
                            return evalNode(imageTextureSetNodes.metallic)
                        case "Specular":
                            return evalNode(imageTextureSetNodes.specular)
                        case "Roughness":
                            return evalNode(imageTextureSetNodes.roughness)
                        case "Anisotropic":
                            return evalNode(imageTextureSetNodes.anisotropic)
                        case "AnisotropicRotation":
                            return evalNode(imageTextureSetNodes.anisotropicRotation)
                        case "Alpha":
                            return evalNode(imageTextureSetNodes.alpha)
                        case "Normal":
                            return evalNode(imageTextureSetNodes.normal)
                        case "Displacement":
                            return evalNode(imageTextureSetNodes.displacement)
                        case "Transmission":
                            return evalNode(imageTextureSetNodes.transmission)
                        default:
                            throw new Error("ShaderNodeTextureSet: Unknown output name")
                    }
                } else if (isNodeOfType(unwrappedNode, "ShaderNodeSetTexture")) {
                    let setImageTextureNode = setImageTextureNodesCache.get(unwrappedNode)
                    if (!setImageTextureNode) {
                        setImageTextureNode = createSetImageTextureNode(unwrappedNode)
                        setImageTextureNodesCache.set(unwrappedNode, setImageTextureNode)
                    }
                    return evalNode(setImageTextureNode)
                }
                // regular handling
                const [evaledNode, evaledOutputName] = await evalNode(unwrappedNode)
                return [evaledNode, evaledOutputName ?? mapColormassToCyclesOutputName(evaledNode.type, outputName)]
            }
            let outNode = nodeMap.get(node)
            if (outNode) {
                return [outNode, undefined]
            }
            if (isOutputWrapper(node)) {
                throw new Error("Unwrapped node expected!")
            } else if (node.nodeType === "ShaderNodeScannedTransmission") {
                return evalNode(createScannedTransmissionNodes(node))
            }

            const mappedNodeType = mapColormassToCyclesNodeType(node.nodeType)
            if (!mappedNodeType) {
                throw new Error(`Unknown material node type: ${node.nodeType}`)
            }
            outNode = {
                type: mappedNodeType,
            }
            const mappedNodeParameters = mapColormassToCyclesNodeParameterSet(node)
            if (mappedNodeParameters) {
                if (!outNode.parameters) outNode.parameters = {}
                for (const [name, value] of Object.entries(mappedNodeParameters)) {
                    const mappedParam = mapColormassToCyclesProperty(mappedNodeType, name, value)
                    if (mappedParam) {
                        const [mappedName, mappedValue] = mappedParam
                        outNode.parameters[mappedName] = mappedValue
                    }
                }
            }
            if (node.inputs) {
                if (!outNode.inputs) outNode.inputs = {}
                for (const [name, input] of Object.entries(node.inputs)) {
                    const [sourceNode, sourceOutputName] = await evalNode(input)
                    if (sourceNode) {
                        if (!sourceOutputName) {
                            console.error("No output socket name:", node)
                            throw Error(`Cannot connect node '${sourceNode.type}' without output socket name`)
                        }
                        const mappedInputName = mapColormassToCyclesInputName(mappedNodeType, name)
                        if (mappedInputName) {
                            outNode.inputs[mappedInputName] = [sourceNode, sourceOutputName]
                        }
                    }
                }
            }
            if (isNodeOfType(node, "OutputMaterial")) {
                if (displacementImage) {
                    const displacementMin = meshRenderSettings.displacementMin ?? -1
                    const displacementMax = meshRenderSettings.displacementMax ?? 1

                    const existingDisplacementOutput = outNode.inputs?.["displacement"]

                    const texNodeData = await (async (): Promise<[RenderNodes.ShaderNode, string]> => {
                        if (isImageNodeGenerator(displacementImage)) {
                            const uvMapNode = wrapNodeOutput<"UVMap">(
                                {
                                    nodeType: "UVMap",
                                    parameters: {
                                        "internal.uv_map_index": meshRenderSettings.displacementUvChannel,
                                    },
                                },
                                "UV",
                            )

                            const [texNode, outputSocket] = await evalNode(
                                displacementImage.generator({uv: uvMapNode, extension: "REPEAT", interpolation: "Linear", projection: "FLAT"}).color,
                            )
                            if (!outputSocket) throw Error("Expected output socket")
                            return [texNode, outputSocket]
                        } else {
                            if (isResolvedResourceWithTransientDataObject(displacementImage)) throw Error("imageResource data must be a stored DataObject!")

                            const uvMapNode: RenderNodes.ShaderNode = {
                                type: "uvmap",
                                parameters: {attribute: CyclesNodePropertyConversions.uvMapAttrName(meshRenderSettings.displacementUvChannel)},
                            }

                            const texNode: RenderNodes.ShaderNode = {
                                type: "image_texture",
                                parameters: {
                                    colorspace: "none",
                                },
                                resources: {
                                    image: RenderNodes.loadImage(displacementImage.mainDataObject.legacyId),
                                },
                                inputs: {vector: [uvMapNode, "uv"]},
                            }

                            return [texNode, "color"]
                        }
                    })()

                    const dispNode: RenderNodes.ShaderNode = {
                        type: "displacement",
                        parameters: {
                            midlevel: -displacementMin / (displacementMax - displacementMin),
                            scale: displacementMax - displacementMin,
                        },
                        inputs: {height: texNodeData},
                    }
                    let newDisplacementOutput: [RenderNodes.ShaderNode, string]
                    if (existingDisplacementOutput) {
                        newDisplacementOutput = [
                            {
                                type: "vector_math",
                                parameters: {type: "add"},
                                inputs: {
                                    vector1: existingDisplacementOutput,
                                    vector2: [dispNode, "displacement"],
                                },
                            },
                            "vector",
                        ]
                    } else {
                        newDisplacementOutput = [dispNode, "displacement"]
                    }

                    if (!outNode.inputs) {
                        outNode.inputs = {}
                    }
                    outNode.inputs!["displacement"] = newDisplacementOutput
                }
            } else if (isNodeOfType(node, "TexImage")) {
                if (node.resolvedResources) {
                    if (node.resolvedResources.length !== 1) {
                        throw new Error("TexImage: Expected exactly one image resource")
                    }
                    const imageResource = node.resolvedResources[0]
                    if (isResolvedResourceWithTransientDataObject(imageResource)) throw Error("imageResource must be a stored DataObject!")
                    let dataObject = imageResource.mainDataObject

                    if (!final) {
                        const previewdataObject = getDataObjectFromImageResourceForFormatAndResolution(
                            imageResource,
                            textureFormatAndResolutionForPreview.format,
                            textureFormatAndResolutionForPreview.resolution,
                        )
                        if (!previewdataObject) throw Error("Failed to get preview data object")
                        dataObject = previewdataObject
                    }
                    if (!outNode.parameters) outNode.parameters = {}
                    outNode.parameters["colorspace"] = dataObject.imageColorSpace === ImageColorSpace.Srgb ? "__builtin_srgb" : "__builtin_raw"
                    if (!outNode.resources) outNode.resources = {}
                    outNode.resources["image"] = RenderNodes.loadImage(dataObject.legacyId)
                }
            } else if (isNodeOfType(node, "DistanceTexture")) {
                if (!outNode.parameters) outNode.parameters = {}
                outNode.parameters["colorspace"] = "__builtin_raw"
                if (!outNode.resources) outNode.resources = {}

                const {meshData, transform} = meshGeometry

                outNode.resources["image"] = RenderNodes.computeImage({
                    type: "distanceTexture",
                    meshData,
                    transform,
                    range: node.parameters?.["internal.range"],
                    width: node.parameters?.["internal.width"],
                    height: node.parameters?.["internal.height"],
                    forceOriginalResolution: node.parameters?.["internal.forceOriginalResolution"],
                    uvChannel: node.parameters?.["internal.uvChannel"],
                    targets: (await Promise.all((node.parameters?.["internal.targets"] as SceneNodes.SceneNode[]).map(getObjects))).flat(),
                    innerValue: node.parameters?.["internal.innerValue"],
                })
            }

            nodeMap.set(node, outNode)
            return [outNode, undefined]
        }

        const [outputNode, _outputSocket] = await evalNode(materialData.materialGraph.rootNode)
        return outputNode
    }
}

export function buildRenderGraph(data: RenderGraphBuilderInput, resolveFns: RenderGraphResolveFunctions): Promise<RenderNodes.Render> {
    return new RenderGraphBuilder(resolveFns).build(data)
}

const IOR_DEFAULT = 1.45 // Based on value from the material template (which in turn based on Blender 2.83), this changed in Cycles 4.2 to 1.5
const SPECULAR_IOR_LEVEL_DEFAULT = 0.0
const BASE_COLOR_DEFAULT = [0.8, 0.8, 0.8]
const SPECULAR_TINT_DEFAULT = 0.0

// account for changes in parametrization of Cycles Principled Shader between versions 3.6 and 4.2
// TODO Unify this with the rest of cycles material conversion once the material graph is migrated to the new graph system
function convertCyclesMaterialBetweenVersions(root: RenderNodes.ShaderNode): RenderNodes.ShaderNode {
    const nodeMap = new Map<RenderNodes.ShaderNode, RenderNodes.ShaderNode>()

    const defaultEvalNode = (node: RenderNodes.ShaderNode): RenderNodes.ShaderNode => {
        const evalInputs: NonNullable<RenderNodes.ShaderNode["inputs"]> = {}
        if (node.inputs) {
            for (const [name, input] of Object.entries(node.inputs)) {
                evalInputs[name] = [evalNode(input[0]), input[1]]
            }
        }

        return {
            type: node.type,
            inputs: evalInputs,
            parameters: {...node.parameters},
            resources: {...node.resources},
        }
    }

    const evalPrincipledShaderNode = (node: RenderNodes.ShaderNode): RenderNodes.ShaderNode => {
        const evaluatedNode: Required<RenderNodes.ShaderNode> = {
            type: node.type,
            inputs: node.inputs ? {...node.inputs} : {},
            parameters: node.parameters ? {...node.parameters} : {},
            resources: node.resources ? {...node.resources} : {},
        }

        const two = ShaderExpr.value(2.0)
        const one = ShaderExpr.value(1.0)

        // specular ior level adjustment
        // see diff for src\kernel\svm\closure.h between our Cycles 3.6 and 4.2 forks
        const IORInput = evaluatedNode.inputs["ior"]
        const IOR = IORInput ? new ShaderExpr(IORInput[0], IORInput[1]) : ShaderExpr.value(evaluatedNode.parameters["ior"] ?? IOR_DEFAULT)
        delete evaluatedNode.inputs.ior
        delete evaluatedNode.parameters.ior

        const specularInput = evaluatedNode.inputs["specular_ior_level"]
        const specular = specularInput
            ? new ShaderExpr(specularInput[0], specularInput[1])
            : ShaderExpr.value(evaluatedNode.parameters["specular_ior_level"] ?? SPECULAR_IOR_LEVEL_DEFAULT)
        delete evaluatedNode.inputs.specular_ior_level
        delete evaluatedNode.parameters.specular_ior_level

        const fresnelF0 = (ior: ShaderExpr) => {
            const one = ShaderExpr.value(1.0)
            return ior.sub(one).div(ior.add(one)).square()
        }

        const targetIOR = two.div(one.sub(specular.mul(ShaderExpr.value(0.08)).sqrt())).sub(one)
        const targetF0 = fresnelF0(targetIOR)

        const F0 = fresnelF0(IOR)
        const specularIORLevel = targetF0.div(two.mul(F0))

        if (!IOR.output) throw Error("Invalid shader expression for IOR")
        if (!specularIORLevel.output) throw Error("Invalid shader expression for specularIORLevel")

        evaluatedNode.inputs["ior"] = [IOR.node, IOR.output]
        evaluatedNode.inputs["specular_ior_level"] = [specularIORLevel.node, specularIORLevel.output]

        // specular tint adjustment
        // see diff for src\kernel\svm\closure.h between our Cycles 3.6 and 4.2 forks
        // specular tint was also changed from float (with default 0.0) to color (with default white)
        const baseColorInput = evaluatedNode.inputs["base_color"]
        const baseColor = baseColorInput
            ? new ShaderExpr(baseColorInput[0], baseColorInput[1])
            : ShaderExpr.color(evaluatedNode.parameters["base_color"] ?? BASE_COLOR_DEFAULT)
        delete evaluatedNode.inputs["base_color"]
        delete evaluatedNode.parameters["base_color"]

        const specularTintInput = evaluatedNode.inputs["specular_tint"]
        const specularTint = specularTintInput
            ? new ShaderExpr(specularTintInput[0], specularTintInput[1])
            : ShaderExpr.value(evaluatedNode.parameters["specular_tint"] ?? SPECULAR_TINT_DEFAULT)
        delete evaluatedNode.inputs["specular_tint"]
        delete evaluatedNode.parameters["specular_tint"]

        const {r: baseColorR, g: baseColorG, b: baseColorB} = baseColor.separateRGB()

        const luminance = ShaderExpr.value(0.3).mul(baseColorR).add(ShaderExpr.value(0.6).mul(baseColorG)).add(ShaderExpr.value(0.1).mul(baseColorB))
        const tintWeight = luminance.gt(ShaderExpr.value(0.0))

        const tintR = tintWeight.mul(baseColorR.div(luminance)).add(one.sub(tintWeight))
        const tintG = tintWeight.mul(baseColorG.div(luminance)).add(one.sub(tintWeight))
        const tintB = tintWeight.mul(baseColorB.div(luminance)).add(one.sub(tintWeight))

        const specularTintNewR = one.sub(specularTint).add(tintR.mul(specularTint))
        const specularTintNewG = one.sub(specularTint).add(tintG.mul(specularTint))
        const specularTintNewB = one.sub(specularTint).add(tintB.mul(specularTint))

        const specularTintNew = ShaderExpr.combineRGB(specularTintNewR, specularTintNewG, specularTintNewB)

        if (!baseColor.output) throw Error("Invalid shader expression for baseColor")
        if (!specularTintNew.output) throw Error("Invalid shader expression for specularTintNew")

        evaluatedNode.inputs["base_color"] = [baseColor.node, baseColor.output]
        evaluatedNode.inputs["specular_tint"] = [specularTintNew.node, specularTintNew.output]

        // subsurface color - dropped in Cycles 4.2
        delete evaluatedNode.inputs["subsurface_color"]
        delete evaluatedNode.parameters["subsurface_color"]

        const emissionInput = evaluatedNode.inputs["emission"]
        const emission = emissionInput
            ? new ShaderExpr(emissionInput[0], emissionInput[1])
            : evaluatedNode.parameters["emission"]
              ? ShaderExpr.value(evaluatedNode.parameters["emission"])
              : undefined
        delete evaluatedNode.inputs["emission"]
        delete evaluatedNode.parameters["emission"]
        if (emission) {
            if (!emission.output) throw Error("Invalid shader expression for emission")
            evaluatedNode.inputs["emission_color"] = [emission.node, emission.output]
            evaluatedNode.parameters["emission_strength"] = 1.0
        } else {
            evaluatedNode.parameters["emission_strength"] = 0.0
        }

        return defaultEvalNode(evaluatedNode)
    }

    const evalNode = (node: RenderNodes.ShaderNode): RenderNodes.ShaderNode => {
        const cachedNode = nodeMap.get(node)
        if (cachedNode) return cachedNode

        const convertedNode = (() => {
            switch (node.type) {
                case "principled_bsdf":
                    return evalPrincipledShaderNode(node)
                default:
                    return defaultEvalNode(node)
            }
        })()

        nodeMap.set(node, convertedNode)
        return convertedNode
    }

    return evalNode(root)
}
