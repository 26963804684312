import {Geometry} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-rasterize-geometry"
import {Size2Like, Vector2, Vector2Like} from "@cm/math"

export type GridPoint = {
    sourcePixel: Vector2Like
    targetPixel: Vector2Like
}

export type ParameterType = {
    gridPoints: GridPoint[][] // 2D array of grid points (inner array = x-axis, outer array = y-axis)
    sourcePixelOffset?: Vector2Like // default: {x: 0, y: 0}
    targetPixelOffset?: Vector2Like // default: {x: 0, y: 0}
}

export type ResultType = {
    geometry: Geometry
    resultSize: Size2Like
}

export function createGridMappingGeometry({gridPoints, sourcePixelOffset, targetPixelOffset}: ParameterType): ResultType {
    sourcePixelOffset ??= {x: 0, y: 0}
    targetPixelOffset ??= {x: 0, y: 0}
    const resultSize = new Vector2()
    const vertexPositions: Vector2Like[] = []
    const vertexUVs: Vector2Like[] = []
    const indices: number[] = []
    const numGridPointsX = gridPoints[0].length
    const numGridPointsY = gridPoints.length
    for (let y = 0; y < numGridPointsY; y++) {
        for (let x = 0; x < numGridPointsX; x++) {
            // vertex
            const point = gridPoints[y][x]
            const vertexPosition = {
                x: point.targetPixel.x + targetPixelOffset.x,
                y: point.targetPixel.y + targetPixelOffset.y,
            }
            const vertexUV = {
                x: point.sourcePixel.x + sourcePixelOffset.x,
                y: point.sourcePixel.y + sourcePixelOffset.y,
            }
            vertexPositions.push(vertexPosition)
            vertexUVs.push(vertexUV)
            resultSize.maxInPlace(vertexPosition)
            // index
            if (x > 0 && y > 0) {
                const i0 = (y - 1) * numGridPointsX + x - 1
                const i1 = i0 + 1
                const i2 = i0 + numGridPointsX
                const i3 = i0 + numGridPointsX + 1
                indices.push(i0, i1, i2, i1, i3, i2)
            }
        }
    }
    resultSize.roundInPlace()
    return {
        geometry: {
            topology: "triangleList",
            vertices: {
                positions: vertexPositions,
                uvs: vertexUVs,
            },
            indices,
        },
        resultSize: {
            width: resultSize.x,
            height: resultSize.y,
        },
    }
}
