import {Matrix4} from "@cm/math"
import {fileToArrayBuffer} from "@legacy/helpers/utils"
import {MeshDataGraph} from "@cm/template-nodes"
import {StoredMesh} from "@cm/template-nodes"
import {Observable, firstValueFrom, switchMap, map} from "rxjs"
import {convertOBJToDracoAndPLY, convertPLYToDraco, decompressMesh, WebAssemblyWorkerService} from "@editor/helpers/mesh-processing/mesh-processing"
import {MaterialAssignments} from "@cm/template-nodes"
import {Nodes} from "@cm/template-nodes"
import {CmmMesh, CmmMeta, CmmModel} from "@app/common/models/cmm"
import {ContentTypeModel, DataObjectAssignmentType, DataObjectType, ImageColorSpace, TextureType} from "@api"
import {Group} from "@cm/template-nodes"
import {DataObjectReference} from "@cm/template-nodes"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {SceneManagerService} from "../services/scene-manager.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {
    UploadTextureSettingDialogComponent,
    UploadTextureSettingDialogResult,
} from "@app/textures/texture-set-revision-view/upload-texture-settings-dialog/upload-texture-settings-dialog.component"

export const selectFile = (title: string, accept: string) => {
    return new Promise<File | null>((resolve, reject) => {
        const inputElement = document.createElement("input")
        inputElement.type = "file"
        inputElement.accept = accept
        inputElement.title = title

        inputElement.addEventListener("change", (event: Event) => {
            const eventTarget = event.target as HTMLInputElement
            if (!eventTarget.files) {
                resolve(null)
                return
            }

            const selectedFile = eventTarget.files[0]
            if (!selectedFile) {
                resolve(null)
                return
            }

            resolve(selectedFile)
        })
        inputElement.click()
        inputElement.remove()
    })
}

export const uploadMeshToGroup = async (
    sdkService: SdkService,
    uploadService: UploadGqlService,
    workerService: WebAssemblyWorkerService,
    sceneManagerService: SceneManagerService,
    file: File,
) => {
    const defaultCustomerId = sceneManagerService.$defaultCustomerId()
    if (!defaultCustomerId) throw new Error("defaultCustomerId is not defined")

    const templateRevisionId = sceneManagerService.$templateRevisionId()
    if (!templateRevisionId) throw new Error("templateRevisionId is not defined")

    const extension = file.name.split(".").pop()?.toLowerCase()
    if (!extension) throw new Error("Failed to get extension from file name")

    const pendingCmmModel = (() => {
        if (extension === "cmm") return CmmModel.fromCmmFile(file)
        else if (extension === "obj") return convertOBJFile(file, workerService)
        else if (extension === "ply") return convertPLYFile(file, workerService)
        else throw new Error(`Unsupported file extension: ${extension}`)
    })()

    const cmmModel = await firstValueFrom(pendingCmmModel)

    if (cmmModel.meshes.length === 0) return false

    const uploadCmmMesh = async (cmmMesh: CmmMesh) => {
        await Promise.all(
            [...cmmMesh.data.entries()].map(async ([format, buffer]) => {
                let fileName = cmmMesh.originalFileName
                let assignmentType: DataObjectAssignmentType
                let type: string
                if (format === "drc") {
                    fileName += ".drc"
                    assignmentType = DataObjectAssignmentType.MeshDataDrc
                    type = "application/draco"
                } else if (format === "drc_proxy") {
                    fileName += "_proxy.drc"
                    assignmentType = DataObjectAssignmentType.MeshDataDrcProxy
                    type = "application/draco"
                } else if (format === "ply") {
                    fileName += ".ply"
                    assignmentType = DataObjectAssignmentType.MeshDataPly
                    type = "application/ply"
                } else throw Error(`Unrecognized mesh format: ${format}`)

                const dataObject = await uploadService.createAndUploadDataObject(
                    new File([buffer], fileName, {type}),
                    {
                        type: DataObjectType.Mesh,
                        organizationLegacyId: defaultCustomerId,
                    },
                    {processUpload: true, showUploadToolbar: true},
                )

                cmmMesh.dataObjectIds.set(format, dataObject.legacyId)

                await sdkService.gql.createDataObjectAssignmentForSceneManagerService({
                    input: {
                        objectId: templateRevisionId,
                        type: assignmentType,
                        contentTypeModel: ContentTypeModel.TemplateRevision,
                        dataObjectId: dataObject.id,
                    },
                })
            }),
        )

        const drcDataObjectId = cmmMesh.dataObjectIds.get("drc")
        const plyDataObjectId = cmmMesh.dataObjectIds.get("ply")
        if (!drcDataObjectId) throw new Error(`drcDataObjectId is not defined for mesh ${cmmMesh.originalFileName}`)
        if (!plyDataObjectId) throw new Error(`plyDataObjectId is not defined for mesh ${cmmMesh.originalFileName}`)

        const dracoData = cmmMesh.data.get("drc")
        if (!dracoData) throw new Error(`dracoData is not defined for mesh ${cmmMesh.originalFileName}`)

        const graph: MeshDataGraph = {
            type: "loadMesh",
            data: {
                type: "dataObjectReference",
                dataObjectId: plyDataObjectId,
            },
        }

        const meshData = await firstValueFrom(decompressMesh(workerService, graph, dracoData.slice(0), 0))

        return {cmmMesh, meshData, drcDataObjectId, plyDataObjectId}
    }

    const group = new Group({name: `Uploaded Meshes (${new Date().toLocaleString()})`, nodes: new Nodes({list: []}), active: true})

    const uploadedMeshes = await Promise.all(cmmModel.meshes.map(uploadCmmMesh))

    uploadedMeshes.forEach(({cmmMesh, meshData, drcDataObjectId, plyDataObjectId}) => {
        const materialAssignments = new MaterialAssignments({})

        for (const materialGroup of meshData!.materialGroups) {
            const slot = `${materialGroup.materialIndex}`
            materialAssignments.updateParameters({[slot]: null})
        }

        const storedMesh = new StoredMesh({
            name: cmmMesh.name,
            metaData: {
                dracoBitDepth: cmmMesh.dracoBitDepth,
                dracoResolution: cmmMesh.dracoResolution,
                osdUseRenderIterations: cmmMesh.osdUseRenderIterations,
                osdRenderIterations: cmmMesh.osdRenderIterations,
                defaultPosition: cmmMesh.defaultPosition,
                exporterVersion: cmmMesh.exporterVersion,
            },
            drcDataObject: new DataObjectReference({name: `${cmmMesh.name}.ply`, dataObjectId: drcDataObjectId}),
            plyDataObject: new DataObjectReference({name: `${cmmMesh.name}.ply`, dataObjectId: plyDataObjectId}),
            subdivisionRenderIterations: cmmMesh.osdRenderIterations,
            materialAssignments,
            materialSlotNames: {},
            lockedTransform: cmmMesh.defaultPosition ? Matrix4.translation(...cmmMesh.defaultPosition).toArray() : undefined,
            visible: true,
            visibleDirectly: true,
            visibleInReflections: true,
            visibleInRefractions: true,
            castRealtimeShadows: true,
            receiveRealtimeShadows: true,
        })

        group.parameters.nodes.addEntry(storedMesh)
    })

    return group
}

export const uploadImage = async (
    sdkService: SdkService,
    uploadService: UploadGqlService,
    sceneManagerService: SceneManagerService,
    dialog: MatDialog,
    file: File,
) => {
    const defaultCustomerId = sceneManagerService.$defaultCustomerId()
    if (!defaultCustomerId) throw new Error("defaultCustomerId is not defined")

    const templateRevisionId = sceneManagerService.$templateRevisionId()
    if (!templateRevisionId) throw new Error("templateRevisionId is not defined")

    const dialogRef: MatDialogRef<UploadTextureSettingDialogComponent, UploadTextureSettingDialogResult | undefined> = dialog.open(
        UploadTextureSettingDialogComponent,
        {
            width: "350px",
            data: {
                showDisplacementSetting: false,
            },
        },
    )
    const result = await firstValueFrom(dialogRef.afterClosed())
    if (!result) return false

    const dataObject = await uploadService.createAndUploadDataObject(
        file,
        {
            type: DataObjectType.Image,
            organizationLegacyId: defaultCustomerId,
            imageColorSpace: result.colorSpace,
        },
        {processUpload: true, showUploadToolbar: true},
    )

    await sdkService.gql.createDataObjectAssignmentForSceneManagerService({
        input: {
            objectId: templateRevisionId,
            type: DataObjectAssignmentType.TemplateDataOther,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            dataObjectId: dataObject.id,
        },
    })

    return new DataObjectReference({name: file.name, dataObjectId: dataObject.legacyId})
}

function convertedMeshToCmmMesh(convMesh: {
    defaultPosition: [number, number, number]
    dracoBitDepth: number
    dracoResolution: number
    drcData: Uint8Array
    exporterVersion: string
    name?: string
    plyData: Uint8Array
}): CmmMesh {
    const cmmMesh = new CmmMesh()
    cmmMesh.name = convMesh.name ?? "Untitled"
    cmmMesh.dracoBitDepth = convMesh.dracoBitDepth
    cmmMesh.dracoResolution = convMesh.dracoResolution
    cmmMesh.defaultPosition = convMesh.defaultPosition
    cmmMesh.exporterVersion = convMesh.exporterVersion
    cmmMesh.originalFileName = convMesh.name ?? "Untitled"
    cmmMesh.data.set("drc", convMesh.drcData.buffer as ArrayBuffer)
    cmmMesh.data.set("ply", convMesh.plyData.buffer as ArrayBuffer)
    return cmmMesh
}

function convertOBJFile(file: File, workerService: WebAssemblyWorkerService, resolution = 0.001): Observable<CmmModel> {
    return fileToArrayBuffer(file).pipe(
        switchMap((buffer) => convertOBJToDracoAndPLY(workerService, buffer, resolution)),
        map((converted) => {
            const cmm = new CmmModel()
            cmm.meta = new CmmMeta()
            cmm.meta.originalFileSize = 0
            cmm.meta.coordinateSystem = 1 // 1 = flipYZ;
            cmm.meshes = []
            for (const mesh of converted!) {
                cmm.meshes.push(convertedMeshToCmmMesh(mesh))
            }
            return cmm
        }),
    )
}

function convertPLYFile(file: File, workerService: WebAssemblyWorkerService, resolution = 0.001): Observable<CmmModel> {
    return fileToArrayBuffer(file).pipe(
        switchMap((buffer) => convertPLYToDraco(workerService, buffer, resolution).pipe(map((converted) => [converted, buffer]))),
        map(([converted, buffer]) => {
            converted.plyData = new Uint8Array(buffer as ArrayBuffer)
            const cmm = new CmmModel()
            cmm.meta = new CmmMeta()
            cmm.meta.originalFileSize = 0
            cmm.meta.coordinateSystem = 1 // 1 = flipYZ;
            cmm.meshes = [convertedMeshToCmmMesh(converted)]
            return cmm
        }),
    )
}
