import {inject, Injectable} from "@angular/core"
import {
    DataObjectAssignmentFilterInput,
    DataObjectAssignmentType,
    DataObjectDetailsForNameAssetFromSchemaServiceFragment,
    DataObjectDetailsForNameAssetFromSchemaServiceFragment as DataObjectDetails,
    MaterialDetailsForNameAssetFromSchemaServiceFragment as MaterialDetails,
    TagType,
} from "@api"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"
import {DataObjectDefinitions} from "@cm/utils/data-object"
import {IsNonNull} from "@cm/utils/filter"
import {SchemaDerivedFilename} from "@cm/job-nodes/schema-derived-filename"
import {SdkService} from "@common/services/sdk/sdk.service"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {getPhysicalSizeInfoForMaterialGraph} from "@cm/material-nodes"
import {FlatOption} from "@labels"

function getRangeInfoForMaterial(material: MaterialDetails) {
    const materialRangeList = material.tagAssignments
        .filter((tag) => tag.tag.type === TagType.MaterialRange)
        .map((tag) => {
            return {
                name: tag.tag.name,
                otherInfo: tag.tag.otherInfo === undefined ? null : tag.tag.otherInfo,
            }
        })

    if (materialRangeList.length > 1) throw Error(`At most one material range expected for material, found ${materialRangeList.length}`)
    return materialRangeList.length === 1 ? materialRangeList[0] : {name: null, otherInfo: null}
}

function ensureOrganizationNameProvided(material: Pick<MaterialDetails, "organization">) {
    if (!material.organization || !material.organization.name) throw Error(`Organization name missing`)
    return material.organization.name
}

function getRelatedDataObjectForFormatAndResolution(
    dataObject: DataObjectDetails,
    format: SchemaDerivedFilename.ImageFormat,
    resolution: SchemaDerivedFilename.ImageResolution,
) {
    if (dataObject.related === undefined) throw Error(`Expected related data objects to be present`)
    const format_ = format === "tif" ? "tiff" : format // derived data objects filenames contain "tiff" instead of "tif"

    return dataObject.related.find((related) => {
        return (
            SchemaDerivedFilename.ensureValidMediaType(related.mediaType) === format &&
            related.objectName.endsWith(resolution === "original" ? `-${format_}` : `-${format_}-${resolution.slice("px".length)}`)
        )
    })
}

@Injectable()
export class NameAssetFromSchemaService {
    private sdk = inject(SdkService)
    private materialGraphService = inject(MaterialGraphService)

    private async fetchMaterialWithDataObjectAssignmentForType(materialId: string, assignmentType?: DataObjectAssignmentType, includeRelated?: boolean) {
        const dataObjectAssignmentFilter: DataObjectAssignmentFilterInput = assignmentType !== undefined ? {assignmentType: [assignmentType]} : {}
        const materials = (
            await this.sdk.gql.materialsForNameAssetFromSchemaService({
                filter: {id: {equals: materialId}},
                dataObjectAssignmentFilter: dataObjectAssignmentFilter,
                includeRelated: includeRelated ?? false,
            })
        ).materials.filter(IsNonNull)
        if (materials.length !== 1) throw Error(`Expected exactly one material for id ${materialId}, found ${materials.length}`)

        if (assignmentType !== undefined && materials[0].dataObjectAssignments.length != 1)
            throw Error(`Single assignment for specified type expected, found ${materials[0].dataObjectAssignments.length}`)

        return materials[0]
    }

    private async getPhysicalInfoForMaterial(material: MaterialDetails) {
        if (!material.latestCyclesRevision) throw Error(`Material has no latest cycles revision`)
        const materialGraph = await this.materialGraphService.graphFromMaterialRevision(material.latestCyclesRevision)
        const info = getPhysicalSizeInfoForMaterialGraph(materialGraph)
        if (!info) throw Error(`Could not determine physical size for material ${material.id}`)
        return {
            widthCm: info.widthCm,
            heightCm: info.heightCm,
        }
    }

    private async getPartialAssetData(material: MaterialDetails) {
        const {widthCm, heightCm} = await this.getPhysicalInfoForMaterial(material)

        return {
            organizationName: ensureOrganizationNameProvided(material).toLowerCase(),
            materialData: {
                articleId: material.articleId ?? null,
                name: material.name ?? null,
                rangeInfo: getRangeInfoForMaterial(material),
                widthCm,
                heightCm,
            },
        }
    }

    async renameRenderFromSchema(materialId: string, assetType: DataObjectAssignmentType) {
        if (!SchemaDerivedFilename.isAssignmentTypeValidAssetType(assetType) || !SchemaDerivedFilename.isRenderAssetType(assetType))
            throw Error(`Invalid asset type: ${assetType}, expected render asset type`)

        const material = await this.sdk.gql
            .materialForNameAssetFromSchemaService({materialId, dataObjectAssignmentFilter: {assignmentType: [assetType]}, includeRelated: true})
            .then(({material}) => material)

        const partialAssetData = await this.getPartialAssetData(material)
        const assetData: SchemaDerivedFilename.AssetData<SchemaDerivedFilename.RenderAssetType> = {
            ...partialAssetData,
            type: assetType,
            imageFormat: "exr",
            imageResolution: "original",
        }

        const newFilename = SchemaDerivedFilename.getFilenameForAssetData(assetData)
        // console.log(`newFilename: ${newFilename}`)
        if (newFilename === null) return

        // filenames for the derived images
        const pending: Promise<DataObjectDetailsForNameAssetFromSchemaServiceFragment>[] = []
        DataObjectDefinitions.DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET.forEach((info) => {
            const derivedDataObjectForInfo = getRelatedDataObjectForFormatAndResolution(
                material.dataObjectAssignments[0].dataObject,
                info.format,
                info.resolution,
            )
            if (derivedDataObjectForInfo === null) throw Error(`Derived data object for format ${info.format} and resolution ${info.resolution} not found`)

            const derivedImageAssetData: SchemaDerivedFilename.AssetData<SchemaDerivedFilename.RenderAssetType> = {
                ...assetData,
                imageFormat: info.format,
                imageResolution: info.resolution,
            }

            const derivedImageNewFilename = SchemaDerivedFilename.getFilenameForAssetData(derivedImageAssetData)
            // console.log(`derived image newFilename ${info.format}/${info.resolution}: ${derivedImageNewFilename}`)
            if (derivedImageNewFilename === null) return

            pending.push(
                this.sdk.gql
                    .nameAssetFromSchemaUpdateDataObject({
                        input: {id: derivedDataObjectForInfo!.id, originalFileName: derivedImageNewFilename},
                    })
                    .then(({updateDataObject}) => updateDataObject),
            )
        })

        pending.push(
            this.sdk.gql
                .nameAssetFromSchemaUpdateDataObject({
                    input: {id: material.dataObjectAssignments[0].dataObject.id, originalFileName: newFilename},
                })
                .then(({updateDataObject}) => updateDataObject),
        )
        await Promise.all(pending)
    }

    private defaultFilenameForDerivedImage<T extends (typeof DataObjectDefinitions.DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET)[number]>(
        format: T["format"],
        resolution: T["resolution"],
        nameBase: string,
    ) {
        const suffixResolution = resolution === "original" ? "" : resolution.replace("px", "-")
        const extension = format === "jpg" ? "jpg" : "tiff"
        const suffix = `-${extension}${suffixResolution}`
        return `${nameBase}${suffix}.${extension}`
    }

    private async getRenderAssetNameSetForMaterialAndType(materialId: string, assetType: SchemaDerivedFilename.RenderAssetType, defaultNameBase: string) {
        const material = await this.sdk.gql
            .materialForNameAssetFromSchemaService({materialId, dataObjectAssignmentFilter: {assignmentType: [assetType]}, includeRelated: true})
            .then(({material}) => material)

        const partialAssetData = await this.getPartialAssetData(material)
        const assetData: SchemaDerivedFilename.AssetData<SchemaDerivedFilename.RenderAssetType> = {
            type: assetType,
            ...partialAssetData,
            imageFormat: "exr",
            imageResolution: "original",
        }

        const filename = SchemaDerivedFilename.getFilenameForAssetData(assetData) ?? `${defaultNameBase}.exr`

        const derivedImagesFilenames = DataObjectDefinitions.DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET.map((info) => {
            const derivedImageAssetData: SchemaDerivedFilename.AssetData<SchemaDerivedFilename.RenderAssetType> = {
                ...assetData,
                imageFormat: info.format,
                imageResolution: info.resolution,
            }

            const derivedImageNewFilename =
                SchemaDerivedFilename.getFilenameForAssetData(derivedImageAssetData) ??
                this.defaultFilenameForDerivedImage(info.format, info.resolution, defaultNameBase)
            // console.log(`derived image newFilename ${info.format}/${info.resolution}: ${derivedImageNewFilename}`)

            return {
                format: info.format,
                resolution: info.resolution,
                filename: derivedImageNewFilename,
            }
        })

        return {
            mainImageFilename: filename,
            derivedImagesFilenames: derivedImagesFilenames as unknown as DataObjectDefinitions.DerivedImageFilenameWithFormatAndResolutionSet,
        }
    }

    async getMaterialTileableRenderNameSet(materialId: string, defaultNameBase: string) {
        return this.getRenderAssetNameSetForMaterialAndType(materialId, DataObjectAssignmentType.MaterialTileableRender, defaultNameBase)
    }

    async getMaterialThumbnailNameSet(materialId: string, assetType: FlatOption["dataObjectAssignmentType"], defaultNameBase: string) {
        if (!SchemaDerivedFilename.isAssignmentTypeValidAssetType(assetType) || !SchemaDerivedFilename.isRenderAssetType(assetType))
            throw Error(`Invalid asset type: ${assetType}, expected render asset type`)
        return this.getRenderAssetNameSetForMaterialAndType(materialId, assetType, defaultNameBase)
    }

    async getMaterialMapsExportName(materialId: string, exportConfig: MaterialMapsExporter.Config) {
        const material = await this.fetchMaterialWithDataObjectAssignmentForType(materialId)
        const partialAssetData = await this.getPartialAssetData(material)
        const assetData: SchemaDerivedFilename.AssetData = {
            type: DataObjectAssignmentType.MaterialMapsExport,
            ...partialAssetData,
            exportConfig,
        }
        return SchemaDerivedFilename.getFilenameForAssetData(assetData)
    }

    async getMaterialMapsExportMapName(materialId: string, conversionConfig: MaterialMapsExporter.ConversionRequest, mapType: SchemaDerivedFilename.MapType) {
        const material = await this.fetchMaterialWithDataObjectAssignmentForType(materialId)
        const partialAssetData = await this.getPartialAssetData(material)
        const assetData: SchemaDerivedFilename.AssetData = {
            type: SchemaDerivedFilename.MAPS_EXPORT_MAP_ASSET_TYPE,
            ...partialAssetData,
            conversionConfig,
            mapType,
        }
        return SchemaDerivedFilename.getFilenameForAssetData(assetData)
    }

    async getMaterialMapsExportInfoName(materialId: string, conversionInfoRequest: MaterialMapsExporter.ConversionInfoRequest) {
        const material = await this.fetchMaterialWithDataObjectAssignmentForType(materialId)
        const partialAssetData = await this.getPartialAssetData(material)
        const assetData: SchemaDerivedFilename.AssetData = {
            type: SchemaDerivedFilename.MAPS_EXPORT_INFO_ASSET_TYPE,
            ...partialAssetData,
            conversionInfo: conversionInfoRequest,
        }
        return SchemaDerivedFilename.getFilenameForAssetData(assetData)
    }
}
