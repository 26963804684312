import {MaterialSocket} from "@material-editor/models/material-socket"

export const ShaderToRgbOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "plain", label: "Color"},
    alpha: {paramType: "socket", id: "Alpha", type: "output", valueType: "plain", label: "Alpha"},
}

export const ShaderToRgbInputs: Record<string, MaterialSocket> = {
    shader: {paramType: "socket", id: "Shader", type: "input", valueType: "plain", label: "Shader"},
}
