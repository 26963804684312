import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {Color, ColorLike, Vector2, Vector2Like} from "@cm/math"
import paper from "paper"
import {EventEmitter} from "@angular/core"

export class LineItem extends CanvasBaseToolboxItemBase {
    readonly linePointsChanged = new EventEmitter<void>()

    constructor(parentItem: CanvasBaseToolboxItem, points: Vector2Like[], width: number, color: ColorLike) {
        super(parentItem)

        this._width = width
        this._color = Color.fromColorLike(color)
        this.setPoints(points)

        this.viewChange.subscribe(() => this.updateZoomDependent())
        this.selectedChange.subscribe(() => this.updateColor())
    }

    override hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        return this._linePath?.hitTest(point, {stroke: true, tolerance: (5 * window.devicePixelRatio) / this.zoomLevel}) ? this : null
    }

    get color(): ColorLike {
        return this._color
    }

    set color(value: ColorLike) {
        this._color = Color.fromColorLike(value)
        this.updateColor()
    }

    get width(): number {
        return this._width
    }

    set width(value: number) {
        this._width = value
        this.createLinePath()
    }

    get points(): Vector2[] {
        return this._points
    }

    setPoints(points: Vector2Like[]) {
        this._points = points.map((p) => Vector2.fromVector2Like(p))
        this.createLinePath()
        this.linePointsChanged.emit()
    }

    get path(): paper.Path | undefined {
        return this._linePath
    }

    get length(): number {
        return this._linePath?.length ?? 0
    }

    getClosestPointAndOffsetToLocation(point: Vector2Like): {closestPoint: Vector2; offset: number} {
        if (!this._linePath) {
            throw Error("Line path not created")
        }
        const closestPoint = Vector2.fromVector2Like(this._linePath.getNearestPoint(point))
        const offset = this._linePath.getOffsetOf(closestPoint)
        return {closestPoint, offset}
    }

    private updateZoomDependent() {
        this.createLinePath()
    }

    private createLinePath() {
        this.beginPaperCreation()
        this._linePath?.remove()
        this._linePath = new paper.Path(this._points)
        this._linePath.strokeWidth = (this._width * window.devicePixelRatio) / this.zoomLevel
        this.updateColor()
    }

    private updateColor() {
        if (this._linePath) {
            this._linePath.strokeColor = this.selected
                ? new paper.Color(1, 1, 1, 1)
                : new paper.Color(this._color.r, this._color.g, this._color.b, this._color.a)
        }
    }

    private _linePath?: paper.Path
    private _points: Vector2[] = []
    private _width: number
    private _color: Color
}
