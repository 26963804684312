import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {inletDescriptor, outletDescriptor, scopeDescriptor, ValueTypeDescriptor} from "#template-nodes/runtime-graph/descriptors"
import {IDataObject, IDataObjectNew, ITransientDataObject, ITransientDataObjectNew} from "@cm/material-nodes/interfaces/data-object"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {IMaterialData, IMaterialGraph, keyForMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {Matrix4 as _Matrix4} from "@cm/math"
import {deepCopy, deepEqual} from "@cm/utils"

export type TemplateImageData = {
    dataObject: IDataObject | ITransientDataObject
}

export type TemplateImageDataNew = {
    dataObject: IDataObjectNew | ITransientDataObjectNew
}

export namespace TypeDescriptors {
    type Desc<T> = ValueTypeDescriptor<T> | undefined

    export const inlet = inletDescriptor
    export const outlet = outletDescriptor
    export const builder = scopeDescriptor

    export function Primitive<T>(): Desc<T> {
        return undefined
    }

    const _Tuple = {deepCompare: 1}
    export function Tuple<T>(): Desc<T> {
        return _Tuple
    }

    export const Number = Primitive<number>()
    export const Boolean = Primitive<boolean>()
    export const String = Primitive<string>()

    export function Function<T>(): Desc<T> {
        return undefined
    }

    export function StaticFunction<T>(): Desc<T> {
        return {
            deepCompare: (a, b) => {
                return typeof a === typeof b
            },
            deepCopy: (a) => a,
        }
    }

    export function Identity<T>(key?: keyof T): Desc<T> {
        if (key) {
            return {
                deepCompare: (a: T, b: T) => (a && a[key]) === (b && b[key]),
                deepCopy: (a: T) => a,
            }
        } else {
            return undefined
        }
    }

    const _Array = {
        deepCompare: 1,
        deepCopy: (a: any) => [...a],
    }
    export function Array<T>(elemType: Desc<T>): Desc<T[]> {
        if (!elemType) return _Array
        const {deepCompare: _deepCompare, deepCopy: _deepCopy} = elemType

        return {
            deepCompare: _deepCompare
                ? (a: T[], b: T[]) => {
                      if (typeof a !== typeof b) return false
                      if (a.length !== b.length) return false
                      for (let i = 0; i < a.length; i++) {
                          if (typeof _deepCompare === "function") {
                              if (!_deepCompare(a[i], b[i])) return false
                          } else if (!deepEqual(a[i], b[i], _deepCompare === true ? undefined : _deepCompare)) return false
                      }
                      return true
                  }
                : _Array.deepCompare,
            deepCopy: _deepCopy
                ? (a: T[]) => {
                      if (typeof _deepCopy === "function") return a.map(_deepCopy)
                      return a.map((x) => deepCopy(x, _deepCopy === true ? undefined : _deepCopy))
                  }
                : _Array.deepCopy,
        }
    }

    export function Nullable<T>(x: Desc<T>): Desc<T | null> {
        //@ts-ignore
        return x
    }

    const _JSON = {deepCompare: true}
    export function JSON<T = any>(): Desc<T> {
        return _JSON
    }

    const _ShallowJSON = {deepCompare: 1}
    export function ShallowJSON<T = any>(): Desc<T> {
        return _ShallowJSON
    }

    export function Map<K, V>(): Desc<Map<K, V>> {
        return undefined
        //TODO: map deep compare
    }

    export function Set<V>(): Desc<Set<V>> {
        return undefined
        //TODO: map deep compare
    }

    export const Matrix4: Desc<_Matrix4> = {
        deepCompare: (a, b) => {
            if (a && b) {
                return a.equals(b)
            }
            if (a || b) return false
            else return true
        },
        deepCopy: (a) => a?.copy(),
    }

    export const MaterialData: Desc<IMaterialData> = {
        deepCompare: (a, b) => keyForMaterialData(a) === keyForMaterialData(b),
    }

    export const MaterialGraph = Identity<IMaterialGraph>("uniqueId")
    export const MeshData = Identity<MeshData>()
    export const DataObject = Identity<IDataObject>("id")
    export const DataObjectNew = Identity<IDataObjectNew>("legacyId")
    export const TemplateImageData = Identity<TemplateImageData>()
    export const ImageGenerator: Desc<ImageGenerator> = {
        deepCompare: (a: ImageGenerator, b: ImageGenerator) => (a && a.imageNode.hash) === (b && b.imageNode.hash),
        deepCopy: (a: ImageGenerator) => a,
    }

    export function TemplateNode<T>(): Desc<T> {
        return ShallowJSON<T>()
    }
}
