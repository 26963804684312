@if (node) {
    <ng-content select="[cm-content]"> </ng-content>
    <cm-inspector-section>
        <ng-container cm-title> Settings </ng-container>
        <ng-container cm-content>
            <cm-input-container>
                <ng-container cm-inline-label> ID: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.id" (valueChange)="nodeChanged(node)" [validate]="isValidId"> </cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Name: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.name" (valueChange)="nodeChanged(node)"> </cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Label: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.label" (valueChange)="nodeChanged(node)"> </cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-top-label> Description: </ng-container>
                <ng-container cm-input>
                    <cm-native-input-text-area [initialValue]="node.description" [minRows]="4" (valueChange)="node.description = $event; nodeChanged(node)">
                    </cm-native-input-text-area>
                </ng-container>
            </cm-input-container>
        </ng-container>
    </cm-inspector-section>
}
