import {assertNoBatching} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {Box2Like, Vector2Like} from "@cm/math"
import {assertNever, wrap} from "@cm/utils"
import {ReplaceAWithB} from "@cm/utils/type"
import {
    ChannelLayout,
    DataType,
    ImageDescriptor,
    ImageRef,
    ImageRefId,
    isImageDescriptor,
    isImageRef,
    makeImageRef,
    resolveImageRefRegion,
    resolveOriginalImageRefRegion,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpContextImgProc, ImgProcImageToImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-imgproc"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {ImageOpCommandQueueBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-base"

export const IMGPROC_USE_FLOAT32_EVERYWHERE = true // if true, it will use float32 for all images, otherwise it will use the requested data type

export class ImageOpCommandQueueImgProc extends ImageOpCommandQueueBase {
    constructor(readonly context: ImageOpContextImgProc) {
        super(context)
    }

    // convenience method
    copyToResultImage(imageRef: ImageRef, resultImageOrDataType?: ImageRef | DataType): ImageRef {
        if (resultImageOrDataType) {
            return copyRegion(this, {sourceImage: imageRef, resultImageOrDataType})
        } else {
            return imageRef
        }
    }

    createImage<T extends ImageProcessingNodes.ImageNode>(descriptorOrImageRef: ImageDescriptor | ImageRef, node?: ImgProcImageToImageRef<T>): ImageRef {
        const id = this._nextImageRefId++
        const descriptor = isImageDescriptor(descriptorOrImageRef) ? descriptorOrImageRef : descriptorOrImageRef.descriptor
        if (IMGPROC_USE_FLOAT32_EVERYWHERE) {
            descriptor.dataType = "float32"
        }
        assertNoBatching(descriptor)
        const mappedNode = node ?? this.createImageNode(descriptor)
        this._nodeByImageRefId.set(id, mappedNode)
        return makeImageRef("temporary", id, descriptor)
    }

    async execute(imageRefsToEvaluate: ImageRef[]): Promise<ExecutionResult<ImageRef[]>> {
        // TODO there is a potential issue here: doing something like this "copyRegion(cmdQueue, {sourceImage, resultImage})" without using the returned resultImage will cause
        //  different behaviour in the WebGL2 and ImgProc execution paths. The WebGL2 path will write to the resultImage, while the ImgProc path will not.
        //  This potential issue should be revisited !!!
        const createCacheId = (imageRef: ImageRef, includeView: boolean): string => {
            const region = includeView ? resolveImageRefRegion(imageRef) : resolveOriginalImageRefRegion(imageRef)
            return `${imageRef.addressSpace}:${imageRef.id}:(${region.x},${region.y},${region.width},${region.height})`
        }
        const nodeCacheByImageRefIdByAddressSpace = new Map<string, ImageProcessingNodes.ImageNode>()
        const traverse = async (imageRef: ImageRef) => {
            // check if we have this node in cache (including view)
            const cacheIdWithView = createCacheId(imageRef, true)
            const cachedNodeWithView = nodeCacheByImageRefIdByAddressSpace.get(cacheIdWithView)
            if (cachedNodeWithView) {
                return cachedNodeWithView
            }
            // nope, let's see if we have the view source in the cache
            let evaluatedNode: ImageProcessingNodes.ImageNode | undefined
            const cacheIdWithoutView = createCacheId(imageRef, false)
            evaluatedNode = nodeCacheByImageRefIdByAddressSpace.get(cacheIdWithoutView)
            if (!evaluatedNode) {
                // nope, let's evaluate it
                const mapImageRefToNode = async (imageRefId: ImageRefId): Promise<ImageProcessingNodes.ImageNode> => {
                    const node = this._nodeByImageRefId.get(imageRefId)
                    const copyAndMap = async (
                        value: unknown,
                        mappingFn: (value: unknown) => Promise<{mappedValue: unknown; recurseOnMappedValue: boolean}>,
                    ): Promise<unknown> => {
                        const mappedResult = await mappingFn(value)
                        value = mappedResult.mappedValue
                        if (mappedResult.recurseOnMappedValue && value && typeof value == "object") {
                            if (Array.isArray(value)) {
                                return await Promise.all(value.map((e) => copyAndMap(e, mappingFn)))
                            } else {
                                const mappedObject: Record<string, unknown> = {}
                                for (const [objKey, objValue] of Object.entries(value)) {
                                    mappedObject[objKey] = await copyAndMap(objValue, mappingFn)
                                }
                                return mappedObject
                            }
                        } else {
                            return value
                        }
                    }
                    return (await copyAndMap(node, async (value) => {
                        if (isImageRef(value)) {
                            const evaledImageRef = await traverse(value)
                            return {
                                mappedValue: evaledImageRef,
                                recurseOnMappedValue: false,
                            }
                        } else {
                            return {
                                mappedValue: value,
                                recurseOnMappedValue: true,
                            }
                        }
                    })) as ImageProcessingNodes.ImageNode
                }
                switch (imageRef.addressSpace) {
                    case "temporary":
                        evaluatedNode = await mapImageRefToNode(imageRef.id)
                        break
                    case "drawable":
                    case "data-object":
                        evaluatedNode = await this.context.getImage(imageRef)
                        break
                    default:
                        assertNever(imageRef.addressSpace)
                }
                if (!evaluatedNode) {
                    throw new Error(`ImageRef not found: ${imageRef.id}`)
                }
                // add to cache
                nodeCacheByImageRefIdByAddressSpace.set(cacheIdWithoutView, evaluatedNode)
            }
            // handle view region
            const region = resolveImageRefRegion(imageRef)
            const fullRegion = resolveOriginalImageRefRegion(imageRef)
            if (region.x !== 0 || region.y !== 0 || region.width !== fullRegion.width || region.height !== fullRegion.height) {
                // this view has an offset or a different size, so we need to create a new image node
                evaluatedNode = this.copyRegionWithWrap(
                    evaluatedNode,
                    {
                        ...imageRef.descriptor,
                        width: fullRegion.width,
                        height: fullRegion.height,
                    },
                    region,
                    {
                        x: 0,
                        y: 0,
                    },
                )
                // add view to cache
                nodeCacheByImageRefIdByAddressSpace.set(cacheIdWithView, evaluatedNode)
            }
            return evaluatedNode
        }

        return Promise.all(imageRefsToEvaluate.map((imageRef) => traverse(imageRef)))
    }

    private copyRegionWithWrap(
        sourceImage: ImageProcessingNodes.ImageNode,
        sourceImageDescriptor: ImageDescriptor,
        sourceRegion: Box2Like,
        targetOffset: Vector2Like,
    ): ImageProcessingNodes.ImageNode {
        sourceRegion.x = wrap(sourceRegion.x, sourceImageDescriptor.width)
        sourceRegion.y = wrap(sourceRegion.y, sourceImageDescriptor.height)
        const sw = Math.min(sourceRegion.width, sourceImageDescriptor.width - sourceRegion.x)
        const sh = Math.min(sourceRegion.height, sourceImageDescriptor.height - sourceRegion.y)
        let resultImageOrDataType: ImageProcessingNodes.ImageNode = this.createImageNode({
            ...sourceImageDescriptor,
            width: targetOffset.x + sourceRegion.width,
            height: targetOffset.y + sourceRegion.height,
        })
        if (sw > 0 && sh > 0) {
            resultImageOrDataType = {
                type: "copyRegion",
                source: sourceImage,
                sourceRegion: {type: "region", region: [sourceRegion.x, sourceRegion.y, sw, sh]},
                target: resultImageOrDataType,
                targetOffset: {type: "offset", offset: [targetOffset.x, targetOffset.y]},
            }
        }
        if (sw < sourceRegion.width) {
            resultImageOrDataType = {
                type: "copyRegion",
                source: sourceImage,
                sourceRegion: {type: "region", region: [0, sourceRegion.y, sourceRegion.width - sw, sh]},
                target: resultImageOrDataType,
                targetOffset: {type: "offset", offset: [targetOffset.x + sw, targetOffset.y]},
            }
        }
        if (sh < sourceRegion.height) {
            resultImageOrDataType = {
                type: "copyRegion",
                source: sourceImage,
                sourceRegion: {type: "region", region: [sourceRegion.x, 0, sw, sourceRegion.height - sh]},
                target: resultImageOrDataType,
                targetOffset: {type: "offset", offset: [targetOffset.x, targetOffset.y + sh]},
            }
        }
        if (sw < sourceRegion.width && sh < sourceRegion.height) {
            resultImageOrDataType = {
                type: "copyRegion",
                source: sourceImage,
                sourceRegion: {type: "region", region: [0, 0, sourceRegion.width - sw, sourceRegion.height - sh]},
                target: resultImageOrDataType,
                targetOffset: {type: "offset", offset: [targetOffset.x + sw, targetOffset.y + sh]},
            }
        }
        return resultImageOrDataType
    }

    private createImageNode(descriptor: ImageDescriptor): ImageProcessingNodes.CreateImage {
        const getColor = (channels: ChannelLayout): number | ImageProcessingNodes.RGBColor | ImageProcessingNodes.RGBAColor => {
            switch (channels) {
                case "RGBA":
                    return [0, 0, 0, 1]
                case "RGB":
                    return [0, 0, 0]
                case "R":
                    return 0
                default:
                    throw new Error(`Unsupported channel layout: ${channels}`)
            }
        }
        const color = getColor(descriptor.channelLayout)
        return ImageProcessingNodes.createImage(
            descriptor.width,
            descriptor.height,
            "float32",
            "linear", // TODO which color space?
            color,
        )
    }

    private _nextImageRefId = 1
    private _nodeByImageRefId = new Map<ImageRefId, ImgProcImageToImageRef<ImageProcessingNodes.ImageNode>>()
}

type ExecutionResult<RootNodeType> = ReplaceAWithB<RootNodeType, ImageRef, ImageProcessingNodes.ImageNode>
