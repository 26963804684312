<div>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link href="https://storage.googleapis.com/cm-platform-prod-static/fonts/fontawesome-pro-6.0.0-alpha3/css/all.css" rel="stylesheet" />
    @if (interfaceDescriptors.length > 0 && _uiStyle !== "hidden") {
        <div class="cm-configurator-menu" style="--icon-size: {{ iconSize + 'px' }}">
            <a [style.display]="!externalMenu && minimized ? 'flex' : 'none'" (click)="minimized = !minimized" class="cm-minimized-menu cm-icon-container">
                <i class="far fa-sliders-h"></i>
            </a>
            <div class="cm-relative-container">
                <div [style.display]="externalMenu || !minimized ? 'inherit' : 'none'">
                    <ng-container *ngTemplateOutlet="configs"></ng-container>
                    @if (!externalMenu) {
                        <a (click)="minimized = !minimized" class="cm-minimize-menu">
                            <i class="far fa-chevron-down"></i>
                        </a>
                    }
                </div>
            </div>
        </div>
        <div class="tooltip-container" #tooltipContainer></div>
        <ng-template #configs>
            @if (_uiStyle === "default") {
                <div class="cm-config-dropdowns-menu">
                    @for (desc of interfaceDescriptors; track desc) {
                        @if (isConfigInput(desc) && shouldShowInput(desc)) {
                            <mat-form-field>
                                <mat-label>{{ desc.name }}</mat-label>
                                <mat-select [value]="getValueForInterface(desc)">
                                    @for (variant of desc.variants; track variant) {
                                        <mat-option [value]="variant.id" (click)="selectVariant(desc, variant)">
                                            {{ variant.name }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        }
                        @if (isMaterialInput(desc)) {
                            <button mat-button class="cm-custom-material-button" (click)="chooseMaterialForInput(desc)">
                                @if (fetchMaterial | memoize: this : getMaterialId(desc) | async; as material) {
                                    @if (getMaterialIcon | memoize: this : material | async; as iconDataObject) {
                                        <img class="cm-material-icon" alt="Material icon" [src]="iconDataObject.thumbnail?.downloadUrl" />
                                    }
                                    {{ desc.name }}: {{ material.name }}
                                } @else {
                                    {{ desc.name }}...
                                }
                            </button>
                        }
                        <!-- TODO: template inputs -->
                        @if (isNumberInput(desc) && shouldShowInput(desc)) {
                            <cm-input-container>
                                <ng-container cm-inline-label>
                                    {{ desc.name }}
                                </ng-container>
                                <ng-container cm-input>
                                    <cm-numeric-input
                                        [value]="$any(getValueForInterface(desc))"
                                        (valueChange)="changeParameter(desc, $event)"
                                        [decimalPlaces]="2"
                                        [emptyValue]="undefined"
                                    >
                                    </cm-numeric-input>
                                </ng-container>
                            </cm-input-container>
                        }
                        @if (isBooleanInput(desc) && shouldShowInput(desc)) {
                            <mat-checkbox [ngModel]="getValueForInterface(desc)" (ngModelChange)="changeParameter(desc, $event)">
                                {{ desc.name }}
                            </mat-checkbox>
                        }
                        @if (isStringInput(desc) && shouldShowInput(desc)) {
                            <cm-input-container>
                                <ng-container cm-inline-label>
                                    {{ desc.name }}
                                </ng-container>
                                <ng-container cm-input>
                                    <cm-string-input [value]="$any(getValueForInterface(desc)?.toString())" (valueChange)="changeParameter(desc, $event)">
                                    </cm-string-input>
                                </ng-container>
                            </cm-input-container>
                        }
                    }
                </div>
            }

            @if (_uiStyle === "accordion" || _uiStyle === "icons") {
                <div part="cm-config-menu-icons" class="cm-config-menu-icons">
                    @for (desc of interfaceDescriptors; track desc) {
                        @if (isConfigInput(desc) && shouldShowInput(desc)) {
                            @if ({selectedVariantId: getValueForInterface(desc)}; as locals) {
                                <div
                                    part="cm-config-group-title"
                                    class="cm-config-group-title"
                                    [ngClass]="{'cm-config-group-title-accordion': _uiStyle === 'accordion'}"
                                    (click)="onConfigGroupClicked(desc)"
                                >
                                    <span class="cm-config-group-name">{{ desc.name }}</span>
                                    @if (_uiStyle === "accordion") {
                                        <i [ngClass]="selectedConfigGroups.has(desc.id) ? 'far fa-chevron-down' : 'far fa-chevron-right'"></i>
                                    }
                                </div>
                                @if (_uiStyle === "icons" || selectedConfigGroups.has(desc.id)) {
                                    <div
                                        [attr.part]="desc.displayWithLabels ? 'cm-labels-container' : 'cm-icons-container'"
                                        [ngClass]="desc.displayWithLabels ? 'cm-labels-container' : 'cm-icons-container'"
                                    >
                                        @for (variant of desc.variants; track variant) {
                                            <cm-config-button-legacy
                                                [asLabel]="desc.displayWithLabels ?? false"
                                                [tooltipContainer]="tooltipContainer"
                                                [descriptor]="desc"
                                                [variant]="variant"
                                                [useCaptions]="useCaptions"
                                                [selectedVariantId]="getValueForInterfaceConfigInfo(desc)"
                                                (click)="selectVariant(desc, variant)"
                                            ></cm-config-button-legacy>
                                        }
                                    </div>
                                }
                            }
                        }
                        @if (isMaterialInput(desc) && shouldShowInput(desc)) {
                            <div class="cm-custom-material-button" (click)="chooseMaterialForInput(desc)">
                                @if (fetchMaterial | memoize: this : getMaterialId(desc) | async; as material) {
                                    @if (getMaterialIcon | memoize: this : material | async; as iconDataObject) {
                                        <img class="cm-material-icon" alt="Material icon" [src]="iconDataObject.thumbnail?.downloadUrl" />
                                    }
                                }
                                <cm-button>
                                    <ng-container cm-left-icon>
                                        <i class="far fa-folder-open"></i>
                                    </ng-container>
                                    <ng-container cm-text> Choose a material </ng-container>
                                </cm-button>
                            </div>
                        }
                    }
                </div>
            }
        </ng-template>
    }
</div>
