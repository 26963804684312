import {registerNode} from "@cm/graph/register-node"
import {ObjectLike, objectLike} from "#template-nodes/node-types"
import {z} from "zod"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {DeclareObjectNode, TemplateObjectNode} from "@cm/template-nodes/declare-object-node"
import {namedNodeParameters} from "@cm/template-nodes/nodes/named-node"
import {SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {nodeInstance} from "@cm/graph/instance"
import {MeshCurve} from "./mesh-curve"
import {SampleCurve} from "../runtime-graph/nodes/sample-curve"
import {mergeBounds, transformBounds} from "../utils/scene-geometry-utils"

const seamParameters = namedNodeParameters.merge(
    z.object({
        item: objectLike.nullable(),
        repeatSize: z.number().or(objectLike).optional(),
        curve: nodeInstance(MeshCurve).nullable(),
        allowScaling: z.boolean(),
    }),
)
export type SeamParameters = {
    item: ObjectLike | null
    repeatSize: number | ObjectLike | undefined
    curve: MeshCurve | null
    allowScaling: boolean
}

@registerNode
export class Seam extends DeclareObjectNode(
    {parameters: seamParameters},
    {
        onVisited: {
            onFilterActive: ({parameters}) => {
                if (parameters.item === null || parameters.curve === null) return skipped
                return visitNone(parameters)
            },
            onCompile: function (this: SeamFwd, {context, parameters}) {
                const {item, curve, repeatSize, allowScaling} = parameters

                if (!item || !curve) return skipped

                const {evaluator} = context

                const scope = evaluator.getScope(this)

                const curveData = evaluator.evaluateMeshCurve(scope, curve)
                const repeat = typeof repeatSize === "number" ? repeatSize : evaluator.evaluateObject(scope, repeatSize ?? null)

                const [objectDataValid] = scope.branch(evaluator.evaluateObject(scope, item))
                const meshesAndBounds = scope.pureLambda(
                    scope.tuple(objectDataValid, repeat),
                    ([{preDisplayList, displayList, matrix}, repeat]) => {
                        const inverseTransform = matrix.inverse()

                        const meshes = [...preDisplayList, ...displayList]
                            .filter(SceneNodes.Mesh.is)
                            .map<SceneNodes.Mesh>((mesh) => ({...mesh, transform: inverseTransform.multiply(mesh.transform)}))

                        if (typeof repeat === "number") return {meshes, bounds: repeat}

                        const boundsMeshes = repeat
                            ? [...repeat.preDisplayList, ...repeat.displayList]
                                  .filter(SceneNodes.Mesh.is)
                                  .map<SceneNodes.Mesh>((mesh) => ({...mesh, transform: inverseTransform.multiply(mesh.transform)}))
                            : meshes

                        const bounds = mergeBounds(boundsMeshes.map((mesh) => transformBounds(mesh.meshData.bounds, mesh.transform)))
                        return {meshes, bounds}
                    },
                    "item",
                )

                const {curvePoints} = scope.node(SampleCurve, {
                    closed: scope.get(curveData, "closed"),
                    allowScaling,
                    controlPoints: scope.get(curveData, "controlPoints"),
                    segmentLength: scope.get(meshesAndBounds, "bounds"),
                })

                this.setupObject(scope, context, "Seam", undefined, curveData, (objectProps) => {
                    return scope.struct<SceneNodes.Seam>("Seam", {
                        type: "Seam",
                        ...objectProps,
                        item: scope.get(meshesAndBounds, "meshes"),
                        curvePoints,
                        meshCurveControlId: scope.get(curveData, "id"),
                    })
                })

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "Seam"},
) {}

export type SeamFwd = TemplateObjectNode<SeamParameters>
