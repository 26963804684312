import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {assertSameSize, assertSameSizeAndBatchSize, getMostPreciseDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {
    createGaussianPyramid,
    ReturnType as GaussianPyramidReturnType,
} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-gaussian-pyramid"
import {
    createLaplacianPyramid,
    ReturnType as LaplacianPyramidReturnType,
} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-laplacian-pyramid"
import {upSample} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/up-sample"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {InterpolationType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {createView} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/utils/create-view"
import {CachedGaussianImagePyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/caching/cached-gaussian-image-pyramid"
import {CachedLaplacianImagePyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/caching/cached-laplacian-image-pyramid"
import {Box2Like, Size2Like, Vector2} from "@cm/math"

const SCOPE_NAME = "BlendByLaplacianPyramid"

export type ParameterType = {
    backgroundImage: ImageRef | CachedLaplacianImagePyramid
    backgroundRegion?: Box2Like // TODO remove this as it should be covered by views
    foregroundImage: ImageRef | CachedLaplacianImagePyramid
    foregroundRegion?: Box2Like // TODO remove this as it should be covered by views
    maskImage: ImageRef | CachedGaussianImagePyramid // mask used for blending; white means foreground, black means background
    maskRegion?: Box2Like // TODO remove this as it should be covered by views
}

export type ReturnType = {
    resultImage: ImageRef
    backgroundLaplacianPyramid: LaplacianPyramidReturnType
    foregroundLaplacianPyramid: LaplacianPyramidReturnType
    maskGaussianPyramid: GaussianPyramidReturnType
}

export const blendByLaplacianPyramid = (
    cmdQueue: ImageOpCommandQueue,
    {backgroundImage, backgroundRegion, foregroundImage, foregroundRegion, maskImage, maskRegion}: ParameterType,
): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)

    // PYRAMID GENERATION
    const interpolation: InterpolationType = "cubic"
    const getSize = (image: ImageRef | CachedLaplacianImagePyramid | CachedGaussianImagePyramid) => {
        return image instanceof CachedLaplacianImagePyramid
            ? image.get().laplacianPyramid.descriptor
            : image instanceof CachedGaussianImagePyramid
              ? image.get().descriptor
              : image.descriptor
    }
    const computeNumLevels = (size: Size2Like) => {
        const minSize = Math.min(size.width, size.height)
        const lowerPow2Exp = Math.floor(Math.log2(minSize))
        const lowerPow2Size = 1 << lowerPow2Exp
        const ratioToNextPow2 = minSize / lowerPow2Size
        const numLevels = Math.max(1, lowerPow2Exp - 2)
        return {
            numLevels,
            ratioToNextPow2,
        }
    }
    const generateLaplacianPyramid = (image: ImageRef | CachedLaplacianImagePyramid) => {
        const size = getSize(image)
        const {numLevels: pyramidGenerationNumLevels, ratioToNextPow2: pyramidGenerationRatioToNextPow2} = computeNumLevels(size)
        if (pyramidGenerationNumLevels < 1) {
            throw new Error("BlendByLaplacianPyramid: numLevels must be at least 1")
        }
        const sigma = 2 * pyramidGenerationRatioToNextPow2
        return image instanceof CachedLaplacianImagePyramid
            ? image.get()
            : createLaplacianPyramid(cmdQueue, {
                  sourceImage: image,
                  sigma,
                  maxLevels: pyramidGenerationNumLevels + 1,
                  upSamplingInterpolation: interpolation,
              })
    }
    const generateGaussianPyramid = (image: ImageRef | CachedGaussianImagePyramid) => {
        const size = getSize(image)
        const {numLevels: pyramidGenerationNumLevels, ratioToNextPow2: pyramidGenerationRatioToNextPow2} = computeNumLevels(size)
        if (pyramidGenerationNumLevels < 1) {
            throw new Error("BlendByLaplacianPyramid: numLevels must be at least 1")
        }
        const sigma = 2 * pyramidGenerationRatioToNextPow2
        return image instanceof CachedGaussianImagePyramid
            ? image.get()
            : createGaussianPyramid(cmdQueue, {
                  sourceImage: image,
                  sigma,
                  maxLevels: pyramidGenerationNumLevels,
              })
    }
    const backgroundLaplacianPyramid = generateLaplacianPyramid(backgroundImage)
    const foregroundLaplacianPyramid = generateLaplacianPyramid(foregroundImage)
    const maskGaussianPyramid = generateGaussianPyramid(maskImage)

    // BLENDING
    const getLevelRegion = (region: Box2Like, level: number): Box2Like => {
        const levelScale = 1 << level
        const levelMinPos = {
            x: Math.floor(region.x / levelScale),
            y: Math.floor(region.y / levelScale),
        }
        const levelMaxPos = {
            x: Math.floor((region.x + region.width - 1) / levelScale),
            y: Math.floor((region.y + region.height - 1) / levelScale),
        }
        return {
            x: levelMinPos.x,
            y: levelMinPos.y,
            width: levelMaxPos.x - levelMinPos.x + 1,
            height: levelMaxPos.y - levelMinPos.y + 1,
        }
    }
    const getRegionView = (pyramid: ImageRef[], region: Box2Like, level: number): ImageRef => {
        const levelRegion = getLevelRegion(region, level)
        const image = pyramid[level]
        if (levelRegion.x === 0 && levelRegion.y === 0 && levelRegion.width === image.descriptor.width && levelRegion.height === image.descriptor.height) {
            return image
        } else {
            return createView(image, levelRegion)
        }
    }
    backgroundRegion ??= {...Vector2.zero, ...backgroundLaplacianPyramid.gaussianPyramid.descriptor}
    foregroundRegion ??= {...Vector2.zero, ...foregroundLaplacianPyramid.gaussianPyramid.descriptor}
    maskRegion ??= {...Vector2.zero, ...maskGaussianPyramid.descriptor}
    assertSameSize(backgroundRegion, foregroundRegion)
    assertSameSize(backgroundRegion, maskRegion)
    const {numLevels} = computeNumLevels(foregroundRegion)
    if (
        backgroundLaplacianPyramid.laplacianPyramid.descriptor.levels < numLevels ||
        foregroundLaplacianPyramid.laplacianPyramid.descriptor.levels < numLevels ||
        maskGaussianPyramid.descriptor.levels < numLevels
    ) {
        throw Error("BlendByLaplacianPyramid: Pyramids have insufficient levels")
    }
    const coarsestMask = getRegionView(maskGaussianPyramid.resultImages, maskRegion, numLevels - 1)
    const maskedCoarsestForeground = math(cmdQueue, {
        operator: "*",
        operandA: getRegionView(foregroundLaplacianPyramid.upSampledGaussianPyramid.resultImages, foregroundRegion, numLevels - 1),
        operandB: coarsestMask,
    })
    const maskedCoarsestBackground = math(cmdQueue, {
        operator: "*",
        operandA: getRegionView(backgroundLaplacianPyramid.upSampledGaussianPyramid.resultImages, backgroundRegion, numLevels - 1),
        operandB: math(cmdQueue, {
            operator: "-",
            operandA: 1,
            operandB: coarsestMask,
        }),
    })
    const sourceDataType = backgroundLaplacianPyramid.gaussianPyramid.resultImages[0].descriptor.dataType
    const resultDataType = getMostPreciseDataType(sourceDataType, "float16")
    let resultImage = math(cmdQueue, {
        operator: "+",
        operandA: maskedCoarsestForeground,
        operandB: maskedCoarsestBackground,
        resultImageOrDataType: resultDataType,
    })
    for (let i = numLevels - 1; i >= 0; i--) {
        const backgroundLaplacian = getRegionView(backgroundLaplacianPyramid.laplacianPyramid.resultImages, backgroundRegion, i)
        const foregroundLaplacian = getRegionView(foregroundLaplacianPyramid.laplacianPyramid.resultImages, foregroundRegion, i)
        const mask = getRegionView(maskGaussianPyramid.resultImages, maskRegion, i)
        assertSameSizeAndBatchSize(backgroundLaplacian.descriptor, foregroundLaplacian.descriptor)
        assertSameSizeAndBatchSize(backgroundLaplacian.descriptor, mask.descriptor)
        const maskedForegroundLaplacian = math(cmdQueue, {
            operator: "*",
            operandA: foregroundLaplacian,
            operandB: mask,
        })
        const maskedBackgroundLaplacian = math(cmdQueue, {
            operator: "*",
            operandA: math(cmdQueue, {
                operator: "-",
                operandA: 1,
                operandB: mask,
            }),
            operandB: backgroundLaplacian,
        })
        const blendedLaplacian = math(cmdQueue, {
            operator: "+",
            operandA: maskedForegroundLaplacian,
            operandB: maskedBackgroundLaplacian,
        })

        if (i < numLevels - 1) {
            // const nextLevelRegion = getLevelRegion(region, level)
            resultImage = upSample(cmdQueue, {
                sourceImage: resultImage,
                higherLevelSize: {
                    width: blendedLaplacian.descriptor.width,
                    height: blendedLaplacian.descriptor.height,
                },
                // TODO: fix this odd pixel computation when up-sampling using regions !
                // oddPixelInFront: {
                //
                // },
                interpolation: interpolation,
            })
        }

        assertSameSizeAndBatchSize(resultImage.descriptor, blendedLaplacian.descriptor)
        resultImage = math(cmdQueue, {
            operator: "+",
            operandA: resultImage,
            operandB: blendedLaplacian,
        })
    }
    resultImage = convert(cmdQueue, {
        sourceImage: resultImage,
        dataType: sourceDataType,
    })

    cmdQueue.endScope(SCOPE_NAME)
    return {
        resultImage,
        backgroundLaplacianPyramid,
        foregroundLaplacianPyramid,
        maskGaussianPyramid,
    }
}
