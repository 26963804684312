<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [$title]="title"
                    [$isLoaded]="$item() !== undefined"
                    [$canEdit]="$can().update.model($item())"
                    (onChanged)="updateItemSubject.next({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>

            <ng-container cm-buttons>
                @if ($item(); as model) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="moreMenu">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item (click)="removeGalleryImage()" [disabled]="!canRemoveGalleryImage">Remove gallery image</button>
                        <button mat-menu-item (click)="requestDeleteConfirmation()" [disabled]="!model || !$can().delete.item(model)">Delete model</button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-sidebar>
                @if ($item(); as model) {
                    @if (model.state === Enums.ModelState.Draft) {
                        <cm-button
                            (click)="updateItemSubject.next({state: Enums.ModelState.InfoReview, nextActor: Enums.NextActor.ProjectManager})"
                            class="cm-action-button"
                        >
                            <ng-container cm-left-icon>
                                <i class="far fa-check"></i>
                            </ng-container>
                            <ng-container cm-text> Submit</ng-container>
                        </cm-button>
                    }

                    @if (canNavigateToAssets()) {
                        <cm-show-assets-button [$queryParamsKey]="'model'" [$entityId]="model.id"></cm-show-assets-button>
                    }

                    <!--Article ID-->
                    <cm-article-id-section
                        [$item]="model"
                        [$canEdit]="$can().update.model(model)"
                        ($onChanged)="updateItemSubject.next($event)"
                    ></cm-article-id-section>

                    <cm-sidebar-description-section
                        [$item]="model"
                        [$canEdit]="$can().update.model(model)"
                        ($onChanged)="updateItemSubject.next($event)"
                    ></cm-sidebar-description-section>

                    <!--Tags-->
                    <cm-tag-select
                        [assignedToId]="model.id"
                        [includeTagsWithNoOrganization]="true"
                        [contentTypeModel]="Enums.ContentTypeModel.Model"
                        [type]="Enums.TagType.Production"
                        [canEdit]="$can().update.tag(null, 'production')"
                    ></cm-tag-select>

                    <!--Customer-->
                    <cm-organization-select
                        [label]="'Customer'"
                        [updateOrganization]="updateItem.bind(this)"
                        [$organizationId]="$item()?.organization?.id ?? null"
                        [$showIfViewedByOwner]="false"
                        [$disabled]="!$can().update.model(model, 'organization')"
                    ></cm-organization-select>

                    <!--Visibility-->
                    @if ($can().update.model(model, "public")) {
                        <cm-boolean-label-select
                            [$name]="'Visibility'"
                            [$options]="Labels.Public"
                            [$value]="model.public"
                            ($onChanged)="updateItem({public: $event})"
                        >
                        </cm-boolean-label-select>
                    }

                    <!--Range-->
                    @if ($can().read.model(model, "tags")) {
                        <cm-tag-select
                            [label]="'Range'"
                            [organizationId]="model.organization?.id"
                            [assignedToId]="model.id"
                            [contentTypeModel]="ContentTypeModel.Model"
                            [type]="Enums.TagType.ProductRange"
                        ></cm-tag-select>
                    }

                    <!--Category-->
                    @if ($can().read.model(model, "productCategoryTags")) {
                        <cm-tag-select
                            [label]="'Category'"
                            [organizationId]="model.organization?.id"
                            [assignedToId]="model.id"
                            [contentTypeModel]="ContentTypeModel.Model"
                            [type]="Enums.TagType.ProductCategory"
                        ></cm-tag-select>
                    }
                }
            </ng-container>
            <ng-container cm-main>
                @if ($item(); as model) {
                    <cm-corners-layout>
                        <cm-gallery-image [item]="model"></cm-gallery-image>
                        @if ($can().read.model(model, "labels")) {
                            <div cm-bottom-left class="cm-state-container">
                                @if ($can().read.model(model, "paymentState")) {
                                    <cm-payment-state-label
                                        [item]="{id: model.id, __typename: 'Model'}"
                                        [editable]="true"
                                        (stateChange)="updateItem({paymentState: $event})"
                                    >
                                    </cm-payment-state-label>
                                }

                                <cm-state-label
                                    [stateLabels]="Labels.ModelState"
                                    [state]="model.state"
                                    [editable]="true"
                                    (stateChange)="updateItem({state: $event})"
                                ></cm-state-label>
                            </div>
                        }
                        <cm-copy-legacy-id-button cm-bottom-right [legacyId]="model.legacyId"></cm-copy-legacy-id-button>
                    </cm-corners-layout>

                    @if ($can().read.model(model, "tasks")) {
                        <cm-project-management-tasks-section [$item]="model"></cm-project-management-tasks-section>
                    }

                    @if ($can().read.model(model, "comment")) {
                        <cm-comment-section
                            [$item]="model"
                            [$canEdit]="$can().update.model(model, 'comment')"
                            ($onChanged)="updateItemSubject.next($event)"
                        ></cm-comment-section>
                    }

                    @if ($can().read.model(model, "files")) {
                        <cm-files-section [$item]="model"></cm-files-section>
                    }

                    @if ($can().read.model(model, "activity")) {
                        <cm-activity-section [$item]="model"></cm-activity-section>
                    }
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>

<router-outlet></router-outlet>
