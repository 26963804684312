import {Component, EventEmitter, inject, Input, Output, TemplateRef} from "@angular/core"
import {MatDialog} from "@angular/material/dialog"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {TransformDialogComponent, TransformDialogData} from "@editor/components/transform-dialog/transform-dialog.component"
import {TransformControls} from "@editor/helpers/scene/transformation"
import {TransformMode} from "@editor/helpers/scene/transformation"
import {PermissionsService} from "@common/services/permissions/permissions.service"

interface IEditorComponent {
    transformControls: TransformControls
}

@Component({
    selector: "cm-floating-controls",
    templateUrl: "./floating-controls.component.html",
    styleUrls: ["./floating-controls.component.scss"],
    standalone: true,
    imports: [NumericInputComponent, InputContainerComponent, InspectorSectionComponent],
})
export class FloatingControlsComponent {
    @Input({required: true}) editor!: IEditorComponent
    @Input({required: true}) transformMode!: TransformMode
    @Output() transformModeChange: EventEmitter<TransformMode> = new EventEmitter<TransformMode>()

    TransformMode = TransformMode

    permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        public dialog: MatDialog,
        public auth: AuthService,
    ) {}

    private getTransformValue(key: "x" | "y" | "z", transformMode: TransformMode.Rotate | TransformMode.Translate) {
        let value = 0.0
        if (transformMode === TransformMode.Translate) {
            const position = this.editor.transformControls.position
            if (position) {
                value = position[key]
            }
        } else if (transformMode === TransformMode.Rotate) {
            const rotation = this.editor.transformControls.rotation
            if (rotation) {
                value = rotation[key] * (180.0 / Math.PI)
            }
        }
        return value
    }

    private setTransformValue(key: "x" | "y" | "z", value: number, transformMode: TransformMode.Rotate | TransformMode.Translate) {
        if (value == null) return
        if (isNaN(value)) return
        if (transformMode === TransformMode.Translate) {
            const position = this.editor.transformControls.position
            if (position) {
                position[key] = value
                this.editor.transformControls.position = position
            }
        } else if (transformMode === TransformMode.Rotate) {
            const rotation = this.editor.transformControls.rotation
            if (rotation) {
                rotation[key] = value * (Math.PI / 180.0)
                this.editor.transformControls.rotation = rotation
            }
        }
    }

    openTransformDialog() {
        const dialogRef = this.dialog.open(TransformDialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                position: this.editor.transformControls.position,
                rotation: this.editor.transformControls.rotation,
            },
        })
        dialogRef.afterClosed().subscribe((data?) => {
            if (!data) return
            this.editor.transformControls.setPositionAndRotation(data.position!, data.rotation)
        })
    }

    get translateValueX() {
        return this.getTransformValue("x", TransformMode.Translate)
    }

    get translateValueY() {
        return this.getTransformValue("y", TransformMode.Translate)
    }

    get translateValueZ() {
        return this.getTransformValue("z", TransformMode.Translate)
    }

    get rotationValueX() {
        return this.getTransformValue("x", TransformMode.Rotate)
    }

    get rotationValueY() {
        return this.getTransformValue("y", TransformMode.Rotate)
    }

    get rotationValueZ() {
        return this.getTransformValue("z", TransformMode.Rotate)
    }

    set translateValueX(value: number) {
        this.setTransformValue("x", value, TransformMode.Translate)
    }

    set translateValueY(value: number) {
        this.setTransformValue("y", value, TransformMode.Translate)
    }

    set translateValueZ(value: number) {
        this.setTransformValue("z", value, TransformMode.Translate)
    }

    set rotationValueX(value: number) {
        this.setTransformValue("x", value, TransformMode.Rotate)
    }

    set rotationValueY(value: number) {
        this.setTransformValue("y", value, TransformMode.Rotate)
    }

    set rotationValueZ(value: number) {
        this.setTransformValue("z", value, TransformMode.Rotate)
    }
}
