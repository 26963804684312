<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Image Operator </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'input'" [label]="'Input'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'operation'" [label]="'Operation'" [selectionPossibilities]="operationPossibilites"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'value'" [label]="'Value'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
