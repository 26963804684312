@if (asLabel) {
    <div
        [attr.part]="selectedVariantId === variant?.id ? 'cm-config-label-selected' : 'cm-config-label'"
        class="cm-config-label"
        [ngClass]="selectedVariantId === variant?.id ? 'cm-selected' : 'cm-regular'"
    >
        {{ variant?.name }}
    </div>
} @else {
    <div part="cm-icon-with-caption" class="cm-icon-with-caption">
        <div
            part="cm-icon-container"
            class="cm-icon cm-icon-shadow"
            [tp]="variant?.name || notAvailableTooltip"
            [tpAppendTo]="$any(tooltipContainer)"
            [style.background]="variant?.iconDataObjectId ? 'none' : getConfigVariantColor()"
            draggable="true"
            (dragstart)="onDragStart($event, variant, descriptor)"
        >
            @if (fetchThumbnailForDataObject | memoize: this : variant?.iconDataObjectId | async; as thumbnailUrl) {
                <img alt="Variant icon" [src]="thumbnailUrl" />
            }

            <i class="far fa-check" [class.cm-active-variant]="selectedVariantId === variant?.id"></i>
        </div>
        @if (useCaptions) {
            <div class="caption">{{ variant?.name }}</div>
        }
    </div>
}
