import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {OperatorTest} from "@app/textures/texture-editor/operator-stack/operators/test/operator-test"
import {VectorFieldItem} from "@app/textures/texture-editor/operator-stack/operators/test/toolbox/vector-field-item"
import {VectorItem} from "@app/textures/texture-editor/operator-stack/operators/test/toolbox/vector-item"
import {ColorLike, Vector2Like} from "@cm/math"
import {EditVectorItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/edit-vector-item"

export class TestToolbox extends OperatorToolboxBase<OperatorTest> {
    constructor(operator: OperatorTest) {
        super(operator, SelectionMode.None, true)

        this.editVectorItem = new EditVectorItem(this)
        this.vectorFieldItem = new VectorFieldItem(this)
    }

    addVector(from: Vector2Like, to: Vector2Like, color: ColorLike) {
        const vectorItem = new VectorItem(this, from, to, color)
        this._vectorItems.push(vectorItem)
        return vectorItem
    }

    readonly editVectorItem: EditVectorItem
    readonly vectorFieldItem: VectorFieldItem
    private _vectorItems: VectorItem[] = []
}
