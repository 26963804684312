import {ConditionalAmountNode} from "#pricing/nodes/conditional-amount-node"
import {PricingContext} from "#pricing/nodes/core"
import {DeclareListNodeTS, registerNode} from "@cm/graph"

@registerNode
export class ConditionalAmountList extends DeclareListNodeTS<PricingContext, ConditionalAmountNode>(
    {},
    {
        nodeClass: "ConditionalAmountList",
    },
) {}
