@if (unsupported) {
    <div class="cm-name cm-name--unsupported" [class.disabled]="disabled()">
        {{ typeInfo?.label }}
        <i matTooltip="Unsupported node. Please use an alternative." class="far fa-warning"> </i>
        @if (disableable()) {
            <cm-toggle [toggle]="!disabled()" (toggleChange)="toggleDisabled()"></cm-toggle>
        }
    </div>
} @else {
    <div [style.background]="typeInfo?.color + 20" [style.color]="typeInfo?.color" class="cm-name" [class.disabled]="disabled()">
        {{ typeInfo?.label }}
        @if (disableable()) {
            <cm-toggle [toggle]="!disabled()" (toggleChange)="toggleDisabled()"></cm-toggle>
        }
    </div>
}
<div class="cm-content" [class.disabled]="disabled()">
    @for (output of outputs | keyvalue: utils.orderByKey; track output) {
        <cm-node-io
            [valueType]="output.value.valueType"
            [socket]="output.value"
            [range]="output.value.range"
            (connectionChange)="connectionChange.emit($event)"
            [value]="getParameter(output.value.id)"
            [disableEditingIfConnected]="false"
            #nodeIo
        >
        </cm-node-io>
    }
    <ng-content select="[cm-outputs]"></ng-content>
    <ng-content select="[cm-settings]"></ng-content>
    @if (settings) {
        <!-- TODO: Does it make sense to bring in this specific setting (a dropdown) here? Why not leave it up to the component to content project it? -->
        @for (setting of settings; track setting) {
            <mat-form-field>
                <mat-select
                    panelWidth=""
                    [value]="getParameter(setting.id)"
                    (selectionChange)="onParameterChange(setting.id, $event.value, setting.valueType, 'update')"
                >
                    @for (settingOption of setting.options; track settingOption) {
                        <mat-option [value]="settingOption.value">{{ settingOption.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    }
    @for (input of inputs | keyvalue: utils.orderByKey; track input) {
        <cm-node-io
            [valueType]="input.value.valueType"
            [socket]="input.value"
            [range]="input.value.range"
            [value]="getParameter(input.value.id)"
            (connectionChange)="connectionChange.emit($event)"
            #nodeIo
        >
        </cm-node-io>
    }
    <ng-content select="[cm-inputs]"></ng-content>
    <ng-content select="[cm-extra-content]"></ng-content>
</div>
