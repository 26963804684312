import {Component, computed, input} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {SelectionPossibilities, SelectionPossibility, ValueSlotComponent, resolveSelectionPossibilityValue} from "../../value-slot/value-slot.component"
import {SceneNodes} from "@cm/template-nodes"
import {ToneMapping, defaultsForToneMapping, schemaForToneMapping} from "@cm/template-nodes"
import {parseZodSchema} from "zod-key-parser"
import * as changeCase from "change-case"
import {TemplateNode} from "@cm/template-nodes"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"

@Component({
    selector: "cm-tone-mapping-inspector",
    standalone: true,
    templateUrl: "./tone-mapping-inspector.component.html",
    styleUrl: "./tone-mapping-inspector.component.scss",
    imports: [ValueSlotComponent],
})
export class ToneMappingInspectorComponent extends BaseInspectorComponent<TemplateNode<{toneMapping?: ToneMapping}>, {toneMapping?: ToneMapping}> {
    sceneNode = computed(() => {
        const sceneNodes = this.sceneManagerService.getSceneNodeParts({templateNode: this.node(), part: "root"}).map((part) => part.sceneNode)
        const camera = sceneNodes.find(SceneNodes.Camera.is)
        return camera
    })
    toneMappingPossibilites: SelectionPossibilities<ToneMapping> = [
        {value: defaultsForToneMapping("pbr-neutral"), name: "PBR Neutral"},
        {value: defaultsForToneMapping("filmic"), name: "Filmic"},
        {value: defaultsForToneMapping("filmic-advanced"), name: "Filmic (Advanced)"},
        {value: defaultsForToneMapping("contrast"), name: "Contrast"},
        {value: defaultsForToneMapping("corona"), name: "Corona"},
        {value: defaultsForToneMapping("linear"), name: "None (Linear)"},
    ]
    toneMapping = computed(() => {
        const {toneMapping} = this.parameters()
        return toneMapping
    })
    toneMappingProperties = computed(() => {
        const toneMapping = this.toneMapping()
        if (!toneMapping) return []
        const schema = schemaForToneMapping(toneMapping.mode)

        const getLabel = (key: string) => {
            if (key === "highlightCompression") return "Highlight"
            return changeCase.capitalCase(key)
        }

        return Object.keys(parseZodSchema(schema).keys)
            .filter((key) => key !== "mode")
            .map((key) => ({key, label: getLabel(key)}))
    })
    toneMappingSelected = (selectionPossibility: SelectionPossibility<ToneMapping>) => {
        const toneMapping = this.toneMapping()
        if (!toneMapping) return false
        const selectionPossibilityValue = resolveSelectionPossibilityValue(selectionPossibility.value)
        return selectionPossibilityValue.mode === toneMapping.mode
    }
}
