import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {BumpInputs, BumpOutputs} from "@material-editor/models/nodes/bump"

@Component({
    selector: "cm-bump-node",
    templateUrl: "./bump-node.component.html",
    styleUrls: ["./bump-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class BumpNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = BumpOutputs
    inputs = BumpInputs
    typeInfo = BumpNodeType
}

export const BumpNodeType: MaterialNodeType<typeof BumpNodeComponent> = {
    id: "bump",
    label: "Bump",
    color: "#8d802f",
    name: "ShaderNodeBump",
    inputs: [BumpInputs.strength, BumpInputs.distance, BumpInputs.height, BumpInputs.normal],
    outputs: [BumpOutputs.normal],
    component: BumpNodeComponent,
}
