import {MaterialSocket} from "@material-editor/models/material-socket"

export const CombineHsvOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Image", type: "output", valueType: "output", label: "Image"},
}

export const CombineHsvInputs: Record<string, MaterialSocket> = {
    h: {paramType: "socket", id: "H", type: "input", valueType: "scalar", label: "H", range: {min: 0, max: 1}},
    s: {paramType: "socket", id: "S", type: "input", valueType: "scalar", label: "S", range: {min: 0, max: 1}},
    v: {paramType: "socket", id: "V", type: "input", valueType: "scalar", label: "V", range: {min: 0, max: 1}},
}
