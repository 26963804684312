import {Size2Like, Vector2} from "@cm/math"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertNoBatching} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {PainterRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {assertNever} from "@cm/utils"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {reduce} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-reduce"
import {reshape} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/utils/reshape"

export type ParameterType = {
    sourceImage: ImageRef
    interpolation: InterpolationType
    resultSize: Size2Like
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export type InterpolationType = "nearest" | "cubic" | "lanczos2" | "lanczos3"

const imageOpResize: ImageOpType<ParameterType, ReturnType> = {
    name: "Resize",

    WebGL2: ({cmdQueue, parameters: {sourceImage, interpolation, resultSize, resultImageOrDataType}}) => {
        assertNoBatching(sourceImage.descriptor)
        if (resultSize.width === sourceImage.descriptor.width && resultSize.height === sourceImage.descriptor.height) {
            return sourceImage
        }

        // NOTE: even though lib-vips says this headroom should be at least 2x the target resolution, practically it has been observed that this can still cause severe moiré patterns (e.g. see "Baltimore Black" - Specular Strength map)
        const shrinkMinHeadroomScale = 1 // the down-sampled (pre-filtered) resolution will be between the target size and twice the target size before final resample
        const effectiveImageSize = new Vector2(sourceImage.descriptor.width, sourceImage.descriptor.height)

        // DOWN-SAMPLING PASS
        // shrink the image by an integer factors (to be at least the target size times the shrinkMinHeadroomScale)
        const shrinkFactor = {
            x: Math.max(1, Math.floor(sourceImage.descriptor.width / resultSize.width / shrinkMinHeadroomScale)),
            y: Math.max(1, Math.floor(sourceImage.descriptor.height / resultSize.height / shrinkMinHeadroomScale)),
        }
        if (shrinkFactor.x > 1 || shrinkFactor.y > 1) {
            effectiveImageSize.divInPlace(shrinkFactor)
            sourceImage = reshape(
                sourceImage,
                {
                    patchSize: {width: shrinkFactor.x, height: shrinkFactor.y},
                },
                {
                    allowPatchFill: true,
                },
            )
            sourceImage = reduce(cmdQueue, {
                sourceImage,
                operator: "mean",
            })
            sourceImage = reshape(sourceImage, {})
        }

        // RESAMPLING PASS
        if (resultSize.width !== effectiveImageSize.x || resultSize.height !== effectiveImageSize.y) {
            let painterInterpolate: PainterRef
            switch (interpolation) {
                case "nearest":
                    painterInterpolate = cmdQueue.createPainter(
                        "compositor",
                        "interpolateNearest",
                        `
                        uniform vec2 u_texelStep;

                        vec4 computeColor(ivec2 targetPixel) {
                            vec2 sourcePixel = (vec2(targetPixel) + 0.5) * u_texelStep - 0.5;
                            return texelFetch0(ivec2(sourcePixel + 0.5), ADDRESS_MODE_CLAMP_TO_EDGE);
                        }
                    `,
                    )
                    break
                case "cubic":
                    painterInterpolate = cmdQueue.createPainter(
                        "compositor",
                        "interpolateCubic",
                        `
                        uniform vec2 u_texelStep;

                        // Catmull-Rom spline interpolation function
                        vec4 catmullRom(vec4 P0, vec4 P1, vec4 P2, vec4 P3, float t) {
                            // vec4 T1 = 0.5 * (P2 - P0); // Tangent at P1
                            // vec4 T2 = 0.5 * (P3 - P1); // Tangent at P2
                            //
                            // float t2 = t * t;
                            // float t3 = t2 * t;
                            //
                            // // Catmull-Rom spline formula
                            // return (2.0 * t3 - 3.0 * t2 + 1.0) * P1 +
                            //        (t3 - 2.0 * t2 + t) * T1 +
                            //        (-2.0 * t3 + 3.0 * t2) * P2 +
                            //        (t3 - t2) * T2;

                            vec4 a0 = P1;
                            vec4 a1 = 0.5 * (P2 - P0);
                            vec4 a2 = 0.5 * (2.0 * P0 - 5.0 * P1 + 4.0 * P2 - P3);
                            vec4 a3 = 0.5 * (3.0 * (P1 - P2) + P3 - P0);
                            float t2 = t * t;
                            float t3 = t2 * t;
                            return a0 + a1 * t + a2 * t2 + a3 * t3;
                        }

                        vec4 computeColor(ivec2 targetPixel) {
                            vec2 sourcePixel = (vec2(targetPixel) + 0.5) * u_texelStep - 0.5;

                            ivec2 baseCoord = ivec2(floor(sourcePixel));
                            vec2 fractCoord = sourcePixel - vec2(baseCoord);

                            vec4 sampleRows[4];
                            for (int j = -1; j <= 2; j++) {
                                vec4 rowValues[4];
                                for (int i = -1; i <= 2; i++) {
                                    ivec2 offset = ivec2(i, j);
                                    rowValues[i + 1] = texelFetch0(baseCoord + offset, ADDRESS_MODE_CLAMP_TO_EDGE);
                                }
                                // Interpolate along the X-axis
                                sampleRows[j + 1] = catmullRom(rowValues[0], rowValues[1], rowValues[2], rowValues[3], fractCoord.x);
                            }
                            // Interpolate along the Y-axis using the interpolated rows
                            return catmullRom(sampleRows[0], sampleRows[1], sampleRows[2], sampleRows[3], fractCoord.y);
                        }
                    `,
                    )
                    break
                case "lanczos2":
                case "lanczos3":
                    throw new Error("Resize: lanczos interpolation not implemented yet")
                default:
                    assertNever(interpolation)
            }
            resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, {
                ...sourceImage.descriptor,
                ...resultSize,
            })
            cmdQueue.paint(painterInterpolate, {
                parameters: {
                    u_texelStep: {
                        type: "float2",
                        value: {
                            x: effectiveImageSize.x / resultSize.width,
                            y: effectiveImageSize.y / resultSize.height,
                        },
                    },
                },
                sourceImages: sourceImage,
                resultImage: resultImageOrDataType,
            })
        } else if (resultImageOrDataType) {
            resultImageOrDataType = copyRegion(cmdQueue, {
                sourceImage: sourceImage,
                resultImageOrDataType,
            })
        } else {
            resultImageOrDataType = sourceImage
        }
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, interpolation, resultSize, resultImageOrDataType}}) => {
        assertNoBatching(sourceImage.descriptor)
        const resultNode = cmdQueue.createImage(
            {
                ...sourceImage.descriptor,
                ...resultSize,
            },
            {
                type: "resize",
                input: sourceImage,
                width: resultSize.width,
                height: resultSize.height,
                interpolationMode: interpolation,
            },
        )
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function resize(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpResize, parameters)
}
