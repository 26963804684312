import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {ImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {ColorLike, Size2Like} from "@cm/math"

export class DebugImage {
    constructor() {}

    dispose() {
        this.debugImageRef?.release()
        this.debugImageRef = undefined
    }

    get imageRef() {
        if (!this.debugImageRef) {
            throw new Error("DebugImage not initialized")
        }
        return this.debugImageRef.ref
    }

    get isInited() {
        return !!this.debugImageRef
    }

    init(cmdQueue: ImageOpCommandQueueWebGL2, size: Size2Like, clearColor?: ColorLike) {
        this.cmdQueue = cmdQueue
        const imageRef = createImage(cmdQueue, {
            imageOrDescriptor: {
                width: size.width,
                height: size.height,
                channelLayout: "RGBA",
                dataType: "float16",
            },
            fillColor: clearColor,
        })
        this.debugImageRef?.release()
        this.debugImageRef = cmdQueue.keepAlive(imageRef)
        this.currentPosition = 0
    }

    addImage(image: ImageRef, options?: {scale?: number | ColorLike; offset?: number | ColorLike}) {
        const debugImageRef = this.debugImageRef?.ref
        if (!this.cmdQueue || !debugImageRef) {
            throw new Error("DebugImage not initialized")
        }
        const useColorTransform = options?.scale || options?.offset
        const targetOffset = {
            x: 0,
            y: this.currentPosition,
        }
        let result = copyRegion(this.cmdQueue, {
            sourceImage: image,
            targetOffset: useColorTransform ? undefined : targetOffset,
            addressMode: "border",
            resultImageOrDataType: useColorTransform ? undefined : debugImageRef,
            options: {
                disableBatchSizeCheck: true,
            },
        })
        if (useColorTransform) {
            const isConstant = (value: number | ColorLike, constant: number) => {
                if (typeof value === "number") {
                    return value === constant
                } else {
                    return value.r === constant && value.g === constant && value.b === constant && (value.a === undefined || value.a === constant)
                }
            }
            const scale = options?.scale ?? 1
            if (!isConstant(scale, 1)) {
                result = math(this.cmdQueue, {
                    operandA: result,
                    operandB: scale,
                    operator: "*",
                })
            }
            const offset = options?.offset ?? 0
            if (!isConstant(offset, 0)) {
                result = math(this.cmdQueue, {
                    operandA: result,
                    operandB: offset,
                    operator: "+",
                })
            }
            result = copyRegion(this.cmdQueue, {
                sourceImage: result,
                targetOffset,
                addressMode: "border",
                resultImageOrDataType: debugImageRef,
                options: {
                    disableBatchSizeCheck: true,
                },
            })
        }
        const newDebugImageRef = this.cmdQueue.keepAlive(result)
        this.debugImageRef?.release()
        this.debugImageRef = newDebugImageRef
        this.currentPosition += image.descriptor.height + 5
    }

    private cmdQueue?: ImageOpCommandQueueWebGL2
    private debugImageRef?: ManagedImageRef
    private currentPosition = 0
}
