import * as WebGl2ShaderUtils from "@common/helpers/webgl2/webgl2-shader-utils"
import {assertNever} from "@cm/utils"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {ColorLike} from "@cm/math"
import {ImageDescriptor} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export function getHalAddressMode(addressMode: AddressMode): number {
    switch (addressMode) {
        case "clamp":
            return WebGl2ShaderUtils.resolveTextureAddressMode("CLAMP_TO_EDGE")
        case "wrap":
            return WebGl2ShaderUtils.resolveTextureAddressMode("REPEAT")
        case "wrap-mirrored":
            return WebGl2ShaderUtils.resolveTextureAddressMode("MIRRORED_REPEAT")
        case "border":
            return WebGl2ShaderUtils.resolveTextureAddressMode("BORDER")
        default:
            assertNever(addressMode)
    }
}

export function getVec4Color(color: ColorLike): string {
    return `vec4(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

export const getHalImageDescriptor = (descriptor: ImageDescriptor): HalImageDescriptor => {
    return descriptor
}
