import {createSegmentedMesh, IScene, mapOverMeshes} from "@editor/helpers/scene/three-proxies/utils"
import {fromEvent, map, of as observableOf, Subject, takeUntil} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {CSS2DObject} from "@cm/material-nodes/three"
import {MeshData} from "@cm/template-nodes"
import {IDisplaySceneEvent, MeshRenderSettings, ObjectId, SceneNodes} from "@cm/template-nodes"
import {IMaterialData} from "@cm/template-nodes"
import {deepEqual} from "@cm/utils"
import {ThreeObjectBase} from "@editor/helpers/scene/three-proxies/utils"

export class ThreeMesh extends ThreeObjectBase {
    threeObject: THREE.Group = new THREE.Group()
    private materialSlotToMeshListMap = new Map<number, THREE.Mesh[]>()
    private _meshData?: MeshData
    private _meshRenderSettings?: MeshRenderSettings
    private _materialMap = new Map<number, [IMaterialData | undefined | null, THREE.Material | undefined]>()

    constructor(scene: IScene) {
        super(scene)
    }

    update(mesh: SceneNodes.Mesh) {
        if (mesh.meshData !== this._meshData || !deepEqual(mesh.meshRenderSettings, this._meshRenderSettings, 1)) {
            this._meshData = mesh.meshData
            this._meshRenderSettings = mesh.meshRenderSettings ? {...mesh.meshRenderSettings} : undefined
            this._materialMap.clear() // invalidate materials, as render settings may have changed
            this.scene.deferUpdate(this.updateMeshFn)
        }
        this.updateTransform(mesh.transform)
        this.updateMaterialMap(mesh.materialMap)
        this.topLevelObjectId = mesh.topLevelObjectId
    }

    updateMaterialMap(materialMap: Map<number, IMaterialData | null>) {
        materialMap?.forEach((materialData, slot) => {
            this.setMaterial(slot, materialData)
        })
        this._materialMap.forEach((materialData, slot) => {
            if (!materialMap?.has(slot)) {
                this.setMaterial(slot, undefined)
            }
        })
    }

    private updateMeshFn = () => {
        if (this.threeObject) {
            mapOverMeshes(this.threeObject, (mesh) => {
                mesh.geometry.dispose()
            })
        }
        this.materialSlotToMeshListMap.clear()
        this.threeObject.children = []
        const slotsWithoutMaterial = new Set<number>()
        if (this._meshData) {
            createSegmentedMesh(this.threeObject, this._meshData, (mesh: THREE.Mesh, materialSlot: number) => {
                mesh.castShadow = true
                mesh.receiveShadow = true
                mesh.userData.threeSceneObject = this
                mesh.userData.materialSlot = materialSlot
                let list = this.materialSlotToMeshListMap.get(materialSlot)
                if (list === undefined) {
                    list = []
                    this.materialSlotToMeshListMap.set(materialSlot, list)
                }
                list.push(mesh)
                slotsWithoutMaterial.add(materialSlot)
                return mesh
            })
        }
        for (const [slot, [materialData, material]] of this._materialMap) {
            if (material) {
                this.applyMaterialToMeshForSlot(slot, material)
            }
            // If the iterator included an entry for this slot, but material is undefined, then it is still loading.
            slotsWithoutMaterial.delete(slot)
        }
        // this will trigger async loading of the default material for slots that do not have an assignment yet
        for (const slot of slotsWithoutMaterial) {
            this.setMaterial(slot, undefined)
        }
    }

    private applyMaterialToMeshForSlot(slot: number, material: THREE.Material) {
        const meshes = this.materialSlotToMeshListMap.get(slot)
        if (meshes) {
            for (const mesh of meshes) {
                if (material) {
                    mesh.material = material
                    this.scene.update()
                } else {
                    console.warn("Tried to set null material!")
                }
            }
        }
    }

    private setMaterial(slot: number, materialData: IMaterialData | undefined | null) {
        let entry: [IMaterialData | undefined | null, THREE.Material | undefined] | undefined = this._materialMap.get(slot)
        if (!entry) {
            entry = [materialData, undefined]
            this._materialMap.set(slot, entry)
        } else if (entry[0] === materialData) {
            // no change
            return
        }
        const mgr = this.scene.materialManager
        this.scene.addTask(
            (materialData ? mgr.getMaterial(materialData, this._meshRenderSettings) : observableOf(mgr.getDefaultMaterial(slot))).pipe(
                map((material) => {
                    const oldMaterial = entry![1]
                    mgr.releaseMaterial(oldMaterial!)
                    entry![0] = materialData
                    entry![1] = material
                    this.applyMaterialToMeshForSlot(slot, material)
                }),
            ),
        )
    }

    override getOutlineTokens(materialSlot: number | null): any[] {
        if (materialSlot === null) {
            return [this.threeObject]
        } else {
            return this.materialSlotToMeshListMap.get(materialSlot) || []
        }
    }

    gatherGeometryAndMaterialData() {
        const retList: [THREE.BufferGeometry, THREE.Material, IMaterialData | null | undefined][] = []
        for (const [slot, meshes] of this.materialSlotToMeshListMap) {
            const materialEntry = this._materialMap.get(slot)
            const materialData = materialEntry && materialEntry[0]
            for (const mesh of meshes) {
                retList.push([mesh.geometry as THREE.BufferGeometry, mesh.material as THREE.Material, materialData])
            }
        }
        // if (meshes) {
        // const matMap = new Map<number, IMaterialData>();
        // this._materialMap.forEach(([materialData, threeMaterial], slot) => matMap.set(slot, materialData));
        // return matMap;
        return retList
    }
}

export class ThreeRectangle extends ThreeObjectBase {
    threeObject: THREE.Group
    private mesh: THREE.Mesh

    constructor(scene: IScene) {
        super(scene)
        const geometry = new THREE.PlaneGeometry()
        const material = new THREE.MeshBasicMaterial({color: 0xcccccc, side: THREE.DoubleSide, transparent: true, opacity: 0.5})
        this.mesh = new THREE.Mesh(geometry, material)
        this.mesh.rotation.x = Math.PI / 2
        this.mesh.userData.threeSceneObject = this
        this.mesh.userData.materialSlot = undefined
        this.mesh.layers.set(1)
        this.threeObject = new THREE.Group()
        this.threeObject.add(this.mesh)
    }

    update(rect: SceneNodes.Rectangle | SceneNodes.LightPortal) {
        const rotX = rect.type === "LightPortal" ? 0 : Math.PI / 2
        if (this.mesh.scale.x !== rect.width || this.mesh.scale.y !== rect.height || this.mesh.rotation.x !== rotX) {
            this.mesh.rotation.x = rotX
            this.mesh.scale.x = rect.width
            this.mesh.scale.y = rect.height
            this.mesh.updateMatrix()
            this.scene.update()
        }
        this.updateTransform(rect.transform)
        this.topLevelObjectId = rect.topLevelObjectId
    }
}

export class ThreePoint extends ThreeObjectBase {
    threeObject: THREE.Group
    private mesh: THREE.Mesh

    constructor(scene: IScene) {
        super(scene)
        const geometry = new THREE.PlaneGeometry(1e-3, 1e-3)
        const material = new THREE.MeshBasicMaterial({color: 0xcccccc, side: THREE.DoubleSide, transparent: true, opacity: 0.2})
        this.mesh = new THREE.Mesh(geometry, material)
        this.mesh.userData.threeSceneObject = this
        this.mesh.userData.materialSlot = undefined
        this.mesh.layers.set(1)
        this.threeObject = new THREE.Group()
        this.threeObject.add(this.mesh)
    }

    update(point: SceneNodes.Point) {
        if (this.mesh.scale.x !== point.size) {
            this.mesh.scale.x = point.size
            this.mesh.scale.y = point.size
            this.mesh.scale.z = point.size
            this.mesh.updateMatrix()
            this.scene.update()
        }
        this.updateTransform(point.transform)
        this.topLevelObjectId = point.topLevelObjectId
    }
}

export interface ThreeAnnotationEvent extends IDisplaySceneEvent {
    type: "AnnotationClickEvent"
    label: string
    description: string
    id: ObjectId
}

export class ThreeAnnotation extends ThreeObjectBase {
    threeObject: THREE.Group
    private readonly unsubscribe = new Subject<void>()
    private readonly cssObject: CSS2DObject
    private readonly labelContainerElement: HTMLDivElement
    private readonly labelElement: HTMLDivElement
    private descriptionElem: HTMLDivElement
    private annotationID?: string

    constructor(scene: IScene) {
        super(scene)

        const css = `
            .cm-annotation-label {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                background-color: #00000080;
                box-shadow: 0 0 0 2px #ffffff80;
                font-size: 14px;
                font-weight: bold;
                color: white;
                border-radius: 50%;
                cursor: pointer;
            }
            .cm-annotation-container {
                cursor: pointer;
                padding: 5px;
                margin: -5px;
                user-select: none;
            }
            .cm-annotation-container:hover .cm-annotation-label {
                color: #40c4ff;
                box-shadow: 0 0 0 2px #40c4ff;
            }`

        const style = document.createElement("style")
        style.innerHTML = css
        document.body.appendChild(style)

        this.labelElement = document.createElement("div")
        this.labelElement.className = "cm-annotation-label"
        this.descriptionElem = document.createElement("div")

        this.labelContainerElement = document.createElement("div")
        this.labelContainerElement.className = "cm-annotation-container"
        this.labelContainerElement.appendChild(this.labelElement)
        // domElem.appendChild(descriptionElem);

        this.cssObject = new CSS2DObject(this.labelContainerElement)
        this.threeObject = new THREE.Group()
        this.threeObject.add(this.cssObject)
        this.threeObject.position.set(0, 1, 0)

        this.addEventBindings()
    }

    override addEventBindings(): void {
        fromEvent(this.labelContainerElement, "click")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((e) => {
                const event = {
                    type: "AnnotationClickEvent",
                    label: this.labelElement.innerHTML,
                    description: this.descriptionElem.innerHTML,
                    id: this.annotationID,
                } as ThreeAnnotationEvent
                this.scene.sceneEvent$.next(event)
            })
        // The 'click' event is triggered on tap/touch as well, but for some reason it does not work without the following line. Maybe it has to do with ThreeJS's event handling?
        fromEvent(this.labelContainerElement, "touchstart")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => event.stopPropagation())
    }

    update(annotation: SceneNodes.Annotation) {
        this.labelElement.innerHTML = annotation.label
        this.descriptionElem.innerHTML = annotation.description
        this.annotationID = annotation.annotationID
        this.updateTransform(annotation.transform)
    }

    override dispose() {
        super.dispose()
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
