import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const phiDescriptor = {
    a: TD.inlet(TD.Identity<any>()),
    b: TD.inlet(TD.Identity<any>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class Phi<T> implements NodeClassImpl<typeof phiDescriptor, typeof Phi> {
    static descriptor = phiDescriptor
    static uniqueName = "Phi"
    a!: Inlet<T>
    b!: Inlet<T>
    output!: Outlet<T>

    run() {
        if (this.a !== NotReady) {
            this.output.emitIfChanged(this.a)
        } else if (this.b !== NotReady) {
            this.output.emitIfChanged(this.b)
        } else {
            this.output.emitIfChanged(NotReady)
        }
    }
}
