import {MaterialSocket} from "@material-editor/models/material-socket"

export const LayerWeightOutputs: Record<string, MaterialSocket> = {
    fresnel: {paramType: "socket", id: "Fresnel", type: "output", valueType: "scalar", label: "Fresnel"},
    facing: {paramType: "socket", id: "Facing", type: "output", valueType: "scalar", label: "Facing"},
}

export const LayerWeightInputs: Record<string, MaterialSocket> = {
    blend: {paramType: "socket", id: "Blend", type: "input", valueType: "scalar", label: "Blend", range: {min: 0, max: 1}},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "vector", label: "Normal"},
}
