import {Component, Input} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {IEditor} from "@editor/models/editor"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"

@Component({
    selector: "cm-hdri-light-inspector",
    templateUrl: "./hdri-light-inspector.component.html",
    styleUrls: ["./hdri-light-inspector.component.scss"],
    standalone: true,
    imports: [MatInputModule, MatSelectModule, InspectorSectionComponent, MemoizePipe, NumericInputComponent, InputContainerComponent, ToggleComponent],
})
export class HdriLightInspectorComponent {
    @Input() hdris: {name: string; id: number}[] = []
    @Input({required: true}) editor!: IEditor
    @Input() node: Nodes.HDRILight | null = null

    constructor(public authService: AuthService) {}

    hdriChanged(hdri: {name: string; id: number}): void {
        this.node!.hdri = {
            type: "hdriReference",
            hdriId: hdri.id,
            name: hdri.name,
        }
        this.nodeChanged(this.node!)
    }

    hdriForHdriNode(node: Nodes.HDRILight): {name: string; id: number} | undefined {
        return this.hdris.find((hdri) => hdri.id === node.hdri?.hdriId)
    }

    nodeChanged(node: Nodes.Node | null): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    parseOptionalNumber(text: string): number | undefined {
        if (text !== null && text !== undefined) {
            const num = parseFloat(text)
            if (!isNaN(num)) {
                return num
            }
        }
        return undefined
    }
}
