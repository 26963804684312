import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http"
import {ErrorHandler, Injectable, NgModule} from "@angular/core"
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics"
import {initializeApp, provideFirebaseApp} from "@angular/fire/app"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core"
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field"
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser"
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {RouteReuseStrategy} from "@angular/router"
import {BackgroundOperationListComponent} from "@app/common/components/background-operation-list/background-operation-list.component"
import {NavigationBarComponent} from "@common/components/navigation"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {GlobalAppInjector} from "@common/helpers/app-injector/app-injector"
import {UtilsService} from "@legacy/helpers/utils"
import {CM_DATE_FORMATS} from "@common/models/settings/settings"
import {GraphQLModule} from "@common/modules/graphql/graphql.module"
import {AuthService} from "@common/services/auth/auth.service"
import {FilesService} from "@common/services/files/files.service"
import {AuthGuard, BackendAuthGuard, PicturesAuthGuard, StaffGuard} from "@common/services/guards/guards.service"
import {InjectorService} from "@common/services/injector/injector.service"
import {ItemUtils} from "@common/services/item-utils/item-utils.service"
import {MaterialAssetsRenderingService} from "@common/services/material-assets-rendering/material-assets-rendering.service"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {MaterialMapsExporterService} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {NameAssetFromSchemaService} from "@common/services/name-asset-from-schema/name-asset-from-schema.service"
import {NavigationBarService} from "@common/services/navigation-bar/navigation-bar-service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {ImageProcessingService} from "@common/services/rendering/image-processing.service"
import {StatisticsService} from "@common/services/statistics/statistics.service"
import {UploadProcessingService} from "@common/services/upload/upload-processing.service"
import {HdriService} from "@editor/services/hdri.service"
import {environment} from "@environment"
import {NodeSelectionManagerService} from "@node-editor/services/node-selection-manager.service"
import {MaterialExplorerComponent} from "@platform/components/materials/material-explorer/material-explorer.component"
import {TokenInterceptor} from "@platform/interceptors/auth/token.interceptor"
import {RouteStrategy} from "@platform/services/route-strategy/route-strategy"
import {AppRoutingModule} from "app/app-routing.module"
import {AppComponent} from "app/app.component"
import * as Hammer from "hammerjs"
import {TippyConfigurationModule} from "@common/modules/tippy/tippy-configuration.module"
import {CM_COLORMASS_CLIENT} from "@common/modules/client-injector"
import {BackgroundOperationService} from "@app/platform/services/background-operation/background-operation.service"
import {ErrorHandlerService} from "@common/services/error-handler-service/error-handler-service.service"
import {MaterialBatchOperationService} from "@platform/services/material/material-batch-operation.service"
import {TemplateThumbnailRenderingService} from "@common/services/template-thumbnail-rendering/template-thumbnail-rendering.service"
import {TexturesApiService} from "@app/textures/service/textures-api.service"

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    override overrides = {
        swipe: {direction: Hammer.DIRECTION_ALL},
    }
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        AppRoutingModule,
        BackgroundOperationListComponent,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        GraphQLModule,
        HammerModule,
        HttpClientModule,
        MaterialExplorerComponent,
        MatNativeDateModule,
        NavigationBarComponent,
        ReactiveFormsModule,
        SceneViewerComponent,
        TippyConfigurationModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {provide: MAT_DATE_FORMATS, useValue: CM_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: "en-GB"},
        {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig},
        {
            provide: RouteReuseStrategy,
            useClass: RouteStrategy,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: "outline",
                floatLabel: "always",
                subscriptSizing: "dynamic",
            },
        },
        {provide: CM_COLORMASS_CLIENT, useValue: "platform"},
        AuthGuard,
        AuthService,
        BackendAuthGuard,
        BackgroundOperationService,
        FilesService,
        HdriService,
        ImageProcessingService,
        InjectorService,
        ItemUtils,
        MaterialAssetsRenderingService,
        MaterialBatchOperationService,
        MaterialGraphService,
        MaterialMapsExporterService,
        NameAssetFromSchemaService,
        NavigationBarComponent,
        NavigationBarService,
        NodeSelectionManagerService,
        NotificationsService,
        PicturesAuthGuard,
        StaffGuard,
        StatisticsService,
        TemplateThumbnailRenderingService,
        TexturesApiService,
        UploadProcessingService,
        UtilsService,
    ],
})
export class AppModule {
    constructor(injector: InjectorService) {
        GlobalAppInjector.injector = injector.injector
        UtilsService.printAppVersion()
    }
}
