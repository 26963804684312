import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParameterCodec, HttpParams} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {GoogleStorageUpload} from "@app/common/models/upload"
import {createTaskProgressOperator, TaskProgressEvent} from "@common/helpers/tasks/observable-task-manager"
import {Settings} from "@common/models/settings/settings"
import {catchError, filter, from as observableFrom, map, Observable, of as observableOf, switchMap, throwError as observableThrowError} from "rxjs"
import {IsDefined} from "@cm/utils"

type ApiJson = any

type ApiPaginated<T> = {
    count: number
    previous: string
    next: string
    results: T[]
}

export type APIQueryParams = HttpParams | Record<string, string | number | boolean | string[] | number[] | boolean[] | undefined | null>

// create a wrapper around the browser cache, to allow graceful fallback when security exceptions are thrown

let _caches: {
    open(name: string): Promise<{
        match(url: string): Promise<Response | undefined>
        put(url: string, response: Response): Promise<void>
    } | null>
}

function _disableCaches() {
    _caches = {open: async (_name) => null} // disable cache
}

const _browserCaches = window.caches
if (_browserCaches) {
    _caches = {
        open: async (name) => {
            try {
                const cache = await _browserCaches.open(name)
                return {
                    match: async (url) => {
                        try {
                            return await cache.match(url)
                        } catch (e) {
                            console.warn("cache.match failed:", e)
                            _disableCaches()
                            return undefined
                        }
                    },
                    put: async (url, response) => {
                        try {
                            await cache.put(url, response)
                        } catch (e) {
                            console.warn("cache.put failed:", e)
                            _disableCaches()
                        }
                    },
                }
            } catch (e) {
                console.warn("Failed to open cache:", e)
                _disableCaches()
                return null
            }
        },
    }
} else {
    console.warn("window.caches is undefined! Downloaded DataObjects will not be cached locally!")
    _disableCaches()
}

@Injectable({
    providedIn: "root",
})
export class APIService {
    constructor(public http: HttpClient) {}

    /**********************************************************************************************************
     * General
     **********************************************************************************************************/

    get<T = ApiJson>(id: number, url: string, queryParams?: APIQueryParams): Observable<T> {
        const requestOptions = APIService.prepareRequestOptions(queryParams)
        return this.http.get<T>(url + id + "/", requestOptions).pipe(catchError(APIService.handleError))
    }

    getRaw<T = any>(url: string, queryParams?: APIQueryParams, _headers?: HttpHeaders): Observable<T> {
        const requestOptions = APIService.prepareRequestOptions(queryParams)
        return this.http.get<T>(url, requestOptions).pipe(catchError(APIService.handleError))
    }

    getAll<T = ApiJson>(url: string, queryParams?: APIQueryParams): Observable<T[]> {
        const requestOptions = APIService.prepareRequestOptions(queryParams)
        return this.http.get<T[]>(url, requestOptions).pipe(catchError(APIService.handleError))
    }

    getAllPaginated<T = ApiJson>(url: string, limit: number, offset: number, queryParams?: APIQueryParams): Observable<ApiPaginated<T>> {
        const requestOptions = APIService.prepareRequestOptions(queryParams)
        requestOptions.params = requestOptions.params.append("limit", limit.toString())
        requestOptions.params = requestOptions.params.append("offset", offset.toString())
        return this.http.get<ApiPaginated<T>>(url, requestOptions).pipe(catchError(APIService.handleError))
    }

    create<T = ApiJson>(url: string, data: T): Observable<T> {
        const requestOptions = APIService.prepareRequestOptions()
        const dataStr: any = JSON.stringify(data)
        return this.http.post<T>(url, dataStr, requestOptions).pipe(catchError(APIService.handleError))
    }

    update<T extends {id: number} = ApiJson>(url: string, data: T): Observable<T> {
        const requestOptions = APIService.prepareRequestOptions()
        const dataStr: any = JSON.stringify(data)
        return this.http.put<T>(url + data.id + "/", dataStr, requestOptions).pipe(catchError(APIService.handleError))
    }

    putRaw<T = any>(url: string, data: T, queryParams?: APIQueryParams): Observable<T> {
        const requestOptions = APIService.prepareRequestOptions(queryParams)
        return this.http.put<T>(url, data, requestOptions).pipe(catchError(APIService.handleError))
    }

    delete(id: number, url: string): Observable<void> {
        const requestOptions = APIService.prepareRequestOptions()
        return this.http.delete<void>(url + id + "/", requestOptions).pipe(catchError(APIService.handleError))
    }

    private static prepareRequestOptions(queryParams?: APIQueryParams) {
        let httpParams = queryParams instanceof HttpParams ? queryParams : undefined
        if (!httpParams) {
            httpParams = new HttpParams()
            if (queryParams) {
                for (const [key, val] of Object.entries(queryParams)) {
                    const type = typeof val
                    if (val === undefined) {
                        continue
                    } else if (val === null) {
                        httpParams = httpParams.set(key, "null")
                    } else if (type === "object") {
                        if (Array.isArray(val)) {
                            httpParams = httpParams.set(key, val.join(","))
                        } else {
                            throw Error("Cannot use object as query parameter")
                        }
                    } else if (type === "string" || type === "number" || type === "boolean") {
                        httpParams = httpParams.set(key, val)
                    } else {
                        throw Error(`Invalid type for query param '${key}': ${type}`)
                    }
                }
            }
        }
        return {
            headers: Settings.API_JSON_HEADERS,
            params: httpParams.set("format", "json"),
            reportProgress: true as const,
            responseType: "json" as const,
        }
    }

    static handleError(error: HttpErrorResponse): Observable<never> {
        console.error(error)
        let errorMessage: string = error.message || "An error occurred."
        if (error.error) {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred.
                errorMessage = error.error.message
            } else if (error.error.detail !== undefined) {
                // The backend returned an unsuccessful response code.
                errorMessage = error.error.detail
            }
        }
        return observableThrowError(errorMessage)
    }

    /**********************************************************************************************************
     * Google Cloud Storage
     **********************************************************************************************************/

    getGoogleStorageUploadUrl(queryParams: APIQueryParams): Observable<GoogleStorageUpload> {
        const requestOptions: {headers: HttpHeaders; params: HttpParams} = APIService.prepareRequestOptions(queryParams)
        return this.http.get<GoogleStorageUpload>(Settings.googleStorageUploadUrl, requestOptions).pipe(catchError(APIService.handleError))
    }

    getGoogleStorageURL(gcsBucketName: string, gcsFileName: string, serviceUrl?: string) {
        return `${serviceUrl || Settings.GCS_ENDPOINT}${gcsBucketName}/${gcsFileName}`
    }

    downloadFile(url: string, cacheSetName: string | undefined = undefined): Observable<Uint8Array | null> {
        const pendingCache = cacheSetName ? observableFrom(_caches.open(cacheSetName)) : observableOf(null)
        return pendingCache.pipe(
            switchMap((cache) => {
                if (cache) {
                    return observableFrom(cache.match(url)).pipe(
                        switchMap((response) => {
                            if (response === undefined) {
                                return this.downloadFile(url, undefined).pipe(
                                    map((body) => new Response(body)),
                                    switchMap((response) => observableFrom(cache.put(url, response))),
                                    switchMap(() => observableFrom(cache.match(url))),
                                )
                            } else {
                                return observableOf(response)
                            }
                        }),
                        switchMap((response) => {
                            if (!response) throw Error("Could not add to cache!")
                            return response.arrayBuffer()
                        }),
                        map((buffer) => {
                            if (!buffer) throw Error("Could not get buffer for cached data!")
                            return new Uint8Array(buffer)
                        }),
                    )
                } else {
                    return this.http
                        .get(url, {
                            responseType: "arraybuffer",
                            observe: "events",
                            reportProgress: true,
                        })
                        .pipe(
                            catchError(APIService.handleError),
                            map((event) => {
                                if (event.type === HttpEventType.Response && event.body) {
                                    return {
                                        type: "complete",
                                        value: new Uint8Array(event.body),
                                    } as TaskProgressEvent<Uint8Array>
                                } else if (event.type === HttpEventType.DownloadProgress) {
                                    return {
                                        type: "progress",
                                        current: event.loaded,
                                        total: event.total,
                                    } as TaskProgressEvent<Uint8Array>
                                } else {
                                    return undefined
                                }
                            }),
                            filter(IsDefined),
                            createTaskProgressOperator(`Download ${url}`),
                        )
                }
            }),
        )
    }

    /**********************************************************************************************************
     * Password management
     **********************************************************************************************************/

    setPassword(token: string, newPassword: string): Observable<{email: string}> {
        const requestOptions: {headers: HttpHeaders; params: HttpParams} = APIService.prepareRequestOptions()
        const data = JSON.stringify({
            token: token,
            new_password: newPassword,
        })
        return this.http
            .put<{
                email: string
            }>(Settings.passwordResetUrl, data, requestOptions)
            .pipe(catchError(APIService.handleError))
    }

    updatePassword(userId: number, password: string): Observable<string> {
        const requestOptions: {headers: HttpHeaders} = APIService.prepareRequestOptions()
        return this.http
            .put<string>(Settings.usersUrl + userId + Settings.userPasswordUrlSuffix, {password: password}, requestOptions)
            .pipe(catchError(APIService.handleError))
    }

    sendPasswordResetEmail(email: string): Observable<Response> {
        const requestOptions: {headers: HttpHeaders; params: HttpParams} = APIService.prepareRequestOptions()
        requestOptions.params = requestOptions.params.set("email", email)
        return this.http.get<Response>(Settings.passwordResetUrl, requestOptions).pipe(catchError(APIService.handleError))
    }

    /**********************************************************************************************************
     * AR generation
     **********************************************************************************************************/

    generateAr(templateId: number, configString: string): Observable<string> {
        const requestOptions: {headers: HttpHeaders; params: HttpParams} = APIService.prepareRequestOptions()
        const data = JSON.stringify({
            templateId: templateId,
            configString: btoa(configString),
        })
        return this.http.put<string>(Settings.generateArUrl, data, requestOptions).pipe(catchError(APIService.handleError))
    }
}

// When sending query params, some symbols don't get encoded by the standard parameter code. For example in the MIME type "image/svg+xml", the plus sign gets sent without encoding,
// and Django interprets it as a space.
// https://github.com/angular/angular/issues/18261
export class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key)
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value)
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key)
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value)
    }
}

export class PaginatedResponse<T> {
    count?: number
    previous?: string
    next?: string
    list: T[] = []
}

export class APIEndpoint<
    T extends {
        id: number
        populateFromJson(data: any, allowCaching: boolean): void
        toJson(): any
    },
> {
    constructor(
        readonly api: APIService,
        readonly endpointUrl: string,
        readonly entityClass: {fromJson: (data: any, allowCaching: boolean) => T},
        readonly onlyCacheSingleRequests: boolean,
    ) {}

    get(id: number, queryParams?: APIQueryParams): Observable<T> {
        return this.api.get(id, this.endpointUrl, queryParams).pipe(
            map((data: any) => {
                try {
                    return this.entityClass.fromJson(data, true) as T
                } catch (error) {
                    console.error("Serialization error.\n", error)
                    throw Error("Serialization error.")
                }
            }),
        )
    }

    load(model: T): Observable<T> {
        if (model.id === undefined || model.id === null) {
            throw new Error("The id cannot be undefined or null.")
        }
        return this.api.get(model.id, this.endpointUrl).pipe(
            map((data: any) => {
                try {
                    model.populateFromJson(data, true)
                    return model
                } catch (error) {
                    console.error("Serialization error.\n", error)
                    throw Error("Serialization error.")
                }
            }),
        )
    }

    getAll(queryParams?: APIQueryParams): Observable<T[]> {
        return this.api.getAll(this.endpointUrl, queryParams).pipe(
            map((data: any[]) => {
                const resultList: T[] = []
                try {
                    for (const item of data) {
                        resultList.push(this.entityClass.fromJson(item, !this.onlyCacheSingleRequests) as T)
                    }
                    return resultList
                } catch (error) {
                    console.error("Serialization error.\n", error)
                    throw Error("Serialization error.")
                }
            }),
        )
    }

    getAllPaginatedBaseByOffset(limit: number, offset: number, queryParams?: APIQueryParams): Observable<PaginatedResponse<T>> {
        return this.api.getAllPaginated(this.endpointUrl, limit, offset, queryParams).pipe(
            map((data) => {
                const paginatedResponse: PaginatedResponse<T> = new PaginatedResponse<T>()
                paginatedResponse.count = data.count
                paginatedResponse.previous = data.previous
                paginatedResponse.next = data.next
                try {
                    for (const item of data.results) {
                        paginatedResponse.list.push(this.entityClass.fromJson(item, !this.onlyCacheSingleRequests))
                    }
                    return paginatedResponse
                } catch (error) {
                    console.error("Serialization error.\n", error)
                    throw Error("Serialization error.")
                }
            }),
        )
    }

    getAllPaginatedBaseByPage(pageSize: number, pageNumber: number, queryParams?: APIQueryParams): Observable<PaginatedResponse<T>> {
        const maxCount: number = pageSize
        const offset: number = pageNumber * pageSize
        return this.getAllPaginatedBaseByOffset(maxCount, offset, queryParams)
    }

    create(model: T): Observable<T> {
        if (!(model.id === undefined || model.id === null)) {
            throw new Error("The id cannot be set.")
        }
        return this.api.create(this.endpointUrl, model.toJson()).pipe(
            map((data: any) => {
                try {
                    model.populateFromJson(data, true)
                    return model
                } catch (error) {
                    console.error("Serialization error.\n", error)
                    throw Error("Serialization error.")
                }
            }),
        )
    }

    update(model: T): Observable<T> {
        if (model.id === undefined || model.id === null) {
            throw new Error("The id cannot be undefined or null.")
        }
        return this.api.update(this.endpointUrl, model.toJson()).pipe(
            map((data: any) => {
                try {
                    model.populateFromJson(data, true)
                    return model
                } catch (error) {
                    console.error("Serialization error.\n", error)
                    throw Error("Serialization error.")
                }
            }),
        )
    }

    delete(id: number): Observable<void> {
        if (id === undefined || id === null) {
            throw new Error("The id cannot be undefined or null.")
        }
        return this.api.delete(id, this.endpointUrl)
    }
}
