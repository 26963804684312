import {Component, computed, inject, input, output, signal} from "@angular/core"
import {AuthService} from "@app/common/services/auth/auth.service"
import {constrainTransformTargetableNode} from "@app/template-editor/helpers/transform"
import {TransformMode} from "@app/template-editor/services/scene-manager.service"
import {Matrix4, Quaternion} from "@cm/math"
import {AreaLight, Camera, Object} from "@cm/template-nodes"
import {from3dsMaxTransform, to3dsMaxTransform} from "@cm/template-nodes/utils/3ds-max-utils"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-object-inspector",
    standalone: true,
    templateUrl: "./object-inspector.component.html",
    styleUrl: "./object-inspector.component.scss",
    imports: [InputContainerComponent, NumericInputComponent, InspectorSectionComponent, ToggleComponent],
})
export class ObjectInspectorComponent extends BaseInspectorComponent<Object> {
    transformMode = input.required<TransformMode>()
    TransformMode = TransformMode
    lockedTransform = computed(() => this.parameters().lockedTransform)
    auth = inject(AuthService)
    useMaxCoordinates = signal(true)
    cameraOrAreaLightNode = computed(() => {
        const node = this.node()
        if (node instanceof Camera || node instanceof AreaLight) return node
        else return undefined
    })

    private transformation = computed(() => {
        const lockedTransform = this.lockedTransform()
        if (!lockedTransform) return undefined

        const matrix = new Matrix4(lockedTransform)

        if (!this.useMaxCoordinates()) {
            const {position, quaternion, scale} = matrix.decompose()
            const rotation = quaternion.toEuler()
            return {position, rotation, scale}
        } else {
            return to3dsMaxTransform(matrix, this.cameraOrAreaLightNode() !== undefined)
        }
    })

    transformModeChanged = output<TransformMode>()

    private getTransformValue(key: "x" | "y" | "z", transformMode: TransformMode.Rotate | TransformMode.Translate) {
        const transformation = this.transformation()
        if (!transformation) return 0.0

        const {position, rotation} = transformation
        if (transformMode === TransformMode.Translate) return position[key]
        else if (transformMode === TransformMode.Rotate) return rotation[key] * (180.0 / Math.PI)
        else throw new Error("Unexpected transform mode")
    }

    private setTransformValue(key: "x" | "y" | "z", value: number, transformMode: TransformMode.Rotate | TransformMode.Translate) {
        const transformation = this.transformation()
        if (!transformation) return

        const {position, rotation, scale} = transformation

        const positionCopy = position.copy()
        const rotationCopy = rotation.copy()

        if (transformMode === TransformMode.Translate) positionCopy[key] = value
        else if (transformMode === TransformMode.Rotate) rotationCopy[key] = value * (Math.PI / 180.0)
        else throw new Error("Unexpected transform mode")

        const node = this.node()
        this.sceneManagerService.modifyTemplateGraph(() => {
            const cameraOrAreaLightNode = this.cameraOrAreaLightNode()
            const transform = !this.useMaxCoordinates()
                ? Matrix4.compose(positionCopy, Quaternion.fromEuler(rotationCopy.x, rotationCopy.y, rotationCopy.z), scale)
                : from3dsMaxTransform(positionCopy, rotationCopy, scale, cameraOrAreaLightNode !== undefined)

            if (cameraOrAreaLightNode) {
                const {transform: constrainedTransform, target: constrainedTarget} = constrainTransformTargetableNode(
                    transform,
                    {templateNode: cameraOrAreaLightNode, part: "root"},
                    transformMode,
                )

                if (constrainedTransform) cameraOrAreaLightNode.updateParameters({lockedTransform: constrainedTransform.toArray()})
                if (constrainedTarget) cameraOrAreaLightNode.updateParameters({target: constrainedTarget.toArray()})
            } else {
                node.updateParameters({lockedTransform: transform.toArray()})
            }
        })
    }

    get translateValueX() {
        return this.getTransformValue("x", TransformMode.Translate)
    }

    get translateValueY() {
        return this.getTransformValue("y", TransformMode.Translate)
    }

    get translateValueZ() {
        return this.getTransformValue("z", TransformMode.Translate)
    }

    get rotationValueX() {
        return this.getTransformValue("x", TransformMode.Rotate)
    }

    get rotationValueY() {
        return this.getTransformValue("y", TransformMode.Rotate)
    }

    get rotationValueZ() {
        return this.getTransformValue("z", TransformMode.Rotate)
    }

    set translateValueX(value: number) {
        this.setTransformValue("x", value, TransformMode.Translate)
    }

    set translateValueY(value: number) {
        this.setTransformValue("y", value, TransformMode.Translate)
    }

    set translateValueZ(value: number) {
        this.setTransformValue("z", value, TransformMode.Translate)
    }

    set rotationValueX(value: number) {
        this.setTransformValue("x", value, TransformMode.Rotate)
    }

    set rotationValueY(value: number) {
        this.setTransformValue("y", value, TransformMode.Rotate)
    }

    set rotationValueZ(value: number) {
        this.setTransformValue("z", value, TransformMode.Rotate)
    }
}
