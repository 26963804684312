import {getAll} from "@cm/graph"
import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({normal: materialSlots})
const InputTypeSchema = z.object({
    color: materialSlots.optional(),
    strength: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({color: color.optional(), strength: z.number().optional()})

export class NormalMap extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, strength} = await getAll(inputs, get)

            const colorValue = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0.5, g: 0.5, b: 1.0})
            const strengthValue = strength ?? threeConvert(parameters.strength, threeValueNode) ?? threeValueNode(1)

            return {normal: THREENodes.normalMap(colorValue, THREENodes.vec2(strengthValue, strengthValue))}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
