import {inject, Injectable} from "@angular/core"
import {
    ContentTypeModel,
    DataObjectAssignmentType,
    JsonFileAssignmentType,
    JsonFileType,
    MapsExportTextureRevisionFragment,
    MaterialGraphTextureSetRevisionFragment,
    TextureType,
} from "@api"
import {ImageProcessingNodes, ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {MaterialMapsExporter as Exporter} from "@cm/material-nodes/material-maps-exporter"
import {imageProcessingTask} from "@cm/job-nodes/image-processing"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {MaterialMapsExporter as ExporterTask, materialMapsExporterUpdateExportConfigTask} from "@cm/job-nodes/material-maps-exporter"
import {uploadProcessingThumbnailsTask} from "@cm/job-nodes/upload-processing"
import {Utility} from "@cm/job-nodes/utility"
import {isNodeOfType, isOutputWrapper, MaterialGraphNode, NodeType} from "@cm/material-nodes"
import {ResolvedResource} from "@cm/material-nodes/material-node-graph"
import {SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY} from "@cm/material-nodes/types"
import {graphToJson} from "@cm/utils/graph-json"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {Settings} from "@common/models/settings/settings"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {NameAssetFromSchemaService} from "@common/services/name-asset-from-schema/name-asset-from-schema.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {z} from "zod"

type KeyT = string | number | symbol
type _OmitDeep<T, U extends KeyT> = T extends {[key: KeyT]: unknown}
    ? {[K in keyof T as Exclude<K, U>]: T[K] extends (infer A)[] ? _OmitDeep<A, U>[] : _OmitDeep<T[K], U>}
    : T
type NonNullableDeep<T> = T extends {
    [key: KeyT]: unknown
}
    ? NonNullable<{[K in keyof T]: NonNullableDeep<T[K]>}>
    : NonNullable<T>
type RequiredDeep<T> = T extends {[key: KeyT]: unknown} ? {[K in keyof T]-?: RequiredDeep<T[K]>} : T

type LegacyId = number
type TextureRevisionDetails = NonNullableDeep<RequiredDeep<MapsExportTextureRevisionFragment>>
type TextureSetRevisionDetails = NonNullableDeep<RequiredDeep<MaterialGraphTextureSetRevisionFragment>>
type IdDetails = {id: string} | {legacyId: LegacyId}

const MaterialDetailsSchema = z.object({
    id: z.string(),
    legacyId: z.number(),
    articleId: z.string().nullable().optional(),
    name: z.string(),
    organization: z.object({
        legacyId: z.number(),
    }),
    tagAssignments: z.array(z.object({tag: z.object({name: z.string()})})),
})
type MaterialDetails = z.infer<typeof MaterialDetailsSchema>

const DataObjectAssignmentTypeStringToNumber = {
    [DataObjectAssignmentType.MaterialMapsExport]: 171,
} as const

const supportedImageTransformsNodes = ["ShaderNodeRGBCurve", "ShaderNodeHueSaturation" /*, "ShaderNodeGamma", "ShaderNodeBrightContrast"*/]

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never
const SRGB_TEXTURE_TYPES_FOR_ENGINE: {
    [workflow in Exporter.Workflow]: {[engine in Exporter.Engine]: ArrayElement<(typeof Exporter.TEXTURE_TYPES_FOR_ENGINE)[workflow][engine]>[]}
} = {
    metalnessRoughness: {
        cm: ["diffuse"],
        corona: ["diffuse"],
        vray: ["diffuse"],
        cycles: ["diffuse"],
    },
    specularGlossiness: {
        cm: ["diffuse", "reflect"],
        corona: ["diffuse", "reflect"],
        vray: ["diffuse", "reflect"],
        cycles: ["diffuse", "reflect"],
    },
}

const CM_TO_INCH = 0.393701

// TODO: handle Transmission/Mask/Alpha
type CanonicalTextureTypes = ["diffuse", "normal", "roughness", "specular-strength", "metalness", "anisotropy", "displacement"]
type MapsNodes<TS extends Readonly<Array<string>>, N = Nodes.ImageNode> = {[type in TS[number]]: N}
type InputMapsInfo = {
    [type: string]: {
        data:
            | {
                  type: "DataObject"
                  dataObjectId: number
                  width: number
                  height: number
                  physicalWidth: number
                  physicalHeight: number
                  displacementScale?: number
              }
            | {type: "ShaderNodeRGB"; shaderNodeRGB: MaterialGraphNode<"ShaderNodeRGB">}
        transformations: MaterialGraphNode<NodeType>[]
    }
}
type InputMapsNodes = {[type: string]: Nodes.ImageNode}

const MAPS_EXPORT_CONFIG_SCHEMA = [0, 0, 1] as const
const MAPS_EXPORT_CONFIG_SCHEMA_STR = `(${MAPS_EXPORT_CONFIG_SCHEMA[0]}.${MAPS_EXPORT_CONFIG_SCHEMA[1]}.${MAPS_EXPORT_CONFIG_SCHEMA[2]})` as const

const PLATFORM_TEXTURE_TYPE_TO_CHANNEL_LAYOUT: {[type: string]: TypedImageData["channelLayout"]} = Object.fromEntries([
    [TextureType.Anisotropy, "RGB"],
    [TextureType.AnisotropyRotation, "L"],
    [TextureType.AnisotropyStrength, "L"],
    [TextureType.Diffuse, "RGB"],
    [TextureType.Displacement, "L"],
    [TextureType.F0, "RGB"],
    [TextureType.Metalness, "L"],
    [TextureType.Normal, "RGB"],
    [TextureType.Roughness, "L"],
    [TextureType.SpecularStrength, "L"],
    [TextureType.Mask, "L"],
    [TextureType.Transmission, "RGB"],
])

function mix(a: Nodes.ImageNode, b: Nodes.ImageNode | number, m: Nodes.ImageNode | number): Nodes.Math {
    const b_ = typeof b === "number" ? Nodes.math("constLike", a, b) : b
    const m_ = typeof m === "number" ? Nodes.math("constLike", a, m) : m
    return Nodes.math("-", Nodes.math("*", b_, m), Nodes.math("*", a, Nodes.math("-", m_, 1)))
}

function ansiotropyRotationAndStrengthToVector(rotation: Nodes.ImageNode, strength: Nodes.ImageNode, valZ = 0.5): Nodes.ImageNode {
    const mag = Nodes.math("*", Nodes.math("-", strength, 0.5), 2.0)
    const rot = Nodes.math("*", rotation, 2 * 2 * Math.PI)
    let vecX = Nodes.math("*", Nodes.math("cos", rot), mag)
    let vecY = Nodes.math("*", Nodes.math("sin", rot), Nodes.math("*", mag, -1))
    vecX = Nodes.math("+", Nodes.math("*", vecX, 0.5), 0.5)
    vecY = Nodes.math("+", Nodes.math("*", vecY, 0.5), 0.5)
    const vecZ = Nodes.math("constLike", mag, valZ)

    return Nodes.combineChannels([vecX, vecY, vecZ], "RGB")
}

function anisotropyVectorToRotationAndStrength(anisoVec: Nodes.ImageNode): {
    rotation: Nodes.ImageNode
    strength: Nodes.ImageNode
} {
    const vecX = Nodes.math("*", Nodes.math("-", Nodes.extractChannel(anisoVec, 0), 0.5), 2.0)
    const vecY = Nodes.math("*", Nodes.math("-", Nodes.extractChannel(anisoVec, 1), 0.5), 2.0)
    let rot = Nodes.math("/", Nodes.math("atan2", Nodes.math("*", vecY, -1), vecX), 2 * 2 * Math.PI)

    rot = Nodes.math("+", rot, Nodes.math("*", Nodes.math("<", rot, 0.0), 0.5))
    rot = Nodes.math("+", rot, Nodes.math("*", Nodes.math("<", rot, 0.0), 0.5))
    rot = Nodes.math("-", rot, Nodes.math("*", Nodes.math(">", rot, 0.5), 0.5))
    rot = Nodes.math("-", rot, Nodes.math("*", Nodes.math(">", rot, 0.5), 0.5))
    const strength = Nodes.math("sqrt", Nodes.math("+", Nodes.math("square", vecX), Nodes.math("square", vecY)))

    return {rotation: rot, strength: strength}
}

async function collectSourceAndMapsInfo(
    sdkService: SdkService,
    materialGraphService: MaterialGraphService,
    materialId: {legacyId: LegacyId} | {id: string},
    request: Exporter.SourceInfoRequest,
) {
    const mapsInfo: InputMapsInfo = {}
    let sourceInfo: Exporter.SourceInfo | null = null

    const isValidTextureRevisionDetails = (x: MapsExportTextureRevisionFragment): x is TextureRevisionDetails => {
        return z
            .object({
                texture: z.object({type: z.string()}),
                width: z.number(),
                height: z.number(),
                dataObject: z.object({legacyId: z.number(), width: z.number(), height: z.number()}),
            })
            .safeParse(x).success
    }

    const isValidTextureSetRevisionDetails = (x: MaterialGraphTextureSetRevisionFragment): x is TextureSetRevisionDetails => {
        return z
            .object({
                width: z.number(),
                height: z.number(),
                mapAssignments: z.array(
                    z.object({
                        textureType: z.string(),
                        dataObject: z.object({legacyId: z.number(), width: z.number(), height: z.number()}),
                    }),
                ),
            })
            .safeParse(x).success
    }

    const appendMapInfo = (info: {
        type: TextureType
        dataObjectId: number
        width: number
        height: number
        physicalWidth: number
        physicalHeight: number
        displacementScale?: number
    }) => {
        const textureType = info.type
        if (mapsInfo[textureType] !== undefined) return
        if (PLATFORM_TEXTURE_TYPE_TO_CHANNEL_LAYOUT[textureType] === undefined) return
        mapsInfo[textureType] = {
            data: {
                type: "DataObject",
                dataObjectId: info.dataObjectId,
                width: info.width,
                height: info.height,
                physicalWidth: info.physicalWidth,
                physicalHeight: info.physicalHeight,
                displacementScale: info.displacementScale,
            },
            transformations: [],
        }
    }

    if (request.source === "materialRevision") {
        // Phase 0: Fetch all data at once and set the source info fields
        const fetchedRevisionData = (await sdkService.gql.materialRevisionForMapsExport(materialId)).material.latestCyclesRevision
        if (!fetchedRevisionData) throw Error("Material does not have a cycles revision")

        const revisionData = {
            ...fetchedRevisionData,
            nodes: await Promise.all(
                fetchedRevisionData.nodes.map(({id}) => sdkService.gql.materialNodeForMapsExport({id}).then(({materialNode}) => materialNode)),
            ),
            connections: await Promise.all(
                fetchedRevisionData.connections.map(({id}) =>
                    sdkService.gql.materialConnectionForMapsExport({id}).then(({materialConnection}) => materialConnection),
                ),
            ),
        }

        sourceInfo = {
            type: "sourceInfo",
            source: "materialRevision",
            sourceId: fetchedRevisionData.legacyId,
            textureRevisionInfo: undefined,
        }

        //Phase 1.: Try to get as much as possible by traversing the material graph
        {
            const materialGraph = await materialGraphService.graphFromMaterialRevision(revisionData)

            type BsdfInput = "Anisotropic" | "Anisotropic Rotation" | "Base Color" | "Metallic" | "Normal" | "Roughness" | "Specular" | "Tangent"
            const bsdfTextureMap: [BsdfInput, TextureType][] = [
                ["Base Color", TextureType.Diffuse],
                ["Metallic", TextureType.Metalness],
                ["Specular", TextureType.SpecularStrength],
                ["Roughness", TextureType.Roughness],
            ]

            const findNodes = <T extends NodeType>(nodeType: T, node: MaterialGraphNode): MaterialGraphNode<T>[] => {
                const result = new Set<MaterialGraphNode<T>>()

                const traverse = (node: MaterialGraphNode) => {
                    if (isNodeOfType(node, nodeType)) {
                        if (result.has(node)) return
                        result.add(node)
                    }
                    if (isOutputWrapper(node)) {
                        traverse(node.inputs.node)
                    } else if (node.inputs) {
                        for (const inputKey of Object.keys(node.inputs)) traverse(node.inputs[inputKey])
                    }
                }

                traverse(node)
                return Array.from(result)
            }

            const bsdfNodes = findNodes("BsdfPrincipled", materialGraph.rootNode)
            if (bsdfNodes.length !== 1) throw Error("Material does not have exactly one Principled BSDF node")
            const bsdfNode = bsdfNodes[0]

            const parameterToShaderNodeRGB = (parameter: unknown): MaterialGraphNode<"ShaderNodeRGB"> => {
                if (typeof parameter === "number") {
                    return {
                        nodeType: "ShaderNodeRGB",
                        parameters: {
                            Color: [parameter, parameter, parameter],
                        },
                    }
                } else if (Array.isArray(parameter) && parameter.every((entry) => typeof entry === "number")) {
                    if (parameter.length === 3 || parameter.length === 4) {
                        return {
                            nodeType: "ShaderNodeRGB",
                            parameters: {
                                Color: [parameter[0], parameter[1], parameter[2]],
                            },
                        }
                    } else throw Error(`Parameter ${parameter} not supported`)
                } else throw Error(`Parameter ${parameter} not supported`)
            }

            const collectLinearImageOperatorSequence = (node: MaterialGraphNode) => {
                const linearTransforms: MaterialGraphNode<NodeType>[] = []

                const supportedNodes = [...supportedImageTransformsNodes, "ShaderNodeRGB", "TexImage", "ShaderNodeTextureSet", "ShaderNodeSetTexture"]

                const traverse = (node: MaterialGraphNode) => {
                    if (isOutputWrapper(node)) {
                        traverse(node.inputs.node)
                        return
                    }
                    if (!supportedNodes.includes(node.nodeType)) throw Error(`Node type ${node.nodeType} is not supported`)
                    linearTransforms.push(node)
                    if (node.nodeType === "TexImage" || node.nodeType === "ShaderNodeTextureSet" || node.nodeType === "ShaderNodeSetTexture") return
                    if (node.inputs) {
                        if (Object.keys(node.inputs).length !== 1) throw Error(`Node type ${node.nodeType} has more than one input, the graph is not linear`)
                        for (const inputKey of Object.keys(node.inputs)) traverse(node.inputs[inputKey])
                    } else if (node.nodeType !== "ShaderNodeRGB") {
                        const colorParameter = node.parameters?.["Color"]
                        if (colorParameter !== undefined) linearTransforms.push(parameterToShaderNodeRGB(colorParameter))
                        else throw Error(`Final node type ${node.nodeType} does not have a Color parameter`)
                    }
                }

                traverse(node)
                return linearTransforms.reverse()
            }

            const getBsdfLinearImageOperatorSequence = (bsdfNode: MaterialGraphNode<"BsdfPrincipled">, bsdfInput: BsdfInput) => {
                const inputNode = bsdfNode.inputs?.[bsdfInput]
                if (inputNode === undefined) {
                    const parameter = bsdfNode.parameters?.[bsdfInput]
                    if (parameter !== undefined) return [parameterToShaderNodeRGB(parameter)]
                    else throw Error(`BSDF node does not have input ${bsdfInput}`)
                } else return collectLinearImageOperatorSequence(inputNode)
            }

            const validateResolvedResources = (resolvedResources: ResolvedResource[]) => {
                const parsedResources = z
                    .array(
                        z.object({
                            metadata: z.object({
                                widthCm: z.number(),
                                heightCm: z.number(),
                                displacementCm: z.number().optional(),
                                textureType: z.nativeEnum(TextureType).optional(),
                            }),
                            mainDataObject: z.object({legacyId: z.number(), width: z.number(), height: z.number()}),
                        }),
                    )
                    .safeParse(resolvedResources)
                if (!parsedResources.success) throw Error(`Invalid image resources`)
                return parsedResources.data
            }

            for (const [bsdfInput, textureType] of bsdfTextureMap) {
                try {
                    const bsdfLinearImageOperatorSequence = getBsdfLinearImageOperatorSequence(bsdfNode, bsdfInput)
                    if (bsdfLinearImageOperatorSequence.length === 0) throw Error(`BSDF input ${bsdfInput} does not have a linear image operator sequence`)
                    const initialNode = bsdfLinearImageOperatorSequence[0]
                    const remainingNodes = bsdfLinearImageOperatorSequence.slice(1)
                    if (remainingNodes.find((node) => node.nodeType === "TexImage" || node.nodeType === "ShaderNodeRGB") !== undefined)
                        throw Error(`BSDF input ${bsdfInput} sequence contains a TexImage or ShaderNodeRGB node after the initial node`)

                    if (isNodeOfType(initialNode, "TexImage")) {
                        const {resolvedResources} = initialNode
                        if (resolvedResources === undefined) throw Error(`TexImage node does not have an image resource`)
                        const parsedResources = validateResolvedResources(resolvedResources)
                        if (parsedResources.length !== 1) throw Error(`TexImage node does not have exactly one image resource`)
                        mapsInfo[textureType] = {
                            data: {
                                type: "DataObject",
                                dataObjectId: parsedResources[0].mainDataObject.legacyId,
                                width: parsedResources[0].mainDataObject.width,
                                height: parsedResources[0].mainDataObject.height,
                                physicalWidth: parsedResources[0].metadata.widthCm,
                                physicalHeight: parsedResources[0].metadata.heightCm,
                                displacementScale: parsedResources[0].metadata.displacementCm,
                            },
                            transformations: remainingNodes,
                        }
                    } else if (isNodeOfType(initialNode, "ShaderNodeTextureSet") || isNodeOfType(initialNode, "ShaderNodeSetTexture")) {
                        const {resolvedResources} = initialNode
                        if (resolvedResources === undefined) throw Error(`TexImage node does not have an image resource`)
                        const parsedResources = validateResolvedResources(resolvedResources)
                        const assignmentTextureType = isNodeOfType(initialNode, "ShaderNodeTextureSet")
                            ? textureType
                            : initialNode.parameters?.[SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY]
                        if (typeof assignmentTextureType !== "string")
                            throw Error(`Invalid texture type parameter for texture set ${textureType}: ${assignmentTextureType} (expected string)`)
                        const resourceForTextureType = parsedResources.find((resource) => resource.metadata.textureType === assignmentTextureType)
                        if (!resourceForTextureType) throw Error(`Missing resource for texture type ${textureType}`)
                        mapsInfo[textureType] = {
                            data: {
                                type: "DataObject",
                                dataObjectId: resourceForTextureType.mainDataObject.legacyId,
                                width: resourceForTextureType.mainDataObject.width,
                                height: resourceForTextureType.mainDataObject.height,
                                physicalWidth: resourceForTextureType.metadata.widthCm,
                                physicalHeight: resourceForTextureType.metadata.heightCm,
                                displacementScale: resourceForTextureType.metadata.displacementCm,
                            },
                            transformations: remainingNodes,
                        }
                    } else if (isNodeOfType(initialNode, "ShaderNodeRGB")) {
                        mapsInfo[textureType] = {
                            data: {type: "ShaderNodeRGB", shaderNodeRGB: initialNode},
                            transformations: remainingNodes,
                        }
                    } else throw Error(`BSDF input ${bsdfInput} sequence does not start with a TexImage or ShaderNodeRGB node`)
                } catch (error) {
                    console.log(`Warning: Could not traverse material graph for slot ${bsdfInput}. Error: ${error}`)
                }
            }
        }

        //Phase 2.: Fill potential information from untraversed nodes via texture revisions and texture set revision
        {
            const mapInfoForTexImageNodes = revisionData.nodes
                .filter((node) => node.name === "TexImage")
                .map((node) => {
                    if (!node.textureRevision) throw Error("Missing texture revision for texImage node")
                    const revision = node.textureRevision
                    if (!isValidTextureRevisionDetails(revision)) throw Error("Invalid texture revision details")
                    return {
                        type: revision.texture.type,
                        dataObjectId: revision.dataObject.legacyId,
                        width: revision.dataObject.width,
                        height: revision.dataObject.height,
                        physicalWidth: revision.width,
                        physicalHeight: revision.height,
                        textureRevisionId: revision.legacyId,
                        displacementScale: revision.displacement,
                    }
                })

            const mapInfoForTextureSetNodes = revisionData.nodes
                .filter((node) => node.name === "ShaderNodeTextureSet")
                .map((node) => {
                    if (!node.textureSetRevision) throw Error("Missing texture set revision for ShaderNodeTextureSet node")
                    const revision = node.textureSetRevision
                    if (!isValidTextureSetRevisionDetails(revision)) throw Error("Invalid texture set revision details")
                    return revision.mapAssignments.map((assignment) => {
                        return {
                            type: assignment.textureType,
                            dataObjectId: assignment.dataObject.legacyId,
                            width: assignment.dataObject.width!,
                            height: assignment.dataObject.height!,
                            physicalWidth: revision.width,
                            physicalHeight: revision.height,
                            displacementScale: revision.displacement,
                        }
                    })
                })
                .reduce((acc, cur) => [...acc, ...cur], [])

            const mapInfoForSetTextureNodes = revisionData.nodes
                .filter((node) => node.name === "ShaderNodeSetTexture")
                .map((node) => {
                    if (!node.textureSetRevision) throw Error("Missing texture set revision for ShaderNodeSetTexture node")
                    const revision = node.textureSetRevision
                    if (!isValidTextureSetRevisionDetails(revision)) throw Error("Invalid texture set revision details")
                    const textureTypeParameter = node.parameters.find(
                        (parameter: {name: string; value: number}) => parameter.name === SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY,
                    )
                    const parsedTextureTypeParameter = z
                        .object({
                            value: z.string(),
                            type: z.literal("string"),
                        })
                        .safeParse(textureTypeParameter)
                    if (!parsedTextureTypeParameter.success)
                        throw Error(`Invalid texture type parameter for ShaderNodeSetTexture node: ${textureTypeParameter}`)
                    const assignment = revision.mapAssignments.find((assignment) => assignment.textureType === parsedTextureTypeParameter.data.value)
                    if (!assignment) throw Error(`Missing assignment for texture type ${parsedTextureTypeParameter.data.value}`)
                    return {
                        type: assignment.textureType,
                        dataObjectId: assignment.dataObject.legacyId,
                        width: assignment.dataObject.width!,
                        height: assignment.dataObject.height!,
                        physicalWidth: revision.width,
                        physicalHeight: revision.height,
                        displacementScale: revision.displacement,
                    }
                })

            ;[...mapInfoForTexImageNodes, ...mapInfoForTextureSetNodes, ...mapInfoForSetTextureNodes].forEach((info) => appendMapInfo(info))

            //Heuristic to grab the displacement texture from the normal map if it is not explicitly provided in the material revision
            await (async () => {
                if (mapsInfo[TextureType.Displacement] !== undefined) return
                const mapInfoForNormalMap = mapInfoForTexImageNodes.find((info) => info.type === TextureType.Normal)
                if (!mapInfoForNormalMap) return

                const normalToDisplacementFetchedData = await sdkService.gql.NormalToDisplacementStepTextureRevision({
                    legacyId: mapInfoForNormalMap.textureRevisionId,
                })
                const displacementTextures =
                    normalToDisplacementFetchedData.textureRevision.texture?.textureSet.textures.filter(
                        (texture) => texture.type === TextureType.Displacement,
                    ) ?? []
                if (displacementTextures.length !== 1) return

                const matchingDisplacementTextureRevision = displacementTextures[0].revisions.find((displacementRevision) => {
                    return (
                        displacementRevision.createdByVersion === normalToDisplacementFetchedData.textureRevision.createdByVersion &&
                        displacementRevision.width === mapInfoForNormalMap.physicalWidth &&
                        displacementRevision.height === mapInfoForNormalMap.physicalHeight
                    )
                })
                if (matchingDisplacementTextureRevision && isValidTextureRevisionDetails(matchingDisplacementTextureRevision))
                    appendMapInfo({
                        type: matchingDisplacementTextureRevision.texture.type,
                        dataObjectId: matchingDisplacementTextureRevision.dataObject.legacyId,
                        width: matchingDisplacementTextureRevision.dataObject.width,
                        height: matchingDisplacementTextureRevision.dataObject.height,
                        physicalWidth: matchingDisplacementTextureRevision.width,
                        physicalHeight: matchingDisplacementTextureRevision.height,
                        displacementScale: matchingDisplacementTextureRevision.displacement,
                    })
            })()
        }
    } else {
        throw Error("Using texture set as source for maps export no longer supported")
    }

    return {mapsInfo, sourceInfo}
}

type ImageMapInfo = {
    width: number
    height: number
    physicalWidth: number
    physicalHeight: number
    displacementScale?: number
    textureType: string
}

function getImageMapInfos(mapsInfo: InputMapsInfo) {
    const imageMapsInfo: ImageMapInfo[] = []
    for (const textureType of Object.keys(mapsInfo)) {
        const info = mapsInfo[textureType]
        if (info.data.type === "DataObject") {
            const {width, height, physicalWidth, physicalHeight, displacementScale} = info.data
            imageMapsInfo.push({width, height, physicalWidth, physicalHeight, textureType, displacementScale})
        }
    }
    return imageMapsInfo
}

function estimateCommonSize(imageMapsInfo: ImageMapInfo[], resolution: Exporter.Resolution) {
    let majoritySize: [number, number] | undefined = undefined
    let majorityCount = 0
    const sizeCount = new Map<string, number>()

    for (const imageMapInfo of imageMapsInfo) {
        const sizeStr = `${imageMapInfo.width}-${imageMapInfo.height}`
        let count = sizeCount.get(sizeStr)
        count = typeof count === "undefined" ? 1 : count + 1
        sizeCount.set(sizeStr, count)
        if (count > majorityCount) {
            majorityCount = count
            majoritySize = [imageMapInfo.width, imageMapInfo.height]
        }
    }

    const avgPhyiscalSize = (() => {
        const retValue = imageMapsInfo.reduce<[number, number]>(
            (acc, cur) => [acc[0] + cur.physicalWidth / Object.keys(imageMapsInfo).length, acc[1] + cur.physicalHeight / Object.keys(imageMapsInfo).length],
            [0, 0],
        )

        for (const imageMapInfo of imageMapsInfo) {
            if (Math.abs(imageMapInfo.physicalWidth - retValue[0]) > 0.1 || Math.abs(imageMapInfo.physicalHeight - retValue[1]) > 0.1) {
                console.log(`Warning: Inhomogeneous physical sizes detected for export, DPI information will not be set`)
                return undefined
            }
        }

        return retValue
    })()

    const targetDpi = (() => {
        switch (resolution) {
            case "original":
                return undefined
            case "dpi72":
                return 72
        }
    })()

    const retValue = ((): {
        resX: number
        resY: number
        dpi?: number
    } => {
        if (majoritySize) {
            if (avgPhyiscalSize === undefined) return {resX: majoritySize[0], resY: majoritySize[1], dpi: undefined}

            const getCurrentDpi = (majoritySize: [number, number]) => {
                const dpiX = majoritySize[0] / (avgPhyiscalSize[0] * CM_TO_INCH)
                const dpiY = majoritySize[1] / (avgPhyiscalSize[1] * CM_TO_INCH)
                const dpi = (dpiX + dpiY) / 2
                return dpi
            }

            if (targetDpi) {
                const currentDpi = getCurrentDpi(majoritySize)
                const scale = targetDpi / currentDpi
                majoritySize = [Math.round(majoritySize[0] * scale), Math.round(majoritySize[1] * scale)]
            }

            return {
                resX: majoritySize[0],
                resY: majoritySize[1],
                dpi: targetDpi !== undefined ? targetDpi : getCurrentDpi(majoritySize),
            }
        } else if (targetDpi) throw Error(`No majority size found, cannot scale to target DPI ${targetDpi}`)
        else return {resX: 256, resY: 256}
    })()

    return retValue
}

function ensureCommonSize(mapsInfo: InputMapsInfo, mapsNodes: InputMapsNodes, resolution: Exporter.Resolution) {
    const imageMapsInfo = getImageMapInfos(mapsInfo)
    const retValue = estimateCommonSize(imageMapsInfo, resolution)
    const {resX, resY} = retValue

    for (const imageMapInfo of imageMapsInfo) {
        if (resX !== imageMapInfo.width || resY !== imageMapInfo.height)
            mapsNodes[imageMapInfo.textureType] = Nodes.resize(mapsNodes[imageMapInfo.textureType], resX, resY)
    }

    for (const type of Object.keys(mapsInfo)) {
        const mapInfo = mapsInfo[type]
        if (mapInfo.data.type === "ShaderNodeRGB") {
            mapsNodes[type] = Nodes.resize(mapsNodes[type], resX, resY)
        }
    }

    return retValue
}

function convertNormal(normal: Nodes.ImageNode, option: Exporter.NormalY, def: Exclude<Exporter.NormalY, "default">): Nodes.ImageNode {
    const normalY = option === "default" || option === undefined ? def : option

    switch (normalY) {
        case "y+down":
            return normal
        case "y+up": {
            const chX = Nodes.extractChannel(normal, 0)
            const chY = Nodes.math("+", Nodes.math("*", Nodes.extractChannel(normal, 1), -1), 1)
            const chZ = Nodes.extractChannel(normal, 2)
            return Nodes.combineChannels([chX, chY, chZ], "RGB")
        }
    }
}

function convertToCanonicalMaps(mapsNodes: InputMapsNodes, fallbackResX: number, fallbackResY: number): MapsNodes<CanonicalTextureTypes> {
    function getMapNode(type: string, fallbackColor: Nodes.RGBColor | number, colorSpace: TypedImageData["colorSpace"]): Nodes.ImageNode {
        if (mapsNodes[type]) return mapsNodes[type]
        console.log(`Generating fallback map for ${type} with resolution ${fallbackResX}x${fallbackResY}, color ${fallbackColor} and ${colorSpace} color space`)
        return Nodes.createImage(fallbackResX, fallbackResY, "float32", colorSpace, fallbackColor)
    }

    function getSpecularAndMetalness(mapsNodes: InputMapsNodes): {
        specular: Nodes.ImageNode
        metalness: Nodes.ImageNode
    } {
        const types = Object.keys(mapsNodes)
        if (types.includes(TextureType.SpecularStrength) && types.includes(TextureType.Metalness)) {
            return {specular: mapsNodes[TextureType.SpecularStrength], metalness: mapsNodes[TextureType.Metalness]}
        } else if (types.includes(TextureType.F0)) {
            const f0 = mapsNodes[TextureType.F0]
            return {specular: Nodes.extractChannel(f0, 0), metalness: Nodes.extractChannel(f0, 1)}
        } else {
            return {
                specular: getMapNode(TextureType.SpecularStrength, 0.04, "linear"),
                metalness: getMapNode(TextureType.Metalness, 0, "linear"),
            }
        }
    }

    function getVectorAnisotropy(mapsNodes: InputMapsNodes): Nodes.ImageNode {
        const types = Object.keys(mapsNodes)
        if (types.includes(TextureType.AnisotropyRotation) && types.includes(TextureType.AnisotropyStrength)) {
            return ansiotropyRotationAndStrengthToVector(mapsNodes[TextureType.AnisotropyRotation], mapsNodes[TextureType.AnisotropyStrength])
        } else if (types.includes(TextureType.Anisotropy)) {
            return mapsNodes[TextureType.Anisotropy]
        } else {
            return getMapNode(TextureType.Anisotropy, [0.5, 0.5, 0], "linear")
        }
    }

    const {specular, metalness} = getSpecularAndMetalness(mapsNodes)
    const anisotropy = getVectorAnisotropy(mapsNodes)

    return {
        diffuse: getMapNode(TextureType.Diffuse, [0, 0, 0], "linear"),
        normal: getMapNode(TextureType.Normal, [0.5, 0.5, 1.0], "linear"),
        roughness: getMapNode(TextureType.Roughness, 1, "linear"),
        "specular-strength": specular,
        metalness,
        anisotropy,
        displacement: getMapNode(TextureType.Displacement, 0, "linear"),
        //TODO: mask/transmission/alpha
    }
}

function encodeConvertedNode(
    imageNode: Nodes.ImageNode,
    format: Exporter.Format,
    dpi: number | undefined,
    colorData = false,
    singleChannel = true,
): Nodes.Encode {
    let dataType: Nodes.Convert["dataType"]
    let options: {[key: string]: unknown} = {}
    let forceLinear: boolean

    if (format === "exr") {
        dataType = "float32"
        options = {bitDepth: 16}
        forceLinear = true
    } else if (format === "tiff") {
        dataType = "uint8"
        forceLinear = false
    } else if (format === "png") {
        dataType = "uint8"
        forceLinear = false
    } else if (format === "jpeg") {
        dataType = "uint8"
        forceLinear = false
    } else {
        throw Error(`Unsupported format: ${format}`)
    }

    const sRGB = colorData && !forceLinear
    let output: Nodes.ImageNode
    if (singleChannel) {
        output = Nodes.convert(imageNode, dataType, "L", sRGB)
    } else {
        output = Nodes.combineChannels(
            [
                Nodes.convert(Nodes.extractChannel(imageNode, 0), dataType, "L", sRGB),
                Nodes.convert(Nodes.extractChannel(imageNode, 1), dataType, "L", sRGB),
                Nodes.convert(Nodes.extractChannel(imageNode, 2), dataType, "L", sRGB),
            ],
            "RGB",
        )
    }

    return Nodes.encode(Nodes.setDpi(output, dpi), Exporter.IMG_FORMAT_TO_ENCODER_FN[format], options)
}

type EncodedConvertedNode = ReturnType<typeof encodeConvertedNode>

function getMetalnessRoughnessMaps(canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>, config: Exporter.ConversionConfig, dpi: number | undefined) {
    const diffuse = canonicalMapsNodes["diffuse"]
    const metalness = Nodes.math("clip01", canonicalMapsNodes["metalness"])
    const roughness = Nodes.math("clip01", canonicalMapsNodes["roughness"])
    const specular = Nodes.math("clip01", canonicalMapsNodes["specular-strength"])

    return {
        diffuse: encodeConvertedNode(diffuse, config.format, dpi, true, false),
        specular: encodeConvertedNode(specular, config.format, dpi),
        metalness: encodeConvertedNode(metalness, config.format, dpi),
        roughness: encodeConvertedNode(roughness, config.format, dpi),
    }
}

function getSpecularGlossinessMaps(canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>, config: Exporter.ConversionConfig, dpi: number | undefined) {
    const specular = Nodes.math("clip01", canonicalMapsNodes["specular-strength"])
    const metalness = Nodes.math("clip01", canonicalMapsNodes["metalness"])
    const ones = Nodes.math("constLike", specular, 1.0)

    const specular_ms = Nodes.math("*", Nodes.math("max", specular, 0), 0.08)
    const f0_scalar = Nodes.math("min", Nodes.math("max", mix(specular_ms, ones, metalness), 1e-6), 1)
    const f0_s_ = Nodes.math("sqrt", f0_scalar)
    const ior = Nodes.math("/", Nodes.math("-", ones, f0_s_), Nodes.math("+", ones, f0_s_))

    const baseColor = canonicalMapsNodes["diffuse"]
    const diffuse = mix(baseColor, 0, metalness)
    const reflect = Nodes.math("/", mix(specular_ms, baseColor, metalness), f0_scalar)
    const gloss = Nodes.math("-", ones, canonicalMapsNodes["roughness"])

    return {
        diffuse: encodeConvertedNode(diffuse, config.format, dpi, true, false),
        reflect: encodeConvertedNode(reflect, config.format, dpi, true, false),
        gloss: encodeConvertedNode(gloss, config.format, dpi),
        "fresnel-ior": encodeConvertedNode(ior, config.format, dpi),
    }
}

function getWorkflowMaps(canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>, config: Exporter.ConversionConfig, dpi: number | undefined) {
    if (config.workflow === "metalnessRoughness") return getMetalnessRoughnessMaps(canonicalMapsNodes, config, dpi)
    else if (config.workflow === "specularGlossiness") return getSpecularGlossinessMaps(canonicalMapsNodes, config, dpi)
    else throw Error(`Unsupported workflow: ${config.workflow}`)
}

type ConvertResult<E extends Exporter.Engine> =
    | MapsNodes<(typeof Exporter.TEXTURE_TYPES_FOR_ENGINE)["metalnessRoughness"][E], EncodedConvertedNode>
    | MapsNodes<(typeof Exporter.TEXTURE_TYPES_FOR_ENGINE)["specularGlossiness"][E], EncodedConvertedNode>

function convertToCoronaMaps(
    canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>,
    config: Exporter.ConversionConfig,
    dpi: number | undefined,
): ConvertResult<"corona"> {
    const vector = anisotropyVectorToRotationAndStrength(canonicalMapsNodes["anisotropy"])
    vector.strength = Nodes.math("+", Nodes.math("*", vector.strength, 0.5), 0.5) //Map to 0.5..1.0 range

    const normal = convertNormal(canonicalMapsNodes["normal"], config.normalY, "y+down")

    return {
        ...getWorkflowMaps(canonicalMapsNodes, config, dpi),
        normal: encodeConvertedNode(normal, config.format, dpi, false, false),
        "anisotropy-strength": encodeConvertedNode(vector.strength, config.format, dpi),
        "anisotropy-rotation": encodeConvertedNode(vector.rotation, config.format, dpi),
        displacement: encodeConvertedNode(canonicalMapsNodes["displacement"], config.format, dpi, false, true),
        //TODO: mask/transmission/alpha
    }
}

function convertToVrayMaps(
    canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>,
    config: Exporter.ConversionConfig,
    dpi: number | undefined,
): ConvertResult<"vray"> {
    const {rotation, strength} = anisotropyVectorToRotationAndStrength(canonicalMapsNodes["anisotropy"])

    const normal = convertNormal(canonicalMapsNodes["normal"], config.normalY, "y+down")

    return {
        ...getWorkflowMaps(canonicalMapsNodes, config, dpi),
        normal: encodeConvertedNode(normal, config.format, dpi, false, false),
        "anisotropy-strength": encodeConvertedNode(strength, config.format, dpi),
        "anisotropy-rotation": encodeConvertedNode(rotation, config.format, dpi),
        displacement: encodeConvertedNode(canonicalMapsNodes["displacement"], config.format, dpi, false, true),
        //TODO: mask/transmission/alpha
    }
}

function convertToCyclesMaps(
    canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>,
    config: Exporter.ConversionConfig,
    dpi: number | undefined,
): ConvertResult<"cycles"> {
    const vector = anisotropyVectorToRotationAndStrength(canonicalMapsNodes["anisotropy"])
    vector.rotation = Nodes.math("+", Nodes.math("*", vector.rotation, -1), 1) // CCW rotation

    const normal = convertNormal(canonicalMapsNodes["normal"], config.normalY, "y+up")

    return {
        ...getWorkflowMaps(canonicalMapsNodes, config, dpi),
        normal: encodeConvertedNode(normal, config.format, dpi, false, false),
        "anisotropy-strength": encodeConvertedNode(vector.strength, config.format, dpi),
        "anisotropy-rotation": encodeConvertedNode(vector.rotation, config.format, dpi),
        displacement: encodeConvertedNode(canonicalMapsNodes["displacement"], config.format, dpi, false, true),
        //TODO: mask/transmission/alpha
    }
}

function convertToCmMaps(
    canonicalMapsNodes: MapsNodes<CanonicalTextureTypes>,
    config: Exporter.ConversionConfig,
    dpi: number | undefined,
): ConvertResult<"cm"> {
    const normal = convertNormal(canonicalMapsNodes["normal"], config.normalY, "y+down")

    return {
        ...getWorkflowMaps(canonicalMapsNodes, config, dpi),
        normal: encodeConvertedNode(normal, config.format, dpi, false, false),
        anisotropy: encodeConvertedNode(canonicalMapsNodes["anisotropy"], config.format, dpi, false, false),
        displacement: encodeConvertedNode(canonicalMapsNodes["displacement"], config.format, dpi, false, true),
        //TODO: mask/transmission/alpha
    }
}

function imageProcessingGraphForConversionConfig(
    config: Exporter.ConversionConfig,
    mapsInfo: InputMapsInfo,
): Nodes.Struct<Record<string, EncodedConvertedNode>> {
    const mapsNodes: InputMapsNodes = {}

    for (const type of Object.keys(mapsInfo)) {
        const mapInfo = mapsInfo[type]
        let image = ((): Nodes.ImageNode => {
            if (mapInfo.data.type === "ShaderNodeRGB") {
                const color = mapInfo.data.shaderNodeRGB.parameters?.["Color"]
                if (!Array.isArray(color) || color.length !== 3) throw Error(`Unsupported color parameter: ${color}`)
                return Nodes.createImage(1, 1, "float32", "linear", color as unknown as Nodes.RGBColor)
            } else {
                const dataObjRef = JobNodes.dataObjectReference(mapInfo.data.dataObjectId)
                const decodedImage = Nodes.decode(Nodes.externalData(dataObjRef, "encodedData"))
                return decodedImage
            }
        })()

        image = mapInfo.transformations.reduce((acc, transformation) => {
            if (!supportedImageTransformsNodes.includes(transformation.nodeType)) throw Error(`Unsupported image transformationxxx: ${transformation.nodeType}`)
            if (transformation.nodeType === "ShaderNodeRGBCurve") {
                if (transformation.parameters === undefined) throw Error(`RGBCurve node has no parameters`)
                const {Fac, ...curveParameters} = transformation.parameters
                if (typeof Fac !== "number") throw Error(`RGBCurve node has no valid Fac parameter`)

                const toneMap: ImageProcessingNodes.ToneMap = {
                    type: "toneMap",
                    input: acc,
                    mode: "rgbCurve",
                    parameters: {fac: Fac, ...curveParameters},
                }
                return toneMap
            } else if (transformation.nodeType === "ShaderNodeHueSaturation") {
                if (transformation.parameters === undefined) throw Error(`HueSaturation node has no parameters`)
                const {Fac, Hue, Saturation, Value} = transformation.parameters
                if (typeof Fac !== "number") throw Error(`HueSaturation node has no valid Fac parameter`)
                if (typeof Hue !== "number") throw Error(`HueSaturation node has no valid Hue parameter`)
                if (typeof Saturation !== "number") throw Error(`HueSaturation node has no valid Saturation parameter`)
                if (typeof Value !== "number") throw Error(`HueSaturation node has no valid Value parameter`)

                const toneMap: ImageProcessingNodes.ToneMap = {
                    type: "toneMap",
                    input: acc,
                    mode: "hueSaturation",
                    parameters: {fac: Fac, hue: Hue, saturation: Saturation, value: Value},
                }
                return toneMap
            } else throw Error(`Image transformation not yet supported: ${transformation.nodeType}`)
        }, image)

        //All potential further computations on the maps should be done in floating linear color space
        image = Nodes.convert(image, "float32", "RGBA", false)

        const channelLayout = PLATFORM_TEXTURE_TYPE_TO_CHANNEL_LAYOUT[type]
        // Some of the manually modified maps are not necessarily stored with L/RGB layout.
        // Explicitly convert image data into format with the expected layout to avoid disambiguity
        if (channelLayout === "L") {
            mapsNodes[type] = Nodes.extractChannel(image, 0)
        } else if (channelLayout === "RGB") {
            mapsNodes[type] = Nodes.combineChannels([Nodes.extractChannel(image, 0), Nodes.extractChannel(image, 1), Nodes.extractChannel(image, 2)], "RGB")
        } else {
            throw Error(`Unsupported channel layout for texture type: ${type}`)
        }
    }

    const {resX, resY, dpi} = ensureCommonSize(mapsInfo, mapsNodes, config.resolution)

    const canonicalMapsNodes = convertToCanonicalMaps(mapsNodes, resX, resY)

    let conversionFn: typeof convertToCoronaMaps | typeof convertToVrayMaps | typeof convertToCyclesMaps | typeof convertToCmMaps
    switch (config.engine) {
        case "corona":
            conversionFn = convertToCoronaMaps
            break
        case "vray":
            conversionFn = convertToVrayMaps
            break
        case "cycles":
            conversionFn = convertToCyclesMaps
            break
        case "cm":
            conversionFn = convertToCmMaps
            break
        default:
            throw Error(`Unsupported engine: ${config.engine}`)
    }

    return Nodes.struct(conversionFn(canonicalMapsNodes, config, dpi))
}

function anisotropyDescriptionForEngine(engine: Exporter.Engine):
    | {
          "Anisotropy Strength": string
          "Anisotropy Rotation": string
      }
    | {
          Anisotropy: string
      } {
    switch (engine) {
        case "cm":
            return {Anisotropy: "As one map"}
        case "corona":
            return {"Anisotropy Strength": "0.5 ... 1.0", "Anisotropy Rotation": "Clockwise"}
        case "vray":
            return {"Anisotropy Strength": "0 ... 1.0", "Anisotropy Rotation": "Clockwise"}
        case "cycles":
            return {"Anisotropy Strength": "0 ... 1.0", "Anisotropy Rotation": "Counter-clockwise"}
        default:
            throw Error(`Unsupported engine: ${engine}`)
    }
}

function generateConversionInfoForConversionInfoRequest(
    conversionRequest: Exporter.ConversionRequest,
    materialDetails: MaterialDetails,
    mapsInfo: InputMapsInfo,
) {
    const imageMapsInfo = getImageMapInfos(mapsInfo)

    const productName = materialDetails.tagAssignments[0]?.tag.name ?? ""
    const physicalHeight = imageMapsInfo.length > 0 ? imageMapsInfo[0].physicalHeight.toFixed(2) : "?"
    const physicalWidth = imageMapsInfo.length > 0 ? imageMapsInfo[0].physicalWidth.toFixed(2) : "?"
    const displacementScale = imageMapsInfo.find((info) => info.textureType === TextureType.Displacement)?.displacementScale?.toFixed(2)

    const {resX: width, resY: height, dpi: estimatedDpi} = estimateCommonSize(imageMapsInfo, conversionRequest.resolution)
    const dpi = estimatedDpi?.toFixed(0) ?? "?"
    const resolution = conversionRequest.resolution === "dpi72" ? "low" : "original"

    const entries: [string, string][] = []
    entries.push(["Product name", productName])
    // entries.push(["Material name", `${materialDetails.name}`])
    entries.push(["Article ID", `${materialDetails.articleId ?? "None"}`])
    entries.push(["Exported for workflow", `${Exporter.workflowToString(conversionRequest.workflow)}`])
    entries.push(["Resolution", `${dpi} DPI (${resolution})`])
    entries.push(["Width", `${physicalWidth} cm (${width} px)`])
    entries.push(["Height", `${physicalHeight} cm (${height} px)`])
    // entries.push(["Exported for render engine", `${Exporter.engineToString(conversionRequest.engine)}`])
    entries.push(["Normal map orientation", `${Exporter.normalYToString(conversionRequest.normalY)}`])
    if (displacementScale !== undefined) entries.push(["Displacement scale", `${displacementScale} cm`])
    Object.entries(anisotropyDescriptionForEngine(conversionRequest.engine)).map(([key, value]) => entries.push([key, value]))
    // entries.push(["colormass Material ID", `${materialDetails.legacyId}`])

    return entries
}

async function exportConfigForExportRequest(
    request: Exporter.Request,
    materialDetails: MaterialDetails,
    sourceInfo: Exporter.SourceInfo,
    mapsInfo: InputMapsInfo,
    generateConvertedMapFilename: (
        materialDetails: MaterialDetails,
        conversionRequest: Exporter.ConversionRequest,
        mapType: Exporter.TextureType,
    ) => Promise<string | null>,
    generateConversionInfoFilename: (materialDetails: MaterialDetails, conversionInfoRequest: Exporter.ConversionInfoRequest) => Promise<string | null>,
): Promise<Exporter.Config> {
    const root: Exporter.Folder = {
        type: "exportFolder",
        content: [],
        name: request.root.name,
    }

    const traverse = async (requestFolder: Exporter.Request["root"], configFolder: Exporter.Config["root"]) => {
        for (const item of requestFolder.content) {
            if (item.type === "exportFolder") {
                const newFolder: Exporter.Config["root"] = {
                    type: "exportFolder",
                    name: item.name,
                    content: [],
                }
                configFolder.content.push(newFolder)
                await traverse(item, newFolder)
            } else if (item.type === "conversionRequest") {
                const format = item.format
                const mapsFilenames = Exporter.TEXTURE_TYPES_FOR_ENGINE[item.workflow][item.engine]
                configFolder.content.push({
                    ...item,
                    type: "conversionConfig",
                    maps: await Promise.all(
                        mapsFilenames.map(async (fn) => {
                            const filename =
                                (await generateConvertedMapFilename(materialDetails, item, fn)) ??
                                generateDefaultConvertedMapFilename(materialDetails, fn, format)
                            return {type: "convertedMap", name: fn, filename}
                        }),
                    ),
                })
            } else if (item.type === "conversionInfoRequest") {
                const infoContent = generateConversionInfoForConversionInfoRequest(item.conversionRequest, materialDetails, mapsInfo)
                if (item.format === "json")
                    configFolder.content.push({
                        type: "exportJsonFile",
                        name: (await generateConversionInfoFilename(materialDetails, item)) ?? `${item.name}.json`,
                        content: Object.fromEntries(infoContent),
                    })
                else if (item.format === "text") {
                    configFolder.content.push({
                        type: "exportTextFile",
                        name: (await generateConversionInfoFilename(materialDetails, item)) ?? `${item.name}.txt`,
                        content: infoContent.map((entry) => `${entry[0]}: ${entry[1]}`).join("\n"),
                    })
                } else throw Error(`Unknown format in conversion info request: ${item.format}`)
            } else {
                throw Error(`Unknown type in export request to config transform: ${item["type"]}`)
            }
        }
    }

    await traverse(request.root, root)
    return {
        type: "exportConfig",
        schema: MAPS_EXPORT_CONFIG_SCHEMA_STR,
        materialId: materialDetails.legacyId,
        sourceInfo: sourceInfo,
        state: "processing",
        root: root,
    }
}

function jobGraphFnForExportConfig(config: Exporter.Config, mapsInfo: InputMapsInfo, materialDetails: MaterialDetails, zipFilename: string | null = null) {
    const exportConfigUpdateOps: JobNodes.TypedDataNode<Exporter.UpdateOp>[] = [
        JobNodes.value(Exporter.createConfigStructFieldUpdateOp(config, config, "state", "done")),
    ]

    const traverse = (configFolder: Exporter.Config["root"]): Utility.Zip.JobNodes.Folder => {
        const content: (typeof Utility.Zip.JobNodes)["folder"] extends (content: infer A) => unknown ? A : never = {}
        for (const item of configFolder.content) {
            if (item.type === "conversionConfig") {
                const imageProcessingGraph = imageProcessingGraphForConversionConfig(item, mapsInfo)
                const imgProcTask = JobNodes.task(imageProcessingTask, {
                    input: JobNodes.value({graph: imageProcessingGraph}),
                })
                for (const mapEntry of item.maps) {
                    const imageColorSpace = useLinearSpace(item, mapEntry.name) ? "LINEAR" : "SRGB"
                    const thumbnailsTask = JobNodes.task(uploadProcessingThumbnailsTask, {
                        input: Utility.DataObject.update(JobNodes.get(imgProcTask, mapEntry.name), {
                            imageColorSpace: imageColorSpace,
                        }),
                    })
                    content[mapEntry.filename] = Utility.Zip.JobNodes.file(JobNodes.get(thumbnailsTask, "dataObject"))

                    // TODO Do we need to keep the data object refs for individual maps?
                    // const _convertedMap = item.maps.filter(x => x.name === mapName);
                    // if (_convertedMap.length !== 1) throw Error(`Expected exactly one map with name: ${mapName}, found: ${_convertedMap.length}`)
                    // exportConfigUpdateOps.push(ExporterTask.JobNodes.createConfigFieldUpdateOpForDataObjectReference(config, _convertedMap[0], 'data', JobNodes.get(imgProcTask, mapName), DataObjectAssignmentType.Attachment));
                }
            } else if (item.type === "exportJsonFile") {
                content[item.name] = Utility.Zip.JobNodes.file(Utility.Zip.JobNodes.json(item.content))
            } else if (item.type === "exportTextFile") {
                content[item.name] = Utility.Zip.JobNodes.file(Utility.Zip.JobNodes.text(item.content))
            } else if (item.type === "exportFolder") {
                content[item.name] = traverse(item)
            } else {
                throw Error(`Unknown type in export config to job graph transform: ${item["type"]}`)
            }
        }
        return Utility.Zip.JobNodes.folder(content)
    }

    const zipRoot = traverse(config.root)
    const zipTask = JobNodes.task(Utility.Zip.task, {
        input: JobNodes.struct({
            filename: JobNodes.value(zipFilename ?? config.root.name),
            destination: JobNodes.value({
                type: "taskOutputOnly",
                customerId: materialDetails.organization.legacyId,
            } as Utility.Zip.Destination),
            root: zipRoot,
        }),
    })

    exportConfigUpdateOps.push(
        ExporterTask.JobNodes.createConfigFieldUpdateOpForDataObjectReference(
            config,
            config,
            "output",
            JobNodes.get(zipTask, "zipFile"),
            DataObjectAssignmentTypeStringToNumber[DataObjectAssignmentType.MaterialMapsExport],
        ),
    )

    return (jsonObjectId: number) => {
        const updateConfigTask = JobNodes.task(materialMapsExporterUpdateExportConfigTask, {
            input: JobNodes.struct({
                jsonObjectId: JobNodes.value(jsonObjectId),
                updateOps: JobNodes.list(exportConfigUpdateOps),
            }),
        })

        return JobNodes.jobGraph(JobNodes.list([updateConfigTask, zipTask]), {platformVersion: Settings.APP_VERSION})
    }
}

function generateDefaultConvertedMapFilename(materialDetails: MaterialDetails, mapName: string, ext: Exporter.Format) {
    return materialDetails.articleId != "" && materialDetails.articleId != null
        ? `${materialDetails.articleId}-${mapName}.${ext}`
        : `${materialDetails.legacyId}-${materialDetails.name}-${mapName}.${ext}`
}

function useLinearSpace(config: Exporter.ConversionConfig, mapName: string) {
    return config.format === "exr" || !SRGB_TEXTURE_TYPES_FOR_ENGINE[config.workflow][config.engine].map((x): string => x).includes(mapName)
}

type ExportSummary = {[K in "workflow" | "engine" | "format" | "normalY" | "resolution"]: Array<Exporter.ConversionConfig[K]>}

export type MaterialMapsExportQueryResult = ExportConfigDBEntry & {
    summary: ExportSummary
    dataObjectId: number | undefined
    updateAvailable: boolean
}
export type ExportConfigDBEntry = {config: Exporter.Config; jsonObjectId: number}

@Injectable()
export class MaterialMapsExporterService {
    private sdk = inject(SdkService)
    private materialGraphService = inject(MaterialGraphService)
    private nameAssetFromSchemaService = inject(NameAssetFromSchemaService)

    constructor() {}

    async fetchExportConfigsDB(materialIdDetail: IdDetails): Promise<ExportConfigDBEntry[]> {
        const filter = "id" in materialIdDetail ? {objectId: materialIdDetail["id"]} : {objectLegacyId: materialIdDetail["legacyId"]}
        const assignments = (
            await this.sdk.gql.materialMapsExporterJsonFileAssignments({
                filter: {
                    ...filter,
                    contentTypeModel: ContentTypeModel.Material,
                },
            })
        ).jsonFileAssignments

        return assignments
            .filter((assignment) => assignment.assignmentType === JsonFileAssignmentType.MaterialMapsExportConfig)
            .map((assignment) => ({config: assignment.jsonFile.content, jsonObjectId: assignment.jsonFile.legacyId}))
    }

    private async storeExportConfigDB(materialDetails: MaterialDetails, exportConfig: Exporter.Config): Promise<ExportConfigDBEntry> {
        const result = await this.sdk.gql.materialMapsExporterCreateJsonFileAssignment({
            input: {
                jsonFile: {
                    content: exportConfig,
                    organizationLegacyId: materialDetails.organization.legacyId,
                    type: JsonFileType.MaterialMapsExportConfig,
                },
                objectLegacyId: materialDetails.legacyId,
                contentTypeModel: ContentTypeModel.Material,
                type: JsonFileAssignmentType.MaterialMapsExportConfig,
            },
        })

        const config = result.createJsonFileAssignment?.jsonFile?.content
        const jsonFileId = result.createJsonFileAssignment?.jsonFile?.legacyId
        if (!config || !jsonFileId) throw Error("Failed to create export config")

        return {
            config,
            jsonObjectId: jsonFileId,
        }
    }

    private async updateExportConfigDB(config: ExportConfigDBEntry) {
        return this.sdk.gql.materialMapsExporterUpdateJsonFile({
            input: {
                content: config.config,
                legacyId: config.jsonObjectId,
            },
        })
    }

    async queryMapsExportsForMaterial(materialIdDetails: IdDetails): Promise<MaterialMapsExportQueryResult[]> {
        const matchSourceInfos = (sourceInfo1: Exporter.SourceInfo, sourceInfo2: Exporter.SourceInfo) => {
            if (sourceInfo1.source !== sourceInfo2.source) return false
            if (sourceInfo1.source === "textureSet") return sourceInfo1.sourceId === sourceInfo2.sourceId
            else if (sourceInfo1.source === "materialRevision") return true
            else throw Error(`Unknown source info sources: ${sourceInfo1.source}/${sourceInfo2.source}`)
        }

        const textureRevisionInfoEqual = (a: Exporter.TextureRevisionInfo, b: Exporter.TextureRevisionInfo) => {
            if (a.textureType !== b.textureType) return false
            if (a.revisionId !== b.revisionId) return false
            return true
        }

        const sourceInfosEqual = (a: Exporter.SourceInfo, b: Exporter.SourceInfo) => {
            if (a.source !== b.source) return false
            if (a.sourceId !== b.sourceId) return false
            if (a.source === "textureSet") {
                if (a.textureRevisionInfo === undefined || b.textureRevisionInfo === undefined) throw Error("Texture revision info is undefined")

                if (a.textureRevisionInfo.length !== b.textureRevisionInfo.length) return false

                return (
                    a.textureRevisionInfo.every(
                        (aTextureRevisionInfo) =>
                            b.textureRevisionInfo!.find((bTextureRevisionInfo) => textureRevisionInfoEqual(aTextureRevisionInfo, bTextureRevisionInfo)) !==
                            undefined,
                    ) &&
                    b.textureRevisionInfo.every(
                        (bTextureRevisionInfo) =>
                            a.textureRevisionInfo!.find((aTextureRevisionInfo) => textureRevisionInfoEqual(aTextureRevisionInfo, bTextureRevisionInfo)) !==
                            undefined,
                    )
                )
            }
            return true
        }

        const resultForConfigDBEntry = (configDBEntry: ExportConfigDBEntry, updateAvailable: boolean): MaterialMapsExportQueryResult => {
            return {
                ...configDBEntry,
                summary: this.getSummaryForExportConfigOrExportRequest(configDBEntry.config),
                updateAvailable,
                dataObjectId:
                    configDBEntry.config.state === "done" && configDBEntry.config.output !== undefined ? configDBEntry.config.output.dataObjectId : undefined,
            }
        }

        const configsDB = await this.fetchExportConfigsDB(materialIdDetails)

        const resultsForTextureSetBasedConfigs = configsDB
            .filter((configDBEntry) => configDBEntry.config.sourceInfo.source === "textureSet")
            .map((configDBEntry) => resultForConfigDBEntry(configDBEntry, false))

        const resultsForMaterialRevisionBasedConfigs = await (async () => {
            const filteredConfigsDB = configsDB.filter((configDBEntry) => configDBEntry.config.sourceInfo.source === "materialRevision")
            const sourceInfos = filteredConfigsDB.map((configDBEntry) => configDBEntry.config.sourceInfo)
            const uniqueSourceInfos = sourceInfos.filter((el, i) => !sourceInfos.some((el2, i2) => i < i2 && matchSourceInfos(el, el2)))
            const populatedSourceInfos = await Promise.all(
                uniqueSourceInfos.map(async (sourceInfo) => {
                    try {
                        const {sourceInfo: populatedSourceInfo} = await collectSourceAndMapsInfo(
                            this.sdk,
                            this.materialGraphService,
                            materialIdDetails,
                            sourceInfo,
                        )
                        return populatedSourceInfo
                    } catch (e) {
                        return sourceInfo
                    }
                }),
            )
            return filteredConfigsDB.map((configDBEntry) => {
                const oldSourceInfo = configDBEntry.config.sourceInfo

                const populatedSourceInfo = populatedSourceInfos.find((populatedSourceInfo) => matchSourceInfos(populatedSourceInfo, oldSourceInfo))
                if (populatedSourceInfo === undefined) throw Error("populatedSourceInfo === undefined")

                const updateAvailable = !sourceInfosEqual(populatedSourceInfo, oldSourceInfo)
                return resultForConfigDBEntry(configDBEntry, updateAvailable)
            })
        })()

        return [...resultsForTextureSetBasedConfigs, ...resultsForMaterialRevisionBasedConfigs]
    }

    async generateMapsExport(exportRequest: Exporter.Request, materialIdDetails: IdDetails): Promise<number> {
        const {mapsInfo, sourceInfo} = await collectSourceAndMapsInfo(this.sdk, this.materialGraphService, materialIdDetails, exportRequest.sourceInfoRequest)

        const queryResult = (await this.sdk.gql.materialForMapsExport(materialIdDetails)).material
        const parsedQueryResult = MaterialDetailsSchema.safeParse(queryResult)
        if (!parsedQueryResult.success) throw Error(`Failed to query all material details`)
        const materialDetails = parsedQueryResult.data

        const generateConvertedMapFilename = (materialDetails: MaterialDetails, conversionRequest: Exporter.ConversionRequest, mapType: Exporter.TextureType) =>
            this.nameAssetFromSchemaService.getMaterialMapsExportMapName(materialDetails.id, conversionRequest, mapType)

        const generateConvertedInfoFilename = (materialDetails: MaterialDetails, conversionInfoRequest: Exporter.ConversionInfoRequest) =>
            this.nameAssetFromSchemaService.getMaterialMapsExportInfoName(materialDetails.id, conversionInfoRequest)

        const config = await exportConfigForExportRequest(
            exportRequest,
            materialDetails,
            sourceInfo,
            mapsInfo,
            generateConvertedMapFilename,
            generateConvertedInfoFilename,
        )

        const zipFilename = await this.nameAssetFromSchemaService.getMaterialMapsExportName(materialDetails.id, config)
        const jobGraphFn = jobGraphFnForExportConfig(config, mapsInfo, materialDetails, zipFilename)
        const addedConfig = await this.storeExportConfigDB(materialDetails, config)

        const jobName = `Maps export generation: Material ${materialDetails.legacyId}`
        const result = await this.sdk.gql.materialMapsExporterCreateJob({
            input: {
                name: jobName,
                organizationLegacyId: materialDetails.organization?.legacyId,
                graph: graphToJson(jobGraphFn(addedConfig.jsonObjectId)),
            },
        })

        if (!result.createJob?.legacyId) throw new Error(`Failed to create job`)
        return result.createJob.legacyId
    }

    async renameMapsExport(configDBEntry: ExportConfigDBEntry, filename: string) {
        if (!configDBEntry.config.output) throw Error("Export config output field not set")

        // NOTE: only update the data object name, the name in config json should not be modified,
        // since it's used to determine if we are dealing with a default or custom export
        await this.sdk.gql.materialMapsExporterUpdateDataObject({
            input: {legacyId: configDBEntry.config.output.dataObjectId, originalFileName: `${filename}.zip`},
        })
        // await this.updateExportConfigDB(configDBEntry)
    }

    async deleteMapsExport(configDBEntry: ExportConfigDBEntry) {
        await this.sdk.gql.materialMapsExporterDeleteJsonFile({legacyId: configDBEntry.jsonObjectId})
        if (configDBEntry.config.output) {
            try {
                // TODO this could be done by the backend, since given data object referenced only by the deleted json file
                await this.sdk.gql.materialMapsExporterDeleteDataObject({legacyId: configDBEntry.config.output.dataObjectId})
            } catch (error) {
                console.log(`Failed to delete data object for export config: ${configDBEntry.jsonObjectId}`)
            }
        }
    }

    private getSummaryForExportConfigOrExportRequest(_export: Exporter.Config | Exporter.Request): ExportSummary {
        const summary: ExportSummary = {
            workflow: [],
            engine: [],
            format: [],
            normalY: [],
            resolution: [],
        }

        const traverse = (folder: (typeof _export)["root"]) => {
            folder.content.map((item) => {
                switch (item.type) {
                    case "exportFolder":
                        traverse(item)
                        break
                    case "conversionRequest":
                    case "conversionConfig":
                        summary.workflow.push(
                            item.workflow ? item.workflow : item.engine === "vray" || item.engine === "corona" ? "specularGlossiness" : "metalnessRoughness",
                        )
                        summary.engine.push(item.engine ? item.engine : "cm")
                        summary.format.push(item.format ? item.format : "exr")
                        summary.normalY.push(item.normalY ? item.normalY : "default")
                        summary.resolution.push(item.resolution ? item.resolution : "original")
                        break
                }
            })
        }
        traverse(_export.root)

        summary.workflow = Array.from(new Set(summary.workflow)).sort()
        summary.engine = Array.from(new Set(summary.engine)).sort()
        summary.format = Array.from(new Set(summary.format)).sort()
        summary.normalY = Array.from(new Set(summary.normalY)).sort()
        summary.resolution = Array.from(new Set(summary.resolution)).sort()
        return summary
    }
}
