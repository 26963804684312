import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {NodeUtils} from "@cm/template-nodes"
import {SceneManager} from "app/templates/template-system/scene-manager"
import {map, mergeMap, Observable, tap} from "rxjs"
import {forkJoinZeroOrMore} from "@legacy/helpers/utils"
import * as SimpleGraph from "@platform/helpers/simple-graph/simple-graph"

// TODO should this be moved to NodeUtils ?
function getTemplateGraphOrRevisionId(node: Nodes.Node): number | Nodes.TemplateGraph {
    let resolvedInstance = NodeUtils.resolveInstance(node)
    while (resolvedInstance.type === "templateInstance") {
        resolvedInstance = (resolvedInstance as Nodes.TemplateInstance).template as Nodes.TemplateReference
    }
    if (NodeUtils.isTemplateGraph(resolvedInstance)) {
        // this is an embedded graph
        return resolvedInstance
    } else {
        return (resolvedInstance as Nodes.TemplateReference).templateRevisionId
    }
}

type NamedTemplateGraph = {
    name: string
    templateGraph: Nodes.TemplateGraph
}

type NodeConnectionType = "parameterReference" | "templateReference" | "input"

type NodeConnection = {
    type: NodeConnectionType
    sourceId: string
    targetId: string
    edgeLabel: string | null
}

const edgeStyleByConnectionType: {[key in NodeConnectionType]: SimpleGraph.EdgeStyle} = {
    input: {
        lineStyle: "solid",
        sourceArrowStyle: "none",
        targetArrowStyle: "standard",
    },
    templateReference: {
        lineStyle: "dotted",
        sourceArrowStyle: "none",
        targetArrowStyle: "none",
    },
    parameterReference: {
        lineStyle: "dashed",
        sourceArrowStyle: "none",
        targetArrowStyle: "standard",
    },
}

class TemplateGraphNodeDesc {
    private nodes = new Set<Nodes.Node>()
    private simpleNodeByNode = new Map<Nodes.Node, SimpleGraph.Node>()
    private simpleNodeByParameterKeyByInstanceNode = new Map<Nodes.TemplateOrInstance, Map<string, SimpleGraph.Node>>()
    private simpleNodeByOutputIdByInstanceNode = new Map<Nodes.TemplateOrInstance, Map<string, SimpleGraph.Node>>()
    private templateGraphByTemplateInstance = new Map<Nodes.TemplateOrInstance, Nodes.TemplateGraph>()

    constructor(readonly templateGraph: Nodes.TemplateGraph) {}

    createSimpleNode(node: Nodes.Node, color?: string, children?: SimpleGraph.Node[], labelOverride?: string) {
        const nodeLabel = labelOverride ?? NodeUtils.describeNode(node)
        const simpleNode = new SimpleGraph.Node(nodeLabel, color, children)
        this.simpleNodeByNode.set(node, simpleNode)
        this.nodes.add(node)
        return simpleNode
    }

    getNodes() {
        return this.nodes
    }

    hasNode(node: Nodes.Node) {
        return this.nodes.has(node)
    }

    findNodeByExternalId(externalId: Nodes.ExternalId) {
        for (const node of this.nodes) {
            if (Nodes.getExternalId(node) === externalId) {
                return node
            }
        }
        return null
    }

    getSimpleNode(node: Nodes.Node) {
        const id = this.simpleNodeByNode.get(node)
        if (!id) {
            throw Error("Id for node " + NodeUtils.describeNode(node) + " not found.")
        }
        return id
    }

    createParameterSimpleNode(node: Nodes.TemplateOrInstance, parameterKey: string, label: string) {
        const simpleNode = new SimpleGraph.Node(label)
        if (!this.simpleNodeByParameterKeyByInstanceNode.has(node)) {
            this.simpleNodeByParameterKeyByInstanceNode.set(node, new Map())
        }
        this.simpleNodeByParameterKeyByInstanceNode.get(node)!.set(parameterKey, simpleNode)
        this.nodes.add(node)
        return simpleNode
    }

    getParameterNodeId(node: Nodes.TemplateOrInstance, parameterKey: string) {
        const nodeIdByParameterKey = this.simpleNodeByParameterKeyByInstanceNode.get(node)
        if (!nodeIdByParameterKey) {
            throw Error("No parameter nodes found for node " + NodeUtils.describeNode(node))
        }
        const nodeId = nodeIdByParameterKey.get(parameterKey)
        if (nodeId == null) {
            throw Error(`Parameter with key ${parameterKey} not found`)
        }
        return nodeId
    }

    findParameterNodeIds(parameterKey: string) {
        const foundNodeIds: SimpleGraph.Node[] = []
        for (const simpleNodeByParameterKey of this.simpleNodeByParameterKeyByInstanceNode.values()) {
            const simpleNode = simpleNodeByParameterKey.get(parameterKey)
            if (simpleNode != null) {
                foundNodeIds.push(simpleNode)
            }
        }
        return foundNodeIds
    }

    createOutputSimpleNode(node: Nodes.TemplateOrInstance, templateOutputId: Nodes.ExternalId, label: string) {
        const simpleNode = new SimpleGraph.Node(label)
        if (!this.simpleNodeByOutputIdByInstanceNode.has(node)) {
            this.simpleNodeByOutputIdByInstanceNode.set(node, new Map())
        }
        this.simpleNodeByOutputIdByInstanceNode.get(node)!.set(templateOutputId, simpleNode)
        this.nodes.add(node)
        return simpleNode
    }

    getOutputSimpleNode(node: Nodes.TemplateOrInstance, templateOutputId: Nodes.ExternalId) {
        const nodeIdByOutputKey = this.simpleNodeByOutputIdByInstanceNode.get(node)
        if (!nodeIdByOutputKey) {
            throw Error("No output nodes found for node " + NodeUtils.describeNode(node))
        }
        const nodeId = nodeIdByOutputKey.get(templateOutputId)
        if (nodeId == null) {
            throw Error(`Parameter with key ${templateOutputId} not found`)
        }
        return nodeId
    }

    getOutputIds(node: Nodes.TemplateOrInstance) {
        const nodeIdByOutputKey = this.simpleNodeByOutputIdByInstanceNode.get(node)
        if (!nodeIdByOutputKey) {
            return [] as string[]
        }
        return Array.from(nodeIdByOutputKey.keys())
    }

    findTemplateOutputReferenceSimpleNodes(templateGraph: Nodes.TemplateGraph, templateOutputId: Nodes.ExternalId) {
        const templateOutputReferenceSimpleNodes: SimpleGraph.Node[] = []
        for (const [instanceNode, nodeIdByOutputId] of this.simpleNodeByOutputIdByInstanceNode) {
            const referencedTemplateGraph = this.getTemplateGraphForRegisteredTemplateInstance(instanceNode)
            if (referencedTemplateGraph === templateGraph) {
                templateOutputReferenceSimpleNodes.push(this.getOutputSimpleNode(instanceNode, templateOutputId))
            }
        }
        return templateOutputReferenceSimpleNodes
    }

    registerTemplateInstanceNode(node: Nodes.TemplateOrInstance, referencedTemplateGraph: Nodes.TemplateGraph) {
        if (this.templateGraphByTemplateInstance.has(node)) {
            throw Error("Attempting to register the same template instance twice.")
        }
        this.templateGraphByTemplateInstance.set(node, referencedTemplateGraph)
    }

    getTemplateInstanceNodes() {
        return this.templateGraphByTemplateInstance.keys()
    }

    getTemplateGraphForRegisteredTemplateInstance(node: Nodes.TemplateOrInstance) {
        const templateGraph = this.templateGraphByTemplateInstance.get(node)
        if (!templateGraph) {
            throw Error(`Template instance ${NodeUtils.describeNode(node)} not registered.`)
        }
        return templateGraph
    }
}

class NodeDesc {
    private nodeDescByTemplateGraph = new Map<Nodes.TemplateGraph, TemplateGraphNodeDesc>()

    constructor(private templateGraphByRevisionId: Map<number, NamedTemplateGraph>) {}

    getReferencedTemplateGraph(node: Nodes.TemplateOrInstance): NamedTemplateGraph {
        const templateGraphOrRevisionId = getTemplateGraphOrRevisionId(node)
        if (typeof templateGraphOrRevisionId === "number") {
            const referencedTemplateGraph = this.templateGraphByRevisionId.get(templateGraphOrRevisionId)
            if (!referencedTemplateGraph) {
                throw Error("Referenced template graph not found.")
            }
            return referencedTemplateGraph
        } else {
            return {
                name: node.name ?? "unknown name",
                templateGraph: templateGraphOrRevisionId,
            }
        }
    }

    registerTemplateGraphNode(templateGraph: Nodes.TemplateGraph) {
        if (this.nodeDescByTemplateGraph.has(templateGraph)) {
            throw Error(`TemplateGraph ${NodeUtils.describeNode(templateGraph)} exists already.`)
        }
        const templateGraphNodesDesc = new TemplateGraphNodeDesc(templateGraph)
        this.nodeDescByTemplateGraph.set(templateGraph, templateGraphNodesDesc)
        return templateGraphNodesDesc
    }

    getTemplateGraphs() {
        return this.nodeDescByTemplateGraph.keys()
    }

    hasTemplateGraph(templateGraph: Nodes.TemplateGraph) {
        return this.nodeDescByTemplateGraph.has(templateGraph)
    }

    hasTemplateGraphNodeDesc(templateGraph: Nodes.TemplateGraph) {
        return this.nodeDescByTemplateGraph.has(templateGraph)
    }

    getTemplateGraphNodeDesc(templateGraph: Nodes.TemplateGraph) {
        const templateGraphNodeDesc = this.nodeDescByTemplateGraph.get(templateGraph)
        if (!templateGraphNodeDesc) {
            throw Error(`TemplateGraph ${NodeUtils.describeNode(templateGraph)} not found.`)
        }
        return templateGraphNodeDesc
    }

    findNodeByExternalId(externalId: Nodes.ExternalId) {
        for (const nodeDesc of this.nodeDescByTemplateGraph.values()) {
            const foundNode = nodeDesc.findNodeByExternalId(externalId)
            if (foundNode) {
                return foundNode
            }
        }
        return null
    }

    getSimpleNode(node: Nodes.Node) {
        for (const nodeDesc of this.nodeDescByTemplateGraph.values()) {
            if (nodeDesc.hasNode(node)) {
                return nodeDesc.getSimpleNode(node)
            }
        }
        throw Error(`Node ${NodeUtils.describeNode(node)} not found.`)
    }
}

export class TemplateGraphExporter {
    private readonly SHOW_REFERENCED_TEMPLATES = true
    private readonly DEFAULT_GROUP_COLOR = "#F5F5F5"
    private readonly ROOT_TEMPLATE_GROUP_COLOR = "#E5E5F5"
    private readonly TEMPLATE_INSTANCE_GROUP_COLOR = "#E5F5E5"
    private readonly EXTRA_NODE_COLOR = "#F5E5E5"

    generateSimpleGraph(sceneManager: SceneManager, templateGraph: Nodes.TemplateGraph, rootName: string): Observable<SimpleGraph.Graph> {
        return this.gatherReferencedTemplates(sceneManager, templateGraph).pipe(
            map((namedTemplateGraphByRevisionId) => {
                const simpleGraph = new SimpleGraph.Graph()
                const nodeDesc = new NodeDesc(namedTemplateGraphByRevisionId)
                simpleGraph.rootNodes.push(
                    this.writeTemplateGraphNodes({templateGraph: templateGraph, name: rootName}, this.ROOT_TEMPLATE_GROUP_COLOR, nodeDesc),
                )
                for (const referencedNamedTemplateGraph of namedTemplateGraphByRevisionId.values()) {
                    simpleGraph.rootNodes.push(this.writeTemplateGraphNodes(referencedNamedTemplateGraph, undefined, nodeDesc))
                }
                const [simpleEdges, extraSimpleNodes] = this.gatherConnections(namedTemplateGraphByRevisionId, nodeDesc)
                simpleGraph.edges.push(...simpleEdges)
                simpleGraph.rootNodes.push(...extraSimpleNodes)
                return simpleGraph
            }),
        )
    }

    private gatherReferencedTemplates(sceneManager: SceneManager, context: Nodes.Context): Observable<Map<number, NamedTemplateGraph>> {
        const thisLevelResults = new Map<number, NamedTemplateGraph>()
        const subTemplateObservables: Observable<Map<number, NamedTemplateGraph>>[] = []
        if (this.SHOW_REFERENCED_TEMPLATES) {
            for (const node of context.nodes) {
                if (NodeUtils.isTemplateOrInstance(node)) {
                    const templateGraphOrRevisionId = getTemplateGraphOrRevisionId(node)
                    if (typeof templateGraphOrRevisionId === "number") {
                        const templateRevisionId = templateGraphOrRevisionId
                        console.log("GATHER --- Encountered template instance ", templateRevisionId)
                        const subTemplateObservable = sceneManager.getTemplateGraph(templateRevisionId).pipe(
                            tap((instantiatedTemplate) =>
                                thisLevelResults.set(templateRevisionId, {
                                    name: node.name ?? "Unnamed node",
                                    templateGraph: instantiatedTemplate,
                                }),
                            ),
                            mergeMap((instantiatedTemplate) => this.gatherReferencedTemplates(sceneManager, instantiatedTemplate)),
                        )
                        subTemplateObservables.push(subTemplateObservable)
                    }
                } else if (NodeUtils.isContext(node)) {
                    const contextGatheredTemplates = this.gatherReferencedTemplates(sceneManager, node as Nodes.Context)
                    subTemplateObservables.push(contextGatheredTemplates)
                }
            }
        }
        return forkJoinZeroOrMore(subTemplateObservables).pipe(
            map((templateGraphs) => {
                // flatten maps
                const flattenedTemplateGraphMaps = new Map<number, NamedTemplateGraph>(thisLevelResults)
                for (const map of templateGraphs) {
                    for (const entry of map.entries()) {
                        flattenedTemplateGraphMaps.set(entry[0], entry[1])
                    }
                }
                return flattenedTemplateGraphMaps
            }),
        )
    }

    private findNodesById(node: Nodes.Node, interfaceId: Nodes.Meta.InterfaceId, nodeDesc: NodeDesc): Nodes.Node[] {
        const foundNodes: Nodes.Node[] = []
        const [beforeSeparator, remainder] = Nodes.Meta.unwrapInterfaceId(interfaceId)
        const id = beforeSeparator ?? remainder
        if ("id" in node && node.id === id) {
            foundNodes.push(node)
        } else if (NodeUtils.isContext(node)) {
            for (const childNode of node.nodes) {
                foundNodes.push(...this.findNodesById(childNode, id, nodeDesc))
            }
        }
        if (beforeSeparator) {
            const subFoundNodes: Nodes.Node[] = []
            for (const foundNode of foundNodes) {
                if (NodeUtils.isTemplateOrInstance(foundNode)) {
                    const referencedTemplateGraph = nodeDesc.getReferencedTemplateGraph(foundNode)
                    subFoundNodes.push(...this.findNodesById(referencedTemplateGraph.templateGraph, remainder, nodeDesc))
                }
            }
            return subFoundNodes
        } else {
            return foundNodes
        }
    }

    private getParameterEntries(node: Nodes.TemplateOrInstance) {
        const parameterEntries: [string, any][] = []
        if (node.parameters) {
            parameterEntries.push(...Object.entries(node.parameters))
        }
        // also add legacy selected config variants
        if (node.selectedConfigVariants) {
            parameterEntries.push(...Object.entries(node.selectedConfigVariants))
        }
        return parameterEntries
    }

    private gatherConnections(templateGraphByRevisionId: Map<number, NamedTemplateGraph>, nodeDesc: NodeDesc): [SimpleGraph.Edge[], SimpleGraph.Node[]] {
        const simpleEdges: SimpleGraph.Edge[] = []
        const extraSimpleNodes: SimpleGraph.Node[] = []
        for (const templateGraph of nodeDesc.getTemplateGraphs()) {
            const templateGraphNodeDesc = nodeDesc.getTemplateGraphNodeDesc(templateGraph)
            // connections for existing nodes
            for (const node of templateGraphNodeDesc.getNodes()) {
                const simpleNode = templateGraphNodeDesc.getSimpleNode(node)
                const [subSimpleEdges, subExtraSimpleNodes] = this.gatherNodeConnections(simpleNode, templateGraphNodeDesc, node, templateGraphByRevisionId)
                simpleEdges.push(...subSimpleEdges)
                extraSimpleNodes.push(...subExtraSimpleNodes)
            }
            // inter template connections
            for (const templateInstanceNode of templateGraphNodeDesc.getTemplateInstanceNodes()) {
                const referencedTemplateGraph = nodeDesc.getReferencedTemplateGraph(templateInstanceNode)
                const referencedTemplateGraphNodeDesc = nodeDesc.hasTemplateGraphNodeDesc(referencedTemplateGraph.templateGraph)
                    ? nodeDesc.getTemplateGraphNodeDesc(referencedTemplateGraph.templateGraph)
                    : templateGraphNodeDesc // if we can't fing the referenced template it must be an inline template and therefore to be found in the containing templates node-descs
                const referencedTemplateSimpleNode = referencedTemplateGraphNodeDesc.getSimpleNode(referencedTemplateGraph.templateGraph)
                const templateInstanceSimpleNode = templateGraphNodeDesc.getSimpleNode(templateInstanceNode)
                simpleEdges.push(
                    new SimpleGraph.Edge(
                        templateInstanceSimpleNode,
                        referencedTemplateSimpleNode,
                        edgeStyleByConnectionType["templateReference"],
                        undefined,
                        undefined,
                    ),
                )
                // add parameter connections
                const parameterEntries = this.getParameterEntries(templateInstanceNode)
                for (const [parameterKey, parameterValue] of parameterEntries) {
                    const parameterSimpleNode = templateGraphNodeDesc.getParameterNodeId(templateInstanceNode, parameterKey)
                    const targetNodes = this.findNodesById(referencedTemplateGraph.templateGraph, parameterKey, nodeDesc)
                    for (const targetNode of targetNodes) {
                        const targetSimpleNode = nodeDesc.getSimpleNode(targetNode) // we call the global resolver because the target might be further down the hierarchy
                        simpleEdges.push(
                            new SimpleGraph.Edge(parameterSimpleNode, targetSimpleNode, edgeStyleByConnectionType["parameterReference"], undefined, undefined),
                        )
                    }
                    // TODO no such NodeUtils helper ?
                    if (parameterValue.type === "getTemplateOutput") {
                        const getTemplateOutputNode = parameterValue as Nodes.GetTemplateOutput<any>
                        const getTemplateOutputNodeReferencedTemplateGraph = nodeDesc.getReferencedTemplateGraph(getTemplateOutputNode.template)
                        const foundOutputReferenceSimpleNodes = templateGraphNodeDesc.findTemplateOutputReferenceSimpleNodes(
                            getTemplateOutputNodeReferencedTemplateGraph.templateGraph,
                            getTemplateOutputNode.outputId,
                        )
                        for (const foundOutputReferenceSimpleNode of foundOutputReferenceSimpleNodes) {
                            simpleEdges.push(
                                new SimpleGraph.Edge(
                                    foundOutputReferenceSimpleNode,
                                    parameterSimpleNode,
                                    edgeStyleByConnectionType["input"],
                                    undefined,
                                    undefined,
                                ),
                            )
                        }
                    } else if (NodeUtils.isNode(parameterValue)) {
                        const parameterValueSimpleNode = templateGraphNodeDesc.getSimpleNode(parameterValue)
                        simpleEdges.push(
                            new SimpleGraph.Edge(parameterValueSimpleNode, parameterSimpleNode, edgeStyleByConnectionType["input"], undefined, undefined),
                        )
                    } else {
                        // it could be a node reference by external id
                        let couldResolve = false
                        if (typeof parameterValue === "string") {
                            const referencedNode = nodeDesc.findNodeByExternalId(parameterValue)
                            if (referencedNode) {
                                const parameterValueSimpleNode = nodeDesc.getSimpleNode(referencedNode)
                                simpleEdges.push(
                                    new SimpleGraph.Edge(
                                        parameterValueSimpleNode,
                                        parameterSimpleNode,
                                        edgeStyleByConnectionType["input"],
                                        undefined,
                                        undefined,
                                    ),
                                )
                                couldResolve = true
                            }
                        }
                        if (!couldResolve) {
                            console.warn("Unsupported parameter value type: " + parameterValue.type)
                        }
                    }
                }
                // connect template outputs
                const templateOutputIds = templateGraphNodeDesc.getOutputIds(templateInstanceNode)
                for (const templateOutputId of templateOutputIds) {
                    const outputReferenceSimpleNode = templateGraphNodeDesc.getOutputSimpleNode(templateInstanceNode, templateOutputId)
                    const foundTemplateOutputNodes = this.findNodesById(referencedTemplateGraph.templateGraph, templateOutputId, nodeDesc)
                    for (const outputNode of foundTemplateOutputNodes) {
                        const outputSimpleNode = referencedTemplateGraphNodeDesc.getSimpleNode(outputNode)
                        simpleEdges.push(
                            new SimpleGraph.Edge(
                                outputSimpleNode,
                                outputReferenceSimpleNode,
                                edgeStyleByConnectionType["parameterReference"],
                                undefined,
                                undefined,
                            ),
                        )
                    }
                }
            }
        }
        return [simpleEdges, extraSimpleNodes]
    }

    private writeTemplateGraphNodes(templateGraph: NamedTemplateGraph, groupColorOverride: string | undefined, nodeDesc: NodeDesc) {
        const templateGraphNodeDesc = nodeDesc.registerTemplateGraphNode(templateGraph.templateGraph)
        return this.writeNodes(templateGraph.templateGraph, templateGraph.name, groupColorOverride, templateGraphNodeDesc, nodeDesc)
    }

    private writeNodes(
        context: Nodes.Context,
        groupLabelOverride: string | undefined,
        groupColorOverride: string | undefined,
        templateGraphNodeDesc: TemplateGraphNodeDesc,
        nodeDesc: NodeDesc,
    ) {
        // write group node
        const contextChildren: SimpleGraph.Node[] = []
        const contextSimpleNode = templateGraphNodeDesc.createSimpleNode(context as Nodes.Node, groupColorOverride, contextChildren, groupLabelOverride)
        // write nodes
        for (const node of context.nodes) {
            if (NodeUtils.isContext(node)) {
                const simpleNode = this.writeNodes(node as Nodes.Context, undefined, undefined, templateGraphNodeDesc, nodeDesc)
                contextChildren.push(simpleNode)
            } else {
                if (NodeUtils.isTemplateOrInstance(node)) {
                    const templateInOutChildren: SimpleGraph.Node[] = []
                    const simpleNode = templateGraphNodeDesc.createSimpleNode(node, this.TEMPLATE_INSTANCE_GROUP_COLOR, templateInOutChildren)
                    contextChildren.push(simpleNode)
                    const referencedTemplateGraph = nodeDesc.getReferencedTemplateGraph(node)
                    templateGraphNodeDesc.registerTemplateInstanceNode(node, referencedTemplateGraph.templateGraph)
                    // add parameter input nodes
                    const parameterEntries = this.getParameterEntries(node)
                    for (const [parameterKey, parameterValue] of parameterEntries) {
                        const foundNodes = this.findNodesById(referencedTemplateGraph.templateGraph, parameterKey, nodeDesc)
                        if (foundNodes.length > 0) {
                            let parameterNodeLabel = NodeUtils.describeNode(foundNodes[0])
                            if (foundNodes.length > 1) {
                                parameterNodeLabel += " [...]"
                            }
                            const parameterSimpleNode = templateGraphNodeDesc.createParameterSimpleNode(node, parameterKey, parameterNodeLabel)
                            templateInOutChildren.push(parameterSimpleNode)
                        }
                    }
                    // add template output nodes
                    const templateOutputs = this.gatherTemplateOutputs(referencedTemplateGraph.templateGraph)
                    for (const templateOutput of templateOutputs) {
                        const templateOutputNodeLabel = NodeUtils.describeNode(templateOutput)
                        const templateOutputSimpleNode = templateGraphNodeDesc.createOutputSimpleNode(node, templateOutput.id, templateOutputNodeLabel)
                        templateInOutChildren.push(templateOutputSimpleNode)
                    }
                } else {
                    const simpleNode = templateGraphNodeDesc.createSimpleNode(node)
                    contextChildren.push(simpleNode)
                }
            }
        }
        return contextSimpleNode
    }

    private gatherTemplateOutputs(templateGraph: Nodes.TemplateGraph) {
        const foundNodes: Nodes.TemplateExport[] = []
        for (const node of templateGraph.nodes) {
            if (NodeUtils.isTemplateExport(node)) {
                foundNodes.push(node)
            }
        }
        return foundNodes
    }

    private gatherNodeConnections(
        targetSimpleNode: SimpleGraph.Node,
        nodeDesc: TemplateGraphNodeDesc,
        obj: object,
        templateGraphByRevisionId: Map<number, NamedTemplateGraph>,
        prevKey = "",
    ): [SimpleGraph.Edge[], SimpleGraph.Node[]] {
        const simpleEdges: SimpleGraph.Edge[] = []
        const extraSimpleNodes: SimpleGraph.Node[] = []
        if (
            !NodeUtils.isTemplateOrInstance(obj as Nodes.Node) && // we don't gather edges for template-instances
            !NodeUtils.isContext(obj as Nodes.Node)
        ) {
            // we don't gather edges for contexts as their children are represented as nested nodes
            for (const [key, value] of Object.entries(obj)) {
                const fullKey = prevKey + (prevKey === "" ? "" : ".") + key
                if (NodeUtils.isNode(value)) {
                    if (!nodeDesc.hasNode(value as Nodes.Node)) {
                        // the node does not exist. this might be due to the node being outside of the top-level context (which happens for data-object or material references for example)
                        extraSimpleNodes.push(nodeDesc.createSimpleNode(value, this.EXTRA_NODE_COLOR))
                        //console.warn("Node ", value, " could not be resolved.");
                        //continue;
                    }
                    simpleEdges.push(
                        new SimpleGraph.Edge(
                            nodeDesc.getSimpleNode(value as Nodes.Node),
                            targetSimpleNode,
                            edgeStyleByConnectionType["input"],
                            undefined,
                            fullKey,
                        ),
                    )
                } else if (typeof value === "object" && value !== null) {
                    const [subSimpleEdges, subExtraSimpleNodes] = this.gatherNodeConnections(
                        targetSimpleNode,
                        nodeDesc,
                        value,
                        templateGraphByRevisionId,
                        fullKey,
                    )
                    simpleEdges.push(...subSimpleEdges)
                    extraSimpleNodes.push(...subExtraSimpleNodes)
                }
            }
        }
        return [simpleEdges, extraSimpleNodes]
    }
}
