import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorCloneStamp} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/operator-clone-stamp"
import {Vector2, Box2, Color} from "@cm/math"
import {CrosshairToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/crosshair-toolbox-item"
import {SelectCloneSourceToolboxItem} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/toolbox/select-clone-source-toolbox-item"
import {DrawableImageBrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/drawable-image-brush-toolbox-item"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import * as paper from "paper"
import {HalImage} from "@common/models/hal/hal-image"
import {isApple} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

export class CloneStampToolbox extends OperatorToolboxBase<OperatorCloneStamp> {
    constructor(operator: OperatorCloneStamp) {
        super(operator, SelectionMode.None, true)

        this.currentStrokeDrawableImageRef = new DrawableImageRef(this.currentStrokeMaskReference, this.operator.callback.organization.legacyId)

        this.brushToolboxItem = new DrawableImageBrushToolboxItem(this, operator.callback.drawableImageCache)
        this.brushToolboxItem.brushSettings = operator.brushSettings
        this.brushToolboxItem.setDrawableImageRef(this.currentStrokeDrawableImageRef, false)
        this.brushToolboxItem.drawableImageUpdated.subscribe(() => this.onBrushStrokeUpdated())
        this.brushToolboxItem.brushStrokeCompleted.subscribe(() => this.onBrushStrokeCompleted())

        this.selectCloneSourceToolboxItem = new SelectCloneSourceToolboxItem(this)

        this.mappedBrushIndicatorToolboxItem = new CrosshairToolboxItem(this)
        this.mappedBrushIndicatorToolboxItem.crosshairColor = new Color(1, 0, 0)
        this.mappedBrushIndicatorToolboxItem.visible = false

        this.setMode("brush")
    }

    get isStrokeInProgress(): boolean {
        return this.brushToolboxItem.isDrawing
    }

    set cloneSourcePosition(value: Vector2) {
        this.selectCloneSourceToolboxItem.cloneSourcePosition = value
    }

    get cloneSourcePosition(): Vector2 {
        return this.selectCloneSourceToolboxItem.cloneSourcePosition
    }

    async getStrokeImage(): Promise<HalImage> {
        return this.brushToolboxItem.getStrokeImage()
    }

    get strokeImageBoundingBox(): Box2 {
        return this.brushToolboxItem.getCurrentStrokeImageBoundingBox()
    }

    get sourceOffset(): Vector2 {
        const brushStartPosition = this.brushToolboxItem.brushStartPosition
        if (!brushStartPosition) {
            throw new Error("Brush start position is not set")
        }
        return this.cloneSourcePosition.sub(brushStartPosition)
    }

    async clearStrokeImage(): Promise<void> {
        await this.brushToolboxItem.clearStrokeImage()
    }

    setMode(mode: "brush" | "select-source"): void {
        this.brushToolboxItem.selected = mode === "brush"
        this.selectCloneSourceToolboxItem.selected = mode === "select-source"
    }

    override onKeyDown(event: paper.KeyEvent): boolean {
        if (event.key === this.selectSourceKey) {
            this.setMode("select-source")
        }
        return false
    }

    override onKeyUp(event: paper.KeyEvent): boolean {
        if (event.key === this.selectSourceKey) {
            this.setMode("brush")
        }
        return false
    }

    private onBrushStrokeUpdated(): void {
        const brushStartPosition = this.brushToolboxItem.brushStartPosition
        const brushCurrentPosition = this.brushToolboxItem.brushCurrentPosition
        if (brushStartPosition && brushCurrentPosition) {
            this.mappedBrushIndicatorToolboxItem.crosshairPosition = this.cloneSourcePosition
                .add(brushCurrentPosition.sub(brushStartPosition))
                .add(new paper.Point(0.5, 0.5))
            this.mappedBrushIndicatorToolboxItem.visible = true
        }
        this.operator.markEdited()
        this.operator.requestEval()
    }

    private async onBrushStrokeCompleted(): Promise<void> {
        this.mappedBrushIndicatorToolboxItem.visible = false
        await this.operator.addCloneStampEdit()
    }

    private brushToolboxItem: DrawableImageBrushToolboxItem
    private selectCloneSourceToolboxItem: SelectCloneSourceToolboxItem
    private mappedBrushIndicatorToolboxItem: CrosshairToolboxItem
    private readonly selectSourceKey = isApple ? "meta" : "alt"

    // dummy mask reference for the current stroke
    private readonly currentStrokeMaskReference: TextureEditNodes.MaskReference = {
        type: "data-object-reference",
        dataObjectId: "",
    }
    readonly currentStrokeDrawableImageRef: DrawableImageRef
}
