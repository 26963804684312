import {ConditionalAmountList} from "#pricing/nodes/conditional-amount-list"
import {ConditionalAmountNode} from "#pricing/nodes/conditional-amount-node"
import {PricingContext} from "#pricing/nodes/core"
import {DeclareNodeGraphTS, registerNode} from "@cm/graph"

export type ConditionalAmountGroupParameters = {
    description: string
    defaultAmount: number
    amountNodes: ConditionalAmountList
}

@registerNode
export class ConditionalAmountGroupNode extends DeclareNodeGraphTS<number, PricingContext, ConditionalAmountGroupParameters>(
    {
        run: async ({get, parameters, context}) => {
            const amountNodes = await get(parameters.amountNodes)
            const nonZeroValues = amountNodes.filter((value) => value !== 0)
            if (nonZeroValues.length > 1) throw new Error("More than one non-zero value found")
            return nonZeroValues.length === 1 ? nonZeroValues[0] : parameters.defaultAmount
        },
    },
    {
        nodeClass: "ConditionalAmountGroupNode",
    },
) {
    getAmountNodes(): ConditionalAmountNode[] {
        return this.parameters.amountNodes.parameters.list
    }
}
