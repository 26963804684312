import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {z} from "zod"

const TD = TypeDescriptors

const materialGraphReferenceParameters = z.object({
    graph: IMaterialGraph,
})
export type MaterialGraphReferenceParameters = z.infer<typeof materialGraphReferenceParameters>

@registerNode
export class MaterialGraphReference
    extends DeclareTemplateNode({parameters: materialGraphReferenceParameters}, {}, {nodeClass: "MaterialGraphReference"})
    implements EvaluableTemplateNode<IMaterialGraph>
{
    evaluate(scope: GraphBuilderScope) {
        const materialGraph = scope.value(this.parameters.graph, TD.MaterialGraph)
        return materialGraph
    }
}

export type MaterialGraphReferenceFwd = TemplateNode<MaterialGraphReferenceParameters> & EvaluableTemplateNode<IMaterialGraph>
