import {JobNodes, makeJobTaskDescriptor} from "#job-nodes/job-nodes"

export type TilingInput = {
    graph: JobNodes.DataObjectReference // data-object containting the tiling graph
}

export type TilingOutput = {
    log: JobNodes.DataObjectReference
    dataObjectReferenceByTextureTypeId: Record<number, JobNodes.DataObjectReference>
}

export const tilingTask = makeJobTaskDescriptor<TilingInput, TilingOutput>("Tiling.tiling", 1)

//----------------------------------------

export type DisplacementGenerationInput = {
    organizationLegacyId: number
    pxPerCm: number
    normalMapDataObject: JobNodes.DataObjectReference
}

export type DisplacementGenerationOutput = {
    log: JobNodes.DataObjectReference
    displacementMapDataObjectReference: JobNodes.DataObjectReference
    displacementCm: number
}

export const displacementGenerationTask = makeJobTaskDescriptor<DisplacementGenerationInput, DisplacementGenerationOutput>("Tiling.displacementGeneration", 1)
