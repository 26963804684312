import {DeclareObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {ObjectData} from "#template-nodes/interfaces/object-data"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {mesh} from "#template-nodes/node-types"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {SampleCurve, SampleCurveData} from "#template-nodes/runtime-graph/nodes/sample-curve"
import {vectorValue} from "#template-nodes/types"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"
import {Vector3} from "@cm/math"
import {z} from "zod"

const controlPoint = z.object({
    position: vectorValue,
    normal: vectorValue,
    corner: z.boolean(),
})

const meshCurveParameters = namedNodeParameters.merge(
    z.object({
        mesh: mesh.nullable(),
        closed: z.boolean(),
        controlPoints: z.array(controlPoint),
    }),
)
export type MeshCurveParameters = z.infer<typeof meshCurveParameters>

@registerNode
export class MeshCurve
    extends DeclareObjectNode(
        {parameters: meshCurveParameters},
        {
            onVisited: {
                onFilterActive: ({parameters}) => {
                    if (parameters.mesh === null) return skipped
                    return visitNone(parameters)
                },
                onCompile: function (this: MeshCurveFwd, {context, parameters}) {
                    const {mesh, closed, controlPoints} = parameters

                    if (!mesh) return skipped

                    const {evaluator} = context
                    const {templateScope} = evaluator

                    const scope = evaluator.getScope(this)

                    const meshObjectData = evaluator.evaluateMesh(scope, mesh)
                    const transform = scope.get(meshObjectData, "matrix")

                    const transformedControlPoints = controlPoints.map((controlPoint) => ({
                        position: Vector3.fromArray(controlPoint.position),
                        normal: Vector3.fromArray(controlPoint.normal),
                        corner: controlPoint.corner,
                    }))

                    const {curvePoints} = scope.node(SampleCurve, {
                        closed: closed ? "repeat" : false,
                        allowScaling: true,
                        controlPoints: transformedControlPoints,
                        segmentLength: 0.1,
                    })

                    const sampleCurveData = scope.struct<SampleCurveData>("SampleCurveData", {
                        visible: scope.pureLambda(
                            scope.tuple(scope.get(meshObjectData, "visible"), this.parameters.visible),
                            ([meshVisible, curveVisible]) => meshVisible && curveVisible,
                            "curveVisible",
                        ),
                        controlPoints: transformedControlPoints,
                        closed: closed,
                        curvePoints,
                        matrix: transform,
                        meshId: scope.get(meshObjectData, "id"),
                    })

                    templateScope.alias(sampleCurveData, `sampleCurveData-${evaluator.getLocalId(this)}`)

                    this.setupObject(scope, context, "MeshCurveControl", undefined, meshObjectData, (objectProps) => {
                        return scope.struct<SceneNodes.MeshCurveControl>("MeshCurveControl", {
                            type: "MeshCurveControl",
                            ...objectProps,
                            controlPoints: transformedControlPoints,
                            meshId: scope.get(sampleCurveData, "meshId"),
                            curvePoints,
                        })
                    })

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "MeshCurve"},
    )
    implements EvaluableTemplateNode<ObjectData & SampleCurveData>
{
    override evaluate = (scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<ObjectData & SampleCurveData> => {
        const objectData = super.evaluate(scope, evaluator)
        const sampleCurveData = evaluator.templateScope.resolve<SampleCurveData>(`sampleCurveData-${evaluator.getLocalId(this)}`)
        return scope.pureLambda(
            scope.tuple(objectData, sampleCurveData),
            ([objectData, sampleCurveData]) => ({
                ...objectData,
                ...sampleCurveData,
            }),
            "evaluatedMeshCurve",
        )
    }
}

export type MeshCurveFwd = TemplateObjectNode<MeshCurveParameters> & EvaluableTemplateNode<ObjectData & SampleCurveData>
