import {forkJoinZeroOrMore} from "@legacy/helpers/utils"
import {createCanvasAndRenderer, createQuadGeometry, ITextureManager} from "@editor/helpers/scene/three-proxies/utils"
import {MaterialManager} from "@editor/helpers/scene/three-proxies/material-manager"
import {IMaterialGraphManager} from "@cm/material-nodes"
import {from as observableFrom, map, Observable, of as observableOf, Subscriber, Subscription, switchMap} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {IMaterialData} from "@cm/template-nodes"
import {ThreeNodes as THREENodes} from "@cm/material-nodes/three"

function removeFromList<T>(list: T[], elem: T) {
    const index = list.indexOf(elem)
    if (index >= 0) {
        list.splice(index, 1)
    }
    return index
}

type LockQueueEntry<T> = [Observable<T>, Subscriber<T>, Subscription | undefined]

class Lock {
    private queue: LockQueueEntry<any>[] = []

    private startNext() {
        if (this.queue.length === 0) {
            return
        }
        const entry = this.queue[0]
        entry[2] = entry[0].subscribe(
            (value) => {
                entry[1].next(value)
            },
            (err) => {
                entry[1].error(err)
            },
            () => {
                entry[1].complete()
                const index = removeFromList(this.queue, entry)
                if (index === 0) {
                    this.startNext()
                } else if (index > 0) {
                    throw Error(`Inconsistent head of lock queue! (${index})`)
                }
            },
        )
    }

    dispose() {
        for (const entry of this.queue) {
            entry[2]?.unsubscribe()
            entry[1].error("Cancelled")
        }
        this.queue = []
    }

    synchronize<T>(obs: Observable<T>): Observable<T> {
        return new Observable<T>((subscriber) => {
            const entry: LockQueueEntry<T> = [obs, subscriber, undefined]
            this.queue.push(entry)
            if (this.queue.length === 1) {
                this.startNext()
            }
            return () => {
                entry[2]?.unsubscribe()
                if (removeFromList(this.queue, entry) === 0) {
                    this.startNext()
                }
            }
        })
    }

    synchronizeSwitchMap<T1, T2>(fn: (_: T1) => Observable<T2>) {
        let x: any
        return switchMap((x: T1) => this.synchronize(observableOf(x).pipe(switchMap(fn))))
    }
}

export class MaterialExporter {
    private materialManager: MaterialManager
    private canvas: HTMLCanvasElement
    private renderer: THREE.WebGLRenderer
    private camera = new THREE.OrthographicCamera(0, 1000, 1000, 0, 0.01, 10)
    private scene = new THREE.Scene()
    private mesh = new THREE.Mesh()
    private lock = new Lock()

    constructor(textureManager: ITextureManager, materialGraphManager: IMaterialGraphManager) {
        this.materialManager = new MaterialManager(textureManager, materialGraphManager)
        ;[this.canvas, this.renderer] = createCanvasAndRenderer()

        this.renderer.autoClear = true
        this.renderer.shadowMap.enabled = false
        this.renderer.setClearColor(0x000000, 0)
        this.renderer.toneMappingExposure = 1.0
        this.renderer.toneMapping = THREE.NoToneMapping

        this.camera.zoom = 1
        this.camera.position.set(0, 0, 1)
        this.camera.lookAt(0, 0, 0)

        this.scene.add(this.mesh)
    }

    dispose() {
        this.lock.dispose()
        this.renderer.forceContextLoss()
        this.renderer.renderLists.dispose()
        this.renderer.dispose()
        this.materialManager.dispose()
    }

    renderPBRMaps(
        materialData: IMaterialData,
        uvBounds: [number, number, number, number],
        imgSz: number,
        forcePowerOfTwo = true,
        defaultMimeType: "image/jpeg" | "image/png" = "image/jpeg",
        quality = 0.9,
        exportAlphaIfTransparent = true,
    ) {
        type OutputEntry = readonly [Uint8Array, typeof defaultMimeType]

        return this.materialManager.getMaterial(materialData).pipe(
            this.lock.synchronizeSwitchMap((orgMaterial) => {
                const renderer = this.renderer
                const canvas = this.canvas
                const camera = this.camera
                const scene = this.scene

                const mapData: {
                    diffuse?: OutputEntry
                    normal?: OutputEntry
                    metallicRoughness?: OutputEntry
                    specular?: OutputEntry
                    emission?: OutputEntry
                } = {
                    diffuse: undefined,
                    normal: undefined,
                    metallicRoughness: undefined,
                    specular: undefined,
                    emission: undefined,
                }

                const minU = uvBounds[0]
                const minV = uvBounds[1]
                const maxU = uvBounds[2]
                const maxV = uvBounds[3]

                const uSize = maxU - minU
                const vSize = maxV - minV
                let imgWidth = Math.round(uSize > vSize ? imgSz : (imgSz * uSize) / vSize)
                let imgHeight = Math.round(uSize > vSize ? (imgSz * vSize) / uSize : imgSz)
                if (forcePowerOfTwo) {
                    imgWidth = 2 ** Math.ceil(Math.log2(imgWidth))
                    imgHeight = 2 ** Math.ceil(Math.log2(imgHeight))
                }

                const pixelRatio = renderer.getPixelRatio()
                canvas.width = imgWidth / pixelRatio
                canvas.height = imgHeight / pixelRatio
                renderer.setSize(canvas.width, canvas.height)

                camera.right = imgWidth
                camera.top = imgHeight
                camera.updateProjectionMatrix()

                this.mesh.geometry = createQuadGeometry(0, 0, minU, maxV, imgWidth, imgHeight, maxU, minV) // v (y) is flipped
                const material = orgMaterial.clone()
                this.mesh.material = material
                const orgCacheKey = material.customProgramCacheKey()

                const pendingMaps: Observable<void>[] = []
                let diffuseHasAlpha = false

                const origAlphaTest = material.alphaTest
                material.alphaTest = 0
                const fixSpecularAlphaMask = origAlphaTest > 0

                const origHook = material.onBeforeCompile

                const renderMap = (outputMapName: keyof typeof mapData, encoding: THREE.ColorSpace) => {
                    let mimeType = defaultMimeType
                    let outputAlpha = false
                    if (outputMapName === "diffuse" && material.transparent && exportAlphaIfTransparent) {
                        mimeType = "image/png"
                        outputAlpha = true
                        diffuseHasAlpha = true
                    }

                    renderer.outputColorSpace = encoding

                    material.customProgramCacheKey = () => orgCacheKey + outputMapName
                    material.onBeforeCompile = (shader: THREE.WebGLProgramParametersWithUniforms, renderer: THREE.WebGLRenderer) => {
                        if (origHook) origHook(shader, renderer)

                        const patchPosition = shader.fragmentShader.lastIndexOf("}")
                        if (patchPosition < 0) throw Error("Failed to find location to patch shader!")

                        const getPatch = (): string => {
                            switch (outputMapName) {
                                case "diffuse":
                                    return outputAlpha ? "diffuseColor" : "vec4(diffuseColor.rgb, 1.0)"
                                case "normal":
                                    return "vec4((normal) * 0.5 + 0.5, 1.0)"
                                case "metallicRoughness":
                                    return fixSpecularAlphaMask
                                        ? "vec4(0.0, 1.0 + ((roughnessFactor) - 1.0)*(diffuseColor.a), (metalnessFactor)*(diffuseColor.a), 1.0)"
                                        : "vec4(0.0, roughnessFactor, metalnessFactor, 1.0)"
                                case "specular":
                                    return "vec4(specularColorFactor, 1.0)"
                                case "emission":
                                    return "vec4(totalEmissiveRadiance, 1.0)"
                            }
                        }

                        const patchedShader =
                            shader.fragmentShader.slice(0, patchPosition) +
                            `gl_FragColor = linearToOutputTexel(${getPatch()});` +
                            shader.fragmentShader.slice(patchPosition)
                        shader.fragmentShader = patchedShader
                    }

                    renderer.compile(scene, camera)
                    renderer.render(scene, camera)

                    //TODO: toDataURL will premultiply the alpha values! This is not correct when exporting to glTF. It won't matter for mask textures, but it may be a problem for things like glass.
                    const dataUrl = canvas.toDataURL(mimeType, quality)
                    pendingMaps.push(
                        observableFrom(fetch(dataUrl)).pipe(
                            switchMap((response) => {
                                return observableFrom(response.arrayBuffer())
                            }),
                            map((data) => {
                                mapData[outputMapName] = [new Uint8Array(data), mimeType] as const
                            }),
                        ),
                    )
                }

                renderMap("diffuse", THREE.SRGBColorSpace)
                renderMap("normal", THREE.LinearSRGBColorSpace)
                renderMap("metallicRoughness", THREE.LinearSRGBColorSpace)
                renderMap("specular", THREE.LinearSRGBColorSpace)
                if (material instanceof THREENodes.MeshStandardNodeMaterial && material.emissiveNode) renderMap("emission", THREE.SRGBColorSpace)

                let alphaType: "none" | "blend" | "mask" = "none"
                if (diffuseHasAlpha) {
                    alphaType = origAlphaTest > 0.5 ? "mask" : "blend"
                }

                return forkJoinZeroOrMore(pendingMaps).pipe(
                    map(() => {
                        this.mesh.geometry.dispose()
                        // @ts-expect-error
                        this.mesh.geometry = null
                        // @ts-expect-error
                        this.mesh.material = null
                        this.materialManager.releaseMaterial(orgMaterial)
                        return {mapData, alphaType, alphaTest: origAlphaTest}
                    }),
                )
            }),
        )
    }
}
