import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ContentTypeModel, PaymentState} from "@api"
import {IsDefined} from "@cm/utils/filter"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {forceEnum} from "@common/helpers/utils/enum"
import {ColormassEntity} from "@common/models/abilities"
import {PaymentStateService} from "@common/services/payment-state/payment-state.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Labels} from "@labels"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {catchError, EMPTY, filter, switchMap} from "rxjs"

@Component({
    selector: "cm-payment-state-label",
    standalone: true,
    imports: [StateLabelComponent, PlaceholderComponent],
    templateUrl: "./payment-state-label.component.html",
    styleUrl: "./payment-state-label.component.scss",
})
export class PaymentStateLabelComponent implements OnInit {
    @Input({required: true}) item!: {id: string} & ColormassEntity
    @Input() editable: boolean = false
    @Output() stateChange: EventEmitter<PaymentState> = new EventEmitter<PaymentState>()

    // null to show nothing, undefined for a loading placeholder
    paymentState?: PaymentState | null

    permission = inject(PermissionsService)
    destroyRef = inject(DestroyRef)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    paymentStateService = inject(PaymentStateService)
    $can = this.permission.$to

    public ngOnInit() {
        // show nothing if the user is not staff
        if (!this.$can().read.item(this.item, "paymentState")) {
            this.paymentState = null
            return
        }
        this.paymentState = null
        this.refresh
            .observeItem$(this.item)
            .pipe(
                filter(IsDefined),
                switchMap((item) =>
                    this.paymentStateService.fetch({
                        id: item.id,
                        __typename: forceEnum(item.__typename, ContentTypeModel),
                    }),
                ),
                catchError((error) => {
                    this.paymentState = null
                    console.error(error)
                    return EMPTY
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((item) => {
                this.paymentState = item?.paymentState ?? null
            })
    }

    protected readonly Labels = Labels
}
