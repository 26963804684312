import {ImageBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-base"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {HalImageChannelLayout, HalImageDataType, HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {isHalImageDescriptor} from "@common/helpers/hal"
import {Box2Like, Size2Like, Vector2Like} from "@cm/math"

export type ChannelLayout = HalImageChannelLayout
export type DataType = HalImageDataType
export type Batching = {
    patchSize: Size2Like // size of a patch in pixels
    batchSize: Size2Like // number of patches in each dimension
}
export type ImageDescriptor = HalImageDescriptor & {
    batching?: Batching
}

export function isImageDescriptor(obj?: unknown): obj is ImageDescriptor {
    return isHalImageDescriptor(obj)
}

export type ImageRefId = number | string
export type AddressSpace = "temporary" | "drawable" | "data-object"

export type ImageRef = {
    readonly isImageRef: true
    readonly addressSpace: AddressSpace
    readonly id: ImageRefId
    readonly descriptor: ImageDescriptor
    readonly view?: {
        readonly sourceImage: ImageRef
        readonly offset: Vector2Like
    }
    readonly debugInfo?: string
}

export function makeImageRef(addressSpace: AddressSpace, id: ImageRefId, descriptor: ImageDescriptor, debugInfo?: string): ImageRef {
    return {
        isImageRef: true,
        addressSpace,
        id,
        descriptor,
        debugInfo,
    }
}

export function isImageRef(obj: unknown): obj is ImageRef {
    return typeof obj === "object" && obj != null && "isImageRef" in obj && obj.isImageRef === true
}

export function resolveImageRefRegion(imageRef: ImageRef): Box2Like {
    return {x: imageRef.view?.offset.x ?? 0, y: imageRef.view?.offset.y ?? 0, width: imageRef.descriptor.width, height: imageRef.descriptor.height}
}

export function resolveOriginalImageRef(imageRef: ImageRef): ImageRef {
    return imageRef.view?.sourceImage ?? imageRef
}

export function resolveOriginalImageRefRegion(imageRef: ImageRef): Box2Like {
    const originalImageRef = resolveOriginalImageRef(imageRef)
    return {x: 0, y: 0, width: originalImageRef.descriptor.width, height: originalImageRef.descriptor.height}
}

export class RefCountedImageRef extends ImageBase implements ImageRef {
    readonly isImageRef = true

    constructor(addressSpace: AddressSpace, id: ImageRefId, descriptor: ImageDescriptor, disposeFn?: (image: ImageBase) => void, debugInfo?: string) {
        super(descriptor, addressSpace, id, disposeFn, debugInfo)
    }
}

export class ManagedImageRef extends SmartPtr<RefCountedImageRef> {}
