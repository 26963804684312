import {ImageColorSpace, ImageDataType} from "@api"
import {MediaType} from "@cm/utils/data-object"
import {blobToDataURL} from "@legacy/helpers/utils"
import {ITransientDataObject} from "@cm/material-nodes"
import {Observable} from "rxjs"

type TransientDataObjectProperties = {
    data: Uint8Array
    mediaType: MediaType
    imageColorSpace: ImageColorSpace
    imageColorProfile?: string
    imageDataType?: ImageDataType
}

export class TransientDataObject implements ITransientDataObject {
    data: Uint8Array
    mediaType: MediaType
    imageColorSpace: ImageColorSpace
    imageColorProfile?: string
    imageDataType?: ImageDataType
    constructor(args: TransientDataObjectProperties) {
        this.data = args.data
        this.mediaType = args.mediaType
        this.imageColorSpace = args.imageColorSpace
        this.imageColorProfile = args.imageColorProfile
        this.imageDataType = args.imageDataType
    }

    toBlob(): Blob {
        return new Blob([this.data], {type: this.mediaType})
    }

    toDataURL(): Observable<string> {
        return blobToDataURL(this.toBlob())
    }

    toObjectURL(): string {
        return URL.createObjectURL(this.toBlob())
    }
}
