import {Injectable} from "@angular/core"
import {ContentTypeModel, ImageColorSpace, ImageDataType} from "@api"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {ImageProcessingService} from "@app/common/services/rendering/image-processing.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {ImageCacheWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache-webgl2"
import {DataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {firstValueFrom} from "rxjs"

export type DataObjectImageDescriptor = {
    id: string
    legacyId: number
    width: number
    height: number
    imageDataType: ImageDataType
    imageColorSpace: ImageColorSpace
    downloadUrlExr: string
    downloadUrlJpg: string
}

@Injectable()
export class TexturesApiService {
    constructor(
        private sdk: SdkService,
        private refreshService: RefreshService,
        private imageProcessingService: ImageProcessingService,
    ) {}

    async queryTextureSetRevision(textureSetRevisionId: string) {
        return await this.sdk.gql.queryTextureSetRevisionData({id: textureSetRevisionId}).then((result) => result.textureSetRevision)
    }

    async queryDataObject(dataObjectId: string) {
        return await this.sdk.gql.queryTextureEditorDataObject({dataObjectId}).then((result) => result.dataObject)
    }

    async deleteTextureSetRevision(textureSetRevisionId: string) {
        console.info(`Deleting texture set revision ${textureSetRevisionId}`)
        const result = await this.sdk.gql.queryTextureSetRevisionTextureRevisionIds({id: textureSetRevisionId})
        await this.sdk.gql.deleteTextureSetRevision({id: textureSetRevisionId})
        // TODO remove this work-around once the implicit creation of texture set revisions from legacy texture revisions is removed
        if (result.textureSetRevision.textureSet.textureSetRevisions.length === 1) {
            // this was the last revision of the texture set, so we need to remove all legacy texture revisions as well, otherwise a new texture set revision will be recreated from those
            console.info(`Deleting all legacy textures and texture-revisions from texture-set ${result.textureSetRevision.textureSet.id}`)
            await Promise.all(
                result.textureSetRevision.textureSet.textures.flatMap((texture) =>
                    texture.revisions.map((revision) => this.sdk.gql.deleteTextureRevision({id: revision.id})),
                ),
            )
            await Promise.all(result.textureSetRevision.textureSet.textures.map((texture) => this.sdk.gql.deleteTexture({id: texture.id})))
        }
        // await Promise.all([
        //     ...result.textureSetRevision.mapAssignments.map((mapAssignment) => this.deleteDataObjectIfNotReferenced(mapAssignment.dataObject.id)),
        //     ...result.textureSetRevision.sourceDataObjectReferences.map((sourceDataObjectReference) =>
        //         this.deleteDataObjectIfNotReferenced(sourceDataObjectReference.dataObject.id),
        //     ),
        // ])
        this.refreshService.item({id: result.textureSetRevision.textureSet.id, __typename: ContentTypeModel.TextureSetRevision})
    }

    // async deleteDataObjectIfNotReferenced(dataObjectId: string) {
    //     const referencesAndRelated = await this.sdk.gql.queryDataObjectReferencesAndRelated({id: dataObjectId}).then((result) => result.dataObject)
    //     const isReferenced =
    //         referencesAndRelated.assignmentsCount > 0 ||
    //         referencesAndRelated.hdrisCount > 0 ||
    //         referencesAndRelated.materialRevisionsCount > 0 ||
    //         referencesAndRelated.textureRevisionsCount > 0 ||
    //         referencesAndRelated.textureSetRevisionMapAssignmentsCount > 0 ||
    //         referencesAndRelated.textureSetRevisionSourceDataObjectReferencesCount > 0
    //     if (!isReferenced) {
    //         console.info(`Deleting unreferenced data object ${dataObjectId}`)
    //         await this.sdk.gql.deleteDataObject({id: dataObjectId})
    //         // delete related data objects as well if not referenced
    //         await Promise.all(referencesAndRelated.related.map((related) => this.deleteDataObjectIfNotReferenced(related.id)))
    //     }
    // }

    // async deleteTextureRevisionIfNotReferenced(textureRevisionIdOrLegacyId: string | number) {
    //     const textureRevisionId =
    //         typeof textureRevisionIdOrLegacyId === "string"
    //             ? textureRevisionIdOrLegacyId
    //             : (await this.sdk.gql.resolveTextureRevisionLegacyId({legacyId: textureRevisionIdOrLegacyId})).textureRevision.id
    //     const result = await this.getTextureRevisionReferences(textureRevisionId)
    //     const isReferenced = result.textureSetRevisions.length > 0 || result.materialNodes.length > 0
    //     if (!isReferenced) {
    //         console.info(`Deleting unreferenced texture revision ${textureRevisionId}`)
    //         await this.sdk.gql.deleteTextureRevision({id: textureRevisionId})
    //         // await this.sdk.gql.texturesApiDeleteDataObject({id: result.textureRevision.dataObject.id})
    //     }
    // }

    // async deleteDataObjectAssignment(dataObjectAssignmentId: string) {
    //     console.info(`Deleting data object assignment ${dataObjectAssignmentId}`)
    //     const result = await this.sdk.gql.queryDataObjectAssignmentForDeletion({id: dataObjectAssignmentId})
    //     await this.sdk.gql.deleteDataObjectAssignment({id: dataObjectAssignmentId})
    //     if (result.dataObjectAssignment.dataObject.assignmentsCount === 1) {
    //         // this was the last assignment of the data object, so we can delete the data object itself
    //         console.info(`Deleting data object ${result.dataObjectAssignment.dataObject.id}`)
    //         await this.sdk.gql.deleteDataObject({id: result.dataObjectAssignment.dataObject.id})
    //     }
    // }

    async getDataObjectLegacyId(dataObjectId: string) {
        return await this.sdk.gql.queryTextureEditDataObjectLegacyId({id: dataObjectId}).then((result) => result.dataObject.legacyId)
    }

    // async getTextureRevisionLegacyId(textureRevisionId: string) {
    //     return await this.sdk.gql.queryTextureEditTextureRevisionLegacyId({id: textureRevisionId}).then((result) => result.textureRevision.legacyId)
    // }

    async getDataObjectImageDescriptor(dataObjectId: string, allowIncomplete = false) {
        const dataObject = await this.sdk.gql.queryTextureLoaderDataObjectImageDescriptor({id: dataObjectId}).then((result) => result.dataObject)
        if (!allowIncomplete) {
            if (!dataObject.width || !dataObject.height) {
                throw Error(`Data object ${dataObjectId} is missing width/height and therefore is an incomplete image descriptor.`)
            }
            if (!dataObject.jpgDataObject) {
                throw Error(`Data object ${dataObjectId} is missing a jpgDataObject and therefore is an incomplete image descriptor.`)
            }
        }
        const dataObjectImageDescriptor: DataObjectImageDescriptor = {
            id: dataObject.id,
            legacyId: dataObject.legacyId,
            width: dataObject.width ?? 0,
            height: dataObject.height ?? 0,
            imageDataType: dataObject.imageDataType ?? ImageDataType.NonColor,
            imageColorSpace: dataObject.imageColorSpace ?? ImageColorSpace.Linear,
            downloadUrlExr: dataObject.downloadUrlExr,
            downloadUrlJpg: dataObject.jpgDataObject?.downloadUrl ?? "",
        }
        return dataObjectImageDescriptor
    }

    async createHalImageFromDataObject(
        imageCache: ImageCacheWebGL2,
        dataObjectId: string,
        options?: {
            downloadFormat?: "exr" | "jpg" // defaults to "jpg"
            preferredOutputFormat?: DataType // defaults to "float32"
            allowIncomplete?: boolean // defaults to false
        },
    ): Promise<HalPaintableImage> {
        const downloadFormat = options?.downloadFormat ?? "jpg"
        const outputFormat = options?.preferredOutputFormat ?? "float32"
        const allowIncomplete = options?.allowIncomplete ?? false
        const dataObjectImageDescriptor = await this.getDataObjectImageDescriptor(dataObjectId, allowIncomplete)
        if (downloadFormat === "exr") {
            const data = await fetch(dataObjectImageDescriptor.downloadUrlExr)
                .then((response) => response.blob())
                .then((blob) => blob.arrayBuffer())
            const decodedImage = await firstValueFrom(this.imageProcessingService.decodeEXR(new Uint8Array(data)))
            const convertedImage = Nodes.convert(Nodes.input(decodedImage), "float32", "L", false)
            const evaledImage = await firstValueFrom(this.imageProcessingService.evalGraph(convertedImage))
            return await this.halPaintableImageFromImageData(imageCache, evaledImage.image, outputFormat)
        } else {
            // const halImage = createHalPaintableImage(halContext)
            // await halImage.create({
            //     url: dataObjectImageDescriptor.downloadUrlJpg,
            //     options: {useSRgbFormat: dataObjectImageDescriptor.imageDataType === ImageDataType.Color},
            // })
            // load image
            const htmlImageElement = new Image()
            htmlImageElement.crossOrigin = "anonymous"
            await new Promise<void>((resolve) => {
                htmlImageElement.onload = async () => {
                    resolve()
                }
                htmlImageElement.onerror = (error) => {
                    throw Error("Failed to load image: " + error)
                }
                htmlImageElement.src = dataObjectImageDescriptor.downloadUrlJpg
            })
            const image = await imageCache.getImage({
                width: htmlImageElement.width,
                height: htmlImageElement.height,
                channelLayout: "RGBA",
                dataType: "uint8",
                options: {useSRgbFormat: dataObjectImageDescriptor.imageDataType === ImageDataType.Color},
            })
            await image.create({htmlImageElement, options: {useSRgbFormat: dataObjectImageDescriptor.imageDataType === ImageDataType.Color}})
            return image
        }
    }

    private async halPaintableImageFromImageData(imageCache: ImageCacheWebGL2, imageData: TypedImageData, outputFormat: DataType): Promise<HalPaintableImage> {
        if (imageData.channelLayout !== "L") {
            throw Error("imageData layout must be L")
        }
        if (imageData.dataType !== "float32") {
            throw Error("imageData dataTyoe must be float32")
        }
        // const halImage = createHalPaintableImage(halContext)
        // await halImage.create({
        //     width: imageData.width,
        //     height: imageData.height,
        //     channelLayout: "R",
        //     dataType: outputFormat,
        // })
        const halImage = await imageCache.getImage({
            width: imageData.width,
            height: imageData.height,
            channelLayout: "R",
            dataType: outputFormat,
        })
        await halImage.writeRawImageData(imageData.dataType, new Float32Array(imageData.data.buffer))
        return halImage
    }
}
