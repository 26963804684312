import {BallRotation, FixedRotation, HingeRotation, Translation} from "#template-nodes/declare-transform-node"
import {Nodes as LegacyNodes} from "#template-nodes/legacy/template-nodes"
import {
    BooleanLike,
    ImageLike,
    isBooleanLike,
    isImageLike,
    isMaterialLike,
    isMesh,
    isNumberLike,
    isObjectLike,
    isStringLike,
    isTemplateLike,
    MaterialLike,
    Node,
    NumberLike,
    ObjectLike,
    StringLike,
    TemplateLike,
} from "#template-nodes/node-types"
import {Annotation} from "#template-nodes/nodes/annotation"
import {AreaLight} from "#template-nodes/nodes/area-light"
import {Camera} from "#template-nodes/nodes/camera"
import {ConfigGroup} from "#template-nodes/nodes/config-group"
import {ConfigVariant} from "#template-nodes/nodes/config-variant"
import {DataObjectReference} from "#template-nodes/nodes/data-object-reference"
import {BooleanExport, ImageExport, MaterialExport, NumberExport, ObjectExport, StringExport, TemplateExport} from "#template-nodes/nodes/export"
import {FindMaterial} from "#template-nodes/nodes/find-material"
import {Group} from "#template-nodes/nodes/group"
import {HDRILight} from "#template-nodes/nodes/hdri-light"
import {BooleanInput, ImageInput, MaterialInput, NumberInput, ObjectInput, StringInput, TemplateInput} from "#template-nodes/nodes/input"
import {LightPortal} from "#template-nodes/nodes/light-portal"
import {MaterialAssignment, MaterialAssignments} from "#template-nodes/nodes/material-assignment"
import {MaterialGraphReference} from "#template-nodes/nodes/material-graph-reference"
import {MaterialReference} from "#template-nodes/nodes/material-reference"
import {MeshDecal} from "#template-nodes/nodes/mesh-decal"
import {Nodes} from "#template-nodes/nodes/nodes"
import {BooleanOutput, ImageOutput, MaterialOutput, NumberOutput, ObjectOutput, StringOutput, TemplateOutput} from "#template-nodes/nodes/output"
import {OverlayMaterialColor} from "#template-nodes/nodes/overlay-material-color"
import {Parameters} from "#template-nodes/nodes/parameters"
import {PlaneGuide} from "#template-nodes/nodes/plane-guide"
import {PointGuide} from "#template-nodes/nodes/point-guide"
import {defaultsForToneMapping, PostProcessRender} from "#template-nodes/nodes/post-process-render"
import {ProceduralMesh} from "#template-nodes/nodes/procedural-mesh"
import {RegexReplace} from "#template-nodes/nodes/regex-replace"
import {Render} from "#template-nodes/nodes/render"
import {RigidRelation} from "#template-nodes/nodes/rigid-relation"
import {SceneProperties} from "#template-nodes/nodes/scene-properties"
import {StoredMesh} from "#template-nodes/nodes/stored-mesh"
import {StringResolve} from "#template-nodes/nodes/string-resolve"
import {
    BooleanLikes,
    BooleanSwitch,
    ImageLikes,
    ImageSwitch,
    MaterialLikes,
    MaterialSwitch,
    NumberLikes,
    NumberSwitch,
    ObjectLikes,
    ObjectSwitch,
    StringLikes,
    StringSwitch,
} from "#template-nodes/nodes/switch"
import {TemplateGraph} from "#template-nodes/nodes/template-graph"
import {TemplateInstance} from "#template-nodes/nodes/template-instance"
import {TemplateReference} from "#template-nodes/nodes/template-reference"
import {TransientDataObject} from "#template-nodes/nodes/transient-data-object"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "#template-nodes/nodes/value"
import {R1Variable, S1Variable, S3Variable} from "#template-nodes/nodes/variable"
import {AnyJSONValue, TemplateNode} from "#template-nodes/types"
import {parseColor} from "@cm/utils"

class UnknownNodeTypeError extends Error {}
class MaterialOverrideError extends Error {}
class UnknownImageTypeError extends Error {}
export class OverridesNotSupportedError extends Error {}
class UnknownExportTypeError extends Error {}
class UnknownMaterialTypeError extends Error {}
class NoMaterialRevisionIdError extends Error {}
class UnknownTextureTypeError extends Error {}
class UnknownInstanceTypeError extends Error {}
class ObjectReferenceTypeError extends Error {}
class TemplateDefinitionTypeError extends Error {}
class InputTypeError extends Error {}
class OutputTypeError extends Error {}
class RotationTypeError extends Error {}
class StringLikeError extends Error {}
class NumberLikeError extends Error {}
class BooleanLikeError extends Error {}
class SwitchError extends Error {}
class ObjectTypeError extends Error {}
class TopologyError extends Error {}
class MeshInstanceError extends Error {}
class MeshOrInstanceError extends Error {}
class NoTemplateError extends Error {}
class TemplateOrInstanceError extends Error {}
class DataObjectIdError extends Error {}

const stripType = <T extends {readonly type: string}>(node: T) => {
    const {type: _, ...props} = node
    return props
}

function cached(target: LegacyTemplateConverter, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function (...args: any[]) {
        const nodeCache = (this as any).nodeCache
        const node = args[0]
        const cacheValue = nodeCache.get(node)
        if (cacheValue !== undefined) return cacheValue

        const result = originalMethod.apply(this, args)
        nodeCache.set(node, result)

        return result
    }

    return descriptor
}

export class LegacyTemplateConverter {
    nodeCache = new Map<LegacyNodes.Node, TemplateNode>()

    @cached
    convertTemplateExport(node: LegacyNodes.TemplateExport) {
        if (!node.node) {
            console.warn("Template export has no node, it is not possible to determine the type of the export. Defaulting to material export", node)
            return new MaterialExport({
                name: node.name,
                id: node.id,
                node: null,
            })
        }

        const convertedNode = this.convertNode(node.node)
        if (isMaterialLike(convertedNode))
            return new MaterialExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else if (isTemplateLike(convertedNode))
            return new TemplateExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else if (isObjectLike(convertedNode))
            return new ObjectExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else if (isImageLike(convertedNode))
            return new ImageExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else if (isStringLike(convertedNode))
            return new StringExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else if (isNumberLike(convertedNode))
            return new NumberExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else if (isBooleanLike(convertedNode))
            return new BooleanExport({
                name: node.name,
                id: node.id,
                node: convertedNode,
            })
        else {
            throw new UnknownExportTypeError(`Unknown export type ${convertedNode.getNodeClass()}`)
        }
    }

    @cached
    convertMaterial(node: LegacyNodes.Material): MaterialLike {
        switch (node.type) {
            case "findMaterial":
                return this.convertFindMaterial(node)
            case "getTemplateOutput":
                return this.convertMaterialOutput(node)
            case "materialGraphReference":
                return this.convertMaterialGraphReference(node)
            case "materialReference":
                return this.convertMaterialReference(node)
            case "overlayMaterialColor":
                return this.convertOverlayMaterialColor(node)
            case "switch":
                return this.convertMaterialSwitch(node)
            case "templateInput":
                return this.convertTemplateMaterialInput(node)
            default:
                throw new UnknownMaterialTypeError(`Unknown material type ${(node as LegacyNodes.Material).type}`)
        }
    }

    @cached
    convertMaterialReference(node: LegacyNodes.MaterialReference) {
        const {type: _, materialRevisionId, allowOverride, id, ...props} = node

        if (allowOverride && id !== undefined)
            throw new MaterialOverrideError("Material reference has both allowOverride and id set. This legacy feature is not supported in the new system.")

        if (materialRevisionId === undefined || materialRevisionId === null)
            throw new NoMaterialRevisionIdError("Material reference has no material revision id")

        return new MaterialReference({materialRevisionId, ...props})
    }

    @cached
    convertFindMaterial(node: LegacyNodes.FindMaterial) {
        return new FindMaterial({
            customerId: node.customerId !== undefined ? convertNumberOrNumberLike(node.customerId, this) : undefined,
            articleId: node.articleId !== undefined ? convertStringOrStringLike(node.articleId, this) : undefined,
        })
    }

    @cached
    convertTexture(node: LegacyNodes.Texture): ImageLike {
        switch (node.type) {
            case "switch":
                return this.convertTextureSwitch(node)
            default:
                throw new UnknownTextureTypeError(`Unknown texture type ${(node as LegacyNodes.Texture).type}`)
        }
    }

    @cached
    convertObject(node: LegacyNodes.Object) {
        switch (node.type) {
            case "annotation":
                return this.convertAnnotation(node)
            case "areaLight":
                return this.convertAreaLight(node)
            case "camera":
                return this.convertCamera(node)
            case "instance":
                if (node.node.type === "mesh") return this.convertMeshInstance(node as LegacyNodes.Instance<LegacyNodes.Mesh>)
                else if (node.node.type === "proceduralMesh") return this.convertMeshInstance(node as LegacyNodes.Instance<LegacyNodes.ProceduralMesh>)
                else if (node.node.type === "templateReference")
                    return this.convertTemplateReferenceInstanceInstance(node as LegacyNodes.Instance<LegacyNodes.TemplateReferenceInstance>)
                else {
                    throw new UnknownInstanceTypeError(
                        `Unknown instance of type ${(node.node as LegacyNodes.Mesh | LegacyNodes.TemplateReferenceInstance).type}`,
                    )
                }
            case "lightPortal":
                return this.convertLightPortal(node)
            case "mesh":
                return this.convertMeshOrInstance(node)
            case "planeGuide":
                return this.convertPlaneGuide(node)
            case "pointGuide":
                return this.convertPointGuide(node)
            case "proceduralMesh":
                return this.convertMeshOrInstance(node)
            case "templateInstance":
                return this.convertTemplateInstance(node)
            case "templateReference":
                return this.convertTemplateReferenceInstance(node)
            default:
                throw new ObjectTypeError(`Unknown object type ${(node as LegacyNodes.Object).type}`)
        }
    }

    @cached
    convertAreaLight(node: LegacyNodes.AreaLight) {
        const {type: _, lockedTransform, $defaultTransform, target, visibleDirectly, visibleInReflections, visibleInRefractions, transparent, ...props} = node
        return new AreaLight({
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            target: convertPosition_JSON(target),
            targeted: true,
            visibleDirectly: visibleDirectly ?? true,
            visibleInReflections: visibleInReflections ?? true,
            visibleInRefractions: visibleInRefractions ?? true,
            transparent: transparent ?? false,
            lightType: "Corona",
            ...props,
        })
    }

    @cached
    convertLightPortal(node: LegacyNodes.LightPortal) {
        const {type: _, lockedTransform, $defaultTransform, ...props} = node
        return new LightPortal({
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            ...props,
        })
    }

    @cached
    convertHDRILight(node: LegacyNodes.HDRILight) {
        const {type: _, hdri, rotation, mirror, ...props} = node
        return new HDRILight({...props, rotation: [rotation[0] ?? 0, rotation[1] ?? 0, rotation[2] ?? 0], hdriId: hdri.hdriId, mirror: mirror ?? false})
    }

    @cached
    convertCamera(node: LegacyNodes.Camera) {
        const {
            type: _,
            automaticTarget,
            minAzimuthAngle,
            maxAzimuthAngle,
            lockedTransform,
            $defaultTransform,
            target,
            focalDistance,
            enableAr,
            enablePanning,
            screenSpacePanning,
            toneMapping,
            exposure,
            ...props
        } = node
        return new Camera({
            automaticTarget: automaticTarget ? this.convertObjectReference(automaticTarget) : undefined,
            minAzimuthAngle: minAzimuthAngle !== null ? minAzimuthAngle : undefined,
            maxAzimuthAngle: maxAzimuthAngle !== null ? maxAzimuthAngle : undefined,
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            target: convertPosition_JSON(target),
            targeted: true,
            focalDistance: typeof focalDistance === "object" ? 100 : focalDistance, //There is one single template where focalDistance is an object, but this is a mistake
            autoFocus: false,
            enablePanning: enablePanning ?? true,
            screenSpacePanning: screenSpacePanning ?? true,
            toneMapping: toneMapping ?? defaultsForToneMapping("linear"),
            ev: Math.log2(exposure),
            ...props,
        })
    }

    @cached
    convertPlaneGuide(node: LegacyNodes.PlaneGuide) {
        const {type: _, lockedTransform, $defaultTransform, ...props} = node
        return new PlaneGuide({
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            ...props,
        })
    }

    @cached
    convertPointGuide(node: LegacyNodes.PointGuide) {
        const {type: _, lockedTransform, $defaultTransform, ...props} = node
        return new PointGuide({
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            ...props,
        })
    }

    @cached
    convertAnnotation(node: LegacyNodes.Annotation) {
        const {type: _, lockedTransform, $defaultTransform, ...props} = node
        return new Annotation({
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            ...props,
        })
    }

    @cached
    convertRender(node: LegacyNodes.Render) {
        return new Render({
            width: convertNumberOrNumberLike(node.width, this),
            height: convertNumberOrNumberLike(node.height, this),
            samples: convertNumberOrNumberLike(node.samples, this),
            gpu: false,
            cloud: false,
        })
    }

    @cached
    convertPostProcessRender(node: LegacyNodes.PostProcessRender) {
        const getRender = (render: LegacyNodes.Render) => {
            if (render.type !== "render") {
                console.warn("Expected render, defaulting to undefined")
                return undefined
            }

            return this.convertRender(render)
        }

        const {
            type: _,
            render,
            exposure,
            whiteBalance,
            processShadows,
            shadowInner,
            shadowOuter,
            shadowFalloff,
            backgroundColor,
            lutUrl,
            composite,
            transparent,
            autoCrop,
            autoCropMargin,
            toneMapping,
            ...props
        } = node

        return new PostProcessRender({
            ...props,
            render: render ? getRender(render) : undefined,
            ev: exposure ? convertNumberOrNumberLike(exposure, this) : 0,
            whiteBalance: whiteBalance ? convertNumberOrNumberLike(whiteBalance, this) : 0,
            lutUrl: lutUrl ?? undefined,
            transparent: transparent ?? false,
            composite: composite ?? false,
            backgroundColor: backgroundColor ? parseColor(backgroundColor) : [1, 1, 1],
            processShadows: processShadows ?? true,
            shadowInner: shadowInner ?? 0,
            shadowOuter: shadowOuter ?? 0,
            shadowFalloff: shadowFalloff ?? 1,
            autoCrop: autoCrop ?? false,
            autoCropMargin: autoCropMargin ?? 50,
            toneMapping: toneMapping ?? defaultsForToneMapping("linear"),
        })
    }

    @cached
    convertObjectReference(node: LegacyNodes.ObjectReference): ObjectLike {
        switch (node.type) {
            case "annotation":
            case "areaLight":
            case "camera":
            case "instance":
            case "lightPortal":
            case "mesh":
            case "planeGuide":
            case "pointGuide":
            case "proceduralMesh":
            case "templateInstance":
            case "templateReference":
                return this.convertObject(node)
            case "getTemplateOutput":
                return this.convertObjectOutput(node)
            case "templateInput":
                return this.convertTemplateObjectInput(node)
            case "switch":
                return this.convertObjectReferenceSwitch(node)
            default:
                throw new ObjectReferenceTypeError(`Unknown object reference type ${(node as LegacyNodes.ObjectReference).type}`)
        }
    }

    @cached
    convertTemplateDefinition(node: LegacyNodes.TemplateDefinition): TemplateLike {
        switch (node.type) {
            case "templateReference":
                return this.convertTemplateReference(node)
            case "templateGraph":
                return this.convertTemplateGraph(node)
            case "getTemplateOutput":
                return this.convertTemplateOutput(node)
            case "templateInput":
                return this.convertTemplateTemplateInput(node)
            default:
                throw new TemplateDefinitionTypeError(`Unknown template definition type ${(node as LegacyNodes.TemplateDefinition).type}`)
        }
    }

    @cached
    convertImage(node: LegacyNodes.Image): ImageLike {
        switch (node.type) {
            case "dataObjectReference":
                return this.convertDataObjectReference(node)
            case "transientDataObject":
                return this.convertTransientDataObject(node)
            case "switch":
                return this.convertTextureSwitch(node)
            case "templateInput":
                return this.convertTemplateImageInput(node)
            case "getTemplateOutput":
                return this.convertImageOutput(node)
            default:
                throw new UnknownImageTypeError(`Unknown image type ${(node as LegacyNodes.Image).type}`)
        }
    }

    @cached
    convertStringValue(node: LegacyNodes.Value<string>) {
        return new StringValue(stripType(node))
    }

    @cached
    convertStringResolve(node: LegacyNodes.ResolveNode<string>) {
        const {type: _, name, template, ...props} = node
        return new StringResolve({...props, name: name ?? "New String Resolve", template: this.convertTemplateDefinition(template)})
    }

    @cached
    convertNumberValue(node: LegacyNodes.Value<number>) {
        return new NumberValue(stripType(node))
    }

    @cached
    convertBooleanValue(node: LegacyNodes.Value<boolean>) {
        return new BooleanValue(stripType(node))
    }

    @cached
    convertJSONValue(node: LegacyNodes.Value<any>) {
        return new JSONValue(stripType(node))
    }

    @cached
    convertValue(node: LegacyNodes.Value<any>) {
        if (typeof node.value === "string") return this.convertStringValue(node)
        else if (typeof node.value === "number") return this.convertNumberValue(node)
        else if (typeof node.value === "boolean") return this.convertBooleanValue(node)
        else return this.convertJSONValue(node)
    }

    @cached
    convertTemplateMaterialInput(node: LegacyNodes.TemplateMaterialInput) {
        return new MaterialInput({
            name: node.name,
            id: node.id,
            default: node.default && node.default !== node ? this.convertMaterial(node.default) : undefined,
        })
    }

    @cached
    convertTemplateObjectInput(node: LegacyNodes.TemplateObjectInput) {
        return new ObjectInput({
            name: node.name,
            id: node.id,
            default: node.default && node.default !== node ? this.convertObjectReference(node.default) : undefined,
        })
    }

    @cached
    convertTemplateTemplateInput(node: LegacyNodes.TemplateTemplateInput) {
        return new TemplateInput({
            name: node.name,
            id: node.id,
            default: node.default && node.default !== node ? this.convertTemplateDefinition(node.default) : undefined,
        })
    }

    @cached
    convertTemplateImageInput(node: LegacyNodes.TemplateImageInput) {
        return new ImageInput({
            name: node.name,
            id: node.id,
            default: node.default && node.default !== node ? this.convertImage(node.default) : undefined,
        })
    }

    @cached
    convertTemplateStringInput(node: LegacyNodes.TemplateStringInput) {
        return new StringInput({
            name: node.name,
            id: node.id,
            default: node.default
                ? typeof node.default === "string"
                    ? node.default
                    : node.default.type === "value"
                      ? this.convertStringValue(node.default)
                      : this.convertStringResolve(node.default)
                : undefined,
        })
    }

    @cached
    convertTemplateNumberInput(node: LegacyNodes.TemplateNumberInput) {
        return new NumberInput({
            name: node.name,
            id: node.id,
            default: node.default ? (typeof node.default === "number" ? node.default : this.convertNumberValue(node.default)) : undefined,
        })
    }

    @cached
    convertTemplateBooleanInput(node: LegacyNodes.TemplateBooleanInput) {
        return new BooleanInput({
            name: node.name,
            id: node.id,
            default: node.default ? (typeof node.default === "boolean" ? node.default : this.convertBooleanValue(node.default)) : undefined,
        })
    }

    @cached
    convertTemplateInput(node: LegacyNodes.TemplateInput) {
        switch (node.inputType) {
            case "material":
                return this.convertTemplateMaterialInput(node)
            case "object":
                return this.convertTemplateObjectInput(node)
            case "template":
                return this.convertTemplateTemplateInput(node)
            case "image":
                return this.convertTemplateImageInput(node)
            case "string":
                return this.convertTemplateStringInput(node)
            case "number":
                return this.convertTemplateNumberInput(node)
            case "boolean":
                return this.convertTemplateBooleanInput(node)
            default:
                throw new InputTypeError(`Unknown input type ${(node as LegacyNodes.TemplateInput).inputType}`)
        }
    }

    @cached
    convertMaterialOutput(node: LegacyNodes.MaterialOutput) {
        return new MaterialOutput({
            name: node.name ?? "New Material Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertObjectOutput(node: LegacyNodes.ObjectOutput) {
        return new ObjectOutput({
            name: node.name ?? "New Object Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertTemplateOutput(node: LegacyNodes.TemplateOutput) {
        return new TemplateOutput({
            name: node.name ?? "New Template Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertImageOutput(node: LegacyNodes.ImageOutput) {
        return new ImageOutput({
            name: node.name ?? "New Image Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertStringOutput(node: LegacyNodes.StringOutput) {
        return new StringOutput({
            name: node.name ?? "New String Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertNumberOutput(node: LegacyNodes.NumberOutput) {
        return new NumberOutput({
            name: node.name ?? "New Number Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertBooleanOutput(node: LegacyNodes.BooleanOutput) {
        return new BooleanOutput({
            name: node.name ?? "New Boolean Output",
            outputId: node.outputId,
            template: this.convertTemplateOrInstance(node.template),
        })
    }

    @cached
    convertGetTemplateOutput(node: LegacyNodes.Outputs) {
        switch (node.outputType) {
            case "material":
                return this.convertMaterialOutput(node)
            case "object":
                return this.convertObjectOutput(node)
            case "template":
                return this.convertTemplateOutput(node)
            case "image":
                return this.convertImageOutput(node)
            case "string":
                return this.convertStringOutput(node)
            case "number":
                return this.convertNumberOutput(node)
            case "boolean":
                return this.convertBooleanOutput(node)
            default:
                throw new OutputTypeError(`Unknown output type ${node.outputType}`)
        }
    }

    @cached
    convertTranslation(node: LegacyNodes.RelationTranslation) {
        const convertR1Variable = (node: number | LegacyNodes.Variable_R1) => {
            if (typeof node === "number") return node
            else return this.convertVariableR1(node)
        }
        return new Translation({x: convertR1Variable(node.x), y: convertR1Variable(node.y), z: convertR1Variable(node.z)})
    }

    @cached
    convertRotation(node: LegacyNodes.RelationRotation) {
        if (node.type === "fixed") {
            const {type: _, ...props} = node
            return new FixedRotation(props)
        } else if (node.type === "hinge") {
            const {type: _, rotation: a, ...props} = node
            return new HingeRotation({...props, rotation: this.convertVariableS1(a)})
        } else if (node.type === "ball") {
            const {type: _, rotation: a, ...props} = node
            return new BallRotation({...props, rotation: this.convertVariableS3(a)})
        } else {
            throw new RotationTypeError(`Unknown rotation type ${(node as LegacyNodes.RelationRotation).type}`)
        }
    }

    @cached
    convertRigidRelation(node: LegacyNodes.RigidRelation) {
        const {type: _, translation, rotation, targetA, targetB, ...props} = node

        return new RigidRelation({
            ...props,
            translation: this.convertTranslation(translation),
            rotation: this.convertRotation(rotation),
            targetA: !targetA ? null : this.convertObjectReference(targetA),
            targetB: !targetB ? null : this.convertObjectReference(targetB),
        })
    }

    @cached
    convertTextureSwitch(node: LegacyNodes.Switch<LegacyNodes.Texture>) {
        return new ImageSwitch({
            name: node.name ?? "New Switch",
            nodes: new ImageLikes({list: tryMap(node, filterCyclicNodes(node, node.nodes), this.convertTexture.bind(this))}),
        })
    }

    @cached
    convertMaterialSwitch(node: LegacyNodes.Switch<LegacyNodes.Material>) {
        return new MaterialSwitch({
            name: node.name ?? "New Switch",
            nodes: new MaterialLikes({list: tryMap(node, filterCyclicNodes(node, node.nodes), this.convertMaterial.bind(this))}),
        })
    }

    @cached
    convertObjectReferenceSwitch(node: LegacyNodes.Switch<LegacyNodes.ObjectReference>) {
        return new ObjectSwitch({
            name: node.name ?? "New Switch",
            nodes: new ObjectLikes({list: tryMap(node, filterCyclicNodes(node, node.nodes), this.convertObjectReference.bind(this))}),
        })
    }

    @cached
    convertStringLikeSwitch(node: LegacyNodes.Switch<LegacyNodes.StringLike>) {
        return new StringSwitch({
            name: node.name ?? "New Switch",
            nodes: new StringLikes({list: tryMap(node, filterCyclicNodes(node, node.nodes), this.convertStringLike.bind(this))}),
        })
    }

    @cached
    convertNumberLikeSwitch(node: LegacyNodes.Switch<LegacyNodes.NumberLike>) {
        return new NumberSwitch({
            name: node.name ?? "New Switch",
            nodes: new NumberLikes({list: tryMap(node, filterCyclicNodes(node, node.nodes), this.convertNumberLike.bind(this))}),
        })
    }

    @cached
    convertBooleanLikeSwitch(node: LegacyNodes.Switch<LegacyNodes.BooleanLike>) {
        return new BooleanSwitch({
            name: node.name ?? "New Switch",
            nodes: new BooleanLikes({list: tryMap(node, filterCyclicNodes(node, node.nodes), this.convertBooleanLike.bind(this))}),
        })
    }

    @cached
    convertStringLike(node: LegacyNodes.StringLike): StringLike {
        switch (node.type) {
            case "value":
                return this.convertStringValue(node)
            case "switch":
                return this.convertStringLikeSwitch(node)
            case "getTemplateOutput":
                return this.convertStringOutput(node)
            case "resolveNode":
                return this.convertStringResolve(node)
            case "templateInput":
                return this.convertTemplateStringInput(node)
            default:
                throw new StringLikeError(`Unknown string like type ${(node as LegacyNodes.StringLike).type}`)
        }
    }

    @cached
    convertNumberLike(node: LegacyNodes.NumberLike): NumberLike {
        switch (node.type) {
            case "value":
                return this.convertNumberValue(node)
            case "switch":
                return this.convertNumberLikeSwitch(node)
            case "getTemplateOutput":
                return this.convertNumberOutput(node)
            case "templateInput":
                return this.convertTemplateNumberInput(node)
            default:
                throw new NumberLikeError(`Unknown number like type ${(node as LegacyNodes.NumberLike).type}`)
        }
    }

    @cached
    convertBooleanLike(node: LegacyNodes.BooleanLike): BooleanLike {
        switch (node.type) {
            case "value":
                return this.convertBooleanValue(node)
            case "switch":
                return this.convertBooleanLikeSwitch(node)
            case "getTemplateOutput":
                return this.convertBooleanOutput(node)
            case "templateInput":
                return this.convertTemplateBooleanInput(node)
            default:
                throw new BooleanLikeError(`Unknown boolean like type ${(node as LegacyNodes.BooleanLike).type}`)
        }
    }

    @cached
    convertVariableR1(node: LegacyNodes.Variable_R1) {
        return new R1Variable({range: node.range, default: node.default})
    }

    @cached
    convertVariableS1(node: LegacyNodes.Variable_S1) {
        return new S1Variable({default: node.default})
    }

    @cached
    convertVariableS3(node: LegacyNodes.Variable_S3) {
        return new S3Variable({default: node.default})
    }

    @cached
    convertVariable(node: LegacyNodes.Variable) {
        if (node.topology === "boundedReal") return this.convertVariableR1(node)
        else if (node.topology === "1-sphere") return this.convertVariableS1(node)
        else if (node.topology === "3-sphere") return this.convertVariableS3(node)
        else {
            throw new TopologyError(`Unsupported variable topology ${node.topology}`)
        }
    }

    @cached
    convertStoredMesh(node: LegacyNodes.StoredMesh) {
        const getDisplacementTexture = (displacementTexture: LegacyNodes.Image | undefined) => {
            if (!displacementTexture) return undefined
            if (
                displacementTexture.type === "dataObjectReference" &&
                (displacementTexture.dataObjectId === null || displacementTexture.dataObjectId === undefined)
            ) {
                console.warn("Displacement texture has no data object id, defaulting to undefined", displacementTexture)
                return undefined
            }
            return this.convertImage(displacementTexture)
        }

        const {
            type: _,
            displacementTexture,
            materialAssignments,
            surfaces,
            subdivisionRenderIterations,
            lockedTransform,
            $defaultTransform,
            drcDataObjectId,
            plyDataObjectId,
            visibleDirectly,
            visibleInReflections,
            visibleInRefractions,
            metadata,
            name,
            ...props
        } = node

        const nameWithDefault = name ?? "New Stored Mesh"

        return new StoredMesh({
            ...props,
            drcDataObject: new DataObjectReference({name: `${nameWithDefault}.drc`, dataObjectId: drcDataObjectId}),
            plyDataObject: new DataObjectReference({name: `${nameWithDefault}.ply`, dataObjectId: plyDataObjectId}),
            displacementTexture: getDisplacementTexture(displacementTexture),
            materialAssignments: convertMaterialAssignments(materialAssignments, this),
            subdivisionRenderIterations: subdivisionRenderIterations !== null ? subdivisionRenderIterations : undefined,
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            visibleDirectly: visibleDirectly ?? true,
            visibleInReflections: visibleInReflections ?? true,
            visibleInRefractions: visibleInRefractions ?? true,
            castRealtimeShadows: true,
            receiveRealtimeShadows: true,
            metaData: metadata,
            name: nameWithDefault,
        })
    }

    @cached
    convertProceduralMesh(node: LegacyNodes.ProceduralMesh) {
        const {
            type: _,
            displacementTexture,
            materialAssignments,
            surfaces,
            subdivisionRenderIterations,
            lockedTransform,
            $defaultTransform,
            visibleDirectly,
            visibleInReflections,
            visibleInRefractions,
            ...props
        } = node
        return new ProceduralMesh({
            ...props,
            displacementTexture: displacementTexture ? this.convertImage(displacementTexture) : undefined,
            materialAssignments: convertMaterialAssignments(materialAssignments, this),
            subdivisionRenderIterations: subdivisionRenderIterations !== null ? subdivisionRenderIterations : undefined,
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
            visibleDirectly: visibleDirectly ?? true,
            visibleInReflections: visibleInReflections ?? true,
            visibleInRefractions: visibleInRefractions ?? true,
            castRealtimeShadows: true,
            receiveRealtimeShadows: true,
        })
    }

    @cached
    convertMeshInstance(node: LegacyNodes.Instance<LegacyNodes.Mesh>) {
        if (node.node.type === "mesh") {
            const {type: _, node: storedMeshInstance, ...instanceProps} = node as LegacyNodes.Instance<LegacyNodes.StoredMesh>
            return this.convertStoredMesh({...storedMeshInstance, ...instanceProps})
        } else if (node.node.type === "proceduralMesh") {
            const {type: _, node: proceduralMeshInstance, ...instanceProps} = node as LegacyNodes.Instance<LegacyNodes.ProceduralMesh>
            return this.convertProceduralMesh({...proceduralMeshInstance, ...instanceProps})
        } else {
            throw new MeshInstanceError(`Unknown mesh instance type ${(node.node as LegacyNodes.Mesh).type}`)
        }
    }

    @cached
    convertMeshOrInstance(node: LegacyNodes.MeshOrInstance) {
        if (node.type === "instance") return this.convertMeshInstance(node)
        else if (node.type === "mesh") return this.convertStoredMesh(node)
        else if (node.type === "proceduralMesh") return this.convertProceduralMesh(node)
        else {
            throw new MeshOrInstanceError(`Unknown mesh or instance type ${(node as LegacyNodes.MeshOrInstance).type}`)
        }
    }

    @cached
    convertTemplateReferenceInstance(node: LegacyNodes.TemplateReferenceInstance) {
        const {type: _, templateRevisionId, selectedConfigVariants, parameters, overrides, lockedTransform, $defaultTransform, ...props} = node

        const handleOverrides = () => {
            if (overrides) {
                let inputForOverrideSet = node.templateRevisionId === 655 && node.parameters && node.parameters["5036c55b-3fa4-43df-bd04-047acf408a03"]
                if (!inputForOverrideSet) {
                    if (node.templateRevisionId === 655 && Object.keys(overrides).length === 1) {
                        //@ts-ignore
                        const {materialRevisionId} = overrides["94c70d09-a7ba-4046-bd5a-22d3322e9f67"]
                        if (materialRevisionId) {
                            if (!node.parameters) node.parameters = {}
                            node.parameters["5036c55b-3fa4-43df-bd04-047acf408a03"] = {materialRevisionId, name: "Material", type: "materialReference"}
                            inputForOverrideSet = true
                        }
                    }
                }

                if (!inputForOverrideSet)
                    throw new OverridesNotSupportedError(node.name + " " + Object.keys(overrides).join(",") + " Overrides are not supported in the new system")
            }
        }

        handleOverrides()

        return new TemplateInstance({
            ...props,
            parameters: convertParameters(selectedConfigVariants, parameters, this),
            template: new TemplateReference({templateRevisionId}),
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
        })
    }

    @cached
    convertTemplateReferenceInstanceInstance(node: LegacyNodes.Instance<LegacyNodes.TemplateReferenceInstance>) {
        const {type: _, node: templateReferenceInstance, ...instanceProps} = node
        return this.convertTemplateReferenceInstance({...templateReferenceInstance, ...instanceProps})
    }

    @cached
    convertTemplateInstance(node: LegacyNodes.TemplateInstance) {
        const {type: _, template, selectedConfigVariants, parameters, overrides, lockedTransform, $defaultTransform, ...props} = node

        if (!template) {
            throw new NoTemplateError("Template instance has no template")
        }

        if (overrides) {
            throw new OverridesNotSupportedError(node.name + " " + Object.keys(overrides).join(",") + " Overrides are not supported in the new system")
        }

        return new TemplateInstance({
            ...props,
            parameters: convertParameters(selectedConfigVariants, parameters, this),
            template: this.convertTemplateDefinition(template),
            lockedTransform: lockedTransform ? convertMatrix4_JSON(lockedTransform) : undefined,
            $defaultTransform: $defaultTransform ? convertMatrix4_JSON($defaultTransform) : undefined,
            visible: true,
        })
    }

    @cached
    convertTemplateOrInstance(node: LegacyNodes.TemplateOrInstance) {
        if (node.type === "instance") return this.convertTemplateReferenceInstanceInstance(node)
        else if (node.type === "templateReference") return this.convertTemplateReferenceInstance(node)
        else if (node.type === "templateInstance") return this.convertTemplateInstance(node)
        else {
            throw new TemplateOrInstanceError(`Unknown template or instance type ${(node as LegacyNodes.TemplateOrInstance).type}`)
        }
    }

    @cached
    convertDataObjectReference(node: LegacyNodes.DataObjectReference) {
        const {type: _, dataObjectId, ...props} = node
        if (dataObjectId === undefined || dataObjectId === null) {
            throw new DataObjectIdError("Data object reference has no data object id")
        }
        return new DataObjectReference({name: "Data Object Reference", dataObjectId, ...props})
    }

    @cached
    convertTemplateReference(node: LegacyNodes.TemplateReference) {
        return new TemplateReference(stripType(node))
    }

    @cached
    convertResolveConfigVariant(node: LegacyNodes.ResolveNode<LegacyNodes.ConfigVariant>) {
        const {type: _, template, ...props} = node
        return new StringResolve({name: "New String Resolve", ...props, template: this.convertTemplateDefinition(template)})
    }

    @cached
    convertMaterialGraphReference(node: LegacyNodes.MaterialGraphReference) {
        return new MaterialGraphReference(stripType(node))
    }

    @cached
    convertGroup(node: LegacyNodes.Group) {
        const {type: _, nodes, ...props} = node

        const active = (() => {
            if (typeof node.active === "boolean") return node.active
            const converted = this.convertNode(node.active)
            if (isBooleanLike(converted)) return converted
            else {
                throw new BooleanLikeError(`Unknown boolean like type ${node.type}`)
            }
        })()

        return new Group({
            ...props,
            nodes: new Nodes({list: tryMap(node, filterInvalidNodes(node, node.nodes), this.convertNode.bind(this))}),
            active,
        })
    }

    @cached
    convertConfigVariant(node: LegacyNodes.ConfigVariant) {
        const {type: _, nodes, iconDataObjectId, ...props} = node
        return new ConfigVariant({
            iconDataObject: iconDataObjectId ? new DataObjectReference({name: "Data Object Reference", dataObjectId: iconDataObjectId}) : undefined,
            ...props,
            nodes: new Nodes({list: tryMap(node, filterInvalidNodes(node, node.nodes), this.convertNode.bind(this))}),
        })
    }

    @cached
    convertConfigGroup(node: LegacyNodes.ConfigGroup) {
        const {type: _, nodes, displayWithLabels, ...props} = node
        return new ConfigGroup({
            displayWithLabels: displayWithLabels ?? false,
            ...props,
            nodes: new Nodes({list: tryMap(node, filterInvalidNodes(node, node.nodes), this.convertNode.bind(this))}),
        })
    }

    @cached
    convertTemplateGraph(node: LegacyNodes.TemplateGraph) {
        const {type: _, nodes, name, schema, ...props} = node
        return new TemplateGraph({
            name: name ?? "Untitled Template",
            ...props,
            nodes: new Nodes({list: tryMap(node, filterInvalidNodes(node, node.nodes), this.convertNode.bind(this))}),
        })
    }

    @cached
    convertSceneProperties(node: LegacyNodes.SceneProperties) {
        const {
            type: _,
            backgroundColor,
            uiColor,
            iconSize,
            focusMode,
            uiStyle,
            enableAr,
            enableSalesEnquiry,
            textureResolution,
            textureFiltering,
            enableOnboardingHint,
            enableGltfDownload,
            enableStlDownload,
            enablePdfGeneration,
            enableSnapshot,
            enableFullscreen,
            environmentMapMode,
            showAnnotations,
            enableAdaptiveSubdivision,
            ...props
        } = node

        return new SceneProperties({
            backgroundColor: backgroundColor ?? undefined,
            uiColor: uiColor ?? [0, 0, 0],
            iconSize: iconSize !== undefined ? stringOrNumberToNumber(iconSize) : 24,
            uiStyle: uiStyle ?? "default",
            enableAr: enableAr ?? false,
            enableSalesEnquiry: enableSalesEnquiry ?? false,
            textureResolution: textureResolution ?? "2000px",
            textureFiltering: textureFiltering ?? false,
            enableRealtimeShadows: true,
            enableRealtimeLights: true,
            enableRealtimeMaterials: true,
            enableOnboardingHint: enableOnboardingHint ?? false,
            enableGltfDownload: enableGltfDownload ?? false,
            enableStlDownload: enableStlDownload ?? false,
            enablePdfGeneration: enablePdfGeneration ?? false,
            enableSnapshot: enableSnapshot ?? true,
            enableFullscreen: enableFullscreen ?? true,
            environmentMapMode: environmentMapMode ?? "full",
            showAnnotations: showAnnotations ?? true,
            enableAdaptiveSubdivision: enableAdaptiveSubdivision ?? false,
            ...props,
        })
    }

    @cached
    convertTransientDataObject(node: LegacyNodes.TransientDataObject) {
        return new TransientDataObject(stripType(node))
    }

    @cached
    convertMeshDecal(node: LegacyNodes.MeshDecal) {
        const {type: _, mesh, mask, color, size, material, invertMask, maskType, ...props} = node
        const ensureDataObjectReference = (image: ImageLike) => {
            if (image instanceof DataObjectReference) return image
            else return undefined
        }
        return new MeshDecal({
            ...props,
            mesh: !mesh ? null : this.convertMeshOrInstance(mesh),
            mask: mask ? ensureDataObjectReference(this.convertImage(mask)) : undefined,
            color: color ? ensureDataObjectReference(this.convertImage(color)) : undefined,
            size: [size[0] ?? null, size[1] ?? null],
            materialAssignment: material ? new MaterialAssignment({node: this.convertMaterial(material), side: "front"}) : null,
            invertMask: invertMask ?? false,
            maskType: maskType ?? "binary",
            visible: true,
        })
    }

    @cached
    convertOverlayMaterialColor(node: LegacyNodes.OverlayMaterialColor) {
        const {material, overlay, size} = node
        return new OverlayMaterialColor({
            material: this.convertMaterial(material!),
            overlay: this.convertImage(overlay!),
            size: [convertNumberOrNumberLike(size[0], this), convertNumberOrNumberLike(size[1], this)],
        })
    }

    @cached
    convertRegexReplace(node: LegacyNodes.RegexReplace) {
        const {input, regex, replace} = node
        return new RegexReplace({
            input: convertStringOrStringLike(input, this),
            regex: convertStringOrStringLike(regex, this),
            replace: convertStringOrStringLike(replace, this),
        })
    }

    @cached
    convertSwitch(node: LegacyNodes.Switch<any>) {
        if (node.nodes.length === 0) {
            console.warn("Switch node has no nodes, it is not possible to determine the type of the switch. Defaulting to material switch", node)
            return this.convertMaterialSwitch(node as LegacyNodes.Switch<LegacyNodes.Material>)
        }

        const firstNode = node.nodes[0]

        const switchType = this.convertNode(firstNode)
        if (isImageLike(switchType)) return this.convertTextureSwitch(node as LegacyNodes.Switch<LegacyNodes.Texture>)
        else if (isMaterialLike(switchType)) return this.convertMaterialSwitch(node as LegacyNodes.Switch<LegacyNodes.Material>)
        else if (isObjectLike(switchType)) return this.convertObjectReferenceSwitch(node as LegacyNodes.Switch<LegacyNodes.ObjectReference>)
        else if (isStringLike(switchType)) return this.convertStringLikeSwitch(node as LegacyNodes.Switch<LegacyNodes.StringLike>)
        else if (isNumberLike(switchType)) return this.convertNumberLikeSwitch(node as LegacyNodes.Switch<LegacyNodes.NumberLike>)
        else if (isBooleanLike(switchType)) return this.convertBooleanLikeSwitch(node as LegacyNodes.Switch<LegacyNodes.BooleanLike>)
        else {
            throw new SwitchError(`Unknown switch type ${firstNode.type}`)
        }
    }

    @cached
    convertInstance(node: LegacyNodes.Instance<LegacyNodes.Mesh> | LegacyNodes.Instance<LegacyNodes.TemplateReferenceInstance>) {
        const converted = this.convertNode(node.node)
        if (isMesh(converted)) return this.convertMeshInstance(node as LegacyNodes.Instance<LegacyNodes.Mesh>)
        else if (converted instanceof TemplateInstance)
            return this.convertTemplateReferenceInstanceInstance(node as LegacyNodes.Instance<LegacyNodes.TemplateReferenceInstance>)
        else {
            throw new UnknownInstanceTypeError(`Unknown instance of type ${node.node.type}`)
        }
    }

    @cached
    convertNode(node: LegacyNodes.Node): Node {
        if (node.type === "switch") {
            return this.convertSwitch(node)
        } else if (node.type === "value") {
            return this.convertValue(node)
        } else if (node.type === "templateExport") {
            return this.convertTemplateExport(node)
        } else if (node.type === "templateInput") {
            return this.convertTemplateInput(node)
        } else if (node.type === "variable") {
            return this.convertVariable(node)
        } else if (node.type === "instance") {
            return this.convertInstance(node)
        } else if (node.type === "dataObjectReference") {
            return this.convertDataObjectReference(node)
        } else if (node.type === "mesh") {
            return this.convertStoredMesh(node)
        } else if (node.type === "proceduralMesh") {
            return this.convertProceduralMesh(node)
        } else if (node.type === "templateReference") {
            const isTemplateReferenceInstance = (
                node: LegacyNodes.TemplateReferenceInstance | LegacyNodes.TemplateReference,
            ): node is LegacyNodes.TemplateReferenceInstance => {
                return typeof (node as LegacyNodes.TemplateReferenceInstance).id === "string"
            }
            if (isTemplateReferenceInstance(node)) return this.convertTemplateReferenceInstance(node)
            else return this.convertTemplateReference(node)
        } else if (node.type === "templateInstance") {
            return this.convertTemplateInstance(node)
        } else if (node.type === "resolveNode") {
            return this.convertResolveConfigVariant(node)
        } else if (node.type === "getTemplateOutput") {
            return this.convertGetTemplateOutput(node)
        } else if (node.type === "materialReference") {
            return this.convertMaterialReference(node)
        } else if (node.type === "materialGraphReference") {
            return this.convertMaterialGraphReference(node)
        } else if (node.type === "findMaterial") {
            return this.convertFindMaterial(node)
        } else if (node.type === "areaLight") {
            return this.convertAreaLight(node)
        } else if (node.type === "lightPortal") {
            return this.convertLightPortal(node)
        } else if (node.type === "hdriLight") {
            return this.convertHDRILight(node)
        } else if (node.type === "camera") {
            return this.convertCamera(node)
        } else if (node.type === "planeGuide") {
            return this.convertPlaneGuide(node)
        } else if (node.type === "pointGuide") {
            return this.convertPointGuide(node)
        } else if (node.type === "annotation") {
            return this.convertAnnotation(node)
        } else if (node.type === "render") {
            return this.convertRender(node)
        } else if (node.type === "postProcessRender") {
            return this.convertPostProcessRender(node)
        } else if (node.type === "rigidRelation") {
            return this.convertRigidRelation(node)
        } else if (node.type === "group") {
            return this.convertGroup(node)
        } else if (node.type === "configVariant") {
            return this.convertConfigVariant(node)
        } else if (node.type === "configGroup") {
            return this.convertConfigGroup(node)
        } else if (node.type === "templateGraph") {
            return this.convertTemplateGraph(node)
        } else if (node.type === "sceneProperties") {
            return this.convertSceneProperties(node)
        } else if (node.type === "transientDataObject") {
            return this.convertTransientDataObject(node)
        } else if (node.type === "meshDecal") {
            return this.convertMeshDecal(node)
        } else if (node.type === "overlayMaterialColor") {
            return this.convertOverlayMaterialColor(node)
        } else if (node.type === "regexReplace") {
            return this.convertRegexReplace(node)
        }

        throw new UnknownNodeTypeError(`Unknown node type ${(node as LegacyNodes.Node).type}`)
    }
}

const convertStringOrStringLike = (node: string | LegacyNodes.StringLike, converter: LegacyTemplateConverter) => {
    if (typeof node === "string") return node
    else return converter.convertStringLike(node)
}
const convertNumberOrNumberLike = (node: number | LegacyNodes.NumberLike, converter: LegacyTemplateConverter) => {
    if (typeof node === "number") return node
    else return converter.convertNumberLike(node)
}

const convertMaterialAssignments = (materialAssignments: {[slot: string]: LegacyNodes.MaterialAssignment | null}, converter: LegacyTemplateConverter) => {
    const getMaterialAssignment = (v: LegacyNodes.MaterialAssignment) => {
        if (!v.node) return null

        const materialTypes: LegacyNodes.Material["type"][] = [
            "findMaterial",
            "getTemplateOutput",
            "materialGraphReference",
            "materialReference",
            "overlayMaterialColor",
            "switch",
            "templateInput",
        ]
        if (!materialTypes.includes(v.node.type)) {
            console.warn("Invalid material assignment, defaulting to null")
            return null
        } else if (v.node.type === "materialReference" && (v.node.materialRevisionId === null || v.node.materialRevisionId === undefined)) {
            console.warn("Material reference has no material revision id, defaulting to null", v.node)
            return null
        } else
            return new MaterialAssignment({
                node: converter.convertMaterial(v.node),
                horizontalOffset:
                    v.horizontalOffset !== undefined && v.horizontalOffset !== null ? convertNumberOrNumberLike(v.horizontalOffset, converter) : undefined,
                verticalOffset:
                    v.verticalOffset !== undefined && v.verticalOffset !== null ? convertNumberOrNumberLike(v.verticalOffset, converter) : undefined,
                rotation: v.rotation !== undefined && v.rotation !== null ? convertNumberOrNumberLike(v.rotation, converter) : undefined,
                side: v.side ?? "front",
            })
    }

    return new MaterialAssignments(Object.fromEntries(Object.entries(materialAssignments).map(([k, v]) => [k, v !== null ? getMaterialAssignment(v) : null])))
}

const convertParameters = (
    selectedConfigVariants:
        | {
              [groupId: string]: string | LegacyNodes.Node
          }
        | undefined,
    parameters: {[paramId: string]: any} | undefined,
    converter: LegacyTemplateConverter,
) => {
    const mergedParameters = {...selectedConfigVariants, ...parameters}
    //Sometimes there are some getTemplateOutput nodes in the parameters, which are not referencing a template. We need to filter them out.
    const filteredParamters = Object.fromEntries(
        Object.entries(mergedParameters).filter(([, v]) => !LegacyNodes.isNode(v) || v.type !== "getTemplateOutput" || v.template !== null),
    )
    return new Parameters(
        Object.fromEntries(Object.entries(filteredParamters).map(([k, v]) => [k, LegacyNodes.isNode(v) ? converter.convertNode(v) : (v as AnyJSONValue)])),
    )
}

function stringOrNumberToNumber(item: string | number): number {
    return typeof item === "string" ? Number(item) : item
}

function convertMatrix4_JSON(matrix: (number | string)[]): number[] {
    return matrix.map((x) => stringOrNumberToNumber(x))
}

function convertPosition_JSON(position: [number | string, number | string, number | string]): [number, number, number] {
    return [stringOrNumberToNumber(position[0]), stringOrNumberToNumber(position[1]), stringOrNumberToNumber(position[2])]
}

function filterCyclicNodes<T>(node: unknown, nodes: T[]): T[] {
    const res = nodes.filter((x) => x !== node)
    if (res.length !== nodes.length) console.warn("Cyclic node detected, removing problematic node", node)
    return res
}

function filterInvalidNodes(node: LegacyNodes.Context, nodes: LegacyNodes.Node[]): LegacyNodes.Node[] {
    const isValidNode = (node: LegacyNodes.Node): boolean => {
        if (node.type === "templateExport" && node.node?.type === "configGroup") return false
        if (node.type === "templateInstance" && !node.template) return false
        if (node.type === "meshSurface") return false
        if (node.type === "proceduralMeshSurface") return false
        if (node.type === "surfaceReference") return false
        if (node.type === "attachSurfaces") return false
        if (node.type === "tagReference") return false
        if (node.type === "textureReference") return false
        if (node.type === "hdriReference") return false
        return true
    }

    const res = nodes.filter((x) => {
        const isValid = isValidNode(x)
        if (!isValid) console.warn("Invalid node in group detected, removing problematic node", x)
        return isValid
    })

    return filterCyclicNodes(node, res)
}

function tryMap<T, R>(node: unknown, nodes: T[], converter: (x: T) => R): R[] {
    const res: R[] = []
    nodes.forEach((x) => {
        try {
            res.push(converter(x))
        } catch (e) {
            console.warn("Error converting node within ", node, x, e)
            console.warn("This node will be skipped")
        }
    })

    return res
}
