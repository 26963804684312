import {ImageProcessing} from "#image-processing/image-processing"
import {WebAssemblyWorkerManager} from "#image-processing/workers/webassembly-worker-manager"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {Observable, of as observableOf} from "rxjs"

export class ImageProcessingActions {
    constructor(private workerManager: WebAssemblyWorkerManager) {}

    encodeEXR(image: TypedImageData, bitDepth: 16 | 32 = 16): Observable<Uint8Array> {
        return this.workerManager.invokeFunction("invokeJSFunction", "encodeEXR", [image, bitDepth])
    }

    decodeEXR(fileData: Uint8Array): Observable<TypedImageData> {
        return this.workerManager.invokeFunction("invokeJSFunction", "decodeEXR", [fileData])
    }

    encodeTIFF(image: TypedImageData): Observable<Uint8Array> {
        return this.workerManager.invokeFunction("invokeJSFunction", "encodeTIFF", [image])
    }

    encodePNG(image: TypedImageData): Observable<Uint8Array> {
        return this.workerManager.invokeFunction("invokeJSFunction", "encodePNG", [image])
    }

    encodeJPEG(image: TypedImageData): Observable<Uint8Array> {
        return this.workerManager.invokeFunction("invokeJSFunction", "encodeJPEG", [image])
    }

    decodeImage(fileData: Uint8Array): Observable<TypedImageData> {
        return this.workerManager.invokeFunction("invokeJSFunction", "decodeImage", [fileData])
    }

    evalGraph<T extends ImageProcessingNodes.Node>(graph: T): Observable<ImageProcessing.EvaledTypeOf<T>> {
        return this.workerManager.invokeFunction("invokeJSFunction", "evalImageProcessingGraph", [graph])
    }

    convertToUint8RGBA(image: TypedImageData): Observable<Uint8Array | null> {
        if (!(image.data && image.width && image.height)) {
            return observableOf(null)
        } else if (image.dataType === "uint8" && image.channelLayout === "RGBA") {
            return observableOf(new Uint8Array(image.data.buffer, image.data.byteOffset, image.data.byteLength))
        } else {
            return this.workerManager.invokeFunction("invokeJSFunction", "convertToUint8RGBA", [image])
        }
    }
}
