import {
    DeclareMaterialNode,
    DeclareMaterialNodeType,
    MaterialInputParameter,
    MaterialSlot,
    materialSlots,
    ThreeNode,
} from "#material-nodes/declare-material-node"
import {ShaderNode} from "#material-nodes/interfaces/shader-node"
import {ImageResourceSchema} from "#material-nodes/material-node-graph"
import {Mapping} from "#material-nodes/nodes/mapping"
import {RGB} from "#material-nodes/nodes/rgb"
import {TexImage} from "#material-nodes/nodes/tex-image"
import {Context} from "#material-nodes/types"
import {CachedNodeGraphResult} from "@cm/graph/evaluators/cached-node-graph-result"
import {getProperty} from "@cm/graph/utils"
import {mapFields, promiseAllProperties} from "@cm/utils"
import {z} from "zod"

type SetTextureGraph = {
    color: MaterialInputParameter<MaterialSlot>
}

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({uv: materialSlots.optional()})
const ParametersTypeSchema = z.object({
    widthCm: z.number().optional(),
    heightCm: z.number().optional(),
    mapAssignmentImageResourceSlot: z.number().optional(),
    textureSetRevisionId: z.string().optional(),
    imageResources: z.array(ImageResourceSchema).optional(),
})

export class SetTexture extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async function (
            this: {
                buildTextureSetGraph: () => SetTextureGraph
            },
            {context},
        ) {
            return promiseAllProperties(mapFields(this.buildTextureSetGraph(), (value) => compileNode<ThreeNode>(value, context)))
        },

        toCycles: async function (
            this: {
                buildTextureSetGraph: () => SetTextureGraph
            },
            {context},
        ) {
            return promiseAllProperties(mapFields(this.buildTextureSetGraph(), (value) => compileNode<ShaderNode>(value, context)))
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {
    buildTextureSetGraph(): SetTextureGraph {
        const {parameters, ...inputs} = this.parameters

        const {imageResources} = parameters
        const {widthCm, heightCm} = parameters.imageResources?.[0]?.metadata ?? {}

        if (widthCm === undefined || heightCm === undefined || imageResources === undefined) {
            return {
                color: createConstantColor([0.5, 0.5, 0.5]),
            }
        }

        const imageResource = imageResources.at(0)
        if (imageResource === undefined)
            return {
                color: createConstantColor([0.5, 0.5, 0.5]),
            }

        const {uv} = inputs
        const mapping = createMappingNode(uv, [widthCm, heightCm, 1])

        return {
            color: getProperty(
                new TexImage({
                    vector: mapping,
                    parameters: {
                        extension: "REPEAT",
                        interpolation: "Closest",
                        projection: "FLAT",
                        imageResource,
                    },
                }),
                "color",
            ),
        }
    }
}

function createConstantColor(color: [number, number, number]) {
    return getProperty(new RGB({parameters: {color: {r: color[0], g: color[1], b: color[2]}}}), "color")
}

function createMappingNode(
    uvNode: MaterialInputParameter<MaterialSlot | undefined>,
    scale: [number, number, number] = [1, 1, 1],
    rotation: [number, number, number] = [0, 0, 0],
    location: [number, number, number] = [0, 0, 0],
) {
    return getProperty(
        new Mapping({
            vector: uvNode,
            parameters: {
                vectorType: "TEXTURE",
                location: {x: location[0], y: location[1], z: location[2]},
                rotation: {x: rotation[0], y: rotation[1], z: rotation[2]},
                scale: {x: scale[0], y: scale[1], z: scale[2]},
            },
        }),
        "vector",
    )
}

const compileNode = async <T extends MaterialSlot>(node: MaterialInputParameter<MaterialSlot>, context: Context): Promise<T> => {
    const result = new CachedNodeGraphResult(node, context)
    const compiled = await result.run()
    return compiled as T
}
