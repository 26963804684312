export type CmmMeshFormat = "drc" | "drc_proxy" | "ply"

export class CmmMesh {
    id!: string
    name!: string
    type!: CmmMeshType
    dracoBitDepth?: number
    dracoResolution?: number
    osdUseRenderIterations?: number
    osdRenderIterations?: number
    defaultPosition?: [number, number, number]
    exporterVersion?: string
    data: Map<CmmMeshFormat, ArrayBuffer> = new Map<CmmMeshFormat, ArrayBuffer>()
    dataObjectIds: Map<CmmMeshFormat, number> = new Map<CmmMeshFormat, number>()
    originalFileName!: string

    static fromJson(
        meshJson: {
            id: string
            name: string
            type: string
            drc_bitDepth: number
            drc_resolution: number
            OSD_useRenderIterations: number
            OSD_renderIterations: number
            position: [number, number, number]
        },
        modelJson: {meta: {exporterVersion: string}},
    ): CmmMesh {
        const cmmMesh: CmmMesh = new CmmMesh()
        cmmMesh.id = meshJson.id
        cmmMesh.name = meshJson.name
        switch (meshJson.type) {
            case CmmMeshType.Generic:
                cmmMesh.type = CmmMeshType.Generic
                break
            case CmmMeshType.OpenSubdiv:
                cmmMesh.type = CmmMeshType.OpenSubdiv
                break
            default:
                throw Error(`Unrecognized mesh type: ${cmmMesh.type}`)
        }
        cmmMesh.dracoBitDepth = meshJson.drc_bitDepth
        cmmMesh.dracoResolution = meshJson.drc_resolution
        cmmMesh.osdUseRenderIterations = meshJson.OSD_useRenderIterations
        cmmMesh.osdRenderIterations = meshJson.OSD_renderIterations
        cmmMesh.defaultPosition = meshJson.position
        cmmMesh.exporterVersion = modelJson.meta.exporterVersion
        return cmmMesh
    }
}

export enum CmmMeshType {
    Generic = "GENERIC",
    OpenSubdiv = "OPEN_SUBDIV",
}
