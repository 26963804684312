import {computed, inject, Injectable} from "@angular/core"
import {PictureState, TagType} from "@api"
import {abilitiesForSilo} from "@common/helpers/permissions/abilities"
import {ColormassAbilitySubject, ColormassAbilityTypename, ColormassAction, ColormassEntity} from "@common/models/abilities"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {toObservable} from "@angular/core/rxjs-interop"
import {combineLatest, map, Observable, of} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class PermissionsService {
    auth = inject(AuthService)
    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)

    public $to = computed(() => {
        const silo = this.sdk.$silo()
        const user = this.auth.$user()

        const ability = abilitiesForSilo(silo, user)

        const check =
            <SubjectType extends Omit<ColormassAbilitySubject, "__typename"> | null | undefined, FieldType extends string = string>(
                verb: ColormassAction,
                typename: ColormassAbilityTypename,
            ) =>
            (subject?: SubjectType | null, field?: FieldType) =>
                ability.can(verb, {__typename: typename, ...subject}, field)

        return {
            create: {
                dataObject: check<{organization?: {id: string}} | null>("create", "DataObject"),
                material: check<{organization?: {id: string}} | null, "overlayColor" | "batch">("create", "Material"),
                materialAssignment: check<{material: {organization?: {id: string} | null}}>("create", "MaterialAssignment"),
                materialRevision: check<{material: {organization?: {id: string} | null}}>("create", "MaterialRevision"),
                pictureAsset: () => ability.can("create", "PictureAssetInfos"),
                scanJob: check<null, "testImage">("create", "ScanJob"),
                tag: () => ability.can("create", "Tag"),
                task: check<
                    {
                        organization?: {id: string} | null
                        state?: PictureState | null
                    } | null,
                    "pins"
                >("create", "Task"),
                template: check<{organization?: {id: string}}>("create", "Template"),
                userAssignment: () => ability.can("create", "UserAssignment"),
                testOperator: () => ability.can("create", "TextureEditor", "testOperator"),
            },
            delete: {
                dataObject: check<{organization?: {id: string}}>("delete", "DataObject"),
                item: (item: ColormassEntity, field?: string) => check<ColormassEntity>("delete", item.__typename)(item, field),
                materialAssignment: check<{
                    material: {organization?: {id: string} | null}
                }>("delete", "MaterialAssignment"),
                materialMapsExport: () => ability.can("delete", "MaterialMapsExport"),
                picture: check<{organization?: {id: string} | null}>("delete", "Picture"),
                pictureRevision: check<{
                    picture?: {organization?: {id: string} | null} | null
                }>("delete", "PictureRevision"),
                taskComment: check("delete", "TaskComment"),
                templateRevision: check<{template: {organization?: {id: string} | null}}>("delete", "TemplateRevision"),
            },
            open: {
                model: check<{organization?: {id: string} | null}>("read", "Model"),
                material: check<{organization?: {id: string} | null}>("read", "Material"),
                picture: check<null>("read", "Picture"),
                template: check<{organization?: {id: string}}>("read", "Template"),
            },
            read: {
                asset: check<{organization: {id: string}}>("read", "Asset"),
                dataObject: check<{organization?: {id: string} | null}>("read", "DataObject"),
                filters: (field: "additional" | "organization") => ability.can("read", "Filters", field),
                item: (item: ColormassEntity, field?: string) => check<ColormassEntity>("read", item.__typename)(item, field),
                job: check<{organization: {id: string}} | null>("read", "Job"),
                material: check<
                    {organization?: {id: string} | null} | null,
                    | "activity"
                    | "assets"
                    | "batchDownload"
                    | "comment"
                    | "commentBoxes"
                    | "explorer"
                    | "explorerAdvanced"
                    | "files"
                    | "iconAssignments"
                    | "labels"
                    | "outputs"
                    | "paymentState"
                    | "private"
                    | "revisions"
                    | "sampleArrival"
                    | "sourceUpdates"
                    | "state"
                    | "tasks"
                    | "templates"
                    | "textures"
                    | "userAssignments"
                >("read", "Material"),
                materialRevisions: check<{material: {organization: {id: string}}}>("read", "MaterialRevision"),
                menu: (
                    menuName:
                        | "advancedAutoTiling"
                        | "archiveTask"
                        | "authenticate"
                        | "authenticateAsStaff"
                        | "authenticateAsSuperadmin"
                        | "authenticateForAnyOrganization"
                        | "authenticateForOrganization"
                        | "batchDownloadMaterials"
                        | "batchDownloadPictures"
                        | "batchStartMaterialImageJobs"
                        | "cloudRender"
                        | "debugScanJob"
                        | "extraFilters"
                        | "files"
                        | "filterByOrganization"
                        | "filterByOrganizationOnTagsPage"
                        | "gpuRender"
                        | "hdris"
                        | "jobFarmGroups"
                        | "jobFarmInstances"
                        | "jobs"
                        | "narrowScanningDebugMenu"
                        | "onlineLinks"
                        | "operator"
                        | "organizations"
                        | "pictureAssignmentType"
                        | "products"
                        | "scanning"
                        | "scenes"
                        | "statistics"
                        | "tags"
                        | "taskTypes"
                        | "templates"
                        | "users",
                ) => ability.can("read", "Menu", menuName),
                model: check<{organization?: {id: string} | null}>("read", "Model"),
                organization: check<{id: string}>("read", "Organization"),
                page: (pageName: string) => ability.can("read", "Page", pageName),
                picture: check<{organization?: {id: string} | null} | null>("read", "Picture"),
                scanJob: check("read", "ScanJob"),
                tag: check("read", "Tag"),
                tagType: (type: TagType) => ability.can("read", "TagType", type),
                tagsPage: (mode: "all") => ability.can("read", "TagsPage", mode),
                task: check("read", "Task"),
                template: check<{organization?: {id: string} | null} | null>("read", "Template"),
                textureSet: check<{textureGroup: {organization?: {id: string}}}>("read", "TextureSet"),
            },
            update: {
                asset: check<{organization: {id: string}}>("update", "Asset"),
                dataObject: check<{organization: {id: string}}>("update", "DataObject"),
                item: (item?: ColormassEntity | null, field?: "customerFont" | "customerLogo" | "galleryImage" | "pdfTemplate") =>
                    item ? check<ColormassEntity>("update", item.__typename)(item, field) : false,
                job: check<{organization: {id: string}}>("update", "Job"),
                material: check<{organization?: {id: string} | null} | null | undefined>("update", "Material"),
                materialRevision: check<{material: {organization?: {id: string} | null}}>("update", "MaterialRevision"),
                menu: (field: "debug" | "online") => ability.can("update", "Menu", field),
                model: check<{organization?: {id: string} | null}>("update", "Model"),
                organization: check<{id: string}>("update", "Organization"),
                project: check<{organization?: {id: string} | null}>("update", "Project"),
                picture: check<{organization?: {id: string} | null; state?: PictureState}>("update", "Picture"),
                scanJob: check<{organization?: {id: string} | null}>("update", "ScanJob"),
                scanSubJob: check("update", "ScanSubJob"),
                set: check<{project?: {organization?: {id: string}}}>("update", "Set"),
                tag: check<{organization?: {id: string} | null} | null | undefined>("update", "Tag"),
                task: check<{organization?: {id: string}}>("update", "Task"),
                template: check<{organization?: {id: string}} | null>("update", "Template"),
                templateRevision: check<{template: {organization?: {id: string}}}>("update", "TemplateRevision"),
                textureGroup: check<{organization?: {id: string}}>("update", "TextureGroup"),
                textureSet: check<{textureGroup: {organization?: {id: string}}}>("update", "TextureSet"),
                user: check("update", "User"),
            },
        }
    })

    private can$ = toObservable(this.$to)

    canViewPage$(page?: string): Observable<boolean | undefined> {
        if (!page) {
            return of(undefined)
        }
        return combineLatest([this.auth.userId$, this.can$]).pipe(
            map(([userId, can]) => {
                return userId ? can.read.page(page) : undefined
            }),
        )
    }
}
