import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {ImageOpCommandQueueImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-imgproc"

// Context type for each engine
export type CommandQueueTypeByEngine = {
    WebGL2: ImageOpCommandQueueWebGL2
    ImgProc: ImageOpCommandQueueImgProc
}

export type ImageOpType<ParameterType, ReturnType> = {
    [P in keyof CommandQueueTypeByEngine]: (inputs: {cmdQueue: CommandQueueTypeByEngine[P]; parameters: ParameterType}) => ReturnType
} & {
    readonly name: string
}

export function runImageOp<ParameterType, ReturnType>(
    cmdQueue: ImageOpCommandQueue,
    imageOp: ImageOpType<ParameterType, ReturnType>,
    parameters: ParameterType,
) {
    cmdQueue.beginScope(imageOp.name)
    let result: ReturnType
    if (cmdQueue instanceof ImageOpCommandQueueWebGL2) {
        result = imageOp.WebGL2({cmdQueue, parameters})
    } else if (cmdQueue instanceof ImageOpCommandQueueImgProc) {
        result = imageOp.ImgProc({cmdQueue, parameters})
    } else {
        throw new Error(`Unknown context type: ${cmdQueue}`)
    }
    cmdQueue.endScope(imageOp.name)
    return result
}
