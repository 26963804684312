import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {BooleanInfo, ImageInfo, JSONInfo, MaterialInfo, NumberInfo, ObjectInfo, StringInfo, TemplateInfo} from "#template-nodes/interface-descriptors"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {ObjectData} from "#template-nodes/interfaces/object-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {ImageLike, imageLike, materialLike, MaterialLike, objectLike, ObjectLike, TemplateLike, templateLike} from "#template-nodes/node-types"
import {idNodeParameters, IdNodeParameters} from "#template-nodes/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "#template-nodes/nodes/named-node"
import {TemplateParameterValue} from "#template-nodes/nodes/parameters"
import {StringResolve} from "#template-nodes/nodes/string-resolve"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "#template-nodes/nodes/value"
import {BuilderInlet, BuilderOutlet, isBuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {WaitReady} from "#template-nodes/runtime-graph/nodes/wait-ready"
import {NotReady} from "#template-nodes/runtime-graph/slots"
import {ResolveAlias} from "#template-nodes/runtime-graph/types"
import {AnyJSONValue, anyJsonValue, EvaluatedTemplateInput, EvaluatedTemplateValueType, TemplateData, TemplateNode} from "#template-nodes/types"
import {visitNone} from "@cm/graph/declare-visitor-node"
import {nodeInstance} from "@cm/graph/instance"
import {NodeGraphClass} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {z} from "zod"

export const inputNode = z.object({})
export type InputNode = z.infer<typeof inputNode>

type ZodNode<T> = z.ZodType<T, z.ZodTypeDef, any>

const inputParameters = <T>(tValidation: ZodNode<T>) =>
    namedNodeParameters
        .merge(idNodeParameters)
        .merge(inputNode)
        .merge(
            z.object({
                default: tValidation.optional(),
            }),
        )
type InputParameters<T> = NamedNodeParameters & IdNodeParameters & InputNode & {default?: T}

const generateInput = <T, E>(
    tValidation: ZodNode<T>,
    type: EvaluatedTemplateValueType,
    implementation: TemplateNodeImplementation<InputParameters<T>>,
    meta: TemplateNodeMeta<InputParameters<T>>,
): NodeGraphClass<
    TemplateNode<InputParameters<T>> & {
        getTemplateInput(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<{value: E; origin: TemplateParameterValue}> | undefined
    }
> => {
    const retClass = class extends DeclareTemplateNodeTS<InputParameters<T>>(
        {validation: {paramsSchema: inputParameters(tValidation)}, ...implementation},
        meta,
    ) {
        getTemplateInput(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            const externalId = this.parameters.id
            const {ambientInputs} = evaluator
            const entry = ambientInputs[externalId]

            if (entry === undefined) return undefined

            const returnResult = (entry: EvaluatedTemplateInput, type: EvaluatedTemplateValueType) => {
                if (entry.type === type) {
                    return {value: entry.value as E, origin: entry.origin}
                } else throw Error(`Type mismatch for input ${externalId}: expected ${type}, got ${entry.type}`)
            }

            if (entry !== NotReady) {
                //Return immediately if the entry is already ready
                return returnResult(entry, type)
            }

            const waitReady = scope.node(WaitReady<EvaluatedTemplateInput>, {
                input: entry,
            })

            return scope.pureLambda(
                scope.tuple(waitReady.output as BuilderOutlet<EvaluatedTemplateInput>, type),
                ([entry, type]) => returnResult(entry, type),
                "templateInput",
            )
        }
    }
    return retClass
}

////////////////////////////////////////////////////////////////////

export interface ObjectInputParameters extends InputParameters<ObjectLike> {} // workaround for recursive type

@registerNode
export class ObjectInput
    extends generateInput<ObjectLike, ObjectData>(
        objectLike,
        "object",
        {
            onVisited: {
                onCompile: function (this: ObjectInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => new ObjectInfo({id, name, value, type: "input", origin}),
                            "objectInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "ObjectInput"},
    )
    implements EvaluableTemplateNode<ObjectData | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<ObjectData | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<ObjectData | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): BuilderInlet<{value: ObjectData | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("ObjectInputData", {value: evaluator.evaluateObject(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type ObjectInputFwd = TemplateNode<ObjectInputParameters> & EvaluableTemplateNode<ObjectData | null>

////////////////////////////////////////////////////////////////////

export interface MaterialInputParameters extends InputParameters<MaterialLike> {} // workaround for recursive type

@registerNode
export class MaterialInput
    extends generateInput<MaterialLike, IMaterialGraph>(
        materialLike,
        "material",
        {
            onVisited: {
                onCompile: function (this: MaterialInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const {descriptorList} = currentTemplate
                    const scope = evaluator.getScope(this)

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => {
                                return new MaterialInfo({id, name, value, type: "input", origin})
                            },
                            "materialInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "MaterialInput"},
    )
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<IMaterialGraph | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<IMaterialGraph | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): BuilderInlet<{value: IMaterialGraph | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("MaterialInputData", {value: evaluator.evaluateMaterial(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type MaterialInputFwd = TemplateNode<MaterialInputParameters> & EvaluableTemplateNode<IMaterialGraph | null>

////////////////////////////////////////////////////////////////////

export interface TemplateInputParameters extends InputParameters<TemplateLike> {} // workaround for recursive type

@registerNode
export class TemplateInput
    extends generateInput<TemplateLike, TemplateData>(
        templateLike,
        "template",
        {
            onVisited: {
                onCompile: function (this: TemplateInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => new TemplateInfo({id, name, value, type: "input", origin}),
                            "templateInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "TemplateInput"},
    )
    implements EvaluableTemplateNode<TemplateData | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<TemplateData | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<TemplateData | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): BuilderInlet<{value: TemplateData | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("TemplateInputData", {value: evaluator.evaluateTemplate(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type TemplateInputFwd = TemplateNode<TemplateInputParameters> & EvaluableTemplateNode<TemplateData | null>

////////////////////////////////////////////////////////////////////

export interface ImageInputParameters extends InputParameters<ImageLike> {} // workaround for recursive type

const ImageInputBaseClass: NodeGraphClass<
    TemplateNode<InputParameters<ImageLike>> & {
        getTemplateInput(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<{value: ImageGenerator; origin: TemplateParameterValue}> | undefined
    }
> = generateInput<ImageLike, ImageGenerator>(
    imageLike,
    "image",
    {
        onVisited: {
            onCompile: function (this: ImageInput, {context, parameters}) {
                const {id, name} = parameters
                const {evaluator, currentTemplate} = context
                const {templateScope} = evaluator
                const scope = evaluator.getScope(this)
                const {descriptorList} = currentTemplate

                const data = this.evaluateImpl(scope, evaluator)

                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, data),
                        ([id, name, {value, origin}]) => new ImageInfo({id, name, value, type: "input", origin}),
                        "imageInfo",
                    ),
                )

                templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "ImageInput"},
)
@registerNode
export class ImageInput extends ImageInputBaseClass implements EvaluableTemplateNode<ImageGenerator | null> {
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<ImageGenerator | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<ImageGenerator | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    evaluateImpl(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<{value: ImageGenerator | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("ImageInputData", {value: evaluator.evaluateImage(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type ImageInputFwd = TemplateNode<ImageInputParameters> & EvaluableTemplateNode<ImageGenerator | null>

////////////////////////////////////////////////////////////////////

const stringInputParameters = z.union([z.string(), nodeInstance(StringValue), nodeInstance(StringResolve)])
export type StringInputParameters = InputParameters<string | StringValue | StringResolve>

@registerNode
export class StringInput
    extends generateInput<string | StringValue | StringResolve, string>(
        stringInputParameters,
        "string",
        {
            onVisited: {
                onCompile: function (this: StringInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => new StringInfo({id, name, value, type: "input", origin}),
                            "stringInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "StringInput"},
    )
    implements EvaluableTemplateNode<string | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<string | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<string | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<{value: string | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("StringInputData", {value: evaluator.evaluateString(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type StringInputFwd = TemplateNode<StringInputParameters> & EvaluableTemplateNode<string | null>

////////////////////////////////////////////////////////////////////

const numberInputParameters = z.union([z.number(), nodeInstance(NumberValue)])
export type NumberInputParameters = InputParameters<number | NumberValue>

@registerNode
export class NumberInput
    extends generateInput<number | NumberValue, number>(
        numberInputParameters,
        "number",
        {
            onVisited: {
                onCompile: function (this: NumberInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => new NumberInfo({id, name, value, type: "input", origin}),
                            "numberInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "NumberInput"},
    )
    implements EvaluableTemplateNode<number | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<number | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<number | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<{value: number | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("NumberInputData", {value: evaluator.evaluateNumber(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type NumberInputFwd = TemplateNode<NumberInputParameters> & EvaluableTemplateNode<number | null>

////////////////////////////////////////////////////////////////////

const booleanInputParameters = z.union([z.boolean(), nodeInstance(BooleanValue)])
export type BooleanInputParameters = InputParameters<boolean | BooleanValue>

@registerNode
export class BooleanInput
    extends generateInput<boolean | BooleanValue, boolean>(
        booleanInputParameters,
        "boolean",
        {
            onVisited: {
                onCompile: function (this: BooleanInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => new BooleanInfo({id, name, value, type: "input", origin}),
                            "booleanInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "BooleanInput"},
    )
    implements EvaluableTemplateNode<boolean | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<boolean | null> {
        //Special case for boolean inputs to be able to resolve active nodes immediately
        const templateInput = this.getTemplateInput(scope, evaluator) ?? evaluator.evaluateBoolean(scope, this.parameters.default ?? null)
        if (templateInput !== undefined && !isBuilderOutlet(templateInput) && !(templateInput instanceof ResolveAlias)) {
            if (typeof templateInput === "object" && templateInput !== null) return templateInput.value
            else return templateInput
        }

        const {templateScope} = evaluator
        return templateScope.resolve<boolean | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): BuilderInlet<{value: boolean | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("BooleanInputData", {value: evaluator.evaluateBoolean(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type BooleanInputFwd = TemplateNode<BooleanInputParameters> & EvaluableTemplateNode<boolean | null>

////////////////////////////////////////////////////////////////////

const jsonInputParameters = z.union([anyJsonValue, nodeInstance(JSONValue)])
export type JSONInputParameters = InputParameters<AnyJSONValue | JSONValue>

@registerNode
export class JSONInput
    extends generateInput<AnyJSONValue | JSONValue, AnyJSONValue>(
        jsonInputParameters,
        "json",
        {
            onVisited: {
                onCompile: function (this: JSONInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {templateScope} = evaluator
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate

                    const data = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.pureLambda(
                            scope.tuple(id, name, data),
                            ([id, name, {value, origin}]) => new JSONInfo({id, name, value, type: "input", origin}),
                            "jsonInfo",
                        ),
                    )

                    templateScope.alias(scope.get(data, "value"), `templateInputs-${evaluator.getLocalId(this)}`)

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "JSONInput"},
    )
    implements EvaluableTemplateNode<AnyJSONValue | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<AnyJSONValue | null> {
        const {templateScope} = evaluator
        return templateScope.resolve<AnyJSONValue | null>(`templateInputs-${evaluator.getLocalId(this)}`)
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): BuilderInlet<{value: AnyJSONValue | null; origin: TemplateParameterValue | undefined}> {
        const templateInput = this.getTemplateInput(scope, evaluator)
        if (templateInput === undefined)
            return scope.struct("JSONInputData", {value: evaluator.evaluateJSON(scope, this.parameters.default ?? null), origin: undefined})
        else return templateInput
    }
}

export type JSONInputFwd = TemplateNode<JSONInputParameters> & EvaluableTemplateNode<AnyJSONValue | null>
