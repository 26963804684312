import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({uv: materialSlots})
const InputTypeSchema = z.object({})
const ParametersTypeSchema = z.object({uvMapIndex: z.number().optional()})

export class UVMap extends (DeclareMaterialNode(
    {
        returns: z.object({uv: materialSlots}),
        inputs: z.object({}),
        parameters: z.object({uvMapIndex: z.number().optional()}),
    },
    {
        toThree: async ({parameters, context}) => {
            const index = parameters.uvMapIndex ?? 0
            context.onThreeUsingUvChannel?.(index)
            const uv = THREENodes.uv(index)
            return {uv}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
