import {animate, style, transition, trigger} from "@angular/animations"
import {Component, inject, Signal} from "@angular/core"
import {Router} from "@angular/router"
import {TagsGridItemFragment} from "@api"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {CardComponent} from "@common/components/cards/card/card.component"
import {AuthService} from "@common/services/auth/auth.service"
import {FiltersService} from "@common/services/filters/filters.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {TagThumbnailComponent} from "@platform/components/tags/tag-thumbnail/tag-thumbnail.component"
import {LoadedData} from "@platform/models/data"
import {DataLoaderService, SortOrderService} from "@platform/services/data"
import {combineLatest, EMPTY, of, switchMap, map} from "rxjs"
import {maybeConstrainTagFilterOnTagsPage} from "@common/helpers/filters/tag"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"

@Component({
    animations: [
        trigger("fadeInPlaceholder", [
            transition("void => *", [
                style({
                    opacity: 0,
                    scale: 0.9,
                }),
                animate("600ms", style({opacity: 0.4, scale: 0.98})),
            ]),
        ]),
        trigger("fadeInCard", [
            transition("void => *", [
                style({
                    opacity: 0.6,
                    scale: 0.98,
                }),
                animate("200ms", style({opacity: 1, scale: 1})),
            ]),
        ]),
    ],
    imports: [CardPlaceholderComponent, CardComponent, TagThumbnailComponent],
    selector: "cm-tags-grid",
    standalone: true,
    styleUrl: "./tags-grid.component.scss",
    templateUrl: "./tags-grid.component.html",
})
export class TagsGridComponent {
    auth = inject(AuthService)
    dataLoaderService = inject(DataLoaderService)
    filters = inject(FiltersService)
    notificationService = inject(NotificationsService)
    permission = inject(PermissionsService)
    refresh = inject(RefreshService)
    router = inject(Router)
    sdk = inject(SdkService)
    sortOrder = inject(SortOrderService)
    $can = this.permission.$to

    $data: Signal<LoadedData<TagsGridItemFragment>>
    organizations = inject(OrganizationsService)
    permissions = inject(PermissionsService)

    constructor() {
        this.$data = this.dataLoaderService.$batchedData<TagsGridItemFragment>(
            combineLatest([this.filters.tagFilter$, this.sortOrder.tags$]).pipe(
                map(([filter, order]) => [maybeConstrainTagFilterOnTagsPage(filter, this.organizations, this.permission), order] as const),
                switchMap(([filter, order]) =>
                    of((skip: number, take: number) => {
                        return this.sdk.gql.getTagsGridItems({skip, take, filter, order})
                    }),
                ),
            ),
            //TODO: add infinite scroll trigger
            EMPTY,
        )
    }
}
