import {ChannelLayout} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {TypedImageData} from "@cm/utils/typed-image-data"

export type TextureChannelLayout = TypedImageData["channelLayout"]

export function imageOpChannelLayoutByTextureChannelLayout(channelLayout: TextureChannelLayout): ChannelLayout {
    switch (channelLayout) {
        case "RGBA":
            return "RGBA"
        case "RGB":
            return "RGB"
        case "L":
            return "R"
        default:
            throw new Error(`Unsupported channel layout: ${channelLayout}`)
    }
}
