import {SlicePipe} from "@angular/common"
import {Component, inject, Input, OnChanges, OnInit, signal, SimpleChanges} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {BasicTagInfoFragment} from "@api"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {Labels} from "@labels"
import {IEditor} from "@editor/models/editor"
import {LegacyTemplateNodes as Nodes, NodeUtils} from "@cm/template-nodes"
import {IsDefined, IsNonNull} from "@cm/utils/filter"
import {AuthService} from "@common/services/auth/auth.service"
import {SdkService} from "@common/services/sdk/sdk.service"

@Component({
    selector: "cm-template-output-inspector",
    templateUrl: "./template-output-inspector.component.html",
    styleUrls: ["./template-output-inspector.component.scss"],
    standalone: true,
    imports: [StringInputComponent, NodeReferenceSlotComponent, SearchComponent, DropdownComponent, SlicePipe, MatTooltipModule],
})
export class TemplateOutputInspectorComponent implements OnInit, OnChanges {
    @Input({required: true}) editor!: IEditor
    @Input() node: Nodes.TemplateExport | null = null

    nodeTags: BasicTagInfoFragment[] = []

    auth = inject(AuthService)
    sdk = inject(SdkService)

    focusSearch = false
    searchTags?: BasicTagInfoFragment[]
    private _searchText?: string

    $tagsForUser = signal<BasicTagInfoFragment[]>([])

    isValidId = NodeUtils.isValidId

    ngOnInit() {
        const userId = this.auth.$user()?.id
        if (userId) {
            void this.sdk.gql.templateOutputInspectorTagsForUser({createdById: userId}).then(({tags}) => {
                this.$tagsForUser.set(tags.filter(IsDefined))
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.node?.currentValue !== changes.node?.previousValue) {
            this.getTags(changes.node.currentValue).then((tags) => (this.nodeTags = tags))
        }
    }

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    get searchText() {
        return this._searchText
    }

    set searchText(text: string | undefined) {
        this._searchText = text
        if (text == null) {
            this.searchTags = undefined
            return
        }
        const existingIds = this.node && this.getTagIds(this.node)
        this.searchTags = this.$tagsForUser().filter(
            (tag) => tag.name.toLowerCase().search(text.toLowerCase()) != -1 && existingIds?.indexOf(tag.legacyId) === -1,
        )
    }

    getTagIds(node: Nodes.TemplateExport): number[] {
        if (!node.tags) return []
        return node.tags.map((x) => x.tagId)
    }

    async getTags(node: Nodes.TemplateExport): Promise<BasicTagInfoFragment[]> {
        if (!node.tags) return []
        const tagIds = node.tags.map((x) => x.tagId)
        return this.sdk.gql.templateOutputInspectorTagsByLegacyId({legacyIds: tagIds}).then(({tags}) => tags.filter(IsNonNull))
    }

    addTag(node: Nodes.TemplateExport, tag: BasicTagInfoFragment): void {
        if (!node.tags) {
            node.tags = []
        }
        node.tags.push(
            Nodes.create<Nodes.TagReference>({
                type: "tagReference",
                name: tag.name,
                tagId: tag.legacyId,
            }),
        )
        this.updateNode(node)
    }

    removeTag(node: Nodes.TemplateExport, tag: BasicTagInfoFragment): void {
        if (!node.tags) return
        node.tags = node.tags.filter((x) => x.tagId !== tag.legacyId)
        this.updateNode(node)
    }

    protected readonly Labels = Labels
}
