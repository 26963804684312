import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeId, NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const traceDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    key: TD.inlet(TD.Primitive<string>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class Trace<T> implements NodeClassImpl<typeof traceDescriptor, typeof Trace> {
    static descriptor = traceDescriptor
    static uniqueName = "Trace"
    input!: Inlet<T>
    key!: Inlet<string>
    output!: Outlet<T>
    $id?: NodeId

    run() {
        const value = this.input
        if (this.key !== NotReady) {
            const msg = this.key ?? `Trace[${this.$id}`
            console.log(msg, value)
        }
        this.output.emit(value)
    }
}
