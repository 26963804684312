import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({
    color: materialSlots.optional(),
    fac: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({color: color.optional(), fac: z.number().optional()})

export class Invert extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, fac} = await getAll(inputs, get)

            const colorValue = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const facValue = fac ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(1)
            const inverted = THREENodes.sub(threeValueNode(1), colorValue)
            return {color: THREENodes.mix(colorValue, inverted, facValue)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
