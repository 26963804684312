import {cyclesNode, DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getDefaultMaterial} from "#material-nodes/nodes/bsdf-principled"
import {ThreeShadowCatcher} from "#material-nodes/three-shadow-catcher"
import {vec3} from "#material-nodes/types"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const getDefaultSurface = () => ({surface: getDefaultMaterial()})

const ReturnTypeSchema = z.object({surface: z.instanceof(THREE.Material).or(cyclesNode)})
const InputTypeSchema = z.object({
    surface: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode).optional(),
    volume: materialSlots.optional(),
    displacement: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    displacement: vec3.optional(),
    shadowCatcher: z.boolean().optional(),
})
export class OutputMaterial extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            if (parameters.shadowCatcher) {
                const shadowCatcher = new ThreeShadowCatcher()

                return {surface: shadowCatcher}
            }

            const {surface} = inputs
            if (!surface) return getDefaultSurface()

            const result = await get(surface)
            if (!result) return getDefaultSurface()

            return {surface: result}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
