<cm-action-panel #scanningPanel arrowRight="70px" minWidth="200px" maxWidth="250px">
    <cm-action-panel-row>
        <div class="cm-material-infos">
            <span class="cm-material-name">
                {{ rangeTag ? rangeTag.name + " - " : "" }}
                {{ scanRegion?.material?.name }}
                {{ scanRegion?.material?.articleId ? "(" + scanRegion?.material?.articleId + ")" : "" }}
            </span>
            <cm-data-object-thumbnail
                [dataObjectId]="scanRegion?.material?.galleryImage?.id"
                (click)="notReady ? null : materialSelect.emit()"
                [style.cursor]="notReady ? 'auto' : 'pointer'"
                [layout]="ThumbnailLayout.Cover"
            ></cm-data-object-thumbnail>
            <div class="cm-material-id">{{ scanRegion?.material?.legacyId }}</div>
            @if (scanRegion?.material; as material) {
                <cm-state-label [stateLabels]="Labels.MaterialState" [state]="material.state" [disableCompleted]="true" [editable]="false"> </cm-state-label>
            }
        </div>
    </cm-action-panel-row>
    <cm-action-panel-row>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Resolution</mat-label>
            <mat-select
                [disabled]="notReady"
                [ngModel]="scanRegion?.resolution"
                (ngModelChange)="scanRegion ? (scanRegion.resolution = $event) && updateParams() : null"
            >
                @for (option of resolutionOptions; track option) {
                    <mat-option [value]="option">{{ option.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </cm-action-panel-row>
    <cm-action-panel-row>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Sample rotation</mat-label>
            <mat-select
                [disabled]="notReady"
                [ngModel]="scanRegion?.sampleRotation"
                (ngModelChange)="scanRegion ? (scanRegion.sampleRotation = $event) && updateParams() : null"
            >
                <mat-option [value]="0">0°</mat-option>
                <mat-option [value]="90" matTooltip="The scan is going to be rotated 90° counterclockwise.">90°</mat-option>
            </mat-select>
        </mat-form-field>
    </cm-action-panel-row>
    @if (hasTransparencySupport) {
        <cm-action-panel-row>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Transparent</mat-label>
                <mat-select
                    [disabled]="notReady"
                    [ngModel]="scanRegion?.transparent ?? false"
                    (ngModelChange)="scanRegion ? (scanRegion.transparent = $event) && updateParams() : null"
                >
                    <mat-option [value]="false">No</mat-option>
                    <mat-option [value]="true" matTooltip="The scan will include a transparency map.">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </cm-action-panel-row>
    }
</cm-action-panel>
<cm-action-panel #debugPanel arrowRight="162px" [marginRight]="$can().read.menu('narrowScanningDebugMenu') ? '15px' : '45px'">
    <cm-action-panel-row>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Scan mode</mat-label>
            <mat-select [(value)]="scanParams.mode" (valueChange)="updateParams()" [disabled]="!$can().update.scanJob(null, 'params') || notReady">
                <mat-option [value]="'cloud'">Cloud</mat-option>
                <mat-option [value]="'local'">Local</mat-option>
            </mat-select>
        </mat-form-field>
    </cm-action-panel-row>
    @if (scanParams.mode == "local") {
        <cm-action-panel-row>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Local scan name</mat-label>
                <input
                    matInput
                    type="text"
                    [(ngModel)]="scanParams.localScanName"
                    (ngModelChange)="updateParams()"
                    [ngModelOptions]="{updateOn: 'blur'}"
                    [disabled]="notReady"
                />
            </mat-form-field>
        </cm-action-panel-row>
    }
    <cm-action-panel-row>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Process tiles locally</mat-label>
            <mat-select [(value)]="scanParams.processTiles" (valueChange)="updateParams()" [disabled]="!$can().update.scanJob(null, 'params') || notReady">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    </cm-action-panel-row>
    <cm-action-bar>
        <ng-container cm-action-items>
            <cm-action-item
                tooltip="Skip table calibration"
                [disabled]="notReady"
                cmIconClass="far fa-circle-exclamation"
                (itemClick)="tableCalibrationSkip.emit()"
            ></cm-action-item>
            <cm-action-item
                tooltip="Calibrate table"
                [disabled]="notReady"
                cmIconClass="far fa-ruler-combined"
                (itemClick)="tableCalibrationStart.emit()"
            ></cm-action-item>
            <cm-action-item
                tooltip="Center table"
                [disabled]="notReady"
                cmIconClass="far fa-up-from-line"
                (itemClick)="tableMove.emit('center')"
            ></cm-action-item>
            @if (hasTransparencySupport) {
                <cm-action-item
                    tooltip="Calibrate backlight"
                    [disabled]="notReady"
                    cmIconClass="far fa-keyboard-brightness"
                    (itemClick)="backlightCalibrationStart.emit()"
                ></cm-action-item>
            }
            @if ($can().create.scanJob(null, "testImage")) {
                <cm-action-item
                    tooltip="Capture test image"
                    [disabled]="notReady"
                    cmIconClass="far fa-camera"
                    (itemClick)="testImageCapture.emit()"
                ></cm-action-item>
            }
            <cm-action-item tooltip="Show logs" (itemClick)="logsClick.emit()" cmIconClass="far fa-memo"></cm-action-item>
        </ng-container>
    </cm-action-bar>
</cm-action-panel>
<cm-action-bar>
    <ng-container cm-action-items>
        @if ($can().read.menu("debugScanJob") || showDebugMenu) {
            <cm-action-item
                tooltip="Debug"
                [panel]="debugPanel"
                [showPanelByDefault]="$can().read.scanJob(null, 'debugPanelByDefault')"
                cmIconClass="far fa-bug"
                #debugActionItem
            ></cm-action-item>
        }
        <cm-action-item
            tooltip="Move out table"
            [disabled]="notReady"
            cmIconClass="far fa-right-to-line"
            (itemClick)="tableMove.emit('loadSample')"
        ></cm-action-item>
        <cm-action-item
            tooltip="Start overview scan"
            [disabled]="notReady"
            cmIconClass="far fa-camera-viewfinder"
            (itemClick)="overviewScanStart.emit()"
        ></cm-action-item>
        <cm-action-item
            tooltip="Scan settings"
            [disabled]="!scanRegion"
            [active]="scanRegion != null"
            cmIconClass="far fa-draw-square"
            [panel]="scanningPanel"
            #scanSettingsActionItem
        ></cm-action-item>
        <cm-action-item tooltip="Cancel current task" cmIconClass="far fa-stop" [disabled]="!taskInProgress" (itemClick)="cancel.emit()"> </cm-action-item>
        <cm-action-item tooltip="Start scan" cmIconClass="far fa-play" [disabled]="!canStartScan" (itemClick)="scanStart.emit()"> </cm-action-item>
    </ng-container>
</cm-action-bar>
