import {ensureValidParameters} from "#graph/validation"
import {hashObject, isClass, mapFields} from "@cm/utils"
import {recursivelyReplaceSubObjects} from "@cm/utils/misc"
import {v4 as uuid4} from "uuid"
import {z} from "zod"

export type NodeParameters = {[key: string]: unknown}
export const nodeParameters = z.record(z.unknown())

export type NodeGraph<ReturnType = unknown, Context = unknown, ParamTypes extends NodeParameters = {}> = NodeGraphImpl<ReturnType, Context, ParamTypes>

export type SerializedNodeGraph = {
    $class: string
    $parameters: NodeParameters
    $refId: number
    $version: number
}

export type ParameterValue<T, Context> = T | NodeGraph<T, Context>

export type GraphParameter<ReturnType, Context> = [ReturnType] extends [NodeGraph<any, Context, {}>] ? ReturnType : ParameterValue<ReturnType, Context>

export type GetParameters<Context, ParamTypes extends NodeParameters> = {
    [P in keyof ParamTypes]: GraphParameter<ParamTypes[P], Context>
}

export const parameterValueSchema = (value: z.ZodTypeAny) => value.or(z.instanceof(NodeGraphImpl))

export const getParametersSchema = (paramsSchema: z.ZodType<NodeParameters>) => {
    const isZodObject = (schema: object): schema is z.ZodObject<z.ZodRawShape> => {
        return (schema as any).shape !== undefined
    }

    const isZodRecord = (schema: object): schema is z.ZodRecord => {
        return (schema as any).keySchema !== undefined && (schema as any).valueSchema !== undefined
    }

    if (isZodObject(paramsSchema)) return z.object(mapFields(paramsSchema.shape, (value) => parameterValueSchema(value)))
    else if (isZodRecord(paramsSchema)) return z.record(paramsSchema.keySchema, parameterValueSchema(paramsSchema.valueSchema))
    else throw Error("paramsSchema must be a ZodObject or ZodRecord")
}

export type Evaluated<T> = T extends NodeGraph<infer ReturnType, any, {}> ? ReturnType : T
export type NodeParamEvaluator = <ReturnType>(value: ReturnType) => Promise<Evaluated<ReturnType>>
export type NodeParamSyncEvaluator = <ReturnType>(value: ReturnType) => Evaluated<ReturnType>

export type GetGraphReturnType<Graph extends NodeGraph<any, any, {}>> = Graph extends NodeGraph<infer ReturnType, any, {}> ? ReturnType : never
export type GetGraphContextType<Graph extends NodeGraph<any, any, {}>> = Graph extends NodeGraph<any, infer Context, {}> ? Context : never
export type GetGraphParamTypes<Graph extends NodeGraph<any, any, {}>> = Graph extends NodeGraph<any, any, infer ParamTypes> ? ParamTypes : never

export enum TraversalAction {
    Continue,
    StopDescend,
    StopCompletely,
}

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null(), z.undefined()])
type Literal = z.infer<typeof literalSchema>
type Json = Literal | {[key: string]: Json} | Json[]
export const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

const concatPath = (path: string, key: string | number) => (path ? `${path}.${key}` : `${key}`)

export const mapGraphParameters = <ParamTypes extends NodeParameters, Context>(
    parameters: ParamTypes,
    nodeMapper?: (node: NodeGraph<unknown, Context>, path: string) => unknown,
): NodeParameters => {
    const mapParameter = (parameter: unknown, path: string): unknown => {
        if (isNodeGraphInstance<unknown, Context>(parameter)) {
            if (!nodeMapper) return parameter
            else return nodeMapper(parameter, path)
        } else if (typeof parameter === "object" && parameter) {
            if (parameter instanceof Array) return parameter.map((entry, index) => mapParameter(entry, concatPath(path, index)))
            else if (isClass(parameter)) return parameter
            else
                return Object.entries(parameter).reduce<NodeParameters>((acc, [key, value]) => {
                    acc[key] = mapParameter(value, concatPath(path, key))
                    return acc
                }, {})
        } else return parameter
    }

    return mapParameter(parameters, "") as NodeParameters
}

export const traverseGraphParameters = <ParamTypes extends NodeParameters, Context>(
    parameters: ParamTypes,
    callback?: (node: NodeGraph<unknown, Context>, path: string) => NodeGraph<unknown, Context> | void,
): ParamTypes => {
    return mapGraphParameters(
        parameters,
        callback
            ? (node, path) => {
                  const retValue = callback(node, path)
                  if (isNodeGraphInstance<unknown, Context>(retValue)) return retValue
                  else return node
              }
            : undefined,
    ) as ParamTypes
}

export type NodeGraphWithRefs = {
    refId: number
    nodeGraph: SerializedNodeGraph
    referencedGraphs: Set<number>
}

type ParameterDifference = {path: string; oldValue: unknown; newValue: unknown}
type ParameterDifferences = ParameterDifference[]
const graphParametersDifferences = <ParamTypes extends NodeParameters, Context>(
    parametersOld: ParamTypes,
    parametersNew: ParamTypes,
    checkOnlyGraphInstanceIdEquality = false,
) => {
    const traverseParameters = (oldValue: unknown, newValue: unknown, path: string): ParameterDifferences => {
        const difference = {path, oldValue, newValue}
        if (isNodeGraphInstance<unknown, Context>(oldValue)) {
            if (!isNodeGraphInstance<unknown, Context>(newValue)) return [difference]
            else if (checkOnlyGraphInstanceIdEquality) return oldValue.instanceId === newValue.instanceId ? [] : [difference]
            else return oldValue === newValue ? [] : [difference]
        } else if (oldValue instanceof Array) {
            if (!(newValue instanceof Array)) return [difference]
            const maxLength = Math.max(oldValue.length, newValue.length)
            const itemDifferences = [...Array(maxLength).keys()]
                .map((index) => traverseParameters(oldValue[index], newValue[index], concatPath(path, index)))
                .flat()
            if (oldValue.length !== newValue.length) {
                const lengthDifference = {path: concatPath(path, "length"), oldValue: oldValue.length, newValue: newValue.length}
                return [lengthDifference, ...itemDifferences]
            } else return itemDifferences
        } else if (typeof oldValue === "object" && oldValue) {
            if (!(typeof newValue === "object" && newValue)) return [difference]

            if (isClass(oldValue)) {
                if (!isClass(newValue)) return [difference]
                return oldValue === newValue ? [] : [difference]
            } else {
                const keysA = Object.keys(oldValue)
                const keysB = Object.keys(newValue)
                const allKeys = [...new Set([...keysA, ...keysB])]
                return allKeys
                    .map((key) => traverseParameters((oldValue as NodeParameters)[key], (newValue as NodeParameters)[key], concatPath(path, key)))
                    .flat()
            }
        } else return oldValue === newValue ? [] : [difference]
    }

    return traverseParameters(parametersOld, parametersNew, "")
}

export class NodeGraphSnapshot<ReturnType, Context, ParamTypes extends NodeParameters> {
    originalNodes: Map<string, NodeGraph<unknown, Context, {}>>
    oldClone: NodeGraph<ReturnType, Context, ParamTypes>
    oldClonedNodes: Map<string, NodeGraph<unknown, Context, {}>>

    constructor(public node: NodeGraph<ReturnType, Context, ParamTypes>) {
        this.originalNodes = getGraphNodesByInstanceIds(node)
        this.oldClone = node.clone({cloneSubNode: () => true, cloneInstanceIds: true})
        this.oldClonedNodes = getGraphNodesByInstanceIds(this.oldClone)
    }
}

const getGraphNodesByInstanceIds = <Context>(node: NodeGraph<unknown, Context>) => {
    const nodes = new Set<NodeGraph<unknown, Context>>()
    node.depthFirstTraversalPreorder(() => TraversalAction.Continue, nodes)

    const nodeMap = new Map<string, NodeGraph<unknown, Context>>()
    for (const node of nodes) nodeMap.set(node.instanceId, node)

    return nodeMap
}

export class NodeGraphDifferences<Context> {
    constructor(
        public addedNodes: Set<NodeGraph<unknown, Context>>,
        public modifiedNodes: Map<NodeGraph<unknown, Context>, ParameterDifferences>,
        public deletedNodes: Set<NodeGraph<unknown, Context>>,
    ) {}

    private apply(value: "oldValue" | "newValue") {
        const expectedValue = value === "oldValue" ? "newValue" : "oldValue"

        const valuesEqual = (a: unknown, b: unknown) => {
            const differences = graphParametersDifferences({value: a}, {value: b})
            return differences.length === 0
        }

        const nodeUpdates = new Map<NodeGraph<unknown, Context>, NodeParameters>()
        for (const [node, nodeDifferences] of this.modifiedNodes.entries()) {
            if (nodeDifferences.length === 0) continue

            const newParameters = traverseGraphParameters(node.parameters)
            for (const {path, [value]: newValue, [expectedValue]: previousValue} of nodeDifferences) {
                if (path.length === 0) throw new Error("Path length is 0")

                const pathParts = path.split(".")
                let current = newParameters as NodeParameters | Array<unknown>
                for (let i = 0; i < pathParts.length - 1; i++) {
                    const part = pathParts[i]
                    const steppedDown = Array.isArray(current) ? current[parseInt(part)] : current[part]
                    if (Array.isArray(steppedDown) || (typeof steppedDown === "object" && steppedDown !== null))
                        current = steppedDown as NodeParameters | Array<unknown>
                    else throw new Error(`Could not find path ${path} in parameters`)
                }

                const key = pathParts[pathParts.length - 1]
                if (Array.isArray(current)) {
                    if (key === "length") {
                        if (!valuesEqual(current.length, previousValue))
                            throw new Error(`Expected array length ${previousValue} but found ${current.length} in path ${path}`)
                        if (typeof newValue !== "number") throw new Error(`Expected number value but found ${typeof newValue} in path ${path}`)
                        current.length = newValue
                    } else {
                        const arrayIndex = parseInt(key)
                        if ((arrayIndex >= current.length && newValue !== undefined) || arrayIndex < 0)
                            throw new Error(`Array index ${arrayIndex} out of bounds in path ${path}, current length is ${current.length}`)
                        if (arrayIndex < current.length) {
                            if (!valuesEqual(current[arrayIndex], previousValue))
                                throw new Error(`Expected array value ${previousValue} but found ${current[arrayIndex]} in path ${path}`)
                            current[arrayIndex] = newValue
                        }
                    }
                } else if (typeof current === "object" && current !== null) {
                    if (!valuesEqual(current[key], previousValue))
                        throw new Error(`Expected object value ${previousValue} but found ${current[key]} in path ${path}`)
                    current[key] = newValue
                } else throw new Error("Current is not an object")
            }

            nodeUpdates.set(node, newParameters)
        }

        for (const [node, newParameters] of nodeUpdates.entries()) node.replaceParameters(newParameters)
    }

    applyForward = () => this.apply("newValue")
    applyBackward = () => this.apply("oldValue")
}

abstract class NodeGraphImpl<ReturnType, Context, ParamTypes extends NodeParameters> {
    readonly children: Set<NodeGraph<unknown, Context>> = new Set()
    readonly parents: Set<NodeGraph<unknown, Context>> = new Set()
    instanceId = uuid4()
    parameters: ParamTypes

    constructor(parameters: ParamTypes, disableValidation = false) {
        this.parameters = traverseGraphParameters<ParamTypes, Context>(!disableValidation ? ensureValidParameters(this, parameters) : parameters, (node) => {
            this.children.add(node)
            node.parents.add(this)
        })
    }

    run(get: NodeParamEvaluator, context: Context): Promise<ReturnType> {
        throw new Error(`Run method not implemented for ${this.getNodeClass()}`)
    }

    runSync(get: NodeParamSyncEvaluator, context: Context): ReturnType {
        throw new Error(`RunSync method not implemented for ${this.getNodeClass()}`)
    }

    getNodeClass(): string {
        const nodeClass = (this.constructor as any).nodeClass
        if (typeof nodeClass === "string") return nodeClass
        else return this.constructor.name
    }

    static getNodeClass(): string {
        const nodeClass = (this as any).nodeClass
        if (typeof nodeClass === "string") return nodeClass
        else return this.name
    }

    getNodeLabel(): string {
        const label = (this.constructor as any).label
        if (typeof label === "string") return label
        else return this.getNodeClass()
    }

    getNodeVersion(): number {
        const versionChain = (this.constructor as any).versionChain
        if (!Array.isArray(versionChain)) throw new Error(`Version chain not defined, forgot to decorate ${this.getNodeClass()} with @declareNode()?`)
        return versionChain.length
    }

    getReturnSchema(): z.ZodType {
        const returnSchema = (this.constructor as any).returnSchema
        if (typeof returnSchema !== "object") throw new Error(`Return schema name not defined, forgot to decorate ${this.getNodeClass()} with @declareNode()?`)
        return returnSchema
    }

    getParamsSchema(): z.ZodType<NodeParameters> {
        const paramsSchema = (this.constructor as any).paramsSchema
        if (typeof paramsSchema !== "object") throw new Error(`Return schema name not defined, forgot to decorate ${this.getNodeClass()} with @declareNode()?`)
        return paramsSchema
    }

    getContextSchema(): z.ZodType {
        const contextSchema = (this.constructor as any).contextSchema
        if (typeof contextSchema !== "object")
            throw new Error(`Context schema name not defined, forgot to decorate ${this.getNodeClass()} with @declareNode()?`)
        return contextSchema
    }

    serialize() {
        function serializeImpl(node: NodeGraph<unknown, Context>, refCache: Map<NodeGraph<unknown, Context>, number>): SerializedNodeGraph {
            const cachedRefId = refCache.get(node)
            if (cachedRefId !== undefined)
                return {
                    $class: "GraphRef",
                    $parameters: {},
                    $refId: cachedRefId,
                    $version: 0,
                }

            const refId = refCache.size
            refCache.set(node, refId)

            return {
                $class: node.getNodeClass(),
                $parameters: mapGraphParameters<ParamTypes, Context>(node.parameters as ParamTypes, (child) => {
                    return serializeImpl(child, refCache)
                }),
                $refId: refId,
                $version: node.getNodeVersion(),
            }
        }

        const retVal = serializeImpl(this, new Map<NodeGraph, number>())
        jsonSchema.parse(retVal)
        return retVal
    }

    //Add this dummy instance member property to make typescript complain about stacking together nodes with tighter contexts (contravariant)
    ensureContextTypes = (context: Context) => {}

    //For illustration of orders, see: https://en.wikipedia.org/wiki/Tree_traversal
    depthFirstTraversalPreorder(
        callback: (node: NodeGraph<unknown, Context>) => TraversalAction,
        visited: Set<NodeGraph<unknown, Context>> = new Set(),
    ): boolean {
        if (visited.has(this)) return true
        visited.add(this)

        const action = callback(this)
        if (action === TraversalAction.StopCompletely) return false
        if (action !== TraversalAction.StopDescend) {
            let stop = false
            traverseGraphParameters<ParamTypes, Context>(this.parameters, (child) => {
                if (!child.depthFirstTraversalPreorder(callback, visited)) stop = true
            })

            if (stop) return false
        }

        return true
    }

    replaceSubNodes(replacedNodes: Map<NodeGraph<unknown, Context>, NodeGraph<unknown, Context>>, visitedNodes = new Set<NodeGraph<unknown, any>>()) {
        const newParameters = traverseGraphParameters<ParamTypes, Context>(this.parameters, (node) => {
            const retValue = replacedNodes.get(node) ?? node

            if (visitedNodes.has(retValue)) return retValue
            visitedNodes.add(retValue)

            retValue.replaceSubNodes(replacedNodes, visitedNodes)
            return retValue
        })
        this.replaceParameters(newParameters)
    }

    replaceParameters(newParameters: ParamTypes, disableValidation = false) {
        const parameters = !disableValidation ? ensureValidParameters(this, newParameters) : newParameters
        traverseGraphParameters<ParamTypes, Context>(this.parameters, (node) => {
            this.children.delete(node)
            node.parents.delete(this)
        })
        this.parameters = traverseGraphParameters<ParamTypes, Context>(parameters, (node) => {
            this.children.add(node)
            node.parents.add(this)
        })
    }

    updateParameters(parameterUpdates: Partial<ParamTypes>, disableValidation = false) {
        const updatedParams = {...this.parameters}
        for (const key in parameterUpdates) updatedParams[key] = parameterUpdates[key] as ParamTypes[Extract<keyof ParamTypes, string>]
        this.replaceParameters(updatedParams, disableValidation)
    }

    clone(options?: {
        cloneSubNode?: (parent: NodeGraph<unknown, Context>, subNode: NodeGraph<unknown, Context>, path: string) => boolean
        parameterOverrides?: Partial<ParamTypes> | ((node: NodeGraph<unknown, Context>) => NodeParameters | undefined)
        cloneInstanceIds?: boolean
    }): this {
        function cloneImpl<P extends NodeParameters, T extends NodeGraph<unknown, Context, P>>(
            node: T,
            replacedNodes: Map<NodeGraph<unknown, Context>, NodeGraph<unknown, Context>>,
            deepCopy: boolean,
            parameterOverrides: Partial<P> | undefined,
            cloneSubNode: ((parent: NodeGraph<unknown, Context>, subNode: NodeGraph<unknown, Context>, path: string) => boolean) | undefined,
            parameterOverridesFn: ((node: NodeGraph<unknown, Context>) => NodeParameters | undefined) | undefined,
            cloneInstanceIds: boolean,
        ): T {
            if (!deepCopy) return node

            const cachedRefId = replacedNodes.get(node)
            if (cachedRefId !== undefined) return cachedRefId as T

            const cycleRef = new CircularRefNode<T>({refId: replacedNodes.size})
            replacedNodes.set(node, cycleRef)

            const retVal = new (node.constructor as {new (parameters: P, disableValidation: boolean): NodeGraph<ReturnType, Context, P>})(
                {
                    ...traverseGraphParameters(node.parameters, (child, path) => {
                        return cloneImpl(
                            child,
                            replacedNodes,
                            cloneSubNode?.(node, child, path) ?? false,
                            parameterOverridesFn?.(child),
                            cloneSubNode,
                            parameterOverridesFn,
                            cloneInstanceIds,
                        )
                    }),
                    ...parameterOverrides,
                },
                true,
            )
            if (cloneInstanceIds) retVal.instanceId = node.instanceId

            replacedNodes.set(node, retVal)
            replacedNodes.set(cycleRef, retVal)

            return retVal as T
        }

        const parameterOverrides = options?.parameterOverrides
        const replacedNodes = new Map<NodeGraph<unknown, Context>, NodeGraph<unknown, Context>>()
        const clonedNode = cloneImpl(
            this,
            replacedNodes,
            true,
            typeof parameterOverrides === "function" ? (parameterOverrides(this) as ParamTypes) : parameterOverrides,
            options?.cloneSubNode,
            typeof parameterOverrides === "function" ? parameterOverrides : undefined,
            options?.cloneInstanceIds ?? false,
        )
        clonedNode.replaceSubNodes(replacedNodes)

        return clonedNode
    }

    getSnapshot(): NodeGraphSnapshot<ReturnType, Context, ParamTypes> {
        return new NodeGraphSnapshot(this)
    }

    trackDifferences(modify: (graph: this) => void, keepChanges = true): NodeGraphDifferences<Context> {
        const snapshot = this.getSnapshot()
        modify(this)
        const differences = this.getDifferences(snapshot)
        if (!keepChanges) differences.applyBackward()
        return differences
    }

    getDifferences(oldSnapshot: NodeGraphSnapshot<ReturnType, Context, ParamTypes>): NodeGraphDifferences<Context> {
        const {originalNodes, oldClonedNodes, node} = oldSnapshot
        if (node !== this) throw new Error("NodeGraphSnapshot does not match this node")

        const newNodes = getGraphNodesByInstanceIds(this)

        const allNodes = new Map([...originalNodes, ...newNodes])

        const addedNodes = new Set<NodeGraph<unknown, Context>>()
        const modifiedNodes = new Map<NodeGraph<unknown, Context>, ParameterDifferences>()
        const deletedNodes = new Set<NodeGraph<unknown, Context>>()
        for (const newNode of allNodes.values()) {
            if (!newNodes.has(newNode.instanceId)) deletedNodes.add(newNode)

            const preChangeNodeClone = oldClonedNodes.get(newNode.instanceId)
            if (!preChangeNodeClone) addedNodes.add(newNode)
            else {
                const preChangedNodeParameters = traverseGraphParameters<{}, Context>(preChangeNodeClone.parameters, (node) => {
                    const originalNode = originalNodes.get(node.instanceId)
                    if (!originalNode) throw new Error(`Original node ${node.instanceId} not found in graph`)
                    return originalNode
                })
                const modifiedParameters = graphParametersDifferences(preChangedNodeParameters, newNode.parameters, true)
                if (modifiedParameters.length > 0) modifiedNodes.set(newNode, modifiedParameters)
            }
        }

        return new NodeGraphDifferences<Context>(addedNodes, modifiedNodes, deletedNodes)
    }

    getHash() {
        type HashableObject = {
            $class: string
            $parameters: NodeParameters
            $version: number
        }

        const resolvedHashableObjects = new Map<CircularRefNode<NodeGraph>, HashableObject>()
        function getHashableObject(
            node: NodeGraph<unknown, Context>,
            refCache: Map<NodeGraph<unknown, Context>, HashableObject | CircularRefNode<NodeGraph>>,
        ): HashableObject | CircularRefNode<NodeGraph> {
            const cachedHashableObject = refCache.get(node)
            if (cachedHashableObject) return cachedHashableObject

            const circularRef = new CircularRefNode({refId: refCache.size})
            refCache.set(node, circularRef)

            const hashableObject: HashableObject = {
                $class: node.getNodeClass(),
                $parameters: mapGraphParameters(node.parameters, (child) => getHashableObject(child, refCache)),
                $version: node.getNodeVersion(),
            }

            refCache.set(node, hashableObject)
            resolvedHashableObjects.set(circularRef, hashableObject)

            return hashableObject
        }

        const hashableObject = getHashableObject(this, new Map()) as HashableObject

        const objectMapper = (object: object): object | void => {
            if (object instanceof CircularRefNode) {
                const resolvedHashObject = resolvedHashableObjects.get(object)
                if (!resolvedHashObject) throw new Error(`Circular reference ${object.parameters.refId} not found in resolved hash objects`)
                return resolvedHashObject
            }
        }

        recursivelyReplaceSubObjects(hashableObject, objectMapper)
        return hashObject(hashableObject)
    }
}

export function isNodeGraphInstance<ReturnType = unknown, Context = unknown, ParamTypes extends NodeParameters = {}>(
    value: unknown,
): value is NodeGraph<ReturnType, Context, ParamTypes> {
    return value instanceof NodeGraphImpl
}
export const nodeGraph = z.any().superRefine((arg, ctx): arg is NodeGraph<unknown, unknown, {}> => {
    if (!isNodeGraphInstance(arg))
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Expected nodeGraph",
            fatal: true,
        })

    return z.NEVER
}) // We have to use .superRefine() because .refine() doesn't abort early https://github.com/colinhacks/zod#abort-early

export type NodeGraphMeta<ParamTypes extends NodeParameters> = {
    nodeClass?: string
    label?: string
    versionChain?: VersionChain<GetVersionParameters<ParamTypes>>
}

export class CircularRefNode<Graph extends NodeGraphImpl<any, any, {}>> extends DeclareNodeGraphTS<unknown, unknown, {refId: number}>({}) {
    ensureGraphTypes = (graph: Graph) => {}
}

type VersionGraphValue<T extends NodeGraph<any, any, {}>> = T | CircularRefNode<T>

export type VersionParameter<T> = T extends NodeGraph<any, any, {}> ? VersionGraphValue<T> : T

export type GetVersionParameters<ParamTypes extends NodeParameters> = {
    [P in keyof ParamTypes]: VersionParameter<ParamTypes[P]>
}

type TupleToArg<T extends any[]> = Extract<[any, ...{[I in keyof T]: T[I]}, any], Record<keyof T, any>>
type TupleToChain<T extends any[]> = {[I in keyof T]: {toNextVersion: (parameters: TupleToArg<T>[I]) => T[I]}}
type Last<T extends NodeParameters[]> = T extends [...infer _, infer L] ? L : never

type VersionChain<T> = {conversionChain: T}

export type Version<From extends NodeParameters, To extends NodeParameters> = {
    nodeClass?: string
    toNextVersion: (parameters: GetVersionParameters<From>) => GetVersionParameters<To>
}

export function versionChain<T extends any[]>(fns: readonly [...TupleToChain<T>]) {
    return fns as VersionChain<Last<T>>
}

const nodeFactory = new Map<string, new (parameters: NodeParameters, disableValidation: boolean) => NodeGraph>()

function declareNodeGraph<ReturnType, Context, ParamTypes extends NodeParameters>(
    returnSchema: z.ZodType,
    contextSchema: z.ZodType,
    paramsSchema: z.ZodType<NodeParameters>,
    meta?: NodeGraphMeta<ParamTypes>,
) {
    const {nodeClass, label, versionChain} = meta ?? {}

    return function (targetClass: {new (parameters: ParamTypes): NodeGraph<ReturnType, Context, ParamTypes>}) {
        ;(targetClass as any).nodeClass = nodeClass
        ;(targetClass as any).label = label
        ;(targetClass as any).versionChain = versionChain ?? []
        ;(targetClass as any).returnSchema = returnSchema
        ;(targetClass as any).contextSchema = contextSchema
        ;(targetClass as any).paramsSchema = paramsSchema
    }
}

export function DeclareNodeGraph<ZodReturnType extends z.ZodType, ZodContextType extends z.ZodType, ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        returns: ZodReturnType
        context: ZodContextType
        parameters: ZodParamTypes
    },
    implementation: {
        run?: (data: {
            get: NodeParamEvaluator
            context: z.infer<typeof definition.context>
            parameters: z.infer<typeof definition.parameters>
        }) => Promise<z.infer<typeof definition.returns>>
        runSync?: (data: {
            get: NodeParamSyncEvaluator
            context: z.infer<typeof definition.context>
            parameters: z.infer<typeof definition.parameters>
        }) => z.infer<typeof definition.returns>
    },
    meta?: NodeGraphMeta<z.infer<typeof definition.parameters>>,
) {
    const {returns: returnSchema, context: contextSchema, parameters: paramsSchema} = definition
    type ReturnType = z.infer<typeof returnSchema>
    type Context = z.infer<typeof contextSchema>
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareNodeGraphTS<ReturnType, Context, ParamTypes>({...implementation, validation: {returnSchema, contextSchema, paramsSchema}}, meta)
}

export type NodeGraphClass<T extends NodeGraph<unknown, any>> =
    T extends NodeGraph<infer ReturnType, infer Context, infer ParamTypes>
        ? {
              new (parameters: ParamTypes): T
              getNodeClass(): string
          }
        : never

export function DeclareNodeGraphTS<ReturnType, Context, ParamTypes extends NodeParameters>(
    implementation: {
        run?: (data: {get: NodeParamEvaluator; context: Context; parameters: ParamTypes}) => Promise<ReturnType>
        runSync?: (data: {get: NodeParamSyncEvaluator; context: Context; parameters: ParamTypes}) => ReturnType
        validation?: {
            returnSchema?: z.ZodType
            contextSchema?: z.ZodType
            paramsSchema?: z.ZodType<NodeParameters>
        }
    },
    meta?: NodeGraphMeta<ParamTypes>,
): NodeGraphClass<NodeGraph<ReturnType, Context, ParamTypes>> {
    const {run, runSync, validation} = implementation
    const returnSchema = validation?.returnSchema ?? z.any()
    const contextSchema = validation?.contextSchema ?? z.any()
    const paramsSchema = validation?.paramsSchema ?? nodeParameters

    const retClass = class extends NodeGraphImpl<ReturnType, Context, ParamTypes> {
        override async run(get: NodeParamEvaluator, context: Context) {
            if (run) return run.bind(this)({get, context, parameters: this.parameters})
            else return super.run(get, context)
        }

        override runSync(get: NodeParamSyncEvaluator, context: Context) {
            if (runSync) return runSync.bind(this)({get, context, parameters: this.parameters})
            else return super.runSync(get, context)
        }
    }

    declareNodeGraph<ReturnType, Context, ParamTypes>(returnSchema, contextSchema, paramsSchema, meta)(retClass)

    return retClass
}

export function registerNodeGraph<ReturnType, Context, ParamTypes extends NodeParameters>(targetClass: {
    new (parameters: ParamTypes): NodeGraph<ReturnType, Context, ParamTypes>
}) {
    const nodeClass = (targetClass as any).nodeClass
    const name = typeof nodeClass === "string" ? nodeClass : targetClass.name
    const versionChain = (targetClass as any).versionChain as Version<{}, {}>[]

    if (!Array.isArray(versionChain)) throw new Error(`Version chain not defined, forgot to decorate ${name} with @declareNode()?`)

    const register = (name: string) => {
        if (name === "GraphRef") throw new Error(`Entity name ${name} is reserved`)
        if (nodeFactory.has(name)) throw new Error(`Node class ${name} already registered in class factory`)
        nodeFactory.set(name, targetClass as any)
    }

    register(name)
    versionChain.forEach((version) => {
        if (version.nodeClass !== undefined) register(version.nodeClass)
    })

    const version = versionChain.length

    ;(targetClass as any).convertVersion = (parameters: NodeParameters, serializedVersion: number) => {
        if (version === serializedVersion) return parameters
        if (serializedVersion > version || version < 0) throw new Error(`Version ${serializedVersion} not supported for ${name}`)
        const [f0, ...fs] = versionChain.slice(serializedVersion)
        return fs.reduce((a, f) => f.toNextVersion(a), f0.toNextVersion(parameters))
    }
}

export function getRegisteredNode(name: string) {
    const retVal = nodeFactory.get(name)
    if (!retVal) throw new Error(`Node class ${name} not found in class factory, forgot to decorate it with @registerNode?`)
    return retVal
}

export function deserializeNodeGraph(serializedGraph: SerializedNodeGraph, disableValidation = false) {
    function deserializeImpl(serializedGraph: SerializedNodeGraph, refCache: Map<number, NodeGraph>, allowCircularRefs: boolean) {
        const deserializeParameter = (parameter: unknown): unknown => {
            if (parameter instanceof Array) return parameter.map((entry) => deserializeParameter(entry))
            else if (typeof parameter === "object" && parameter) {
                const {$class, $parameters, $refId, $version} = parameter as NodeParameters
                if (
                    typeof $class === "string" &&
                    typeof $parameters === "object" &&
                    $parameters &&
                    typeof $refId === "number" &&
                    typeof $version === "number"
                ) {
                    if (refCache.has($refId)) return refCache.get($refId)

                    if ($class === "GraphRef") {
                        if (!allowCircularRefs) throw new Error(`Cached ref ${$refId} not found`)
                        return new CircularRefNode({refId: $refId})
                    }

                    return deserializeImpl(
                        {
                            $class,
                            $parameters: $parameters as NodeParameters,
                            $refId,
                            $version,
                        },
                        refCache,
                        true,
                    )
                } else
                    return Object.entries(parameter).reduce<NodeParameters>((acc, [key, value]) => {
                        acc[key] = deserializeParameter(value)
                        return acc
                    }, {})
            } else return parameter
        }

        const {$class, $parameters, $refId, $version} = serializedGraph

        const NodeClass = getRegisteredNode($class)
        const convertVersion = (NodeClass as any).convertVersion as (parameters: NodeParameters, version: number) => NodeParameters
        if (!convertVersion) throw new Error(`convertVersion not defined for ${$class}, forgot to decorate it with @registerNode?`)
        const retVal = new NodeClass(convertVersion(deserializeParameter($parameters) as NodeParameters, $version), true)

        //Graphs are cached recursively, do not move this outside of this function
        refCache.set($refId, retVal)

        //Replace circular references with the actual graph instances as soon as the come available
        for (const graph of refCache.values()) {
            let resolvedRef = false
            const newParameters = traverseGraphParameters(graph.parameters, (node) => {
                if (node instanceof CircularRefNode) {
                    const refGraph = refCache.get(node.parameters.refId)
                    if (!refGraph || refGraph instanceof CircularRefNode) return node
                    resolvedRef = true
                    return refGraph
                }
                return undefined
            })
            if (resolvedRef) graph.replaceParameters(newParameters, true)
        }

        return retVal
    }

    /*collects all serialized graphs and tracks the subgraphs referenced by them*/
    const collectReferencedGraphs = (serializedGraph: SerializedNodeGraph): Map<number, NodeGraphWithRefs> => {
        const nodeGraphsWithRef = new Map<number, NodeGraphWithRefs>()
        const collectReferences = (parameter: unknown, parentIds: number[]) => {
            if (parameter instanceof Array) {
                //mixed array of primitives and graphs
                parameter.forEach((entry, index) => collectReferences(entry, parentIds))
            } else if (typeof parameter === "object" && parameter) {
                const {$class, $parameters, $refId, $version} = parameter as NodeParameters

                if (
                    typeof $class === "string" &&
                    typeof $parameters === "object" &&
                    $parameters &&
                    typeof $refId === "number" &&
                    typeof $version === "number"
                ) {
                    if ($class === "GraphRef") {
                        //Reference to another graph
                        parentIds.forEach((parentRefId, index) => {
                            const parent = nodeGraphsWithRef.get(parentRefId)
                            if (!parent) throw new Error(`Parent refId ${parentRefId} not found`)
                            parent.referencedGraphs.add($refId)
                        })
                    } else {
                        //Real graph instance
                        if (nodeGraphsWithRef.has($refId)) throw new Error(`Duplicate refId ${$refId}`)
                        nodeGraphsWithRef.set($refId, {refId: $refId, nodeGraph: parameter as SerializedNodeGraph, referencedGraphs: new Set<number>()})
                        collectReferences($parameters, [...parentIds, $refId])
                    }
                } else {
                    //some object with various keys: Parameter object or something else
                    Object.entries(parameter).forEach(([key, value]) => {
                        collectReferences(value, parentIds)
                    })
                }
            }
        }

        if (serializedGraph.$class === "GraphRef") throw new Error("GraphRef not allowed in this context")

        collectReferences(serializedGraph, [])
        return nodeGraphsWithRef
    }

    const nodeGraphsWithRef = collectReferencedGraphs(serializedGraph)
    const refCache = new Map<number, NodeGraph>()

    /*The loop deserializes all graphs in a specific order, such that referenced subgraphs are always in the cache.
        This is required, because our DB stores the graphs in jsonb format which has random order of keys. I.e. the keys
        are potentially returned in different order as originally serialized.
        The different scheduling always works if there are no cycles in the graph.
        If there are cycles in the graph, we resolve them one by one by temporarily assigning a special CircularRefNode that gets resolved later in the process*/
    while (nodeGraphsWithRef.size > 0) {
        let deserializeableGraphs = [...nodeGraphsWithRef.values()].filter((value) => {
            return Array.from(value.referencedGraphs).every((refId) => refCache.has(refId))
        })

        let allowCircularRefs = false
        if (deserializeableGraphs.length === 0) {
            //No new graphs can be deserialized, because of cyclic dependencies
            //We just pick the first unresolvable, and allow circular references for this one iteration
            allowCircularRefs = true
            deserializeableGraphs = [[...nodeGraphsWithRef.values()][0]]
        }

        for (const {refId, nodeGraph} of deserializeableGraphs) {
            if (refCache.has(refId)) continue //the graph was deserialized in a prev iteration, while deserializing a parent.

            const existingGraphIds = new Set(refCache.keys())
            deserializeImpl(nodeGraph, refCache, allowCircularRefs)
            const newlyDeserializedGraphIds = Array.from(new Set(refCache.keys())).filter((key) => !existingGraphIds.has(key))

            if (newlyDeserializedGraphIds.length === 0) throw new Error("Deserialization failed, no new graphs deserialized")

            newlyDeserializedGraphIds.forEach((newlyDeserializedGraphId) => {
                nodeGraphsWithRef.delete(newlyDeserializedGraphId)
                nodeGraphsWithRef.forEach((value) => value.referencedGraphs.delete(newlyDeserializedGraphId))
            })
        }
    }

    //Sanity check to see if all circular refs are resolved and apply automatic zod schema transformations
    for (const graph of refCache.values())
        graph.replaceParameters(
            traverseGraphParameters(graph.parameters, (node) => {
                if (node instanceof CircularRefNode) {
                    throw new Error(`Circular ref ${node.parameters.refId} not resolved`)
                }
            }),
            disableValidation,
        )

    const nodeGraph = refCache.get(serializedGraph.$refId)
    if (!nodeGraph) throw new Error(`Root node graph ${serializedGraph.$refId} not found`)

    return nodeGraph
}
