import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const pureLambdaDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    fn: TD.inlet(TD.StaticFunction<(x: any) => any>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class PureLambda<I, O> implements NodeClassImpl<typeof pureLambdaDescriptor, typeof PureLambda> {
    static descriptor = pureLambdaDescriptor
    static uniqueName = "PureLambda"
    input!: Inlet<I>
    fn!: Inlet<(x: I) => O>
    output!: Outlet<O>

    run() {
        if (this.input === NotReady || this.fn === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        this.output.emitIfChanged(this.fn(this.input))
    }
}
