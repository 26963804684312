<cm-inspector-section>
    <ng-container cm-title> Post processing </ng-container>
    <ng-container cm-content>
        <div class="cm-render-input">
            <span>Render input:</span>
            <cm-node-reference-slot [(node)]="$any(node).render" (nodeChange)="nodeChanged(node)"> </cm-node-reference-slot>
        </div>
        <cm-input-container>
            <ng-container cm-inline-label>
                <span matTooltip="Exposure Adjust (EV)">Exposure:</span>
            </ng-container>
            <ng-container cm-input>
                <cm-node-reference-slot fallbackText="" [node]="$any(node.exposure)" (nodeChange)="node.exposure = $any($event); nodeChanged(node)">
                    <ng-container cm-fallback>
                        <cm-numeric-input [(value)]="$any(node).exposure" (valueChange)="nodeChanged(node)" [decimalPlaces]="2" matTooltip="Range: -5 to 5">
                        </cm-numeric-input>
                    </ng-container>
                </cm-node-reference-slot>
            </ng-container>
        </cm-input-container>
        <cm-input-container>
            <ng-container cm-inline-label>
                <span matTooltip="White Balance (K)">White B.:</span>
            </ng-container>
            <ng-container cm-input>
                <cm-node-reference-slot fallbackText="" [node]="$any(node.whiteBalance)" (nodeChange)="node.whiteBalance = $any($event); nodeChanged(node)">
                    <ng-container cm-fallback>
                        <cm-numeric-input
                            [(value)]="$any(node).whiteBalance"
                            (valueChange)="nodeChanged(node)"
                            [decimalPlaces]="0"
                            matTooltip="Range: 2000 to 12000"
                        >
                        </cm-numeric-input>
                    </ng-container>
                </cm-node-reference-slot>
            </ng-container>
        </cm-input-container>
        <mat-form-field>
            <mat-label>Tone Mapping</mat-label>
            <mat-select
                [value]="node.toneMapping?.mode ?? 'linear'"
                (selectionChange)="node.toneMapping = defaultsForToneMapping($event.value); nodeChanged(node)"
            >
                <mat-option [value]="'pbr-neutral'">PBR Neutral</mat-option>
                <mat-option [value]="'filmic'">Filmic</mat-option>
                <mat-option [value]="'filmic-advanced'">Filmic (Advanced)</mat-option>
                <mat-option [value]="'contrast'">Contrast</mat-option>
                <mat-option [value]="'corona'">Corona</mat-option>
                <mat-option [value]="'linear'">None (Linear)</mat-option>
            </mat-select>
        </mat-form-field>
        @if (node.toneMapping?.mode == "corona" && hasHighlightCompression(node.toneMapping!)) {
            <cm-input-container>
                <ng-container cm-inline-label> Highlight compression </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="1"-->
                    <!--                           max="5"-->
                    <!--                           step="0.01"-->
                    <!--                           matTooltip="Range: 1 to 5, default 1. Compress brigher highlights."-->
                    <!--                           [(ngModel)]="node.toneMapping.highlightCompression"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.toneMapping.highlightCompression"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Range: 1 to 5, default 1. Compress brigher highlights."
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        @if (
            (node.toneMapping?.mode == "contrast" || node.toneMapping?.mode == "filmic-advanced" || node.toneMapping?.mode == "corona") &&
            hasContrast(node.toneMapping!)
        ) {
            <cm-input-container>
                <ng-container cm-inline-label> Contrast </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="1"-->
                    <!--                           max="5"-->
                    <!--                           step="0.01"-->
                    <!--                           matTooltip="Range: 1 to 5, default 1. Boost highlight/shadow contrast (no effect if set less than 1)."-->
                    <!--                           [(ngModel)]="node.toneMapping.contrast"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.toneMapping.contrast"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Range: 1 to 5, default 1. Boost highlight/shadow contrast (no effect if set less than 1)."
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        @if (node.toneMapping?.mode == "corona" && hasSaturation(node.toneMapping!)) {
            <cm-input-container>
                <ng-container cm-inline-label> Saturation </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="-1"-->
                    <!--                           max="1"-->
                    <!--                           step="0.01"-->
                    <!--                           matTooltip="Range: -1 to 1, default 0."-->
                    <!--                           [(ngModel)]="node.toneMapping.saturation"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.toneMapping.saturation"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Range: -1 to 1, default 0."
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        @if ((node.toneMapping?.mode == "contrast" || node.toneMapping?.mode == "filmic-advanced") && hasBalance(node.toneMapping!)) {
            <cm-input-container>
                <ng-container cm-inline-label> Balance </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="0"-->
                    <!--                           max="1"-->
                    <!--                           step="0.01"-->
                    <!--                           matTooltip="Range 0 to 1, default 0.5. Controls the balance between highlights and shadows."-->
                    <!--                           [(ngModel)]="node.toneMapping.balance"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.toneMapping.balance"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Range 0 to 1, default 0.5. Controls the balance between highlights and shadows."
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        @if ((node.toneMapping?.mode == "contrast" || node.toneMapping?.mode == "filmic-advanced") && hasColorBalance(node.toneMapping!)) {
            <cm-input-container>
                <ng-container cm-inline-label> Color Balance </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="-.5"-->
                    <!--                           max=".5"-->
                    <!--                           step="0.01"-->
                    <!--                           matTooltip="Range -0.5 to 0.5. Applies a color shift to the mid-tones."-->
                    <!--                           [(ngModel)]="node.toneMapping.colorBalance"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.toneMapping.colorBalance"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Range -0.5 to 0.5. Applies a color shift to the mid-tones."
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        <mat-form-field>
            <mat-label>LUT</mat-label>
            <mat-select [(value)]="node.lutUrl" (selectionChange)="nodeChanged(node)">
                @for (entry of luts; track entry) {
                    <mat-option [value]="entry.url">
                        {{ entry.name }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
        <cm-toggle [(toggle)]="node.transparent!" (toggleChange)="nodeChanged(node)">
            <ng-container cm-text>Transparent</ng-container>
        </cm-toggle>
        <cm-toggle [(toggle)]="node.composite!" (toggleChange)="nodeChanged(node)">
            <ng-container cm-text>Composite</ng-container>
        </cm-toggle>
        @if (node.composite) {
            <cm-input-container>
                <ng-container cm-inline-label> Background Color: </ng-container>
                <ng-container cm-input>
                    <!--TODO: Use color input.-->
                    <input [(ngModel)]="node.backgroundColor" (ngModelChange)="nodeChanged(node)" type="color" matInput />
                </ng-container>
            </cm-input-container>
        }
        <cm-toggle [toggle]="node.processShadows ?? true" (toggleChange)="node.processShadows = $event; nodeChanged(node)">
            <ng-container cm-text>Process Shadows</ng-container>
        </cm-toggle>
        @if (node.processShadows ?? true) {
            <cm-input-container>
                <ng-container cm-inline-label> Shadow Inner: </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="0"-->
                    <!--                           max="1"-->
                    <!--                           step="0.01"-->
                    <!--                           [(ngModel)]="node.shadowInner"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.shadowInner!"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Higher values 'pull' the inner shadow regions away from the object. Range: 0 to 1"
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        @if (node.processShadows ?? true) {
            <cm-input-container>
                <ng-container cm-inline-label> Shadow Outer: </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="0"-->
                    <!--                           max="1"-->
                    <!--                           step="0.01"-->
                    <!--                           [(ngModel)]="node.shadowOuter"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.shadowOuter"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Higher values 'push' the outer shadow regions towards the object. Range: 0 to 1"
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        @if (node.processShadows ?? true) {
            <cm-input-container>
                <ng-container cm-inline-label> Shadow Falloff: </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="0"-->
                    <!--                           max="1"-->
                    <!--                           step="0.01"-->
                    <!--                           [(ngModel)]="node.shadowFalloff"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input
                        [(value)]="node.shadowFalloff"
                        (valueChange)="nodeChanged(node)"
                        [decimalPlaces]="2"
                        matTooltip="Controls the light/dark balance of the shadow gradient. Higher values give darker shadows. Range: 0 to 1"
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        <cm-toggle [(toggle)]="node.autoCrop!" (toggleChange)="nodeChanged(node)">
            <ng-container cm-text>Auto Crop</ng-container>
        </cm-toggle>
        @if (node.autoCrop) {
            <cm-input-container>
                <ng-container cm-inline-label> Auto Crop Margin: </ng-container>
                <ng-container cm-input>
                    <!--TODO: Remove regular input.-->
                    <!--                    <input type="number"-->
                    <!--                           min="0"-->
                    <!--                           max="1000"-->
                    <!--                           step="1"-->
                    <!--                           [(ngModel)]="node.autoCropMargin"-->
                    <!--                           (ngModelChange)="nodeChanged(node)"-->
                    <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
                    <!--                           matInput>-->
                    <cm-numeric-input [(value)]="node.autoCropMargin" (valueChange)="nodeChanged(node)" [decimalPlaces]="0"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
    </ng-container>
</cm-inspector-section>
<!--FIXME: Replace with mask list and use showMasks to show and hide mask overlay-->
<!-- <cm-inspector-section>
<ng-container cm-title>
  Masks
  <ng-container *ngIf="showMasks"></ng-container>
</ng-container>
<ng-container cm-content>
  <div class="cm-tonemapping-settings">
    <cm-toggle [(toggle)]="mask01">
      <ng-container cm-text>Mask 1</ng-container>
    </cm-toggle>
    <cm-toggle [(toggle)]="mask02">
      <ng-container cm-text>Mask 2</ng-container>
    </cm-toggle>
  </div>
</ng-container>
</cm-inspector-section> -->
