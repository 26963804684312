import {SdkService} from "@common/services/sdk/sdk.service"
import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchApiCall} from "@common/helpers/batch-api-call/batch-api-call"
import {IsUnique} from "@cm/utils/filter"
import {TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragment} from "@api"

type RequestPayload = {
    legacyId: number
}

type ResponsePayload = TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragment

type BatchedRequestPayload = {
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
}

export class TemplateRevisionBatchApiCall extends BatchApiCall<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    constructor(private sdk: SdkService) {
        super()
    }

    protected dispatchResponses(batchedPayload: BatchedRequestPayload, responses: ResponsePayload[]) {
        const requestsById = new Map<number, ApiRequest<RequestPayload, ResponsePayload>[]>()
        batchedPayload.requests.forEach((request) => {
            if (!requestsById.has(request.payload.legacyId)) {
                requestsById.set(request.payload.legacyId, [])
            }
            requestsById.get(request.payload.legacyId)!.push(request)
        })
        responses.forEach((response) => {
            const requests = requestsById.get(response.legacyId)
            if (!requests) {
                throw new Error("No request not found")
            }
            requests.forEach((request) => request.resolve(response))
            requestsById.delete(response.legacyId)
        })
        requestsById.forEach((requests) => requests.forEach((request) => request.reject("No response received")))
    }

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        return [{requests}]
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const legacyIds = payload.requests.map((request) => request.payload.legacyId).filter(IsUnique)
        return this.sdk.gql
            .getTemplateRevisionDetailsForTemplateRevisionBatchApiCall({
                legacyIds,
            })
            .then((response) => response.templateRevisions)
    }
}
