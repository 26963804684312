import {Component, Input} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {NodeUtils} from "@cm/template-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"

@Component({
    selector: "cm-group-inspector",
    templateUrl: "./group-inspector.component.html",
    styleUrls: ["./group-inspector.component.scss"],
    standalone: true,
    imports: [NodeReferenceSlotComponent, ToggleComponent, InspectorSectionComponent],
})
export class GroupInspectorComponent {
    @Input() editor!: IEditor
    @Input() node: Nodes.Group | null = null
    NodeUtils = NodeUtils

    constructor() {}

    updateNode(node: Nodes.Node) {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
