import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2Shader} from "@common/helpers/webgl2/webgl2-shader"
import {WebGl2ShaderCache} from "@common/helpers/webgl2/webgl2-shader-cache"

import {HalEntity} from "@common/models/hal/hal-context"

export class WebGl2ShaderInstance implements HalEntity {
    readonly shader: WebGl2Shader

    constructor(
        readonly context: WebGl2Context,
        readonly shadingFunction: string,
    ) {
        this.shader = WebGl2ShaderInstance.shaderCache.getOrCreateShader(this.context, this.shadingFunction)
    }

    // HalEntity
    dispose(): void {
        WebGl2ShaderInstance.shaderCache.releaseShader(this.context, this.shadingFunction)
    }

    // HalShader
    // setParameter(name: string, value: HalPainterParameterValueType, isOptional?: boolean): void {
    //     this.shader.setParameter(name, value, isOptional)
    // }

    private static shaderCache = new WebGl2ShaderCache()
}
