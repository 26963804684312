import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeValueNode} from "#material-nodes/three-utils"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({vector: materialSlots})
const InputTypeSchema = z.object({x: materialSlots.optional(), y: materialSlots.optional(), z: materialSlots.optional()})
const ParametersTypeSchema = z.object({x: z.number().optional(), y: z.number().optional(), z: z.number().optional()})

export class CombineXYZ extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {x, y, z} = await getAll(inputs, get)
            const xValue = x ?? threeValueNode(parameters.x ?? 0)
            const yValue = y ?? threeValueNode(parameters.y ?? 0)
            const zValue = z ?? threeValueNode(parameters.z ?? 0)
            return {vector: new THREENodes.JoinNode([xValue, yValue, zValue])}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
