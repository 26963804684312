import {Component, EventEmitter, Input, Output} from "@angular/core"
import {MatSliderModule} from "@angular/material/slider"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"

@Component({
    selector: "cm-slider",
    templateUrl: "./slider.component.html",
    styleUrls: ["./slider.component.scss"],
    standalone: true,
    imports: [MatSliderModule, InputContainerComponent, NumericInputComponent],
})
export class SliderComponent {
    @Input() disabled = false
    @Input() exponent = 1 // allows for non-linear sliders when set to a value other than 1
    @Input() displayValue = true
    @Input() displayValuePrecision = 2
    @Input() min = 1
    @Input() max = 100
    @Input() value = 50
    @Output() valueChange = new EventEmitter<number>()
    @Output() isDragging = new EventEmitter<boolean>()

    constructor() {}

    set valueNumber(value: number) {
        this.value = Math.max(this.min, Math.min(this.max, value))
        this.valueChange.emit(this.value)
    }

    get valueNumber(): number {
        return this.value
    }

    get sliderMin(): number {
        return this.value2sliderValue(this.min)
    }

    get sliderMax(): number {
        return this.value2sliderValue(this.max)
    }

    get sliderStep(): number {
        return (this.sliderMax - this.sliderMin) / 100
    }

    get sliderValue(): number {
        return this.value2sliderValue(this.value)
    }

    set sliderValue(value: number) {
        this.value = this.sliderValue2value(value)
        this.valueChange.emit(this.value)
    }

    private value2sliderValue(value: number): number {
        return Math.pow(value, 1 / this.exponent)
    }

    private sliderValue2value(sliderValue: number): number {
        return Math.pow(sliderValue, this.exponent)
    }

    protected updateValueNumber(value: number | null): void {
        if (value === null) return
        this.valueNumber = value
    }
}
