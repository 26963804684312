<div class="cm-node-flex-container">
    @if ($conditionalAmountNode()?.parameters; as parameters) {
        <div class="cm-node-icon" matTooltip="Conditional Amount" matTooltipPosition="above">
            <i class="fa-solid fa-hashtag"></i>
        </div>
        <span class="cm-node-text">{{ $description() }}</span>
        <div class="cm-tools-right">
            <cm-variant-condition [variantConditionNode]="parameters.condition" ($conditionRemoved)="$conditionRemoved.emit()"></cm-variant-condition>
            <input class="cm-input-width-limit" type="number" min="0" [(ngModel)]="parameters.amount" />
        </div>
    }
</div>
