import {Box2, Matrix3x2, Vector2} from "@cm/math"
import {assertNever} from "@cm/utils"
import {WebGl2ShaderInstance} from "@common/helpers/webgl2/webgl2-shader-instance"
import {isHalView} from "@common/models/hal/hal-image-view/utils"
import {HalPainterPrimitive, PainterPrimitivePaintArgs} from "@common/models/hal/hal-painter-primitive"
import {HalPainterBlendMode} from "@common/models/hal/hal-painter/types"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2Image} from "@common/models/webgl2/webgl2-image"
import {WebGl2ImageOrView, WebGl2ImageView} from "@common/models/webgl2/webgl2-image-view"
import {WebGl2Geometry} from "@common/models/webgl2/webgl2-geometry"

const MAX_TEXTURE_UNITS = 4

export class WebGl2PainterPrimitive extends WebGl2ShaderInstance implements HalPainterPrimitive {
    constructor(context: WebGl2Context, shadingFunction: string) {
        super(context, shadingFunction)
        if (MAX_TEXTURE_UNITS > this.context.maxTextureUnits) {
            throw Error(
                `WebGlLayerImagesComposite requires ${MAX_TEXTURE_UNITS} texture units, but the WebGlCanvas only supports ${this.context.maxTextureUnits}`,
            )
        }
    }

    // HalPainterPrimitive
    async paint({target, geometry, parameters, sourceImages, options}: PainterPrimitivePaintArgs): Promise<void> {
        if (!(geometry instanceof WebGl2Geometry)) {
            throw Error("Unsupported geometry")
        }
        const numIndices = geometry.prepareGeometry(this.shader)
        if (numIndices >= 3) {
            const transform = options?.transform ? Matrix3x2.fromMatrix3x2Like(options.transform) : new Matrix3x2()
            const blendMode: HalPainterBlendMode = options?.blendMode ?? "none"
            const imageAssignments: (WebGl2ImageOrView | undefined)[] = (sourceImages ? (Array.isArray(sourceImages) ? sourceImages : [sourceImages]) : []).map(
                (sourceImage) => {
                    if (!sourceImage) {
                        return undefined
                    }
                    if (!(sourceImage instanceof WebGl2Image) && !(sourceImage instanceof WebGl2ImageView)) {
                        throw Error("Unsupported source image")
                    }
                    return sourceImage
                },
            )
            if (imageAssignments.length > MAX_TEXTURE_UNITS) {
                throw Error(`WebGlLayerImagesComposite requires ${MAX_TEXTURE_UNITS} source images, but ${imageAssignments.length} were provided`)
            }
            for (let i = imageAssignments.length; i < MAX_TEXTURE_UNITS; i++) {
                imageAssignments.push(undefined)
            }
            const gl = this.context.gl
            this.shader.setProgramAndData(imageAssignments)
            this.shader.setUniforms(target, transform, imageAssignments, [1, 1, 1, 1], parameters)
            this.setGlBlendMode(blendMode)
            const region = isHalView(target) ? target.region : Box2.fromPositionAndSize(new Vector2(0, 0), new Vector2(target.width, target.height))
            const targetPaintable = isHalView(target) ? target.resource : target
            targetPaintable.preDraw(region)
            const numPasses = targetPaintable.getNumDrawPasses(region)
            for (let pass = 0; pass < numPasses; pass++) {
                const viewTransform = targetPaintable.setDrawPass(region, pass)
                this.shader.setViewTransform(viewTransform)
                gl.drawElements(gl.TRIANGLES, numIndices, gl.UNSIGNED_INT, 0)
            }
            targetPaintable.postDraw(region)
            this.shader.unsetProgramAndData() // unbind textures to avoid potential subsequent feedback framebuffer operation
        }
    }

    private setGlBlendMode(blendMode: HalPainterBlendMode) {
        const gl = this.context.gl
        switch (blendMode) {
            case "none":
                gl.disable(gl.BLEND)
                break
            case "normal":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA)
                break
            case "add":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "sub":
                gl.enable(gl.BLEND)
                gl.blendEquationSeparate(gl.FUNC_REVERSE_SUBTRACT, gl.MAX) // TODO should this be separate ?
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "mul":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.DST_COLOR, gl.ZERO)
                break
            case "min":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.MIN)
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "max":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.MAX)
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "screen":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_COLOR)
                break
            default:
                assertNever(blendMode)
        }
    }

    // protected getUniformLocation(uniformName: string): WebGLUniformLocation {
    //     return this.shader.getUniformLocation(uniformName)
    // }
}
