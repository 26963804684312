import {HalContext} from "@common/models/hal/hal-context"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {ColorLike, Matrix3x2Like} from "@cm/math"
import * as WebGl2PainterUtils from "@common/helpers/webgl2/webgl2-painter-utils"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {HalViewRegion} from "@common/models/hal/hal-view/types"

export class WebGl2PaintableCanvas implements HalPaintable {
    readonly context: WebGl2Context

    constructor(
        context: HalContext,
        private canvas: HTMLCanvasElement,
    ) {
        if (!(context instanceof WebGl2Context)) {
            throw new Error("WebGl2PaintableCanvas requires a WebGl2Context")
        }
        this.context = context
    }

    // HalEntity
    dispose() {}

    // HalPaintable
    get width(): number {
        return this.canvas.width
    }

    // HalPaintable
    get height(): number {
        return this.canvas.height
    }

    // HalPaintable
    get forceAlphaToOne(): boolean {
        return false
    }

    // HalPaintable
    preDraw(region: HalViewRegion): void {
        if (region.x !== 0 || region.y !== 0 || region.width !== this.width || region.height !== this.height) {
            throw new Error("WebGl2PaintableCanvas does not support drawing to a region")
        }
    }

    // HalPaintable
    postDraw(): void {}

    // HalPaintable
    getNumDrawPasses(): number {
        return 1
    }

    // HalPaintable
    setDrawPass(): Matrix3x2Like {
        const gl = this.context.gl
        gl.bindFramebuffer(gl.FRAMEBUFFER, null)
        gl.viewport(0, 0, this.canvas.width, this.canvas.height)

        const transform = new paper.Matrix(1, 0, 0, -1, 0, 0) // flip y axis
        transform.append(new paper.Matrix(1, 0, 0, 1, -1, -1)) // origin at top left
        transform.append(new paper.Matrix(2 / this.canvas.width, 0, 0, 2 / this.canvas.height, 0, 0)) // normalize to [-1, 1]
        return transform
    }

    // HalPaintable
    async clear(color?: ColorLike): Promise<void> {
        return WebGl2PainterUtils.clearPaintable(this, color)
    }
}
