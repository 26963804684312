import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {TemplateNode, VisitMode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {ConfigVariant} from "#template-nodes/nodes/config-variant"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {ConfigInfo, VariantInfo} from "#template-nodes/interface-descriptors"
import {visitAll, visitNone} from "@cm/graph/declare-visitor-node"
import {groupNodeParameters, GroupNodeParameters} from "#template-nodes/nodes/group-node"
import {idNodeParameters, IdNodeParameters} from "#template-nodes/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "#template-nodes/nodes/named-node"
import {z} from "zod"
import {versionChain} from "@cm/graph/node-graph"
import {NotReady} from "#template-nodes/runtime-graph/slots"

export const configGroupParameters = namedNodeParameters
    .merge(groupNodeParameters)
    .merge(idNodeParameters)
    .merge(z.object({displayWithLabels: z.boolean()}))
export type ConfigGroupParameters = NamedNodeParameters & GroupNodeParameters & IdNodeParameters & {displayWithLabels: boolean}

type V0 = NamedNodeParameters & GroupNodeParameters & IdNodeParameters
type V1 = V0 & {displayWithLabels: boolean}
const v0 = {
    toNextVersion: (parameters: V0): V1 => {
        return {...parameters, displayWithLabels: false}
    },
}

@registerNode
export class ConfigGroup extends DeclareTemplateNodeTS<ConfigGroupParameters>(
    {
        validation: {paramsSchema: configGroupParameters},
        onVisited: {
            onFilterActive: function (this: ConfigGroup, {visit, context, parameters}) {
                const {nodes, ...rest} = parameters

                const activeConfigVariant = this.getActiveConfigVariant(context.evaluator)

                return {
                    nodes: nodes.visit({...context, visitMode: VisitMode.FilterActive}, visit, (subNode): boolean => {
                        if (subNode instanceof ConfigVariant) {
                            return subNode === activeConfigVariant
                        } else return true
                    }),
                    ...visitNone(rest),
                }
            },
            onCompile: function (this: ConfigGroup, {visit, context, parameters}) {
                const {id, name} = parameters
                const {evaluator, currentTemplate} = context
                const {activeNodeSet} = evaluator
                const {descriptorList} = currentTemplate

                const configVariants = this.getConfigVariants()

                const variants = configVariants.map<VariantInfo>((variant) => {
                    const {id, name, iconColor, iconDataObject} = variant.parameters
                    const iconDataObjectId = iconDataObject?.parameters.dataObjectId

                    return {id, name, iconColor, iconDataObjectId}
                })

                const activeConfigVariant = activeNodeSet ? configVariants.find((configVariant) => activeNodeSet.has(configVariant)) : undefined

                const activeVariant = variants.find((variant) => variant.id === activeConfigVariant?.parameters.id)

                const scope = evaluator.getScope(this)

                descriptorList.push(
                    scope.valueWithoutType(
                        new ConfigInfo({
                            id,
                            name,
                            variants,
                            value: activeVariant ?? null,
                            type: "input",
                            displayWithLabels: parameters.displayWithLabels,
                        }),
                    ),
                )

                return visitAll(parameters, visit)
            },
        },
    },
    {nodeClass: "ConfigGroup", versionChain: versionChain([v0])},
) {
    getConfigVariants = () => this.parameters.nodes.parameters.list.filter((subNode): subNode is ConfigVariant => subNode instanceof ConfigVariant)

    getActiveConfigVariant = (evaluator: NodeEvaluator) => {
        const selectedId = this.getTemplateInput(evaluator) //TODO: default?
        const activeConfigVariant = this.getConfigVariants().find((configVariant) => {
            if (selectedId === undefined) {
                //console.log(`No config selected for group ${parameters.name}. Using first (${subNode.parameters.name}) as default.`);
                return true
            } else if (configVariant.parameters.id === selectedId) return true
            return false
        })
        return activeConfigVariant ?? null
    }

    getTemplateInput(evaluator: NodeEvaluator) {
        const externalId = this.parameters.id
        const {ambientInputs} = evaluator
        const entry = ambientInputs[externalId]
        if (!entry) return undefined

        if (entry === NotReady) throw Error(`ConfigGroup parameter ${this.parameters.name} has been evaluated before it was ready`)

        if (entry.type === "string") return entry.value as string
        else throw Error(`ConfigGroup parameter ${this.parameters.name} is not a string`)
    }
}

export type ConfigGroupFwd = TemplateNode<ConfigGroupParameters>
