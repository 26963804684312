import {ContentTypeModel} from "@api"
import {TemplateRevision} from "@legacy/api-model/template-revision"
import {MakeApiModelExtendedBase} from "@legacy/api-model/api-model-extended"
import {ApiFields, declareApiModel} from "@legacy/api-model/api-model"
import {EntityType} from "@legacy/models/entity-type"
import {Settings} from "@common/models/settings/settings"
import {State} from "@legacy/models/state-labels"
import {map, Observable, switchMap} from "rxjs"

export class TemplateType extends State {
    constructor(id: number, label: string, background: string) {
        super(id, label, background)
    }

    static override get(id: number): TemplateType {
        return State.get(id, TemplateTypes)
    }
}

export const TemplateTypes: {
    Product: TemplateType
    Room: TemplateType
    Part: TemplateType
    Scene: TemplateType
} = {
    Product: {id: 1, label: "Product", background: "#000000"},
    Room: {id: 2, label: "Room", background: "#000000"},
    Part: {id: 3, label: "Part", background: "#000000"},
    Scene: {id: 4, label: "Scene", background: "#000000"},
}

export class TemplateState extends State {
    constructor(id: number, label: string, background: string) {
        super(id, label, background)
    }

    static override get(id: number): TemplateState {
        return State.get(id, TemplateStates)
    }
}

export const TemplateStates: {
    Draft: TemplateState
    InProgress: TemplateState
    Review: TemplateState
    Completed: TemplateState
} = {
    Draft: {id: 50, label: "Draft", background: "#989898"},
    InProgress: {id: 100, label: "In progress", background: "#ffab4a"},
    Review: {id: 200, label: "Review", background: "#7cbcb0"},
    Completed: {id: 300, label: "Completed", background: "#7ec17a"},
}

@declareApiModel(Settings.templatesUrl, EntityType.Template, ContentTypeModel.Template)
export class Template extends MakeApiModelExtendedBase<Template>() {
    @ApiFields.string({name: "name"}) name!: string
    @ApiFields.id({name: "customer"}) customer!: number
    @ApiFields.date({name: "created", readOnly: true}) created!: Date
    @ApiFields.id({name: "created_by", readOnly: true}) createdBy!: number
    @ApiFields.string({name: "comment"}) comment!: string
    @ApiFields.manyRelated({name: "revisions", model: TemplateRevision}) revisions: TemplateRevision[] = []
    @ApiFields.enumObject({name: "type", model: TemplateType}) type!: TemplateType
    @ApiFields.enumObject({name: "state", model: TemplateState}) state!: TemplateState
    @ApiFields.boolean({name: "public"}) public!: boolean

    getLatestRevision(): TemplateRevision {
        return this.revisions[0]
    }

    editable(): boolean {
        const latestRevision: TemplateRevision = this.getLatestRevision()
        return latestRevision ? true : false
    }

    copy(): Observable<Template> {
        return Template.get(this.id).pipe(
            switchMap((template: Template) => {
                console.log(template)
                const templateRevision = template.getLatestRevision()
                const newTemplate = new Template()
                const newTemplateRevision = new TemplateRevision()
                newTemplate.name = `Copy of ${template.name}`
                newTemplate.customer = template.customer
                newTemplate.state = TemplateStates.Draft
                newTemplate.type = template.type
                newTemplate.comment = template.comment
                newTemplate.public = template.public
                return newTemplate.save().pipe(
                    switchMap(() => {
                        newTemplateRevision.template = newTemplate.id
                        newTemplateRevision.templateGraph = templateRevision.templateGraph
                        return newTemplateRevision.save()
                    }),
                    map(() => {
                        return newTemplate
                    }),
                )
            }),
        )
    }
}
