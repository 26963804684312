<mat-slider [disabled]="disabled" [min]="sliderMin" [max]="sliderMax" [step]="sliderStep" #ngSlider
    ><input
        matSliderThumb
        [value]="sliderValue"
        (input)="sliderValue = {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value"
        #ngSliderThumb="matSliderThumb"
        (dragStart)="isDragging.emit(true)"
        (dragEnd)="isDragging.emit(false)"
/></mat-slider>
@if (displayValue) {
    <cm-input-container>
        <ng-container cm-input>
            <cm-numeric-input
                #valueInput
                [value]="valueNumber"
                (valueChange)="updateValueNumber($event)"
                [decimalPlaces]="displayValuePrecision"
                [readonly]="disabled"
            ></cm-numeric-input>
        </ng-container>
    </cm-input-container>
}
