import {Component, EventEmitter, Input, Output, OnDestroy, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NodeDropTargetDirective} from "@app/templates/template-publisher/template-tree/node-drop-target.directive"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {NodeUtils} from "@cm/template-nodes"
import {NodeClipboardService} from "app/templates/template-publisher/node-clipboard.service"

@Component({
    selector: "cm-node-reference-slot",
    templateUrl: "./node-reference-slot.component.html",
    styleUrls: ["./node-reference-slot.component.scss"],
    standalone: true,
    imports: [MatTooltipModule, NodeDropTargetDirective],
})
export class NodeReferenceSlotComponent implements OnInit, OnDestroy {
    @Input() fallbackText = "(no node)"
    @Output() nodeChange = new EventEmitter<Nodes.Node | null>()
    @Output() chooseNode = new EventEmitter<(material: Nodes.Node | null) => void>()
    @Input() filter?: (node: Nodes.Node) => boolean

    private _node!: Nodes.Node

    isNode!: boolean

    @Input() set node(node: Nodes.Node) {
        this._node = node
        this.isNode = NodeUtils.isNode(node)
    }

    get node() {
        return this._node
    }

    constructor(public clipboard: NodeClipboardService) {}

    ngOnInit(): void {}

    ngOnDestroy() {}

    get description(): string {
        return NodeUtils.describeNode(this.node)
    }

    clear() {
        this.nodeChange.emit(null)
    }

    get canPaste(): boolean {
        const node = this.clipboard.nodes.length === 1 ? this.clipboard.nodes[0] : null
        if (!node) return false
        if (this.filter && !this.filter(node)) return false
        return true
    }

    paste() {
        const node = this.clipboard.nodes.length === 1 ? this.clipboard.nodes[0] : null
        if (!node) return
        this.nodeChange.emit(node)
    }

    onDrop(node: Nodes.Node) {
        this.nodeChange.emit(node)
    }

    _chooseNode() {
        this.chooseNode.emit((node) => {
            this.nodeChange.emit(node)
        })
    }
}
