import {Component, inject, OnInit} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatBadgeModule} from "@angular/material/badge"
import {MatCardModule} from "@angular/material/card"
import {MatTooltipModule} from "@angular/material/tooltip"
import {AssetHistory, AssetState, ContentTypeModel, MaterialState, ModelState, NextActor, PictureState} from "@api"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {FiltersService} from "@common/services/filters/filters.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {ChangeListComponent} from "@platform/components/statistics/change-list/change-list.component"
import {StatisticsFiltersComponent} from "@platform/components/statistics/statistics-filters/statistics-filters.component"
import {itemsWhoseNextActorChanged, itemsWhoseStateChanged} from "@platform/helpers/data/statistics"
import {StatisticsData} from "@platform/models/statistics"
import {from, merge, switchMap, tap} from "rxjs"
import {IsDefined} from "@cm/utils/filter"
import {BasePageComponent} from "@pages/base/base-page.component"

@Component({
    selector: "cm-statistics",
    templateUrl: "./statistics-page.component.html",
    styleUrls: ["./statistics-page.component.scss"],
    standalone: true,
    imports: [EntityResponsiveSidebarComponent, StatisticsFiltersComponent, ChangeListComponent, MatCardModule, MatBadgeModule, MatTooltipModule],
})
export class StatisticsPageComponent extends BasePageComponent implements OnInit {
    data: StatisticsData = {}

    filters = inject(FiltersService)
    sdk = inject(SdkService)

    override ngOnInit() {
        super.ngOnInit()
        this.filters.statisticsFilter$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => (this.data = {})),
                switchMap((filters) =>
                    merge(
                        from(this.loadAssetData(filters)),
                        from(this.loadMaterialData(filters)),
                        from(this.loadModelData(filters)),
                        from(this.loadPictureData(filters)),
                    ),
                ),
            )
            .subscribe((data) => {
                this.data = {...this.data, ...data}
            })
    }

    private async loadAssetData({from, to}: {from: Date; to: Date}): Promise<Pick<StatisticsData, "asset">> {
        const {assetHistoryItems} = await this.sdk.gql.statisticsAssetsHistory({fromDate: from.toJSON(), toDate: to.toJSON()})
        const ids = assetHistoryItems.map((item) => item?.asset?.id).filter(IsDefined)
        const {previousAssetHistoryItems} = ids.length
            ? await this.sdk.gql.statisticsPreviousAssetStates({
                  fromDate: from.toJSON(),
                  ids,
              })
            : {previousAssetHistoryItems: [] as AssetHistory[]}
        const assetItems = [...previousAssetHistoryItems, ...assetHistoryItems]
        return {
            asset: {
                shading: itemsWhoseStateChanged(assetItems, AssetState.Shading, [AssetState.Review, AssetState.Completed]),
                review: itemsWhoseStateChanged(assetItems, AssetState.Review, [AssetState.Shading, AssetState.Completed]),
            },
        }
    }

    private async loadMaterialData({from, to}: {from: Date; to: Date}): Promise<Pick<StatisticsData, "material">> {
        const {materialHistoryItems} = await this.sdk.gql.statisticsMaterialsHistory({fromDate: from.toJSON(), toDate: to.toJSON()})
        const ids = materialHistoryItems.map((item) => item?.material?.id).filter(IsDefined)
        const {previousMaterialHistoryItems} = ids.length
            ? await this.sdk.gql.statisticsPreviousMaterialStates({
                  fromDate: from.toJSON(),
                  ids,
              })
            : {previousMaterialHistoryItems: [] as AssetHistory[]}
        const materialItems = [...previousMaterialHistoryItems, ...materialHistoryItems]
        return {
            material: {
                infoReview: itemsWhoseStateChanged(materialItems, MaterialState.InfoReview, [
                    MaterialState.Scanning,
                    MaterialState.Tiling,
                    MaterialState.Shading,
                ]),
                scanning: itemsWhoseStateChanged(materialItems, MaterialState.Scanning, [MaterialState.Tiling, MaterialState.Shading]),
                tiling: itemsWhoseStateChanged(materialItems, MaterialState.Tiling, [MaterialState.Shading, MaterialState.Review]),
                shading: itemsWhoseStateChanged(materialItems, MaterialState.Shading, [MaterialState.Review, MaterialState.Completed]),
                review: itemsWhoseStateChanged(materialItems, MaterialState.Review, [
                    MaterialState.Scanning,
                    MaterialState.Tiling,
                    MaterialState.Shading,
                    MaterialState.Completed,
                ]),
            },
        }
    }

    private async loadModelData({from, to}: {from: Date; to: Date}): Promise<Pick<StatisticsData, "model">> {
        const {modelHistoryItems} = await this.sdk.gql.statisticsModelsHistory({fromDate: from.toJSON(), toDate: to.toJSON()})
        const ids = modelHistoryItems.map((item) => item?.model?.id).filter(IsDefined)
        const {previousModelHistoryItems} = ids.length
            ? await this.sdk.gql.statisticsPreviousModelStates({
                  fromDate: from.toJSON(),
                  ids,
              })
            : {previousModelHistoryItems: [] as AssetHistory[]}
        const modelItems = [...previousModelHistoryItems, ...modelHistoryItems]
        return {
            model: {
                infoReview: itemsWhoseStateChanged(modelItems, ModelState.InfoReview, [ModelState.Modelling]),
                modelling: itemsWhoseStateChanged(modelItems, ModelState.Modelling, [ModelState.Review, ModelState.Completed]),
                review: itemsWhoseStateChanged(modelItems, ModelState.Review, [ModelState.Completed, ModelState.Modelling]),
            },
        }
    }

    private async loadPictureData({from, to}: {from: Date; to: Date}): Promise<Pick<StatisticsData, "picture">> {
        const {pictureHistoryItems} = await this.sdk.gql.statisticsPicturesHistory({fromDate: from.toJSON(), toDate: to.toJSON()})
        const ids = pictureHistoryItems.map((item) => item?.picture?.id).filter(IsDefined)
        const {previousPictureHistoryItems} = ids.length
            ? await this.sdk.gql.statisticsPreviousPictureStates({
                  fromDate: from.toJSON(),
                  ids,
              })
            : {previousPictureHistoryItems: [] as AssetHistory[]}
        const pictureItems = [...previousPictureHistoryItems, ...pictureHistoryItems]
        const picturesConcept = [
            ...itemsWhoseNextActorChanged(pictureItems, NextActor.Team1, NextActor.Customer, PictureState.Stage1),
            ...itemsWhoseNextActorChanged(pictureItems, NextActor.Team2, NextActor.Customer, PictureState.Stage1),
        ]
        const picturesAdjustment = [
            ...itemsWhoseNextActorChanged(pictureItems, NextActor.Team1, NextActor.Customer, PictureState.Stage2),
            ...itemsWhoseNextActorChanged(pictureItems, NextActor.Team2, NextActor.Customer, PictureState.Stage2),
        ]

        return {
            picture: {
                infoReview: itemsWhoseStateChanged(pictureItems, PictureState.InfoReview, [PictureState.Stage1, PictureState.Stage2, PictureState.FinalRender]),
                finalRender: itemsWhoseStateChanged(pictureItems, PictureState.FinalRender, [PictureState.Completed]),
                concept: picturesConcept,
                conceptCount: picturesConcept.length,
                adjustment: picturesAdjustment,
                adjustmentsCount: picturesAdjustment.length,
            },
        }
    }

    protected readonly ContentTypeModel = ContentTypeModel
}
