import {IThreadWorker, TransferListItem} from "@cm/image-processing/workers/webassembly-worker-manager"
import {Observable, fromEvent as observableFromEvent} from "rxjs"

export declare var WebWorkerImpl: Worker

export class WebWorker implements IThreadWorker {
    constructor(private _workerImpl: typeof WebWorkerImpl) {}

    postMessage(message: any, transfer?: TransferListItem[]): void {
        this._workerImpl.postMessage(message, transfer ?? [])
    }

    terminate(): void {
        this._workerImpl.terminate()
    }

    observableFromEvent(event: string): Observable<MessageEvent> {
        return observableFromEvent<MessageEvent>(this._workerImpl, "message")
    }
}
