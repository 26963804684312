import {Component} from "@angular/core"
import {ImageOperator} from "@cm/template-nodes/nodes/image-operator"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"

@Component({
    selector: "cm-image-operator-inspector",
    standalone: true,
    templateUrl: "./image-operator-inspector.component.html",
    styleUrl: "./image-operator-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class ImageOperatorInspectorComponent extends BaseInspectorComponent<ImageOperator> {
    //Currently unsupported by THREE.JS:
    //"MULTIPLY_ADD" | "COMPARE" | "SMOOTH_MIN" | "SMOOTH_MAX" | "FLOORED_MODULO" | "WRAP" | "SNAP" | "PINGPONG" | "SINH" | "COSH" | "TANH"

    operationPossibilites: SelectionPossibilities<ImageOperator["parameters"]["operation"]> = [
        {value: "ADD", name: "Add"},
        {value: "SUBTRACT", name: "Subtract"},
        {value: "MULTIPLY", name: "Multiply"},
        {value: "DIVIDE", name: "Divide"},
        //{value: "MULTIPLY_ADD", name: "Multiply Add"},
        {value: "POWER", name: "Power"},
        {value: "LOGARITHM", name: "Logarithm"},
        {value: "SQRT", name: "Square Root"},
        {value: "INVERSE_SQRT", name: "Inverse Square Root"},
        {value: "ABSOLUTE", name: "Absolute"},
        {value: "EXPONENT", name: "Exponent"},
        {value: "MINIMUM", name: "Minimum"},
        {value: "MAXIMUM", name: "Maximum"},
        {value: "LESS_THAN", name: "Less Than"},
        {value: "GREATER_THAN", name: "Greater Than"},
        {value: "SIGN", name: "Sign"},
        //{value: "COMPARE", name: "Compare"},
        //{value: "SMOOTH_MIN", name: "Smooth Min"},
        //{value: "SMOOTH_MAX", name: "Smooth Max"},
        {value: "ROUND", name: "Round"},
        {value: "FLOOR", name: "Floor"},
        {value: "CEIL", name: "Ceil"},
        {value: "TRUNC", name: "Trunc"},
        {value: "FRACT", name: "Fract"},
        {value: "MODULO", name: "Modulo"},
        //{value: "FLOORED_MODULO", name: "Floored Modulo"},
        //{value: "WRAP", name: "Wrap"},
        //{value: "SNAP", name: "Snap"},
        //{value: "PINGPONG", name: "Ping Pong"},
        {value: "SINE", name: "Sine"},
        {value: "COSINE", name: "Cosine"},
        {value: "TANGENT", name: "Tangent"},
        {value: "ARCSINE", name: "Arcsine"},
        {value: "ARCCOSINE", name: "Arccosine"},
        {value: "ARCTANGENT", name: "Arctangent"},
        {value: "ARCTAN2", name: "Arctan2"},
        //{value: "SINH", name: "Sinh"},
        //{value: "COSH", name: "Cosh"},
        //{value: "TANH", name: "Tanh"},
        {value: "RADIANS", name: "Radians"},
        {value: "DEGREES", name: "Degrees"},
    ]
}
