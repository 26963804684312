import {Component, computed, inject, OnInit, signal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {
    ContentTypeModel,
    GetTemplatesGQL,
    GetTemplatesQuery,
    ModelState,
    MutationCreateTemplateInput,
    MutationUpdateTemplateInput,
    SortOrder,
    TemplateListItemFragment,
    TemplateOrderByCriteria,
    TemplateState,
    TemplateType,
} from "@api"
import {getEditorType, TemplateEditorType} from "@app/templates/helpers/editor-type"
import {getGraphJSONForTemplateRevision} from "@app/templates/template-graph-migration"
import {CachedNodeGraphResult} from "@cm/graph"
import {LegacyTemplateConverter} from "@cm/template-nodes"
import {LegacyTemplateNodes as LegacyNodes} from "@cm/template-nodes"
import {VisitMode, VisitorNodeContext} from "@cm/template-nodes"
import {jsonToGraph} from "@cm/utils/graph-json"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent, PinnedFilterComponent, PinnedFilterOptionComponent, TagSearchFilterComponent} from "@common/components/filters"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {ItemFiltersComponent, ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {Labels, ProductTypeLabels, SceneTypeLabels, StateLabel, TemplateTypeLabels} from "@labels"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {CancellableRequest} from "@platform/models/data/cancellable-request"

@Component({
    selector: "cm-templates",
    templateUrl: "./templates.component.html",
    styleUrls: ["./templates.component.scss"],
    standalone: true,
    imports: [
        CardErrorComponent,
        CardPlaceholderComponent,
        CheckboxesFilterComponent,
        EntityCardComponent,
        EntityResponsiveSidebarComponent,
        FormsModule,
        InfiniteListComponent,
        ItemFiltersComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        RouterModule,
        StateLabelComponent,
        TagSearchFilterComponent,
        OrganizationSelectComponent,
        MatTooltipModule,
    ],
})
export class TemplatesComponent
    extends ItemListComponent<TemplateListItemFragment, MutationUpdateTemplateInput, MutationCreateTemplateInput>
    implements OnInit
{
    protected override _contentTypeModel = ContentTypeModel.Template
    public stateLabels: StateLabel<ModelState>[] = Array.from(Labels.ModelState.values())
    public sortedTemplateTypeLabels: StateLabel<TemplateType>[] = []

    private fetchRequest = new CancellableRequest<GetTemplatesQuery>(inject(GetTemplatesGQL), this.sdk, this.destroyRef)

    public $advancedActions = computed(() =>
        this.$can().update.template(null, "batch")
            ? [
                  {
                      label: `Batch convert ${this.entityName()}s`,
                      operation: () => {
                          this.batchConvertTemplates()
                      },
                  },
              ]
            : [],
    )

    public viewMode = signal<"scenes" | "products" | undefined>(undefined)
    public entityName = computed(() => {
        const viewMode = this.viewMode()
        if (viewMode === "scenes") return "scene"
        else if (viewMode === "products") return "product"
        else return "template"
    })
    public typeLabels = computed(() => {
        const viewMode = this.viewMode()
        if (viewMode === "scenes") return SceneTypeLabels
        else if (viewMode === "products") return ProductTypeLabels
        else return TemplateTypeLabels
    })

    private filterFunction = computed(() => {
        const viewMode = this.viewMode()
        if (viewMode === "scenes") return this.filters.sceneFilter
        else if (viewMode === "products") return this.filters.productFilter
        else return this.filters.templateFilter
    })

    override _initialNewItemData = () => ({
        organizationId: this.organizations.$current()?.id,
        name: "",
        state: TemplateState.Draft,
        templateType: this.viewMode() === "scenes" ? TemplateType.Room : TemplateType.Product,
    })

    override ngOnInit() {
        super.ngOnInit()

        this.viewMode.set(this.route.snapshot.data.viewMode)

        this.sortedTemplateTypeLabels = Array.from(this.typeLabels().values()).sort((a, b) => a.label.localeCompare(b.label))
    }

    batchConvertTemplates = async () => {
        const allTemplates = await this.sdk.gql.getTemplates({
            take: 100,
            filter: this.filterFunction()(),
            orderBy: [{key: TemplateOrderByCriteria.Name, direction: SortOrder.Asc}],
        })

        for (const template of allTemplates.templates) {
            if (template) {
                const id = template.latestRevision?.legacyId
                if (id) {
                    try {
                        const {templateRevision} = await this.sdk.gql.sceneManagerTemplateRevision({templateRevisionLegacyId: id})
                        const graphJson = getGraphJSONForTemplateRevision(templateRevision)
                        const templateGraph = jsonToGraph(graphJson) as LegacyNodes.TemplateGraph
                        if (templateGraph.schema !== LegacyNodes.currentTemplateGraphSchema) {
                            throw new Error(`Invalid template graph schema: '${templateGraph.schema}' != '${LegacyNodes.currentTemplateGraphSchema}'`)
                        }

                        const converter = new LegacyTemplateConverter()
                        converter.convertTemplateGraph(templateGraph)

                        console.log("Successfully converted template")
                    } catch (e) {
                        console.error("Failed to convert template: ", template.name, template.legacyId, e)
                    }
                }
            }
        }
    }

    setNewTemplateOrganization = async (data: {organizationId: string}) => {
        console.log("Setting new template organization", data.organizationId)
        this.newItemData.organizationId = data.organizationId
    }

    // OVERLOADS
    protected override openNewItem: () => boolean = () => false

    protected override _createItem = async (data: MutationCreateTemplateInput) => {
        this.notifications.showInfo("Creating template...")
        const {createTemplate: template} = await this.sdk.gql.createTemplate({
            input: data,
        })
        await this.router.navigate([template.id, "revisions", "add"], {
            relativeTo: this.route,
            queryParamsHandling: "preserve",
        })
        return template
    }

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.fetchRequest
            .fetch({
                take: take,
                skip: skip,
                filter: this.filterFunction()(),
                orderBy: [
                    {key: TemplateOrderByCriteria.Name, direction: SortOrder.Asc},
                    {key: TemplateOrderByCriteria.Id, direction: SortOrder.Desc},
                ],
            })
            .then(({templates, templatesCount}) => ({items: templates, totalCount: templatesCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .getTemplates({
                take: 1,
                filter: {
                    ...this.filterFunction()(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({templates}) => templates?.[0] || undefined)

    protected override _updateItem = (data: MutationUpdateTemplateInput) =>
        this.sdk.gql
            .updateTemplate({
                input: data,
            })
            .then(({updateTemplate: template}) => template)

    templateLink = (template: TemplateListItemFragment & {editorType?: TemplateEditorType}) => {
        if (!template.latestRevision) {
            return ["./", template.id, "revisions", "add"]
        } else {
            const editorType = template.editorType ?? getEditorType(template.latestRevision?.graph)
            template.editorType = editorType
            return ["./", template.id, "revisions", template.latestRevision.id, editorType]
        }
    }

    multiThumbnailIds = (template: TemplateListItemFragment) => {
        if (template?.galleryImage?.id) {
            return null
        }
        return template?.latestRevision?.renderedImages?.map(({id}) => id) ?? null
    }

    _copyTemplate = async (_template: TemplateListItemFragment) => {}

    copyTemplate = async (template: TemplateListItemFragment) => {
        await this.notifications.withUserFeedback(
            async () => {
                const {createTemplate: newTemplate} = await this.sdk.gql.createTemplate({
                    input: {
                        name: `Copy of ${template.name}`,
                        organizationId: template.organization.id,
                        state: TemplateState.Draft,
                        templateType: template.type,
                        comment: template.comment,
                        public: template.public,
                        revisions: [
                            {
                                graph: template.latestRevision?.graph,
                            },
                        ],
                    },
                })

                if (!newTemplate.latestRevision) throw new Error("No template revision added to copy")
                await this.router.navigate([newTemplate.id, "revisions", newTemplate.latestRevision.id, getEditorType(template.latestRevision?.graph)], {
                    relativeTo: this.route,
                    queryParamsHandling: "preserve",
                })
            },
            {
                success: "Template copied successfully",
                error: "Cannot copy template",
            },
        )
    }
}
