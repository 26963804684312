import {DEFAULT_BATCH_SIZES} from "@common/models/data/constants"
import {NotVisibleError} from "@common/models/errors"
import {EMPTY_DATA, PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {ApiData, DataWatcher, LoadedData} from "@platform/models/data"
import {BehaviorSubject, catchError, combineLatest, EMPTY, exhaustMap, filter, map, Observable, of, startWith, switchMap, tap} from "rxjs"

export const batchedData$ = <EntityType extends {id: string}>(
    loader$: Observable<DataWatcher<EntityType>>,
    needsMorePlaceholders$: Observable<boolean>,
    batchSizes: {initial: number; placeholders: number; max: number} = DEFAULT_BATCH_SIZES,
): Observable<LoadedData<EntityType>> => {
    return loader$.pipe(
        switchMap((loader) => {
            // raw data (not yet containing placeholders)
            let data = EMPTY_DATA as LoadedData<EntityType>
            // the total number of items shown, including placeholders
            let numItemsShown = batchSizes.initial
            // emit this to trigger a check whether new data should be loaded
            // this has no effect if a fetch is already in progress
            const triggerLoad$ = new BehaviorSubject<null>(null)

            // number of items to show - if there aren't enough, we will fill the spaces in with placeholders
            const numItemsShown$ = needsMorePlaceholders$.pipe(
                // need to start with true, as a change in loader might require more items to be loaded
                // without a change in the visibility of the anchor etc.
                // (e.g. a single item is shown and the search text is changed, causing more items to be visible)
                startWith(true),
                // a truthy value indicates that we need to load more placeholders
                filter((value) => !!value),
                // we stop at totalCount (if known) or the configured maximum value (to prevent infinite loops)
                filter(() => numItemsShown < (data.totalCount ?? batchSizes.max)),
                // increase the number of items to show and trigger a potential data fetch
                tap(() => {
                    numItemsShown = Math.min(numItemsShown + batchSizes.placeholders, data.totalCount ?? batchSizes.max)
                    triggerLoad$.next(null)
                }),
                // return the number of items to show
                map(() => numItemsShown),
                // start with the initial number of items, so that combineLatest emits immediately
                startWith(batchSizes.initial),
            )

            // each addition of placeholders triggers a potential data fetch
            const fetchData$ = triggerLoad$.pipe(
                // if a fetch is currently in progress, we wait for it to finish before starting a new one
                exhaustMap(() => {
                    if (data.error) {
                        return EMPTY
                    }
                    const firstIndex = data.items.length
                    const lastIndex = numItemsShown
                    if (firstIndex >= lastIndex) {
                        return EMPTY
                    }
                    if (firstIndex >= (data.totalCount ?? batchSizes.max)) {
                        return EMPTY
                    }
                    return loader(firstIndex, lastIndex - firstIndex).pipe(
                        // merge the loaded data with the existing data
                        map(
                            (loadedBatch: ApiData<EntityType>): Omit<LoadedData<EntityType>, "complete"> => ({
                                error: loadedBatch?.error ?? null,
                                items: [
                                    ...data.items,
                                    ...loadedBatch.items.map((dataItem) => {
                                        if (dataItem) {
                                            return {data: dataItem, error: null}
                                        } else {
                                            // TODO: more precise error handling
                                            // we currently assume that a null data item means that the item is not visible
                                            return {data: null, error: new NotVisibleError()}
                                        }
                                    }),
                                ],
                                totalCount: loadedBatch?.totalCount,
                            }),
                        ),
                        // handle errors as gracefully as possible
                        catchError(
                            (error: Error): Observable<Omit<LoadedData<EntityType>, "complete">> =>
                                of({
                                    error,
                                    items: [...data.items, ...Array.from({length: lastIndex - firstIndex}, () => ({data: null, error}))],
                                    totalCount: data.totalCount,
                                }),
                        ),
                        map(
                            (loadedData: Omit<LoadedData<EntityType>, "complete">): LoadedData<EntityType> => ({
                                ...loadedData,
                                complete: loadedData.items.length === (loadedData.totalCount ?? batchSizes.max),
                            }),
                        ),
                        tap((loadedData: LoadedData<EntityType>) => {
                            data = loadedData
                        }),
                        tap({
                            complete: () => {
                                setTimeout(() => triggerLoad$.next(null), 200)
                            },
                        }),
                    )
                }),
                startWith(data),
            )

            // add placeholders to the data, if needed
            return combineLatest([fetchData$, numItemsShown$]).pipe(
                map(([data, numItemsShown]): LoadedData<EntityType> => {
                    const numberOfPlaceholders = Math.max(Math.min(numItemsShown, data.totalCount ?? batchSizes.max) - data.items.length, 0)
                    const placeholders = data.error ? [] : Array.from({length: numberOfPlaceholders}, () => ({data: null, error: null}))
                    return {
                        items: [...data.items, ...placeholders],
                        totalCount: data.totalCount,
                        complete: data.items.length === data.totalCount,
                        error: data.error ?? null,
                    }
                }),
            )
        }),
        startWith(PLACEHOLDER_ITEMS<EntityType>(batchSizes.initial)),
    )
}
