import {ZodUtils} from "@cm/utils/zod-utils"
import {z} from "zod"

// TODO derive this from JobFarmInstanceControl
export type BackendExitAction = `${"Exit" | "Update"}${"Now" | "WhenIdle"}`
export type InstanceControlCommand = BackendExitAction
export type FrontendExitAction = "exit" | "logout" | "update"

export type Signal<T> = Promise<T>
export type SignalTrigger<T> = (signal: T) => void
export function registerSignalHandler<T>(): {signal: Signal<T>; trigger: SignalTrigger<T>} {
    let trigger: SignalTrigger<T> = (signal: T) => {}
    const signal = new Promise<T>((resolve) => (trigger = resolve))
    return {signal, trigger}
}

export type SystemInfo = {
    hwid: string
    num_logical_cpus: number
    num_physical_cpus: number
    total_memory: number
    available_memory: number
    os_version: string
    host_name: string
    gpu_name?: string
    gpu_total_memory?: number
}

export const maxMessageSize = z.union([
    ZodUtils.templateLiteral(z.number(), z.literal("K")),
    ZodUtils.templateLiteral(z.number(), z.literal("M")),
    ZodUtils.templateLiteral(z.number(), z.literal("G")),
])
export type MaxMessageSize = z.infer<typeof maxMessageSize>

export function maxMessageSizeAsInt(size: MaxMessageSize) {
    const parsedSize = maxMessageSize.safeParse(size)
    if (!parsedSize.success) throw Error(`Invalid value for MaxMessageSize: ${size}`)

    const multiplier = (() => {
        switch (size.slice(-1)) {
            case "K":
                return 1024
            case "M":
                return 1024 * 1024
            case "G":
            default:
                return 1024 * 1024 * 1024
        }
    })()

    return parseInt(size.slice(0, -1)) * multiplier
}
