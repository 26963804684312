import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {extractChannel} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-extract-channel"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {combineChannels} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-combine-channels"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"
import {deg2rad} from "@cm/math"
import {mapZeroOneToMinusOnePlusOne} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/map-zero-one-to-minus-one-plus-one"
import {mapMinusOnePlusOneToZeroOne} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/map-minus-one-plus-one-to-zero-one"

const SCOPE_NAME = "RotateVectorMap"

export type ParameterType = {
    sourceImage: ImageRef
    angleInDegrees: number // angle in degrees to rotate by
    angleFactor?: number // factor by which a full rotation is accomplished; 1 for vectors which repeat in 360°; 2 for vectors which repeat in 180°; negative values reverse cw/ccw; default: 1
}

export type ReturnType = ImageRef

export function rotateVectorMap(cmdQueue: ImageOpCommandQueue, {sourceImage, angleInDegrees, angleFactor}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)

    angleFactor ??= 1
    let x = extractChannel(cmdQueue, {
        sourceImage: sourceImage,
        channel: "R",
    })
    let y = extractChannel(cmdQueue, {
        sourceImage: sourceImage,
        channel: "G",
    })
    const z = extractChannel(cmdQueue, {
        sourceImage: sourceImage,
        channel: "B",
    })

    x = mapZeroOneToMinusOnePlusOne(cmdQueue, {sourceImage: x})
    y = mapZeroOneToMinusOnePlusOne(cmdQueue, {sourceImage: y})

    const xTimesAngleCos = math(cmdQueue, {
        operandA: x,
        operandB: Math.cos(deg2rad(angleInDegrees * angleFactor)),
        operator: "*",
    })
    const yTimesAngleSin = math(cmdQueue, {
        operandA: y,
        operandB: Math.sin(deg2rad(angleInDegrees * angleFactor)),
        operator: "*",
    })
    let rotatedX = math(cmdQueue, {
        operandA: xTimesAngleCos,
        operandB: yTimesAngleSin,
        operator: "+",
    })

    const yTimesAngleCos = math(cmdQueue, {
        operandA: y,
        operandB: Math.cos(deg2rad(angleInDegrees * angleFactor)),
        operator: "*",
    })
    const xTimesAngleSin = math(cmdQueue, {
        operandA: x,
        operandB: Math.sin(deg2rad(angleInDegrees * angleFactor)),
        operator: "*",
    })
    let rotatedY = math(cmdQueue, {
        operandA: yTimesAngleCos,
        operandB: xTimesAngleSin,
        operator: "-",
    })

    rotatedX = mapMinusOnePlusOneToZeroOne(cmdQueue, {sourceImage: rotatedX})
    rotatedY = mapMinusOnePlusOneToZeroOne(cmdQueue, {sourceImage: rotatedY})

    let result = combineChannels(cmdQueue, {
        sourceImages: [rotatedX, rotatedY, z],
    })
    result = convert(cmdQueue, {
        sourceImage: result,
        dataType: sourceImage.descriptor.dataType,
    })

    cmdQueue.endScope(SCOPE_NAME)

    return result
}
