import {getAllTemplateNodes} from "#template-nodes/utils"
import {DataObjectReference} from "@cm/template-nodes/nodes/data-object-reference"
import {HDRILight} from "@cm/template-nodes/nodes/hdri-light"
import {MaterialReference} from "@cm/template-nodes/nodes/material-reference"
import {TemplateGraph} from "@cm/template-nodes/nodes/template-graph"
import {TemplateReference} from "@cm/template-nodes/nodes/template-reference"
import {DataObjectAssignmentType} from "@cm/utils/data-object-assignment"

export type TemplateGraphReferencedIds = {
    templateReferences: Set<number>
    materialReferences: Set<number>
    hdriReferences: Set<number>
    dataObjectAssignments: Map<number, Set<DataObjectAssignmentType>>
}

/*The old template system does this in the backend, see
backend/pothos/src\helpers/graph/templates/collect-references.ts*/
export function collectReferences(templateGraph: TemplateGraph): TemplateGraphReferencedIds {
    const templateReferences = new Set<number>()
    const materialReferences = new Set<number>()
    const hdriReferences = new Set<number>()
    const dataObjectAssignments = new Map<number, Set<DataObjectAssignmentType>>()

    for (const node of getAllTemplateNodes(templateGraph)) {
        if (node instanceof TemplateReference) {
            templateReferences.add(node.parameters.templateRevisionId)
        } else if (node instanceof MaterialReference) {
            materialReferences.add(node.parameters.materialRevisionId)
        } else if (node instanceof HDRILight) {
            hdriReferences.add(node.parameters.hdriId)
        } else if (node instanceof DataObjectReference) {
            dataObjectAssignments.set(
                node.parameters.dataObjectId,
                dataObjectAssignments.get(node.parameters.dataObjectId)?.add(DataObjectAssignmentType.TemplateDataOther) ??
                    new Set<DataObjectAssignmentType>([DataObjectAssignmentType.TemplateDataOther]),
            )
        }
    }

    return {templateReferences, materialReferences, hdriReferences, dataObjectAssignments}
}
