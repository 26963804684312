import {NodeSettings} from "@material-editor/models/material-nodes"
import {MaterialSocket} from "@material-editor/models/material-socket"

export const MathInputs: Record<string, MaterialSocket> = {
    value01: {paramType: "socket", id: "Value", type: "input", valueType: "scalar", label: "Value", range: {min: 0, max: 2}},
    value02: {paramType: "socket", id: "Value_001", type: "input", valueType: "scalar", label: "Value", range: {min: 0, max: 2}},
}

export const MathOutputs: Record<string, MaterialSocket> = {
    value: {paramType: "socket", id: "Value", type: "output", valueType: "output", label: "Value"},
}

export const MathMethod: NodeSettings = {
    id: "internal.operation",
    valueType: "string",
    options: [
        {label: "Add", value: "ADD"},
        {label: "Multiply", value: "MULTIPLY"},
        {label: "Subtract", value: "SUBTRACT"},
        {label: "Divide", value: "DIVIDE"},
        {label: "Greater" + " Than", value: "GREATER_THAN"},
        {label: "Less Than", value: "LESS_THAN"},
        {label: "Arctan2", value: "ARCTAN2"},
        {label: "Maximum", value: "MAXIMUM"},
    ],
}
