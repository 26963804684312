import {Overlay} from "@angular/cdk/overlay"
import {Component, inject, OnDestroy} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DATE_FORMATS} from "@angular/material/core"
import {MatDatepickerModule} from "@angular/material/datepicker"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterLink, RouterOutlet} from "@angular/router"
import {ContentTypeModel, MutationUpdatePictureInput, NextActor, PictureDetailsFragment, PictureState} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {SectionComponent} from "@common/components/item"
import {CM_SHORT_DATE_FORMAT} from "@common/models/settings/settings"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {Enums} from "@enums"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {ActivitySectionComponent} from "@platform/components/details/activity-section/activity-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {PictureAssetsSectionComponent} from "@platform/components/details/picture-assets-section/picture-assets-section.component"
import {ProjectManagementTasksSectionComponent} from "@platform/components/details/project-management-tasks-section/project-management-tasks-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {AssociatedJobsTableComponent} from "@platform/components/jobs/associated-jobs-table/associated-jobs-table.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {PictureRevisionSelectorComponent} from "@platform/components/pictures/picture-revision-selector/picture-revision-selector.component"
import {PictureStatisticsComponent} from "@platform/components/pictures/picture-statistics/picture-statistics.component"
import {TasksService} from "@platform/services/tasks/tasks.service"
import {TagSelectComponent} from "@common/components/inputs/select/tag-select/tag-select.component"

@Component({
    imports: [
        DetailsDialogLayoutComponent,
        FormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatTooltipModule,
        PictureRevisionSelectorComponent,
        RouterLink,
        SectionComponent,
        AssociatedJobsTableComponent,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        PictureStatisticsComponent,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        FilesSectionComponent,
        ActivitySectionComponent,
        ProjectManagementTasksSectionComponent,
        TitleSectionComponent,
        PictureAssetsSectionComponent,
        RouterOutlet,
        TagSelectComponent,
    ],
    providers: [{provide: MAT_DATE_FORMATS, useValue: CM_SHORT_DATE_FORMAT}],
    selector: "cm-picture",
    standalone: true,
    styleUrls: ["picture-details.component.scss"],
    templateUrl: "picture-details.component.html",
})
export class PictureDetailsComponent extends BaseDetailsComponent<PictureDetailsFragment, Omit<MutationUpdatePictureInput, "id">> implements OnDestroy {
    override _contentTypeModel = ContentTypeModel.Picture
    override _fetchItem = this.sdk.gql.pictureDetails
    override _updateItem = this.sdk.gql.updatePictureDetails
    override _deleteItem = this.sdk.raw.deletePictureDetails

    override displayName = "picture"

    organizations = inject(OrganizationsService)
    overlay = inject(Overlay)
    task = inject(TasksService)

    async updatePicture(data: Omit<MutationUpdatePictureInput, "id">, stateChange = false) {
        const pictureId = this.$item()?.id
        if (pictureId) {
            await this.notifications.withUserFeedback(
                () =>
                    this.sdk.throwable.pictureDetailsUpdatePicture({
                        input: {
                            id: pictureId,
                            ...data,
                        },
                    }),
                {
                    success: "Picture updated.",
                    error: "Failed to update picture.",
                },
            )
            if (stateChange) {
                this.refresh.item(this.$item())
            }
        }
    }

    async afterAssetChange() {
        this.refresh.item(this.$item())
    }

    canSubmitPicture(picture: PictureDetailsFragment) {
        return picture.state === PictureState.Draft && !this.sdk.$silo()?.systemRole && this.sdk.$silo()?.organization?.id === picture.organization.id
    }

    canMovePictureAlongPipeline(picture: PictureDetailsFragment) {
        return !this.sdk.$silo()?.systemRole && picture && Enums.InPipelineStates.Picture.includes(picture.state)
    }

    ngOnDestroy(): void {
        this.refresh.item(this.$item())
    }

    protected readonly ContentTypeModel = ContentTypeModel
    protected readonly NextActor = NextActor
    protected readonly PictureState = PictureState
    protected readonly DialogSize = DialogSize
}
