import {ThreeNodes as THREENodes} from "@cm/material-nodes/three"

export const lutSize = 256
//+ 0.5 / lutSize due to THREE.NearestFilter
const applyLut = new THREENodes.FunctionNode(`
vec3 applyLut(vec3 rgbIn, sampler2D lut, float fac) {
    vec3 rgbOut = vec3(
        texture(lut, vec2(rgbIn.r + ${0.5 / lutSize}, 0.5)).r,
        texture(lut, vec2(rgbIn.g + ${0.5 / lutSize}, 0.5)).g,
        texture(lut, vec2(rgbIn.b + ${0.5 / lutSize}, 0.5)).b
    );
    return mix(rgbIn, rgbOut, fac);
}
`)

export class ApplyLUTNode extends THREENodes.TempNode {
    constructor(
        public rgbInput: THREENodes.Node,
        public lutTexture: THREE.Texture,
        public fac: THREENodes.Node,
    ) {
        super("vec3")
    }

    override generate(builder: THREENodes.NodeBuilder) {
        const type = this.getNodeType(builder)

        const lutTextureNode = THREENodes.convert(THREENodes.texture(this.lutTexture), "texture")
        const rgbOutput = THREENodes.call(applyLut, {rgbIn: this.rgbInput, lut: lutTextureNode, fac: this.fac})
        return rgbOutput.build(builder, type)
    }
}
