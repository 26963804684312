import {Catalog, CatalogDiff, CatalogItem, CatalogPrice, GetSubgraphCallback} from "#pricing/catalogs/catalog-interface"
import {ConfigurationList} from "#pricing/nodes/configuration-list"
import {Currency, PricingContext} from "#pricing/nodes/core"
import {PricedItemNode} from "#pricing/nodes/priced-item-node"
import {VariantConditionNode} from "#pricing/nodes/variant-condition-node"
import type {utils, WorkSheet} from "xlsx"

export class VadoCatalog implements Catalog {
    items: VadoCatalogItem[]

    constructor(items: VadoCatalogItem[]) {
        this.items = items
    }

    search(query: string): CatalogItem[] {
        const lowerCaseQuery = query.toLowerCase()
        return this.items.filter((item) => item.description.toLowerCase().includes(lowerCaseQuery) || item.sku.toLowerCase().includes(lowerCaseQuery))
    }

    getAllPricesAsContext(): PricingContext {
        const priceMap = new Map<string, number>()
        this.items.forEach((item) => priceMap.set(item.sku, Number(item.priceIncVat)))
        return new PricingContext(priceMap, Currency.Gbp)
    }

    getAllPrices(): CatalogPrice[] {
        const result: CatalogPrice[] = []
        this.items.forEach((item) => result.push({uniqueId: item.sku, price: item.priceIncVat, currency: Currency.Gbp})) //TODO: GBP
        return result
    }

    diff(other: Catalog): CatalogDiff {
        throw new Error("Method not implemented.")
    }
}

class VadoCatalogItem implements CatalogItem {
    sku: string
    descriptionCatalog: string
    finish: string
    priceExVat: number
    priceIncVat: number

    constructor(sku: string, description: string, finish: string, priceExVat: number, priceIncVat: number) {
        this.sku = sku
        this.descriptionCatalog = description
        this.finish = finish
        this.priceExVat = priceExVat
        this.priceIncVat = priceIncVat
    }

    get uniqueId() {
        return this.sku
    }

    get description() {
        return `${this.descriptionCatalog}, ${this.finish}`
    }

    createPriceGraph(getSubgraph: GetSubgraphCallback): PricedItemNode {
        return new PricedItemNode({
            description: this.description,
            uniqueId: this.uniqueId,
            condition: new VariantConditionNode({
                condition: {variantIds: [], variantOperator: "AND", negated: false},
                currentConfigurations: new ConfigurationList({list: []}),
            }),
            subPrices: undefined,
            sku: this.sku,
            dependsOnSubprice: false,
            amount: 1,
        })
    }
}

export function parseXlsb(xlsb: Uint8Array, xlsx: typeof import("xlsx")): Catalog {
    const workbook = xlsx.read(xlsb, {type: "buffer", cellDates: true, cellStyles: true, cellNF: true, cellText: true})
    const sheetName = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[sheetName]

    const headerRowIndex = 0

    const expectedColTitles = ["SKU", "Description", "Finish", "Price (ex VAT)", "Price (inc VAT)"]
    verifyXlsx(worksheet, headerRowIndex, expectedColTitles, xlsx)

    const range = xlsx.utils.decode_range(worksheet["!ref"]!)
    range.s.r = headerRowIndex + 1 // Skip header row for loading data

    const newRange = xlsx.utils.encode_range(range)
    const jsonData = xlsx.utils.sheet_to_json(worksheet, {
        range: newRange,
        header: expectedColTitles,
        blankrows: false,
    })

    const exclude = new Set(["l/min", "for"])

    const catalogItems = jsonData.map((item: any) => {
        const a = new VadoCatalogItem(
            item["SKU"],
            capitalize(item["Description"], exclude),
            capitalize(item["Finish"], exclude),
            item["Price (ex VAT)"],
            item["Price (inc VAT)"],
        )
        console.log(a.description)
        return a
    })

    verifyData(catalogItems)

    return new VadoCatalog(catalogItems)
}

function verifyXlsx(worksheet: WorkSheet, headerRowIndex: number, expectedColTitles: string[], xlsx: {utils: typeof utils}) {
    expectedColTitles.forEach((title, index) => {
        const cellValue = worksheet[xlsx.utils.encode_cell({r: headerRowIndex, c: index})]?.v.trim() // Adjusted column index for zero-based
        if (cellValue !== title) throw new Error(`Expected ${title} in cell ${xlsx.utils.encode_col(index)}${headerRowIndex + 1}, but got ${cellValue}`)
    })
}

function capitalize(sentence: string, exclude: Set<string>): string {
    return sentence
        .split(" ")
        .map((word) => {
            if (exclude.has(word)) return word
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(" ")
}

function verifyData(catalogItems: VadoCatalogItem[]) {
    const uniqueIds = new Set<string>()
    catalogItems.forEach((item) => {
        if (uniqueIds.has(item.sku)) throw new Error(`Duplicate SKU: ${item.sku}`)
        uniqueIds.add(item.sku)
    })
}
