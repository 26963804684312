import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {DiffuseBsdfInputs, DiffuseBsdfOutputs} from "@material-editor/models/nodes/diffuse-bsdf"

@Component({
    selector: "cm-diffuse-bsdf-node",
    templateUrl: "./diffuse-bsdf-node.component.html",
    styleUrls: ["./diffuse-bsdf-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class DiffuseBsdfNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = DiffuseBsdfInputs
    outputs = DiffuseBsdfOutputs
    typeInfo = DiffuseBsdfNodeType
}

export const DiffuseBsdfNodeType: MaterialNodeType<typeof DiffuseBsdfNodeComponent> = {
    id: "diffuseBsdf",
    label: "Diffuse BSDF",
    color: "#4987af",
    name: "ShaderNodeBsdfDiffuse",
    inputs: [DiffuseBsdfInputs.color, DiffuseBsdfInputs.roughness, DiffuseBsdfInputs.normal] as never[],
    outputs: [DiffuseBsdfOutputs.bsdf],
    component: DiffuseBsdfNodeComponent,
}
