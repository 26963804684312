import {Component, Input} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {IEditor} from "@editor/models/editor"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {defaultsForToneMapping} from "@cm/image-processing/tone-mapping"
import {NodeUtils} from "@cm/template-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"

@Component({
    selector: "cm-camera-inspector",
    templateUrl: "./camera-inspector.component.html",
    styleUrls: ["./camera-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        InputContainerComponent,
        NumericInputComponent,
        MatTooltipModule,
        ToggleComponent,
        MatInputModule,
        MatSelectModule,
        NodeReferenceSlotComponent,
    ],
})
export class CameraInspectorComponent {
    @Input() editor!: IEditor
    @Input() node: Nodes.Camera | null = null

    NodeUtils = NodeUtils

    convertExposure = {
        toDisplay: (linearExposure: number) => Math.log2(linearExposure),
        fromDisplay: (ev: number) => Math.pow(2, ev),
    }

    defaultsForToneMapping = defaultsForToneMapping

    nodeChanged(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    hasHighlightCompression(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {highlightCompression: number} {
        return "highlightCompression" in toneMapping
    }
    hasContrast(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {contrast: number} {
        return "contrast" in toneMapping
    }
    hasSaturation(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {saturation: number} {
        return "saturation" in toneMapping
    }
    hasBalance(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {balance: number} {
        return "balance" in toneMapping
    }
    hasColorBalance(toneMapping: Nodes.ToneMapping): toneMapping is Nodes.ToneMapping & {colorBalance: number} {
        return "colorBalance" in toneMapping
    }
}
