import {TagType} from "@api"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const TagTypeLabels: Map<TagType, StateLabel<TagType>> = new Map<TagType, StateLabel<TagType>>([
    [TagType.Production, {state: TagType.Production, label: "Production", background: "#06b6d4"}],
    [TagType.MaterialRange, {state: TagType.MaterialRange, label: "Material Range", background: "#84cc16"}],
    [TagType.ProductRange, {state: TagType.ProductRange, label: "Product Range", background: "#f4d03f"}],
    [TagType.PdfSpecItem, {state: TagType.PdfSpecItem, label: "PDF Spec Item", background: "#d33ff4"}],
    [TagType.PictureAnnotation, {state: TagType.PictureAnnotation, label: "Picture Annotation", background: "#2d7348"}],
    [TagType.ProductCategory, {state: TagType.ProductCategory, label: "Product Category", background: "#4d52a8"}],
])

export const TagTypeOptions = [
    TagType.Production,
    TagType.MaterialRange,
    TagType.ProductRange,
    TagType.PdfSpecItem,
    TagType.PictureAnnotation,
    TagType.ProductCategory,
].map((tagType) => TagTypeLabels.get(tagType) as StateLabel<TagType>)
