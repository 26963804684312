@for (selectedNode of selectedNodes(); track selectedNode; let index = $index) {
    @if (index > 0) {
        <div class="cm-inspector-divider"></div>
    }
    @if (isAnnotation(selectedNode)) {
        <cm-annotation-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-annotation-inspector>
    } @else if (isCamera(selectedNode)) {
        <cm-camera-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-camera-inspector>
    } @else if (isConfigGroup(selectedNode)) {
        <cm-config-group-inspector [node]="selectedNode" class="cm-inspector"> </cm-config-group-inspector>
    } @else if (isConfigVariant(selectedNode)) {
        <cm-config-variant-inspector [node]="selectedNode" class="cm-inspector"> </cm-config-variant-inspector>
    } @else if (isMesh(selectedNode)) {
        <cm-mesh-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-mesh-inspector>
    } @else if (isPostProcessRender(selectedNode)) {
        <cm-post-process-render-inspector [node]="selectedNode" class="cm-inspector"> </cm-post-process-render-inspector>
    } @else if (isSceneProperties(selectedNode)) {
        <cm-scene-properties-inspector [node]="selectedNode" class="cm-inspector"> </cm-scene-properties-inspector>
    } @else if (isTemplateInstance(selectedNode)) {
        <cm-template-instance-inspector [node]="selectedNode" class="cm-inspector">
            @if (isTemplateInstance(selectedNode)) {
                <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
                </cm-object-inspector>
            }
        </cm-template-instance-inspector>
    } @else if (isRigidRelation(selectedNode)) {
        <cm-rigid-relation-inspector [node]="selectedNode" class="cm-inspector"> </cm-rigid-relation-inspector>
    } @else if (isMeshDecal(selectedNode)) {
        <cm-mesh-decal-inspector [node]="selectedNode" class="cm-inspector"> </cm-mesh-decal-inspector>
    } @else if (isMeshCurve(selectedNode)) {
        <cm-mesh-curve-inspector [node]="selectedNode" class="cm-inspector"> </cm-mesh-curve-inspector>
    } @else if (isGroup(selectedNode)) {
        <cm-group-inspector [node]="selectedNode" class="cm-inspector"> </cm-group-inspector>
    } @else if (isPlaneGuide(selectedNode)) {
        <cm-plane-guide-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-plane-guide-inspector>
    } @else if (isPointGuide(selectedNode)) {
        <cm-point-guide-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-point-guide-inspector>
    } @else if (isHDRILight(selectedNode)) {
        <cm-hdri-light-inspector [node]="selectedNode" class="cm-inspector"> </cm-hdri-light-inspector>
    } @else if (isAreaLight(selectedNode)) {
        <cm-area-light-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-area-light-inspector>
    } @else if (isLightPortal(selectedNode)) {
        <cm-light-portal-inspector [node]="selectedNode" class="cm-inspector">
            <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
            </cm-object-inspector>
        </cm-light-portal-inspector>
    } @else if (isRender(selectedNode)) {
        <cm-render-inspector [node]="selectedNode" class="cm-inspector"> </cm-render-inspector>
    } @else if (isInput(selectedNode)) {
        <cm-input-inspector [node]="selectedNode" class="cm-inspector"> </cm-input-inspector>
    } @else if (isExport(selectedNode)) {
        <cm-export-inspector [node]="selectedNode" class="cm-inspector"> </cm-export-inspector>
    } @else if (isValue(selectedNode)) {
        <cm-value-inspector [node]="selectedNode" class="cm-inspector"> </cm-value-inspector>
    } @else if (isOverlayMaterialColor(selectedNode)) {
        <cm-overlay-material-color-inspector [node]="selectedNode" class="cm-inspector"> </cm-overlay-material-color-inspector>
    } @else if (isMaterialReference(selectedNode)) {
        <cm-material-reference-inspector [node]="selectedNode" class="cm-inspector"> </cm-material-reference-inspector>
    } @else if (isLodType(selectedNode)) {
        <cm-lod-type-inspector [node]="selectedNode" class="cm-inspector"></cm-lod-type-inspector>
    } @else if (isDataObjectReference(selectedNode)) {
        <cm-data-object-reference-inspector [node]="selectedNode" class="cm-inspector"></cm-data-object-reference-inspector>
    } @else if (isImageOperator(selectedNode)) {
        <cm-image-operator-inspector [node]="selectedNode" class="cm-inspector"> </cm-image-operator-inspector>
    } @else {
        <cm-generic-inspector [node]="selectedNode" class="cm-inspector">
            @if (isObject(selectedNode)) {
                <cm-object-inspector [node]="selectedNode" [transformMode]="transformMode()" (transformModeChanged)="transformModeChanged.emit($event)">
                </cm-object-inspector>
            }
        </cm-generic-inspector>
    }
}
