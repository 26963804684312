import {IMaterialConnection, IMaterialGraph, IMaterialNode, isMaterialGraph} from "#material-nodes/material-node-graph"
import {TextureResolution} from "#material-nodes/types"
import {hashObject, removeUndefinedEntriesFromObject} from "@cm/utils"

export {IMaterialNode, IMaterialConnection, IMaterialGraph, isMaterialGraph}

export type MaterialSide = "front" | "back" | "double"

export interface IMaterialData {
    name: string
    materialGraph: IMaterialGraph
    side: MaterialSide
    alphaMaskThreshold?: number
    realtimeSettings?: {
        disable?: boolean
        textureResolution?: TextureResolution
        textureFiltering?: "linear" | "nearest"
    }
}

export interface IMaterialRevision {
    id: number
}

export interface IMaterial {
    id: number
    getLatestCyclesRevision(): IMaterialRevision | undefined
}

export function keyForMaterialData(materialData: IMaterialData) {
    return hashObject(
        removeUndefinedEntriesFromObject({
            uniqueId: materialData.materialGraph.uniqueId,
            side: materialData.side,
            alphaMaskThreshold: materialData.alphaMaskThreshold,
            disable: materialData.realtimeSettings?.disable,
            textureResolution: materialData.realtimeSettings?.textureResolution,
            textureFiltering: materialData.realtimeSettings?.textureFiltering,
        }),
    )
}
