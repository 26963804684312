import {AfterViewInit, Component, inject} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute} from "@angular/router"
import {ContentTypeModel, DataObjectAssignmentType} from "@api"
import {GltfExportService} from "@app/common/services/gltf-export/gltf-export.service"
import {ThreeTemplateSceneProviderComponent} from "@app/template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {loadGraphForNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {deserializeNodeGraph} from "@cm/graph/node-graph"
import {IsDefined} from "@cm/utils"
import {AuthService} from "@common/services/auth/auth.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {removeCachedArData} from "@pages/ar-generation/helpers/helpers"
import {filter, take} from "rxjs"
import {Parameters} from "@cm/template-nodes/nodes/parameters"

@Component({
    selector: "cm-backend-ar-generation",
    templateUrl: "./backend-ar-generation.component.html",
    styleUrls: ["./backend-ar-generation.component.scss"],
    standalone: true,
    providers: [SceneManagerService, GltfExportService],
    imports: [ThreeTemplateSceneProviderComponent],
})
export class BackendArGenerationComponent implements AfterViewInit {
    auth = inject(AuthService)
    sdk = inject(SdkService)
    gltfExportService = inject(GltfExportService)
    sceneManagerService = inject(SceneManagerService)
    threeSceneManagerService: ThreeSceneManagerService | undefined
    userAvailable = false

    constructor(
        private route: ActivatedRoute,
        private uploadService: UploadGqlService,
        private snackBar: MatSnackBar,
    ) {}

    ngAfterViewInit(): void {
        // need to wait for the user to be loaded due to Angular bug in headless mode:
        // component is activated before the `canActivate` guard is resolved
        this.auth.userId$.pipe(filter(IsDefined), take(1)).subscribe(() => {
            this.userAvailable = true
            this.exportSceneIfPossible()
        })
    }

    getTemplateRevisionId(): string {
        const result = this.route.snapshot.queryParamMap.get("templateRevisionId")
        if (!result) throw new Error("The templateRevisionId query parameter is missing.")
        return result
    }

    getParameters() {
        const parameterStringB64 = this.route.snapshot.queryParamMap.get("templateParametersBase64")
        if (!parameterStringB64) throw new Error("The templateParametersBase64 query parameter is missing.")

        const parameters = deserializeNodeGraph(JSON.parse(atob(parameterStringB64)))
        if (!(parameters instanceof Parameters)) throw new Error("Wrong parameter types")
        return parameters
    }

    async setupAndExportScene(threeSceneManagerService: ThreeSceneManagerService) {
        const templateRevisionId = this.getTemplateRevisionId()
        const parameters = this.getParameters()
        const assignmentKey = this.route.snapshot.queryParamMap.get("assignmentKey")
        if (!assignmentKey) throw new Error("The assignmentKey query parameter is missing.")
        const {templateRevision} = await this.sdk.gql.getTemplateRevisionForBackendArGeneration({
            id: templateRevisionId,
            assignmentKey: {equals: assignmentKey},
        })

        await removeCachedArData(templateRevision, this.sdk)

        console.log("Exporting scene with assignment key:", assignmentKey)
        console.log("Parameters: ", parameters)

        const templateGraph = loadGraphForNewTemplateSystem(templateRevision.graph)

        const glbData = await this.gltfExportService.exportGltfFile(templateGraph, parameters, threeSceneManagerService)

        await this.uploadAndAttachGLTFToEntity(glbData, assignmentKey, templateRevisionId, templateRevision.template.organizationId)

        console.log("Export and upload completed.")

        //For debugging: FilesService.downloadFile("glb-export.glb", new Blob([glbData]))
    }

    async uploadAndAttachGLTFToEntity(glbData: Uint8Array, assignmentKey: string, templateRevisionId: string, organizationId: string) {
        const dataObject = await this.uploadService.createAndUploadDataObject(new File([glbData], "export.glb"), {
            organizationId,
            mediaType: "model/gltf-binary",
            size: glbData.byteLength,
        })
        return this.sdk.gql.createDataObjectAssignmentForBackendArGeneration({
            input: {
                dataObjectId: dataObject.id,
                contentTypeModel: ContentTypeModel.TemplateRevision,
                objectId: templateRevisionId,
                type: DataObjectAssignmentType.CachedTemplateGltf,
                assignmentKey: assignmentKey,
            },
        })
    }

    async exportSceneIfPossible() {
        if (!this.threeSceneManagerService || !this.userAvailable) return
        await this.setupAndExportScene(this.threeSceneManagerService)
        window.status = "ready" // Let puppeteer know that the AR generation finished.
    }

    onInititalizedThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService) {
        threeSceneManagerService.$ambientLight.set(false)
        threeSceneManagerService.$showGrid.set(false)
        threeSceneManagerService.$displayMode.set("configurator")
        this.threeSceneManagerService = threeSceneManagerService

        this.exportSceneIfPossible()
    }
}
