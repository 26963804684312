export const NUM_GRID_POINTS_PER_SEGMENT = 1 // increase this when using curve interpolators other than linear

export function computeTValuesForControlPoints(controlPoints: {t: number}[], numGridPointsPerSegment = NUM_GRID_POINTS_PER_SEGMENT): number[] {
    const numSegments = controlPoints.length - 1
    const tValues = new Array<number>(numSegments * numGridPointsPerSegment + 1)
    for (let i = 0; i < numSegments; i++) {
        const tStart = controlPoints[i].t
        const tEnd = controlPoints[i + 1].t
        for (let j = 0; j < numGridPointsPerSegment; j++) {
            tValues[i * numGridPointsPerSegment + j] = tStart + (tEnd - tStart) * (j / numGridPointsPerSegment)
        }
    }
    tValues[numSegments * numGridPointsPerSegment] = controlPoints[numSegments].t
    return tValues
}

export function subdivideTValues(tValues: number[], numSubdivisions: number): number[] {
    if (numSubdivisions < 0) {
        throw new Error("numSubdivisions must be non-negative")
    }
    if (numSubdivisions === 0) {
        return tValues
    }
    const newTValues = new Array<number>((tValues.length - 1) * (numSubdivisions + 1) + 1)
    for (let i = 0; i < tValues.length - 1; i++) {
        const tStart = tValues[i]
        const tEnd = tValues[i + 1]
        for (let j = 0; j <= numSubdivisions; j++) {
            newTValues[i * (numSubdivisions + 1) + j] = tStart + (tEnd - tStart) * (j / (numSubdivisions + 1))
        }
    }
    newTValues[(tValues.length - 1) * (numSubdivisions + 1)] = tValues[tValues.length - 1]
    return newTValues
}
