<h2 mat-dialog-title>New item</h2>
<mat-dialog-content>
    <form id="entityForm" (submit)="confirmAction()">
        @if (data.entityType !== Enums.ContentTypeModel.Organization) {
            <mat-form-field>
                <mat-select
                    [(ngModel)]="result.organizationId"
                    [disabled]="!$can().update.organization() && !!organizations.$own() && (organizations.$own()?.length ?? 0) <= 1"
                    placeholder="Organization"
                    name="mOrganization"
                >
                    @for (option of organizations.$filterOptions(); track option) {
                        <mat-option [value]="option.state">{{ option.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        <mat-form-field>
            <input matInput placeholder="Name" [(ngModel)]="result.name" name="mName" />
        </mat-form-field>
        @if (data.entityType === Enums.ContentTypeModel.Organization) {
            <mat-form-field>
                <input matInput placeholder="URL" [(ngModel)]="result.url" name="mUrl" />
            </mat-form-field>
        }
        @if (data.entityType === Enums.ContentTypeModel.Template) {
            <mat-form-field>
                <mat-select [(ngModel)]="result.templateType" placeholder="Type" name="mTemplateType">
                    @for (templateType of templateTypeLabels; track templateType) {
                        <mat-option [value]="templateType.state">{{ templateType.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        @if (data.entityType === Enums.ContentTypeModel.Tag) {
            <mat-form-field>
                <mat-select [(ngModel)]="result.tagType" placeholder="Type" name="mTagType">
                    @for (tagType of tagTypeLabels; track tagType) {
                        <mat-option [value]="tagType.state">{{ tagType.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (data.entityType === Enums.ContentTypeModel.Organization) {
        <button mat-button form="entityForm" [disabled]="!result.name || !result.url">Create</button>
    } @else {
        <button mat-button form="entityForm" [disabled]="!result.name || !result.organizationId">Create</button>
    }
    <button mat-button (click)="cancelAction()">Cancel</button>
</mat-dialog-actions>
