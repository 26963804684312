import {TransfiniteInterpolator2D} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/transfinite-interpolator-2d"
import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-interpolator"

export class CurveBoundaryInterpolator extends TransfiniteInterpolator2D {
    constructor(curveTop: CurveInterpolator, curveBottom: CurveInterpolator, curveLeft: CurveInterpolator, curveRight: CurveInterpolator) {
        const evaluatePoint = (curve: CurveInterpolator) => {
            return (t: number) => curve.evaluate(t)
        }
        super(evaluatePoint(curveTop), evaluatePoint(curveBottom), evaluatePoint(curveLeft), evaluatePoint(curveRight))
    }
}
