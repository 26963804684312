import {HalContext} from "@common/models/hal/hal-context"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {traceObject} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {createHalPaintableImage} from "@common/models/hal/hal-paintable-image/create"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {getBytesPerChannel, getNumChannels} from "@common/models/hal/hal-image/utils"
import {completeHalImageOptions, OutOfMemoryError} from "@common/models/webgl2"
import {bytesToSize, deepCopy} from "@cm/utils"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {isHalImageDescriptor} from "@common/helpers/hal"

const TRACE = TextureEditorSettings.EnableFullTrace
const TRACE_ALLOCATIONS = TextureEditorSettings.EnableAllocTrace

export class ImageCacheWebGL2 {
    constructor(readonly halContext: HalContext) {}

    dispose(): void {
        this.freeAllImages()
    }

    async getImage(descriptor: HalImageDescriptor): Promise<HalPaintableImage> {
        const hash = this.getHash(descriptor)
        let availableTemporaryRenderTargets = this.availableImagesByHash.get(hash)
        if (!availableTemporaryRenderTargets) {
            availableTemporaryRenderTargets = []
            this.availableImagesByHash.set(hash, availableTemporaryRenderTargets)
        }
        let target = availableTemporaryRenderTargets.pop()
        if (target) {
            this.cacheStats.numAvailableImages--
        } else {
            target = await this.createImage(descriptor)
        }
        let usedTemporaryRenderTargets = this.usedImagesByHash.get(hash)
        if (!usedTemporaryRenderTargets) {
            usedTemporaryRenderTargets = []
            this.usedImagesByHash.set(hash, usedTemporaryRenderTargets)
        }
        usedTemporaryRenderTargets.push(target!)
        this.cacheStats.numUsedImages++
        this.registerImageUsage(target!)
        if (TRACE) {
            this.logCacheStats()
        }
        return target!
    }

    releaseImage(image: HalPaintableImage): void {
        const hash = this.getHash(image)
        // remove from used
        const usedImages = this.usedImagesByHash.get(hash)
        if (!usedImages) {
            throw Error("usedImages not found")
        }
        const index = usedImages.indexOf(image)
        if (index < 0) {
            throw Error("image not found")
        }
        usedImages.splice(index, 1)
        this.cacheStats.numUsedImages--
        // add to available
        const availableImages = this.availableImagesByHash.get(hash)
        if (!availableImages) {
            throw Error("availableImages not found")
        }
        availableImages.push(image)
        this.cacheStats.numAvailableImages++
        this.registerImageRelease(image)
        if (TRACE) {
            this.logCacheStats()
        }
    }

    private releaseAllImages(): void {
        Array.from(this.usedImagesByHash.values()).forEach((targets) => targets.slice().forEach((target) => this.releaseImage(target)))
    }

    freeUnusedImages(minBytesToFree: number, optimalBytesToFree: number): void {
        if (TRACE_ALLOCATIONS) {
            console.log(
                `ImageCacheWebGL2: freeing unused images to free at least ${bytesToSize(minBytesToFree)} (optimally ${bytesToSize(optimalBytesToFree)})`,
            )
        }
        const imageAndLastReleased: [HalPaintableImage, number][] = []
        this.usageStatsByImage.forEach((usageStats, image) => {
            if (usageStats.isAvailable && usageStats.lastReleased) {
                imageAndLastReleased.push([image, usageStats.lastReleased])
            }
        })
        imageAndLastReleased.sort((a, b) => b[1] - a[1])
        const cacheSizeBefore = this.cacheStats.usedSizeInBytes
        let imagesFreed = 0
        for (;;) {
            const bytesFreed = cacheSizeBefore - this.cacheStats.usedSizeInBytes
            if (bytesFreed >= optimalBytesToFree) {
                break
            }
            if (imageAndLastReleased.length === 0) {
                if (bytesFreed < minBytesToFree) {
                    throw Error(`Attempting to free ${bytesToSize(minBytesToFree)}, but no more images to free.`)
                } else {
                    break
                }
            }
            const [image] = imageAndLastReleased.pop()!
            this.freeImage(image)
            imagesFreed++
        }
        if (TRACE_ALLOCATIONS) {
            console.log(`ImageCacheWebGL2: freed ${imagesFreed} images (${bytesToSize(cacheSizeBefore - this.cacheStats.usedSizeInBytes)})`)
            this.logMemStats()
        }
    }

    set maxCacheSize(maxSizeInBytes: number | "auto") {
        this.cacheStats.maxSizeInBytes = maxSizeInBytes
        if (TRACE_ALLOCATIONS) {
            console.log(`ImageCacheWebGL2: setting max cache size to ${maxSizeInBytes === "auto" ? "auto" : bytesToSize(maxSizeInBytes)}`)
        }
    }

    get maxCacheSize(): number | "auto" {
        return this.cacheStats.maxSizeInBytes
    }

    get contentStats(): string {
        return `${this.cacheStats.numAvailableImages + this.cacheStats.numUsedImages} cached (${this.cacheStats.numUsedImages} used, ${this.cacheStats.numAvailableImages} available)`
    }

    get memStats(): string {
        return `${bytesToSize(this.cacheStats.usedSizeInBytes)}${this.cacheStats.maxSizeInBytes === "auto" ? "" : "/" + bytesToSize(this.cacheStats.maxSizeInBytes)} used`
    }

    get stats(): string {
        return `${this.contentStats} - ${this.memStats}`
    }

    private logCacheStats(): void {
        console.log("ImageCacheWebGl2: : " + this.contentStats)
    }

    private logMemStats(): void {
        console.log("ImageCacheWebGl2: " + this.memStats)
    }

    private freeAllImages() {
        this.releaseAllImages()
        this.freeImages(Array.from(this.availableImagesByHash.values()).flat())
    }

    private async createImage(descriptor: HalImageDescriptor) {
        const id = this.nextId++
        const debugId = `ImageCacheWebGL2[${id}]`
        const approximateImageSizeInBytes = this.estimateImageSizeInBytes(descriptor)
        const image = createHalPaintableImage(this.halContext)
        for (;;) {
            if (this.cacheStats.maxSizeInBytes !== "auto" && this.cacheStats.usedSizeInBytes + approximateImageSizeInBytes > this.cacheStats.maxSizeInBytes) {
                // let's free at least the amount of memory we need to allocate the new image OR to 90% of the max cache size
                const memoryToFreeForHeadroom = this.cacheStats.usedSizeInBytes - 0.9 * this.cacheStats.maxSizeInBytes
                const minMemoryToFree = this.cacheStats.usedSizeInBytes + approximateImageSizeInBytes - this.cacheStats.maxSizeInBytes
                const idealMemoryToFree = Math.max(minMemoryToFree, memoryToFreeForHeadroom)
                this.freeUnusedImages(minMemoryToFree, idealMemoryToFree)
            }
            try {
                if (TRACE_ALLOCATIONS) {
                    console.log(`ImageCacheWebGL2: allocating image with descriptor ${traceObject(descriptor)} with id ${debugId}`)
                }
                await image.create(descriptor)
                break
            } catch (e) {
                if (e instanceof OutOfMemoryError) {
                    // we ran out of memory, try to free up some space and try again
                    this.cacheStats.maxSizeInBytes = this.cacheStats.usedSizeInBytes - approximateImageSizeInBytes
                    console.warn(
                        `ImageCacheWebGL2: Out of memory. Setting max cache size to ${bytesToSize(this.cacheStats.maxSizeInBytes)} bytes and retrying.`,
                    )
                } else {
                    throw e
                }
            }
        }
        this.originalDescriptorByHalImage.set(image, deepCopy(descriptor))
        this.usageStatsByImage.set(image, {isAvailable: true})
        const imageSizeInBytes = this.estimateImageSizeInBytes(image.descriptor) // re-estimate size after creation as it may have changed descriptor due to format constraints
        this.cacheStats.usedSizeInBytes += imageSizeInBytes
        if (TRACE_ALLOCATIONS) {
            this.logMemStats()
        }
        return image
    }

    private freeImage(image: HalPaintableImage): void {
        if (TRACE_ALLOCATIONS) {
            console.log(`ImageCacheWebGL2: freeing image with id ${traceObject(image.descriptor)}`)
        }
        const hash = this.getHash(image)
        const availableImages = this.availableImagesByHash.get(hash)
        if (!availableImages) {
            throw Error("availableImages not found")
        }
        const index = availableImages.indexOf(image)
        if (index < 0) {
            throw Error("image not found")
        }
        availableImages.splice(index, 1)
        this.cacheStats.numAvailableImages--
        if (!this.originalDescriptorByHalImage.has(image)) {
            throw Error("originalDescriptor not found")
        }
        this.originalDescriptorByHalImage.delete(image)
        this.usageStatsByImage.delete(image)
        const imageSizeInBytes = this.estimateImageSizeInBytes(image.descriptor)
        this.cacheStats.usedSizeInBytes -= imageSizeInBytes
        if (this.cacheStats.usedSizeInBytes < 0) {
            throw Error("Unexpected negative cache size")
        }
        image.dispose()
        if (TRACE_ALLOCATIONS) {
            this.logMemStats()
        }
    }

    private freeImages(images: HalPaintableImage[]): void {
        for (const image of images.slice()) {
            this.freeImage(image)
        }
    }

    private registerImageUsage(image: HalPaintableImage): void {
        const usage = this.usageStatsByImage.get(image)
        if (!usage) {
            throw Error("usage not found")
        }
        usage.lastReleased = undefined
        usage.isAvailable = false
    }

    private registerImageRelease(image: HalPaintableImage): void {
        const usage = this.usageStatsByImage.get(image)
        if (!usage) {
            throw Error("usage not found")
        }
        usage.lastReleased = Date.now()
        usage.isAvailable = true
    }

    private estimateImageSizeInBytes(descriptor: HalImageDescriptor): number {
        const {width, height, channelLayout, dataType} = descriptor
        const numChannels = getNumChannels(channelLayout)
        const bytesPerChannel = getBytesPerChannel(dataType)
        return width * height * numChannels * bytesPerChannel
    }

    private getHash(descriptorOrImage: HalImageDescriptor | HalPaintableImage): string {
        const descriptor = isHalImageDescriptor(descriptorOrImage) ? descriptorOrImage : this.originalDescriptorByHalImage.get(descriptorOrImage)
        if (!descriptor) {
            throw Error("Original descriptor not found")
        }
        const options = completeHalImageOptions(descriptor.options)
        return `${descriptor.width}x${descriptor.height} ${descriptor.channelLayout} ${descriptor.dataType}${options.useSRgbFormat ? " sRGB" : ""}${options.useMipMaps ? " mips" : ""}`

        // commented out as this appears to be extremely slow
        // make a copy for hash in case imageDesc contains excess properties
        // const descriptorToHash: ImageDescriptor = {
        //     width: descriptor.width,
        //     height: descriptor.height,
        //     channelLayout: descriptor.channelLayout,
        //     dataType: descriptor.dataType,
        //     options: completeHalImageOptions(descriptor.options),
        // }
        // return hashObject(descriptorToHash)
    }

    private readonly originalDescriptorByHalImage = new Map<HalPaintableImage, HalImageDescriptor>()
    private readonly availableImagesByHash = new Map<string, HalPaintableImage[]>()
    private readonly usedImagesByHash = new Map<string, HalPaintableImage[]>()
    private cacheStats: CacheStats = {
        numAvailableImages: 0,
        numUsedImages: 0,
        usedSizeInBytes: 0,
        maxSizeInBytes: 6 * 1024 * 1024 * 1024,
    }
    private readonly usageStatsByImage = new Map<HalPaintableImage, ImageUsageStats>()
    private nextId: number = 1
}

type CacheStats = {
    numAvailableImages: number
    numUsedImages: number
    usedSizeInBytes: number
    maxSizeInBytes: number | "auto"
}

type ImageUsageStats = {
    lastReleased?: number
    isAvailable: boolean
}
