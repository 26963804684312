import {Node} from "@cm/graph/declare-node"
import {isNodeGraphInstance} from "@cm/graph/node-graph"
import {Observable} from "rxjs"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"
import {WebGLRenderer} from "three"

export const textureResolution = z.enum(["500px", "1000px", "2000px", "original"])
export type TextureResolution = z.infer<typeof textureResolution>

export const materialType = z.union([z.literal("cycles"), z.literal("three")])
export type MaterialType = z.infer<typeof materialType>

const isMaterialNodeNode = (instance: unknown): instance is Node<{}, unknown, {}, {parameters: {}}> => isNodeGraphInstance(instance)
const materialNodeInstance = z.any().refine(isMaterialNodeNode, {message: "Expected material node"})

export const context = z.object({
    type: materialType,
    progressiveTextureLoading: z.boolean().optional(),
    textureResolution: textureResolution,
    forceFiltering: z.union([z.literal("linear"), z.literal("nearest")]).optional(),
    threeRenderer: z.instanceof(WebGLRenderer).optional(),
    threeDefaultFloatTextureType: z.literal(THREE.HalfFloatType).or(z.literal(THREE.FloatType)).optional(),
    onThreeCreatedTexture: z
        .function(z.tuple([z.instanceof(THREE.Texture).or(z.instanceof(THREENodes.TextureNode)).or(z.instanceof(THREE.WebGLRenderTarget))]))
        .returns(z.void())
        .optional(),
    onThreeUsingUvChannel: z
        .function(z.tuple([z.number()]))
        .returns(z.void())
        .optional(),
    onRequestRedraw: z.function().args(materialNodeInstance).returns(z.void()).optional(),
    parentMesh: z.unknown().optional(),
    addTask: z.function(z.tuple([z.object({description: z.string(), task: z.instanceof(Observable), critical: z.boolean()})])).returns(z.any()),
})
export type Context = z.infer<typeof context>

export const vec2 = z.object({x: z.number(), y: z.number()})
export type Vec2 = z.infer<typeof vec2>

export const vec3 = z.object({x: z.number(), y: z.number(), z: z.number()})
export type Vec3 = z.infer<typeof vec3>

export const vec4 = z.object({x: z.number(), y: z.number(), z: z.number(), w: z.number()})
export type Vec4 = z.infer<typeof vec4>

export const color = z.object({r: z.number(), g: z.number(), b: z.number(), a: z.number().optional()})
export type Color = z.infer<typeof color>

export const isVec2 = (value: unknown): value is Vec2 => vec2.safeParse(value).success
export const isVec3 = (value: unknown): value is Vec3 => vec3.safeParse(value).success
export const isVec4 = (value: unknown): value is Vec4 => vec4.safeParse(value).success
export const isColor = (value: unknown): value is Color => color.safeParse(value).success

export enum TextureType {
    Anisotropy = "Anisotropy",
    AnisotropyRotation = "AnisotropyRotation",
    AnisotropyStrength = "AnisotropyStrength",
    Diffuse = "Diffuse",
    DiffuseRoughness = "DiffuseRoughness",
    Displacement = "Displacement",
    Error = "Error",
    F0 = "F0",
    Ior = "IOR",
    Mask = "Mask",
    Metalness = "Metalness",
    Normal = "Normal",
    Roughness = "Roughness",
    SpecularStrength = "SpecularStrength",
    SpecularTint = "SpecularTint",
    Transmission = "Transmission",
}

export const SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY = "MapAssignment_TextureType"
export {WebGLRenderer}
