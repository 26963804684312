<div [class.cm-content]="$galleryImage()" (click)="openImageViewer()">
    <cm-drop-files
        class="cm-inner-content"
        [mimeTypeFilter]="MimeType.Images"
        [showDropzone]="$showDropzone()"
        [enableDrop]="$can().update.item(item$.value, 'galleryImage')"
        [maxFiles]="1"
        (filesDropped)="assignGalleryImage($event)"
        [subtitle]="
            item$.value
                ? '...to add as ' + (galleryImageService.labelForItem | memoize: galleryImageService : item$.value) + ' or click to browse for files'
                : ''
        "
    >
        @if ($multiThumbnailIds(); as multiThumbnailIds) {
            @if (multiThumbnailIds.length <= 1) {
                <cm-data-object-thumbnail [dataObjectId]="multiThumbnailIds?.[0]"></cm-data-object-thumbnail>
            } @else {
                <cm-four-square-layout>
                    @for (index of [0, 1, 2, 3]; track index) {
                        <cm-data-object-thumbnail [dataObjectId]="multiThumbnailIds?.[index]"></cm-data-object-thumbnail>
                    }
                </cm-four-square-layout>
            }
        } @else {
            <cm-data-object-thumbnail [dataObjectId]="$galleryImage()?.id"></cm-data-object-thumbnail>
        }
    </cm-drop-files>
</div>
<cm-image-viewer #imageViewer></cm-image-viewer>
