import {NodeGraph, NodeParameters} from "@cm/graph"

export type GroupId = string
export type VariantId = string

export type ConfiguratorVariant = {
    groupId: GroupId
    currentVariantId: VariantId
}

export type NamedConfiguratorVariant = {
    groupId: GroupId
    groupName: string
    variantId: VariantId
    variantName: string
}

/**Reflects the enum from the prisma schema. It is actually a copy from generated/graphql.ts from the frontend. */
export enum Currency {
    Eur = "EUR",
    Usd = "USD",
    Gbp = "GBP",
}

export function currencyToSymbol(currency: Currency): string {
    switch (currency) {
        case Currency.Eur:
            return "€"
        case Currency.Usd:
            return "$"
        case Currency.Gbp:
            return "£"
        default:
            throw new Error("Unknown currency")
    }
}

export type PricedItem = {
    description: string
    sku?: string
    price: number
    currency: Currency
    amount: number
}

export type PriceWithCurrency = {
    price: number
    currency: Currency
}

export type PricingNodegraph = NodeGraph<PricedItem[], PricingContext, NodeParameters>

export class PricingContext {
    private priceMap: Map<string, number>
    private currency: Currency

    constructor(priceMap: Map<string, number>, currency: Currency) {
        this.priceMap = priceMap
        this.currency = currency
    }

    getPrice(uniqueId: string): number {
        const price = this.priceMap.get(uniqueId)
        if (price === undefined) throw new Error("Price not found")
        return price
    }

    getCurrency(): Currency {
        return this.currency
    }
}
