import {PointVizItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/point-viz-item"
import {Vector2, Vector2Like} from "@cm/math"
import {TilingControlPointType} from "@app/textures/texture-editor/texture-edit-nodes"
import {EventEmitter} from "@angular/core"

export class BoundaryControlPoint {
    readonly sourcePositionChanged = new EventEmitter<void>()
    readonly resultUVChanged = new EventEmitter<void>()

    constructor(
        readonly type: TilingControlPointType,
        sourcePosition: Vector2Like,
        resultUV: Vector2Like,
        readonly item: PointVizItem,
        readonly deletable: boolean,
    ) {
        this._sourcePosition = Vector2.fromVector2Like(sourcePosition)
        this._resultUV = Vector2.fromVector2Like(resultUV)
    }

    get sourcePosition(): Vector2 {
        return this._sourcePosition
    }

    set sourcePosition(value: Vector2Like) {
        if (this._sourcePosition.equals(value)) {
            return
        }
        this._sourcePosition.set(value.x, value.y)
        this.sourcePositionChanged.emit()
    }

    get resultUV(): Vector2 {
        return this._resultUV
    }

    set resultUV(value: Vector2Like) {
        if (this._resultUV.equals(value)) {
            return
        }
        this._resultUV.set(value.x, value.y)
        this.resultUVChanged.emit()
    }

    private _sourcePosition: Vector2
    private _resultUV: Vector2
}
