import {Component, inject} from "@angular/core"
import {RoutedDialogComponent} from "@app/common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@app/common/models/dialogs"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {ConfigMenuComponent} from "@app/common/components/viewers/configurator/config-menu/config-menu.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {loadGraphForNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {OverridesNotSupportedError} from "@cm/template-nodes/legacy-template-converter"
import {PdfDownloadButton} from "@app/configurator/components/buttons/pdf-download-button/pdf-download-button.component"

@Component({
    selector: "cm-configurator-test",
    standalone: true,
    imports: [ConfiguratorComponent, ConfigMenuComponent, PdfDownloadButton, RoutedDialogComponent],
    templateUrl: "./configurator-test-components.component.html",
    styleUrl: "./configurator-test-components.component.scss",
    providers: [SceneManagerService],
})
export class ConfiguratorTestComponentsComponent {
    dialogSizes = DialogSize
    sdk = inject(SdkService)

    constructor() {}

    mapToObjectWithArrays(map: Map<string, Set<string>>) {
        return Object.fromEntries(Array.from(map.entries(), ([key, value]) => [key, Array.from(value)]))
    }

    //TODO: Remove this together with the old template system
    async loadTemplates() {
        const chunkSize = 100

        const overrideErrors = new Map<string, Set<string>>()
        const exceptionTemplates = new Map<string, Set<string>>()

        for (let skip = 0; ; skip += chunkSize) {
            const {templates} = await this.sdk.throwable.getTemplatesForConfiguratorTest({skip, take: chunkSize})
            if (templates.length === 0) break

            templates.forEach((template) => {
                try {
                    const graph = template?.latestRevision?.graph
                    if (graph) loadGraphForNewTemplateSystem(graph)
                } catch (e) {
                    const exceptionName = e instanceof Error ? e.constructor.name : "Unknown"
                    const templateId = template ? template.id : "Unknown"

                    if (!exceptionTemplates.has(exceptionName)) exceptionTemplates.set(exceptionName, new Set())
                    exceptionTemplates.get(exceptionName)?.add(templateId)

                    if (e instanceof OverridesNotSupportedError) {
                        if (!overrideErrors.has(e.message)) overrideErrors.set(e.message, new Set())
                        overrideErrors.get(e.message)?.add(templateId)
                    }
                }
            })

            console.log("Started from", skip)
        }

        console.log("overrideErrors", overrideErrors)
        console.log("exceptionTemplates", exceptionTemplates)
        console.log("json", JSON.stringify(this.mapToObjectWithArrays(overrideErrors)))
        console.log("json", JSON.stringify(this.mapToObjectWithArrays(exceptionTemplates)))
    }
}
