<cm-inspector-section>
    <ng-container cm-title> HDRI</ng-container>
    <ng-container cm-content>
        <mat-form-field>
            <mat-label>HDRI</mat-label>
            <mat-select [value]="hdriForHdriNode | memoize: this : node!" (selectionChange)="hdriChanged($event.value)">
                @for (hdri of hdris; track hdri) {
                    <mat-option [value]="hdri">{{ hdri.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <!--TODO: Remove regular input.-->
        <!--            <mat-form-field class="cm-input-medium">-->
        <!--                <mat-label>Intensity</mat-label>-->
        <!--                <input type="number"-->
        <!--                       autocomplete="off" matInput-->
        <!--                       step="0.01"-->
        <!--                       min="0"-->
        <!--                       max="60"-->
        <!--                       #hdriIntensityInput-->
        <!--                       [ngModelOptions]="{updateOn: 'blur'}"-->
        <!--                       (ngModelChange)="hdriIntensityInput.checkValidity() ? nodeChanged(node) : null;"-->
        <!--                       [(ngModel)]="node.intensity">-->
        <!--            </mat-form-field>-->
        @if (node) {
            <cm-input-container>
                <ng-container cm-inline-label> Intensity:</ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="node.intensity" (valueChange)="nodeChanged(node)" [decimalPlaces]="2"></cm-numeric-input>
                </ng-container>
            </cm-input-container>
        }
        <!--TODO: Remove regular input.-->
        <!--        <mat-form-field class="cm-input-medium">-->
        <!--            <mat-label>Clamp Highlights</mat-label>-->
        <!--            <input type="number"-->
        <!--                   autocomplete="off" matInput-->
        <!--                   step="0.01"-->
        <!--                   min="0"-->
        <!--                   max="60"-->
        <!--                   #hdriClampHighlightsInput-->
        <!--                   [ngModelOptions]="{updateOn: 'blur'}"-->
        <!--                   (ngModelChange)="node.clampHighlights = parseOptionalNumber($event); nodeChanged(node)"-->
        <!--                   [ngModel]="node.clampHighlights">-->
        <!--        </mat-form-field>-->
        @if (node) {
            <cm-input-container>
                <ng-container cm-inline-label> Clamp:</ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="node.clampHighlights" (valueChange)="nodeChanged(node)" [decimalPlaces]="2"></cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <!--TODO: Remove regular input.-->
            <!--                <mat-form-field class="cm-input-medium">-->
            <!--                    <mat-label>Rotation (X)</mat-label>-->
            <!--                    <input type="number"-->
            <!--                           autocomplete="off" matInput-->
            <!--                           step="0.1"-->
            <!--                           #hdriRotationXInput-->
            <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
            <!--                           (ngModelChange)="hdriRotationXInput.checkValidity() ? nodeChanged(node) : null;"-->
            <!--                           [(ngModel)]="node.rotation[0]">-->
            <!--                </mat-form-field>-->
            <!--                <mat-form-field class="cm-input-medium">-->
            <!--                    <mat-label>Rotation (Y)</mat-label>-->
            <!--                    <input type="number"-->
            <!--                           autocomplete="off" matInput-->
            <!--                           step="0.1"-->
            <!--                           #hdriRotationYInput-->
            <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
            <!--                           (ngModelChange)="hdriRotationYInput.checkValidity() ? nodeChanged(node) : null;"-->
            <!--                           [(ngModel)]="node.rotation[1]">-->
            <!--                </mat-form-field>-->
            <!--                <mat-form-field class="cm-input-medium">-->
            <!--                    <mat-label>Rotation (Z)</mat-label>-->
            <!--                    <input type="number"-->
            <!--                           autocomplete="off" matInput-->
            <!--                           step="0.1"-->
            <!--                           #hdriRotationZInput-->
            <!--                           [ngModelOptions]="{updateOn: 'blur'}"-->
            <!--                           (ngModelChange)="hdriRotationZInput.checkValidity() ? nodeChanged(node) : null;"-->
            <!--                           [(ngModel)]="node.rotation[2]">-->
            <!--                </mat-form-field>-->
            <cm-input-container>
                <ng-container cm-inline-label> Rotation (X):</ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="node.rotation[0]!" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"></cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Rotation (Y):</ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="node.rotation[1]!" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"></cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Rotation (Z):</ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="node.rotation[2]!" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"></cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-toggle [(toggle)]="node.mirror!" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Mirror</ng-container>
            </cm-toggle>
        }
    </ng-container>
</cm-inspector-section>
