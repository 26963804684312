import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {imageColorSpace, TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {dataObjectNewToImageRessource} from "@cm/material-nodes/interfaces/data-object"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {MaterialGraphNode, wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {internalColorSpaceForImageResource} from "@cm/material-nodes/material-node-graph-transformations"
import {hashObject} from "@cm/utils"
import {z} from "zod"

const transientDataObjectParameters = z.object({
    data: z.instanceof(Uint8Array),
    contentType: z.string(),
    imageColorSpace: imageColorSpace,
})
export type TransientDataObjectParameters = z.infer<typeof transientDataObjectParameters>

@registerNode
export class TransientDataObject
    extends DeclareTemplateNode({parameters: transientDataObjectParameters}, {}, {nodeClass: "TransientDataObject"})
    implements EvaluableTemplateNode<ImageGenerator>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {data, contentType, imageColorSpace} = this.parameters
        return {
            imageNode: {
                generator: ({uv, extension, interpolation, projection, size, disableProgressiveLoading, forceOriginalResolution}) => {
                    const dataObject = evaluator.templateContext.sceneManager.createTransientDataObjectNew(
                        this.parameters.data,
                        this.parameters.contentType,
                        this.parameters.imageColorSpace,
                    )

                    const imageResource = dataObjectNewToImageRessource(dataObject)
                    const imgNode: MaterialGraphNode<"TexImage"> = {
                        nodeType: "TexImage",
                        inputs: {
                            Vector: uv,
                        },
                        parameters: {
                            "internal.extension": extension,
                            "internal.interpolation": interpolation,
                            "internal.projection": projection,
                            "internal.disable_progressive_loading": disableProgressiveLoading,
                            "internal.force_original_resolution": forceOriginalResolution,
                            "internal.image.colorspace_settings.name": internalColorSpaceForImageResource(imageResource),
                        },
                        resolvedResources: [
                            {
                                ...imageResource,
                                metadata: size
                                    ? {
                                          widthCm: size[0],
                                          heightCm: size[1],
                                      }
                                    : undefined,
                            },
                        ],
                    }

                    return {color: wrapNodeOutput(imgNode, "Color"), alpha: wrapNodeOutput(imgNode, "Alpha")}
                },
                hash: hashObject({
                    type: "TransientDataObject",
                    data,
                    contentType,
                    imageColorSpace,
                }),
            },
        } as ImageGenerator
    }
}

export type TransientDataObjectFwd = TemplateNode<TransientDataObjectParameters> & EvaluableTemplateNode<ImageGenerator>
