import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeValueNode} from "#material-nodes/three-utils"
import {vec3} from "#material-nodes/types"
import {z} from "zod"

const ReturnTypeSchema = z.object({
    displacement: materialSlots,
})
const InputTypeSchema = z.object({
    height: materialSlots.optional(),
    midlevel: materialSlots.optional(),
    scale: materialSlots.optional(),
    normal: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    displacement: vec3.optional(),
    midlevel: z.number().optional(),
    normal: vec3.optional(),
    scale: z.number().optional(),
    space: z.enum(["OBJECT", "WORLD"]).optional(),
})
export class Displacement extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            console.warn("Displacement map node not implemented for THREE materials")
            return {
                displacement: threeValueNode(0),
            }
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
