import {MaterialSocket} from "@material-editor/models/material-socket"

export const AmbientOcclusionInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    distance: {paramType: "socket", id: "Distance", type: "input", valueType: "scalar", label: "Distance"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "input", label: "Normal"},
}

export const AmbientOcclusionOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
    ao: {paramType: "socket", id: "AO", type: "output", valueType: "output", label: "AO"},
}
