import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {numberLike, booleanLike, NumberLike} from "#template-nodes/node-types"
import {z} from "zod"
import {skipped, visitNone, VisitorNodeVersion} from "@cm/graph/declare-visitor-node"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {ThisStructID} from "#template-nodes/runtime-graph/types"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {versionChain} from "@cm/graph/node-graph"

const renderParameters = z.object({
    width: numberLike,
    height: numberLike,
    samples: numberLike,
    gpu: booleanLike,
    cloud: booleanLike,
})
export type RenderParameters = z.infer<typeof renderParameters>

type V0 = {
    width: NumberLike
    height: NumberLike
    samples: NumberLike
}
type V1 = RenderParameters
export const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        return {
            gpu: false,
            cloud: false,
            ...parameters,
        }
    },
}

@registerNode
export class Render extends DeclareTemplateNode(
    {parameters: renderParameters},
    {
        onVisited: {
            onFilterActive: function (this: Render, {visit, context, parameters}) {
                const {onVisitedRender} = context

                if (!onVisitedRender(this)) return skipped

                return visitNone(parameters)
            },
            onCompile: function (this: Render, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {displayList} = currentTemplate
                const {templateScope} = evaluator
                const {width, height, samples, gpu, cloud} = parameters

                const scope = evaluator.getScope(this)

                const nullToZero = (number: BuilderInlet<number | null>, uniqueId: string) => scope.pureLambda(number, (number) => number ?? 0, uniqueId)
                const nullToFalse = (boolean: BuilderInlet<boolean | null>, uniqueId: string) =>
                    scope.pureLambda(boolean, (boolean) => boolean ?? false, uniqueId)

                const renderSettings = templateScope.struct<SceneNodes.RenderSettings>("RenderSettings", {
                    id: ThisStructID,
                    type: "RenderSettings",
                    width: nullToZero(evaluator.evaluateNumber(scope, width), "width"),
                    height: nullToZero(evaluator.evaluateNumber(scope, height), "height"),
                    samples: nullToZero(evaluator.evaluateNumber(scope, samples), "samples"),
                    gpu: nullToFalse(evaluator.evaluateBoolean(scope, gpu), "gpu"),
                    cloud: nullToFalse(evaluator.evaluateBoolean(scope, cloud), "cloud"),
                })

                displayList.push(renderSettings)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "Render", versionChain: versionChain([v0])},
) {}

export type RenderFwd = TemplateNode<RenderParameters>
