import {Three as THREE} from "@cm/material-nodes/three"
import {OutlinePass as ThreeOutlinePass} from "@cm/material-nodes/three"
import {HELPER_OBJECTS_LAYER} from "@app/template-editor/helpers/helper-objects"

export class OutlinePass extends ThreeOutlinePass {
    constructor(
        resolution: THREE.Vector2,
        scene: THREE.Scene,
        private camera: THREE.PerspectiveCamera,
    ) {
        super(resolution, scene, camera)
    }

    override render(
        renderer: THREE.WebGLRenderer,
        writeBuffer: THREE.WebGLRenderTarget,
        readBuffer: THREE.WebGLRenderTarget,
        deltaTime: number,
        maskActive: boolean,
    ) {
        this.camera.layers.enable(HELPER_OBJECTS_LAYER)
        super.render(renderer, writeBuffer, readBuffer, deltaTime, maskActive)
        this.camera.layers.disable(HELPER_OBJECTS_LAYER)
    }
}
