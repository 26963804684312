import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeValueNode} from "#material-nodes/three-utils"
import {z} from "zod"

const ReturnTypeSchema = z.object({value: materialSlots})
const InputTypeSchema = z.object({value: materialSlots.optional()})
const ParametersTypeSchema = z.object({value: z.number().optional()})

export class Value extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const value = (await get(inputs.value)) ?? threeConvert(parameters.value, threeValueNode) ?? threeValueNode(0)
            return {value}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
