import {Component, inject, signal} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {SdkService} from "@common/services/sdk/sdk.service"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"

@Component({
    selector: "cm-template-viewer-dialog",
    templateUrl: "./template-viewer-dialog.component.html",
    styleUrls: ["./template-viewer-dialog.component.scss"],
    standalone: true,
    imports: [RoutedDialogComponent, ConfiguratorComponent],
})
export class TemplateViewerDialogComponent {
    $templateId = signal<string | undefined>(undefined)

    dialogSizes = DialogSize
    templateLegacyId?: number
    closeNavigationPath: string

    router = inject(Router)
    route = inject(ActivatedRoute)
    sdk = inject(SdkService)

    constructor() {
        this.initTemplateId()
        this.closeNavigationPath = this.route.snapshot.data.closeNavigationPath ?? "../"
    }

    async initTemplateId() {
        let templateId: string | null | undefined = this.route.snapshot.paramMap.get("templateId")

        if (!templateId) {
            const pictureId = this.route.snapshot.paramMap.get("pictureId")
            if (pictureId) templateId = (await this.sdk.gql.getTemplateDetailsForTemplateViewer({pictureId: pictureId})).picture?.template?.id
        }

        if (!templateId) throw new Error("No templateId found in route")

        this.$templateId.set(templateId)
    }

    overlayClosed() {
        void this.router.navigate([this.closeNavigationPath], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }
}
