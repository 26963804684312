import {defer, filter, finalize, map, Observable, pipe, Subject, Subscription} from "rxjs"

export type TaskProgressEvent<T> =
    | {
          type: "progress"
          current: number
          total: number
      }
    | {
          type: "complete"
          value: T
      }

export function createTaskProgressOperator<T>(name: string) {
    return ObservableTaskManager.getInstance().addProgressOperator<T>(name)
}

export function createTaskOperator<T>(name: string) {
    return pipe(
        map((x: T) => ({type: "complete", value: x}) as TaskProgressEvent<T>),
        createTaskProgressOperator(name),
    )
}

export type ProgressInfo = {
    label: string
    state: "waiting" | "active" | "complete"
    current?: number
    total?: number
}

export class ObservableTaskManager {
    private taskCounter = 0
    private suspendCount = 0
    private taskMap = new Map<string, Subscription>()
    private progressMap = new Map<string, ProgressInfo>()
    private deferredUpdateFns = new Set<() => void>()
    readonly errorSubject = new Subject<[string, unknown]>()
    readonly updateSubject = new Subject<boolean>()
    readonly progressSubject = new Subject<Map<string, ProgressInfo>>()

    private debug = false

    private constructor() {}

    private static instance: ObservableTaskManager
    static getInstance(): ObservableTaskManager {
        if (!ObservableTaskManager.instance) {
            ObservableTaskManager.instance = new ObservableTaskManager()
        }
        return ObservableTaskManager.instance
    }

    addProgressOperator<T>(name: string) {
        const taskID = `${this.taskCounter++}_${name}`
        return (source: Observable<TaskProgressEvent<T>>) => {
            return defer(() => {
                if (this.debug) console.log(`Add progress ${taskID}`)
                this.progressMap.set(taskID, {label: name, state: "waiting", current: undefined, total: undefined})
                this.notifyProgressChanged()
                return source.pipe(
                    filter((x) => !!x),
                    map((x) => {
                        const progressData = this.progressMap.get(taskID)!
                        if (x.type === "progress") {
                            progressData.state = "active"
                            progressData.current = x.current
                            progressData.total = x.total
                            if (this.debug) console.log(`Update progress ${taskID}`, x.current, x.total)
                        } else if (x.type === "complete") {
                            progressData.state = "complete"
                            if (this.debug) console.log(`Complete progress ${taskID}`, x.value)
                        }
                        this.notifyProgressChanged()
                        return x
                    }),
                    filter((x): x is TaskProgressEvent<T> & {type: "complete"} => x.type === "complete"),
                    map((x) => x.value),
                )
            }).pipe(
                finalize(() => {
                    //TODO: does this handle errors also?
                    //if(this.debug) console.log(`Remove progress ${taskID}`);
                    //this.progressMap.delete(taskID);
                    //this.notifyProgressChanged();
                }),
            )
        }
    }

    private notifyProgressChanged() {
        //TODO: coalesce updates
        let haveIncomplete = false
        for (const [_taskID, taskInfo] of this.progressMap) {
            if (taskInfo.state !== "complete") {
                haveIncomplete = true
                break
            }
        }
        this.progressSubject.next(this.progressMap)
        // clear all progress info when all outstanding tasks have completed
        if (!haveIncomplete) {
            this.progressMap.clear()
            this.progressSubject.next(this.progressMap)
        }
    }

    addTask(task: Observable<unknown>, name: string, isBinding?: boolean) {
        const taskID = `${this.taskCounter++}_${name}`
        let complete = false
        if (this.debug) console.log(`Add task ${taskID}`)
        const subscription = task.subscribe(
            () => {
                if (!complete) {
                    const existing = this.taskMap.get(taskID)
                    if (existing) {
                        if (!isBinding) existing.unsubscribe()
                        this.taskMap.delete(taskID)
                    } else {
                        complete = true
                    }
                    if (this.debug) console.log(`Complete task ${taskID} - pending = ${Array.from(this.taskMap.keys())} - suspend:${this.suspendCount}`)
                }
                this.update()
            },
            (err: unknown) => {
                if (!complete) {
                    const existing = this.taskMap.get(taskID)
                    if (existing) {
                        existing.unsubscribe()
                        this.taskMap.delete(taskID)
                    } else {
                        complete = true
                    }
                }
                this.errorSubject.next([taskID, err])
            },
        )
        if (!complete) {
            this.taskMap.set(taskID, subscription)
        }
    }

    addBinding(task: Observable<unknown>, name: string) {
        this.addTask(task, name, true)
    }

    addDeferredFn(fn: () => void) {
        this.deferredUpdateFns.add(fn)
    }

    get pendingTaskCount() {
        return this.taskMap.size
    }

    suspend() {
        ++this.suspendCount
    }

    resume() {
        if (this.suspendCount > 0) {
            --this.suspendCount
            this.update()
        }
    }

    update() {
        if (this.suspendCount > 0) return
        const isIdle = this.pendingTaskCount == 0
        if (this.debug) console.log(`Update taskMgr isIdle ${isIdle}`)
        while (this.deferredUpdateFns.size > 0) {
            const fns = Array.from(this.deferredUpdateFns)
            this.deferredUpdateFns.clear()
            for (const fn of fns) {
                fn()
            }
        }
        this.updateSubject.next(isIdle)
    }
}
