import {EventEmitter, Injector} from "@angular/core"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalContext} from "@common/models/hal/hal-context"
import {InjectorService} from "@common/services/injector/injector.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {ImageProcessingService} from "@common/services/rendering/image-processing.service"
import {of, Subject, switchMap, takeUntil, tap} from "rxjs"
import {AsyncReentrancyGuard} from "@cm/utils/async-reentrancy-guard"
import {assertNever, DeferredBatchCall} from "@cm/utils"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {TilingCanvasComponent} from "app/textures/texture-editor/tiling-canvas/tiling-canvas.component"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {Operator, OperatorType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {OperatorAutoTiling} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import {imageOpChannelLayoutByTextureChannelLayout} from "app/textures/texture-editor/operator-stack/operators/shared/utils/utils"
import {OperatorShift} from "app/textures/texture-editor/operator-stack/operators/shift/operator-shift"
import {SourceImageData, TextureEditorCallback, TextureEditorData} from "app/textures/texture-editor/texture-editor-callback"
import {TextureType} from "@api"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {OperatorInitial} from "app/textures/texture-editor/operator-stack/operators/initial/operator-initial"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImageCacheWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache-webgl2"
import {ImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {levels} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-levels"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {OperatorRotate} from "@app/textures/texture-editor/operator-stack/operators/rotate/operator-rotate"
import {OperatorHighpass} from "@app/textures/texture-editor/operator-stack/operators/highpass/operator-highpass"
import {OperatorLayerAndMask} from "@app/textures/texture-editor/operator-stack/operators/layer-and-mask/operator-layer-and-mask"
import {OperatorCloneStamp} from "@app/textures/texture-editor/operator-stack/operators/clone-stamp/operator-clone-stamp"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OperatorTest} from "@app/textures/texture-editor/operator-stack/operators/test/operator-test"
import {ImageViewPtrWebGl2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-view-webgl2"

const TRACE = TextureEditorSettings.EnableFullTrace

const CACHE_SELECTED_OPERATOR_SOURCE = true

export class OperatorStack implements OperatorCallback {
    readonly selectedOperatorChanged = new EventEmitter<Operator | null>() // OperatorCallback
    readonly resultImageChanged = new EventEmitter<ImageViewPtrWebGl2>()

    constructor(
        private textureEditorCallback: TextureEditorCallback,
        private injectorService: InjectorService,
        readonly tilingCanvas: TilingCanvasComponent,
    ) {
        const imageProcessingService = this.injectorService.injector.get(ImageProcessingService)
        const uploadGqlService = this.injectorService.injector.get(UploadGqlService)
        this._texturesApi = this.injectorService.injector.get(TexturesApiService)
        this._notificationService = this.injectorService.injector.get(NotificationsService)
        this._halContext = this.tilingCanvas.canvasBase.halContext
        this._imageCacheWebGL2 = new ImageCacheWebGL2(this.halContext)
        this._drawableImageCache = new DrawableImageCache(this._imageCacheWebGL2, imageProcessingService, uploadGqlService, this._texturesApi)
        this._imageOpContextWebGL2 = new ImageOpContextWebGL2(this._halContext, this._imageCacheWebGL2, this._texturesApi, this._drawableImageCache)
        this._imageOpEvaluatorPromiseGate = new AsyncReentrancyGuard.PromiseGate()
        this._halBlitter = HalPainterImageBlit.create(this._halContext)

        this.textureEditorCallback.sourceDataChanged
            .pipe(
                tap(() => this.beginBulkChange()),
                switchMap((data) => this.setSourceImage(data?.sourceImage ?? null).then(() => data)),
                switchMap((data) => (!data || data.textureEdits !== undefined ? this.setTextureEdits(data?.textureEdits ?? null) : of(undefined))), // only set texture edits if provided or reset if no data is provided at all
                tap(() => this.endBulkChange()),
                takeUntil(this.unsubscribe),
            )
            .subscribe()
    }

    dispose(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.deferredUpdateResultImage.cancel()
        this._sourceImageRef?.release()
        this._imageOpContextWebGL2.dispose()
        this._drawableImageCache.dispose()
        this._imageCacheWebGL2.dispose()
        this._halBlitter.dispose()
    }

    get isDebugEnabled(): boolean {
        return this.textureEditorCallback.isDebugEnabled
    }

    get textureEditorData(): TextureEditorData {
        if (!this.textureEditorCallback.data) {
            throw Error("data is null")
        }
        return this.textureEditorCallback.data
    }

    get texturesApi(): TexturesApiService {
        return this._texturesApi
    }

    get organization() {
        const organization = this.textureEditorData.textureSetRevisionSpecific.textureSet.textureGroup.organization
        return {
            id: organization.id,
            legacyId: organization.legacyId,
        }
    }

    get isBusy(): boolean {
        return this._busyCounter > 0
    }

    get isEdited(): boolean {
        return this._operatorsChanged || this._operators.some((operator) => operator.edited)
    }

    get muteUpdates(): boolean {
        return this._updatesMutedCounter > 0
    }

    set muteUpdates(value: boolean) {
        if (value) {
            if (this._updatesMutedCounter === 0) {
                this._mutedUpdatePending = this.deferredUpdateResultImage.isPending
                this.deferredUpdateResultImage.cancel()
            }
            this._updatesMutedCounter++
        } else {
            this._updatesMutedCounter--
            if (this._updatesMutedCounter < 0) {
                throw Error("Mismatched muteUpdates calls")
            }
            if (this._updatesMutedCounter === 0) {
                if (this._mutedUpdatePending) {
                    this._mutedUpdatePending = false
                    this.requestUpdateResultImage()
                }
            }
        }
    }

    // OperatorCallback
    get injector(): Injector {
        return this.injectorService.injector
    }

    get imageCacheWebGL2(): ImageCacheWebGL2 {
        return this._imageCacheWebGL2
    }

    // OperatorCallback
    get drawableImageCache(): DrawableImageCache {
        return this._drawableImageCache
    }

    // OperatorCallback
    get halContext(): HalContext {
        return this._halContext
    }

    // OperatorCallback
    get selectedOperatorInput(): ImageRef | undefined {
        return this._cachedOperator?.sourceImageRef
    }

    // OperatorCallback
    get imageOpContextWebGL2(): ImageOpContextWebGL2 {
        return this._imageOpContextWebGL2
    }

    get operators(): readonly Operator[] {
        return this._operators
    }

    get selectedOperator(): Operator | null {
        return this._selectedOperator
    }

    set selectedOperator(value: Operator | null) {
        if (this._selectedOperator === value) {
            return
        }
        this._selectedOperator = value
        this.requestUpdateResultImage()
        this.selectedOperatorChanged.emit(this._selectedOperator)
    }

    resetChangedFlag() {
        this._operatorsChanged = false
    }

    async setTextureEdits(edits: TextureEditNodes.TextureEdits | null) {
        this.beginBulkChange()
        await this.resetAllOperators()
        if (edits) {
            for (const operator of edits.operators) {
                await this.createOperator(operator)
            }
            this.selectedOperator = this._operators.length > 0 ? this._operators[this._operators.length - 1] : null
        }
        this.resetChangedFlag()
        this.endBulkChange()
    }

    async duplicateOperator(operator: Operator): Promise<Operator> {
        const clonedOperator = await operator.clone()
        const index = this._operators.indexOf(operator)
        await this.addOperator(clonedOperator, index + 1)
        clonedOperator.markEdited()
        return clonedOperator
    }

    async createOperator(operatorTypeOrNode: OperatorType | TextureEditNodes.Operator): Promise<Operator> {
        if (TRACE) {
            console.log("createOperator - begin: ", operatorTypeOrNode)
        }
        const operator = this.createOperatorFromTypeOrNode(operatorTypeOrNode)
        await this.addOperator(operator)
        if (TRACE) {
            console.log("createOperator - end: ", operatorTypeOrNode)
        }
        return operator
    }

    removeOperator(operator: Operator): void {
        const operatorIndex = this._operators.indexOf(operator)
        if (operatorIndex < 0) {
            throw Error("operator not found")
        }
        this.onOperatorChanged(operator)
        if (this._selectedOperator === operator) {
            this.selectedOperator =
                operatorIndex > 0 ? this._operators[operatorIndex - 1] : this._operators.length > 1 ? this._operators[operatorIndex + 1] : null
        }
        operator.dispose()
        this._operators.splice(operatorIndex, 1)
        this.resetOperatorCache()
        this.requestUpdateResultImage()
    }

    async resetAllOperators() {
        while (this._operators.length > 0) {
            this.removeOperator(this._operators[this._operators.length - 1])
        }
        // add and select initial operator per default
        this.selectedOperator = await this.createOperator("operator-initial")
    }

    setOperatorLocked(operator: Operator, locked: boolean): void {
        if (operator.locked === locked) {
            return
        }
        operator.locked = locked
        this.requestUpdateResultImage()
    }

    setOperatorEnabled(operator: Operator, enabled: boolean): void {
        if (operator.enabled === enabled) {
            return
        }
        operator.enabled = enabled
        this.onOperatorChanged(operator)
        this.requestUpdateResultImage()
    }

    private async addOperator(operator: Operator, insertAtIndex?: number): Promise<void> {
        operator.requestEvaluation.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.requestUpdateResultImage())
        if (insertAtIndex !== undefined && insertAtIndex >= 0 && insertAtIndex < this._operators.length) {
            this._operators.splice(insertAtIndex, 0, operator)
        } else {
            this._operators.push(operator)
        }
        this.resetOperatorCache()
        this.onOperatorChanged(operator)
        this.requestUpdateResultImage()
    }

    private beginBulkChange() {
        this.busyInc()
        this.muteUpdates = true
    }

    private endBulkChange(forceUpdate = false) {
        if (forceUpdate) {
            this.requestUpdateResultImage()
        }
        this.muteUpdates = false
        this.busyDec()
    }

    private async setSourceImage(data: SourceImageData | null) {
        this._sourceImageRef?.release()
        this._sourceImageRef = data?.dataObjectId ? await this._imageOpContextWebGL2.createDataObjectImageRef(data.dataObjectId) : undefined
        this._sourceImageIsResult = data?.isResult ?? false
        this._lastEvalMode = "none"
        this.resetOperatorCache()
        this.requestUpdateResultImage()
    }

    private onOperatorChanged(operator: Operator) {
        this._operatorsChanged = true
        if (this._cachedOperator) {
            const cachedOperatorIndex = this._operators.indexOf(this._cachedOperator.operator)
            const changedOperatorIndex = this._operators.indexOf(operator)
            if (cachedOperatorIndex >= 0 && changedOperatorIndex >= 0 && changedOperatorIndex < cachedOperatorIndex) {
                this.resetOperatorCache()
            }
        }
    }

    private resetOperatorCache() {
        if (!this._cachedOperator) {
            return
        }
        if (TRACE) {
            console.log("resetting operator cache")
        }
        this._cachedOperator.evaluatedImage?.release()
        this._cachedOperator = undefined
    }

    private createOperatorFromTypeOrNode(operatorTypeOrNode: OperatorType | TextureEditNodes.Operator): Operator {
        const isOperatorNode = typeof operatorTypeOrNode === "object"
        const operatorType = isOperatorNode ? operatorTypeOrNode.type : operatorTypeOrNode
        const operatorNode = isOperatorNode ? operatorTypeOrNode : null
        switch (operatorType) {
            case "operator-initial":
                return new OperatorInitial(this)
            case "operator-auto-tiling":
                return new OperatorAutoTiling(this, operatorNode as TextureEditNodes.OperatorAutoTiling)
            case "operator-shift":
                return new OperatorShift(this, operatorNode as TextureEditNodes.OperatorShift)
            case "operator-rotate":
                return new OperatorRotate(this, operatorNode as TextureEditNodes.OperatorRotate)
            case "operator-layer-and-mask":
                return new OperatorLayerAndMask(this, operatorNode as TextureEditNodes.OperatorLayerAndMask)
            case "operator-highpass":
                return new OperatorHighpass(this, operatorNode as TextureEditNodes.OperatorHighpass)
            case "operator-clone-stamp":
                return new OperatorCloneStamp(this, operatorNode as TextureEditNodes.OperatorCloneStamp)
            case "operator-tiling":
                return new OperatorTiling(this, operatorNode as TextureEditNodes.OperatorTiling)
            case "operator-test":
                return new OperatorTest(this, operatorNode as TextureEditNodes.OperatorTest)
            default:
                assertNever(operatorType)
        }
    }

    requestUpdateResultImage(): void {
        if (this.muteUpdates) {
            this._mutedUpdatePending = true
        } else {
            this.deferredUpdateResultImage.schedule()
        }
    }

    private async updateResultImage(): Promise<void> {
        if (TRACE) {
            console.log("updateResultImage - started")
        }
        this.busyInc()
        this._imageOpEvaluatorPromiseGate
            .startAndRejectCurrent(
                () => this.executeOperators(),
                (result) => result?.release(),
            )
            .then((resultImage) => {
                this.resultImageChanged.emit(resultImage)
                resultImage.release()
                if (TRACE) {
                    console.log("updateResultImage - finished")
                }
            })
            .catch((e) => {
                if (e instanceof AsyncReentrancyGuard.RejectionError) {
                    if (TRACE) {
                        console.log("updateResultImage - cancelled")
                    }
                } else {
                    this._notificationService.showError("Error while updating result image: " + e.message)
                    // setTimeout(() => {
                    //     throw e
                    // }, 0) // Throws as an unhandled exception
                }
            })
            .finally(() => this.busyDec())
    }

    private async executeOperators(): Promise<ImageViewPtrWebGl2> {
        if (!this._sourceImageRef) {
            return new ImageViewPtrWebGl2()
        }
        if (TRACE) {
            console.log("executeOperators - begin")
        }

        // generate node graph
        const cmdQueue = this._imageOpContextWebGL2.createCommandQueue()
        const cachedOperatorInput = CACHE_SELECTED_OPERATOR_SOURCE ? this._cachedOperator : undefined
        const generatedNodeGraph = await this.generateOperatorNodeGraph(cmdQueue, this.selectedTextureType, this._sourceImageRef.ref, {
            processToSelectionOnly: true,
            cachedOperatorInput,
            sourceImageIsResult: this._sourceImageIsResult,
        })

        // execute command queue
        const imageRefsToEvaluate = [generatedNodeGraph.resultImage]
        if (generatedNodeGraph.cachedOperatorInput) {
            imageRefsToEvaluate.push(generatedNodeGraph.cachedOperatorInput.sourceImageRef)
        }

        // const graph = cmdQueue.buildGraph(imageRefsToEvaluate)
        // if (AUTO_DOWNLOAD_GRAPH) {
        //     this.downloadImageOpCommandQueueGraph(graph)
        // }
        // const evaluatedResultImages = await cmdQueue.execute(graph)
        const evaluatedResultImages = await cmdQueue.execute(imageRefsToEvaluate)

        const evaluatedResultImage = evaluatedResultImages[0]
        if (generatedNodeGraph.cachedOperatorInput) {
            // keep a reference to the cached operator input to prevent it from being released
            this.resetOperatorCache()
            this._cachedOperator = {
                ...generatedNodeGraph.cachedOperatorInput,
                evaluatedImage: evaluatedResultImages[1],
            }
        }

        if (TRACE) {
            console.log("executeOperators - end")
        }
        return evaluatedResultImage
    }

    get selectedTextureType(): TextureType {
        return this.textureEditorCallback.data?.textureTypeSpecific?.textureType ?? TextureType.Diffuse
    }

    hasAppliedOperators(): boolean {
        return this._operators.some((operator) => !operator.flags.has("is-internal"))
    }

    hasAppliedOperatorsForTextureType(textureType: TextureType): boolean {
        for (let i = 0; i < this._operators.length; i++) {
            const operator = this._operators[i]
            if (!operator.flags.has("is-internal") && operator.shouldApplyTo(textureType)) {
                return true
            }
        }
        return false
    }

    prepareSourceImage(cmdQueue: ImageOpCommandQueue, textureType: TextureType, sourceImage: ImageRef) {
        if (this.hasAppliedOperatorsForTextureType(textureType)) {
            // convert to the correct channel layout (as it is not correctly set by the incoming data-object image)
            sourceImage = convert(cmdQueue, {
                sourceImage: sourceImage,
                channelLayout: imageOpChannelLayoutByTextureChannelLayout(descriptorByTextureType(textureType).channelLayout),
                //dataType: cmdQueue.mode === "preview" ? TextureEditorSettings.PreviewProcessingImageFormat : TextureEditorSettings.FinalProcessingImageFormat,
            })
            // apply levels to ensure no negative values. we only do this if we have applied operators to keep the graph trivial otherwise (for further down optimizations)
            sourceImage = levels(cmdQueue, {
                sourceImage: sourceImage,
                blackLevel: 0.0,
                whiteLevel: 1.0,
            })
        }
        return sourceImage
    }

    async generateOperatorNodeGraph(
        cmdQueue: ImageOpCommandQueue,
        textureType: TextureType,
        sourceImage: ImageRef,
        options?: {
            processToSelectionOnly?: boolean
            cachedOperatorInput?: CachedOperatorInput
            sourceImageIsResult?: boolean
        },
    ): Promise<GeneratedOperatorNodeGraphResult> {
        const processToSelectionOnly = options?.processToSelectionOnly ?? false
        const cachedOperatorInput = options?.cachedOperatorInput
        const sourceImageIsResult = options?.sourceImageIsResult ?? false
        if (cachedOperatorInput && cmdQueue.mode !== "preview") {
            throw Error("Caching is only supported for preview mode")
        }

        const isInitialEval = this._lastEvalMode !== cmdQueue.mode
        this._lastEvalMode = cmdQueue.mode
        let currSourceImage: ImageRef
        let firstOperatorIndexToExecute: number
        if (
            !sourceImageIsResult &&
            cachedOperatorInput &&
            this._selectedOperator &&
            this._operators.indexOf(this._selectedOperator) >= this._operators.indexOf(cachedOperatorInput.operator)
        ) {
            if (TRACE) {
                console.log(`starting evaluation at cached operator: ${cachedOperatorInput.operator.type}`)
            }
            currSourceImage = cachedOperatorInput.sourceImageRef
            firstOperatorIndexToExecute = this._operators.indexOf(cachedOperatorInput.operator)
        } else {
            firstOperatorIndexToExecute = 0
            currSourceImage = this.prepareSourceImage(cmdQueue, textureType, sourceImage)
        }

        let newlyCachedOperatorInput: CachedOperatorInput | undefined = undefined
        let hasAppliedOperators = false
        if (!sourceImageIsResult) {
            const operatorIndexToCacheSource = this._selectedOperator ? this._operators.indexOf(this._selectedOperator) : -1
            for (let i = firstOperatorIndexToExecute; i < this._operators.length; i++) {
                const operator = this._operators[i]
                if (TRACE) {
                    console.log(`  executeOperators - operator(${i}): `, operator)
                }

                if (i === operatorIndexToCacheSource) {
                    if (TRACE) {
                        console.log("  executeOperators - caching operator source image: ", currSourceImage)
                    }
                    // let's cache this result
                    newlyCachedOperatorInput = {
                        operator: operator,
                        sourceImageRef: currSourceImage,
                    }
                }

                if (operator.shouldApplyTo(textureType)) {
                    hasAppliedOperators = true
                    const inputChanged = isInitialEval || !(i === firstOperatorIndexToExecute) // the first operator gets the same source image as last time (except if this is the initial eval)
                    const operatorOutput = await operator.queueImageOps(cmdQueue, currSourceImage, {textureType, inputChanged})
                    currSourceImage = operatorOutput.resultImage
                    if (operatorOutput.options?.stopEvaluation) {
                        break
                    }
                }

                if (processToSelectionOnly && this.selectedOperator === operator) {
                    break
                }
            }
        }

        return {
            hasAppliedOperators,
            resultImage: currSourceImage,
            cachedOperatorInput: newlyCachedOperatorInput,
        }
    }

    // OperatorCallback
    setBusy(busy: boolean): void {
        if (busy) {
            this.busyInc()
        } else {
            this.busyDec()
        }
    }

    private busyInc() {
        this._busyCounter++
    }

    private busyDec() {
        this._busyCounter--
    }

    private readonly unsubscribe = new Subject<void>()
    private _busyCounter = 0
    private _updatesMutedCounter = 0
    private _mutedUpdatePending = false
    private _sourceImageRef?: ManagedImageRef
    private _sourceImageIsResult = true
    private _lastEvalMode: "none" | "preview" | "final" = "none"
    private readonly _halContext: HalContext
    private _operatorsChanged = false
    private readonly _operators: Operator[] = []
    private _selectedOperator: Operator | null = null
    private readonly deferredUpdateResultImage = new DeferredBatchCall(() => this.updateResultImage())
    private readonly _imageOpContextWebGL2: ImageOpContextWebGL2
    private readonly _imageOpEvaluatorPromiseGate: AsyncReentrancyGuard.PromiseGate<ImageViewPtrWebGl2>
    private _cachedOperator?: CachedOperatorInput
    private readonly _imageCacheWebGL2: ImageCacheWebGL2
    private readonly _drawableImageCache: DrawableImageCache
    private readonly _halBlitter: HalPainterImageBlit
    private readonly _texturesApi: TexturesApiService
    private readonly _notificationService: NotificationsService
}

type CachedOperatorInput = {
    operator: Operator
    sourceImageRef: ImageRef
    evaluatedImage?: ImageViewPtrWebGl2
}

export type GeneratedOperatorNodeGraphResult = {
    hasAppliedOperators: boolean
    resultImage: ImageRef
    cachedOperatorInput?: CachedOperatorInput
}
