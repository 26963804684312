import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const sparseListDescriptor = {
    length: TD.inlet(TD.Primitive<number>()),
    __output_value: TD.outlet(TD.Array(TD.Identity<any>())),
}

export class SparseList<T> implements NodeClassImpl<typeof sparseListDescriptor, typeof SparseList> {
    static descriptor = sparseListDescriptor
    static uniqueName = "SparseList"
    length!: Inlet<number>;
    [index: number]: Inlet<T>
    __output_value!: Outlet<T[]>

    run() {
        if (this.length === NotReady) {
            this.__output_value.emitIfChanged(NotReady)
            return
        }
        const list: T[] = []
        for (let n = 0; n < this.length; n++) {
            const elem = this[n]
            if (elem === NotReady) {
                continue
            }
            list.push(elem)
        }
        this.__output_value.emitIfChanged(list)
    }
}
