import {CachedImageRefBag} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/caching/cached-image-ref-bag"
import {ReturnType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-image-pyramid"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"

export class CachedImagePyramid {
    dispose() {
        this.descriptor = undefined
        this.cachedImageRefBag.dispose()
    }

    has() {
        return !!this.descriptor
    }

    set(cmdQueue: ImageOpCommandQueueWebGL2, value: ReturnType | undefined) {
        this.descriptor = value?.descriptor
        this.cachedImageRefBag.addOrReplace(cmdQueue, value?.resultImages)
    }

    get(): ReturnType {
        const result = this.getIfExists()
        if (!result) {
            throw new Error("ImagePyramid not found in bag")
        }
        return result
    }

    getIfExists(): ReturnType | undefined {
        if (!this.descriptor) {
            return undefined
        }
        const resultImages = this.cachedImageRefBag.get()
        return {
            descriptor: this.descriptor,
            resultImages,
        }
    }

    private descriptor?: ReturnType["descriptor"]
    private cachedImageRefBag = new CachedImageRefBag()
}
