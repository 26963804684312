import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {ImageDataObjectComponent} from "@editor/components/image-data-object/image-data-object.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {DataObject} from "@legacy/api-model/data-object"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {NodeUtils} from "@cm/template-nodes"

const UV_PRECISION = 0.01

@Component({
    selector: "cm-decal-inspector",
    templateUrl: "./decal-inspector.component.html",
    styleUrls: ["./decal-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        NodeReferenceSlotComponent,
        InputContainerComponent,
        NumericInputComponent,
        ButtonComponent,
        ImageDataObjectComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
    ],
})
export class DecalInspectorComponent implements OnInit, OnDestroy {
    @Input({required: true}) editor!: IEditor
    @Input() node: Nodes.MeshDecal | null = null
    @Output() chooseMeshUV = new EventEmitter<[Nodes.MeshOrInstance, (u: number, v: number) => void]>()

    NodeUtils = NodeUtils

    constructor() {}

    ngOnInit(): void {}

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    _chooseUV(): void {
        const node = this.node!
        this.chooseMeshUV.emit([
            node.mesh!,
            (u, v) => {
                u = Math.round(u / UV_PRECISION) * UV_PRECISION
                v = Math.round(v / UV_PRECISION) * UV_PRECISION
                node.offset = [u, v]
                this.updateNode(node)
            },
        ])
    }

    getDataObjectReference(dataObject: DataObject | undefined) {
        return dataObject
            ? Nodes.create<Nodes.DataObjectReference>({
                  type: "dataObjectReference",
                  dataObjectId: dataObject.id,
              })
            : undefined
    }

    hasDataObjectId(image: Nodes.Image | undefined): image is Nodes.Image & {dataObjectId: Nodes.DataObjectReference["dataObjectId"]} {
        return !!image && "dataObjectId" in image
    }

    ngOnDestroy(): void {}
}
