import {PricedItem, PricingContext} from "#pricing/nodes/core"
import {PriceList} from "#pricing/nodes/price-list"
import {PricedItemNode} from "#pricing/nodes/priced-item-node"
import {DeclareNodeGraphTS, TraversalAction} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"

@registerNode
export class FlattenPriceList extends DeclareNodeGraphTS<PricedItem[], PricingContext, {subprices: PriceList}>(
    {
        run: async ({get, parameters, context}) => {
            const subprices = await get(parameters.subprices)
            return subprices.flat()
        },
    },
    {
        nodeClass: "FlattenPriceList",
    },
) {
    getSubprices(): PriceList {
        return this.parameters.subprices
    }

    getNodesOfType<T>(type: new (...args: any[]) => T): T[] {
        const nodes = new Set<T>()
        this.depthFirstTraversalPreorder((node) => {
            if (node instanceof type) {
                nodes.add(node)
            }
            return TraversalAction.Continue
        })
        return Array.from(nodes)
    }

    getOriginalIdsFromCatalog(): string[] {
        const identifiers = new Set<string>()
        this.depthFirstTraversalPreorder((node) => {
            if (node instanceof PricedItemNode) {
                const identifier = node.getUniqueId()
                if (identifier.length === 0) throw new Error(`Node ${node} has no unique identifier`)
                identifiers.add(identifier)
            }
            return TraversalAction.Continue
        })

        return Array.from(identifiers)
    }
}
