import {ScanRegionMaterialFragment} from "@api"
import {EditMode, RectangleRegion} from "@common/helpers/canvas/canvas-base-toolbox/std-toolbox-items/rectangle-region"
import * as paper from "paper"
import {ResolutionOption, SampleRotation, ScanRegionInfo, ScanRegionParameters} from "@platform/models/scanning/types"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export type QueryMaterialForScanRegion = (materialLegacyId: number) => Promise<ScanRegionMaterialFragment>

export class ScanRegion extends RectangleRegion {
    sampleRotation: SampleRotation = 0
    megapixelWarningThreshold = 100

    private _materialId: number | null = null // we keep the material-id separate since we may need it before the material has actually loaded
    private _material: ScanRegionMaterialFragment | null = null
    private _resolution?: ResolutionOption
    private _state?: ScanRegionParameters["state"]
    private _transparent?: boolean
    private centerLabel?: paper.PointText
    private tileGroup?: paper.Group

    constructor(
        parent: CanvasBaseToolboxItem,
        x = 0,
        y = 0,
        width = 500,
        height = 500,
        private queryMaterialForScanRegion: QueryMaterialForScanRegion,
    ) {
        super(parent, x, y, width, height)
    }

    allowEdit = true

    set materialId(value: number | null) {
        this._materialId = value
        if (!value) return

        this.queryMaterialForScanRegion(value).then((material) => {
            this._material = material
            this.updateLabels()
        })
    }

    get materialId() {
        return this._materialId
    }

    get material(): ScanRegionMaterialFragment | null {
        return this._material
    }

    get resolution(): ResolutionOption | undefined {
        return this._resolution
    }

    set resolution(value: ResolutionOption | undefined) {
        this._resolution = value
        this.updateLabels()
    }

    get state(): ScanRegionParameters["state"] | undefined {
        return this._state
    }

    set state(value: ScanRegionParameters["state"]) {
        this._state = value
        this.updateLabels()
    }

    get transparent(): boolean | undefined {
        return this._transparent
    }

    set transparent(value: boolean | undefined) {
        this._transparent = value
        this.updateLabels()
    }

    get area(): [number, number, number, number] {
        const [x1, y1, x2, y2] = this.getRect()
        const cmPerPx = 1 / this.physicalInfo!.pixelsPerCm
        return [x1 * cmPerPx + this.physicalInfo!.originXCm, y1 * cmPerPx + this.physicalInfo!.originYCm, (x2 - x1) * cmPerPx, (y2 - y1) * cmPerPx]
    }

    set area(area: [number, number, number, number]) {
        const pxPerCm = this.physicalInfo!.pixelsPerCm
        const x1 = (area[0] - this.physicalInfo!.originXCm) * pxPerCm
        const y1 = (area[1] - this.physicalInfo!.originYCm) * pxPerCm
        const x2 = x1 + area[2] * pxPerCm
        const y2 = y1 + area[3] * pxPerCm
        this.setRect(x1, y1, x2, y2)
    }

    private initCenterLabel(): void {
        this.centerLabel = new paper.PointText(new paper.Point(0, 0))
        this.centerLabel.fillColor = new paper.Color("whitesmoke")
        this.centerLabel.justification = "center"
        this.centerLabel.content = "State label"
        this.centerLabel.fontSize = this.baseFontSize
        this.centerLabel.fontFamily = "Roboto"
        this.labelGroup.addChild(this.centerLabel)
    }

    protected override updateLabels(): void {
        super.updateLabels()

        const [x1, y1, x2, y2] = this.getRect()
        const width = x2 - x1
        const height = y2 - y1
        const position: paper.Point = new paper.Point(x1 + width / 2, y1 + height / 2)

        let redOutlineAndText = false
        let fillColorName: string
        let labelText: string

        switch (this._state) {
            case "editing":
                {
                    fillColorName = "whitesmoke"
                    const megapixel = this.getScanAreMegapixel()
                    if (megapixel > this.megapixelWarningThreshold) {
                        labelText = `WARNING\n~${megapixel.toFixed(0)} MP`
                        redOutlineAndText = true
                    } else {
                        labelText = `~${megapixel.toFixed(0)} MP`
                    }
                }
                break
            case "waiting":
                fillColorName = "whitesmoke"
                labelText = "Waiting"
                break
            case "scanning":
                fillColorName = "orange"
                labelText = "Scanning"
                break
            case "complete":
                fillColorName = "green"
                labelText = "Complete"
                break
            case "failed":
                fillColorName = "red"
                labelText = "Failed"
                break
            case "aborted":
                fillColorName = "black"
                labelText = "Aborted"
                break
        }

        this.rectanglePath.fillColor = new paper.Color(fillColorName!)
        this.rectanglePath.fillColor.alpha = 0.15

        if (!this.centerLabel) {
            this.initCenterLabel()
        }
        this.centerLabel!.content = labelText!
        this.centerLabel!.fontWeight = "Bold"
        this.centerLabel!.strokeColor = this.fontStrokeColor

        if (redOutlineAndText) {
            this.centerLabel!.fillColor = new paper.Color("red")
        } else {
            this.centerLabel!.fillColor = new paper.Color("whitesmoke")
        }
        this.rectanglePath.strokeColor = this.centerLabel!.fillColor
        this.rectanglePath.strokeWidth = 2

        this.centerLabel!.fontSize = this.baseFontSize / this.zoomLevel
        this.centerLabel!.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        this.centerLabel!.position = position
        this.centerLabel!.visible = this.visible && this.centerLabel!.bounds.width <= 0.9 * width && this.centerLabel!.bounds.height <= 0.9 * height
    }

    private getScanAreMegapixel(): number {
        if (!this._resolution) return 0
        const scanPixelsWidth = this.canvasToScanPixels(this.width)
        const scanPixelsHeight = this.canvasToScanPixels(this.height)
        return (scanPixelsWidth * scanPixelsHeight) / 10 ** 6
    }

    protected override getLabel(canvasPixels: number): string {
        const cm = canvasPixels / this.physicalInfo!.pixelsPerCm
        const scanPixels = this._resolution ? this.canvasToScanPixels(canvasPixels) : 0
        return `${cm.toFixed(2)} cm (${scanPixels.toFixed(0)} px)`
    }

    private canvasToScanPixels(canvasPixels: number): number {
        const cm = canvasPixels / this.physicalInfo!.pixelsPerCm
        return cm * 10 * this._resolution!.pixelsPerMm
    }

    updateInfo(info: ScanRegionInfo | null) {
        this.tileGroup?.remove()
        this.tileGroup = new paper.Group()
        this.regionGroup.addChild(this.tileGroup)
        this.tileGroup.sendToBack()
        if (info) {
            for (const tile of info.tiles) {
                const x = (tile.area[0] - this.physicalInfo!.originXCm) * this.physicalInfo!.pixelsPerCm
                const y = (tile.area[1] - this.physicalInfo!.originYCm) * this.physicalInfo!.pixelsPerCm
                const w = tile.area[2] * this.physicalInfo!.pixelsPerCm
                const h = tile.area[3] * this.physicalInfo!.pixelsPerCm
                const tileRect = new paper.Path.Rectangle(new paper.Point([x, y]), new paper.Size([w, h]))
                let colorName: string
                switch (tile.state) {
                    default:
                    case "editing":
                        colorName = "whitesmoke"
                        break
                    case "waiting":
                        colorName = "whitesmoke"
                        break
                    case "scanning":
                        colorName = "orange"
                        break
                    case "complete":
                        colorName = "green"
                        break
                    case "failed":
                        colorName = "red"
                        break
                    case "aborted":
                        colorName = "black"
                        break
                }
                tileRect.strokeColor = new paper.Color("whitesmoke")
                tileRect.strokeColor.alpha = 0.3
                tileRect.fillColor = new paper.Color(colorName)
                tileRect.fillColor.alpha = 0.15
                this.tileGroup.addChild(tileRect)
            }
        }
    }

    protected override getEditModeByHitResult(hitResult: paper.HitResult | null): EditMode {
        if (this.allowEdit) {
            return super.getEditModeByHitResult(hitResult)
        } else {
            return hitResult ? EditMode.Select : EditMode.None
        }
    }
}
