import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {z} from "zod"
import {color} from "#material-nodes/types"
import {threeRGBColorNode, threeConvert} from "#material-nodes/three-utils"

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({color: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional()})

export class RGB extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const colorValue = (await get(inputs.color)) ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            return {color: colorValue}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
