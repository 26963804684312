import {BoundaryCurveItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-item"
import {BoundaryControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-control-point"
import {BoundaryDirection} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {assertNever} from "@cm/utils"

export class BoundaryCurveControlPoint {
    constructor(
        readonly curve: BoundaryCurveItem,
        readonly controlPoint: BoundaryControlPoint,
        t: number,
    ) {
        this.t = t
    }

    get t() {
        switch (this.curve.boundary.direction) {
            case BoundaryDirection.Horizontal:
                return this.controlPoint.resultUV.x
            case BoundaryDirection.Vertical:
                return this.controlPoint.resultUV.y
            default:
                assertNever(this.curve.boundary.direction)
        }
    }

    set t(value: number) {
        switch (this.curve.boundary.direction) {
            case BoundaryDirection.Horizontal:
                this.controlPoint.resultUV = {
                    x: value,
                    y: this.controlPoint.resultUV.y,
                }
                break
            case BoundaryDirection.Vertical:
                this.controlPoint.resultUV = {
                    x: this.controlPoint.resultUV.x,
                    y: value,
                }
                break
            default:
                assertNever(this.curve.boundary.direction)
        }
    }
}
