import {JobNodes as Nodes, JobNodes, makeJobTaskDescriptor} from "#job-nodes/job-nodes"
import {CryptomatteId, PictureRenderJobOutput} from "#job-nodes/rendering"
import {SchemaDerivedFilename} from "#job-nodes/schema-derived-filename"
import {ImageProcessingNodes, PostProcessingSettings} from "@cm/image-processing-nodes"
type DataObjectReference = Nodes.DataObjectReference

type LegacyEntityId = number
type EntityId = string

export namespace Utility.Zip {
    export const FOLDER = "utilityZipFolder" as const
    export const FILE = "utilityZipFile"

    export type Destination =
        | {
              type: "fromEntityAndAssignmentInfo"
              entityId: LegacyEntityId
              entityType: "Material" // TODO;
              assignmentType: "Attachment" // TODO;
              assignmentKey: string | undefined
              customerId: LegacyEntityId // TODO
          }
        | {
              type: "fromDataObjectId"
              dataObjectId: number
          }
        | {
              type: "taskOutputOnly"
              customerId: LegacyEntityId
          }
        | {
              type: "tmpDownloadData"
              customerId: LegacyEntityId
          }

    export type Text = {
        type: "text"
        value: string
    }

    export type Json = {
        type: "json"
        value: any
    }

    export type File = {
        type: typeof FILE
        content: DataObjectReference | Text | Json
    }

    export type Folder = {
        type: typeof FOLDER
        content: Record<string, File | Folder>
    }

    export namespace JobNodes {
        export function text<T extends string>(_value: T | Nodes.TypedDataNode<T>) {
            return Nodes.struct({type: Nodes.value("text"), value: typeof _value === "string" ? Nodes.value(_value) : _value})
        }
        export function json<T>(_value: T | Nodes.TypedDataNode<T>) {
            return Nodes.struct({type: Nodes.value("json"), value: Nodes.isGetNode(_value) ? _value : Nodes.value(_value as any)})
        }
        export function file<T extends File["content"]>(_content: T | Nodes.TypedDataNode<T> | ReturnType<typeof text | typeof json>) {
            return Nodes.struct({
                type: Nodes.value(FILE),
                content: Nodes.isGetNode(_content) || Nodes.isStructNode(_content) ? _content : Nodes.value(_content),
            })
        }

        export type FolderContentT = ReturnType<typeof text | typeof json | typeof file> | Folder | Nodes.TypedDataNode<DataObjectReference>
        export type FolderContent = Record<string, FolderContentT>
        export type Folder = Nodes.TypedDataNode<
            Nodes.MapStructType<{
                type: Nodes.TypedDataNode<typeof Utility.Zip.FOLDER>
                content: Nodes.TypedDataNode<Nodes.MapStructType<FolderContent>>
            }>
        >

        export function folder<
            A extends Utility.Zip.Folder["content"] extends Record<string, infer _> ? _ : never,
            B extends ReturnType<typeof text | typeof json | typeof file> | Folder,
            T extends A | B,
        >(_content: Record<string, T>): Folder {
            const content: FolderContent = {}

            for (const key of Object.keys(_content)) {
                const item = _content[key]

                if (Nodes.isGetNode(item) || Nodes.isStructNode(item)) {
                    content[key] = item
                } else if (item.type === Utility.Zip.FOLDER) {
                    content[key] = folder(item.content)
                } else if (item.type === Utility.Zip.FILE) {
                    if (item.content.type === "dataObjectReference") {
                        content[key] = file(item.content)
                    } else if (item.content.type === "json") {
                        content[key] = file(json(item.content.value))
                    } else if (item.content.type === "text") {
                        content[key] = file(text(item.content.value))
                    }
                } else {
                    throw Error(`Unknown folder content item: ${item}`)
                }
            }
            return Nodes.struct({type: Nodes.value(Utility.Zip.FOLDER), content: Nodes.struct(content)})
        }
    }

    export type Input = {
        destination: Utility.Zip.Destination
        root: Utility.Zip.Folder
        filename: string
    }
    export type Output = {
        zipFile: Nodes.DataObjectReference
    }
    export const task = makeJobTaskDescriptor<Input, Output>("Utility.zip", 1)
}

export namespace Utility.DataObject {
    type DataObjectFields = {
        // FIXME Use the type from generated graphql file
        organization: number
        originalFileName: string
        mediaType?: string
        state?: "COMPLETED" | "INIT" | "PROCESSING" | "UPLOAD_FINISHED"
        // bucketName?: string;
        // objectName?: string;
        width?: number
        height?: number
        size?: number
        imageColorSpace?: "GAMMA_2_0" | "GAMMA_2_2" | "LINEAR" | "SRGB" | "UNKNOWN"
        imageDataType?: "COLOR" | "NON_COLOR" | "UNKNOWN"
    }

    export type InputCreate = {
        operation: "create"
        fields: DataObjectFields
    }

    export type InputUpdate = {
        operation: "update"
        dataObject: Nodes.DataObjectReference
        fields: Partial<Omit<DataObjectFields, "organization">>
    }

    export function update(_dataObject: Nodes.TypedDataNode<Nodes.DataObjectReference> | Nodes.DataObjectReference, _fields: InputUpdate["fields"]) {
        const dataObject = Nodes.isDataObjectReference(_dataObject) ? Nodes.value(_dataObject) : _dataObject
        const fields = Nodes.value(_fields)
        return Nodes.task(task, {input: Nodes.struct({operation: Nodes.value("update" as const), fields: fields, dataObject: dataObject})})
    }

    export function create(_fields: InputCreate["fields"]) {
        const fields = Nodes.value(_fields)
        return Nodes.task(task, {input: Nodes.struct({operation: Nodes.value("create" as const), fields: fields})})
    }

    export type Input = InputCreate | InputUpdate
    export type Output = {
        dataObject: Nodes.DataObjectReference
    }
    export const task = makeJobTaskDescriptor<Input, Output>("Utility.dataObject", 1)
}

export namespace Utility.TextureRevision {
    // FIXME Use the type from generated graphql file
    type CreateTextureRevisionFields = {
        width?: number // absolute width in cm
        height?: number // absolute height in cm
        pxPerCm?: number // width and height are computed from pixels per cm and data-object width/height
        displacement?: number
        dataObject: JobNodes.DataObjectReference
        state: "Completed" | "Draft"
        createdById?: string | number // id or legacyId
    }

    export type InputCreate = {
        operation: "create"
        fields: CreateTextureRevisionFields
    }

    export type OutputCreate = {
        textureRevisionId: LegacyEntityId
    }

    export type InputQuery = {
        operation: "query"
        fields: {
            textureRevisionId: LegacyEntityId | EntityId
        }
    }

    export type OutputQuery = {
        id: EntityId
        legacyId: LegacyEntityId
        width: number
        height: number
        displacement?: number
        dataObject: JobNodes.DataObjectReference
    }

    export function create(_fields: InputCreate["fields"]) {
        const fields = Nodes.value(_fields)
        return Nodes.task(task, {input: Nodes.struct({operation: Nodes.value("create" as const), fields: fields})})
    }

    export type Input = InputCreate | InputQuery
    export type Output = OutputCreate & OutputQuery
    export const task = makeJobTaskDescriptor<Input, Output>("Utility.textureRevision", 1)
}

export namespace Utility.TextureSetRevision {
    // FIXME Use the type from generated graphql file
    type TextureSetRevisionCreateFields = {
        textureSetId: string | number
        name?: string
        width: number
        height: number
        displacement?: number
        mapAssignments: {
            textureType:
                | "Anisotropy"
                | "AnisotropyRotation"
                | "AnisotropyStrength"
                | "Diffuse"
                | "DiffuseRoughness"
                | "Displacement"
                | "Error"
                | "F0"
                | "IOR"
                | "Mask"
                | "Metalness"
                | "Normal"
                | "Roughness"
                | "SpecularStrength"
                | "SpecularTint"
                | "Transmission"
            dataObjectId: string | number
        }[]
        editsJson?: {} // TODO can we be more specific here ?
        createdById?: string | number // id or legacyId
    }

    type TextureSetRevisionUpdateFields = {
        textureSetRevisionId: string
        name?: string
        width?: number
        height?: number
        displacement?: number
        autoRescaleSize?: boolean
        mapAssignments?: {
            textureType:
                | "Anisotropy"
                | "AnisotropyRotation"
                | "AnisotropyStrength"
                | "Diffuse"
                | "DiffuseRoughness"
                | "Displacement"
                | "Error"
                | "F0"
                | "IOR"
                | "Mask"
                | "Metalness"
                | "Normal"
                | "Roughness"
                | "SpecularStrength"
                | "SpecularTint"
                | "Transmission"
            dataObjectId: string | number
        }[]
        editsJson?: {} // TODO can we be more specific here ?
        updatedById?: string | number // id or legacyId
    }

    export type InputCreate = {
        operation: "create"
        fields: TextureSetRevisionCreateFields
    }

    export type InputUpdate = {
        operation: "update"
        fields: TextureSetRevisionUpdateFields
    }

    export type Output = {
        textureSetRevisionId: EntityId
    }

    export function create(_fields: InputCreate["fields"]) {
        const fields = Nodes.value(_fields)
        return Nodes.task(task, {input: Nodes.struct({operation: Nodes.value("create" as const), fields: fields})})
    }

    export function update(_fields: InputUpdate["fields"]) {
        const fields = Nodes.value(_fields)
        return Nodes.task(task, {input: Nodes.struct({operation: Nodes.value("update" as const), fields: fields})})
    }

    export type Input = InputCreate | InputUpdate
    export const task = makeJobTaskDescriptor<Input, Output>("Utility.textureSetRevision", 1)
}

export namespace Utility.NameAssetFromSchema {
    export type Input = {
        schema: SchemaDerivedFilename.FilenameSchema
        assetData: Omit<SchemaDerivedFilename.AssetData, "config" | "extension"> & {configJsonOrDataObjectId: EntityId}
    }

    export type Output = {
        dataObject: Nodes.DataObjectReference
    }

    export const task = makeJobTaskDescriptor<Input, Output>("Utility.nameAssetFromSchema", 1)
}

// ------ create post processing graph ------ //
export type CreatePostProcessingGraphInput = {
    data: PictureRenderJobOutput
    settings?: PostProcessingSettings
    selectedMask?: CryptomatteId
}
export type CreatePostProcessingGraphOutput = {
    image: ImageProcessingNodes.ImageNode
    selectedMask: ImageProcessingNodes.Mask | undefined
}
export const createPostProcessingGraph = makeJobTaskDescriptor<CreatePostProcessingGraphInput, CreatePostProcessingGraphOutput>(
    "Utility.createPostProcessingGraph",
    1,
)
