import {Component, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, DataObjectAssignmentType, MutationUpdateOrganizationInput, OrganizationDetailsFragment, OrganizationType} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {IsLoadingDirective} from "@common/directives"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {capitalizeFirstLetter} from "@common/helpers/utils/string"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {AttachmentsComponent} from "@common/components/files/attachments/attachments.component"

@Component({
    imports: [
        AttachmentsComponent,
        DetailsDialogLayoutComponent,
        FormsModule,
        IsLoadingDirective,
        MatInputModule,
        MatSelectModule,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        GalleryImageComponent,
        TitleSectionComponent,
        AttachmentsComponent,
    ],
    selector: "cm-organization-details",
    standalone: true,
    styleUrls: ["organization-details.component.scss"],
    templateUrl: "organization-details.component.html",
})
export class OrganizationDetailsComponent
    extends BaseDetailsComponent<OrganizationDetailsFragment, Omit<MutationUpdateOrganizationInput, "id">>
    implements OnInit
{
    override _contentTypeModel = ContentTypeModel.Organization
    override _fetchItem = this.sdk.gql.organizationDetails
    override _updateItem = this.sdk.gql.updateOrganization

    override get title() {
        const item = this.$item()
        return item?.name || (item ? `${capitalizeFirstLetter(this.displayName)} ${item?.legacyId}` : null)
    }

    hasGalleryImage() {
        return this.$item()?.dataObjectAssignments?.some((assignment) => assignment.assignmentType === DataObjectAssignmentType.GalleryImage)
    }

    parseLegacyIdFromString(legacyId: string) {
        return parseInt(legacyId, 10)
    }

    protected readonly OrganizationType = OrganizationType
    protected readonly DialogSize = DialogSize
    protected readonly assignmentTypes = [
        DataObjectAssignmentType.Attachment,
        DataObjectAssignmentType.GalleryImage,
        DataObjectAssignmentType.PdfTemplate,
        DataObjectAssignmentType.Font,
        DataObjectAssignmentType.FontBold,
    ]
}
