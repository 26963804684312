import {Component, EventEmitter, inject, Input, OnInit, Output, DestroyRef} from "@angular/core"
import {ContentTypeModel, PictureAssetsSectionFragment} from "@api"
import {EntityAddCardComponent} from "@common/components/entity/entity-add-card/entity-add-card.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {SectionComponent} from "@common/components/item"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {SelectAssetComponent} from "@platform/components/assets/select-asset/select-asset.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DialogSize} from "@app/common/models/dialogs"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {DialogService} from "@common/services/dialog/dialog.service"

@Component({
    selector: "cm-picture-assets-section",
    standalone: true,
    imports: [SectionComponent, EntityCardComponent, EntityAddCardComponent, StateLabelComponent, DataObjectThumbnailComponent],
    templateUrl: "./picture-assets-section.component.html",
    styleUrl: "./picture-assets-section.component.scss",
})
export class PictureAssetsSectionComponent implements OnInit {
    @Input({required: true}) pictureId!: string
    @Output() afterAssetChange = new EventEmitter<string>()

    permission = inject(PermissionsService)
    dialog = inject(DialogService)
    destroyRef = inject(DestroyRef)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    picture: PictureAssetsSectionFragment | null = null

    ngOnInit() {
        this.refresh
            .keepFetched$(this.pictureId, ContentTypeModel.Picture, this.sdk.gql.pictureAssetsSection)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((picture) => {
                this.picture = picture ?? null
            })
    }

    openSelectAssetDialog() {
        this.dialog.selectInDialog(SelectAssetComponent, {
            onSelect: (asset) =>
                this.notifications.withUserFeedback(
                    async () => {
                        const picture = this.picture
                        if (picture) {
                            await this.sdk.gql.createPictureDetailsAssetAssignment({
                                input: {
                                    pictureId: picture.id,
                                    assetId: asset.id,
                                },
                            })
                            this.afterAssetChange.emit(picture.id)
                        }
                    },
                    {error: "Cannot create picture asset assignment"},
                ),
        })
    }

    async deletePictureAssetAssignment(assetAssignmentId: string) {
        return this.notifications.withUserFeedback(
            async () => {
                const picture = this.picture
                if (picture) {
                    await this.sdk.gql.pictureDetailsDeleteAssetAssignment({id: assetAssignmentId})
                    this.afterAssetChange.emit(picture.id)
                }
            },
            {
                success: "Picture asset assignment deleted",
                error: "Cannot delete picture asset assignment",
            },
        )
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
    protected readonly DialogSize = DialogSize
}
