import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode, externalId} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {templateLike} from "#template-nodes/node-types"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {z} from "zod"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"

const stringResolveParameters = namedNodeParameters.merge(
    z.object({
        template: templateLike,
        nodeId: externalId,
    }),
)
export type StringResolveParameters = z.infer<typeof stringResolveParameters>

@registerNode
export class StringResolve
    extends DeclareTemplateNode({parameters: stringResolveParameters}, {}, {nodeClass: "StringResolve"})
    implements EvaluableTemplateNode<string>
{
    evaluate() {
        return this.parameters.nodeId
    }
}

export type StringResolveFwd = TemplateNode<StringResolveParameters> & EvaluableTemplateNode<string>
