import {AsyncPipe} from "@angular/common"
import {Component, EventEmitter, inject, Input, Output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatMenuModule} from "@angular/material/menu"
import {MaterialInspectorMaterialFragment} from "@api"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {GridListComponent} from "@common/components/lists/grid-list/grid-list.component"
import {GridSize} from "@legacy/helpers/utils"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IEditor} from "@editor/models/editor"
import {Material} from "@legacy/api-model/material"
import {MaterialRevision} from "@legacy/api-model/material-revision"
import {Settings} from "@common/models/settings/settings"
import {Observable, of as observableOf, switchMap} from "rxjs"
import {NodeUtils} from "@cm/template-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"

@Component({
    selector: "cm-material-inspector",
    templateUrl: "./material-inspector.component.html",
    styleUrls: ["./material-inspector.component.scss"],
    standalone: true,
    imports: [MatCheckboxModule, FormsModule, GridListComponent, MemoizePipe, AsyncPipe, MatMenuModule, EntityCardComponent],
})
export class MaterialInspectorComponent {
    @Input() editor!: IEditor
    @Input() slotName!: string
    @Input() materialNode: Nodes.Material | null = null
    @Input() showNode = true
    @Output() chooseMaterial = new EventEmitter<(material: MaterialInspectorMaterialFragment) => void>()
    @Output() showNodeChange: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() templateEditorMode = false
    gridSizes = GridSize

    readonly noMaterialUrl = Settings.NO_MATERIAL_URL

    isMaterialReference = NodeUtils.isMaterialReference

    sdk = inject(SdkService)

    constructor(public authService: AuthService) {}

    getMaterialRevisionId(materialNode: Nodes.Material) {
        if ("materialRevisionId" in materialNode) return materialNode.materialRevisionId
        else return undefined
    }

    _chooseMaterial(): void {
        const node = this.materialNode
        if (!node || !NodeUtils.isMaterialReference(node)) return
        this.chooseMaterial.emit((material: MaterialInspectorMaterialFragment) => {
            const materialRevision = material.latestCyclesRevision
            if (!materialRevision) {
                throw new Error("Material has no latest cycles revision")
            }
            node.materialRevisionId = materialRevision.legacyId
            node.name = material.name!
            this.editor.sceneManager.markNodeChanged(node)
        })
    }

    async fetchMaterialFromRevisionId(legacyId: number | undefined): Promise<MaterialInspectorMaterialFragment | null> {
        if (legacyId !== undefined && legacyId !== null) {
            return this.sdk.gql.materialInspectorRevision({legacyId}).then(({materialRevision: {material}}) => material)
        } else {
            return null
        }
    }

    getCurrentRevisionNumber(id: number | undefined): Observable<number | null> {
        if (id !== undefined && id !== null) {
            return MaterialRevision.get(id).pipe(switchMap((materialRevision) => observableOf(materialRevision.number)))
        } else {
            return observableOf(null)
        }
    }

    getNewestRevisionNumber(material: Material): number | null {
        const materialRevision = material.getLatestCyclesRevision()
        return materialRevision?.number ?? null
    }

    updateToSpecificRevision(material: MaterialInspectorMaterialFragment, legacyId: number): void {
        if (!this.materialNode || !NodeUtils.isMaterialReference(this.materialNode)) return

        if (!material.cyclesRevisions!.some((revision) => revision.legacyId === legacyId)) {
            return
        }

        this.materialNode.materialRevisionId = legacyId
        this.materialNode.name = material.name!
        this.nodeChanged()
    }

    updateToLatestCyclesRevision(material: MaterialInspectorMaterialFragment): void {
        if (!this.materialNode || !NodeUtils.isMaterialReference(this.materialNode)) return
        const materialRevision = material.latestCyclesRevision
        if (!materialRevision) throw new Error("Material has no latest revision for cycles!")
        this.materialNode.materialRevisionId = materialRevision.legacyId
        this.materialNode.name = material.name!
        this.nodeChanged()
    }

    removeAllowOverride(node: Nodes.Material) {
        if (NodeUtils.isMaterialReference(node)) {
            delete node.allowOverride
            this.nodeChanged()
        }
    }

    nodeChanged(): void {
        this.editor.sceneManager.markNodeChanged(this.materialNode!)
    }
}
