import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const waitReadyDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class WaitReady<T> implements NodeClassImpl<typeof waitReadyDescriptor, typeof WaitReady> {
    static descriptor = waitReadyDescriptor
    static uniqueName = "WaitReady"
    input!: Inlet<T | typeof NotReady>
    output!: Outlet<T>

    run() {
        if (this.input === NotReady) this.output.emitIfChanged(NotReady)
        else this.output.emitIfChanged(this.input)
    }
}
