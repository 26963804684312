import {Box2Like} from "@cm/math"
import {HalImage} from "@common/models/hal/hal-image"
import {HalImageView} from "@common/models/hal/hal-image-view/index"
import {WebGl2Image} from "@common/models/webgl2"
import {WebGl2ImageView} from "@common/models/webgl2/webgl2-image-view"

export const createHalImageView = (image: HalImage, region: Box2Like): HalImageView => {
    if (image instanceof WebGl2Image) {
        return new WebGl2ImageView(image, region)
    }
    throw Error("Unsupported context")
}
