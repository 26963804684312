@if (meshNode) {
    <ng-content select="[cm-content]"> </ng-content>
    @if (!isProcedural) {
        <cm-inspector-section>
            <ng-container cm-title> Subdivision </ng-container>
            <ng-container cm-content>
                <div class="cm-mesh-settings">
                    <cm-input-container>
                        <ng-container cm-inline-label> Iterations: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="subdivision!" [decimalPlaces]="0"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                </div>
            </ng-container>
        </cm-inspector-section>
    }
    @if (isProcedural) {
        @if (hasGeometryGraph(meshNode)) {
            <cm-inspector-section>
                <ng-container cm-title> Geometry </ng-container>
                <ng-container cm-content>
                    <cm-json-input [(value)]="meshNode.geometryGraph" (valueChange)="updateNode(meshNode)"></cm-json-input>
                </ng-container>
            </cm-inspector-section>
        }
        @if (hasParameters(meshNode)) {
            <cm-inspector-section>
                <ng-container cm-title> Parameters </ng-container>
                <ng-container cm-content>
                    <cm-json-input [(value)]="meshNode.parameters" (valueChange)="updateNode(meshNode)"></cm-json-input>
                </ng-container>
            </cm-inspector-section>
        }
    }
    <cm-inspector-section>
        <ng-container cm-title>
            <div class="cm-extended-section-title">
                Materials
                <i
                    (click)="isMaterialSettingsOpen = !isMaterialSettingsOpen; $event.stopPropagation()"
                    #materialSettingsTrigger="cdkOverlayOrigin"
                    cdkOverlayOrigin
                    class="far fa-cog cm-displacement-settings"
                ></i>
            </div>
        </ng-container>
        <ng-container cm-content>
            <div class="cm-mesh-settings">
                @for (assignmentKV of getMaterialAssignments() | keyvalue; track assignmentKV) {
                    <cm-material-assignment
                        [assignment]="assignmentKV.value!"
                        (chooseMaterialNode)="this.chooseMaterialNode.emit($event)"
                        (addAssignment)="addMaterialAssignment(assignmentKV.key, $event)"
                        (removeAssignment)="removeMaterialAssignment(assignmentKV.key)"
                        (assignmentChanged)="updateNode(meshNode)"
                        (mouseenter)="highlightMaterialSlot(true, assignmentKV.key)"
                        (mouseleave)="highlightMaterialSlot(false, null)"
                    >
                        <ng-container cm-title>
                            {{ getSlotName(assignmentKV.key) }}
                        </ng-container>
                    </cm-material-assignment>
                }
            </div>
        </ng-container>
    </cm-inspector-section>
    <ng-template
        cdkConnectedOverlay
        (overlayOutsideClick)="isMaterialSettingsOpen = false"
        [cdkConnectedOverlayOrigin]="materialSettingsTrigger"
        [cdkConnectedOverlayOpen]="isMaterialSettingsOpen"
    >
        <div class="cm-overlay-settings">
            <div class="cm-material-slot-buttons">
                <cm-button (click)="addMaterialSlot()">
                    <ng-container cm-text>Add slot</ng-container>
                </cm-button>
                <cm-button (click)="removeEmptyMaterialSlots()">
                    <ng-container cm-text>Remove empty</ng-container>
                </cm-button>
            </div>
        </div>
    </ng-template>
    <cm-inspector-section>
        <ng-container cm-title>
            <div class="cm-extended-section-title">
                Displacement
                <i
                    (click)="isDisplacementSettingsOpen = !isDisplacementSettingsOpen; $event.stopPropagation()"
                    #displacementSettingsTrigger="cdkOverlayOrigin"
                    cdkOverlayOrigin
                    class="far fa-cog cm-displacement-settings"
                ></i>
            </div>
        </ng-container>
        <ng-container cm-content>
            <div class="cm-mesh-settings">
                <cm-image-data-object [dataObjectId]="displacementDataObjectId!" (dataObjectChange)="displacementDataObjectId = $event.id">
                </cm-image-data-object>
            </div>
        </ng-container>
    </cm-inspector-section>
    <ng-template
        cdkConnectedOverlay
        (overlayOutsideClick)="isDisplacementSettingsOpen = false"
        [cdkConnectedOverlayOrigin]="displacementSettingsTrigger"
        [cdkConnectedOverlayOpen]="isDisplacementSettingsOpen"
    >
        <div class="cm-overlay-settings">
            <mat-form-field class="cm-displacement-settings-input">
                <mat-label>UV Channel</mat-label>
                <input matInput type="number" min="0" max="10" [(ngModel)]="displacementUvChannel" />
            </mat-form-field>
            <mat-form-field class="cm-displacement-settings-input">
                <mat-label>Min (cm)</mat-label>
                <input matInput type="number" min="-10" max="10" [(ngModel)]="displacementMin" />
            </mat-form-field>
            <mat-form-field class="cm-displacement-settings-input">
                <mat-label>Max (cm)</mat-label>
                <input matInput type="number" min="-10" max="10" [(ngModel)]="displacementMax" />
            </mat-form-field>
        </div>
    </ng-template>
    <cm-inspector-section>
        <ng-container cm-title> Visibility </ng-container>
        <ng-container cm-content>
            <cm-toggle [toggle]="meshNode.visibleDirectly ?? true" (toggleChange)="meshNode.visibleDirectly = $event; updateNode(meshNode)">
                <ng-container cm-text>Visible to Camera</ng-container>
            </cm-toggle>
            <cm-toggle [toggle]="meshNode.visibleInReflections ?? true" (toggleChange)="meshNode.visibleInReflections = $event; updateNode(meshNode)">
                <ng-container cm-text>Visible in Reflections</ng-container>
            </cm-toggle>
            <cm-toggle [toggle]="meshNode.visibleInRefractions ?? true" (toggleChange)="meshNode.visibleInRefractions = $event; updateNode(meshNode)">
                <ng-container cm-text>Visible in Refractions</ng-container>
            </cm-toggle>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Surfaces </ng-container>
        <ng-container cm-content>
            @for (surface of getSurfaces(); track surface) {
                <cm-list-item [selected]="surface === selectedSurface" (click)="selectionService.selectSurface(surface)">
                    <ng-container cm-title>
                        {{ surface.name }}
                    </ng-container>
                    <ng-container cm-icons>
                        <div class="cm-surface-icons">
                            <i (click)="editSurface.emit([sceneObjectId!, meshNode, surface])" class="far fa-edit"></i>
                            <i (click)="deleteSurface(surface)" class="far fa-trash"></i>
                            <i (click)="openRenameSurfaceDialog(surface)" class="far fa-pencil"></i>
                        </div>
                    </ng-container>
                </cm-list-item>
            }
            <cm-list-item (click)="defineNewSurface.emit([sceneObjectId!, meshNode])">
                <ng-container cm-title>
                    <span class="cm-add-item">+ Add item</span>
                </ng-container>
            </cm-list-item>
        </ng-container>
    </cm-inspector-section>
}
