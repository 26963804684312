<div class="cm-node-flex-container">
    @if ($pricedItemNode()) {
        <div class="cm-node-icons">
            @switch ($validityStatus()) {
                @case ("valid") {
                    <div class="cm-node-icon" matTooltip="Priced Item" matTooltipPosition="above">
                        <i class="fa-solid fa-euro-sign"></i>
                    </div>
                }

                @case ("warning") {
                    <div class="cm-node-icon cm-warning" matTooltip="Price not found, no conditions assigned" matTooltipPosition="above">
                        <i class="fa-solid fa-exclamation-triangle"></i>
                    </div>
                }

                @case ("error") {
                    <div class="cm-node-icon cm-invalid" matTooltip="{{ $priceResult().error }}" matTooltipPosition="above">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                }
            }
        </div>

        <span class="cm-node-text" matTooltip="{{ $pricedItemNode()?.getUniqueId() }}" matTooltipPosition="above">{{ $description() }}</span>

        @if ($pricedItemNode()?.parameters?.condition; as condition) {
            <div class="cm-tools-right">
                <cm-variant-condition [variantConditionNode]="condition" ($conditionRemoved)="$conditionRemoved.emit()"></cm-variant-condition>
            </div>
        }
    }
</div>
