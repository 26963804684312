<div class="cm-details-dialog-content" [class.cm-details-dialog-content--wide-sidebar]="wideSidebar">
    <div class="cm-details-dialog-header">
        <div class="cm-details-dialog-title">
            <ng-content select="[cm-title]"></ng-content>
        </div>
        <div class="cm-details-dialog-buttons-container">
            <ng-content select="[cm-buttons]"></ng-content>
            <button aria-label="Close dialog" class="cm-simple-window-header-button cm-close-button" (click)="closeModal()" [tabIndex]="-1">
                <i class="far fa-xmark"></i>
            </button>
        </div>
    </div>
    @if (loadError) {
        <cm-full-page-feedback [text]="errorText(loadError) ?? ''" />
    } @else if (isLoaded) {
        <div class="cm-details-dialog-main-content">
            <ng-content select="[cm-main]"></ng-content>
        </div>
        <div class="cm-details-dialog-sidebar-content">
            <ng-content select="[cm-sidebar]"></ng-content>
        </div>
    } @else {
        <div class="cm-details-dialog-main-content">
            <cm-image-placeholder [dark]="true"></cm-image-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
        </div>
        <div class="cm-details-dialog-sidebar-content">
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
            <cm-placeholder [dark]="true"></cm-placeholder>
        </div>
    }
</div>
