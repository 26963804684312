@if (action === actions.COPY_SCENE) {
    <h2 mat-dialog-title>Copy scene</h2>
}
@if (action === actions.MOVE_PICTURE) {
    <h2 mat-dialog-title>Move picture</h2>
}
<mat-dialog-content>
    <form id="entityForm" (submit)="confirmAction()">
        @if (isStaff) {
            <mat-form-field>
                <mat-select
                    [(ngModel)]="result.organizationId"
                    placeholder="Customer"
                    name="mCustomer"
                    (selectionChange)="project = undefined; result.setId = undefined; loadProjects($event.value)"
                >
                    @for (option of organizations.$filterOptions(); track option) {
                        <mat-option [value]="option.state">{{ option.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        <mat-form-field>
            <mat-select [(ngModel)]="project" placeholder="Project" name="mProject" (selectionChange)="result.setId = undefined">
                @for (project of projects; track project) {
                    <mat-option [value]="project">Project {{ project.number }}: {{ project.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <ng-container>
            <mat-form-field>
                <mat-select [disabled]="project?.sets?.length === 0" [(ngModel)]="result.setId" [placeholder]="loadingSets ? 'Loading...' : 'Set'" name="mSet">
                    @for (set of project?.sets; track set) {
                        <mat-option [value]="set.id">Set {{ set.number }}: {{ set.name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button form="entityForm" [disabled]="!result.organizationId || !result.setId">
        @if (action === actions.COPY_SCENE) {
            Copy
        }
        @if (action === actions.MOVE_PICTURE) {
            Move
        }
    </button>
    <button mat-button (click)="cancelAction()">Cancel</button>
</mat-dialog-actions>
