import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MimeType} from "@legacy/helpers/utils"
import {Settings} from "@common/models/settings/settings"
import {FilesService} from "@common/services/files/files.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {EditorService} from "@editor/services/editor.service"
import {DataObject, DataObjectType} from "@legacy/api-model/data-object"
import {UploadService} from "@legacy/services/upload/upload.service"
import {Subscription} from "rxjs"

@Component({
    selector: "cm-image-data-object",
    templateUrl: "./image-data-object.component.html",
    styleUrls: ["./image-data-object.component.scss"],
    standalone: true,
    imports: [MatButtonModule, MatTooltipModule],
})
export class ImageDataObjectComponent implements OnInit, OnDestroy {
    @ViewChild("dropZone", {static: true}) dropZone!: ElementRef<HTMLElement>

    @Input() dataObject?: DataObject
    @Input() altText = "Image file"
    @Input() allowDelete = true
    @Output() dataObjectChange = new EventEmitter<DataObject>()

    @Input() set dataObjectId(id: number | null | undefined) {
        if (id !== undefined && id !== null) {
            if (id !== this.dataObject?.id) {
                DataObject.get(id).subscribe((dataObject) => (this.dataObject = dataObject))
            }
        } else {
            this.dataObject = undefined
        }
    }

    get dataObjectId(): number | undefined {
        return this.dataObject?.id
    }

    readonly dropZoneActive = {value: false}
    readonly noImageURL = Settings.IMAGE_NOT_AVAILABLE_URL
    private pollingSubscription?: Subscription

    constructor(
        private uploadService: UploadService,
        private uploadGqlService: UploadGqlService,
        private filesService: FilesService,
        private editorService: EditorService,
    ) {}

    ngOnInit(): void {
        this.initDropZone(this.dropZone.nativeElement)
    }

    initDropZone(elementRef: HTMLElement) {
        this.uploadService
            .initDropZone(elementRef, this.dropZoneActive, this.editorService.organizationLegacyId!, 1, MimeType.All, DataObjectType.Image)
            .subscribe(this.onUploadComplete.bind(this))
    }

    private onUploadComplete(dataObject: DataObject) {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe()
        }
        // TODO: use the refresh service instead
        // this.pollingSubscription = this.filesService.initDataObjectPolling(dataObject)

        this.dataObject = dataObject
        this.dataObjectChange.emit(this.dataObject)
    }

    filesSelectedForUpload(event: Event): void {
        // Cast is needed, otherwise there is no 'files' attribute: https://github.com/microsoft/TypeScript/issues/31816
        const files: FileList = (<HTMLInputElement>event.target).files!

        if (files.length < 1) return
        const file = files[0]

        const dataObject: DataObject = new DataObject()
        dataObject.customer = this.editorService.organizationLegacyId!
        this.uploadService.uploadFile(file, dataObject, true, true).subscribe(this.onUploadComplete.bind(this))

        // Clear the selected files, so even if the same file is picked a second time, the change event gets fired.
        // @ts-expect-error
        ;(<HTMLInputElement>event.target).value = null
    }

    onDelete() {
        // @ts-expect-error
        this.dataObject = null
        this.dataObjectChange.emit(this.dataObject)
    }

    ngOnDestroy() {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe()
        }
    }
}
