import {map, Subscription} from "rxjs"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"

const TD = TypeDescriptors

const loadMaterialRevisionDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    materialRevisionId: TD.inlet(TD.Number),
    materialGraph: TD.outlet(TD.MaterialGraph),
}

export class LoadMaterialRevision implements NodeClassImpl<typeof loadMaterialRevisionDescriptor, typeof LoadMaterialRevision> {
    static descriptor = loadMaterialRevisionDescriptor
    static uniqueName = "LoadMaterialRevision"
    sceneManager!: Inlet<ISceneManager>
    materialRevisionId!: Inlet<number>
    materialGraph!: Outlet<IMaterialGraph>

    private _pending: Subscription | null = null
    private _curRevisionId: number | null = null

    run() {
        if (this.sceneManager === NotReady || this.materialRevisionId === NotReady) {
            this.materialGraph.emitIfChanged(NotReady)
            return
        }
        if (this._curRevisionId === this.materialRevisionId) return
        this._curRevisionId = this.materialRevisionId
        this._pending?.unsubscribe()
        this._pending = null
        this.materialGraph.emitIfChanged(NotReady)
        this._pending = this.sceneManager.addBinding(
            this.sceneManager.loadMaterial(this.materialRevisionId).pipe(
                map((materialGraph) => {
                    this.materialGraph.emitIfChanged(materialGraph)
                }),
            ),
        )
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
