import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {LightPathInputs, LightPathOutputs} from "@material-editor/models/nodes/light-path"

@Component({
    selector: "cm-light-path-node",
    templateUrl: "./light-path-node.component.html",
    styleUrls: ["./light-path-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class LightPathNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = LightPathOutputs
    inputs = LightPathInputs
    typeInfo = LightPathNodeType
}

export const LightPathNodeType: MaterialNodeType<typeof LightPathNodeComponent> = {
    id: "lightPathNode",
    label: "Light Path",
    color: "#4987af",
    name: "ShaderNodeLightPath",
    inputs: [],
    outputs: [LightPathOutputs.isGlossyRay, LightPathOutputs.isShadowRay, LightPathOutputs.isDiffuseRay],
    component: LightPathNodeComponent,
}
