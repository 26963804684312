import {MaterialSocket} from "@material-editor/models/material-socket"

export const TexNoiseOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Value"},
    fac: {paramType: "socket", id: "Fac", type: "output", valueType: "output", label: "Fac"},
}

export const TexNoiseInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "input", label: "Vector"},
    scale: {paramType: "socket", id: "Scale", type: "input", valueType: "scalar", label: "Scale"},
    detail: {paramType: "socket", id: "Detail", type: "input", valueType: "scalar", label: "Detail"},
    // roughness: {paramType: "socket", id: "Roughness", type: "input", valueType: "scalar", label: "Roughness"},
    // lacunarit: {paramType: "socket", id: "Lacunarit", type: "input", valueType: "scalar", label: "Lacunarit"},
    distortion: {paramType: "socket", id: "Distortion", type: "input", valueType: "scalar", label: "Distortion"},
}
