import {Component, OnInit, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {UvMapOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {InputContainerComponent} from "../../../../common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "../../../../common/components/inputs/numeric-input/numeric-input.component"

@Component({
    selector: "cm-uv-map-node",
    templateUrl: "./uv-map-node.component.html",
    styleUrls: ["./uv-map-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent, InputContainerComponent, NumericInputComponent],
})
export class UvMapNodeComponent implements OnInit {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = UvMapOutputs
    typeInfo = UVMapNodeType
    uvChannel: number | undefined

    ngOnInit() {
        this.uvChannel = this.nodeBase?.getParameter("internal.uv_map_index")
    }

    changeUvChannel(value: number | undefined) {
        this.uvChannel = value
        this.nodeBase?.setParameter("internal.uv_map_index", value, "scalar")
    }
}

export const UVMapNodeType: MaterialNodeType<typeof UvMapNodeComponent> = {
    id: "uvMap",
    label: "UV Map",
    color: "#9e343e",
    name: "UVMap",
    inputs: [] as never[],
    outputs: [UvMapOutputs.uv],
    component: UvMapNodeComponent,
}
