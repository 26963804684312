import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {TransparentBsdfInputs, TransparentBsdfOutputs} from "@material-editor/models/nodes/transparent-bsdf"

@Component({
    selector: "cm-transparent-bsdf-node",
    templateUrl: "./transparent-bsdf.component.html",
    styleUrls: ["./transparent-bsdf.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class TransparentBsdfNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = TransparentBsdfInputs
    outputs = TransparentBsdfOutputs
    typeInfo = TransparentBsdfNodeType
}

export const TransparentBsdfNodeType: MaterialNodeType<typeof TransparentBsdfNodeComponent> = {
    id: "transparentBsdf",
    label: "Transparent BSDF",
    color: "#4987af",
    name: "ShaderNodeBsdfTransparent",
    inputs: [TransparentBsdfInputs.color, TransparentBsdfInputs.normal] as never[],
    outputs: [TransparentBsdfOutputs.bsdf],
    component: TransparentBsdfNodeComponent,
}
