import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {Size2Like, Vector2Like} from "@cm/math"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ExtRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"

export type ParameterType = {
    sourceImage: ImageRef
    sourceRegion?: ExtRegion // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}; offset can be a 1x1 >=2-channel image
    patchSize: Size2Like
    stride?: Vector2Like // default: {width: 1, height: 1}
    addressMode?: AddressMode // default: "wrap"
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

const imageOpExtractPatches: ImageOpType<ParameterType, ReturnType> = {
    name: "ExtractPatches",

    WebGL2: ({cmdQueue, parameters: {sourceImage: source, sourceRegion, patchSize, stride, addressMode, resultImageOrDataType}}) => {
        stride ??= {x: 1, y: 1}
        addressMode ??= "wrap"
        if (stride.x < 1 || stride.y < 1) {
            throw new Error("Stride must be >= 1")
        }
        const painterExtractPatches = cmdQueue.createPainter(
            "compositor",
            "extractPatches",
            `
            uniform int u_useOffsetImage;
            uniform ivec2 u_offset;
            uniform ivec2 u_patchSize;
            uniform ivec2 u_stride;
        
            vec4 computeColor(ivec2 targetPixel) {
                ivec2 patchIndex = targetPixel / u_patchSize;
                ivec2 patchPixel = targetPixel % u_patchSize;
                ivec2 patchOffset = patchIndex * u_stride;
                ivec2 offset = u_offset;
                if (u_useOffsetImage != 0) {
                    offset += ivec2(texelFetch1(ivec2(0, 0)).xy);
                }
                ivec2 sourcePixel = offset + patchOffset + patchPixel;
                return texelFetch0(sourcePixel, ${getHalAddressMode(addressMode)});
            }
            `,
        )
        sourceRegion ??= {x: 0, y: 0, width: source.descriptor.width, height: source.descriptor.height}
        const numPatches = {
            x: Math.floor((sourceRegion.width - patchSize.width) / stride.x) + 1,
            y: Math.floor((sourceRegion.height - patchSize.height) / stride.y) + 1,
        }
        const targetSize = {width: numPatches.x * patchSize.width, height: numPatches.y * patchSize.height}
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, {
            ...targetSize,
            channelLayout: source.descriptor.channelLayout,
            dataType: source.descriptor.dataType,
            options: source.descriptor.options,
            batching: {
                patchSize: {width: patchSize.width, height: patchSize.height},
                batchSize: {width: numPatches.x, height: numPatches.y},
            },
        })
        const offsetImage = "offsetImage" in sourceRegion ? sourceRegion.offsetImage : undefined
        const offset = "offsetImage" in sourceRegion ? sourceRegion.offset ?? {x: 0, y: 0} : {x: sourceRegion.x, y: sourceRegion.y}
        cmdQueue.paint(painterExtractPatches, {
            parameters: {
                u_useOffsetImage: {type: "int", value: offsetImage ? 1 : 0},
                u_offset: {type: "int2", value: offset},
                u_patchSize: {type: "int2", value: {x: patchSize.width, y: patchSize.height}},
                u_stride: {type: "int2", value: stride},
            },
            sourceImages: [source, offsetImage],
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: () => {
        throw new Error("Not implemented")
    },
}

export function extractPatches(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpExtractPatches, parameters)
}
