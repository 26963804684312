import {Box2Like} from "@cm/math"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {HalPaintableImageView} from "@common/models/hal/hal-paintable-image-view/index"
import {WebGl2PaintableImage} from "@common/models/webgl2"
import {WebGl2PaintableImageView} from "@common/models/webgl2/webgl2-paintable-image-view"

export const createHalPaintableImageView = (image: HalPaintableImage, region: Box2Like): HalPaintableImageView => {
    if (image instanceof WebGl2PaintableImage) {
        return new WebGl2PaintableImageView(image, region)
    }
    throw Error("Unsupported context")
}
