import {MaterialSocket} from "@material-editor/models/material-socket"

export const BumpInputs: Record<string, MaterialSocket> = {
    strength: {paramType: "socket", id: "Strength", type: "input", valueType: "scalar", label: "Strength"},
    distance: {paramType: "socket", id: "Distance", type: "input", valueType: "scalar", label: "Distance"},
    height: {paramType: "socket", id: "Height", type: "input", valueType: "scalar", label: "Height"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "input", label: "Normal"},
}

export const BumpOutputs: Record<string, MaterialSocket> = {
    normal: {paramType: "socket", id: "Normal", type: "output", valueType: "output", label: "Normal"},
}
