import {ConfigurationGroupNode} from "#pricing/nodes/configuration-group-node"
import {PricingContext} from "#pricing/nodes/core"
import {DeclareListNodeTS, registerNode} from "@cm/graph"

@registerNode
export class ConfigurationList extends DeclareListNodeTS<PricingContext, ConfigurationGroupNode>(
    {},
    {
        nodeClass: "ConfigurationList",
    },
) {}
