import {SceneNodes} from "@cm/template-nodes"
import {
    ThreeObject,
    getThreeObjectPart,
    mathIsEqual,
    setThreeObjectPart,
    threeObjectPartToSceneNodePart,
    updateTransform,
} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {Three as THREE} from "@cm/material-nodes/three"
import {TemplateNodePart} from "../services/scene-manager.service"
import {Subscription, combineLatest} from "rxjs"

const css = `
.cm-annotation-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #00000080;
    box-shadow: 0 0 0 2px #ffffff80;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 50%;
}
.cm-annotation-container {
    position: absolute;
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
}
.cm-annotation-container:hover .cm-annotation-label {
    color: #40c4ff;
    box-shadow: 0 0 0 2px #40c4ff;
}`

const style = document.createElement("style")
style.innerHTML = css
document.body.appendChild(style)

export class ThreeAnnotationObject extends THREE.Object3D {
    private readonly labelContainerElement: HTMLDivElement
    private readonly labelElement: HTMLDivElement
    private readonly descriptionElem: HTMLDivElement
    private elementCache = new Map<string, HTMLDivElement>()
    private subscription: Subscription

    constructor(
        protected threeSceneManagerService: ThreeSceneManagerService,
        protected templateNodePart: TemplateNodePart,
        protected concealable: boolean,
    ) {
        super()

        this.labelElement = document.createElement("div")
        this.labelElement.className = "cm-annotation-label"
        this.descriptionElem = document.createElement("div")

        this.labelContainerElement = document.createElement("div")
        this.labelContainerElement.className = "cm-annotation-container"
        this.labelContainerElement.appendChild(this.labelElement)

        this.subscription = combineLatest([
            this.threeSceneManagerService.sceneManagerService.selectedNodeParts$,
            this.threeSceneManagerService.sceneManagerService.hoveredNodePart$,
        ]).subscribe(([selectedNodeParts, hoveredNodePart]) => {
            const outlineObjects = hoveredNodePart ? [hoveredNodePart] : selectedNodeParts

            const highlighted = hoveredNodePart
                ? hoveredNodePart.templateNode === this.templateNodePart.templateNode &&
                  (hoveredNodePart.part === "root" || hoveredNodePart.part === templateNodePart.part)
                : outlineObjects.some((nodePart) => {
                      return this.templateNodePart.templateNode === nodePart.templateNode && this.templateNodePart.part === nodePart.part
                  })

            const selected = selectedNodeParts.some((nodePart) => {
                return this.templateNodePart.templateNode === nodePart.templateNode && this.templateNodePart.part === nodePart.part
            })

            const backgroundColor = highlighted ? "red" : ""
            const pointerEvents = selected ? "none" : "auto"

            if (this.labelElement.style.backgroundColor !== backgroundColor || this.labelContainerElement.style.pointerEvents !== pointerEvents) {
                this.labelElement.style.backgroundColor = backgroundColor
                this.labelContainerElement.style.pointerEvents = pointerEvents
                this.dispose(false)
            }
        })

        //this.labelContainerElement.appendChild(this.descriptionElem)
    }

    isConcealable() {
        return this.concealable
    }

    updateLabels(label: string, description: string) {
        this.labelElement.innerHTML = label
        this.descriptionElem.innerHTML = description
        this.dispose(false)
    }

    getElement(camera: THREE.Camera) {
        const cached = this.elementCache.get(camera.uuid)
        if (cached) return cached
        else {
            const cloned = this.labelContainerElement.cloneNode(true) as HTMLDivElement

            cloned.addEventListener("mousedown", this.onMouseDown)
            cloned.addEventListener("click", this.onClick)

            this.elementCache.set(camera.uuid, cloned)
            return cloned
        }
    }

    private onMouseDown = (event: MouseEvent) => {
        event.stopPropagation()
    }

    private onClick = (event: MouseEvent) => {
        if (this.parent) {
            this.threeSceneManagerService.sceneManagerService.handleClickEvent({
                target: [{templateNodePart: this.templateNodePart}],
                modifiers: {
                    shiftKey: event.shiftKey,
                    ctrlKey: event.ctrlKey,
                },
            })
            event.stopPropagation()
        }
    }

    dispose(final: boolean) {
        for (const element of this.elementCache.values()) {
            element.removeEventListener("mousedown", this.onMouseDown)
            element.removeEventListener("click", this.onClick)
            element.remove()
        }
        this.elementCache.clear()

        if (final) this.subscription.unsubscribe()
    }
}

export class ThreeAnnotation extends ThreeObject<SceneNodes.Annotation> {
    protected override renderObject: ThreeAnnotationObject | undefined

    override setup(sceneNode: SceneNodes.Annotation) {
        if (this.renderObject === undefined) {
            const {sceneManagerService} = this.threeSceneManagerService
            const templateNodePart = sceneManagerService.sceneNodePartToTemplateNodePart({
                sceneNode,
                part: "root",
            })

            if (!templateNodePart) throw new Error("Template node part not found")

            this.renderObject = new ThreeAnnotationObject(this.threeSceneManagerService, templateNodePart, true)
            setThreeObjectPart(this.renderObject, this)
        }

        const renderObject = this.renderObject

        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => {
                    return mathIsEqual(valueA, valueB)
                },
                ({transform}) => {
                    updateTransform(transform, renderObject)
                },
            ),
            objectFieldsDifferent(sceneNode, this.parameters, ["label", "description"], undefined, ({label, description}) => {
                renderObject.updateLabels(label, description)
            }),
        ])
    }

    override dispose() {
        if (this.renderObject) this.renderObject.dispose(true)
    }
}
