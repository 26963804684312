import {ContentTypeModel} from "@api"
import {IMaterial} from "@cm/material-nodes"
import {ApiFields, declareApiModel, EndpointUrls} from "@legacy/api-model/api-model"
import {MakeApiModelExtendedBase} from "@legacy/api-model/api-model-extended"
import {MaterialRevision} from "@legacy/api-model/material-revision"
import {EntityType} from "@legacy/models/entity-type"
import {State} from "@legacy/models/state-labels"
import {Observable, tap} from "rxjs"

export class MaterialState extends State {
    constructor(id: number, label: string, background: string) {
        super(id, label, background)
    }

    static override get(id: number): MaterialState {
        return State.get(id, MaterialStates)
    }
}

export const MaterialStates: Record<string, MaterialState> = {
    Draft: new MaterialState(100, "Draft", "#989898"),
    InfoReview: new MaterialState(300, "Info review", "#989898"),
    Scanning: new MaterialState(400, "Scanning", "#ffab4a"),
    Tiling: new MaterialState(450, "Tiling", "#ffab4a"),
    Shading: new MaterialState(500, "Shading", "#ffab4a"),
    Review: new MaterialState(600, "Review", "#7cbcb0"),
    OnHold: new MaterialState(700, "On hold", "#000000"),
    Completed: new MaterialState(800, "Completed", "#7ec17a"),
}

@declareApiModel(EndpointUrls.materialsUrl, EntityType.Material, ContentTypeModel.Material, false, true)
export class Material extends MakeApiModelExtendedBase<Material>() implements IMaterial {
    @ApiFields.string({name: "name"}) name!: string
    @ApiFields.string({name: "internal_name"}) internalName!: string
    @ApiFields.string({name: "comment"}) comment!: string
    @ApiFields.string({name: "description"}) description!: string
    @ApiFields.string({name: "article_id"}) articleId!: string
    @ApiFields.id({name: "customer"}) customer!: number
    @ApiFields.enumObject({name: "state", model: MaterialState}) state!: MaterialState
    @ApiFields.boolean({name: "public"}) public!: boolean
    @ApiFields.boolean({name: "mesh_specific"}) meshSpecific!: boolean
    @ApiFields.date({name: "sample_arrival"}) sampleArrival!: Date
    @ApiFields.manyRelated({name: "revisions", model: MaterialRevision}) revisions: MaterialRevision[] = []

    getCyclesRevisionIds(): number[] {
        const cyclesRevisionIds: number[] = []

        for (const revision of this.revisions) {
            if (revision.hasCyclesMaterial) {
                cyclesRevisionIds.push(revision.id)
            }
        }

        return cyclesRevisionIds
    }

    getLatestRevision(): MaterialRevision | undefined {
        return this.revisions[0]
    }

    getLatestCyclesRevision(): MaterialRevision | undefined {
        for (const revision of this.revisions) {
            if (revision.hasCyclesMaterial) return revision
        }
        return undefined
    }

    deleteRevision(revision: MaterialRevision): Observable<void> {
        const revisionIndex: number = this.revisions.indexOf(revision)
        return revision.delete().pipe(tap(() => this.revisions.splice(revisionIndex, 1)))
    }
}

MaterialRevision._Material = Material // for breaking circular import
