import {z} from "zod"
import {Context} from "#template-nodes/types"
import {isNodeGraphInstance, NodeParameters} from "@cm/graph/node-graph"
import {VisitorNode} from "@cm/graph/declare-visitor-node"

export type TemplateNode<ParamTypes extends NodeParameters = {}> = VisitorNode<Context, ParamTypes>
export const isTemplateNode = (instance: unknown): instance is TemplateNode => isNodeGraphInstance(instance)
export const templateNode = z.any().superRefine((arg, ctx): arg is TemplateNode => {
    if (!isTemplateNode(arg))
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Expected template node",
            fatal: true,
        })

    return z.NEVER
}) // We have to use .superRefine() because .refine() doesn't abort early https://github.com/colinhacks/zod#abort-early
