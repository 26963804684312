<cm-node-base #nodeBase [typeInfo]="typeInfo" [inputs]="inputs" [outputs]="outputs">
    <ng-container cm-settings>
        @if (thumbnailUrl) {
            <div class="cm-thumbnail-container">
                <img [src]="thumbnailUrl" class="cm-thumbnail" alt="thumbnail" draggable="false" />
                <i #textureIcon class="cm-thumbnail-info far fa-circle-info"></i>
            </div>
        } @else {
            <div class="cm-no-thumbnail">
                <i class="far fa-image-slash"></i>
            </div>
        }
        @if (texture && nodeBase.node.textureRevision !== texture.latestRevision?.legacyId) {
            <div class="cm-revision-update" (click)="nodeBase.node.textureRevision = texture.latestRevision?.legacyId; loadTextureRevision()">
                <i class="far fa-arrow-up"></i>
                Update to v{{ texture.latestRevision?.number }}
            </div>
        }
        @if (textureRevision && textureRevision.state === TextureRevisionState.Draft) {
            <div class="cm-revision-draft-only">
                <i class="far fa-warning"></i>
                Draft only
            </div>
        }
        <cm-input-container>
            <ng-container cm-inline-label> ID:</ng-container>
            <ng-container cm-input>
                <div class="cm-texture-id-input">
                    <cm-numeric-input
                        [value]="nodeBase.node.textureRevision"
                        (mousewheel)="$any($event.target).blur()"
                        (valueChange)="nodeBase.node.textureRevision = $event; loadTextureRevision()"
                        [placeholder]="'Add a texture ID'"
                    >
                    </cm-numeric-input>
                    @if (texture && texture.revisions.length > 1) {
                        <div [matMenuTriggerFor]="revisionMenu" class="cm-texture-revision">
                            v{{ textureRevision?.number }}
                            <i class="fa-regular fa-clock-rotate-left cm-revision-icon"></i>
                        </div>
                        <mat-menu #revisionMenu="matMenu">
                            @for (revision of texture.revisions; track revision) {
                                <button mat-menu-item (click)="nodeBase.node.textureRevision = revision.legacyId; loadTextureRevision()">
                                    v{{ revision.number }}
                                </button>
                            }
                        </mat-menu>
                    }
                </div>
            </ng-container>
        </cm-input-container>
    </ng-container>
</cm-node-base>
<div #textureInfoTemplate id="textureInfo" class="cm-texture-details">
    @if (textureRevision && texture) {
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Type:</div>
            <div class="cm-texture-detail-value">
                {{ Labels.TextureType.get(texture.type)?.label ?? "" }}
            </div>
        </div>
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Width:</div>
            <div class="cm-texture-detail-value">
                {{ textureRevision.width | number: "1.1-3" }}
            </div>
        </div>
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Height:</div>
            <div class="cm-texture-detail-value">
                {{ textureRevision.height | number: "1.1-3" }}
            </div>
        </div>
        <div class="cm-texture-detail">
            <div class="cm-texture-detail-label">Group ID:</div>
            <div class="cm-texture-detail-value">
                {{ textureGroupId }}
            </div>
        </div>
    }
</div>
