<cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
    <cm-list-info cm-list-header [parentList]="this" [count]="totalCount ?? 0" [createItem]="openNewItemDialog.bind(this)"></cm-list-info>
    @for (item of items; track item) {
        @if (item.data; as user) {
            <cm-entity-card @fadeInCard [$entity]="user" [entityLink]="['/users', user.id]" [entityQueryParamsHandling]="'preserve'"></cm-entity-card>
        } @else if (item.placeholder) {
            <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
        } @else if (item.error) {
            <cm-card-error @fadeInPlaceholder></cm-card-error>
        }
    }
</cm-infinite-list>
<ng-template #newItemDialog>
    <div class="cm-new-item-dialog cm-new-user-dialog">
        <h2 mat-dialog-title>New User</h2>
        <mat-dialog-content>
            <form class="cm-new-item-form">
                <mat-form-field appearance="fill">
                    <mat-label>Organizations</mat-label>
                    <mat-select
                        [ngModel]="organizationIds"
                        placeholder="Customer"
                        name="organizationId"
                        [multiple]="true"
                        (ngModelChange)="toggleOrganizationMembership($event)"
                        [disabled]="organizations.$editOptions().length <= 1"
                    >
                        @for (option of organizations.$editOptions(); track option) {
                            <mat-option [value]="option.state">{{ option.label }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <input matInput placeholder="First Name" [(ngModel)]="newItemData.firstName" name="name" />
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <input matInput placeholder="Last Name" [(ngModel)]="newItemData.lastName" name="name" />
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <input matInput placeholder="EMail" [(ngModel)]="newItemData.email" name="name" />
                </mat-form-field>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                mat-button
                [mat-dialog-close]="newItemData"
                [disabled]="!newItemData.memberships?.length || !newItemData.firstName || !newItemData.lastName || !newItemData.email"
            >
                Create
            </button>
        </mat-dialog-actions>
    </div>
</ng-template>
