import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {SolverData} from "#template-nodes/runtime-graph/nodes/solver/data"
import {SolverObjectData} from "#template-nodes/runtime-graph/nodes/solver/object-data"
import {SolverRelationData} from "#template-nodes/runtime-graph/nodes/solver/relation-data"
import {SolverVariableData} from "#template-nodes/runtime-graph/nodes/solver/variable-data"

const TD = TypeDescriptors

const mergeSolverDataDescriptor = {
    input: TD.inlet(TD.Array(TD.Identity<SolverData>())),
    output: TD.outlet(TD.Identity<SolverData>()),
}

export class MergeSolverData implements NodeClassImpl<typeof mergeSolverDataDescriptor, typeof MergeSolverData> {
    static descriptor = mergeSolverDataDescriptor
    static uniqueName = "MergeSolverData"
    input!: Inlet<SolverData[]>
    output!: Outlet<SolverData>

    run() {
        if (this.input === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const objects: SolverObjectData[] = []
        const relations: SolverRelationData[] = []
        const variables: SolverVariableData[] = []
        for (const inp of this.input) {
            if (!inp) continue
            if (inp.objects) {
                for (const x of inp.objects) {
                    if (x) objects.push(x)
                }
            }
            if (inp.relations) {
                for (const x of inp.relations) {
                    if (x) relations.push(x)
                }
            }
            if (inp.variables) {
                for (const x of inp.variables) {
                    if (x) variables.push(x)
                }
            }
        }
        this.output.emitIfChanged({
            objects,
            relations,
            variables,
        })
    }
}
