import {SmoothingIndicatorToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/highpass/toolbox/smoothing-indicator-toolbox-item"
import {HalImage} from "@common/models/hal/hal-image"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorHighpass} from "app/textures/texture-editor/operator-stack/operators/highpass/operator-highpass"
import {DrawableImageBrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/drawable-image-brush-toolbox-item"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {takeUntil} from "rxjs"
import {Vector2} from "@cm/math"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

export class HighpassToolbox extends OperatorToolboxBase<OperatorHighpass> {
    constructor(operator: OperatorHighpass) {
        super(operator, SelectionMode.None, true)

        this.brushToolboxItem = new DrawableImageBrushToolboxItem(this, operator.callback.drawableImageCache)
        this.brushToolboxItem.brushSettings = operator.brushSettings
        this.brushToolboxItem.drawableImageUpdated.subscribe(() => operator.requestEval())
        this.brushToolboxItem.brushStrokeUpdated.subscribe(() => operator.markEdited())
        this.brushToolboxItem.selected = false

        this.smoothingIndicatorToolboxItem = new SmoothingIndicatorToolboxItem(this)
        this.smoothingIndicatorToolboxItem.visible = true

        this.canvasBase.canvasBoundsChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.updateLineGuides())
        this.updateLineGuides()
    }

    setMaskReference(value: DrawableImageRef | undefined) {
        this.brushToolboxItem.selected = value !== undefined
        this.brushToolboxItem.setDrawableImageRef(value, true)
    }

    async setStrokeImage(value: HalImage) {
        await this.brushToolboxItem.setStrokeImage(value)
    }

    async getStrokeImage(): Promise<HalImage> {
        return this.brushToolboxItem.getStrokeImage()
    }

    set showGuides(value: boolean) {
        this.smoothingIndicatorToolboxItem.visible = value
    }

    get showGuides(): boolean {
        return this.smoothingIndicatorToolboxItem.visible
    }

    updateLineGuides(): void {
        const tileBounds = this.getTileBounds()
        this.smoothingIndicatorToolboxItem.position = new Vector2(tileBounds.width / 2, tileBounds.height / 2)
        this.smoothingIndicatorToolboxItem.angle = this.operator.angleOffset
        this.smoothingIndicatorToolboxItem.width = this.operator.useH ? this.operator.smoothingDistanceH : 0
        this.smoothingIndicatorToolboxItem.height = this.operator.useV ? this.operator.smoothingDistanceV : 0
    }

    private smoothingIndicatorToolboxItem: SmoothingIndicatorToolboxItem
    private brushToolboxItem: DrawableImageBrushToolboxItem
}
