import {Vector2, Vector2Like} from "@cm/math"
import {GridInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/grid-interpolator"

export type BoundaryCurveFunction = (t: number) => Vector2

export class TransfiniteInterpolator2D implements GridInterpolator {
    constructor(
        private boundaryCurveTopLeftToRight: BoundaryCurveFunction,
        private boundaryCurveBottomLeftToRight: BoundaryCurveFunction,
        private boundaryCurveLeftTopToBottom: BoundaryCurveFunction,
        private boundaryCurveRightTopToBottom: BoundaryCurveFunction,
    ) {}

    interpolate(uv: Vector2Like): Vector2 {
        const sampledLeft = this.boundaryCurveLeftTopToBottom(uv.y)
        const sampledRight = this.boundaryCurveRightTopToBottom(uv.y)
        const sampledTop = this.boundaryCurveTopLeftToRight(uv.x)
        const sampledBottom = this.boundaryCurveBottomLeftToRight(uv.x)

        const sampledTopLeft = this.boundaryCurveTopLeftToRight(0)
        const sampledTopRight = this.boundaryCurveTopLeftToRight(1)
        const sampledBottomLeft = this.boundaryCurveBottomLeftToRight(0)
        const sampledBottomRight = this.boundaryCurveBottomLeftToRight(1)

        const secondTerm = new Vector2(0, 0)
        secondTerm.addInPlace(sampledTopLeft.mul((1 - uv.x) * (1 - uv.y)))
        secondTerm.addInPlace(sampledTopRight.mul(uv.x * (1 - uv.y)))
        secondTerm.addInPlace(sampledBottomLeft.mul((1 - uv.x) * uv.y))
        secondTerm.addInPlace(sampledBottomRight.mul(uv.x * uv.y))

        const pos = new Vector2(0, 0)
        pos.addInPlace(sampledLeft.mul(1 - uv.x))
        pos.addInPlace(sampledRight.mul(uv.x))
        pos.addInPlace(sampledTop.mul(1 - uv.y))
        pos.addInPlace(sampledBottom.mul(uv.y))
        pos.subInPlace(secondTerm)

        return pos
    }

    solveForUV(position: Vector2Like, initialGuess: Vector2Like = {x: 0, y: 0}): Vector2 | undefined {
        const maxIterations = 100
        const tolerance = 1e-6
        let u = initialGuess.x
        let v = initialGuess.y
        for (let i = 0; i < maxIterations; i++) {
            // Compute the current value of F(u, v)
            const {x: x_uv, y: y_uv} = this.interpolate({x: u, y: v})
            const residual = [x_uv - position.x, y_uv - position.y]
            // Check for convergence
            if (Math.abs(residual[0]) < tolerance && Math.abs(residual[1]) < tolerance) {
                return new Vector2(u, v)
            }
            // Compute the Jacobian matrix
            const J = this.computeJacobian(u, v)
            // Solve the linear system J * delta = -residual
            const delta = this.solveLinearSystem(J, [-residual[0], -residual[1]])
            // Update (u, v)
            u += delta[0]
            v += delta[1]
        }
        return undefined
    }

    private computeJacobian(u: number, v: number, epsilon = 1e-6) {
        // Partial derivatives of x and y with respect to u and v
        const diffX = this.interpolate({x: u + epsilon, y: v})
            .subInPlace(this.interpolate({x: u - epsilon, y: v}))
            .div(2 * epsilon)
        const diffY = this.interpolate({x: u, y: v + epsilon})
            .subInPlace(this.interpolate({x: u, y: v - epsilon}))
            .div(2 * epsilon)
        return [
            [diffX.x, diffY.x],
            [diffX.y, diffY.y],
        ] as const
    }

    private solveLinearSystem(A: readonly [readonly [number, number], readonly [number, number]], b: readonly [number, number]) {
        // Solve the linear system A * x = b using Gaussian elimination.

        // A is a 2x2 matrix, b is a 2x1 vector
        const a11 = A[0][0]
        const a12 = A[0][1]
        let a21 = A[1][0]
        let a22 = A[1][1]
        const b1 = b[0]
        let b2 = b[1]

        // Forward elimination
        const factor = a21 / a11
        a21 -= factor * a11
        a22 -= factor * a12
        b2 -= factor * b1

        // Back substitution
        const v = b2 / a22
        const u = (b1 - a12 * v) / a11

        return [u, v] as const
    }
}
