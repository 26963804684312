import {SolverObjectData} from "#template-nodes/runtime-graph/nodes/solver/object-data"
import {SolverRelationData} from "#template-nodes/runtime-graph/nodes/solver/relation-data"
import {SolverVariableData} from "#template-nodes/runtime-graph/nodes/solver/variable-data"
import {SolverData} from "#template-nodes/runtime-graph/nodes/solver/data"
import {IConnectionSolver} from "#template-nodes/interfaces/connection-solver"

function trackAddRemove<Obj, Id>(objs: Iterable<Obj>, idFn: (obj: Obj) => Id, map: Map<Id, Obj>): [added: Map<Id, Obj>, removed: Map<Id, Obj>] {
    const added = new Map<Id, Obj>()
    const removed = new Map<Id, Obj>(map)
    for (const obj of objs) {
        const id = idFn(obj)
        removed.delete(id)
        const existing = map.get(id)
        if (!existing) {
            added.set(id, obj)
            map.set(id, obj)
        } else {
            map.set(id, {...existing, ...obj})
        }
    }
    for (const [id, obj] of removed) {
        map.delete(id)
    }
    return [added, removed]
}

function keyForRelation(relation: SolverRelationData): string {
    const isSolverVariableData = (x: SolverVariableData | string | number): x is SolverVariableData => typeof x === "object" && "id" in x
    const keyForRelationParams = (params: SolverRelationData["translation"] | SolverRelationData["rotation"]) =>
        JSON.stringify(Object.fromEntries(Object.entries(params).map(([key, value]) => [key, isSolverVariableData(value) ? {id: value.id} : value])))

    const keyForTranslation = keyForRelationParams(relation.translation)
    const keyForRotation = keyForRelationParams(relation.rotation)
    return `${relation.id},${relation.objA.id},${relation.objB.id},${keyForTranslation},${keyForRotation}`
}

export class SolverState {
    private objects = new Map<string, SolverObjectData>()
    private relations = new Map<string, SolverRelationData>()
    private variables = new Map<string, SolverVariableData>()
    private solver: IConnectionSolver | null = null

    update(solver: IConnectionSolver, data: SolverData) {
        const [addedObjects, removedObjects] = trackAddRemove(data.objects, (x) => x.id, this.objects)
        const [addedRelations, removedRelations] = trackAddRemove(data.relations, (x) => keyForRelation(x), this.relations)
        const [addedVariables, removedVariables] = trackAddRemove(data.variables, (x) => x.id, this.variables)

        this.solver = solver

        // console.log("Solver.run");

        for (const [id, rel] of removedRelations) {
            if (rel.data) {
                // console.log("removeRelation", rel.id);
                solver.removeRelation(rel.data)
            }
        }

        for (const [id, obj] of removedObjects) {
            // console.log("removeObject", obj.id);
            solver.removeObject(id)
        }

        for (const [id, obj] of addedObjects) {
            // console.log("addObject", obj.id);
            solver.addObject(id)
        }

        for (const [id, rel] of addedRelations) {
            // console.log("addObjectRelation", rel.id, rel.objA?.id, rel.objB?.id);
            rel.data = solver.addObjectRelation(rel.objA?.id, rel.objB?.id)
            if (!rel.data) {
                this.relations.delete(rel.id) //TODO: hack
            }
        }

        for (const [id, vari] of addedVariables) {
            vari.data = solver.addVariable(vari.id, vari.topology as any)
        }

        for (const [id, vari] of removedVariables) {
            if (vari.data) {
                solver.removeVariable(vari.data)
            }
        }

        for (const [id, vari] of this.variables) {
            if (vari.range) solver.setVariableConstraints(vari.data, vari.range)
            solver.setVariableDefault(vari.data, vari.default, false)
        }

        for (const [id, rel] of this.relations) {
            if (!rel.data) {
                continue
            }
            // console.log("setRelationConstraints", id, rel.translation, rel.rotation);
            const trans = {
                tx: typeof rel.translation.tx === "number" ? rel.translation.tx : this.variables.get(rel.translation.tx.id)?.data,
                ty: typeof rel.translation.ty === "number" ? rel.translation.ty : this.variables.get(rel.translation.ty.id)?.data,
                tz: typeof rel.translation.tz === "number" ? rel.translation.tz : this.variables.get(rel.translation.tz.id)?.data,
            }
            const rot: any = {
                type: rel.rotation.type,
            }
            if (rel.rotation.type === "fixed") {
                rot.type = "fixed"
                rot.rx = rel.rotation.rx
                rot.ry = rel.rotation.ry
                rot.rz = rel.rotation.rz
            } else if (rel.rotation.type === "hinge") {
                rot.axis = rel.rotation.axis
                rot.angleVariable = this.variables.get(rel.rotation.angleVariable.id)?.data
            } else if (rel.rotation.type === "ball") {
                rot.angleVariable = this.variables.get(rel.rotation.angleVariable.id)?.data
            }
            solver.setRelationConstraints(rel.data, trans, rot)
        }
    }

    runSolver(postStep: boolean) {
        const solver = this.solver
        if (!solver) return
        if (!postStep) {
            const rootIds: SolverObjectData["id"][] = []
            for (const [id, obj] of this.objects) {
                if (obj.transformAccessor.checkSolverNeedsUpdate()) {
                    const matrix = obj.transformAccessor.getTransform()
                    if (matrix) {
                        solver.setObjectMatrix(obj.id, matrix)
                    }
                    solver.setObjectActive(obj.id, obj.transformAccessor.controlledBySolver, obj.transformAccessor.controlledBySolver)
                    if (!obj.transformAccessor.controlledBySolver) {
                        rootIds.push(obj.id)
                    }
                }
            }
            if (rootIds.length > 0) {
                solver.propagateFixedRelations(rootIds)
            }
        } else {
            for (const [id, obj] of this.objects) {
                if (obj.transformAccessor.controlledBySolver) {
                    const matrix = solver.getObjectMatrix(obj.id)
                    if (matrix) {
                        obj.transformAccessor.setTransform(matrix)
                    }
                }
            }
        }
    }
}
