import {MaterialAssetsRenderingMaterialFragment} from "@api"
import {SceneManager} from "@app/templates/template-system/scene-manager"
import {Matrix4, Vector3} from "@cm/math"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {buildRenderGraph} from "@cm/template-nodes/render-graph/scene-graph-to-render-graph"
import {firstValueFrom} from "rxjs"
import {RenderNodes} from "@cm/render-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {SdkService} from "@common/services/sdk/sdk.service"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {APIService} from "@legacy/services/api/api.service"
import {DataObjectBatchApiCallService} from "@app/templates/template-system/data-object-batch-api-call.service"
import {TemplateRevisionBatchApiCallService} from "@app/templates/template-system/template-revision-batch-api-call.service"
import {cameraLookAt} from "@cm/template-nodes/utils/camera-utils"
import {SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {mapLegacyDataObjectToImageResource} from "@app/templates/legacy/material-node-graph"

export const MAX_PIXEL_PER_MM = 32.9
export const CM_TO_M = 0.01
export const CM_TO_MM = 10
export const CM_TO_INCH = 0.393701

type NonNullableOverRootFields<T extends {[key: string]: unknown}> = {[K in keyof T]: NonNullable<T[K]>}

export type LegacyId = number
export type MaterialDetails = NonNullableOverRootFields<Required<Omit<MaterialAssetsRenderingMaterialFragment, "__typename">>>
export type JobGraphMaterialDetails = Omit<MaterialDetails, "latestCyclesRevision">

export async function renderGraphForTemplateGraph(
    graph: Nodes.TemplateGraph,
    webAssemblyWorkerService: WebAssemblyWorkerService,
    sdkService: SdkService,
    refreshService: RefreshService,
    materialGraphService: MaterialGraphService,
    meshDataBatchApiCallService: MeshDataBatchApiCallService,
    dataObjectBatchApiCallService: DataObjectBatchApiCallService,
    templateRevisionBatchApiCallService: TemplateRevisionBatchApiCallService,
    legacyApi: APIService,
    useGpu: boolean = false,
    useCloud: boolean = false,
): Promise<RenderNodes.Render> {
    const sceneManager = new SceneManager(
        sdkService,
        refreshService,
        webAssemblyWorkerService,
        materialGraphService,
        false,
        meshDataBatchApiCallService,
        dataObjectBatchApiCallService,
        templateRevisionBatchApiCallService,
        legacyApi,
    )

    const rootNode: Nodes.TemplateInstance = {
        name: "Root Template",
        id: "root",
        type: "templateInstance",
        template: graph,
        lockedTransform: Matrix4.identity().toArray(),
    }

    sceneManager.updateRoot(rootNode)
    await firstValueFrom(sceneManager.sync(false))

    // material asset rendering is still using the old template system which does not have gpu flag in the render node
    // for now, set the gpu flag in the corresponding scene node instead
    // TODO clean this up, once the asset rendering also updated to use the new template system
    const sceneNodes = sceneManager.getAllSceneNodes()
    const renderNode = sceneNodes.find(SceneNodes.RenderSettings.is)
    if (!renderNode) throw Error("Cannot find render node")
    renderNode.gpu = useGpu
    renderNode.cloud = useCloud
    const resolveFns = {
        getHdriDataObjectIdDetails: async (hdriIdDetails: {legacyId: number}): Promise<{legacyId: number}> => {
            const dataObjectIdDetails = (await sdkService.gql.hdriForRendering(hdriIdDetails)).hdri.dataObject
            if (!dataObjectIdDetails) throw Error("Failed to query hdri data object id details")
            return dataObjectIdDetails
        },
        mapLegacyDataObjectToImageResource,
    }
    return buildRenderGraph({nodes: sceneNodes, final: true, verifySchema: true, passes: ["Combined"]}, resolveFns)
}

export function setupTemplateGraphForPlaneRender(
    materialRevisionId: LegacyId,
    resolutionX: number,
    resolutionY: number,
    samples: number,
    planeX: number,
    planeY: number,
    fieldOfView: number,
    sensorSize: number,
    matOffsetX = 0,
    matOffsetY = 0,
    matRot = 180,
): Nodes.TemplateGraph {
    const focalLength = getFocalLengthFromFOVAndSensorSize(fieldOfView, sensorSize)
    const cameraDistanceToPlane = (focalLength / sensorSize) * Math.max(planeX, planeY)
    // console.log("cameraDistanceToPlane: ", cameraDistanceToPlane);

    const materialNode: Nodes.MaterialReference = {
        name: "matName",
        type: "materialReference",
        materialRevisionId,
    }

    const plane_padding = 20.0
    const lightScale = 100

    return {
        type: "templateGraph",
        schema: Nodes.currentTemplateGraphSchema,
        name: "Untitled Scene",
        nodes: [
            {
                type: "proceduralMesh",
                name: "Studio",
                materialAssignments: {
                    "0": {
                        node: materialNode,
                        horizontalOffset: matOffsetX,
                        verticalOffset: matOffsetY,
                        rotation: matRot,
                        side: "back",
                    },
                },
                materialSlotNames: {
                    "0": "Floor",
                },
                surfaces: [],
                parameters: {
                    width: (planeX + plane_padding) * CM_TO_M,
                    length: (planeY + plane_padding) * CM_TO_M,
                    height: 1,
                    showFloor: true,
                    showWalls: false,
                    showCeiling: false,
                },
                geometryGraph: "simpleStudioRoom",
                lockedTransform: Matrix4.rotationX(90).multiply(Matrix4.rotationZ(180)).toArray(),
            } as Nodes.ProceduralMesh,
            {
                type: "sceneProperties",
                backgroundColor: [1, 1, 1],
                maxSubdivisionLevel: 1,
                uiStyle: "default",
                uiColor: [0, 0, 0],
                textureResolution: "2000px",
                showAnnotations: true,
            } as Nodes.SceneProperties,
            {
                type: "camera",
                name: "Main Camera",
                resolutionX: resolutionX,
                resolutionY: resolutionY,
                lockedTransform: cameraLookAt(new Vector3(0, 0, cameraDistanceToPlane), new Vector3(0, 0, 0)).toArray(),
                target: [0, 0, 0],
                filmGauge: sensorSize,
                focalLength: focalLength,
                focalDistance: cameraDistanceToPlane,
                fStop: 16,
                exposure: 1,
                shiftX: 0,
                shiftY: 0,
                automaticVerticalTilt: false,
                enablePanning: true,
                screenSpacePanning: true,
                minDistance: 0,
                maxDistance: 10000,
                minPolarAngle: 0,
                maxPolarAngle: 180,
                minAzimuthAngle: -Infinity,
                maxAzimuthAngle: Infinity,
            } as Nodes.Camera,
            {
                type: "areaLight",
                name: "Area Light",
                intensity: 35,
                width: 600 * lightScale,
                height: 100 * lightScale,
                color: [1, 1, 1],
                lockedTransform: cameraLookAt(new Vector3(3000 * lightScale, 0, 1500 * lightScale), new Vector3(0, 0, 0)).toArray(),
                target: [0, 0, 0],
                on: true,
                directionality: 0.6,
                visibleDirectly: false,
                visibleInReflections: true,
                visibleInRefractions: true,
            } as Nodes.AreaLight,
            {
                type: "areaLight",
                name: "Area Light",
                intensity: 0.01,
                width: lightScale,
                height: lightScale,
                color: [1, 1, 1],
                lockedTransform: cameraLookAt(new Vector3(0, 540 * lightScale, 150 * lightScale), new Vector3(0, 0, 0)).toArray(),
                target: [0, 0, 0],
                on: true,
                directionality: 0.6,
                visibleDirectly: false,
                visibleInReflections: true,
                visibleInRefractions: true,
            } as Nodes.AreaLight,
            {
                type: "render",
                width: resolutionX,
                height: resolutionY,
                samples: samples,
            } as Nodes.Render,
        ],
    } as Nodes.TemplateGraph
}

export function getFocalLengthFromFOVAndSensorSize(FOVInDeg: number, sensorSize: number): number {
    return sensorSize / (2 * Math.atan(((FOVInDeg / 2) * Math.PI) / 180))
}
