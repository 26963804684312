import {castToUint8Array} from "#utils/misc"

export function toBase64(data: ArrayBuffer | Uint32Array | Float32Array): string {
    const byteBuffer = castToUint8Array(data)
    let binaryString = ""
    for (let i = 0; i < byteBuffer.length; i++) {
        binaryString += String.fromCharCode(byteBuffer[i])
    }
    return btoa(binaryString)
}

export function fromBase64(data: string): ArrayBuffer {
    const binaryString = atob(data)
    const uint8Array = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i)
    }
    return uint8Array.buffer
}
