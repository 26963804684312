import {Component, computed, AfterViewInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatIcon} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, JobTaskDetailsFragment, MutationUpdateJobTaskInput} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {IsLoadingDirective} from "@common/directives"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {CopyValueToClipboardMenuComponent} from "@platform/components/shared/copy-value-to-clipboard-menu/copy-value-to-clipboard-menu.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {switchMap, of, tap, from} from "rxjs"
import {IsDefined} from "@cm/utils"
import {formatRenderJobTaskClaimedByField, isClaimedRenderJobTask} from "@app/platform/models/job-task/render-job-task"

@Component({
    selector: "cm-job-task-details",
    templateUrl: "job-task-details.component.html",
    styleUrls: ["job-task-details.component.scss"],
    standalone: true,
    imports: [
        DetailsDialogLayoutComponent,
        SectionComponent,
        MatInputModule,
        MatSelectModule,
        IsLoadingDirective,
        FormsModule,
        MatCheckboxModule,
        MatButtonModule,
        RoutedDialogComponent,
        MatIcon,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        FilesSectionComponent,
        TitleSectionComponent,
        CopyValueToClipboardMenuComponent,
        OrganizationSelectComponent,
    ],
})
export class JobTaskDetailsComponent extends BaseDetailsComponent<JobTaskDetailsFragment, Omit<MutationUpdateJobTaskInput, "id">> implements AfterViewInit {
    override _contentTypeModel = ContentTypeModel.JobTask
    override _fetchItem = this.sdk.gql.jobTaskDetails
    override _updateItem = this.sdk.gql.updateJobTaskDetails

    override paramName = "jobTaskId"
    override displayName = "Job Task"

    $copyItems = computed(() => {
        const result: {value: string | undefined | (() => string | undefined); displayText: string; icon: string}[] = [
            {value: this.$item()?.legacyId?.toString(), displayText: "Legacy ID", icon: "elderly"},
            {value: this.$item()?.id, displayText: "ID", icon: "badge"},
        ]
        return result
    })

    async closeDialog() {
        const jobLegacyId = this.$item()?.job?.legacyId
        if (jobLegacyId) {
            await this.router.navigate(["jobs", jobLegacyId], {queryParamsHandling: "preserve"})
        }
    }

    formattedClaimedByField: string | undefined | null = undefined
    ngAfterViewInit() {
        this.item$
            .pipe(
                switchMap((item) => (IsDefined(item) && isClaimedRenderJobTask(item) ? from(formatRenderJobTaskClaimedByField(item, this.sdk)) : of(item))),
                tap((maybeItem) => {
                    this.formattedClaimedByField = maybeItem?.claimedBy ?? null
                    this.changeDetectorRef.detectChanges()
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe()
    }

    protected readonly DialogSize = DialogSize
    protected readonly ContentTypeModel = ContentTypeModel
}
