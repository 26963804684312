import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const tapDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    fn: TD.inlet(TD.Identity<(x: any) => void>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class Tap<T> implements NodeClassImpl<typeof tapDescriptor, typeof Tap> {
    static descriptor = tapDescriptor
    static uniqueName = "Tap"
    input!: Inlet<T>
    fn!: Inlet<(x: T | typeof NotReady) => void>
    output!: Outlet<T>

    run() {
        if (this.fn !== NotReady) {
            this.fn(this.input)
        }
        this.output.emit(this.input)
    }
}
