import {Component, input, output} from "@angular/core"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"

@Component({
    selector: "cm-title-section",
    standalone: true,
    imports: [PlaceholderComponent],
    templateUrl: "./title-section.component.html",
    styleUrl: "./title-section.component.scss",
})
export class TitleSectionComponent {
    $iconClass = input<string | null>("far fa-info-circle")
    $title = input<string | null | undefined>(undefined)
    $isLoaded = input<boolean>(false)
    $canEdit = input<boolean>(false)
    $placeholder = input<string>("(name)")
    onChanged = output<string>()
    onClosed = output<void>()
}
