import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeRGBColorNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({value: materialSlots})
const InputTypeSchema = z.object({color: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional()})

export class RGBToBW extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const colorValue = (await get(inputs.color)) ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})

            return {value: THREENodes.luminance(THREENodes.vec3(colorValue), THREENodes.lumaCoeffs)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
