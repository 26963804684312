import {TransientDataObject as LegacyTransientDataObject} from "@legacy/api-model/data-object"
import {TransientDataObject} from "@app/common/helpers/transient-data-object/transient-data-object"
import {mapRestToGqlImageColorSpace} from "@legacy/helpers/image-color-space"
import {mapRestToGqlImageDataType} from "@legacy/helpers/image-data-type"
import {MediaTypeSchema} from "@cm/utils/data-object"

export function mapLegacyTransientDataObject(legacyObject: LegacyTransientDataObject): TransientDataObject {
    if (!legacyObject.imageColorSpace) throw Error("Missing color space information!")
    const imageColorSpace = mapRestToGqlImageColorSpace(legacyObject.imageColorSpace)
    const parsedMediaType = MediaTypeSchema.safeParse(legacyObject.contentType)
    if (!parsedMediaType.success) throw Error(`Invalid media/content type: ${legacyObject.contentType}`)

    return new TransientDataObject({
        data: legacyObject.data,
        imageColorSpace,
        mediaType: parsedMediaType.data,
        imageColorProfile: legacyObject.imageColorProfile,
        imageDataType: legacyObject.imageDataType ? mapRestToGqlImageDataType(legacyObject.imageDataType) : undefined,
    })
}
