import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {RgbToBwInputs, RgbToBwOutputs} from "@material-editor/models/nodes/rgb-to-bw"

@Component({
    selector: "cm-rgb-to-bw-node",
    templateUrl: "./rgb-to-bw-node.component.html",
    styleUrls: ["./rgb-to-bw-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class RgbToBwNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = RgbToBwInputs
    outputs = RgbToBwOutputs
    typeInfo = RgbToBwNodeType
}

export const RgbToBwNodeType: MaterialNodeType<typeof RgbToBwNodeComponent> = {
    id: "texGradient",
    label: "RGB to BW",
    color: "#4987af",
    name: "ShaderNodeRGBToBW",
    inputs: [RgbToBwInputs.image] as never[],
    outputs: [RgbToBwOutputs.val],
    component: RgbToBwNodeComponent,
}
