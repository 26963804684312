import {Type} from "@angular/core"
import {CombineRgbNodeComponent} from "@material-editor/components/nodes/combine-rgb-node/combine-rgb-node.component"
import {
    BrightContrastNodeComponent,
    CombineXYZNodeComponent,
    DisplacementNodeComponent,
    FresnelNodeComponent,
    GammaNodeComponent,
    HsvNodeComponent,
    ImageTextureNodeComponent,
    ImageTextureSetNodeComponent,
    InvertNodeComponent,
    MappingNodeComponent,
    MathNodeComponent,
    NormalMapNodeComponent,
    OutputNodeComponent,
    PrincipledBsdfNodeComponent,
    RgbCurvesNodeComponent,
    RgbMixNodeComponent,
    RgbNodeComponent,
    SetImageTextureNodeComponent,
    SeparateRgbNodeComponent,
    SeparateXyzNodeComponent,
    TangentNodeComponent,
    UvMapNodeComponent,
    ValueNodeComponent,
    VectorMathNodeComponent,
    EmissionNodeComponent,
    TranslucentBsdfNodeComponent,
    AddShaderNodeComponent,
    ScannedTransmissionNodeComponent,
} from "@material-editor/components/nodes"

export type MaterialNodeComponent =
    | MathNodeComponent
    | ImageTextureNodeComponent
    | ImageTextureSetNodeComponent
    | MappingNodeComponent
    | CombineRgbNodeComponent
    | NormalMapNodeComponent
    | OutputNodeComponent
    | UvMapNodeComponent
    | PrincipledBsdfNodeComponent
    | RgbCurvesNodeComponent
    | SetImageTextureNodeComponent
    | SeparateRgbNodeComponent
    | SeparateXyzNodeComponent
    | TangentNodeComponent
    | ValueNodeComponent
    | HsvNodeComponent
    | RgbNodeComponent
    | RgbMixNodeComponent
    | VectorMathNodeComponent
    | GammaNodeComponent
    | DisplacementNodeComponent
    | BrightContrastNodeComponent
    | FresnelNodeComponent
    | InvertNodeComponent
    | CombineXYZNodeComponent
    | EmissionNodeComponent
    | TranslucentBsdfNodeComponent
    | AddShaderNodeComponent

import {
    BrightContrastNodeType,
    CombineXYZNodeType,
    DisplacementNodeType,
    FresnelNodeType,
    GammaNodeType,
    HsvNodeType,
    ImageTextureNodeType,
    ImageTextureSetNodeType,
    InvertNodeType,
    MappingNodeType,
    MathNodeType,
    NormalMapNodeType,
    OutputNodeType,
    PrincipledBsdfNodeType,
    RgbCurvesNodeType,
    RgbMixNodeType,
    RgbNodeType,
    SetImageTextureNodeType,
    SeparateRgbNodeType,
    SeparateXyzNodeType,
    TangentNodeType,
    UVMapNodeType,
    ValueNodeType,
    VectorMathNodeType,
    EmissionNodeType,
    TranslucentBsdfNodeType,
    AddShaderNodeType,
    ScannedTransmissionNodeType,
} from "@material-editor/components/nodes"
import {CombineRGBNodeType} from "@material-editor/components/nodes/combine-rgb-node/combine-rgb-node.component"
import {MaterialEnumSetting, MaterialNodeTypeId, MaterialSocket} from "@material-editor/models/material-socket"

import {NodeTypeInfo} from "@node-editor/models/node-type-info"
import {TransparentBsdfNodeComponent, TransparentBsdfNodeType} from "@material-editor/components/nodes/transparent-bsdf/transparent-bsdf.component"
import {MixShaderNodeComponent, MixShaderNodeType} from "@material-editor/components/nodes/mix-shader/mix-shader.component"
import {LightPathNodeComponent, LightPathNodeType} from "@material-editor/components/nodes/light-path-node/light-path-node.component"
import {ValToRgbNodeComponent, ValToRgbNodeType} from "@material-editor/components/nodes/val-to-rgb/val-to-rgb.component"
import {TexNoiseNodeComponent, TexNoiseNodeType} from "@material-editor/components/nodes/tex-noise/tex-noise.component"
import {BumpNodeComponent, BumpNodeType} from "@material-editor/components/nodes/bump-node/bump-node.component"
import {
    AmbientOcclusionNodeComponent,
    AmbientOcclusionNodeType,
} from "@material-editor/components/nodes/ambient-occlusion-node/ambient-occlusion-node.component"
import {TexGradientNodeComponent, TexGradientNodeType} from "@material-editor/components/nodes/tex-gradient-node/tex-gradient-node.component"
import {RgbToBwNodeComponent, RgbToBwNodeType} from "@material-editor/components/nodes/rgb-to-bw-node/rgb-to-bw-node.component"
import {ShaderToRgbNodeComponent, ShaderToRgbNodeType} from "@material-editor/components/nodes/shader-to-rgb/shader-to-rgb.component"
import {SeparateHsvNodeComponent, SeparateHsvNodeType} from "@material-editor/components/nodes/separate-hsv-node/separate-hsv-node.component"
import {DiffuseBsdfNodeComponent, DiffuseBsdfNodeType} from "@material-editor/components/nodes/diffuse-bsdf-node/diffuse-bsdf-node.component"
import {CombineHsvNodeComponent, CombineHsvNodeType} from "@material-editor/components/nodes/combine-hsv-node/combine-hsv-node.component"
import {TexVoronoiNodeComponent, TexVoronoiNodeType} from "@material-editor/components/nodes/tex-voronoi-node/tex-voronoi-node.component"
import {TexCoordNodeComponent, TexCoordNodeType} from "@material-editor/components/nodes/tex-coord-node/tex-coord-node.component"
import {LayerWeightNodeComponent, LayerWeightNodeType} from "@material-editor/components/nodes/layer-weight-node/layer-weight-node.component"

export interface MaterialNodeType extends NodeTypeInfo {
    id: MaterialNodeTypeId
    name: string
    inputs: MaterialSocket[]
    outputs: MaterialSocket[]
    settings?: MaterialEnumSetting[]
    component?: Type<
        | AddShaderNodeComponent
        | AmbientOcclusionNodeComponent
        | BrightContrastNodeComponent
        | BumpNodeComponent
        | CombineHsvNodeComponent
        | CombineRgbNodeComponent
        | CombineXYZNodeComponent
        | DiffuseBsdfNodeComponent
        | DisplacementNodeComponent
        | EmissionNodeComponent
        | FresnelNodeComponent
        | GammaNodeComponent
        | HsvNodeComponent
        | ImageTextureNodeComponent
        | ImageTextureSetNodeComponent
        | InvertNodeComponent
        | LayerWeightNodeComponent
        | LightPathNodeComponent
        | MappingNodeComponent
        | MathNodeComponent
        | MixShaderNodeComponent
        | NormalMapNodeComponent
        | OutputNodeComponent
        | PrincipledBsdfNodeComponent
        | RgbCurvesNodeComponent
        | RgbMixNodeComponent
        | RgbNodeComponent
        | RgbToBwNodeComponent
        | ScannedTransmissionNodeComponent
        | SeparateHsvNodeComponent
        | SeparateRgbNodeComponent
        | SeparateXyzNodeComponent
        | SetImageTextureNodeComponent
        | ShaderToRgbNodeComponent
        | TangentNodeComponent
        | TexCoordNodeComponent
        | TexGradientNodeComponent
        | TexNoiseNodeComponent
        | TexVoronoiNodeComponent
        | TranslucentBsdfNodeComponent
        | TransparentBsdfNodeComponent
        | UvMapNodeComponent
        | ValToRgbNodeComponent
        | ValueNodeComponent
        | VectorMathNodeComponent
    >
}

export const MaterialNodeTypes: MaterialNodeType[] = [
    AddShaderNodeType,
    AmbientOcclusionNodeType,
    BrightContrastNodeType,
    BumpNodeType,
    CombineHsvNodeType,
    CombineRGBNodeType,
    CombineXYZNodeType,
    DiffuseBsdfNodeType,
    DisplacementNodeType,
    EmissionNodeType,
    FresnelNodeType,
    GammaNodeType,
    HsvNodeType,
    ImageTextureNodeType,
    ImageTextureSetNodeType,
    InvertNodeType,
    LayerWeightNodeType,
    LightPathNodeType,
    MappingNodeType,
    MathNodeType,
    MixShaderNodeType,
    NormalMapNodeType,
    OutputNodeType,
    PrincipledBsdfNodeType,
    RgbCurvesNodeType,
    RgbMixNodeType,
    RgbNodeType,
    RgbToBwNodeType,
    ScannedTransmissionNodeType,
    SeparateHsvNodeType,
    SeparateRgbNodeType,
    SeparateXyzNodeType,
    SetImageTextureNodeType,
    ShaderToRgbNodeType,
    ValToRgbNodeType,
    TangentNodeType,
    TexCoordNodeType,
    TexGradientNodeType,
    TexNoiseNodeType,
    TexVoronoiNodeType,
    TranslucentBsdfNodeType,
    TransparentBsdfNodeType,
    UVMapNodeType,
    ValueNodeType,
    VectorMathNodeType,
]

// material node types available when adding a new node in the material editor
export const ImplementedMaterialNodeTypes: MaterialNodeType[] = [
    AddShaderNodeType,
    // AmbientOcclusionNodeType,
    BrightContrastNodeType,
    BumpNodeType,
    CombineHsvNodeType,
    CombineRGBNodeType,
    CombineXYZNodeType,
    DiffuseBsdfNodeType,
    // not implemented in three.js
    // DisplacementNodeType,
    EmissionNodeType,
    FresnelNodeType,
    GammaNodeType,
    HsvNodeType,
    // ImageTextureNodeType,
    ImageTextureSetNodeType,
    InvertNodeType,
    // LayerWeightNodeType,
    LightPathNodeType,
    MappingNodeType,
    MathNodeType,
    MixShaderNodeType,
    NormalMapNodeType,
    OutputNodeType,
    PrincipledBsdfNodeType,
    RgbCurvesNodeType,
    RgbMixNodeType,
    RgbNodeType,
    RgbToBwNodeType,
    ScannedTransmissionNodeType,
    SeparateHsvNodeType,
    SeparateRgbNodeType,
    SeparateXyzNodeType,
    SetImageTextureNodeType,
    // ShaderToRgbNodeType,
    ValToRgbNodeType,
    TangentNodeType,
    TexCoordNodeType,
    TexGradientNodeType,
    TexNoiseNodeType,
    TexVoronoiNodeType,
    TranslucentBsdfNodeType,
    TransparentBsdfNodeType,
    UVMapNodeType,
    ValueNodeType,
    VectorMathNodeType,
]
