<cm-sidebar-layout>
    <cm-item-filters cm-filters>
        <cm-tag-search-filter
            placeholder="Name, description, ..."
            [selectableTagTypes]="[Enums.TagType.Production, Enums.TagType.ProductRange, Enums.TagType.ProductCategory]"
        ></cm-tag-search-filter>
        @if ($can().read.filters("additional")) {
            <cm-pinned-filter label="Pinned">
                <cm-pinned-filter-option>Clear filters</cm-pinned-filter-option>
                <cm-pinned-filter-option [$queryParams]="{modelState: Enums.InPipelineStates.Model, nextActor: Enums.NextActors.ProductionInProgress}">
                    In progress
                </cm-pinned-filter-option>
                <cm-pinned-filter-option [$queryParams]="{modelState: Enums.InPipelineStates.Model, nextActor: Enums.NextActors.ProductionWaitingForFeedback}">
                    Waiting for feedback
                </cm-pinned-filter-option>
            </cm-pinned-filter>
            <cm-checkboxes-filter label="Up Next" name="nextActor" [options]="Labels.NextActor"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Availability" name="hasAssignedUser" [options]="Labels.Assigned"></cm-checkboxes-filter>
        }
        <cm-checkboxes-filter label="State" name="modelState" [options]="Labels.ModelState"></cm-checkboxes-filter>
        @if ($can().read.filters("additional")) {
            <cm-checkboxes-filter label="User" name="userId" [options]="userLabels"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Payment State" name="paymentState" [options]="Labels.PaymentState"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Visibility" name="public" [options]="Labels.Public"></cm-checkboxes-filter>
        }
    </cm-item-filters>
    <cm-models-grid></cm-models-grid>
</cm-sidebar-layout>
<router-outlet></router-outlet>
