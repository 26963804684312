import {Component, input, output} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"

type ItemT = {description?: string | null}

@Component({
    selector: "cm-sidebar-description-section",
    standalone: true,
    imports: [InputContainerComponent, NativeInputTextAreaComponent],
    templateUrl: "./sidebar-description-section.component.html",
    styleUrl: "./sidebar-description-section.component.scss",
})
export class SidebarDescriptionSectionComponent {
    $item = input<ItemT>()
    $label = input<string>("Description:")
    $minRows = input<number>(2)
    $canEdit = input<boolean>(false)
    $onChanged = output<ItemT>()
}
