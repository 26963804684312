import {Matrix4, Vector3} from "@cm/math"
import {disposeMaterialAndTextures, IScene} from "@editor/helpers/scene/three-proxies/utils"
import {BehaviorSubject} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {SceneNodes, ToneMappingData} from "@cm/template-nodes"
import {deepEqual} from "@cm/utils"
import {ThreeObjectBase} from "@editor/helpers/scene/three-proxies/utils"
import {fromThreeVector} from "@template-editor/helpers/three-utils"

const MIN_NEAR_CLIP = 1
const MAX_FAR_CLIP = 20000

export class ThreeCamera extends ThreeObjectBase {
    _shiftX = 0.0
    _shiftY = 0.0
    _focalLength = 50.0
    _focalDistance = 50.0
    _fStop = 512
    _filmGauge = 0.0
    _aspectRatio: number | undefined
    _target = new Vector3(0, 0, 0)
    _nearClip = 1
    _farClip?: number
    _exposure?: number
    _toneMapping?: ToneMappingData
    _depthOfField?: {apertureSize: number; focusDistance: number}
    node?: SceneNodes.Camera

    threeCamera = new THREE.PerspectiveCamera(50, 1, MIN_NEAR_CLIP, MAX_FAR_CLIP)

    threeObject: THREE.Group
    override threeHelperObject?: ThreeProxyCameraHelper | null

    positionAndTarget$ = new BehaviorSubject<[Vector3 | undefined, Vector3 | undefined]>([undefined, undefined])
    aspectRatio$ = new BehaviorSubject<number | undefined>(undefined)

    constructor(scene: IScene) {
        super(scene)
        this.threeObject = new THREE.Group()

        this.threeCamera.layers.set(0)

        this.threeHelperObject = scene.config.editMode ? new ThreeProxyCameraHelper(this) : null
    }

    update(camera: SceneNodes.Camera) {
        this.node = camera

        let updateHelper = false
        let updatePositionAndTarget = false
        let updateScene = false

        if (camera.focalDistance !== this._focalDistance || camera.fStop !== this._fStop || camera.focalLength !== this._focalLength) {
            this._focalDistance = camera.focalDistance
            this._fStop = camera.fStop
            // don't update this._focalLength here, it is checked below
            this._depthOfField = {
                apertureSize: (camera.focalLength * 0.1) / camera.fStop,
                focusDistance: camera.target.sub(camera.transform.getTranslation()).norm(),
            }
            updateHelper = true
            updateScene = true
        }

        if (
            camera.shiftX !== this._shiftX ||
            camera.shiftY !== this._shiftY ||
            camera.focalLength !== this._focalLength ||
            camera.focalDistance !== this._focalDistance ||
            camera.filmGauge !== this._filmGauge ||
            camera.nearClip !== this._nearClip ||
            camera.farClip !== this._farClip ||
            camera.aspectRatio !== this._aspectRatio
        ) {
            this._shiftX = camera.shiftX
            this._shiftY = camera.shiftY
            this._focalLength = camera.focalLength
            this._focalDistance = camera.focalDistance
            this._filmGauge = camera.filmGauge
            this._nearClip = camera.nearClip!
            this._farClip = camera.farClip
            this.threeCamera.near = Math.max(MIN_NEAR_CLIP, camera.nearClip ?? MIN_NEAR_CLIP)
            this.threeCamera.far = Math.min(MAX_FAR_CLIP, camera.farClip ?? MAX_FAR_CLIP)
            this._aspectRatio = camera.aspectRatio
            this.updateCameraMatrix()
            updateScene = true
            updateHelper = true
            if (this._aspectRatio !== this.aspectRatio$.value) {
                this.aspectRatio$.next(this._aspectRatio)
            }
        }
        if (!camera.target.equals(this._target)) {
            if (!(camera.target instanceof Vector3)) throw new Error("camera.target is not a Vector3")
            this._target = camera.target
            updateHelper = true
            updatePositionAndTarget = true
        }
        if (!(camera.transform instanceof Matrix4)) throw new Error("camera.transform is not a Matrix4")
        if (this.updateTransform(camera.transform)) {
            updateHelper = true
            updatePositionAndTarget = true
        }
        this.topLevelObjectId = camera.topLevelObjectId

        if (camera.exposure !== this._exposure) {
            this._exposure = camera.exposure
            updateScene = true
        }

        if (!deepEqual(camera.toneMapping, this._toneMapping)) {
            this._toneMapping = camera.toneMapping && {...camera.toneMapping} //TODO: use deepCopy when all pending PRs are merged
            updateScene = true
        }

        if (updateHelper) {
            this.updateHelper()
        }
        if (updatePositionAndTarget) {
            const val = [fromThreeVector(this.threeObject.position), this._target]
            this.positionAndTarget$.next(val as any)
        }
        if (updateScene) {
            this.scene.update()
        }
    }

    override updateTransform(transform: Matrix4): boolean {
        if (super.updateTransform(transform)) {
            this.threeCamera.matrix.copy(this.threeObject.matrix)
            this.threeCamera.matrix.decompose(this.threeCamera.position, this.threeCamera.quaternion, this.threeCamera.scale)
            this.threeCamera.updateMatrixWorld() // needed if camera has no parent
            this.scene.update()
            return true
        } else {
            return false
        }
    }

    override getOutlineTokens(materialSlot: number | null): any[] {
        if (!this.threeHelperObject) {
            return []
        } else if (materialSlot === 1) {
            return [this.threeHelperObject.targetCube]
        } else {
            return [this.threeHelperObject.localMesh]
        }
    }

    updateCameraMatrix(aspect?: number) {
        aspect ??= this._aspectRatio ?? 1
        const filmGauge = this._filmGauge
        const focalLength = this._focalLength
        const shiftX = this._shiftX
        const shiftY = this._shiftY
        this.threeCamera.clearViewOffset()
        this.threeCamera.aspect = aspect
        this.threeCamera.filmGauge = filmGauge
        this.threeCamera.setFocalLength(focalLength)
        if (aspect > 1.0) {
            this.threeCamera.setViewOffset(1, 1 / aspect, shiftX, -shiftY / aspect, 1, 1 / aspect)
        } else {
            this.threeCamera.setViewOffset(aspect, 1, shiftX * aspect, -shiftY, aspect, 1)
        }
        this.threeCamera.updateProjectionMatrix()
    }

    private updateHelper() {
        if (this.threeHelperObject) {
            this.threeHelperObject.update()
        }
    }

    override showEditHelpers(show: boolean) {
        this.threeHelperObject?.showEditHelpers(show)
    }
}

class ThreeProxyCameraHelper extends THREE.Object3D {
    private camera: ThreeCamera

    private globalMesh: THREE.Group
    readonly localMesh: THREE.Group

    private targetCubeGeometry: THREE.BufferGeometry
    targetCube: THREE.Object3D
    private materialTargetCube1 = new THREE.MeshBasicMaterial({color: 0xff0000})
    private materialTargetCube2 = new THREE.MeshBasicMaterial({color: 0xff0000, transparent: true, opacity: 0.2, depthTest: false, depthWrite: false})

    private bufferPositionStand: THREE.Float32BufferAttribute
    private geometryStand: THREE.BufferGeometry
    private linesStand: THREE.Line
    private materialStand = new THREE.LineBasicMaterial({color: 0xbbbbbb})

    private _camera = new THREE.PerspectiveCamera()
    private bufferPositionFrustrum: THREE.Float32BufferAttribute
    private geometryFrustrum: THREE.BufferGeometry
    private linesFrustrum: THREE.Line
    private materialFrustrum = new THREE.LineBasicMaterial({color: 0xee8888})

    private bufferPositionFocalPlane: THREE.Float32BufferAttribute
    private geometryFocalPlane: THREE.BufferGeometry
    private meshFocalPlane: THREE.Mesh
    private materialFocalPlane = new THREE.MeshBasicMaterial({color: 0x8888ee, opacity: 0.2, transparent: true, side: THREE.DoubleSide})

    private bufferPositionFocalPlaneOutline: THREE.Float32BufferAttribute
    private geometryFocalPlaneOutline: THREE.BufferGeometry
    private linesFocalPlaneOutline: THREE.Line
    private materialFocalPlaneOutline = new THREE.LineBasicMaterial({color: 0x88ee88})

    private geometryBody: THREE.BufferGeometry
    private geometryLens: THREE.BufferGeometry
    private meshBody: THREE.Mesh
    private meshLens: THREE.Mesh
    private materialBody = new THREE.MeshBasicMaterial({color: 0x333333})

    constructor(camera: ThreeCamera) {
        super()

        this.camera = camera

        this.localMesh = new THREE.Group()
        this.globalMesh = new THREE.Group()

        this.targetCubeGeometry = new THREE.BoxGeometry(5, 5, 5)
        const targetMesh1 = new THREE.Mesh(this.targetCubeGeometry, this.materialTargetCube1)
        const targetMesh2 = new THREE.Mesh(this.targetCubeGeometry, this.materialTargetCube2)
        this.targetCube = new THREE.Group()
        this.targetCube.add(targetMesh1)
        this.targetCube.add(targetMesh2)
        targetMesh1.userData.threeSceneObject = camera
        targetMesh1.userData.materialSlot = 1
        targetMesh1.layers.set(1)
        targetMesh2.userData.threeSceneObject = camera
        targetMesh2.userData.materialSlot = 1
        targetMesh2.layers.set(1)
        this.globalMesh.add(this.targetCube)

        this.geometryStand = new THREE.BufferGeometry()
        this.bufferPositionStand = new THREE.Float32BufferAttribute(6, 3)
        this.geometryStand.setAttribute("position", this.bufferPositionStand)
        this.linesStand = new THREE.Line(this.geometryStand, this.materialStand)
        this.linesStand.layers.set(1)
        this.linesStand.userData.threeSceneObject = camera
        this.globalMesh.add(this.linesStand)

        this.geometryFrustrum = new THREE.BufferGeometry()
        this.bufferPositionFrustrum = new THREE.Float32BufferAttribute((1 + 4 + 4 + 4) * 2 * 3, 3)
        this.geometryFrustrum.setAttribute("position", this.bufferPositionFrustrum)
        this.linesFrustrum = new THREE.LineSegments(this.geometryFrustrum, this.materialFrustrum)
        this.linesFrustrum.userData.threeSceneObject = camera
        this.linesFrustrum.userData.materialSlot = 1
        this.linesFrustrum.layers.set(1)
        this.globalMesh.add(this.linesFrustrum)

        this.geometryFocalPlane = new THREE.BufferGeometry()
        this.bufferPositionFocalPlane = new THREE.Float32BufferAttribute(6 * 3, 3)
        this.geometryFocalPlane.setAttribute("position", this.bufferPositionFocalPlane)
        this.meshFocalPlane = new THREE.Mesh(this.geometryFocalPlane, this.materialFocalPlane)
        this.meshFocalPlane.userData.threeSceneObject = camera
        this.meshFocalPlane.userData.materialSlot = 1
        this.meshFocalPlane.userData.excludeFromHitTest = true
        this.meshFocalPlane.layers.set(1)
        this.globalMesh.add(this.meshFocalPlane)

        this.geometryFocalPlaneOutline = new THREE.BufferGeometry()
        this.bufferPositionFocalPlaneOutline = new THREE.Float32BufferAttribute((4 + 2) * 2 * 3, 3)
        this.geometryFocalPlaneOutline.setAttribute("position", this.bufferPositionFocalPlaneOutline)
        this.linesFocalPlaneOutline = new THREE.LineSegments(this.geometryFocalPlaneOutline, this.materialFocalPlaneOutline)
        this.linesFocalPlaneOutline.userData.threeSceneObject = camera
        this.linesFocalPlaneOutline.userData.materialSlot = 1
        this.linesFocalPlaneOutline.layers.set(1)
        this.globalMesh.add(this.linesFocalPlaneOutline)

        this.geometryBody = new THREE.BoxGeometry(15, 7.5, 10)
        this.meshBody = new THREE.Mesh(this.geometryBody, this.materialBody)
        this.meshBody.rotation.x = Math.PI / 2
        this.meshBody.userData.threeSceneObject = camera
        this.meshBody.layers.set(1)
        this.localMesh.add(this.meshBody)

        this.geometryLens = new THREE.CylinderGeometry(4, 4, 10, 32)
        this.meshLens = new THREE.Mesh(this.geometryLens, this.materialBody)
        this.meshLens.rotation.z = Math.PI / 2
        this.meshLens.rotation.y = Math.PI / 2
        this.meshLens.position.z = -2
        this.meshLens.userData.threeSceneObject = camera
        this.meshLens.layers.set(1)
        this.localMesh.add(this.meshLens)

        this.add(this.globalMesh)
        this.add(this.localMesh)

        this.showEditHelpers(false)
        this.update()
    }

    update(): void {
        this.localMesh.matrix = this.camera.threeCamera.matrix
        this.localMesh.matrix.decompose(this.localMesh.position, this.localMesh.quaternion, this.localMesh.scale)
        this.localMesh.updateMatrix()

        const position = this.camera.threeCamera.position
        this.bufferPositionStand.set([position.x, 0, position.z, position.x, position.y, position.z])
        this.bufferPositionStand.needsUpdate = true

        this.targetCube.matrix.makeTranslation(this.camera._target.x, this.camera._target.y, this.camera._target.z)
        this.targetCube.matrix.decompose(this.targetCube.position, this.targetCube.quaternion, this.targetCube.scale)
        this.targetCube.updateMatrix()

        this._camera = this.camera.threeCamera
        this._camera.projectionMatrixInverse.copy(this.camera.threeCamera.projectionMatrixInverse)

        // calculate camera origin and factor to scale down the far plane

        const V3 = THREE.Vector3

        const origin = this._camera.position
        const targ = new V3(this.camera._target.x, this.camera._target.y, this.camera._target.z)
        const dir = targ.clone().sub(origin).normalize()
        const focus = dir.clone().multiplyScalar(this.camera._focalDistance).add(origin)

        // normalized camera Z values:
        const nzFocus = focus.clone().project(this._camera).z
        const nzNear = -1
        const nzFar = 1

        const focusA = new V3(-1, -1, nzFocus).unproject(this._camera)
        const focusB = new V3(-1, 1, nzFocus).unproject(this._camera)
        const focusC = new V3(1, 1, nzFocus).unproject(this._camera)
        const focusD = new V3(1, -1, nzFocus).unproject(this._camera)

        const focusXA = new V3(-0.5, 0, nzFocus).unproject(this._camera)
        const focusXB = new V3(0.5, 0, nzFocus).unproject(this._camera)
        const focusXC = new V3(0, -0.5, nzFocus).unproject(this._camera)
        const focusXD = new V3(0, 0.5, nzFocus).unproject(this._camera)

        const nearA = new V3(-1, -1, nzNear).unproject(this._camera)
        const nearB = new V3(-1, 1, nzNear).unproject(this._camera)
        const nearC = new V3(1, 1, nzNear).unproject(this._camera)
        const nearD = new V3(1, -1, nzNear).unproject(this._camera)

        const farA = new V3(-1, -1, nzFar).unproject(this._camera)
        const farB = new V3(-1, 1, nzFar).unproject(this._camera)
        const farC = new V3(1, 1, nzFar).unproject(this._camera)
        const farD = new V3(1, -1, nzFar).unproject(this._camera)

        this.bufferPositionFrustrum.set(
            [
                // target line
                origin,
                targ,
                // frustum lines
                nearA,
                farA,
                nearB,
                farB,
                nearC,
                farC,
                nearD,
                farD,
                // frustum near rectangle
                nearA,
                nearB,
                nearB,
                nearC,
                nearC,
                nearD,
                nearD,
                nearA,
                // frustum far rectangle
                farA,
                farB,
                farB,
                farC,
                farC,
                farD,
                farD,
                farA,
            ]
                .map((v) => [v.x, v.y, v.z])
                .flat(),
        )
        this.bufferPositionFrustrum.needsUpdate = true

        this.bufferPositionFocalPlane.set(
            [
                // focus plane
                focusA,
                focusC,
                focusB,
                focusA,
                focusD,
                focusC,
            ]
                .map((v) => [v.x, v.y, v.z])
                .flat(),
        )
        this.bufferPositionFocalPlane.needsUpdate = true

        this.bufferPositionFocalPlaneOutline.set(
            [
                // focus rectangle
                focusA,
                focusB,
                focusB,
                focusC,
                focusC,
                focusD,
                focusD,
                focusA,
                // focus cross
                focusXA,
                focusXB,
                focusXC,
                focusXD,
            ]
                .map((v) => [v.x, v.y, v.z])
                .flat(),
        )
        this.bufferPositionFocalPlaneOutline.needsUpdate = true
    }

    showEditHelpers(enable: boolean) {
        this.targetCube.visible = enable
        this.linesFrustrum.visible = enable
        this.meshFocalPlane.visible = enable
        this.linesFocalPlaneOutline.visible = enable
    }

    dispose(): void {
        this.targetCubeGeometry.dispose()
        this.geometryStand.dispose()
        this.geometryFrustrum.dispose()
        this.geometryFocalPlane.dispose()
        this.geometryFocalPlaneOutline.dispose()
        this.geometryBody.dispose()
        this.geometryLens.dispose()
        disposeMaterialAndTextures(this.materialTargetCube1)
        disposeMaterialAndTextures(this.materialTargetCube2)
        disposeMaterialAndTextures(this.materialStand)
        disposeMaterialAndTextures(this.materialFrustrum)
        disposeMaterialAndTextures(this.materialFocalPlane)
        disposeMaterialAndTextures(this.materialFocalPlaneOutline)
        disposeMaterialAndTextures(this.materialBody)
    }
}
