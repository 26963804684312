<div id="job-task-state-tooltip" class="cm-job-task-state-tooltip">
    @if ($jobTask()?.started) {
        <span class="state--light"> Started {{ formatForDisplay($jobTask()?.started, {shorten: false}) }}</span>
    }
    <span [class]="jobTaskStateColor($jobTask()?.state ?? null)"
        ><i class="fa" [class]="jobTaskStateIconClass($jobTask())"></i>&nbsp;
        @switch ($jobTask()?.state) {
            @case (JobTaskState.Complete) {
                <strong>Completed</strong>
                @if ($jobTask()?.started && $jobTask()?.finished) {
                    after {{ formatTimeDifference($jobTask()?.started, $jobTask()?.finished) }}
                }
            }
            @case (JobTaskState.Cancelled) {
                <strong>Cancelled</strong>
                @if ($jobTask()?.started && $jobTask()?.cancelled) {
                    after {{ formatTimeDifference($jobTask()?.started, $jobTask()?.cancelled) }}
                }
            }
            @case (JobTaskState.Runnable) {
                <strong>Runnable</strong>
                @if ($jobTask()?.started && $jobTask()?.cancelled) {
                    for {{ formatTimeDifference($jobTask()?.started, $jobTask()?.cancelled) }}
                }
            }
            @case (JobTaskState.Queued) {
                <strong>Invoked</strong>
                @if ($jobTask()?.started && $jobTask()?.cancelled) {
                    {{ formatTimeDifference($jobTask()?.started, $jobTask()?.cancelled) }} ago
                }
            }
            @case (JobTaskState.Fatal) {
                <strong>Fatally failed</strong>
                @if ($jobTask()?.started && $jobTask()?.finished) {
                    after {{ formatTimeDifference($jobTask()?.started, $jobTask()?.finished) }}
                }
            }
            @case (JobTaskState.Timeout) {
                <strong>Timed out</strong>
                @if ($jobTask()?.started && $jobTask()?.finished) {
                    after {{ formatTimeDifference($jobTask()?.started, $jobTask()?.finished) }}
                }
            }
            @case (JobTaskState.Failed) {
                <strong>Failed</strong>
                @if ($jobTask()?.started && $jobTask()?.finished) {
                    after {{ formatTimeDifference($jobTask()?.started, $jobTask()?.finished) }}
                }
            }
            @case (JobTaskState.Init) {
                <strong>Init</strong>
            }
            @case (JobTaskState.Running) {
                <strong>Running</strong>
                @if ($jobTask()?.progress; as progress) {
                    @if (progress < 100) {
                        <div class="cm-simple-progress-container">
                            <div class="cm-simple-progress">
                                <div class="cm-simple-progress-bar" [style.width]="progress + '%'"></div>
                            </div>
                            <span class="state--light">{{ progress }}%</span>
                        </div>
                    }
                }
            }
        }
    </span>
    @if ($jobTask()?.state === JobTaskState.Init) {
        <span class="state--light">Waiting for invocation</span>
    }
    @if ($jobTask()?.state === JobTaskState.Runnable) {
        <span class="state--light">Waiting to be picked up by task runner</span>
    }
    @if ($jobTask()?.state === JobTaskState.Failed) {
        @if ($jobTask()?.attemptCount) {
            @if ($jobTask()?.maxAttempts) {
                <span class="state--light">Attempt {{ $jobTask()?.attemptCount }} of {{ $jobTask()?.maxAttempts }}</span>
            } @else {
                <span class="state--light">Attempt {{ $jobTask()?.attemptCount }}</span>
            }
        }
        @if ($jobTask()?.deferredUntil) {
            <span class="state--light">Next attempt deferred until {{ formatForDisplay($jobTask()?.deferredUntil) }}</span>
        }
    }
</div>
