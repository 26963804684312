import {Component, DestroyRef, ElementRef, inject, input, OnInit, signal} from "@angular/core"
import {getDropPositionFromPosition, TemplateDropTarget, TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {TemplateTreeItemComponent} from "../template-tree-item/template-tree-item.component"
import {TemplateTreeComponent} from "../template-tree/template-tree.component"
import {getNodeOwner, isNode, Nodes, TemplateNode} from "@cm/template-nodes"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"

@Component({
    selector: "cm-template-tree-filler",
    standalone: true,
    imports: [],
    templateUrl: "./template-tree-filler.component.html",
    styleUrl: "./template-tree-filler.component.scss",
})
export class TemplateTreeFillerComponent implements OnInit {
    sceneManagerService = inject(SceneManagerService)
    templateTree = input.required<TemplateTreeComponent>()
    drag = inject(TemplateNodeDragService)
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    private destroyRef = inject(DestroyRef)

    isEmpty = signal(true)

    constructor() {
        this.checkEmptyGraph()
    }

    ngOnInit() {
        this.drag.draggedItem$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({dragSource, dropTarget}) => {
            if (dropTarget.component instanceof TemplateTreeFillerComponent) {
                const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
                if (!draggedNode) return
                if (!isNode(draggedNode)) throw new Error("Source node is not of type node")

                this.sceneManagerService.modifyTemplateGraph((templateGraph) => {
                    templateGraph.parameters.nodes.addEntry(draggedNode)
                })
            }
        })

        this.sceneManagerService.templateSwapped$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.checkEmptyGraph()
        })

        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            for (const [node, difference] of differences.modifiedNodes) {
                if (
                    (node instanceof Nodes && getNodeOwner(node) === this.sceneManagerService.$templateGraph()) ||
                    (node === this.sceneManagerService.$templateGraph() && difference.find((x) => x.path === "nodes"))
                ) {
                    this.checkEmptyGraph()
                    return
                }
            }
        })
    }

    private checkEmptyGraph() {
        const templateGraph = this.sceneManagerService.$templateGraph()
        this.isEmpty.set(templateGraph.parameters.nodes.parameters.list.length === 0)
    }

    private getOutsideTreeDropTarget(draggedNode: TemplateNode) {
        const templateTree = this.templateTree()

        const templateGraph = this.sceneManagerService.$templateGraph()
        if (templateGraph.parameters.nodes.parameters.list.length === 0)
            return {component: this, position: "inside"} as TemplateDropTarget<TemplateTreeFillerComponent>

        const lastItem = templateGraph.parameters.nodes.parameters.list[templateGraph.parameters.nodes.parameters.list.length - 1]
        const children = templateTree.children()

        const component = children.find((x) => x.node() === lastItem && x.parentSwitchNode() === undefined)
        if (!component) return {component: this, position: "inside"} as TemplateDropTarget<TemplateTreeFillerComponent>

        if (!component.isMovingToAllowed(draggedNode, "after")) return null

        const dropTarget = {component, position: "after"} as TemplateDropTarget<TemplateTreeItemComponent>
        return dropTarget
    }

    onClick(event: MouseEvent) {
        const {shiftKey, ctrlKey} = event

        this.templateTree().sceneManagerService.handleClickEvent({
            target: [],
            modifiers: {shiftKey, ctrlKey},
        })
    }

    dragOver(event: DragEvent) {
        event.stopPropagation()

        const dragSource = this.drag.dragSource()
        if (!dragSource) return

        const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
        if (!draggedNode) return

        this.drag.dropTarget.update((previous) => {
            const dropTarget = this.getOutsideTreeDropTarget(draggedNode)
            if (!dropTarget) return dropTarget

            if (
                previous &&
                previous.component === dropTarget.component &&
                getDropPositionFromPosition(previous.position) === getDropPositionFromPosition(dropTarget.position)
            )
                return previous
            return dropTarget
        })

        if (this.drag.dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        const dragSource = this.drag.dragSource()
        if (!dragSource) return

        const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
        if (!draggedNode) return

        const dropTarget = this.getOutsideTreeDropTarget(draggedNode)
        if (!dropTarget) return

        this.drag.dragLeave(event, dropTarget.component, this.elementRef.nativeElement)
    }
}
