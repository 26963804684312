import {DataObject, ImageResource, IMaterialNodeGraphTransientDataObject as ITransientDataObjectNewOrg} from "#material-nodes/material-node-graph"

export interface IDataObject {
    id: number
    width: number
    height: number
    downloadUrl: string
}

export type IDataObjectNew = DataObject & {
    related: DataObject[]
}

export function isIDataObject(x: IDataObject | ITransientDataObject): x is IDataObject {
    return x.hasOwnProperty("id")
}

export function isIDataObjectNew(x: IDataObjectNew | ITransientDataObjectNew): x is IDataObjectNew {
    return x.hasOwnProperty("legacyId")
}

export interface ITransientDataObject {
    data: Uint8Array
}

export interface ITransientDataObjectNew extends ITransientDataObjectNewOrg {
    data: Uint8Array
    mediaType: string
}

export function dataObjectNewToImageRessource(dataObject: IDataObjectNew | ITransientDataObjectNew): ImageResource {
    if (isIDataObjectNew(dataObject)) {
        const {related, ...rest} = dataObject
        return {mainDataObject: rest, relatedDataObjects: related}
    } else return {transientDataObject: dataObject}
}
