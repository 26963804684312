import {findBestMatchAlongSegment, ReturnType} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/find-best-match-along-segment"
import {Vector2, Vector2Like} from "@cm/math"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export type ParameterType = {
    sourceImage: ImageRef
    sourceCastRayOrigin: Vector2Like
    sourceCastRayTarget: Vector2Like
    currentCastRayOrigin: Vector2Like
    currentCastRayTarget: Vector2Like
    idealDistance?: number // default: distance between sourceCastRayOrigin and sourceCastRayTarget
    searchWidth?: number // default: idealDistance * 1.2
    searchOffset?: number // default: idealDistance * 0.4
}

export async function featureRayCast(imageOpContext: ImageOpContextWebGL2, parameters: ParameterType) {
    const sourceImage = parameters.sourceImage
    const sourceCastRayOrigin = Vector2.fromVector2Like(parameters.sourceCastRayOrigin)
    const sourceCastRayTarget = Vector2.fromVector2Like(parameters.sourceCastRayTarget)
    const currentCastRayOrigin = Vector2.fromVector2Like(parameters.currentCastRayOrigin)
    const currentCastRayTarget = Vector2.fromVector2Like(parameters.currentCastRayTarget)

    const referenceSegment = {
        from: sourceCastRayOrigin,
        to: sourceCastRayTarget,
        width: 32,
    }
    const referenceSegmentLength = Vector2.distance(referenceSegment.from, referenceSegment.to)

    const currentCastRayDelta = currentCastRayTarget.sub(currentCastRayOrigin)
    const currentCastRayLength = currentCastRayDelta.norm()
    if (currentCastRayLength < 1e-6) {
        return undefined
    }
    const currentCentralRayDir = currentCastRayDelta.div(currentCastRayLength)
    const idealDistance = parameters.idealDistance ?? currentCastRayLength
    const maxWiggleIterations = 1 + 2 * 4
    const wiggleStep = 0.5
    const promisedLineSearchResults: Promise<ReturnType>[] = []
    for (let wiggleIteration = 1; wiggleIteration <= maxWiggleIterations; wiggleIteration++) {
        const wiggleAmount = wiggleStep * (wiggleIteration >> 1) * ((wiggleIteration & 1) === 0 ? 1 : -1)
        const wiggleDir = currentCentralRayDir.perp()
        const currentTarget = currentCastRayTarget.add(wiggleDir.mul(wiggleAmount))
        const currentRayDir = currentTarget.sub(currentCastRayOrigin).normalized()
        const searchOffset = currentRayDir.mul(parameters.searchOffset ?? idealDistance * 0.4) // start the search slightly offset to avoid self-correlation
        const searchWidth = parameters.searchWidth ?? idealDistance * 1.2 // pixels to search for with correlation
        const searchEndShift = currentRayDir.mul(referenceSegmentLength + searchWidth)
        const searchSegment = {
            from: currentCastRayOrigin.add(searchOffset),
            to: currentCastRayOrigin.add(searchOffset).add(searchEndShift),
            width: referenceSegment.width,
        }
        const lineSearchPenaltyFn = (position: Vector2) => {
            // favor ideal intervals
            const interval = position.sub(currentCastRayOrigin).norm()
            const intervalDiff = Math.abs(interval - idealDistance)
            const intervalPenalty = (intervalDiff / idealDistance) * 0.5
            // const intervalPenalty = intervalDiff * 0.01
            // favor similar direction
            const dir = position.sub(currentCastRayOrigin).normalized()
            const dot = dir.dot(currentCentralRayDir)
            const anglePenalty = Math.acos(Math.min(1, Math.max(-1, dot))) / (Math.PI / 2)
            return intervalPenalty + anglePenalty
        }
        const promisedLineSearchResult = findBestMatchAlongSegment(imageOpContext, {
            sourceImage,
            referenceSegment,
            searchSegment,
            penaltyFn: lineSearchPenaltyFn,
        })
        promisedLineSearchResults.push(promisedLineSearchResult)
    }
    const lineSearchResults = await Promise.all(promisedLineSearchResults)
    const bestLineSearchResult = lineSearchResults.reduce((best, current) => (current.peakValue > best.peakValue ? current : best))
    const bestPositionAlongLine = bestLineSearchResult.bestMatchPosition
    return {
        nextPosition: bestPositionAlongLine,
        details: bestLineSearchResult,
    }

    // if (bestLineSearchResult.peakValue < minCorrelationToProceed) {
    //     return
    // }

    // const prevDir =
    //     helperLine.points.length > 1
    //         ? helperLine.points[helperLine.points.length - 1].sub(helperLine.points[helperLine.points.length - 2]).normalized()
    //         : undefined
    // const vicinityPenaltyFn = (position: Vector2) => {
    //     if (!prevDir) {
    //         return 0
    //     }
    //     // favor similar direction
    //     const dir = position.sub(helperLine.currentCastRayOrigin).normalized()
    //     const dot = dir.dot(prevDir)
    //     return (Math.acos(Math.min(1, Math.max(-1, dot))) / Math.PI) * 0.25
    // }
    // const vicinitySearchResult = await findBestMatchInVicinity(imageOpContext, {
    //     sourceImage: sourceImageRef,
    //     referencePosition: helperLine.sourceCastRayOrigin,
    //     position: bestPositionAlongLine,
    //     vicinity: 4,
    //     matchingSize: 16,
    //     penaltyFn: vicinityPenaltyFn,
    // })
    // if (vicinitySearchResult.peakValue < minCorrelationToProceed) {
    //     return
    // }
    // const newOrigin = vicinitySearchResult.bestMatchPosition
}
