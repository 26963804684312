import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TemplateNode} from "#template-nodes/types"
import {VisitorNodeVersion} from "@cm/graph/declare-visitor-node"
import {versionChain} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {dataObjectNewToImageRessource, IDataObjectNew} from "@cm/material-nodes/interfaces/data-object"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {MaterialGraphNode, wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {internalColorSpaceForImageResource} from "@cm/material-nodes/material-node-graph-transformations"
import {hashObject} from "@cm/utils"
import {z} from "zod"

const dataObjectReferenceParameters = namedNodeParameters.merge(
    z.object({
        dataObjectId: z.number(),
    }),
)
export type DataObjectReferenceParameters = z.infer<typeof dataObjectReferenceParameters>

type V0 = {
    dataObjectId: number
}

type V1 = V0 & NamedNodeParameters
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        return {name: "Data Object Reference", ...parameters}
    },
}

@registerNode
export class DataObjectReference
    extends DeclareTemplateNode({parameters: dataObjectReferenceParameters}, {}, {nodeClass: "DataObjectReference", versionChain: versionChain([v0])})
    implements EvaluableTemplateNode<ImageGenerator>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const dataObject = evaluator.fetchDataObject(scope, this)
        return scope.pureLambda<[IDataObjectNew, number], ImageGenerator>(
            scope.tuple(dataObject, this.parameters.dataObjectId),
            ([dataObject, dataObjectId]) => {
                return {
                    imageNode: {
                        generator: ({uv, extension, interpolation, projection, size, disableProgressiveLoading, forceOriginalResolution}) => {
                            const imageResource = dataObjectNewToImageRessource(dataObject)
                            const imgNode: MaterialGraphNode<"TexImage"> = {
                                nodeType: "TexImage",
                                inputs: {
                                    Vector: uv,
                                },
                                parameters: {
                                    "internal.extension": extension,
                                    "internal.interpolation": interpolation,
                                    "internal.projection": projection,
                                    "internal.disable_progressive_loading": disableProgressiveLoading,
                                    "internal.force_original_resolution": forceOriginalResolution,
                                    "internal.image.colorspace_settings.name": internalColorSpaceForImageResource(imageResource),
                                },
                                resolvedResources: [
                                    {
                                        ...imageResource,
                                        metadata: size
                                            ? {
                                                  widthCm: size[0],
                                                  heightCm: size[1],
                                              }
                                            : undefined,
                                    },
                                ],
                            }

                            return {color: wrapNodeOutput(imgNode, "Color"), alpha: wrapNodeOutput(imgNode, "Alpha")}
                        },
                        hash: hashObject({
                            type: "DataObjectReference",
                            dataObjectId,
                        }),
                    },
                    metadata: {
                        width: dataObject.width,
                        height: dataObject.height,
                        legacyId: dataObject.legacyId,
                    },
                }
            },
            "dataObjectReference",
        )
    }
}

export type DataObjectReferenceFwd = TemplateNode<DataObjectReferenceParameters> & EvaluableTemplateNode<ImageGenerator>
