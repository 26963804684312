import {ConfiguratorVariant, GroupId, PricingContext, VariantId} from "#pricing/nodes/core"
import {DeclareNodeGraphTS, getAll, registerNode} from "@cm/graph"

export type ConfigurationGroupNodeParameters = {
    groupId: GroupId
    currentVariantId: VariantId
    allVariantIds: VariantId[] | undefined
}

@registerNode
export class ConfigurationGroupNode extends DeclareNodeGraphTS<ConfiguratorVariant, PricingContext, ConfigurationGroupNodeParameters>(
    {
        run: async ({get, parameters, context}) => {
            const {groupId, currentVariantId} = await getAll(parameters, get)
            return {groupId, currentVariantId}
        },
    },
    {
        nodeClass: "ConfigurationGroupNode",
    },
) {
    setCurrentVariantId(currentVariantId: VariantId) {
        this.parameters.currentVariantId = currentVariantId
    }

    addVariant(variantId: VariantId) {
        if (this.parameters.allVariantIds === undefined) this.parameters.allVariantIds = []
        if (!this.parameters.allVariantIds.includes(variantId)) this.parameters.allVariantIds.push(variantId)
    }

    hasVariant(variantId: VariantId): boolean {
        if (this.parameters.allVariantIds === undefined) return false
        return this.parameters.allVariantIds.includes(variantId)
    }

    getGroupId(): GroupId {
        return this.parameters.groupId
    }
}
