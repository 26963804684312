import {Component, ElementRef, input, signal, viewChild} from "@angular/core"
import {NgClass} from "@angular/common"
import {TestCaseEntry} from "@app/test/manual-testing/helpers/configurator-test-helpers"
import {TestButtonComponent} from "@app/test/manual-testing/test-button/test-button.component"

@Component({
    selector: "cm-test-container",
    templateUrl: "./test-container.component.html",
    styleUrls: ["./test-container.component.scss"],
    standalone: true,
    imports: [NgClass, TestButtonComponent],
})
export class TestContainerComponent {
    $testCases = input.required<TestCaseEntry[]>({alias: "testCases"})

    private $image = viewChild.required<ElementRef<HTMLImageElement>>("image")
    showOverlay = signal(false)

    setImage(src: string) {
        this.$image().nativeElement.src = src
        this.showOverlay.set(true)
    }
}
