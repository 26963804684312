import {Object3D} from "@cm/material-nodes/three"
import {OutlinePass} from "@cm/material-nodes/three"

export class PatchedOutlinePass extends OutlinePass {
    _visibilityCache: Map<Object3D, boolean> = new Map<Object3D, boolean>()

    override changeVisibilityOfNonSelectedObjects = (bVisible: boolean) => {
        const cache = this._visibilityCache
        const selectedMeshes: Object3D[] = []

        // TODO: fix types (!)
        function gatherSelectedMeshesCallBack(object: Object3D & {isMesh: boolean}) {
            if (object.isMesh) selectedMeshes.push(object)
        }

        for (let i = 0; i < this.selectedObjects.length; i++) {
            const selectedObject = this.selectedObjects[i]
            selectedObject.traverse(gatherSelectedMeshesCallBack as (object: Object3D) => any)
        }

        function VisibilityChangeCallBack(
            // TODO: fix types (!)
            object: Object3D & {isMesh: boolean; isSprite: boolean; isTransformControls: boolean; isPoints: boolean; isLine: boolean},
        ) {
            //ThreeJS Patch: "|| object.isTransformControls" was added
            if (object.isMesh || object.isSprite || object.isTransformControls) {
                // only meshes and sprites are supported by OutlinePass

                let bFound = false

                for (let i = 0; i < selectedMeshes.length; i++) {
                    const selectedObjectId = selectedMeshes[i].id

                    if (selectedObjectId === object.id) {
                        bFound = true
                        break
                    }
                }

                if (bFound === false) {
                    const visibility = object.visible

                    if (bVisible === false || cache.get(object) === true) {
                        object.visible = bVisible
                    }

                    cache.set(object, visibility)
                }
            } else if (object.isPoints || object.isLine) {
                // the visibility of points and lines is always set to false in order to
                // not affect the outline computation

                if (bVisible === true) {
                    object.visible = !!cache.get(object) // restore
                } else {
                    cache.set(object, object.visible)
                    object.visible = bVisible
                }
            }
        }

        this.renderScene.traverse(VisibilityChangeCallBack as (object: Object3D) => any)
    }
}
