import {MaterialSocket} from "@material-editor/models/material-socket"

export const TexVoronoiOutputs: Record<string, MaterialSocket> = {
    distance: {paramType: "socket", id: "Distance", type: "output", valueType: "output", label: "Distance"},
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Value"},
    position: {paramType: "socket", id: "Position", type: "output", valueType: "vector", label: "Position"},
}

export const TexVoronoiInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "input", label: "Vector"},
    w: {paramType: "socket", id: "W", type: "input", valueType: "scalar", label: "W"},
    scale: {paramType: "socket", id: "Scale", type: "input", valueType: "scalar", label: "Scale"},
    detail: {paramType: "socket", id: "Detail", type: "input", valueType: "scalar", label: "Detail"},
    smoothness: {paramType: "socket", id: "Smoothness", type: "input", valueType: "scalar", label: "Smoothness"},
    randomness: {paramType: "socket", id: "Randomness", type: "input", valueType: "scalar", label: "Randomness"},
}
