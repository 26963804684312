import {Component, inject} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, SearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {HdrisGridComponent} from "@platform/components/hdris/hdris-grid/hdris-grid.component"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"

@Component({
    selector: "cm-hdris-page",
    standalone: true,
    imports: [ItemFiltersComponent, RouterOutlet, SearchFilterComponent, CheckboxesFilterComponent, SidebarLayoutComponent, HdrisGridComponent],
    templateUrl: "./hdris-page.component.html",
    styleUrl: "./hdris-page.component.scss",
})
export class HdrisPageComponent {
    organizations = inject(OrganizationsService)
}
