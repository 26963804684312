import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {BorderMode, convolve} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convolve"
import {resize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {Vector2Like} from "@cm/math"

const SCOPE_NAME = "GaussianBlur"

export type ParameterType = {
    sourceImage: ImageRef
    sigma: Vector2Like | number
    kernelClampThresh?: number // ratio where to cut off the kernel to bound it; default: 1e-2
    borderMode?: BorderMode // default: "renormalize"
}

export type ReturnType = ImageRef

export function gaussianBlur(cmdQueue: ImageOpCommandQueue, {sourceImage, sigma, kernelClampThresh, borderMode}: ParameterType): ReturnType {
    if (typeof sigma === "number") {
        sigma = {x: sigma, y: sigma}
    }
    if (sigma.x === 0 && sigma.y === 0) {
        return sourceImage
    }
    cmdQueue.beginScope(SCOPE_NAME)
    kernelClampThresh ??= 1e-2
    borderMode ??= "renormalize"
    const computeKernelSize = (sigma: number, yThresh: number) => Math.sqrt(2) * Math.sqrt(sigma * sigma * Math.log(1 / yThresh))
    const halfKernelSize = {
        x: Math.ceil(computeKernelSize(sigma.x, kernelClampThresh)),
        y: Math.ceil(computeKernelSize(sigma.y, kernelClampThresh)),
    }
    const computeDownSamplingFactor = (imageSize: number, halfKernelSize: number) =>
        Math.floor(imageSize * Math.min(1, MAX_KERNEL_SIZE / halfKernelSize)) / imageSize
    const downSamplingFactor = {
        x: computeDownSamplingFactor(sourceImage.descriptor.width, halfKernelSize.x),
        y: computeDownSamplingFactor(sourceImage.descriptor.height, halfKernelSize.y),
    }
    const interpolation = "cubic"
    const downSampledSourceImage = resize(cmdQueue, {
        sourceImage,
        interpolation,
        resultSize: {
            width: sigma.x === 0 ? 1 : Math.round(sourceImage.descriptor.width * downSamplingFactor.x),
            height: sigma.y === 0 ? 1 : Math.round(sourceImage.descriptor.height * downSamplingFactor.y),
        },
    })
    const downSampledBlurredImageH = convolve(cmdQueue, {
        sourceImage: downSampledSourceImage,
        kernel: (() => {
            const halfKernelSizeH = sigma.x === 0 ? 0 : Math.ceil(halfKernelSize.x * downSamplingFactor.x)
            const sigmaH = sigma.x === 0 ? 1 : sigma.x * downSamplingFactor.x
            const kernelH = computeKernel(halfKernelSizeH, sigmaH)
            return {
                width: kernelH.length,
                height: 1,
                values: kernelH,
            }
        })(),
        borderMode,
    })
    const downSampledBlurredImage = convolve(cmdQueue, {
        sourceImage: downSampledBlurredImageH,
        kernel: (() => {
            const halfKernelSizeV = sigma.y === 0 ? 0 : Math.ceil(halfKernelSize.y * downSamplingFactor.y)
            const sigmaV = sigma.y === 0 ? 1 : sigma.y * downSamplingFactor.y
            const kernelV = computeKernel(halfKernelSizeV, sigmaV)
            return {
                width: 1,
                height: kernelV.length,
                values: kernelV,
            }
        })(),
        borderMode,
    })
    const result = resize(cmdQueue, {
        sourceImage: downSampledBlurredImage,
        interpolation,
        resultSize: sourceImage.descriptor,
    })
    cmdQueue.endScope(SCOPE_NAME)
    return result
}

function computeKernel(halfKernelSize: number, sigma: number): number[] {
    // compute Gaussian kernel
    halfKernelSize = Math.ceil(halfKernelSize)
    const kernel = []
    let sum = 0
    for (let i = -halfKernelSize; i <= halfKernelSize; i++) {
        const v = Math.exp((-i * i) / (2 * sigma * sigma))
        kernel.push(v)
        sum += v
    }
    // make area under curve equal to 1
    for (let i = 0; i < kernel.length; i++) {
        kernel[i] /= sum
    }
    return kernel
}

const MAX_KERNEL_SIZE = 32
