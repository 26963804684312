import {SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {ThreeObject, getThreeObjectPart, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {Three as THREE} from "@cm/material-nodes/three"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {anyDifference, arrayDifferent, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeAnnotationObject} from "./three-annotation"
import {ThreeMesh} from "./three-mesh"
import {projectCurvePointsToObject} from "./three-utils"
import {configThreeHelperObjectLayers} from "./helper-objects"

export class ThreeMeshCurveControl extends ThreeObject<SceneNodes.MeshCurveControl> {
    protected override renderObject = new THREE.Group()

    protected curveGeometry = new THREE.BufferGeometry()
    protected curveOccludedMaterial = new THREE.LineBasicMaterial({color: 0xff0000, depthTest: false})
    protected curveMaterial = new THREE.LineBasicMaterial({color: 0x0000ff})
    protected curveObject = new THREE.Group()
    protected controlPoints = new THREE.Group()

    protected hitRaysGeometry = new THREE.BufferGeometry()
    protected hitRaysMaterial = new THREE.LineBasicMaterial({color: 0xffffff, depthTest: false})
    protected hitRays = new THREE.LineSegments(this.hitRaysGeometry, this.hitRaysMaterial)

    protected hitCurveGeometry = new THREE.BufferGeometry()
    protected hitCurveMaterial = new THREE.LineBasicMaterial({color: 0x00ff00, depthTest: false})
    protected hitCurve = new THREE.LineSegments(this.hitCurveGeometry, this.hitCurveMaterial)

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)

        setThreeObjectPart(this.getRenderObject(), this)

        this.renderObject.add(this.controlPoints)

        this.curveObject.add(new THREE.Line(this.curveGeometry, this.curveOccludedMaterial))
        this.curveObject.add(new THREE.Line(this.curveGeometry, this.curveMaterial))
        this.curveObject.renderOrder = 1
        this.renderObject.add(this.curveObject)

        this.renderObject.add(this.hitRays)

        this.renderObject.renderOrder = 1
        this.renderObject.add(this.hitCurve)

        configThreeHelperObjectLayers(this)
    }

    override setup(sceneNode: SceneNodes.MeshCurveControl) {
        return anyDifference([
            arrayDifferent(
                sceneNode.controlPoints,
                this.parameters?.controlPoints,
                (a, b) => mathIsEqual(a.position, b.position),
                (controlPoints) => {
                    for (const [i, controlPoint] of controlPoints.entries()) {
                        const {sceneManagerService} = this.threeSceneManagerService
                        const previousPoint = this.parameters?.controlPoints.at(i)
                        if (!previousPoint) {
                            const templateNodePart = sceneManagerService.sceneNodePartToTemplateNodePart({
                                sceneNode,
                                part: `controlPoint${i}`,
                            })

                            if (!templateNodePart) throw new Error("Template node part not found")

                            const annotation = new ThreeAnnotationObject(this.threeSceneManagerService, templateNodePart, false)
                            annotation.position.copy(controlPoint.position)
                            annotation.updateLabels(`${i + 1}`, "")
                            this.controlPoints.add(annotation)
                            setThreeObjectPart(annotation, this, `controlPoint${i}`)
                        } else if (!mathIsEqual(controlPoint.position, previousPoint.position)) {
                            const annotation = this.getControlPoint(i)
                            annotation.position.copy(controlPoint.position)
                        }
                    }

                    for (let i = controlPoints.length; i < (this.parameters?.controlPoints.length ?? 0); i++) {
                        const annotation = this.getControlPoint(i)
                        this.controlPoints.remove(annotation)
                        annotation.dispose(true)
                    }
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["curvePoints"],
                (a, b) => a?.points === b?.points,
                ({curvePoints}) => {
                    const points = curvePoints?.points
                    if (points) {
                        const pointsArray: THREE.Vector3[] = []
                        for (let i = 0; i < points.length; i += 3) pointsArray.push(new THREE.Vector3(points[i], points[i + 1], points[i + 2]))

                        this.curveGeometry.setFromPoints(pointsArray)
                        this.curveGeometry.computeBoundingSphere()
                    } else this.curveGeometry.deleteAttribute("position")
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    updateTransform(transform, this.renderObject)
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["curvePoints", "meshId"],
                (a, b) => {
                    if (a === null || typeof a === "string" || b === null || typeof b === "string") return a === b
                    return a.points === b.points && a.normals === b.normals && a.tangents === b.tangents
                },
                ({curvePoints, meshId}) => {
                    //We need to update the world matrix of the scene to get the correct hit points even before the first render
                    this.threeSceneManagerService.getSceneReference().updateMatrixWorld()

                    const meshObject = this.threeSceneManagerService.getThreeObject(meshId)
                    if (!meshObject || !(meshObject instanceof ThreeMesh)) throw new Error(`Mesh object with id ${meshId} not found`)
                    const meshScene = meshObject.getRenderObject()

                    const segmentLength = 0.1
                    const queryRange = 2 * segmentLength

                    if (curvePoints) {
                        const {points} = curvePoints

                        const {hitPoints, valid} = projectCurvePointsToObject(curvePoints, this.renderObject.matrix, queryRange, meshScene)

                        const numValid = valid.reduce((acc, value) => acc + (value ? 1 : 0), 0)
                        const hitRays = new Float32Array(numValid * 2 * 3)

                        let hitRaysIndex = 0
                        for (let i = 0; i < valid.length; i++) {
                            if (valid[i]) {
                                hitRays[hitRaysIndex++] = points[i * 3]
                                hitRays[hitRaysIndex++] = points[i * 3 + 1]
                                hitRays[hitRaysIndex++] = points[i * 3 + 2]

                                const hitPoint = hitPoints[i]

                                hitRays[hitRaysIndex++] = hitPoint.x
                                hitRays[hitRaysIndex++] = hitPoint.y
                                hitRays[hitRaysIndex++] = hitPoint.z
                            }
                        }

                        const numConsecutiveValid = valid.reduce((acc, value, index) => acc + (value && index > 0 && valid[index - 1] ? 1 : 0), 0)
                        const hitCurve = new Float32Array(numConsecutiveValid * 2 * 3)

                        let hitCurveIndex = 0
                        for (let i = 0; i < valid.length; i++) {
                            if (valid[i] && i > 0 && valid[i - 1]) {
                                const hitPoint = hitPoints[i]
                                const previousHitPoint = hitPoints[i - 1]

                                hitCurve[hitCurveIndex++] = hitPoint.x
                                hitCurve[hitCurveIndex++] = hitPoint.y
                                hitCurve[hitCurveIndex++] = hitPoint.z

                                hitCurve[hitCurveIndex++] = previousHitPoint.x
                                hitCurve[hitCurveIndex++] = previousHitPoint.y
                                hitCurve[hitCurveIndex++] = previousHitPoint.z
                            }
                        }

                        this.hitRaysGeometry.setAttribute("position", new THREE.BufferAttribute(hitRays, 3))
                        this.hitRaysGeometry.computeBoundingSphere()
                        this.hitCurveGeometry.setAttribute("position", new THREE.BufferAttribute(hitCurve, 3))
                        this.hitCurveGeometry.computeBoundingSphere()
                    } else {
                        this.hitRaysGeometry.deleteAttribute("position")
                        this.hitCurveGeometry.deleteAttribute("position")
                    }
                },
            ),
        ])
    }

    getControlPoint(index: number) {
        for (const child of this.controlPoints.children) {
            if (child instanceof ThreeAnnotationObject) {
                const threeObjectPart = getThreeObjectPart(child)

                if (threeObjectPart && threeObjectPart.part === `controlPoint${index}`) return child
            }
        }

        throw new Error(`Control point with index ${index} not found`)
    }

    override dispose() {
        for (const child of this.controlPoints.children) {
            if (child instanceof ThreeAnnotationObject) child.dispose(true)
        }

        this.curveGeometry.dispose()
        this.curveOccludedMaterial.dispose()
        this.curveMaterial.dispose()

        this.hitRaysGeometry.dispose()
        this.hitRaysMaterial.dispose()

        this.hitCurveGeometry.dispose()
        this.hitCurveMaterial.dispose()
    }

    override onSelectionChange(selected: boolean) {
        this.controlPoints.visible = selected
        this.curveObject.visible = selected
        this.hitRays.visible = selected
        this.hitCurve.visible = selected
    }
}
