import {Color, Vector2, Vector2Like} from "@cm/math"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {LineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/line-item"

export class HelperLineItem extends LineItem {
    constructor(parent: CanvasBaseToolboxItem, from: Vector2Like, to: Vector2Like) {
        super(parent, [], 2, new Color(1, 0.2, 1, 1))
        this.cursor = "pointer"
        this._sourceCastRayOrigin = Vector2.fromVector2Like(from)
        this._sourceCastRayTarget = Vector2.fromVector2Like(to)
        this.sendToBack()
    }

    override remove() {
        super.remove()
        this._removed = true
    }

    get isRemoved() {
        return this._removed
    }

    get sourceCastRayOrigin(): Vector2 {
        return this._sourceCastRayOrigin
    }

    get sourceCastRayTarget(): Vector2 {
        return this._sourceCastRayTarget
    }

    rescaleSourceTargetDistance(distance: number) {
        const dir = this._sourceCastRayTarget.sub(this._sourceCastRayOrigin).normalized()
        this._sourceCastRayTarget = this._sourceCastRayOrigin.add(dir.mul(distance))
    }

    addHeadPoint(point: Vector2Like) {
        this.setPoints([point, ...this.points])
    }

    addTailPoint(point: Vector2Like) {
        this.setPoints([...this.points, point])
    }

    private _sourceCastRayOrigin: Vector2
    private _sourceCastRayTarget: Vector2
    private _removed = false
}
