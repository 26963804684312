<div class="cm-main-card">
    <div class="cm-menu">
        @for (entry of $testCases(); track entry.name) {
            <cm-test-button [entry]="entry"></cm-test-button>
        }
    </div>

    <div class="cm-configurator-container">
        <ng-content></ng-content>
    </div>

    <div class="cm-image-overlay" [ngClass]="{show: showOverlay()}" (click)="showOverlay.set(false)">
        <img #image />
    </div>
</div>
