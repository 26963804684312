import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-interpolator"
import {Vector2, Vector2Like} from "@cm/math"

export abstract class CurveInterpolatorBase implements CurveInterpolator {
    abstract evaluate(t: number): Vector2

    abstract solveForT(position: Vector2Like): number

    evaluateTangent(t: number): Vector2 {
        const eps = 1e-6
        const p0 = this.evaluate(t - eps)
        const p1 = this.evaluate(t + eps)
        return p1.sub(p0).normalized()
    }
}
