import {SceneNodes} from "@cm/template-nodes"
import {ThreeObject, getNextThreeObjectPart, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {Three as THREE} from "@cm/material-nodes/three"
import {DisplayMode, ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {ThreeStandHelper} from "@template-editor/helpers/three-stand-helper"
import {MIN_NEAR_CLIP, MAX_FAR_CLIP, updateThreeCamera} from "@template-editor/helpers/three-utils"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {configThreeHelperObjectLayers} from "@template-editor/helpers/helper-objects"

export class ThreeCamera extends ThreeObject<SceneNodes.Camera> {
    protected override renderObject: THREE.Group = new THREE.Group()
    threeCamera = new THREE.PerspectiveCamera(50, 1, MIN_NEAR_CLIP, MAX_FAR_CLIP)

    private cameraHelper: ThreeCameraHelper
    private raycaster = new THREE.Raycaster()

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)
        this.cameraHelper = new ThreeCameraHelper(this, threeSceneManagerService, onAsyncUpdate)

        this.onDisplayModeChange(threeSceneManagerService.$displayMode())
    }

    override onDisplayModeChange(displayMode: DisplayMode) {
        //objects are added/removed only once internally
        if (displayMode === "editor") this.renderObject.add(this.cameraHelper.getRenderObject())
        else if (displayMode === "configurator") this.renderObject.remove(this.cameraHelper.getRenderObject())
    }

    override setup(sceneNode: SceneNodes.Camera) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform", "nearClip", "farClip", "filmGauge", "focalLength", "shiftX", "shiftY", "aspectRatio", "autoFocus"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return mathIsEqual(valueA, valueB)
                    return valueA === valueB
                },
                ({transform, nearClip, farClip, filmGauge, focalLength, shiftX, shiftY, aspectRatio, autoFocus}) => {
                    updateThreeCamera(this.threeCamera, {nearClip, farClip, filmGauge, focalLength, shiftX, shiftY, aspectRatio}, transform)
                    if (autoFocus) {
                        const {sceneManagerService} = this.threeSceneManagerService
                        const templateNode = sceneManagerService.sceneNodePartToTemplateNodePart({
                            sceneNode,
                            part: "root",
                        })?.templateNode
                        if (!templateNode) return

                        this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.threeCamera)

                        for (const intersection of this.raycaster.intersectObjects(this.threeSceneManagerService.getSceneReference().children, true)) {
                            const {distance, object} = intersection
                            if (object.visible && distance > this.threeCamera.near && distance < this.threeCamera.far) {
                                const threeObjectPart = getNextThreeObjectPart(object)
                                if (threeObjectPart && !(threeObjectPart.threeObject instanceof ThreeCamera)) {
                                    templateNode.updateParameters({focalDistance: distance})
                                    sceneManagerService.compileTemplate()
                                    return
                                }
                            }
                        }
                    }
                },
            ),
            this.cameraHelper.update(sceneNode),
        ])
    }

    override dispose() {
        this.cameraHelper.dispose()
    }

    override onSelectionChange(selected: boolean) {
        this.cameraHelper.onSelectionChange(selected)
    }
}

class ThreeCameraHelper extends ThreeObject<SceneNodes.Camera> {
    protected override renderObject: THREE.Group = new THREE.Group()
    private localMesh = new THREE.Group()
    private globalMesh = new THREE.Group()
    private targetMesh = new THREE.Group()

    private proxyFrustrum: THREE.LineSegments<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private proxyFocalPlane: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
    private proxyFocalPlaneOutline: THREE.LineSegments<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private proxyBodyMesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>
    private proxyLensMesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>
    private proxyLaser: THREE.Line<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private proxyTarget: THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>
    private proxyOccludedTarget: THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>

    private standHelper: ThreeStandHelper

    constructor(
        protected camera: ThreeCamera,
        threeSceneManagerService: ThreeSceneManagerService,
        onAsyncUpdate: () => void,
    ) {
        super(threeSceneManagerService, onAsyncUpdate)

        {
            this.proxyBodyMesh = new THREE.Mesh(new THREE.BoxGeometry(15, 7.5, 10), new THREE.MeshStandardMaterial({color: 0x333333, fog: false}))
            this.proxyBodyMesh.rotation.x = Math.PI / 2
        }
        {
            this.proxyLensMesh = new THREE.Mesh(new THREE.CylinderGeometry(4, 4, 10, 32), new THREE.MeshStandardMaterial({color: 0x333333, fog: false}))
            this.proxyLensMesh.rotation.z = Math.PI / 2
            this.proxyLensMesh.rotation.y = Math.PI / 2
            this.proxyLensMesh.position.z = -2
        }

        this.localMesh.add(this.proxyBodyMesh)
        setThreeObjectPart(this.proxyBodyMesh, camera)
        this.localMesh.add(this.proxyLensMesh)
        setThreeObjectPart(this.proxyLensMesh, camera)

        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute((4 + 4 + 4) * 2 * 3, 3))

            const material = new THREE.LineBasicMaterial({color: 0xee8888, fog: false})
            this.proxyFrustrum = new THREE.LineSegments(geometry, material)
        }
        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute(6 * 3, 3))

            const material = new THREE.MeshBasicMaterial({color: 0x8888ee, opacity: 0.2, transparent: true, side: THREE.DoubleSide, fog: false})
            this.proxyFocalPlane = new THREE.Mesh(geometry, material)
        }
        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute((4 + 2) * 2 * 3, 3))

            const material = new THREE.LineBasicMaterial({color: 0x88ee88, fog: false})
            this.proxyFocalPlaneOutline = new THREE.LineSegments(geometry, material)
        }
        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute(6, 3))
            this.proxyLaser = new THREE.Line(geometry, new THREE.LineBasicMaterial({color: 0xee8888, fog: false}))
        }
        this.proxyTarget = new THREE.Mesh(new THREE.BoxGeometry(5, 5, 5), new THREE.MeshStandardMaterial({color: 0xff0000, fog: false}))
        this.proxyOccludedTarget = new THREE.Mesh(
            new THREE.BoxGeometry(5, 5, 5),
            new THREE.MeshStandardMaterial({color: 0xff0000, fog: false, transparent: true, opacity: 0.2, depthTest: false, depthWrite: false}),
        )
        this.standHelper = new ThreeStandHelper(threeSceneManagerService, onAsyncUpdate)

        this.globalMesh.add(this.proxyFrustrum)
        this.globalMesh.add(this.proxyFocalPlane)
        this.globalMesh.add(this.proxyFocalPlaneOutline)
        setThreeObjectPart(this.proxyFocalPlaneOutline, camera)
        this.globalMesh.add(this.proxyLaser)
        this.globalMesh.add(this.targetMesh)
        this.targetMesh.add(this.proxyTarget)
        this.targetMesh.add(this.proxyOccludedTarget)
        setThreeObjectPart(this.targetMesh, camera, "target")
        this.globalMesh.add(this.standHelper.getRenderObject())
        setThreeObjectPart(this.standHelper.getRenderObject(), camera)

        this.renderObject.add(this.localMesh)
        this.renderObject.add(this.globalMesh)

        configThreeHelperObjectLayers(this)
    }

    setup(sceneNode: SceneNodes.Camera) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                //We also need to include the relevant parameters that change the threeCamera, to trigger the helper update if they change
                ["transform", "target", "nearClip", "farClip", "filmGauge", "focalLength", "focalDistance", "shiftX", "shiftY", "aspectRatio"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return mathIsEqual(valueA, valueB)
                    return valueA === valueB
                },
                ({transform, target: iTarget, focalDistance}) => {
                    const {position} = updateTransform(transform, this.localMesh)

                    const {threeCamera} = this.camera

                    const target = new THREE.Vector3(iTarget.x, iTarget.y, iTarget.z)

                    const nzNear = -1
                    const nzFar = 1

                    const nearA = new THREE.Vector3(-1, -1, nzNear).unproject(threeCamera)
                    const nearB = new THREE.Vector3(-1, 1, nzNear).unproject(threeCamera)
                    const nearC = new THREE.Vector3(1, 1, nzNear).unproject(threeCamera)
                    const nearD = new THREE.Vector3(1, -1, nzNear).unproject(threeCamera)

                    const farA = new THREE.Vector3(-1, -1, nzFar).unproject(threeCamera)
                    const farB = new THREE.Vector3(-1, 1, nzFar).unproject(threeCamera)
                    const farC = new THREE.Vector3(1, 1, nzFar).unproject(threeCamera)
                    const farD = new THREE.Vector3(1, -1, nzFar).unproject(threeCamera)

                    const bufferPositionProxyFrustrum = this.proxyFrustrum.geometry.getAttribute("position") as THREE.Float32BufferAttribute
                    bufferPositionProxyFrustrum.set(
                        [
                            // frustum lines
                            nearA,
                            farA,
                            nearB,
                            farB,
                            nearC,
                            farC,
                            nearD,
                            farD,
                            // frustum near rectangle
                            nearA,
                            nearB,
                            nearB,
                            nearC,
                            nearC,
                            nearD,
                            nearD,
                            nearA,
                            // frustum far rectangle
                            farA,
                            farB,
                            farB,
                            farC,
                            farC,
                            farD,
                            farD,
                            farA,
                        ]
                            .map((v) => [v.x, v.y, v.z])
                            .flat(),
                    )
                    bufferPositionProxyFrustrum.needsUpdate = true

                    const direction = new THREE.Vector3(0, 0, nzFar).unproject(threeCamera).normalize()
                    const focusPoint = direction.clone().multiplyScalar(focalDistance).add(position)

                    // normalized camera Z values:
                    const nzFocus = focusPoint.clone().project(threeCamera).z

                    const focusA = new THREE.Vector3(-1, -1, nzFocus).unproject(threeCamera)
                    const focusB = new THREE.Vector3(-1, 1, nzFocus).unproject(threeCamera)
                    const focusC = new THREE.Vector3(1, 1, nzFocus).unproject(threeCamera)
                    const focusD = new THREE.Vector3(1, -1, nzFocus).unproject(threeCamera)

                    const focusXA = new THREE.Vector3(-0.5, 0, nzFocus).unproject(threeCamera)
                    const focusXB = new THREE.Vector3(0.5, 0, nzFocus).unproject(threeCamera)
                    const focusXC = new THREE.Vector3(0, -0.5, nzFocus).unproject(threeCamera)
                    const focusXD = new THREE.Vector3(0, 0.5, nzFocus).unproject(threeCamera)

                    const bufferPositionProxyFocalPlane = this.proxyFocalPlane.geometry.getAttribute("position") as THREE.Float32BufferAttribute
                    bufferPositionProxyFocalPlane.set(
                        [
                            // focus plane
                            focusA,
                            focusC,
                            focusB,
                            focusA,
                            focusD,
                            focusC,
                        ]
                            .map((v) => [v.x, v.y, v.z])
                            .flat(),
                    )
                    bufferPositionProxyFocalPlane.needsUpdate = true

                    const bufferPositionProxyFocalPlaneOutline = this.proxyFocalPlaneOutline.geometry.getAttribute("position") as THREE.Float32BufferAttribute
                    bufferPositionProxyFocalPlaneOutline.set(
                        [
                            // focus rectangle
                            focusA,
                            focusB,
                            focusB,
                            focusC,
                            focusC,
                            focusD,
                            focusD,
                            focusA,
                            // focus cross
                            focusXA,
                            focusXB,
                            focusXC,
                            focusXD,
                        ]
                            .map((v) => [v.x, v.y, v.z])
                            .flat(),
                    )
                    bufferPositionProxyFocalPlaneOutline.needsUpdate = true

                    const transformMatrix = new THREE.Matrix4().setPosition(target)
                    updateTransform(transformMatrix, this.targetMesh)

                    const laserBuffer = this.proxyLaser.geometry.getAttribute("position") as THREE.Float32BufferAttribute
                    laserBuffer.setXYZ(0, position.x, position.y, position.z)
                    laserBuffer.setXYZ(1, target.x, target.y, target.z)
                    laserBuffer.needsUpdate = true
                },
            ),
            objectFieldsDifferent(sceneNode, this.parameters, ["targeted"], undefined, ({targeted}) => {
                this.proxyLaser.visible = targeted
                this.proxyTarget.visible = targeted
                this.proxyOccludedTarget.visible = targeted
            }),
            this.standHelper.update(sceneNode),
        ])
    }

    override dispose() {
        this.proxyFrustrum.geometry.dispose()
        this.proxyFrustrum.material.dispose()
        this.proxyFocalPlane.geometry.dispose()
        this.proxyFocalPlane.material.dispose()
        this.proxyFocalPlaneOutline.geometry.dispose()
        this.proxyFocalPlaneOutline.material.dispose()
        this.proxyBodyMesh.geometry.dispose()
        this.proxyBodyMesh.material.dispose()
        this.proxyLensMesh.geometry.dispose()
        this.proxyLensMesh.material.dispose()
        this.proxyLaser.geometry.dispose()
        this.proxyLaser.material.dispose()
        this.proxyTarget.geometry.dispose()
        this.proxyTarget.material.dispose()
        this.proxyOccludedTarget.geometry.dispose()
        this.proxyOccludedTarget.material.dispose()
        this.standHelper.dispose()
    }

    override onSelectionChange(selected: boolean) {
        const targeted = this.parameters?.targeted ?? false

        this.proxyFrustrum.visible = selected
        this.proxyFocalPlane.visible = selected
        this.proxyFocalPlaneOutline.visible = selected
        this.proxyLaser.visible = selected && targeted
        this.proxyTarget.visible = selected && targeted
        this.proxyOccludedTarget.visible = selected && targeted
    }
}
