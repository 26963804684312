<cm-inspector-section>
    <ng-container cm-title> Render</ng-container>
    <ng-container cm-content>
        @if (node) {
            <div class="cm-resolution">
                <cm-input-container>
                    <ng-container cm-inline-label> W:</ng-container>
                    <ng-container cm-input>
                        <cm-node-reference-slot fallbackText="" [node]="$any(node.width)" (nodeChange)="node.width = $any($event); updateNode(node)">
                            <ng-container cm-fallback>
                                <cm-numeric-input [(value)]="$any(node).width" (valueChange)="updateNode(node)" [decimalPlaces]="0"></cm-numeric-input>
                            </ng-container>
                        </cm-node-reference-slot>
                    </ng-container>
                </cm-input-container>
                <div class="cm-multiplier">×</div>
                <cm-input-container>
                    <ng-container cm-inline-label> H:</ng-container>
                    <ng-container cm-input>
                        <cm-node-reference-slot fallbackText="" [node]="$any(node.height)" (nodeChange)="node.height = $any($event); updateNode(node)">
                            <ng-container cm-fallback>
                                <cm-numeric-input [(value)]="$any(node).height" (valueChange)="updateNode(node)" [decimalPlaces]="0"></cm-numeric-input>
                            </ng-container>
                        </cm-node-reference-slot>
                    </ng-container>
                </cm-input-container>
            </div>
            <cm-input-container>
                <ng-container cm-inline-label> Samples:</ng-container>
                <ng-container cm-input>
                    <cm-node-reference-slot fallbackText="" [node]="$any(node.samples)" (nodeChange)="node.samples = $any($event); updateNode(node)">
                        <ng-container cm-fallback>
                            <cm-numeric-input
                                [(value)]="$any(node).samples"
                                (valueChange)="updateNode(node)"
                                [decimalPlaces]="0"
                                matTooltip="Controls how noisy the image will be (the higher the less noisy)."
                            >
                            </cm-numeric-input>
                        </ng-container>
                    </cm-node-reference-slot>
                </ng-container>
            </cm-input-container>
        }
    </ng-container>
</cm-inspector-section>
