import {JsonObjectUtils} from "@cm/utils/json-object"

export namespace MaterialMapsExporter {
    const metalnessRoughnessMaps = ["diffuse", "metalness", "roughness", "specular"] as const
    const specularGlossinessMaps = ["diffuse", "reflect", "gloss", "fresnel-ior"] as const
    const singleAnisotropyMaps = ["anisotropy"] as const
    const splitAnisotropyMaps = ["anisotropy-strength", "anisotropy-rotation"] as const

    export const TEXTURE_TYPES_FOR_ENGINE = {
        metalnessRoughness: {
            cm: [...metalnessRoughnessMaps, "normal", ...singleAnisotropyMaps, "displacement"] as const,
            corona: [...metalnessRoughnessMaps, "normal", ...splitAnisotropyMaps, "displacement"] as const,
            vray: [...metalnessRoughnessMaps, "normal", ...splitAnisotropyMaps, "displacement"] as const,
            cycles: [...metalnessRoughnessMaps, "normal", ...splitAnisotropyMaps, "displacement"] as const,
        },
        specularGlossiness: {
            cm: [...specularGlossinessMaps, "normal", "anisotropy", "displacement"] as const,
            corona: [...specularGlossinessMaps, "normal", ...splitAnisotropyMaps, "displacement"] as const,
            vray: [...specularGlossinessMaps, "normal", ...splitAnisotropyMaps, "displacement"] as const,
            cycles: [...specularGlossinessMaps, "normal", ...splitAnisotropyMaps, "displacement"] as const,
        },
    } as const

    export type TextureType = (typeof TEXTURE_TYPES_FOR_ENGINE)[Workflow][keyof (typeof TEXTURE_TYPES_FOR_ENGINE)[Workflow]] extends readonly (infer T)[]
        ? T
        : never

    export const formats = ["exr", "tiff", "png", "jpeg"] as const
    export type Format = (typeof formats)[number]

    export const IMG_FORMAT_TO_ENCODER_FN: {[key in Format]: string} = {
        exr: "image/x-exr",
        tiff: "image/tiff",
        png: "image/png",
        jpeg: "image/jpeg",
    } as const

    export type ConvertedMap = {
        type: "convertedMap"
        name: string
        filename: string
        data?: JsonObjectUtils.DataObjectReference
    }

    export const engines = ["cm", "corona", "vray", "cycles"] as const
    export type Engine = (typeof engines)[number]

    export const workflows = ["metalnessRoughness", "specularGlossiness"] as const
    export type Workflow = (typeof workflows)[number]

    export const normalsY = ["default", "y+up", "y+down"] as const
    export type NormalY = (typeof normalsY)[number]

    export const resolutions = ["original", "dpi72"] as const
    export type Resolution = (typeof resolutions)[number]

    export function workflowToString(workflow: Workflow): string {
        switch (workflow) {
            case "metalnessRoughness":
                return "Metallness / Roughness"
            case "specularGlossiness":
                return "Specular / Glossiness"
        }
        return workflow
    }

    export function engineToString(engine: Engine): string {
        switch (engine) {
            case "cm":
                return "colormass"
            case "corona":
                return "Corona"
            case "vray":
                return "VRay"
            case "cycles":
                return "Cycles"
        }
        return engine
    }

    export function normalYToString(normalY: NormalY): string {
        switch (normalY) {
            case "default":
                return "Engine default"
            case "y+up":
                return "+Y up"
            case "y+down":
                return "+Y down"
        }
        return normalY
    }

    export function resolutionToString(resolution: Resolution): string {
        switch (resolution) {
            case "original":
                return "Original"
            case "dpi72":
                return "Low (72 DPI)"
        }
        return resolution
    }

    export function renameExport(exportConfig: Config, filename: string) {
        return {
            ...exportConfig,
            root: {
                ...exportConfig.root,
                name: filename,
            },
        }
    }

    export type ConversionConfig = {
        type: "conversionConfig"
        workflow: Workflow
        engine: Engine
        format: Format
        normalY: NormalY
        resolution: Resolution
        maps: ConvertedMap[]
    }

    export type TextFile = {
        type: "exportTextFile"
        name: string
        content: string
    }

    export type JsonFile = {
        type: "exportJsonFile"
        name: string
        content: Record<string, string>
    }

    export type Folder<T = ConversionConfig | TextFile | JsonFile> = {
        type: "exportFolder"
        name: string
        content: (T | Folder<T>)[]
    }

    export type TextureRevisionInfo = {
        type: "textureRevisionInfo"
        textureType: string
        revisionId: number
    }

    export type SourceInfo = {
        type: "sourceInfo"
        source: "materialRevision" | "textureSet"
        sourceId: number
        textureRevisionInfo: TextureRevisionInfo[] | undefined
    }

    export type Config = {
        type: "exportConfig"
        schema: string
        materialId: number
        sourceInfo: SourceInfo
        state: "processing" | "done"
        root: Folder
        output?: JsonObjectUtils.DataObjectReference
    }

    export type ConversionRequest = Omit<ConversionConfig, "type" | "maps"> & {
        type: "conversionRequest"
    }

    export type SourceInfoRequest = Pick<SourceInfo, "source"> & Partial<Pick<SourceInfo, "sourceId">>

    export type Request = {
        type: "exportRequest"
        root: Folder<ConversionRequest | ConversionInfoRequest>
        sourceInfoRequest: SourceInfoRequest
    }

    export type ConversionInfoRequest = {
        type: "conversionInfoRequest"
        conversionRequest: ConversionRequest
        name: string
        format: "json" | "text"
    }

    export function conversionRequest(workflow: Workflow, engine: Engine, normalY: NormalY, format: Format, resolution: Resolution): ConversionRequest {
        return {type: "conversionRequest", workflow, engine, normalY, format, resolution}
    }
    export function exportRequest(root: Request["root"], sourceInfoRequest: SourceInfoRequest): Request {
        return {type: "exportRequest", root, sourceInfoRequest}
    }
    export function exportFolder(content: Request["root"]["content"], name: Folder["name"]): Request["root"] {
        return {type: "exportFolder", content, name}
    }
    export function conversionInfoRequest(
        conversionRequest: ConversionInfoRequest["conversionRequest"],
        name: ConversionInfoRequest["name"],
        format: ConversionInfoRequest["format"],
    ): ConversionInfoRequest {
        return {type: "conversionInfoRequest", conversionRequest: conversionRequest, name: name, format: format}
    }

    export function collectConversionConfigsForExportConfig(exportConfig: Config | Request) {
        const conversionConfigs: (ConversionConfig | ConversionRequest)[] = []

        const traverse = (folder: (typeof exportConfig)["root"]) => {
            folder.content.map((item) => {
                switch (item.type) {
                    case "exportFolder":
                        traverse(item)
                        break
                    case "conversionConfig":
                    case "conversionRequest":
                        conversionConfigs.push(item)
                        break
                }
            })
        }
        traverse(exportConfig.root)
        return conversionConfigs
    }

    /////////////////////////////////////////////////////////////////////////////////////////

    export type UpdateArrayElement<T = any> = {type: "ArrayElementUpdate"} & ({idx: number; option: "overwrite"; value: T} | {option: "append"; value: T}) // TODO remove?
    export type UpdateStructField<K extends string = string, T extends Record<K, any> = Record<K, any>> = {type: "StructFieldUpdate"; key: K; value: T[K]}
    type UpdateArrayElementDescend = {type: "ArrayElementDescend"; idx: number}
    type UpdateStructFieldDescend<K extends string = string> = {type: "StructFieldDescend"; key: K}
    export const UPDATE_OP = "UpdateOp" as const
    export type UpdateOp = {
        type: typeof UPDATE_OP
        descendSeq: (UpdateArrayElementDescend | UpdateStructFieldDescend)[]
        update: UpdateArrayElement | UpdateStructField
    }

    export type RecordKeysForValueType<T, V> =
        T extends Record<string, any> ? (Required<T> extends infer T_ ? keyof {[K_ in keyof T_ as T_[K_] extends V ? K_ : never]: string} : never) : never

    // type UpdateStructFieldDescendKeyT_<T> = T extends Record<string, any> ? Required<T> extends infer _ ? keyof { [K in keyof _ as _[K] extends number | string | boolean ? never : K]: _[K] } : never : never;
    // type UpdateStructFieldDescendKeyT = UpdateStructFieldDescendKeyT_<ConversionConfig | Config | Folder>;

    export function descendSeqForStructOrArray<RootT extends Object, StructT extends Object>(
        root: RootT,
        target: StructT,
    ): {found: boolean; seq: UpdateOp["descendSeq"]} {
        let seq: UpdateOp["descendSeq"] = []
        let found: boolean

        const traverse = (x: Object): boolean => {
            if (!(x instanceof Object)) return false

            if (x instanceof Array) {
                for (let idx = 0; idx < x.length; idx++) {
                    if (x[idx] === target || traverse(x[idx])) {
                        seq = [{type: "ArrayElementDescend", idx: idx}, ...seq]
                        return true
                    }
                }
            } else if (x instanceof Object) {
                for (const key of Object.keys(x)) {
                    const val: any = (x as any)[key]
                    if (key in x && (val === target || traverse(val))) {
                        seq = [{type: "StructFieldDescend", key: key}, ...seq]
                        return true
                    }
                }
            }
            return false
        }

        if ((root as any) === (target as any)) {
            found = true
        } else {
            found = traverse(root)
        }

        return {found, seq}
    }

    type UpdateStructT = Config | Folder | ConversionConfig | ConvertedMap | JsonObjectUtils.DataObjectReference
    export function createConfigStructFieldUpdateOp<T extends UpdateStructT, K extends keyof T extends string ? keyof T : never>(
        config: Config,
        struct: T,
        key: K,
        value: T[K],
    ): UpdateOp {
        const {found, seq} = descendSeqForStructOrArray(config, struct)
        if (!found) throw Error("Failed to build the descend sequence")

        return {
            type: "UpdateOp",
            descendSeq: seq,
            update: {
                type: "StructFieldUpdate",
                key: key,
                value: value,
            },
        }
    }

    function applyUpdateOp<RootT extends Record<string, any>>(root: RootT, updateOp: UpdateOp): RootT {
        let obj: any = root

        updateOp.descendSeq.map((descend) => {
            if (descend.type === "ArrayElementDescend") {
                if (!(obj instanceof Array)) throw Error("Expected array instance!")
                obj = obj[descend.idx]
            } else if (descend.type === "StructFieldDescend") {
                if (!(obj instanceof Object)) throw Error("Expected an object!")
                obj = obj[descend.key]
            } else {
                throw Error(`Unknown descend type: ${(descend as any).type}`)
            }
        })

        const update = updateOp.update

        if (update.type === "ArrayElementUpdate") {
            if (update.option === "append") {
                obj.push(update.value)
            } else if (update.option === "overwrite") {
                obj[update.idx] = update.value
            } else {
                throw Error(`Unknown option for array element update: ${(update as any).option}`)
            }
        } else if (updateOp.update.type === "StructFieldUpdate") {
            obj[update.key] = update.value
        } else {
            throw Error(`Unknown update type: ${(updateOp.update as any).type}`)
        }

        return root
    }

    export function applyConfigUpdateOp(config: Config, updateOp: UpdateOp): Config {
        return applyUpdateOp<Config>(config, updateOp)
    }
}
