<mat-paginator
    [disabled]="false"
    [pageIndex]="page ?? paging.$page()"
    [pageSize]="pageSize ?? paging.$pageSize()"
    (page)="setPage($event)"
    [pageSizeOptions]="pageSizeOptions"
    [hidePageSize]="!pageSizeOptions.length"
    [length]="totalCount"
    aria-label="Select page"
></mat-paginator>
