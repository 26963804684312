import {Component, inject, computed, Input, model, signal, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatButtonModule} from "@angular/material/button"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {CreateMaterialData} from "@platform/components/materials/materials-grid/materials-grid.component"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsDefined} from "@cm/utils/filter"
import {AddMaterialDialogTagFragment} from "@api"

type AddMaterialData = Partial<CreateMaterialData>

@Component({
    selector: "cm-add-material-dialog",
    standalone: true,
    imports: [FormsModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatSelectModule, MatInputModule, OrganizationSelectComponent, MatButtonModule],
    templateUrl: "./add-material-dialog.component.html",
    styleUrl: "./add-material-dialog.component.scss",
})
export class AddMaterialDialogComponent implements OnInit {
    @Input({required: true}) addMaterial?: (data: AddMaterialData) => Promise<{id: string; legacyId: number} | undefined>
    @Input() isBatchMode = false

    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)

    $updateSelectedOrganization = signal<{id: string} | undefined | null>(null)
    $selectedOrganization = computed(() => {
        const updateSelectedOrganization = this.$updateSelectedOrganization()
        if (updateSelectedOrganization) return updateSelectedOrganization
        return this.organizations.$current()
    })
    $materialRangesForSelectedOrganization = computed(() => {
        return this.$materialRangeTags().filter((range) => range.organization?.id === this.$selectedOrganization()?.id)
    })
    $materialRangeTags = signal<AddMaterialDialogTagFragment[]>([])

    $name = model<string>("")

    $data = computed<AddMaterialData>(() => {
        return {
            name: this.$name(),
            materialRangeTagId: undefined,
            organizationId: this.$selectedOrganization()?.id,
        }
    })

    ngOnInit() {
        this.sdk.gql.addMaterialDialogMaterialRangeTags().then(({tags}) => {
            this.$materialRangeTags.set(tags.filter(IsDefined))
        })
    }

    canConfirmCreate = () => (!!this.$data().name || this.isBatchMode) && !!this.$data().organizationId

    updateOrganization = async (data: {organizationId: string}) => {
        this.$updateSelectedOrganization.set({id: data.organizationId})
    }

    confirmCreate = () => {
        if (this.addMaterial === undefined) throw new Error("Missing addMaterial function in add-material-button component")
        return this.addMaterial(this.$data())
    }
}
