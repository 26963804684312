import {inject, Injectable} from "@angular/core"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {uploadProcessingMetadataTask, uploadProcessingThumbnailsTask} from "@cm/job-nodes/upload-processing"
import {graphToJson} from "@cm/utils/graph-json"
import {Settings} from "@common/models/settings/settings"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"

@Injectable()
export class UploadProcessingService {
    organization = inject(OrganizationsService)
    sdk = inject(SdkService)

    public async createUploadProcessingJob(dataObjectLegacyId: number, organizationId?: string) {
        const {dataObject} = await this.sdk.gql.uploadProcessingDataObject({
            legacyId: dataObjectLegacyId,
        })
        // don't create another job if we are already processing this data object
        if (dataObject.metadataProcessingJob && dataObject.thumbnailProcessingJob) {
            return dataObject.metadataProcessingJob
        }
        const graph = JobNodes.jobGraph(
            JobNodes.task(uploadProcessingThumbnailsTask, {
                input: JobNodes.task(uploadProcessingMetadataTask, {
                    input: JobNodes.value({
                        dataObject: JobNodes.dataObjectReference(dataObjectLegacyId),
                    }),
                }),
            }),
            {
                platformVersion: Settings.APP_VERSION,
            },
        )
        return this.sdk.gql
            .uploadProcessingCreateJob({
                input: {
                    metadataProcessingJobForDataObjectLegacyId: dataObjectLegacyId,
                    name: `Upload processing for data object ${dataObjectLegacyId}`,
                    graph: graphToJson(graph),
                    organizationId: await this.organization.ensureUserIsMember(organizationId),
                    thumbnailProcessingJobForDataObjectLegacyId: dataObjectLegacyId,
                },
            })
            .then(({createJob}) => createJob)
    }
}
