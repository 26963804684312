import {CdkTreeModule, NestedTreeControl} from "@angular/cdk/tree"
import {Component, DestroyRef, OnInit, computed, effect, forwardRef, inject, output, signal} from "@angular/core"
import {MatTreeNestedDataSource} from "@angular/material/tree"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {NodeOwner, Switch, isNode, isNodeOwner, isSwitch, isTemplateContainer, isValue} from "@cm/template-nodes"
import {TemplateTreeItemComponent} from "@template-editor/components/template-tree-item/template-tree-item.component"
import {TemplateTreeAddComponent} from "@template-editor/components/template-tree-add/template-tree-add.component"
import {pairwise} from "rxjs"
import {TemplateNodeDragService, TemplateDropTarget, DroppableComponent, DropPosition} from "@app/template-editor/services/template-node-drag.service"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {Hotkeys} from "@app/common/services/hotkeys/hotkeys.service"
import {isNamedNode} from "@cm/template-nodes"
import {TemplateNode, isTemplateNode} from "@cm/template-nodes"
import {RigidRelation} from "@cm/template-nodes"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {ConfigGroup} from "@cm/template-nodes"
import {TemplateTreeFillerComponent} from "../template-tree-filler/template-tree-filler.component"
import {TemplateGraph} from "@cm/template-nodes"
import {LodType} from "@cm/template-nodes"

export type TemplateTreeNode<T extends TemplateNode = TemplateNode> = {
    parent: TemplateTreeNode<NodeOwner | Switch> | null
    node: T
}

const createTemplateTreeNode = <T extends TemplateNode>(parent: TemplateTreeNode<NodeOwner | Switch> | null, node: T): TemplateTreeNode<T> => {
    return {node, parent}
}

export const getNodeChildren = (node: TemplateTreeNode): TemplateTreeNode[] => {
    const {node: nodeValue} = node
    if (isNodeOwner(nodeValue)) return nodeValue.parameters.nodes.parameters.list.map((x) => createTemplateTreeNode(node as TemplateTreeNode<NodeOwner>, x))

    if (isSwitch(nodeValue)) {
        const supportedNodes = nodeValue.parameters.nodes.parameters.list.filter((x: unknown): x is TemplateNode => isTemplateNode(x)) as TemplateNode[]
        return supportedNodes.map((x) => createTemplateTreeNode(node as TemplateTreeNode<Switch>, x))
    }

    return []
}

export const hasChildren = (node: TemplateTreeNode): node is TemplateTreeNode<NodeOwner | Switch> => {
    const {node: nodeValue} = node
    return isNodeOwner(nodeValue) || isSwitch(nodeValue)
}

const getAllNodes = (treeControl: NestedTreeControl<TemplateTreeNode, string>, dataSource: MatTreeNestedDataSource<TemplateTreeNode>): TemplateTreeNode[] => {
    const result: TemplateTreeNode[] = []
    const stack = [...dataSource.data]
    while (stack.length > 0) {
        const node = stack.pop()!
        result.push(node)
        if (hasChildren(node)) stack.push(...treeControl.getDescendants(node))
    }
    return result
}

export const getTemplateTreeNodeId = (node: TemplateTreeNode): string => {
    const {parent} = node
    if (!parent) return `${node.node.instanceId}`
    else return `${parent.node.instanceId}-${node.node.instanceId}`
}

const isTemplateTreeItemDropTarget = (
    dropTarget: TemplateDropTarget<DroppableComponent>,
): dropTarget is TemplateDropTarget<TemplateTreeItemComponent> & {position: DropPosition} => {
    return dropTarget.component instanceof TemplateTreeItemComponent && typeof dropTarget.position === "string"
}

const getTargetNode = (dropTarget: TemplateDropTarget<DroppableComponent> | null) => {
    if (!dropTarget || !isTemplateTreeItemDropTarget(dropTarget)) return null
    const {component, position} = dropTarget
    const node = component.treeNode()

    return position === "inside" ? node : node.parent
}

@Component({
    selector: "cm-template-tree",
    standalone: true,
    templateUrl: "./template-tree.component.html",
    styleUrl: "./template-tree.component.scss",
    imports: [CdkTreeModule, forwardRef(() => TemplateTreeItemComponent), TemplateTreeAddComponent, TemplateTreeFillerComponent],
})
export class TemplateTreeComponent implements OnInit {
    requestSaveInLibrary = output<TemplateGraph>()

    sceneManagerService = inject(SceneManagerService)
    private destroyRef = inject(DestroyRef)
    private hotkeys = inject(Hotkeys)
    private clipboardService = inject(TemplateNodeClipboardService)

    rootNode = computed(() => createTemplateTreeNode(null, this.sceneManagerService.$templateGraph()))
    children = signal<TemplateTreeItemComponent[]>([])
    registerChild = (child: TemplateTreeItemComponent) => this.children.update((children) => [...children, child])
    unregisterChild = (child: TemplateTreeItemComponent) => this.children.update((children) => children.filter((x) => x !== child))

    treeControl = new NestedTreeControl<TemplateTreeNode, string>(getNodeChildren, {trackBy: (node) => node.node.instanceId})
    dataSource = new MatTreeNestedDataSource<TemplateTreeNode>()
    highlightedNodes = signal<Map<string, Set<string>>>(new Map())
    hasChildren = hasChildren
    needsAddWidget = (node: TemplateTreeNode) => {
        const templateNode = node.node
        return templateNode instanceof ConfigGroup || isSwitch(templateNode)
    }

    drag = inject(TemplateNodeDragService)
    private dropTarget$ = toObservable(this.drag.dropTarget)

    constructor() {
        effect(() => this.refreshTree())

        effect(() => {
            const allNodes = getAllNodes(this.treeControl, this.dataSource)

            const selectedTreeNodes = this.sceneManagerService
                .$selectedNodeParts()
                .map((selectedNodePart) =>
                    allNodes.find((node) => node.node === selectedNodePart.templateNode && (node.parent === null || isNodeOwner(node.parent.node))),
                )
                .filter((x): x is TemplateTreeNode<NodeOwner> => x !== undefined)

            selectedTreeNodes.forEach((selectedTreeNode) => {
                let {parent} = selectedTreeNode
                while (parent) {
                    this.treeControl.expand(parent)
                    parent = parent.parent
                }
            })
        })
    }

    ngOnInit() {
        this.dropTarget$.pipe(takeUntilDestroyed(this.destroyRef), pairwise()).subscribe(([previousDropTarget, dropTarget]) => {
            const previousTargetNode = getTargetNode(previousDropTarget)
            const targetNode = getTargetNode(dropTarget)

            if (previousTargetNode === targetNode) return

            if (previousTargetNode) this.highlightNode(previousTargetNode, "nodeDrop", false)
            if (targetNode) this.highlightNode(targetNode, "nodeDrop", true)
        })

        this.drag.draggedItem$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({dragSource, dropTarget}) => {
            if (isTemplateTreeItemDropTarget(dropTarget)) {
                dropTarget.component.moveNodeTo(dragSource, dropTarget.position)

                const targetNode = getTargetNode(dropTarget)
                if (targetNode) this.treeControl.expand(targetNode)
            }
        })

        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            for (const [node, difference] of differences.modifiedNodes) {
                if (isTemplateNode(node)) {
                    if (
                        ((isNodeOwner(node) || isSwitch(node)) && difference.find((x) => x.path === "nodes")) ||
                        isTemplateContainer(node) ||
                        (isNamedNode(node) && difference.find((x) => x.path === "name") !== undefined) ||
                        (node instanceof RigidRelation && difference.find((x) => x.path === "targetA" || x.path === "targetB")) ||
                        (isValue(node) && difference.find((x) => x.path === "value")) ||
                        (node instanceof LodType && difference.find((x) => x.path === "lodType"))
                    ) {
                        this.refreshTree()
                        return
                    }
                }
            }
        })

        this.sceneManagerService.templateSwapped$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.highlightedNodes.set(new Map())
        })

        this.hotkeys
            .addShortcut("undo")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.sceneManagerService.undo())
        this.hotkeys
            .addShortcut("redo")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.sceneManagerService.redo())
        this.hotkeys
            .addShortcut("copy")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.copy())
        this.hotkeys
            .addShortcut("paste")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.paste())
        this.hotkeys
            .addShortcut("delete")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.sceneManagerService.deleteNodes(this.sceneManagerService.$selectedNodeParts()))
    }

    private refreshTree() {
        const templateGraph = this.sceneManagerService.$templateGraph()
        //@ts-ignore
        this.dataSource.data = null //This needs to be done to force the dataSource to update
        const rootNode = this.rootNode()
        this.dataSource.data = templateGraph.parameters.nodes.parameters.list.map((x) => createTemplateTreeNode(rootNode, x))
        this.treeControl.expand(rootNode)
    }

    highlightNode(node: TemplateTreeNode, highlightId: string, value: boolean) {
        const id = getTemplateTreeNodeId(node)

        this.highlightedNodes.update((highlightedNodes) => {
            if (value) {
                const newHighlightedNodes = new Map(highlightedNodes)
                if (newHighlightedNodes.has(id)) {
                    const ids = newHighlightedNodes.get(id)!
                    ids.add(highlightId)
                } else newHighlightedNodes.set(id, new Set([highlightId]))

                return newHighlightedNodes
            } else {
                if (!highlightedNodes.has(id)) return highlightedNodes
                const highlightIds = highlightedNodes.get(id)!
                if (!highlightIds.has(highlightId)) return highlightedNodes

                const newHighlightedNodes = new Map(highlightedNodes)
                highlightIds.delete(highlightId)
                if (highlightIds.size === 0) newHighlightedNodes.delete(id)

                return newHighlightedNodes
            }
        })
    }

    isHighlighted(node: TemplateTreeNode, highlightId?: string) {
        const highlightIds = this.highlightedNodes().get(getTemplateTreeNodeId(node))
        if (!highlightIds) return false
        if (highlightId) return highlightIds.has(highlightId)
        return highlightIds.size > 0
    }

    private copy() {
        const selectedNodes = this.sceneManagerService.$selectedNodeParts().map((selectedNodePart) => selectedNodePart.templateNode)
        this.clipboardService.copy(selectedNodes)
    }

    private paste() {
        if (!this.clipboardService.valid()) return

        const selectedNodes = this.sceneManagerService.$selectedNodeParts().map((selectedNodePart) => selectedNodePart.templateNode)
        const nodeOwners = new Set(
            selectedNodes.map((selectedNode) => (isNodeOwner(selectedNode) ? selectedNode : undefined)).filter((x): x is NodeOwner => x !== undefined),
        )

        this.sceneManagerService.modifyTemplateGraph((templateGraph) => {
            if (nodeOwners.size === 0) nodeOwners.add(templateGraph)

            nodeOwners.forEach((nodeOwner) => {
                this.clipboardService
                    .pasteDuplicates()
                    .filter(isNode)
                    .forEach((node) => nodeOwner.parameters.nodes.addEntry(node))
            })
        })
    }
}
