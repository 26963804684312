import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertNoBatching} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"

export type ParameterType = {
    sourceImage: ImageRef
    kernel: {
        width: number
        height: number
        values: number[] // row-major
    }
    borderMode?: BorderMode // default: "clamp"
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export type BorderMode = AddressMode | "renormalize"

const imageOpConvolve: ImageOpType<ParameterType, ReturnType> = {
    name: "Convolve",

    WebGL2: ({cmdQueue, parameters: {sourceImage, kernel, borderMode, resultImageOrDataType}}) => {
        assertNoBatching(sourceImage.descriptor)
        if (kernel.values.length != kernel.width * kernel.height) {
            throw new Error("Kernel values array length doesn't match the kernel size")
        }
        borderMode ??= "clamp"
        const painter = cmdQueue.createPainter(
            "compositor",
            "convolve",
            `
            uniform float u_kernel[${kernel.width} * ${kernel.height}];

            vec4 computeColor(ivec2 targetPixel) {
                vec4 color = vec4(0);
                ivec2 kernelOfs = (ivec2(${kernel.width}, ${kernel.height}) - 1) / 2;
                float kernelSum = 0.0;
                for (int ky = 0; ky < ${kernel.height}; ky++) {
                    for (int kx = 0; kx < ${kernel.width}; kx++) {
                        float k = u_kernel[kx + ky * ${kernel.width}];
                        ivec2 sourcePixelIndex = targetPixel + ivec2(kx, ky) - kernelOfs;
                        vec4 i = texelFetch0(sourcePixelIndex, ${getHalAddressMode(borderMode === "renormalize" ? "border" : borderMode)});
                        color += i * k;
                        if (!isBorderTexel(0, sourcePixelIndex)) {
                            kernelSum += k;
                        }
                    }
                }
                ${borderMode === "renormalize" ? `color /= kernelSum;` : ""}
                return color;
            }
        `,
        )
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, sourceImage.descriptor)
        cmdQueue.paint(painter, {
            parameters: {
                u_kernel: {type: "float[]", value: kernel.values},
            },
            sourceImages: sourceImage,
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, kernel, borderMode, resultImageOrDataType}}) => {
        assertNoBatching(sourceImage.descriptor)
        if (kernel.values.length != kernel.width * kernel.height) {
            throw new Error("Kernel values array length doesn't match the kernel size")
        }
        borderMode ??= "clamp"
        const translateBorderMode = (borderMode: BorderMode) => {
            switch (borderMode) {
                case "border":
                    return "zero"
                default:
                    return borderMode
            }
        }
        const resultNode = cmdQueue.createImage(sourceImage.descriptor, {
            type: "convolve",
            input: sourceImage,
            kernel: {type: "constKernel", width: kernel.width, height: kernel.height, data: kernel.values},
            borderMode: translateBorderMode(borderMode),
        })
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function convolve(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpConvolve, parameters)
}
