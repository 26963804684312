import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TemplateImageData, TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"

const TD = TypeDescriptors

const transformColorOverlayDescriptor = {
    material: TD.inlet(TD.MaterialGraph),
    size: TD.inlet(TD.Tuple<[number, number]>()),
    image: TD.inlet(TD.TemplateImageData),
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    useAlpha: TD.inlet(TD.Boolean),
    outputMaterial: TD.outlet(TD.MaterialGraph),
}

export class TransformColorOverlay implements NodeClassImpl<typeof transformColorOverlayDescriptor, typeof TransformColorOverlay> {
    static descriptor = transformColorOverlayDescriptor
    static uniqueName = "TransformColorOverlay"
    material!: Inlet<IMaterialGraph>
    size!: Inlet<[number, number]>
    image!: Inlet<TemplateImageData>
    sceneManager!: Inlet<ISceneManager>
    useAlpha!: Inlet<boolean>
    outputMaterial!: Outlet<IMaterialGraph>

    run() {
        const material = this.material
        const image = this.image
        const size = this.size
        const sceneManager = this.sceneManager
        const useAlpha = this.useAlpha
        if (material === NotReady || image === NotReady || size === NotReady || sceneManager === NotReady || useAlpha === NotReady) {
            this.outputMaterial.emitIfChanged(NotReady)
            return
        }
        this.outputMaterial.emitIfChanged(image ? sceneManager.transformColorOverlay(material, image.dataObject, size, useAlpha) : material)
    }
}
