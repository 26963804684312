import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {
    DataObject,
    getDataObjectFromImageResourceForFormatAndResolution,
    ImageResourceSchema,
    isResolvedResourceWithTransientDataObject,
    ResolvedResource,
    IMaterialNodeGraphTransientDataObject,
} from "#material-nodes/material-node-graph"
import {threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color, Context} from "#material-nodes/types"
import {GetProperty} from "@cm/graph/utils"
import {extensionForContentType} from "@cm/utils/content-types"
import {ImageColorSpace, ImageFormat, MediaType} from "@cm/utils/data-object"
import {catchError, defer, tap} from "rxjs"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

export const textureResolution = z.enum(["500px", "1000px", "2000px", "original"])
export type TextureResolution = z.infer<typeof textureResolution>

type LoadTextureDescriptor = {
    lowRes?: string
    primary: string | IMaterialNodeGraphTransientDataObject
    colorSpace?: THREE.ColorSpace
}

function selectTextureFormatAndResolutionFromOptionsAndMediaType(
    context: Context,
    mediaType: MediaType,
    alphaIsImportant: boolean,
    forceOriginalResolution: boolean,
): {primary: {format: ImageFormat; resolution: TextureResolution}; lowRes?: {format: ImageFormat; resolution: TextureResolution}} {
    if (forceOriginalResolution || (mediaType === "image/png" && alphaIsImportant)) {
        // Image may have an alpha channel, so use _only_ the original data object!
        return {
            primary: {
                format: (() => {
                    try {
                        const format = extensionForContentType(mediaType)
                        if (format === "jpg" || format === "png") return format
                        throw Error(`Unsupported format ${format}`)
                    } catch (e) {
                        console.warn(`Failed to get supported extension for content type ${mediaType}, defaulting to png`)
                        return "png"
                    }
                })(),
                resolution: "original",
            },
        }
    }

    return {
        primary: {
            format: "jpg",
            resolution: context.textureResolution,
        },
        lowRes: context.progressiveTextureLoading && context.textureResolution !== "500px" ? {format: "jpg", resolution: "500px"} : undefined,
    }
}

function getTextureDescriptorForImageResource(
    context: Context,
    resolvedResource: ResolvedResource,
    alphaIsImportant: boolean,
    forceOriginalResolution: boolean,
): LoadTextureDescriptor {
    if (isResolvedResourceWithTransientDataObject(resolvedResource)) {
        return {
            primary: resolvedResource.transientDataObject,
            colorSpace: getThreeJSColorSpaceForDataObject(resolvedResource.transientDataObject),
        }
    } else {
        const {primary, lowRes} = selectTextureFormatAndResolutionFromOptionsAndMediaType(
            context,
            resolvedResource.mainDataObject.mediaType,
            alphaIsImportant,
            forceOriginalResolution,
        )
        const primaryDataObject = getDataObjectFromImageResourceForFormatAndResolution(resolvedResource, primary.format, primary.resolution)
        const lowResDataObject = lowRes ? getDataObjectFromImageResourceForFormatAndResolution(resolvedResource, lowRes.format, lowRes.resolution) : undefined

        if (!primaryDataObject)
            throw Error(`No primary data object found for image resource (data object legacyId: ${resolvedResource.mainDataObject.legacyId})`)

        return {
            lowRes: lowResDataObject?.downloadUrl,
            primary: primaryDataObject.downloadUrl,
            colorSpace: getThreeJSColorSpaceForDataObject(primaryDataObject),
        }
    }
}

function getThreeJSColorSpaceForDataObject(dataObject: DataObject | IMaterialNodeGraphTransientDataObject) {
    if (dataObject.imageColorSpace === ImageColorSpace.Linear) {
        return THREE.LinearSRGBColorSpace
    } else if (dataObject.imageColorSpace === ImageColorSpace.Srgb) {
        return THREE.SRGBColorSpace
    }
    return undefined
}

const ReturnTypeSchema = z.object({color: materialSlots, alpha: materialSlots})
const InputTypeSchema = z.object({
    vector: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    alpha: z.number().optional(),
    color: color.optional(),
    extension: z.enum(["REPEAT", "EXTEND", "CLIP", "MIRROR"]).optional(),
    imageColorspaceSettingsName: z.enum(["Linear", "Non-Color", "sRGB"]).optional(),
    interpolation: z.enum(["Linear", "Cubic", "Closest", "Smart"]).optional(),
    projection: z.enum(["FLAT", "BOX", "SPHERE", "TUBE"]).optional(),
    imageResource: ImageResourceSchema.optional(),
    disableProgressiveLoading: z.boolean().optional(),
    forceOriginalResolution: z.boolean().optional(),
})

export class TexImage extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async function (this: TexImage, {get, inputs, parameters, context}) {
            const {imageResource, imageColorspaceSettingsName, extension, interpolation} = parameters
            const {onThreeCreatedTexture, progressiveTextureLoading, forceFiltering, onRequestRedraw, addTask} = context

            if (!imageResource) {
                console.warn("No image resource provided")
                return {
                    color: threeRGBColorNode(parameters.color ?? {r: 0.5, g: 0.5, b: 0.5}),
                    alpha: threeValueNode(1),
                }
            }

            const getInputColorSpace = () => {
                switch (imageColorspaceSettingsName) {
                    case "Linear":
                        return THREE.LinearSRGBColorSpace
                    case "Non-Color":
                        return THREE.NoColorSpace
                    case "sRGB":
                        return THREE.SRGBColorSpace
                }
                return undefined
            }

            const getWrapping = () => {
                switch (extension) {
                    case "REPEAT":
                        return THREE.RepeatWrapping
                    case "EXTEND":
                        return THREE.ClampToEdgeWrapping
                    case "MIRROR":
                        return THREE.MirroredRepeatWrapping
                }
                return undefined
            }

            const getMinFilter = (): THREE.MinificationTextureFilter | undefined => {
                if (forceFiltering === "nearest") return THREE.NearestFilter
                else if (forceFiltering === "linear") return THREE.LinearMipMapLinearFilter

                switch (interpolation) {
                    case "Linear":
                        return THREE.LinearMipMapLinearFilter
                    case "Smart":
                        return THREE.LinearMipMapLinearFilter
                    case "Closest":
                        return THREE.NearestFilter
                }
                return undefined
            }

            const getMagFilter = (): THREE.MagnificationTextureFilter | undefined => {
                if (forceFiltering === "nearest") return THREE.NearestFilter
                else if (forceFiltering === "linear") return THREE.LinearFilter

                switch (interpolation) {
                    case "Linear":
                        return THREE.LinearFilter
                    case "Closest":
                        return THREE.NearestFilter
                }
                return undefined
            }

            const alphaIsImportant = [...this.parents].some((parent) => parent instanceof GetProperty && parent.parameters.key === "alpha")

            const {
                primary,
                lowRes,
                colorSpace: dataObjectColorSpace,
            } = getTextureDescriptorForImageResource(
                {...context, progressiveTextureLoading: parameters.disableProgressiveLoading ? false : progressiveTextureLoading},
                imageResource,
                alphaIsImportant,
                parameters.forceOriginalResolution ?? false,
            )
            const colorSpace = dataObjectColorSpace ?? getInputColorSpace()
            const wrapping = getWrapping()
            const minFilter = getMinFilter()
            const magFilter = getMagFilter()

            if (!colorSpace) console.warn("No color space value provided, using sRGB")
            if (!minFilter) console.warn(`Warning: Unsupported minification filter ${interpolation}, using LinearMipMapLinearFilter`)
            if (!magFilter) console.warn(`Warning: Unsupported magnification filter ${interpolation}, using LinearFilter`)
            if (!wrapping) console.warn(`Warning: Unsupported extension mode: ${extension}, using repeat instead`)

            const setupTexture = (texture: THREE.Texture) => {
                texture.colorSpace = colorSpace ?? THREE.SRGBColorSpace
                texture.minFilter = getMinFilter() ?? THREE.LinearMipMapLinearFilter
                texture.magFilter = getMagFilter() ?? THREE.LinearFilter
                texture.wrapS = texture.wrapT = wrapping ?? THREE.RepeatWrapping
                texture.anisotropy = 1
                return texture
            }

            const textureLoader = new THREE.TextureLoader()

            const firstTexture = lowRes ?? primary
            const secondTexture = firstTexture !== primary ? primary : undefined

            THREE.Cache.enabled = true

            const loadTexture = (texture: string | IMaterialNodeGraphTransientDataObject, critical: boolean) => {
                const url = typeof texture === "string" ? texture : texture.toObjectURL()

                const loadTexturePromise = async (texture: string | IMaterialNodeGraphTransientDataObject) => {
                    return new Promise<THREE.Texture>((resolve, reject) => {
                        const cleanup = () => {
                            if (typeof texture !== "string") URL.revokeObjectURL(url)
                        }

                        textureLoader.load(
                            url,
                            (texture) => {
                                resolve(setupTexture(texture))
                                cleanup()
                            },
                            undefined,
                            () => {
                                reject("Failed to load texture")
                                cleanup()
                            },
                        )
                    })
                }

                return new Promise<THREE.Texture>((resolve, reject) => {
                    const observable = defer(() => loadTexturePromise(firstTexture)).pipe(
                        tap((x) => {
                            resolve(x)
                        }),
                        catchError((error) => {
                            reject(error)
                            throw error
                        }),
                    )

                    addTask({
                        description: `loadTexture(${url})`,
                        task: observable,
                        critical,
                    })
                })
            }

            const texture = await loadTexture(firstTexture, true)
            const textureNode = THREENodes.texture(texture, await get(inputs.vector))
            onThreeCreatedTexture?.(textureNode)

            if (secondTexture)
                loadTexture(secondTexture, false)
                    .then((secondTexture) => {
                        textureNode.value.dispose()
                        textureNode.value = secondTexture
                        onRequestRedraw?.(this)
                    })
                    .catch(() => {
                        console.warn("Failed to load second texture")
                    })

            return {
                color: textureNode,
                alpha: new THREENodes.SplitNode(textureNode, "a"),
            }
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
