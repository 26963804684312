import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, input, OnChanges, OnDestroy, output, SimpleChanges} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ConfigButtonComponent} from "@app/common/components/viewers/configurator/config-menu/icons-accordion-menu/config-button/config-button.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {VariantConditionNode} from "@cm/pricing/nodes/variant-condition-node"
import {ConfigInfo, isConfigInput, VariantInfo} from "@cm/template-nodes/interface-descriptors"
import {Observable, Subject, takeUntil} from "rxjs"

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-variant-condition",
    templateUrl: "./variant-condition-node.component.html",
    styleUrls: ["./variant-condition-node.component.scss", "../node-styles.scss"],
    imports: [ConfigButtonComponent],
})
export class VariantConditionNodeComponent implements OnChanges, OnDestroy {
    variantConditionNode = input.required<VariantConditionNode>()
    $conditionRemoved = output<void>()

    configMenuService = inject(ConfigMenuService)
    private cdr = inject(ChangeDetectorRef)
    private nodeChanged = new Subject<void>()
    private destroyRef = inject(DestroyRef)

    constructor() {
        this.configMenuService.interfaceNew$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.cdr.markForCheck()
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.variantConditionNode) {
            this.nodeChanged.next()
            this.subscribeToNodeChanges()
        }
    }

    ngOnDestroy() {
        this.nodeChanged.next()
        this.nodeChanged.complete()
    }

    private subscribeToNodeChanges() {
        if (this.variantConditionNode) {
            this.variantConditionNode()
                .nodeChanged$.pipe(takeUntil(this.nodeChanged))
                .subscribe(() => this.cdr.markForCheck())
        }
    }

    getTooltip(variantId: string): string {
        const variant = this.configMenuService.getVariant(variantId)
        if (!variant) return variantId
        return variant.name
    }

    getVariant(variantId: string): VariantInfo | undefined {
        return this.configMenuService.getVariant(variantId)
    }

    getDescriptorForVariant(variantId: string): ConfigInfo | undefined {
        const descriptor = this.configMenuService.getDescriptorForVariant(variantId)
        if (!descriptor) return undefined
        if (!isConfigInput(descriptor)) throw new Error(`Descriptor for variant ${variantId} is not a ConfigInput`)
        return descriptor
    }

    getIconDataobjectId(variantId: string): number | undefined {
        const variant = this.configMenuService.getVariant(variantId)
        if (!variant) throw new Error(`Variant ${variantId} not found`)
        return variant.iconDataObjectId
    }

    fetchThumbnailForDataObject(id?: number): Observable<string | null> {
        return this.configMenuService.fetchThumbnailForDataObject(id)
    }

    removeVariant(variantId: string, $event: Event): void {
        this.variantConditionNode().removeDependency(variantId)
        $event.stopPropagation()

        this.$conditionRemoved.emit()
    }

    getVariantIds(): string[] {
        return this.variantConditionNode().getVariantIds()
    }
}
