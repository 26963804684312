import {Injectable} from "@angular/core"
import {Observable} from "rxjs"

@Injectable()
export class EditorService {
    public organizationId: string | null = null
    // to be removed
    public organizationLegacyId: number | null = null
    public addTask!: (task: Observable<any>) => void

    constructor() {}
}
