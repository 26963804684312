import {Component} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {Render} from "@cm/template-nodes"

@Component({
    selector: "cm-render-inspector",
    standalone: true,
    templateUrl: "./render-inspector.component.html",
    styleUrl: "./render-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class RenderInspectorComponent extends BaseInspectorComponent<Render> {}
