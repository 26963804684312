import {z} from "zod"

export const RenderNodeBaseSchema = z.object({})
export type RenderNodeBase = z.infer<typeof RenderNodeBaseSchema>

export const DataObjectReferenceSchema = RenderNodeBaseSchema.and(
    z.object({
        type: z.literal("dataObjectReference"),
        dataObjectId: z.number(),
    }),
)
export type DataObjectReference = z.infer<typeof DataObjectReferenceSchema>

export const DataSchema = DataObjectReferenceSchema
export type Data = z.infer<typeof DataSchema>

export const LoadImageSchema = RenderNodeBaseSchema.and(
    z.object({
        type: z.literal("loadImage"),
        data: DataSchema,
    }),
)
export type LoadImage = z.infer<typeof LoadImageSchema>
export const ImageSchema = LoadImageSchema
export type Image = z.infer<typeof ImageSchema>

export type ShaderNode = RenderNodeBase & {
    type: string
    inputs?: {[name: string]: readonly [ShaderNode, string]}
    parameters?: {[name: string]: Exclude<any, null | Array<any>> | Array<Exclude<any, null>>}
    resources?: {[name: string]: Image}
}

export const ShaderNodeSchema: z.ZodType<ShaderNode> = z.lazy(() =>
    RenderNodeBaseSchema.and(
        z.object({
            type: z.string(),
            inputs: z.record(z.tuple([ShaderNodeSchema, z.string()])).optional(),
            parameters: z
                .record(
                    z.string(),
                    z
                        .any()
                        .refine((value) => value !== null, {message: "Shader node parameter value can not be null!"})
                        .refine((value) => (value instanceof Array ? !value.includes(null) : true), {
                            message: "Shader node parameter value can not be an array containing a null!",
                        }),
                )
                .optional(),
            resources: z.record(z.string(), ImageSchema).optional(),
        }),
    ),
)
