import {MaterialSocket} from "@material-editor/models/material-socket"

export const TransparentBsdfOutputs: Record<string, MaterialSocket> = {
    bsdf: {paramType: "socket", id: "BSDF", type: "output", valueType: "output", label: "BSDF"},
}

export const TransparentBsdfInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "plain", label: "Normal"},
}
