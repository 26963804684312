import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {numberLike, stringLike} from "#template-nodes/node-types"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {FindMaterialNew} from "#template-nodes/runtime-graph/nodes/find-material-new"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {z} from "zod"

const findMaterialParameters = z.object({
    customerId: numberLike.optional(),
    articleId: stringLike.optional(),
})
export type FindMaterialParameters = z.infer<typeof findMaterialParameters>

@registerNode
export class FindMaterial
    extends DeclareTemplateNode({parameters: findMaterialParameters}, {}, {nodeClass: "FindMaterial"})
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {templateContext} = evaluator
        const {sceneManager, defaultCustomerId} = templateContext
        const {materialGraph} = scope.node(FindMaterialNew, {
            sceneManager,
            articleId: evaluator.evaluateString(scope, this.parameters.articleId ?? null),
            customerId: evaluator.evaluateNumber(scope, this.parameters.customerId ?? defaultCustomerId ?? null),
        })
        return materialGraph
    }
}

export type FindMaterialFwd = TemplateNode<FindMaterialParameters> & EvaluableTemplateNode<IMaterialGraph | null>
