import {DeclareObjectNodeTS, ObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {TemplateNodeImplementation, TemplateNodeMeta, TemplateNodeTSImplementation} from "#template-nodes/declare-template-node"
import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {MeshRenderSettings, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {MeshObjectData, NodeEvaluator} from "#template-nodes/node-evaluator"
import {imageLike} from "#template-nodes/node-types"
import {MaterialAssignments} from "#template-nodes/nodes/material-assignment"
import {BuilderInlet, BuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {OnCompileContext} from "#template-nodes/types"
import {nodeInstance} from "@cm/graph/instance"
import {NodeGraphClass, NodeParameters, nodeParameters} from "@cm/graph/node-graph"
import {z} from "zod"

const meshNode = z.object({
    subdivisionRenderIterations: z.number().optional(),
    displacementTexture: imageLike.optional(),
    displacementUvChannel: z.number().optional(),
    displacementMin: z.number().optional(),
    displacementMax: z.number().optional(),
    displacementNormalStrength: z.number().optional(),
    displacementNormalSmoothness: z.number().optional(),
    displacementNormalOriginalResolution: z.boolean().optional(),
    materialAssignments: nodeInstance(MaterialAssignments),
    materialSlotNames: z.record(z.string()),
    visibleDirectly: z.boolean(),
    visibleInReflections: z.boolean(),
    visibleInRefractions: z.boolean(),
    receiveRealtimeShadows: z.boolean(),
    castRealtimeShadows: z.boolean(),
})
export type MeshNode = z.infer<typeof meshNode>

export function DeclareMeshNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters> & MeshNode & ObjectNode>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters> & MeshNode & ObjectNode>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareMeshNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

export function DeclareMeshNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes & MeshNode & ObjectNode>,
    meta: TemplateNodeMeta<ParamTypes & MeshNode & ObjectNode>,
): NodeGraphClass<TemplateMeshNode<ParamTypes>> {
    const retClass = class extends DeclareObjectNodeTS<ParamTypes & MeshNode & ObjectNode>(
        {...implementation, validation: {paramsSchema: meshNode.and(implementation.validation?.paramsSchema ?? nodeParameters)}},
        meta,
    ) {
        setupMesh(scope: GraphBuilderScope, context: OnCompileContext, meshData: BuilderOutlet<MeshData>, isProcedural: boolean) {
            const {evaluator, topLevelObjectId} = context
            const {templateScope} = evaluator

            const {
                displacementTexture,
                displacementUvChannel,
                displacementMin,
                displacementMax,
                displacementNormalStrength,
                displacementNormalSmoothness,
                displacementNormalOriginalResolution,
                visibleDirectly,
                visibleInReflections,
                visibleInRefractions,
                materialAssignments,
                receiveRealtimeShadows,
                castRealtimeShadows,
            } = this.parameters

            const materialMap = materialAssignments.setupMaterialAssignments(scope, context)

            const displacementImage = scope.pureLambda(
                evaluator.evaluateImage(scope, displacementTexture ?? null),
                (displacementImage) => {
                    if (!displacementImage) return undefined
                    return displacementImage
                },
                "displacementImageNode",
            )

            const meshRenderSettings = scope.struct<MeshRenderSettings>("MeshRenderSettings", {
                displacementUvChannel,
                displacementMin,
                displacementMax,
                displacementNormalStrength,
                displacementNormalSmoothness,
                displacementNormalOriginalResolution,
                displacementImage,
                // cryptoMatteObjectName: topLevelObjectId ?? objProps.id,
                cryptoMatteAssetName: topLevelObjectId ?? undefined,
            })

            this.setupObject(scope, context, "Mesh", meshData, undefined, (objectProps, {visible}) => {
                const meshObjectData = scope.struct<MeshObjectData>("MeshObjectData", {
                    id: objectProps.id,
                    matrix: objectProps.transform,
                    visible,
                    meshData,
                })
                templateScope.alias(meshObjectData, `meshObjectData-${evaluator.getLocalId(this)}`)

                return scope.struct<SceneNodes.Mesh>("Mesh", {
                    type: "Mesh",
                    ...objectProps,
                    meshData,
                    materialMap,
                    meshRenderSettings,
                    visibleDirectly,
                    visibleInReflections,
                    visibleInRefractions,
                    receiveRealtimeShadows,
                    castRealtimeShadows,
                    isDecal: false,
                    isProcedural,
                })
            })
        }

        evaluateMeshObjectData(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            return evaluator.templateScope.resolve<MeshObjectData>(`meshObjectData-${evaluator.getLocalId(this)}`)
        }
    }
    return retClass
}

export type TemplateMeshNode<ParamTypes extends NodeParameters = {}> = TemplateObjectNode<ParamTypes & MeshNode> & {
    setupMesh(scope: GraphBuilderScope, context: OnCompileContext, meshData: BuilderOutlet<MeshData>, isProcedural: boolean): void
    evaluateMeshObjectData(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<MeshObjectData>
}
