import {Component} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogActions, MatDialogClose, MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, MembershipRole, MutationCreateUserInput, MutationUpdateUserInput, UserListItemFragment} from "@api"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {extractErrorInfo} from "@common/helpers/api/errors"

@Component({
    selector: "cm-users-grid",
    templateUrl: "./users-grid.component.html",
    styleUrls: ["./users-grid.component.scss"],
    standalone: true,
    imports: [
        CardErrorComponent,
        CardPlaceholderComponent,
        EntityCardComponent,
        FormsModule,
        InfiniteListComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        RouterModule,
    ],
})
export class UsersGridComponent extends ItemListComponent<UserListItemFragment, MutationUpdateUserInput, MutationCreateUserInput> {
    protected override _contentTypeModel = ContentTypeModel.User
    protected override _createItem = (data: MutationCreateUserInput) =>
        this.sdk.throwable
            .inviteUser({
                input: data,
            })
            .then(({inviteUser: model}) => model)
            .catch((errorResponse) => {
                throw Error(extractErrorInfo(errorResponse, {409: "User with this email already exists"}).message)
            })

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.sdk.gql
            .getUsers({
                take: take,
                skip: skip,
                filter: this.filters.userFilter(),
            })
            .then(({users, usersCount}) => ({items: users, totalCount: usersCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .getUsers({
                take: 1,
                filter: {
                    ...this.filters.userFilter(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({users}) => users?.[0] || undefined)

    organizationIds = this.sdk.$silo()?.organization?.id ? [this.sdk.$silo()?.organization?.id] : []

    async toggleOrganizationMembership(organizationIds: string[]) {
        this.newItemData = {
            ...this.newItemData,
            memberships: organizationIds.map((organizationId) => ({organizationId, role: MembershipRole.Viewer})),
        }
    }
}
