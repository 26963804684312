import {Component, Input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {ColorInputComponent} from "@common/components/inputs/color-input/color-input.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {IEditor} from "@editor/models/editor"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"

@Component({
    selector: "cm-scene-properties-inspector",
    templateUrl: "./scene-properties-inspector.component.html",
    styleUrls: ["./scene-properties-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        ToggleComponent,
        InputContainerComponent,
        ColorInputComponent,
        MatInputModule,
        MatSelectModule,
        NumericInputComponent,
        FormsModule,
        MatTooltipModule,
    ],
})
export class ScenePropertiesInspectorComponent {
    @Input() editor!: IEditor
    @Input() node: Nodes.SceneProperties | null = null

    nodeChanged(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
