import {Three as THREE} from "@cm/material-nodes/three"
import {ThreeObject} from "@template-editor/helpers/three-object"

export const HELPER_OBJECTS_LAYER = 1

export function configThreeHelperObjectLayers(object: ThreeObject) {
    object.getRenderObject().traverse((child) => child.layers.set(HELPER_OBJECTS_LAYER)) // this also sets the layer for the object itself
}

export function addObjectForHelperObjectsLayer(object: THREE.Object3D) {
    object.traverse((child) => child.layers.enable(HELPER_OBJECTS_LAYER))
}

export function renderHelperObjects(
    renderer: THREE.WebGLRenderer,
    scene: THREE.Scene,
    camera: THREE.Camera,
    autoClear: boolean = false,
    autoClearDepth: boolean = false,
) {
    const currentAutoClear = renderer.autoClear
    const currentAutoClearDepth = renderer.autoClearDepth
    renderer.autoClear = autoClear
    renderer.autoClearDepth = autoClearDepth

    const currentCameraLayers = camera.layers.mask
    camera.layers.set(HELPER_OBJECTS_LAYER)
    renderer.render(scene, camera)
    camera.layers.mask = currentCameraLayers

    renderer.autoClear = currentAutoClear
    renderer.autoClearDepth = currentAutoClearDepth
}
