@if (node) {
    <cm-inspector-section>
        <ng-container cm-title> Base Mesh </ng-container>
        <ng-container cm-content>
            <cm-node-reference-slot [filter]="NodeUtils.isMeshOrInstance" [(node)]="$any(node).mesh" (nodeChange)="updateNode(node)"></cm-node-reference-slot>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Offset </ng-container>
        <ng-container cm-content>
            <div class="cm-decal-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> U: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.offset[0]" (valueChange)="updateNode(node)" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> V: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.offset[1]" (valueChange)="updateNode(node)" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-button (click)="_chooseUV()">
                    <ng-container cm-text> Choose point </ng-container>
                </cm-button>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Rotation </ng-container>
        <ng-container cm-content>
            <div class="cm-decal-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> Rotation: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.rotation" (valueChange)="updateNode(node)" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Size </ng-container>
        <ng-container cm-content>
            <div class="cm-decal-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> U: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.size[0]!" (valueChange)="updateNode(node)" [decimalPlaces]="2" [emptyValue]="null">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> V: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.size[1]!" (valueChange)="updateNode(node)" [decimalPlaces]="2" [emptyValue]="null">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Distance </ng-container>
        <ng-container cm-content>
            <div class="cm-decal-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> Distance: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.distance" (valueChange)="updateNode(node)" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Material </ng-container>
        <ng-container cm-content>
            <cm-node-reference-slot [filter]="NodeUtils.resolvesToMaterial" [(node)]="$any(node).material" (nodeChange)="updateNode(node)">
            </cm-node-reference-slot>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Mask </ng-container>
        <ng-container cm-content>
            <div class="cm-decal-settings">
                <div class="cm-mask">
                    <cm-image-data-object
                        [dataObjectId]="hasDataObjectId(node.mask) ? node.mask.dataObjectId : undefined"
                        (dataObjectChange)="node.mask = getDataObjectReference($event); updateNode(node)"
                    >
                    </cm-image-data-object>
                    <mat-form-field>
                        <mat-label>Mask polarity</mat-label>
                        <mat-select [(ngModel)]="node.invertMask" (selectionChange)="updateNode(node)">
                            <mat-option [value]="true">White is transparent</mat-option>
                            <mat-option [value]="false">Black is transparent</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Mask type</mat-label>
                        <mat-select [(ngModel)]="node.maskType" (selectionChange)="updateNode(node)">
                            <mat-option [value]="'binary'">Binary</mat-option>
                            <mat-option [value]="'opacity'">Opacity</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </cm-inspector-section>
    @if (hasDataObjectId(node.color)) {
        <cm-inspector-section>
            <ng-container cm-title> Overlay image </ng-container>
            <ng-container cm-content>
                <cm-image-data-object
                    [dataObjectId]="node.color.dataObjectId!"
                    (dataObjectChange)="node.color = getDataObjectReference($event); updateNode(node)"
                >
                </cm-image-data-object>
            </ng-container>
        </cm-inspector-section>
    }
}
