import {Vector3, Vector3Like} from "#math/vector3"

export type QuaternionLike = {x: number; y: number; z: number; w: number}

export class Quaternion {
    constructor(
        public x: number = 0,
        public y: number = 0,
        public z: number = 0,
        public w: number = 1,
    ) {}

    equals(other: Quaternion): boolean {
        return this.x === other.x && this.y === other.y && this.z === other.z && this.w === other.w
    }

    withinEpsilon(other: Quaternion, epsilon: number): boolean {
        return (
            Math.abs(this.x - other.x) < epsilon &&
            Math.abs(this.y - other.y) < epsilon &&
            Math.abs(this.z - other.z) < epsilon &&
            Math.abs(this.w - other.w) < epsilon
        )
    }

    multiply(b: Quaternion): Quaternion {
        const ax = this.x,
            ay = this.y,
            az = this.z,
            aw = this.w
        const bx = b.x,
            by = b.y,
            bz = b.z,
            bw = b.w
        const x = ax * bw + aw * bx + ay * bz - az * by
        const y = ay * bw + aw * by + az * bx - ax * bz
        const z = az * bw + aw * bz + ax * by - ay * bx
        const w = aw * bw - ax * bx - ay * by - az * bz
        return new Quaternion(x, y, z, w)
    }

    multiplyVector(v: Vector3): Vector3 {
        const vx = v.x,
            vy = v.y,
            vz = v.z
        const qx = this.x,
            qy = this.y,
            qz = this.z,
            qw = this.w
        const tx = 2 * (qy * vz - qz * vy)
        const ty = 2 * (qz * vx - qx * vz)
        const tz = 2 * (qx * vy - qy * vx)
        return new Vector3(vx + qw * tx + qy * tz - qz * ty, vy + qw * ty + qz * tx - qx * tz, vz + qw * tz + qx * ty - qy * tx)
    }

    sqrt(): Quaternion {
        let w = this.w
        let x = this.x
        let y = this.y
        let z = this.z
        w = Math.sqrt((w + Math.sqrt(w * w + x * x + y * y + z * z)) * 0.5)
        x /= 2 * w
        y /= 2 * w
        z /= 2 * w
        return new Quaternion(x, y, z, w)
    }

    square(): Quaternion {
        return this.multiply(this)
    }

    inverse(): Quaternion {
        return new Quaternion(-this.x, -this.y, -this.z, this.w)
    }

    angleTo(b: Quaternion): number {
        return 2 * Math.acos(Math.abs(Math.max(-1, Math.min(1, this.x * b.x + this.y * b.y + this.z * b.z + this.w * b.w))))
    }

    toEuler(order: "XYZ" | "ZYX" = "XYZ"): Vector3 {
        const x = this.x,
            y = this.y,
            z = this.z,
            w = this.w
        const x2 = x + x,
            y2 = y + y,
            z2 = z + z
        const xx = x * x2,
            xy = x * y2,
            xz = x * z2
        const yy = y * y2,
            yz = y * z2,
            zz = z * z2
        const wx = w * x2,
            wy = w * y2,
            wz = w * z2
        const m11 = 1 - (yy + zz)
        const m21 = xy + wz
        const m31 = xz - wy
        const m12 = xy - wz
        const m22 = 1 - (xx + zz)
        const m32 = yz + wx
        const m13 = xz + wy
        const m23 = yz - wx
        const m33 = 1 - (xx + yy)
        if (order == "XYZ") {
            if (Math.abs(m13) < 0.9999999) {
                return new Vector3(Math.atan2(-m23, m33), Math.asin(Math.max(-1, Math.min(1, m13))), Math.atan2(-m12, m11))
            } else {
                return new Vector3(Math.atan2(m32, m22), Math.asin(Math.max(-1, Math.min(1, m13))), 0)
            }
        } else if (order == "ZYX") {
            if (Math.abs(m23) < 0.9999999) {
                return new Vector3(Math.atan2(m32, m33), Math.asin(-Math.max(-1, Math.min(1, m31))), Math.atan2(m21, m11))
            } else {
                return new Vector3(0, Math.asin(-Math.max(-1, Math.min(1, m31))), Math.atan2(-m12, m22))
            }
        } else {
            throw Error(`Unsupported Euler order ${order}`)
        }
    }

    static fromEuler(x: number, y: number, z: number, order: "XYZ" | "ZYX" = "XYZ"): Quaternion {
        const c1 = Math.cos(x / 2)
        const c2 = Math.cos(y / 2)
        const c3 = Math.cos(z / 2)
        const s1 = Math.sin(x / 2)
        const s2 = Math.sin(y / 2)
        const s3 = Math.sin(z / 2)
        if (order === "XYZ") {
            return new Quaternion(s1 * c2 * c3 + c1 * s2 * s3, c1 * s2 * c3 - s1 * c2 * s3, c1 * c2 * s3 + s1 * s2 * c3, c1 * c2 * c3 - s1 * s2 * s3)
        } else if (order === "ZYX") {
            return new Quaternion(s1 * c2 * c3 - c1 * s2 * s3, c1 * s2 * c3 + s1 * c2 * s3, c1 * c2 * s3 - s1 * s2 * c3, c1 * c2 * c3 + s1 * s2 * s3)
        } else {
            throw Error(`Unsupported Euler order ${order}`)
        }
    }

    static fromUnitVectors(fromVec: Vector3Like, toVec: Vector3Like) {
        const x1 = fromVec.x
        const y1 = fromVec.y
        const z1 = fromVec.z
        const x2 = toVec.x
        const y2 = toVec.y
        const z2 = toVec.z
        const r = x1 * x2 + y1 * y2 + z1 * z2 + 1
        let qx!: number
        let qy!: number
        let qz!: number
        let qw!: number
        if (r < Number.EPSILON) {
            if (Math.abs(x1) > Math.abs(z1)) {
                qx = -y1
                qy = x1
                qz = 0
                qw = 0
            } else {
                qx = 0
                qy = -z1
                qz = y1
                qw = 0
            }
        } else {
            qx = y1 * z2 - z1 * y2
            qy = z1 * x2 - x1 * z2
            qz = x1 * y2 - y1 * x2
            qw = r
        }
        let sc = qx * qx + qy * qy + qz * qz + qw * qw
        if (sc === 0) {
            return new Quaternion(0, 0, 0, 1)
        } else {
            sc = 1 / Math.sqrt(sc)
            return new Quaternion(qx * sc, qy * sc, qz * sc, qw * sc)
        }
    }
}
