import {Component, input} from "@angular/core"
import {AttachmentsItemType, AttachmentsComponent} from "@common/components/files/attachments/attachments.component"
import {SectionComponent} from "@common/components/item"
import {DataObjectAssignmentType} from "@api"

@Component({
    selector: "cm-files-section",
    standalone: true,
    imports: [SectionComponent, AttachmentsComponent],
    templateUrl: "./files-section.component.html",
    styleUrl: "./files-section.component.scss",
})
export class FilesSectionComponent {
    $item = input<AttachmentsItemType | null | undefined>(null)
    $assignmentTypes = input<DataObjectAssignmentType[]>([DataObjectAssignmentType.Attachment, DataObjectAssignmentType.GalleryImage], {
        alias: "assignmentTypes",
    })
}
