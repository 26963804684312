import {z} from "zod"

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null(), z.undefined()])
type Literal = z.infer<typeof literalSchema>
type Json = Literal | {[key: string]: Json} | Json[]
export const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const anyJsonValue = jsonSchema
export type AnyJSONValue = z.infer<typeof anyJsonValue>
export const isAnyJSONValue = (value: unknown): value is AnyJSONValue => anyJsonValue.safeParse(value).success
