import {HttpParams} from "@angular/common/http"
import {Settings} from "@common/models/settings/settings"
import {blobToDataURL, MimeType, UtilsService} from "@legacy/helpers/utils"
import {ApiFields, declareApiModel, EndpointUrls, MakeApiModelBase} from "@legacy/api-model/api-model"
import {EntityType} from "@legacy/models/entity-type"
import {State} from "@legacy/models/state-labels/state"
import {map, mapTo, Observable, of as observableOf, Subject, switchMap, tap, timer} from "rxjs"
import {IDataObject, ITransientDataObject} from "@cm/material-nodes/interfaces/data-object"

export class DataObjectState extends State {
    constructor(id: number, label: string, background: string) {
        super(id, label, background)
    }

    static override get(id: number): DataObjectState {
        return State.get(id, DataObjectStates)
    }
}

export const DataObjectStates: {
    Init: DataObjectState
    UploadFinished: DataObjectState
    Processing: DataObjectState
    ProcessingFailed: DataObjectState
    Completed: DataObjectState
} = {
    Init: {id: 10, label: "Init", background: "#989898"},
    UploadFinished: {id: 20, label: "Upload finished", background: "#ffab4a"},
    Processing: {id: 30, label: "Processing", background: "#7cbcb0"},
    ProcessingFailed: {id: 35, label: "Processing failed", background: "#000000"},
    Completed: {id: 40, label: "Completed", background: "#7ec17a"},
}

export class ImageColorSpace extends State {
    constructor(id: number, label: string, background: string) {
        super(id, label, background)
    }

    static override get(id: number): ImageColorSpace {
        return State.get(id, ImageColorSpaces)
    }
}

export const ImageColorSpaces: {
    Unknown: ImageColorSpace
    sRgb: ImageColorSpace
    Linear: ImageColorSpace
    Gamma2_0: ImageColorSpace
    Gamma2_2: ImageColorSpace
} = {
    Unknown: {id: 0, label: "Unknown", background: "#989898"},
    sRgb: {id: 1, label: "sRGB", background: "#989898"},
    Linear: {id: 2, label: "Linear", background: "#989898"},
    Gamma2_0: {id: 3, label: "Gamma 2.0", background: "#989898"},
    Gamma2_2: {id: 4, label: "Gamma 2.2", background: "#989898"},
}

@declareApiModel(EndpointUrls.dataObjectsUrl, EntityType.DataObject)
export class DataObject extends MakeApiModelBase<DataObject>() implements IDataObject {
    @ApiFields.date({name: "created", readOnly: true}) created!: Date
    @ApiFields.id({name: "created_by", readOnly: true}) createdBy!: number
    @ApiFields.id({name: "customer"}) customer!: number
    @ApiFields.string({name: "original_file_name"}) originalFileName!: string
    @ApiFields.id({name: "related_to"}) relatedTo!: number
    @ApiFields.manyRelated({name: "related", model: DataObject}) related: DataObject[] = []
    @ApiFields.entityType({name: "content_type"}) contentType!: string
    @ApiFields.string({name: "gcs_bucket_name", readOnly: true}) gcsBucketName!: string
    @ApiFields.string({name: "gcs_object_name", readOnly: true}) gcsObjectName!: string
    /** @deprecated Use contentType instead, which will become mediaType in the GraphQL API. **/
    @ApiFields.enum({name: "type"}) type!: DataObjectType
    @ApiFields.enumObject({name: "state", model: DataObjectState}) state!: DataObjectState
    @ApiFields.number({name: "width"}) width!: number
    @ApiFields.number({name: "height"}) height!: number
    @ApiFields.number({name: "size"}) size!: number
    @ApiFields.enumObject({name: "image_color_space", model: ImageColorSpace}) imageColorSpace!: ImageColorSpace
    @ApiFields.string({name: "image_color_profile"}) imageColorProfile!: string
    @ApiFields.enum({name: "image_data_type"}) imageDataType!: ImageDataType
    @ApiFields.string({name: "signed_upload_url", readOnly: true}) signedUploadUrl!: string
    // This is populated only if the query parameters include signed_download_url
    @ApiFields.string({name: "signed_download_url", readOnly: true}) signedDownloadUrl!: string
    @ApiFields.string({name: "thumbnail_processing_job_id", readOnly: true}) thumbnailProcessingJobId!: string
    @ApiFields.string({name: "metadata_processing_job_id", readOnly: true}) metadataProcessingJobId!: string

    @ApiFields.string({name: "service_url", readOnly: true}) serviceUrl!: string

    static readonly cacheSetName = "colormass-dataobject-cache"

    get name(): string {
        return this.originalFileName
    }

    get downloadUrl(): string {
        return this.getOriginalFileUrl()
    }

    set name(value: string) {
        this.originalFileName = value
    }

    available(): boolean {
        return this.state == DataObjectStates.Completed
    }

    getThumbnailUrl(width = 500, fallback = true): string | undefined {
        if (
            (!!this.contentType && !UtilsService.mimeTypeMatch(MimeType.Images, this.contentType)) ||
            this.state === DataObjectStates.Init ||
            this.state === DataObjectStates.UploadFinished ||
            this.state === DataObjectStates.ProcessingFailed
        ) {
            return Settings.IMAGE_NOT_AVAILABLE_URL
        }

        if (this.state === DataObjectStates.Processing) {
            if (fallback) {
                return Settings.DATA_OBJECT_PROCESSING_URL
            }
            return undefined
        }

        // Workaround to account for the fact that we now have a separate GCS bucket for thumbnails
        const relatedJpeg = this.getRelatedJpeg(width)
        if (!relatedJpeg) throw new Error("Full resolution related JPEG not found")
        return relatedJpeg.getOriginalFileUrl()
    }

    getOriginalFileUrl(): string {
        return this.getApi().getGoogleStorageURL(this.gcsBucketName, this.gcsObjectName)
    }

    getJpegUrl(): string {
        // Workaround to account for the fact that we now have a separate GCS bucket for thumbnails
        const relatedJpeg = this.getRelatedJpeg()
        if (!relatedJpeg) throw new Error("Full resolution related JPEG not found")
        return relatedJpeg.getOriginalFileUrl()
        // return this.getApi().getGoogleStorageURL(this.gcsBucketName, this.gcsObjectName + "-jpg")
    }

    getTiffUrl(): string {
        // Workaround to account for the fact that we now have a separate GCS bucket for thumbnails
        const relatedTiff = this.getRelatedTiff()
        if (!relatedTiff) throw new Error("Full resolution related TIFF not found")
        return relatedTiff.getOriginalFileUrl()
        // return this.getApi().getGoogleStorageURL(this.gcsBucketName, this.gcsObjectName + "-tiff")
    }

    getRelatedTiff(): DataObject | null {
        for (const related of this.related) {
            if (related.contentType === "image/tiff") return related
        }
        return null
    }

    getRelatedJpeg(size: number | undefined = undefined): DataObject | null {
        for (const related of this.related) {
            if (size === undefined) {
                if (related.width * related.height === this.width * this.height) {
                    if (related.contentType === "image/jpeg") return related
                }
            } else {
                const diff = Math.abs(Math.max(related.width, related.height) - size)
                if (diff < 2) {
                    if (related.contentType === "image/jpeg") return related
                }
            }
        }
        return null
    }

    getRelated(match_fields: {[K in keyof DataObject]?: DataObject[K]}): DataObject | null {
        const keys = Object.keys(match_fields) as (keyof DataObject)[]
        const matching = this.related.filter((related) => {
            for (const key of keys) {
                if (related[key] !== match_fields[key]) {
                    return false
                }
            }
            return true
        })
        if (matching.length == 0) {
            return null
        } else if (matching.length == 1) {
            return matching[0]
        } else {
            console.warn("More than one related DataObject matches fields:", match_fields)
            return matching[0]
        }
    }

    loadSignedDownloadUrl(): Observable<void | null> {
        let queryParams: HttpParams = new HttpParams()
        queryParams = queryParams.set("signed_download_url", "")
        return DataObject.get(this.id, queryParams).pipe(
            tap((dataObject: DataObject) => (this.signedDownloadUrl = dataObject.signedDownloadUrl)),
            mapTo(null),
        )
    }

    download(cacheLocally = false): Observable<Uint8Array | null> {
        const url = this.getOriginalFileUrl()
        return this.getApi().downloadFile(url, cacheLocally ? DataObject.cacheSetName : undefined)
    }

    downloadJpeg(cacheLocally = false): Observable<Uint8Array | null> {
        const url = this.getJpegUrl()
        return this.getApi().downloadFile(url, cacheLocally ? DataObject.cacheSetName : undefined)
    }

    downloadJSON(cacheLocally = false): Observable<any> {
        return this.download().pipe(
            switchMap((data) => UtilsService.arrayBufferToFile(data!, this.originalFileName, "application/json").text()),
            map((text) => JSON.parse(text)),
        )
    }

    static getAllAssignedTo(entity: any): Observable<DataObject[]> {
        let filters: HttpParams = new HttpParams()
        filters = filters.set("assigned_to_content_type", entity.entityType)
        filters = filters.set("assigned_to_id", entity.id)
        // filters = filters.set("assignment_type", [DataObjectAssignmentType...].join(","));
        return DataObject.getAll(filters)
    }

    waitReady(timeout_s = 30): Observable<DataObject> {
        if (this.state === DataObjectStates.Completed) {
            return observableOf(this)
        }
        const ready$ = new Subject<DataObject>()
        const check = () => {
            timer(2000)
                .pipe(switchMap(() => this.load()))
                .subscribe(
                    () => {
                        if (this.state === DataObjectStates.Completed) {
                            ready$.next(this)
                        } else if (timeout_s > 0) {
                            console.log(`DataObject ${this.id} is not ready`)
                            timeout_s -= 2
                            check()
                        } else {
                            ready$.error("Timeout waiting for data object to become ready")
                        }
                    },
                    (err: unknown) => {
                        ready$.error(err)
                    },
                )
        }
        check()
        return ready$
    }
}

export enum DataObjectType {
    Image = 10,
    Video = 20,
    Pdf = 30,
    Archive = 40,
    Text = 50,
    Model = 60,
    ModelObj = 61,
    ModelDrc = 62,
    Mesh = 70,
    Scan = 80,
    Texture = 90,
    Other = 100,
}

export enum ImageDataType {
    Unknown = 0,
    Color = 1,
    NonColor = 2,
}

type TransientDataObjectProperties = {
    data: Uint8Array
    contentType: string
    imageColorSpace?: ImageColorSpace
    imageColorProfile?: string
    imageDataType?: ImageDataType
}

export class TransientDataObject implements ITransientDataObject {
    data: Uint8Array
    contentType: string
    imageColorSpace?: ImageColorSpace
    imageColorProfile?: string
    imageDataType?: ImageDataType
    constructor(args: TransientDataObjectProperties) {
        this.data = args.data
        this.contentType = args.contentType
        this.imageColorSpace = args.imageColorSpace
        this.imageColorProfile = args.imageColorProfile
        this.imageDataType = args.imageDataType
    }

    toBlob(): Blob {
        return new Blob([this.data], {type: this.contentType})
    }

    toDataURL(): Observable<string> {
        return blobToDataURL(this.toBlob())
    }

    toObjectURL(): string {
        return URL.createObjectURL(this.toBlob())
    }
}
