import {OperatorToolboxBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {TilingAreaToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/tiling-area-toolbox-item"
import paper from "paper"
import {Box2Like, Vector2Like} from "@cm/math"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

import {SnapAreaItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/snap-area-item"
import {HelperLinesBagItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/helper-lines-bag-item"
import {ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {merge} from "rxjs"

export class TilingToolbox extends OperatorToolboxBase<OperatorTiling> {
    constructor(operator: OperatorTiling) {
        super(operator, SelectionMode.SingleSelect, true)

        this._snapArea = new SnapAreaItem(this)
        this._snapArea.visible = false
        this.tilingArea = new TilingAreaToolboxItem(this)
        this.helperLinesBag = new HelperLinesBagItem(this)

        this.viewChange.subscribe(() => this.onViewChanged())
        merge(this.operator.viewMode$, this.operator.showGuides$).subscribe(() => this.updateVisibility())
        this.updateVisibility()
    }

    showSnapRadius(params: {position: Vector2Like; snapDistancePx: number} | undefined) {
        if (params) {
            this._snapArea.radius = params.snapDistancePx * 1.25
            this._snapArea.distancePenalty = 0.05
            this._snapArea.position = new paper.Point(params.position)
            this._snapArea.visible = true
        } else {
            this._snapArea.visible = false
        }
    }

    clearDebugRects() {
        this.debugRects.forEach((rect) => rect.remove())
        this.debugRects = []
    }

    createDebugRect(box: Box2Like, color: string) {
        this.tilingArea.beginPaperCreation()
        const rect = new paper.Path.Rectangle(new paper.Point(box.x, box.y), new paper.Size(box.width, box.height))
        rect.strokeColor = new paper.Color(color)
        rect.strokeWidth = 1 / this.zoomLevel
        this.debugRects.push(rect)
    }

    private onViewChanged() {
        this.debugRects.forEach((rect) => (rect.strokeWidth = 1 / this.zoomLevel))
    }

    private updateVisibility() {
        this.tilingArea.visible = this.operator.showGuides$.value
        this.helperLinesBag.visible = this.operator.showGuides$.value && this.operator.viewMode$.value === ViewMode.Source
    }

    readonly tilingArea: TilingAreaToolboxItem
    readonly helperLinesBag: HelperLinesBagItem
    private _snapArea: SnapAreaItem
    private debugRects: paper.Path[] = []
}
