import {TextureType} from "generated/graphql"
import {Vector2Like, Size2Like} from "@cm/math"
import * as Tiling from "@app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling.service"

export type TextureSet = {
    readonly type: "texture-set"
    dataObjectByTextureType: {
        [textureType: string]: DataObjectReference
    }
}

export type Region = {
    readonly type: "region"
    x: number
    y: number
    width: number
    height: number
}

export type DataObjectReference = {
    readonly type: "data-object-reference"
    dataObjectId: string
}

export type MaskReference = DataObjectReference

export type OperatorBase = {
    enabled: boolean
    locked?: boolean // defaults to false
    ignoreTextureTypes?: TextureType[]
}

export type OperatorAutoTiling = OperatorBase & {
    readonly type: "operator-auto-tiling"
    mode: Tiling.Mode
    area: Tiling.Area | undefined
    hints: Tiling.Hint[]
    params: Tiling.Params
    jobTaskId?: string // used to track the job task id of the tiling job
    outputMaps?: TextureSet // these are the AUTO-TILING OUTPUT maps further texture edits are applied to (if this is undefined, then the auto-tiling operator is the last operator and the texture set revision output maps are the auto-tiling result)
}

export type OperatorShift = OperatorBase & {
    readonly type: "operator-shift"
    shiftInPixels: Vector2Like
}

export type OperatorRotate = OperatorBase & {
    readonly type: "operator-rotate"
    angleInDegrees: number
}

export type OperatorLayerAndMask = OperatorBase & {
    readonly type: "operator-layer-and-mask"
    maskReference: MaskReference
    mapOffsetInPixels: Vector2Like
    maskOffsetInPixels: Vector2Like
    layerMinOpacity?: number // defaults to 0
    layerMaxOpacity?: number // defaults to 1
    maskFeathering?: number // in pixels; defaults to 0
}

export type OperatorHighpassBlurSettings = {
    enabled: boolean
    smoothingDistance: number
}

export type OperatorHighpassCorrectionMode = "offset" | "modulation"

export type OperatorHighpass = OperatorBase & {
    readonly type: "operator-highpass"
    blurSettingsH: OperatorHighpassBlurSettings
    blurSettingsV: OperatorHighpassBlurSettings
    wrapAround: boolean
    intensity?: number // defaults to 1
    angleOffset: number // degrees CCW
    maskReference?: MaskReference
    correctionMode?: OperatorHighpassCorrectionMode // defaults to 'offset'
    // legacy properties:
    smoothingDistance?: Vector2Like
}

export type CloneStampStroke = {
    maskReference: MaskReference
    maskRegion: Region
    sourceOffsetInPixels: Vector2Like // maskRegion.xy maps to this pixel position in the source
}

export type OperatorCloneStamp = OperatorBase & {
    readonly type: "operator-clone-stamp"
    strokes: CloneStampStroke[]
}

export type TilingControlPoint = {
    positionPx: Vector2Like
}

export type TilingCurveControlPoint = TilingControlPoint & {
    normalizedCurvePosition: number
}

export type TilingControlPointType = "user" | "alignment"

export type TilingBoundary = {
    controlPoints: {
        type: TilingControlPointType
        loBound: TilingCurveControlPoint
        hiBound: TilingCurveControlPoint
    }[]
}

export type OperatorTiling = OperatorBase & {
    readonly type: "operator-tiling"
    cornerControlPoints: {
        topLeft: TilingControlPoint
        topRight: TilingControlPoint
        bottomLeft: TilingControlPoint
        bottomRight: TilingControlPoint
    }
    boundaries: {
        horizontal: TilingBoundary
        vertical: TilingBoundary
    }
    display: {
        showGuides: boolean
        viewMode: "source" | "result"
    }
    alignment: {
        controlPointSpacingPx: number
        searchSizeRatio: number
        minCorrelation: number
        correlationPenaltyAlongEdge?: number
        correlationPenaltyAcrossEdge?: number
    }
    borderBlending: {
        enabled: boolean
        widthPx: number
    }
    uploadedGridMapping?: {
        resultSize: Size2Like
        meshDataObject: DataObjectReference
    }
    helperLines?: {
        curves: {
            origin: Vector2Like
            target: Vector2Like
            points: Vector2Like[]
        }[]
    }
}

export type OperatorTest = OperatorBase & {
    readonly type: "operator-test"
}

export type Operator =
    | OperatorAutoTiling
    | OperatorShift
    | OperatorRotate
    | OperatorLayerAndMask
    | OperatorHighpass
    | OperatorCloneStamp
    | OperatorTiling
    | OperatorTest

export type OperatorType = Operator["type"]

export type TextureEdits = {
    readonly type: "texture-edits"
    schema: number
    maps: TextureSet // these are the SOURCE maps these texture edits were applied to
    operators: Operator[]
}

export const CURRENT_SCHEMA = 4
