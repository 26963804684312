import {ConfigurationGroupNode} from "#pricing/nodes/configuration-group-node"
import {ConfigurationList} from "#pricing/nodes/configuration-list"
import {GroupId, PricingContext, VariantId} from "#pricing/nodes/core"
import {DeclareNodeGraphTS, registerNode} from "@cm/graph"
import {Subject} from "rxjs"

export type BooleanOperator = "AND" | "OR"

export interface VariantCondition {
    variantIds: VariantId[]
    variantOperator: BooleanOperator
    negated: boolean
}

export function evaluate(condition: VariantCondition, currentConfigurations: VariantId[]): boolean {
    let result: boolean
    if (condition.variantOperator === "AND") result = condition.variantIds.every((variantId) => currentConfigurations.includes(variantId))
    else if (condition.variantOperator === "OR") result = condition.variantIds.some((variantId) => currentConfigurations.includes(variantId))
    else throw new Error("Invalid variantOperator")

    if (condition.negated) result = !result

    return result
}

export type VariantConditionNodeParameters = {
    condition: VariantCondition
    currentConfigurations: ConfigurationList
}

@registerNode
export class VariantConditionNode extends DeclareNodeGraphTS<boolean, PricingContext, VariantConditionNodeParameters>(
    {
        run: async ({get, parameters, context}) => {
            const currentConfigurations = await get(parameters.currentConfigurations)

            if (parameters.condition.variantIds === undefined || currentConfigurations === undefined)
                throw new Error("Variant ids or current configurations are not available")

            //In this case, we have a conditional price that has no conditions set yet. This is the case when the price node was added, but not edited yet.
            if (parameters.condition.variantIds.length === 0) return false

            const currentVariantIds = currentConfigurations.map((configGroup) => configGroup.currentVariantId)
            return evaluate(parameters.condition, currentVariantIds)
        },
    },
    {
        nodeClass: "VariantConditionNode",
    },
) {
    private nodeChangedSubject = new Subject<void>()
    nodeChanged$ = this.nodeChangedSubject.asObservable()

    getVariantIds(): VariantId[] {
        return this.parameters.condition.variantIds
    }

    addVariantId(variantId: VariantId) {
        if (this.parameters.condition.variantIds.includes(variantId)) throw new Error("Variant id already exists")
        this.parameters.condition.variantIds.push(variantId)
    }

    removeVariantId(variantId: VariantId) {
        const index = this.parameters.condition.variantIds.findIndex((id) => id === variantId)
        if (index === -1) throw new Error("Condition does not exist")
        this.parameters.condition.variantIds.splice(index, 1)
    }

    addDependency(configGroupNode: ConfigurationGroupNode, variantId: VariantId) {
        this.addVariantId(variantId)
        this.addRefToConfigGroup(configGroupNode, variantId)
        this.nodeChangedSubject.next()
    }

    removeDependency(variantId: VariantId) {
        this.removeVariantId(variantId)
        this.removeRefToConfigGroup(variantId) //There cannot / must not be multiple dependencies to the same group, so the group can be removed.
        this.nodeChangedSubject.next()
    }

    addRefToConfigGroup(configGroupNode: ConfigurationGroupNode, variantId: VariantId) {
        if (this.parameters.currentConfigurations === undefined) throw new Error("Current configurations are not available")

        const newGroupId = configGroupNode.getGroupId()

        const availableConfigGroups = this.parameters.currentConfigurations.parameters.list

        /*In theory, it is possible to switch the operator after the conditions were set. This is then not captured by the safety checks...*/
        const configGroupFound = availableConfigGroups.find((curConfigGroupNode) => newGroupId === curConfigGroupNode.getGroupId()) !== undefined
        if (configGroupFound && this.parameters.condition.variantOperator === "AND") throw new Error("Config group already exists")

        if (availableConfigGroups.find((curConfigGroupNode) => curConfigGroupNode.hasVariant(variantId) && configGroupNode !== curConfigGroupNode))
            throw new Error("Variant id already in different config group")

        /*It is ok to add, but never remove this variant. This way, the config group node gradually becomes aware of all variants it can possibly handle.*/
        configGroupNode.addVariant(variantId)
        if (!configGroupFound) this.parameters.currentConfigurations.addEntry(configGroupNode)
    }

    removeRefToConfigGroup(variantId: VariantId) {
        if (this.parameters.currentConfigurations === undefined) throw new Error("Current configurations are not available")
        const configGroupNode = this.parameters.currentConfigurations.parameters.list.find((config) => config.hasVariant(variantId))

        if (configGroupNode === undefined) throw new Error("Config group node not found")

        let numOtherCoveredVariantIds = 0
        this.parameters.condition.variantIds.forEach((id) => {
            if (configGroupNode.hasVariant(id) && id !== variantId) numOtherCoveredVariantIds++
        })

        if (numOtherCoveredVariantIds === 0) this.parameters.currentConfigurations.removeEntry(configGroupNode)
    }

    canAddDependency(groupId: GroupId, variantId: VariantId) {
        if (this.parameters.condition.variantIds.includes(variantId)) return false

        if (this.parameters.condition.variantOperator === "AND") {
            if (this.parameters.currentConfigurations) {
                for (const configGroupNode of this.parameters.currentConfigurations.parameters.list) {
                    if (configGroupNode.parameters.groupId === groupId) return false
                }
            }
        }

        return true
    }
}
