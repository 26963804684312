import {getAll} from "@cm/graph"
import {cyclesNode, DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getDefaultMaterial} from "#material-nodes/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({emission: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)})
const InputTypeSchema = z.object({color: materialSlots.optional(), strength: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional(), strength: z.number().optional()})

export class Emission extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, strength} = await getAll(inputs, get)

            const material = getDefaultMaterial()
            material.colorNode = threeRGBColorNode({r: 0, g: 0, b: 0})

            const emissionValue =
                color ?? threeConvert(parameters.color, threeRGBColorNode, (val) => val.r > 0 || val.g > 0 || val.b > 0) ?? material.emissiveNode
            if (emissionValue) {
                const emissionStrengthValue = strength ?? threeConvert(parameters.strength, threeValueNode)
                if (emissionStrengthValue) material.emissiveNode = THREENodes.mul(emissionValue, emissionStrengthValue)
                else material.emissiveNode = emissionValue
            }

            return {emission: material}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
