import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-tag-thumbnail",
    standalone: true,
    imports: [],
    templateUrl: "./tag-thumbnail.component.html",
    styleUrl: "./tag-thumbnail.component.scss",
})
export class TagThumbnailComponent {
    @Input() tag: {color?: string | null} | undefined | null = null
}
