import {Batching, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Size2Like} from "@cm/math"

export const reshape = (
    sourceImage: ImageRef,
    shape: {
        patchSize?: Size2Like // default: {width: width, height: height}
        batchSize?: Size2Like // default: {width: 1, height: 1}
    },
    options?: {
        allowPatchFill?: boolean // default: false; allow for size to be not a multiple of patch size, filling the remaining pixels with the address mode provided
    },
): ImageRef => {
    let patchSize = shape.patchSize
    let batchSize = shape.batchSize
    if (!patchSize) {
        if (!batchSize) {
            batchSize = {
                width: 1,
                height: 1,
            }
        }
        patchSize = {
            width: Math.ceil(sourceImage.descriptor.width / batchSize.width),
            height: Math.ceil(sourceImage.descriptor.height / batchSize.height),
        }
    }
    if (!batchSize) {
        batchSize = {
            width: Math.ceil(sourceImage.descriptor.width / patchSize.width),
            height: Math.ceil(sourceImage.descriptor.height / patchSize.height),
        }
    }
    const allowPatchFill = options?.allowPatchFill ?? false
    if (!allowPatchFill) {
        if (patchSize.width * batchSize.width !== sourceImage.descriptor.width || patchSize.height * batchSize.height !== sourceImage.descriptor.height) {
            throw new Error(
                `reshape: target image size (${sourceImage.descriptor.width}, ${sourceImage.descriptor.height}) is not a multiple of batchSize (${batchSize.width}, ${batchSize.height}) and patchSize (${patchSize.width}, ${patchSize.height})`,
            )
        }
    }
    const batching: Batching | undefined = batchSize.width === 1 && batchSize.height === 1 ? undefined : {patchSize, batchSize}
    return {
        ...sourceImage,
        descriptor: {
            ...sourceImage.descriptor,
            batching,
        },
    }
}
