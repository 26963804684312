import {Injectable} from "@angular/core"
import {DataObjectAssignmentType, DataObjectState, ItemUtilsGalleryImageFragment} from "@api"
import {MimeType, UtilsService} from "@legacy/helpers/utils"
import {EndpointUrls} from "@common/models/constants/urls"

interface DataObjectBase {
    state: DataObjectState
    contentType?: string
    bucketName: string
    objectName: string
}

export type ItemId = number

@Injectable({
    providedIn: "root",
})
export class ItemUtils {
    getThumbnailUrl(dataObject: DataObjectBase, width = 500, fallback = true): string | undefined {
        if (!dataObject) {
            return EndpointUrls.IMAGE_NOT_AVAILABLE_URL
        }
        if (
            dataObject.state === DataObjectState.Init ||
            dataObject.state === DataObjectState.UploadFinished ||
            dataObject.state === DataObjectState.Processing
        ) {
            if (fallback) return EndpointUrls.DATA_OBJECT_PROCESSING_URL
            return undefined
        }

        if (dataObject.contentType != null && !UtilsService.mimeTypeMatch(MimeType.Images, dataObject.contentType)) {
            return EndpointUrls.IMAGE_NOT_AVAILABLE_URL
        }

        let gcsObjectName: string = dataObject.objectName
        // if (this.relatedTo != null) {
        //     gcsObjectName = gcsObjectName.replace(Settings.GCS_IMAGE_SUFFIX_500, "");
        //     gcsObjectName = gcsObjectName.replace(Settings.GCS_IMAGE_SUFFIX_1000, "");
        //     gcsObjectName = gcsObjectName.replace(Settings.GCS_IMAGE_SUFFIX_2000, "");
        //     gcsObjectName = gcsObjectName.replace(Settings.GCS_IMAGE_SUFFIX_JPG, "");
        // }
        gcsObjectName = gcsObjectName + "-jpg-" + width.toString()
        return EndpointUrls.GoogleStorage(dataObject.bucketName, gcsObjectName)
    }

    getOriginalFileUrl(dataObject: {bucketName: string; objectName: string}): string {
        return EndpointUrls.GoogleStorage(dataObject.bucketName, dataObject.objectName)
    }

    getGalleryDataObject(
        assignments: {type: DataObjectAssignmentType; dataObject: ItemUtilsGalleryImageFragment}[],
    ): ItemUtilsGalleryImageFragment | null | undefined {
        return assignments.find((assignment) => assignment.type === DataObjectAssignmentType.GalleryImage)?.dataObject
    }
}
