<div class="cm-section-title">Material outputs</div>
@if (material === undefined) {
    <cm-placeholder [dark]="true"></cm-placeholder>
    <cm-placeholder [dark]="true"></cm-placeholder>
    <cm-placeholder [dark]="true"></cm-placeholder>
    <cm-placeholder [dark]="true"></cm-placeholder>
} @else {
    @if (material.latestCyclesRevision; as latestCyclesRevision) {
        @if ($can().read.material(material, "outputs")) {
            <div>
                <cm-button (click)="showFlatOptions = !showFlatOptions" [class.cm-unavailable]="numberOfFlatImages === 0" class="cm-action-button">
                    <ng-container cm-left-icon>
                        <i class="far fa-image"></i>
                    </ng-container>
                    <ng-container cm-text> Flat thumbnail ({{ numberOfFlatImages }})</ng-container>
                    <ng-container cm-right-icon>
                        @if (!showFlatOptions) {
                            <i class="fa-regular fa-chevron-down"></i>
                        }
                        @if (showFlatOptions) {
                            <i class="fa-regular fa-chevron-up"></i>
                        }
                    </ng-container>
                </cm-button>
                @if (showFlatOptions && this.flatOptions) {
                    <div class="cm-flat-options">
                        @for (option of this.flatOptions; track option) {
                            @if (option.dataObject) {
                                <div [matMenuTriggerFor]="imageDownloadMenu" [matMenuTriggerData]="{dataObject: option.dataObject}">
                                    <ng-container *ngTemplateOutlet="flatOptionButton"></ng-container>
                                </div>
                            } @else {
                                <div class="cm-unavailable">
                                    <ng-container *ngTemplateOutlet="flatOptionButton"></ng-container>
                                </div>
                            }
                            <ng-template #flatOptionButton>
                                <div [matTooltip]="option.dataObject ? '' : 'Generate ' + option.label + ' flat image'" class="cm-flat-option">
                                    {{ option.label }}
                                    <div class="cm-material-download-icons">
                                        @if (option.dataObject; as dataObject) {
                                            <a class="cm-image-download" download>
                                                <i class="far fa-arrow-down-to-line"></i>
                                            </a>
                                            <button class="cm-simple-button" (click)="$event.stopPropagation(); openImageViewer(dataObject.id)">
                                                <i class="fa-regular fa-magnifying-glass"></i>
                                            </button>
                                            <button class="cm-simple-button" (click)="$event.stopPropagation(); deleteDataObject(dataObject.id)">
                                                <i class="fa-regular fa-trash cm-image-remove"></i>
                                            </button>
                                        } @else {
                                            <button
                                                class="cm-simple-button"
                                                (click)="$event.stopPropagation(); generateThumbnailForMaterial(option.flatOption)"
                                            >
                                                <i class="fa-regular fa-play"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </ng-template>
                        }
                    </div>
                }
            </div>
            <ng-template #tileableButton>
                <cm-button [class.cm-unavailable]="!tileableImage" class="cm-action-button">
                    <ng-container cm-left-icon>
                        <i class="far fa-image"></i>
                    </ng-container>
                    <ng-container cm-text> Tileable image</ng-container>
                    <ng-container cm-right-icon>
                        <div class="cm-material-download-icons">
                            @if (tileableImage) {
                                <a class="cm-image-download">
                                    <i class="far fa-arrow-down-to-line"></i>
                                </a>
                                <button class="cm-simple-button" (click)="$event.stopPropagation(); deleteDataObject(tileableImage.id)">
                                    <i class="fa-regular fa-trash cm-image-remove"></i>
                                </button>
                            } @else {
                                @if (isDebugEnabled) {
                                    <i class="fa-regular fa-bug"></i>
                                } @else {
                                    <i class="fa-regular fa-play"></i>
                                }
                            }
                        </div>
                    </ng-container>
                </cm-button>
            </ng-template>
            @if (tileableImage && !tileableImageIsZipFile) {
                @if (tileableImageIsZipFile) {
                    <a href="{{ tileableImage.downloadUrl }}" download>
                        <ng-container *ngTemplateOutlet="tileableButton"></ng-container>
                    </a>
                } @else {
                    <div [matMenuTriggerFor]="imageDownloadMenu" [matMenuTriggerData]="{dataObject: tileableImage}">
                        <ng-container *ngTemplateOutlet="tileableButton"></ng-container>
                    </div>
                }
            } @else {
                @if (isDebugEnabled) {
                    <div [matMenuTriggerFor]="debugMenu">
                        <ng-container *ngTemplateOutlet="tileableButton"></ng-container>
                    </div>
                } @else {
                    <button class="cm-simple-button" [matTooltip]="'Generate tileable image'" (click)="generateTileForMaterial()">
                        <ng-container *ngTemplateOutlet="tileableButton"></ng-container>
                    </button>
                }
            }
            <cm-button (click)="generateShaderBallThumbnailForMaterial()" matTooltip="Generate shader-ball image" class="cm-action-button cm-unavailable">
                <ng-container cm-left-icon>
                    <i class="fa-solid fa-circle-small"></i>
                </ng-container>
                <ng-container cm-text> Shader ball</ng-container>
                <ng-container cm-right-icon>
                    <i class="fa-regular fa-play"></i>
                </ng-container>
            </cm-button>
            @if (!!exportSourceInfoRequest || allExports.length > 0) {
                <cm-button (click)="showMapsExports = !showMapsExports" [class.cm-unavailable]="allExports.length === 0" class="cm-action-button">
                    <ng-container cm-left-icon>
                        <i class="fa-solid fa-box-archive"></i>
                    </ng-container>
                    <ng-container cm-text> PBR exports ({{ allExports.length }})</ng-container>
                    <ng-container cm-right-icon>
                        @if (!showMapsExports) {
                            <i class="fa-regular fa-chevron-down"></i>
                        }
                        @if (showMapsExports) {
                            <i class="fa-regular fa-chevron-up"></i>
                        }
                    </ng-container>
                </cm-button>
                @if (showMapsExports) {
                    <div class="cm-maps-exports">
                        @if (defaultExports.length > 0 && !!exportSourceInfoRequest && material.latestCyclesRevision) {
                            <div class="cm-export-section">Default exports</div>
                            @for (defaultExport of defaultExports; track defaultExport) {
                                @if (defaultExport.items.length > 0) {
                                    <div>
                                        <div class="cm-flat-option">
                                            {{ defaultExport.displayName }}
                                        </div>
                                        <cm-material-exports-list></cm-material-exports-list>
                                        <cm-material-exports-list
                                            [materialId]="material.id"
                                            [exports]="defaultExport.items"
                                            [sourceInfoRequestToString]="sourceInfoRequestToString"
                                        ></cm-material-exports-list>
                                    </div>
                                } @else {
                                    <div
                                        matTooltip="Generate {{ defaultExport.displayName }} export"
                                        class="cm-unavailable cm-flat-option"
                                        (click)="generateExport(defaultExport)"
                                    >
                                        {{ defaultExport.displayName }}
                                        <div class="cm-material-download-icons">
                                            <i class="fa-regular fa-play"></i>
                                        </div>
                                    </div>
                                }
                            }
                            @if (customExports.length > 0) {
                                <div>
                                    <div class="cm-export-section">Custom exports</div>
                                    <cm-material-exports-list
                                        [exports]="customExports"
                                        [sourceInfoRequestToString]="sourceInfoRequestToString"
                                    ></cm-material-exports-list>
                                </div>
                            }
                        }
                        @if (!!exportSourceInfoRequest) {
                            <cm-button (click)="showCustomMapsExports = !showCustomMapsExports" class="cm-action-button">
                                <ng-container cm-left-icon>
                                    <i class="fa-solid fa-sliders-h"></i>
                                </ng-container>
                                <ng-container cm-text> Custom exports ({{ customExports.length }})</ng-container>
                                <ng-container cm-right-icon>
                                    @if (!showCustomMapsExports) {
                                        <i class="fa-regular fa-chevron-down"></i>
                                    }
                                    @if (showCustomMapsExports) {
                                        <i class="fa-regular fa-chevron-up"></i>
                                    }
                                </ng-container>
                            </cm-button>
                        }
                        @if (!!exportSourceInfoRequest && showCustomMapsExports) {
                            <!--Workflow-->
                            <mat-form-field class="cm-infos-field">
                                <mat-select placeholder="Workflow" [(ngModel)]="selectedExportWorkflow">
                                    @for (exportWorkflow of exportWorkflows; track exportWorkflow) {
                                        <mat-option [value]="exportWorkflow">{{ MaterialMapsExporter.workflowToString(exportWorkflow) }} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <!--Engine-->
                            <mat-form-field class="cm-infos-field">
                                <mat-select placeholder="Engine" [(ngModel)]="selectedExportEngine">
                                    @for (exportEngine of exportEngines; track exportEngine) {
                                        <mat-option [value]="exportEngine">{{ MaterialMapsExporter.engineToString(exportEngine) }} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <!--NormalY-->
                            <mat-form-field class="cm-infos-field">
                                <mat-select placeholder="Normal" [(ngModel)]="selectedExportNormalY">
                                    @for (exportNormalY of exportNormalsY; track exportNormalY) {
                                        <mat-option [value]="exportNormalY">
                                            {{ MaterialMapsExporter.normalYToString(exportNormalY) }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <!--Format-->
                            <mat-form-field class="cm-infos-field">
                                <mat-select placeholder="Format" [(ngModel)]="selectedExportFormat">
                                    @for (exportFormat of exportFormats; track exportFormat) {
                                        <mat-option [value]="exportFormat">
                                            {{ exportFormat | uppercase }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <!--Format-->
                            <mat-form-field class="cm-infos-field">
                                <mat-select placeholder="Resolution" [(ngModel)]="selectedExportResolution">
                                    @for (exportResolution of exportResolutions; track exportResolution) {
                                        <mat-option [value]="exportResolution">
                                            {{ MaterialMapsExporter.resolutionToString(exportResolution) }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <cm-button matTooltip="Export with current settings" class="cm-action-button" (click)="generateCustomMapsExport()">
                                <ng-container cm-text> Create custom export</ng-container>
                                <ng-container cm-right-icon>
                                    <i class="fa-regular fa-play"></i>
                                </ng-container>
                            </cm-button>
                        }
                    </div>
                }
            }
        } @else {
            <cm-hint>
                <ng-container cm-text> Outputs for materials outside your organizations are not visible to you. </ng-container>
            </cm-hint>
        }
    } @else {
        <cm-hint>
            <ng-container cm-text>
                @if (material.textureGroup) {
                    Please create a new material and <b>save it</b>. The material output options will only appear then.
                } @else {
                    Please scan a new fabric to get started.
                }
            </ng-container>
        </cm-hint>
    }
}
<mat-menu #imageDownloadMenu="matMenu">
    <ng-template matMenuContent let-dataObject="dataObject">
        <div class="cm-menu-container">
            <div>Download as:</div>
            <div class="cm-menu-options">
                <a href="{{ dataObject.downloadUrl }}" class="cm-menu-option"> .EXR </a>
                <a href="{{ dataObject.jpeg?.downloadUrl }}" class="cm-menu-option"> .JPEG </a>
                <a href="{{ dataObject.tiff?.downloadUrl }}" class="cm-menu-option"> .TIFF </a>
            </div>
        </div>
    </ng-template>
</mat-menu>
<mat-menu #debugMenu="matMenu">
    <ng-template matMenuContent>
        <div class="cm-menu-container">
            <div>Rendering options:</div>
            <div class="cm-menu-options">
                <button class="cm-menu-option cm-simple-button" (click)="$event.stopPropagation(); generateTileForMaterial({cloud: true, gpu: true})">
                    Cloud GPU
                </button>
                <button class="cm-menu-option cm-simple-button" (click)="$event.stopPropagation(); generateTileForMaterial({cloud: false, gpu: false})">
                    Local CPU
                </button>
            </div>
        </div>
    </ng-template>
</mat-menu>
<cm-image-viewer #imageViewer></cm-image-viewer>
