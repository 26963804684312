import {ReturnType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-laplacian-pyramid"
import {CachedImagePyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/caching/cached-image-pyramid"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"

export class CachedLaplacianImagePyramid {
    dispose() {
        this.cachedGaussianPyramid.dispose()
        this.cachedUpSampledGaussianPyramid.dispose()
        this.cachedLaplacianPyramid.dispose()
    }

    set(cmdQueue: ImageOpCommandQueueWebGL2, value: ReturnType) {
        this.cachedGaussianPyramid.set(cmdQueue, value.gaussianPyramid)
        this.cachedUpSampledGaussianPyramid.set(cmdQueue, value.upSampledGaussianPyramid)
        this.cachedLaplacianPyramid.set(cmdQueue, value.laplacianPyramid)
    }

    get(): ReturnType {
        const result = this.getIfExists()
        if (!result) {
            throw new Error("CachedGaussianImagePyramid: no cached value found")
        }
        return result
    }

    getIfExists(): ReturnType | undefined {
        if (!this.cachedGaussianPyramid.has()) {
            return undefined
        }
        return {
            gaussianPyramid: this.cachedGaussianPyramid.get(),
            upSampledGaussianPyramid: this.cachedUpSampledGaussianPyramid.get(),
            laplacianPyramid: this.cachedLaplacianPyramid.get(),
        }
    }

    private cachedGaussianPyramid = new CachedImagePyramid()
    private cachedUpSampledGaussianPyramid = new CachedImagePyramid()
    private cachedLaplacianPyramid = new CachedImagePyramid()
}
