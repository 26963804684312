import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeRGBColorNode} from "#material-nodes/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({r: materialSlots, g: materialSlots, b: materialSlots})
const InputTypeSchema = z.object({image: materialSlots.optional()})
const ParametersTypeSchema = z.object({r: z.number().optional(), g: z.number().optional(), b: z.number().optional()})

export class SeparateRGB extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const color = (await get(inputs.image)) ?? threeRGBColorNode({r: parameters.r ?? 0, g: parameters.g ?? 0, b: parameters.b ?? 0})
            return {r: new THREENodes.SplitNode(color, "r"), g: new THREENodes.SplitNode(color, "g"), b: new THREENodes.SplitNode(color, "b")}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
