import {AfterViewInit, ChangeDetectorRef, Component, computed, effect, ElementRef, inject, input, signal, viewChild} from "@angular/core"
import {DialogSize} from "@app/common/models/dialogs"
import {CMConfiguratorElement} from "@app/configurator/types/cm-configurator"
import {TestCaseEntry, updateIframe} from "@app/test/manual-testing/helpers/configurator-test-helpers"
import {getBasicPage, getPdfButtonPage, getStylingPage} from "@app/test/manual-testing/helpers/webcomponent-pages"
import {TestContainerComponent} from "@app/test/manual-testing/test-container/test-container.component"
import {Source} from "@app/test/manual-testing/types/types"

@Component({
    standalone: true,
    selector: "cm-configurator-test-wc",
    templateUrl: "./configurator-test-wc.component.html",
    styleUrls: ["./configurator-test-wc.component.scss"],
    imports: [TestContainerComponent],
})
export class ConfiguratorTestWCComponent implements AfterViewInit {
    source = input.required<Source>({alias: "source"})

    private $testContainer = viewChild.required<TestContainerComponent>("testContainer")
    private $iframe = viewChild.required<ElementRef<HTMLIFrameElement>>("configuratorIframe")

    dialogSizes = DialogSize
    showOverlay = signal(false)
    changeDetectorRef = inject(ChangeDetectorRef)

    $moduleUrl = computed(() => {
        const source = this.source()
        switch (source) {
            case "prod":
                return "https://cwc.colormass.com/cm-configurator.js"
            case "dev":
                return "https://cm-configurator-wc-dev.web.app/cm-configurator.js"
            case "qa":
                return "https://cm-configurator-wc-qa-b6f44.web.app/cm-configurator.js"
            case "local":
                return "http://localhost:8080/cm-configurator.js"
            default:
                throw new Error(`Unknown source ${source}`)
        }
    })

    testCases: TestCaseEntry[] = [
        {
            type: "info",
            name: "What you should see",
            tooltip: "A template, loaded by specifying the template-uuid attribute on the web component.",
            fct: () => {},
        },
        {
            type: "test",
            name: "Initial parameters",
            tooltip: "Load a template with initial parameters set on the web component. The same chair, but it should display a different configuration.",
            fct: () => {
                const _doc = this.$iframe().nativeElement.contentDocument
                updateIframe(
                    this.$iframe().nativeElement,
                    getBasicPage(
                        this.$moduleUrl(),
                        "param(4d201a9a-81b1-45d1-a4bc-a7c36121a0e7)=config(fd256de2-c0e3-4ef3-98e9-25ffa7e800ff)&param(0fb20bb9-2284-4a8b-b6e0-db02ebc36742%2Fced9d80c-7103-43f2-a5ba-2d90e0c823b3)=config(ec17fd8c-6131-46d8-8bdd-a2ac0f9d29a6)&param(0fb20bb9-2284-4a8b-b6e0-db02ebc36742%2F60a5a88a-7701-4c10-819e-deb5f87f15c4)=config(c93e318c-88ee-4e8c-9b56-9c7075f53784)",
                    ),
                )
            },
        },
        {
            type: "test",
            name: "Switch template",
            tooltip: `Switch to a page that shows all styling options.`,
            fct: () => {
                const _doc = this.$iframe().nativeElement.contentDocument
                updateIframe(this.$iframe().nativeElement, getStylingPage(this.$moduleUrl(), "accordion", "manualClose", true, false))
            },
        },
        {
            type: "info",
            name: "Fullscreen menu",
            tooltip: `Toggle fullscreen and check if the internal menu appears. Note that this will put the iframe in fullscreen mode and not only the web component's element,
            so the external menu is still visible. This is normal in this test case, because toggling checks for iframe presence - usually the web components are not
            displayed in an iframe.`,
            fct: () => {},
        },
        {
            type: "info",
            name: "Manual close, captions",
            tooltip: "Opening multiple menu items should keep them open",
            fct: () => {},
        },
        {
            type: "info",
            name: "Styling",
            tooltip: "The current configurator uses all styling options. Check - TODO: Maybe add some images and more detailed hints on what to look for.",
            fct: () => {},
        },
        {
            type: "test",
            name: "Auto close",
            tooltip: `Reload the page and check if the external menu items now close automatically.`,
            fct: () => {
                const _doc = this.$iframe().nativeElement.contentDocument
                updateIframe(this.$iframe().nativeElement, getStylingPage(this.$moduleUrl(), "accordion", "autoClose", true, false))
            },
        },
        {
            type: "test",
            name: "Icons, no captions",
            tooltip: `Reload the page and check if the external menu displays as icons without captions`,
            fct: () => {
                const _doc = this.$iframe().nativeElement.contentDocument
                updateIframe(this.$iframe().nativeElement, getStylingPage(this.$moduleUrl(), "icons", "autoClose", false, false))
            },
        },
        {
            type: "test",
            name: "Pdf button",
            tooltip: `Load a template with pdf enabled, then check: The button web component, the pdf button inside the configurator and prices inside the pdf.`,
            fct: () => {
                const _doc = this.$iframe().nativeElement.contentDocument
                updateIframe(this.$iframe().nativeElement, getPdfButtonPage(this.$moduleUrl()))
            },
        },
        {
            type: "test",
            name: "In-memory snapshot",
            tooltip: `After clicking, an image should be shown on the bottom of the page (click image to close).`,
            fct: () => {
                const doc = this.$iframe().nativeElement.contentDocument
                const configurator = doc?.querySelector("cm-configurator-main") as CMConfiguratorElement
                configurator.captureSnapshotInMemory().then((snapshot) => {
                    this.$testContainer().setImage(snapshot)
                    this.showOverlay.set(true)
                })
            },
        },
        {
            type: "test",
            name: "AR api",
            tooltip: `The web component listens for the AR url and generates its own QR code. This is displayed in a separate overlay  (click image to close).`,
            fct: () => {
                const doc = this.$iframe().nativeElement.contentDocument
                const configurator = doc?.querySelector("cm-configurator-main") as CMConfiguratorElement
                configurator.addEventListener("arUrl", (event) => {
                    console.log("arUrl", event.detail)
                    configurator.generateQrCode(event.detail, "low", 200, 0).then((snapshot) => {
                        const _imgElement = document.querySelector(".cm-image-overlay img") as HTMLImageElement
                        this.$testContainer().setImage(snapshot)
                        this.showOverlay.set(true)
                        this.changeDetectorRef.detectChanges() //TODO: Check if this is still necessary after Angular 17
                    })
                })
                configurator.viewInAr()
            },
        },
        {
            type: "test",
            name: "Mobile test",
            tooltip: `Use the QR code to open the configurator on a mobile device and click around. This is not web-component specific, it is just placed here for convenience.`,
            fct: () => {
                const doc = this.$iframe().nativeElement.contentDocument
                const configurator = doc?.querySelector("cm-configurator-main") as CMConfiguratorElement
                configurator.generateQrCode("https://ci.colormass.com/?sceneId=955", "low", 200, 0).then((snapshot) => {
                    this.$testContainer().setImage(snapshot)
                    this.showOverlay.set(true)
                })
            },
        },
    ]

    ngAfterViewInit() {
        this.loadDefaultConfigurator()
    }

    constructor() {
        effect(() => {
            this.loadDefaultConfigurator()
        })
    }

    private loadDefaultConfigurator() {
        const _doc = this.$iframe().nativeElement.contentDocument
        updateIframe(this.$iframe().nativeElement, getBasicPage(this.$moduleUrl(), ""))
    }
}
