import {Context, OperatorExecutionMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context"
import {DrawableImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {ImageDescriptor, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export class ImageOpCommandQueueBase {
    constructor(private _context: Context) {}

    get mode(): OperatorExecutionMode {
        return this._context.mode
    }

    createDrawableImage(drawableImageRef: DrawableImageRef, descriptor: ImageDescriptor): ImageRef {
        return this._context.createDrawableImage(drawableImageRef, descriptor)
    }

    beginScope(name: string): void {
        const parentScope = this.scopeStack.length > 0 ? this.scopeStack[this.scopeStack.length - 1] : undefined
        this.scopeStack.push({name, parentScope})
    }

    endScope(name: string): void {
        const scope = this.scopeStack.pop()
        if (scope?.name !== name) {
            throw new Error(`Scope mismatch: expected ${name}, got ${scope?.name}`)
        }
    }

    get scope(): ImageOpScope | undefined {
        if (this.scopeStack.length === 0) {
            return undefined
        }
        return this.scopeStack[this.scopeStack.length - 1]
    }

    private scopeStack: ImageOpScope[] = []
}

export type ImageOpScope = {
    name: string
    parentScope?: ImageOpScope
}

export function getFullScopeName(scope: ImageOpScope | undefined): string {
    if (scope === undefined) {
        return ""
    }
    const parentName = getFullScopeName(scope.parentScope)
    return parentName === "" ? scope.name : `${parentName}.${scope.name}`
}
