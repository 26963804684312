import {RemoteMessaging, WebSocketMessaging} from "@cm/node-utils/websocket-messaging"
import {RenderNodes} from "@cm/render-nodes"
import {firstValueFrom} from "rxjs"

export type RendererStatus = {
    message: string
    progress: number
    state: "idle" | "updating" | "rendering" | "finished" | "cancelled" | "error"
}

export type RendererImage = {
    data: ArrayBufferView
    dataType: "float16" | "float32"
    width: number
    height: number
    components: number
}

export type RendererInfo = {
    name: string
    version: string
    extra: string
}

// TODO unify this with the definition in image-processing/matte-processing.ts
type CryptomatteManifest = {
    objects?: Record<string, string>
    materials?: Record<string, string>
    assets?: Record<string, string>
}

export type RenderMetadata = {
    cryptomatteManifest: CryptomatteManifest
    passInfo?: {[key: string]: {bitDepth: 16 | 32}}
}

export type RenderDataObject = {
    type: "dataObject"
    dataObjectId: number
    data?: Uint8Array
    localPath?: string
    contentType: string
}

export type CacheableEntity = RenderDataObject

export type InvalidatableEntity = Omit<RenderDataObject, "data" | "localPath" | "contentType">

export class RenderEngineMessaging extends RemoteMessaging {
    private constructor(messaging: WebSocketMessaging) {
        super(messaging, "Remote renderer")
    }

    static connect(url: string, maxPayload?: number) {
        return firstValueFrom(WebSocketMessaging.connect(url, maxPayload)).then((messaging: WebSocketMessaging) => {
            return new RenderEngineMessaging(messaging)
        })
    }

    getInfo(): Promise<RendererInfo> {
        return this.doTransaction({command: "getInfo"})
    }

    async update(graph: RenderNodes.Render) {
        await this.doTransaction({command: "update", graph})
    }

    async stop(): Promise<void> {
        await this.doTransaction({command: "stop"})
    }

    async cacheEntity(entity: CacheableEntity) {
        await this.doTransaction({command: "cacheEntity", entity})
    }

    async invalidateEntity(entity: InvalidatableEntity) {
        await this.doTransaction({command: "invalidateEntity", entity})
    }

    async clearCaches() {
        await this.doTransaction({command: "clearCaches"})
    }

    getStatus(): Promise<RendererStatus> {
        return this.doTransaction({command: "getStatus"})
    }

    getRenderMetadata(): Promise<RenderMetadata> {
        return this.doTransaction({command: "getRenderMetadata"})
    }

    getPreviewImage(format: "raw" | "exr16"): Promise<RendererImage> {
        return this.doTransaction({command: "getPreviewImage", format: format})
    }

    getFinalImage(passName: string, format: "raw" | "exr16" | "exr32"): Promise<RendererImage> {
        return this.doTransaction({command: "getFinalImage", pass: passName, format})
    }
}
