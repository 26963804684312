<ng-content></ng-content>
<cm-inspector-section>
    <ng-container cm-title> Features </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'enableSnapshot'" [label]="'Snapshot'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableAr'" [label]="'AR'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableGltfDownload'" [label]="'glTF Download'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableStlDownload'" [label]="'STL Download'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableFullscreen'" [label]="'Fullscreen'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enablePdfGeneration'" [label]="'PDF Generation'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableOnboardingHint'" [label]="'Onboarding hint'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableSalesEnquiry'" [label]="'Sales Enquiry'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'showAnnotations'" [label]="'Annotations'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Rendering Settings </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'enableAdaptiveSubdivision'" [label]="'Enable Adaptive Subdiv'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Configurator UI Settings </ng-container>
    <ng-container cm-content>
        <div class="cm-scene-property-settings">
            <cm-value-slot [node]="node()" [key]="'backgroundColor'" [label]="'BG Color'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'uiColor'" [label]="'UI Color'"></cm-value-slot>
            <cm-value-slot
                [node]="node()"
                [key]="'uiStyle'"
                [label]="'UI Style'"
                [selectionPossibilities]="uiStylePossibilites"
                [topLabel]="true"
            ></cm-value-slot>
            <cm-value-slot
                [node]="node()"
                [key]="'iconSize'"
                [label]="'Icon Size'"
                [selectionPossibilities]="iconSizePossibilites"
                [topLabel]="true"
            ></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Configurator Settings </ng-container>
    <ng-container cm-content>
        <div class="cm-scene-property-settings">
            <cm-value-slot [node]="node()" [key]="'maxSubdivisionLevel'" [label]="'Max subdiv.'" [decimalPlaces]="0"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'maxSubdivisionLevelOnMobile'" [label]="'Mob. max subdiv.'" [decimalPlaces]="0"></cm-value-slot>
            <cm-value-slot
                [node]="node()"
                [key]="'textureResolution'"
                [label]="'Texture resolution'"
                [selectionPossibilities]="textureResolutionPossibilites"
                [topLabel]="true"
            ></cm-value-slot>
            <cm-value-slot
                [node]="node()"
                [key]="'textureFiltering'"
                [label]="'Texture filtering'"
                [topLabel]="true"
                [selectionPossibilities]="textureFilteringPossibilites"
            ></cm-value-slot>
            <cm-value-slot
                [node]="node()"
                [key]="'environmentMapMode'"
                [label]="'Env. reflection mode'"
                [selectionPossibilities]="environmentMapModePossibilites"
                [topLabel]="true"
            ></cm-value-slot>
        </div>
        <cm-value-slot [node]="node()" [key]="'enableRealtimeShadows'" [label]="'Enable Shadows'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableRealtimeLights'" [label]="'Enable Lights'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'enableRealtimeMaterials'" [label]="'Enable Materials'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

@if (parameters().enableRealtimeShadows) {
    <cm-inspector-section>
        <ng-container cm-title> Configurator Shadows </ng-container>
        <ng-container cm-content>
            <cm-value-slot
                [node]="node()"
                [key]="'realtimeShadowMapOptions'"
                [label]="'Customize'"
                [selectionPossibilities]="realtimeShadowMapOptionsPossibilites"
                [isSelected]="realtimeShadowMapOptionsSelected"
            ></cm-value-slot>
            @if (realtimeShadowMapOptions(); as realtimeShadowMapOptions) {
                <cm-value-slot
                    [node]="node()"
                    [key]="'realtimeShadowMapOptions'"
                    [subKey]="'resolution'"
                    [label]="'Resolution'"
                    [decimalPlaces]="0"
                ></cm-value-slot>
                Compute Iterations
                <div class="cm-scene-property-settings">
                    <cm-value-slot
                        [node]="node()"
                        [key]="'realtimeShadowMapOptions'"
                        [subKey]="'outerUpdateIterations'"
                        [label]="'Iterations'"
                        [decimalPlaces]="0"
                    ></cm-value-slot>
                    <cm-value-slot
                        [node]="node()"
                        [key]="'realtimeShadowMapOptions'"
                        [subKey]="'innerUpdateIterations'"
                        [label]="'Steps per Frame'"
                        [decimalPlaces]="0"
                    ></cm-value-slot>
                </div>
                Smoothing Iterations
                <div class="cm-scene-property-settings">
                    <cm-value-slot
                        [node]="node()"
                        [key]="'realtimeShadowMapOptions'"
                        [subKey]="'outerSmoothIterations'"
                        [label]="'Iterations'"
                        [decimalPlaces]="0"
                    ></cm-value-slot>
                    <cm-value-slot
                        [node]="node()"
                        [key]="'realtimeShadowMapOptions'"
                        [subKey]="'innerSmoothIterations'"
                        [label]="'Steps per Frame'"
                        [decimalPlaces]="0"
                    ></cm-value-slot>
                </div>
            }
        </ng-container>
    </cm-inspector-section>
}

<cm-inspector-section>
    <ng-container cm-title> Shadow Catcher </ng-container>
    <ng-container cm-content>
        <cm-value-slot
            [node]="node()"
            [key]="'shadowCatcherFalloff'"
            [label]="'Falloff'"
            [selectionPossibilities]="shadowCatcherFalloffPossibilites"
            [isSelected]="shadowCatcherFalloffSelected"
        ></cm-value-slot>
        @if (shadowCatcherFalloff(); as shadowCatcherFalloff) {
            <div class="cm-scene-property-settings">
                <cm-value-slot [node]="node()" [key]="'shadowCatcherFalloff'" [subKey]="'sizeX'" [label]="'Size X'" [decimalPlaces]="1"></cm-value-slot>
                <cm-value-slot [node]="node()" [key]="'shadowCatcherFalloff'" [subKey]="'sizeZ'" [label]="'Size Z'" [decimalPlaces]="1"></cm-value-slot>
                <cm-value-slot
                    [node]="node()"
                    [key]="'shadowCatcherFalloff'"
                    [subKey]="'smoothness'"
                    [label]="'Smoothness'"
                    [decimalPlaces]="1"
                ></cm-value-slot>
                <cm-value-slot [node]="node()" [key]="'shadowCatcherFalloff'" [subKey]="'opacity'" [label]="'Opacity'" [decimalPlaces]="1"></cm-value-slot>
            </div>
        }
    </ng-container>
</cm-inspector-section>
