import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {map, Subscription} from "rxjs"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"

const TD = TypeDescriptors

const meshDecalDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    inputMeshData: TD.inlet(TD.MeshData),
    offset: TD.inlet(TD.Tuple<[number, number]>()),
    rotation: TD.inlet(TD.Number),
    size: TD.inlet(TD.Tuple<[number, number]>()),
    distance: TD.inlet(TD.Number),
    meshData: TD.outlet(TD.MeshData),
}

export class MeshDecal implements NodeClassImpl<typeof meshDecalDescriptor, typeof MeshDecal> {
    static descriptor = meshDecalDescriptor
    static uniqueName = "MeshDecal"
    sceneManager!: Inlet<ISceneManager>
    inputMeshData!: Inlet<MeshData>
    offset!: Inlet<[number, number]>
    rotation!: Inlet<number>
    size!: Inlet<[number, number]>
    distance!: Inlet<number>
    meshData!: Outlet<MeshData>

    private _pending: Subscription | null = null

    run() {
        this.meshData.emitIfChanged(NotReady)
        this._pending?.unsubscribe()
        if (
            this.sceneManager === NotReady ||
            this.inputMeshData === NotReady ||
            this.offset === NotReady ||
            this.rotation === NotReady ||
            this.size === NotReady ||
            this.distance === NotReady
        )
            return
        this._pending = this.sceneManager.addTask(
            this.sceneManager.clipAndOffsetMeshForDecal(this.inputMeshData, this.offset, this.rotation, this.size, this.distance).pipe(
                map((meshData) => {
                    this._pending = null
                    this.meshData.emitIfChanged(meshData)
                }),
            ),
        )
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
