import {inject, Injectable, signal} from "@angular/core"
import {toSignal} from "@angular/core/rxjs-interop"
import {ContentTypeModel, TaskState} from "@api"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {BehaviorSubject, combineLatestWith, map, switchMap} from "rxjs"
import {IsDefined} from "@cm/utils/filter"

@Injectable({
    providedIn: "root",
})
export class TasksService {
    refresh = inject(RefreshService)
    sdk = inject(SdkService)

    $isBeingAdded = signal(false)
    selectedTaskId$ = new BehaviorSubject<string | null>(null)
    private _showCompleted$ = new BehaviorSubject<boolean>(false)
    public $showCompleted = toSignal(this._showCompleted$, {requireSync: true})
    public setShowCompleted = (showCompleted: boolean) => this._showCompleted$.next(showCompleted)

    selectTask(task: {id: string}) {
        this.selectedTaskId$.next(task.id)
    }

    tasks$ = (id: string, contentTypeModel: ContentTypeModel) => {
        return this.refresh.observeItem$({id, __typename: contentTypeModel}).pipe(
            switchMap(async () =>
                this.sdk.gql
                    .tasksServiceTasks({
                        id,
                        contentTypeModel,
                    })
                    .then(({tasks}) => tasks),
            ),
            combineLatestWith(this._showCompleted$),
            map(([tasks, showCompleted]) =>
                tasks.filter(IsDefined).filter((task) => task.state !== TaskState.Archived && (showCompleted || task.state !== TaskState.Completed)),
            ),
        )
    }
}
