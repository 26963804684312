import {CurveVizItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/curve-viz-item"
import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-interpolator"
import {BoundaryDirection, BoundaryItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {BoundaryCurveControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-control-point"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {Vector2, Vector2Like} from "@cm/math"
import {LinearInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/linear-interpolator"
import {EventEmitter} from "@angular/core"
import {auditTime, merge, Subject, takeUntil} from "rxjs"
import {
    computeTValuesForControlPoints,
    NUM_GRID_POINTS_PER_SEGMENT,
} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/utils"
import {BoundaryControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-control-point"

export type ChangeEvent = {
    type: "point-added" | "point-removed" | "point-source-position-moved" | "point-result-uv-moved"
}

export class BoundaryCurveItem extends CanvasBaseToolboxItemBase<BoundaryItem> {
    readonly curveChanged = new EventEmitter<ChangeEvent>()

    constructor(
        readonly boundary: BoundaryItem,
        flipLabelSide: boolean,
    ) {
        super(boundary)
        this._curveVisItem = new CurveVizItem(this, 2, flipLabelSide)

        merge(this.spatialMapping.mappingChanged, this.spatialMapping.viewModeChanged)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onDisplayedMappingChanged())

        this._synchronizeDisplay.pipe(auditTime(0), takeUntil(this.unsubscribe)).subscribe(() => this.updateDisplay())
        this._synchronizeDisplay.next()
    }

    get spatialMapping() {
        return this.boundary.spatialMapping
    }

    createControlPoint(controlPoint: BoundaryControlPoint, t: number) {
        controlPoint.item.itemRemove.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            const index = this._curveControlPoints.findIndex((curveControlPoint) => curveControlPoint.controlPoint === controlPoint)
            if (index < 0) {
                throw new Error("Control point not found")
            }
            this._curveControlPoints.splice(index, 1)
            this.invalidate()
            this.curveChanged.emit({type: "point-removed"})
        })
        const curveControlPoint = new BoundaryCurveControlPoint(this, controlPoint, t)
        curveControlPoint.controlPoint.sourcePositionChanged.subscribe(() => this.onControlPointSourcePositionChanged())
        curveControlPoint.controlPoint.resultUVChanged.subscribe(() => this.onControlPointResultUVChanged())
        const insertionIndex = this._curveControlPoints.findIndex((curveControlPoint) => curveControlPoint.t > t)
        this._curveControlPoints.splice(insertionIndex === -1 ? this._curveControlPoints.length : insertionIndex, 0, curveControlPoint)
        this.invalidate()
        this.curveChanged.emit({type: "point-added"})
        return curveControlPoint
    }

    computeClosestT(point: Vector2Like) {
        return this.interpolator.solveForT(point)
    }

    getPoint(t: number) {
        return this.interpolator.evaluate(t)
    }

    getTangent(t: number) {
        return this.interpolator.evaluateTangent(t)
    }

    computeCurveCumulativeArcLengths(): number[] {
        const interpolator = this.interpolator
        const numSegments = this._curveControlPoints.length - 1
        const cumulativeControlPointArcLengths = new Array<number>(numSegments + 1)
        cumulativeControlPointArcLengths[0] = 0
        for (let i = 0; i < numSegments; i++) {
            const tStart = this._curveControlPoints[i].t
            const tEnd = this._curveControlPoints[i + 1].t
            let segmentLength = 0
            let lastPosition: Vector2 | undefined
            for (let j = 0; j <= NUM_GRID_POINTS_PER_SEGMENT; j++) {
                const t = tStart + (tEnd - tStart) * (j / NUM_GRID_POINTS_PER_SEGMENT)
                const position = interpolator.evaluate(t)
                if (lastPosition) {
                    segmentLength += Vector2.distance(lastPosition, position)
                }
                lastPosition = position
            }
            cumulativeControlPointArcLengths[i + 1] = cumulativeControlPointArcLengths[i] + segmentLength
        }

        return cumulativeControlPointArcLengths
    }

    get curveControlPoints() {
        return this._curveControlPoints
    }

    get curveVisItem() {
        return this._curveVisItem
    }

    get interpolator() {
        this.update()
        if (!this._curveInterpolator) {
            throw new Error("Curve interpolator not initialized")
        }
        return this._curveInterpolator
    }

    private onControlPointSourcePositionChanged() {
        this.invalidate()
        this.curveChanged.emit({type: "point-source-position-moved"})
    }

    private onControlPointResultUVChanged() {
        this.invalidate()
        this.curveChanged.emit({type: "point-result-uv-moved"})
    }

    private updateDisplay() {
        this.update()
        const tValues = computeTValuesForControlPoints(this._curveControlPoints)
        const points = new Array<Vector2>(tValues.length)
        const isHorizontal = this.boundary.direction === BoundaryDirection.Horizontal
        const otherT = this.boundary.curveMin === this ? 0 : 1
        tValues.forEach((t, i) => {
            const uv = isHorizontal ? new Vector2(t, otherT) : new Vector2(otherT, t)
            points[i] = this.spatialMapping.mapUVToScreenSpace(uv)
        })
        this._curveVisItem.setCurvePoints(points, tValues)
    }

    private onDisplayedMappingChanged() {
        this._synchronizeDisplay.next()
    }

    private invalidate() {
        this._needsUpdate = true
        this._synchronizeDisplay.next()
    }

    private update() {
        if (!this._needsUpdate) {
            return
        }
        this._needsUpdate = false

        const controlPointPositions = this._curveControlPoints.map((curveControlPoint) => curveControlPoint.controlPoint.sourcePosition)
        const controlPointTValues = this._curveControlPoints.map((controlPoint) => controlPoint.t)
        this._curveInterpolator = new LinearInterpolator(controlPointPositions, controlPointTValues)
    }

    private _needsUpdate = true
    private _curveControlPoints = new Array<BoundaryCurveControlPoint>()
    private _curveVisItem: CurveVizItem
    private _curveInterpolator?: CurveInterpolator
    private _synchronizeDisplay = new Subject<void>()
}
