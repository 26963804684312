import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({color: materialSlots.optional(), gamma: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional(), gamma: z.number().optional()})

export class Gamma extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const colorValue = (await get(inputs.color)) ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const gammaValue = (await get(inputs.gamma)) ?? threeConvert(parameters.gamma, threeValueNode) ?? threeValueNode(1)
            return {color: THREENodes.pow(colorValue, gammaValue)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
