import {Matrix3x2, Matrix3x2Like, Size2Like, Vector2, Box2} from "@cm/math"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageDescriptor, ImageRef, isImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {getImgProcPixelAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertNoBatching} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export type ParameterType = {
    sourceImage: ImageRef
    transform: Matrix3x2Like
    addressMode?: AddressMode // default: "wrap"
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

const imageOpAffineTransform: ImageOpType<ParameterType, ReturnType> = {
    name: "AffineTransform",

    WebGL2: ({cmdQueue, parameters: {sourceImage, transform, addressMode, resultImageOrDataType}}) => {
        assertNoBatching(sourceImage.descriptor)
        addressMode ??= "wrap"
        const painter = cmdQueue.createPainter(
            "compositor",
            "affineTransform",
            `
            uniform mat3x2 u_invTransform;

            vec4 computeColor(ivec2 targetPixel) {
                vec2 sourcePixel = u_invTransform * vec3(vec2(targetPixel) + 0.5, 1); // we offset by 0.5 because texelFetchInterpolated samples at the center of the texel
                return texelFetchInterpolated0(sourcePixel, ${getHalAddressMode(addressMode)});
            }
        `,
        )
        const resultImageDescriptor = computeResultImageDescriptor(sourceImage.descriptor, transform)
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, resultImageDescriptor, false)
        const invTransform = Matrix3x2.fromMatrix3x2Like(transform).inverse()
        cmdQueue.paint(painter, {
            parameters: {
                u_invTransform: {type: "float3x2", value: invTransform},
            },
            sourceImages: sourceImage,
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, transform, addressMode, resultImageOrDataType}}) => {
        assertNoBatching(sourceImage.descriptor)
        addressMode ??= "wrap"
        resultImageOrDataType = isImageRef(resultImageOrDataType)
            ? resultImageOrDataType
            : cmdQueue.createImage(
                  computeResultImageDescriptor(
                      {
                          ...sourceImage.descriptor,
                          dataType: resultImageOrDataType ?? sourceImage.descriptor.dataType,
                      },
                      transform,
                  ),
              )
        resultImageOrDataType = cmdQueue.createImage(resultImageOrDataType.descriptor, {
            type: "affineTransform",
            input: sourceImage,
            transform: Matrix3x2.fromMatrix3x2Like(transform).toArray(),
            borderMode: getImgProcPixelAddressMode(addressMode),
            target: resultImageOrDataType,
        })
        return resultImageOrDataType
    },
}

function computeResultImageDescriptor(sourceImageDescriptor: ImageDescriptor, transform: Matrix3x2Like): ImageDescriptor {
    const resultSize = computeResultSize(sourceImageDescriptor, transform)
    return {
        ...sourceImageDescriptor,
        ...resultSize,
    }
}

function computeResultSize(sourceSize: Size2Like, transform: Matrix3x2Like): Box2 {
    const matrix = Matrix3x2.fromMatrix3x2Like(transform)
    const p00 = new Vector2(0, 0)
    const p10 = new Vector2(sourceSize.width, 0)
    const p01 = new Vector2(0, sourceSize.height)
    const p11 = new Vector2(sourceSize.width, sourceSize.height)
    const p00t = matrix.multiplyVector(p00)
    const p10t = matrix.multiplyVector(p10)
    const p01t = matrix.multiplyVector(p01)
    const p11t = matrix.multiplyVector(p11)
    const minX = Math.min(p00t.x, p10t.x, p01t.x, p11t.x)
    const maxX = Math.max(p00t.x, p10t.x, p01t.x, p11t.x)
    const minY = Math.min(p00t.y, p10t.y, p01t.y, p11t.y)
    const maxY = Math.max(p00t.y, p10t.y, p01t.y, p11t.y)
    return new Box2(Math.round(minX), Math.round(minY), Math.round(maxX - minX), Math.round(maxY - minY))
}

export function affineTransform(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpAffineTransform, parameters)
}
