import {AssetState, MaterialState, ModelState, NextActor, PictureState} from "@api"
import {groupBy, pairwise} from "@common/helpers/array"
import {IsDefined, IsNonNull} from "@cm/utils/filter"

export const itemsWhoseStateChanged = <
    ItemType extends {
        object?: {id: string} | null
        state: AssetState | MaterialState | ModelState | PictureState
    },
>(
    items: (ItemType | null | undefined)[],
    previous: ItemType["state"],
    next: ItemType["state"][],
): ItemType[] => {
    const groupedItems = groupBy(items.filter(IsDefined), (item) => item.object?.id)
    return Array.from(groupedItems.values())
        .map((itemsInGroup) =>
            itemsInGroup.filter((itemInGroup, index) => index > 0 && itemsInGroup[index - 1].state === previous && next.includes(itemInGroup.state)),
        )
        .flat()
        .filter(IsNonNull)
}

export const itemsWhoseNextActorChanged = <
    ItemType extends {
        object?: {id: string} | null
        nextActor: NextActor
        state: AssetState | MaterialState | ModelState | PictureState
    },
>(
    items: (ItemType | null | undefined)[],
    previous: NextActor,
    next: NextActor,
    state: ItemType["state"],
): ItemType[] => {
    const groupedItems = groupBy(
        items.filter(IsDefined).filter((item) => item.state === state),
        (item) => item.object?.id,
    )
    return Array.from(groupedItems.values())
        .map((items) => {
            if (pairwise(items).some(([previousItem, nextItem]) => previousItem.nextActor === previous && nextItem.nextActor === next)) {
                return items[items.length - 1]
            }
            return null
        })
        .filter(IsNonNull)
}
