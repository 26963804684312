import {getAll} from "@cm/graph"
import {cyclesNode, DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getDefaultMaterial} from "#material-nodes/nodes/bsdf-principled"
import {threeConvert, threeValueNode} from "#material-nodes/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({
    shader: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode),
})
const InputTypeSchema = z.object({
    shader: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode).optional(),
    shader_001: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode).optional(),
    fac: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    fac: z.number().optional(),
})
export class MixShader extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {shader, shader_001, fac} = await getAll(inputs, get)

            const material = getDefaultMaterial()
            if (!shader || !shader_001) {
                if (shader) return {shader: shader}
                else if (shader_001) return {shader: shader_001}
                else return {shader: material}
            }

            const facNode = fac ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(0.5)

            const mix = (node1: THREENodes.Node | null, node2: THREENodes.Node | null) => {
                if (!node1) return node2
                else if (!node2) return node1
                else return THREENodes.mix(node1, node2, facNode)
            }

            material.colorNode = mix(shader.colorNode, shader_001.colorNode) ?? material.colorNode
            material.roughnessNode = mix(shader.roughnessNode, shader_001.roughnessNode) ?? material.roughnessNode
            material.metalnessNode = mix(shader.metalnessNode, shader_001.metalnessNode) ?? material.metalnessNode
            material.iorNode = mix(shader.iorNode ?? null, shader_001.iorNode ?? null) ?? material.iorNode
            material.specularColorNode = mix(shader.specularColorNode, shader_001.specularColorNode) ?? material.specularColorNode
            material.sheenNode = mix(shader.sheenNode, shader_001.sheenNode) ?? material.sheenNode
            material.clearcoatNode = mix(shader.clearcoatNode, shader_001.clearcoatNode) ?? material.clearcoatNode
            material.clearcoatRoughnessNode = mix(shader.clearcoatRoughnessNode, shader_001.clearcoatRoughnessNode) ?? material.clearcoatRoughnessNode
            material.clearcoatNormalNode = mix(shader.clearcoatNormalNode, shader_001.clearcoatNormalNode) ?? material.clearcoatNormalNode
            material.normalNode = mix(shader.normalNode, shader_001.normalNode) ?? material.normalNode
            material.emissiveNode = mix(shader.emissiveNode, shader_001.emissiveNode) ?? material.emissiveNode
            const alphaValue = mix(shader.opacityNode, shader_001.opacityNode) ?? material.opacityNode
            const transmissionValue = mix(shader.transmissionNode, shader_001.transmissionNode) ?? material.transmissionNode

            if (alphaValue || transmissionValue) {
                material.transparent = shader.transparent || shader_001.transparent
                if (alphaValue) {
                    if (transmissionValue) console.warn("Material uses both alpha and transmission! Preferring alpha.")
                    material.opacityNode = alphaValue
                } else if (transmissionValue) {
                    material.transmissionNode = transmissionValue
                    //@ts-ignore
                    material.transmission = 0.01 //This is just to indicate to three that the material is translucent
                }
            }

            return {shader: material}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
