import {inject, Injectable} from "@angular/core"
import {FilesService} from "@app/common/services/files/files.service"
import {PdfGenerationService} from "@app/common/services/pdf-generation/pdf-generation.service"
import {StlExportService} from "@app/common/services/stl-export/stl-export.service"
import {captureSnapshot as captureSnapshotExt} from "@app/template-editor/helpers/snapshot"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {Parameters, TemplateGraph} from "@cm/template-nodes"
import {Subject} from "rxjs"
import {GltfExportService} from "@app/common/services/gltf-export/gltf-export.service"

@Injectable()
export class ActionMenuService {
    private stlExportService = inject(StlExportService)
    private pdfService = inject(PdfGenerationService)
    protected gltfExportService = inject(GltfExportService)

    private creatingStlSubject = new Subject<boolean>()
    creatingStl$ = this.creatingStlSubject.asObservable()

    private creatingGltfSubject = new Subject<boolean>()
    creatingGltf$ = this.creatingGltfSubject.asObservable()

    private toggleFullscreenSubject = new Subject<void>()
    toggleFullscreen$ = this.toggleFullscreenSubject.asObservable()

    toggleFullscreen(): void {
        this.toggleFullscreenSubject.next()
    }

    async downloadStl(templateGraph: TemplateGraph, parameters: Parameters, sceneManagerService: SceneManagerService): Promise<void> {
        this.creatingStlSubject.next(true)
        const zippedStls = await this.stlExportService.exportStlFiles(templateGraph, parameters, sceneManagerService)
        FilesService.downloadFile("stl-export.zip", zippedStls)
        this.creatingStlSubject.next(false)
    }

    async downloadGltf(templateGraph: TemplateGraph, parameters: Parameters, threeSceneManagerService: ThreeSceneManagerService): Promise<void> {
        this.creatingGltfSubject.next(true)
        const binaryGltf = await this.gltfExportService.exportGltfFile(templateGraph, parameters, threeSceneManagerService)
        FilesService.downloadFile("glb-export.glb", new Blob([binaryGltf]))
        this.creatingGltfSubject.next(false)
    }

    captureSnapshot(threeSceneManagerService: ThreeSceneManagerService): void {
        const snapshot = captureSnapshotExt(threeSceneManagerService, "image/jpeg", 95)
        FilesService.downloadFile("snapshot.jpg", snapshot)
    }

    async downloadPdf(threeSceneManagerService: ThreeSceneManagerService, templateId: string): Promise<void> {
        this.pdfService.generatedAndDownloadPdf(threeSceneManagerService, templateId)
    }
}
