import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {findBestMatchAlongSegment} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/find-best-match-along-segment"
import {Vector2, Vector2Like} from "@cm/math"

export type ParameterType = {
    sourceImage: ImageRef
    rayOrigin: Vector2Like
    rayTarget: Vector2Like
    minFeatureLength?: number // default 32
}

export async function determineFeatureWaveLength(imageOpContext: ImageOpContextWebGL2, {sourceImage, rayOrigin, rayTarget, minFeatureLength}: ParameterType) {
    minFeatureLength ??= 32
    const featureWidth = 32
    const searchWindowRatio = 6
    const maxFeatureLength = 128
    const from = Vector2.fromVector2Like(rayOrigin)
    const to = Vector2.fromVector2Like(rayTarget)
    const dir = to.sub(from).normalized()
    const segmentLength = Vector2.distance(from, to)
    const featureRescale = Math.min(1, maxFeatureLength / segmentLength)
    const searchRescale = featureRescale * searchWindowRatio
    const referenceSegment = {
        from: from,
        to: from.add(dir.mul(segmentLength * featureRescale)),
        width: featureWidth,
    }
    const searchSegment = {
        from: from,
        to: from.add(dir.mul(segmentLength * searchRescale)),
        width: featureWidth,
    }
    const result = await findBestMatchAlongSegment(imageOpContext, {
        sourceImage,
        referenceSegment,
        searchSegment,
    })
    // let's try to fold the correlation data by different lengths to find the ideal wave length
    const correlationMean = result.correlationData.reduce((a, b) => a + b, 0) / result.correlationData.length
    let bestResult: {waveLength: number; energy: number} | undefined = undefined
    for (let waveLength = minFeatureLength; waveLength < result.correlationData.length; waveLength++) {
        let sum = 0
        for (let i = 0; i < result.correlationData.length; i += waveLength) {
            sum += result.correlationData[i] - correlationMean
        }
        const energy = sum
        if (!bestResult || energy > bestResult.energy) {
            bestResult = {waveLength, energy}
        }
    }
    if (!bestResult) {
        throw new Error("no result found")
    }
    return {
        ...bestResult,
        details: result,
    }
}
