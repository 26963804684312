import {Box2, Vector2} from "@cm/math"
import {HalPainterImageCompositor, PainterImageCompositorPaintArgs} from "@common/models/hal/hal-painter-image-compositor"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2PainterPrimitive} from "@common/models/webgl2/webgl2-painter-primitive"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {isHalView} from "@common/models/hal/hal-image-view/utils"
import {HalGeometry} from "@common/models/hal/hal-geometry"
import {WebGl2Geometry} from "@common/models/webgl2/webgl2-geometry"

export class WebGl2PainterImageCompositor implements HalPainterImageCompositor {
    constructor(
        readonly context: WebGl2Context,
        compositingFunction: string,
    ) {
        this.painterPrimitive = new WebGl2PainterPrimitive(this.context, this.getShadingFunction(compositingFunction))
        this.geometry = new WebGl2Geometry(this.context)
    }

    // HalEntity
    dispose(): void {
        this.painterPrimitive.dispose()
        this.geometry.dispose()
    }

    // HalPainterImageCompositor
    async paint({target, parameters, sourceImages, options}: PainterImageCompositorPaintArgs): Promise<void> {
        const targetSize = isHalView(target) ? target.region : target
        const targetRegion = options?.targetRegion ?? Box2.fromPositionAndSize(new Vector2(0, 0), new Vector2(targetSize.width, targetSize.height))
        // TODO subsequent calls will use the same geometry buffer, which might cause stalls
        this.geometry.clear()
        this.geometry.addRect(targetRegion, targetRegion)
        await this.painterPrimitive.paint({
            target,
            geometry: this.geometry,
            parameters,
            sourceImages,
            options,
        })
    }

    private getShadingFunction(compositingFunction: string): string {
        return `
            ${compositingFunction}
            vec4 computeColor(vec2 position, vec2 uv, vec4 color) {
                return computeColor(ivec2(uv)) * color;
            }
        `
    }

    private painterPrimitive: HalPainterPrimitive
    private geometry: HalGeometry
}
