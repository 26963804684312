import {BackendArGenerationTemplateRevisionFragment} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"

export const removeCachedArData = async (templateRevision: BackendArGenerationTemplateRevisionFragment, sdk: SdkService): Promise<void> => {
    for (const gltfAssignment of templateRevision.gltfAssignments) {
        for (const relatedDataObject of gltfAssignment.dataObject.related) {
            await sdk.gql.deleteDataObjectForBackendArGeneration({dataObjectId: relatedDataObject.id})
        }
        await sdk.gql.deleteDataObjectForBackendArGeneration({dataObjectId: gltfAssignment.dataObject.id})
    }
}
