import {IMaterialGraphManager} from "@cm/material-nodes"
import {MeshData} from "@cm/template-nodes"
import {LegacyTemplateNodes as Nodes} from "@cm/template-nodes"
import {colorToString} from "@cm/utils"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {forkJoinZeroOrMore, SyncTaskSet} from "@legacy/helpers/utils"
import {ThreeCamera} from "@editor/helpers/scene/three-proxies/camera"
import {ThreeEnvironment} from "@editor/helpers/scene/three-proxies/environment"
import {exportSceneGLB} from "@editor/helpers/scene/three-proxies/gltf-scene-exporter"
import {ThreeAreaLight} from "@editor/helpers/scene/three-proxies/light"
import {MaterialManager} from "@editor/helpers/scene/three-proxies/material-manager"
import {ThreeAnnotation, ThreeMesh, ThreePoint, ThreeRectangle} from "@editor/helpers/scene/three-proxies/mesh"
import {
    createCanvasAndRenderer,
    DEFAULT_FLOAT_TEXTURE_TYPE,
    IScene,
    LoadTextureDescriptor,
    MeshGeometryAccessor,
    setBVHCreatedWithMesh,
} from "@editor/helpers/scene/three-proxies/utils"
import {TransformControls} from "@editor/helpers/scene/transformation"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {Color, IDisplayScene, IDisplaySceneEvent, IMeshGeometryAccessor, ObjectId, SceneNodes} from "@cm/template-nodes"
import {map, mapTo, Observable, of as observableOf, Subject, switchMap, take} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {EXRLoader} from "@cm/material-nodes/three"
import {RGBELoader} from "@cm/material-nodes/three"
import {FontLoader, Font} from "@cm/material-nodes/three"
import {nodeFrame} from "@cm/material-nodes/three"
import {PreloadMaterial} from "@editor/helpers/scene/three-proxies/preload-material"
import {RenderView} from "@editor/helpers/scene/three-proxies/render-view"
import {constFloatNode} from "@editor/helpers/scene/three-proxies/material-converter"
import {ThreeNodes as THREENodes} from "@cm/material-nodes/three"
import {Vector3} from "@cm/math"
import {IHdriManager} from "@app/editor/services/hdri.service"
import {isSafari} from "@app/common/helpers/device-browser-detection/device-browser-detection"

type ThreeObject = ThreeMesh | ThreeRectangle | ThreeAreaLight | ThreeCamera | PreloadMaterial | ThreePoint | ThreeAnnotation

export type ThreeSceneConfig = {
    backgroundColor?: Color
    editMode?: boolean
    useCameraView?: boolean // TODO remove
    ambientLight?: boolean
    textureResolution?: Nodes.TextureResolution
    showGrid?: boolean
    progressiveLoading?: boolean
}
const modifiableConfigParams = ["ambientLight", "showGrid"] as const
export type ThreeSceneModifiableConfigParams = Pick<ThreeSceneConfig, (typeof modifiableConfigParams)[number]>
export class ThreeScene implements IDisplayScene, IScene {
    readonly threeScene: THREE.Scene
    readonly rayCaster: THREE.Raycaster
    private updatePendingFrameId: number | null = null
    private deferredUpdateFns = new Set<() => void>()
    private grid = new THREE.GridHelper(1000, 10, 0x444444, 0xbbbbbb)
    forceCompile = false
    private taaFrame = 0
    readonly maxTaaFrames = 64
    readonly materialManager: MaterialManager
    private idToObjectMap = new Map<ObjectId, ThreeObject>()

    readonly textureCache = new Map<string, [THREE.Texture, ((texture: THREE.Texture) => void)[]]>() // map from URL to texture object and callback list

    private _defaultFont!: Font

    private environment: ThreeEnvironment
    private environmentMapMode?: SceneNodes.SceneOptions["environmentMapMode"]
    private readonly defaultEnvironment: SceneNodes.Environment = {
        id: "defaultEnvironment",
        type: "Environment",
        envData: {
            type: "url",
            url: "assets/images/template-editor-envmap.exr",
            originalFileExtension: "exr",
        },
        intensity: 1,
        rotation: new Vector3(0, 0, 0),
        mirror: false,
        priority: 9999,
    }
    private bestEnvNode?: SceneNodes.Environment

    private renderEvent = new Subject<void>()

    private geometryAccessorCache = new AsyncCacheMap<string, MeshGeometryAccessor, MeshData>((id: string, meshData: MeshData) => {
        const geomAccessor = MeshGeometryAccessor.createFromMeshData(meshData)
        return observableOf(geomAccessor)
    })

    cameraAdded$ = new Subject<string>()
    cameraRemoved$ = new Subject<string>()

    private sceneHasLights = false

    constructor(
        private workerService: WebAssemblyWorkerService,
        readonly materialGraphManager: IMaterialGraphManager,
        readonly hdriManager: IHdriManager,
        readonly config: ThreeSceneConfig,
    ) {
        this.environment = new ThreeEnvironment(this, this.hdriManager)

        setBVHCreatedWithMesh(config.editMode!)
        this.threeScene = new THREE.Scene()
        this.rayCaster = new THREE.Raycaster()

        const precompileMaterial = (material: THREE.Material) => {
            const renderer = this.getRenderer(false)
            if (!renderer) return material
            // Precompile material:
            // NOTE: need to create temporary scene with the same shader-relevant settings (# of lights, shadow flags, etc.)
            const tmpMesh = new THREE.Mesh(new THREE.PlaneGeometry(), material)
            tmpMesh.castShadow = true
            const lights: THREE.Light[] = []
            this.threeScene.traverse((child) => {
                if (child instanceof THREE.Light) {
                    lights.push(child)
                    child.userData.tmpParent = child.parent
                }
            })
            // console.log("Compile material", material.uuid, material.name, material.userData);
            renderer.compile(new THREE.Group().add(tmpMesh, ...lights), new THREE.PerspectiveCamera())

            /*const currentProgram = renderer.info.programs.find((program) => program.name === material.name)
            if(currentProgram)
                console.log(material.name, gl.getShaderSource(currentProgram.fragmentShader))*/

            // restore parents:
            for (const light of lights) {
                light.userData.tmpParent.add(light)
                light.userData.tmpParent = undefined
            }
            return material
        }

        const materialModifier = (material: THREE.Material) => {
            if (!(material instanceof THREENodes.MeshStandardNodeMaterial)) return
            if (material.roughnessNode && this.environmentMapMode === "specularOnly") {
                const zero = constFloatNode(0)
                const one = constFloatNode(1)

                //TODO THREE UPGRADE
                //@ts-ignore
                material.envMapIntensityNode = THREENodes.pow(THREENodes.sub(one, THREENodes.clamp(material.roughnessNode, zero, one)), constFloatNode(4))
            } else {
                //TODO THREE UPGRADE
                //@ts-ignore
                material.envMapIntensityNode = null
            }
        }

        this.materialManager = new MaterialManager(this, materialGraphManager, precompileMaterial, materialModifier)

        this.rayCaster.layers.set(0)
        if (this.config.editMode) {
            this.rayCaster.layers.enable(1)
        }

        this.grid.name = "gridHelper"
        this.grid.userData.excludeFromHitTest = true
        this.grid.layers.set(1)
        this.threeScene.add(this.grid)

        if (config.backgroundColor !== undefined) {
            this.setBackgroundColor(config.backgroundColor)
        }

        if (config.textureResolution !== undefined) {
            this.materialManager.options.textureResolution = config.textureResolution
        }

        this.materialManager.options.progressiveLoading = config?.progressiveLoading ?? true

        // console.log(`progressive loading is ${this.materialManager.options.progressiveLoading ? "enabled" : "disabled"}`)

        this.grid.visible = !!config.showGrid
    }

    private updateEnvMapMode() {
        this.materialManager.applyMaterialModifierOnCachedMaterials()
        this.update()
    }

    setModifiableConfigParams(config: ThreeSceneModifiableConfigParams) {
        const prevAmbientLightConfig = this.config.ambientLight
        this.config.ambientLight = config.ambientLight ?? this.config.ambientLight
        this.config.showGrid = config.showGrid ?? this.config.showGrid

        if (this.config.ambientLight !== prevAmbientLightConfig) this.reloadEnvironmentMaps(false)

        this.grid.visible = !!this.config.showGrid
        this.update()
    }

    _views = new Set<RenderView>()

    createRenderView(initCamera = true): RenderView {
        return new RenderView(this, initCamera ? new ThreeCamera(this) : null)
    }

    private _tasks = new SyncTaskSet()
    private _renderSuspended = false
    private _renderScheduledWhileSuspended = false

    loadFont(fn: (font: Font) => void) {
        if (!this._defaultFont) {
            const loader = new FontLoader()
            loader.load("assets/threejs/helvetiker_regular.typeface.json", (font) => {
                this._defaultFont = font
                fn(font)
            })
        } else {
            fn(this._defaultFont)
        }
    }

    set renderSuspended(suspended: boolean) {
        this._renderSuspended = suspended
        if (!suspended && this._renderScheduledWhileSuspended) {
            this._renderScheduledWhileSuspended = false
            this.scheduleRender()
        }
    }

    get renderSuspended() {
        return this._renderSuspended
    }

    addTask(task: Observable<any>): void {
        this._tasks.add(task)
    }

    syncTasks(): Observable<void> {
        return forkJoinZeroOrMore([this._tasks.sync(), this.materialManager.sync()]).pipe(mapTo(undefined))
    }

    initTransformControls(transformControls: TransformControls): void {
        this.threeScene.add(transformControls.transformControls)
    }

    getObjectForId(id?: ObjectId): ThreeObject | undefined {
        return this.idToObjectMap.get(id!)
    }

    reloadEnvironmentMaps(forceUpdate: boolean) {
        const onEnvMapUpdated = (env: SceneNodes.Environment | null, texture: THREE.Texture | null) => {
            if (env === null || env === this.bestEnvNode) {
                this.threeScene.environment = texture
                this.update()
            }
        }

        if (!this.bestEnvNode || (this.bestEnvNode === this.defaultEnvironment && this.config.ambientLight !== true)) onEnvMapUpdated(null, null)
        else this.environment.update(this.bestEnvNode, forceUpdate, onEnvMapUpdated)
    }

    defaultEnvironmentMapActive() {
        return !this.sceneHasLights
    }

    updateAll(componentList: SceneNodes.SceneNode[]) {
        let needsUpdate = false

        const toDelete = new Set<ObjectId>()
        for (const [id] of this.idToObjectMap) {
            toDelete.add(id)
        }

        const addObj = (obj: ThreeObject) => {
            if (obj.threeObject) this.threeScene.add(obj.threeObject)
            if (obj.threeHelperObject) this.threeScene.add(obj.threeHelperObject)
            needsUpdate = true
        }

        const sideEffects: (() => void)[] = []

        let bestEnvNode: SceneNodes.Environment | undefined = undefined
        this.sceneHasLights = false

        for (const comp of componentList) {
            let obj: ThreeObject = this.idToObjectMap.get(comp.id)!

            if (!comp) continue

            if (SceneNodes.Mesh.is(comp)) {
                if (!obj) addObj((obj = new ThreeMesh(this)))
                ;(obj as ThreeMesh).update(comp)
            } else if (SceneNodes.AreaLight.is(comp)) {
                this.sceneHasLights = true
                if (!obj) addObj((obj = new ThreeAreaLight(this)))
                ;(obj as ThreeAreaLight).update(comp)
            } else if (SceneNodes.Camera.is(comp)) {
                if (!obj) {
                    addObj((obj = new ThreeCamera(this)))
                    sideEffects.push(() => this.cameraAdded$.next(comp.id))
                }
                ;(obj as ThreeCamera).update(comp)
            } else if (SceneNodes.Rectangle.is(comp)) {
                if (!obj) addObj((obj = new ThreeRectangle(this)))
                ;(obj as ThreeRectangle).update(comp)
            } else if (SceneNodes.LightPortal.is(comp)) {
                if (!obj) addObj((obj = new ThreeRectangle(this)))
                ;(obj as ThreeRectangle).update(comp)
            } else if (SceneNodes.Point.is(comp)) {
                if (!obj) addObj((obj = new ThreePoint(this)))
                ;(obj as ThreePoint).update(comp)
            } else if (SceneNodes.Environment.is(comp)) {
                this.sceneHasLights = true
                // only take the _last_ env node in the list
                if (!bestEnvNode || comp.priority <= bestEnvNode.priority) {
                    bestEnvNode = comp
                }
            } else if (SceneNodes.SceneOptions.is(comp)) {
                this.setBackgroundColor(comp.backgroundColor!)
                // the following needs to be evaluated before any materials are loaded!
                this.materialManager.options.textureResolution = comp.textureResolution
                this.materialManager.options.textureFiltering = comp.textureFiltering
                this.materialManager.options.shadowCatcherFalloff = comp.shadowCatcherFalloff
                if (comp.environmentMapMode !== this.environmentMapMode) {
                    this.environmentMapMode = comp.environmentMapMode
                    this.updateEnvMapMode()
                }
            } else if (SceneNodes.PreloadMaterial.is(comp)) {
                if (!obj) addObj((obj = new PreloadMaterial(this)))
                ;(obj as PreloadMaterial).update(comp)
            } else if (SceneNodes.Annotation.is(comp)) {
                if (!obj) addObj((obj = new ThreeAnnotation(this)))
                ;(obj as ThreeAnnotation).update(comp)
            } else {
                // console.warn(`Unrecognized SceneNode: ${comp.type}`);
            }

            if (obj) {
                this.idToObjectMap.set(comp.id, obj)
                toDelete.delete(comp.id)
            }
        }

        if (bestEnvNode === undefined && !this.sceneHasLights) {
            bestEnvNode = this.defaultEnvironment
        }

        if (this.bestEnvNode !== bestEnvNode) {
            this.bestEnvNode = bestEnvNode
            this.reloadEnvironmentMaps(false)
        }

        for (const id of toDelete) {
            const obj = this.idToObjectMap.get(id)!

            if (obj instanceof ThreeCamera) this.cameraRemoved$.next(id)

            if (obj.threeObject) this.threeScene.remove(obj.threeObject)
            if (obj.threeHelperObject) this.threeScene.remove(obj.threeHelperObject)

            this.idToObjectMap.delete(id)
            needsUpdate = true
        }

        if (needsUpdate) {
            this.update()
        }

        if (sideEffects.length) sideEffects.map((fn) => fn())
    }

    private _bgColorString?: string
    private setBackgroundColor(color: Color | null) {
        const bgColorString = color === null ? "transparent" : color !== undefined ? colorToString(color) : undefined
        if (bgColorString !== this._bgColorString) {
            this._bgColorString = bgColorString
            for (const view of this._views) {
                view.canvas.style.background = bgColorString!
            }
        }
    }

    getObjectsForOutlines(outlines: [ObjectId, number | undefined][] | null): THREE.Object3D[] {
        const threeObjs: THREE.Object3D[] = []
        if (outlines) {
            for (const [_, obj] of this.idToObjectMap) {
                if (obj.topLevelObjectId) {
                    for (const [id, slot] of outlines) {
                        if (obj.topLevelObjectId === id) {
                            threeObjs.push(...obj.getOutlineTokens(slot ?? null))
                        }
                    }
                }
            }
        }
        return threeObjs
    }

    setHelpersVisible(helpers: ObjectId[]): void {
        for (const [_, obj] of this.idToObjectMap) {
            obj.showEditHelpers(Boolean(obj.topLevelObjectId && helpers.indexOf(obj.topLevelObjectId) !== -1))
        }
        this.update()
    }

    update(): void {
        this.taaFrame = 0
        this.scheduleRender()
    }

    deferUpdate(fn: () => void): void {
        this.deferredUpdateFns.add(fn)
        this.update()
    }

    private scheduleRender(): void {
        if (this._renderSuspended) {
            this._renderScheduledWhileSuspended = true
        } else {
            if (this.updatePendingFrameId === null) {
                const renderFn = () => {
                    this.updatePendingFrameId = null
                    this._render()
                }
                if (isSafari) {
                    // schedule work at the _beginning_ of the next animation frame
                    this.updatePendingFrameId = requestAnimationFrame(() => setTimeout(renderFn))
                } else {
                    this.updatePendingFrameId = requestAnimationFrame(renderFn)
                }
            }
        }
    }

    syncRender(): Observable<void> {
        if (this.updatePendingFrameId === null) {
            return observableOf(undefined)
        } else {
            return this.renderEvent.pipe(take(1))
        }
    }

    flushDeferredUpdates() {
        while (this.deferredUpdateFns.size > 0) {
            const fns = Array.from(this.deferredUpdateFns)
            this.deferredUpdateFns.clear()
            for (const fn of fns) {
                fn()
            }
        }
    }

    dropDeferredUpdates() {
        this.deferredUpdateFns.clear()
    }

    forceRender() {
        if (this.updatePendingFrameId != null && this.taaFrame == 0) {
            this._render()
        }
    }

    _render(): void {
        const taaFrame = this.taaFrame++
        if (this.taaFrame < this.maxTaaFrames) {
            this.scheduleRender()
        }

        if (isSafari) {
            // FIXME
            // wait for previous GPU work to complete before starting some more
            // for (const view of this.views) {
            //     view.renderer.getContext().finish();
            // }

            for (const view of this._views) {
                view.renderer.getContext().finish()
            }
        }

        this.flushDeferredUpdates()

        for (const [_, obj] of this.idToObjectMap) {
            if (!(obj instanceof ThreeEnvironment)) {
                obj.updateSamplingFrame(taaFrame, this.maxTaaFrames)
            }
        }

        // FIXME
        // for (const view of this.views) {
        //     view.render(taaFrame, this.forceCompile);
        // }

        nodeFrame.update()

        for (const view of this._views) {
            view.render(taaFrame, this.forceCompile)
        }

        this.forceCompile = false

        this.renderEvent.next()
    }

    public getGeometryAccessorForMeshData(meshData: MeshData): Observable<IMeshGeometryAccessor> {
        return this.geometryAccessorCache.get(meshData.id, meshData)
    }

    public clearCaches(): void {
        this.geometryAccessorCache.clear()
        this.textureCache.clear()
    }

    loadTextureFromURL(url: string, freeObjectURL: boolean, onReady: (texture: THREE.Texture) => void): THREE.Texture {
        const entry = this.textureCache.get(url)
        if (!entry) {
            const callbackList = [onReady] // this list object must persist!
            const textureLoader = new THREE.TextureLoader()
            const texture = textureLoader.load(
                url,
                (texture) => {
                    if (freeObjectURL) {
                        URL.revokeObjectURL(url)
                    }
                    while (callbackList.length > 0) {
                        const cb = callbackList.shift()
                        cb?.(texture)
                    }
                },
                undefined,
                (err) => {
                    if (freeObjectURL) {
                        URL.revokeObjectURL(url)
                    }
                    console.error(err)
                },
            )
            this.textureCache.set(url, [texture, callbackList])
            return texture
        } else {
            const [texture, callbackList] = entry
            // If the callback list is empty, then the texture must have already loaded.
            if (callbackList.length === 0) {
                onReady(texture)
            } else {
                callbackList.push(onReady)
            }
            return texture
        }
    }

    public loadTexture(
        desc: LoadTextureDescriptor,
        onReady: (texture: THREE.Texture) => void,
        onHighResReady?: (texture: THREE.Texture) => void,
    ): THREE.Texture {
        let firstURL: string
        let secondURL: string | undefined
        const freeObjectURL = desc.freeObjectURL ?? false
        if (desc.primaryURL && desc.lowResURL) {
            if (desc.primaryURL === desc.lowResURL) {
                firstURL = desc.primaryURL
                secondURL = undefined
            } else {
                firstURL = desc.lowResURL
                secondURL = desc.primaryURL
            }
        } else {
            firstURL = desc.primaryURL ?? desc.lowResURL
        }
        if (!firstURL) {
            throw new Error("Image descriptor has no valid URL!")
        }
        return this.loadTextureFromURL(firstURL, freeObjectURL, (tex) => {
            // tex.onUpdate = () => {
            //     console.log("tex.onUpdate");
            //     tex.image = null; // discard DOM image
            // };
            if (secondURL) {
                this.loadTextureFromURL(secondURL, freeObjectURL, (tex2) => {
                    // don't immediately update, wait until the next frame has been rendered with the low-res texture
                    this.renderEvent.pipe(take(1)).subscribe(() => {
                        onHighResReady?.(tex2)
                    })
                })
            }
            onReady(tex) // only notify ready for the initial texture load
        })
    }

    public getRenderer(createIfNoViews: boolean): THREE.WebGLRenderer | null {
        if (this._views.size === 0) {
            if (createIfNoViews) {
                console.warn("Tried to get renderer instance without any view")
                return createCanvasAndRenderer()[1]
            } else {
                return null
            }
        }
        return this._views.values().next().value.renderer
    }

    public getThreeScene(): THREE.Scene {
        return this.threeScene
    }

    public loadHDRTexture(url: string, extension: string, onReady: (texture: THREE.Texture) => void): THREE.Texture {
        let loader: THREE.DataTextureLoader | THREE.TextureLoader
        switch (extension.toLowerCase()) {
            case "exr":
                loader = new EXRLoader().setDataType(DEFAULT_FLOAT_TEXTURE_TYPE)
                break
            case "hdr":
                loader = new RGBELoader().setDataType(DEFAULT_FLOAT_TEXTURE_TYPE)
                break
            default:
                loader = new THREE.TextureLoader()
                break
        }
        return loader.load(
            url,
            (texture) => {
                onReady(texture)
            },
            () => {},
            () => {
                console.error("Env map load error")
            },
        ) as any as THREE.Texture
    }

    destroy(): void {
        this._renderSuspended = true
        if (this.updatePendingFrameId !== null) {
            cancelAnimationFrame(this.updatePendingFrameId)
        }

        for (const [_, obj] of this.idToObjectMap) {
            obj.dispose()
        }

        this.materialManager.dispose()
    }

    _getRootObject(object: THREE.Object3D): THREE.Group | THREE.Object3D {
        if (object.parent !== null && !(object.parent instanceof THREE.Scene)) {
            return this._getRootObject(object.parent)
        } else {
            return object
        }
    }

    //TODO: get rid of this
    getTransformableObjectAndHelper(): [any, any] {
        const foundInMap: any = null //this.idToObjectMap.get(id);
        const found = foundInMap && !(foundInMap.threeObject instanceof ThreeEnvironment) && foundInMap.threeObject
        return [found, (found instanceof ThreeCamera || found instanceof ThreeAreaLight) && found.threeHelperObject] as const
    }

    exportScene(excludedObjectIDs?: (string | undefined)[]) {
        return this.syncTasks().pipe(
            switchMap(() => {
                if (!excludedObjectIDs) excludedObjectIDs = []
                const meshes: ThreeMesh[] = []
                let camera: ThreeCamera | undefined = undefined
                for (const [_, obj] of this.idToObjectMap) {
                    if (obj instanceof ThreeMesh) {
                        const topLevelId = obj.topLevelObjectId
                        if (!(topLevelId && excludedObjectIDs.includes(topLevelId))) {
                            meshes.push(obj)
                        }
                    } else if (obj instanceof ThreeCamera) {
                        camera = obj
                    }
                }
                this.threeScene.updateMatrixWorld(true)
                camera?.threeCamera.updateMatrixWorld(true)
                this.flushDeferredUpdates()
                return this.syncTasks().pipe(
                    switchMap(() => {
                        return exportSceneGLB(this.workerService, this, this.materialGraphManager, meshes, camera!)
                    }),
                    map((data) => data.buffer as ArrayBuffer),
                )
            }),
        )
    }

    readonly sceneEvent$ = new Subject<IDisplaySceneEvent>()
}
