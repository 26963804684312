import {cyclesNode, DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getDefaultMaterial} from "#material-nodes/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)})
const InputTypeSchema = z.object({color: materialSlots.optional()})
const ParametersTypeSchema = z.object({color: color.optional()})

export class BsdfTransparent extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const color = await get(inputs.color)
            const material = getDefaultMaterial()

            const alphaValue =
                color ?? threeConvert(parameters.color, threeRGBColorNode, (val) => val.r < 0.95 || val.g < 0.95 || val.b < 0.95) ?? material.opacityNode

            if (alphaValue) {
                material.transparent = true
                material.opacityNode = alphaValue
            }

            return {bsdf: material}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
