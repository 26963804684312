import {DataObjectDefinitions} from "@cm/utils/data-object"
import {JobNodes, makeJobTaskDescriptor} from "#job-nodes/job-nodes"
import {ImageProcessingNodes, TypedImageData} from "@cm/image-processing-nodes"
import * as z from "zod"
import DataObjectReference = JobNodes.DataObjectReference

type EntityId = number
export type DerivedImagesFilenames = DataObjectDefinitions.DerivedImageFilenameWithFormatAndResolutionSet | null

// ------ shader ball ------ //

export type ShaderBallPostRenderStepInput = {
    renderImage: DataObjectReference
    imageProcessingGraph: {
        inputPlaceholder: TypedImageData
        output: ImageProcessingNodes.Node
    }
    materialId: EntityId
    customerId: EntityId
    renderFileName: string
}
export const shaderBallPostRenderStepTask = makeJobTaskDescriptor<ShaderBallPostRenderStepInput, {}>("AssetRendering.ShaderBall.postRenderStep", 1)

// ------ thumbnail & tileable renders common ------ //

export const BasePostRenderStepInputSchema = z.object({
    renderImage: JobNodes.DataObjectReferenceSchema,
    materialId: z.number(),
    filenames: z.object({
        mainImageFilename: z.string(),
        derivedImagesFilenames: DataObjectDefinitions.DerivedImageFilenameWithFormatAndResolutionSetSchema.nullable(),
    }),
})

// ------ thumbnail renders ------ //

export const MaterialThumbnailPostRenderStepInputSchema = BasePostRenderStepInputSchema.extend({
    sizeCm: z.number(),
})
export const MaterialThumbnailPostRenderStepGraphSchema = z.object({
    input: MaterialThumbnailPostRenderStepInputSchema,
})

// TODO why are these not equal? (non matching type error in web-app's material asset rendering for derivedImagesFilenames field, typescript 4.7.4)
// export type MaterialThumbnailPostRenderStepInput = z.infer<typeof MaterialThumbnailPostRenderStepInputSchema>
export type MaterialThumbnailPostRenderStepInput = {
    renderImage: JobNodes.DataObjectReference
    materialId: number
    filenames: {
        mainImageFilename: string
        derivedImagesFilenames: DerivedImagesFilenames
    }
    sizeCm: number
}

export const materialThumbnailPostRenderStepTask = makeJobTaskDescriptor<MaterialThumbnailPostRenderStepInput, {}>(
    "AssetRendering.MaterialThumbnail.postRenderStep",
    1,
)

export const TemplateThumbnailPostRenderStepInputSchema = z.object({
    renderImage: JobNodes.DataObjectReferenceSchema,
    templateId: z.number(),
    renderFileName: z.string(),
    customerId: z.number(),
})
export const TemplateThumbnailPostRenderStepGraphSchema = z.object({
    input: TemplateThumbnailPostRenderStepInputSchema,
})
export type TemplateThumbnailPostRenderStepInput = z.infer<typeof TemplateThumbnailPostRenderStepInputSchema>
export const templateThumbnailPostRenderStepTask = makeJobTaskDescriptor<TemplateThumbnailPostRenderStepInput, {}>(
    "AssetRendering.TemplateThumbnail.postRenderStep",
    1,
)

// ------ tileable renders ------ //

export const TileableMaterialPostRenderStepInputSchema = BasePostRenderStepInputSchema
export const TileableMaterialPostRenderStepGraphSchema = z.object({
    input: TileableMaterialPostRenderStepInputSchema,
})
// TODO why are these not equal? (non matching type error in web-app's material asset rendering for derivedImagesFilenames field, typescript 4.7.4)
// export type TileableMaterialPostRenderStepInput = z.infer<typeof TileableMaterialPostRenderStepInputSchema>
export type TileableMaterialPostRenderStepInput = {
    renderImage: JobNodes.DataObjectReference
    materialId: number
    filenames: {
        mainImageFilename: string
        derivedImagesFilenames: DerivedImagesFilenames
    }
}

export const tileableMaterialPostRenderStepTask = makeJobTaskDescriptor<TileableMaterialPostRenderStepInput, {}>(
    "AssetRendering.TileableMaterial.postRenderStep",
    1,
)

export type PostRenderStepInputFilenames = z.infer<typeof BasePostRenderStepInputSchema>["filenames"]
