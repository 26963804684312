import {NgClass} from "@angular/common"
import {Component, input} from "@angular/core"
import {TestCaseEntry} from "@app/test/manual-testing/helpers/configurator-test-helpers"
import {TippyDirective} from "@ngneat/helipopper"

@Component({
    standalone: true,
    selector: "cm-test-button",
    templateUrl: "./test-button.component.html",
    styleUrls: ["./test-button.component.scss"],
    providers: [],
    imports: [TippyDirective, NgClass],
})
export class TestButtonComponent {
    $entry = input.required<TestCaseEntry>({alias: "entry"})
}
