import {Box2, ColorLike, Matrix3x2Like} from "@cm/math"
import {isHalImageDescriptor} from "@common/helpers/hal"
import {clearPaintable} from "@common/helpers/webgl2/webgl2-painter-utils"
import {HalImageSource} from "@common/models/hal/hal-image/types"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {HalViewRegion} from "@common/models/hal/hal-view/types"
import {WebGl2Image} from "@common/models/webgl2/webgl2-image"
import * as paper from "paper"

export function fixFormatForRenderableRGB(source: HalImageSource): {source: HalImageSource; forceAlphaToOne: boolean} {
    if (isHalImageDescriptor(source)) {
        if (source.channelLayout === "RGB") {
            // console.warn("Float32/Float16 is not supported with RGB channel layout for renderable images; reverting to RGBA channel layout")
            return {
                source: {
                    ...source,
                    channelLayout: "RGBA",
                },
                forceAlphaToOne: true,
            }
        }
    }
    return {source, forceAlphaToOne: false}
}

export class WebGl2PaintableImage extends WebGl2Image implements HalPaintableImage {
    // WebGl2Image
    override dispose() {
        this.releaseFrameBuffer()
        super.dispose()
    }

    // WebGl2Image
    override get texture(): WebGLTexture {
        // regenerate mipmaps if image has been modified
        if (this._isModified) {
            this._isModified = false
            this.generateMipmaps()
        }
        return super.texture
    }

    // HalPaintable
    get width(): number {
        return this.descriptor.width
    }

    // HalPaintable
    get height(): number {
        return this.descriptor.height
    }

    // HalPaintable
    get forceAlphaToOne(): boolean {
        return this._forceAlphaToOne
    }

    // HalPaintable
    preDraw(): void {}

    // HalPaintable
    postDraw(): void {
        const gl = this.context.gl
        gl.bindFramebuffer(gl.FRAMEBUFFER, null) // unbind framebuffer to avoid potential subsequent feedback framebuffer operation
    }

    // HalPaintable
    getNumDrawPasses(): number {
        return this.numShards
    }

    // HalPaintable
    setDrawPass(region: HalViewRegion, pass: number): Matrix3x2Like {
        if (!this._frameBuffers) {
            throw Error("Frame buffers not initialized")
        }
        const gl = this.context.gl
        const renderTargetShardIndex = pass * this._shardsPerPass
        this._isModified = true // we assume that the image will be modified

        const shardRegion = new Box2(
            (renderTargetShardIndex % this.numShardsX) * this.shardWidth,
            Math.floor(renderTargetShardIndex / this.numShardsX) * this.shardHeight,
            this.shardWidth,
            this.shardHeight,
        )
        const regionIntersection = Box2.intersect(shardRegion, region)
        gl.bindFramebuffer(gl.FRAMEBUFFER, this._frameBuffers[renderTargetShardIndex])
        gl.viewport(regionIntersection.x - shardRegion.x, regionIntersection.y - shardRegion.y, regionIntersection.width, regionIntersection.height)
        const transform = new paper.Matrix(1, 0, 0, 1, -1, -1) // origin at top left
        transform.append(new paper.Matrix(2 / regionIntersection.width, 0, 0, 2 / regionIntersection.height, 0, 0)) // normalize to [-1, 1]
        transform.append(new paper.Matrix(1, 0, 0, 1, -Math.max(0, shardRegion.x - region.x), -Math.max(0, shardRegion.y - region.y))) // offset to shard
        return transform
    }

    // HalPaintable
    async clear(color?: ColorLike): Promise<void> {
        return clearPaintable(this, color)
    }

    // WebGl2Image
    override async create(source: HalImageSource) {
        this.releaseFrameBuffer()
        const fixedSource = fixFormatForRenderableRGB(source)
        this._forceAlphaToOne = fixedSource.forceAlphaToOne
        await super.create(fixedSource.source)
        this.createFrameBuffer()
    }

    private createFrameBuffer(): void {
        this.releaseFrameBuffer()
        if (this.numShards > 0) {
            if (this.descriptor.dataType === "float32") {
                if (!this.context.EXT_color_buffer_float) {
                    throw new Error("Float32 format for paintable images not supported by device")
                }
                if (this.descriptor.channelLayout === "RGB") {
                    throw new Error("Float32 format for paintable images not supported for RGB channel layout")
                }
            } else if (this.descriptor.dataType === "float16") {
                if (!this.context.EXT_color_buffer_half_float) {
                    throw new Error("Float16 format for paintable images not supported by device")
                }
                if (this.descriptor.channelLayout === "RGB") {
                    throw new Error("Float16 format for paintable images not supported for RGB channel layout")
                }
            }
            const gl = this.context.gl
            const currentBinding = gl.getParameter(gl.FRAMEBUFFER_BINDING)
            this._frameBuffers = []
            for (let i = 0; i < this.numShards; i++) {
                const frameBuffer = gl.createFramebuffer()
                if (!frameBuffer) {
                    throw new Error("Failed to create framebuffer")
                }
                this._frameBuffers.push(frameBuffer)
                gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer)
                gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, super.texture, 0, i)
            }
            gl.bindFramebuffer(gl.FRAMEBUFFER, currentBinding) // restore previous binding
        }
    }

    private releaseFrameBuffer(): void {
        if (this._frameBuffers) {
            const gl = this.context.gl
            this._frameBuffers.forEach((frameBuffer) => gl.deleteFramebuffer(frameBuffer))
            this._frameBuffers = undefined
        }
    }

    private _forceAlphaToOne = false
    private _frameBuffers?: WebGLFramebuffer[]
    private _isModified = false
    private readonly _shardsPerPass = 1 // TODO there is some optimization potential here as we could render maxDrawBuffers shards simultaneously per pass (but this makes the shaders more complex)
}
