@if ($data(); as item) {
    <div class="cm-entity-metadata-tooltip">
        @if (item.createdBy?.name || item.createdAt) {
            <div>
                @if (item.createdBy?.name; as createdByName) {
                    <span
                        >Created by <strong>{{ createdByName }}</strong></span
                    >
                } @else {
                    <span>Created</span>
                }
            </div>
            @if (item.createdAt) {
                <div>
                    <span>{{ formatForDisplay(item.createdAt, {shorten: false}) }}</span>
                </div>
            }
        }
        @if (item.updatedBy?.name || item.updatedAt) {
            <div>
                @if (item.updatedBy?.name; as updatedByName) {
                    <span
                        >Last modified by <strong>{{ updatedByName }}</strong></span
                    >
                } @else {
                    <span>Last modified</span>
                }
            </div>
            @if (item.updatedAt) {
                <div>
                    <span>{{ formatForDisplay(item.updatedAt, {shorten: false}) }}</span>
                </div>
            }
        }
    </div>
}
