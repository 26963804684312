import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  GraphQLBigInt: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type ArStatistics = {
  __typename: 'ArStatistics';
  configString: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  sceneRevision?: Maybe<SceneRevision>;
  templateRevision?: Maybe<TemplateRevision>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ArStatisticsCreateDataInput = {
  configString: Scalars['String']['input'];
  created: Scalars['DateTime']['input'];
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type ArStatisticsFilterInput = {
  configString?: InputMaybe<StringFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  sceneRevisionId?: InputMaybe<IdInFilter>;
  sceneRevisionLegacyId?: InputMaybe<IntInFilter>;
  templateRevisionId?: InputMaybe<IdInFilter>;
  templateRevisionLegacyId?: InputMaybe<IntInFilter>;
};

export enum ArStatisticsOrderByCriteria {
  Id = 'id'
}

export type ArStatisticsOrderByInput = {
  direction: SortOrder;
  key: ArStatisticsOrderByCriteria;
};

export type Asset = {
  __typename: 'Asset';
  articleId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialAssignments: Array<MaterialAssignment>;
  materialAssignmentsCount: Scalars['Int']['output'];
  materials: Array<Material>;
  model: Model;
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization: Organization;
  pictureAssignments: Array<PictureAssetAssignment>;
  /** Pictures assigned to this asset via a PictureAssetAssignment. This is a convenience field that is equivalent to `pictureAssignments.picture`. */
  pictures: Array<Picture>;
  picturesCount: Scalars['Int']['output'];
  priorityOrder?: Maybe<Scalars['GraphQLBigInt']['output']>;
  state: AssetState;
  taskInfos: TaskInfos;
  template: Template;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type AssetDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type AssetMaterialsArgs = {
  where?: InputMaybe<MaterialFilterInput>;
};


export type AssetUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type AssetCreateInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  dataObjects?: InputMaybe<Array<DataObjectAssignmentCreateWithoutObjectInput>>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId: Scalars['ID']['input'];
  state: AssetState;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type AssetFilterInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<StringFilterInput>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  materialId?: InputMaybe<IdInFilter>;
  materialLegacyId?: InputMaybe<IntInFilter>;
  modelId?: InputMaybe<IdInFilter>;
  modelLegacyId?: InputMaybe<IntInFilter>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<AssetState>>;
  userId?: InputMaybe<IdInFilter>;
};

export type AssetHistory = {
  __typename: 'AssetHistory';
  articleId?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<Asset>;
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  state: AssetState;
};

export type AssetHistoryFilterInput = {
  articleId?: InputMaybe<StringFilterInput>;
  assetId?: InputMaybe<IdInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  modelId?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  state?: InputMaybe<Array<AssetState>>;
  templateId?: InputMaybe<IdInFilter>;
};

export enum AssetHistoryOrderByCriteria {
  HistoryDate = 'history_date',
  Id = 'id'
}

export type AssetHistoryOrderByInput = {
  direction: SortOrder;
  key: AssetHistoryOrderByCriteria;
};

export enum AssetOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Priority = 'priority'
}

export type AssetOrderByInput = {
  direction: SortOrder;
  key: AssetOrderByCriteria;
};

export enum AssetState {
  Completed = 'Completed',
  InfoReview = 'InfoReview',
  Review = 'Review',
  Shading = 'Shading'
}

export type AssetUpdateInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId: Scalars['ID']['input'];
  state: AssetState;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ContentTypeModel {
  Asset = 'Asset',
  AssetHistory = 'AssetHistory',
  DataObject = 'DataObject',
  DataObjectAssignment = 'DataObjectAssignment',
  Hdri = 'Hdri',
  IdFromCatalog = 'IdFromCatalog',
  IdFromCatalogToPriceGraph = 'IdFromCatalogToPriceGraph',
  Job = 'Job',
  JobFarmGroup = 'JobFarmGroup',
  JobFarmInstance = 'JobFarmInstance',
  JobTask = 'JobTask',
  JsonFile = 'JsonFile',
  Material = 'Material',
  MaterialAssignment = 'MaterialAssignment',
  MaterialAssignmentHistory = 'MaterialAssignmentHistory',
  MaterialConnection = 'MaterialConnection',
  MaterialHistory = 'MaterialHistory',
  MaterialNode = 'MaterialNode',
  MaterialReference = 'MaterialReference',
  MaterialRevision = 'MaterialRevision',
  Model = 'Model',
  ModelHistory = 'ModelHistory',
  Organization = 'Organization',
  Picture = 'Picture',
  PictureAssetAssignmentHistory = 'PictureAssetAssignmentHistory',
  PictureHistory = 'PictureHistory',
  PictureRevision = 'PictureRevision',
  ProductionStatistics = 'ProductionStatistics',
  Project = 'Project',
  RenderJob = 'RenderJob',
  ScanJob = 'ScanJob',
  ScanSubJob = 'ScanSubJob',
  Scene = 'Scene',
  SceneRevision = 'SceneRevision',
  Set = 'Set',
  StorageDeleteTask = 'StorageDeleteTask',
  Tag = 'Tag',
  TagAssignment = 'TagAssignment',
  Task = 'Task',
  TaskComment = 'TaskComment',
  TaskPin = 'TaskPin',
  Template = 'Template',
  TemplateRevision = 'TemplateRevision',
  Texture = 'Texture',
  TextureGroup = 'TextureGroup',
  TextureReference = 'TextureReference',
  TextureRevision = 'TextureRevision',
  TextureSet = 'TextureSet',
  TextureSetRevision = 'TextureSetRevision',
  User = 'User',
  UserAssignment = 'UserAssignment',
  UserAssignmentHistory = 'UserAssignmentHistory'
}

export type CreateDataObjectAssignmentInlineInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  assignmentType: DataObjectAssignmentType;
  dataObject?: InputMaybe<CreateDataObjectInlineInput>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDataObjectInlineInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  originalFileName: Scalars['String']['input'];
  relatedToId?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  state: DataObjectState;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DataObject = {
  __typename: 'DataObject';
  assignments: Array<DataObjectAssignment>;
  assignmentsCount: Scalars['Int']['output'];
  bucketName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  downloadUrl: Scalars['String']['output'];
  hdris: Array<Hdri>;
  hdrisCount: Scalars['Int']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageColorProfile?: Maybe<Scalars['String']['output']>;
  /** The image's color space. Note that this does not match the color space reported by the OS or libraries like sharp. For example, rendered EXR files always have ImageColorSpace.Linear while sharp reports 'scrgb' and Mac OS 'RGB' */
  imageColorSpace?: Maybe<ImageColorSpace>;
  imageDataType?: Maybe<ImageDataType>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevisions: Array<MaterialRevision>;
  materialRevisionsCount: Scalars['Int']['output'];
  mediaType?: Maybe<Scalars['String']['output']>;
  metadataProcessingJob?: Maybe<Job>;
  objectName: Scalars['String']['output'];
  organization: Organization;
  originalFileName: Scalars['String']['output'];
  processedByVersion?: Maybe<Scalars['String']['output']>;
  related: Array<DataObject>;
  relatedCount: Scalars['Int']['output'];
  relatedTo?: Maybe<DataObject>;
  signedUploadUrl: Scalars['String']['output'];
  size?: Maybe<Scalars['GraphQLBigInt']['output']>;
  state: DataObjectState;
  textureRevisions: Array<TextureRevision>;
  textureRevisionsCount: Scalars['Int']['output'];
  textureSetRevisionMapAssignments: Array<TextureSetRevisionMapAssignment>;
  textureSetRevisionMapAssignmentsCount: Scalars['Int']['output'];
  textureSetRevisionSourceDataObjectReferences: Array<TextureSetRevisionSourceDataObjectReference>;
  textureSetRevisionSourceDataObjectReferencesCount: Scalars['Int']['output'];
  thumbnail?: Maybe<DataObject>;
  thumbnailAvailable: Scalars['Boolean']['output'];
  thumbnailProcessingJob?: Maybe<Job>;
  type?: Maybe<DataObjectType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  width?: Maybe<Scalars['Int']['output']>;
};


export type DataObjectDownloadUrlArgs = {
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
};


export type DataObjectThumbnailArgs = {
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
};

export type DataObjectAssignment = {
  __typename: 'DataObjectAssignment';
  assignedTo?: Maybe<DataObjectAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  assignmentType: DataObjectAssignmentType;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObject: DataObject;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  objectId: Scalars['String']['output'];
  objectLegacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type DataObjectAssignmentCreateWithoutObjectInput = {
  assignmentKey: Scalars['String']['input'];
  assignmentType: DataObjectAssignmentType;
  dataObject?: InputMaybe<DataObjectCreateInput>;
  dataObjectId?: InputMaybe<Scalars['String']['input']>;
  dataObjectType?: InputMaybe<Array<DataObjectType>>;
};

export type DataObjectAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  assignmentType?: InputMaybe<Array<DataObjectAssignmentType>>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export enum DataObjectAssignmentOrderByCriteria {
  AssignmentKey = 'assignmentKey',
  AssignmentType = 'assignmentType',
  Id = 'id'
}

export type DataObjectAssignmentOrderByInput = {
  direction: SortOrder;
  key: DataObjectAssignmentOrderByCriteria;
};

export type DataObjectAssignmentSubject = Asset | Hdri | JobTask | JsonFile | Material | MaterialRevision | Model | Organization | Picture | PictureRevision | Project | ScanJob | ScanSubJob | Scene | SceneRevision | Set | Tag | Template | TemplateRevision | Texture | TextureGroup | TextureRevision | TextureSet | User;

export enum DataObjectAssignmentType {
  Attachment = 'Attachment',
  BatchExport = 'BatchExport',
  CachedTemplateGltf = 'CachedTemplateGltf',
  CachedTemplateUsdz = 'CachedTemplateUsdz',
  CryptoMatte01 = 'CryptoMatte01',
  CryptoMatte02 = 'CryptoMatte02',
  CryptoMatte03 = 'CryptoMatte03',
  CustomerLogo = 'CustomerLogo',
  DrawingData = 'DrawingData',
  Font = 'Font',
  FontBold = 'FontBold',
  GalleryImage = 'GalleryImage',
  GalleryImageMain = 'GalleryImageMain',
  Icon = 'Icon',
  JobTaskData = 'JobTaskData',
  JobTaskInternalData = 'JobTaskInternalData',
  MaterialCetSimple = 'MaterialCetSimple',
  MaterialMapsExport = 'MaterialMapsExport',
  MaterialThumbnail_7x7Inches = 'MaterialThumbnail_7x7Inches',
  MaterialThumbnail_9x9Inches = 'MaterialThumbnail_9x9Inches',
  MaterialThumbnail_10x10 = 'MaterialThumbnail_10x10',
  MaterialThumbnail_16x16Inches = 'MaterialThumbnail_16x16Inches',
  MaterialThumbnail_20x20 = 'MaterialThumbnail_20x20',
  MaterialThumbnail_30x30 = 'MaterialThumbnail_30x30',
  MaterialTileableRender = 'MaterialTileableRender',
  MeshDataDrc = 'MeshDataDrc',
  MeshDataDrcProxy = 'MeshDataDrcProxy',
  MeshDataPly = 'MeshDataPly',
  PdfTemplate = 'PdfTemplate',
  PictureData = 'PictureData',
  PictureDataLinear = 'PictureDataLinear',
  PictureDataSecondary = 'PictureDataSecondary',
  PictureDataTertiary = 'PictureDataTertiary',
  ProfilePicture = 'ProfilePicture',
  ScanAssemblyWorkPackage = 'ScanAssemblyWorkPackage',
  ScanCalibrationData = 'ScanCalibrationData',
  ScanFittingWorkPackage = 'ScanFittingWorkPackage',
  ScanLog = 'ScanLog',
  ScanMergedFile = 'ScanMergedFile',
  ScanReferenceFile = 'ScanReferenceFile',
  TemplateConfigIcon = 'TemplateConfigIcon',
  TemplateDataOther = 'TemplateDataOther',
  TextureEditDescriptor = 'TextureEditDescriptor',
  TextureEditMask = 'TextureEditMask',
  TilingGraph = 'TilingGraph'
}

export type DataObjectAssignmentsFilterInput = {
  every?: InputMaybe<DataObjectAssignmentFilterInput>;
  none?: InputMaybe<DataObjectAssignmentFilterInput>;
  some?: InputMaybe<DataObjectAssignmentFilterInput>;
};

export type DataObjectCreateInput = {
  bucketName: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  objectName: Scalars['String']['input'];
  originalFileName: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Float']['input']>;
  state: DataObjectState;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type DataObjectFilterInput = {
  assignedToContentType?: InputMaybe<ContentTypeModel>;
  assignedToId?: InputMaybe<Scalars['ID']['input']>;
  assignedToLegacyId?: InputMaybe<Scalars['Int']['input']>;
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  assignmentType?: InputMaybe<Array<DataObjectAssignmentType>>;
  bucketName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  /** If true, only return objects that are related to a parent. If false, only return objects that are not related to a parent. */
  isRelated?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<IntFilterInput>;
  mediaType?: InputMaybe<StringFilterInput>;
  objectName?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  /** If provided, only return objects that are related to a parent matching the UUID filter. */
  relatedTo?: InputMaybe<IdInFilter>;
  state?: InputMaybe<Array<DataObjectState>>;
};

export enum DataObjectOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  ObjectName = 'objectName',
  OriginalFileName = 'originalFileName'
}

export type DataObjectOrderByInput = {
  direction: SortOrder;
  key: DataObjectOrderByCriteria;
};

export enum DataObjectState {
  Completed = 'Completed',
  Init = 'Init',
  Processing = 'Processing',
  ProcessingFailed = 'ProcessingFailed',
  UploadFinished = 'UploadFinished'
}

export enum DataObjectType {
  Archive = 'Archive',
  Image = 'Image',
  Mesh = 'Mesh',
  Model = 'Model',
  ModelDrc = 'ModelDrc',
  ModelObj = 'ModelObj',
  Other = 'Other',
  Pdf = 'Pdf',
  Scan = 'Scan',
  Text = 'Text',
  TextJson = 'TextJson',
  Texture = 'Texture',
  TmpFile = 'TmpFile',
  Video = 'Video'
}

export type DateTimeFilterInput = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DownloadFileType {
  Exr = 'exr',
  Jpg = 'jpg',
  Png = 'png',
  Tiff = 'tiff'
}

export enum DownloadResolution {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Tiny = 'Tiny'
}

export enum DownloadType {
  Cet = 'Cet',
  Pbr = 'Pbr',
  Thumbnail = 'Thumbnail',
  Tile = 'Tile'
}

export type FloatFilterInput = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Hdri = {
  __typename: 'Hdri';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObject?: Maybe<DataObject>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  hdriReferences: Array<TemplateRevision>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  tasksCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type HdriDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type HdriJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type HdriShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type HdriTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};

export type HdriFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum HdriOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type HdriOrderByInput = {
  direction: SortOrder;
  key: HdriOrderByCriteria;
};

export enum HistoryType {
  Added = 'Added',
  Deleted = 'Deleted',
  Moved = 'Moved'
}

export type IdFilterInput = {
  contains?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  equals?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<IdFilterInput>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IdFromCatalog = {
  __typename: 'IdFromCatalog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  disambiguator?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  originalId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  uuid: Scalars['ID']['output'];
};

export type IdFromCatalogToPriceGraph = {
  __typename: 'IdFromCatalogToPriceGraph';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  idFromCatalog: IdFromCatalog;
  idFromCatalogUuid: Scalars['ID']['output'];
  priceGraphUuid: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type IdFromCatalogToPriceGraphFilterInput = {
  disambiguator?: InputMaybe<Scalars['String']['input']>;
  organizationUuid: Scalars['ID']['input'];
  originalIds: Array<Scalars['String']['input']>;
};

export enum IdFromCatalogToPriceGraphOrderByCriteria {
  PriceGraphUuid = 'priceGraphUuid'
}

export type IdFromCatalogToPriceGraphOrderByInput = {
  direction: SortOrder;
  key: IdFromCatalogToPriceGraphOrderByCriteria;
};

export type IdInFilter = {
  equals?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum ImageColorSpace {
  Gamma_2_0 = 'Gamma_2_0',
  Gamma_2_2 = 'Gamma_2_2',
  Linear = 'Linear',
  Srgb = 'Srgb',
  Unknown = 'Unknown'
}

export enum ImageDataType {
  Color = 'Color',
  NonColor = 'NonColor',
  Unknown = 'Unknown'
}

export type IntFilterInput = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  not?: InputMaybe<IntFilterInput>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntInFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum InvocationMethod {
  CloudTasks = 'CloudTasks',
  ComputeEngine = 'ComputeEngine',
  Http = 'Http',
  None = 'None'
}

export type Job = {
  __typename: 'Job';
  assignments: Array<JobAssignment>;
  cancelled?: Maybe<Scalars['DateTime']['output']>;
  claimedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  executionPlan?: Maybe<Scalars['Json']['output']>;
  finished?: Maybe<Scalars['DateTime']['output']>;
  graph?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  jobTasks: Array<JobTask>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  metadataProcessingJobForDataObject?: Maybe<DataObject>;
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  output?: Maybe<Scalars['Json']['output']>;
  priority: Scalars['Int']['output'];
  progress?: Maybe<Scalars['Int']['output']>;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: JobState;
  thumbnailProcessingJobForDataObject?: Maybe<DataObject>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type JobDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type JobJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type JobAssignment = {
  __typename: 'JobAssignment';
  assignedTo?: Maybe<JobAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  job: Job;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobAssignmentOrderByCriteria {
  AssignmentKey = 'assignmentKey',
  Id = 'id'
}

export type JobAssignmentOrderByInput = {
  direction: SortOrder;
  key: JobAssignmentOrderByCriteria;
};

export type JobAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type JobFarmGroup = {
  __typename: 'JobFarmGroup';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  instances: Array<JobFarmInstance>;
  name: Scalars['String']['output'];
  organization: Organization;
  otherConfig?: Maybe<Scalars['Json']['output']>;
  queueGroup: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobFarmGroupFilterInput = {
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  queueGroup?: InputMaybe<StringInFilter>;
};

export enum JobFarmGroupOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type JobFarmGroupOrderByInput = {
  direction: SortOrder;
  key: JobFarmGroupOrderByCriteria;
};

export type JobFarmInstance = {
  __typename: 'JobFarmInstance';
  claim?: Maybe<JobTask>;
  /** @deprecated Not yet implemented */
  control: JobFarmInstanceControl;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  group?: Maybe<JobFarmGroup>;
  hwFingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastReport?: Maybe<Scalars['DateTime']['output']>;
  otherInfo?: Maybe<Scalars['Json']['output']>;
  state: JobFarmInstanceState;
  type: JobFarmInstanceType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
  version: Scalars['String']['output'];
};

export enum JobFarmInstanceControl {
  ExitNow = 'ExitNow',
  ExitWhenIdle = 'ExitWhenIdle',
  Pause = 'Pause',
  Run = 'Run',
  UpdateNow = 'UpdateNow',
  UpdateWhenIdle = 'UpdateWhenIdle'
}

export type JobFarmInstanceFilterInput = {
  groupId?: InputMaybe<IdInFilter>;
  hwFingerprint?: InputMaybe<StringInFilter>;
  id?: InputMaybe<IdInFilter>;
  lastReport?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<JobFarmInstanceState>;
  type?: InputMaybe<JobFarmInstanceType>;
  userId?: InputMaybe<IdInFilter>;
  version?: InputMaybe<StringFilterInput>;
};

export enum JobFarmInstanceOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type JobFarmInstanceOrderByInput = {
  direction: SortOrder;
  key: JobFarmInstanceOrderByCriteria;
};

export enum JobFarmInstanceState {
  NotResponding = 'NotResponding',
  Paused = 'Paused',
  Running = 'Running',
  Stopped = 'Stopped',
  Updating = 'Updating'
}

export enum JobFarmInstanceType {
  RenderNodeApp = 'RenderNodeApp'
}

export type JobFilterInput = {
  createdByIds?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<JobState>>;
};

export enum JobOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  Started = 'started'
}

export type JobOrderByInput = {
  direction: SortOrder;
  key: JobOrderByCriteria;
};

export enum JobState {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  Init = 'Init',
  Runnable = 'Runnable',
  Running = 'Running'
}

export type JobTask = {
  __typename: 'JobTask';
  assignments: Array<JobTaskAssignment>;
  attemptCount: Scalars['Int']['output'];
  cancelled?: Maybe<Scalars['DateTime']['output']>;
  claimedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: User;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  deferredUntil?: Maybe<Scalars['DateTime']['output']>;
  finished?: Maybe<Scalars['DateTime']['output']>;
  graph?: Maybe<Scalars['Json']['output']>;
  hashInParent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  job?: Maybe<JobTask>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  maxAttempts: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  metadataProcessingJobForDataObject?: Maybe<DataObject>;
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  output?: Maybe<Scalars['Json']['output']>;
  priority: Scalars['Int']['output'];
  progress?: Maybe<Scalars['Int']['output']>;
  queueGroup?: Maybe<Scalars['String']['output']>;
  queueToken?: Maybe<Scalars['String']['output']>;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: JobTaskState;
  taskType?: Maybe<Scalars['String']['output']>;
  thumbnailProcessingJobForDataObject?: Maybe<DataObject>;
  updateDeadline?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  versionCounter: Scalars['Int']['output'];
};


export type JobTaskDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type JobTaskJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type JobTaskAssignment = {
  __typename: 'JobTaskAssignment';
  assignedTo?: Maybe<JobTaskAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  jobTask: JobTask;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobTaskAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobTaskId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobTaskAssignmentOrderByCriteria {
  AssignmentKey = 'assignmentKey',
  Id = 'id'
}

export type JobTaskAssignmentOrderByInput = {
  direction: SortOrder;
  key: JobTaskAssignmentOrderByCriteria;
};

export type JobTaskAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type JobTaskFilterInput = {
  claimedBy?: InputMaybe<StringInFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<IdInFilter>;
  jobId?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<JobTaskState>>;
  taskType?: InputMaybe<Scalars['String']['input']>;
  versionCounter?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobTaskOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  Started = 'started',
  VersionCounter = 'versionCounter'
}

export type JobTaskOrderByInput = {
  direction: SortOrder;
  key: JobTaskOrderByCriteria;
};

export type JobTaskRunnableFilterEntry = {
  /** @deprecated No longer used */
  maxVersion?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated No longer used */
  minVersion?: InputMaybe<Scalars['Int']['input']>;
  /** A string corresponding to a registered task type (e.g. `AssetRendering.ShaderBall.postRenderStep`). It must have a matching entry in the JobTaskType database table. No relation to the enum of the same name. */
  taskType: Scalars['String']['input'];
};

export type JobTaskRunnableFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  queueGroup: Scalars['String']['input'];
  queueToken?: InputMaybe<Scalars['String']['input']>;
  taskTypes?: InputMaybe<Array<JobTaskRunnableFilterEntry>>;
};

export enum JobTaskState {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  Fatal = 'Fatal',
  Init = 'Init',
  Missing = 'Missing',
  Queued = 'Queued',
  Runnable = 'Runnable',
  Running = 'Running',
  Timeout = 'Timeout'
}

export type JobTaskType = {
  __typename: 'JobTaskType';
  cancelTimeout?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  defaultTimeout?: Maybe<Scalars['Int']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invocationMethod: InvocationMethod;
  maxTimeout?: Maybe<Scalars['Int']['output']>;
  queueGroup: Scalars['String']['output'];
  taskType: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobTaskTypeFilterInput = {
  endpoint?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  queueGroup?: InputMaybe<StringFilterInput>;
  taskType?: InputMaybe<StringFilterInput>;
};

export enum JobTaskTypeOrderByCriteria {
  LegacyId = 'legacyId',
  QueueGroup = 'queueGroup',
  TaskType = 'taskType'
}

export type JobTaskTypeOrderByInput = {
  direction: SortOrder;
  key: JobTaskTypeOrderByCriteria;
};

export type JsonFile = {
  __typename: 'JsonFile';
  content: Scalars['Json']['output'];
  created: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jsonFileType: JsonFileType;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  organization: Organization;
  outputDataObject?: Maybe<DataObject>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type JsonFileDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFileAssignment = {
  __typename: 'JsonFileAssignment';
  assignedTo?: Maybe<JsonFileAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  assignmentType: JsonFileAssignmentType;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  jsonFile: JsonFile;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  objectId: Scalars['String']['output'];
  objectLegacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JsonFileAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  assignmentType?: InputMaybe<Array<JsonFileAssignmentType>>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jsonFileContent?: InputMaybe<Array<JsonFileConfigInput>>;
  jsonFileId?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFileAssignmentOrderByInput = {
  assignmentKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  objectId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type JsonFileAssignmentSubject = Asset | Hdri | JobTask | Material | MaterialRevision | Model | Organization | Picture | PictureRevision | Project | ScanJob | ScanSubJob | Scene | SceneRevision | Set | Tag | Template | TemplateRevision | Texture | TextureGroup | TextureRevision | TextureSet | User;

export enum JsonFileAssignmentType {
  MaterialMapsExportConfig = 'MaterialMapsExportConfig',
  TextureEdits = 'TextureEdits'
}

export type JsonFileConfigInput = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<DownloadFileType>;
  name?: InputMaybe<Scalars['String']['input']>;
  normalY?: InputMaybe<Scalars['String']['input']>;
  resolution?: InputMaybe<DownloadResolution>;
  state?: InputMaybe<Scalars['String']['input']>;
  workflow?: InputMaybe<Scalars['String']['input']>;
};

export type JsonFileCreateWithoutAssignmentInput = {
  content: Scalars['Json']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type: JsonFileType;
};

export type JsonFileFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<JsonFileType>>;
};

export enum JsonFileOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type JsonFileOrderByInput = {
  direction: SortOrder;
  key: JsonFileOrderByCriteria;
};

export enum JsonFileType {
  MaterialMapsExportConfig = 'MaterialMapsExportConfig',
  TextureEdits = 'TextureEdits'
}

export type LoginResult = {
  __typename: 'LoginResult';
  /** JWT token for authentication. To use, add a header `Authorization: "Bearer <token>"`. Expires in 30 days. */
  token: Scalars['String']['output'];
  /** The user that has just been logged in */
  user: User;
};

export type Material = {
  __typename: 'Material';
  articleId?: Maybe<Scalars['String']['output']>;
  /** Assets assigned to this material via a MaterialAssignment. This is a convenience field that is equivalent to `materialAssignments.assets`. */
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  cyclesRevisions?: Maybe<Array<MaterialRevision>>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestCyclesRevision?: Maybe<MaterialRevision>;
  latestRevision?: Maybe<MaterialRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  meshSpecific: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']['output']>;
  paymentState: PaymentState;
  priorityOrder?: Maybe<Scalars['GraphQLBigInt']['output']>;
  public: Scalars['Boolean']['output'];
  revisions: Array<MaterialRevision>;
  revisionsCount: Scalars['Int']['output'];
  sampleArrival?: Maybe<Scalars['DateTime']['output']>;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: MaterialState;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  textureGroup?: Maybe<TextureGroup>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type MaterialDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MaterialJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type MaterialShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type MaterialTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type MaterialUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type MaterialAssignment = {
  __typename: 'MaterialAssignment';
  asset: Asset;
  assetId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material: Material;
  materialId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialAssignmentFilterInput = {
  asset?: InputMaybe<AssetFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  material?: InputMaybe<MaterialFilterInput>;
};

export type MaterialAssignmentHistory = {
  __typename: 'MaterialAssignmentHistory';
  asset?: Maybe<Asset>;
  createdBy?: Maybe<User>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material?: Maybe<Material>;
};

export type MaterialAssignmentHistoryFilterInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  assignmentId?: InputMaybe<IdInFilter>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
};

export enum MaterialAssignmentHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type MaterialAssignmentHistoryOrderByInput = {
  direction: SortOrder;
  key: MaterialAssignmentHistoryOrderByCriteria;
};

export type MaterialConnection = {
  __typename: 'MaterialConnection';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  destination: MaterialNode;
  destinationParameter: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  source: MaterialNode;
  sourceParameter: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialConnectionFilterInput = {
  id?: InputMaybe<IdInFilter>;
  materialRevisionId?: InputMaybe<IdInFilter>;
};

export enum MaterialConnectionOrderByCriteria {
  Id = 'id',
  MaterialRevisionId = 'materialRevisionId'
}

export type MaterialConnectionOrderByInput = {
  direction: SortOrder;
  key: MaterialConnectionOrderByCriteria;
};

export type MaterialFilterInput = {
  articleId?: InputMaybe<StringInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasCyclesMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  internalName?: InputMaybe<StringFilterInput>;
  legacyId?: InputMaybe<IntInFilter>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrived?: InputMaybe<Scalars['Boolean']['input']>;
  scannedByNone?: InputMaybe<Scalars['Boolean']['input']>;
  scannedByUserId?: InputMaybe<IdInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<MaterialState>>;
  tagId?: InputMaybe<IdInFilter>;
  tagLegacyId?: InputMaybe<IntInFilter>;
  userId?: InputMaybe<IdInFilter>;
};

export type MaterialHistory = {
  __typename: 'MaterialHistory';
  articleId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material?: Maybe<Material>;
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  paymentState: PaymentState;
  public: Scalars['Boolean']['output'];
  sampleArrival?: Maybe<Scalars['DateTime']['output']>;
  state: MaterialState;
};

export type MaterialHistoryFilterInput = {
  articleId?: InputMaybe<StringInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  internalName?: InputMaybe<StringFilterInput>;
  materialId?: InputMaybe<IdInFilter>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrival?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<MaterialState>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum MaterialHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type MaterialHistoryOrderByInput = {
  direction: SortOrder;
  key: MaterialHistoryOrderByCriteria;
};

export type MaterialNode = {
  __typename: 'MaterialNode';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  incoming: Array<MaterialConnection>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  name: Scalars['String']['output'];
  outgoing: Array<MaterialConnection>;
  parameters: Scalars['Json']['output'];
  textureRevision?: Maybe<TextureRevision>;
  textureSetRevision?: Maybe<TextureSetRevision>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialNodeFilterInput = {
  id?: InputMaybe<IdInFilter>;
  materialRevisionId?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  textureRevisionId?: InputMaybe<IdInFilter>;
};

export enum MaterialNodeOrderByCriteria {
  Id = 'id',
  MaterialRevisionId = 'materialRevisionId'
}

export type MaterialNodeOrderByInput = {
  direction: SortOrder;
  key: MaterialNodeOrderByCriteria;
};

export enum MaterialOrderByCriteria {
  ArticleId = 'articleId',
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  Priority = 'priority',
  Public = 'public',
  State = 'state'
}

export type MaterialOrderByInput = {
  direction: SortOrder;
  key: MaterialOrderByCriteria;
};

export type MaterialReference = {
  __typename: 'MaterialReference';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  templateRevision: TemplateRevision;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialRevision = {
  __typename: 'MaterialRevision';
  comment?: Maybe<Scalars['String']['output']>;
  connections: Array<MaterialConnection>;
  connectionsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  graphSchema?: Maybe<Scalars['String']['output']>;
  hasCyclesMaterial: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  matFileDataObject?: Maybe<DataObject>;
  material: Material;
  nodes: Array<MaterialNode>;
  nodesCount: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  /** All template revisions that reference this material revision via materialReferences */
  templateRevisions: Array<TemplateRevision>;
  templateRevisionsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type MaterialRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MaterialRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type MaterialRevisionFilterInput = {
  comment?: InputMaybe<StringFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  hasCyclesMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  matFileDataObjectId?: InputMaybe<IntFilterInput>;
  materialId?: InputMaybe<IdInFilter>;
  number?: InputMaybe<IntFilterInput>;
};

export enum MaterialRevisionOrderByCriteria {
  Id = 'id',
  MaterialId = 'material_id',
  Number = 'number'
}

export type MaterialRevisionOrderByInput = {
  direction: SortOrder;
  key: MaterialRevisionOrderByCriteria;
};

export enum MaterialState {
  Completed = 'Completed',
  Draft = 'Draft',
  InfoReview = 'InfoReview',
  OnHold = 'OnHold',
  Review = 'Review',
  Scanning = 'Scanning',
  Shading = 'Shading',
  Tiling = 'Tiling'
}

export type Membership = {
  __typename: 'Membership';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  organization: Organization;
  packages: Array<Package>;
  role: MembershipRole;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export type MembershipCreateInput = {
  organizationId: Scalars['ID']['input'];
  role: MembershipRole;
};

export enum MembershipRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Viewer = 'Viewer'
}

export type Model = {
  __typename: 'Model';
  activeUserAssignment?: Maybe<UserAssignment>;
  articleId?: Maybe<Scalars['String']['output']>;
  assets: Array<Asset>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization?: Maybe<Organization>;
  paymentState: PaymentState;
  public: Scalars['Boolean']['output'];
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: ModelState;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  type: ModelType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type ModelDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ModelJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type ModelShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type ModelTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type ModelUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type ModelFilterInput = {
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<ModelState>>;
  tagId?: InputMaybe<IdInFilter>;
  userId?: InputMaybe<IdInFilter>;
};

export type ModelHistory = {
  __typename: 'ModelHistory';
  articleId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  model?: Maybe<Model>;
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization?: Maybe<Organization>;
  paymentState: PaymentState;
  public: Scalars['Boolean']['output'];
  state: ModelState;
  type: ModelType;
};

export type ModelHistoryFilterInput = {
  articleId?: InputMaybe<StringInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  date?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  internalName?: InputMaybe<StringFilterInput>;
  modelId?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<ModelState>>;
  type?: InputMaybe<Array<ModelType>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum ModelHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type ModelHistoryOrderByInput = {
  direction: SortOrder;
  key: ModelHistoryOrderByCriteria;
};

export enum ModelOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name',
  PaymentState = 'paymentState',
  Priority = 'priority',
  Public = 'public',
  State = 'state'
}

export type ModelOrderByInput = {
  direction: SortOrder;
  key: ModelOrderByCriteria;
};

export enum ModelState {
  Completed = 'Completed',
  Draft = 'Draft',
  InfoReview = 'InfoReview',
  Modelling = 'Modelling',
  OnHold = 'OnHold',
  Review = 'Review'
}

export enum ModelType {
  Product = 'Product',
  Room = 'Room'
}

export type Mutation = {
  __typename: 'Mutation';
  batchUpdateArStatistics: Scalars['Int']['output'];
  batchUpdateAssets: Scalars['Int']['output'];
  batchUpdateDataObjectAssignments: Scalars['Int']['output'];
  batchUpdateMaterials: Scalars['Int']['output'];
  batchUpdateModels: Scalars['Int']['output'];
  batchUpdatePictures: Scalars['Int']['output'];
  batchUpdateRenderJobs: Scalars['Int']['output'];
  batchUpdateSceneStatistics: Scalars['Int']['output'];
  /** Mark as cancelled and process job graph */
  cancelJob: Job;
  /** Find an unclaimed, runnable job task matching filter criteria and claim it for processing */
  claimRunnableJobTask?: Maybe<JobTask>;
  /** Claim a specific task for processing. Failed tasks can be retried if claimed this way. */
  claimTask?: Maybe<JobTask>;
  /** Mark as completed and resynchronize data object assignments of type `JobTaskData` */
  completeJobTask: JobTask;
  createArStatistics: ArStatistics;
  createAsset: Asset;
  createDataObject: DataObject;
  createDataObjectAssignment: DataObjectAssignment;
  createHdri: Hdri;
  /** Create a new job and start it straightaway */
  createJob: Job;
  createJobAssignment: JobAssignment;
  createJobFarmGroup: JobFarmGroup;
  createJobFarmInstance: JobFarmInstance;
  /** Create a single job task. Useful for tests. */
  createJobTask: JobTask;
  createJobTaskAssignment: JobTaskAssignment;
  createJobTaskType: JobTaskType;
  createJsonFile: JsonFile;
  createJsonFileAssignment: JsonFileAssignment;
  createMaterial: Material;
  createMaterialAssignment: MaterialAssignment;
  createMaterialConnection: MaterialConnection;
  createMaterialNode: MaterialNode;
  createMaterialReference: MaterialReference;
  createMaterialRevision: MaterialRevision;
  createMembership: Membership;
  createModel: Model;
  createOrUpdatePrices?: Maybe<Scalars['Void']['output']>;
  createOrganization: Organization;
  createPicture: Picture;
  createPictureAssetAssignment: PictureAssetAssignment;
  createPictureRevision: PictureRevision;
  createPriceGraph: PriceGraph;
  createPrices: Scalars['Void']['output'];
  createProject: Project;
  createRenderJob: RenderJob;
  createSalesEnquiry: SalesEnquiry;
  createScanJob: ScanJob;
  createScanSubJob: ScanSubJob;
  createScene: Scene;
  createSceneRevision: SceneRevision;
  createSceneStatistics: SceneStatistics;
  createSet: Set;
  createStorageDeleteTask: StorageDeleteTask;
  createTag: Tag;
  createTagAssignment: TagAssignment;
  createTask: Task;
  createTaskComment: TaskComment;
  createTaskPin: TaskPin;
  createTemplate: Template;
  createTemplateRevision: TemplateRevision;
  createTexture: Texture;
  createTextureEditGroup: TextureEditGroup;
  createTextureGroup: TextureGroup;
  createTextureRevision: TextureRevision;
  createTextureSet: TextureSet;
  createTextureSetRevision: TextureSetRevision;
  /** Create a raw job without starting it. Useful for tests and other cases that require tight control of the job's state. */
  createUnstartedJob: Job;
  createUser: User;
  createUserAssignment: UserAssignment;
  deleteArStatistics?: Maybe<Scalars['Void']['output']>;
  deleteAsset?: Maybe<Scalars['Void']['output']>;
  deleteDataObject?: Maybe<Scalars['Void']['output']>;
  deleteDataObjectAssignment?: Maybe<Scalars['Void']['output']>;
  deleteDataObjectAssignments: Scalars['Int']['output'];
  deleteHdri?: Maybe<Scalars['Void']['output']>;
  deleteIdFromCustomerToPriceGraph?: Maybe<Scalars['Void']['output']>;
  /** Delete a job and all of its children */
  deleteJob?: Maybe<Scalars['Void']['output']>;
  deleteJobAssignment?: Maybe<Scalars['Void']['output']>;
  deleteJobFarmGroup?: Maybe<Scalars['Void']['output']>;
  deleteJobFarmInstance?: Maybe<Scalars['Void']['output']>;
  deleteJobTaskAssignment?: Maybe<Scalars['Void']['output']>;
  deleteJobTaskType?: Maybe<Scalars['Void']['output']>;
  deleteJsonFile?: Maybe<Scalars['Void']['output']>;
  deleteJsonFileAssignment?: Maybe<Scalars['Void']['output']>;
  deleteMaterial?: Maybe<Scalars['Void']['output']>;
  deleteMaterialAssignment?: Maybe<Scalars['Void']['output']>;
  deleteMaterialConnection?: Maybe<Scalars['Void']['output']>;
  deleteMaterialNode?: Maybe<Scalars['Void']['output']>;
  deleteMaterialReference?: Maybe<Scalars['Void']['output']>;
  deleteMaterialRevision?: Maybe<Scalars['Void']['output']>;
  deleteMembership?: Maybe<Scalars['Void']['output']>;
  deleteModel?: Maybe<Scalars['Void']['output']>;
  deleteOrganization?: Maybe<Scalars['Void']['output']>;
  deletePicture?: Maybe<Scalars['Void']['output']>;
  deletePictureAssetAssignment?: Maybe<Scalars['Void']['output']>;
  deletePictureRevision?: Maybe<Scalars['Void']['output']>;
  deletePriceGraph?: Maybe<Scalars['Void']['output']>;
  deletePrices?: Maybe<Scalars['Void']['output']>;
  deleteProject?: Maybe<Scalars['Void']['output']>;
  deleteRenderJob?: Maybe<Scalars['Void']['output']>;
  deleteSalesEnquiry?: Maybe<Scalars['Void']['output']>;
  deleteScanJob?: Maybe<Scalars['Void']['output']>;
  deleteScanSubJob?: Maybe<Scalars['Void']['output']>;
  deleteScene?: Maybe<Scalars['Void']['output']>;
  deleteSceneRevision?: Maybe<Scalars['Void']['output']>;
  deleteSceneStatistics?: Maybe<Scalars['Void']['output']>;
  deleteSet?: Maybe<Scalars['Void']['output']>;
  deleteStorageDeleteTask?: Maybe<Scalars['Void']['output']>;
  deleteTag?: Maybe<Scalars['Void']['output']>;
  deleteTagAssignment?: Maybe<Scalars['Void']['output']>;
  deleteTask?: Maybe<Scalars['Void']['output']>;
  deleteTaskComment?: Maybe<Scalars['Void']['output']>;
  deleteTaskPin?: Maybe<Scalars['Void']['output']>;
  deleteTemplate?: Maybe<Scalars['Void']['output']>;
  deleteTemplateRevision?: Maybe<Scalars['Void']['output']>;
  deleteTexture?: Maybe<Scalars['Void']['output']>;
  deleteTextureEditGroup?: Maybe<Scalars['Void']['output']>;
  deleteTextureGroup?: Maybe<Scalars['Void']['output']>;
  deleteTextureRevision?: Maybe<Scalars['Void']['output']>;
  deleteTextureSet?: Maybe<Scalars['Void']['output']>;
  deleteTextureSetRevision: TextureSetRevision;
  deleteUser?: Maybe<Scalars['Void']['output']>;
  deleteUserAssignment?: Maybe<Scalars['Void']['output']>;
  deleteUserAssignments: Scalars['Int']['output'];
  /** Mark job task as failed */
  failJobTask: JobTask;
  /** Generate an API key for an API service user. Use in `X-Api-Key` header to authenticate API users. This type of key does not expire and should be rotated regularly. */
  generateApiKey: Scalars['String']['output'];
  /** Generate AR file for a template. */
  generateAr: Job;
  inviteUser: User;
  /** Log in a user. Select the `token` field to get a JWT token for authentication. */
  login: LoginResult;
  /** Trigger job graph processing for all active jobs to advance them more quickly */
  nudgeAllJobs: Scalars['Int']['output'];
  /** Trigger job graph processing to advance the job more quickly */
  nudgeJob: Job;
  /** Delete and recreate all child tasks, then restart the job */
  rerunAllTasksInJob: Job;
  resetPassword: Scalars['String']['output'];
  /** Reprocess job from the top, keeping completed tasks */
  restartJob: Job;
  sendPasswordResetEmail: Scalars['Boolean']['output'];
  updateArStatistics: ArStatistics;
  updateAsset: Asset;
  updateDataObject: DataObject;
  updateDataObjectAssignment: DataObjectAssignment;
  updateHdri: Hdri;
  updateJob: Job;
  updateJobAssignment: JobAssignment;
  updateJobFarmGroup: JobFarmGroup;
  updateJobFarmInstance: JobFarmInstance;
  updateJobTask: JobTask;
  updateJobTaskAssignment: JobTaskAssignment;
  updateJobTaskType: JobTaskType;
  updateJsonFile: JsonFile;
  updateJsonFileAssignment: JsonFileAssignment;
  updateMaterial: Material;
  updateMaterialConnection: MaterialConnection;
  updateMaterialNode: MaterialNode;
  updateMaterialReference: MaterialReference;
  updateMaterialRevision: MaterialRevision;
  updateModel: Model;
  updateOrganization: Organization;
  updatePicture: Picture;
  updatePictureRevision: PictureRevision;
  updatePriceGraph: PriceGraph;
  updatePrices: Scalars['Void']['output'];
  updateProject: Project;
  updateRenderJob: RenderJob;
  updateScanJob: ScanJob;
  updateScanSubJob: ScanSubJob;
  updateScene: Scene;
  updateSceneRevision: SceneRevision;
  updateSceneStatistics: SceneStatistics;
  updateSet: Set;
  updateStorageDeleteTask: StorageDeleteTask;
  updateTag: Tag;
  updateTagAssignment: TagAssignment;
  updateTask: Task;
  updateTaskComment: TaskComment;
  updateTaskPin: TaskPin;
  updateTemplate: Template;
  updateTemplateRevision: TemplateRevision;
  updateTemplateRevisionNew: TemplateRevision;
  updateTexture: Texture;
  updateTextureEditGroup: TextureEditGroup;
  updateTextureGroup: TextureGroup;
  updateTextureRevision: TextureRevision;
  updateTextureSet: TextureSet;
  updateTextureSetRevision: TextureSetRevision;
  updateUser: User;
  updateUserAssignment: UserAssignment;
};


export type MutationBatchUpdateArStatisticsArgs = {
  filter?: InputMaybe<ArStatisticsFilterInput>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationBatchUpdateAssetsArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AssetFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<AssetState>;
};


export type MutationBatchUpdateDataObjectAssignmentsArgs = {
  contentTypeModel: ContentTypeModel;
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  objectId: Scalars['ID']['input'];
};


export type MutationBatchUpdateMaterialsArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<MaterialFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<MaterialState>;
};


export type MutationBatchUpdateModelsArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ModelFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<ModelState>;
};


export type MutationBatchUpdatePicturesArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<PictureFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<PictureState>;
};


export type MutationBatchUpdateRenderJobsArgs = {
  filter?: InputMaybe<RenderJobFilterInput>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationBatchUpdateSceneStatisticsArgs = {
  filter?: InputMaybe<SceneStatisticsFilterInput>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCancelJobArgs = {
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationClaimRunnableJobTaskArgs = {
  filter: JobTaskRunnableFilterInput;
  instanceId: Scalars['String']['input'];
  updateTimeout: Scalars['Int']['input'];
};


export type MutationClaimTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  queueGroup: Scalars['String']['input'];
  queueToken?: InputMaybe<Scalars['String']['input']>;
  updateTimeout: Scalars['Int']['input'];
};


export type MutationCompleteJobTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  output: Scalars['Json']['input'];
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateArStatisticsArgs = {
  input: MutationCreateArStatisticsInput;
};


export type MutationCreateAssetArgs = {
  input: MutationCreateAssetInput;
};


export type MutationCreateDataObjectArgs = {
  input?: InputMaybe<MutationCreateDataObjectInput>;
};


export type MutationCreateDataObjectAssignmentArgs = {
  input: MutationCreateDataObjectAssignmentInput;
};


export type MutationCreateHdriArgs = {
  input: MutationCreateHdriInput;
};


export type MutationCreateJobArgs = {
  input: MutationCreateJobInput;
};


export type MutationCreateJobAssignmentArgs = {
  input: MutationCreateJobAssignmentInput;
};


export type MutationCreateJobFarmGroupArgs = {
  input: MutationCreateJobFarmGroupInput;
};


export type MutationCreateJobFarmInstanceArgs = {
  input: MutationCreateJobFarmInstanceInput;
};


export type MutationCreateJobTaskArgs = {
  input: MutationCreateJobTaskInput;
};


export type MutationCreateJobTaskAssignmentArgs = {
  input: MutationCreateJobTaskAssignmentInput;
};


export type MutationCreateJobTaskTypeArgs = {
  input: MutationCreateJobTaskTypeInput;
};


export type MutationCreateJsonFileArgs = {
  input: MutationCreateJsonFileInput;
};


export type MutationCreateJsonFileAssignmentArgs = {
  input: MutationCreateJsonFileAssignmentInput;
};


export type MutationCreateMaterialArgs = {
  input: MutationCreateMaterialInput;
};


export type MutationCreateMaterialAssignmentArgs = {
  input: MutationCreateMaterialAssignmentInput;
};


export type MutationCreateMaterialConnectionArgs = {
  input: MutationCreateMaterialConnectionInput;
};


export type MutationCreateMaterialNodeArgs = {
  input: MutationCreateMaterialNodeInput;
};


export type MutationCreateMaterialReferenceArgs = {
  input: MutationCreateMaterialReferenceInput;
};


export type MutationCreateMaterialRevisionArgs = {
  input: MutationCreateMaterialRevisionInput;
};


export type MutationCreateMembershipArgs = {
  input: MutationCreateMembershipInput;
};


export type MutationCreateModelArgs = {
  input: MutationCreateModelInput;
};


export type MutationCreateOrUpdatePricesArgs = {
  organizationId: Scalars['ID']['input'];
  prices: Array<PriceInput>;
};


export type MutationCreateOrganizationArgs = {
  input: MutationCreateOrganizationInput;
};


export type MutationCreatePictureArgs = {
  input: MutationCreatePictureInput;
};


export type MutationCreatePictureAssetAssignmentArgs = {
  input: MutationCreatePictureAssetAssignmentInput;
};


export type MutationCreatePictureRevisionArgs = {
  input: MutationCreatePictureRevisionInput;
};


export type MutationCreatePriceGraphArgs = {
  input: MutationCreatePriceGraphInput;
};


export type MutationCreatePricesArgs = {
  organizationId: Scalars['ID']['input'];
  prices: Array<PriceInput>;
};


export type MutationCreateProjectArgs = {
  input: MutationCreateProjectInput;
};


export type MutationCreateRenderJobArgs = {
  input: MutationCreateRenderJobInput;
};


export type MutationCreateSalesEnquiryArgs = {
  input: MutationCreateSalesEnquiryInput;
};


export type MutationCreateScanJobArgs = {
  input: MutationCreateScanJobInput;
};


export type MutationCreateScanSubJobArgs = {
  input: MutationCreateScanSubJobInput;
};


export type MutationCreateSceneArgs = {
  input: MutationCreateSceneInput;
};


export type MutationCreateSceneRevisionArgs = {
  input: MutationCreateSceneRevisionInput;
};


export type MutationCreateSceneStatisticsArgs = {
  input: MutationCreateSceneStatisticsInput;
};


export type MutationCreateSetArgs = {
  input: MutationCreateSetInput;
};


export type MutationCreateStorageDeleteTaskArgs = {
  input: MutationCreateStorageDeleteTaskInput;
};


export type MutationCreateTagArgs = {
  input: MutationCreateTagInput;
};


export type MutationCreateTagAssignmentArgs = {
  input: MutationCreateTagAssignmentInput;
};


export type MutationCreateTaskArgs = {
  input: MutationCreateTaskInput;
};


export type MutationCreateTaskCommentArgs = {
  input: MutationCreateTaskCommentInput;
};


export type MutationCreateTaskPinArgs = {
  input: MutationCreateTaskPinInput;
};


export type MutationCreateTemplateArgs = {
  input: MutationCreateTemplateInput;
};


export type MutationCreateTemplateRevisionArgs = {
  input: MutationCreateTemplateRevisionInput;
};


export type MutationCreateTextureArgs = {
  input: MutationCreateTextureInput;
};


export type MutationCreateTextureEditGroupArgs = {
  input: MutationCreateTextureEditGroupInput;
};


export type MutationCreateTextureGroupArgs = {
  input: MutationCreateTextureGroupInput;
};


export type MutationCreateTextureRevisionArgs = {
  input: MutationCreateTextureRevisionInput;
};


export type MutationCreateTextureSetArgs = {
  input: MutationCreateTextureSetInput;
};


export type MutationCreateTextureSetRevisionArgs = {
  input: MutationCreateTextureSetRevisionInput;
};


export type MutationCreateUnstartedJobArgs = {
  input: MutationCreateUnstartedJobInput;
};


export type MutationCreateUserArgs = {
  input: MutationCreateUserInput;
};


export type MutationCreateUserAssignmentArgs = {
  input: MutationCreateUserAssignmentInput;
};


export type MutationDeleteArStatisticsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAssetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDataObjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDataObjectAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDataObjectAssignmentsArgs = {
  input: MutationDeleteDataObjectAssignmentsInput;
};


export type MutationDeleteHdriArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteIdFromCustomerToPriceGraphArgs = {
  priceGraphId: Scalars['ID']['input'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteJobAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobFarmGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobFarmInstanceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobTaskAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobTaskTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJsonFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteJsonFileAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialNodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteModelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeletePictureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePictureAssetAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePictureRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePriceGraphArgs = {
  priceGraphId: Scalars['ID']['input'];
};


export type MutationDeletePricesArgs = {
  organizationId: Scalars['ID']['input'];
  originalIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRenderJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSalesEnquiryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteScanJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteScanSubJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSceneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSceneRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSceneStatisticsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStorageDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteTagAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskPinArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureEditGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureSetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureSetRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteUserAssignmentsArgs = {
  input: MutationDeleteUserAssignmentsInput;
};


export type MutationFailJobTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  isFatal?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateApiKeyArgs = {
  input: MutationGenerateApiKeyInput;
};


export type MutationGenerateArArgs = {
  configString: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLegacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationInviteUserArgs = {
  input: MutationInviteUserInput;
};


export type MutationLoginArgs = {
  input: MutationLoginInput;
};


export type MutationNudgeJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRerunAllTasksInJobArgs = {
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordArgs = {
  input: MutationResetPasswordInput;
};


export type MutationRestartJobArgs = {
  graph?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationSendPasswordResetEmailArgs = {
  input: MutationSendPasswordResetEmailInput;
};


export type MutationUpdateArStatisticsArgs = {
  input: MutationUpdateArStatisticsInput;
};


export type MutationUpdateAssetArgs = {
  input: MutationUpdateAssetInput;
};


export type MutationUpdateDataObjectArgs = {
  input: MutationUpdateDataObjectInput;
};


export type MutationUpdateDataObjectAssignmentArgs = {
  input: MutationUpdateDataObjectAssignmentInput;
};


export type MutationUpdateHdriArgs = {
  input: MutationUpdateHdriInput;
};


export type MutationUpdateJobArgs = {
  input: MutationUpdateJobInput;
};


export type MutationUpdateJobAssignmentArgs = {
  input: MutationUpdateJobAssignmentInput;
};


export type MutationUpdateJobFarmGroupArgs = {
  input: MutationUpdateJobFarmGroupInput;
};


export type MutationUpdateJobFarmInstanceArgs = {
  input: MutationUpdateJobFarmInstanceInput;
};


export type MutationUpdateJobTaskArgs = {
  input: MutationUpdateJobTaskInput;
};


export type MutationUpdateJobTaskAssignmentArgs = {
  input: MutationUpdateJobTaskAssignmentInput;
};


export type MutationUpdateJobTaskTypeArgs = {
  input: MutationUpdateJobTaskTypeInput;
};


export type MutationUpdateJsonFileArgs = {
  input: MutationUpdateJsonFileInput;
};


export type MutationUpdateJsonFileAssignmentArgs = {
  input: MutationUpdateJsonFileAssignmentInput;
};


export type MutationUpdateMaterialArgs = {
  input: MutationUpdateMaterialInput;
};


export type MutationUpdateMaterialConnectionArgs = {
  input: MutationUpdateMaterialConnectionInput;
};


export type MutationUpdateMaterialNodeArgs = {
  input: MutationUpdateMaterialNodeInput;
};


export type MutationUpdateMaterialReferenceArgs = {
  input: MutationUpdateMaterialReferenceInput;
};


export type MutationUpdateMaterialRevisionArgs = {
  input: MutationUpdateMaterialRevisionInput;
};


export type MutationUpdateModelArgs = {
  input: MutationUpdateModelInput;
};


export type MutationUpdateOrganizationArgs = {
  input: MutationUpdateOrganizationInput;
};


export type MutationUpdatePictureArgs = {
  input: MutationUpdatePictureInput;
};


export type MutationUpdatePictureRevisionArgs = {
  input: MutationUpdatePictureRevisionInput;
};


export type MutationUpdatePriceGraphArgs = {
  input: MutationUpdatePriceGraphInput;
};


export type MutationUpdatePricesArgs = {
  organizationId: Scalars['ID']['input'];
  prices: Array<PriceInput>;
};


export type MutationUpdateProjectArgs = {
  input: MutationUpdateProjectInput;
};


export type MutationUpdateRenderJobArgs = {
  input: MutationUpdateRenderJobInput;
};


export type MutationUpdateScanJobArgs = {
  input: MutationUpdateScanJobInput;
};


export type MutationUpdateScanSubJobArgs = {
  input: MutationUpdateScanSubJobInput;
};


export type MutationUpdateSceneArgs = {
  input: MutationUpdateSceneInput;
};


export type MutationUpdateSceneRevisionArgs = {
  input: MutationUpdateSceneRevisionInput;
};


export type MutationUpdateSceneStatisticsArgs = {
  input: MutationUpdateSceneStatisticsInput;
};


export type MutationUpdateSetArgs = {
  input: MutationUpdateSetInput;
};


export type MutationUpdateStorageDeleteTaskArgs = {
  input: MutationUpdateStorageDeleteTaskInput;
};


export type MutationUpdateTagArgs = {
  input: MutationUpdateTagInput;
};


export type MutationUpdateTagAssignmentArgs = {
  input: MutationUpdateTagAssignmentInput;
};


export type MutationUpdateTaskArgs = {
  input: MutationUpdateTaskInput;
};


export type MutationUpdateTaskCommentArgs = {
  input: MutationUpdateTaskCommentInput;
};


export type MutationUpdateTaskPinArgs = {
  input: MutationUpdateTaskPinInput;
};


export type MutationUpdateTemplateArgs = {
  input: MutationUpdateTemplateInput;
};


export type MutationUpdateTemplateRevisionArgs = {
  input: MutationUpdateTemplateRevisionInput;
};


export type MutationUpdateTemplateRevisionNewArgs = {
  input: MutationUpdateTemplateRevisionNewInput;
};


export type MutationUpdateTextureArgs = {
  input: MutationUpdateTextureInput;
};


export type MutationUpdateTextureEditGroupArgs = {
  input: MutationUpdateTextureEditGroupInput;
};


export type MutationUpdateTextureGroupArgs = {
  input: MutationUpdateTextureGroupInput;
};


export type MutationUpdateTextureRevisionArgs = {
  input: MutationUpdateTextureRevisionInput;
};


export type MutationUpdateTextureSetArgs = {
  input: MutationUpdateTextureSetInput;
};


export type MutationUpdateTextureSetRevisionArgs = {
  input: MutationUpdateTextureSetRevisionInput;
};


export type MutationUpdateUserArgs = {
  input: MutationUpdateUserInput;
};


export type MutationUpdateUserAssignmentArgs = {
  input: MutationUpdateUserAssignmentInput;
};

export type MutationCreateArStatisticsInput = {
  configString: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
  templateRevisionLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateAssetInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  dataObjectAssignments?: InputMaybe<Array<CreateDataObjectAssignmentInlineInput>>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId: Scalars['ID']['input'];
  pictureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state: AssetState;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateDataObjectAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  dataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyAssignmentType?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DataObjectAssignmentType>;
};

export type MutationCreateDataObjectInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  originalFileName: Scalars['String']['input'];
  processedByVersion?: InputMaybe<Scalars['String']['input']>;
  relatedToId?: InputMaybe<Scalars['ID']['input']>;
  relatedToLegacyId?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated No longer used. Create and start a job to perform upload processing. */
  state?: InputMaybe<DataObjectState>;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateHdriInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  jobLegacyId?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `objectId` instead */
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobFarmGroupInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  otherConfig?: InputMaybe<Scalars['Json']['input']>;
  queueGroup: Scalars['String']['input'];
};

export type MutationCreateJobFarmInstanceInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  hwFingerprint: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastReport?: InputMaybe<Scalars['DateTime']['input']>;
  otherInfo?: InputMaybe<Scalars['Json']['input']>;
  state: JobFarmInstanceState;
  type: JobFarmInstanceType;
  userId?: InputMaybe<Scalars['ID']['input']>;
  version: Scalars['String']['input'];
};

export type MutationCreateJobInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadataProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  metadataProcessingJobForDataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `organizationId` instead */
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<JobState>;
  thumbnailProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  thumbnailProcessingJobForDataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobTaskAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  jobTaskId?: InputMaybe<Scalars['ID']['input']>;
  jobTaskLegacyId?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `objectId` instead */
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobTaskInput = {
  cancelled?: InputMaybe<Scalars['DateTime']['input']>;
  claimedBy?: InputMaybe<Scalars['String']['input']>;
  deferredUntil?: InputMaybe<Scalars['DateTime']['input']>;
  finished?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  hashInParent?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `organizationId` instead */
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  output?: InputMaybe<Scalars['Json']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<JobTaskState>;
};

export type MutationCreateJobTaskTypeInput = {
  cancelTimeout?: InputMaybe<Scalars['Int']['input']>;
  defaultTimeout?: InputMaybe<Scalars['Int']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invocationMethod: InvocationMethod;
  maxTimeout?: InputMaybe<Scalars['Int']['input']>;
  queueGroup: Scalars['String']['input'];
  /** The job task's type as a string. Note that this is different from a task's type field (of type `TaskType`) */
  taskType: Scalars['String']['input'];
};

export type MutationCreateJsonFileAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  jsonFile?: InputMaybe<JsonFileCreateWithoutAssignmentInput>;
  jsonFileId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<JsonFileAssignmentType>;
};

export type MutationCreateJsonFileInput = {
  content: Scalars['Json']['input'];
  createdById?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<JsonFileType>;
};

export type MutationCreateMaterialAssignmentInput = {
  assetId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
};

export type MutationCreateMaterialConnectionInput = {
  destinationId: Scalars['ID']['input'];
  destinationParameter: Scalars['String']['input'];
  materialRevisionId: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  sourceParameter: Scalars['String']['input'];
};

export type MutationCreateMaterialInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nextActor: NextActor;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paymentState: PaymentState;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrival?: InputMaybe<Scalars['DateTime']['input']>;
  state: MaterialState;
  tagAssignments?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationCreateMaterialNodeInput = {
  incomingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialRevisionId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  outgoingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  parameters: Scalars['Json']['input'];
  textureRevisionId?: InputMaybe<Scalars['ID']['input']>;
  textureSetRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateMaterialReferenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  materialRevisionId: Scalars['ID']['input'];
  templateRevisionId: Scalars['ID']['input'];
};

export type MutationCreateMaterialRevisionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  connectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  graphSchema: Scalars['String']['input'];
  matFileDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['ID']['input'];
  materialReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateMembershipInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCreateModelInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paymentState: PaymentState;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state: ModelState;
  type: ModelType;
};

export type MutationCreateOrganizationInput = {
  defaultScannerUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  salesEmail?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrganizationType>;
  url?: InputMaybe<Scalars['String']['input']>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreatePictureAssetAssignmentInput = {
  assetId: Scalars['ID']['input'];
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  pictureLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreatePictureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  number?: InputMaybe<Scalars['Int']['input']>;
  paymentState: PaymentState;
  priorityOrder?: InputMaybe<Scalars['Int']['input']>;
  setId: Scalars['String']['input'];
  state?: InputMaybe<PictureState>;
};

export type MutationCreatePictureRevisionInlineDataObjectAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  dataObjectId: Scalars['ID']['input'];
  type: DataObjectAssignmentType;
};

export type MutationCreatePictureRevisionInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  dataObjectAssignment?: InputMaybe<MutationCreatePictureRevisionInlineDataObjectAssignmentInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureId: Scalars['ID']['input'];
};

export type MutationCreatePriceGraphInput = {
  graph: Scalars['Json']['input'];
  organizationId: Scalars['ID']['input'];
  originalIdsFromCatalog: Array<Scalars['String']['input']>;
  state?: InputMaybe<PriceGraphState>;
  templateId: Scalars['ID']['input'];
};

export type MutationCreateProjectInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type MutationCreateRenderJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  samples?: InputMaybe<Scalars['Int']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<RenderJobState>;
  statusMessage?: InputMaybe<Scalars['String']['input']>;
  templateRevisionId: Scalars['ID']['input'];
};

export type MutationCreateSalesEnquiryInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactName: Scalars['String']['input'];
  context?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateScanJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  lastFailedState?: InputMaybe<ScanJobState>;
  progress?: InputMaybe<Scalars['String']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  sampleRotation?: InputMaybe<Scalars['Int']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanJobState>;
  stateStarted?: InputMaybe<Scalars['DateTime']['input']>;
  textureSetId: Scalars['ID']['input'];
};

export type MutationCreateScanSubJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  lastFailedState?: InputMaybe<ScanSubJobState>;
  progress?: InputMaybe<Scalars['String']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  scanJobId: Scalars['ID']['input'];
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanSubJobState>;
  tileX: Scalars['Int']['input'];
  tileY: Scalars['Int']['input'];
};

export type MutationCreateSceneInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pictureId: Scalars['ID']['input'];
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  revisions?: InputMaybe<Array<SceneCreateSceneRevisionInlineInput>>;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCreateSceneRevisionInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sceneId: Scalars['ID']['input'];
};

export type MutationCreateSceneStatisticsInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateSetInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type MutationCreateStorageDeleteTaskInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  gcsBucketName: Scalars['String']['input'];
  gcsObjectName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateTagAssignmentInput = {
  contentTypeModel: ContentTypeModel;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type MutationCreateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  otherInfo?: InputMaybe<Scalars['String']['input']>;
  tagAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: TagType;
};

export type MutationCreateTaskCommentInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  taskId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationCreateTaskInput = {
  commentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contentTypeModel: ContentTypeModel;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['ID']['input'];
  pinIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state: TaskState;
  title?: InputMaybe<Scalars['String']['input']>;
  type: TaskType;
};

export type MutationCreateTaskPinInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  taskId: Scalars['ID']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type MutationCreateTemplateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  exSceneId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  pictureLegacyId?: InputMaybe<Scalars['Int']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  revisions?: InputMaybe<Array<TemplateCreateTemplateRevisionInlineInput>>;
  state: TemplateState;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateType: TemplateType;
};

export type MutationCreateTemplateRevisionInput = {
  completed: Scalars['Boolean']['input'];
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  exSceneRevisionId?: InputMaybe<Scalars['Int']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  templateId: Scalars['ID']['input'];
};

export type MutationCreateTextureEditGroupInput = {
  content?: InputMaybe<Scalars['Json']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated Prefer to use `revisionIds` instead */
  revisionLegacyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateTextureGroupInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  textureSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Create texture sets inline. Overrides `textureSetIds`. */
  textureSets?: InputMaybe<Array<TextureSetInlineCreateInput>>;
};

export type MutationCreateTextureInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  textureSetId: Scalars['ID']['input'];
  type: TextureType;
};

export type MutationCreateTextureRevisionInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  createdByVersion?: InputMaybe<Scalars['String']['input']>;
  dataObjectId: Scalars['ID']['input'];
  displacement?: InputMaybe<Scalars['Float']['input']>;
  height: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<TextureRevisionState>;
  textureId?: InputMaybe<Scalars['ID']['input']>;
  width: Scalars['Float']['input'];
};

export type MutationCreateTextureSetInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['ID']['input']>;
  textureGroupId: Scalars['ID']['input'];
  textureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCreateTextureSetRevisionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  editsJson?: InputMaybe<Scalars['Json']['input']>;
  editsProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  height: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mapAssignments: Array<TextureSetRevisionMapAssignmentInput>;
  migratedFromTextureEditGroupId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  textureSetId: Scalars['ID']['input'];
  width: Scalars['Float']['input'];
};

export type MutationCreateUnstartedJobInput = {
  cancelled?: InputMaybe<Scalars['DateTime']['input']>;
  claimedBy?: InputMaybe<Scalars['String']['input']>;
  finished?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  hashInParent?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadataProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `organizationId` instead */
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  output?: InputMaybe<Scalars['Json']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<JobState>;
  thumbnailProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateUserAssignmentInput = {
  contentTypeModel: ContentTypeModel;
  id?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['ID']['input'];
  /** If true, remove all existing user assignments with the same combination of (objectId, contentTypeModel, state) before creating the new one. */
  removeExisting?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<UserAssignmentState>;
  type: UserAssignmentType;
  userId: Scalars['ID']['input'];
};

export type MutationCreateUserInput = {
  dateJoined?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  memberships?: InputMaybe<Array<MembershipCreateInput>>;
  password: Scalars['String']['input'];
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDeleteDataObjectAssignmentsInput = {
  contentTypeModel: ContentTypeModel;
  objectId: Scalars['ID']['input'];
  type: DataObjectAssignmentType;
};

export type MutationDeleteUserAssignmentsInput = {
  contentTypeModel: ContentTypeModel;
  objectId: Scalars['ID']['input'];
  state: UserAssignmentState;
  type: UserAssignmentType;
};

export type MutationGenerateApiKeyInput = {
  email: Scalars['String']['input'];
  /** Optionally specify a value for the API key. If not specified, a random value will be generated and returned (recommended). */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteUserInput = {
  dateJoined?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  memberships?: InputMaybe<Array<MembershipCreateInput>>;
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationSendPasswordResetEmailInput = {
  email: Scalars['String']['input'];
};

export type MutationUpdateArStatisticsInput = {
  configString?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  sceneRevisionId?: InputMaybe<Scalars['String']['input']>;
  templateRevisionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAssetInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  modelId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<AssetState>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateDataObjectAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<DataObjectAssignmentType>;
};

export type MutationUpdateDataObjectInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  metadataProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originalFileName?: InputMaybe<Scalars['String']['input']>;
  processedByVersion?: InputMaybe<Scalars['String']['input']>;
  relatedToId?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<DataObjectState>;
  thumbnailProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateHdriInput = {
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  dataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateJobAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id: Scalars['ID']['input'];
  jobId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateJobFarmGroupInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  otherConfig?: InputMaybe<Scalars['Json']['input']>;
  queueGroup: Scalars['String']['input'];
};

export type MutationUpdateJobFarmInstanceInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  hwFingerprint?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastReport?: InputMaybe<Scalars['DateTime']['input']>;
  otherInfo?: InputMaybe<Scalars['Json']['input']>;
  state?: InputMaybe<JobFarmInstanceState>;
  type?: InputMaybe<JobFarmInstanceType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateJobInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateJobTaskAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  id: Scalars['ID']['input'];
  jobTaskId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateJobTaskInput = {
  attemptCount?: InputMaybe<Scalars['Int']['input']>;
  attemptsRemaining?: InputMaybe<Scalars['Int']['input']>;
  cancelled?: InputMaybe<Scalars['DateTime']['input']>;
  finished?: InputMaybe<Scalars['DateTime']['input']>;
  hashInParent?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  output?: InputMaybe<Scalars['Json']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  taskType?: InputMaybe<Scalars['String']['input']>;
  updateDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  versionCounter?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateJobTaskTypeInput = {
  /** @deprecated No longer used */
  cancelTimeout?: InputMaybe<Scalars['Int']['input']>;
  defaultTimeout?: InputMaybe<Scalars['Int']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  invocationMethod?: InputMaybe<InvocationMethod>;
  maxTimeout?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  /** The job task's type as a string. Note that this is different from a task's type field (of type `TaskType`) */
  taskType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateJsonFileAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id: Scalars['ID']['input'];
  jsonFileId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  type: JsonFileAssignmentType;
};

export type MutationUpdateJsonFileInput = {
  content?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<JsonFileType>;
};

export type MutationUpdateMaterialConnectionInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  destinationId?: InputMaybe<Scalars['String']['input']>;
  destinationParameter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  materialRevisionId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceParameter?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateMaterialInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrival?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<MaterialState>;
};

export type MutationUpdateMaterialNodeInput = {
  id: Scalars['ID']['input'];
  incomingId?: InputMaybe<Scalars['ID']['input']>;
  materialRevisionId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outgoingId?: InputMaybe<Scalars['ID']['input']>;
  parameters?: InputMaybe<Scalars['Json']['input']>;
  textureRevisionId?: InputMaybe<Scalars['ID']['input']>;
  textureSetRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateMaterialReferenceInput = {
  id: Scalars['ID']['input'];
  materialRevisionId?: InputMaybe<Scalars['ID']['input']>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateMaterialRevisionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  connectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  graphSchema?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  matFileDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  materialReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateModelInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<ModelState>;
  type?: InputMaybe<ModelType>;
};

export type MutationUpdateOrganizationInput = {
  defaultScannerUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  matExplorerMaterialId?: InputMaybe<Scalars['Int']['input']>;
  matExplorerTemplateId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nodeEditorTemplateId?: InputMaybe<Scalars['Int']['input']>;
  salesEmail?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrganizationType>;
  url?: InputMaybe<Scalars['String']['input']>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdatePictureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  number?: InputMaybe<Scalars['Int']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  priorityOrder?: InputMaybe<Scalars['Int']['input']>;
  regenerateNumber?: InputMaybe<Scalars['Boolean']['input']>;
  setId?: InputMaybe<Scalars['ID']['input']>;
  setLegacyId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<PictureState>;
};

export type MutationUpdatePictureRevisionInput = {
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePriceGraphInput = {
  conflicts?: InputMaybe<Scalars['String']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  organizationId: Scalars['ID']['input'];
  originalIdsFromCatalog: Array<Scalars['String']['input']>;
  priceGraphId: Scalars['ID']['input'];
  revisionNumber?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<PriceGraphState>;
};

export type MutationUpdateProjectInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateRenderJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  samples?: InputMaybe<Scalars['Int']['input']>;
  sceneRevisionId?: InputMaybe<Scalars['ID']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<RenderJobState>;
  statusMessage?: InputMaybe<Scalars['String']['input']>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateScanJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  sampleRotation?: InputMaybe<Scalars['Int']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanJobState>;
  stateStarted?: InputMaybe<Scalars['DateTime']['input']>;
  textureSetId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateScanSubJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['ID']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanSubJobState>;
  tileX?: InputMaybe<Scalars['Int']['input']>;
  tileY?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateSceneInput = {
  id: Scalars['String']['input'];
  pictureId?: InputMaybe<Scalars['String']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationUpdateSceneRevisionInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['ID']['input'];
  sceneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateSceneStatisticsInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  sceneId?: InputMaybe<Scalars['ID']['input']>;
  sceneLegacyId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateSetInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateStorageDeleteTaskInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  gcsBucketName?: InputMaybe<Scalars['String']['input']>;
  gcsObjectName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTagAssignmentInput = {
  id: Scalars['ID']['input'];
  tagId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  otherInfo?: InputMaybe<Scalars['String']['input']>;
  tagAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<TagType>;
};

export type MutationUpdateTaskCommentInput = {
  id: Scalars['ID']['input'];
  taskId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTaskInput = {
  commentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  pinIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<TaskState>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
};

export type MutationUpdateTaskPinInput = {
  id: Scalars['ID']['input'];
  taskId?: InputMaybe<Scalars['ID']['input']>;
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateTemplateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  exSceneId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['String']['input']>;
  pictureLegacyId?: InputMaybe<Scalars['Int']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<TemplateState>;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateType?: InputMaybe<TemplateType>;
};

export type MutationUpdateTemplateRevisionInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  exSceneRevisionId?: InputMaybe<Scalars['Int']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTemplateRevisionNewInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  dataObjectAssignments?: InputMaybe<Array<TemplateDataObjectAssignmentInput>>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  hdriReferences?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['ID']['input'];
  materialReferences?: InputMaybe<Array<Scalars['Int']['input']>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  templateReferences?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationUpdateTextureEditGroupInput = {
  content?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTextureGroupInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  materialId?: InputMaybe<Scalars['ID']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  textureSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationUpdateTextureInput = {
  id: Scalars['ID']['input'];
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  textureSetId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<TextureType>;
};

export type MutationUpdateTextureRevisionInput = {
  createdByVersion?: InputMaybe<Scalars['String']['input']>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  state?: InputMaybe<TextureRevisionState>;
  textureId?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateTextureSetInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['ID']['input']>;
  textureGroupId?: InputMaybe<Scalars['ID']['input']>;
  textureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationUpdateTextureSetRevisionInput = {
  autoRescaleSize?: InputMaybe<Scalars['Boolean']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  editsJson?: InputMaybe<Scalars['Json']['input']>;
  editsProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  mapAssignments?: InputMaybe<Array<TextureSetRevisionMapAssignmentInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateUserAssignmentInput = {
  id: Scalars['String']['input'];
  state?: InputMaybe<UserAssignmentState>;
  type?: InputMaybe<UserAssignmentType>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserInput = {
  dateJoined?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NextActor {
  Customer = 'Customer',
  ProjectManager = 'ProjectManager',
  Team1 = 'Team1',
  Team2 = 'Team2'
}

export type Organization = {
  __typename: 'Organization';
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  dataObjects: Array<DataObject>;
  defaultScannerUrl?: Maybe<Scalars['String']['output']>;
  font?: Maybe<DataObject>;
  fontBold?: Maybe<DataObject>;
  galleryImage?: Maybe<DataObject>;
  hdris: Array<Hdri>;
  id: Scalars['ID']['output'];
  jobFarmGroups: Array<JobFarmGroup>;
  jobFarmGroupsCount: Scalars['Int']['output'];
  jobTasks: Array<JobTask>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  jsonFiles: Array<JsonFile>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  matExplorerMaterial?: Maybe<Material>;
  matExplorerTemplate?: Maybe<Template>;
  materials: Array<Material>;
  members: Array<Membership>;
  membersCount: Scalars['Int']['output'];
  models: Array<Model>;
  name?: Maybe<Scalars['String']['output']>;
  nodeEditorTemplate?: Maybe<Template>;
  pdfTemplate?: Maybe<DataObject>;
  projects: Array<Project>;
  projectsCount: Scalars['Int']['output'];
  salesEmail?: Maybe<Scalars['String']['output']>;
  salesEnquiries: Array<SalesEnquiry>;
  salesEnquiriesCount: Scalars['Int']['output'];
  tags: Array<Tag>;
  tagsCount: Scalars['Int']['output'];
  templates: Array<Template>;
  templatesCount: Scalars['Int']['output'];
  textureGroups: Array<TextureGroup>;
  textureGroupsCount: Scalars['Int']['output'];
  type?: Maybe<OrganizationType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  url?: Maybe<Scalars['String']['output']>;
  visibleInFilters?: Maybe<Scalars['Boolean']['output']>;
};


export type OrganizationDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type OrganizationProjectsArgs = {
  orderBy?: InputMaybe<Array<ProjectOrderByInput>>;
};

export type OrganizationFilterInput = {
  defaultScannerUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  matExplorerMaterialId?: InputMaybe<IntFilterInput>;
  matExplorerTemplateId?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  nodeEditorTemplateId?: InputMaybe<IntFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<StringFilterInput>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrganizationOrderByCriteria {
  Created = 'created',
  Id = 'id',
  Name = 'name',
  VisibleInFilters = 'visibleInFilters'
}

export type OrganizationOrderByInput = {
  direction: SortOrder;
  key: OrganizationOrderByCriteria;
};

export enum OrganizationType {
  FabricManufacturer = 'FabricManufacturer',
  Photographer = 'Photographer'
}

export type Package = {
  __typename: 'Package';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  membership: Membership;
  type: PackageType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export enum PackageType {
  Configurator = 'Configurator',
  Exports = 'Exports',
  ProjectManagement = 'ProjectManagement',
  Rendering = 'Rendering'
}

export type Partnership = {
  __typename: 'Partnership';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  material: Material;
  model: Model;
  objectId: Scalars['ID']['output'];
  objectType: PartnershipSubject;
  organization: Organization;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export enum PartnershipSubject {
  Material = 'Material',
  Model = 'Model',
  Organization = 'Organization'
}

export enum PaymentState {
  InvoiceDrafted = 'InvoiceDrafted',
  InvoiceIssued = 'InvoiceIssued',
  OrderPlaced = 'OrderPlaced',
  Paid = 'Paid'
}

export type Picture = {
  __typename: 'Picture';
  assetAssignments: Array<PictureAssetAssignment>;
  assetInfos: PictureAssetInfos;
  /** Assets assigned to this picture via a PictureAssetAssignment. This is a convenience field that is equivalent to `assetAssignments.asset`. */
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  downloadUrl: Scalars['String']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<PictureRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextActor: NextActor;
  number: Scalars['Int']['output'];
  organization: Organization;
  paymentState: PaymentState;
  priorityOrder: Scalars['GraphQLBigInt']['output'];
  revisions: Array<PictureRevision>;
  scene?: Maybe<Scene>;
  set: Set;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: PictureState;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type PictureDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type PictureDownloadUrlArgs = {
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
};


export type PictureJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type PictureRevisionsArgs = {
  orderBy?: InputMaybe<Array<PictureRevisionOrderByInput>>;
};


export type PictureShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type PictureTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type PictureUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type PictureAssetAssignment = {
  __typename: 'PictureAssetAssignment';
  asset: Asset;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  picture: Picture;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PictureAssetAssignmentFilterInput = {
  asset?: InputMaybe<AssetFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  picture?: InputMaybe<PictureFilterInput>;
};

export type PictureAssetAssignmentHistory = {
  __typename: 'PictureAssetAssignmentHistory';
  asset?: Maybe<Asset>;
  assignment?: Maybe<PictureAssetAssignment>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
};

export type PictureAssetAssignmentHistoryFilterInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  assignmentId?: InputMaybe<IdInFilter>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
};

export enum PictureAssetAssignmentHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type PictureAssetAssignmentHistoryOrderByInput = {
  direction: SortOrder;
  key: PictureAssetAssignmentHistoryOrderByCriteria;
};

export type PictureAssetInfos = {
  __typename: 'PictureAssetInfos';
  completed: Scalars['Int']['output'];
  new: Scalars['Int']['output'];
  readyForShading: Scalars['Int']['output'];
  review: Scalars['Int']['output'];
  shading: Scalars['Int']['output'];
};

export type PictureFilterInput = {
  assetId?: InputMaybe<IdInFilter>;
  assetLegacyId?: InputMaybe<IntInFilter>;
  assetsCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  organizationUrl?: InputMaybe<StringFilterInput>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  projectId?: InputMaybe<IdInFilter>;
  projectLegacyId?: InputMaybe<IntFilterInput>;
  projectNumber?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  setId?: InputMaybe<IdInFilter>;
  setLegacyId?: InputMaybe<IntFilterInput>;
  setNumber?: InputMaybe<IntInFilter>;
  state?: InputMaybe<Array<PictureState>>;
  tagId?: InputMaybe<IdInFilter>;
  tagLegacyId?: InputMaybe<IntInFilter>;
  userId?: InputMaybe<IdInFilter>;
};

export type PictureHistory = {
  __typename: 'PictureHistory';
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextActor: NextActor;
  number: Scalars['Int']['output'];
  paymentState: PaymentState;
  picture?: Maybe<Picture>;
  priorityOrder: Scalars['GraphQLBigInt']['output'];
  set?: Maybe<Set>;
  state: PictureState;
};

export type PictureHistoryFilterInput = {
  description?: InputMaybe<StringFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  number?: InputMaybe<IntFilterInput>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  pictureId?: InputMaybe<IdInFilter>;
  priorityOrder?: InputMaybe<IntFilterInput>;
  setId?: InputMaybe<IdInFilter>;
  state?: InputMaybe<Array<PictureState>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum PictureHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type PictureHistoryOrderByInput = {
  direction: SortOrder;
  key: PictureHistoryOrderByCriteria;
};

export enum PictureOrderByCriteria {
  ArticleId = 'articleId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  Number = 'number',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  SetNumber = 'setNumber',
  State = 'state'
}

export type PictureOrderByInput = {
  direction: SortOrder;
  key: PictureOrderByCriteria;
};

export type PictureRevision = {
  __typename: 'PictureRevision';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  picture: Picture;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type PictureRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type PictureRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type PictureRevisionFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  number?: InputMaybe<IntFilterInput>;
  pictureId?: InputMaybe<IdInFilter>;
};

export enum PictureRevisionOrderByCriteria {
  Id = 'id',
  Number = 'number',
  PictureId = 'picture_id'
}

export type PictureRevisionOrderByInput = {
  direction: SortOrder;
  key: PictureRevisionOrderByCriteria;
};

export enum PictureState {
  Completed = 'Completed',
  Draft = 'Draft',
  FinalRender = 'FinalRender',
  InfoReview = 'InfoReview',
  OnHold = 'OnHold',
  Review = 'Review',
  Stage1 = 'Stage1',
  Stage2 = 'Stage2'
}

export type Price = {
  __typename: 'Price';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currency: Currency;
  description?: Maybe<Scalars['String']['output']>;
  idFromCatalog: IdFromCatalog;
  price: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PriceFilterInput = {
  organizationId: IdInFilter;
  originalId?: InputMaybe<IdInFilter>;
};

export type PriceGraph = {
  __typename: 'PriceGraph';
  conflicts?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  graph: Scalars['Json']['output'];
  id: Scalars['ID']['output'];
  revisionNumber: Scalars['Int']['output'];
  state: PriceGraphState;
  templateId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PriceGraphFilterInput = {
  state?: InputMaybe<PriceGraphState>;
  templateId?: InputMaybe<IdInFilter>;
};

export enum PriceGraphOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  RevisionNumber = 'revisionNumber'
}

export type PriceGraphOrderByInput = {
  direction: SortOrder;
  key: PriceGraphOrderByCriteria;
};

export enum PriceGraphState {
  Draft = 'Draft',
  Invalid = 'Invalid',
  Ready = 'Ready'
}

export type PriceInput = {
  currency: Currency;
  description?: InputMaybe<Scalars['String']['input']>;
  disambiguator?: InputMaybe<Scalars['String']['input']>;
  originalId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
};

export enum PriceOrderByCriteria {
  CreatedAt = 'createdAt'
}

export type PriceOrderByInput = {
  direction: SortOrder;
  key: PriceOrderByCriteria;
};

export type Project = {
  __typename: 'Project';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  organization: Organization;
  sets: Array<Set>;
  setsCount: Scalars['Int']['output'];
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ProjectDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type ProjectSetsArgs = {
  orderBy?: InputMaybe<Array<SetOrderByInput>>;
};


export type ProjectShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type ProjectTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};

export type ProjectFilterInput = {
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  number?: InputMaybe<IntFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  setIds?: InputMaybe<IdInFilter>;
};

export enum ProjectOrderByCriteria {
  ArticleId = 'articleId',
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name',
  NextActor = 'nextActor',
  Number = 'number',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type ProjectOrderByInput = {
  direction: SortOrder;
  key: ProjectOrderByCriteria;
};

export type Query = {
  __typename: 'Query';
  arStatisticsItem: ArStatistics;
  arStatisticsItems: Array<Maybe<ArStatistics>>;
  arStatisticsItemsCount: Scalars['Int']['output'];
  asset: Asset;
  assetHistoryItem: AssetHistory;
  assetHistoryItems: Array<Maybe<AssetHistory>>;
  assetHistoryItemsCount: Scalars['Int']['output'];
  assets: Array<Maybe<Asset>>;
  assetsCount: Scalars['Int']['output'];
  currentUser?: Maybe<User>;
  dataObject: DataObject;
  dataObjectAssignment: DataObjectAssignment;
  dataObjectAssignments: Array<Maybe<DataObjectAssignment>>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  dataObjects: Array<Maybe<DataObject>>;
  dataObjectsCount: Scalars['Int']['output'];
  hdri: Hdri;
  hdris: Array<Maybe<Hdri>>;
  hdrisCount: Scalars['Int']['output'];
  idFromCatalogToPriceGraphItems: Array<Maybe<IdFromCatalogToPriceGraph>>;
  idFromCatalogToPriceGraphItemsCount: Scalars['Int']['output'];
  job: Job;
  jobAssignment: JobAssignment;
  jobAssignments: Array<Maybe<JobAssignment>>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jobFarmGroup: JobFarmGroup;
  jobFarmGroups: Array<Maybe<JobFarmGroup>>;
  jobFarmGroupsCount: Scalars['Int']['output'];
  jobFarmInstance: JobFarmInstance;
  jobFarmInstances: Array<Maybe<JobFarmInstance>>;
  jobFarmInstancesCount: Scalars['Int']['output'];
  jobTask: JobTask;
  jobTaskAssignment: JobTaskAssignment;
  jobTaskAssignments: Array<Maybe<JobTaskAssignment>>;
  jobTaskAssignmentsCount: Scalars['Int']['output'];
  jobTaskType: JobTaskType;
  jobTaskTypes: Array<Maybe<JobTaskType>>;
  jobTaskTypesCount: Scalars['Int']['output'];
  jobTasks: Array<Maybe<JobTask>>;
  jobTasksCount: Scalars['Int']['output'];
  jobs: Array<Maybe<Job>>;
  jobsCount: Scalars['Int']['output'];
  jsonFile: JsonFile;
  jsonFileAssignment: JsonFileAssignment;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFiles: Array<Maybe<JsonFile>>;
  jsonFilesCount: Scalars['Int']['output'];
  material: Material;
  materialAssignmentHistoryItem: MaterialAssignmentHistory;
  materialAssignmentHistoryItems: Array<Maybe<MaterialAssignmentHistory>>;
  materialAssignmentHistoryItemsCount: Scalars['Int']['output'];
  materialConnection: MaterialConnection;
  materialConnections: Array<Maybe<MaterialConnection>>;
  materialConnectionsCount: Scalars['Int']['output'];
  materialHistoryItem: MaterialHistory;
  materialHistoryItems: Array<Maybe<MaterialHistory>>;
  materialHistoryItemsCount: Scalars['Int']['output'];
  materialNode: MaterialNode;
  materialNodes: Array<Maybe<MaterialNode>>;
  materialNodesCount: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  materialRevisions: Array<Maybe<MaterialRevision>>;
  materialRevisionsCount: Scalars['Int']['output'];
  materials: Array<Maybe<Material>>;
  materialsCount: Scalars['Int']['output'];
  model: Model;
  modelHistoryItem: ModelHistory;
  modelHistoryItems: Array<Maybe<ModelHistory>>;
  modelHistoryItemsCount: Scalars['Int']['output'];
  models: Array<Maybe<Model>>;
  modelsCount: Scalars['Int']['output'];
  organization: Organization;
  organizations: Array<Maybe<Organization>>;
  organizationsCount: Scalars['Int']['output'];
  picture: Picture;
  pictureAssetAssignmentHistoryItem: PictureAssetAssignmentHistory;
  pictureAssetAssignmentHistoryItems: Array<Maybe<PictureAssetAssignmentHistory>>;
  pictureAssetAssignmentHistoryItemsCount: Scalars['Int']['output'];
  pictureHistoryItem: PictureHistory;
  pictureHistoryItems: Array<Maybe<PictureHistory>>;
  pictureHistoryItemsCount: Scalars['Int']['output'];
  pictureRevision: PictureRevision;
  pictureRevisions: Array<Maybe<PictureRevision>>;
  pictureRevisionsCount: Scalars['Int']['output'];
  pictures: Array<Maybe<Picture>>;
  picturesCount: Scalars['Int']['output'];
  price: Price;
  priceGraph: PriceGraph;
  priceGraphs: Array<Maybe<PriceGraph>>;
  priceGraphsCount: Scalars['Int']['output'];
  prices: Array<Maybe<Price>>;
  pricesCount: Scalars['Int']['output'];
  project: Project;
  projects: Array<Maybe<Project>>;
  projectsCount: Scalars['Int']['output'];
  renderJob: RenderJob;
  renderJobs: Array<Maybe<RenderJob>>;
  renderJobsCount: Scalars['Int']['output'];
  scanJob: ScanJob;
  scanJobs: Array<Maybe<ScanJob>>;
  scanJobsCount: Scalars['Int']['output'];
  scanSubJob: ScanSubJob;
  scanSubJobs: Array<Maybe<ScanSubJob>>;
  scanSubJobsCount: Scalars['Int']['output'];
  scene: Scene;
  sceneRevision: SceneRevision;
  sceneRevisions: Array<Maybe<SceneRevision>>;
  sceneRevisionsCount: Scalars['Int']['output'];
  sceneStatisticsItem: SceneStatistics;
  sceneStatisticsItems: Array<Maybe<SceneStatistics>>;
  sceneStatisticsItemsCount: Scalars['Int']['output'];
  scenes: Array<Maybe<Scene>>;
  scenesCount: Scalars['Int']['output'];
  set: Set;
  sets: Array<Maybe<Set>>;
  setsCount: Scalars['Int']['output'];
  storageDeleteTask: StorageDeleteTask;
  storageDeleteTasks: Array<Maybe<StorageDeleteTask>>;
  storageDeleteTasksCount: Scalars['Int']['output'];
  tag: Tag;
  tagAssignment: TagAssignment;
  tagAssignments: Array<Maybe<TagAssignment>>;
  tagAssignmentsCount: Scalars['Int']['output'];
  tags: Array<Maybe<Tag>>;
  tagsCount: Scalars['Int']['output'];
  task: Task;
  taskComment: TaskComment;
  taskComments: Array<Maybe<TaskComment>>;
  taskCommentsCount: Scalars['Int']['output'];
  taskPin: TaskPin;
  taskPins: Array<Maybe<TaskPin>>;
  taskPinsCount: Scalars['Int']['output'];
  tasks: Array<Maybe<Task>>;
  tasksCount: Scalars['Int']['output'];
  template: Template;
  templateRevision: TemplateRevision;
  templateRevisions: Array<Maybe<TemplateRevision>>;
  templateRevisionsCount: Scalars['Int']['output'];
  templates: Array<Maybe<Template>>;
  templatesCount: Scalars['Int']['output'];
  texture: Texture;
  textureEditGroup: TextureEditGroup;
  textureEditGroups: Array<Maybe<TextureEditGroup>>;
  textureEditGroupsCount: Scalars['Int']['output'];
  textureGroup: TextureGroup;
  textureGroups: Array<Maybe<TextureGroup>>;
  textureGroupsCount: Scalars['Int']['output'];
  textureRevision: TextureRevision;
  textureRevisions: Array<Maybe<TextureRevision>>;
  textureRevisionsCount: Scalars['Int']['output'];
  textureSet: TextureSet;
  textureSetRevision: TextureSetRevision;
  textureSetRevisions: Array<Maybe<TextureSetRevision>>;
  textureSetRevisionsCount: Scalars['Int']['output'];
  textureSets: Array<Maybe<TextureSet>>;
  textureSetsCount: Scalars['Int']['output'];
  textures: Array<Maybe<Texture>>;
  texturesCount: Scalars['Int']['output'];
  user: User;
  userAssignment: UserAssignment;
  userAssignmentHistoryItem: UserAssignmentHistory;
  userAssignmentHistoryItems: Array<Maybe<UserAssignmentHistory>>;
  userAssignmentHistoryItemsCount: Scalars['Int']['output'];
  userAssignments: Array<Maybe<UserAssignment>>;
  userAssignmentsCount: Scalars['Int']['output'];
  users: Array<Maybe<User>>;
  usersCount: Scalars['Int']['output'];
};


export type QueryArStatisticsItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArStatisticsItemsArgs = {
  filter?: InputMaybe<ArStatisticsFilterInput>;
  orderBy?: InputMaybe<Array<ArStatisticsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArStatisticsItemsCountArgs = {
  filter?: InputMaybe<ArStatisticsFilterInput>;
};


export type QueryAssetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AssetHistoryFilterInput>;
  orderBy?: InputMaybe<Array<AssetHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetHistoryItemsCountArgs = {
  filter?: InputMaybe<AssetHistoryFilterInput>;
};


export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetFilterInput>;
  orderBy?: InputMaybe<Array<AssetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetsCountArgs = {
  filter?: InputMaybe<AssetFilterInput>;
};


export type QueryDataObjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<DataObjectAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectAssignmentsCountArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
};


export type QueryDataObjectsArgs = {
  filter?: InputMaybe<DataObjectFilterInput>;
  orderBy?: InputMaybe<Array<DataObjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectsCountArgs = {
  filter?: InputMaybe<DataObjectFilterInput>;
};


export type QueryHdriArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHdrisArgs = {
  filter?: InputMaybe<HdriFilterInput>;
  orderBy?: InputMaybe<Array<HdriOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHdrisCountArgs = {
  filter?: InputMaybe<HdriFilterInput>;
};


export type QueryIdFromCatalogToPriceGraphItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IdFromCatalogToPriceGraphFilterInput>;
  orderBy?: InputMaybe<Array<IdFromCatalogToPriceGraphOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIdFromCatalogToPriceGraphItemsCountArgs = {
  filter?: InputMaybe<IdFromCatalogToPriceGraphFilterInput>;
};


export type QueryJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobAssignmentsArgs = {
  filter?: InputMaybe<JobAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<JobAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobAssignmentsCountArgs = {
  filter?: InputMaybe<JobAssignmentFilterInput>;
};


export type QueryJobFarmGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmGroupsArgs = {
  filter?: InputMaybe<JobFarmGroupFilterInput>;
  orderBy?: InputMaybe<Array<JobFarmGroupOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmGroupsCountArgs = {
  filter?: InputMaybe<JobFarmGroupFilterInput>;
};


export type QueryJobFarmInstanceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmInstancesArgs = {
  filter?: InputMaybe<JobFarmInstanceFilterInput>;
  orderBy?: InputMaybe<Array<JobFarmInstanceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmInstancesCountArgs = {
  filter?: InputMaybe<JobFarmInstanceFilterInput>;
};


export type QueryJobTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskAssignmentsArgs = {
  filter?: InputMaybe<JobTaskAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<JobTaskAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskAssignmentsCountArgs = {
  filter?: InputMaybe<JobTaskAssignmentFilterInput>;
};


export type QueryJobTaskTypeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskTypesArgs = {
  filter?: InputMaybe<JobTaskTypeFilterInput>;
  orderBy?: InputMaybe<Array<JobTaskTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskTypesCountArgs = {
  filter?: InputMaybe<JobTaskTypeFilterInput>;
};


export type QueryJobTasksArgs = {
  filter?: InputMaybe<JobTaskFilterInput>;
  orderBy?: InputMaybe<Array<JobTaskOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTasksCountArgs = {
  filter?: InputMaybe<JobTaskFilterInput>;
};


export type QueryJobsArgs = {
  filter?: InputMaybe<JobFilterInput>;
  orderBy?: InputMaybe<Array<JobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobsCountArgs = {
  filter?: InputMaybe<JobFilterInput>;
};


export type QueryJsonFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJsonFileAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
};


export type QueryJsonFilesArgs = {
  filter?: InputMaybe<JsonFileFilterInput>;
  orderBy?: InputMaybe<Array<JsonFileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJsonFilesCountArgs = {
  filter?: InputMaybe<JsonFileFilterInput>;
};


export type QueryMaterialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialAssignmentHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialAssignmentHistoryItemsArgs = {
  filter?: InputMaybe<MaterialAssignmentHistoryFilterInput>;
  orderBy?: InputMaybe<Array<MaterialAssignmentHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialAssignmentHistoryItemsCountArgs = {
  filter?: InputMaybe<MaterialAssignmentHistoryFilterInput>;
};


export type QueryMaterialConnectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialConnectionsArgs = {
  filter?: InputMaybe<MaterialConnectionFilterInput>;
  orderBy?: InputMaybe<Array<MaterialConnectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialConnectionsCountArgs = {
  filter?: InputMaybe<MaterialConnectionFilterInput>;
};


export type QueryMaterialHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MaterialHistoryFilterInput>;
  orderBy?: InputMaybe<Array<MaterialHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialHistoryItemsCountArgs = {
  filter?: InputMaybe<MaterialHistoryFilterInput>;
};


export type QueryMaterialNodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialNodesArgs = {
  filter?: InputMaybe<MaterialNodeFilterInput>;
  orderBy?: InputMaybe<Array<MaterialNodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialNodesCountArgs = {
  filter?: InputMaybe<MaterialNodeFilterInput>;
};


export type QueryMaterialRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialRevisionsArgs = {
  filter?: InputMaybe<MaterialRevisionFilterInput>;
  orderBy?: InputMaybe<Array<MaterialRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialRevisionsCountArgs = {
  filter?: InputMaybe<MaterialRevisionFilterInput>;
};


export type QueryMaterialsArgs = {
  filter?: InputMaybe<MaterialFilterInput>;
  orderBy?: InputMaybe<Array<MaterialOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialsCountArgs = {
  filter?: InputMaybe<MaterialFilterInput>;
};


export type QueryModelArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ModelHistoryFilterInput>;
  orderBy?: InputMaybe<Array<ModelHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelHistoryItemsCountArgs = {
  filter?: InputMaybe<ModelHistoryFilterInput>;
};


export type QueryModelsArgs = {
  filter?: InputMaybe<ModelFilterInput>;
  orderBy?: InputMaybe<Array<ModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelsCountArgs = {
  filter?: InputMaybe<ModelFilterInput>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilterInput>;
  orderBy?: InputMaybe<Array<OrganizationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsCountArgs = {
  filter?: InputMaybe<OrganizationFilterInput>;
};


export type QueryPictureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureAssetAssignmentHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureAssetAssignmentHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PictureAssetAssignmentHistoryFilterInput>;
  orderBy?: InputMaybe<Array<PictureAssetAssignmentHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureAssetAssignmentHistoryItemsCountArgs = {
  filter?: InputMaybe<PictureAssetAssignmentHistoryFilterInput>;
};


export type QueryPictureHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PictureHistoryFilterInput>;
  orderBy?: InputMaybe<Array<PictureHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureHistoryItemsCountArgs = {
  filter?: InputMaybe<PictureHistoryFilterInput>;
};


export type QueryPictureRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureRevisionsArgs = {
  filter?: InputMaybe<PictureRevisionFilterInput>;
  orderBy?: InputMaybe<Array<PictureRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureRevisionsCountArgs = {
  filter?: InputMaybe<PictureRevisionFilterInput>;
};


export type QueryPicturesArgs = {
  filter?: InputMaybe<PictureFilterInput>;
  orderBy?: InputMaybe<Array<PictureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPicturesCountArgs = {
  filter?: InputMaybe<PictureFilterInput>;
};


export type QueryPriceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPriceGraphArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPriceGraphsArgs = {
  filter?: InputMaybe<PriceGraphFilterInput>;
  orderBy?: InputMaybe<Array<PriceGraphOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPriceGraphsCountArgs = {
  filter?: InputMaybe<PriceGraphFilterInput>;
};


export type QueryPricesArgs = {
  filter?: InputMaybe<PriceFilterInput>;
  orderBy?: InputMaybe<Array<PriceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPricesCountArgs = {
  filter?: InputMaybe<PriceFilterInput>;
};


export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectsArgs = {
  filter?: InputMaybe<ProjectFilterInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectsCountArgs = {
  filter?: InputMaybe<ProjectFilterInput>;
};


export type QueryRenderJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRenderJobsArgs = {
  filter?: InputMaybe<RenderJobFilterInput>;
  orderBy?: InputMaybe<Array<RenderJobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRenderJobsCountArgs = {
  filter?: InputMaybe<RenderJobFilterInput>;
};


export type QueryScanJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanJobsArgs = {
  filter?: InputMaybe<ScanJobFilterInput>;
  orderBy?: InputMaybe<Array<ScanJobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanJobsCountArgs = {
  filter?: InputMaybe<ScanJobFilterInput>;
};


export type QueryScanSubJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanSubJobsArgs = {
  filter?: InputMaybe<ScanSubJobFilterInput>;
  orderBy?: InputMaybe<Array<ScanSubJobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanSubJobsCountArgs = {
  filter?: InputMaybe<ScanSubJobFilterInput>;
};


export type QuerySceneArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneRevisionsArgs = {
  filter?: InputMaybe<SceneRevisionFilterInput>;
  orderBy?: InputMaybe<Array<SceneRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneRevisionsCountArgs = {
  filter?: InputMaybe<SceneRevisionFilterInput>;
};


export type QuerySceneStatisticsItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneStatisticsItemsArgs = {
  filter?: InputMaybe<SceneStatisticsFilterInput>;
  orderBy?: InputMaybe<Array<SceneStatisticsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneStatisticsItemsCountArgs = {
  filter?: InputMaybe<SceneStatisticsFilterInput>;
};


export type QueryScenesArgs = {
  filter?: InputMaybe<SceneFilterInput>;
  orderBy?: InputMaybe<Array<SceneOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScenesCountArgs = {
  filter?: InputMaybe<SceneFilterInput>;
};


export type QuerySetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySetsArgs = {
  filter?: InputMaybe<SetFilterInput>;
  orderBy?: InputMaybe<Array<SetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySetsCountArgs = {
  filter?: InputMaybe<SetFilterInput>;
};


export type QueryStorageDeleteTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStorageDeleteTasksArgs = {
  filter?: InputMaybe<StorageDeleteTaskFilterInput>;
  orderBy?: InputMaybe<Array<StorageDeleteTaskOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStorageDeleteTasksCountArgs = {
  filter?: InputMaybe<StorageDeleteTaskFilterInput>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagAssignmentsArgs = {
  filter?: InputMaybe<TagAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<TagAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagAssignmentsCountArgs = {
  filter?: InputMaybe<TagAssignmentFilterInput>;
};


export type QueryTagsArgs = {
  filter?: InputMaybe<TagFilterInput>;
  orderBy?: InputMaybe<Array<TagOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagsCountArgs = {
  filter?: InputMaybe<TagFilterInput>;
};


export type QueryTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskCommentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskCommentsArgs = {
  filter?: InputMaybe<TaskCommentFilterInput>;
  orderBy?: InputMaybe<Array<TaskCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskCommentsCountArgs = {
  filter?: InputMaybe<TaskCommentFilterInput>;
};


export type QueryTaskPinArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskPinsArgs = {
  filter?: InputMaybe<TaskPinFilterInput>;
  orderBy?: InputMaybe<Array<TaskPinOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskPinsCountArgs = {
  filter?: InputMaybe<TaskPinFilterInput>;
};


export type QueryTasksArgs = {
  filter?: InputMaybe<TaskFilterInput>;
  orderBy?: InputMaybe<Array<TaskOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTasksCountArgs = {
  filter?: InputMaybe<TaskFilterInput>;
};


export type QueryTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateRevisionsArgs = {
  filter?: InputMaybe<TemplateRevisionFilterInput>;
  orderBy?: InputMaybe<Array<TemplateRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateRevisionsCountArgs = {
  filter?: InputMaybe<TemplateRevisionFilterInput>;
};


export type QueryTemplatesArgs = {
  filter?: InputMaybe<TemplateFilterInput>;
  orderBy?: InputMaybe<Array<TemplateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplatesCountArgs = {
  filter?: InputMaybe<TemplateFilterInput>;
};


export type QueryTextureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureEditGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureEditGroupsArgs = {
  filter?: InputMaybe<TextureEditGroupFilterInput>;
  orderBy?: InputMaybe<Array<TextureEditGroupOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureEditGroupsCountArgs = {
  filter?: InputMaybe<TextureEditGroupFilterInput>;
};


export type QueryTextureGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureGroupsArgs = {
  filter?: InputMaybe<TextureGroupFilterInput>;
  orderBy?: InputMaybe<Array<TextureGroupOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureGroupsCountArgs = {
  filter?: InputMaybe<TextureGroupFilterInput>;
};


export type QueryTextureRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureRevisionsArgs = {
  filter?: InputMaybe<TextureRevisionFilterInput>;
  orderBy?: InputMaybe<Array<TextureRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureRevisionsCountArgs = {
  filter?: InputMaybe<TextureRevisionFilterInput>;
};


export type QueryTextureSetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetRevisionsArgs = {
  filter?: InputMaybe<TextureSetRevisionFilterInput>;
  orderBy?: InputMaybe<Array<TextureSetRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetRevisionsCountArgs = {
  filter?: InputMaybe<TextureSetRevisionFilterInput>;
};


export type QueryTextureSetsArgs = {
  filter?: InputMaybe<TextureSetFilterInput>;
  orderBy?: InputMaybe<Array<TextureSetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetsCountArgs = {
  filter?: InputMaybe<TextureSetFilterInput>;
};


export type QueryTexturesArgs = {
  filter?: InputMaybe<TextureFilterInput>;
  orderBy?: InputMaybe<Array<TextureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTexturesCountArgs = {
  filter?: InputMaybe<TextureFilterInput>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentHistoryItemsArgs = {
  filter?: InputMaybe<UserAssignmentHistoryFilterInput>;
  orderBy?: InputMaybe<Array<UserAssignmentHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentHistoryItemsCountArgs = {
  filter?: InputMaybe<UserAssignmentHistoryFilterInput>;
};


export type QueryUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<UserAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentsCountArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUsersCountArgs = {
  filter?: InputMaybe<UserFilterInput>;
};

export type RenderJob = {
  __typename: 'RenderJob';
  active: Scalars['Boolean']['output'];
  completed?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  instanceId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  log?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  samples: Scalars['Int']['output'];
  sceneRevision: SceneRevision;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: RenderJobState;
  statusMessage?: Maybe<Scalars['String']['output']>;
  templateRevision?: Maybe<TemplateRevision>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type RenderJobFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<DateTimeFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  log?: InputMaybe<StringFilterInput>;
  progress?: InputMaybe<IntFilterInput>;
  samples?: InputMaybe<IntFilterInput>;
  sceneRevisionId?: InputMaybe<StringFilterInput>;
  started?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<RenderJobState>>;
  statusMessage?: InputMaybe<StringFilterInput>;
  templateRevisionId?: InputMaybe<StringFilterInput>;
};

export enum RenderJobOrderByCriteria {
  Active = 'active',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  Started = 'started'
}

export type RenderJobOrderByInput = {
  direction: SortOrder;
  key: RenderJobOrderByCriteria;
};

export enum RenderJobState {
  Aborted = 'Aborted',
  Completed = 'Completed',
  Failed = 'Failed',
  Init = 'Init',
  Rendering = 'Rendering'
}

export type SalesEnquiry = {
  __typename: 'SalesEnquiry';
  companyName?: Maybe<Scalars['String']['output']>;
  contactName: Scalars['String']['output'];
  context?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  organization: Organization;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ScanJob = {
  __typename: 'ScanJob';
  active: Scalars['Boolean']['output'];
  activeSubJobsCount: Scalars['Int']['output'];
  completed?: Maybe<Scalars['DateTime']['output']>;
  completedSubJobsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  instanceId?: Maybe<Scalars['String']['output']>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  lastFailedState?: Maybe<ScanJobState>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  progress?: Maybe<Scalars['String']['output']>;
  retryCount: Scalars['Int']['output'];
  sampleRotation: Scalars['Int']['output'];
  started?: Maybe<Scalars['DateTime']['output']>;
  startedSubJobsCount: Scalars['Int']['output'];
  state: ScanJobState;
  stateStarted?: Maybe<Scalars['DateTime']['output']>;
  subJobs: Array<ScanSubJob>;
  subJobsCount: Scalars['Int']['output'];
  textureSet: TextureSet;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ScanJobDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ScanJobJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type ScanJobSubJobsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScanJobFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<DateTimeFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  instanceId?: InputMaybe<StringFilterInput>;
  progress?: InputMaybe<StringFilterInput>;
  sampleRotation?: InputMaybe<IntFilterInput>;
  started?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<ScanJobState>>;
  stateStarted?: InputMaybe<DateTimeFilterInput>;
  textureSetId?: InputMaybe<StringFilterInput>;
};

export enum ScanJobOrderByCriteria {
  Active = 'active',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  Started = 'started'
}

export type ScanJobOrderByInput = {
  direction: SortOrder;
  key: ScanJobOrderByCriteria;
};

export enum ScanJobState {
  Aborted = 'Aborted',
  Assembly = 'Assembly',
  Completed = 'Completed',
  Failed = 'Failed',
  Fitting = 'Fitting',
  ImageProcessing = 'ImageProcessing',
  Init = 'Init',
  Preprocessing = 'Preprocessing',
  Scanning = 'Scanning',
  Uploading = 'Uploading'
}

export type ScanSubJob = {
  __typename: 'ScanSubJob';
  active: Scalars['Boolean']['output'];
  completed?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  id: Scalars['ID']['output'];
  instanceId?: Maybe<Scalars['String']['output']>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  lastFailedState?: Maybe<ScanSubJobState>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  progress?: Maybe<Scalars['String']['output']>;
  retryCount: Scalars['Int']['output'];
  scanJob: ScanJob;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: ScanSubJobState;
  tileX: Scalars['Int']['output'];
  tileY: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ScanSubJobDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ScanSubJobJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type ScanSubJobFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<DateTimeFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  instanceId?: InputMaybe<StringFilterInput>;
  progress?: InputMaybe<StringFilterInput>;
  scanJobId?: InputMaybe<IdInFilter>;
  started?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<ScanSubJobState>>;
  tileX?: InputMaybe<IntFilterInput>;
  tileY?: InputMaybe<IntFilterInput>;
};

export enum ScanSubJobOrderByCriteria {
  Active = 'active',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  Started = 'started'
}

export type ScanSubJobOrderByInput = {
  direction: SortOrder;
  key: ScanSubJobOrderByCriteria;
};

export enum ScanSubJobState {
  Aborted = 'Aborted',
  Completed = 'Completed',
  Failed = 'Failed',
  Init = 'Init',
  Processing = 'Processing'
}

export type Scene = {
  __typename: 'Scene';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<SceneRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  picture: Picture;
  revisions: Array<SceneRevision>;
  statistics: Array<SceneStatistics>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type SceneDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SceneJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type SceneCreateSceneRevisionInlineInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
};

export type SceneFilterInput = {
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  pictureId?: InputMaybe<IdInFilter>;
  revisionIds?: InputMaybe<IdInFilter>;
  statisticsIds?: InputMaybe<IdInFilter>;
};

export enum SceneOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type SceneOrderByInput = {
  direction: SortOrder;
  key: SceneOrderByCriteria;
};

export type SceneRevision = {
  __typename: 'SceneRevision';
  arStatistics: Array<ArStatistics>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  graph?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  renderJobs: Array<RenderJob>;
  scene: Scene;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type SceneRevisionCreateOneWithoutArStatisticsInput = {
  assign: Scalars['Int']['input'];
};

export type SceneRevisionFilterInput = {
  arStatisticsIds?: InputMaybe<IdInFilter>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  renderJobsIds?: InputMaybe<IdInFilter>;
  sceneId?: InputMaybe<IdInFilter>;
};

export enum SceneRevisionOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type SceneRevisionOrderByInput = {
  direction: SortOrder;
  key: SceneRevisionOrderByCriteria;
};

export type SceneStatistics = {
  __typename: 'SceneStatistics';
  created?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  scene?: Maybe<Scene>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type SceneStatisticsFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  sceneId?: InputMaybe<IdInFilter>;
  sceneLegacyId?: InputMaybe<IntInFilter>;
  templateId?: InputMaybe<IdInFilter>;
  templateLegacyId?: InputMaybe<IntInFilter>;
};

export enum SceneStatisticsOrderByCriteria {
  Id = 'id'
}

export type SceneStatisticsOrderByInput = {
  direction: SortOrder;
  key: SceneStatisticsOrderByCriteria;
};

export type Set = {
  __typename: 'Set';
  created: Scalars['DateTime']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  pictures: Array<Picture>;
  project: Project;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type SetDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SetJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type SetPicturesArgs = {
  orderBy?: InputMaybe<Array<PictureOrderByInput>>;
};


export type SetShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type SetTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};

export type SetFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  number?: InputMaybe<IntFilterInput>;
  projectId?: InputMaybe<IdInFilter>;
};

export enum SetOrderByCriteria {
  ArticleId = 'articleId',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  Number = 'number',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  ProjectId = 'project_id',
  Public = 'public',
  State = 'state'
}

export type SetOrderByInput = {
  direction: SortOrder;
  key: SetOrderByCriteria;
};

export type ShareAssignment = {
  __typename: 'ShareAssignment';
  assignedTo?: Maybe<ShareAssignmentSubject>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** @deprecated Currently unused */
  permission: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export type ShareAssignmentFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ShareAssignmentOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type ShareAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type SharingLink = {
  __typename: 'SharingLink';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  organization: Organization;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StorageDeleteTask = {
  __typename: 'StorageDeleteTask';
  created: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  gcsBucketName: Scalars['String']['output'];
  gcsObjectName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type StorageDeleteTaskFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  gcsBucketName?: InputMaybe<StringFilterInput>;
  gcsObjectName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
};

export enum StorageDeleteTaskOrderByCriteria {
  CreatedAt = 'createdAt',
  GcsBucketName = 'gcsBucketName',
  GcsObjectName = 'gcsObjectName',
  Id = 'id',
  LegacyId = 'legacyId'
}

export type StorageDeleteTaskOrderByInput = {
  direction: SortOrder;
  key: StorageDeleteTaskOrderByCriteria;
};

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<StringFilterInput>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringInFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SystemRole {
  ArGenerationService = 'ArGenerationService',
  AssetRenderingService = 'AssetRenderingService',
  Configurator = 'Configurator',
  FileProcessingService = 'FileProcessingService',
  ImageProcessingService = 'ImageProcessingService',
  JobSystemService = 'JobSystemService',
  MaterialMapsExporterService = 'MaterialMapsExporterService',
  RenderingService = 'RenderingService',
  Scanner = 'Scanner',
  Staff = 'Staff',
  Superadmin = 'Superadmin',
  TilingService = 'TilingService',
  UtilityService = 'UtilityService',
  WebComponents = 'WebComponents'
}

export type Tag = {
  __typename: 'Tag';
  assignments: Array<TagAssignment>;
  assignmentsCount: Scalars['Int']['output'];
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: User;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  otherInfo?: Maybe<Scalars['String']['output']>;
  templateRevisions: Array<TemplateRevision>;
  templateRevisionsCount: Scalars['Int']['output'];
  type: TagType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TagAssignmentsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TagDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TagJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type TagAssignment = {
  __typename: 'TagAssignment';
  assignedTo?: Maybe<TagAssignmentSubject>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  tag: Tag;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TagAssignmentFilterInput = {
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<IdInFilter>;
};

export enum TagAssignmentOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type TagAssignmentOrderByInput = {
  direction: SortOrder;
  key: TagAssignmentOrderByCriteria;
};

export type TagAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type TagFilterInput = {
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<Array<TagType>>;
};

export enum TagOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  MaterialRevisionId = 'materialRevisionId',
  Name = 'name'
}

export type TagOrderByInput = {
  direction: SortOrder;
  key: TagOrderByCriteria;
};

export enum TagType {
  MaterialRange = 'MaterialRange',
  PdfSpecItem = 'PdfSpecItem',
  PictureAnnotation = 'PictureAnnotation',
  ProductCategory = 'ProductCategory',
  ProductRange = 'ProductRange',
  Production = 'Production'
}

export type Task = {
  __typename: 'Task';
  assignedTo?: Maybe<TaskAssignmentSubject>;
  comments: Array<TaskComment>;
  commentsCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  pins: Array<TaskPin>;
  pinsCount: Scalars['Int']['output'];
  public: Scalars['Boolean']['output'];
  state: TaskState;
  taskType: TaskType;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TaskCommentsArgs = {
  orderBy?: InputMaybe<Array<TaskCommentOrderByInput>>;
};

export type TaskAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type TaskComment = {
  __typename: 'TaskComment';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  task: Task;
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TaskCommentFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  taskId?: InputMaybe<IdInFilter>;
  text?: InputMaybe<StringFilterInput>;
};

export enum TaskCommentOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  LegacyId = 'legacyId',
  Text = 'text'
}

export type TaskCommentOrderByInput = {
  direction: SortOrder;
  key: TaskCommentOrderByCriteria;
};

export type TaskFilterInput = {
  commentIds?: InputMaybe<IdInFilter>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  number?: InputMaybe<IntFilterInput>;
  objectId?: InputMaybe<IdFilterInput>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  pinIds?: InputMaybe<IdInFilter>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<TaskState>>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<Array<TaskType>>;
};

export type TaskInfos = {
  __typename: 'TaskInfos';
  archived: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  waitingForFeedback: Scalars['Int']['output'];
};

export enum TaskOrderByCriteria {
  Comments = 'comments',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  LegacyId = 'legacyId',
  Number = 'number',
  Public = 'public',
  Title = 'title'
}

export type TaskOrderByInput = {
  direction: SortOrder;
  key: TaskOrderByCriteria;
};

export type TaskPin = {
  __typename: 'TaskPin';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  task: Task;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type TaskPinFilterInput = {
  id?: InputMaybe<IdInFilter>;
  taskId?: InputMaybe<IdInFilter>;
  x?: InputMaybe<FloatFilterInput>;
  y?: InputMaybe<FloatFilterInput>;
};

export enum TaskPinOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text'
}

export type TaskPinOrderByInput = {
  direction: SortOrder;
  key: TaskPinOrderByCriteria;
};

export enum TaskState {
  Archived = 'Archived',
  Completed = 'Completed',
  InProgress = 'InProgress',
  WaitingForFeedback = 'WaitingForFeedback'
}

export enum TaskType {
  Generic = 'Generic',
  Material = 'Material',
  Model = 'Model',
  Picture = 'Picture'
}

export type Template = {
  __typename: 'Template';
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  exSceneId?: Maybe<Scalars['Int']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<TemplateRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  /** @deprecated Use organizationId instead */
  organizationLegacyId: Scalars['Int']['output'];
  picture: Picture;
  public: Scalars['Boolean']['output'];
  revisions: Array<TemplateRevision>;
  revisionsCount: Scalars['Int']['output'];
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: TemplateState;
  statistics: Array<SceneStatistics>;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  tasks: Array<Task>;
  type: TemplateType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type TemplateDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TemplateJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TemplateRevisionsArgs = {
  orderBy?: InputMaybe<Array<TemplateRevisionOrderByInput>>;
};


export type TemplateShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type TemplateTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type TemplateUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type TemplateCreateTemplateRevisionInlineInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
};

export type TemplateDataObjectAssignmentInput = {
  dataObjectId: Scalars['Int']['input'];
  types: Array<DataObjectAssignmentType>;
};

export type TemplateFilterInput = {
  exSceneId?: InputMaybe<Scalars['Int']['input']>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  pictureId?: InputMaybe<IntInFilter>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<TemplateState>>;
  statisticsIds?: InputMaybe<IdInFilter>;
  tagId?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<TemplateType>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum TemplateOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Public = 'public'
}

export type TemplateOrderByInput = {
  direction: SortOrder;
  key: TemplateOrderByCriteria;
};

export type TemplateRevision = {
  __typename: 'TemplateRevision';
  arStatistics: Array<ArStatistics>;
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  exSceneRevisionId?: Maybe<Scalars['Int']['output']>;
  graph?: Maybe<Scalars['Json']['output']>;
  hdris: Array<Hdri>;
  hdrisCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLegacy: Scalars['Boolean']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevisions: Array<MaterialRevision>;
  materialRevisionsCount: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  referencedBy: Array<TemplateRevision>;
  referencedByCount: Scalars['Int']['output'];
  references: Array<TemplateRevision>;
  referencesCount: Scalars['Int']['output'];
  renderJobs: Array<RenderJob>;
  /** Up to four thumbnails based on the output of assigned job tasks */
  renderedImages: Array<DataObject>;
  tags: Array<Tag>;
  tagsCount: Scalars['Int']['output'];
  template: Template;
  textureRevisions: Array<TextureRevision>;
  textureRevisionsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TemplateRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TemplateRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TemplateRevisionRenderedImagesArgs = {
  orderBy?: InputMaybe<JobAssignmentOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type TemplateRevisionFilterInput = {
  arStatisticsIds?: InputMaybe<IdInFilter>;
  exSceneRevisionId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  number?: InputMaybe<Scalars['Int']['input']>;
  renderJobsIds?: InputMaybe<IdInFilter>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum TemplateRevisionOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Number = 'number',
  TemplateId = 'template_id'
}

export type TemplateRevisionOrderByInput = {
  direction: SortOrder;
  key: TemplateRevisionOrderByCriteria;
};

export enum TemplateState {
  Completed = 'Completed',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Review = 'Review'
}

export enum TemplateType {
  Part = 'Part',
  Product = 'Product',
  Room = 'Room',
  Scene = 'Scene'
}

export type Texture = {
  __typename: 'Texture';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<TextureRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  revisions: Array<TextureRevision>;
  textureSet: TextureSet;
  type: TextureType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TextureDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TextureJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TextureRevisionsArgs = {
  orderBy?: InputMaybe<Array<TextureRevisionOrderByInput>>;
};

/** High-level description of an edit made to multiple revisions within a texture set */
export type TextureEditGroup = {
  __typename: 'TextureEditGroup';
  assignments: Array<TextureEditGroupAssignment>;
  content?: Maybe<Scalars['Json']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** Are all of the originally assigned revisions still present? Returns false if at least one assigned revision has been deleted since creation of the texture edit group. */
  isComplete: Scalars['Boolean']['output'];
  latestCyclesRevision?: Maybe<TextureRevision>;
  /** The number of revisions assigned at creation time. */
  originalRevisionsCount: Scalars['Int']['output'];
  revisions: Array<TextureRevision>;
  textureSet: TextureSet;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TextureEditGroupAssignment = {
  __typename: 'TextureEditGroupAssignment';
  editGroup: TextureEditGroup;
  revision: TextureRevision;
};

export type TextureEditGroupFilterInput = {
  id?: InputMaybe<IdInFilter>;
  textureSetId?: InputMaybe<IdInFilter>;
};

export enum TextureEditGroupOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Title = 'title'
}

export type TextureEditGroupOrderByInput = {
  direction: SortOrder;
  key: TextureEditGroupOrderByCriteria;
};

export type TextureFilterInput = {
  id?: InputMaybe<IdInFilter>;
  revisionId?: InputMaybe<IdInFilter>;
  textureSetId?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<TextureType>>;
};

export type TextureGroup = {
  __typename: 'TextureGroup';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material?: Maybe<Material>;
  meshSpecific: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  textureSets: Array<TextureSet>;
  textureSetsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TextureGroupDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TextureGroupJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type TextureGroupFilterInput = {
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  materialId?: InputMaybe<IdInFilter>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum TextureGroupOrderByCriteria {
  Comment = 'comment',
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name'
}

export type TextureGroupOrderByInput = {
  direction: SortOrder;
  key: TextureGroupOrderByCriteria;
};

export enum TextureOrderByCriteria {
  Id = 'id'
}

export type TextureOrderByInput = {
  direction: SortOrder;
  key: TextureOrderByCriteria;
};

/** A particular version of a texture. Subsequent edits will create new revisions of the same texture. */
export type TextureRevision = {
  __typename: 'TextureRevision';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdByVersion?: Maybe<Scalars['String']['output']>;
  dataObject: DataObject;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  displacement?: Maybe<Scalars['Float']['output']>;
  editGroups: Array<TextureEditGroup>;
  group?: Maybe<Scalars['String']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialNodes: Array<MaterialNode>;
  materialNodesCount: Scalars['Int']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  state: TextureRevisionState;
  templateRevisions: Array<TemplateRevision>;
  texture?: Maybe<Texture>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  width: Scalars['Float']['output'];
};


/** A particular version of a texture. Subsequent edits will create new revisions of the same texture. */
export type TextureRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


/** A particular version of a texture. Subsequent edits will create new revisions of the same texture. */
export type TextureRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type TextureRevisionFilterInput = {
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  number?: InputMaybe<IntInFilter>;
  state?: InputMaybe<Array<TextureRevisionState>>;
  textureId?: InputMaybe<IdInFilter>;
};

export enum TextureRevisionOrderByCriteria {
  Id = 'id',
  Number = 'number',
  TextureId = 'texture_id'
}

export type TextureRevisionOrderByInput = {
  direction: SortOrder;
  key: TextureRevisionOrderByCriteria;
};

export enum TextureRevisionState {
  Completed = 'Completed',
  Draft = 'Draft'
}

export type TextureSet = {
  __typename: 'TextureSet';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  editGroups: Array<TextureEditGroup>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  scanJob?: Maybe<ScanJob>;
  textureGroup: TextureGroup;
  textureSetRevisions: Array<TextureSetRevision>;
  textures: Array<Texture>;
  texturesCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TextureSetDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TextureSetJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TextureSetTextureSetRevisionsArgs = {
  orderBy?: InputMaybe<Array<TextureSetRevisionOrderByInput>>;
};

export type TextureSetFilterInput = {
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  scanJobId?: InputMaybe<IdInFilter>;
  textureGroupId?: InputMaybe<IdInFilter>;
};

export type TextureSetInlineCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['String']['input']>;
};

export enum TextureSetOrderByCriteria {
  Id = 'id'
}

export type TextureSetOrderByInput = {
  direction: SortOrder;
  key: TextureSetOrderByCriteria;
};

/** Revision of TextureSet describing all maps of PBR set as well as their edits */
export type TextureSetRevision = {
  __typename: 'TextureSetRevision';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  displacement?: Maybe<Scalars['Float']['output']>;
  editsJson?: Maybe<Scalars['Json']['output']>;
  editsProcessingJob?: Maybe<Job>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  mapAssignments: Array<TextureSetRevisionMapAssignment>;
  materialNodes: Array<MaterialNode>;
  migratedFromTextureEditGroupUuid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sourceDataObjectReferences: Array<TextureSetRevisionSourceDataObjectReference>;
  textureSet: TextureSet;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  width: Scalars['Float']['output'];
};

export type TextureSetRevisionFilterInput = {
  id?: InputMaybe<IdInFilter>;
  migratedFromTextureEditGroupId?: InputMaybe<IdInFilter>;
  textureSetId?: InputMaybe<IdInFilter>;
};

/** Assignment of texture revision to texture set revision for specified texture type */
export type TextureSetRevisionMapAssignment = {
  __typename: 'TextureSetRevisionMapAssignment';
  dataObject: DataObject;
  textureSetRevision: TextureSetRevision;
  textureType: TextureType;
};

export type TextureSetRevisionMapAssignmentInput = {
  dataObjectId: Scalars['ID']['input'];
  textureType: TextureType;
};

export enum TextureSetRevisionOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Title = 'title'
}

export type TextureSetRevisionOrderByInput = {
  direction: SortOrder;
  key: TextureSetRevisionOrderByCriteria;
};

/** Assignment of data-object reference to texture set revision */
export type TextureSetRevisionSourceDataObjectReference = {
  __typename: 'TextureSetRevisionSourceDataObjectReference';
  dataObject: DataObject;
  textureSetRevision: TextureSetRevision;
};

export enum TextureType {
  Anisotropy = 'Anisotropy',
  AnisotropyRotation = 'AnisotropyRotation',
  AnisotropyStrength = 'AnisotropyStrength',
  Diffuse = 'Diffuse',
  DiffuseRoughness = 'DiffuseRoughness',
  Displacement = 'Displacement',
  Error = 'Error',
  F0 = 'F0',
  Ior = 'IOR',
  Mask = 'Mask',
  Metalness = 'Metalness',
  Normal = 'Normal',
  Roughness = 'Roughness',
  SpecularStrength = 'SpecularStrength',
  SpecularTint = 'SpecularTint',
  Transmission = 'Transmission'
}

export type User = {
  __typename: 'User';
  assetHistories: Array<AssetHistory>;
  assetHistoriesCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUserAssignments: Array<UserAssignment>;
  createdUserAssignmentsCount: Scalars['Int']['output'];
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  dateJoined: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  emailNotifications: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  isSuperuser: Scalars['Boolean']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialAssignmentHistories: Array<MaterialAssignmentHistory>;
  materialAssignmentHistoriesCount: Scalars['Int']['output'];
  materialHistories: Array<MaterialHistory>;
  materialHistoriesCount: Scalars['Int']['output'];
  memberships: Array<Membership>;
  modelHistories: Array<ModelHistory>;
  modelHistoriesCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pictureAssetAssignmentHistories: Array<PictureAssetAssignmentHistory>;
  pictureAssetAssignmentHistoriesCount: Scalars['Int']['output'];
  pictureHistories: Array<PictureHistory>;
  pictureHistoriesCount: Scalars['Int']['output'];
  role?: Maybe<SystemRole>;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignmentHistories: Array<UserAssignmentHistory>;
  userAssignmentHistoriesCount: Scalars['Int']['output'];
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
  visibleInFilters: Scalars['Boolean']['output'];
};


export type UserDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type UserAssignment = {
  __typename: 'UserAssignment';
  assignedTo?: Maybe<UserAssignmentSubject>;
  /** Currently, there is only one type of assignment: 'Generic'. */
  assignmentType: UserAssignmentType;
  contentTypeModel: ContentTypeModel;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** The state of the Model, Material, Asset or Picture the User is assigned to. */
  objectState?: Maybe<UserAssignmentState>;
  /** We can assign different users for each state. This is the state of the UserAssignment itself. The current assignment is the one whose state matches that of the object. */
  state?: Maybe<UserAssignmentState>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export type UserAssignmentFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  state?: InputMaybe<UserAssignmentState>;
  type?: InputMaybe<UserAssignmentType>;
  userId?: InputMaybe<IdInFilter>;
};

export type UserAssignmentHistory = {
  __typename: 'UserAssignmentHistory';
  assignment?: Maybe<UserAssignment>;
  /** Currently, there is only one type of assignment: 'Generic'. */
  assignmentType: UserAssignmentType;
  contentTypeModel?: Maybe<ContentTypeModel>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** The state of the Model, Material, Asset or Picture at which a User is assigned to it. */
  state?: Maybe<UserAssignmentState>;
  user?: Maybe<User>;
};

export type UserAssignmentHistoryFilterInput = {
  assignmentId?: InputMaybe<IdInFilter>;
  created?: InputMaybe<DateTimeFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<UserAssignmentType>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum UserAssignmentHistoryOrderByCriteria {
  CreatedAt = 'createdAt',
  HistoryDate = 'history_date',
  Id = 'id'
}

export type UserAssignmentHistoryOrderByInput = {
  direction: SortOrder;
  key: UserAssignmentHistoryOrderByCriteria;
};

export enum UserAssignmentOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text'
}

export type UserAssignmentOrderByInput = {
  direction: SortOrder;
  key: UserAssignmentOrderByCriteria;
};

export enum UserAssignmentState {
  Completed = 'Completed',
  Draft = 'Draft',
  FinalRender = 'FinalRender',
  InfoReview = 'InfoReview',
  Modelling = 'Modelling',
  OnHold = 'OnHold',
  Review = 'Review',
  Scanning = 'Scanning',
  Shading = 'Shading',
  Stage1 = 'Stage1',
  Stage2 = 'Stage2',
  Tiling = 'Tiling'
}

export type UserAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export enum UserAssignmentType {
  Generic = 'Generic'
}

export type UserFilterInput = {
  dateJoined?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  firstName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  lastLogin?: InputMaybe<DateTimeFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  legacyId?: InputMaybe<IntFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserOrderByCriteria {
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  LegacyId = 'legacyId'
}

export type UserOrderByInput = {
  direction: SortOrder;
  key: UserOrderByCriteria;
};

export type CreateSalesEnquiryMutationVariables = Exact<{
  input: MutationCreateSalesEnquiryInput;
}>;


export type CreateSalesEnquiryMutation = { __typename: 'Mutation', createSalesEnquiry: { __typename: 'SalesEnquiry', id: string } };

export type GetTemplateDetailsForTemplateViewerQueryVariables = Exact<{
  pictureId: Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForTemplateViewerQuery = { __typename: 'Query', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } };

export type AssetActivityItemsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AssetActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'AssetHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: HistoryType, state: AssetState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type MaterialActivityItemsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'MaterialHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: HistoryType, state: MaterialState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type ModelActivityItemsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ModelActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'ModelHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: HistoryType, state: ModelState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type PictureActivityItemsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'PictureHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: HistoryType, state: PictureState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type AttachedDataObjectFragment = { __typename: 'DataObject', id: string, state: DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null };

export type AttachmentFragment = { __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null } };

export type CreateAttachmentMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type CreateAttachmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null } } };

export type DeleteAttachedFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAttachedFileMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type UpdateAttachmentMutationVariables = Exact<{
  input: MutationUpdateDataObjectAssignmentInput;
}>;


export type UpdateAttachmentMutation = { __typename: 'Mutation', updateDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null } } };

export type AttachmentRenameDataObjectMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
}>;


export type AttachmentRenameDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string, state: DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null } };

export type AttachmentsQueryVariables = Exact<{
  filter: DataObjectAssignmentFilterInput;
}>;


export type AttachmentsQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: JobState } | null } } | null> };

export type TagSearchFilterTagFragment = { __typename: 'Tag', id: string, name: string, type: TagType };

export type TagSearchFilterTagsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilterInput>;
}>;


export type TagSearchFilterTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string, type: TagType } | null> };

export type TagSearchFilterOrganizationsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
}>;


export type TagSearchFilterOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name?: string | null } | null> };

export type TagSearchFilterUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TagSearchFilterUserQuery = { __typename: 'Query', user: { __typename: 'User', memberships: Array<{ __typename: 'Membership', organization: { __typename: 'Organization', id: string } }> } };

export type TagSelectAssignMutationVariables = Exact<{
  objectId: Scalars['ID']['input'];
  contentTypeModel: ContentTypeModel;
  tagId: Scalars['ID']['input'];
}>;


export type TagSelectAssignMutation = { __typename: 'Mutation', createTagAssignment: { __typename: 'TagAssignment', id: string } };

export type TagSelectUnassignMutationVariables = Exact<{
  tagAssignmentId: Scalars['ID']['input'];
}>;


export type TagSelectUnassignMutation = { __typename: 'Mutation', deleteTagAssignment?: any | null };

export type TagSelectTagFragment = { __typename: 'Tag', id: string, name: string, type: TagType };

export type TagSelectTagsQueryVariables = Exact<{
  filter: TagFilterInput;
}>;


export type TagSelectTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string, type: TagType } | null> };

export type TagSelectTagAssignmentsQueryVariables = Exact<{
  filter: TagAssignmentFilterInput;
}>;


export type TagSelectTagAssignmentsQuery = { __typename: 'Query', tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string } } | null> };

export type ItemListUserFragment = { __typename: 'User', id: string, name: string };

export type ItemListVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemListVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type ListInfoOrganizationLegacyIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ListInfoOrganizationLegacyIdQuery = { __typename: 'Query', organization: { __typename: 'Organization', legacyId: number } };

export type ListInfoTagFragment = { __typename: 'Tag', id: string, name: string };

export type ListInfoVisibleTagsQueryVariables = Exact<{
  filter: TagFilterInput;
}>;


export type ListInfoVisibleTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string } | null> };

export type ListInfoUserFragment = { __typename: 'User', id: string, name: string };

export type ListInfoVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListInfoVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type ConfigMenuMaterialFragment = { __typename: 'Material', name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> };

export type ConfigMenuMaterialIconFragment = { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type ConfigMenuFetchMaterialQueryVariables = Exact<{
  materialLegacyId: Scalars['Int']['input'];
}>;


export type ConfigMenuFetchMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', name?: string | null, id: string, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type ConfigMenuDataObjectQueryVariables = Exact<{
  dataObjectLegacyId: Scalars['Int']['input'];
}>;


export type ConfigMenuDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type MaterialForConfigMenuLegacyServiceFragment = { __typename: 'Material', id: string, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type DataObjectThumbnailForConfigMenuLegacyServiceQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type DataObjectThumbnailForConfigMenuLegacyServiceQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type NavigationBarOrganizationFragment = { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null };

export type NavigationBarAllOrganizationsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
}>;


export type NavigationBarAllOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } | null> };

export type NavigationBarUserMembershipsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type NavigationBarUserMembershipsQuery = { __typename: 'Query', user: { __typename: 'User', memberships: Array<{ __typename: 'Membership', organization: { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } }> } };

export type NavigationBarColormassOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigationBarColormassOrganizationQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } | null> };

export type MaterialForConfigMenuServiceFragment = { __typename: 'Material', id: string, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type DataObjectThumbnailForConfigMenuServiceQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type DataObjectThumbnailForConfigMenuServiceQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type TemplateRevisionDetailsForConfiguratorFragment = { __typename: 'TemplateRevision', id: string, graph?: any | null };

export type TemplateDetailsForConfiguratorFragment = { __typename: 'Template', id: string, organizationLegacyId: number, latestRevision?: { __typename: 'TemplateRevision', id: string, graph?: any | null } | null };

export type GetTemplateDetailsForConfiguratorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForConfiguratorQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationLegacyId: number, latestRevision?: { __typename: 'TemplateRevision', id: string, graph?: any | null } | null } };

export type GetTemplateUuidFromSceneLegacyIdForConfiguratorQueryVariables = Exact<{
  sceneLegacyId: Scalars['Int']['input'];
}>;


export type GetTemplateUuidFromSceneLegacyIdForConfiguratorQuery = { __typename: 'Query', scene: { __typename: 'Scene', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } } };

export type LatestRevisionFromMaterialIdForConfiguratorQueryVariables = Exact<{
  materialId: Scalars['ID']['input'];
}>;


export type LatestRevisionFromMaterialIdForConfiguratorQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type LatestRevisionFromMaterialLegacyIdForConfiguratorQueryVariables = Exact<{
  materialLegacyId: Scalars['Int']['input'];
}>;


export type LatestRevisionFromMaterialLegacyIdForConfiguratorQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type MaterialIdFromLegacyIdQueryVariables = Exact<{
  materialLegacyId: Scalars['Int']['input'];
}>;


export type MaterialIdFromLegacyIdQuery = { __typename: 'Query', material: { __typename: 'Material', id: string } };

export type DataObjectForArServiceFragment = { __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null };

export type DataObjectWithRelatedForArServiceFragment = { __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null }> };

export type GetDataObjectAssignmentsForArServiceQueryVariables = Exact<{
  filter: DataObjectAssignmentFilterInput;
}>;


export type GetDataObjectAssignmentsForArServiceQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null }> } } | null> };

export type GetTemplateIdForArServiceQueryVariables = Exact<{
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetTemplateIdForArServiceQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, template: { __typename: 'Template', id: string, legacyId: number, organizationId: string, organizationLegacyId: number } } };

export type GetJobTaskForArServiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetJobTaskForArServiceQuery = { __typename: 'Query', jobTask: { __typename: 'JobTask', id: string, state: JobTaskState, output?: any | null } };

export type ArServiceCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type ArServiceCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type ImageViewerItemFragment = { __typename: 'DataObject', id: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type ImageViewerItemsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type ImageViewerItemsQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } | null> };

export type SceneViewerDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, mediaType?: string | null };

export type SceneViewerMaterialFragment = { __typename: 'Material', id: string, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type SceneViewerMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, legacyId: number };

export type SceneViewerTemplateFragment = { __typename: 'Template', id: string, legacyId: number, organizationLegacyId: number, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', organizationId: string } } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', organizationId: string } }> };

export type SceneViewerTemplateRevisionFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', organizationId: string } };

export type SceneViewerDataObjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SceneViewerDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', downloadUrl: string, objectName: string, id: string, legacyId: number, mediaType?: string | null, related: Array<{ __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null }> } };

export type SceneViewerMaterialQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type SceneViewerMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type SceneViewerTemplateQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type SceneViewerTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, organizationLegacyId: number, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', organizationId: string } } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', organizationId: string } }> } };

export type SceneViewerTemplateLegacyIdFromUuidQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SceneViewerTemplateLegacyIdFromUuidQuery = { __typename: 'Query', template: { __typename: 'Template', legacyId: number } };

export type SceneViewerTemplateUuidFromLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type SceneViewerTemplateUuidFromLegacyIdQuery = { __typename: 'Query', scene: { __typename: 'Scene', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } } };

export type SceneViewerPdfTemplateQueryVariables = Exact<{
  organizationLegacyId: Scalars['Int']['input'];
}>;


export type SceneViewerPdfTemplateQuery = { __typename: 'Query', organization: { __typename: 'Organization', pdfTemplate?: { __typename: 'DataObject', bucketName: string, objectName: string } | null, font?: { __typename: 'DataObject', bucketName: string, objectName: string } | null, fontBold?: { __typename: 'DataObject', bucketName: string, objectName: string } | null } };

export type SceneViewerDataObjectAssignmentsQueryVariables = Exact<{
  filter: DataObjectAssignmentFilterInput;
}>;


export type SceneViewerDataObjectAssignmentsQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, legacyId: number, mediaType?: string | null } } | null> };

export type SceneViewerCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type SceneViewerCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type SceneViewerJobTaskStateWithOutputQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SceneViewerJobTaskStateWithOutputQuery = { __typename: 'Query', jobTask: { __typename: 'JobTask', id: string, state: JobTaskState, output?: any | null } };

export type JobsHelperStateUpdateQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type JobsHelperStateUpdateQuery = { __typename: 'Query', jobs: Array<{ __typename: 'Job', id: string, message?: string | null, progress?: number | null, started?: string | null, state: JobState } | null> };

export type ShaderBallMaterialFragment = { __typename: 'Material', legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number } | null };

export type TemplateDetailsForMaterialAssetsRenderingFragment = { __typename: 'Template', name?: string | null, legacyId: number, latestRevision?: { __typename: 'TemplateRevision', legacyId: number, graph?: any | null } | null };

export type GetTemplateDetailsForMaterialAssetsRenderingQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetTemplateDetailsForMaterialAssetsRenderingQuery = { __typename: 'Query', template: { __typename: 'Template', name?: string | null, legacyId: number, latestRevision?: { __typename: 'TemplateRevision', legacyId: number, graph?: any | null } | null } };

export type QueryMaterialConverterTextureSetRevisionQueryVariables = Exact<{
  textureSetRevisionId: Scalars['ID']['input'];
}>;


export type QueryMaterialConverterTextureSetRevisionQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', id: string } }> } };

export type BatchExportMaterialCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type BatchExportMaterialCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type BatchExportMaterialCancelJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BatchExportMaterialCancelJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export type BatchExportMaterialGetJobStateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BatchExportMaterialGetJobStateQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, progress?: number | null, state: JobState } };

export type BatchExportMaterialGetJobDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BatchExportMaterialGetJobDetailsQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, output?: any | null, state: JobState } };

export type AuthenticatedUserFragment = { __typename: 'User', id: string, email: string, isStaff: boolean, isSuperuser: boolean, name: string, role?: SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } }> };

export type AuthSiloDataFragment = { __typename: 'User', id: string, role?: SystemRole | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } }> };

export type AuthSiloDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AuthSiloDataQuery = { __typename: 'Query', user: { __typename: 'User', id: string, email: string, isStaff: boolean, isSuperuser: boolean, name: string, role?: SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } }> } };

export type DataObjectThumbnailFragment = { __typename: 'DataObject', id: string, mediaType?: string | null, state: DataObjectState, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, thumbnailProcessingJob?: { __typename: 'Job', state: JobState } | null };

export type QueryDataObjectThumbnailsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  resolution: DownloadResolution;
}>;


export type QueryDataObjectThumbnailsQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, state: DataObjectState, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, thumbnailProcessingJob?: { __typename: 'Job', state: JobState } | null } | null> };

export type DownloadableFileFragment = { __typename: 'DataObject', downloadUrl: string, originalFileName: string };

export type DownloadableFileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DownloadableFileQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', downloadUrl: string, originalFileName: string } };

export type GalleryImageAssignmentsQueryVariables = Exact<{
  objectId: Scalars['ID']['input'];
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  assignmentType?: InputMaybe<Array<DataObjectAssignmentType> | DataObjectAssignmentType>;
}>;


export type GalleryImageAssignmentsQuery = { __typename: 'Query', galleryImageAssignments: Array<{ __typename: 'DataObjectAssignment', id: string } | null> };

export type GalleryImageAssignmentRemoveMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GalleryImageAssignmentRemoveMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type GalleryImageCountQueryVariables = Exact<{
  objectId: Scalars['ID']['input'];
  contentTypeModel: ContentTypeModel;
  assignmentType?: InputMaybe<Array<DataObjectAssignmentType> | DataObjectAssignmentType>;
}>;


export type GalleryImageCountQuery = { __typename: 'Query', galleryImageCount: number };

export type ItemUtilsGalleryImageFragment = { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null };

export type MaterialAssetsRenderingMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null };

export type MaterialAssetsRenderingMaterialQueryVariables = Exact<{
  materialId: Scalars['ID']['input'];
}>;


export type MaterialAssetsRenderingMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } };

export type MaterialAssetsRenderingCreateJobTaskMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type MaterialAssetsRenderingCreateJobTaskMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type MaterialsCardInputFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type MaterialsCardFragment = { __typename: 'Material', id: string, nextActor: NextActor, state: MaterialState, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization?: { __typename: 'Organization', id: string } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number } };

export type MaterialsCardDataQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type MaterialsCardDataQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, nextActor: NextActor, state: MaterialState, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization?: { __typename: 'Organization', id: string } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number } } | null> };

export type MaterialGraphRevisionFragment = { __typename: 'MaterialRevision', legacyId: number, material: { __typename: 'Material', name?: string | null, legacyId: number } };

export type MaterialGraphTextureSetRevisionFragment = { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> };

export type MaterialGraphNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null };

export type MaterialGraphConnectionFragment = { __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } };

export type MaterialGraphExtendedRevisionFragment = { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } };

export type MaterialRevisionForMaterialGraphQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MaterialRevisionForMaterialGraphQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } };

export type MaterialRevisionsForMaterialGraphQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type MaterialRevisionsForMaterialGraphQuery = { __typename: 'Query', materialRevisions: Array<{ __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null> };

export type MaterialGraphDataObjectDetailsFragment = { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> };

export type MaterialGraphTextureRevisionFragment = { __typename: 'TextureRevision', id: string, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } };

export type TextureRevisionForMaterialGraphQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type TextureRevisionForMaterialGraphQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } } };

export type TextureRevisionsForMaterialGraphQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type TextureRevisionsForMaterialGraphQuery = { __typename: 'Query', textureRevisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } } | null> };

export type DataObjectForMaterialGraphQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type DataObjectForMaterialGraphQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } };

export type DataObjectsForMaterialGraphQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DataObjectsForMaterialGraphQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } | null> };

export type RelatedDataObjectForMaterialGraphQueryVariables = Exact<{
  fileType: DownloadFileType;
  resolution: DownloadResolution;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type RelatedDataObjectForMaterialGraphQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, downloadUrl: string } | null } };

export type MapsExportTextureRevisionFragment = { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: TextureType } | null };

export type NormalToDisplacementStepTextureRevisionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type NormalToDisplacementStepTextureRevisionQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', createdByVersion?: string | null, texture?: { __typename: 'Texture', textureSet: { __typename: 'TextureSet', textures: Array<{ __typename: 'Texture', type: TextureType, revisions: Array<{ __typename: 'TextureRevision', createdByVersion?: string | null, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: TextureType } | null }> }> } } | null } };

export type MaterialRevisionForMapsExportFragment = { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', id: string }>, material: { __typename: 'Material', name?: string | null, legacyId: number } };

export type MaterialMapsExporterConnectionFragment = { __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } };

export type MaterialConnectionForMapsExportQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MaterialConnectionForMapsExportQuery = { __typename: 'Query', materialConnection: { __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } } };

export type MaterialMapsExporterNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null };

export type MaterialNodeForMapsExportQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MaterialNodeForMapsExportQuery = { __typename: 'Query', materialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null } };

export type MaterialRevisionForMapsExportQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MaterialRevisionForMapsExportQuery = { __typename: 'Query', material: { __typename: 'Material', latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', id: string }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null } };

export type MapsExportMaterialDetailsFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, organization?: { __typename: 'Organization', legacyId: number } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> };

export type MaterialForMapsExportQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MaterialForMapsExportQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, organization?: { __typename: 'Organization', legacyId: number } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> } };

export type MaterialMapsExporterDeleteDataObjectMutationVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type MaterialMapsExporterDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type MaterialMapsExporterDeleteJsonFileMutationVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type MaterialMapsExporterDeleteJsonFileMutation = { __typename: 'Mutation', deleteJsonFile?: any | null };

export type MaterialMapsExporterUpdateDataObjectMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
}>;


export type MaterialMapsExporterUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', legacyId: number } };

export type MaterialMapsExporterCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type MaterialMapsExporterCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type MaterialMapsExporterUpdateJsonFileMutationVariables = Exact<{
  input: MutationUpdateJsonFileInput;
}>;


export type MaterialMapsExporterUpdateJsonFileMutation = { __typename: 'Mutation', updateJsonFile: { __typename: 'JsonFile', legacyId: number } };

export type MaterialMapsExporterCreateJsonFileAssignmentMutationVariables = Exact<{
  input: MutationCreateJsonFileAssignmentInput;
}>;


export type MaterialMapsExporterCreateJsonFileAssignmentMutation = { __typename: 'Mutation', createJsonFileAssignment: { __typename: 'JsonFileAssignment', id: string, jsonFile: { __typename: 'JsonFile', content: any, legacyId: number } } };

export type MaterialMapsExporterJsonFileAssignmentsQueryVariables = Exact<{
  filter: JsonFileAssignmentFilterInput;
}>;


export type MaterialMapsExporterJsonFileAssignmentsQuery = { __typename: 'Query', jsonFileAssignments: Array<{ __typename: 'JsonFileAssignment', id: string, assignmentType: JsonFileAssignmentType, jsonFile: { __typename: 'JsonFile', content: any, legacyId: number } }> };

export type DataObjectDetailsForNameAssetFromSchemaServiceFragment = { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> };

export type MaterialDetailsForNameAssetFromSchemaServiceFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } }>, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string, type: TagType, otherInfo?: string | null } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null };

export type MaterialForNameAssetFromSchemaServiceQueryVariables = Exact<{
  materialId: Scalars['ID']['input'];
  dataObjectAssignmentFilter?: InputMaybe<DataObjectAssignmentFilterInput>;
  includeRelated: Scalars['Boolean']['input'];
}>;


export type MaterialForNameAssetFromSchemaServiceQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } }>, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string, type: TagType, otherInfo?: string | null } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null } };

export type MaterialsForNameAssetFromSchemaServiceQueryVariables = Exact<{
  filter: MaterialFilterInput;
  dataObjectAssignmentFilter?: InputMaybe<DataObjectAssignmentFilterInput>;
  includeRelated?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MaterialsForNameAssetFromSchemaServiceQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } }>, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string, type: TagType, otherInfo?: string | null } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null } | null> };

export type NameAssetFromSchemaUpdateDataObjectMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
  includeRelated?: Scalars['Boolean']['input'];
}>;


export type NameAssetFromSchemaUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } };

export type BasicOrganizationInfoFragment = { __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type BasicOrganizationMembershipInfoFragment = { __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type OrganizationDropdownOptionFragment = { __typename: 'Organization', id: string, name?: string | null };

export type AllOrganizationsQueryVariables = Exact<{
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
}>;


export type AllOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type OrganizationMembershipsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type OrganizationMembershipsQuery = { __typename: 'Query', user: { __typename: 'User', id: string, role?: SystemRole | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null } }> } };

export type PaymentStateLabelForMaterialFragment = { __typename: 'Material', id: string, paymentState: PaymentState };

export type PaymentStateLabelForMaterialsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type PaymentStateLabelForMaterialsQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, paymentState: PaymentState } | null> };

export type PaymentStateLabelForModelFragment = { __typename: 'Model', id: string, paymentState: PaymentState };

export type PaymentStateLabelForModelsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type PaymentStateLabelForModelsQuery = { __typename: 'Query', models: Array<{ __typename: 'Model', id: string, paymentState: PaymentState } | null> };

export type PaymentStateLabelForPictureFragment = { __typename: 'Picture', id: string, paymentState: PaymentState };

export type PaymentStateLabelForPicturesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type PaymentStateLabelForPicturesQuery = { __typename: 'Query', pictures: Array<{ __typename: 'Picture', id: string, paymentState: PaymentState } | null> };

export type PdfDataForPdfServiceFragment = { __typename: 'Organization', id: string, pdfTemplate?: { __typename: 'DataObject', downloadUrl: string } | null, font?: { __typename: 'DataObject', downloadUrl: string } | null, fontBold?: { __typename: 'DataObject', downloadUrl: string } | null };

export type GetPdfDataForPdfServiceOrgaIdQueryVariables = Exact<{
  organizationLegacyId: Scalars['Int']['input'];
}>;


export type GetPdfDataForPdfServiceOrgaIdQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, pdfTemplate?: { __typename: 'DataObject', downloadUrl: string } | null, font?: { __typename: 'DataObject', downloadUrl: string } | null, fontBold?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type GetPdfDataForPdfServiceTemplateRevisionIdQueryVariables = Exact<{
  templateRevisionId: Scalars['ID']['input'];
}>;


export type GetPdfDataForPdfServiceTemplateRevisionIdQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, template: { __typename: 'Template', organization: { __typename: 'Organization', id: string, pdfTemplate?: { __typename: 'DataObject', downloadUrl: string } | null, font?: { __typename: 'DataObject', downloadUrl: string } | null, fontBold?: { __typename: 'DataObject', downloadUrl: string } | null } } } };

export type PriceGraphDetailsFragment = { __typename: 'PriceGraph', id: string, state: PriceGraphState, templateId: string, revisionNumber: number, graph: any };

export type PriceDetailsFragment = { __typename: 'Price', currency: Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type CreatePriceGraphMutationVariables = Exact<{
  input: MutationCreatePriceGraphInput;
}>;


export type CreatePriceGraphMutation = { __typename: 'Mutation', createPriceGraph: { __typename: 'PriceGraph', id: string, state: PriceGraphState, graph: any, revisionNumber: number } };

export type UpdatePriceGraphMutationVariables = Exact<{
  input: MutationUpdatePriceGraphInput;
}>;


export type UpdatePriceGraphMutation = { __typename: 'Mutation', updatePriceGraph: { __typename: 'PriceGraph', state: PriceGraphState, graph: any, revisionNumber: number } };

export type DeletePriceGraphMutationVariables = Exact<{
  priceGraphUuid: Scalars['ID']['input'];
}>;


export type DeletePriceGraphMutation = { __typename: 'Mutation', deletePriceGraph?: any | null };

export type CreatePricesMutationVariables = Exact<{
  prices: Array<PriceInput> | PriceInput;
  organizationUuid: Scalars['ID']['input'];
}>;


export type CreatePricesMutation = { __typename: 'Mutation', createPrices: any };

export type GetPriceGraphQueryVariables = Exact<{
  filter: PriceGraphFilterInput;
}>;


export type GetPriceGraphQuery = { __typename: 'Query', priceGraphs: Array<{ __typename: 'PriceGraph', id: string, state: PriceGraphState, templateId: string, revisionNumber: number, graph: any } | null> };

export type GetPriceQueryVariables = Exact<{
  filter: PriceFilterInput;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPriceQuery = { __typename: 'Query', prices: Array<{ __typename: 'Price', currency: Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export type HdriForRenderingQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HdriForRenderingQuery = { __typename: 'Query', hdri: { __typename: 'Hdri', dataObject?: { __typename: 'DataObject', id: string, legacyId: number } | null } };

export type RenderingServiceCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type RenderingServiceCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type SdkServiceDataObjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SdkServiceDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, state: DataObjectState } };

export type SdkServiceOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SdkServiceOrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } };

export type SdkServiceColormassOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type SdkServiceColormassOrganizationQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, type?: OrganizationType | null } | null> };

export type BasicTagInfoFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null };

export type BasicTagAssignmentFragment = { __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, type: TagType } };

export type FullTagInfoFragment = { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null };

export type AllTagsBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTagsBasicInfoQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type TagFullInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TagFullInfoQuery = { __typename: 'Query', tag: { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } };

export type GetTagAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<TagAssignmentFilterInput>;
}>;


export type GetTagAssignmentsQuery = { __typename: 'Query', tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, type: TagType } } | null> };

export type HasTagAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<TagAssignmentFilterInput>;
}>;


export type HasTagAssignmentsQuery = { __typename: 'Query', tagAssignmentsCount: number };

export type AssignTagMutationVariables = Exact<{
  objectId: Scalars['ID']['input'];
  contentTypeModel: ContentTypeModel;
  tagId: Scalars['ID']['input'];
}>;


export type AssignTagMutation = { __typename: 'Mutation', createTagAssignment: { __typename: 'TagAssignment', id: string } };

export type UnassignTagMutationVariables = Exact<{
  tagAssignmentId: Scalars['ID']['input'];
}>;


export type UnassignTagMutation = { __typename: 'Mutation', deleteTagAssignment?: any | null };

export type TemplateThumbnailRenderingFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } | null };

export type GetTemplateForThumbnailRenderingQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;


export type GetTemplateForThumbnailRenderingQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } | null } };

export type TemplateThumbnailRenderingCreateJobTaskMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type TemplateThumbnailRenderingCreateJobTaskMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type UploadProcessingDataObjectFragment = { __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null }>, metadataProcessingJob?: { __typename: 'Job', id: string } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string } | null };

export type UploadProcessingDataObjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UploadProcessingDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null }>, metadataProcessingJob?: { __typename: 'Job', id: string } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string } | null } };

export type UploadProcessingCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type UploadProcessingCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type UploadServiceDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string };

export type UploadServiceGetDataObjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UploadServiceGetDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string } };

export type UploadServiceDeleteDataObjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UploadServiceDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type UploadServiceCreateDataObjectMutationVariables = Exact<{
  input: MutationCreateDataObjectInput;
}>;


export type UploadServiceCreateDataObjectMutation = { __typename: 'Mutation', createDataObject: { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string } };

export type ConfigVariantIconDataObjectFragment = { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type ConfigVariantIconDataObjectQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ConfigVariantIconDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: ImageColorSpace | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type MaterialInspectorMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null };

export type MaterialInspectorMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null } };

export type MaterialInspectorMaterialQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialInspectorMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null } };

export type MaterialInspectorRevisionQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type MaterialInspectorRevisionQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null } } };

export type TemplateInputInspectorTagsForUserQueryVariables = Exact<{
  createdById: Scalars['ID']['input'];
}>;


export type TemplateInputInspectorTagsForUserQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type TemplateOutputInspectorTagsForUserQueryVariables = Exact<{
  createdById: Scalars['ID']['input'];
}>;


export type TemplateOutputInspectorTagsForUserQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type TemplateOutputInspectorTagsByLegacyIdQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type TemplateOutputInspectorTagsByLegacyIdQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type HdriForEditorSceneQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HdriForEditorSceneQuery = { __typename: 'Query', hdri: { __typename: 'Hdri', dataObject?: { __typename: 'DataObject', mediaType?: string | null, bucketName: string, objectName: string } | null } };

export type UploadHdriFromFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  dataObjectId: Scalars['ID']['input'];
}>;


export type UploadHdriFromFileMutation = { __typename: 'Mutation', updateHdri: { __typename: 'Hdri', id: string } };

export type EntityDetailsGalleryImageDataObjectFragment = { __typename: 'DataObject', id: string, downloadUrl: string, thumbnail?: { __typename: 'DataObject', id: string, downloadUrl: string } | null };

export type EntityDetailsGalleryImageDataObjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type EntityDetailsGalleryImageDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, thumbnail?: { __typename: 'DataObject', id: string, downloadUrl: string } | null } };

export type ArViewerCreateDataObjectAssignmentMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type ArViewerCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type MaterialEditorMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number };

export type MaterialEditorMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number }> };

export type MaterialEditorMaterialConnectionFragment = { __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } };

export type MaterialEditorMaterialNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } };

export type MaterialEditorTextureFragment = { __typename: 'Texture', id: string, legacyId: number, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } | null };

export type MaterialEditorTextureRevisionFragment = { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: TextureType } | null };

export type MaterialEditorTextureSetFragment = { __typename: 'TextureSet', id: string, textures: Array<{ __typename: 'Texture', id: string, legacyId: number, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } | null }> };

export type OrganizationsWithNodeEditorTemplateIdQueryVariables = Exact<{
  organizationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type OrganizationsWithNodeEditorTemplateIdQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, nodeEditorTemplate?: { __typename: 'Template', legacyId: number, id: string } | null } | null> };

export type MaterialEditorCreateMaterialRevisionMutationVariables = Exact<{
  input: MutationCreateMaterialRevisionInput;
}>;


export type MaterialEditorCreateMaterialRevisionMutation = { __typename: 'Mutation', createMaterialRevision: { __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number } };

export type MaterialEditorCreateMaterialNodeMutationVariables = Exact<{
  input: MutationCreateMaterialNodeInput;
}>;


export type MaterialEditorCreateMaterialNodeMutation = { __typename: 'Mutation', createMaterialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } } };

export type MaterialEditorCreateMaterialConnectionMutationVariables = Exact<{
  input: MutationCreateMaterialConnectionInput;
}>;


export type MaterialEditorCreateMaterialConnectionMutation = { __typename: 'Mutation', createMaterialConnection: { __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } } };

export type MaterialEditorMaterialQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialEditorMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number }> } };

export type MaterialEditorTextureRevisionQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type MaterialEditorTextureRevisionQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } };

export type MaterialEditorTextureSetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialEditorTextureSetQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, textures: Array<{ __typename: 'Texture', id: string, legacyId: number, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } | null }> } };

export type MaterialEditorMaterialConnectionsQueryVariables = Exact<{
  materialRevisionId: Scalars['ID']['input'];
}>;


export type MaterialEditorMaterialConnectionsQuery = { __typename: 'Query', materialConnections: Array<{ __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } } | null> };

export type MaterialEditorMaterialNodesQueryVariables = Exact<{
  materialRevisionId: Scalars['ID']['input'];
}>;


export type MaterialEditorMaterialNodesQuery = { __typename: 'Query', materialNodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } } | null> };

export type MaterialEditorResolveTextureRevisionLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type MaterialEditorResolveTextureRevisionLegacyIdQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string } };

export type MaterialEditorLatestTextureSetRevisionQueryVariables = Exact<{
  textureSetId: Scalars['ID']['input'];
}>;


export type MaterialEditorLatestTextureSetRevisionQuery = { __typename: 'Query', textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string } | null> };

export type ImageTextureSetNodeTextureSetRevisionInfoFragment = { __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null };

export type ImageTextureSetNodeTextureSetFragment = { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> };

export type ImageTextureSetNodeTextureSetRevisionFragment = { __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', id: string, legacyId: number } }>, editsProcessingJob?: { __typename: 'Job', state: JobState } | null, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> } };

export type ImageTextureSetNodeTextureSetQueryVariables = Exact<{
  textureSetLegacyId: Scalars['Int']['input'];
}>;


export type ImageTextureSetNodeTextureSetQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> } };

export type ImageTextureSetNodeTextureSetRevisionQueryVariables = Exact<{
  textureSetRevisionId: Scalars['ID']['input'];
}>;


export type ImageTextureSetNodeTextureSetRevisionQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', id: string, legacyId: number } }>, editsProcessingJob?: { __typename: 'Job', state: JobState } | null, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> } } };

export type ImageTextureNodeTextureFragment = { __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', legacyId: number, number?: number | null } | null, revisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, number?: number | null }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', id: string } } };

export type ImageTextureNodeTextureRevisionFragment = { __typename: 'TextureRevision', id: string, height: number, number?: number | null, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', legacyId: number, number?: number | null } | null, revisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, number?: number | null }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', id: string } } } | null, dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null }, thumbnailAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> };

export type ImageTextureNodeTextureQueryVariables = Exact<{
  textureRevisionLegacyId: Scalars['Int']['input'];
}>;


export type ImageTextureNodeTextureQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, height: number, number?: number | null, state: TextureRevisionState, width: number, texture?: { __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', legacyId: number, number?: number | null } | null, revisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, number?: number | null }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', id: string } } } | null, dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null }, thumbnailAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> } };

export type SaveMaterialRevisionDialogMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, comment?: string | null, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> };

export type SaveMaterialRevisionDialogMaterialQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SaveMaterialRevisionDialogMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, comment?: string | null, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> } };

export type AssetBoxesFragment = { __typename: 'Asset', id: string, legacyId: number, materials: Array<{ __typename: 'Material', state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type AssetBoxesQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
}>;


export type AssetBoxesQuery = { __typename: 'Query', asset: { __typename: 'Asset', id: string, legacyId: number, materials: Array<{ __typename: 'Material', state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null } } };

export type AssetDetailsFragment = { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, materialAssignments: Array<{ __typename: 'MaterialAssignment', id: string, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } }>, model: { __typename: 'Model', id: string, legacyId: number, name?: string | null, state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type AssetDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AssetDetailsQuery = { __typename: 'Query', item: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, materialAssignments: Array<{ __typename: 'MaterialAssignment', id: string, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } }>, model: { __typename: 'Model', id: string, legacyId: number, name?: string | null, state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } } };

export type UpdateAssetDetailsMutationVariables = Exact<{
  input: MutationUpdateAssetInput;
}>;


export type UpdateAssetDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, materialAssignments: Array<{ __typename: 'MaterialAssignment', id: string, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } }>, model: { __typename: 'Model', id: string, legacyId: number, name?: string | null, state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } } };

export type DeleteAssetDetailsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAssetDetailsMutation = { __typename: 'Mutation', deleteAsset?: any | null };

export type CreateAssetDetailsMaterialAssignmentMutationVariables = Exact<{
  input: MutationCreateMaterialAssignmentInput;
}>;


export type CreateAssetDetailsMaterialAssignmentMutation = { __typename: 'Mutation', createMaterialAssignment: { __typename: 'MaterialAssignment', id: string } };

export type DeleteAssetDetailsMaterialAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAssetDetailsMaterialAssignmentMutation = { __typename: 'Mutation', deleteMaterialAssignment?: any | null };

export type AssetListItemFragment = { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type GetAssetsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<AssetFilterInput>;
  orderBy?: InputMaybe<Array<AssetOrderByInput> | AssetOrderByInput>;
}>;


export type GetAssetsQuery = { __typename: 'Query', assetsCount: number, assets: Array<{ __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type BatchUpdateAssetsMutationVariables = Exact<{
  filter: AssetFilterInput;
  state?: InputMaybe<AssetState>;
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  nextActor?: InputMaybe<NextActor>;
}>;


export type BatchUpdateAssetsMutation = { __typename: 'Mutation', batchUpdateAssets: number };

export type CreateAssetMutationVariables = Exact<{
  input: MutationCreateAssetInput;
}>;


export type CreateAssetMutation = { __typename: 'Mutation', createAsset: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } };

export type UpdateAssetMutationVariables = Exact<{
  input: MutationUpdateAssetInput;
}>;


export type UpdateAssetMutation = { __typename: 'Mutation', updateAsset: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: NextActor, state: AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } };

export type SelectAssetItemFragment = { __typename: 'Asset', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectAssetItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<AssetFilterInput>;
}>;


export type SelectAssetItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Asset', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type PictureAssetsSectionFragment = { __typename: 'Picture', id: string, legacyId: number, assetAssignments: Array<{ __typename: 'PictureAssetAssignment', id: string, asset: { __typename: 'Asset', id: string, legacyId: number, name?: string | null, state: AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, materials: Array<{ __typename: 'Material', state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null }, organization: { __typename: 'Organization', name?: string | null, id: string, legacyId: number } } }> };

export type PictureAssetsSectionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureAssetsSectionQuery = { __typename: 'Query', item: { __typename: 'Picture', id: string, legacyId: number, assetAssignments: Array<{ __typename: 'PictureAssetAssignment', id: string, asset: { __typename: 'Asset', id: string, legacyId: number, name?: string | null, state: AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, materials: Array<{ __typename: 'Material', state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null }, organization: { __typename: 'Organization', name?: string | null, id: string, legacyId: number } } }> } };

export type TemplatesSectionItemFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, organization: { __typename: 'Organization', id: string, name?: string | null } };

export type TemplatesSectionItemsForMaterialQueryVariables = Exact<{
  materialId: Scalars['ID']['input'];
}>;


export type TemplatesSectionItemsForMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', revisions: Array<{ __typename: 'MaterialRevision', templateRevisions: Array<{ __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, organization: { __typename: 'Organization', id: string, name?: string | null } } }> }> } };

export type TemplatesSectionItemsForTemplateQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;


export type TemplatesSectionItemsForTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', revisions: Array<{ __typename: 'TemplateRevision', referencedBy: Array<{ __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, organization: { __typename: 'Organization', id: string, name?: string | null } } }> }> } };

export type FileDetailsFragment = { __typename: 'DataObject', id: string, legacyId: number, bucketName: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, state: DataObjectState, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type FileDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type FileDetailsQuery = { __typename: 'Query', item: { __typename: 'DataObject', id: string, legacyId: number, bucketName: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, state: DataObjectState, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type UpdateFileDetailsMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
}>;


export type UpdateFileDetailsMutation = { __typename: 'Mutation', item: { __typename: 'DataObject', id: string, legacyId: number, bucketName: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, state: DataObjectState, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  legacyId: Scalars['Int']['input'];
}>;


export type DeleteFileMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type FileThumbnailFragment = { __typename: 'DataObject', id: string, originalFileName: string, state: DataObjectState, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type FileThumbnailQueryVariables = Exact<{
  dataObjectId: Scalars['ID']['input'];
  resolution?: InputMaybe<DownloadResolution>;
  fileType?: InputMaybe<DownloadFileType>;
}>;


export type FileThumbnailQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, originalFileName: string, state: DataObjectState, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type FilesGridDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, state: DataObjectState, name: string, organization: { __typename: 'Organization', id: string, name?: string | null } };

export type FilesGridItemsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DataObjectFilterInput>;
}>;


export type FilesGridItemsQuery = { __typename: 'Query', dataObjectsCount: number, dataObjects: Array<{ __typename: 'DataObject', id: string, legacyId: number, state: DataObjectState, name: string, organization: { __typename: 'Organization', id: string, name?: string | null } } | null> };

export type FilesGridCreateDataObjectMutationVariables = Exact<{
  input: MutationCreateDataObjectInput;
}>;


export type FilesGridCreateDataObjectMutation = { __typename: 'Mutation', createDataObject: { __typename: 'DataObject', id: string, legacyId: number, state: DataObjectState, name: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type HdriDetailsFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type HdriDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type HdriDetailsQuery = { __typename: 'Query', item: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateHdriDetailsMutationVariables = Exact<{
  input: MutationUpdateHdriInput;
}>;


export type UpdateHdriDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type DeleteHdriMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteHdriMutation = { __typename: 'Mutation', deleteHdri?: any | null };

export type HdrisGridItemFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type HdrisGridItemsQueryVariables = Exact<{
  filter?: InputMaybe<HdriFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HdrisGridItemsQuery = { __typename: 'Query', hdrisCount: number, hdris: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type CreateHdrisGridItemMutationVariables = Exact<{
  input: MutationCreateHdriInput;
}>;


export type CreateHdrisGridItemMutation = { __typename: 'Mutation', createHdri: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateHdriMutationVariables = Exact<{
  input: MutationUpdateHdriInput;
}>;


export type UpdateHdriMutation = { __typename: 'Mutation', updateHdri: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type SelectHdriItemFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectHdriItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<HdriFilterInput>;
}>;


export type SelectHdriItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type EditJobFarmGroupDialogJobFarmGroupFragment = { __typename: 'JobFarmGroup', id: string, name: string, queueGroup: string, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type JobFarmGroupsTableItemFragment = { __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } };

export type JobFarmGroupsTableItemsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type JobFarmGroupsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } } | null> };

export type CreateJobFarmGroupsTableItemMutationVariables = Exact<{
  input: MutationCreateJobFarmGroupInput;
}>;


export type CreateJobFarmGroupsTableItemMutation = { __typename: 'Mutation', createJobFarmGroup: { __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type UpdateJobFarmGroupsTableItemMutationVariables = Exact<{
  input: MutationUpdateJobFarmGroupInput;
}>;


export type UpdateJobFarmGroupsTableItemMutation = { __typename: 'Mutation', updateJobFarmGroup: { __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type DeleteJobFarmGroupsTableItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteJobFarmGroupsTableItemMutation = { __typename: 'Mutation', deleteJobFarmGroup?: any | null };

export type EditJobFarmInstanceDialogJobFarmGroupFragment = { __typename: 'JobFarmGroup', id: string, name: string };

export type EditJobFarmInstanceJobFarmGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type EditJobFarmInstanceJobFarmGroupsQuery = { __typename: 'Query', jobFarmGroups: Array<{ __typename: 'JobFarmGroup', id: string, name: string } | null> };

export type JobFarmInstancesTableFragment = { __typename: 'JobFarmInstance', id: string, hwFingerprint: string, otherInfo?: any | null, lastReport?: string | null, state: JobFarmInstanceState, type: JobFarmInstanceType, version: string, user: { __typename: 'User', email: string }, group?: { __typename: 'JobFarmGroup', id: string, name: string } | null, claim?: { __typename: 'JobTask', id: string, legacyId: number, job?: { __typename: 'JobTask', id: string } | null } | null };

export type JobFarmInstancesDeleteJobFarmInstanceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobFarmInstancesDeleteJobFarmInstanceMutation = { __typename: 'Mutation', deleteJobFarmInstance?: any | null };

export type JobFarmInstancesUpdateJobFarmInstanceMutationVariables = Exact<{
  input: MutationUpdateJobFarmInstanceInput;
}>;


export type JobFarmInstancesUpdateJobFarmInstanceMutation = { __typename: 'Mutation', updateJobFarmInstance: { __typename: 'JobFarmInstance', id: string } };

export type JobFarmInstancesTableItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type JobFarmInstancesTableItemsQuery = { __typename: 'Query', jobFarmInstances: Array<{ __typename: 'JobFarmInstance', id: string, hwFingerprint: string, otherInfo?: any | null, lastReport?: string | null, state: JobFarmInstanceState, type: JobFarmInstanceType, version: string, user: { __typename: 'User', email: string }, group?: { __typename: 'JobFarmGroup', id: string, name: string } | null, claim?: { __typename: 'JobTask', id: string, legacyId: number, job?: { __typename: 'JobTask', id: string } | null } | null } | null> };

export type AssociatedJobsTableItemFragment = { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type AssociatedJobsTableJobAssignmentFragment = { __typename: 'JobAssignment', id: string, job: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } };

export type AssociatedJobsTableItemsQueryVariables = Exact<{
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
}>;


export type AssociatedJobsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'JobAssignment', id: string, job: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } } | null> };

export type ExecutionPlanFragment = { __typename: 'Job', executionPlan?: any | null, state: JobState };

export type GetExecutionPlanQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetExecutionPlanQuery = { __typename: 'Query', job: { __typename: 'Job', executionPlan?: any | null, state: JobState } };

export type JobDetailsFragment = { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type JobDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobDetailsQuery = { __typename: 'Query', item: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type UpdateJobDetailsMutationVariables = Exact<{
  input: MutationUpdateJobInput;
}>;


export type UpdateJobDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsDeleteJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobDetailsDeleteJobMutation = { __typename: 'Mutation', deleteJob?: any | null };

export type JobDetailsCancelMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobDetailsCancelMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsRerunAllTasksInJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobDetailsRerunAllTasksInJobMutation = { __typename: 'Mutation', rerunAllTasksInJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsRestartMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobDetailsRestartMutation = { __typename: 'Mutation', restartJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsNudgeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobDetailsNudgeMutation = { __typename: 'Mutation', nudgeJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobTaskDetailsFragment = { __typename: 'JobTask', id: string, legacyId: number, claimedBy?: string | null, deferredUntil?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, started?: string | null, state: JobTaskState, taskType?: string | null, createdBy: { __typename: 'User', id: string, legacyId: number, name: string }, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, job?: { __typename: 'JobTask', id: string, legacyId: number, name?: string | null } | null };

export type JobTaskDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type JobTaskDetailsQuery = { __typename: 'Query', item: { __typename: 'JobTask', id: string, legacyId: number, claimedBy?: string | null, deferredUntil?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, started?: string | null, state: JobTaskState, taskType?: string | null, createdBy: { __typename: 'User', id: string, legacyId: number, name: string }, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, job?: { __typename: 'JobTask', id: string, legacyId: number, name?: string | null } | null } };

export type UpdateJobTaskDetailsMutationVariables = Exact<{
  input: MutationUpdateJobTaskInput;
}>;


export type UpdateJobTaskDetailsMutation = { __typename: 'Mutation', item: { __typename: 'JobTask', id: string, legacyId: number, claimedBy?: string | null, deferredUntil?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, started?: string | null, state: JobTaskState, taskType?: string | null, createdBy: { __typename: 'User', id: string, legacyId: number, name: string }, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, job?: { __typename: 'JobTask', id: string, legacyId: number, name?: string | null } | null } };

export type JobTaskTableItemFragment = { __typename: 'JobTask', id: string, legacyId: number, attemptCount: number, deferredUntil?: string | null, finished?: string | null, maxAttempts: number, message?: string | null, name?: string | null, taskType?: string | null, progress?: number | null, state: JobTaskState, started?: string | null, claimedBy?: string | null, createdBy: { __typename: 'User', id: string, name: string } };

export type JobTaskTableItemsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobTaskTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'JobTask', id: string, legacyId: number, attemptCount: number, deferredUntil?: string | null, finished?: string | null, maxAttempts: number, message?: string | null, name?: string | null, taskType?: string | null, progress?: number | null, state: JobTaskState, started?: string | null, claimedBy?: string | null, createdBy: { __typename: 'User', id: string, name: string } } | null> };

export type JobsGridItemFragment = { __typename: 'Job', id: string, legacyId: number, message?: string | null, name?: string | null, output?: any | null, priority: number, state: JobState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type JobsGridItemsQueryVariables = Exact<{
  filter?: InputMaybe<JobFilterInput>;
  orderBy?: InputMaybe<Array<JobOrderByInput> | JobOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type JobsGridItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Job', id: string, legacyId: number, message?: string | null, name?: string | null, output?: any | null, priority: number, state: JobState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } | null> };

export type JobsTableItemFragment = { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type JobsTableItemsQueryVariables = Exact<{
  filter?: InputMaybe<JobFilterInput>;
  orderBy?: InputMaybe<Array<JobOrderByInput> | JobOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type JobsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } | null> };

export type JobsTableNudgeJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobsTableNudgeJobMutation = { __typename: 'Mutation', nudgeJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableUpdateJobMutationVariables = Exact<{
  input: MutationUpdateJobInput;
}>;


export type JobsTableUpdateJobMutation = { __typename: 'Mutation', updateJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableCancelJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobsTableCancelJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableCleanJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobsTableCleanJobMutation = { __typename: 'Mutation', rerunAllTasksInJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableRestartJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobsTableRestartJobMutation = { __typename: 'Mutation', restartJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type AddMaterialDialogTagFragment = { __typename: 'Tag', id: string, name: string, organization?: { __typename: 'Organization', id: string } | null };

export type AddMaterialDialogMaterialRangeTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type AddMaterialDialogMaterialRangeTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type MaterialDownloadDataFragment = { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, downloadUrl: string, mediaType?: string | null };

export type MaterialDetailsFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, name?: string | null, nextActor: NextActor, priorityOrder?: any | null, state: MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, downloadUrl: string, mediaType?: string | null }> } }> };

export type MaterialDetailsQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MaterialDetailsQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, name?: string | null, nextActor: NextActor, priorityOrder?: any | null, state: MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, downloadUrl: string, mediaType?: string | null }> } }> } };

export type MaterialDetailsDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialDetailsDeleteMutation = { __typename: 'Mutation', deleteMaterial?: any | null };

export type MaterialDetailsDeleteDataObjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialDetailsDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type GetMaterialDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMaterialDetailsQuery = { __typename: 'Query', item: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, name?: string | null, nextActor: NextActor, priorityOrder?: any | null, state: MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, downloadUrl: string, mediaType?: string | null }> } }> } };

export type UpdateMaterialDetailsMutationVariables = Exact<{
  input: MutationUpdateMaterialInput;
}>;


export type UpdateMaterialDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, name?: string | null, nextActor: NextActor, priorityOrder?: any | null, state: MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, downloadUrl: string, mediaType?: string | null }> } }> } };

export type MaterialDetailsCreateTextureGroupMutationVariables = Exact<{
  input: MutationCreateTextureGroupInput;
}>;


export type MaterialDetailsCreateTextureGroupMutation = { __typename: 'Mutation', createTextureGroup: { __typename: 'TextureGroup', id: string } };

export type MaterialDetailsIconDataObjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialDetailsIconDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, thumbnailProcessingJob?: { __typename: 'Job', state: JobState } | null } };

export type MaterialDetailsCreateTextureSetMutationVariables = Exact<{
  input: MutationCreateTextureSetInput;
}>;


export type MaterialDetailsCreateTextureSetMutation = { __typename: 'Mutation', createTextureSet: { __typename: 'TextureSet', id: string } };

export type MaterialDetailsDeleteIconAssignmentMutationVariables = Exact<{
  dataObjectAssignmentId: Scalars['ID']['input'];
}>;


export type MaterialDetailsDeleteIconAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type MaterialDetailsCreateIconAssignmentMutationVariables = Exact<{
  objectId: Scalars['ID']['input'];
  dataObjectId: Scalars['ID']['input'];
}>;


export type MaterialDetailsCreateIconAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type MaterialExplorerItemFragment = { __typename: 'Material', legacyId: number, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', legacyId: number, id: string }, destination: { __typename: 'MaterialNode', legacyId: number, id: string } }> } | null, textureGroup?: { __typename: 'TextureGroup', legacyId: number, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null }> }> } | null };

export type MaterialExplorerInfoQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MaterialExplorerInfoQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, matExplorerMaterial?: { __typename: 'Material', id: string } | null, matExplorerTemplate?: { __typename: 'Template', id: string } | null } };

export type MaterialExplorerItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialExplorerItemQuery = { __typename: 'Query', material: { __typename: 'Material', legacyId: number, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', legacyId: number, id: string }, destination: { __typename: 'MaterialNode', legacyId: number, id: string } }> } | null, textureGroup?: { __typename: 'TextureGroup', legacyId: number, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null }> }> } | null } };

export type MaterialExplorerDataQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type MaterialExplorerDataQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, legacyId: number, matExplorerMaterial?: { __typename: 'Material', id: string } | null, matExplorerTemplate?: { __typename: 'Template', id: string, legacyId: number } | null } };

export type MaterialExplorerMaterialNodeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialExplorerMaterialNodeQuery = { __typename: 'Query', materialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null } };

export type MaterialExplorerTextureFragment = { __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null };

export type MaterialExplorerTextureRevisionFragment = { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } };

export type MaterialExplorerTextureSetRevisionFragment = { __typename: 'TextureSetRevision', id: string, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, state: DataObjectState } }> };

export type MaterialExplorerTextureSetFragment = { __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null }> };

export type MaterialExplorerTextureGroupFragment = { __typename: 'TextureGroup', legacyId: number, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null }> }> };

export type MaterialExplorerMaterialNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null };

export type MaterialExplorerMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', legacyId: number, id: string }, destination: { __typename: 'MaterialNode', legacyId: number, id: string } }> };

export type MaterialExplorerUpdateMaterialNodeMutationVariables = Exact<{
  input: MutationUpdateMaterialNodeInput;
}>;


export type MaterialExplorerUpdateMaterialNodeMutation = { __typename: 'Mutation', updateMaterialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } | null } };

export type MaterialExplorerCreateTextureRevisionMutationVariables = Exact<{
  input: MutationCreateTextureRevisionInput;
}>;


export type MaterialExplorerCreateTextureRevisionMutation = { __typename: 'Mutation', createTextureRevision: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: DataObjectState } } };

export type MaterialExplorerDeleteTextureRevisionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialExplorerDeleteTextureRevisionMutation = { __typename: 'Mutation', deleteTextureRevision?: any | null };

export type MaterialExplorerCreateTextureSetRevisionMutationVariables = Exact<{
  input: MutationCreateTextureSetRevisionInput;
}>;


export type MaterialExplorerCreateTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', legacyId: number, state: DataObjectState } }> } };

export type MaterialExplorerDeleteTextureSetRevisionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialExplorerDeleteTextureSetRevisionMutation = { __typename: 'Mutation', deleteTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type MaterialExportsGetDataObjectQueryVariables = Exact<{
  dataObjectLegacyId: Scalars['Int']['input'];
}>;


export type MaterialExportsGetDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', downloadUrl: string } };

export type MaterialExportsGetRenameDataObjectQueryVariables = Exact<{
  dataObjectLegacyId: Scalars['Int']['input'];
}>;


export type MaterialExportsGetRenameDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', originalFileName: string } };

export type MaterialOutputsDataFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', id: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, jpeg?: { __typename: 'DataObject', downloadUrl: string } | null, tiff?: { __typename: 'DataObject', downloadUrl: string } | null } }>, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null };

export type MaterialOutputTileableImageFragment = { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null };

export type MaterialOutputsDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialOutputsDataQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', id: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, jpeg?: { __typename: 'DataObject', downloadUrl: string } | null, tiff?: { __typename: 'DataObject', downloadUrl: string } | null } }>, revisions: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null } };

export type MaterialOutputsDeleteDataObjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialOutputsDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type MaterialRevisionsHistoryDialogMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, number: number, createdAt: string, hasCyclesMaterial: boolean, graphSchema?: string | null, comment?: string | null, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, matFileDataObject?: { __typename: 'DataObject', downloadUrl: string } | null };

export type MaterialRevisionsHistoryDialogMaterialRevisionQueryVariables = Exact<{
  materialId: Scalars['ID']['input'];
}>;


export type MaterialRevisionsHistoryDialogMaterialRevisionQuery = { __typename: 'Query', materialRevisions: Array<{ __typename: 'MaterialRevision', id: string, number: number, createdAt: string, hasCyclesMaterial: boolean, graphSchema?: string | null, comment?: string | null, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, matFileDataObject?: { __typename: 'DataObject', downloadUrl: string } | null } | null> };

export type MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutationVariables = Exact<{
  materialRevisionId: Scalars['ID']['input'];
}>;


export type MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutation = { __typename: 'Mutation', deleteMaterialRevision?: any | null };

export type MaterialsGridItemFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type GetMaterialForMaterialExplorerQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetMaterialForMaterialExplorerQuery = { __typename: 'Query', material: { __typename: 'Material', revisions: Array<{ __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', name: string, legacyId: number }> }>, textureGroup?: { __typename: 'TextureGroup', textureSets: Array<{ __typename: 'TextureSet', legacyId: number }> } | null } };

export type GetMaterialsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MaterialFilterInput>;
  orderBy?: InputMaybe<Array<MaterialOrderByInput> | MaterialOrderByInput>;
}>;


export type GetMaterialsQuery = { __typename: 'Query', materialsCount: number, materials: Array<{ __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type GetMaterialIdsQueryVariables = Exact<{
  filter?: InputMaybe<MaterialFilterInput>;
}>;


export type GetMaterialIdsQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string } | null> };

export type GetMaterialForCopyQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetMaterialForCopyQuery = { __typename: 'Query', material: { __typename: 'Material', legacyId: number, articleId?: string | null, name?: string | null, nextActor: NextActor, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, revisions: Array<{ __typename: 'MaterialRevision', id: string, number: number, graphSchema?: string | null, comment?: string | null, hasCyclesMaterial: boolean, nodes: Array<{ __typename: 'MaterialNode', id: string, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string } | null }>, connections: Array<{ __typename: 'MaterialConnection', id: string, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string }, source: { __typename: 'MaterialNode', id: string } }> }> } };

export type BatchUpdateMaterialsMutationVariables = Exact<{
  filter: MaterialFilterInput;
  state?: InputMaybe<MaterialState>;
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  nextActor?: InputMaybe<NextActor>;
}>;


export type BatchUpdateMaterialsMutation = { __typename: 'Mutation', batchUpdateMaterials: number };

export type CreateMaterialMutationVariables = Exact<{
  input: MutationCreateMaterialInput;
}>;


export type CreateMaterialMutation = { __typename: 'Mutation', createMaterial: { __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateMaterialMutationVariables = Exact<{
  input: MutationUpdateMaterialInput;
}>;


export type UpdateMaterialMutation = { __typename: 'Mutation', updateMaterial: { __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type CreateMaterialRevisionMutationVariables = Exact<{
  input: MutationCreateMaterialRevisionInput;
}>;


export type CreateMaterialRevisionMutation = { __typename: 'Mutation', createMaterialRevision: { __typename: 'MaterialRevision', id: string } };

export type CreateMaterialNodeMutationVariables = Exact<{
  input: MutationCreateMaterialNodeInput;
}>;


export type CreateMaterialNodeMutation = { __typename: 'Mutation', createMaterialNode: { __typename: 'MaterialNode', id: string } };

export type CreateMaterialConnectionMutationVariables = Exact<{
  input: MutationCreateMaterialConnectionInput;
}>;


export type CreateMaterialConnectionMutation = { __typename: 'Mutation', createMaterialConnection: { __typename: 'MaterialConnection', id: string } };

export type MaterialsGridDeleteThumbnailMutationVariables = Exact<{
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MaterialsGridDeleteThumbnailMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type MaterialsGridUpdateMaterialMutationVariables = Exact<{
  input: MutationUpdateMaterialInput;
}>;


export type MaterialsGridUpdateMaterialMutation = { __typename: 'Mutation', updateMaterial: { __typename: 'Material', id: string } };

export type MaterialsGridCreateDataObjectAssignmentMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type MaterialsGridCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type MaterialsGridMaterialRangeTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type MaterialsGridMaterialRangeTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type SelectMaterialItemFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectMaterialItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MaterialFilterInput>;
}>;


export type SelectMaterialItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type ModelDetailsFragment = { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, description?: string | null, comment?: string | null, name?: string | null, state: ModelState, public: boolean, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type ModelDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ModelDetailsQuery = { __typename: 'Query', item: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, description?: string | null, comment?: string | null, name?: string | null, state: ModelState, public: boolean, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateModelDetailsMutationVariables = Exact<{
  input: MutationUpdateModelInput;
}>;


export type UpdateModelDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, description?: string | null, comment?: string | null, name?: string | null, state: ModelState, public: boolean, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type DeleteModelDetailsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteModelDetailsMutation = { __typename: 'Mutation', deleteModel?: any | null };

export type ModelListItemFragment = { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: NextActor, state: ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type GetModelsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ModelFilterInput>;
  orderBy?: InputMaybe<Array<ModelOrderByInput> | ModelOrderByInput>;
}>;


export type GetModelsQuery = { __typename: 'Query', modelsCount: number, models: Array<{ __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: NextActor, state: ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type CreateModelMutationVariables = Exact<{
  input: MutationCreateModelInput;
}>;


export type CreateModelMutation = { __typename: 'Mutation', createModel: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: NextActor, state: ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateModelMutationVariables = Exact<{
  input: MutationUpdateModelInput;
}>;


export type UpdateModelMutation = { __typename: 'Mutation', updateModel: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: NextActor, state: ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type BatchUpdateModelsMutationVariables = Exact<{
  filter: ModelFilterInput;
  state?: InputMaybe<ModelState>;
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  nextActor?: InputMaybe<NextActor>;
}>;


export type BatchUpdateModelsMutation = { __typename: 'Mutation', batchUpdateModels: number };

export type SelectModelItemFragment = { __typename: 'Model', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectModelItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ModelFilterInput>;
}>;


export type SelectModelItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Model', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type OrganizationDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type OrganizationDetailsQuery = { __typename: 'Query', item: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type FeedbackCanvasItemFragment = { __typename: 'PictureRevision', id: string, legacyId: number, number: number, picture: { __typename: 'Picture', id: string }, pictureData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, drawingData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> };

export type FeedbackCanvasDataObjectFragment = { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type FeedbackCanvasItemUploadLinkQueryVariables = Exact<{
  dataObjectId: Scalars['ID']['input'];
}>;


export type FeedbackCanvasItemUploadLinkQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', signedUploadUrl: string } };

export type FeedbackCanvasItemQueryVariables = Exact<{
  pictureRevisionId: Scalars['ID']['input'];
}>;


export type FeedbackCanvasItemQuery = { __typename: 'Query', item: { __typename: 'PictureRevision', id: string, legacyId: number, number: number, picture: { __typename: 'Picture', id: string }, pictureData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, drawingData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> } };

export type FeedbackCanvasDeleteDataObjectAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FeedbackCanvasDeleteDataObjectAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type FeedbackCanvasUpdateDrawingDataMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
}>;


export type FeedbackCanvasUpdateDrawingDataMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type FeedbackCanvasCreateDataObjectAssignmentMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type FeedbackCanvasCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } } };

export type FeedbackCanvasTaskPinFragment = { __typename: 'TaskPin', id: string, x: number, y: number };

export type FeedbackCanvasTaskFragment = { __typename: 'Task', id: string, number: number, state: TaskState, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> };

export type FeedbackCanvasTaskQueryVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;


export type FeedbackCanvasTaskQuery = { __typename: 'Query', task: { __typename: 'Task', id: string, number: number, state: TaskState, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> } };

export type FeedbackCanvasCreateTaskPinMutationVariables = Exact<{
  input: MutationCreateTaskPinInput;
}>;


export type FeedbackCanvasCreateTaskPinMutation = { __typename: 'Mutation', createTaskPin: { __typename: 'TaskPin', id: string, x: number, y: number } };

export type FeedbackCanvasUpdateTaskPinPositionMutationVariables = Exact<{
  input: MutationUpdateTaskPinInput;
}>;


export type FeedbackCanvasUpdateTaskPinPositionMutation = { __typename: 'Mutation', updateTaskPin: { __typename: 'TaskPin', id: string, x: number, y: number } };

export type MovePictureDialogProjectFragment = { __typename: 'Project', id: string, legacyId: number, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, legacyId: number, name: string, number: number }> };

export type MovePictureProjectsQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilterInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByInput> | ProjectOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MovePictureProjectsQuery = { __typename: 'Query', projects: Array<{ __typename: 'Project', id: string, legacyId: number, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, legacyId: number, name: string, number: number }> } | null> };

export type PictureDetailsRevisionFragment = { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null };

export type PictureDetailsFragment = { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: NextActor, number: number, state: PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type PictureDetailsRenderJobFragment = { __typename: 'Job', id: string, legacyId: number, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, state: JobState, started?: string | null, createdBy?: { __typename: 'User', id: string, name: string } | null };

export type PictureDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureDetailsQuery = { __typename: 'Query', item: { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: NextActor, number: number, state: PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type PictureDetailsRenderJobsQueryVariables = Exact<{
  pictureLegacyId: Scalars['Int']['input'];
}>;


export type PictureDetailsRenderJobsQuery = { __typename: 'Query', jobAssignments: Array<{ __typename: 'JobAssignment', job: { __typename: 'Job', id: string, legacyId: number, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, state: JobState, started?: string | null, createdBy?: { __typename: 'User', id: string, name: string } | null } } | null> };

export type CreatePictureDetailsAssetAssignmentMutationVariables = Exact<{
  input: MutationCreatePictureAssetAssignmentInput;
}>;


export type CreatePictureDetailsAssetAssignmentMutation = { __typename: 'Mutation', createPictureAssetAssignment: { __typename: 'PictureAssetAssignment', id: string, legacyId: number } };

export type UpdatePictureDetailsMutationVariables = Exact<{
  input: MutationUpdatePictureInput;
}>;


export type UpdatePictureDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: NextActor, number: number, state: PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type PictureDetailsDeleteAssetAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureDetailsDeleteAssetAssignmentMutation = { __typename: 'Mutation', deletePictureAssetAssignment?: any | null };

export type PictureDetailsDeleteRevisionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureDetailsDeleteRevisionMutation = { __typename: 'Mutation', deletePictureRevision?: any | null };

export type DeletePictureDetailsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePictureDetailsMutation = { __typename: 'Mutation', deletePicture?: any | null };

export type PictureDetailsUpdatePictureMutationVariables = Exact<{
  input: MutationUpdatePictureInput;
}>;


export type PictureDetailsUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: NextActor, number: number, state: PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type PictureRevisionSelectorPictureFragment = { __typename: 'Picture', id: string, state: PictureState, latestRevision?: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } | null, organization: { __typename: 'Organization', id: string }, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> }> };

export type PictureRevisionSelectorRevisionFragment = { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> };

export type PictureRevisionSelectorItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureRevisionSelectorItemQuery = { __typename: 'Query', item: { __typename: 'Picture', id: string, state: PictureState, latestRevision?: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } | null, organization: { __typename: 'Organization', id: string }, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> }> } };

export type PictureRevisionCreateDataObjectAssignmentMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type PictureRevisionCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type PictureRevisionSelectorUpdatePictureMutationVariables = Exact<{
  input: MutationUpdatePictureInput;
}>;


export type PictureRevisionSelectorUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string, state: PictureState, latestRevision?: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } | null, organization: { __typename: 'Organization', id: string }, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> }> } };

export type PictureRevisionSelectorCreateRevisionMutationVariables = Exact<{
  input: MutationCreatePictureRevisionInput;
}>;


export type PictureRevisionSelectorCreateRevisionMutation = { __typename: 'Mutation', createPictureRevision: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } };

export type PictureArStatisticsQueryVariables = Exact<{
  filter: ArStatisticsFilterInput;
}>;


export type PictureArStatisticsQuery = { __typename: 'Query', arStatisticsItemsCount: number };

export type PictureSceneStatisticsQueryVariables = Exact<{
  filter: SceneStatisticsFilterInput;
}>;


export type PictureSceneStatisticsQuery = { __typename: 'Query', sceneStatisticsItemsCount: number };

export type PictureViewerPictureFragment = { __typename: 'Picture', id: string, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType }> }> };

export type PictureViewerPictureRevisionFragment = { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType }> };

export type PictureViewerDataObjectAssignmentFragment = { __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type PictureViewerPictureDataObjectThumbnailFragment = { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type PictureViewerPictureDataObjectFragment = { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type PictureViewerItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureViewerItemQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType }> }> } };

export type PictureViewerPictureDataObjectThumbnailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PictureViewerPictureDataObjectThumbnailQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type PicturesGridItemFragment = { __typename: 'Picture', id: string, legacyId: number, name: string, state: PictureState, priorityOrder: any, nextActor: NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, isLegacy: boolean, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> };

export type PicturesGridPictureDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PicturesGridPictureDataQuery = { __typename: 'Query', pictureRevision: { __typename: 'PictureRevision', pictureData: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string } }> } };

export type PicturesGridUpdateDataObjectMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
}>;


export type PicturesGridUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string } };

export type PicturesGridCreatePictureMutationVariables = Exact<{
  input: MutationCreatePictureInput;
}>;


export type PicturesGridCreatePictureMutation = { __typename: 'Mutation', createPicture: { __typename: 'Picture', id: string, legacyId: number, name: string, state: PictureState, priorityOrder: any, nextActor: NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, isLegacy: boolean, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type PicturesGridCreateTemplateMutationVariables = Exact<{
  input: MutationCreateTemplateInput;
}>;


export type PicturesGridCreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string } };

export type PicturesGridItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<PictureFilterInput>;
  orderBy?: InputMaybe<Array<PictureOrderByInput> | PictureOrderByInput>;
}>;


export type PicturesGridItemsQuery = { __typename: 'Query', picturesCount: number, pictures: Array<{ __typename: 'Picture', id: string, legacyId: number, name: string, state: PictureState, priorityOrder: any, nextActor: NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, isLegacy: boolean, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } | null> };

export type PicturesGridUpdatePictureMutationVariables = Exact<{
  input: MutationUpdatePictureInput;
}>;


export type PicturesGridUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string, legacyId: number, name: string, state: PictureState, priorityOrder: any, nextActor: NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, isLegacy: boolean, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type PicturesGridDeletePictureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PicturesGridDeletePictureMutation = { __typename: 'Mutation', deletePicture?: any | null };

export type PicturesGridCreatePictureRevisionMutationVariables = Exact<{
  input: MutationCreatePictureRevisionInput;
}>;


export type PicturesGridCreatePictureRevisionMutation = { __typename: 'Mutation', createPictureRevision: { __typename: 'PictureRevision', id: string } };

export type PicturesGridBatchUpdatePicturesMutationVariables = Exact<{
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  nextActor?: InputMaybe<NextActor>;
  filter: PictureFilterInput;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<PictureState>;
}>;


export type PicturesGridBatchUpdatePicturesMutation = { __typename: 'Mutation', batchUpdatePictures: number };

export type PicturesGridSetForPicturesQueryVariables = Exact<{
  setLegacyId: Scalars['Int']['input'];
}>;


export type PicturesGridSetForPicturesQuery = { __typename: 'Query', set: { __typename: 'Set', id: string } };

export type PicturesGridProjectForPicturesQueryVariables = Exact<{
  projectLegacyId: Scalars['Int']['input'];
}>;


export type PicturesGridProjectForPicturesQuery = { __typename: 'Query', project: { __typename: 'Project', id: string } };

export type PicturesGridUserFragment = { __typename: 'User', id: string, name: string };

export type PicturesGridVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type PicturesGridVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type ProjectTreeProjectsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type ProjectTreeProjectsQuery = { __typename: 'Query', projects: Array<{ __typename: 'Project', id: string, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null }> } | null> };

export type ProjectTreeCreateProjectMutationVariables = Exact<{
  input: MutationCreateProjectInput;
}>;


export type ProjectTreeCreateProjectMutation = { __typename: 'Mutation', createProject: { __typename: 'Project', id: string, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null }> } };

export type ProjectTreeDeleteProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectTreeDeleteProjectMutation = { __typename: 'Mutation', deleteProject?: any | null };

export type ProjectTreeDeleteSetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectTreeDeleteSetMutation = { __typename: 'Mutation', deleteSet?: any | null };

export type ProjectTreeSetFragment = { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type ProjectTreeProjectFragment = { __typename: 'Project', id: string, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null }> };

export type ProjectTreeSetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectTreeSetQuery = { __typename: 'Query', set: { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type ProjectTreeUpdateProjectMutationVariables = Exact<{
  input: MutationUpdateProjectInput;
}>;


export type ProjectTreeUpdateProjectMutation = { __typename: 'Mutation', updateProject: { __typename: 'Project', id: string, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null }> } };

export type ProjectTreeUpdateSetMutationVariables = Exact<{
  input: MutationUpdateSetInput;
}>;


export type ProjectTreeUpdateSetMutation = { __typename: 'Mutation', updateSet: { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type ProjectTreeCreateSetMutationVariables = Exact<{
  input: MutationCreateSetInput;
}>;


export type ProjectTreeCreateSetMutation = { __typename: 'Mutation', createSet: { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type ProjectTreePicturesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<PictureFilterInput>;
}>;


export type ProjectTreePicturesQuery = { __typename: 'Query', pictures: Array<{ __typename: 'Picture', id: string } | null> };

export type ProjectTreeUpdatePictureMutationVariables = Exact<{
  input: MutationUpdatePictureInput;
}>;


export type ProjectTreeUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string } };

export type ProjectTreeUserFragment = { __typename: 'User', id: string, name: string };

export type ProjectTreeVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectTreeVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type RenderJobViewerDialogJobQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type RenderJobViewerDialogJobQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, output?: any | null } };

export type ScanningActionBarTagFragment = { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null };

export type ScanningActionBarTagQueryVariables = Exact<{
  filter: TagFilterInput;
}>;


export type ScanningActionBarTagQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type MaterialWithRangeTagsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MaterialWithRangeTagsQuery = { __typename: 'Query', material: { __typename: 'Material', tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type OrganizationsWithScanningUrlQueryVariables = Exact<{
  organizationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type OrganizationsWithScanningUrlQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, defaultScannerUrl?: string | null } | null> };

export type AddTagMutationVariables = Exact<{
  input: MutationCreateTagInput;
}>;


export type AddTagMutation = { __typename: 'Mutation', createTag: { __typename: 'Tag', id: string, legacyId: number } };

export type TagAssignmentsItemFragment = { __typename: 'TagAssignment', id: string, legacyId: number, contentTypeModel: ContentTypeModel, assignedTo?: { __typename: 'Asset', id: string, legacyId: number, name?: string | null } | { __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Model', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Picture', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Project', id: string, legacyId: number, altName: string } | { __typename: 'Set', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type TagAssignmentsLoadPageQueryVariables = Exact<{
  filter?: InputMaybe<TagAssignmentFilterInput>;
  take: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
}>;


export type TagAssignmentsLoadPageQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'TagAssignment', id: string, legacyId: number, contentTypeModel: ContentTypeModel, assignedTo?: { __typename: 'Asset', id: string, legacyId: number, name?: string | null } | { __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Model', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Picture', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Project', id: string, legacyId: number, altName: string } | { __typename: 'Set', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type TagAssignmentsDeleteAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TagAssignmentsDeleteAssignmentMutation = { __typename: 'Mutation', deleteTagAssignment?: any | null };

export type TagDetailsItemFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string, name: string, email: string }, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type GetTagDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTagDetailsQuery = { __typename: 'Query', item: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string, name: string, email: string }, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type UpdateTagDetailsMutationVariables = Exact<{
  input: MutationUpdateTagInput;
}>;


export type UpdateTagDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string, name: string, email: string }, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type TagsGridItemFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type GetTagsGridItemsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilterInput>;
  order?: InputMaybe<Array<TagOrderByInput> | TagOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTagsGridItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export type TagsTableItemFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, assignmentsCount: number, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type GetTagsTableItemsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilterInput>;
  order?: InputMaybe<Array<TagOrderByInput> | TagOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTagsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, assignmentsCount: number, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export type EditTaskTypeDialogFragment = { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string };

export type TaskTypesTableItemFragment = { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string };

export type TaskTypesTableItemsQueryVariables = Exact<{
  filter?: InputMaybe<JobTaskTypeFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TaskTypesTableItemsQuery = { __typename: 'Query', itemsCount: number, items: Array<{ __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string } | null> };

export type TaskTypesTableUpdateTaskTypeMutationVariables = Exact<{
  input: MutationUpdateJobTaskTypeInput;
}>;


export type TaskTypesTableUpdateTaskTypeMutation = { __typename: 'Mutation', updateJobTaskType: { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string } };

export type TaskTypesTableCreateTaskTypeMutationVariables = Exact<{
  input: MutationCreateJobTaskTypeInput;
}>;


export type TaskTypesTableCreateTaskTypeMutation = { __typename: 'Mutation', createJobTaskType: { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string } };

export type TaskTypesTableDeleteTaskTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TaskTypesTableDeleteTaskTypeMutation = { __typename: 'Mutation', deleteJobTaskType?: any | null };

export type TaskDetailsFragment = { __typename: 'Task', id: string, legacyId: number, public: boolean, comments: Array<{ __typename: 'TaskComment', id: string, createdAt?: string | null, text: string, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null }> };

export type TaskDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TaskDetailsQuery = { __typename: 'Query', item: { __typename: 'Task', id: string, legacyId: number, public: boolean, comments: Array<{ __typename: 'TaskComment', id: string, createdAt?: string | null, text: string, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null }> } };

export type UpdateTaskDetailsMutationVariables = Exact<{
  input: MutationUpdateTaskInput;
}>;


export type UpdateTaskDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Task', id: string, legacyId: number, public: boolean, comments: Array<{ __typename: 'TaskComment', id: string, createdAt?: string | null, text: string, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null }> } };

export type TaskDetailsCreateCommentMutationVariables = Exact<{
  input: MutationCreateTaskCommentInput;
}>;


export type TaskDetailsCreateCommentMutation = { __typename: 'Mutation', createTaskComment: { __typename: 'TaskComment', id: string } };

export type TaskDetailsDeleteCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TaskDetailsDeleteCommentMutation = { __typename: 'Mutation', deleteTaskComment?: any | null };

export type TaskListItemFragment = { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> };

export type TaskListQueryVariables = Exact<{
  objectId: Scalars['ID']['input'];
  contentTypeModel: ContentTypeModel;
}>;


export type TaskListQuery = { __typename: 'Query', tasks: Array<{ __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> } | null> };

export type UpdateTaskListItemMutationVariables = Exact<{
  input: MutationUpdateTaskInput;
}>;


export type UpdateTaskListItemMutation = { __typename: 'Mutation', updateTask: { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> } };

export type AddTaskListItemMutationVariables = Exact<{
  input: MutationCreateTaskInput;
}>;


export type AddTaskListItemMutation = { __typename: 'Mutation', createTask: { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> } };

export type AddCommentToTaskListItemMutationVariables = Exact<{
  input: MutationCreateTaskCommentInput;
}>;


export type AddCommentToTaskListItemMutation = { __typename: 'Mutation', createTaskComment: { __typename: 'TaskComment', id: string } };

export type AssignUserDialogUserFragment = { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type AssignUserDialogVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AssignUserDialogVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type AssignUserFragment = { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type UserDetailsFragment = { __typename: 'User', id: string, legacyId: number, dateJoined: string, email: string, emailNotifications: boolean, firstName?: string | null, isActive: boolean, isStaff: boolean, isSuperuser: boolean, lastName?: string | null, name: string, visibleInFilters: boolean, systemRole?: SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } }> };

export type UserDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserDetailsQuery = { __typename: 'Query', item: { __typename: 'User', id: string, legacyId: number, dateJoined: string, email: string, emailNotifications: boolean, firstName?: string | null, isActive: boolean, isStaff: boolean, isSuperuser: boolean, lastName?: string | null, name: string, visibleInFilters: boolean, systemRole?: SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } }> } };

export type UpdateUserDetailsMutationVariables = Exact<{
  input: MutationUpdateUserInput;
}>;


export type UpdateUserDetailsMutation = { __typename: 'Mutation', item: { __typename: 'User', id: string, legacyId: number, dateJoined: string, email: string, emailNotifications: boolean, firstName?: string | null, isActive: boolean, isStaff: boolean, isSuperuser: boolean, lastName?: string | null, name: string, visibleInFilters: boolean, systemRole?: SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } }> } };

export type UserDetailsCreateMembershipMutationVariables = Exact<{
  input: MutationCreateMembershipInput;
}>;


export type UserDetailsCreateMembershipMutation = { __typename: 'Mutation', item: { __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type UserDetailsDeleteMembershipMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserDetailsDeleteMembershipMutation = { __typename: 'Mutation', item?: any | null };

export type UserListItemFragment = { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type GetUsersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<UserFilterInput>;
}>;


export type GetUsersQuery = { __typename: 'Query', usersCount: number, users: Array<{ __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type CreateUserMutationVariables = Exact<{
  input: MutationCreateUserInput;
}>;


export type CreateUserMutation = { __typename: 'Mutation', createUser: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type InviteUserMutationVariables = Exact<{
  input: MutationInviteUserInput;
}>;


export type InviteUserMutation = { __typename: 'Mutation', inviteUser: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type CreateUserAssignmentMutationVariables = Exact<{
  input: MutationCreateUserAssignmentInput;
}>;


export type CreateUserAssignmentMutation = { __typename: 'Mutation', createUserAssignment: { __typename: 'UserAssignment', id: string, state?: UserAssignmentState | null, user: { __typename: 'User', firstName?: string | null, lastName?: string | null } } };

export type UpdateUserMutationVariables = Exact<{
  input: MutationUpdateUserInput;
}>;


export type UpdateUserMutation = { __typename: 'Mutation', updateUser: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type DeleteUserAssignmentsMutationVariables = Exact<{
  input: MutationDeleteUserAssignmentsInput;
}>;


export type DeleteUserAssignmentsMutation = { __typename: 'Mutation', deleteUserAssignments: number };

export type RenderJobTaskJobFarmInstanceQueryVariables = Exact<{
  hwFingerprint: Scalars['String']['input'];
}>;


export type RenderJobTaskJobFarmInstanceQuery = { __typename: 'Query', jobFarmInstances: Array<{ __typename: 'JobFarmInstance', otherInfo?: any | null } | null> };

export type VisualPinTaskFragment = { __typename: 'Task', id: string, number: number, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> };

export type VisualPinTaskPinFragment = { __typename: 'TaskPin', id: string, x: number, y: number };

export type ScanRegionMaterialFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type ScanRegionMaterialQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ScanRegionMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, state: MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type BackendArGenerationTemplateRevisionFragment = { __typename: 'TemplateRevision', id: string, graph?: any | null, template: { __typename: 'Template', id: string, organizationId: string }, gltfAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string }> } }> };

export type GetTemplateRevisionForBackendArGenerationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  assignmentKey: StringFilterInput;
}>;


export type GetTemplateRevisionForBackendArGenerationQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, graph?: any | null, template: { __typename: 'Template', id: string, organizationId: string }, gltfAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string }> } }> } };

export type DeleteDataObjectForBackendArGenerationMutationVariables = Exact<{
  dataObjectId: Scalars['ID']['input'];
}>;


export type DeleteDataObjectForBackendArGenerationMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type CreateDataObjectAssignmentForBackendArGenerationMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type CreateDataObjectAssignmentForBackendArGenerationMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type PerformLoginMutationVariables = Exact<{
  input: MutationLoginInput;
}>;


export type PerformLoginMutation = { __typename: 'Mutation', login: { __typename: 'LoginResult', token: string, user: { __typename: 'User', id: string, legacyId: number } } };

export type AuthenticatedUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AuthenticatedUserQuery = { __typename: 'Query', item: { __typename: 'User', id: string, email: string, isStaff: boolean, isSuperuser: boolean, name: string, role?: SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: OrganizationType | null } }> } };

export type LoginSendPasswordResetEmailMutationVariables = Exact<{
  input: MutationSendPasswordResetEmailInput;
}>;


export type LoginSendPasswordResetEmailMutation = { __typename: 'Mutation', sendPasswordResetEmail: boolean };

export type LoginResetPasswordMutationVariables = Exact<{
  input: MutationResetPasswordInput;
}>;


export type LoginResetPasswordMutation = { __typename: 'Mutation', resetPassword: string };

export type MaterialsPageUserFragment = { __typename: 'User', id: string, name: string };

export type MaterialsPageScannerUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type MaterialsPageScannerUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type MaterialsPageVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type MaterialsPageVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type ModelsPageUserFragment = { __typename: 'User', id: string, name: string };

export type ModelsPageVisibleUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ModelsPageVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type GetOrganizationListItemsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetOrganizationListItemsQuery = { __typename: 'Query', organizationsCount: number, organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type OrganizationListItemFragment = { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type FindOrganizationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type FindOrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type GetOrganizationDetailsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
}>;


export type GetOrganizationDetailsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } | null> };

export type CreateOrganizationMutationVariables = Exact<{
  input: MutationCreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename: 'Mutation', createOrganization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type UpdateOrganizationMutationVariables = Exact<{
  input: MutationUpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = { __typename: 'Mutation', item: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOrganizationMutation = { __typename: 'Mutation', deleteOrganization?: any | null };

export type OrganizationDetailsFragment = { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> };

export type GetSceneStatisticsQueryVariables = Exact<{
  filter?: InputMaybe<SceneStatisticsFilterInput>;
}>;


export type GetSceneStatisticsQuery = { __typename: 'Query', sceneStatisticsItemsCount: number };

export type CreateSceneStatisticsMutationVariables = Exact<{
  input: MutationCreateSceneStatisticsInput;
}>;


export type CreateSceneStatisticsMutation = { __typename: 'Mutation', createSceneStatistics: { __typename: 'SceneStatistics', id: string } };

export type SceneStatisticsDetailsFragment = { __typename: 'SceneStatistics', id: string, created?: string | null, scene?: { __typename: 'Scene', id: string } | null };

export type GetArStatisticsQueryVariables = Exact<{
  filter?: InputMaybe<ArStatisticsFilterInput>;
}>;


export type GetArStatisticsQuery = { __typename: 'Query', arStatisticsItemsCount: number };

export type CreateArStatisticsMutationVariables = Exact<{
  input: MutationCreateArStatisticsInput;
}>;


export type CreateArStatisticsMutation = { __typename: 'Mutation', createArStatistics: { __typename: 'ArStatistics', id: string } };

export type ArStatisticsDetailsFragment = { __typename: 'ArStatistics', id: string, created?: string | null, sceneRevision?: { __typename: 'SceneRevision', id: string } | null };

export type AssetStatisticsFragment = { __typename: 'AssetHistory', id: string, nextActor: NextActor, state: AssetState, asset?: { __typename: 'Asset', id: string } | null, object?: { __typename: 'Asset', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type MaterialStatisticsFragment = { __typename: 'MaterialHistory', id: string, nextActor: NextActor, state: MaterialState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, material?: { __typename: 'Material', id: string } | null, object?: { __typename: 'Material', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type ModelStatisticsFragment = { __typename: 'ModelHistory', id: string, nextActor: NextActor, state: ModelState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, model?: { __typename: 'Model', id: string } | null, object?: { __typename: 'Model', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type PictureStatisticsFragment = { __typename: 'PictureHistory', id: string, nextActor: NextActor, state: PictureState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, object?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, picture?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type StatisticsAssetsHistoryQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type StatisticsAssetsHistoryQuery = { __typename: 'Query', assetHistoryItems: Array<{ __typename: 'AssetHistory', id: string, nextActor: NextActor, state: AssetState, asset?: { __typename: 'Asset', id: string } | null, object?: { __typename: 'Asset', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousAssetStatesQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type StatisticsPreviousAssetStatesQuery = { __typename: 'Query', previousAssetHistoryItems: Array<{ __typename: 'AssetHistory', id: string, nextActor: NextActor, state: AssetState, asset?: { __typename: 'Asset', id: string } | null, object?: { __typename: 'Asset', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsMaterialsHistoryQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type StatisticsMaterialsHistoryQuery = { __typename: 'Query', materialHistoryItems: Array<{ __typename: 'MaterialHistory', id: string, nextActor: NextActor, state: MaterialState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, material?: { __typename: 'Material', id: string } | null, object?: { __typename: 'Material', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousMaterialStatesQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type StatisticsPreviousMaterialStatesQuery = { __typename: 'Query', previousMaterialHistoryItems: Array<{ __typename: 'MaterialHistory', id: string, nextActor: NextActor, state: MaterialState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, material?: { __typename: 'Material', id: string } | null, object?: { __typename: 'Material', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsModelsHistoryQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type StatisticsModelsHistoryQuery = { __typename: 'Query', modelHistoryItems: Array<{ __typename: 'ModelHistory', id: string, nextActor: NextActor, state: ModelState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, model?: { __typename: 'Model', id: string } | null, object?: { __typename: 'Model', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousModelStatesQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type StatisticsPreviousModelStatesQuery = { __typename: 'Query', previousModelHistoryItems: Array<{ __typename: 'ModelHistory', id: string, nextActor: NextActor, state: ModelState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, model?: { __typename: 'Model', id: string } | null, object?: { __typename: 'Model', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPicturesHistoryQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type StatisticsPicturesHistoryQuery = { __typename: 'Query', pictureHistoryItems: Array<{ __typename: 'PictureHistory', id: string, nextActor: NextActor, state: PictureState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, object?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, picture?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousPictureStatesQueryVariables = Exact<{
  fromDate: Scalars['DateTime']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type StatisticsPreviousPictureStatesQuery = { __typename: 'Query', previousPictureHistoryItems: Array<{ __typename: 'PictureHistory', id: string, nextActor: NextActor, state: PictureState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, object?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, picture?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type GetTagsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTagsQuery = { __typename: 'Query', tagsCount: number, tags: Array<{ __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export type GetTagsListQueryVariables = Exact<{
  filter?: InputMaybe<TagFilterInput>;
  order?: InputMaybe<Array<TagOrderByInput> | TagOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTagsListQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export type TagListItemFragment = { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type CreateTagMutationVariables = Exact<{
  input: MutationCreateTagInput;
}>;


export type CreateTagMutation = { __typename: 'Mutation', createTag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTagMutation = { __typename: 'Mutation', deleteTag?: any | null };

export type ChangeTagMutationVariables = Exact<{
  input: MutationUpdateTagInput;
}>;


export type ChangeTagMutation = { __typename: 'Mutation', updateTag: { __typename: 'Tag', id: string } };

export type GetOrganizationByLegacyIdQueryVariables = Exact<{
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetOrganizationByLegacyIdQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string } };

export type DataObjectDetailsForBatchDownloadServiceFragment = { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null };

export type MaterialBatchOperationFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null };

export type BatchMaterialRenderDetailsFragment = { __typename: 'Material', dataObjectAssignments?: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null };

export type GetBatchMaterialRenderDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dataObjectAssignmentFilter?: InputMaybe<DataObjectAssignmentFilterInput>;
  includeAssignments?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBatchMaterialRenderDetailsQuery = { __typename: 'Query', material: { __typename: 'Material', dataObjectAssignments?: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null } };

export type MaterialBatchOperationMaterialWithMaterialRangeTagsQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type MaterialBatchOperationMaterialWithMaterialRangeTagsQuery = { __typename: 'Query', material: { __typename: 'Material', tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type ImageDownloadDetailsForBatchDownloadServiceFragment = { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null, tiff?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegHigh?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegMedium?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegLow?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegTiny?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null };

export type GetImageDownloadDetailsForBatchDownloadServiceQueryVariables = Exact<{
  materialId: Scalars['ID']['input'];
  assignmentType: DataObjectAssignmentType;
}>;


export type GetImageDownloadDetailsForBatchDownloadServiceQuery = { __typename: 'Query', material: { __typename: 'Material', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null, tiff?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegHigh?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegMedium?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegLow?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegTiny?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null } }> } };

export type ResolveServicePictureRevisionFragment = { __typename: 'PictureRevision', id: string, legacyId: number, number: number, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }> };

export type ResolveServicePictureFragment = { __typename: 'Picture', id: string, legacyId: number, number: number, state: PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, number: number } | null, revisions: Array<{ __typename: 'PictureRevision', id: string, legacyId: number, number: number, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }> }>, organization: { __typename: 'Organization', id: string, legacyId: number }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } | null };

export type ResolveServiceTemplateFragment = { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> };

export type ResolveServiceTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ResolveServiceTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } };

export type ResolveServicePictureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ResolveServicePictureQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string, legacyId: number, number: number, state: PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, number: number } | null, revisions: Array<{ __typename: 'PictureRevision', id: string, legacyId: number, number: number, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }> }>, organization: { __typename: 'Organization', id: string, legacyId: number }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } | null } };

export type ResolveServiceCreateTemplateMutationVariables = Exact<{
  input: MutationCreateTemplateInput;
}>;


export type ResolveServiceCreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } };

export type ResolveServiceUpdateTemplateMutationVariables = Exact<{
  input: MutationUpdateTemplateInput;
}>;


export type ResolveServiceUpdateTemplateMutation = { __typename: 'Mutation', updateTemplate: { __typename: 'Template', id: string } };

export type ResolveServicePictureFromLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type ResolveServicePictureFromLegacyIdQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string } };

export type TasksServiceTaskPinFragment = { __typename: 'TaskPin', id: string, x: number, y: number };

export type TasksServiceTaskFragment = { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }>, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> };

export type TasksServiceTasksQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  contentTypeModel: ContentTypeModel;
}>;


export type TasksServiceTasksQuery = { __typename: 'Query', tasks: Array<{ __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }>, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> } | null> };

export type QueryThumbnailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  resolution: DownloadResolution;
}>;


export type QueryThumbnailQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, thumbnailProcessingJob?: { __typename: 'Job', state: JobState } | null } };

export type ThumbnailAvailableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ThumbnailAvailableQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnailAvailable: boolean } };

export type PriceMappingPriceGraphFragment = { __typename: 'PriceGraph', id: string, state: PriceGraphState, templateId: string, revisionNumber: number, graph: any };

export type PriceMappingPriceFragment = { __typename: 'Price', currency: Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type PriceMappingIdFromCatalogToPriceGraphFragment = { __typename: 'IdFromCatalogToPriceGraph', priceGraphUuid: string, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type PriceMappingGetPriceGraphsQueryVariables = Exact<{
  filter: PriceGraphFilterInput;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PriceMappingGetPriceGraphsQuery = { __typename: 'Query', priceGraphs: Array<{ __typename: 'PriceGraph', id: string, state: PriceGraphState, templateId: string, revisionNumber: number, graph: any } | null> };

export type PriceMappingGetPriceQueryVariables = Exact<{
  filter: PriceFilterInput;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PriceMappingGetPriceQuery = { __typename: 'Query', prices: Array<{ __typename: 'Price', currency: Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export type PriceMappingOrganizationIdFromTemplateIdQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;


export type PriceMappingOrganizationIdFromTemplateIdQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationId: string } };

export type PriceMappingCreatePriceGraphMutationVariables = Exact<{
  input: MutationCreatePriceGraphInput;
}>;


export type PriceMappingCreatePriceGraphMutation = { __typename: 'Mutation', createPriceGraph: { __typename: 'PriceGraph', id: string, state: PriceGraphState, graph: any, revisionNumber: number } };

export type PriceMappingUpdatePriceGraphMutationVariables = Exact<{
  input: MutationUpdatePriceGraphInput;
}>;


export type PriceMappingUpdatePriceGraphMutation = { __typename: 'Mutation', updatePriceGraph: { __typename: 'PriceGraph', state: PriceGraphState, graph: any, revisionNumber: number } };

export type PriceMappingDeletePriceGraphMutationVariables = Exact<{
  priceGraphId: Scalars['ID']['input'];
}>;


export type PriceMappingDeletePriceGraphMutation = { __typename: 'Mutation', deletePriceGraph?: any | null };

export type GetPriceGraphsForCatalogIdsQueryVariables = Exact<{
  organizationUuid: Scalars['ID']['input'];
  originalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  take: Scalars['Int']['input'];
}>;


export type GetPriceGraphsForCatalogIdsQuery = { __typename: 'Query', idFromCatalogToPriceGraphItems: Array<{ __typename: 'IdFromCatalogToPriceGraph', priceGraphUuid: string, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export type PricingHelperPriceGraphFragment = { __typename: 'PriceGraph', id: string, state: PriceGraphState, templateId: string, revisionNumber: number, graph: any };

export type PricingHelperPriceFragment = { __typename: 'Price', currency: Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type PricingHelperGetPricesQueryVariables = Exact<{
  filter: PriceFilterInput;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PricingHelperGetPricesQuery = { __typename: 'Query', prices: Array<{ __typename: 'Price', currency: Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export type PricingHelperGetPriceGraphsQueryVariables = Exact<{
  filter: PriceGraphFilterInput;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PricingHelperGetPriceGraphsQuery = { __typename: 'Query', priceGraphs: Array<{ __typename: 'PriceGraph', id: string, state: PriceGraphState, templateId: string, revisionNumber: number, graph: any } | null> };

export type PricingHelperDeletePricesMutationVariables = Exact<{
  originalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
}>;


export type PricingHelperDeletePricesMutation = { __typename: 'Mutation', deletePrices?: any | null };

export type PricingHelperDeleteIdFromCustomerToPriceGraphMutationVariables = Exact<{
  priceGraphId: Scalars['ID']['input'];
}>;


export type PricingHelperDeleteIdFromCustomerToPriceGraphMutation = { __typename: 'Mutation', deleteIdFromCustomerToPriceGraph?: any | null };

export type PricingHelperCreateOrUpdatePricesMutationVariables = Exact<{
  prices: Array<PriceInput> | PriceInput;
  organizationId: Scalars['ID']['input'];
}>;


export type PricingHelperCreateOrUpdatePricesMutation = { __typename: 'Mutation', createOrUpdatePrices?: any | null };

export type PricingServiceGetTemplateQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;


export type PricingServiceGetTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationId: string } };

export type DataObjectDetailsForDataObjectInspectorFragment = { __typename: 'DataObject', id: string, legacyId: number, createdAt?: string | null, width?: number | null, height?: number | null, imageColorSpace?: ImageColorSpace | null, size?: any | null, originalFileName: string, createdBy?: { __typename: 'User', name: string } | null };

export type GetDataObjectDetailsForDataObjectReferenceInspectorQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetDataObjectDetailsForDataObjectReferenceInspectorQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, createdAt?: string | null, width?: number | null, height?: number | null, imageColorSpace?: ImageColorSpace | null, size?: any | null, originalFileName: string, createdBy?: { __typename: 'User', name: string } | null } };

export type HdriDetailsForHdriLightInspectorFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null };

export type GetHdriDetailsForHdriLightInspectorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHdriDetailsForHdriLightInspectorQuery = { __typename: 'Query', hdris: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | null> };

export type DataObjectDetailsForImageInspectorFragment = { __typename: 'DataObject', id: string };

export type GetDataObjectForImageInspectorQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetDataObjectForImageInspectorQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string } };

export type MaterialAssignmentDetailsForMaterialAssignmentInspectorFragment = { __typename: 'MaterialRevision', material: { __typename: 'Material', galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type GetMaterialAssignmentDetailsForMaterialAssignmentInspectorQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetMaterialAssignmentDetailsForMaterialAssignmentInspectorQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', material: { __typename: 'Material', galleryImage?: { __typename: 'DataObject', id: string } | null } } };

export type MaterialDetailsForMaterialReferenceInspectorFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type MaterialRevisionDetailsForMaterialReferenceInspectorFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type GetMaterialRevisionDetailsForMaterialReferenceInspectorQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetMaterialRevisionDetailsForMaterialReferenceInspectorQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } } };

export type TemplateDetailsForTemplateInstanceInspectorFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, number: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, number: number } | null };

export type TemplateRevisionDetailsForTemplateInstanceInspectorFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, number: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, number: number } | null } };

export type GetTemplateRevisionDetailsForTemplateInstanceInspectorQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetTemplateRevisionDetailsForTemplateInstanceInspectorQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, number: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, number: number } | null } } };

export type TemplateAddMaterialItemFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type TemplateAddMaterialItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MaterialFilterInput>;
}>;


export type TemplateAddMaterialItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } | null> };

export type TemplateAddTemplateItemFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null };

export type TemplateAddTemplateItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<TemplateFilterInput>;
}>;


export type TemplateAddTemplateItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null } | null> };

export type GetTemplateRevisionDetailsTemplateAddSceneQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetTemplateRevisionDetailsTemplateAddSceneQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } };

export type TemplateDetailsForTemplateEditorFragment = { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> };

export type GetTemplateDetailsForTemplateEditorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForTemplateEditorQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } };

export type PictureDetailsForTemplateEditorFragment = { __typename: 'Picture', id: string, legacyId: number, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } | null, organization: { __typename: 'Organization', id: string } };

export type GetPictureDetailsForTemplateEditorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPictureDetailsForTemplateEditorQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string, legacyId: number, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } | null, organization: { __typename: 'Organization', id: string } } };

export type TemplateRevisionDetailsForTemplateEditorFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type GetTemplateRevisionDetailsForTemplateEditorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTemplateRevisionDetailsForTemplateEditorQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } };

export type GetLatestTemplateRevisionDetailsForTemplateEditorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetLatestTemplateRevisionDetailsForTemplateEditorQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } | null } } };

export type GetTemplateRevisionsDetailsForTemplateEditorQueryVariables = Exact<{
  filter?: InputMaybe<TemplateRevisionFilterInput>;
}>;


export type GetTemplateRevisionsDetailsForTemplateEditorQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } | null> };

export type CreateTemplateRevisionForTemplateEditorMutationVariables = Exact<{
  input: MutationCreateTemplateRevisionInput;
}>;


export type CreateTemplateRevisionForTemplateEditorMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } };

export type CreateTemplateForTemplateEditorMutationVariables = Exact<{
  input: MutationCreateTemplateInput;
}>;


export type CreateTemplateForTemplateEditorMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } };

export type GetJobAssignmentsDetailsForTemplateImageViewerQueryVariables = Exact<{
  filter: JobAssignmentFilterInput;
}>;


export type GetJobAssignmentsDetailsForTemplateImageViewerQuery = { __typename: 'Query', jobAssignments: Array<{ __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: JobState, progress?: number | null, message?: string | null } } | null> };

export type GetJobDetailsForTemplateImageViewerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetJobDetailsForTemplateImageViewerQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: JobState, progress?: number | null, message?: string | null } };

export type GetDataObjectDetailsForTemplateImageViewerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
}>;


export type GetDataObjectDetailsForTemplateImageViewerQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, imageColorSpace?: ImageColorSpace | null, imageColorProfile?: string | null, width?: number | null, height?: number | null, size?: any | null, originalFileName: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type JobAssignmentDetailsForTemplateImageViewerFragment = { __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: JobState, progress?: number | null, message?: string | null } };

export type CreateJobAssignmentForTemplateImageViewerMutationVariables = Exact<{
  input: MutationCreateJobAssignmentInput;
}>;


export type CreateJobAssignmentForTemplateImageViewerMutation = { __typename: 'Mutation', createJobAssignment: { __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: JobState, progress?: number | null, message?: string | null } } };

export type DeleteJobForTemplateImageViewerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteJobForTemplateImageViewerMutation = { __typename: 'Mutation', deleteJob?: any | null };

export type JobDetailsForTemplateImageViewerFragment = { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: JobState, progress?: number | null, message?: string | null };

export type CreateJobForTemplateImageViewerMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type CreateJobForTemplateImageViewerMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: JobState, progress?: number | null, message?: string | null } };

export type CancelJobForTemplateImageViewerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CancelJobForTemplateImageViewerMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export type TemplateDetailsForTemplateTreeAddFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null };

export type GetTemplateDetailsForTemplateTreeAddQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForTemplateTreeAddQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null } };

export type MaterialDetailsForTemplateTreeAddFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type GetMaterialDetailsForTemplateTreeAddQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMaterialDetailsForTemplateTreeAddQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type TemplateRevisionDetailsForTemplateTreeItemFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, type: TemplateType, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null }> } };

export type GetTemplateRevisionDetailsForTemplateTreeItemQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type GetTemplateRevisionDetailsForTemplateTreeItemQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, type: TemplateType, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null }> } } | null> };

export type MaterialDetailsForTemplateTreeItemFragment = { __typename: 'Material', id: string, legacyId: number };

export type MaterialRevisionDetailsForTemplateTreeItemFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number } };

export type GetMaterialRevisionDetailsForTemplateTreeItemQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetMaterialRevisionDetailsForTemplateTreeItemQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number } } };

export type DataObjectDetailsForDataObjectBatchApiCallFragment = { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string }> };

export type GetDataObjectDetailsForDataObjectBatchApiCallQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type GetDataObjectDetailsForDataObjectBatchApiCallQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } | null> };

export type TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null };

export type GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } | null> };

export type GetMaterialsDetailsForSceneManagerServiceQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MaterialFilterInput>;
  orderBy?: InputMaybe<Array<MaterialOrderByInput> | MaterialOrderByInput>;
}>;


export type GetMaterialsDetailsForSceneManagerServiceQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, legacyId: number, articleId?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } | null> };

export type GetHdriDetailsForSceneManagerServiceQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetHdriDetailsForSceneManagerServiceQuery = { __typename: 'Query', hdri: { __typename: 'Hdri', dataObject?: { __typename: 'DataObject', mediaType?: string | null, bucketName: string, objectName: string, originalFileName: string } | null } };

export type DataObjectAssignmentDetailsForTemplateImageViewerFragment = { __typename: 'DataObjectAssignment', id: string };

export type CreateDataObjectAssignmentForSceneManagerServiceMutationVariables = Exact<{
  input: MutationCreateDataObjectAssignmentInput;
}>;


export type CreateDataObjectAssignmentForSceneManagerServiceMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type UpdateTemplateRevisionNewMutationVariables = Exact<{
  input: MutationUpdateTemplateRevisionNewInput;
}>;


export type UpdateTemplateRevisionNewMutation = { __typename: 'Mutation', updateTemplateRevisionNew: { __typename: 'TemplateRevision', id: string, legacyId: number } };

export type SelectTemplateItemFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectTemplateItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<TemplateFilterInput>;
}>;


export type SelectTemplateItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type TemplateDetailsFragment = { __typename: 'Template', id: string, legacyId: number, comment?: string | null, name?: string | null, public: boolean, state: TemplateState, type: TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type TemplateDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplateDetailsQuery = { __typename: 'Query', item: { __typename: 'Template', id: string, legacyId: number, comment?: string | null, name?: string | null, public: boolean, state: TemplateState, type: TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type UpdateTemplateDetailsMutationVariables = Exact<{
  input: MutationUpdateTemplateInput;
}>;


export type UpdateTemplateDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Template', id: string, legacyId: number, comment?: string | null, name?: string | null, public: boolean, state: TemplateState, type: TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type DeleteTemplateDetailsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTemplateDetailsMutation = { __typename: 'Mutation', deleteTemplate?: any | null };

export type TemplateDetailsCreateTemplateMutationVariables = Exact<{
  input: MutationCreateTemplateInput;
}>;


export type TemplateDetailsCreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, comment?: string | null, name?: string | null, public: boolean, state: TemplateState, type: TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type TemplateDetailsCreateTemplateRevisionMutationVariables = Exact<{
  input: MutationCreateTemplateRevisionInput;
}>;


export type TemplateDetailsCreateTemplateRevisionMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string } };

export type TemplateDetailsDeleteTemplateRevisionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplateDetailsDeleteTemplateRevisionMutation = { __typename: 'Mutation', deleteTemplateRevision?: any | null };

export type TemplateGraphMigrationTemplateRevisionFragment = { __typename: 'TemplateRevision', id: string, graph?: any | null, template: { __typename: 'Template', id: string, name?: string | null } };

export type TemplatePublisherMaterialQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplatePublisherMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null } };

export type TemplatePublisherGetAllHdrisQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplatePublisherGetAllHdrisQuery = { __typename: 'Query', hdris: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | null> };

export type TemplatePublisherCreateJobAssignmentMutationVariables = Exact<{
  input: MutationCreateJobAssignmentInput;
}>;


export type TemplatePublisherCreateJobAssignmentMutation = { __typename: 'Mutation', createJobAssignment: { __typename: 'JobAssignment', id: string, job: { __typename: 'Job', id: string } } };

export type TemplatePublisherCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type TemplatePublisherCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type TemplatePublisherJobAssignmentsQueryVariables = Exact<{
  filter: JobAssignmentFilterInput;
}>;


export type TemplatePublisherJobAssignmentsQuery = { __typename: 'Query', jobAssignments: Array<{ __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, progress?: number | null, state: JobState } } | null> };

export type TemplatePublisherDeleteJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplatePublisherDeleteJobMutation = { __typename: 'Mutation', deleteJob?: any | null };

export type TemplatePublisherJobWithOutputQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplatePublisherJobWithOutputQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, output?: any | null } };

export type TemplatePublisherUuidFromLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type TemplatePublisherUuidFromLegacyIdQuery = { __typename: 'Query', template: { __typename: 'Template', id: string } };

export type TemplatePublisherJobStatesQueryVariables = Exact<{
  filter: JobFilterInput;
}>;


export type TemplatePublisherJobStatesQuery = { __typename: 'Query', jobs: Array<{ __typename: 'Job', id: string, state: JobState } | null> };

export type TemplatePublisherTemplateRevisionQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type TemplatePublisherTemplateRevisionQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, graph?: any | null, number: number, template: { __typename: 'Template', id: string } } };

export type TemplatePublisherLatestTemplateRevisionQueryVariables = Exact<{
  templateRevisionlegacyId: Scalars['Int']['input'];
}>;


export type TemplatePublisherLatestTemplateRevisionQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, template: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null } } };

export type TemplateTreeTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplateTreeTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', name?: string | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null } };

export type TemplateTreeMaterialQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TemplateTreeMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type TemplateTreeTemplateRevisionQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type TemplateTreeTemplateRevisionQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number } } };

export type DataObjectBatchApiCallDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, height?: number | null, width?: number | null };

export type DataObjectBatchApiCallQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DataObjectBatchApiCallQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, legacyId: number, height?: number | null, width?: number | null } | null> };

export type MeshDataBatchApiCallDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string };

export type MeshDataBatchApiCallQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type MeshDataBatchApiCallQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string } | null> };

export type SceneManagerTemplateRevisionFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', id: string, name?: string | null } };

export type SceneManagerTemplateRevisionQueryVariables = Exact<{
  templateRevisionLegacyId: Scalars['Int']['input'];
}>;


export type SceneManagerTemplateRevisionQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', id: string, name?: string | null } } };

export type SceneManagerTemplateRevisionsQueryVariables = Exact<{
  legacyIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type SceneManagerTemplateRevisionsQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, template: { __typename: 'Template', id: string, name?: string | null } } | null> };

export type SceneManagerDataObjectQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type SceneManagerDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, height?: number | null, width?: number | null } };

export type GetDataObjectDetailsForSceneManagerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetDataObjectDetailsForSceneManagerQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } };

export type TemplateListItemFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, state: TemplateState, type: TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null };

export type TemplateWithLatestRevisionFragment = { __typename: 'Template', state: TemplateState, type: TemplateType, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null } | null };

export type GetOrganizationUuidFromTemplateUuidQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOrganizationUuidFromTemplateUuidQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationId: string } };

export type GetTemplateUuidFromLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetTemplateUuidFromLegacyIdQuery = { __typename: 'Query', template: { __typename: 'Template', id: string } };

export type GetTemplateLegacyIdFromUuidQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTemplateLegacyIdFromUuidQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number } };

export type GetTemplateUuidFromSceneLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetTemplateUuidFromSceneLegacyIdQuery = { __typename: 'Query', scene: { __typename: 'Scene', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } } };

export type GetTemplateMaterialRevisionIdFromLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type GetTemplateMaterialRevisionIdFromLegacyIdQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string } };

export type GetTemplateWithLatestRevisionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTemplateWithLatestRevisionQuery = { __typename: 'Query', template: { __typename: 'Template', state: TemplateState, type: TemplateType, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null } | null } };

export type GetTemplateRevisionDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTemplateRevisionDetailsQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, legacyId: number } } };

export type GetTemplatesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<TemplateFilterInput>;
  orderBy?: InputMaybe<Array<TemplateOrderByInput> | TemplateOrderByInput>;
}>;


export type GetTemplatesQuery = { __typename: 'Query', templatesCount: number, templates: Array<{ __typename: 'Template', id: string, legacyId: number, name?: string | null, state: TemplateState, type: TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null> };

export type BatchUpdateArStatisticsMutationVariables = Exact<{
  filter?: InputMaybe<ArStatisticsFilterInput>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BatchUpdateArStatisticsMutation = { __typename: 'Mutation', batchUpdateArStatistics: number };

export type BatchUpdateDataObjectAssignmentsMutationVariables = Exact<{
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  contentTypeModel: ContentTypeModel;
  objectId: Scalars['ID']['input'];
}>;


export type BatchUpdateDataObjectAssignmentsMutation = { __typename: 'Mutation', batchUpdateDataObjectAssignments: number };

export type BatchUpdateRenderJobsMutationVariables = Exact<{
  filter?: InputMaybe<RenderJobFilterInput>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BatchUpdateRenderJobsMutation = { __typename: 'Mutation', batchUpdateRenderJobs: number };

export type BatchUpdateSceneStatisticsMutationVariables = Exact<{
  filter?: InputMaybe<SceneStatisticsFilterInput>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BatchUpdateSceneStatisticsMutation = { __typename: 'Mutation', batchUpdateSceneStatistics: number };

export type CreateTemplateMutationVariables = Exact<{
  input: MutationCreateTemplateInput;
}>;


export type CreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, name?: string | null, state: TemplateState, type: TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } };

export type CreateTemplateRevisionMutationVariables = Exact<{
  input: MutationCreateTemplateRevisionInput;
}>;


export type CreateTemplateRevisionMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number } };

export type UpdateTemplateNameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTemplateNameMutation = { __typename: 'Mutation', updateTemplate: { __typename: 'Template', legacyId: number } };

export type UpdateTemplateMutationVariables = Exact<{
  input: MutationUpdateTemplateInput;
}>;


export type UpdateTemplateMutation = { __typename: 'Mutation', updateTemplate: { __typename: 'Template', id: string, legacyId: number, name?: string | null, state: TemplateState, type: TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } };

export type GetTemplatesForConfiguratorTestQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTemplatesForConfiguratorTestQuery = { __typename: 'Query', templates: Array<{ __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', graph?: any | null } | null } | null> };

export type GetTemplateRevisionIdForConfiguratorIframeTestQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;


export type GetTemplateRevisionIdForConfiguratorIframeTestQuery = { __typename: 'Query', template: { __typename: 'Template', latestRevision?: { __typename: 'TemplateRevision', id: string, graph?: any | null } | null } };

export type DeleteTextureSetRevisionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTextureSetRevisionMutation = { __typename: 'Mutation', deleteTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type QueryTextureSetRevisionTextureRevisionIdsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureSetRevisionTextureRevisionIdsQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', textureSet: { __typename: 'TextureSet', id: string, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string }>, textures: Array<{ __typename: 'Texture', id: string, revisions: Array<{ __typename: 'TextureRevision', id: string }> }> } } };

export type QueryDataObjectAssignmentForDeletionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryDataObjectAssignmentForDeletionQuery = { __typename: 'Query', dataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, assignmentsCount: number } } };

export type DeleteDataObjectAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDataObjectAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type DeleteDataObjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type DeleteTextureRevisionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTextureRevisionMutation = { __typename: 'Mutation', deleteTextureRevision?: any | null };

export type DeleteTextureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTextureMutation = { __typename: 'Mutation', deleteTexture?: any | null };

export type QueryDataObjectReferencesAndRelatedQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type QueryDataObjectReferencesAndRelatedQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', assignmentsCount: number, hdrisCount: number, materialRevisionsCount: number, textureSetRevisionMapAssignmentsCount: number, textureSetRevisionSourceDataObjectReferencesCount: number, textureRevisionsCount: number, related: Array<{ __typename: 'DataObject', id: string }> } };

export type ResolveTextureRevisionLegacyIdQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type ResolveTextureRevisionLegacyIdQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string } };

export type TextureSetDataFragment = { __typename: 'TextureSet', id: string, legacyId: number, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string, legacyId: number } }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string }> };

export type TextureSetRevisionDescriptorFragment = { __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string };

export type TextureSetRevisionDataFragment = { __typename: 'TextureSetRevision', id: string, createdAt: string, updatedAt?: string | null, name?: string | null, width: number, height: number, displacement?: number | null, editsJson?: any | null, createdBy?: { __typename: 'User', id: string, name: string } | null, updatedBy?: { __typename: 'User', id: string, name: string } | null, editsProcessingJob?: { __typename: 'Job', id: string, state: JobState, progress?: number | null } | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', id: string, width?: number | null, height?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string, legacyId: number } }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string }> }, materialNodes: Array<{ __typename: 'MaterialNode', materialRevision: { __typename: 'MaterialRevision', id: string } }> };

export type QueryTextureSetRevisionDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureSetRevisionDataQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', id: string, createdAt: string, updatedAt?: string | null, name?: string | null, width: number, height: number, displacement?: number | null, editsJson?: any | null, createdBy?: { __typename: 'User', id: string, name: string } | null, updatedBy?: { __typename: 'User', id: string, name: string } | null, editsProcessingJob?: { __typename: 'Job', id: string, state: JobState, progress?: number | null } | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', id: string, width?: number | null, height?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string, legacyId: number } }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string }> }, materialNodes: Array<{ __typename: 'MaterialNode', materialRevision: { __typename: 'MaterialRevision', id: string } }> } };

export type TextureEditorDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, size?: any | null, originalFileName: string, mediaType?: string | null, imageDataType?: ImageDataType | null, imageColorSpace?: ImageColorSpace | null, originalDownloadUrl: string, jpgDataObject?: { __typename: 'DataObject', downloadUrl: string } | null, tiffDataObject?: { __typename: 'DataObject', downloadUrl: string } | null };

export type QueryTextureEditorDataObjectQueryVariables = Exact<{
  dataObjectId: Scalars['ID']['input'];
}>;


export type QueryTextureEditorDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, size?: any | null, originalFileName: string, mediaType?: string | null, imageDataType?: ImageDataType | null, imageColorSpace?: ImageColorSpace | null, originalDownloadUrl: string, jpgDataObject?: { __typename: 'DataObject', downloadUrl: string } | null, tiffDataObject?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type TexturesApiDeleteDataObjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TexturesApiDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type QueryTextureEditDataObjectLegacyIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureEditDataObjectLegacyIdQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', legacyId: number } };

export type QueryTextureLoaderDataObjectImageDescriptorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureLoaderDataObjectImageDescriptorQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, imageDataType?: ImageDataType | null, imageColorSpace?: ImageColorSpace | null, downloadUrlExr: string, jpgDataObject?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type AutoTilingGetTextureRevisionDataObjectIdQueryVariables = Exact<{
  textureRevisionId: Scalars['ID']['input'];
}>;


export type AutoTilingGetTextureRevisionDataObjectIdQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', dataObject: { __typename: 'DataObject', id: string } } };

export type AutoTilingCreateDataObjectMutationVariables = Exact<{
  input: MutationCreateDataObjectInput;
}>;


export type AutoTilingCreateDataObjectMutation = { __typename: 'Mutation', createDataObject: { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string } };

export type TextureEditorCreateJobMutationVariables = Exact<{
  input: MutationCreateJobInput;
}>;


export type TextureEditorCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type TextureEditorCreateJobAssignmentMutationVariables = Exact<{
  input: MutationCreateJobAssignmentInput;
}>;


export type TextureEditorCreateJobAssignmentMutation = { __typename: 'Mutation', createJobAssignment: { __typename: 'JobAssignment', id: string } };

export type TextureEditorUpdateTextureSetRevisionMutationVariables = Exact<{
  input: MutationUpdateTextureSetRevisionInput;
}>;


export type TextureEditorUpdateTextureSetRevisionMutation = { __typename: 'Mutation', updateTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type TextureEditorCancelProcessingJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;


export type TextureEditorCancelProcessingJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export type QueryTextureEditProcessingJobStateQueryVariables = Exact<{
  textureSetRevisionId: Scalars['ID']['input'];
}>;


export type QueryTextureEditProcessingJobStateQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', editsProcessingJob?: { __typename: 'Job', id: string, state: JobState, progress?: number | null } | null } };

export type QueryTextureSetReferencedMaterialInfoQueryVariables = Exact<{
  materialRevisionId: Scalars['ID']['input'];
}>;


export type QueryTextureSetReferencedMaterialInfoQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null } } };

export type CreateNewTextureSetRevisionMutationVariables = Exact<{
  input: MutationCreateTextureSetRevisionInput;
}>;


export type CreateNewTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type TilingInfoPanelTextureRevisionFragment = { __typename: 'TextureRevision', id: string, createdAt: string, displacement?: number | null, height: number, width: number, createdBy: { __typename: 'User', firstName?: string | null, lastName?: string | null }, dataObject: { __typename: 'DataObject', height?: number | null, imageColorSpace?: ImageColorSpace | null, size?: any | null, width?: number | null } };

export type TilingInfoPanelTextureRevisionQueryVariables = Exact<{
  legacyId: Scalars['Int']['input'];
}>;


export type TilingInfoPanelTextureRevisionQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, createdAt: string, displacement?: number | null, height: number, width: number, createdBy: { __typename: 'User', firstName?: string | null, lastName?: string | null }, dataObject: { __typename: 'DataObject', height?: number | null, imageColorSpace?: ImageColorSpace | null, size?: any | null, width?: number | null } } };

export type ScanJobDialogFragment = { __typename: 'ScanJob', id: string, legacyId: number };

export type ScanJobDialogSubJobQueryVariables = Exact<{
  scanSubJobId: Scalars['ID']['input'];
}>;


export type ScanJobDialogSubJobQuery = { __typename: 'Query', scanSubJob: { __typename: 'ScanSubJob', id: string, active: boolean, completed?: string | null, started?: string | null, state: ScanSubJobState, tileX: number, tileY: number, progress?: string | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, fittingAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, assemblyAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }> } };

export type ScanJobDialogSubJobsQueryVariables = Exact<{
  scanJobId: Scalars['ID']['input'];
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
}>;


export type ScanJobDialogSubJobsQuery = { __typename: 'Query', items: Array<{ __typename: 'ScanSubJob', id: string, active: boolean, completed?: string | null, started?: string | null, state: ScanSubJobState, tileX: number, tileY: number, progress?: string | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, fittingAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, assemblyAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }> } | null> };

export type ScanJobDialogSubJobFragment = { __typename: 'ScanSubJob', id: string, active: boolean, completed?: string | null, started?: string | null, state: ScanSubJobState, tileX: number, tileY: number, progress?: string | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, fittingAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, assemblyAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }> };

export type ScanJobDialogUpdateSubJobMutationVariables = Exact<{
  input: MutationUpdateScanSubJobInput;
}>;


export type ScanJobDialogUpdateSubJobMutation = { __typename: 'Mutation', updateScanSubJob: { __typename: 'ScanSubJob', id: string } };

export type ScanJobDialogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ScanJobDialogQuery = { __typename: 'Query', item: { __typename: 'ScanJob', id: string, legacyId: number, subJobs: Array<{ __typename: 'ScanSubJob', id: string }> } };

export type ScanJobFragment = { __typename: 'ScanJob', id: string, active: boolean, completed?: string | null, createdAt: string, sampleRotation: number, started?: string | null, state: ScanJobState, activeSubJobsCount: number, startedSubJobsCount: number, subJobsCount: number, completedSubJobsCount: number, createdBy?: { __typename: 'User', name: string } | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string } } } };

export type ScanJobsForTextureSetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ScanJobsForTextureSetQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, name?: string | null, scanJob?: { __typename: 'ScanJob', id: string, active: boolean, completed?: string | null, createdAt: string, sampleRotation: number, started?: string | null, state: ScanJobState, activeSubJobsCount: number, startedSubJobsCount: number, subJobsCount: number, completedSubJobsCount: number, createdBy?: { __typename: 'User', name: string } | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string } } } } | null } };

export type ScanJobUpdateMutationVariables = Exact<{
  input: MutationUpdateScanJobInput;
}>;


export type ScanJobUpdateMutation = { __typename: 'Mutation', updateScanJob: { __typename: 'ScanJob', id: string, active: boolean, completed?: string | null, createdAt: string, sampleRotation: number, started?: string | null, state: ScanJobState, activeSubJobsCount: number, startedSubJobsCount: number, subJobsCount: number, completedSubJobsCount: number, createdBy?: { __typename: 'User', name: string } | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string } } } } };

export type TextureGroupDetailsFragment = { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> };

export type TextureGroupDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TextureGroupDetailsQuery = { __typename: 'Query', item: { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> } };

export type CreateTextureGroupMutationVariables = Exact<{
  input: MutationCreateTextureGroupInput;
}>;


export type CreateTextureGroupMutation = { __typename: 'Mutation', createTextureGroup: { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> } };

export type TextureGroupDetailsDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TextureGroupDetailsDeleteMutation = { __typename: 'Mutation', deleteTextureGroup?: any | null };

export type UpdateTextureGroupDetailsMutationVariables = Exact<{
  input: MutationUpdateTextureGroupInput;
}>;


export type UpdateTextureGroupDetailsMutation = { __typename: 'Mutation', item: { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> } };

export type TextureGroupDetailsCreateTextureSetMutationVariables = Exact<{
  input: MutationCreateTextureSetInput;
}>;


export type TextureGroupDetailsCreateTextureSetMutation = { __typename: 'Mutation', createTextureSet: { __typename: 'TextureSet', id: string, legacyId: number, name?: string | null } };

export type QueryTextureSetViewDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureSetViewDataQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', legacyId: number } } } };

export type QueryTextureSetRevisionViewDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureSetRevisionViewDataQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', name?: string | null, width: number, height: number, displacement?: number | null, textureSet: { __typename: 'TextureSet', id: string }, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: TextureType, dataObject: { __typename: 'DataObject', id: string } }> } };

export type TextureSetRevisionViewTextureSetFragment = { __typename: 'TextureSet', id: string, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', legacyId: number } } };

export type TextureSetRevisionViewCreateTextureSetRevisionMutationVariables = Exact<{
  input: MutationCreateTextureSetRevisionInput;
}>;


export type TextureSetRevisionViewCreateTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type TextureThumbnailViewQueryDataObjectQueryVariables = Exact<{
  dataObjectId: Scalars['ID']['input'];
}>;


export type TextureThumbnailViewQueryDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } }>, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type TextureThumbnailViewDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } }>, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type TextureThumbnailViewDataObjectDetailsFragment = { __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type TextureThumbnailViewUpdateDataObjectMutationVariables = Exact<{
  input: MutationUpdateDataObjectInput;
}>;


export type TextureThumbnailViewUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string } };

export type QueryTextureSetDataQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type QueryTextureSetDataQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, textureGroup: { __typename: 'TextureGroup', id: string }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null }> } };

export type TextureSetViewRevisionDataFragment = { __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null };

export type TextureSetViewDataFragment = { __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, textureGroup: { __typename: 'TextureGroup', id: string }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null }> };

export type UpdateTextureSetNameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTextureSetNameMutation = { __typename: 'Mutation', updateTextureSet: { __typename: 'TextureSet', id: string } };

export type DeleteTextureSetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTextureSetMutation = { __typename: 'Mutation', deleteTextureSet?: any | null };

export type CreateInitialTextureSetRevisionMutationVariables = Exact<{
  input: MutationCreateTextureSetRevisionInput;
}>;


export type CreateInitialTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type QueryLatestTextureSetRevisionIdQueryVariables = Exact<{
  textureSetId: Scalars['ID']['input'];
}>;


export type QueryLatestTextureSetRevisionIdQuery = { __typename: 'Query', textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string } | null> };

export type QueryInitialTextureRevisionsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type QueryInitialTextureRevisionsQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', scanJob?: { __typename: 'ScanJob', createdBy?: { __typename: 'User', id: string } | null } | null, textures: Array<{ __typename: 'Texture', type: TextureType, revisions: Array<{ __typename: 'TextureRevision', number?: number | null, createdAt: string, width: number, height: number, displacement?: number | null, createdBy: { __typename: 'User', id: string }, dataObject: { __typename: 'DataObject', id: string } }> }> } };

export type TextureGroupTextureSetIdsFragment = { __typename: 'TextureGroup', id: string, textureSets: Array<{ __typename: 'TextureSet', id: string }>, organization: { __typename: 'Organization', id: string } };

export type QueryTextureGroupTextureSetIdsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryTextureGroupTextureSetIdsQuery = { __typename: 'Query', textureGroup: { __typename: 'TextureGroup', id: string, textureSets: Array<{ __typename: 'TextureSet', id: string }>, organization: { __typename: 'Organization', id: string } } };

export const AttachedDataObjectFragmentDoc = gql`
    fragment AttachedDataObject on DataObject {
  id
  state
  originalFileName
  mediaType
  bucketName
  objectName
  width
  height
  downloadUrl
  organization {
    id
    name
  }
  metadataProcessingJob {
    id
    state
  }
  thumbnailProcessingJob {
    id
    state
  }
}
    `;
export const AttachmentFragmentDoc = gql`
    fragment Attachment on DataObjectAssignment {
  id
  dataObject {
    ...AttachedDataObject
  }
  assignmentType
  assignmentKey
}
    ${AttachedDataObjectFragmentDoc}`;
export const TagSearchFilterTagFragmentDoc = gql`
    fragment TagSearchFilterTag on Tag {
  id
  name
  type
}
    `;
export const TagSelectTagFragmentDoc = gql`
    fragment TagSelectTag on Tag {
  id
  name
  type
}
    `;
export const ItemListUserFragmentDoc = gql`
    fragment ItemListUser on User {
  id
  name
}
    `;
export const ListInfoTagFragmentDoc = gql`
    fragment ListInfoTag on Tag {
  id
  name
}
    `;
export const ListInfoUserFragmentDoc = gql`
    fragment ListInfoUser on User {
  id
  name
}
    `;
export const ConfigMenuMaterialIconFragmentDoc = gql`
    fragment ConfigMenuMaterialIcon on DataObject {
  id
  thumbnail(fileType: jpg, resolution: Low) {
    downloadUrl
  }
}
    `;
export const ConfigMenuMaterialFragmentDoc = gql`
    fragment ConfigMenuMaterial on Material {
  dataObjectAssignments(filter: {assignmentType: [Icon]}) {
    dataObject {
      ...ConfigMenuMaterialIcon
    }
  }
  name
}
    ${ConfigMenuMaterialIconFragmentDoc}`;
export const SceneViewerMaterialRevisionFragmentDoc = gql`
    fragment SceneViewerMaterialRevision on MaterialRevision {
  id
  legacyId
}
    `;
export const SceneViewerMaterialFragmentDoc = gql`
    fragment SceneViewerMaterial on Material {
  __typename
  id
  revisions {
    ...SceneViewerMaterialRevision
  }
  latestCyclesRevision {
    ...SceneViewerMaterialRevision
  }
  name
}
    ${SceneViewerMaterialRevisionFragmentDoc}`;
export const MaterialForConfigMenuLegacyServiceFragmentDoc = gql`
    fragment MaterialForConfigMenuLegacyService on Material {
  ...SceneViewerMaterial
}
    ${SceneViewerMaterialFragmentDoc}`;
export const NavigationBarOrganizationFragmentDoc = gql`
    fragment NavigationBarOrganization on Organization {
  id
  name
  type
}
    `;
export const MaterialForConfigMenuServiceFragmentDoc = gql`
    fragment MaterialForConfigMenuService on Material {
  ...SceneViewerMaterial
}
    ${SceneViewerMaterialFragmentDoc}`;
export const TemplateRevisionDetailsForConfiguratorFragmentDoc = gql`
    fragment TemplateRevisionDetailsForConfigurator on TemplateRevision {
  id
  graph
}
    `;
export const TemplateDetailsForConfiguratorFragmentDoc = gql`
    fragment TemplateDetailsForConfigurator on Template {
  id
  organizationLegacyId
  latestRevision {
    ...TemplateRevisionDetailsForConfigurator
  }
}
    ${TemplateRevisionDetailsForConfiguratorFragmentDoc}`;
export const DataObjectForArServiceFragmentDoc = gql`
    fragment DataObjectForArService on DataObject {
  id
  downloadUrl
  objectName
  mediaType
}
    `;
export const DataObjectWithRelatedForArServiceFragmentDoc = gql`
    fragment DataObjectWithRelatedForArService on DataObject {
  ...DataObjectForArService
  related {
    ...DataObjectForArService
  }
}
    ${DataObjectForArServiceFragmentDoc}`;
export const ImageViewerItemFragmentDoc = gql`
    fragment ImageViewerItem on DataObject {
  id
  downloadUrl
  height
  thumbnail(fileType: jpg, resolution: High) {
    downloadUrl
  }
  mediaType
  originalFileName
  width
}
    `;
export const SceneViewerDataObjectFragmentDoc = gql`
    fragment SceneViewerDataObject on DataObject {
  id
  legacyId
  mediaType
}
    `;
export const SceneViewerTemplateRevisionFragmentDoc = gql`
    fragment SceneViewerTemplateRevision on TemplateRevision {
  id
  legacyId
  graph
  template {
    organizationId
  }
}
    `;
export const SceneViewerTemplateFragmentDoc = gql`
    fragment SceneViewerTemplate on Template {
  __typename
  id
  legacyId
  organizationLegacyId
  latestRevision {
    ...SceneViewerTemplateRevision
  }
  revisions {
    ...SceneViewerTemplateRevision
  }
}
    ${SceneViewerTemplateRevisionFragmentDoc}`;
export const ShaderBallMaterialFragmentDoc = gql`
    fragment ShaderBallMaterial on Material {
  legacyId
  name
  organization {
    legacyId
  }
}
    `;
export const TemplateDetailsForMaterialAssetsRenderingFragmentDoc = gql`
    fragment TemplateDetailsForMaterialAssetsRendering on Template {
  name
  legacyId
  latestRevision {
    legacyId
    graph
  }
}
    `;
export const AuthenticatedUserFragmentDoc = gql`
    fragment AuthenticatedUser on User {
  id
  __typename
  email
  isStaff
  isSuperuser
  name
  role
  galleryImage {
    id
  }
  memberships {
    id
    organization {
      id
      name
      type
    }
  }
}
    `;
export const AuthSiloDataFragmentDoc = gql`
    fragment AuthSiloData on User {
  id
  __typename
  role
  memberships {
    id
    organization {
      id
      name
      type
    }
  }
}
    `;
export const DataObjectThumbnailFragmentDoc = gql`
    fragment DataObjectThumbnail on DataObject {
  id
  mediaType
  state
  thumbnail(fileType: jpg, resolution: $resolution) {
    downloadUrl
  }
  thumbnailProcessingJob {
    state
  }
}
    `;
export const DownloadableFileFragmentDoc = gql`
    fragment DownloadableFile on DataObject {
  downloadUrl
  originalFileName
}
    `;
export const ItemUtilsGalleryImageFragmentDoc = gql`
    fragment ItemUtilsGalleryImage on DataObject {
  state
  bucketName
  objectName
  mediaType
}
    `;
export const MaterialAssetsRenderingMaterialFragmentDoc = gql`
    fragment MaterialAssetsRenderingMaterial on Material {
  id
  legacyId
  name
  latestCyclesRevision {
    id
    legacyId
  }
  organization {
    id
    legacyId
  }
}
    `;
export const MaterialsCardInputFragmentDoc = gql`
    fragment MaterialsCardInput on Material {
  id
  legacyId
  name
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const MaterialsCardFragmentDoc = gql`
    fragment MaterialsCard on Material {
  __typename
  id
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
  organization {
    id
  }
  nextActor
  state
  tagAssignments {
    id
    tag {
      id
      legacyId
      createdBy {
        id
      }
      name
      description
      color
      type
      organization {
        id
        legacyId
      }
    }
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
}
    `;
export const MaterialGraphDataObjectDetailsFragmentDoc = gql`
    fragment MaterialGraphDataObjectDetails on DataObject {
  legacyId
  imageColorSpace
  mediaType
  width
  height
  downloadUrl
  related {
    legacyId
    imageColorSpace
    width
    height
    mediaType
    downloadUrl
  }
}
    `;
export const MaterialGraphTextureRevisionFragmentDoc = gql`
    fragment MaterialGraphTextureRevision on TextureRevision {
  id
  legacyId
  width
  height
  displacement
  dataObject {
    ...MaterialGraphDataObjectDetails
  }
}
    ${MaterialGraphDataObjectDetailsFragmentDoc}`;
export const MaterialGraphRevisionFragmentDoc = gql`
    fragment MaterialGraphRevision on MaterialRevision {
  legacyId
  material {
    name
    legacyId
  }
}
    `;
export const MaterialRevisionForMapsExportFragmentDoc = gql`
    fragment MaterialRevisionForMapsExport on MaterialRevision {
  ...MaterialGraphRevision
  nodes {
    id
  }
  connections {
    id
  }
}
    ${MaterialGraphRevisionFragmentDoc}`;
export const MaterialMapsExporterConnectionFragmentDoc = gql`
    fragment MaterialMapsExporterConnection on MaterialConnection {
  source {
    id
    legacyId
  }
  sourceParameter
  destination {
    id
    legacyId
  }
  destinationParameter
}
    `;
export const MapsExportTextureRevisionFragmentDoc = gql`
    fragment MapsExportTextureRevision on TextureRevision {
  legacyId
  width
  height
  displacement
  dataObject {
    legacyId
    width
    height
  }
  texture {
    type
  }
}
    `;
export const MaterialGraphTextureSetRevisionFragmentDoc = gql`
    fragment MaterialGraphTextureSetRevision on TextureSetRevision {
  id
  width
  height
  displacement
  mapAssignments {
    textureType
    dataObject {
      legacyId
      width
      height
    }
  }
}
    `;
export const MaterialMapsExporterNodeFragmentDoc = gql`
    fragment MaterialMapsExporterNode on MaterialNode {
  id
  legacyId
  name
  textureRevision {
    ...MapsExportTextureRevision
  }
  textureSetRevision {
    ...MaterialGraphTextureSetRevision
  }
  parameters
}
    ${MapsExportTextureRevisionFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}`;
export const MapsExportMaterialDetailsFragmentDoc = gql`
    fragment MapsExportMaterialDetails on Material {
  id
  legacyId
  articleId
  organization {
    legacyId
  }
  name
  tagAssignments(tagTypes: MaterialRange) {
    tag {
      name
    }
  }
}
    `;
export const DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc = gql`
    fragment DataObjectDetailsForNameAssetFromSchemaService on DataObject {
  id
  mediaType
  objectName
  related @include(if: $includeRelated) {
    id
    mediaType
    objectName
    originalFileName
  }
}
    `;
export const MaterialGraphNodeFragmentDoc = gql`
    fragment MaterialGraphNode on MaterialNode {
  id
  legacyId
  name
  textureRevision {
    legacyId
  }
  textureSetRevision {
    ...MaterialGraphTextureSetRevision
  }
  parameters
}
    ${MaterialGraphTextureSetRevisionFragmentDoc}`;
export const MaterialGraphConnectionFragmentDoc = gql`
    fragment MaterialGraphConnection on MaterialConnection {
  source {
    id
    legacyId
  }
  sourceParameter
  destination {
    id
    legacyId
  }
  destinationParameter
}
    `;
export const MaterialGraphExtendedRevisionFragmentDoc = gql`
    fragment MaterialGraphExtendedRevision on MaterialRevision {
  ...MaterialGraphRevision
  nodes {
    ...MaterialGraphNode
  }
  connections {
    ...MaterialGraphConnection
  }
}
    ${MaterialGraphRevisionFragmentDoc}
${MaterialGraphNodeFragmentDoc}
${MaterialGraphConnectionFragmentDoc}`;
export const MaterialDetailsForNameAssetFromSchemaServiceFragmentDoc = gql`
    fragment MaterialDetailsForNameAssetFromSchemaService on Material {
  id
  legacyId
  articleId
  dataObjectAssignments(filter: $dataObjectAssignmentFilter) {
    assignmentType
    dataObject {
      ...DataObjectDetailsForNameAssetFromSchemaService
    }
  }
  name
  organization {
    name
  }
  tagAssignments {
    tag {
      name
      type
      otherInfo
    }
  }
  latestCyclesRevision {
    ...MaterialGraphExtendedRevision
  }
}
    ${DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc}
${MaterialGraphExtendedRevisionFragmentDoc}`;
export const BasicOrganizationInfoFragmentDoc = gql`
    fragment BasicOrganizationInfo on Organization {
  id
  legacyId
  galleryImage {
    id
  }
  name
  type
  visibleInFilters
}
    `;
export const BasicOrganizationMembershipInfoFragmentDoc = gql`
    fragment BasicOrganizationMembershipInfo on Membership {
  id
  organization {
    ...BasicOrganizationInfo
  }
}
    ${BasicOrganizationInfoFragmentDoc}`;
export const OrganizationDropdownOptionFragmentDoc = gql`
    fragment OrganizationDropdownOption on Organization {
  id
  name
}
    `;
export const PaymentStateLabelForMaterialFragmentDoc = gql`
    fragment PaymentStateLabelForMaterial on Material {
  id
  __typename
  paymentState
}
    `;
export const PaymentStateLabelForModelFragmentDoc = gql`
    fragment PaymentStateLabelForModel on Model {
  id
  __typename
  paymentState
}
    `;
export const PaymentStateLabelForPictureFragmentDoc = gql`
    fragment PaymentStateLabelForPicture on Picture {
  id
  __typename
  paymentState
}
    `;
export const PdfDataForPdfServiceFragmentDoc = gql`
    fragment PdfDataForPdfService on Organization {
  id
  pdfTemplate {
    downloadUrl
  }
  font {
    downloadUrl
  }
  fontBold {
    downloadUrl
  }
}
    `;
export const PriceGraphDetailsFragmentDoc = gql`
    fragment PriceGraphDetails on PriceGraph {
  id
  state
  templateId
  revisionNumber
  graph
}
    `;
export const PriceDetailsFragmentDoc = gql`
    fragment PriceDetails on Price {
  idFromCatalog {
    originalId
  }
  currency
  price
}
    `;
export const BasicTagAssignmentFragmentDoc = gql`
    fragment BasicTagAssignment on TagAssignment {
  id
  tag {
    id
    type
  }
}
    `;
export const BasicTagInfoFragmentDoc = gql`
    fragment BasicTagInfo on Tag {
  id
  legacyId
  color
  description
  name
  type
  createdBy {
    id
  }
  organization {
    id
  }
}
    `;
export const FullTagInfoFragmentDoc = gql`
    fragment FullTagInfo on Tag {
  ...BasicTagInfo
  description
}
    ${BasicTagInfoFragmentDoc}`;
export const TemplateThumbnailRenderingFragmentDoc = gql`
    fragment TemplateThumbnailRendering on Template {
  id
  legacyId
  name
  organization {
    id
    legacyId
  }
  latestRevision {
    id
    legacyId
    graph
  }
}
    `;
export const UploadProcessingDataObjectFragmentDoc = gql`
    fragment UploadProcessingDataObject on DataObject {
  downloadUrl
  objectName
  mediaType
  related {
    downloadUrl
    objectName
    mediaType
  }
  metadataProcessingJob {
    id
  }
  thumbnailProcessingJob {
    id
  }
}
    `;
export const UploadServiceDataObjectFragmentDoc = gql`
    fragment UploadServiceDataObject on DataObject {
  id
  legacyId
  signedUploadUrl
}
    `;
export const ConfigVariantIconDataObjectFragmentDoc = gql`
    fragment ConfigVariantIconDataObject on DataObject {
  legacyId
  imageColorSpace
  thumbnailAvailable
  thumbnail(fileType: jpg, resolution: Low) {
    downloadUrl
  }
}
    `;
export const MaterialInspectorMaterialFragmentDoc = gql`
    fragment MaterialInspectorMaterial on Material {
  id
  legacyId
  name
  galleryImage {
    id
  }
  organization {
    id
    name
  }
  cyclesRevisions {
    id
    legacyId
    number
  }
  latestCyclesRevision {
    id
    legacyId
    number
  }
}
    `;
export const MaterialInspectorMaterialRevisionFragmentDoc = gql`
    fragment MaterialInspectorMaterialRevision on MaterialRevision {
  id
  legacyId
  material {
    ...MaterialInspectorMaterial
  }
}
    ${MaterialInspectorMaterialFragmentDoc}`;
export const EntityDetailsGalleryImageDataObjectFragmentDoc = gql`
    fragment EntityDetailsGalleryImageDataObject on DataObject {
  id
  downloadUrl
  thumbnail(fileType: jpg, resolution: Medium) {
    id
    downloadUrl
  }
}
    `;
export const MaterialEditorMaterialRevisionFragmentDoc = gql`
    fragment MaterialEditorMaterialRevision on MaterialRevision {
  id
  hasCyclesMaterial
  legacyId
  number
}
    `;
export const MaterialEditorMaterialFragmentDoc = gql`
    fragment MaterialEditorMaterial on Material {
  __typename
  id
  legacyId
  name
  revisions {
    ...MaterialEditorMaterialRevision
  }
}
    ${MaterialEditorMaterialRevisionFragmentDoc}`;
export const MaterialEditorMaterialConnectionFragmentDoc = gql`
    fragment MaterialEditorMaterialConnection on MaterialConnection {
  id
  legacyId
  destination {
    id
    legacyId
  }
  destinationParameter
  source {
    id
    legacyId
  }
  sourceParameter
}
    `;
export const MaterialEditorMaterialNodeFragmentDoc = gql`
    fragment MaterialEditorMaterialNode on MaterialNode {
  id
  legacyId
  name
  parameters
  textureRevision {
    id
    legacyId
    height
    texture {
      type
    }
    width
  }
  textureSetRevision {
    id
    width
    height
    mapAssignments {
      textureType
      dataObject {
        legacyId
      }
    }
  }
  materialRevision {
    id
  }
}
    `;
export const MaterialEditorTextureRevisionFragmentDoc = gql`
    fragment MaterialEditorTextureRevision on TextureRevision {
  id
  legacyId
  displacement
  height
  state
  texture {
    type
  }
  width
}
    `;
export const MaterialEditorTextureFragmentDoc = gql`
    fragment MaterialEditorTexture on Texture {
  id
  legacyId
  latestRevision {
    ...MaterialEditorTextureRevision
  }
  type
}
    ${MaterialEditorTextureRevisionFragmentDoc}`;
export const MaterialEditorTextureSetFragmentDoc = gql`
    fragment MaterialEditorTextureSet on TextureSet {
  id
  textures {
    ...MaterialEditorTexture
  }
}
    ${MaterialEditorTextureFragmentDoc}`;
export const ImageTextureSetNodeTextureSetRevisionInfoFragmentDoc = gql`
    fragment ImageTextureSetNodeTextureSetRevisionInfo on TextureSetRevision {
  id
  createdAt
  name
}
    `;
export const ImageTextureSetNodeTextureSetFragmentDoc = gql`
    fragment ImageTextureSetNodeTextureSet on TextureSet {
  id
  legacyId
  textureSetRevisions {
    ...ImageTextureSetNodeTextureSetRevisionInfo
  }
}
    ${ImageTextureSetNodeTextureSetRevisionInfoFragmentDoc}`;
export const ImageTextureSetNodeTextureSetRevisionFragmentDoc = gql`
    fragment ImageTextureSetNodeTextureSetRevision on TextureSetRevision {
  id
  name
  width
  height
  displacement
  mapAssignments {
    textureType
    dataObject {
      id
      legacyId
    }
  }
  editsProcessingJob {
    state
  }
  textureSet {
    ...ImageTextureSetNodeTextureSet
  }
}
    ${ImageTextureSetNodeTextureSetFragmentDoc}`;
export const ImageTextureNodeTextureFragmentDoc = gql`
    fragment ImageTextureNodeTexture on Texture {
  id
  latestRevision {
    legacyId
    number
  }
  revisions {
    id
    legacyId
    number
  }
  textureSet {
    textureGroup {
      id
    }
  }
  type
}
    `;
export const ImageTextureNodeTextureRevisionFragmentDoc = gql`
    fragment ImageTextureNodeTextureRevision on TextureRevision {
  id
  height
  number
  state
  texture {
    ...ImageTextureNodeTexture
  }
  dataObject {
    thumbnail(fileType: jpg, resolution: Low) {
      downloadUrl
    }
  }
  thumbnailAssignments: dataObjectAssignments(
    filter: {assignmentType: [GalleryImage]}
  ) {
    dataObject {
      thumbnail(fileType: jpg, resolution: Low) {
        downloadUrl
      }
    }
  }
  width
}
    ${ImageTextureNodeTextureFragmentDoc}`;
export const SaveMaterialRevisionDialogMaterialFragmentDoc = gql`
    fragment SaveMaterialRevisionDialogMaterial on Material {
  id
  legacyId
  name
  comment
  organization {
    name
  }
  tagAssignments(tagTypes: MaterialRange) {
    tag {
      name
    }
  }
}
    `;
export const AssetBoxesFragmentDoc = gql`
    fragment AssetBoxes on Asset {
  id
  legacyId
  materials {
    state
    galleryImage {
      id
    }
  }
  model {
    state
    galleryImage {
      id
    }
  }
}
    `;
export const AssetDetailsFragmentDoc = gql`
    fragment AssetDetails on Asset {
  id
  legacyId
  __typename
  articleId
  comment
  name
  nextActor
  state
  galleryImage {
    id
  }
  organization {
    id
    legacyId
    name
  }
  materialAssignments {
    id
    material {
      id
      legacyId
      galleryImage {
        id
      }
      name
      state
      organization {
        id
        legacyId
        name
      }
    }
  }
  model {
    id
    legacyId
    galleryImage {
      id
    }
    name
    state
    organization {
      id
      legacyId
      name
    }
  }
}
    `;
export const AssetListItemFragmentDoc = gql`
    fragment AssetListItem on Asset {
  __typename
  id
  legacyId
  articleId
  comment
  name
  nextActor
  state
  organization {
    id
    legacyId
    name
  }
  galleryImage {
    id
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
}
    `;
export const SelectAssetItemFragmentDoc = gql`
    fragment SelectAssetItem on Asset {
  id
  id
  legacyId
  name
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const PictureAssetsSectionFragmentDoc = gql`
    fragment PictureAssetsSection on Picture {
  __typename
  id
  legacyId
  assetAssignments {
    id
    asset {
      __typename
      id
      legacyId
      name
      state
      galleryImage {
        id
      }
      materials {
        state
        galleryImage {
          id
        }
      }
      model {
        state
        galleryImage {
          id
        }
      }
      organization {
        name
        id
        legacyId
      }
    }
  }
}
    `;
export const TemplatesSectionItemFragmentDoc = gql`
    fragment TemplatesSectionItem on Template {
  id
  legacyId
  name
  galleryImage {
    id
  }
  latestRevision {
    renderedImages(take: 4) {
      id
    }
  }
  organization {
    id
    name
  }
}
    `;
export const FileDetailsFragmentDoc = gql`
    fragment FileDetails on DataObject {
  __typename
  id
  legacyId
  bucketName
  downloadUrl
  height
  mediaType
  originalFileName
  state
  thumbnail(resolution: High, fileType: jpg) {
    downloadUrl
  }
  width
  createdBy {
    id
    legacyId
    name
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const FileThumbnailFragmentDoc = gql`
    fragment FileThumbnail on DataObject {
  id
  originalFileName
  state
  thumbnailAvailable
  thumbnail(resolution: $resolution, fileType: $fileType) {
    downloadUrl
  }
}
    `;
export const FilesGridDataObjectFragmentDoc = gql`
    fragment FilesGridDataObject on DataObject {
  id
  legacyId
  name: originalFileName
  organization {
    id
    name
  }
  state
}
    `;
export const HdriDetailsFragmentDoc = gql`
    fragment HdriDetails on Hdri {
  id
  legacyId
  __typename
  name
  organization {
    id
    legacyId
    name
  }
  dataObject {
    state
    bucketName
    objectName
    mediaType
  }
  galleryImage {
    id
  }
}
    `;
export const HdrisGridItemFragmentDoc = gql`
    fragment HdrisGridItem on Hdri {
  id
  legacyId
  name
  organization {
    id
    legacyId
    name
  }
  dataObject {
    state
    bucketName
    objectName
    mediaType
  }
  galleryImage {
    id
  }
}
    `;
export const SelectHdriItemFragmentDoc = gql`
    fragment SelectHdriItem on Hdri {
  id
  legacyId
  name
  organization {
    legacyId
    name
  }
  galleryImage {
    id
  }
}
    `;
export const EditJobFarmGroupDialogJobFarmGroupFragmentDoc = gql`
    fragment EditJobFarmGroupDialogJobFarmGroup on JobFarmGroup {
  id
  name
  queueGroup
  organization {
    id
    legacyId
  }
}
    `;
export const JobFarmGroupsTableItemFragmentDoc = gql`
    fragment JobFarmGroupsTableItem on JobFarmGroup {
  id
  name
  otherConfig
  queueGroup
  organization {
    id
    name
  }
}
    `;
export const EditJobFarmInstanceDialogJobFarmGroupFragmentDoc = gql`
    fragment EditJobFarmInstanceDialogJobFarmGroup on JobFarmGroup {
  id
  name
}
    `;
export const JobFarmInstancesTableFragmentDoc = gql`
    fragment JobFarmInstancesTable on JobFarmInstance {
  id
  hwFingerprint
  otherInfo
  lastReport
  state
  type
  version
  user {
    email
  }
  group {
    id
    name
  }
  claim {
    id
    legacyId
    job {
      id
    }
  }
}
    `;
export const AssociatedJobsTableItemFragmentDoc = gql`
    fragment AssociatedJobsTableItem on Job {
  id
  legacyId
  cancelled
  createdAt
  finished
  message
  name
  progress
  started
  state
  createdBy {
    id
    name
    galleryImage {
      id
    }
  }
}
    `;
export const AssociatedJobsTableJobAssignmentFragmentDoc = gql`
    fragment AssociatedJobsTableJobAssignment on JobAssignment {
  id
  job {
    ...AssociatedJobsTableItem
  }
}
    ${AssociatedJobsTableItemFragmentDoc}`;
export const ExecutionPlanFragmentDoc = gql`
    fragment ExecutionPlan on Job {
  executionPlan
  state
}
    `;
export const JobDetailsFragmentDoc = gql`
    fragment JobDetails on Job {
  __typename
  id
  legacyId
  cancelled
  claimedBy
  finished
  graph
  message
  name
  priority
  progress
  started
  state
  createdBy {
    id
    legacyId
    name
  }
  dataObjectAssignments {
    id
    legacyId
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const JobTaskDetailsFragmentDoc = gql`
    fragment JobTaskDetails on JobTask {
  __typename
  id
  legacyId
  claimedBy
  deferredUntil
  finished
  graph
  message
  name
  started
  state
  taskType
  createdBy {
    id
    legacyId
    name
  }
  organization {
    id
    legacyId
    name
  }
  job {
    id
    legacyId
    name
  }
}
    `;
export const JobTaskTableItemFragmentDoc = gql`
    fragment JobTaskTableItem on JobTask {
  id
  legacyId
  attemptCount
  deferredUntil
  finished
  maxAttempts
  message
  name
  taskType
  progress
  state
  started
  claimedBy
  createdBy {
    id
    name
  }
}
    `;
export const JobsGridItemFragmentDoc = gql`
    fragment JobsGridItem on Job {
  id
  legacyId
  message
  name
  output
  priority
  state
  organization {
    id
    legacyId
    name
  }
}
    `;
export const JobsTableItemFragmentDoc = gql`
    fragment JobsTableItem on Job {
  id
  legacyId
  cancelled
  createdAt
  finished
  message
  name
  output
  priority
  progress
  started
  state
  updatedAt
  createdBy {
    id
    legacyId
    name
    galleryImage {
      id
    }
  }
  updatedBy {
    id
    legacyId
    name
    galleryImage {
      id
    }
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const AddMaterialDialogTagFragmentDoc = gql`
    fragment AddMaterialDialogTag on Tag {
  id
  name
  organization {
    id
  }
}
    `;
export const MaterialDownloadDataFragmentDoc = gql`
    fragment MaterialDownloadData on DataObject {
  id
  legacyId
  originalFileName
  downloadUrl
  mediaType
}
    `;
export const MaterialDetailsFragmentDoc = gql`
    fragment MaterialDetails on Material {
  __typename
  id
  legacyId
  articleId
  description
  galleryImage {
    id
  }
  latestCyclesRevision {
    id
    createdAt
    number
    createdBy {
      firstName
      lastName
    }
  }
  latestRevision {
    id
    legacyId
    createdAt
    number
    createdBy {
      name
      firstName
      lastName
    }
  }
  name
  nextActor
  priorityOrder
  state
  organization {
    id
    legacyId
  }
  textureGroup {
    id
    legacyId
    name
    organization {
      id
    }
    textureSets {
      id
      legacyId
      name
    }
  }
  iconAssignments: dataObjectAssignments(filter: {assignmentType: Icon}) {
    id
    dataObject {
      id
      related {
        ...MaterialDownloadData
      }
    }
  }
  public
  meshSpecific
  sampleArrival
  comment
}
    ${MaterialDownloadDataFragmentDoc}`;
export const MaterialExplorerMaterialRevisionFragmentDoc = gql`
    fragment MaterialExplorerMaterialRevision on MaterialRevision {
  id
  legacyId
  nodes {
    id
  }
  connections {
    sourceParameter
    destinationParameter
    source {
      legacyId
      id
    }
    destination {
      legacyId
      id
    }
  }
}
    `;
export const MaterialExplorerTextureRevisionFragmentDoc = gql`
    fragment MaterialExplorerTextureRevision on TextureRevision {
  id
  legacyId
  dataObject {
    state
  }
}
    `;
export const MaterialExplorerTextureFragmentDoc = gql`
    fragment MaterialExplorerTexture on Texture {
  id
  type
  latestRevision {
    ...MaterialExplorerTextureRevision
  }
}
    ${MaterialExplorerTextureRevisionFragmentDoc}`;
export const MaterialExplorerTextureSetFragmentDoc = gql`
    fragment MaterialExplorerTextureSet on TextureSet {
  id
  legacyId
  textures {
    ...MaterialExplorerTexture
  }
}
    ${MaterialExplorerTextureFragmentDoc}`;
export const MaterialExplorerTextureGroupFragmentDoc = gql`
    fragment MaterialExplorerTextureGroup on TextureGroup {
  legacyId
  textureSets {
    ...MaterialExplorerTextureSet
  }
}
    ${MaterialExplorerTextureSetFragmentDoc}`;
export const MaterialExplorerItemFragmentDoc = gql`
    fragment MaterialExplorerItem on Material {
  legacyId
  __typename
  latestRevision {
    ...MaterialExplorerMaterialRevision
  }
  textureGroup {
    ...MaterialExplorerTextureGroup
  }
}
    ${MaterialExplorerMaterialRevisionFragmentDoc}
${MaterialExplorerTextureGroupFragmentDoc}`;
export const MaterialExplorerTextureSetRevisionFragmentDoc = gql`
    fragment MaterialExplorerTextureSetRevision on TextureSetRevision {
  id
  mapAssignments {
    textureType
    dataObject {
      legacyId
      state
    }
  }
}
    `;
export const MaterialExplorerMaterialNodeFragmentDoc = gql`
    fragment MaterialExplorerMaterialNode on MaterialNode {
  id
  legacyId
  __typename
  name
  parameters
  textureRevision {
    ...MaterialExplorerTextureRevision
  }
}
    ${MaterialExplorerTextureRevisionFragmentDoc}`;
export const MaterialOutputTileableImageFragmentDoc = gql`
    fragment MaterialOutputTileableImage on DataObject {
  id
  downloadUrl
  mediaType
}
    `;
export const MaterialOutputsDataFragmentDoc = gql`
    fragment MaterialOutputsData on Material {
  id
  legacyId
  __typename
  name
  organization {
    id
  }
  dataObjectAssignments {
    assignmentType
    dataObject {
      id
      ...MaterialOutputTileableImage
      jpeg: thumbnail(fileType: jpg, resolution: High) {
        downloadUrl
      }
      tiff: thumbnail(fileType: tiff, resolution: High) {
        downloadUrl
      }
    }
  }
  revisions {
    id
    legacyId
    number
  }
  latestCyclesRevision {
    id
  }
  textureGroup {
    id
    legacyId
    name
    organization {
      id
    }
    textureSets {
      id
      legacyId
      name
    }
  }
}
    ${MaterialOutputTileableImageFragmentDoc}`;
export const MaterialRevisionsHistoryDialogMaterialRevisionFragmentDoc = gql`
    fragment MaterialRevisionsHistoryDialogMaterialRevision on MaterialRevision {
  id
  number
  createdBy {
    firstName
    lastName
  }
  createdAt
  hasCyclesMaterial
  graphSchema
  comment
  matFileDataObject {
    downloadUrl
  }
}
    `;
export const MaterialsGridItemFragmentDoc = gql`
    fragment MaterialsGridItem on Material {
  __typename
  id
  legacyId
  name
  articleId
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const SelectMaterialItemFragmentDoc = gql`
    fragment SelectMaterialItem on Material {
  id
  legacyId
  name
  description
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const ModelDetailsFragmentDoc = gql`
    fragment ModelDetails on Model {
  __typename
  id
  legacyId
  articleId
  description
  comment
  name
  state
  public
  organization {
    id
    legacyId
  }
  galleryImage {
    id
  }
}
    `;
export const ModelListItemFragmentDoc = gql`
    fragment ModelListItem on Model {
  __typename
  id
  legacyId
  articleId
  name
  nextActor
  state
  organization {
    id
    legacyId
    name
  }
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
  tagAssignments {
    tag {
      id
      legacyId
      createdBy {
        id
      }
      name
      description
      color
      type
      organization {
        id
        legacyId
      }
    }
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
  galleryImage {
    id
  }
}
    `;
export const SelectModelItemFragmentDoc = gql`
    fragment SelectModelItem on Model {
  id
  legacyId
  name
  description
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const FeedbackCanvasDataObjectFragmentDoc = gql`
    fragment FeedbackCanvasDataObject on DataObject {
  id
  bucketName
  downloadUrl
  height
  thumbnail {
    downloadUrl
  }
  objectName
  width
}
    `;
export const FeedbackCanvasItemFragmentDoc = gql`
    fragment FeedbackCanvasItem on PictureRevision {
  id
  legacyId
  __typename
  number
  picture {
    id
  }
  pictureData: dataObjectAssignments(filter: {assignmentType: [PictureData]}) {
    id
    dataObject {
      id
    }
  }
  drawingData: dataObjectAssignments(filter: {assignmentType: [DrawingData]}) {
    id
    dataObject {
      ...FeedbackCanvasDataObject
    }
  }
}
    ${FeedbackCanvasDataObjectFragmentDoc}`;
export const FeedbackCanvasTaskPinFragmentDoc = gql`
    fragment FeedbackCanvasTaskPin on TaskPin {
  id
  x
  y
}
    `;
export const FeedbackCanvasTaskFragmentDoc = gql`
    fragment FeedbackCanvasTask on Task {
  id
  __typename
  number
  pins {
    ...FeedbackCanvasTaskPin
  }
  state
}
    ${FeedbackCanvasTaskPinFragmentDoc}`;
export const MovePictureDialogProjectFragmentDoc = gql`
    fragment MovePictureDialogProject on Project {
  id
  legacyId
  name
  number
  sets {
    id
    legacyId
    name
    number
  }
}
    `;
export const PictureDetailsRevisionFragmentDoc = gql`
    fragment PictureDetailsRevision on PictureRevision {
  id
  legacyId
  __typename
  createdAt
  number
  createdBy {
    id
    legacyId
    firstName
    lastName
  }
}
    `;
export const PictureDetailsFragmentDoc = gql`
    fragment PictureDetails on Picture {
  __typename
  id
  legacyId
  description
  jobAssignmentsCount
  name
  nextActor
  number
  state
  attachments: dataObjectAssignments(filter: {assignmentType: [Attachment]}) {
    id
    dataObject {
      id
    }
  }
  latestRevision {
    ...PictureDetailsRevision
  }
  organization {
    id
    legacyId
    name
  }
  set {
    id
    legacyId
    name
    number
    project {
      id
      legacyId
      name
      number
      organization {
        id
      }
    }
  }
  template {
    id
    legacyId
    revisions {
      id
      legacyId
    }
    latestRevision {
      id
    }
  }
  galleryImage {
    id
  }
}
    ${PictureDetailsRevisionFragmentDoc}`;
export const PictureDetailsRenderJobFragmentDoc = gql`
    fragment PictureDetailsRenderJob on Job {
  id
  legacyId
  finished
  message
  name
  progress
  state
  started
  createdBy {
    id
    name
  }
}
    `;
export const PictureRevisionSelectorRevisionFragmentDoc = gql`
    fragment PictureRevisionSelectorRevision on PictureRevision {
  id
  __typename
  createdAt
  number
  createdBy {
    id
    firstName
    name
  }
  pictureDataAssignments: dataObjectAssignments(
    filter: {assignmentType: [PictureData]}
  ) {
    dataObject {
      id
      downloadUrl
    }
  }
}
    `;
export const PictureRevisionSelectorPictureFragmentDoc = gql`
    fragment PictureRevisionSelectorPicture on Picture {
  id
  __typename
  state
  latestRevision {
    ...PictureRevisionSelectorRevision
  }
  organization {
    id
  }
  revisions(orderBy: [{key: number, direction: desc}]) {
    ...PictureRevisionSelectorRevision
  }
}
    ${PictureRevisionSelectorRevisionFragmentDoc}`;
export const PictureViewerPictureDataObjectThumbnailFragmentDoc = gql`
    fragment PictureViewerPictureDataObjectThumbnail on DataObject {
  thumbnail(fileType: jpg, resolution: High) {
    downloadUrl
  }
}
    `;
export const PictureViewerPictureDataObjectFragmentDoc = gql`
    fragment PictureViewerPictureDataObject on DataObject {
  id
  height
  imageColorProfile
  imageColorSpace
  mediaType
  originalFileName
  size
  width
  downloadUrl
  ...PictureViewerPictureDataObjectThumbnail
}
    ${PictureViewerPictureDataObjectThumbnailFragmentDoc}`;
export const PictureViewerDataObjectAssignmentFragmentDoc = gql`
    fragment PictureViewerDataObjectAssignment on DataObjectAssignment {
  id
  dataObject {
    ...PictureViewerPictureDataObject
  }
}
    ${PictureViewerPictureDataObjectFragmentDoc}`;
export const PictureViewerPictureRevisionFragmentDoc = gql`
    fragment PictureViewerPictureRevision on PictureRevision {
  id
  createdAt
  createdBy {
    firstName
    lastName
  }
  number
  pictureDataAssignments: dataObjectAssignments(
    filter: {assignmentType: PictureData}
  ) {
    ...PictureViewerDataObjectAssignment
  }
  dataObjectAssignments {
    id
    assignmentType
  }
}
    ${PictureViewerDataObjectAssignmentFragmentDoc}`;
export const PictureViewerPictureFragmentDoc = gql`
    fragment PictureViewerPicture on Picture {
  id
  revisions {
    ...PictureViewerPictureRevision
  }
}
    ${PictureViewerPictureRevisionFragmentDoc}`;
export const PicturesGridItemFragmentDoc = gql`
    fragment PicturesGridItem on Picture {
  __typename
  id
  legacyId
  name
  state
  priorityOrder
  nextActor
  number
  assetInfos {
    completed
    new
    readyForShading
    review
    shading
  }
  organization {
    id
    name
  }
  set {
    id
    name
    number
    project {
      name
      number
      organization {
        id
      }
    }
  }
  template {
    id
    latestRevision {
      id
      isLegacy
      renderedImages(take: 4) {
        id
      }
    }
  }
  downloadUrl
  galleryImage {
    id
  }
  latestRevision {
    id
    number
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
  tagAssignments {
    id
    tag {
      ...BasicTagInfo
    }
  }
}
    ${BasicTagInfoFragmentDoc}`;
export const PicturesGridUserFragmentDoc = gql`
    fragment PicturesGridUser on User {
  id
  name
}
    `;
export const ProjectTreeSetFragmentDoc = gql`
    fragment ProjectTreeSet on Set {
  id
  name
  number
  created
  project {
    id
  }
  galleryImage {
    id
  }
}
    `;
export const ProjectTreeProjectFragmentDoc = gql`
    fragment ProjectTreeProject on Project {
  id
  name
  number
  sets {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;
export const ProjectTreeUserFragmentDoc = gql`
    fragment ProjectTreeUser on User {
  id
  name
}
    `;
export const ScanningActionBarTagFragmentDoc = gql`
    fragment ScanningActionBarTag on Tag {
  ...BasicTagInfo
  description
}
    ${BasicTagInfoFragmentDoc}`;
export const TagAssignmentsItemFragmentDoc = gql`
    fragment TagAssignmentsItem on TagAssignment {
  id
  legacyId
  contentTypeModel
  assignedTo {
    __typename
    ... on Asset {
      id
      legacyId
      name
    }
    ... on Hdri {
      id
      legacyId
      name
    }
    ... on Material {
      id
      legacyId
      name
      galleryImage {
        id
      }
    }
    ... on Model {
      id
      legacyId
      name
      galleryImage {
        id
      }
    }
    ... on Picture {
      id
      legacyId
      altName: name
      galleryImage {
        id
      }
    }
    ... on Project {
      id
      legacyId
      altName: name
    }
    ... on Set {
      id
      legacyId
      altName: name
      galleryImage {
        id
      }
    }
    ... on Template {
      id
      legacyId
      name
      galleryImage {
        id
      }
    }
  }
}
    `;
export const TagDetailsItemFragmentDoc = gql`
    fragment TagDetailsItem on Tag {
  id
  legacyId
  __typename
  color
  description
  name
  type
  createdBy {
    id
    name
    email
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const TagsGridItemFragmentDoc = gql`
    fragment TagsGridItem on Tag {
  id
  legacyId
  color
  description
  name
  organization {
    id
    legacyId
    name
  }
}
    `;
export const TagsTableItemFragmentDoc = gql`
    fragment TagsTableItem on Tag {
  id
  legacyId
  color
  description
  name
  type
  organization {
    id
    legacyId
    name
  }
  assignmentsCount
}
    `;
export const EditTaskTypeDialogFragmentDoc = gql`
    fragment EditTaskTypeDialog on JobTaskType {
  id
  cancelTimeout
  defaultTimeout
  endpoint
  invocationMethod
  maxTimeout
  queueGroup
  taskType
}
    `;
export const TaskTypesTableItemFragmentDoc = gql`
    fragment TaskTypesTableItem on JobTaskType {
  id
  cancelTimeout
  defaultTimeout
  endpoint
  invocationMethod
  maxTimeout
  queueGroup
  taskType
}
    `;
export const TaskDetailsFragmentDoc = gql`
    fragment TaskDetails on Task {
  id
  legacyId
  __typename
  comments {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    text
  }
  public
}
    `;
export const TaskListItemFragmentDoc = gql`
    fragment TaskListItem on Task {
  id
  createdAt
  number
  public
  state
  comments {
    createdAt
    text
  }
}
    `;
export const AssignUserDialogUserFragmentDoc = gql`
    fragment AssignUserDialogUser on User {
  id
  name
  galleryImage {
    id
  }
}
    `;
export const AssignUserFragmentDoc = gql`
    fragment AssignUser on User {
  id
  firstName
  lastName
  galleryImage {
    id
  }
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  id
  legacyId
  __typename
  dateJoined
  email
  emailNotifications
  firstName
  isActive
  isStaff
  isSuperuser
  lastName
  name
  systemRole: role
  visibleInFilters
  galleryImage {
    id
  }
  memberships {
    id
    organization {
      id
      name
    }
  }
}
    `;
export const UserListItemFragmentDoc = gql`
    fragment UserListItem on User {
  id
  legacyId
  firstName
  lastName
  name
  galleryImage {
    id
  }
}
    `;
export const VisualPinTaskPinFragmentDoc = gql`
    fragment VisualPinTaskPin on TaskPin {
  id
  x
  y
}
    `;
export const VisualPinTaskFragmentDoc = gql`
    fragment VisualPinTask on Task {
  id
  number
  pins {
    ...VisualPinTaskPin
  }
}
    ${VisualPinTaskPinFragmentDoc}`;
export const ScanRegionMaterialFragmentDoc = gql`
    fragment ScanRegionMaterial on Material {
  id
  legacyId
  articleId
  name
  state
  galleryImage {
    id
  }
}
    `;
export const BackendArGenerationTemplateRevisionFragmentDoc = gql`
    fragment BackendArGenerationTemplateRevision on TemplateRevision {
  id
  graph
  template {
    id
    organizationId
  }
  gltfAssignments: dataObjectAssignments(
    filter: {assignmentKey: $assignmentKey, assignmentType: CachedTemplateGltf}
  ) {
    id
    dataObject {
      id
      related {
        id
      }
    }
  }
}
    `;
export const MaterialsPageUserFragmentDoc = gql`
    fragment MaterialsPageUser on User {
  id
  name
}
    `;
export const ModelsPageUserFragmentDoc = gql`
    fragment ModelsPageUser on User {
  id
  name
}
    `;
export const OrganizationListItemFragmentDoc = gql`
    fragment OrganizationListItem on Organization {
  id
  legacyId
  name
  url
  galleryImage {
    id
  }
}
    `;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on Organization {
  __typename
  id
  legacyId
  name
  url
  visibleInFilters
  salesEmail
  defaultScannerUrl
  type
  galleryImage {
    id
  }
  nodeEditorTemplate {
    legacyId
  }
  matExplorerTemplate {
    legacyId
  }
  matExplorerMaterial {
    legacyId
  }
  dataObjectAssignments {
    id
    assignmentType
    dataObject {
      state
      bucketName
      objectName
      mediaType
    }
  }
}
    `;
export const SceneStatisticsDetailsFragmentDoc = gql`
    fragment SceneStatisticsDetails on SceneStatistics {
  id
  scene {
    id
  }
  created
}
    `;
export const ArStatisticsDetailsFragmentDoc = gql`
    fragment ArStatisticsDetails on ArStatistics {
  id
  sceneRevision {
    id
  }
  created
}
    `;
export const AssetStatisticsFragmentDoc = gql`
    fragment AssetStatistics on AssetHistory {
  id
  asset {
    id
  }
  nextActor
  state
  object: asset {
    id
    galleryImage {
      id
    }
  }
  createdBy {
    id
    galleryImage {
      id
    }
  }
}
    `;
export const MaterialStatisticsFragmentDoc = gql`
    fragment MaterialStatistics on MaterialHistory {
  id
  createdBy {
    id
    galleryImage {
      id
    }
  }
  material {
    id
  }
  nextActor
  object: material {
    id
    galleryImage {
      id
    }
  }
  state
}
    `;
export const ModelStatisticsFragmentDoc = gql`
    fragment ModelStatistics on ModelHistory {
  id
  createdBy {
    id
    galleryImage {
      id
    }
  }
  model {
    id
  }
  nextActor
  object: model {
    id
    galleryImage {
      id
    }
  }
  state
}
    `;
export const PictureStatisticsFragmentDoc = gql`
    fragment PictureStatistics on PictureHistory {
  id
  createdBy {
    id
    galleryImage {
      id
    }
  }
  nextActor
  object: picture {
    id
    galleryImage {
      id
    }
  }
  picture {
    id
    galleryImage {
      id
    }
  }
  state
}
    `;
export const TagListItemFragmentDoc = gql`
    fragment TagListItem on Tag {
  id
  legacyId
  name
  organization {
    id
    legacyId
    name
  }
  description
  color
  type
}
    `;
export const MaterialBatchOperationFragmentDoc = gql`
    fragment MaterialBatchOperation on Material {
  id
  legacyId
  name
}
    `;
export const BatchMaterialRenderDetailsFragmentDoc = gql`
    fragment BatchMaterialRenderDetails on Material {
  dataObjectAssignments(filter: $dataObjectAssignmentFilter) @include(if: $includeAssignments) {
    dataObject {
      id
    }
  }
  latestCyclesRevision {
    id
  }
}
    `;
export const DataObjectDetailsForBatchDownloadServiceFragmentDoc = gql`
    fragment DataObjectDetailsForBatchDownloadService on DataObject {
  id
  legacyId
  originalFileName
  mediaType
}
    `;
export const ImageDownloadDetailsForBatchDownloadServiceFragmentDoc = gql`
    fragment ImageDownloadDetailsForBatchDownloadService on DataObject {
  ...DataObjectDetailsForBatchDownloadService
  tiff: thumbnail(fileType: tiff, resolution: High) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegHigh: thumbnail(fileType: jpg, resolution: High) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegMedium: thumbnail(fileType: jpg, resolution: Medium) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegLow: thumbnail(fileType: jpg, resolution: Low) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegTiny: thumbnail(fileType: jpg, resolution: Tiny) {
    ...DataObjectDetailsForBatchDownloadService
  }
}
    ${DataObjectDetailsForBatchDownloadServiceFragmentDoc}`;
export const ResolveServicePictureRevisionFragmentDoc = gql`
    fragment ResolveServicePictureRevision on PictureRevision {
  id
  legacyId
  number
  dataObjectAssignments(filter: {assignmentType: [PictureData]}) {
    id
    dataObject {
      id
    }
  }
}
    `;
export const ResolveServiceTemplateFragmentDoc = gql`
    fragment ResolveServiceTemplate on Template {
  id
  legacyId
  revisions {
    id
    legacyId
  }
}
    `;
export const ResolveServicePictureFragmentDoc = gql`
    fragment ResolveServicePicture on Picture {
  id
  legacyId
  number
  state
  attachments: dataObjectAssignments(filter: {assignmentType: [Attachment]}) {
    id
    dataObject {
      id
    }
  }
  latestRevision {
    id
    legacyId
    number
  }
  revisions {
    ...ResolveServicePictureRevision
  }
  organization {
    id
    legacyId
  }
  template {
    ...ResolveServiceTemplate
  }
}
    ${ResolveServicePictureRevisionFragmentDoc}
${ResolveServiceTemplateFragmentDoc}`;
export const TasksServiceTaskPinFragmentDoc = gql`
    fragment TasksServiceTaskPin on TaskPin {
  id
  x
  y
}
    `;
export const TasksServiceTaskFragmentDoc = gql`
    fragment TasksServiceTask on Task {
  id
  __typename
  comments {
    createdAt
    text
  }
  createdAt
  number
  pins {
    ...TasksServiceTaskPin
  }
  public
  state
}
    ${TasksServiceTaskPinFragmentDoc}`;
export const PriceMappingPriceGraphFragmentDoc = gql`
    fragment PriceMappingPriceGraph on PriceGraph {
  id
  state
  templateId
  revisionNumber
  graph
}
    `;
export const PriceMappingPriceFragmentDoc = gql`
    fragment PriceMappingPrice on Price {
  idFromCatalog {
    originalId
  }
  currency
  price
}
    `;
export const PriceMappingIdFromCatalogToPriceGraphFragmentDoc = gql`
    fragment PriceMappingIdFromCatalogToPriceGraph on IdFromCatalogToPriceGraph {
  priceGraphUuid
  idFromCatalog {
    originalId
  }
}
    `;
export const PricingHelperPriceGraphFragmentDoc = gql`
    fragment PricingHelperPriceGraph on PriceGraph {
  id
  state
  templateId
  revisionNumber
  graph
}
    `;
export const PricingHelperPriceFragmentDoc = gql`
    fragment PricingHelperPrice on Price {
  idFromCatalog {
    originalId
  }
  currency
  price
}
    `;
export const DataObjectDetailsForDataObjectInspectorFragmentDoc = gql`
    fragment DataObjectDetailsForDataObjectInspector on DataObject {
  id
  legacyId
  createdAt
  createdBy {
    name
  }
  width
  height
  imageColorSpace
  size
  originalFileName
}
    `;
export const HdriDetailsForHdriLightInspectorFragmentDoc = gql`
    fragment HDRIDetailsForHDRILightInspector on Hdri {
  id
  legacyId
  name
}
    `;
export const DataObjectDetailsForImageInspectorFragmentDoc = gql`
    fragment DataObjectDetailsForImageInspector on DataObject {
  id
}
    `;
export const MaterialAssignmentDetailsForMaterialAssignmentInspectorFragmentDoc = gql`
    fragment MaterialAssignmentDetailsForMaterialAssignmentInspector on MaterialRevision {
  material {
    galleryImage {
      id
    }
  }
}
    `;
export const MaterialDetailsForMaterialReferenceInspectorFragmentDoc = gql`
    fragment MaterialDetailsForMaterialReferenceInspector on Material {
  id
  legacyId
  name
  organization {
    legacyId
    name
  }
  cyclesRevisions {
    id
    legacyId
    number
  }
  latestCyclesRevision {
    id
    legacyId
    number
  }
  galleryImage {
    id
  }
}
    `;
export const MaterialRevisionDetailsForMaterialReferenceInspectorFragmentDoc = gql`
    fragment MaterialRevisionDetailsForMaterialReferenceInspector on MaterialRevision {
  id
  legacyId
  material {
    ...MaterialDetailsForMaterialReferenceInspector
  }
}
    ${MaterialDetailsForMaterialReferenceInspectorFragmentDoc}`;
export const TemplateDetailsForTemplateInstanceInspectorFragmentDoc = gql`
    fragment TemplateDetailsForTemplateInstanceInspector on Template {
  id
  legacyId
  name
  galleryImage {
    id
  }
  revisions {
    id
    legacyId
    number
  }
  latestRevision {
    id
    legacyId
    number
  }
}
    `;
export const TemplateRevisionDetailsForTemplateInstanceInspectorFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateInstanceInspector on TemplateRevision {
  id
  legacyId
  template {
    ...TemplateDetailsForTemplateInstanceInspector
  }
}
    ${TemplateDetailsForTemplateInstanceInspectorFragmentDoc}`;
export const TemplateAddMaterialItemFragmentDoc = gql`
    fragment TemplateAddMaterialItem on Material {
  id
  legacyId
  name
  description
  organization {
    id
    name
  }
  galleryImage {
    id
  }
  latestCyclesRevision {
    id
    legacyId
  }
}
    `;
export const TemplateAddTemplateItemFragmentDoc = gql`
    fragment TemplateAddTemplateItem on Template {
  id
  legacyId
  name
  organization {
    id
    name
  }
  galleryImage {
    id
  }
  latestRevision {
    id
    legacyId
  }
}
    `;
export const TemplateDetailsForTemplateEditorFragmentDoc = gql`
    fragment TemplateDetailsForTemplateEditor on Template {
  id
  legacyId
  revisions {
    id
    number
    createdAt
  }
}
    `;
export const PictureDetailsForTemplateEditorFragmentDoc = gql`
    fragment PictureDetailsForTemplateEditor on Picture {
  id
  legacyId
  template {
    ...TemplateDetailsForTemplateEditor
  }
  organization {
    id
  }
}
    ${TemplateDetailsForTemplateEditorFragmentDoc}`;
export const TemplateRevisionDetailsForTemplateEditorFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateEditor on TemplateRevision {
  id
  legacyId
  graph
  number
  template {
    id
    legacyId
    name
    organization {
      id
      legacyId
    }
  }
}
    `;
export const JobDetailsForTemplateImageViewerFragmentDoc = gql`
    fragment JobDetailsForTemplateImageViewer on Job {
  id
  name
  output
  state
  progress
  message
}
    `;
export const JobAssignmentDetailsForTemplateImageViewerFragmentDoc = gql`
    fragment JobAssignmentDetailsForTemplateImageViewer on JobAssignment {
  id
  assignmentKey
  job {
    ...JobDetailsForTemplateImageViewer
  }
}
    ${JobDetailsForTemplateImageViewerFragmentDoc}`;
export const TemplateDetailsForTemplateTreeAddFragmentDoc = gql`
    fragment TemplateDetailsForTemplateTreeAdd on Template {
  id
  legacyId
  name
  latestRevision {
    id
    legacyId
  }
}
    `;
export const MaterialDetailsForTemplateTreeAddFragmentDoc = gql`
    fragment MaterialDetailsForTemplateTreeAdd on Material {
  id
  legacyId
  name
  latestCyclesRevision {
    id
    legacyId
  }
}
    `;
export const TemplateRevisionDetailsForTemplateTreeItemFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateTreeItem on TemplateRevision {
  id
  legacyId
  template {
    id
    legacyId
    type
    revisions {
      id
      legacyId
      graph
    }
  }
}
    `;
export const MaterialDetailsForTemplateTreeItemFragmentDoc = gql`
    fragment MaterialDetailsForTemplateTreeItem on Material {
  id
  legacyId
}
    `;
export const MaterialRevisionDetailsForTemplateTreeItemFragmentDoc = gql`
    fragment MaterialRevisionDetailsForTemplateTreeItem on MaterialRevision {
  id
  legacyId
  material {
    ...MaterialDetailsForTemplateTreeItem
  }
}
    ${MaterialDetailsForTemplateTreeItemFragmentDoc}`;
export const DataObjectDetailsForDataObjectBatchApiCallFragmentDoc = gql`
    fragment DataObjectDetailsForDataObjectBatchApiCall on DataObject {
  id
  legacyId
  width
  height
  downloadUrl
  mediaType
  originalFileName
  related {
    id
    legacyId
    width
    height
    downloadUrl
    mediaType
    originalFileName
  }
}
    `;
export const TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateRevisionBatchApiCall on TemplateRevision {
  id
  legacyId
  graph
}
    `;
export const DataObjectAssignmentDetailsForTemplateImageViewerFragmentDoc = gql`
    fragment DataObjectAssignmentDetailsForTemplateImageViewer on DataObjectAssignment {
  id
}
    `;
export const SelectTemplateItemFragmentDoc = gql`
    fragment SelectTemplateItem on Template {
  id
  id
  legacyId
  name
  organization {
    legacyId
    name
  }
  galleryImage {
    id
  }
}
    `;
export const TemplateDetailsFragmentDoc = gql`
    fragment TemplateDetails on Template {
  __typename
  id
  legacyId
  comment
  name
  public
  state
  type
  galleryImage {
    id
  }
  latestRevision {
    id
    legacyId
    createdAt
    createdBy {
      id
      legacyId
      name
    }
    graph
    renderedImages(take: 4) {
      id
    }
  }
  revisions {
    id
  }
  organization {
    id
    legacyId
  }
}
    `;
export const TemplateGraphMigrationTemplateRevisionFragmentDoc = gql`
    fragment TemplateGraphMigrationTemplateRevision on TemplateRevision {
  id
  graph
  template {
    id
    name
  }
}
    `;
export const DataObjectBatchApiCallDataObjectFragmentDoc = gql`
    fragment DataObjectBatchApiCallDataObject on DataObject {
  id
  legacyId
  height
  width
}
    `;
export const MeshDataBatchApiCallDataObjectFragmentDoc = gql`
    fragment MeshDataBatchApiCallDataObject on DataObject {
  id
  legacyId
  downloadUrl
}
    `;
export const SceneManagerTemplateRevisionFragmentDoc = gql`
    fragment SceneManagerTemplateRevision on TemplateRevision {
  id
  legacyId
  graph
  template {
    id
    name
  }
}
    `;
export const TemplateListItemFragmentDoc = gql`
    fragment TemplateListItem on Template {
  id
  legacyId
  name
  state
  galleryImage {
    id
  }
  type
  public
  comment
  organization {
    name
    id
  }
  latestRevision {
    id
    legacyId
    completed
    createdAt
    graph
    createdBy {
      id
    }
    renderedImages(take: 4) {
      id
    }
  }
}
    `;
export const TemplateWithLatestRevisionFragmentDoc = gql`
    fragment TemplateWithLatestRevision on Template {
  state
  type
  latestRevision {
    id
    legacyId
    createdAt
    graph
    createdBy {
      id
    }
  }
}
    `;
export const TextureSetRevisionDescriptorFragmentDoc = gql`
    fragment TextureSetRevisionDescriptor on TextureSetRevision {
  id
  name
  createdAt
}
    `;
export const TextureSetDataFragmentDoc = gql`
    fragment TextureSetData on TextureSet {
  id
  legacyId
  textureGroup {
    organization {
      id
      legacyId
    }
  }
  textureSetRevisions {
    ...TextureSetRevisionDescriptor
  }
}
    ${TextureSetRevisionDescriptorFragmentDoc}`;
export const TextureSetRevisionDataFragmentDoc = gql`
    fragment TextureSetRevisionData on TextureSetRevision {
  id
  createdAt
  createdBy {
    id
    name
  }
  updatedAt
  updatedBy {
    id
    name
  }
  name
  width
  height
  displacement
  editsJson
  editsProcessingJob {
    id
    state
    progress
  }
  mapAssignments {
    textureType
    dataObject {
      id
      thumbnail: thumbnail(fileType: jpg, resolution: Tiny) {
        downloadUrl
      }
      width
      height
    }
  }
  textureSet {
    ...TextureSetData
  }
  materialNodes {
    materialRevision {
      id
    }
  }
}
    ${TextureSetDataFragmentDoc}`;
export const TextureEditorDataObjectFragmentDoc = gql`
    fragment TextureEditorDataObject on DataObject {
  id
  legacyId
  width
  height
  size
  originalFileName
  mediaType
  imageDataType
  imageColorSpace
  originalDownloadUrl: downloadUrl
  jpgDataObject: thumbnail(fileType: jpg, resolution: High) {
    downloadUrl
  }
  tiffDataObject: thumbnail(fileType: tiff, resolution: High) {
    downloadUrl
  }
}
    `;
export const TilingInfoPanelTextureRevisionFragmentDoc = gql`
    fragment TilingInfoPanelTextureRevision on TextureRevision {
  id
  createdAt
  createdBy {
    firstName
    lastName
  }
  dataObject {
    height
    imageColorSpace
    size
    width
  }
  displacement
  height
  width
}
    `;
export const ScanJobDialogFragmentDoc = gql`
    fragment ScanJobDialog on ScanJob {
  id
  legacyId
  __typename
}
    `;
export const ScanJobDialogSubJobFragmentDoc = gql`
    fragment ScanJobDialogSubJob on ScanSubJob {
  id
  active
  completed
  started
  state
  tileX
  tileY
  progress
  logAssignments: dataObjectAssignments(filter: {assignmentType: ScanLog}) {
    dataObject {
      downloadUrl
    }
  }
  fittingAssignments: dataObjectAssignments(
    filter: {assignmentType: ScanFittingWorkPackage}
  ) {
    dataObject {
      downloadUrl
    }
  }
  assemblyAssignments: dataObjectAssignments(
    filter: {assignmentType: ScanAssemblyWorkPackage}
  ) {
    dataObject {
      downloadUrl
    }
  }
}
    `;
export const ScanJobFragmentDoc = gql`
    fragment ScanJob on ScanJob {
  id
  active
  completed
  createdAt
  sampleRotation
  started
  state
  createdBy {
    name
  }
  logAssignments: dataObjectAssignments(filter: {assignmentType: ScanLog}) {
    dataObject {
      downloadUrl
    }
  }
  activeSubJobsCount
  startedSubJobsCount
  subJobsCount
  completedSubJobsCount
  textureSet {
    textureGroup {
      organization {
        id
      }
    }
  }
}
    `;
export const TextureGroupDetailsFragmentDoc = gql`
    fragment TextureGroupDetails on TextureGroup {
  __typename
  id
  legacyId
  meshSpecific
  name
  galleryImage {
    id
  }
  material {
    id
    legacyId
  }
  organization {
    id
    legacyId
    name
  }
  textureSets {
    id
    legacyId
    name
    jobAssignments {
      id
    }
  }
}
    `;
export const TextureSetRevisionViewTextureSetFragmentDoc = gql`
    fragment TextureSetRevisionViewTextureSet on TextureSet {
  id
  textureGroup {
    organization {
      legacyId
    }
  }
}
    `;
export const TextureThumbnailViewDataObjectDetailsFragmentDoc = gql`
    fragment TextureThumbnailViewDataObjectDetails on DataObject {
  id
  legacyId
  imageColorSpace
  downloadUrl
  mediaType
  originalFileName
  width
  height
  thumbnailAvailable
  thumbnail(fileType: jpg, resolution: Low) {
    downloadUrl
  }
  organization {
    id
    legacyId
  }
}
    `;
export const TextureThumbnailViewDataObjectFragmentDoc = gql`
    fragment TextureThumbnailViewDataObject on DataObject {
  ...TextureThumbnailViewDataObjectDetails
  related {
    ...TextureThumbnailViewDataObjectDetails
  }
}
    ${TextureThumbnailViewDataObjectDetailsFragmentDoc}`;
export const TextureSetViewRevisionDataFragmentDoc = gql`
    fragment TextureSetViewRevisionData on TextureSetRevision {
  id
  name
  width
  height
  displacement
}
    `;
export const TextureSetViewDataFragmentDoc = gql`
    fragment TextureSetViewData on TextureSet {
  id
  legacyId
  name
  textureGroup {
    id
  }
  textureSetRevisions {
    ...TextureSetViewRevisionData
  }
}
    ${TextureSetViewRevisionDataFragmentDoc}`;
export const TextureGroupTextureSetIdsFragmentDoc = gql`
    fragment TextureGroupTextureSetIds on TextureGroup {
  id
  textureSets {
    id
  }
  organization {
    id
  }
}
    `;
export const CreateSalesEnquiryDocument = gql`
    mutation createSalesEnquiry($input: MutationCreateSalesEnquiryInput!) {
  createSalesEnquiry(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSalesEnquiryGQL extends Apollo.Mutation<CreateSalesEnquiryMutation, CreateSalesEnquiryMutationVariables> {
    override document = CreateSalesEnquiryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateDetailsForTemplateViewerDocument = gql`
    query getTemplateDetailsForTemplateViewer($pictureId: ID!) {
  picture(id: $pictureId) {
    template {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForTemplateViewerGQL extends Apollo.Query<GetTemplateDetailsForTemplateViewerQuery, GetTemplateDetailsForTemplateViewerQueryVariables> {
    override document = GetTemplateDetailsForTemplateViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssetActivityItemsDocument = gql`
    query assetActivityItems($id: ID!) {
  items: assetHistoryItems(
    filter: {assetId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetActivityItemsGQL extends Apollo.Query<AssetActivityItemsQuery, AssetActivityItemsQueryVariables> {
    override document = AssetActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialActivityItemsDocument = gql`
    query materialActivityItems($id: ID!) {
  items: materialHistoryItems(
    filter: {materialId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialActivityItemsGQL extends Apollo.Query<MaterialActivityItemsQuery, MaterialActivityItemsQueryVariables> {
    override document = MaterialActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ModelActivityItemsDocument = gql`
    query modelActivityItems($id: ID!) {
  items: modelHistoryItems(
    filter: {modelId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ModelActivityItemsGQL extends Apollo.Query<ModelActivityItemsQuery, ModelActivityItemsQueryVariables> {
    override document = ModelActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureActivityItemsDocument = gql`
    query pictureActivityItems($id: ID!) {
  items: pictureHistoryItems(
    filter: {pictureId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureActivityItemsGQL extends Apollo.Query<PictureActivityItemsQuery, PictureActivityItemsQueryVariables> {
    override document = PictureActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAttachmentDocument = gql`
    mutation createAttachment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAttachmentGQL extends Apollo.Mutation<CreateAttachmentMutation, CreateAttachmentMutationVariables> {
    override document = CreateAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAttachedFileDocument = gql`
    mutation deleteAttachedFile($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAttachedFileGQL extends Apollo.Mutation<DeleteAttachedFileMutation, DeleteAttachedFileMutationVariables> {
    override document = DeleteAttachedFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAttachmentDocument = gql`
    mutation updateAttachment($input: MutationUpdateDataObjectAssignmentInput!) {
  updateDataObjectAssignment(input: $input) {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttachmentGQL extends Apollo.Mutation<UpdateAttachmentMutation, UpdateAttachmentMutationVariables> {
    override document = UpdateAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AttachmentRenameDataObjectDocument = gql`
    mutation attachmentRenameDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    ...AttachedDataObject
  }
}
    ${AttachedDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AttachmentRenameDataObjectGQL extends Apollo.Mutation<AttachmentRenameDataObjectMutation, AttachmentRenameDataObjectMutationVariables> {
    override document = AttachmentRenameDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AttachmentsDocument = gql`
    query attachments($filter: DataObjectAssignmentFilterInput!) {
  dataObjectAssignments(filter: $filter) {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AttachmentsGQL extends Apollo.Query<AttachmentsQuery, AttachmentsQueryVariables> {
    override document = AttachmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSearchFilterTagsDocument = gql`
    query tagSearchFilterTags($filter: TagFilterInput) {
  tags(filter: $filter, take: 9999) {
    ...TagSearchFilterTag
  }
}
    ${TagSearchFilterTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSearchFilterTagsGQL extends Apollo.Query<TagSearchFilterTagsQuery, TagSearchFilterTagsQueryVariables> {
    override document = TagSearchFilterTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSearchFilterOrganizationsDocument = gql`
    query tagSearchFilterOrganizations($filter: OrganizationFilterInput) {
  organizations(filter: $filter, take: 9999) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSearchFilterOrganizationsGQL extends Apollo.Query<TagSearchFilterOrganizationsQuery, TagSearchFilterOrganizationsQueryVariables> {
    override document = TagSearchFilterOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSearchFilterUserDocument = gql`
    query tagSearchFilterUser($id: ID!) {
  user(id: $id) {
    memberships {
      organization {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSearchFilterUserGQL extends Apollo.Query<TagSearchFilterUserQuery, TagSearchFilterUserQueryVariables> {
    override document = TagSearchFilterUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectAssignDocument = gql`
    mutation tagSelectAssign($objectId: ID!, $contentTypeModel: ContentTypeModel!, $tagId: ID!) {
  createTagAssignment(
    input: {objectId: $objectId, tagId: $tagId, contentTypeModel: $contentTypeModel}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectAssignGQL extends Apollo.Mutation<TagSelectAssignMutation, TagSelectAssignMutationVariables> {
    override document = TagSelectAssignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectUnassignDocument = gql`
    mutation tagSelectUnassign($tagAssignmentId: ID!) {
  deleteTagAssignment(id: $tagAssignmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectUnassignGQL extends Apollo.Mutation<TagSelectUnassignMutation, TagSelectUnassignMutationVariables> {
    override document = TagSelectUnassignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectTagsDocument = gql`
    query tagSelectTags($filter: TagFilterInput!) {
  tags(filter: $filter, take: 9999) {
    ...TagSelectTag
  }
}
    ${TagSelectTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectTagsGQL extends Apollo.Query<TagSelectTagsQuery, TagSelectTagsQueryVariables> {
    override document = TagSelectTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectTagAssignmentsDocument = gql`
    query tagSelectTagAssignments($filter: TagAssignmentFilterInput!) {
  tagAssignments(filter: $filter) {
    id
    tag {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectTagAssignmentsGQL extends Apollo.Query<TagSelectTagAssignmentsQuery, TagSelectTagAssignmentsQueryVariables> {
    override document = TagSelectTagAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ItemListVisibleUsersDocument = gql`
    query itemListVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ItemListUser
  }
}
    ${ItemListUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ItemListVisibleUsersGQL extends Apollo.Query<ItemListVisibleUsersQuery, ItemListVisibleUsersQueryVariables> {
    override document = ItemListVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListInfoOrganizationLegacyIdDocument = gql`
    query listInfoOrganizationLegacyId($id: ID!) {
  organization(id: $id) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInfoOrganizationLegacyIdGQL extends Apollo.Query<ListInfoOrganizationLegacyIdQuery, ListInfoOrganizationLegacyIdQueryVariables> {
    override document = ListInfoOrganizationLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListInfoVisibleTagsDocument = gql`
    query listInfoVisibleTags($filter: TagFilterInput!) {
  tags(filter: $filter, take: 9999) {
    ...ListInfoTag
  }
}
    ${ListInfoTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInfoVisibleTagsGQL extends Apollo.Query<ListInfoVisibleTagsQuery, ListInfoVisibleTagsQueryVariables> {
    override document = ListInfoVisibleTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListInfoVisibleUsersDocument = gql`
    query listInfoVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ListInfoUser
  }
}
    ${ListInfoUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInfoVisibleUsersGQL extends Apollo.Query<ListInfoVisibleUsersQuery, ListInfoVisibleUsersQueryVariables> {
    override document = ListInfoVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfigMenuFetchMaterialDocument = gql`
    query configMenuFetchMaterial($materialLegacyId: Int!) {
  material(legacyId: $materialLegacyId) {
    ...ConfigMenuMaterial
    ...SceneViewerMaterial
  }
}
    ${ConfigMenuMaterialFragmentDoc}
${SceneViewerMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigMenuFetchMaterialGQL extends Apollo.Query<ConfigMenuFetchMaterialQuery, ConfigMenuFetchMaterialQueryVariables> {
    override document = ConfigMenuFetchMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfigMenuDataObjectDocument = gql`
    query configMenuDataObject($dataObjectLegacyId: Int!) {
  dataObject(legacyId: $dataObjectLegacyId) {
    ...ConfigMenuMaterialIcon
  }
}
    ${ConfigMenuMaterialIconFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigMenuDataObjectGQL extends Apollo.Query<ConfigMenuDataObjectQuery, ConfigMenuDataObjectQueryVariables> {
    override document = ConfigMenuDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectThumbnailForConfigMenuLegacyServiceDocument = gql`
    query dataObjectThumbnailForConfigMenuLegacyService($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    id
    thumbnail {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectThumbnailForConfigMenuLegacyServiceGQL extends Apollo.Query<DataObjectThumbnailForConfigMenuLegacyServiceQuery, DataObjectThumbnailForConfigMenuLegacyServiceQueryVariables> {
    override document = DataObjectThumbnailForConfigMenuLegacyServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavigationBarAllOrganizationsDocument = gql`
    query navigationBarAllOrganizations($filter: OrganizationFilterInput, $skip: Int!, $take: Int!) {
  organizations(filter: $filter, skip: $skip, take: $take) {
    ...NavigationBarOrganization
  }
}
    ${NavigationBarOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationBarAllOrganizationsGQL extends Apollo.Query<NavigationBarAllOrganizationsQuery, NavigationBarAllOrganizationsQueryVariables> {
    override document = NavigationBarAllOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavigationBarUserMembershipsDocument = gql`
    query navigationBarUserMemberships($id: ID!) {
  user(id: $id) {
    memberships {
      organization {
        ...NavigationBarOrganization
      }
    }
  }
}
    ${NavigationBarOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationBarUserMembershipsGQL extends Apollo.Query<NavigationBarUserMembershipsQuery, NavigationBarUserMembershipsQueryVariables> {
    override document = NavigationBarUserMembershipsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavigationBarColormassOrganizationDocument = gql`
    query navigationBarColormassOrganization {
  organizations(filter: {url: {equals: "colormass"}}) {
    ...NavigationBarOrganization
  }
}
    ${NavigationBarOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationBarColormassOrganizationGQL extends Apollo.Query<NavigationBarColormassOrganizationQuery, NavigationBarColormassOrganizationQueryVariables> {
    override document = NavigationBarColormassOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectThumbnailForConfigMenuServiceDocument = gql`
    query dataObjectThumbnailForConfigMenuService($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    id
    thumbnail {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectThumbnailForConfigMenuServiceGQL extends Apollo.Query<DataObjectThumbnailForConfigMenuServiceQuery, DataObjectThumbnailForConfigMenuServiceQueryVariables> {
    override document = DataObjectThumbnailForConfigMenuServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateDetailsForConfiguratorDocument = gql`
    query getTemplateDetailsForConfigurator($id: ID!) {
  template(id: $id) {
    ...TemplateDetailsForConfigurator
  }
}
    ${TemplateDetailsForConfiguratorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForConfiguratorGQL extends Apollo.Query<GetTemplateDetailsForConfiguratorQuery, GetTemplateDetailsForConfiguratorQueryVariables> {
    override document = GetTemplateDetailsForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateUuidFromSceneLegacyIdForConfiguratorDocument = gql`
    query getTemplateUuidFromSceneLegacyIdForConfigurator($sceneLegacyId: Int!) {
  scene(legacyId: $sceneLegacyId) {
    picture {
      template {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateUuidFromSceneLegacyIdForConfiguratorGQL extends Apollo.Query<GetTemplateUuidFromSceneLegacyIdForConfiguratorQuery, GetTemplateUuidFromSceneLegacyIdForConfiguratorQueryVariables> {
    override document = GetTemplateUuidFromSceneLegacyIdForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LatestRevisionFromMaterialIdForConfiguratorDocument = gql`
    query latestRevisionFromMaterialIdForConfigurator($materialId: ID!) {
  material(id: $materialId) {
    id
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LatestRevisionFromMaterialIdForConfiguratorGQL extends Apollo.Query<LatestRevisionFromMaterialIdForConfiguratorQuery, LatestRevisionFromMaterialIdForConfiguratorQueryVariables> {
    override document = LatestRevisionFromMaterialIdForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LatestRevisionFromMaterialLegacyIdForConfiguratorDocument = gql`
    query latestRevisionFromMaterialLegacyIdForConfigurator($materialLegacyId: Int!) {
  material(legacyId: $materialLegacyId) {
    id
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LatestRevisionFromMaterialLegacyIdForConfiguratorGQL extends Apollo.Query<LatestRevisionFromMaterialLegacyIdForConfiguratorQuery, LatestRevisionFromMaterialLegacyIdForConfiguratorQueryVariables> {
    override document = LatestRevisionFromMaterialLegacyIdForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialIdFromLegacyIdDocument = gql`
    query materialIdFromLegacyId($materialLegacyId: Int!) {
  material(legacyId: $materialLegacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialIdFromLegacyIdGQL extends Apollo.Query<MaterialIdFromLegacyIdQuery, MaterialIdFromLegacyIdQueryVariables> {
    override document = MaterialIdFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectAssignmentsForArServiceDocument = gql`
    query getDataObjectAssignmentsForArService($filter: DataObjectAssignmentFilterInput!) {
  dataObjectAssignments(filter: $filter) {
    id
    dataObject {
      ...DataObjectWithRelatedForArService
    }
  }
}
    ${DataObjectWithRelatedForArServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectAssignmentsForArServiceGQL extends Apollo.Query<GetDataObjectAssignmentsForArServiceQuery, GetDataObjectAssignmentsForArServiceQueryVariables> {
    override document = GetDataObjectAssignmentsForArServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateIdForArServiceDocument = gql`
    query getTemplateIdForArService($templateRevisionId: ID) {
  templateRevision(id: $templateRevisionId) {
    id
    template {
      id
      legacyId
      organizationId
      organizationLegacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateIdForArServiceGQL extends Apollo.Query<GetTemplateIdForArServiceQuery, GetTemplateIdForArServiceQueryVariables> {
    override document = GetTemplateIdForArServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobTaskForArServiceDocument = gql`
    query getJobTaskForArService($id: ID!) {
  jobTask(id: $id) {
    id
    state
    output
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobTaskForArServiceGQL extends Apollo.Query<GetJobTaskForArServiceQuery, GetJobTaskForArServiceQueryVariables> {
    override document = GetJobTaskForArServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArServiceCreateJobDocument = gql`
    mutation arServiceCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArServiceCreateJobGQL extends Apollo.Mutation<ArServiceCreateJobMutation, ArServiceCreateJobMutationVariables> {
    override document = ArServiceCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImageViewerItemsDocument = gql`
    query imageViewerItems($ids: [ID!]!) {
  dataObjects(filter: {id: {in: $ids}}) {
    ...ImageViewerItem
  }
}
    ${ImageViewerItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageViewerItemsGQL extends Apollo.Query<ImageViewerItemsQuery, ImageViewerItemsQueryVariables> {
    override document = ImageViewerItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerDataObjectDocument = gql`
    query sceneViewerDataObject($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    ...SceneViewerDataObject
    downloadUrl
    objectName
    related {
      downloadUrl
      objectName
      mediaType
    }
  }
}
    ${SceneViewerDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerDataObjectGQL extends Apollo.Query<SceneViewerDataObjectQuery, SceneViewerDataObjectQueryVariables> {
    override document = SceneViewerDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerMaterialDocument = gql`
    query sceneViewerMaterial($legacyId: Int!) {
  material(legacyId: $legacyId) {
    ...SceneViewerMaterial
  }
}
    ${SceneViewerMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerMaterialGQL extends Apollo.Query<SceneViewerMaterialQuery, SceneViewerMaterialQueryVariables> {
    override document = SceneViewerMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerTemplateDocument = gql`
    query sceneViewerTemplate($legacyId: Int!) {
  template(legacyId: $legacyId) {
    ...SceneViewerTemplate
  }
}
    ${SceneViewerTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerTemplateGQL extends Apollo.Query<SceneViewerTemplateQuery, SceneViewerTemplateQueryVariables> {
    override document = SceneViewerTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerTemplateLegacyIdFromUuidDocument = gql`
    query sceneViewerTemplateLegacyIdFromUuid($id: ID!) {
  template(id: $id) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerTemplateLegacyIdFromUuidGQL extends Apollo.Query<SceneViewerTemplateLegacyIdFromUuidQuery, SceneViewerTemplateLegacyIdFromUuidQueryVariables> {
    override document = SceneViewerTemplateLegacyIdFromUuidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerTemplateUuidFromLegacyIdDocument = gql`
    query sceneViewerTemplateUuidFromLegacyId($legacyId: Int!) {
  scene(legacyId: $legacyId) {
    picture {
      template {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerTemplateUuidFromLegacyIdGQL extends Apollo.Query<SceneViewerTemplateUuidFromLegacyIdQuery, SceneViewerTemplateUuidFromLegacyIdQueryVariables> {
    override document = SceneViewerTemplateUuidFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerPdfTemplateDocument = gql`
    query sceneViewerPdfTemplate($organizationLegacyId: Int!) {
  organization(legacyId: $organizationLegacyId) {
    pdfTemplate {
      bucketName
      objectName
    }
    font {
      bucketName
      objectName
    }
    fontBold {
      bucketName
      objectName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerPdfTemplateGQL extends Apollo.Query<SceneViewerPdfTemplateQuery, SceneViewerPdfTemplateQueryVariables> {
    override document = SceneViewerPdfTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerDataObjectAssignmentsDocument = gql`
    query sceneViewerDataObjectAssignments($filter: DataObjectAssignmentFilterInput!) {
  dataObjectAssignments(filter: $filter) {
    id
    dataObject {
      ...SceneViewerDataObject
    }
  }
}
    ${SceneViewerDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerDataObjectAssignmentsGQL extends Apollo.Query<SceneViewerDataObjectAssignmentsQuery, SceneViewerDataObjectAssignmentsQueryVariables> {
    override document = SceneViewerDataObjectAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerCreateJobDocument = gql`
    mutation sceneViewerCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerCreateJobGQL extends Apollo.Mutation<SceneViewerCreateJobMutation, SceneViewerCreateJobMutationVariables> {
    override document = SceneViewerCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneViewerJobTaskStateWithOutputDocument = gql`
    query sceneViewerJobTaskStateWithOutput($id: ID!) {
  jobTask(id: $id) {
    id
    state
    output
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneViewerJobTaskStateWithOutputGQL extends Apollo.Query<SceneViewerJobTaskStateWithOutputQuery, SceneViewerJobTaskStateWithOutputQueryVariables> {
    override document = SceneViewerJobTaskStateWithOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsHelperStateUpdateDocument = gql`
    query jobsHelperStateUpdate($ids: [ID!]!) {
  jobs(filter: {id: {in: $ids}}) {
    id
    message
    progress
    started
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsHelperStateUpdateGQL extends Apollo.Query<JobsHelperStateUpdateQuery, JobsHelperStateUpdateQueryVariables> {
    override document = JobsHelperStateUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateDetailsForMaterialAssetsRenderingDocument = gql`
    query getTemplateDetailsForMaterialAssetsRendering($legacyId: Int!) {
  template(legacyId: $legacyId) {
    ...TemplateDetailsForMaterialAssetsRendering
  }
}
    ${TemplateDetailsForMaterialAssetsRenderingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForMaterialAssetsRenderingGQL extends Apollo.Query<GetTemplateDetailsForMaterialAssetsRenderingQuery, GetTemplateDetailsForMaterialAssetsRenderingQueryVariables> {
    override document = GetTemplateDetailsForMaterialAssetsRenderingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryMaterialConverterTextureSetRevisionDocument = gql`
    query queryMaterialConverterTextureSetRevision($textureSetRevisionId: ID!) {
  textureSetRevision(id: $textureSetRevisionId) {
    id
    width
    height
    mapAssignments {
      textureType
      dataObject {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryMaterialConverterTextureSetRevisionGQL extends Apollo.Query<QueryMaterialConverterTextureSetRevisionQuery, QueryMaterialConverterTextureSetRevisionQueryVariables> {
    override document = QueryMaterialConverterTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialCreateJobDocument = gql`
    mutation batchExportMaterialCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialCreateJobGQL extends Apollo.Mutation<BatchExportMaterialCreateJobMutation, BatchExportMaterialCreateJobMutationVariables> {
    override document = BatchExportMaterialCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialCancelJobDocument = gql`
    mutation batchExportMaterialCancelJob($id: ID!) {
  cancelJob(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialCancelJobGQL extends Apollo.Mutation<BatchExportMaterialCancelJobMutation, BatchExportMaterialCancelJobMutationVariables> {
    override document = BatchExportMaterialCancelJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialGetJobStateDocument = gql`
    query batchExportMaterialGetJobState($id: ID!) {
  job(id: $id) {
    id
    progress
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialGetJobStateGQL extends Apollo.Query<BatchExportMaterialGetJobStateQuery, BatchExportMaterialGetJobStateQueryVariables> {
    override document = BatchExportMaterialGetJobStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialGetJobDetailsDocument = gql`
    query batchExportMaterialGetJobDetails($id: ID!) {
  job(id: $id) {
    id
    output
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialGetJobDetailsGQL extends Apollo.Query<BatchExportMaterialGetJobDetailsQuery, BatchExportMaterialGetJobDetailsQueryVariables> {
    override document = BatchExportMaterialGetJobDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthSiloDataDocument = gql`
    query authSiloData($id: ID!) {
  user(id: $id) {
    ...AuthenticatedUser
  }
}
    ${AuthenticatedUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthSiloDataGQL extends Apollo.Query<AuthSiloDataQuery, AuthSiloDataQueryVariables> {
    override document = AuthSiloDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryDataObjectThumbnailsDocument = gql`
    query queryDataObjectThumbnails($ids: [ID!], $resolution: DownloadResolution!) {
  dataObjects(filter: {id: {in: $ids}}, take: 10000) {
    ...DataObjectThumbnail
  }
}
    ${DataObjectThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectThumbnailsGQL extends Apollo.Query<QueryDataObjectThumbnailsQuery, QueryDataObjectThumbnailsQueryVariables> {
    override document = QueryDataObjectThumbnailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadableFileDocument = gql`
    query downloadableFile($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    ...DownloadableFile
  }
}
    ${DownloadableFileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadableFileGQL extends Apollo.Query<DownloadableFileQuery, DownloadableFileQueryVariables> {
    override document = DownloadableFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GalleryImageAssignmentsDocument = gql`
    query galleryImageAssignments($objectId: ID!, $contentTypeModel: ContentTypeModel, $assignmentType: [DataObjectAssignmentType!]) {
  galleryImageAssignments: dataObjectAssignments(
    filter: {objectId: $objectId, contentTypeModel: $contentTypeModel, assignmentType: $assignmentType}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GalleryImageAssignmentsGQL extends Apollo.Query<GalleryImageAssignmentsQuery, GalleryImageAssignmentsQueryVariables> {
    override document = GalleryImageAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GalleryImageAssignmentRemoveDocument = gql`
    mutation galleryImageAssignmentRemove($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GalleryImageAssignmentRemoveGQL extends Apollo.Mutation<GalleryImageAssignmentRemoveMutation, GalleryImageAssignmentRemoveMutationVariables> {
    override document = GalleryImageAssignmentRemoveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GalleryImageCountDocument = gql`
    query galleryImageCount($objectId: ID!, $contentTypeModel: ContentTypeModel!, $assignmentType: [DataObjectAssignmentType!]) {
  galleryImageCount: dataObjectAssignmentsCount(
    filter: {objectId: $objectId, contentTypeModel: $contentTypeModel, assignmentType: $assignmentType}
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GalleryImageCountGQL extends Apollo.Query<GalleryImageCountQuery, GalleryImageCountQueryVariables> {
    override document = GalleryImageCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialAssetsRenderingMaterialDocument = gql`
    query materialAssetsRenderingMaterial($materialId: ID!) {
  material(id: $materialId) {
    ...MaterialAssetsRenderingMaterial
  }
}
    ${MaterialAssetsRenderingMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialAssetsRenderingMaterialGQL extends Apollo.Query<MaterialAssetsRenderingMaterialQuery, MaterialAssetsRenderingMaterialQueryVariables> {
    override document = MaterialAssetsRenderingMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialAssetsRenderingCreateJobTaskDocument = gql`
    mutation materialAssetsRenderingCreateJobTask($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialAssetsRenderingCreateJobTaskGQL extends Apollo.Mutation<MaterialAssetsRenderingCreateJobTaskMutation, MaterialAssetsRenderingCreateJobTaskMutationVariables> {
    override document = MaterialAssetsRenderingCreateJobTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsCardDataDocument = gql`
    query materialsCardData($ids: [ID!]) {
  materials(filter: {id: {in: $ids}}, take: 10000) {
    ...MaterialsCard
  }
}
    ${MaterialsCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsCardDataGQL extends Apollo.Query<MaterialsCardDataQuery, MaterialsCardDataQueryVariables> {
    override document = MaterialsCardDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionForMaterialGraphDocument = gql`
    query materialRevisionForMaterialGraph($legacyId: Int, $id: ID) {
  materialRevision(legacyId: $legacyId, id: $id) {
    ...MaterialGraphExtendedRevision
  }
}
    ${MaterialGraphExtendedRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionForMaterialGraphGQL extends Apollo.Query<MaterialRevisionForMaterialGraphQuery, MaterialRevisionForMaterialGraphQueryVariables> {
    override document = MaterialRevisionForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionsForMaterialGraphDocument = gql`
    query materialRevisionsForMaterialGraph($legacyIds: [Int!]) {
  materialRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MaterialGraphExtendedRevision
  }
}
    ${MaterialGraphExtendedRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionsForMaterialGraphGQL extends Apollo.Query<MaterialRevisionsForMaterialGraphQuery, MaterialRevisionsForMaterialGraphQueryVariables> {
    override document = MaterialRevisionsForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureRevisionForMaterialGraphDocument = gql`
    query textureRevisionForMaterialGraph($legacyId: Int, $id: ID) {
  textureRevision(legacyId: $legacyId, id: $id) {
    ...MaterialGraphTextureRevision
  }
}
    ${MaterialGraphTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureRevisionForMaterialGraphGQL extends Apollo.Query<TextureRevisionForMaterialGraphQuery, TextureRevisionForMaterialGraphQueryVariables> {
    override document = TextureRevisionForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureRevisionsForMaterialGraphDocument = gql`
    query textureRevisionsForMaterialGraph($legacyIds: [Int!]) {
  textureRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MaterialGraphTextureRevision
  }
}
    ${MaterialGraphTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureRevisionsForMaterialGraphGQL extends Apollo.Query<TextureRevisionsForMaterialGraphQuery, TextureRevisionsForMaterialGraphQueryVariables> {
    override document = TextureRevisionsForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectForMaterialGraphDocument = gql`
    query dataObjectForMaterialGraph($legacyId: Int, $id: ID) {
  dataObject(legacyId: $legacyId, id: $id) {
    ...MaterialGraphDataObjectDetails
  }
}
    ${MaterialGraphDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectForMaterialGraphGQL extends Apollo.Query<DataObjectForMaterialGraphQuery, DataObjectForMaterialGraphQueryVariables> {
    override document = DataObjectForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectsForMaterialGraphDocument = gql`
    query dataObjectsForMaterialGraph($legacyIds: [Int!]) {
  dataObjects(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MaterialGraphDataObjectDetails
  }
}
    ${MaterialGraphDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectsForMaterialGraphGQL extends Apollo.Query<DataObjectsForMaterialGraphQuery, DataObjectsForMaterialGraphQueryVariables> {
    override document = DataObjectsForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RelatedDataObjectForMaterialGraphDocument = gql`
    query relatedDataObjectForMaterialGraph($fileType: DownloadFileType!, $resolution: DownloadResolution!, $legacyId: Int, $id: ID) {
  dataObject(legacyId: $legacyId, id: $id) {
    thumbnail(fileType: $fileType, resolution: $resolution) {
      legacyId
      imageColorSpace
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RelatedDataObjectForMaterialGraphGQL extends Apollo.Query<RelatedDataObjectForMaterialGraphQuery, RelatedDataObjectForMaterialGraphQueryVariables> {
    override document = RelatedDataObjectForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NormalToDisplacementStepTextureRevisionDocument = gql`
    query NormalToDisplacementStepTextureRevision($id: ID, $legacyId: Int) {
  textureRevision(id: $id, legacyId: $legacyId) {
    createdByVersion
    texture {
      textureSet {
        textures {
          type
          revisions {
            ...MapsExportTextureRevision
            createdByVersion
          }
        }
      }
    }
  }
}
    ${MapsExportTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NormalToDisplacementStepTextureRevisionGQL extends Apollo.Query<NormalToDisplacementStepTextureRevisionQuery, NormalToDisplacementStepTextureRevisionQueryVariables> {
    override document = NormalToDisplacementStepTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialConnectionForMapsExportDocument = gql`
    query materialConnectionForMapsExport($id: ID) {
  materialConnection(id: $id) {
    ...MaterialMapsExporterConnection
  }
}
    ${MaterialMapsExporterConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialConnectionForMapsExportGQL extends Apollo.Query<MaterialConnectionForMapsExportQuery, MaterialConnectionForMapsExportQueryVariables> {
    override document = MaterialConnectionForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialNodeForMapsExportDocument = gql`
    query materialNodeForMapsExport($id: ID) {
  materialNode(id: $id) {
    ...MaterialMapsExporterNode
    textureRevision {
      ...MapsExportTextureRevision
    }
  }
}
    ${MaterialMapsExporterNodeFragmentDoc}
${MapsExportTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialNodeForMapsExportGQL extends Apollo.Query<MaterialNodeForMapsExportQuery, MaterialNodeForMapsExportQueryVariables> {
    override document = MaterialNodeForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionForMapsExportDocument = gql`
    query materialRevisionForMapsExport($id: ID, $legacyId: Int) {
  material(id: $id, legacyId: $legacyId) {
    latestCyclesRevision {
      ...MaterialRevisionForMapsExport
    }
  }
}
    ${MaterialRevisionForMapsExportFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionForMapsExportGQL extends Apollo.Query<MaterialRevisionForMapsExportQuery, MaterialRevisionForMapsExportQueryVariables> {
    override document = MaterialRevisionForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialForMapsExportDocument = gql`
    query materialForMapsExport($id: ID, $legacyId: Int) {
  material(id: $id, legacyId: $legacyId) {
    ...MapsExportMaterialDetails
  }
}
    ${MapsExportMaterialDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialForMapsExportGQL extends Apollo.Query<MaterialForMapsExportQuery, MaterialForMapsExportQueryVariables> {
    override document = MaterialForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterDeleteDataObjectDocument = gql`
    mutation materialMapsExporterDeleteDataObject($legacyId: Int!) {
  deleteDataObject(legacyId: $legacyId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterDeleteDataObjectGQL extends Apollo.Mutation<MaterialMapsExporterDeleteDataObjectMutation, MaterialMapsExporterDeleteDataObjectMutationVariables> {
    override document = MaterialMapsExporterDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterDeleteJsonFileDocument = gql`
    mutation materialMapsExporterDeleteJsonFile($legacyId: Int!) {
  deleteJsonFile(legacyId: $legacyId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterDeleteJsonFileGQL extends Apollo.Mutation<MaterialMapsExporterDeleteJsonFileMutation, MaterialMapsExporterDeleteJsonFileMutationVariables> {
    override document = MaterialMapsExporterDeleteJsonFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterUpdateDataObjectDocument = gql`
    mutation materialMapsExporterUpdateDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterUpdateDataObjectGQL extends Apollo.Mutation<MaterialMapsExporterUpdateDataObjectMutation, MaterialMapsExporterUpdateDataObjectMutationVariables> {
    override document = MaterialMapsExporterUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterCreateJobDocument = gql`
    mutation materialMapsExporterCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterCreateJobGQL extends Apollo.Mutation<MaterialMapsExporterCreateJobMutation, MaterialMapsExporterCreateJobMutationVariables> {
    override document = MaterialMapsExporterCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterUpdateJsonFileDocument = gql`
    mutation materialMapsExporterUpdateJsonFile($input: MutationUpdateJsonFileInput!) {
  updateJsonFile(input: $input) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterUpdateJsonFileGQL extends Apollo.Mutation<MaterialMapsExporterUpdateJsonFileMutation, MaterialMapsExporterUpdateJsonFileMutationVariables> {
    override document = MaterialMapsExporterUpdateJsonFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterCreateJsonFileAssignmentDocument = gql`
    mutation materialMapsExporterCreateJsonFileAssignment($input: MutationCreateJsonFileAssignmentInput!) {
  createJsonFileAssignment(input: $input) {
    id
    jsonFile {
      content
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterCreateJsonFileAssignmentGQL extends Apollo.Mutation<MaterialMapsExporterCreateJsonFileAssignmentMutation, MaterialMapsExporterCreateJsonFileAssignmentMutationVariables> {
    override document = MaterialMapsExporterCreateJsonFileAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterJsonFileAssignmentsDocument = gql`
    query materialMapsExporterJsonFileAssignments($filter: JsonFileAssignmentFilterInput!) {
  jsonFileAssignments(filter: $filter) {
    id
    assignmentType
    jsonFile {
      content
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterJsonFileAssignmentsGQL extends Apollo.Query<MaterialMapsExporterJsonFileAssignmentsQuery, MaterialMapsExporterJsonFileAssignmentsQueryVariables> {
    override document = MaterialMapsExporterJsonFileAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialForNameAssetFromSchemaServiceDocument = gql`
    query materialForNameAssetFromSchemaService($materialId: ID!, $dataObjectAssignmentFilter: DataObjectAssignmentFilterInput, $includeRelated: Boolean!) {
  material(id: $materialId) {
    ...MaterialDetailsForNameAssetFromSchemaService
  }
}
    ${MaterialDetailsForNameAssetFromSchemaServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialForNameAssetFromSchemaServiceGQL extends Apollo.Query<MaterialForNameAssetFromSchemaServiceQuery, MaterialForNameAssetFromSchemaServiceQueryVariables> {
    override document = MaterialForNameAssetFromSchemaServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsForNameAssetFromSchemaServiceDocument = gql`
    query materialsForNameAssetFromSchemaService($filter: MaterialFilterInput!, $dataObjectAssignmentFilter: DataObjectAssignmentFilterInput, $includeRelated: Boolean = false) {
  materials(filter: $filter) {
    ...MaterialDetailsForNameAssetFromSchemaService
  }
}
    ${MaterialDetailsForNameAssetFromSchemaServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsForNameAssetFromSchemaServiceGQL extends Apollo.Query<MaterialsForNameAssetFromSchemaServiceQuery, MaterialsForNameAssetFromSchemaServiceQueryVariables> {
    override document = MaterialsForNameAssetFromSchemaServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NameAssetFromSchemaUpdateDataObjectDocument = gql`
    mutation nameAssetFromSchemaUpdateDataObject($input: MutationUpdateDataObjectInput!, $includeRelated: Boolean! = false) {
  updateDataObject(input: $input) {
    ...DataObjectDetailsForNameAssetFromSchemaService
  }
}
    ${DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NameAssetFromSchemaUpdateDataObjectGQL extends Apollo.Mutation<NameAssetFromSchemaUpdateDataObjectMutation, NameAssetFromSchemaUpdateDataObjectMutationVariables> {
    override document = NameAssetFromSchemaUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllOrganizationsDocument = gql`
    query allOrganizations($skip: Int!, $take: Int!) {
  organizations(skip: $skip, take: $take) {
    ...BasicOrganizationInfo
  }
}
    ${BasicOrganizationInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AllOrganizationsGQL extends Apollo.Query<AllOrganizationsQuery, AllOrganizationsQueryVariables> {
    override document = AllOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationMembershipsDocument = gql`
    query organizationMemberships($userId: ID!) {
  user(id: $userId) {
    id
    role
    memberships {
      ...BasicOrganizationMembershipInfo
    }
  }
}
    ${BasicOrganizationMembershipInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationMembershipsGQL extends Apollo.Query<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables> {
    override document = OrganizationMembershipsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentStateLabelForMaterialsDocument = gql`
    query paymentStateLabelForMaterials($ids: [ID!]) {
  materials(filter: {id: {in: $ids}}, take: 10000) {
    ...PaymentStateLabelForMaterial
  }
}
    ${PaymentStateLabelForMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStateLabelForMaterialsGQL extends Apollo.Query<PaymentStateLabelForMaterialsQuery, PaymentStateLabelForMaterialsQueryVariables> {
    override document = PaymentStateLabelForMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentStateLabelForModelsDocument = gql`
    query paymentStateLabelForModels($ids: [ID!]) {
  models(filter: {id: {in: $ids}}, take: 10000) {
    ...PaymentStateLabelForModel
  }
}
    ${PaymentStateLabelForModelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStateLabelForModelsGQL extends Apollo.Query<PaymentStateLabelForModelsQuery, PaymentStateLabelForModelsQueryVariables> {
    override document = PaymentStateLabelForModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentStateLabelForPicturesDocument = gql`
    query paymentStateLabelForPictures($ids: [ID!]) {
  pictures(filter: {id: {in: $ids}}, take: 10000) {
    ...PaymentStateLabelForPicture
  }
}
    ${PaymentStateLabelForPictureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStateLabelForPicturesGQL extends Apollo.Query<PaymentStateLabelForPicturesQuery, PaymentStateLabelForPicturesQueryVariables> {
    override document = PaymentStateLabelForPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPdfDataForPdfServiceOrgaIdDocument = gql`
    query getPdfDataForPdfServiceOrgaId($organizationLegacyId: Int!) {
  organization(legacyId: $organizationLegacyId) {
    ...PdfDataForPdfService
  }
}
    ${PdfDataForPdfServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPdfDataForPdfServiceOrgaIdGQL extends Apollo.Query<GetPdfDataForPdfServiceOrgaIdQuery, GetPdfDataForPdfServiceOrgaIdQueryVariables> {
    override document = GetPdfDataForPdfServiceOrgaIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPdfDataForPdfServiceTemplateRevisionIdDocument = gql`
    query getPdfDataForPdfServiceTemplateRevisionId($templateRevisionId: ID!) {
  templateRevision(id: $templateRevisionId) {
    id
    template {
      organization {
        ...PdfDataForPdfService
      }
    }
  }
}
    ${PdfDataForPdfServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPdfDataForPdfServiceTemplateRevisionIdGQL extends Apollo.Query<GetPdfDataForPdfServiceTemplateRevisionIdQuery, GetPdfDataForPdfServiceTemplateRevisionIdQueryVariables> {
    override document = GetPdfDataForPdfServiceTemplateRevisionIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePriceGraphDocument = gql`
    mutation createPriceGraph($input: MutationCreatePriceGraphInput!) {
  createPriceGraph(input: $input) {
    id
    state
    graph
    revisionNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePriceGraphGQL extends Apollo.Mutation<CreatePriceGraphMutation, CreatePriceGraphMutationVariables> {
    override document = CreatePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePriceGraphDocument = gql`
    mutation updatePriceGraph($input: MutationUpdatePriceGraphInput!) {
  updatePriceGraph(input: $input) {
    state
    graph
    revisionNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePriceGraphGQL extends Apollo.Mutation<UpdatePriceGraphMutation, UpdatePriceGraphMutationVariables> {
    override document = UpdatePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePriceGraphDocument = gql`
    mutation deletePriceGraph($priceGraphUuid: ID!) {
  deletePriceGraph(priceGraphId: $priceGraphUuid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePriceGraphGQL extends Apollo.Mutation<DeletePriceGraphMutation, DeletePriceGraphMutationVariables> {
    override document = DeletePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePricesDocument = gql`
    mutation createPrices($prices: [PriceInput!]!, $organizationUuid: ID!) {
  createPrices(prices: $prices, organizationId: $organizationUuid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePricesGQL extends Apollo.Mutation<CreatePricesMutation, CreatePricesMutationVariables> {
    override document = CreatePricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPriceGraphDocument = gql`
    query getPriceGraph($filter: PriceGraphFilterInput!) {
  priceGraphs(filter: $filter) {
    ...PriceGraphDetails
  }
}
    ${PriceGraphDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPriceGraphGQL extends Apollo.Query<GetPriceGraphQuery, GetPriceGraphQueryVariables> {
    override document = GetPriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPriceDocument = gql`
    query getPrice($filter: PriceFilterInput!, $take: Int, $skip: Int) {
  prices(filter: $filter, take: $take, skip: $skip) {
    ...PriceDetails
  }
}
    ${PriceDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPriceGQL extends Apollo.Query<GetPriceQuery, GetPriceQueryVariables> {
    override document = GetPriceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HdriForRenderingDocument = gql`
    query hdriForRendering($id: ID, $legacyId: Int) {
  hdri(id: $id, legacyId: $legacyId) {
    dataObject {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HdriForRenderingGQL extends Apollo.Query<HdriForRenderingQuery, HdriForRenderingQueryVariables> {
    override document = HdriForRenderingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenderingServiceCreateJobDocument = gql`
    mutation renderingServiceCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenderingServiceCreateJobGQL extends Apollo.Mutation<RenderingServiceCreateJobMutation, RenderingServiceCreateJobMutationVariables> {
    override document = RenderingServiceCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SdkServiceDataObjectDocument = gql`
    query sdkServiceDataObject($id: ID!) {
  dataObject(id: $id) {
    id
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SdkServiceDataObjectGQL extends Apollo.Query<SdkServiceDataObjectQuery, SdkServiceDataObjectQueryVariables> {
    override document = SdkServiceDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SdkServiceOrganizationDocument = gql`
    query sdkServiceOrganization($id: ID!) {
  organization(id: $id) {
    id
    name
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SdkServiceOrganizationGQL extends Apollo.Query<SdkServiceOrganizationQuery, SdkServiceOrganizationQueryVariables> {
    override document = SdkServiceOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SdkServiceColormassOrganizationDocument = gql`
    query sdkServiceColormassOrganization {
  organizations(filter: {url: {equals: "colormass"}}) {
    id
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SdkServiceColormassOrganizationGQL extends Apollo.Query<SdkServiceColormassOrganizationQuery, SdkServiceColormassOrganizationQueryVariables> {
    override document = SdkServiceColormassOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllTagsBasicInfoDocument = gql`
    query allTagsBasicInfo {
  tags(take: 9999) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AllTagsBasicInfoGQL extends Apollo.Query<AllTagsBasicInfoQuery, AllTagsBasicInfoQueryVariables> {
    override document = AllTagsBasicInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagFullInfoDocument = gql`
    query tagFullInfo($id: ID!) {
  tag(id: $id) {
    ...FullTagInfo
  }
}
    ${FullTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagFullInfoGQL extends Apollo.Query<TagFullInfoQuery, TagFullInfoQueryVariables> {
    override document = TagFullInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagAssignmentsDocument = gql`
    query getTagAssignments($filter: TagAssignmentFilterInput) {
  tagAssignments(filter: $filter) {
    ...BasicTagAssignment
  }
}
    ${BasicTagAssignmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagAssignmentsGQL extends Apollo.Query<GetTagAssignmentsQuery, GetTagAssignmentsQueryVariables> {
    override document = GetTagAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HasTagAssignmentsDocument = gql`
    query hasTagAssignments($filter: TagAssignmentFilterInput) {
  tagAssignmentsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HasTagAssignmentsGQL extends Apollo.Query<HasTagAssignmentsQuery, HasTagAssignmentsQueryVariables> {
    override document = HasTagAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignTagDocument = gql`
    mutation assignTag($objectId: ID!, $contentTypeModel: ContentTypeModel!, $tagId: ID!) {
  createTagAssignment(
    input: {objectId: $objectId, contentTypeModel: $contentTypeModel, tagId: $tagId}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignTagGQL extends Apollo.Mutation<AssignTagMutation, AssignTagMutationVariables> {
    override document = AssignTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnassignTagDocument = gql`
    mutation unassignTag($tagAssignmentId: ID!) {
  deleteTagAssignment(id: $tagAssignmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnassignTagGQL extends Apollo.Mutation<UnassignTagMutation, UnassignTagMutationVariables> {
    override document = UnassignTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateForThumbnailRenderingDocument = gql`
    query getTemplateForThumbnailRendering($templateId: ID!) {
  template(id: $templateId) {
    ...TemplateThumbnailRendering
  }
}
    ${TemplateThumbnailRenderingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateForThumbnailRenderingGQL extends Apollo.Query<GetTemplateForThumbnailRenderingQuery, GetTemplateForThumbnailRenderingQueryVariables> {
    override document = GetTemplateForThumbnailRenderingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateThumbnailRenderingCreateJobTaskDocument = gql`
    mutation templateThumbnailRenderingCreateJobTask($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateThumbnailRenderingCreateJobTaskGQL extends Apollo.Mutation<TemplateThumbnailRenderingCreateJobTaskMutation, TemplateThumbnailRenderingCreateJobTaskMutationVariables> {
    override document = TemplateThumbnailRenderingCreateJobTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadProcessingDataObjectDocument = gql`
    query uploadProcessingDataObject($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    ...UploadProcessingDataObject
  }
}
    ${UploadProcessingDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadProcessingDataObjectGQL extends Apollo.Query<UploadProcessingDataObjectQuery, UploadProcessingDataObjectQueryVariables> {
    override document = UploadProcessingDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadProcessingCreateJobDocument = gql`
    mutation uploadProcessingCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadProcessingCreateJobGQL extends Apollo.Mutation<UploadProcessingCreateJobMutation, UploadProcessingCreateJobMutationVariables> {
    override document = UploadProcessingCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadServiceGetDataObjectDocument = gql`
    query uploadServiceGetDataObject($id: ID!) {
  dataObject(id: $id) {
    ...UploadServiceDataObject
  }
}
    ${UploadServiceDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadServiceGetDataObjectGQL extends Apollo.Query<UploadServiceGetDataObjectQuery, UploadServiceGetDataObjectQueryVariables> {
    override document = UploadServiceGetDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadServiceDeleteDataObjectDocument = gql`
    mutation uploadServiceDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadServiceDeleteDataObjectGQL extends Apollo.Mutation<UploadServiceDeleteDataObjectMutation, UploadServiceDeleteDataObjectMutationVariables> {
    override document = UploadServiceDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadServiceCreateDataObjectDocument = gql`
    mutation uploadServiceCreateDataObject($input: MutationCreateDataObjectInput!) {
  createDataObject(input: $input) {
    ...UploadServiceDataObject
  }
}
    ${UploadServiceDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadServiceCreateDataObjectGQL extends Apollo.Mutation<UploadServiceCreateDataObjectMutation, UploadServiceCreateDataObjectMutationVariables> {
    override document = UploadServiceCreateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfigVariantIconDataObjectDocument = gql`
    query configVariantIconDataObject($legacyId: Int, $id: ID) {
  dataObject(legacyId: $legacyId, id: $id) {
    ...ConfigVariantIconDataObject
  }
}
    ${ConfigVariantIconDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigVariantIconDataObjectGQL extends Apollo.Query<ConfigVariantIconDataObjectQuery, ConfigVariantIconDataObjectQueryVariables> {
    override document = ConfigVariantIconDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialInspectorMaterialDocument = gql`
    query materialInspectorMaterial($id: ID!) {
  material(id: $id) {
    ...MaterialInspectorMaterial
  }
}
    ${MaterialInspectorMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialInspectorMaterialGQL extends Apollo.Query<MaterialInspectorMaterialQuery, MaterialInspectorMaterialQueryVariables> {
    override document = MaterialInspectorMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialInspectorRevisionDocument = gql`
    query materialInspectorRevision($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialInspectorMaterialRevision
  }
}
    ${MaterialInspectorMaterialRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialInspectorRevisionGQL extends Apollo.Query<MaterialInspectorRevisionQuery, MaterialInspectorRevisionQueryVariables> {
    override document = MaterialInspectorRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateInputInspectorTagsForUserDocument = gql`
    query templateInputInspectorTagsForUser($createdById: ID!) {
  tags(filter: {createdById: {equals: $createdById}}) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateInputInspectorTagsForUserGQL extends Apollo.Query<TemplateInputInspectorTagsForUserQuery, TemplateInputInspectorTagsForUserQueryVariables> {
    override document = TemplateInputInspectorTagsForUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateOutputInspectorTagsForUserDocument = gql`
    query templateOutputInspectorTagsForUser($createdById: ID!) {
  tags(filter: {createdById: {equals: $createdById}}) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateOutputInspectorTagsForUserGQL extends Apollo.Query<TemplateOutputInspectorTagsForUserQuery, TemplateOutputInspectorTagsForUserQueryVariables> {
    override document = TemplateOutputInspectorTagsForUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateOutputInspectorTagsByLegacyIdDocument = gql`
    query templateOutputInspectorTagsByLegacyId($legacyIds: [Int!]) {
  tags(filter: {legacyId: {in: $legacyIds}}) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateOutputInspectorTagsByLegacyIdGQL extends Apollo.Query<TemplateOutputInspectorTagsByLegacyIdQuery, TemplateOutputInspectorTagsByLegacyIdQueryVariables> {
    override document = TemplateOutputInspectorTagsByLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HdriForEditorSceneDocument = gql`
    query hdriForEditorScene($id: ID, $legacyId: Int) {
  hdri(id: $id, legacyId: $legacyId) {
    dataObject {
      mediaType
      bucketName
      objectName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HdriForEditorSceneGQL extends Apollo.Query<HdriForEditorSceneQuery, HdriForEditorSceneQueryVariables> {
    override document = HdriForEditorSceneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadHdriFromFileDocument = gql`
    mutation uploadHdriFromFile($id: ID!, $dataObjectId: ID!) {
  updateHdri(input: {id: $id, dataObjectId: $dataObjectId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadHdriFromFileGQL extends Apollo.Mutation<UploadHdriFromFileMutation, UploadHdriFromFileMutationVariables> {
    override document = UploadHdriFromFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntityDetailsGalleryImageDataObjectDocument = gql`
    query entityDetailsGalleryImageDataObject($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    ...EntityDetailsGalleryImageDataObject
  }
}
    ${EntityDetailsGalleryImageDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EntityDetailsGalleryImageDataObjectGQL extends Apollo.Query<EntityDetailsGalleryImageDataObjectQuery, EntityDetailsGalleryImageDataObjectQueryVariables> {
    override document = EntityDetailsGalleryImageDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArViewerCreateDataObjectAssignmentDocument = gql`
    mutation arViewerCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArViewerCreateDataObjectAssignmentGQL extends Apollo.Mutation<ArViewerCreateDataObjectAssignmentMutation, ArViewerCreateDataObjectAssignmentMutationVariables> {
    override document = ArViewerCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsWithNodeEditorTemplateIdDocument = gql`
    query organizationsWithNodeEditorTemplateId($organizationIds: [ID!]!) {
  organizations(filter: {id: {in: $organizationIds}}) {
    id
    legacyId
    nodeEditorTemplate {
      legacyId
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsWithNodeEditorTemplateIdGQL extends Apollo.Query<OrganizationsWithNodeEditorTemplateIdQuery, OrganizationsWithNodeEditorTemplateIdQueryVariables> {
    override document = OrganizationsWithNodeEditorTemplateIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorCreateMaterialRevisionDocument = gql`
    mutation materialEditorCreateMaterialRevision($input: MutationCreateMaterialRevisionInput!) {
  createMaterialRevision(input: $input) {
    ...MaterialEditorMaterialRevision
  }
}
    ${MaterialEditorMaterialRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorCreateMaterialRevisionGQL extends Apollo.Mutation<MaterialEditorCreateMaterialRevisionMutation, MaterialEditorCreateMaterialRevisionMutationVariables> {
    override document = MaterialEditorCreateMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorCreateMaterialNodeDocument = gql`
    mutation materialEditorCreateMaterialNode($input: MutationCreateMaterialNodeInput!) {
  createMaterialNode(input: $input) {
    ...MaterialEditorMaterialNode
  }
}
    ${MaterialEditorMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorCreateMaterialNodeGQL extends Apollo.Mutation<MaterialEditorCreateMaterialNodeMutation, MaterialEditorCreateMaterialNodeMutationVariables> {
    override document = MaterialEditorCreateMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorCreateMaterialConnectionDocument = gql`
    mutation materialEditorCreateMaterialConnection($input: MutationCreateMaterialConnectionInput!) {
  createMaterialConnection(input: $input) {
    ...MaterialEditorMaterialConnection
  }
}
    ${MaterialEditorMaterialConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorCreateMaterialConnectionGQL extends Apollo.Mutation<MaterialEditorCreateMaterialConnectionMutation, MaterialEditorCreateMaterialConnectionMutationVariables> {
    override document = MaterialEditorCreateMaterialConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorMaterialDocument = gql`
    query materialEditorMaterial($id: ID!) {
  material(id: $id) {
    ...MaterialEditorMaterial
  }
}
    ${MaterialEditorMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorMaterialGQL extends Apollo.Query<MaterialEditorMaterialQuery, MaterialEditorMaterialQueryVariables> {
    override document = MaterialEditorMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorTextureRevisionDocument = gql`
    query materialEditorTextureRevision($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    ...MaterialEditorTextureRevision
  }
}
    ${MaterialEditorTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorTextureRevisionGQL extends Apollo.Query<MaterialEditorTextureRevisionQuery, MaterialEditorTextureRevisionQueryVariables> {
    override document = MaterialEditorTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorTextureSetDocument = gql`
    query materialEditorTextureSet($id: ID!) {
  textureSet(id: $id) {
    ...MaterialEditorTextureSet
  }
}
    ${MaterialEditorTextureSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorTextureSetGQL extends Apollo.Query<MaterialEditorTextureSetQuery, MaterialEditorTextureSetQueryVariables> {
    override document = MaterialEditorTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorMaterialConnectionsDocument = gql`
    query materialEditorMaterialConnections($materialRevisionId: ID!) {
  materialConnections(
    filter: {materialRevisionId: {equals: $materialRevisionId}}
    take: 2147483647
  ) {
    ...MaterialEditorMaterialConnection
  }
}
    ${MaterialEditorMaterialConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorMaterialConnectionsGQL extends Apollo.Query<MaterialEditorMaterialConnectionsQuery, MaterialEditorMaterialConnectionsQueryVariables> {
    override document = MaterialEditorMaterialConnectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorMaterialNodesDocument = gql`
    query materialEditorMaterialNodes($materialRevisionId: ID!) {
  materialNodes(
    filter: {materialRevisionId: {equals: $materialRevisionId}}
    take: 2147483647
  ) {
    ...MaterialEditorMaterialNode
  }
}
    ${MaterialEditorMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorMaterialNodesGQL extends Apollo.Query<MaterialEditorMaterialNodesQuery, MaterialEditorMaterialNodesQueryVariables> {
    override document = MaterialEditorMaterialNodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorResolveTextureRevisionLegacyIdDocument = gql`
    query materialEditorResolveTextureRevisionLegacyId($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorResolveTextureRevisionLegacyIdGQL extends Apollo.Query<MaterialEditorResolveTextureRevisionLegacyIdQuery, MaterialEditorResolveTextureRevisionLegacyIdQueryVariables> {
    override document = MaterialEditorResolveTextureRevisionLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorLatestTextureSetRevisionDocument = gql`
    query materialEditorLatestTextureSetRevision($textureSetId: ID!) {
  textureSetRevisions(
    filter: {textureSetId: {equals: $textureSetId}}
    orderBy: {direction: desc, key: createdAt}
    take: 1
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorLatestTextureSetRevisionGQL extends Apollo.Query<MaterialEditorLatestTextureSetRevisionQuery, MaterialEditorLatestTextureSetRevisionQueryVariables> {
    override document = MaterialEditorLatestTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImageTextureSetNodeTextureSetDocument = gql`
    query imageTextureSetNodeTextureSet($textureSetLegacyId: Int!) {
  textureSet(legacyId: $textureSetLegacyId) {
    ...ImageTextureSetNodeTextureSet
  }
}
    ${ImageTextureSetNodeTextureSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageTextureSetNodeTextureSetGQL extends Apollo.Query<ImageTextureSetNodeTextureSetQuery, ImageTextureSetNodeTextureSetQueryVariables> {
    override document = ImageTextureSetNodeTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImageTextureSetNodeTextureSetRevisionDocument = gql`
    query imageTextureSetNodeTextureSetRevision($textureSetRevisionId: ID!) {
  textureSetRevision(id: $textureSetRevisionId) {
    ...ImageTextureSetNodeTextureSetRevision
  }
}
    ${ImageTextureSetNodeTextureSetRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageTextureSetNodeTextureSetRevisionGQL extends Apollo.Query<ImageTextureSetNodeTextureSetRevisionQuery, ImageTextureSetNodeTextureSetRevisionQueryVariables> {
    override document = ImageTextureSetNodeTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImageTextureNodeTextureDocument = gql`
    query imageTextureNodeTexture($textureRevisionLegacyId: Int!) {
  textureRevision(legacyId: $textureRevisionLegacyId) {
    ...ImageTextureNodeTextureRevision
  }
}
    ${ImageTextureNodeTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageTextureNodeTextureGQL extends Apollo.Query<ImageTextureNodeTextureQuery, ImageTextureNodeTextureQueryVariables> {
    override document = ImageTextureNodeTextureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveMaterialRevisionDialogMaterialDocument = gql`
    query saveMaterialRevisionDialogMaterial($id: ID, $legacyId: Int) {
  material(id: $id, legacyId: $legacyId) {
    ...SaveMaterialRevisionDialogMaterial
  }
}
    ${SaveMaterialRevisionDialogMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveMaterialRevisionDialogMaterialGQL extends Apollo.Query<SaveMaterialRevisionDialogMaterialQuery, SaveMaterialRevisionDialogMaterialQueryVariables> {
    override document = SaveMaterialRevisionDialogMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssetBoxesDocument = gql`
    query assetBoxes($assetId: ID!) {
  asset(id: $assetId) {
    ...AssetBoxes
  }
}
    ${AssetBoxesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetBoxesGQL extends Apollo.Query<AssetBoxesQuery, AssetBoxesQueryVariables> {
    override document = AssetBoxesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssetDetailsDocument = gql`
    query assetDetails($id: ID!) {
  item: asset(id: $id) {
    ...AssetDetails
  }
}
    ${AssetDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetDetailsGQL extends Apollo.Query<AssetDetailsQuery, AssetDetailsQueryVariables> {
    override document = AssetDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssetDetailsDocument = gql`
    mutation updateAssetDetails($input: MutationUpdateAssetInput!) {
  item: updateAsset(input: $input) {
    ...AssetDetails
  }
}
    ${AssetDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAssetDetailsGQL extends Apollo.Mutation<UpdateAssetDetailsMutation, UpdateAssetDetailsMutationVariables> {
    override document = UpdateAssetDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAssetDetailsDocument = gql`
    mutation deleteAssetDetails($id: ID!) {
  deleteAsset(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAssetDetailsGQL extends Apollo.Mutation<DeleteAssetDetailsMutation, DeleteAssetDetailsMutationVariables> {
    override document = DeleteAssetDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAssetDetailsMaterialAssignmentDocument = gql`
    mutation createAssetDetailsMaterialAssignment($input: MutationCreateMaterialAssignmentInput!) {
  createMaterialAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAssetDetailsMaterialAssignmentGQL extends Apollo.Mutation<CreateAssetDetailsMaterialAssignmentMutation, CreateAssetDetailsMaterialAssignmentMutationVariables> {
    override document = CreateAssetDetailsMaterialAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAssetDetailsMaterialAssignmentDocument = gql`
    mutation deleteAssetDetailsMaterialAssignment($id: ID!) {
  deleteMaterialAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAssetDetailsMaterialAssignmentGQL extends Apollo.Mutation<DeleteAssetDetailsMaterialAssignmentMutation, DeleteAssetDetailsMaterialAssignmentMutationVariables> {
    override document = DeleteAssetDetailsMaterialAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAssetsDocument = gql`
    query getAssets($skip: Int, $take: Int, $filter: AssetFilterInput, $orderBy: [AssetOrderByInput!]) {
  assets(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...AssetListItem
  }
  assetsCount(filter: $filter)
}
    ${AssetListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssetsGQL extends Apollo.Query<GetAssetsQuery, GetAssetsQueryVariables> {
    override document = GetAssetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateAssetsDocument = gql`
    mutation batchUpdateAssets($filter: AssetFilterInput!, $state: AssetState, $addTagId: ID, $removeTagId: ID, $assignUserId: ID, $removeUserAssignment: Boolean, $nextActor: NextActor) {
  batchUpdateAssets(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
    nextActor: $nextActor
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateAssetsGQL extends Apollo.Mutation<BatchUpdateAssetsMutation, BatchUpdateAssetsMutationVariables> {
    override document = BatchUpdateAssetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAssetDocument = gql`
    mutation createAsset($input: MutationCreateAssetInput!) {
  createAsset(input: $input) {
    ...AssetListItem
  }
}
    ${AssetListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAssetGQL extends Apollo.Mutation<CreateAssetMutation, CreateAssetMutationVariables> {
    override document = CreateAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssetDocument = gql`
    mutation updateAsset($input: MutationUpdateAssetInput!) {
  updateAsset(input: $input) {
    ...AssetListItem
  }
}
    ${AssetListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAssetGQL extends Apollo.Mutation<UpdateAssetMutation, UpdateAssetMutationVariables> {
    override document = UpdateAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelectAssetItemsDocument = gql`
    query selectAssetItems($skip: Int, $take: Int, $filter: AssetFilterInput) {
  items: assets(skip: $skip, take: $take, filter: $filter) {
    ...SelectAssetItem
  }
  totalCount: assetsCount(filter: $filter)
}
    ${SelectAssetItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectAssetItemsGQL extends Apollo.Query<SelectAssetItemsQuery, SelectAssetItemsQueryVariables> {
    override document = SelectAssetItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureAssetsSectionDocument = gql`
    query pictureAssetsSection($id: ID!) {
  item: picture(id: $id) {
    ...PictureAssetsSection
  }
}
    ${PictureAssetsSectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureAssetsSectionGQL extends Apollo.Query<PictureAssetsSectionQuery, PictureAssetsSectionQueryVariables> {
    override document = PictureAssetsSectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatesSectionItemsForMaterialDocument = gql`
    query templatesSectionItemsForMaterial($materialId: ID!) {
  material(id: $materialId) {
    revisions {
      templateRevisions {
        template {
          ...TemplatesSectionItem
        }
      }
    }
  }
}
    ${TemplatesSectionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesSectionItemsForMaterialGQL extends Apollo.Query<TemplatesSectionItemsForMaterialQuery, TemplatesSectionItemsForMaterialQueryVariables> {
    override document = TemplatesSectionItemsForMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatesSectionItemsForTemplateDocument = gql`
    query templatesSectionItemsForTemplate($templateId: ID!) {
  template(id: $templateId) {
    revisions {
      referencedBy {
        template {
          ...TemplatesSectionItem
        }
      }
    }
  }
}
    ${TemplatesSectionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesSectionItemsForTemplateGQL extends Apollo.Query<TemplatesSectionItemsForTemplateQuery, TemplatesSectionItemsForTemplateQueryVariables> {
    override document = TemplatesSectionItemsForTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FileDetailsDocument = gql`
    query fileDetails($id: ID, $legacyId: Int) {
  item: dataObject(id: $id, legacyId: $legacyId) {
    ...FileDetails
  }
}
    ${FileDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FileDetailsGQL extends Apollo.Query<FileDetailsQuery, FileDetailsQueryVariables> {
    override document = FileDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFileDetailsDocument = gql`
    mutation updateFileDetails($input: MutationUpdateDataObjectInput!) {
  item: updateDataObject(input: $input) {
    ...FileDetails
  }
}
    ${FileDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFileDetailsGQL extends Apollo.Mutation<UpdateFileDetailsMutation, UpdateFileDetailsMutationVariables> {
    override document = UpdateFileDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!, $legacyId: Int!) {
  deleteDataObject(id: $id, legacyId: $legacyId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFileGQL extends Apollo.Mutation<DeleteFileMutation, DeleteFileMutationVariables> {
    override document = DeleteFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FileThumbnailDocument = gql`
    query fileThumbnail($dataObjectId: ID!, $resolution: DownloadResolution = High, $fileType: DownloadFileType = jpg) {
  dataObject(id: $dataObjectId) {
    ...FileThumbnail
  }
}
    ${FileThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FileThumbnailGQL extends Apollo.Query<FileThumbnailQuery, FileThumbnailQueryVariables> {
    override document = FileThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilesGridItemsDocument = gql`
    query filesGridItems($take: Int, $skip: Int, $filter: DataObjectFilterInput) {
  dataObjects(take: $take, skip: $skip, filter: $filter) {
    ...FilesGridDataObject
  }
  dataObjectsCount(filter: $filter)
}
    ${FilesGridDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FilesGridItemsGQL extends Apollo.Query<FilesGridItemsQuery, FilesGridItemsQueryVariables> {
    override document = FilesGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilesGridCreateDataObjectDocument = gql`
    mutation filesGridCreateDataObject($input: MutationCreateDataObjectInput!) {
  createDataObject(input: $input) {
    ...FilesGridDataObject
  }
}
    ${FilesGridDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FilesGridCreateDataObjectGQL extends Apollo.Mutation<FilesGridCreateDataObjectMutation, FilesGridCreateDataObjectMutationVariables> {
    override document = FilesGridCreateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HdriDetailsDocument = gql`
    query hdriDetails($id: ID) {
  item: hdri(id: $id) {
    ...HdriDetails
  }
}
    ${HdriDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HdriDetailsGQL extends Apollo.Query<HdriDetailsQuery, HdriDetailsQueryVariables> {
    override document = HdriDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHdriDetailsDocument = gql`
    mutation updateHdriDetails($input: MutationUpdateHdriInput!) {
  item: updateHdri(input: $input) {
    ...HdriDetails
  }
}
    ${HdriDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHdriDetailsGQL extends Apollo.Mutation<UpdateHdriDetailsMutation, UpdateHdriDetailsMutationVariables> {
    override document = UpdateHdriDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteHdriDocument = gql`
    mutation deleteHdri($id: ID!) {
  deleteHdri(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteHdriGQL extends Apollo.Mutation<DeleteHdriMutation, DeleteHdriMutationVariables> {
    override document = DeleteHdriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HdrisGridItemsDocument = gql`
    query hdrisGridItems($filter: HdriFilterInput, $skip: Int, $take: Int) {
  hdris(filter: $filter, skip: $skip, take: $take) {
    ...HdrisGridItem
  }
  hdrisCount(filter: $filter)
}
    ${HdrisGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HdrisGridItemsGQL extends Apollo.Query<HdrisGridItemsQuery, HdrisGridItemsQueryVariables> {
    override document = HdrisGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHdrisGridItemDocument = gql`
    mutation createHdrisGridItem($input: MutationCreateHdriInput!) {
  createHdri(input: $input) {
    ...HdrisGridItem
  }
}
    ${HdrisGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHdrisGridItemGQL extends Apollo.Mutation<CreateHdrisGridItemMutation, CreateHdrisGridItemMutationVariables> {
    override document = CreateHdrisGridItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHdriDocument = gql`
    mutation updateHdri($input: MutationUpdateHdriInput!) {
  updateHdri(input: $input) {
    ...HdriDetails
  }
}
    ${HdriDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHdriGQL extends Apollo.Mutation<UpdateHdriMutation, UpdateHdriMutationVariables> {
    override document = UpdateHdriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelectHdriItemsDocument = gql`
    query selectHdriItems($skip: Int, $take: Int, $filter: HdriFilterInput) {
  items: hdris(skip: $skip, take: $take, filter: $filter) {
    ...SelectHdriItem
  }
  totalCount: hdrisCount(filter: $filter)
}
    ${SelectHdriItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectHdriItemsGQL extends Apollo.Query<SelectHdriItemsQuery, SelectHdriItemsQueryVariables> {
    override document = SelectHdriItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobFarmGroupsTableItemsDocument = gql`
    query jobFarmGroupsTableItems($take: Int, $skip: Int) {
  items: jobFarmGroups(
    take: $take
    skip: $skip
    orderBy: [{key: name, direction: asc}]
  ) {
    ...JobFarmGroupsTableItem
  }
  totalCount: jobFarmGroupsCount
}
    ${JobFarmGroupsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmGroupsTableItemsGQL extends Apollo.Query<JobFarmGroupsTableItemsQuery, JobFarmGroupsTableItemsQueryVariables> {
    override document = JobFarmGroupsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJobFarmGroupsTableItemDocument = gql`
    mutation createJobFarmGroupsTableItem($input: MutationCreateJobFarmGroupInput!) {
  createJobFarmGroup(input: $input) {
    ...JobFarmGroupsTableItem
  }
}
    ${JobFarmGroupsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobFarmGroupsTableItemGQL extends Apollo.Mutation<CreateJobFarmGroupsTableItemMutation, CreateJobFarmGroupsTableItemMutationVariables> {
    override document = CreateJobFarmGroupsTableItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobFarmGroupsTableItemDocument = gql`
    mutation updateJobFarmGroupsTableItem($input: MutationUpdateJobFarmGroupInput!) {
  updateJobFarmGroup(input: $input) {
    ...JobFarmGroupsTableItem
  }
}
    ${JobFarmGroupsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobFarmGroupsTableItemGQL extends Apollo.Mutation<UpdateJobFarmGroupsTableItemMutation, UpdateJobFarmGroupsTableItemMutationVariables> {
    override document = UpdateJobFarmGroupsTableItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteJobFarmGroupsTableItemDocument = gql`
    mutation deleteJobFarmGroupsTableItem($id: ID!) {
  deleteJobFarmGroup(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteJobFarmGroupsTableItemGQL extends Apollo.Mutation<DeleteJobFarmGroupsTableItemMutation, DeleteJobFarmGroupsTableItemMutationVariables> {
    override document = DeleteJobFarmGroupsTableItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditJobFarmInstanceJobFarmGroupsDocument = gql`
    query editJobFarmInstanceJobFarmGroups {
  jobFarmGroups {
    ...EditJobFarmInstanceDialogJobFarmGroup
  }
}
    ${EditJobFarmInstanceDialogJobFarmGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EditJobFarmInstanceJobFarmGroupsGQL extends Apollo.Query<EditJobFarmInstanceJobFarmGroupsQuery, EditJobFarmInstanceJobFarmGroupsQueryVariables> {
    override document = EditJobFarmInstanceJobFarmGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobFarmInstancesDeleteJobFarmInstanceDocument = gql`
    mutation jobFarmInstancesDeleteJobFarmInstance($id: ID!) {
  deleteJobFarmInstance(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmInstancesDeleteJobFarmInstanceGQL extends Apollo.Mutation<JobFarmInstancesDeleteJobFarmInstanceMutation, JobFarmInstancesDeleteJobFarmInstanceMutationVariables> {
    override document = JobFarmInstancesDeleteJobFarmInstanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobFarmInstancesUpdateJobFarmInstanceDocument = gql`
    mutation jobFarmInstancesUpdateJobFarmInstance($input: MutationUpdateJobFarmInstanceInput!) {
  updateJobFarmInstance(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmInstancesUpdateJobFarmInstanceGQL extends Apollo.Mutation<JobFarmInstancesUpdateJobFarmInstanceMutation, JobFarmInstancesUpdateJobFarmInstanceMutationVariables> {
    override document = JobFarmInstancesUpdateJobFarmInstanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobFarmInstancesTableItemsDocument = gql`
    query jobFarmInstancesTableItems($skip: Int, $take: Int) {
  jobFarmInstances(skip: $skip, take: $take) {
    ...JobFarmInstancesTable
  }
}
    ${JobFarmInstancesTableFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmInstancesTableItemsGQL extends Apollo.Query<JobFarmInstancesTableItemsQuery, JobFarmInstancesTableItemsQueryVariables> {
    override document = JobFarmInstancesTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssociatedJobsTableItemsDocument = gql`
    query associatedJobsTableItems($objectId: ID, $objectLegacyId: Int, $contentTypeModel: ContentTypeModel) {
  items: jobAssignments(
    filter: {objectId: $objectId, objectLegacyId: $objectLegacyId, contentTypeModel: $contentTypeModel}
    take: 1000
  ) {
    ...AssociatedJobsTableJobAssignment
  }
  totalCount: jobAssignmentsCount(
    filter: {objectId: $objectId, objectLegacyId: $objectLegacyId, contentTypeModel: $contentTypeModel}
  )
}
    ${AssociatedJobsTableJobAssignmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssociatedJobsTableItemsGQL extends Apollo.Query<AssociatedJobsTableItemsQuery, AssociatedJobsTableItemsQueryVariables> {
    override document = AssociatedJobsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetExecutionPlanDocument = gql`
    query getExecutionPlan($id: ID, $legacyId: Int) {
  job(id: $id, legacyId: $legacyId) {
    ...ExecutionPlan
  }
}
    ${ExecutionPlanFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetExecutionPlanGQL extends Apollo.Query<GetExecutionPlanQuery, GetExecutionPlanQueryVariables> {
    override document = GetExecutionPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsDocument = gql`
    query jobDetails($id: ID!) {
  item: job(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsGQL extends Apollo.Query<JobDetailsQuery, JobDetailsQueryVariables> {
    override document = JobDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobDetailsDocument = gql`
    mutation updateJobDetails($input: MutationUpdateJobInput!) {
  item: updateJob(input: $input) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobDetailsGQL extends Apollo.Mutation<UpdateJobDetailsMutation, UpdateJobDetailsMutationVariables> {
    override document = UpdateJobDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsDeleteJobDocument = gql`
    mutation jobDetailsDeleteJob($id: ID!) {
  deleteJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsDeleteJobGQL extends Apollo.Mutation<JobDetailsDeleteJobMutation, JobDetailsDeleteJobMutationVariables> {
    override document = JobDetailsDeleteJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsCancelDocument = gql`
    mutation jobDetailsCancel($id: ID!) {
  cancelJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsCancelGQL extends Apollo.Mutation<JobDetailsCancelMutation, JobDetailsCancelMutationVariables> {
    override document = JobDetailsCancelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsRerunAllTasksInJobDocument = gql`
    mutation jobDetailsRerunAllTasksInJob($id: ID!) {
  rerunAllTasksInJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsRerunAllTasksInJobGQL extends Apollo.Mutation<JobDetailsRerunAllTasksInJobMutation, JobDetailsRerunAllTasksInJobMutationVariables> {
    override document = JobDetailsRerunAllTasksInJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsRestartDocument = gql`
    mutation jobDetailsRestart($id: ID!) {
  restartJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsRestartGQL extends Apollo.Mutation<JobDetailsRestartMutation, JobDetailsRestartMutationVariables> {
    override document = JobDetailsRestartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsNudgeDocument = gql`
    mutation jobDetailsNudge($id: ID!) {
  nudgeJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsNudgeGQL extends Apollo.Mutation<JobDetailsNudgeMutation, JobDetailsNudgeMutationVariables> {
    override document = JobDetailsNudgeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobTaskDetailsDocument = gql`
    query jobTaskDetails($id: ID, $legacyId: Int) {
  item: jobTask(id: $id, legacyId: $legacyId) {
    ...JobTaskDetails
  }
}
    ${JobTaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobTaskDetailsGQL extends Apollo.Query<JobTaskDetailsQuery, JobTaskDetailsQueryVariables> {
    override document = JobTaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobTaskDetailsDocument = gql`
    mutation updateJobTaskDetails($input: MutationUpdateJobTaskInput!) {
  item: updateJobTask(input: $input) {
    ...JobTaskDetails
  }
}
    ${JobTaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobTaskDetailsGQL extends Apollo.Mutation<UpdateJobTaskDetailsMutation, UpdateJobTaskDetailsMutationVariables> {
    override document = UpdateJobTaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobTaskTableItemsDocument = gql`
    query jobTaskTableItems($id: ID!) {
  items: jobTasks(
    filter: {jobId: {equals: $id}}
    take: 1000
    orderBy: [{key: legacyId, direction: asc}]
  ) {
    ...JobTaskTableItem
  }
  totalCount: jobTasksCount(filter: {jobId: {equals: $id}})
}
    ${JobTaskTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobTaskTableItemsGQL extends Apollo.Query<JobTaskTableItemsQuery, JobTaskTableItemsQueryVariables> {
    override document = JobTaskTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsGridItemsDocument = gql`
    query jobsGridItems($filter: JobFilterInput, $orderBy: [JobOrderByInput!], $take: Int, $skip: Int) {
  items: jobs(filter: $filter, orderBy: $orderBy, take: $take, skip: $skip) {
    ...JobsGridItem
  }
  totalCount: jobsCount(filter: $filter)
}
    ${JobsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsGridItemsGQL extends Apollo.Query<JobsGridItemsQuery, JobsGridItemsQueryVariables> {
    override document = JobsGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableItemsDocument = gql`
    query jobsTableItems($filter: JobFilterInput, $orderBy: [JobOrderByInput!], $take: Int, $skip: Int) {
  items: jobs(filter: $filter, orderBy: $orderBy, take: $take, skip: $skip) {
    ...JobsTableItem
  }
  totalCount: jobsCount(filter: $filter)
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableItemsGQL extends Apollo.Query<JobsTableItemsQuery, JobsTableItemsQueryVariables> {
    override document = JobsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableNudgeJobDocument = gql`
    mutation jobsTableNudgeJob($id: ID!) {
  nudgeJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableNudgeJobGQL extends Apollo.Mutation<JobsTableNudgeJobMutation, JobsTableNudgeJobMutationVariables> {
    override document = JobsTableNudgeJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableUpdateJobDocument = gql`
    mutation jobsTableUpdateJob($input: MutationUpdateJobInput!) {
  updateJob(input: $input) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableUpdateJobGQL extends Apollo.Mutation<JobsTableUpdateJobMutation, JobsTableUpdateJobMutationVariables> {
    override document = JobsTableUpdateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableCancelJobDocument = gql`
    mutation jobsTableCancelJob($id: ID!) {
  cancelJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableCancelJobGQL extends Apollo.Mutation<JobsTableCancelJobMutation, JobsTableCancelJobMutationVariables> {
    override document = JobsTableCancelJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableCleanJobDocument = gql`
    mutation jobsTableCleanJob($id: ID!) {
  rerunAllTasksInJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableCleanJobGQL extends Apollo.Mutation<JobsTableCleanJobMutation, JobsTableCleanJobMutationVariables> {
    override document = JobsTableCleanJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableRestartJobDocument = gql`
    mutation jobsTableRestartJob($id: ID!) {
  restartJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableRestartJobGQL extends Apollo.Mutation<JobsTableRestartJobMutation, JobsTableRestartJobMutationVariables> {
    override document = JobsTableRestartJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddMaterialDialogMaterialRangeTagsDocument = gql`
    query addMaterialDialogMaterialRangeTags {
  tags(filter: {tagType: MaterialRange}, take: 9999) {
    ...AddMaterialDialogTag
  }
}
    ${AddMaterialDialogTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddMaterialDialogMaterialRangeTagsGQL extends Apollo.Query<AddMaterialDialogMaterialRangeTagsQuery, AddMaterialDialogMaterialRangeTagsQueryVariables> {
    override document = AddMaterialDialogMaterialRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDocument = gql`
    query materialDetails($legacyId: Int, $id: ID) {
  material(legacyId: $legacyId, id: $id) {
    ...MaterialDetails
  }
}
    ${MaterialDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsGQL extends Apollo.Query<MaterialDetailsQuery, MaterialDetailsQueryVariables> {
    override document = MaterialDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDeleteDocument = gql`
    mutation materialDetailsDelete($id: ID!) {
  deleteMaterial(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsDeleteGQL extends Apollo.Mutation<MaterialDetailsDeleteMutation, MaterialDetailsDeleteMutationVariables> {
    override document = MaterialDetailsDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDeleteDataObjectDocument = gql`
    mutation materialDetailsDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsDeleteDataObjectGQL extends Apollo.Mutation<MaterialDetailsDeleteDataObjectMutation, MaterialDetailsDeleteDataObjectMutationVariables> {
    override document = MaterialDetailsDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialDetailsDocument = gql`
    query getMaterialDetails($id: ID!) {
  item: material(id: $id) {
    ...MaterialDetails
  }
}
    ${MaterialDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialDetailsGQL extends Apollo.Query<GetMaterialDetailsQuery, GetMaterialDetailsQueryVariables> {
    override document = GetMaterialDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMaterialDetailsDocument = gql`
    mutation updateMaterialDetails($input: MutationUpdateMaterialInput!) {
  item: updateMaterial(input: $input) {
    ...MaterialDetails
  }
}
    ${MaterialDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMaterialDetailsGQL extends Apollo.Mutation<UpdateMaterialDetailsMutation, UpdateMaterialDetailsMutationVariables> {
    override document = UpdateMaterialDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsCreateTextureGroupDocument = gql`
    mutation materialDetailsCreateTextureGroup($input: MutationCreateTextureGroupInput!) {
  createTextureGroup(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsCreateTextureGroupGQL extends Apollo.Mutation<MaterialDetailsCreateTextureGroupMutation, MaterialDetailsCreateTextureGroupMutationVariables> {
    override document = MaterialDetailsCreateTextureGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsIconDataObjectDocument = gql`
    query materialDetailsIconDataObject($id: ID!) {
  dataObject(id: $id) {
    id
    thumbnailProcessingJob {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsIconDataObjectGQL extends Apollo.Query<MaterialDetailsIconDataObjectQuery, MaterialDetailsIconDataObjectQueryVariables> {
    override document = MaterialDetailsIconDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsCreateTextureSetDocument = gql`
    mutation materialDetailsCreateTextureSet($input: MutationCreateTextureSetInput!) {
  createTextureSet(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsCreateTextureSetGQL extends Apollo.Mutation<MaterialDetailsCreateTextureSetMutation, MaterialDetailsCreateTextureSetMutationVariables> {
    override document = MaterialDetailsCreateTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDeleteIconAssignmentDocument = gql`
    mutation materialDetailsDeleteIconAssignment($dataObjectAssignmentId: ID!) {
  deleteDataObjectAssignment(id: $dataObjectAssignmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsDeleteIconAssignmentGQL extends Apollo.Mutation<MaterialDetailsDeleteIconAssignmentMutation, MaterialDetailsDeleteIconAssignmentMutationVariables> {
    override document = MaterialDetailsDeleteIconAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsCreateIconAssignmentDocument = gql`
    mutation materialDetailsCreateIconAssignment($objectId: ID!, $dataObjectId: ID!) {
  createDataObjectAssignment(
    input: {objectId: $objectId, dataObjectId: $dataObjectId, type: Icon, contentTypeModel: Material}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsCreateIconAssignmentGQL extends Apollo.Mutation<MaterialDetailsCreateIconAssignmentMutation, MaterialDetailsCreateIconAssignmentMutationVariables> {
    override document = MaterialDetailsCreateIconAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerInfoDocument = gql`
    query materialExplorerInfo($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    matExplorerMaterial {
      id
    }
    matExplorerTemplate {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerInfoGQL extends Apollo.Query<MaterialExplorerInfoQuery, MaterialExplorerInfoQueryVariables> {
    override document = MaterialExplorerInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerItemDocument = gql`
    query materialExplorerItem($id: ID!) {
  material(id: $id) {
    ...MaterialExplorerItem
  }
}
    ${MaterialExplorerItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerItemGQL extends Apollo.Query<MaterialExplorerItemQuery, MaterialExplorerItemQueryVariables> {
    override document = MaterialExplorerItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerDataDocument = gql`
    query materialExplorerData($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    legacyId
    matExplorerMaterial {
      id
    }
    matExplorerTemplate {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerDataGQL extends Apollo.Query<MaterialExplorerDataQuery, MaterialExplorerDataQueryVariables> {
    override document = MaterialExplorerDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerMaterialNodeDocument = gql`
    query materialExplorerMaterialNode($id: ID!) {
  materialNode(id: $id) {
    ...MaterialExplorerMaterialNode
  }
}
    ${MaterialExplorerMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerMaterialNodeGQL extends Apollo.Query<MaterialExplorerMaterialNodeQuery, MaterialExplorerMaterialNodeQueryVariables> {
    override document = MaterialExplorerMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerUpdateMaterialNodeDocument = gql`
    mutation materialExplorerUpdateMaterialNode($input: MutationUpdateMaterialNodeInput!) {
  updateMaterialNode(input: $input) {
    ...MaterialExplorerMaterialNode
  }
}
    ${MaterialExplorerMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerUpdateMaterialNodeGQL extends Apollo.Mutation<MaterialExplorerUpdateMaterialNodeMutation, MaterialExplorerUpdateMaterialNodeMutationVariables> {
    override document = MaterialExplorerUpdateMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerCreateTextureRevisionDocument = gql`
    mutation materialExplorerCreateTextureRevision($input: MutationCreateTextureRevisionInput!) {
  createTextureRevision(input: $input) {
    ...MaterialExplorerTextureRevision
  }
}
    ${MaterialExplorerTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerCreateTextureRevisionGQL extends Apollo.Mutation<MaterialExplorerCreateTextureRevisionMutation, MaterialExplorerCreateTextureRevisionMutationVariables> {
    override document = MaterialExplorerCreateTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerDeleteTextureRevisionDocument = gql`
    mutation materialExplorerDeleteTextureRevision($id: ID!) {
  deleteTextureRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerDeleteTextureRevisionGQL extends Apollo.Mutation<MaterialExplorerDeleteTextureRevisionMutation, MaterialExplorerDeleteTextureRevisionMutationVariables> {
    override document = MaterialExplorerDeleteTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerCreateTextureSetRevisionDocument = gql`
    mutation materialExplorerCreateTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    ...MaterialExplorerTextureSetRevision
  }
}
    ${MaterialExplorerTextureSetRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerCreateTextureSetRevisionGQL extends Apollo.Mutation<MaterialExplorerCreateTextureSetRevisionMutation, MaterialExplorerCreateTextureSetRevisionMutationVariables> {
    override document = MaterialExplorerCreateTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerDeleteTextureSetRevisionDocument = gql`
    mutation materialExplorerDeleteTextureSetRevision($id: ID!) {
  deleteTextureSetRevision(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerDeleteTextureSetRevisionGQL extends Apollo.Mutation<MaterialExplorerDeleteTextureSetRevisionMutation, MaterialExplorerDeleteTextureSetRevisionMutationVariables> {
    override document = MaterialExplorerDeleteTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExportsGetDataObjectDocument = gql`
    query materialExportsGetDataObject($dataObjectLegacyId: Int!) {
  dataObject(legacyId: $dataObjectLegacyId) {
    downloadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExportsGetDataObjectGQL extends Apollo.Query<MaterialExportsGetDataObjectQuery, MaterialExportsGetDataObjectQueryVariables> {
    override document = MaterialExportsGetDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExportsGetRenameDataObjectDocument = gql`
    query materialExportsGetRenameDataObject($dataObjectLegacyId: Int!) {
  dataObject(legacyId: $dataObjectLegacyId) {
    originalFileName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExportsGetRenameDataObjectGQL extends Apollo.Query<MaterialExportsGetRenameDataObjectQuery, MaterialExportsGetRenameDataObjectQueryVariables> {
    override document = MaterialExportsGetRenameDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsDataDocument = gql`
    query materialOutputsData($id: ID!) {
  material(id: $id) {
    ...MaterialOutputsData
  }
}
    ${MaterialOutputsDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsDataGQL extends Apollo.Query<MaterialOutputsDataQuery, MaterialOutputsDataQueryVariables> {
    override document = MaterialOutputsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsDeleteDataObjectDocument = gql`
    mutation materialOutputsDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsDeleteDataObjectGQL extends Apollo.Mutation<MaterialOutputsDeleteDataObjectMutation, MaterialOutputsDeleteDataObjectMutationVariables> {
    override document = MaterialOutputsDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionsHistoryDialogMaterialRevisionDocument = gql`
    query materialRevisionsHistoryDialogMaterialRevision($materialId: ID!) {
  materialRevisions(
    orderBy: {direction: desc, key: number}
    filter: {materialId: {equals: $materialId}}
  ) {
    ...MaterialRevisionsHistoryDialogMaterialRevision
  }
}
    ${MaterialRevisionsHistoryDialogMaterialRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionsHistoryDialogMaterialRevisionGQL extends Apollo.Query<MaterialRevisionsHistoryDialogMaterialRevisionQuery, MaterialRevisionsHistoryDialogMaterialRevisionQueryVariables> {
    override document = MaterialRevisionsHistoryDialogMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionsHistoryDialogDeleteMaterialRevisionDocument = gql`
    mutation materialRevisionsHistoryDialogDeleteMaterialRevision($materialRevisionId: ID!) {
  deleteMaterialRevision(id: $materialRevisionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionsHistoryDialogDeleteMaterialRevisionGQL extends Apollo.Mutation<MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutation, MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutationVariables> {
    override document = MaterialRevisionsHistoryDialogDeleteMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialForMaterialExplorerDocument = gql`
    query getMaterialForMaterialExplorer($legacyId: Int!) {
  material(legacyId: $legacyId) {
    revisions {
      legacyId
      nodes {
        name
        legacyId
      }
    }
    textureGroup {
      textureSets {
        legacyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialForMaterialExplorerGQL extends Apollo.Query<GetMaterialForMaterialExplorerQuery, GetMaterialForMaterialExplorerQueryVariables> {
    override document = GetMaterialForMaterialExplorerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialsDocument = gql`
    query getMaterials($skip: Int, $take: Int, $filter: MaterialFilterInput, $orderBy: [MaterialOrderByInput!]) {
  materials(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...MaterialsGridItem
  }
  materialsCount(filter: $filter)
}
    ${MaterialsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialsGQL extends Apollo.Query<GetMaterialsQuery, GetMaterialsQueryVariables> {
    override document = GetMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialIdsDocument = gql`
    query getMaterialIds($filter: MaterialFilterInput) {
  materials(filter: $filter) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialIdsGQL extends Apollo.Query<GetMaterialIdsQuery, GetMaterialIdsQueryVariables> {
    override document = GetMaterialIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialForCopyDocument = gql`
    query getMaterialForCopy($legacyId: Int, $id: ID) {
  material(legacyId: $legacyId, id: $id) {
    legacyId
    articleId
    name
    nextActor
    organization {
      id
      legacyId
      name
    }
    tagAssignments {
      tag {
        id
        legacyId
        name
      }
    }
    tagAssignments {
      id
      tag {
        id
        legacyId
        createdBy {
          id
        }
        name
        description
        color
        type
        organization {
          id
          legacyId
        }
      }
    }
    public
    meshSpecific
    sampleArrival
    revisions {
      id
      number
      graphSchema
      comment
      hasCyclesMaterial
      nodes {
        id
        name
        parameters
        textureRevision {
          id
        }
        textureSetRevision {
          id
        }
      }
      connections {
        id
        destination {
          id
        }
        destinationParameter
        source {
          id
        }
        sourceParameter
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialForCopyGQL extends Apollo.Query<GetMaterialForCopyQuery, GetMaterialForCopyQueryVariables> {
    override document = GetMaterialForCopyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateMaterialsDocument = gql`
    mutation batchUpdateMaterials($filter: MaterialFilterInput!, $state: MaterialState, $addTagId: ID, $removeTagId: ID, $paymentState: PaymentState, $assignUserId: ID, $removeUserAssignment: Boolean, $nextActor: NextActor) {
  batchUpdateMaterials(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    paymentState: $paymentState
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
    nextActor: $nextActor
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateMaterialsGQL extends Apollo.Mutation<BatchUpdateMaterialsMutation, BatchUpdateMaterialsMutationVariables> {
    override document = BatchUpdateMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialDocument = gql`
    mutation createMaterial($input: MutationCreateMaterialInput!) {
  createMaterial(input: $input) {
    ...MaterialsGridItem
  }
}
    ${MaterialsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialGQL extends Apollo.Mutation<CreateMaterialMutation, CreateMaterialMutationVariables> {
    override document = CreateMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMaterialDocument = gql`
    mutation updateMaterial($input: MutationUpdateMaterialInput!) {
  updateMaterial(input: $input) {
    ...MaterialsGridItem
  }
}
    ${MaterialsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMaterialGQL extends Apollo.Mutation<UpdateMaterialMutation, UpdateMaterialMutationVariables> {
    override document = UpdateMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialRevisionDocument = gql`
    mutation createMaterialRevision($input: MutationCreateMaterialRevisionInput!) {
  createMaterialRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialRevisionGQL extends Apollo.Mutation<CreateMaterialRevisionMutation, CreateMaterialRevisionMutationVariables> {
    override document = CreateMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialNodeDocument = gql`
    mutation createMaterialNode($input: MutationCreateMaterialNodeInput!) {
  createMaterialNode(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialNodeGQL extends Apollo.Mutation<CreateMaterialNodeMutation, CreateMaterialNodeMutationVariables> {
    override document = CreateMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialConnectionDocument = gql`
    mutation createMaterialConnection($input: MutationCreateMaterialConnectionInput!) {
  createMaterialConnection(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialConnectionGQL extends Apollo.Mutation<CreateMaterialConnectionMutation, CreateMaterialConnectionMutationVariables> {
    override document = CreateMaterialConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridDeleteThumbnailDocument = gql`
    mutation materialsGridDeleteThumbnail($dataObjectId: ID) {
  deleteDataObject(id: $dataObjectId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridDeleteThumbnailGQL extends Apollo.Mutation<MaterialsGridDeleteThumbnailMutation, MaterialsGridDeleteThumbnailMutationVariables> {
    override document = MaterialsGridDeleteThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridUpdateMaterialDocument = gql`
    mutation materialsGridUpdateMaterial($input: MutationUpdateMaterialInput!) {
  updateMaterial(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridUpdateMaterialGQL extends Apollo.Mutation<MaterialsGridUpdateMaterialMutation, MaterialsGridUpdateMaterialMutationVariables> {
    override document = MaterialsGridUpdateMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridCreateDataObjectAssignmentDocument = gql`
    mutation materialsGridCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridCreateDataObjectAssignmentGQL extends Apollo.Mutation<MaterialsGridCreateDataObjectAssignmentMutation, MaterialsGridCreateDataObjectAssignmentMutationVariables> {
    override document = MaterialsGridCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridMaterialRangeTagsDocument = gql`
    query materialsGridMaterialRangeTags {
  tags(filter: {tagType: MaterialRange}) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridMaterialRangeTagsGQL extends Apollo.Query<MaterialsGridMaterialRangeTagsQuery, MaterialsGridMaterialRangeTagsQueryVariables> {
    override document = MaterialsGridMaterialRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelectMaterialItemsDocument = gql`
    query selectMaterialItems($skip: Int, $take: Int, $filter: MaterialFilterInput) {
  items: materials(skip: $skip, take: $take, filter: $filter) {
    ...SelectMaterialItem
  }
  totalCount: materialsCount(filter: $filter)
}
    ${SelectMaterialItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectMaterialItemsGQL extends Apollo.Query<SelectMaterialItemsQuery, SelectMaterialItemsQueryVariables> {
    override document = SelectMaterialItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ModelDetailsDocument = gql`
    query modelDetails($id: ID!) {
  item: model(id: $id) {
    ...ModelDetails
  }
}
    ${ModelDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ModelDetailsGQL extends Apollo.Query<ModelDetailsQuery, ModelDetailsQueryVariables> {
    override document = ModelDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModelDetailsDocument = gql`
    mutation updateModelDetails($input: MutationUpdateModelInput!) {
  item: updateModel(input: $input) {
    ...ModelDetails
  }
}
    ${ModelDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelDetailsGQL extends Apollo.Mutation<UpdateModelDetailsMutation, UpdateModelDetailsMutationVariables> {
    override document = UpdateModelDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteModelDetailsDocument = gql`
    mutation deleteModelDetails($id: ID!) {
  deleteModel(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteModelDetailsGQL extends Apollo.Mutation<DeleteModelDetailsMutation, DeleteModelDetailsMutationVariables> {
    override document = DeleteModelDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetModelsDocument = gql`
    query getModels($skip: Int, $take: Int, $filter: ModelFilterInput, $orderBy: [ModelOrderByInput!]) {
  models(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...ModelListItem
  }
  modelsCount(filter: $filter)
}
    ${ModelListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetModelsGQL extends Apollo.Query<GetModelsQuery, GetModelsQueryVariables> {
    override document = GetModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateModelDocument = gql`
    mutation createModel($input: MutationCreateModelInput!) {
  createModel(input: $input) {
    ...ModelListItem
  }
}
    ${ModelListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateModelGQL extends Apollo.Mutation<CreateModelMutation, CreateModelMutationVariables> {
    override document = CreateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModelDocument = gql`
    mutation updateModel($input: MutationUpdateModelInput!) {
  updateModel(input: $input) {
    ...ModelListItem
  }
}
    ${ModelListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelGQL extends Apollo.Mutation<UpdateModelMutation, UpdateModelMutationVariables> {
    override document = UpdateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateModelsDocument = gql`
    mutation batchUpdateModels($filter: ModelFilterInput!, $state: ModelState, $addTagId: ID, $removeTagId: ID, $paymentState: PaymentState, $assignUserId: ID, $removeUserAssignment: Boolean, $nextActor: NextActor) {
  batchUpdateModels(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    paymentState: $paymentState
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
    nextActor: $nextActor
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateModelsGQL extends Apollo.Mutation<BatchUpdateModelsMutation, BatchUpdateModelsMutationVariables> {
    override document = BatchUpdateModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelectModelItemsDocument = gql`
    query selectModelItems($skip: Int, $take: Int, $filter: ModelFilterInput) {
  items: models(skip: $skip, take: $take, filter: $filter) {
    ...SelectModelItem
  }
  totalCount: modelsCount(filter: $filter)
}
    ${SelectModelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectModelItemsGQL extends Apollo.Query<SelectModelItemsQuery, SelectModelItemsQueryVariables> {
    override document = SelectModelItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationDetailsDocument = gql`
    query organizationDetails($id: ID, $legacyId: Int) {
  item: organization(id: $id, legacyId: $legacyId) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationDetailsGQL extends Apollo.Query<OrganizationDetailsQuery, OrganizationDetailsQueryVariables> {
    override document = OrganizationDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasItemUploadLinkDocument = gql`
    query feedbackCanvasItemUploadLink($dataObjectId: ID!) {
  dataObject(id: $dataObjectId) {
    signedUploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasItemUploadLinkGQL extends Apollo.Query<FeedbackCanvasItemUploadLinkQuery, FeedbackCanvasItemUploadLinkQueryVariables> {
    override document = FeedbackCanvasItemUploadLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasItemDocument = gql`
    query feedbackCanvasItem($pictureRevisionId: ID!) {
  item: pictureRevision(id: $pictureRevisionId) {
    ...FeedbackCanvasItem
  }
}
    ${FeedbackCanvasItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasItemGQL extends Apollo.Query<FeedbackCanvasItemQuery, FeedbackCanvasItemQueryVariables> {
    override document = FeedbackCanvasItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasDeleteDataObjectAssignmentDocument = gql`
    mutation feedbackCanvasDeleteDataObjectAssignment($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasDeleteDataObjectAssignmentGQL extends Apollo.Mutation<FeedbackCanvasDeleteDataObjectAssignmentMutation, FeedbackCanvasDeleteDataObjectAssignmentMutationVariables> {
    override document = FeedbackCanvasDeleteDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasUpdateDrawingDataDocument = gql`
    mutation feedbackCanvasUpdateDrawingData($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    ...FeedbackCanvasDataObject
  }
}
    ${FeedbackCanvasDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasUpdateDrawingDataGQL extends Apollo.Mutation<FeedbackCanvasUpdateDrawingDataMutation, FeedbackCanvasUpdateDrawingDataMutationVariables> {
    override document = FeedbackCanvasUpdateDrawingDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasCreateDataObjectAssignmentDocument = gql`
    mutation feedbackCanvasCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
    dataObject {
      ...FeedbackCanvasDataObject
    }
  }
}
    ${FeedbackCanvasDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasCreateDataObjectAssignmentGQL extends Apollo.Mutation<FeedbackCanvasCreateDataObjectAssignmentMutation, FeedbackCanvasCreateDataObjectAssignmentMutationVariables> {
    override document = FeedbackCanvasCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasTaskDocument = gql`
    query feedbackCanvasTask($taskId: ID!) {
  task(id: $taskId) {
    ...FeedbackCanvasTask
  }
}
    ${FeedbackCanvasTaskFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasTaskGQL extends Apollo.Query<FeedbackCanvasTaskQuery, FeedbackCanvasTaskQueryVariables> {
    override document = FeedbackCanvasTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasCreateTaskPinDocument = gql`
    mutation feedbackCanvasCreateTaskPin($input: MutationCreateTaskPinInput!) {
  createTaskPin(input: $input) {
    ...FeedbackCanvasTaskPin
  }
}
    ${FeedbackCanvasTaskPinFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasCreateTaskPinGQL extends Apollo.Mutation<FeedbackCanvasCreateTaskPinMutation, FeedbackCanvasCreateTaskPinMutationVariables> {
    override document = FeedbackCanvasCreateTaskPinDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasUpdateTaskPinPositionDocument = gql`
    mutation feedbackCanvasUpdateTaskPinPosition($input: MutationUpdateTaskPinInput!) {
  updateTaskPin(input: $input) {
    ...FeedbackCanvasTaskPin
  }
}
    ${FeedbackCanvasTaskPinFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasUpdateTaskPinPositionGQL extends Apollo.Mutation<FeedbackCanvasUpdateTaskPinPositionMutation, FeedbackCanvasUpdateTaskPinPositionMutationVariables> {
    override document = FeedbackCanvasUpdateTaskPinPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MovePictureProjectsDocument = gql`
    query movePictureProjects($filter: ProjectFilterInput, $orderBy: [ProjectOrderByInput!], $skip: Int, $take: Int) {
  projects(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    ...MovePictureDialogProject
  }
}
    ${MovePictureDialogProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MovePictureProjectsGQL extends Apollo.Query<MovePictureProjectsQuery, MovePictureProjectsQueryVariables> {
    override document = MovePictureProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsDocument = gql`
    query pictureDetails($id: ID!) {
  item: picture(id: $id) {
    ...PictureDetails
  }
}
    ${PictureDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsGQL extends Apollo.Query<PictureDetailsQuery, PictureDetailsQueryVariables> {
    override document = PictureDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsRenderJobsDocument = gql`
    query pictureDetailsRenderJobs($pictureLegacyId: Int!) {
  jobAssignments(
    filter: {objectLegacyId: $pictureLegacyId, contentTypeModel: Picture}
  ) {
    job {
      ...PictureDetailsRenderJob
    }
  }
}
    ${PictureDetailsRenderJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsRenderJobsGQL extends Apollo.Query<PictureDetailsRenderJobsQuery, PictureDetailsRenderJobsQueryVariables> {
    override document = PictureDetailsRenderJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePictureDetailsAssetAssignmentDocument = gql`
    mutation createPictureDetailsAssetAssignment($input: MutationCreatePictureAssetAssignmentInput!) {
  createPictureAssetAssignment(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePictureDetailsAssetAssignmentGQL extends Apollo.Mutation<CreatePictureDetailsAssetAssignmentMutation, CreatePictureDetailsAssetAssignmentMutationVariables> {
    override document = CreatePictureDetailsAssetAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePictureDetailsDocument = gql`
    mutation updatePictureDetails($input: MutationUpdatePictureInput!) {
  item: updatePicture(input: $input) {
    ...PictureDetails
  }
}
    ${PictureDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePictureDetailsGQL extends Apollo.Mutation<UpdatePictureDetailsMutation, UpdatePictureDetailsMutationVariables> {
    override document = UpdatePictureDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsDeleteAssetAssignmentDocument = gql`
    mutation pictureDetailsDeleteAssetAssignment($id: ID!) {
  deletePictureAssetAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsDeleteAssetAssignmentGQL extends Apollo.Mutation<PictureDetailsDeleteAssetAssignmentMutation, PictureDetailsDeleteAssetAssignmentMutationVariables> {
    override document = PictureDetailsDeleteAssetAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsDeleteRevisionDocument = gql`
    mutation pictureDetailsDeleteRevision($id: ID!) {
  deletePictureRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsDeleteRevisionGQL extends Apollo.Mutation<PictureDetailsDeleteRevisionMutation, PictureDetailsDeleteRevisionMutationVariables> {
    override document = PictureDetailsDeleteRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePictureDetailsDocument = gql`
    mutation deletePictureDetails($id: ID!) {
  deletePicture(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePictureDetailsGQL extends Apollo.Mutation<DeletePictureDetailsMutation, DeletePictureDetailsMutationVariables> {
    override document = DeletePictureDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsUpdatePictureDocument = gql`
    mutation pictureDetailsUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    ...PictureDetails
  }
}
    ${PictureDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsUpdatePictureGQL extends Apollo.Mutation<PictureDetailsUpdatePictureMutation, PictureDetailsUpdatePictureMutationVariables> {
    override document = PictureDetailsUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionSelectorItemDocument = gql`
    query pictureRevisionSelectorItem($id: ID!) {
  item: picture(id: $id) {
    ...PictureRevisionSelectorPicture
  }
}
    ${PictureRevisionSelectorPictureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionSelectorItemGQL extends Apollo.Query<PictureRevisionSelectorItemQuery, PictureRevisionSelectorItemQueryVariables> {
    override document = PictureRevisionSelectorItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionCreateDataObjectAssignmentDocument = gql`
    mutation pictureRevisionCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionCreateDataObjectAssignmentGQL extends Apollo.Mutation<PictureRevisionCreateDataObjectAssignmentMutation, PictureRevisionCreateDataObjectAssignmentMutationVariables> {
    override document = PictureRevisionCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionSelectorUpdatePictureDocument = gql`
    mutation pictureRevisionSelectorUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    ...PictureRevisionSelectorPicture
  }
}
    ${PictureRevisionSelectorPictureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionSelectorUpdatePictureGQL extends Apollo.Mutation<PictureRevisionSelectorUpdatePictureMutation, PictureRevisionSelectorUpdatePictureMutationVariables> {
    override document = PictureRevisionSelectorUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionSelectorCreateRevisionDocument = gql`
    mutation pictureRevisionSelectorCreateRevision($input: MutationCreatePictureRevisionInput!) {
  createPictureRevision(input: $input) {
    ...PictureRevisionSelectorRevision
  }
}
    ${PictureRevisionSelectorRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionSelectorCreateRevisionGQL extends Apollo.Mutation<PictureRevisionSelectorCreateRevisionMutation, PictureRevisionSelectorCreateRevisionMutationVariables> {
    override document = PictureRevisionSelectorCreateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureArStatisticsDocument = gql`
    query pictureArStatistics($filter: ArStatisticsFilterInput!) {
  arStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureArStatisticsGQL extends Apollo.Query<PictureArStatisticsQuery, PictureArStatisticsQueryVariables> {
    override document = PictureArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureSceneStatisticsDocument = gql`
    query pictureSceneStatistics($filter: SceneStatisticsFilterInput!) {
  sceneStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureSceneStatisticsGQL extends Apollo.Query<PictureSceneStatisticsQuery, PictureSceneStatisticsQueryVariables> {
    override document = PictureSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureViewerItemDocument = gql`
    query pictureViewerItem($id: ID!) {
  picture(id: $id) {
    ...PictureViewerPicture
  }
}
    ${PictureViewerPictureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureViewerItemGQL extends Apollo.Query<PictureViewerItemQuery, PictureViewerItemQueryVariables> {
    override document = PictureViewerItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureViewerPictureDataObjectThumbnailDocument = gql`
    query pictureViewerPictureDataObjectThumbnail($id: ID!) {
  dataObject(id: $id) {
    ...PictureViewerPictureDataObjectThumbnail
  }
}
    ${PictureViewerPictureDataObjectThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureViewerPictureDataObjectThumbnailGQL extends Apollo.Query<PictureViewerPictureDataObjectThumbnailQuery, PictureViewerPictureDataObjectThumbnailQueryVariables> {
    override document = PictureViewerPictureDataObjectThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridPictureDataDocument = gql`
    query picturesGridPictureData($id: ID!) {
  pictureRevision(id: $id) {
    pictureData: dataObjectAssignments(
      filter: {assignmentType: PictureData}
      take: 1
    ) {
      dataObject {
        id
        legacyId
        originalFileName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridPictureDataGQL extends Apollo.Query<PicturesGridPictureDataQuery, PicturesGridPictureDataQueryVariables> {
    override document = PicturesGridPictureDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridUpdateDataObjectDocument = gql`
    mutation picturesGridUpdateDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridUpdateDataObjectGQL extends Apollo.Mutation<PicturesGridUpdateDataObjectMutation, PicturesGridUpdateDataObjectMutationVariables> {
    override document = PicturesGridUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridCreatePictureDocument = gql`
    mutation picturesGridCreatePicture($input: MutationCreatePictureInput!) {
  createPicture(input: $input) {
    ...PicturesGridItem
  }
}
    ${PicturesGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridCreatePictureGQL extends Apollo.Mutation<PicturesGridCreatePictureMutation, PicturesGridCreatePictureMutationVariables> {
    override document = PicturesGridCreatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridCreateTemplateDocument = gql`
    mutation picturesGridCreateTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridCreateTemplateGQL extends Apollo.Mutation<PicturesGridCreateTemplateMutation, PicturesGridCreateTemplateMutationVariables> {
    override document = PicturesGridCreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridItemsDocument = gql`
    query picturesGridItems($skip: Int, $take: Int, $filter: PictureFilterInput, $orderBy: [PictureOrderByInput!]) {
  pictures(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...PicturesGridItem
  }
  picturesCount(filter: $filter)
}
    ${PicturesGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridItemsGQL extends Apollo.Query<PicturesGridItemsQuery, PicturesGridItemsQueryVariables> {
    override document = PicturesGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridUpdatePictureDocument = gql`
    mutation picturesGridUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    ...PicturesGridItem
  }
}
    ${PicturesGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridUpdatePictureGQL extends Apollo.Mutation<PicturesGridUpdatePictureMutation, PicturesGridUpdatePictureMutationVariables> {
    override document = PicturesGridUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridDeletePictureDocument = gql`
    mutation picturesGridDeletePicture($id: ID!) {
  deletePicture(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridDeletePictureGQL extends Apollo.Mutation<PicturesGridDeletePictureMutation, PicturesGridDeletePictureMutationVariables> {
    override document = PicturesGridDeletePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridCreatePictureRevisionDocument = gql`
    mutation picturesGridCreatePictureRevision($input: MutationCreatePictureRevisionInput!) {
  createPictureRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridCreatePictureRevisionGQL extends Apollo.Mutation<PicturesGridCreatePictureRevisionMutation, PicturesGridCreatePictureRevisionMutationVariables> {
    override document = PicturesGridCreatePictureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridBatchUpdatePicturesDocument = gql`
    mutation picturesGridBatchUpdatePictures($addTagId: ID, $assignUserId: ID, $nextActor: NextActor, $filter: PictureFilterInput!, $paymentState: PaymentState, $removeTagId: ID, $removeUserAssignment: Boolean, $state: PictureState) {
  batchUpdatePictures(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    nextActor: $nextActor
    paymentState: $paymentState
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridBatchUpdatePicturesGQL extends Apollo.Mutation<PicturesGridBatchUpdatePicturesMutation, PicturesGridBatchUpdatePicturesMutationVariables> {
    override document = PicturesGridBatchUpdatePicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridSetForPicturesDocument = gql`
    query picturesGridSetForPictures($setLegacyId: Int!) {
  set(legacyId: $setLegacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridSetForPicturesGQL extends Apollo.Query<PicturesGridSetForPicturesQuery, PicturesGridSetForPicturesQueryVariables> {
    override document = PicturesGridSetForPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridProjectForPicturesDocument = gql`
    query picturesGridProjectForPictures($projectLegacyId: Int!) {
  project(legacyId: $projectLegacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridProjectForPicturesGQL extends Apollo.Query<PicturesGridProjectForPicturesQuery, PicturesGridProjectForPicturesQueryVariables> {
    override document = PicturesGridProjectForPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridVisibleUsersDocument = gql`
    query picturesGridVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...PicturesGridUser
  }
}
    ${PicturesGridUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridVisibleUsersGQL extends Apollo.Query<PicturesGridVisibleUsersQuery, PicturesGridVisibleUsersQueryVariables> {
    override document = PicturesGridVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeProjectsDocument = gql`
    query projectTreeProjects($organizationId: ID!) {
  projects(
    filter: {organizationId: {equals: $organizationId}}
    orderBy: [{key: legacyId, direction: desc}]
    take: 1000
  ) {
    ...ProjectTreeProject
  }
}
    ${ProjectTreeProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeProjectsGQL extends Apollo.Query<ProjectTreeProjectsQuery, ProjectTreeProjectsQueryVariables> {
    override document = ProjectTreeProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeCreateProjectDocument = gql`
    mutation projectTreeCreateProject($input: MutationCreateProjectInput!) {
  createProject(input: $input) {
    ...ProjectTreeProject
  }
}
    ${ProjectTreeProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeCreateProjectGQL extends Apollo.Mutation<ProjectTreeCreateProjectMutation, ProjectTreeCreateProjectMutationVariables> {
    override document = ProjectTreeCreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeDeleteProjectDocument = gql`
    mutation projectTreeDeleteProject($id: ID!) {
  deleteProject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeDeleteProjectGQL extends Apollo.Mutation<ProjectTreeDeleteProjectMutation, ProjectTreeDeleteProjectMutationVariables> {
    override document = ProjectTreeDeleteProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeDeleteSetDocument = gql`
    mutation projectTreeDeleteSet($id: ID!) {
  deleteSet(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeDeleteSetGQL extends Apollo.Mutation<ProjectTreeDeleteSetMutation, ProjectTreeDeleteSetMutationVariables> {
    override document = ProjectTreeDeleteSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeSetDocument = gql`
    query projectTreeSet($id: ID!) {
  set(id: $id) {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeSetGQL extends Apollo.Query<ProjectTreeSetQuery, ProjectTreeSetQueryVariables> {
    override document = ProjectTreeSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeUpdateProjectDocument = gql`
    mutation projectTreeUpdateProject($input: MutationUpdateProjectInput!) {
  updateProject(input: $input) {
    ...ProjectTreeProject
  }
}
    ${ProjectTreeProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeUpdateProjectGQL extends Apollo.Mutation<ProjectTreeUpdateProjectMutation, ProjectTreeUpdateProjectMutationVariables> {
    override document = ProjectTreeUpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeUpdateSetDocument = gql`
    mutation projectTreeUpdateSet($input: MutationUpdateSetInput!) {
  updateSet(input: $input) {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeUpdateSetGQL extends Apollo.Mutation<ProjectTreeUpdateSetMutation, ProjectTreeUpdateSetMutationVariables> {
    override document = ProjectTreeUpdateSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeCreateSetDocument = gql`
    mutation projectTreeCreateSet($input: MutationCreateSetInput!) {
  createSet(input: $input) {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeCreateSetGQL extends Apollo.Mutation<ProjectTreeCreateSetMutation, ProjectTreeCreateSetMutationVariables> {
    override document = ProjectTreeCreateSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreePicturesDocument = gql`
    query projectTreePictures($take: Int, $filter: PictureFilterInput) {
  pictures(take: $take, filter: $filter) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreePicturesGQL extends Apollo.Query<ProjectTreePicturesQuery, ProjectTreePicturesQueryVariables> {
    override document = ProjectTreePicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeUpdatePictureDocument = gql`
    mutation projectTreeUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeUpdatePictureGQL extends Apollo.Mutation<ProjectTreeUpdatePictureMutation, ProjectTreeUpdatePictureMutationVariables> {
    override document = ProjectTreeUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeVisibleUsersDocument = gql`
    query projectTreeVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ProjectTreeUser
  }
}
    ${ProjectTreeUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeVisibleUsersGQL extends Apollo.Query<ProjectTreeVisibleUsersQuery, ProjectTreeVisibleUsersQueryVariables> {
    override document = ProjectTreeVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenderJobViewerDialogJobDocument = gql`
    query renderJobViewerDialogJob($legacyId: Int!) {
  job(legacyId: $legacyId) {
    id
    output
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenderJobViewerDialogJobGQL extends Apollo.Query<RenderJobViewerDialogJobQuery, RenderJobViewerDialogJobQueryVariables> {
    override document = RenderJobViewerDialogJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanningActionBarTagDocument = gql`
    query scanningActionBarTag($filter: TagFilterInput!) {
  tags(filter: $filter, take: 1) {
    ...ScanningActionBarTag
  }
}
    ${ScanningActionBarTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanningActionBarTagGQL extends Apollo.Query<ScanningActionBarTagQuery, ScanningActionBarTagQueryVariables> {
    override document = ScanningActionBarTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialWithRangeTagsDocument = gql`
    query materialWithRangeTags($id: ID!) {
  material(id: $id) {
    tagAssignments(tagTypes: [MaterialRange]) {
      tag {
        ...ScanningActionBarTag
      }
    }
  }
}
    ${ScanningActionBarTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialWithRangeTagsGQL extends Apollo.Query<MaterialWithRangeTagsQuery, MaterialWithRangeTagsQueryVariables> {
    override document = MaterialWithRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsWithScanningUrlDocument = gql`
    query organizationsWithScanningUrl($organizationIds: [ID!]!) {
  organizations(filter: {id: {in: $organizationIds}}) {
    id
    legacyId
    defaultScannerUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsWithScanningUrlGQL extends Apollo.Query<OrganizationsWithScanningUrlQuery, OrganizationsWithScanningUrlQueryVariables> {
    override document = OrganizationsWithScanningUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTagDocument = gql`
    mutation addTag($input: MutationCreateTagInput!) {
  createTag(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTagGQL extends Apollo.Mutation<AddTagMutation, AddTagMutationVariables> {
    override document = AddTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagAssignmentsLoadPageDocument = gql`
    query tagAssignmentsLoadPage($filter: TagAssignmentFilterInput, $take: Int!, $skip: Int!) {
  items: tagAssignments(filter: $filter, take: $take, skip: $skip) {
    ...TagAssignmentsItem
  }
  totalCount: tagAssignmentsCount(filter: $filter)
}
    ${TagAssignmentsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagAssignmentsLoadPageGQL extends Apollo.Query<TagAssignmentsLoadPageQuery, TagAssignmentsLoadPageQueryVariables> {
    override document = TagAssignmentsLoadPageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagAssignmentsDeleteAssignmentDocument = gql`
    mutation tagAssignmentsDeleteAssignment($id: ID!) {
  deleteTagAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagAssignmentsDeleteAssignmentGQL extends Apollo.Mutation<TagAssignmentsDeleteAssignmentMutation, TagAssignmentsDeleteAssignmentMutationVariables> {
    override document = TagAssignmentsDeleteAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagDetailsDocument = gql`
    query getTagDetails($id: ID!) {
  item: tag(id: $id) {
    ...TagDetailsItem
  }
}
    ${TagDetailsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagDetailsGQL extends Apollo.Query<GetTagDetailsQuery, GetTagDetailsQueryVariables> {
    override document = GetTagDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTagDetailsDocument = gql`
    mutation updateTagDetails($input: MutationUpdateTagInput!) {
  item: updateTag(input: $input) {
    ...TagDetailsItem
  }
}
    ${TagDetailsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTagDetailsGQL extends Apollo.Mutation<UpdateTagDetailsMutation, UpdateTagDetailsMutationVariables> {
    override document = UpdateTagDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsGridItemsDocument = gql`
    query getTagsGridItems($filter: TagFilterInput, $order: [TagOrderByInput!], $take: Int, $skip: Int) {
  items: tags(filter: $filter, orderBy: $order, take: $take, skip: $skip) {
    ...TagsGridItem
  }
  totalCount: tagsCount(filter: $filter)
}
    ${TagsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsGridItemsGQL extends Apollo.Query<GetTagsGridItemsQuery, GetTagsGridItemsQueryVariables> {
    override document = GetTagsGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsTableItemsDocument = gql`
    query getTagsTableItems($filter: TagFilterInput, $order: [TagOrderByInput!], $take: Int, $skip: Int) {
  items: tags(filter: $filter, orderBy: $order, take: $take, skip: $skip) {
    ...TagsTableItem
  }
  totalCount: tagsCount(filter: $filter)
}
    ${TagsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsTableItemsGQL extends Apollo.Query<GetTagsTableItemsQuery, GetTagsTableItemsQueryVariables> {
    override document = GetTagsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableItemsDocument = gql`
    query taskTypesTableItems($filter: JobTaskTypeFilterInput, $skip: Int, $take: Int) {
  items: jobTaskTypes(filter: $filter, skip: $skip, take: $take) {
    ...TaskTypesTableItem
  }
  itemsCount: jobTaskTypesCount(filter: $filter)
}
    ${TaskTypesTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableItemsGQL extends Apollo.Query<TaskTypesTableItemsQuery, TaskTypesTableItemsQueryVariables> {
    override document = TaskTypesTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableUpdateTaskTypeDocument = gql`
    mutation taskTypesTableUpdateTaskType($input: MutationUpdateJobTaskTypeInput!) {
  updateJobTaskType(input: $input) {
    ...TaskTypesTableItem
  }
}
    ${TaskTypesTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableUpdateTaskTypeGQL extends Apollo.Mutation<TaskTypesTableUpdateTaskTypeMutation, TaskTypesTableUpdateTaskTypeMutationVariables> {
    override document = TaskTypesTableUpdateTaskTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableCreateTaskTypeDocument = gql`
    mutation taskTypesTableCreateTaskType($input: MutationCreateJobTaskTypeInput!) {
  createJobTaskType(input: $input) {
    ...TaskTypesTableItem
  }
}
    ${TaskTypesTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableCreateTaskTypeGQL extends Apollo.Mutation<TaskTypesTableCreateTaskTypeMutation, TaskTypesTableCreateTaskTypeMutationVariables> {
    override document = TaskTypesTableCreateTaskTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableDeleteTaskTypeDocument = gql`
    mutation taskTypesTableDeleteTaskType($id: ID!) {
  deleteJobTaskType(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableDeleteTaskTypeGQL extends Apollo.Mutation<TaskTypesTableDeleteTaskTypeMutation, TaskTypesTableDeleteTaskTypeMutationVariables> {
    override document = TaskTypesTableDeleteTaskTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskDetailsDocument = gql`
    query taskDetails($id: ID!) {
  item: task(id: $id) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskDetailsGQL extends Apollo.Query<TaskDetailsQuery, TaskDetailsQueryVariables> {
    override document = TaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskDetailsDocument = gql`
    mutation updateTaskDetails($input: MutationUpdateTaskInput!) {
  item: updateTask(input: $input) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskDetailsGQL extends Apollo.Mutation<UpdateTaskDetailsMutation, UpdateTaskDetailsMutationVariables> {
    override document = UpdateTaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskDetailsCreateCommentDocument = gql`
    mutation taskDetailsCreateComment($input: MutationCreateTaskCommentInput!) {
  createTaskComment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskDetailsCreateCommentGQL extends Apollo.Mutation<TaskDetailsCreateCommentMutation, TaskDetailsCreateCommentMutationVariables> {
    override document = TaskDetailsCreateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskDetailsDeleteCommentDocument = gql`
    mutation taskDetailsDeleteComment($id: ID!) {
  deleteTaskComment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskDetailsDeleteCommentGQL extends Apollo.Mutation<TaskDetailsDeleteCommentMutation, TaskDetailsDeleteCommentMutationVariables> {
    override document = TaskDetailsDeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskListDocument = gql`
    query taskList($objectId: ID!, $contentTypeModel: ContentTypeModel!) {
  tasks(
    filter: {objectId: {equals: $objectId}, contentTypeModel: $contentTypeModel}
    take: 1000
  ) {
    ...TaskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskListGQL extends Apollo.Query<TaskListQuery, TaskListQueryVariables> {
    override document = TaskListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskListItemDocument = gql`
    mutation updateTaskListItem($input: MutationUpdateTaskInput!) {
  updateTask(input: $input) {
    ...TaskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskListItemGQL extends Apollo.Mutation<UpdateTaskListItemMutation, UpdateTaskListItemMutationVariables> {
    override document = UpdateTaskListItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTaskListItemDocument = gql`
    mutation addTaskListItem($input: MutationCreateTaskInput!) {
  createTask(input: $input) {
    ...TaskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTaskListItemGQL extends Apollo.Mutation<AddTaskListItemMutation, AddTaskListItemMutationVariables> {
    override document = AddTaskListItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCommentToTaskListItemDocument = gql`
    mutation addCommentToTaskListItem($input: MutationCreateTaskCommentInput!) {
  createTaskComment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCommentToTaskListItemGQL extends Apollo.Mutation<AddCommentToTaskListItemMutation, AddCommentToTaskListItemMutationVariables> {
    override document = AddCommentToTaskListItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignUserDialogVisibleUsersDocument = gql`
    query assignUserDialogVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 1000) {
    ...AssignUserDialogUser
  }
}
    ${AssignUserDialogUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignUserDialogVisibleUsersGQL extends Apollo.Query<AssignUserDialogVisibleUsersQuery, AssignUserDialogVisibleUsersQueryVariables> {
    override document = AssignUserDialogVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDetailsDocument = gql`
    query userDetails($id: ID!) {
  item: user(id: $id) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDetailsGQL extends Apollo.Query<UserDetailsQuery, UserDetailsQueryVariables> {
    override document = UserDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDetailsDocument = gql`
    mutation updateUserDetails($input: MutationUpdateUserInput!) {
  item: updateUser(input: $input) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserDetailsGQL extends Apollo.Mutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables> {
    override document = UpdateUserDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDetailsCreateMembershipDocument = gql`
    mutation userDetailsCreateMembership($input: MutationCreateMembershipInput!) {
  item: createMembership(input: $input) {
    id
    organization {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDetailsCreateMembershipGQL extends Apollo.Mutation<UserDetailsCreateMembershipMutation, UserDetailsCreateMembershipMutationVariables> {
    override document = UserDetailsCreateMembershipDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDetailsDeleteMembershipDocument = gql`
    mutation userDetailsDeleteMembership($id: ID!) {
  item: deleteMembership(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDetailsDeleteMembershipGQL extends Apollo.Mutation<UserDetailsDeleteMembershipMutation, UserDetailsDeleteMembershipMutationVariables> {
    override document = UserDetailsDeleteMembershipDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query getUsers($skip: Int, $take: Int, $filter: UserFilterInput) {
  users(skip: $skip, take: $take, filter: $filter) {
    ...UserListItem
  }
  usersCount(filter: $filter)
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    override document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: MutationCreateUserInput!) {
  createUser(input: $input) {
    ...UserListItem
  }
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    override document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteUserDocument = gql`
    mutation inviteUser($input: MutationInviteUserInput!) {
  inviteUser(input: $input) {
    ...UserListItem
  }
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteUserGQL extends Apollo.Mutation<InviteUserMutation, InviteUserMutationVariables> {
    override document = InviteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserAssignmentDocument = gql`
    mutation createUserAssignment($input: MutationCreateUserAssignmentInput!) {
  createUserAssignment(input: $input) {
    id
    state
    user {
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserAssignmentGQL extends Apollo.Mutation<CreateUserAssignmentMutation, CreateUserAssignmentMutationVariables> {
    override document = CreateUserAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($input: MutationUpdateUserInput!) {
  updateUser(input: $input) {
    ...UserListItem
  }
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserAssignmentsDocument = gql`
    mutation deleteUserAssignments($input: MutationDeleteUserAssignmentsInput!) {
  deleteUserAssignments(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserAssignmentsGQL extends Apollo.Mutation<DeleteUserAssignmentsMutation, DeleteUserAssignmentsMutationVariables> {
    override document = DeleteUserAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenderJobTaskJobFarmInstanceDocument = gql`
    query renderJobTaskJobFarmInstance($hwFingerprint: String!) {
  jobFarmInstances(filter: {hwFingerprint: {equals: $hwFingerprint}}) {
    otherInfo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenderJobTaskJobFarmInstanceGQL extends Apollo.Query<RenderJobTaskJobFarmInstanceQuery, RenderJobTaskJobFarmInstanceQueryVariables> {
    override document = RenderJobTaskJobFarmInstanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanRegionMaterialDocument = gql`
    query scanRegionMaterial($legacyId: Int, $id: ID) {
  material(legacyId: $legacyId, id: $id) {
    ...ScanRegionMaterial
  }
}
    ${ScanRegionMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanRegionMaterialGQL extends Apollo.Query<ScanRegionMaterialQuery, ScanRegionMaterialQueryVariables> {
    override document = ScanRegionMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionForBackendArGenerationDocument = gql`
    query getTemplateRevisionForBackendArGeneration($id: ID!, $assignmentKey: StringFilterInput!) {
  templateRevision(id: $id) {
    ...BackendArGenerationTemplateRevision
  }
}
    ${BackendArGenerationTemplateRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionForBackendArGenerationGQL extends Apollo.Query<GetTemplateRevisionForBackendArGenerationQuery, GetTemplateRevisionForBackendArGenerationQueryVariables> {
    override document = GetTemplateRevisionForBackendArGenerationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectForBackendArGenerationDocument = gql`
    mutation deleteDataObjectForBackendArGeneration($dataObjectId: ID!) {
  deleteDataObject(id: $dataObjectId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectForBackendArGenerationGQL extends Apollo.Mutation<DeleteDataObjectForBackendArGenerationMutation, DeleteDataObjectForBackendArGenerationMutationVariables> {
    override document = DeleteDataObjectForBackendArGenerationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDataObjectAssignmentForBackendArGenerationDocument = gql`
    mutation createDataObjectAssignmentForBackendArGeneration($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDataObjectAssignmentForBackendArGenerationGQL extends Apollo.Mutation<CreateDataObjectAssignmentForBackendArGenerationMutation, CreateDataObjectAssignmentForBackendArGenerationMutationVariables> {
    override document = CreateDataObjectAssignmentForBackendArGenerationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PerformLoginDocument = gql`
    mutation performLogin($input: MutationLoginInput!) {
  login(input: $input) {
    token
    user {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PerformLoginGQL extends Apollo.Mutation<PerformLoginMutation, PerformLoginMutationVariables> {
    override document = PerformLoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthenticatedUserDocument = gql`
    query authenticatedUser($id: ID!) {
  item: user(id: $id) {
    ...AuthenticatedUser
  }
}
    ${AuthenticatedUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticatedUserGQL extends Apollo.Query<AuthenticatedUserQuery, AuthenticatedUserQueryVariables> {
    override document = AuthenticatedUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginSendPasswordResetEmailDocument = gql`
    mutation loginSendPasswordResetEmail($input: MutationSendPasswordResetEmailInput!) {
  sendPasswordResetEmail(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginSendPasswordResetEmailGQL extends Apollo.Mutation<LoginSendPasswordResetEmailMutation, LoginSendPasswordResetEmailMutationVariables> {
    override document = LoginSendPasswordResetEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginResetPasswordDocument = gql`
    mutation loginResetPassword($input: MutationResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginResetPasswordGQL extends Apollo.Mutation<LoginResetPasswordMutation, LoginResetPasswordMutationVariables> {
    override document = LoginResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsPageScannerUsersDocument = gql`
    query materialsPageScannerUsers {
  users(filter: {systemRole: Scanner}) {
    ...MaterialsPageUser
  }
}
    ${MaterialsPageUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsPageScannerUsersGQL extends Apollo.Query<MaterialsPageScannerUsersQuery, MaterialsPageScannerUsersQueryVariables> {
    override document = MaterialsPageScannerUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsPageVisibleUsersDocument = gql`
    query materialsPageVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...MaterialsPageUser
  }
}
    ${MaterialsPageUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsPageVisibleUsersGQL extends Apollo.Query<MaterialsPageVisibleUsersQuery, MaterialsPageVisibleUsersQueryVariables> {
    override document = MaterialsPageVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ModelsPageVisibleUsersDocument = gql`
    query modelsPageVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ModelsPageUser
  }
}
    ${ModelsPageUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ModelsPageVisibleUsersGQL extends Apollo.Query<ModelsPageVisibleUsersQuery, ModelsPageVisibleUsersQueryVariables> {
    override document = ModelsPageVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationListItemsDocument = gql`
    query getOrganizationListItems($filter: OrganizationFilterInput, $skip: Int, $take: Int) {
  organizations(filter: $filter, skip: $skip, take: $take) {
    ...OrganizationListItem
  }
  organizationsCount(filter: $filter)
}
    ${OrganizationListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationListItemsGQL extends Apollo.Query<GetOrganizationListItemsQuery, GetOrganizationListItemsQueryVariables> {
    override document = GetOrganizationListItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindOrganizationDocument = gql`
    query findOrganization($id: ID, $legacyId: Int) {
  organization(id: $id, legacyId: $legacyId) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOrganizationGQL extends Apollo.Query<FindOrganizationQuery, FindOrganizationQueryVariables> {
    override document = FindOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationDetailsDocument = gql`
    query getOrganizationDetails($filter: OrganizationFilterInput) {
  organizations(filter: $filter) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationDetailsGQL extends Apollo.Query<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables> {
    override document = GetOrganizationDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: MutationCreateOrganizationInput!) {
  createOrganization(input: $input) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationGQL extends Apollo.Mutation<CreateOrganizationMutation, CreateOrganizationMutationVariables> {
    override document = CreateOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: MutationUpdateOrganizationInput!) {
  item: updateOrganization(input: $input) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGQL extends Apollo.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> {
    override document = UpdateOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: ID!) {
  deleteOrganization(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationGQL extends Apollo.Mutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables> {
    override document = DeleteOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSceneStatisticsDocument = gql`
    query getSceneStatistics($filter: SceneStatisticsFilterInput) {
  sceneStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSceneStatisticsGQL extends Apollo.Query<GetSceneStatisticsQuery, GetSceneStatisticsQueryVariables> {
    override document = GetSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSceneStatisticsDocument = gql`
    mutation createSceneStatistics($input: MutationCreateSceneStatisticsInput!) {
  createSceneStatistics(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSceneStatisticsGQL extends Apollo.Mutation<CreateSceneStatisticsMutation, CreateSceneStatisticsMutationVariables> {
    override document = CreateSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetArStatisticsDocument = gql`
    query getArStatistics($filter: ArStatisticsFilterInput) {
  arStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetArStatisticsGQL extends Apollo.Query<GetArStatisticsQuery, GetArStatisticsQueryVariables> {
    override document = GetArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateArStatisticsDocument = gql`
    mutation createArStatistics($input: MutationCreateArStatisticsInput!) {
  createArStatistics(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateArStatisticsGQL extends Apollo.Mutation<CreateArStatisticsMutation, CreateArStatisticsMutationVariables> {
    override document = CreateArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsAssetsHistoryDocument = gql`
    query statisticsAssetsHistory($fromDate: DateTime!, $toDate: DateTime) {
  assetHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...AssetStatistics
  }
}
    ${AssetStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsAssetsHistoryGQL extends Apollo.Query<StatisticsAssetsHistoryQuery, StatisticsAssetsHistoryQueryVariables> {
    override document = StatisticsAssetsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousAssetStatesDocument = gql`
    query statisticsPreviousAssetStates($fromDate: DateTime!, $ids: [ID!]) {
  previousAssetHistoryItems: assetHistoryItems(
    filter: {historyDate: {lte: $fromDate}, assetId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...AssetStatistics
  }
}
    ${AssetStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousAssetStatesGQL extends Apollo.Query<StatisticsPreviousAssetStatesQuery, StatisticsPreviousAssetStatesQueryVariables> {
    override document = StatisticsPreviousAssetStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsMaterialsHistoryDocument = gql`
    query statisticsMaterialsHistory($fromDate: DateTime!, $toDate: DateTime) {
  materialHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...MaterialStatistics
  }
}
    ${MaterialStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsMaterialsHistoryGQL extends Apollo.Query<StatisticsMaterialsHistoryQuery, StatisticsMaterialsHistoryQueryVariables> {
    override document = StatisticsMaterialsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousMaterialStatesDocument = gql`
    query statisticsPreviousMaterialStates($fromDate: DateTime!, $ids: [ID!]) {
  previousMaterialHistoryItems: materialHistoryItems(
    filter: {historyDate: {lte: $fromDate}, materialId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...MaterialStatistics
  }
}
    ${MaterialStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousMaterialStatesGQL extends Apollo.Query<StatisticsPreviousMaterialStatesQuery, StatisticsPreviousMaterialStatesQueryVariables> {
    override document = StatisticsPreviousMaterialStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsModelsHistoryDocument = gql`
    query statisticsModelsHistory($fromDate: DateTime!, $toDate: DateTime) {
  modelHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...ModelStatistics
  }
}
    ${ModelStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsModelsHistoryGQL extends Apollo.Query<StatisticsModelsHistoryQuery, StatisticsModelsHistoryQueryVariables> {
    override document = StatisticsModelsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousModelStatesDocument = gql`
    query statisticsPreviousModelStates($fromDate: DateTime!, $ids: [ID!]) {
  previousModelHistoryItems: modelHistoryItems(
    filter: {historyDate: {lte: $fromDate}, modelId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...ModelStatistics
  }
}
    ${ModelStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousModelStatesGQL extends Apollo.Query<StatisticsPreviousModelStatesQuery, StatisticsPreviousModelStatesQueryVariables> {
    override document = StatisticsPreviousModelStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPicturesHistoryDocument = gql`
    query statisticsPicturesHistory($fromDate: DateTime!, $toDate: DateTime) {
  pictureHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...PictureStatistics
  }
}
    ${PictureStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPicturesHistoryGQL extends Apollo.Query<StatisticsPicturesHistoryQuery, StatisticsPicturesHistoryQueryVariables> {
    override document = StatisticsPicturesHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousPictureStatesDocument = gql`
    query statisticsPreviousPictureStates($fromDate: DateTime!, $ids: [ID!]) {
  previousPictureHistoryItems: pictureHistoryItems(
    filter: {historyDate: {lte: $fromDate}, pictureId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...PictureStatistics
  }
}
    ${PictureStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousPictureStatesGQL extends Apollo.Query<StatisticsPreviousPictureStatesQuery, StatisticsPreviousPictureStatesQueryVariables> {
    override document = StatisticsPreviousPictureStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsDocument = gql`
    query getTags($filter: TagFilterInput, $skip: Int, $take: Int) {
  tags(filter: $filter, skip: $skip, take: $take) {
    ...TagListItem
  }
  tagsCount(filter: $filter)
}
    ${TagListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsGQL extends Apollo.Query<GetTagsQuery, GetTagsQueryVariables> {
    override document = GetTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsListDocument = gql`
    query getTagsList($filter: TagFilterInput, $order: [TagOrderByInput!], $skip: Int, $take: Int) {
  items: tags(filter: $filter, orderBy: $order, skip: $skip, take: $take) {
    ...TagListItem
  }
  totalCount: tagsCount(filter: $filter)
}
    ${TagListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsListGQL extends Apollo.Query<GetTagsListQuery, GetTagsListQueryVariables> {
    override document = GetTagsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTagDocument = gql`
    mutation createTag($input: MutationCreateTagInput!) {
  createTag(input: $input) {
    ...TagListItem
  }
}
    ${TagListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTagGQL extends Apollo.Mutation<CreateTagMutation, CreateTagMutationVariables> {
    override document = CreateTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTagDocument = gql`
    mutation deleteTag($id: ID!) {
  deleteTag(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTagGQL extends Apollo.Mutation<DeleteTagMutation, DeleteTagMutationVariables> {
    override document = DeleteTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeTagDocument = gql`
    mutation changeTag($input: MutationUpdateTagInput!) {
  updateTag(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeTagGQL extends Apollo.Mutation<ChangeTagMutation, ChangeTagMutationVariables> {
    override document = ChangeTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationByLegacyIdDocument = gql`
    query getOrganizationByLegacyId($legacyId: Int) {
  organization(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationByLegacyIdGQL extends Apollo.Query<GetOrganizationByLegacyIdQuery, GetOrganizationByLegacyIdQueryVariables> {
    override document = GetOrganizationByLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBatchMaterialRenderDetailsDocument = gql`
    query getBatchMaterialRenderDetails($id: ID!, $dataObjectAssignmentFilter: DataObjectAssignmentFilterInput, $includeAssignments: Boolean = true) {
  material(id: $id) {
    ...BatchMaterialRenderDetails
  }
}
    ${BatchMaterialRenderDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBatchMaterialRenderDetailsGQL extends Apollo.Query<GetBatchMaterialRenderDetailsQuery, GetBatchMaterialRenderDetailsQueryVariables> {
    override document = GetBatchMaterialRenderDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialBatchOperationMaterialWithMaterialRangeTagsDocument = gql`
    query materialBatchOperationMaterialWithMaterialRangeTags($legacyId: Int!) {
  material(legacyId: $legacyId) {
    tagAssignments(tagTypes: [MaterialRange]) {
      tag {
        ...BasicTagInfo
      }
    }
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialBatchOperationMaterialWithMaterialRangeTagsGQL extends Apollo.Query<MaterialBatchOperationMaterialWithMaterialRangeTagsQuery, MaterialBatchOperationMaterialWithMaterialRangeTagsQueryVariables> {
    override document = MaterialBatchOperationMaterialWithMaterialRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImageDownloadDetailsForBatchDownloadServiceDocument = gql`
    query getImageDownloadDetailsForBatchDownloadService($materialId: ID!, $assignmentType: DataObjectAssignmentType!) {
  material(id: $materialId) {
    dataObjectAssignments(filter: {assignmentType: [$assignmentType]}) {
      dataObject {
        ...ImageDownloadDetailsForBatchDownloadService
      }
    }
  }
}
    ${ImageDownloadDetailsForBatchDownloadServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImageDownloadDetailsForBatchDownloadServiceGQL extends Apollo.Query<GetImageDownloadDetailsForBatchDownloadServiceQuery, GetImageDownloadDetailsForBatchDownloadServiceQueryVariables> {
    override document = GetImageDownloadDetailsForBatchDownloadServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServiceTemplateDocument = gql`
    query resolveServiceTemplate($id: ID!) {
  template(id: $id) {
    ...ResolveServiceTemplate
  }
}
    ${ResolveServiceTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServiceTemplateGQL extends Apollo.Query<ResolveServiceTemplateQuery, ResolveServiceTemplateQueryVariables> {
    override document = ResolveServiceTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServicePictureDocument = gql`
    query resolveServicePicture($id: ID!) {
  picture(id: $id) {
    ...ResolveServicePicture
  }
}
    ${ResolveServicePictureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServicePictureGQL extends Apollo.Query<ResolveServicePictureQuery, ResolveServicePictureQueryVariables> {
    override document = ResolveServicePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServiceCreateTemplateDocument = gql`
    mutation resolveServiceCreateTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...ResolveServiceTemplate
  }
}
    ${ResolveServiceTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServiceCreateTemplateGQL extends Apollo.Mutation<ResolveServiceCreateTemplateMutation, ResolveServiceCreateTemplateMutationVariables> {
    override document = ResolveServiceCreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServiceUpdateTemplateDocument = gql`
    mutation resolveServiceUpdateTemplate($input: MutationUpdateTemplateInput!) {
  updateTemplate(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServiceUpdateTemplateGQL extends Apollo.Mutation<ResolveServiceUpdateTemplateMutation, ResolveServiceUpdateTemplateMutationVariables> {
    override document = ResolveServiceUpdateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServicePictureFromLegacyIdDocument = gql`
    query resolveServicePictureFromLegacyId($legacyId: Int!) {
  picture(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServicePictureFromLegacyIdGQL extends Apollo.Query<ResolveServicePictureFromLegacyIdQuery, ResolveServicePictureFromLegacyIdQueryVariables> {
    override document = ResolveServicePictureFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TasksServiceTasksDocument = gql`
    query tasksServiceTasks($id: ID!, $contentTypeModel: ContentTypeModel!) {
  tasks(
    filter: {objectId: {equals: $id}, contentTypeModel: $contentTypeModel}
    take: 1000
  ) {
    ...TasksServiceTask
  }
}
    ${TasksServiceTaskFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TasksServiceTasksGQL extends Apollo.Query<TasksServiceTasksQuery, TasksServiceTasksQueryVariables> {
    override document = TasksServiceTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryThumbnailDocument = gql`
    query queryThumbnail($id: ID!, $resolution: DownloadResolution!) {
  dataObject(id: $id) {
    thumbnail(fileType: jpg, resolution: $resolution) {
      downloadUrl
    }
    thumbnailProcessingJob {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryThumbnailGQL extends Apollo.Query<QueryThumbnailQuery, QueryThumbnailQueryVariables> {
    override document = QueryThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ThumbnailAvailableDocument = gql`
    query thumbnailAvailable($id: ID!) {
  dataObject(id: $id) {
    thumbnailAvailable
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ThumbnailAvailableGQL extends Apollo.Query<ThumbnailAvailableQuery, ThumbnailAvailableQueryVariables> {
    override document = ThumbnailAvailableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingGetPriceGraphsDocument = gql`
    query priceMappingGetPriceGraphs($filter: PriceGraphFilterInput!, $take: Int, $skip: Int) {
  priceGraphs(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPriceGraph
  }
}
    ${PriceMappingPriceGraphFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingGetPriceGraphsGQL extends Apollo.Query<PriceMappingGetPriceGraphsQuery, PriceMappingGetPriceGraphsQueryVariables> {
    override document = PriceMappingGetPriceGraphsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingGetPriceDocument = gql`
    query priceMappingGetPrice($filter: PriceFilterInput!, $take: Int, $skip: Int) {
  prices(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPrice
  }
}
    ${PriceMappingPriceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingGetPriceGQL extends Apollo.Query<PriceMappingGetPriceQuery, PriceMappingGetPriceQueryVariables> {
    override document = PriceMappingGetPriceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingOrganizationIdFromTemplateIdDocument = gql`
    query priceMappingOrganizationIdFromTemplateId($templateId: ID!) {
  template(id: $templateId) {
    id
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingOrganizationIdFromTemplateIdGQL extends Apollo.Query<PriceMappingOrganizationIdFromTemplateIdQuery, PriceMappingOrganizationIdFromTemplateIdQueryVariables> {
    override document = PriceMappingOrganizationIdFromTemplateIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingCreatePriceGraphDocument = gql`
    mutation priceMappingCreatePriceGraph($input: MutationCreatePriceGraphInput!) {
  createPriceGraph(input: $input) {
    id
    state
    graph
    revisionNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingCreatePriceGraphGQL extends Apollo.Mutation<PriceMappingCreatePriceGraphMutation, PriceMappingCreatePriceGraphMutationVariables> {
    override document = PriceMappingCreatePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingUpdatePriceGraphDocument = gql`
    mutation priceMappingUpdatePriceGraph($input: MutationUpdatePriceGraphInput!) {
  updatePriceGraph(input: $input) {
    state
    graph
    revisionNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingUpdatePriceGraphGQL extends Apollo.Mutation<PriceMappingUpdatePriceGraphMutation, PriceMappingUpdatePriceGraphMutationVariables> {
    override document = PriceMappingUpdatePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingDeletePriceGraphDocument = gql`
    mutation priceMappingDeletePriceGraph($priceGraphId: ID!) {
  deletePriceGraph(priceGraphId: $priceGraphId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingDeletePriceGraphGQL extends Apollo.Mutation<PriceMappingDeletePriceGraphMutation, PriceMappingDeletePriceGraphMutationVariables> {
    override document = PriceMappingDeletePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPriceGraphsForCatalogIdsDocument = gql`
    query getPriceGraphsForCatalogIds($organizationUuid: ID!, $originalIds: [String!]!, $take: Int!) {
  idFromCatalogToPriceGraphItems(
    distinct: false
    filter: {originalIds: $originalIds, organizationUuid: $organizationUuid}
    take: $take
  ) {
    ...PriceMappingIdFromCatalogToPriceGraph
  }
}
    ${PriceMappingIdFromCatalogToPriceGraphFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPriceGraphsForCatalogIdsGQL extends Apollo.Query<GetPriceGraphsForCatalogIdsQuery, GetPriceGraphsForCatalogIdsQueryVariables> {
    override document = GetPriceGraphsForCatalogIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperGetPricesDocument = gql`
    query pricingHelperGetPrices($filter: PriceFilterInput!, $take: Int, $skip: Int) {
  prices(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPrice
  }
}
    ${PriceMappingPriceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperGetPricesGQL extends Apollo.Query<PricingHelperGetPricesQuery, PricingHelperGetPricesQueryVariables> {
    override document = PricingHelperGetPricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperGetPriceGraphsDocument = gql`
    query pricingHelperGetPriceGraphs($filter: PriceGraphFilterInput!, $take: Int, $skip: Int) {
  priceGraphs(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPriceGraph
  }
}
    ${PriceMappingPriceGraphFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperGetPriceGraphsGQL extends Apollo.Query<PricingHelperGetPriceGraphsQuery, PricingHelperGetPriceGraphsQueryVariables> {
    override document = PricingHelperGetPriceGraphsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperDeletePricesDocument = gql`
    mutation pricingHelperDeletePrices($originalIds: [String!]!, $organizationId: ID!) {
  deletePrices(originalIds: $originalIds, organizationId: $organizationId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperDeletePricesGQL extends Apollo.Mutation<PricingHelperDeletePricesMutation, PricingHelperDeletePricesMutationVariables> {
    override document = PricingHelperDeletePricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperDeleteIdFromCustomerToPriceGraphDocument = gql`
    mutation pricingHelperDeleteIdFromCustomerToPriceGraph($priceGraphId: ID!) {
  deleteIdFromCustomerToPriceGraph(priceGraphId: $priceGraphId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperDeleteIdFromCustomerToPriceGraphGQL extends Apollo.Mutation<PricingHelperDeleteIdFromCustomerToPriceGraphMutation, PricingHelperDeleteIdFromCustomerToPriceGraphMutationVariables> {
    override document = PricingHelperDeleteIdFromCustomerToPriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperCreateOrUpdatePricesDocument = gql`
    mutation pricingHelperCreateOrUpdatePrices($prices: [PriceInput!]!, $organizationId: ID!) {
  createOrUpdatePrices(prices: $prices, organizationId: $organizationId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperCreateOrUpdatePricesGQL extends Apollo.Mutation<PricingHelperCreateOrUpdatePricesMutation, PricingHelperCreateOrUpdatePricesMutationVariables> {
    override document = PricingHelperCreateOrUpdatePricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingServiceGetTemplateDocument = gql`
    query pricingServiceGetTemplate($templateId: ID!) {
  template(id: $templateId) {
    id
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingServiceGetTemplateGQL extends Apollo.Query<PricingServiceGetTemplateQuery, PricingServiceGetTemplateQueryVariables> {
    override document = PricingServiceGetTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectDetailsForDataObjectReferenceInspectorDocument = gql`
    query getDataObjectDetailsForDataObjectReferenceInspector($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    ...DataObjectDetailsForDataObjectInspector
  }
}
    ${DataObjectDetailsForDataObjectInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForDataObjectReferenceInspectorGQL extends Apollo.Query<GetDataObjectDetailsForDataObjectReferenceInspectorQuery, GetDataObjectDetailsForDataObjectReferenceInspectorQueryVariables> {
    override document = GetDataObjectDetailsForDataObjectReferenceInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHdriDetailsForHdriLightInspectorDocument = gql`
    query getHDRIDetailsForHDRILightInspector {
  hdris(take: 9999, orderBy: [{key: name, direction: asc}]) {
    ...HDRIDetailsForHDRILightInspector
  }
}
    ${HdriDetailsForHdriLightInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHdriDetailsForHdriLightInspectorGQL extends Apollo.Query<GetHdriDetailsForHdriLightInspectorQuery, GetHdriDetailsForHdriLightInspectorQueryVariables> {
    override document = GetHdriDetailsForHdriLightInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectForImageInspectorDocument = gql`
    query getDataObjectForImageInspector($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    ...DataObjectDetailsForImageInspector
  }
}
    ${DataObjectDetailsForImageInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectForImageInspectorGQL extends Apollo.Query<GetDataObjectForImageInspectorQuery, GetDataObjectForImageInspectorQueryVariables> {
    override document = GetDataObjectForImageInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialAssignmentDetailsForMaterialAssignmentInspectorDocument = gql`
    query getMaterialAssignmentDetailsForMaterialAssignmentInspector($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialAssignmentDetailsForMaterialAssignmentInspector
  }
}
    ${MaterialAssignmentDetailsForMaterialAssignmentInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialAssignmentDetailsForMaterialAssignmentInspectorGQL extends Apollo.Query<GetMaterialAssignmentDetailsForMaterialAssignmentInspectorQuery, GetMaterialAssignmentDetailsForMaterialAssignmentInspectorQueryVariables> {
    override document = GetMaterialAssignmentDetailsForMaterialAssignmentInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialRevisionDetailsForMaterialReferenceInspectorDocument = gql`
    query getMaterialRevisionDetailsForMaterialReferenceInspector($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialRevisionDetailsForMaterialReferenceInspector
  }
}
    ${MaterialRevisionDetailsForMaterialReferenceInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialRevisionDetailsForMaterialReferenceInspectorGQL extends Apollo.Query<GetMaterialRevisionDetailsForMaterialReferenceInspectorQuery, GetMaterialRevisionDetailsForMaterialReferenceInspectorQueryVariables> {
    override document = GetMaterialRevisionDetailsForMaterialReferenceInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsForTemplateInstanceInspectorDocument = gql`
    query getTemplateRevisionDetailsForTemplateInstanceInspector($legacyId: Int!) {
  templateRevision(legacyId: $legacyId) {
    ...TemplateRevisionDetailsForTemplateInstanceInspector
  }
}
    ${TemplateRevisionDetailsForTemplateInstanceInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateInstanceInspectorGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateInstanceInspectorQuery, GetTemplateRevisionDetailsForTemplateInstanceInspectorQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateInstanceInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateAddMaterialItemsDocument = gql`
    query templateAddMaterialItems($skip: Int, $take: Int, $filter: MaterialFilterInput) {
  items: materials(
    skip: $skip
    take: $take
    filter: $filter
    orderBy: [{direction: asc, key: legacyId}]
  ) {
    ...TemplateAddMaterialItem
  }
  totalCount: materialsCount(filter: $filter)
}
    ${TemplateAddMaterialItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateAddMaterialItemsGQL extends Apollo.Query<TemplateAddMaterialItemsQuery, TemplateAddMaterialItemsQueryVariables> {
    override document = TemplateAddMaterialItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateAddTemplateItemsDocument = gql`
    query templateAddTemplateItems($skip: Int, $take: Int, $filter: TemplateFilterInput) {
  items: templates(
    skip: $skip
    take: $take
    filter: $filter
    orderBy: [{direction: asc, key: createdAt}]
  ) {
    ...TemplateAddTemplateItem
  }
  totalCount: templatesCount(filter: $filter)
}
    ${TemplateAddTemplateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateAddTemplateItemsGQL extends Apollo.Query<TemplateAddTemplateItemsQuery, TemplateAddTemplateItemsQueryVariables> {
    override document = TemplateAddTemplateItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsTemplateAddSceneDocument = gql`
    query getTemplateRevisionDetailsTemplateAddScene($legacyId: Int!) {
  templateRevision(legacyId: $legacyId) {
    id
    legacyId
    graph
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsTemplateAddSceneGQL extends Apollo.Query<GetTemplateRevisionDetailsTemplateAddSceneQuery, GetTemplateRevisionDetailsTemplateAddSceneQueryVariables> {
    override document = GetTemplateRevisionDetailsTemplateAddSceneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateDetailsForTemplateEditorDocument = gql`
    query getTemplateDetailsForTemplateEditor($id: ID!) {
  template(id: $id) {
    ...TemplateDetailsForTemplateEditor
  }
}
    ${TemplateDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForTemplateEditorGQL extends Apollo.Query<GetTemplateDetailsForTemplateEditorQuery, GetTemplateDetailsForTemplateEditorQueryVariables> {
    override document = GetTemplateDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPictureDetailsForTemplateEditorDocument = gql`
    query getPictureDetailsForTemplateEditor($id: ID!) {
  picture(id: $id) {
    ...PictureDetailsForTemplateEditor
  }
}
    ${PictureDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPictureDetailsForTemplateEditorGQL extends Apollo.Query<GetPictureDetailsForTemplateEditorQuery, GetPictureDetailsForTemplateEditorQueryVariables> {
    override document = GetPictureDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsForTemplateEditorDocument = gql`
    query getTemplateRevisionDetailsForTemplateEditor($id: ID!) {
  templateRevision(id: $id) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateEditorGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateEditorQuery, GetTemplateRevisionDetailsForTemplateEditorQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLatestTemplateRevisionDetailsForTemplateEditorDocument = gql`
    query getLatestTemplateRevisionDetailsForTemplateEditor($id: ID!) {
  templateRevision(id: $id) {
    template {
      id
      latestRevision {
        ...TemplateRevisionDetailsForTemplateEditor
      }
    }
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestTemplateRevisionDetailsForTemplateEditorGQL extends Apollo.Query<GetLatestTemplateRevisionDetailsForTemplateEditorQuery, GetLatestTemplateRevisionDetailsForTemplateEditorQueryVariables> {
    override document = GetLatestTemplateRevisionDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionsDetailsForTemplateEditorDocument = gql`
    query getTemplateRevisionsDetailsForTemplateEditor($filter: TemplateRevisionFilterInput) {
  templateRevisions(filter: $filter) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionsDetailsForTemplateEditorGQL extends Apollo.Query<GetTemplateRevisionsDetailsForTemplateEditorQuery, GetTemplateRevisionsDetailsForTemplateEditorQueryVariables> {
    override document = GetTemplateRevisionsDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateRevisionForTemplateEditorDocument = gql`
    mutation createTemplateRevisionForTemplateEditor($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateRevisionForTemplateEditorGQL extends Apollo.Mutation<CreateTemplateRevisionForTemplateEditorMutation, CreateTemplateRevisionForTemplateEditorMutationVariables> {
    override document = CreateTemplateRevisionForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateForTemplateEditorDocument = gql`
    mutation createTemplateForTemplateEditor($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateDetailsForTemplateEditor
  }
}
    ${TemplateDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateForTemplateEditorGQL extends Apollo.Mutation<CreateTemplateForTemplateEditorMutation, CreateTemplateForTemplateEditorMutationVariables> {
    override document = CreateTemplateForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobAssignmentsDetailsForTemplateImageViewerDocument = gql`
    query getJobAssignmentsDetailsForTemplateImageViewer($filter: JobAssignmentFilterInput!) {
  jobAssignments(filter: $filter) {
    ...JobAssignmentDetailsForTemplateImageViewer
  }
}
    ${JobAssignmentDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobAssignmentsDetailsForTemplateImageViewerGQL extends Apollo.Query<GetJobAssignmentsDetailsForTemplateImageViewerQuery, GetJobAssignmentsDetailsForTemplateImageViewerQueryVariables> {
    override document = GetJobAssignmentsDetailsForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobDetailsForTemplateImageViewerDocument = gql`
    query getJobDetailsForTemplateImageViewer($id: ID, $legacyId: Int) {
  job(id: $id, legacyId: $legacyId) {
    ...JobDetailsForTemplateImageViewer
  }
}
    ${JobDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobDetailsForTemplateImageViewerGQL extends Apollo.Query<GetJobDetailsForTemplateImageViewerQuery, GetJobDetailsForTemplateImageViewerQueryVariables> {
    override document = GetJobDetailsForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectDetailsForTemplateImageViewerDocument = gql`
    query getDataObjectDetailsForTemplateImageViewer($id: ID, $legacyId: Int, $fileType: DownloadFileType = jpg, $resolution: DownloadResolution = High) {
  dataObject(id: $id, legacyId: $legacyId) {
    id
    downloadUrl
    mediaType
    imageColorSpace
    imageColorProfile
    width
    height
    size
    originalFileName
    thumbnail(fileType: $fileType, resolution: $resolution) {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForTemplateImageViewerGQL extends Apollo.Query<GetDataObjectDetailsForTemplateImageViewerQuery, GetDataObjectDetailsForTemplateImageViewerQueryVariables> {
    override document = GetDataObjectDetailsForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJobAssignmentForTemplateImageViewerDocument = gql`
    mutation createJobAssignmentForTemplateImageViewer($input: MutationCreateJobAssignmentInput!) {
  createJobAssignment(input: $input) {
    ...JobAssignmentDetailsForTemplateImageViewer
  }
}
    ${JobAssignmentDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobAssignmentForTemplateImageViewerGQL extends Apollo.Mutation<CreateJobAssignmentForTemplateImageViewerMutation, CreateJobAssignmentForTemplateImageViewerMutationVariables> {
    override document = CreateJobAssignmentForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteJobForTemplateImageViewerDocument = gql`
    mutation deleteJobForTemplateImageViewer($id: ID!) {
  deleteJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteJobForTemplateImageViewerGQL extends Apollo.Mutation<DeleteJobForTemplateImageViewerMutation, DeleteJobForTemplateImageViewerMutationVariables> {
    override document = DeleteJobForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJobForTemplateImageViewerDocument = gql`
    mutation createJobForTemplateImageViewer($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    ...JobDetailsForTemplateImageViewer
  }
}
    ${JobDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobForTemplateImageViewerGQL extends Apollo.Mutation<CreateJobForTemplateImageViewerMutation, CreateJobForTemplateImageViewerMutationVariables> {
    override document = CreateJobForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelJobForTemplateImageViewerDocument = gql`
    mutation cancelJobForTemplateImageViewer($id: ID!) {
  cancelJob(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelJobForTemplateImageViewerGQL extends Apollo.Mutation<CancelJobForTemplateImageViewerMutation, CancelJobForTemplateImageViewerMutationVariables> {
    override document = CancelJobForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateDetailsForTemplateTreeAddDocument = gql`
    query getTemplateDetailsForTemplateTreeAdd($id: ID!) {
  template(id: $id) {
    ...TemplateDetailsForTemplateTreeAdd
  }
}
    ${TemplateDetailsForTemplateTreeAddFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForTemplateTreeAddGQL extends Apollo.Query<GetTemplateDetailsForTemplateTreeAddQuery, GetTemplateDetailsForTemplateTreeAddQueryVariables> {
    override document = GetTemplateDetailsForTemplateTreeAddDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialDetailsForTemplateTreeAddDocument = gql`
    query getMaterialDetailsForTemplateTreeAdd($id: ID!) {
  material(id: $id) {
    ...MaterialDetailsForTemplateTreeAdd
  }
}
    ${MaterialDetailsForTemplateTreeAddFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialDetailsForTemplateTreeAddGQL extends Apollo.Query<GetMaterialDetailsForTemplateTreeAddQuery, GetMaterialDetailsForTemplateTreeAddQueryVariables> {
    override document = GetMaterialDetailsForTemplateTreeAddDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsForTemplateTreeItemDocument = gql`
    query getTemplateRevisionDetailsForTemplateTreeItem($legacyIds: [Int!]) {
  templateRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...TemplateRevisionDetailsForTemplateTreeItem
  }
}
    ${TemplateRevisionDetailsForTemplateTreeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateTreeItemGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateTreeItemQuery, GetTemplateRevisionDetailsForTemplateTreeItemQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateTreeItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialRevisionDetailsForTemplateTreeItemDocument = gql`
    query getMaterialRevisionDetailsForTemplateTreeItem($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialRevisionDetailsForTemplateTreeItem
  }
}
    ${MaterialRevisionDetailsForTemplateTreeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialRevisionDetailsForTemplateTreeItemGQL extends Apollo.Query<GetMaterialRevisionDetailsForTemplateTreeItemQuery, GetMaterialRevisionDetailsForTemplateTreeItemQueryVariables> {
    override document = GetMaterialRevisionDetailsForTemplateTreeItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectDetailsForDataObjectBatchApiCallDocument = gql`
    query getDataObjectDetailsForDataObjectBatchApiCall($legacyIds: [Int!]) {
  dataObjects(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...DataObjectDetailsForDataObjectBatchApiCall
  }
}
    ${DataObjectDetailsForDataObjectBatchApiCallFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForDataObjectBatchApiCallGQL extends Apollo.Query<GetDataObjectDetailsForDataObjectBatchApiCallQuery, GetDataObjectDetailsForDataObjectBatchApiCallQueryVariables> {
    override document = GetDataObjectDetailsForDataObjectBatchApiCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallDocument = gql`
    query getTemplateRevisionDetailsForTemplateRevisionBatchApiCall($legacyIds: [Int!]) {
  templateRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...TemplateRevisionDetailsForTemplateRevisionBatchApiCall
  }
}
    ${TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQuery, GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialsDetailsForSceneManagerServiceDocument = gql`
    query getMaterialsDetailsForSceneManagerService($skip: Int, $take: Int, $filter: MaterialFilterInput, $orderBy: [MaterialOrderByInput!]) {
  materials(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    __typename
    id
    legacyId
    articleId
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialsDetailsForSceneManagerServiceGQL extends Apollo.Query<GetMaterialsDetailsForSceneManagerServiceQuery, GetMaterialsDetailsForSceneManagerServiceQueryVariables> {
    override document = GetMaterialsDetailsForSceneManagerServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHdriDetailsForSceneManagerServiceDocument = gql`
    query getHDRIDetailsForSceneManagerService($id: ID, $legacyId: Int) {
  hdri(id: $id, legacyId: $legacyId) {
    dataObject {
      mediaType
      bucketName
      objectName
      originalFileName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHdriDetailsForSceneManagerServiceGQL extends Apollo.Query<GetHdriDetailsForSceneManagerServiceQuery, GetHdriDetailsForSceneManagerServiceQueryVariables> {
    override document = GetHdriDetailsForSceneManagerServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDataObjectAssignmentForSceneManagerServiceDocument = gql`
    mutation createDataObjectAssignmentForSceneManagerService($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    ...DataObjectAssignmentDetailsForTemplateImageViewer
  }
}
    ${DataObjectAssignmentDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDataObjectAssignmentForSceneManagerServiceGQL extends Apollo.Mutation<CreateDataObjectAssignmentForSceneManagerServiceMutation, CreateDataObjectAssignmentForSceneManagerServiceMutationVariables> {
    override document = CreateDataObjectAssignmentForSceneManagerServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateRevisionNewDocument = gql`
    mutation updateTemplateRevisionNew($input: MutationUpdateTemplateRevisionNewInput!) {
  updateTemplateRevisionNew(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateRevisionNewGQL extends Apollo.Mutation<UpdateTemplateRevisionNewMutation, UpdateTemplateRevisionNewMutationVariables> {
    override document = UpdateTemplateRevisionNewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelectTemplateItemsDocument = gql`
    query selectTemplateItems($skip: Int, $take: Int, $filter: TemplateFilterInput) {
  items: templates(skip: $skip, take: $take, filter: $filter) {
    ...SelectTemplateItem
  }
  totalCount: templatesCount(filter: $filter)
}
    ${SelectTemplateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectTemplateItemsGQL extends Apollo.Query<SelectTemplateItemsQuery, SelectTemplateItemsQueryVariables> {
    override document = SelectTemplateItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsDocument = gql`
    query templateDetails($id: ID!) {
  item: template(id: $id) {
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsGQL extends Apollo.Query<TemplateDetailsQuery, TemplateDetailsQueryVariables> {
    override document = TemplateDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateDetailsDocument = gql`
    mutation updateTemplateDetails($input: MutationUpdateTemplateInput!) {
  item: updateTemplate(input: $input) {
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateDetailsGQL extends Apollo.Mutation<UpdateTemplateDetailsMutation, UpdateTemplateDetailsMutationVariables> {
    override document = UpdateTemplateDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTemplateDetailsDocument = gql`
    mutation deleteTemplateDetails($id: ID!) {
  deleteTemplate(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTemplateDetailsGQL extends Apollo.Mutation<DeleteTemplateDetailsMutation, DeleteTemplateDetailsMutationVariables> {
    override document = DeleteTemplateDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsCreateTemplateDocument = gql`
    mutation templateDetailsCreateTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsCreateTemplateGQL extends Apollo.Mutation<TemplateDetailsCreateTemplateMutation, TemplateDetailsCreateTemplateMutationVariables> {
    override document = TemplateDetailsCreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsCreateTemplateRevisionDocument = gql`
    mutation templateDetailsCreateTemplateRevision($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsCreateTemplateRevisionGQL extends Apollo.Mutation<TemplateDetailsCreateTemplateRevisionMutation, TemplateDetailsCreateTemplateRevisionMutationVariables> {
    override document = TemplateDetailsCreateTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsDeleteTemplateRevisionDocument = gql`
    mutation templateDetailsDeleteTemplateRevision($id: ID!) {
  deleteTemplateRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsDeleteTemplateRevisionGQL extends Apollo.Mutation<TemplateDetailsDeleteTemplateRevisionMutation, TemplateDetailsDeleteTemplateRevisionMutationVariables> {
    override document = TemplateDetailsDeleteTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherMaterialDocument = gql`
    query templatePublisherMaterial($id: ID!) {
  material(id: $id) {
    id
    legacyId
    name
    latestCyclesRevision {
      id
      legacyId
      number
    }
    ...MaterialInspectorMaterial
  }
}
    ${MaterialInspectorMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherMaterialGQL extends Apollo.Query<TemplatePublisherMaterialQuery, TemplatePublisherMaterialQueryVariables> {
    override document = TemplatePublisherMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherGetAllHdrisDocument = gql`
    query templatePublisherGetAllHdris {
  hdris(take: 9999) {
    id
    legacyId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherGetAllHdrisGQL extends Apollo.Query<TemplatePublisherGetAllHdrisQuery, TemplatePublisherGetAllHdrisQueryVariables> {
    override document = TemplatePublisherGetAllHdrisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherCreateJobAssignmentDocument = gql`
    mutation templatePublisherCreateJobAssignment($input: MutationCreateJobAssignmentInput!) {
  createJobAssignment(input: $input) {
    id
    job {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherCreateJobAssignmentGQL extends Apollo.Mutation<TemplatePublisherCreateJobAssignmentMutation, TemplatePublisherCreateJobAssignmentMutationVariables> {
    override document = TemplatePublisherCreateJobAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherCreateJobDocument = gql`
    mutation templatePublisherCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherCreateJobGQL extends Apollo.Mutation<TemplatePublisherCreateJobMutation, TemplatePublisherCreateJobMutationVariables> {
    override document = TemplatePublisherCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherJobAssignmentsDocument = gql`
    query templatePublisherJobAssignments($filter: JobAssignmentFilterInput!) {
  jobAssignments(filter: $filter) {
    id
    assignmentKey
    job {
      id
      name
      output
      progress
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherJobAssignmentsGQL extends Apollo.Query<TemplatePublisherJobAssignmentsQuery, TemplatePublisherJobAssignmentsQueryVariables> {
    override document = TemplatePublisherJobAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherDeleteJobDocument = gql`
    mutation templatePublisherDeleteJob($id: ID!) {
  deleteJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherDeleteJobGQL extends Apollo.Mutation<TemplatePublisherDeleteJobMutation, TemplatePublisherDeleteJobMutationVariables> {
    override document = TemplatePublisherDeleteJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherJobWithOutputDocument = gql`
    query templatePublisherJobWithOutput($id: ID!) {
  job(id: $id) {
    id
    output
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherJobWithOutputGQL extends Apollo.Query<TemplatePublisherJobWithOutputQuery, TemplatePublisherJobWithOutputQueryVariables> {
    override document = TemplatePublisherJobWithOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherUuidFromLegacyIdDocument = gql`
    query templatePublisherUuidFromLegacyId($legacyId: Int!) {
  template(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherUuidFromLegacyIdGQL extends Apollo.Query<TemplatePublisherUuidFromLegacyIdQuery, TemplatePublisherUuidFromLegacyIdQueryVariables> {
    override document = TemplatePublisherUuidFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherJobStatesDocument = gql`
    query templatePublisherJobStates($filter: JobFilterInput!) {
  jobs(filter: $filter) {
    id
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherJobStatesGQL extends Apollo.Query<TemplatePublisherJobStatesQuery, TemplatePublisherJobStatesQueryVariables> {
    override document = TemplatePublisherJobStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherTemplateRevisionDocument = gql`
    query templatePublisherTemplateRevision($legacyId: Int!) {
  templateRevision(legacyId: $legacyId) {
    id
    graph
    number
    template {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherTemplateRevisionGQL extends Apollo.Query<TemplatePublisherTemplateRevisionQuery, TemplatePublisherTemplateRevisionQueryVariables> {
    override document = TemplatePublisherTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatePublisherLatestTemplateRevisionDocument = gql`
    query templatePublisherLatestTemplateRevision($templateRevisionlegacyId: Int!) {
  templateRevision(legacyId: $templateRevisionlegacyId) {
    id
    template {
      id
      latestRevision {
        id
        legacyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatePublisherLatestTemplateRevisionGQL extends Apollo.Query<TemplatePublisherLatestTemplateRevisionQuery, TemplatePublisherLatestTemplateRevisionQueryVariables> {
    override document = TemplatePublisherLatestTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateTreeTemplateDocument = gql`
    query templateTreeTemplate($id: ID!) {
  template(id: $id) {
    name
    latestRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateTreeTemplateGQL extends Apollo.Query<TemplateTreeTemplateQuery, TemplateTreeTemplateQueryVariables> {
    override document = TemplateTreeTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateTreeMaterialDocument = gql`
    query templateTreeMaterial($id: ID!) {
  material(id: $id) {
    name
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateTreeMaterialGQL extends Apollo.Query<TemplateTreeMaterialQuery, TemplateTreeMaterialQueryVariables> {
    override document = TemplateTreeMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateTreeTemplateRevisionDocument = gql`
    query templateTreeTemplateRevision($legacyId: Int!) {
  templateRevision(legacyId: $legacyId) {
    id
    legacyId
    template {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateTreeTemplateRevisionGQL extends Apollo.Query<TemplateTreeTemplateRevisionQuery, TemplateTreeTemplateRevisionQueryVariables> {
    override document = TemplateTreeTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectBatchApiCallDocument = gql`
    query dataObjectBatchApiCall($legacyIds: [Int!]) {
  dataObjects(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...DataObjectBatchApiCallDataObject
  }
}
    ${DataObjectBatchApiCallDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectBatchApiCallGQL extends Apollo.Query<DataObjectBatchApiCallQuery, DataObjectBatchApiCallQueryVariables> {
    override document = DataObjectBatchApiCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeshDataBatchApiCallDocument = gql`
    query meshDataBatchApiCall($legacyIds: [Int!]) {
  dataObjects(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MeshDataBatchApiCallDataObject
  }
}
    ${MeshDataBatchApiCallDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MeshDataBatchApiCallGQL extends Apollo.Query<MeshDataBatchApiCallQuery, MeshDataBatchApiCallQueryVariables> {
    override document = MeshDataBatchApiCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneManagerTemplateRevisionDocument = gql`
    query sceneManagerTemplateRevision($templateRevisionLegacyId: Int!) {
  templateRevision(legacyId: $templateRevisionLegacyId) {
    ...SceneManagerTemplateRevision
  }
}
    ${SceneManagerTemplateRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneManagerTemplateRevisionGQL extends Apollo.Query<SceneManagerTemplateRevisionQuery, SceneManagerTemplateRevisionQueryVariables> {
    override document = SceneManagerTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneManagerTemplateRevisionsDocument = gql`
    query sceneManagerTemplateRevisions($legacyIds: [Int!]) {
  templateRevisions(filter: {legacyId: {in: $legacyIds}}) {
    ...SceneManagerTemplateRevision
  }
}
    ${SceneManagerTemplateRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneManagerTemplateRevisionsGQL extends Apollo.Query<SceneManagerTemplateRevisionsQuery, SceneManagerTemplateRevisionsQueryVariables> {
    override document = SceneManagerTemplateRevisionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SceneManagerDataObjectDocument = gql`
    query sceneManagerDataObject($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    id
    legacyId
    height
    width
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SceneManagerDataObjectGQL extends Apollo.Query<SceneManagerDataObjectQuery, SceneManagerDataObjectQueryVariables> {
    override document = SceneManagerDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectDetailsForSceneManagerDocument = gql`
    query getDataObjectDetailsForSceneManager($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    id
    legacyId
    width
    height
    downloadUrl
    mediaType
    originalFileName
    related {
      id
      legacyId
      width
      height
      downloadUrl
      mediaType
      originalFileName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForSceneManagerGQL extends Apollo.Query<GetDataObjectDetailsForSceneManagerQuery, GetDataObjectDetailsForSceneManagerQueryVariables> {
    override document = GetDataObjectDetailsForSceneManagerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationUuidFromTemplateUuidDocument = gql`
    query getOrganizationUuidFromTemplateUuid($id: ID!) {
  template(id: $id) {
    id
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationUuidFromTemplateUuidGQL extends Apollo.Query<GetOrganizationUuidFromTemplateUuidQuery, GetOrganizationUuidFromTemplateUuidQueryVariables> {
    override document = GetOrganizationUuidFromTemplateUuidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateUuidFromLegacyIdDocument = gql`
    query getTemplateUuidFromLegacyId($legacyId: Int!) {
  template(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateUuidFromLegacyIdGQL extends Apollo.Query<GetTemplateUuidFromLegacyIdQuery, GetTemplateUuidFromLegacyIdQueryVariables> {
    override document = GetTemplateUuidFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateLegacyIdFromUuidDocument = gql`
    query getTemplateLegacyIdFromUuid($id: ID!) {
  template(id: $id) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateLegacyIdFromUuidGQL extends Apollo.Query<GetTemplateLegacyIdFromUuidQuery, GetTemplateLegacyIdFromUuidQueryVariables> {
    override document = GetTemplateLegacyIdFromUuidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateUuidFromSceneLegacyIdDocument = gql`
    query getTemplateUuidFromSceneLegacyId($legacyId: Int!) {
  scene(legacyId: $legacyId) {
    picture {
      template {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateUuidFromSceneLegacyIdGQL extends Apollo.Query<GetTemplateUuidFromSceneLegacyIdQuery, GetTemplateUuidFromSceneLegacyIdQueryVariables> {
    override document = GetTemplateUuidFromSceneLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateMaterialRevisionIdFromLegacyIdDocument = gql`
    query getTemplateMaterialRevisionIdFromLegacyId($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateMaterialRevisionIdFromLegacyIdGQL extends Apollo.Query<GetTemplateMaterialRevisionIdFromLegacyIdQuery, GetTemplateMaterialRevisionIdFromLegacyIdQueryVariables> {
    override document = GetTemplateMaterialRevisionIdFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateWithLatestRevisionDocument = gql`
    query getTemplateWithLatestRevision($id: ID!) {
  template(id: $id) {
    ...TemplateWithLatestRevision
  }
}
    ${TemplateWithLatestRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateWithLatestRevisionGQL extends Apollo.Query<GetTemplateWithLatestRevisionQuery, GetTemplateWithLatestRevisionQueryVariables> {
    override document = GetTemplateWithLatestRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsDocument = gql`
    query getTemplateRevisionDetails($id: ID, $legacyId: Int) {
  templateRevision(id: $id, legacyId: $legacyId) {
    template {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsGQL extends Apollo.Query<GetTemplateRevisionDetailsQuery, GetTemplateRevisionDetailsQueryVariables> {
    override document = GetTemplateRevisionDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplatesDocument = gql`
    query getTemplates($skip: Int, $take: Int, $filter: TemplateFilterInput, $orderBy: [TemplateOrderByInput!]) {
  templates(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...TemplateListItem
  }
  templatesCount(filter: $filter)
}
    ${TemplateListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplatesGQL extends Apollo.Query<GetTemplatesQuery, GetTemplatesQueryVariables> {
    override document = GetTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateArStatisticsDocument = gql`
    mutation batchUpdateArStatistics($filter: ArStatisticsFilterInput, $templateRevisionId: ID) {
  batchUpdateArStatistics(
    filter: $filter
    templateRevisionId: $templateRevisionId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateArStatisticsGQL extends Apollo.Mutation<BatchUpdateArStatisticsMutation, BatchUpdateArStatisticsMutationVariables> {
    override document = BatchUpdateArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateDataObjectAssignmentsDocument = gql`
    mutation batchUpdateDataObjectAssignments($filter: DataObjectAssignmentFilterInput, $contentTypeModel: ContentTypeModel!, $objectId: ID!) {
  batchUpdateDataObjectAssignments(
    filter: $filter
    contentTypeModel: $contentTypeModel
    objectId: $objectId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateDataObjectAssignmentsGQL extends Apollo.Mutation<BatchUpdateDataObjectAssignmentsMutation, BatchUpdateDataObjectAssignmentsMutationVariables> {
    override document = BatchUpdateDataObjectAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateRenderJobsDocument = gql`
    mutation batchUpdateRenderJobs($filter: RenderJobFilterInput, $templateRevisionId: ID) {
  batchUpdateRenderJobs(filter: $filter, templateRevisionId: $templateRevisionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateRenderJobsGQL extends Apollo.Mutation<BatchUpdateRenderJobsMutation, BatchUpdateRenderJobsMutationVariables> {
    override document = BatchUpdateRenderJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateSceneStatisticsDocument = gql`
    mutation batchUpdateSceneStatistics($filter: SceneStatisticsFilterInput, $templateId: ID) {
  batchUpdateSceneStatistics(filter: $filter, templateId: $templateId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateSceneStatisticsGQL extends Apollo.Mutation<BatchUpdateSceneStatisticsMutation, BatchUpdateSceneStatisticsMutationVariables> {
    override document = BatchUpdateSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateDocument = gql`
    mutation createTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateListItem
  }
}
    ${TemplateListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateGQL extends Apollo.Mutation<CreateTemplateMutation, CreateTemplateMutationVariables> {
    override document = CreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateRevisionDocument = gql`
    mutation createTemplateRevision($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateRevisionGQL extends Apollo.Mutation<CreateTemplateRevisionMutation, CreateTemplateRevisionMutationVariables> {
    override document = CreateTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateNameDocument = gql`
    mutation UpdateTemplateName($id: ID!, $name: String) {
  updateTemplate(input: {id: $id, name: $name}) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateNameGQL extends Apollo.Mutation<UpdateTemplateNameMutation, UpdateTemplateNameMutationVariables> {
    override document = UpdateTemplateNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($input: MutationUpdateTemplateInput!) {
  updateTemplate(input: $input) {
    ...TemplateListItem
  }
}
    ${TemplateListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateGQL extends Apollo.Mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables> {
    override document = UpdateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplatesForConfiguratorTestDocument = gql`
    query getTemplatesForConfiguratorTest($skip: Int, $take: Int) {
  templates(skip: $skip, take: $take) {
    id
    latestRevision {
      graph
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplatesForConfiguratorTestGQL extends Apollo.Query<GetTemplatesForConfiguratorTestQuery, GetTemplatesForConfiguratorTestQueryVariables> {
    override document = GetTemplatesForConfiguratorTestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionIdForConfiguratorIframeTestDocument = gql`
    query getTemplateRevisionIdForConfiguratorIframeTest($templateId: ID!) {
  template(id: $templateId) {
    latestRevision {
      id
      graph
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionIdForConfiguratorIframeTestGQL extends Apollo.Query<GetTemplateRevisionIdForConfiguratorIframeTestQuery, GetTemplateRevisionIdForConfiguratorIframeTestQueryVariables> {
    override document = GetTemplateRevisionIdForConfiguratorIframeTestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureSetRevisionDocument = gql`
    mutation deleteTextureSetRevision($id: ID!) {
  deleteTextureSetRevision(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureSetRevisionGQL extends Apollo.Mutation<DeleteTextureSetRevisionMutation, DeleteTextureSetRevisionMutationVariables> {
    override document = DeleteTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetRevisionTextureRevisionIdsDocument = gql`
    query queryTextureSetRevisionTextureRevisionIds($id: ID!) {
  textureSetRevision(id: $id) {
    textureSet {
      id
      textureSetRevisions {
        id
      }
      textures {
        id
        revisions {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetRevisionTextureRevisionIdsGQL extends Apollo.Query<QueryTextureSetRevisionTextureRevisionIdsQuery, QueryTextureSetRevisionTextureRevisionIdsQueryVariables> {
    override document = QueryTextureSetRevisionTextureRevisionIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryDataObjectAssignmentForDeletionDocument = gql`
    query queryDataObjectAssignmentForDeletion($id: ID!) {
  dataObjectAssignment(id: $id) {
    id
    dataObject {
      id
      assignmentsCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectAssignmentForDeletionGQL extends Apollo.Query<QueryDataObjectAssignmentForDeletionQuery, QueryDataObjectAssignmentForDeletionQueryVariables> {
    override document = QueryDataObjectAssignmentForDeletionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectAssignmentDocument = gql`
    mutation deleteDataObjectAssignment($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectAssignmentGQL extends Apollo.Mutation<DeleteDataObjectAssignmentMutation, DeleteDataObjectAssignmentMutationVariables> {
    override document = DeleteDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectDocument = gql`
    mutation deleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectGQL extends Apollo.Mutation<DeleteDataObjectMutation, DeleteDataObjectMutationVariables> {
    override document = DeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureRevisionDocument = gql`
    mutation deleteTextureRevision($id: ID!) {
  deleteTextureRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureRevisionGQL extends Apollo.Mutation<DeleteTextureRevisionMutation, DeleteTextureRevisionMutationVariables> {
    override document = DeleteTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureDocument = gql`
    mutation deleteTexture($id: ID!) {
  deleteTexture(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureGQL extends Apollo.Mutation<DeleteTextureMutation, DeleteTextureMutationVariables> {
    override document = DeleteTextureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryDataObjectReferencesAndRelatedDocument = gql`
    query queryDataObjectReferencesAndRelated($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    assignmentsCount
    hdrisCount
    materialRevisionsCount
    textureSetRevisionMapAssignmentsCount
    textureSetRevisionSourceDataObjectReferencesCount
    textureRevisionsCount
    related {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectReferencesAndRelatedGQL extends Apollo.Query<QueryDataObjectReferencesAndRelatedQuery, QueryDataObjectReferencesAndRelatedQueryVariables> {
    override document = QueryDataObjectReferencesAndRelatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveTextureRevisionLegacyIdDocument = gql`
    query resolveTextureRevisionLegacyId($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveTextureRevisionLegacyIdGQL extends Apollo.Query<ResolveTextureRevisionLegacyIdQuery, ResolveTextureRevisionLegacyIdQueryVariables> {
    override document = ResolveTextureRevisionLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetRevisionDataDocument = gql`
    query queryTextureSetRevisionData($id: ID!) {
  textureSetRevision(id: $id) {
    ...TextureSetRevisionData
  }
}
    ${TextureSetRevisionDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetRevisionDataGQL extends Apollo.Query<QueryTextureSetRevisionDataQuery, QueryTextureSetRevisionDataQueryVariables> {
    override document = QueryTextureSetRevisionDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureEditorDataObjectDocument = gql`
    query queryTextureEditorDataObject($dataObjectId: ID!) {
  dataObject(id: $dataObjectId) {
    ...TextureEditorDataObject
  }
}
    ${TextureEditorDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureEditorDataObjectGQL extends Apollo.Query<QueryTextureEditorDataObjectQuery, QueryTextureEditorDataObjectQueryVariables> {
    override document = QueryTextureEditorDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TexturesApiDeleteDataObjectDocument = gql`
    mutation texturesApiDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TexturesApiDeleteDataObjectGQL extends Apollo.Mutation<TexturesApiDeleteDataObjectMutation, TexturesApiDeleteDataObjectMutationVariables> {
    override document = TexturesApiDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureEditDataObjectLegacyIdDocument = gql`
    query queryTextureEditDataObjectLegacyId($id: ID!) {
  dataObject(id: $id) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureEditDataObjectLegacyIdGQL extends Apollo.Query<QueryTextureEditDataObjectLegacyIdQuery, QueryTextureEditDataObjectLegacyIdQueryVariables> {
    override document = QueryTextureEditDataObjectLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureLoaderDataObjectImageDescriptorDocument = gql`
    query queryTextureLoaderDataObjectImageDescriptor($id: ID!) {
  dataObject(id: $id) {
    id
    legacyId
    width
    height
    imageDataType
    imageColorSpace
    downloadUrlExr: downloadUrl
    jpgDataObject: thumbnail(fileType: jpg, resolution: High) {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureLoaderDataObjectImageDescriptorGQL extends Apollo.Query<QueryTextureLoaderDataObjectImageDescriptorQuery, QueryTextureLoaderDataObjectImageDescriptorQueryVariables> {
    override document = QueryTextureLoaderDataObjectImageDescriptorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AutoTilingGetTextureRevisionDataObjectIdDocument = gql`
    query autoTilingGetTextureRevisionDataObjectId($textureRevisionId: ID!) {
  textureRevision(id: $textureRevisionId) {
    dataObject {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoTilingGetTextureRevisionDataObjectIdGQL extends Apollo.Query<AutoTilingGetTextureRevisionDataObjectIdQuery, AutoTilingGetTextureRevisionDataObjectIdQueryVariables> {
    override document = AutoTilingGetTextureRevisionDataObjectIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AutoTilingCreateDataObjectDocument = gql`
    mutation autoTilingCreateDataObject($input: MutationCreateDataObjectInput!) {
  createDataObject(input: $input) {
    id
    legacyId
    signedUploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoTilingCreateDataObjectGQL extends Apollo.Mutation<AutoTilingCreateDataObjectMutation, AutoTilingCreateDataObjectMutationVariables> {
    override document = AutoTilingCreateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorCreateJobDocument = gql`
    mutation textureEditorCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorCreateJobGQL extends Apollo.Mutation<TextureEditorCreateJobMutation, TextureEditorCreateJobMutationVariables> {
    override document = TextureEditorCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorCreateJobAssignmentDocument = gql`
    mutation textureEditorCreateJobAssignment($input: MutationCreateJobAssignmentInput!) {
  createJobAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorCreateJobAssignmentGQL extends Apollo.Mutation<TextureEditorCreateJobAssignmentMutation, TextureEditorCreateJobAssignmentMutationVariables> {
    override document = TextureEditorCreateJobAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorUpdateTextureSetRevisionDocument = gql`
    mutation textureEditorUpdateTextureSetRevision($input: MutationUpdateTextureSetRevisionInput!) {
  updateTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorUpdateTextureSetRevisionGQL extends Apollo.Mutation<TextureEditorUpdateTextureSetRevisionMutation, TextureEditorUpdateTextureSetRevisionMutationVariables> {
    override document = TextureEditorUpdateTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorCancelProcessingJobDocument = gql`
    mutation textureEditorCancelProcessingJob($jobId: ID!) {
  cancelJob(id: $jobId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorCancelProcessingJobGQL extends Apollo.Mutation<TextureEditorCancelProcessingJobMutation, TextureEditorCancelProcessingJobMutationVariables> {
    override document = TextureEditorCancelProcessingJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureEditProcessingJobStateDocument = gql`
    query queryTextureEditProcessingJobState($textureSetRevisionId: ID!) {
  textureSetRevision(id: $textureSetRevisionId) {
    editsProcessingJob {
      id
      state
      progress
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureEditProcessingJobStateGQL extends Apollo.Query<QueryTextureEditProcessingJobStateQuery, QueryTextureEditProcessingJobStateQueryVariables> {
    override document = QueryTextureEditProcessingJobStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetReferencedMaterialInfoDocument = gql`
    query queryTextureSetReferencedMaterialInfo($materialRevisionId: ID!) {
  materialRevision(id: $materialRevisionId) {
    id
    legacyId
    number
    material {
      id
      legacyId
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetReferencedMaterialInfoGQL extends Apollo.Query<QueryTextureSetReferencedMaterialInfoQuery, QueryTextureSetReferencedMaterialInfoQueryVariables> {
    override document = QueryTextureSetReferencedMaterialInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateNewTextureSetRevisionDocument = gql`
    mutation createNewTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewTextureSetRevisionGQL extends Apollo.Mutation<CreateNewTextureSetRevisionMutation, CreateNewTextureSetRevisionMutationVariables> {
    override document = CreateNewTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TilingInfoPanelTextureRevisionDocument = gql`
    query tilingInfoPanelTextureRevision($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    ...TilingInfoPanelTextureRevision
  }
}
    ${TilingInfoPanelTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TilingInfoPanelTextureRevisionGQL extends Apollo.Query<TilingInfoPanelTextureRevisionQuery, TilingInfoPanelTextureRevisionQueryVariables> {
    override document = TilingInfoPanelTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogSubJobDocument = gql`
    query scanJobDialogSubJob($scanSubJobId: ID!) {
  scanSubJob(id: $scanSubJobId) {
    ...ScanJobDialogSubJob
  }
}
    ${ScanJobDialogSubJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogSubJobGQL extends Apollo.Query<ScanJobDialogSubJobQuery, ScanJobDialogSubJobQueryVariables> {
    override document = ScanJobDialogSubJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogSubJobsDocument = gql`
    query scanJobDialogSubJobs($scanJobId: ID!, $skip: Int!, $take: Int!) {
  items: scanSubJobs(
    take: $take
    skip: $skip
    filter: {scanJobId: {equals: $scanJobId}}
  ) {
    ...ScanJobDialogSubJob
  }
}
    ${ScanJobDialogSubJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogSubJobsGQL extends Apollo.Query<ScanJobDialogSubJobsQuery, ScanJobDialogSubJobsQueryVariables> {
    override document = ScanJobDialogSubJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogUpdateSubJobDocument = gql`
    mutation scanJobDialogUpdateSubJob($input: MutationUpdateScanSubJobInput!) {
  updateScanSubJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogUpdateSubJobGQL extends Apollo.Mutation<ScanJobDialogUpdateSubJobMutation, ScanJobDialogUpdateSubJobMutationVariables> {
    override document = ScanJobDialogUpdateSubJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogDocument = gql`
    query scanJobDialog($id: ID!) {
  item: scanJob(id: $id) {
    subJobs {
      id
    }
    ...ScanJobDialog
  }
}
    ${ScanJobDialogFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogGQL extends Apollo.Query<ScanJobDialogQuery, ScanJobDialogQueryVariables> {
    override document = ScanJobDialogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobsForTextureSetDocument = gql`
    query scanJobsForTextureSet($id: ID!) {
  textureSet(id: $id) {
    id
    name
    scanJob {
      ...ScanJob
    }
  }
}
    ${ScanJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobsForTextureSetGQL extends Apollo.Query<ScanJobsForTextureSetQuery, ScanJobsForTextureSetQueryVariables> {
    override document = ScanJobsForTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobUpdateDocument = gql`
    mutation scanJobUpdate($input: MutationUpdateScanJobInput!) {
  updateScanJob(input: $input) {
    ...ScanJob
  }
}
    ${ScanJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobUpdateGQL extends Apollo.Mutation<ScanJobUpdateMutation, ScanJobUpdateMutationVariables> {
    override document = ScanJobUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureGroupDetailsDocument = gql`
    query textureGroupDetails($id: ID!) {
  item: textureGroup(id: $id) {
    ...TextureGroupDetails
  }
}
    ${TextureGroupDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureGroupDetailsGQL extends Apollo.Query<TextureGroupDetailsQuery, TextureGroupDetailsQueryVariables> {
    override document = TextureGroupDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTextureGroupDocument = gql`
    mutation createTextureGroup($input: MutationCreateTextureGroupInput!) {
  createTextureGroup(input: $input) {
    ...TextureGroupDetails
  }
}
    ${TextureGroupDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTextureGroupGQL extends Apollo.Mutation<CreateTextureGroupMutation, CreateTextureGroupMutationVariables> {
    override document = CreateTextureGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureGroupDetailsDeleteDocument = gql`
    mutation textureGroupDetailsDelete($id: ID!) {
  deleteTextureGroup(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureGroupDetailsDeleteGQL extends Apollo.Mutation<TextureGroupDetailsDeleteMutation, TextureGroupDetailsDeleteMutationVariables> {
    override document = TextureGroupDetailsDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTextureGroupDetailsDocument = gql`
    mutation updateTextureGroupDetails($input: MutationUpdateTextureGroupInput!) {
  item: updateTextureGroup(input: $input) {
    ...TextureGroupDetails
  }
}
    ${TextureGroupDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTextureGroupDetailsGQL extends Apollo.Mutation<UpdateTextureGroupDetailsMutation, UpdateTextureGroupDetailsMutationVariables> {
    override document = UpdateTextureGroupDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureGroupDetailsCreateTextureSetDocument = gql`
    mutation textureGroupDetailsCreateTextureSet($input: MutationCreateTextureSetInput!) {
  createTextureSet(input: $input) {
    id
    legacyId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureGroupDetailsCreateTextureSetGQL extends Apollo.Mutation<TextureGroupDetailsCreateTextureSetMutation, TextureGroupDetailsCreateTextureSetMutationVariables> {
    override document = TextureGroupDetailsCreateTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetViewDataDocument = gql`
    query queryTextureSetViewData($id: ID!) {
  textureSet(id: $id) {
    ...TextureSetRevisionViewTextureSet
  }
}
    ${TextureSetRevisionViewTextureSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetViewDataGQL extends Apollo.Query<QueryTextureSetViewDataQuery, QueryTextureSetViewDataQueryVariables> {
    override document = QueryTextureSetViewDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetRevisionViewDataDocument = gql`
    query queryTextureSetRevisionViewData($id: ID!) {
  textureSetRevision(id: $id) {
    textureSet {
      id
    }
    name
    width
    height
    displacement
    mapAssignments {
      textureType
      dataObject {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetRevisionViewDataGQL extends Apollo.Query<QueryTextureSetRevisionViewDataQuery, QueryTextureSetRevisionViewDataQueryVariables> {
    override document = QueryTextureSetRevisionViewDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureSetRevisionViewCreateTextureSetRevisionDocument = gql`
    mutation textureSetRevisionViewCreateTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureSetRevisionViewCreateTextureSetRevisionGQL extends Apollo.Mutation<TextureSetRevisionViewCreateTextureSetRevisionMutation, TextureSetRevisionViewCreateTextureSetRevisionMutationVariables> {
    override document = TextureSetRevisionViewCreateTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureThumbnailViewQueryDataObjectDocument = gql`
    query textureThumbnailViewQueryDataObject($dataObjectId: ID!) {
  dataObject(id: $dataObjectId) {
    ...TextureThumbnailViewDataObject
  }
}
    ${TextureThumbnailViewDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureThumbnailViewQueryDataObjectGQL extends Apollo.Query<TextureThumbnailViewQueryDataObjectQuery, TextureThumbnailViewQueryDataObjectQueryVariables> {
    override document = TextureThumbnailViewQueryDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureThumbnailViewUpdateDataObjectDocument = gql`
    mutation textureThumbnailViewUpdateDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureThumbnailViewUpdateDataObjectGQL extends Apollo.Mutation<TextureThumbnailViewUpdateDataObjectMutation, TextureThumbnailViewUpdateDataObjectMutationVariables> {
    override document = TextureThumbnailViewUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetDataDocument = gql`
    query queryTextureSetData($id: ID) {
  textureSet(id: $id) {
    ...TextureSetViewData
  }
}
    ${TextureSetViewDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetDataGQL extends Apollo.Query<QueryTextureSetDataQuery, QueryTextureSetDataQueryVariables> {
    override document = QueryTextureSetDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTextureSetNameDocument = gql`
    mutation updateTextureSetName($id: ID!, $name: String) {
  updateTextureSet(input: {id: $id, name: $name}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTextureSetNameGQL extends Apollo.Mutation<UpdateTextureSetNameMutation, UpdateTextureSetNameMutationVariables> {
    override document = UpdateTextureSetNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureSetDocument = gql`
    mutation deleteTextureSet($id: ID!) {
  deleteTextureSet(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureSetGQL extends Apollo.Mutation<DeleteTextureSetMutation, DeleteTextureSetMutationVariables> {
    override document = DeleteTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInitialTextureSetRevisionDocument = gql`
    mutation createInitialTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInitialTextureSetRevisionGQL extends Apollo.Mutation<CreateInitialTextureSetRevisionMutation, CreateInitialTextureSetRevisionMutationVariables> {
    override document = CreateInitialTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryLatestTextureSetRevisionIdDocument = gql`
    query queryLatestTextureSetRevisionId($textureSetId: ID!) {
  textureSetRevisions(
    take: 1
    filter: {textureSetId: {equals: $textureSetId}}
    orderBy: [{direction: desc, key: createdAt}]
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryLatestTextureSetRevisionIdGQL extends Apollo.Query<QueryLatestTextureSetRevisionIdQuery, QueryLatestTextureSetRevisionIdQueryVariables> {
    override document = QueryLatestTextureSetRevisionIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryInitialTextureRevisionsDocument = gql`
    query queryInitialTextureRevisions($id: ID, $legacyId: Int) {
  textureSet(id: $id, legacyId: $legacyId) {
    scanJob {
      createdBy {
        id
      }
    }
    textures {
      type
      revisions {
        number
        createdAt
        createdBy {
          id
        }
        width
        height
        displacement
        dataObject {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryInitialTextureRevisionsGQL extends Apollo.Query<QueryInitialTextureRevisionsQuery, QueryInitialTextureRevisionsQueryVariables> {
    override document = QueryInitialTextureRevisionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureGroupTextureSetIdsDocument = gql`
    query queryTextureGroupTextureSetIds($id: ID!) {
  textureGroup(id: $id) {
    ...TextureGroupTextureSetIds
  }
}
    ${TextureGroupTextureSetIdsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureGroupTextureSetIdsGQL extends Apollo.Query<QueryTextureGroupTextureSetIdsQuery, QueryTextureGroupTextureSetIdsQueryVariables> {
    override document = QueryTextureGroupTextureSetIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }