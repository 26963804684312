import {ApplicationRef, Injectable} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {BackgroundOperationItem, BackgroundOperationState} from "@app/common/models/background-operation"
import {BackgroundOperationService} from "@app/platform/services/background-operation/background-operation.service"
import {MimeType, UtilsService} from "@legacy/helpers/utils"
import {Settings} from "@common/models/settings/settings"
import {UploadProcessingService} from "@common/services/upload/upload-processing.service"
import {DataObject, DataObjectStates, DataObjectType} from "@legacy/api-model/data-object"
import {Observable, Subject, Subscription, timer} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class UploadService {
    // A timer invoked every couple of seconds.
    timer: Observable<number>

    parallelUploadsInProgress = 0

    constructor(
        private app: ApplicationRef,
        private snackBar: MatSnackBar,
        private utils: UtilsService,
        private uploadProcessingService: UploadProcessingService,
        private backgroundOperationService: BackgroundOperationService,
    ) {
        this.timer = timer(0, 2000)
    }

    // Upload

    // TODO: replace all uses of this with the upload GQL service
    /**
     * The upload has three steps:
     *   1. Get signed URL from the server
     *   2. Upload the file to Google Cloud Storage
     *   3. Let the server know if the upload was successful
     * TODO: Use Angular's HTTPClient for the upload
     * TODO: Get the signed URL just before the upload starts, otherwise it could expire if it is at the end of the queue. Right now the quick fix is to increase the expiration
     * of the signed upload URL.
     */
    uploadFile(
        file: File,
        dataObject: DataObject,
        showUploadToolbar = true,
        cancellable = false,
        waitForCompletion = true,
        disableCaching = false,
        triggerUploadProcessing = true,
    ): Observable<DataObject> {
        const uploadedDataObject = new Subject<DataObject>()
        const uploadedDataObject$ = uploadedDataObject.asObservable()

        dataObject.originalFileName = file.name
        dataObject.contentType = file.type
        dataObject.state = DataObjectStates.Init

        let backgroundOperationItem: BackgroundOperationItem | undefined
        if (showUploadToolbar) {
            backgroundOperationItem = this.backgroundOperationService.addBackgroundOperationToList("Upload", file.name, cancellable)
        }

        dataObject.save().subscribe(
            () => {
                if (!waitForCompletion) {
                    uploadedDataObject.next(dataObject)
                }

                const xhr: XMLHttpRequest = new XMLHttpRequest()
                if (backgroundOperationItem) {
                    backgroundOperationItem.abort = () => {
                        xhr.abort()
                    }
                }

                // Don't use xhr.upload.onload to detect completion! It will be fired before the final 200 OK response comes back.

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === xhr.DONE) {
                        if (xhr.status === 200) {
                            this.parallelUploadsInProgress -= 1
                            backgroundOperationItem?.progressSubject?.complete()
                            if (waitForCompletion) {
                                uploadedDataObject.next(dataObject)
                            }
                            uploadedDataObject.complete()

                            if (triggerUploadProcessing) {
                                // void this.uploadProcessingService.createUploadProcessingJob(dataObject.id, dataObject.legacyId, {legacyId: dataObject.customer})
                            }

                            this.app.tick()
                        } else if (backgroundOperationItem && backgroundOperationItem.state !== BackgroundOperationState.Cancelled) {
                            // Upload errors which are not triggering the upload.onerror event, excluding abortion by the user.
                            this.parallelUploadsInProgress -= 1
                            dataObject.delete().subscribe()
                            backgroundOperationItem.progressSubject?.error(xhr.response)
                            uploadedDataObject.error(xhr.response)
                        }
                    }
                }

                xhr.upload.onprogress = (event) => {
                    const progress: number = Math.round((event.loaded / event.total) * 100)
                    backgroundOperationItem?.progressSubject?.next(progress)
                    this.app.tick()
                }

                xhr.upload.onerror = (event) => {
                    this.parallelUploadsInProgress -= 1
                    dataObject.delete().subscribe()
                    backgroundOperationItem?.progressSubject?.error(event)
                    uploadedDataObject.error(event)
                    this.app.tick()
                }

                if (backgroundOperationItem) {
                    backgroundOperationItem.state = BackgroundOperationState.Waiting
                }
                const timerSubscription: Subscription = this.timer.subscribe(() => {
                    if (backgroundOperationItem && backgroundOperationItem.state === BackgroundOperationState.Cancelled) {
                        dataObject.delete().subscribe()
                        timerSubscription.unsubscribe()
                        return
                    }
                    if (this.parallelUploadsInProgress < Settings.MAX_ALLOWED_PARALLEL_UPLOADS) {
                        this.parallelUploadsInProgress += 1
                        xhr.open("PUT", dataObject.signedUploadUrl, true)
                        if (disableCaching) xhr.setRequestHeader("Cache-Control", "public,max-age=0")
                        xhr.send(file)
                        if (backgroundOperationItem) {
                            backgroundOperationItem.state = BackgroundOperationState.Completed
                        }
                        timerSubscription.unsubscribe()
                    }
                })
            },
            () => {
                dataObject.delete().subscribe(
                    () => {},
                    () => {
                        this.snackBar.open("Cannot delete data object.", "", {duration: 3000})
                    },
                )
                backgroundOperationItem?.progressSubject?.error("Could not get signed URL.")
                uploadedDataObject.error("Could not get signed URL.")
            },
        )
        return uploadedDataObject$
    }

    uploadFileAsync(file: File, dataObject: DataObject): Observable<DataObject> {
        return this.uploadFile(file, dataObject, true, false, false, false)
    }

    /*We could consider to get rid of of the upload service completely and move the implementation
    into a subclass of background operation in the future...
    */
    finalizeUploads(): void {
        this.backgroundOperationService.closeToolbarAndCleanOperations()
    }

    /**
     * Initializes a drop zone and uploads the files automatically.
     * It makes sure to remove the handler before initializing them, so it can be called multiple times if necessary.
     * @param dropZone The HTML element which receives the drag/drop etc. events.
     * @param customerId The customerId to which the file will belong to.
     * @param maxFiles Maximum number of files allowed.
     * @param dropZoneActive An object containing a boolean property which is going to be set to true or false depending on the drag events.
     * @param mimeTypeFilter Defines which files are accepted. Everything is accepted by default.
     * @returns {Observable<DataObject>}
     */
    initDropZone(
        dropZone: EventTarget,
        dropZoneActive: {[value: string]: boolean} = {},
        customerId: number,
        maxFiles = 100,
        mimeTypeFilter: MimeType = MimeType.All,
        dataObjectType: DataObjectType | undefined = undefined,
    ): Observable<DataObject> {
        const uploadedDataObject = new Subject<DataObject>()
        const uploadedDataObject$ = uploadedDataObject.asObservable()

        this.utils.initDropZoneHelper(dropZone, dropZoneActive, maxFiles, mimeTypeFilter).subscribe((droppedFile: File) => {
            const dataObject: DataObject = new DataObject()
            dataObject.customer = customerId
            if (dataObjectType !== undefined) {
                dataObject.type = dataObjectType
            }
            this.uploadFile(droppedFile, dataObject, true, true).subscribe((dataObject: DataObject) => {
                uploadedDataObject.next(dataObject)
            })
        })
        return uploadedDataObject$
    }

    updateDataObject(dataObject: DataObject): void {
        DataObject.get(dataObject.id).subscribe(
            (updatedDataObject: DataObject) => {
                if (updatedDataObject.state === DataObjectStates.Completed) {
                    dataObject.state = updatedDataObject.state
                    dataObject.type = updatedDataObject.type
                    dataObject.width = updatedDataObject.width
                    dataObject.height = updatedDataObject.height
                }
            },
            () => {
                this.snackBar.open("Cannot update data object.", "", {duration: 3000})
            },
        )
    }
}
