import {JobNodes, makeJobTaskDescriptor} from "#job-nodes/job-nodes"
import {RenderNodes} from "@cm/render-nodes"
import {z} from "zod"
import DataObjectReference = JobNodes.DataObjectReference

type CryptomatteEntity = "object" | "material" | "asset"

export const CryptomatteIdSchema = z
    .string()
    .refine((value) => value.startsWith("object:") || value.startsWith("material:") || value.startsWith("asset:")) as z.ZodType<CryptomatteId>

export type CryptomatteId = `${CryptomatteEntity}:${string}`

export const CryptomatteManifestSchema = z.object({
    objects: z.record(z.string(), z.string()).optional(), // key is ID without entity tag, value is hex string
    materials: z.record(z.string(), z.string()).optional(),
    assets: z.record(z.string(), z.string()).optional(),
})

export type CryptomatteManifest = z.infer<typeof CryptomatteManifestSchema>

export const RENDERING_TASK_NAME = "Rendering.cmRender"

export const RenderInputSchema = z.object({
    renderGraph: JobNodes.DataObjectReferenceSchema,
    customerId: z.number(),
    previewForProgressValue: z.array(z.number()).optional(),
})
export type RenderInput = z.infer<typeof RenderInputSchema>

export const RenderOutputSchema = z.object({
    renderPasses: z.record(RenderNodes.PassNameSchema, JobNodes.DataObjectReferenceSchema).nullable().optional(),
    preview: JobNodes.DataObjectReferenceSchema.nullable().optional(),
    metadata: JobNodes.DataObjectReferenceSchema.nullable().optional(),
})
export type RenderOutput = z.infer<typeof RenderOutputSchema>

type PassName = RenderNodes.PassName
export type RenderOutputForPassName<T extends PassName = PassName> = {
    renderPasses: Record<T, DataObjectReference>
    preview?: DataObjectReference | null
    metadata?: DataObjectReference | null
}
export function cmRenderTaskForPassNames<T extends PassName>(...passNames: T[]) {
    return makeJobTaskDescriptor<RenderInput, RenderOutputForPassName<T extends [] ? PassName : T>>(RENDERING_TASK_NAME, 1)
}

export const PictureRenderJobOutputSchema = z.object({
    ...RenderOutputSchema.shape,
    aoShadowMaskPass: JobNodes.DataObjectReferenceSchema.nullable().optional(),
})
export type PictureRenderJobOutput = z.infer<typeof PictureRenderJobOutputSchema>

export type PictureRenderJobOutputForPassNames<T extends PassName = PassName> = RenderOutputForPassName<T> & {
    aoShadowMaskPass?: JobNodes.DataObjectReference | null
}

export const RenderMetadataSchema = z.object({
    cryptomatteManifest: CryptomatteManifestSchema,
    passInfo: z.record(z.string(), z.object({bitDepth: z.union([z.literal(16), z.literal(32)])})).optional(),
})

export type RenderMetadata = z.infer<typeof RenderMetadataSchema>

export function renderTaskQueueDomain(useGpu: boolean | undefined, useCloud: boolean | undefined) {
    return `colormass-${useCloud ? "cloud" : "render-farm"}-${useGpu ? "gpu" : "cpu"}`
}
