import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {assertSameSizeAndBatchSize, getChannelLayoutByCount} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {getImgProcChannelLayout} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export type ParameterType = {
    sourceImages: [ImageRef, ImageRef, ImageRef] | [ImageRef, ImageRef, ImageRef, ImageRef]
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

const imageOpCombineChannels: ImageOpType<ParameterType, ReturnType> = {
    name: "CombineChannels",

    WebGL2: ({cmdQueue, parameters: {sourceImages, resultImageOrDataType}}) => {
        const primarySourceImage = sourceImages[0]
        for (const sourceImage of sourceImages) {
            assertSameSizeAndBatchSize(primarySourceImage.descriptor, sourceImage.descriptor)
        }
        const numChannels = sourceImages.length
        const painter = cmdQueue.createPainter(
            "compositor",
            "combineChannels",
            `
            vec4 computeColor(ivec2 targetPixel) {
                float r = texelFetch0(targetPixel).r;
                float g = ${numChannels >= 2 ? "texelFetch1(targetPixel).r" : "0.0"};
                float b = ${numChannels >= 3 ? "texelFetch2(targetPixel).r" : "0.0"};
                float a = ${numChannels >= 4 ? "texelFetch3(targetPixel).r" : "1.0"};
                return vec4(r, g, b, a);
            }
        `,
        )
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, {
            ...primarySourceImage.descriptor,
            channelLayout: getChannelLayoutByCount(numChannels),
        })
        cmdQueue.paint(painter, {
            sourceImages,
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImages, resultImageOrDataType}}) => {
        const primarySourceImage = sourceImages[0]
        for (const sourceImage of sourceImages) {
            assertSameSizeAndBatchSize(primarySourceImage.descriptor, sourceImage.descriptor)
        }
        const channelLayout = getChannelLayoutByCount(sourceImages.length)
        const imgProcChannelLayout = getImgProcChannelLayout(channelLayout)
        if (imgProcChannelLayout === "L") {
            throw Error("ImgProc's CombineChannels does not support single channel images")
        }
        const resultNode = cmdQueue.createImage(
            {
                ...primarySourceImage.descriptor,
                channelLayout: channelLayout,
            },
            {
                type: "combineChannels",
                input: sourceImages,
                channelLayout: imgProcChannelLayout,
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
        )
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function combineChannels(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpCombineChannels, parameters)
}
