import * as z from "zod"
import {ExtensionT} from "#utils/content-types"

export namespace DataObjectDefinitions {
    export type ImageFormat = Exclude<ExtensionT, "zip" | "bmp">
    export type DerivedImageFormat = Exclude<ImageFormat, "exr" | "png">
    // TODO, possible to avoid duplication?
    export const DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET = [
        {format: "jpg", resolution: "original"},
        {format: "tif", resolution: "original"},
        {format: "jpg", resolution: "px2000"},
        {format: "jpg", resolution: "px1000"},
        {format: "jpg", resolution: "px500"},
    ] as const

    export const DerivedImageFilenameWithFormatAndResolutionSetSchema = z
        .tuple([
            z.object({format: z.literal("jpg"), resolution: z.literal("original"), filename: z.string().nullable()}),
            z.object({format: z.literal("tif"), resolution: z.literal("original"), filename: z.string().nullable()}),
            z.object({format: z.literal("jpg"), resolution: z.literal("px2000"), filename: z.string().nullable()}),
            z.object({format: z.literal("jpg"), resolution: z.literal("px1000"), filename: z.string().nullable()}),
            z.object({format: z.literal("jpg"), resolution: z.literal("px500"), filename: z.string().nullable()}),
        ])
        .readonly()

    // export type DerivedImageResolution = (typeof DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET)[number]["resolution"]
    // type AuxT<K extends ReadonlyArray<any>, E> = {[I in keyof K]: K[I] & E}
    // export type DerivedImageFilenameWithFormatAndResolutionSet = AuxT<typeof DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET, {filename: string | null}>

    export type DerivedImageFilenameWithFormatAndResolutionSet = z.infer<typeof DerivedImageFilenameWithFormatAndResolutionSetSchema>
    export type DerivedImageResolution = DerivedImageFilenameWithFormatAndResolutionSet[number]["resolution"]
}

export type ImageFormat = DataObjectDefinitions.ImageFormat

const imageColorSpace_ = ["Gamma_2_0", "Gamma_2_2", "Linear", "Srgb", "Unknown"] as const
export const ImageColorSpaceSchema = z.enum(imageColorSpace_)
export const ImageColorSpace = ImageColorSpaceSchema.Enum
export type ImageColorSpace = z.infer<typeof ImageColorSpaceSchema>

// TODO unify that a bit with ./utils/data-object.ts. Also defined in template nodes
const thumbnailResolution_ = ["original", "2000px", "1000px", "500px"] as const
export const ThumbnailResolutionSchema = z.enum(thumbnailResolution_)
export const ThumbnailResolution = ThumbnailResolutionSchema.Enum
export type ThumbnailResolution = z.infer<typeof ThumbnailResolutionSchema>

const mediaType_ = [
    "application/zip",
    "image/jpeg",
    "image/tiff",
    "image/png",
    "image/bmp",
    "image/x-exr",
    "image/exr",
    "application/octet-stream",
    "application/draco",
    "application/ply",
] as const
export const MediaTypeSchema = z.enum(mediaType_)
export const MediaType = MediaTypeSchema.Enum
export type MediaType = z.infer<typeof MediaTypeSchema>

export enum DownloadFileType {
    Exr = "exr",
    Jpg = "jpg",
    Png = "png",
    Tiff = "tiff",
}

export function mapImageFormatToDownloadFileType(format: ImageFormat): DownloadFileType {
    switch (format) {
        case "exr":
            return DownloadFileType.Exr
        case "jpg":
            return DownloadFileType.Jpg
        case "png":
            return DownloadFileType.Png
        case "tif":
            return DownloadFileType.Tiff
        default:
            throw Error(`Unsupported format ${format}`)
    }
}

export enum DownloadResolution {
    High = "High",
    Medium = "Medium",
    Low = "Low",
    Tiny = "Tiny",
}

export function mapImageResolutionToDownloadResolution(resolution: ThumbnailResolution): DownloadResolution {
    switch (resolution) {
        case "original":
            return DownloadResolution.High
        case "2000px":
            return DownloadResolution.Medium
        case "1000px":
            return DownloadResolution.Low
        case "500px":
            return DownloadResolution.Tiny
        default:
            throw Error(`Unsupported resolution ${resolution}`)
    }
}

export function mapDownloadResolutionToImageResolution(resolution: DownloadResolution): ThumbnailResolution {
    switch (resolution) {
        case DownloadResolution.High:
            return "original"
        case DownloadResolution.Medium:
            return "2000px"
        case DownloadResolution.Low:
            return "1000px"
        case DownloadResolution.Tiny:
            return "500px"
        default:
            throw Error(`Unsupported resolution ${resolution}`)
    }
}
