import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"

@Component({
    selector: "cm-native-input-text",
    templateUrl: "./native-input-text.component.html",
    styleUrls: ["./native-input-text.component.scss"],
    standalone: true,
})
export class NativeInputTextComponent implements OnInit {
    @Input() placeholder: string = "Search..."
    @Input() initialValue: string = ""
    @Output() valueChange = new EventEmitter<string>()
    @Output() focus = new EventEmitter<FocusEvent>()
    @Output() blur = new EventEmitter<FocusEvent>()
    @Output() keyUp = new EventEmitter<string>()
    @Output() inputStarted = new EventEmitter<string>()
    @Input() disabled = false
    @Input() required = false
    @Input() showInvalid = false
    @Input() type = "text"

    @ViewChild("inputField", {static: true}) input: ElementRef<HTMLInputElement> | null = null

    ngOnInit() {
        if (this.input) {
            this.input.nativeElement.value = this.initialValue
        }
    }

    onChange($event: Event) {
        const target = $event.currentTarget
        if (target instanceof HTMLInputElement) {
            this.valueChange.emit(target.value)
        }
    }

    onKeyUp($event: KeyboardEvent) {
        const target = $event.currentTarget
        if (target instanceof HTMLInputElement) {
            this.keyUp.emit(target.value)
        }
    }

    updateValue(value: string) {
        if (this.input) {
            this.input.nativeElement.value = value
        }
        this.valueChange.emit(value)
    }
}
