import {IMaterialData, keyForMaterialData} from "@cm/template-nodes"
import {SceneNodes} from "@cm/template-nodes"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Observable, of, tap} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"

export class ThreePreloadMaterial extends ThreeObject<SceneNodes.PreloadMaterial> {
    protected override renderObject = new THREE.Mesh<THREE.BufferGeometry, THREE.Material>(new THREE.PlaneGeometry(0, 0))
    wasDisposed = false

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)
        this.renderObject.frustumCulled = false
    }

    override setup(sceneNode: SceneNodes.PreloadMaterial) {
        const {sceneManagerService, materialManagerService} = this.threeSceneManagerService

        const setupDeferredMaterialMaterial = (
            mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
            deferredMaterial: [Observable<THREE.Material>, IMaterialData],
        ) => {
            const [material, materialData] = deferredMaterial
            sceneManagerService.addTask({
                description: `preloadMaterial(${keyForMaterialData(materialData)})`,
                task: material.pipe(
                    tap((material) => {
                        if (this.wasDisposed) {
                            materialManagerService.releaseMaterial(material)
                            return
                        }

                        materialManagerService.releaseMaterial(mesh.material)
                        mesh.material = material

                        this.onAsyncUpdate()
                    }),
                ),
                critical: true,
            })
        }

        const getMaterial = (materialData: IMaterialData): [THREE.Material, [Observable<THREE.Material>, IMaterialData] | null] => {
            const [defaultMaterial, deferredMaterial] = materialManagerService.acquireMaterial(materialData, undefined, 0)
            if (!deferredMaterial) return [defaultMaterial, null]
            return [defaultMaterial, [deferredMaterial, materialData]]
        }

        const notifyCachedMaterial = (materialData: IMaterialData) => {
            sceneManagerService.addTask({description: `preloadCachedMaterial(${keyForMaterialData(materialData)})`, task: of(null), critical: true})
        }

        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["materialData"],
                (valueA, valueB) => valueA === valueB || keyForMaterialData(valueA) === keyForMaterialData(valueB),
                ({materialData}) => {
                    const [material, deferredMaterial] = getMaterial(materialData)
                    if (deferredMaterial) setupDeferredMaterialMaterial(this.renderObject, deferredMaterial)
                    else {
                        materialManagerService.releaseMaterial(this.renderObject.material)
                        this.renderObject.material = material
                        notifyCachedMaterial(materialData)
                    }
                },
            ),
        ])
    }

    override dispose(final: boolean) {
        const {materialManagerService} = this.threeSceneManagerService

        this.renderObject.geometry.dispose()
        materialManagerService.releaseMaterial(this.renderObject.material)

        if (final) this.wasDisposed = true
    }
}
