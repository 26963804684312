import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import paper from "paper"
import {ColorLike, Vector2, Vector2Like} from "@cm/math"

export class VectorItem extends CanvasBaseToolboxItemBase {
    constructor(parentItem: CanvasBaseToolboxItem, from: Vector2Like, to: Vector2Like, color: ColorLike) {
        super(parentItem)
        this._from = Vector2.fromVector2Like(from)
        this._to = Vector2.fromVector2Like(to)
        this._line = new paper.Path.Line(this._from, this._to)
        this._line.strokeColor = new paper.Color(color.r, color.g, color.b, color.a)
        this._line.strokeWidth = 1 / this.zoomLevel

        this.viewChange.subscribe(() => this.updateZoomDependent())
        this.updateZoomDependent()
    }

    private updateZoomDependent() {
        this._line.strokeWidth = 1 / this.zoomLevel
    }

    private _line: paper.Path
    private _from: Vector2
    private _to: Vector2
}
