import {ContentTypeModel, JobDetailsForTemplateImageViewerFragment, JobState} from "@api"
import {Settings} from "@app/common/models/settings/settings"
import {RenderingService} from "@app/common/services/rendering/rendering.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {postProcessingGraph} from "@cm/image-processing/render-post-processing"
import {JobNodes} from "@cm/job-nodes"
import {ImageProcessingInput, ImageProcessingOutput, imageProcessingTask} from "@cm/job-nodes/image-processing"
import {PictureRenderJobOutputSchema} from "@cm/job-nodes/rendering"
import {SceneNodes} from "@cm/template-nodes"
import {Parameters} from "@cm/template-nodes/nodes/parameters"
import {graphToJson, jsonToGraph} from "@cm/utils/graph-json"
import {sortedJSONStringify} from "@cm/utils/sorted-json-stringify"
import {z} from "zod"
import {getPostProcessingInput} from "../components/template-image-viewer/template-image-viewer.component"

export type JobData = JobDetailsForTemplateImageViewerFragment
export type UnassignedJobData = {
    displayName: string
    type: JobType
    job: JobData
}

const jobType = z.union([z.literal("render"), z.literal("postProcess")])
export type JobType = z.infer<typeof jobType>

export function isJobError(job: JobData) {
    switch (job.state) {
        case JobState.Failed:
        case JobState.Cancelled:
            return true
        default:
            return false
    }
}

export function isJobPending(job: JobData) {
    switch (job.state) {
        case JobState.Init:
        case JobState.Running:
        case JobState.Runnable:
            return true
        default:
            return false
    }
}

export const getConfigurationString = (parameters: Parameters) => sortedJSONStringify(parameters.parameters)

export const parseAssignmentKey = (key: string) => {
    if (!key.includes(":")) throw Error(`Failed to parse configuration job assignment key (missing colon): ${key}`)
    const parsedType = jobType.safeParse(key.split(":")[0])
    if (!parsedType.success) throw Error(`Failed to parse configuration job assignment key (invalid job type): ${key}`)

    const isValidUUID = (value: string) => z.string().uuid().safeParse(value).success
    const isValidParameterValue = isValidUUID
    const isValidParameterKey = (value: string) => isValidUUID(value) || value.split("/").every(isValidUUID)

    const configurationParameters = (() => {
        try {
            const json = JSON.parse(key.substring(`${parsedType.data}:`.length))
            const parsedParameters = z.record(z.string().refine(isValidParameterKey), z.string().refine(isValidParameterValue)).safeParse(json)
            if (!parsedParameters.success) throw Error("Invalid schema")
            return new Parameters(JSON.parse(key.substring(`${parsedType.data}:`.length)))
        } catch (error) {
            console.warn(`Unable to parse configuration parameters from job assignment: ${key}, error: ${error}`)
            return undefined
        }
    })()
    return {type: parsedType.data, configurationParameters}
}

export const getAssignmentKey = (type: "render" | "postProcess", configurationString: string) => `${type}:${configurationString}`

export async function getJobAssignment(sdkService: SdkService, templateRevisionId: string, assignmentKey: string) {
    const result = await sdkService.gql.getJobAssignmentsDetailsForTemplateImageViewer({
        filter: {
            objectId: templateRevisionId,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            assignmentKey: {equals: assignmentKey},
        },
    })

    return result.jobAssignments[0]?.job
}

export async function getAllJobAssignments(sdkService: SdkService, templateRevisionId: string) {
    const result = await sdkService.gql.getJobAssignmentsDetailsForTemplateImageViewer({
        filter: {
            objectId: templateRevisionId,
            contentTypeModel: ContentTypeModel.TemplateRevision,
        },
    })
    return result.jobAssignments
}

export async function getJobAssignments<T extends readonly string[]>(
    sdkService: SdkService,
    templateRevisionId: string,
    assignmentKeys: [...T],
): Promise<{[K in keyof T]: JobData | undefined}> {
    const result = await sdkService.gql.getJobAssignmentsDetailsForTemplateImageViewer({
        filter: {
            objectId: templateRevisionId,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            assignmentKey: {in: assignmentKeys},
        },
    })

    return assignmentKeys.map((key) => result.jobAssignments.find((assignment) => assignment?.assignmentKey === key)?.job) as {
        [K in keyof T]: JobData | undefined
    }
}

export const createRenderJob = async (
    sdkService: SdkService,
    renderingService: RenderingService,
    templateRevisionId: string,
    configurationString: string,
    sceneNodes: SceneNodes.SceneNode[],
    organizationLegacyId: number,
) => {
    const assignmentKey = getAssignmentKey("render", configurationString)

    const existingRenderJob = await getJobAssignment(sdkService, templateRevisionId, assignmentKey)
    if (existingRenderJob) throw Error(`A render job already exists for this variation. (id: ${existingRenderJob.id})`)

    const jobName = `Render template ${templateRevisionId} (variation)`

    const renderJob = await renderingService.submitRenderJob({
        nodes: sceneNodes,
        final: true,
        name: jobName,
        organizationLegacyId,
    })

    const jobAssignment = (
        await sdkService.gql.createJobAssignmentForTemplateImageViewer({
            input: {
                objectId: templateRevisionId,
                contentTypeModel: ContentTypeModel.TemplateRevision,
                assignmentKey,
                jobId: renderJob.id,
            },
        })
    ).createJobAssignment

    const createdJob = await getJobAssignment(sdkService, templateRevisionId, assignmentKey)
    if (!createdJob) throw Error("Failed to create render job")

    return [jobAssignment, createdJob] as const
}

const deleteJob = async (sdkService: SdkService, templateRevisionId: string, assignmentKey: string) => {
    const job = await getJobAssignment(sdkService, templateRevisionId, assignmentKey)
    if (!job) return undefined

    return sdkService.gql.deleteJobForTemplateImageViewer({id: job.id})
}

export const deleteRenderJob = async (sdkService: SdkService, templateRevisionId: string, configurationString: string) => {
    const assignmentKey = getAssignmentKey("render", configurationString)
    return deleteJob(sdkService, templateRevisionId, assignmentKey)
}

export const createPostProcessJob = async (
    sdkService: SdkService,
    templateRevisionId: string,
    configurationString: string,
    postProcessingSettings: SceneNodes.RenderPostProcessingSettings,
    organizationLegacyId: number,
) => {
    const assignmentKey = getAssignmentKey("postProcess", configurationString)

    const [existingPostProcessingJob, existingRenderJob] = await getJobAssignments(sdkService, templateRevisionId, [
        assignmentKey,
        getAssignmentKey("render", configurationString),
    ])

    if (!existingRenderJob) throw Error(`No existing render job`)
    if (existingPostProcessingJob) throw Error(`A postprocess job already exists for this variation. (id: ${existingPostProcessingJob.id})`)

    if (isJobPending(existingRenderJob) || isJobError(existingRenderJob)) throw Error(`Render job is not complete, state is ${existingRenderJob.state}`)

    const {output} = existingRenderJob
    const graph = jsonToGraph(output)
    const renderOutput = PictureRenderJobOutputSchema.parse(graph)
    if (!renderOutput.renderPasses) throw Error("Render job has no render passes")

    const postProcessingInput = await getPostProcessingInput(renderOutput, sdkService, false)

    const input: ImageProcessingInput = {
        graph: {
            type: "encode",
            mediaType: "image/tiff",
            input: {
                type: "convert",
                input: postProcessingGraph(postProcessingInput, postProcessingSettings).image,
                channelLayout: "RGBA",
                dataType: "uint8",
                sRGB: true,
            },
        },
    }

    const jobName = `Postprocess template ${templateRevisionId} (variation)`

    const jobGraph = JobNodes.jobGraph<ImageProcessingOutput>(JobNodes.task(imageProcessingTask, {input: JobNodes.value(input)}), {
        platformVersion: Settings.APP_VERSION,
    })
    const postProcessingJob = (
        await sdkService.gql.createJobForTemplateImageViewer({
            input: {
                name: jobName,
                organizationLegacyId,
                graph: graphToJson(jobGraph),
            },
        })
    ).createJob

    const jobAssignment = (
        await sdkService.gql.createJobAssignmentForTemplateImageViewer({
            input: {
                objectId: templateRevisionId,
                contentTypeModel: ContentTypeModel.TemplateRevision,
                assignmentKey,
                jobId: postProcessingJob.id,
            },
        })
    ).createJobAssignment

    return [jobAssignment, postProcessingJob] as const
}

export const deletePostProcessJob = async (sdkService: SdkService, templateRevisionId: string, configurationString: string) => {
    const assignmentKey = getAssignmentKey("postProcess", configurationString)
    return deleteJob(sdkService, templateRevisionId, assignmentKey)
}
