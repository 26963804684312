import {MeshData} from "#template-nodes/geometry-processing/mesh-data"
import {ISceneManagerNew} from "#template-nodes/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {defer, map, Subscription} from "rxjs"

const TD = TypeDescriptors

const loadMeshNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    data: TD.inlet(TD.Identity<Uint8Array>()),
    plyDataObjectId: TD.inlet(TD.Number),
    displaySubdivisionLevel: TD.inlet(TD.Number),
    renderSubdivisionLevel: TD.inlet(TD.Number),
    meshData: TD.outlet(TD.MeshData),
}

export class LoadMeshNew implements NodeClassImpl<typeof loadMeshNewDescriptor, typeof LoadMeshNew> {
    static descriptor = loadMeshNewDescriptor
    static uniqueName = "LoadMeshNew"
    sceneManager!: Inlet<ISceneManagerNew>
    data!: Inlet<Uint8Array>
    plyDataObjectId!: Inlet<number>
    displaySubdivisionLevel!: Inlet<number>
    renderSubdivisionLevel!: Inlet<number>
    meshData!: Outlet<MeshData>

    private pending: Subscription | null = null

    run() {
        this.meshData.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (
            this.sceneManager === NotReady ||
            this.data === NotReady ||
            this.plyDataObjectId === NotReady ||
            this.displaySubdivisionLevel === NotReady ||
            this.renderSubdivisionLevel === NotReady
        ) {
            return
        }

        const {displaySubdivisionLevel, renderSubdivisionLevel, sceneManager, data, plyDataObjectId} = this

        const highResSettings = {
            displaySubdivisionLevel,
            renderSubdivisionLevel,
        }

        if (sceneManager.isMeshDataCached(plyDataObjectId, highResSettings)) {
            this.pending = this.sceneManager.addTaskNew({
                description: `loadMeshData(${plyDataObjectId})`,
                task: defer(() => sceneManager.loadMeshDataNew(data, plyDataObjectId, highResSettings)).pipe(
                    map((meshDataHigh) => {
                        this.pending = null
                        this.meshData.emitIfChanged(meshDataHigh)
                    }),
                ),
                critical: true,
            })
        } else {
            this.pending = this.sceneManager.addTaskNew({
                description: `loadMeshData(${plyDataObjectId})`,
                task: defer(() =>
                    sceneManager.loadMeshDataNew(data, plyDataObjectId, {
                        displaySubdivisionLevel: 0,
                        renderSubdivisionLevel,
                    }),
                ).pipe(
                    map((meshDataLow) => {
                        this.pending = null
                        this.meshData.emitIfChanged(meshDataLow)

                        if (displaySubdivisionLevel > 0) {
                            this.pending = sceneManager.addTaskNew({
                                description: `loadMeshDataHigh(${plyDataObjectId})`,
                                task: defer(() => sceneManager.loadMeshDataNew(data, plyDataObjectId, highResSettings)).pipe(
                                    map((meshDataHigh) => {
                                        this.pending = null
                                        this.meshData.emitIfChanged(meshDataHigh)
                                    }),
                                ),
                                critical: false,
                            })
                        }
                    }),
                ),
                critical: true,
            })
        }
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
