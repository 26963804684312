import {MaterialSocket} from "@material-editor/models/material-socket"

export const DiffuseBsdfInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    roughness: {paramType: "socket", id: "Roughness", type: "input", valueType: "scalar", label: "Roughness"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "vector", label: "Normal"},
}

export const DiffuseBsdfOutputs: Record<string, MaterialSocket> = {
    bsdf: {paramType: "socket", id: "BSDF", type: "output", valueType: "output", label: "BSDF"},
}
