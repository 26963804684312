import {registerNode} from "@cm/graph/register-node"
import {anyJsonValue} from "#template-nodes/types"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {DeclareMeshNode, TemplateMeshNode} from "#template-nodes/declare-mesh-node"
import {z} from "zod"
import {visitNone} from "@cm/graph/declare-visitor-node"
import {GenerateMeshNew} from "#template-nodes/runtime-graph/nodes/generate-mesh-new"

const proceduralMeshParameters = namedNodeParameters.merge(
    z.object({
        geometryGraph: z.string(),
        parameters: z.record(anyJsonValue),
    }),
)
export type ProceduralMeshParameters = z.infer<typeof proceduralMeshParameters>

@registerNode
export class ProceduralMesh extends DeclareMeshNode(
    {parameters: proceduralMeshParameters},
    {
        onVisited: {
            onCompile: function (this: ProceduralMeshFwd, {context, parameters}) {
                const {evaluator} = context
                const {templateContext} = evaluator
                const {sceneManager} = templateContext
                const {geometryGraph, parameters: generateMeshParameters} = parameters

                const scope = evaluator.getScope(this)
                const {meshData} = scope.node(GenerateMeshNew, {
                    sceneManager,
                    graphPresetName: geometryGraph,
                    parameters: generateMeshParameters,
                })

                this.setupMesh(scope, context, meshData, true)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "ProceduralMesh"},
) {}

export type ProceduralMeshFwd = TemplateMeshNode<ProceduralMeshParameters>
