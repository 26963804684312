import {Component, input} from "@angular/core"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DownloadResolution} from "@api"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"

@Component({
    selector: "cm-user-thumbnail",
    standalone: true,
    imports: [DataObjectThumbnailComponent],
    templateUrl: "./user-thumbnail.component.html",
    styleUrl: "./user-thumbnail.component.scss",
})
export class UserThumbnailComponent {
    $user = input<{galleryImage?: {id: string} | null} | undefined | null>(null)
    $size = input<"small" | "medium">("small", {alias: "size"})

    protected readonly DownloadResolution = DownloadResolution
    protected readonly ThumbnailLayout = ThumbnailLayout
}
