import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {groupNodeParameters, GroupNodeParameters} from "#template-nodes/nodes/group-node"
import {namedNodeParameters, NamedNodeParameters} from "#template-nodes/nodes/named-node"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TemplateData, TemplateNode} from "#template-nodes/types"
import {skipped, visitAll} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"

const templateGraphParameters = namedNodeParameters.merge(groupNodeParameters)
export type TemplateGraphParameters = NamedNodeParameters & GroupNodeParameters

@registerNode
export class TemplateGraph
    extends DeclareTemplateNodeTS<TemplateGraphParameters>(
        {
            validation: {paramsSchema: templateGraphParameters},
            onVisited: {
                onFilterActive: function (this: TemplateGraph, {visit, context, parameters}) {
                    const {root} = context
                    if (this !== root) return skipped // skip internal template definitions

                    return visitAll(parameters, visit)
                },
                onCompile: function (this: TemplateGraph, {visit, context, parameters}) {
                    return visitAll(parameters, visit)
                },
            },
        },
        {nodeClass: "TemplateGraph"},
    )
    implements EvaluableTemplateNode<TemplateData>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<TemplateData> {
        return {graph: this}
    }
}

export type TemplateGraphFwd = TemplateNode<TemplateGraphParameters> & EvaluableTemplateNode<TemplateData>
