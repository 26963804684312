import {Component, computed, inject} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {MaterialReference} from "@cm/template-nodes"
import {from, switchMap} from "rxjs"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {CardComponent} from "../../../../common/components/cards/card/card.component"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {SelectMaterialComponent} from "@app/platform/components/materials/select-material/select-material.component"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {ButtonComponent} from "../../../../common/components/buttons/button/button.component"
import {Settings} from "@app/common/models/settings/settings"
import {MaterialFilterInput} from "@api"
import {DialogService} from "@app/common/services/dialog/dialog.service"
import {DataObjectThumbnailComponent} from "../../../../common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailComponent} from "../../../../common/components/thumbnail/thumbnail.component"
import {RouterModule} from "@angular/router"
import {MatTooltipModule} from "@angular/material/tooltip"

@Component({
    selector: "cm-material-reference-inspector",
    standalone: true,
    templateUrl: "./material-reference-inspector.component.html",
    styleUrl: "./material-reference-inspector.component.scss",
    imports: [
        InspectorSectionComponent,
        ValueSlotComponent,
        CardComponent,
        ButtonComponent,
        DataObjectThumbnailComponent,
        ThumbnailComponent,
        RouterModule,
        MatTooltipModule,
    ],
})
export class MaterialReferenceInspectorComponent extends BaseInspectorComponent<MaterialReference> {
    private sdk = inject(SdkService)
    dialogService = inject(DialogService)
    organizations = inject(OrganizationsService)
    materialRevisionId = computed(() => this.parameters().materialRevisionId)
    private materialRevisionData = toSignal(
        toObservable(this.materialRevisionId).pipe(
            switchMap((materialRevisionId) => from(this.sdk.gql.getMaterialRevisionDetailsForMaterialReferenceInspector({legacyId: materialRevisionId}))),
        ),
        {
            initialValue: null,
        },
    )
    thumbnailUrl = computed(() => {
        const materialRevisionData = this.materialRevisionData()
        if (!materialRevisionData) return Settings.IMAGE_NOT_AVAILABLE_SMALL_URL
        return undefined
    })
    thumbnailObject = computed(() => {
        const materialRevisionData = this.materialRevisionData()
        if (materialRevisionData) return materialRevisionData.materialRevision.material.galleryImage
        return undefined
    })
    title = computed(() => {
        const materialRevisionData = this.materialRevisionData()
        if (!materialRevisionData) return this.parameters().name

        const {material} = materialRevisionData.materialRevision

        return `${material.legacyId} - ${material.name}`
    })
    latestCyclesRevisionId = computed(() => {
        const materialRevisionData = this.materialRevisionData()
        return materialRevisionData?.materialRevision.material.latestCyclesRevision?.legacyId
    })

    revisionPossibilities = computed<SelectionPossibilities<number>>(() => {
        const materialRevisionData = this.materialRevisionData()
        return (
            materialRevisionData?.materialRevision.material.cyclesRevisions?.map((revision) => ({
                value: revision.legacyId,
                name: `v${revision.number}`,
            })) ?? []
        )
    })

    materialFilters: MaterialFilterInput = {hasCyclesMaterial: true}

    onClick() {
        this.dialogService.selectInDialog(SelectMaterialComponent, {
            filters: this.materialFilters,
            onSelect: async ({id}: {id: string}) => {
                const {material} = await this.sdk.gql.getMaterialDetailsForTemplateTreeAdd({id: id})
                const {latestCyclesRevision} = material

                if (latestCyclesRevision && latestCyclesRevision.legacyId !== undefined)
                    this.sceneManagerService.modifyTemplateGraph(() => {
                        this.node().updateParameters({materialRevisionId: latestCyclesRevision.legacyId, name: material.name ?? "New Material Reference"})
                    })
            },
        })
    }

    updateToLatestCyclesRevision() {
        const latestCyclesRevisionId = this.latestCyclesRevisionId()
        if (latestCyclesRevisionId === undefined) return
        this.sceneManagerService.modifyTemplateGraph(() => {
            this.node().updateParameters({materialRevisionId: latestCyclesRevisionId})
        })
    }
}
