import {MaterialSocket} from "@material-editor/models/material-socket"

export const TexGradientOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Value"},
    fac: {paramType: "socket", id: "Fac", type: "output", valueType: "output", label: "Fac"},
}

export const TexGradientInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "input", label: "Vector"},
}
