import {registerNode} from "@cm/graph/register-node"
import {NodeParameters} from "@cm/graph/node-graph"
import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {z} from "zod"

const generateVariable = <T extends NodeParameters>(
    tValidation: z.ZodType<NodeParameters>,
    implementation: TemplateNodeImplementation<T>,
    meta: TemplateNodeMeta<T>,
) => {
    return DeclareTemplateNodeTS<T>({validation: {paramsSchema: tValidation}, ...implementation}, meta)
}

////////////////////////////////////////////////////////////////////

const r1VariableParameters = z.object({
    range: z.tuple([z.number(), z.number()]),
    default: z.number(),
})
export type R1VariableParameters = z.infer<typeof r1VariableParameters>

@registerNode
export class R1Variable extends generateVariable<R1VariableParameters>(r1VariableParameters, {}, {nodeClass: "R1Variable"}) {}

export type R1VariableFwd = TemplateNode<R1VariableParameters>

////////////////////////////////////////////////////////////////////

const s1VariableParameters = z.object({
    default: z.tuple([z.number(), z.number()]).readonly(), //sin(angle), cos(angle)
})
export type S1VariableParameters = z.infer<typeof s1VariableParameters>

@registerNode
export class S1Variable extends generateVariable<S1VariableParameters>(s1VariableParameters, {}, {nodeClass: "S1Variable"}) {}

export type S1VariableFwd = TemplateNode<S1VariableParameters>

////////////////////////////////////////////////////////////////////

const s3VariableParameters = z.object({
    default: z.tuple([z.number(), z.number(), z.number(), z.number()]).readonly(), //x, y, z, w
})
export type S3VariableParameters = z.infer<typeof s3VariableParameters>

@registerNode
export class S3Variable extends generateVariable<S3VariableParameters>(s3VariableParameters, {}, {nodeClass: "S3Variable"}) {}

export type S3VariableFwd = TemplateNode<S3VariableParameters>
