import {DestroyRef, inject, Injectable} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ActivatedRoute, ParamMap, Router} from "@angular/router"
import {
    AssetFilterInput,
    AssetState,
    ContentTypeModel,
    DataObjectFilterInput,
    DataObjectState,
    HdriFilterInput,
    IntInFilter,
    JobFilterInput,
    JobState,
    MaterialFilterInput,
    MaterialState,
    ModelFilterInput,
    ModelState,
    NextActor,
    OrganizationFilterInput,
    PaymentState,
    PictureFilterInput,
    PictureState,
    TagFilterInput,
    TagType,
    TemplateFilterInput,
    TemplateState,
    TemplateType,
    TextureGroupFilterInput,
    UserFilterInput,
} from "@api"
import {ProductTypeLabels} from "@app/platform/models/state-labels/product-type-labels"
import {SceneTypeLabels} from "@app/platform/models/state-labels/scene-type-labels"
import {filtersAreEqual} from "@common/helpers/filters"
import {midnightThisMorning, midnightTonight} from "@common/models/date/date"
import {AuthService} from "@common/services/auth/auth.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {
    AnyStateType,
    AssetStateLabels,
    DataObjectStateLabels,
    MaterialStateLabels,
    ModelStateLabels,
    NextActorLabels,
    PaymentStateLabels,
    PictureStateLabels,
    TagTypeOptions,
    TemplateStateLabels,
    TemplateTypeLabels,
} from "@labels"
import {distinctUntilChanged, map, Observable, ReplaySubject, shareReplay, startWith} from "rxjs"

const BooleanOptions = (falseOption: string, trueOption: string) =>
    new Map([
        ["0", {label: falseOption}],
        ["1", {label: trueOption}],
    ])

export const FilterDefinitions = {
    assetId: {},
    assetState: {label: "State", options: AssetStateLabels},
    assetsCompleted: {
        label: "Assets",
        options: BooleanOptions("Not completed", "Completed"),
    },
    assignedToContentType: {},
    assignedToId: {},
    dataObjectState: {label: "State", options: DataObjectStateLabels},
    dateFrom: {},
    dateTo: {},
    hasAssignedUser: {
        label: "Availability",
        options: BooleanOptions("Not assigned", "Assigned"),
    },
    hasCyclesMaterial: {
        label: "Cycles Material",
        options: BooleanOptions("Not available", "Available"),
    },
    isActive: {
        label: "UserActive",
        options: BooleanOptions("Disabled", "Enabled"),
    },
    isPhotographer: {
        label: "Photographer",
        options: BooleanOptions("Non-photographer", "Photographer"),
    },
    isRelated: {
        label: "Relation to other files",
        options: BooleanOptions("Related", "Not related"),
    },
    isStaff: {
        label: "User Type",
        options: BooleanOptions("Non-staff", "Staff"),
    },
    legacyId: {},
    materialId: {},
    materialState: {label: "State", options: MaterialStateLabels},
    meshSpecific: {
        label: "Usage",
        options: BooleanOptions("General", "Mesh-specific"),
    },
    model: {
        label: "Model",
    },
    modelState: {label: "State", options: ModelStateLabels},
    nextActor: {label: "Up Next", options: NextActorLabels},
    organizationId: {label: "Customer", kind: "separate"},
    paymentState: {label: "Payment State", options: PaymentStateLabels},
    pictureState: {label: "State", options: PictureStateLabels},
    public: {
        label: "Visibility",
        options: BooleanOptions("Public", "Private"),
    },
    relatedToId: {label: "Related to", kind: "separate"},
    sampleArrived: {
        label: "Sample",
        options: BooleanOptions("Not available", "Available"),
    },
    scannedByNone: {},
    scannedByUserId: {},
    setId: {},
    state: {},
    projectId: {label: "Project"},
    tagId: {label: "Tag"},
    tagType: {label: "Tag Type", options: TagTypeOptions},
    templateState: {label: "State", options: TemplateStateLabels},
    templateType: {label: "Template Type", options: TemplateTypeLabels},
    productType: {label: "Product Type", options: ProductTypeLabels},
    sceneType: {label: "Scene Type", options: SceneTypeLabels},
    userId: {label: "User"},
}

export type FilterNameType = keyof typeof FilterDefinitions
export const FilterNames = Object.keys(FilterDefinitions) as FilterNameType[]

export type FilterCriterionState = {
    [name: string]: Set<string>
}

export type FilterStates = {search: string | undefined; criteria: FilterCriterionState}

export const checkFilterCriterionStatesEqual = (criteria1: FilterCriterionState, criteria2: FilterCriterionState) => {
    const checkSetsEqual = (set1: Set<string>, set2: Set<string>) => {
        const array1 = Array.from(set1)
        const array2 = Array.from(set2)
        return array1.every((value) => set2.has(value)) && array2.every((value) => set1.has(value))
    }

    return (
        Object.entries(criteria1).every(([key, value]) => criteria2[key] && checkSetsEqual(value, criteria2[key])) &&
        Object.entries(criteria2).every(([key, value]) => criteria1[key] && checkSetsEqual(value, criteria1[key]))
    )
}

const booleanFilter = (values?: Set<string>) => {
    if (!values) {
        return undefined
    }
    if (values.has("0")) {
        if (values.has("1")) {
            return undefined
        } else {
            return false
        }
    } else {
        if (values.has("1")) {
            return true
        } else {
            return undefined
        }
    }
}

export const arrayFilter = (values: Set<string> | undefined) => {
    if (!values?.size) {
        return undefined
    }
    return Array.from(values)
}

const stringFilter = (values: Set<string> | undefined) => {
    if (!values?.size || values.size > 1) {
        return undefined
    }
    return Array.from(values)[0]
}

export const stringInFilter = (values: Set<string> | undefined) => {
    if (!values?.size) {
        return undefined
    }
    return {in: Array.from(values)}
}

const intInFilter = (values: Set<number | string> | undefined): IntInFilter | undefined => {
    if (!values?.size) {
        return undefined
    }
    return {in: Array.from(values).map((value) => Number(value))}
}

const dateFilter = (values: Set<string> | undefined, defaultValue?: Date) => {
    if (!values?.size) {
        return defaultValue ?? new Date()
    }
    return new Date(Array.from(values)[0])
}

/**
 * Parses the query parameters into a filter object ready to be used in GraphQL queries.
 */
@Injectable({
    providedIn: "root",
})
export class FiltersService {
    public currentStates: FilterStates = {search: "", criteria: {}}
    public states = new ReplaySubject<FilterStates>()

    auth = inject(AuthService)
    permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private destroyRef: DestroyRef,
    ) {
        this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef), map(this.parseURLFilters)).subscribe((states) => {
            this.currentStates = states
            this.states.next(states)
        })
    }

    // we show all public items to non-staff users when no organization is selected
    defaultPublicFilter(states: FilterStates) {
        if (this.$can().read.organization() || states.criteria.organizationId) {
            return undefined
        } else {
            return true
        }
    }

    public updateSearchText = async (searchText: string) => {
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {search: searchText || undefined},
            queryParamsHandling: "merge",
        })
    }

    public updateCriteria = async (filterName: string, values: Iterable<string>) => {
        // TODO: maybe sort the values? then again, probably not necessary - we don't sort the query parameters, after all...
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {[filterName]: Array.from(values)?.join(",") || undefined, page: null},
            queryParamsHandling: "merge",
        })
    }

    private parseURLFilters = (params: ParamMap): FilterStates => {
        const result: FilterStates = {
            search: params.get("search") ?? undefined,
            criteria: {},
        }
        params.keys.forEach((key) => {
            if (key in FilterDefinitions) {
                // TODO: maybe parse using something like zod to ensure that the values are valid (?)
                const values = params.getAll(key).map((value) => value.split(",") as AnyStateType[])
                result.criteria[key as keyof typeof FilterDefinitions] = new Set(values.flat())
            }
        })
        return result
    }

    public makeObservable$: <FilterType>(filterGetter: (states: FilterStates) => FilterType) => Observable<FilterType> = (filterGetter) =>
        this.route.queryParamMap.pipe(
            takeUntilDestroyed(this.destroyRef),
            map(this.parseURLFilters),
            distinctUntilChanged(
                (previous, current) => previous.search === current.search && checkFilterCriterionStatesEqual(previous.criteria, current.criteria),
            ),
            map((states) => filterGetter(states)),
            shareReplay({bufferSize: 0, refCount: true}),
        )

    public assetFilter = (): AssetFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            hasAssignedUser: booleanFilter(states.criteria.hasAssignedUser),
            nextActor: arrayFilter(states.criteria.nextActor) as NextActor[],
            materialId: stringInFilter(states.criteria.materialId),
            modelId: stringInFilter(states.criteria.model),
            organizationId: stringInFilter(states.criteria.organizationId),
            search: states.search ?? undefined,
            state: arrayFilter(states.criteria.assetState) as AssetState[],
            userId: stringInFilter(states.criteria.userId),
        }
    }

    public dataObjectFilter = (): DataObjectFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            assignedToContentType: stringFilter(states.criteria.assignedToContentType) as ContentTypeModel | undefined,
            assignedToId: stringFilter(states.criteria.assignedToId),
            isRelated: booleanFilter(states.criteria.isRelated),
            relatedTo: states.criteria.relatedToId ? {in: Array.from(states.criteria.relatedToId)} : undefined,
            state: arrayFilter(states.criteria.dataObjectState) as DataObjectState[],
        }
    }

    public jobFilter$: Observable<JobFilterInput> = this.route.queryParamMap.pipe(
        takeUntilDestroyed(this.destroyRef),
        map(this.parseURLFilters),
        distinctUntilChanged((previous, current) => previous.search === current.search && checkFilterCriterionStatesEqual(previous.criteria, current.criteria)),
        map((states) => {
            if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
                return {}
            }
            return {
                createdByIds: stringInFilter(states.criteria.userId),
                organizationId: stringInFilter(states.criteria.organizationId),
                search: states.search ?? undefined,
                state: arrayFilter(states.criteria.state) as JobState[],
            }
        }),
        shareReplay({bufferSize: 0, refCount: true}),
    )

    public hdriFilter = (): HdriFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            search: states.search ?? undefined,
            organizationId: states.criteria.organizationId?.size ? {in: Array.from(states.criteria.organizationId)} : undefined,
        }
    }

    public materialFilter = (states = this.currentStates): MaterialFilterInput => {
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            hasAssignedUser: booleanFilter(states.criteria.hasAssignedUser),
            hasCyclesMaterial: booleanFilter(states.criteria.hasCyclesMaterial),
            legacyId: intInFilter(states.criteria.legacyId),
            meshSpecific: booleanFilter(states.criteria.meshSpecific),
            nextActor: arrayFilter(states.criteria.nextActor) as NextActor[],
            organizationId: stringInFilter(states.criteria.organizationId),
            paymentState: arrayFilter(states.criteria.paymentState) as PaymentState[],
            public: booleanFilter(states.criteria.public),
            sampleArrived: booleanFilter(states.criteria.sampleArrived),
            scannedByNone: booleanFilter(states.criteria.scannedByNone),
            scannedByUserId: stringInFilter(states.criteria.scannedByUserId),
            search: states.search ?? undefined,
            state: arrayFilter(states.criteria.materialState) as MaterialState[],
            tagId: stringInFilter(states.criteria.tagId),
            userId: stringInFilter(states.criteria.userId),
        }
    }
    public materialFilter$ = this.makeObservable$(this.materialFilter)

    public modelFilter = (): ModelFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            hasAssignedUser: booleanFilter(states.criteria.hasAssignedUser),
            legacyId: intInFilter(states.criteria.legacyId),
            nextActor: arrayFilter(states.criteria.nextActor) as NextActor[],
            organizationId: stringInFilter(states.criteria.organizationId),
            paymentState: arrayFilter(states.criteria.paymentState) as PaymentState[],
            public: booleanFilter(states.criteria.public),
            search: states.search ?? undefined,
            state: arrayFilter(states.criteria.modelState) as ModelState[],
            tagId: stringInFilter(states.criteria.tagId),
            userId: stringInFilter(states.criteria.userId),
        }
    }

    public organizationFilter = (): OrganizationFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            search: states.search ?? undefined,
        }
    }

    public statisticsFilter$: Observable<{from: Date; to: Date}> = this.route.queryParamMap.pipe(
        takeUntilDestroyed(this.destroyRef),
        map(this.parseURLFilters),
        distinctUntilChanged(filtersAreEqual),
        map((states) => {
            return {
                from: dateFilter(states.criteria.dateFrom, midnightThisMorning()),
                to: dateFilter(states.criteria.dateTo, midnightTonight()),
            }
        }),
    )

    public tagFilter = (): TagFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            search: states.search ?? undefined,
            organizationId: stringInFilter(states.criteria.organizationId),
            tagType: arrayFilter(states.criteria.tagType) as TagType[],
        }
    }

    public tagFilter$: Observable<TagFilterInput> = this.route.queryParamMap.pipe(
        takeUntilDestroyed(this.destroyRef),
        map(this.parseURLFilters),
        distinctUntilChanged(filtersAreEqual),
        map((states) => {
            if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
                return {}
            }
            return {
                search: states.search ?? undefined,
                organizationId: stringInFilter(states.criteria.organizationId),
                tagType: arrayFilter(states.criteria.tagType) as TagType[],
            }
        }),
        startWith({}),
    )

    public pictureFilter = (): PictureFilterInput => {
        const states = this.currentStates
        const organizationIds = states.criteria.organizationId
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {
                organizationId: stringInFilter(organizationIds),
            }
        }
        return {
            assetId: stringInFilter(states.criteria.assetId),
            assetsCompleted: booleanFilter(states.criteria.assetsCompleted),
            hasAssignedUser: booleanFilter(states.criteria.hasAssignedUser),
            nextActor: arrayFilter(states.criteria.nextActor) as NextActor[],
            state: arrayFilter(states.criteria.pictureState) as PictureState[],
            userId: stringInFilter(states.criteria.userId),
            organizationId: stringInFilter(organizationIds),
            paymentState: arrayFilter(states.criteria.paymentState) as PaymentState[],
            setId: stringInFilter(states.criteria.setId),
            projectId: stringInFilter(states.criteria.projectId),
            search: states.search ?? undefined,
            tagId: stringInFilter(states.criteria.tagId),
        }
    }

    public templateFilter = (): TemplateFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {
                type: [TemplateType.Part, TemplateType.Product, TemplateType.Room],
            }
        }
        return {
            public: booleanFilter(states.criteria.public),
            organizationId: stringInFilter(states.criteria.organizationId),
            search: states.search ?? undefined,
            state: arrayFilter(states.criteria.templateState) as TemplateState[],
            tagId: stringInFilter(states.criteria.tagId),
            type: (arrayFilter(states.criteria.templateType) ?? [TemplateType.Part, TemplateType.Product, TemplateType.Room]) as TemplateType[],
        }
    }

    public productFilter = (): TemplateFilterInput => {
        const {type, ...rest} = this.templateFilter()
        const productTypes = [...ProductTypeLabels.values()]
        return {...rest, type: type?.filter((t) => productTypes.some((x) => x.state === t))}
    }

    public sceneFilter = (): TemplateFilterInput => {
        const {type, ...rest} = this.templateFilter()
        const sceneTypes = [...SceneTypeLabels.values()]
        return {...rest, type: type?.filter((t) => sceneTypes.some((x) => x.state === t))}
    }

    public textureGroupFilter = (): TextureGroupFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            search: states.search ?? undefined,
            organizationId: states.criteria.organizationId?.size ? {in: Array.from(states.criteria.organizationId)} : undefined,
        }
    }

    public userFilter = (): UserFilterInput => {
        const states = this.currentStates
        if (!states.search && Object.values(states.criteria).every((state) => !state?.size)) {
            return {}
        }
        return {
            isActive: booleanFilter(states.criteria.isActive),
            isStaff: booleanFilter(states.criteria.isStaff),
            organizationId: states.criteria.organizationId?.size ? {in: Array.from(states.criteria.organizationId)} : undefined,
            search: states.search ?? undefined,
        }
    }
}
