import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"

export const defaultExportWorkflow: MaterialMapsExporter.Workflow = "metalnessRoughness"
export const defaultExportEngine: MaterialMapsExporter.Engine = "vray"
export const defaultExportNormalY: MaterialMapsExporter.NormalY = "y+up"
export const defaultExportFormat: MaterialMapsExporter.Format = "png"
export const defaultExportResolution: MaterialMapsExporter.Resolution = "original"

export const getDefaultExportConfigs = () => {
    const exports: [MaterialMapsExporter.Format, MaterialMapsExporter.Resolution][] = [
        ["jpeg", "dpi72"],
        ["jpeg", "original"],
        ["tiff", "dpi72"],
        ["tiff", "original"],
        ["exr", "original"],
    ]

    return exports.map(([format, resolution]) => {
        const exportName = `default_${format}_${resolution}`
        const conversionRequest = MaterialMapsExporter.conversionRequest(defaultExportWorkflow, defaultExportEngine, defaultExportNormalY, format, resolution)
        const exportRequest = MaterialMapsExporter.exportRequest(
            MaterialMapsExporter.exportFolder([conversionRequest, MaterialMapsExporter.conversionInfoRequest(conversionRequest, "info", "text")], exportName),
            {source: "materialRevision"},
        )
        return {
            ...exportRequest,
            displayName: `${format.toUpperCase()} - ${MaterialMapsExporter.resolutionToString(resolution)}`,
        }
    })
}
