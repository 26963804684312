import {Box2Like} from "@cm/math"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export const createView = (sourceImage: ImageRef, region: Box2Like): ImageRef => {
    if (region.width <= 0 || region.height <= 0) {
        throw new Error("Region must have positive width and height")
    }
    if (region.x < 0 || region.y < 0 || region.x + region.width > sourceImage.descriptor.width || region.y + region.height > sourceImage.descriptor.height) {
        throw new Error("Region must be contained within the source image")
    }
    return {
        ...sourceImage,
        descriptor: {
            ...sourceImage.descriptor,
            width: region.width,
            height: region.height,
        },
        view: {
            sourceImage: sourceImage.view?.sourceImage ?? sourceImage,
            offset: {
                x: region.x + (sourceImage.view?.offset.x ?? 0),
                y: region.y + (sourceImage.view?.offset.y ?? 0),
            },
        },
    }
}
