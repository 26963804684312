import {Nodes} from "#template-nodes/legacy/template-nodes"
import {v4 as uuid4} from "uuid"

type Node = Nodes.Node

export namespace NodeUtils {
    export type ResolvesTo<T extends Node> = T | Nodes.Instance<T>
    export type NodeType = Node["type"]

    export const isNode = Nodes.isNode

    export function isContext(node: Node): node is Nodes.Context & Node {
        switch (node.type) {
            case "templateGraph":
            case "configVariant":
            case "switch":
            case "group":
            case "configGroup":
                return true
            default:
                return false
        }
    }

    export function isValidId(id: string) {
        if (typeof id !== "string") return false
        else if (id === "") return false
        else return /^[a-zA-Z0-9_-]+$/.test(id)
    }

    export function isSwitchOf<T extends Node>(x: T | Nodes.Switch<T>, predFn: (x: Node) => x is T): x is Nodes.Switch<T> {
        return x.type === "switch" && (x as any).nodes.length > 0 && predFn((x as any).nodes[0])
    }

    export function isInstance(x: Node): x is Nodes.Instance<any> {
        return x.type === "instance"
    }
    export function isSwitch(x: Node): x is Nodes.Switch<any> {
        return x.type === "switch"
    }
    export function isTemplateExport(x: Node): x is Nodes.TemplateExport {
        return x.type === "templateExport"
    }
    export function isTemplateInput(x: Node): x is Nodes.TemplateInput {
        return x.type === "templateInput"
    }
    export function isMesh(x: Node): x is Nodes.Mesh {
        return x.type === "mesh" || x.type === "proceduralMesh"
    }
    export function isMeshOrInstance(x: Node): x is Nodes.MeshOrInstance {
        return x.type === "mesh" || x.type === "proceduralMesh" || (x.type === "instance" && isMesh(x.node))
    }
    export function isMeshDecal(x: Node): x is Nodes.MeshDecal {
        return x.type === "meshDecal"
    }
    export function isProceduralMesh(x: Node): x is Nodes.Mesh {
        return x.type === "proceduralMesh"
    }
    export function isGroup(x: Node): x is Nodes.Group {
        return x.type === "group"
    }
    export function isGuide(x: Node): x is Nodes.Guide {
        return x.type === "planeGuide" || x.type === "pointGuide"
    }
    export function isLight(x: Node): x is Nodes.Light {
        return x.type === "areaLight" || x.type === "lightPortal"
    }
    export function isAreaLight(x: Node): x is Nodes.AreaLight {
        return x.type === "areaLight"
    }
    export function isLightPortal(x: Node): x is Nodes.LightPortal {
        return x.type === "lightPortal"
    }
    export function isHDRILight(x: Node): x is Nodes.HDRILight {
        return x.type === "hdriLight"
    }
    export function isRelation(x: Node): x is Nodes.Relation {
        return x.type === "attachSurfaces" || x.type === "rigidRelation"
    }
    export function isCamera(x: Node): x is Nodes.Camera {
        return x.type === "camera"
    }
    export function isConfigGroup(x: Node): x is Nodes.ConfigGroup {
        return x.type === "configGroup"
    }
    export function isConfigVariant(x: Node): x is Nodes.ConfigVariant {
        return x.type === "configVariant"
    }
    export function isTemplateReference(x: Node): x is Nodes.TemplateReference {
        return x.type === "templateReference"
    }
    export function isTemplateGraph(x: Node): x is Nodes.TemplateGraph {
        return x.type === "templateGraph"
    }
    export function isMaterialReference(x: Node): x is Nodes.MaterialReference {
        return x.type === "materialReference"
    }
    export function isMaterialGraphReference(x: Node): x is Nodes.MaterialGraphReference {
        return x.type === "materialGraphReference"
    }
    export function isFindMaterial(x: Node): x is Nodes.FindMaterial {
        return x.type === "findMaterial"
    }
    export function isValue(x: Node): x is Nodes.Value {
        return x.type === "value"
    }
    export function isResolveNode(x: Node): x is Nodes.ResolveNode<unknown> {
        return x.type === "resolveNode"
    }
    export function isAnnotation(x: Node): x is Nodes.Annotation {
        return x.type === "annotation"
    }
    export function isSceneProperties(x: Node): x is Nodes.SceneProperties {
        return x.type === "sceneProperties"
    }
    export function isRender(x: Node): x is Nodes.Render {
        return x.type === "render"
    }
    export function isPostProcessRender(x: Node): x is Nodes.PostProcessRender {
        return x.type === "postProcessRender"
    }
    export function isOverlayMaterialColor(x: Node): x is Nodes.OverlayMaterialColor {
        return x.type === "overlayMaterialColor"
    }
    export function isDataObjectReference(x: Node): x is Nodes.DataObjectReference {
        return x.type === "dataObjectReference"
    }
    export function isTransientDataObject(x: Node): x is Nodes.TransientDataObject {
        return x.type === "transientDataObject"
    }

    export function isObject(x: Node): x is Nodes.Object {
        return isMesh(x) || isTemplateOrInstance(x) || isLight(x) || isGuide(x) || (x.type === "instance" && isObject(x.node))
    }
    export function isTemplateOrInstance(x: Node): x is Nodes.TemplateOrInstance {
        return isTemplateReference(x) || x.type === "templateInstance" || (x.type === "instance" && isTemplateOrInstance(x.node))
    }

    function _resolve(x: Node): Node {
        const orig = x
        while (x) {
            if (x.type === "switch") x = x.nodes[0]
            else if (x.type === "instance") x = x.node
            else break
        }
        return x ?? orig
    }

    function _isDynamicOf(x: Node, ioType: string): x is Nodes.TemplateInput | Nodes.GetTemplateOutput<unknown> {
        if (!x) return false
        if (x.type === "templateInput") return x.inputType === ioType
        else if (x.type === "getTemplateOutput") return x.outputType === ioType
        return false
    }

    function _isValueOf(x: Node, valueType: string): x is Nodes.Value {
        return isValue(x) && typeof x.value == valueType
    }

    export function resolvesToObject(x: Node): x is Nodes.ObjectReference {
        x = _resolve(x)
        return isObject(x) || _isDynamicOf(x, "object")
    }
    export function resolvesToMaterial(x: Node): x is Nodes.Material {
        x = _resolve(x)
        return isMaterialReference(x) || isMaterialGraphReference(x) || isFindMaterial(x) || isOverlayMaterialColor(x) || _isDynamicOf(x, "material")
    }
    export function resolvesToTemplateDefinition(x: Node): x is Nodes.TemplateDefinition {
        x = _resolve(x)
        return isTemplateReference(x) || isTemplateGraph(x) || _isDynamicOf(x, "template")
    }
    export function resolvesToImage(x: Node): x is Nodes.Image {
        x = _resolve(x)
        return isDataObjectReference(x) || isTransientDataObject(x) || _isDynamicOf(x, "image")
    }
    export function resolvesToTexture(x: Node): x is Nodes.Texture {
        x = _resolve(x)
        return x.type === "textureReference"
    }
    export function resolvesToSurface(x: Node): x is Nodes.SurfaceReference {
        x = _resolve(x)
        return x.type === "surfaceReference"
    }
    export function resolvesToString(x: Node): boolean {
        x = _resolve(x)
        return isResolveNode(x) || _isValueOf(x, "string") || _isDynamicOf(x, "string")
    }
    export function resolvesToNumber(x: Node): boolean {
        x = _resolve(x)
        return _isValueOf(x, "number") || _isDynamicOf(x, "number")
    }
    export function resolvesToBoolean(x: Node): boolean {
        x = _resolve(x)
        return _isValueOf(x, "boolean") || _isDynamicOf(x, "boolean")
    }

    export function isTransformable(x: Node): x is Nodes.Object | Nodes.Camera | Nodes.Annotation {
        return isObject(x) || isCamera(x) || isAnnotation(x)
    }
    export function hasTargetPosition(x: Node): x is Nodes.Camera | Nodes.AreaLight {
        return x.type === "camera" || x.type === "areaLight"
    }

    export class NodeSorter {
        constructor(private context: Nodes.Context) {}
        getHDRILight(): Nodes.HDRILight {
            return this.context.nodes.find((x) => x.type === "hdriLight") as Nodes.HDRILight
        }
        getSceneProperties(): Nodes.SceneProperties {
            return findSceneProperties(this.context)
        }
        getUsedMaterials(): Nodes.Material[] {
            const materials: Nodes.Material[] = []
            for (const node of this.context.nodes) {
                if (isMaterialReference(node)) {
                    materials.push(node)
                } else if (isConfigGroup(node)) {
                    for (const subNode of node.nodes) {
                        if (NodeUtils.isSwitch(subNode) && NodeUtils.resolvesToMaterial(subNode)) {
                            materials.push(subNode)
                        }
                    }
                }
            }
            return materials
        }
        getCamera() {
            return this.context.nodes.find(isCamera)
        }
        getStudio() {
            //TODO: Better filtering for studio/ground plane node...
            return this.context.nodes.find((x) => x.type === "proceduralMesh") as Nodes.ProceduralMesh
        }
    }

    export function resolveInstance<T extends Node>(x: T | Nodes.Instance<T>): T {
        if (x.type === "instance") {
            return resolveInstance((x as Nodes.Instance<any>).node) as T
        } else {
            return x as T
        }
    }

    export function resolveTemplateInstance<T extends Node>(x: T | Nodes.TemplateInstance): T {
        if (x.type === "templateInstance" && x.template) {
            return resolveInstance(x.template) as T
        } else {
            return x as T
        }
    }

    function describeSurface(ref: Nodes.SurfaceReference | null | undefined): string {
        if (!ref) {
            return "???"
        } else if (ref.type === "switch") {
            return `${ref.name} (ref)`
        } else {
            const resolved: Node = resolveInstance(ref.object)
            if (resolved.type === "mesh") {
                const surf = resolved.surfaces.find((x) => Nodes.getExternalId(x) === ref.surfaceId)
                return `${ref.object.name}/${surf ? surf.name : "???"}`
            } else {
                //TODO: need to cache surface names for templates!
                return `${ref.object.name}/${ref.surfaceId}`
            }
        }
    }

    function describeObject(ref: Nodes.ObjectReference | null | undefined): string {
        if (!ref) {
            return "???"
        } else if (ref.type === "switch") {
            return `${ref.name} (ref)`
        } else {
            return `${ref.name}`
        }
    }

    export function describeRelationReference(ref: Nodes.SurfaceReference | Nodes.ObjectReference | null | undefined) {
        if (!ref) {
            return "???"
        } else if (resolvesToSurface(ref)) {
            return describeSurface(ref)
        } else {
            return describeObject(ref)
        }
    }

    export function describeNode(node: Node): string {
        if (!node) return "(null)"
        switch (node.type) {
            case "templateGraph":
                return `Template: ${node.name}`
            case "configVariant":
                return `Config Variant: ${node.name}`
            case "areaLight":
                return `Area Light: ${node.name}`
            case "lightPortal":
                return `Light Portal: ${node.name}`
            case "attachSurfaces":
                return `Attach [${describeSurface(node.targetA)}] to [${describeSurface(node.targetB)}]`
            case "rigidRelation":
                return `Align [${describeObject(node.targetA)}] to [${describeObject(node.targetB)}]`
            case "camera":
                return `Camera: ${node.name}`
            case "configGroup":
                return `Config Group: ${node.name}`
            case "instance":
                return `Instance: ${node.name}`
            case "materialReference":
                return `MaterialRef: ${node.name}`
            case "textureReference":
                return `TextureRef: ${node.name}`
            case "hdriReference":
                return `HDRIRef: ${node.name}`
            case "hdriLight":
                return `HDRILight: ${node.name}`
            case "mesh":
                return `Mesh: ${node.name}`
            case "meshDecal":
                return `Decal: ${node.name}`
            case "proceduralMesh":
                return `Procedural Mesh: ${node.name}`
            case "planeGuide":
                return `PlaneGuide: ${node.name}`
            case "pointGuide":
                return `PointGuide: ${node.name}`
            case "annotation":
                return `Annotation: ${node.name}`
            case "switch":
                return `Switch: ${node.name}`
            case "group":
                return `Group: ${node.name}`
            case "templateInstance":
                return `Template Instance: ${node.name}`
            case "templateExport":
                return `Output: ${node.name}`
            case "templateInput":
                return `Input: ${node.name}`
            case "surfaceReference":
                return "SurfaceRef: " + describeSurface(node)
            case "meshSurface":
                return `Mesh Surface: ${node.name}`
            case "sceneProperties":
                return "Scene Properties"
            case "value":
                return `Value: ${JSON.stringify(node.value)}`
            case "render":
                return "Render"
            case "postProcessRender":
                return "Post Process Render"
            case "dataObjectReference":
                return `Data Object Reference: ${node.dataObjectId}`
            default: {
                let typeName = ((node as any).type as string).replace(/([A-Z]+)/g, " $1")
                typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
                if ("name" in node) {
                    return `${typeName}: ${node.name}`
                } else {
                    return typeName
                }
            }
        }
    }

    export function isParentAllowed(node: Node, parent: Node): boolean {
        return getAllowedParentTypes(node).includes(parent.type)
    }

    function getAllowedParentTypes(node: Node): NodeType[] {
        if (!node) return []
        switch (node.type) {
            case "meshSurface":
                return ["mesh"]
            case "configGroup":
            case "templateExport":
            case "sceneProperties":
                return ["templateGraph"]
            case "configVariant":
            case "switch":
                return ["configGroup"]
            default:
                return ["templateGraph", "group", "configVariant"]
        }
    }

    export function sortNamedNodesAlphabetically(nodes: Nodes.Node[]): void {
        const isNamedNode = (node: Nodes.Node): node is Nodes.Node & {name: string} => "name" in node && typeof node.name === "string"
        nodes.sort((a, b): number => {
            if (isNamedNode(a) && isNamedNode(b)) {
                return a.name.localeCompare(b.name)
            } else if (isNamedNode(a)) {
                return -1
            } else if (isNamedNode(b)) {
                return 1
            } else {
                return 0
            }
        })
    }

    function traverseContexts(node: Node, fn: (node: Node, path: Nodes.Context[]) => void): void {
        const visited = new Set<Node>()
        const _traverse = (node: Node, path: Nodes.Context[]): void => {
            if (visited.has(node)) return
            visited.add(node)
            fn(node, path)
            if (isContext(node)) {
                const childPath = [...path, node]
                for (const child of node.nodes) {
                    _traverse(child, childPath)
                }
            }
        }
        _traverse(node, [])
    }

    export function removeNodesFromContexts(root: Node, nodes: Node[]) {
        const nodeSet = new Set<Node>(nodes)
        const toRemove: [Node, Nodes.Context][] = []
        traverseContexts(root, (node, path) => {
            if (nodeSet.has(node)) {
                toRemove.push([node, path[path.length - 1]])
            }
        })
        const modifiedContexts = new Set<Nodes.Context>()
        toRemove.forEach(([node, context]) => {
            const index = context.nodes.indexOf(node)
            if (index >= 0) {
                context.nodes.splice(index, 1)
                modifiedContexts.add(context)
            }
        })
        return Array.from(modifiedContexts)
    }

    export function findContextForNode(root: Node, node: Node): Nodes.Context | undefined {
        let context: Nodes.Context | undefined
        traverseContexts(root, (visitNode, path) => {
            if (visitNode === node) {
                context = path[path.length - 1]
            }
        })
        return context
    }

    export function getCommonContextForNodes(root: Node, nodes: Node[]): Nodes.Context | null {
        const nodeSet = new Set<Node>(nodes)
        let commonPath: Nodes.Context[] | undefined
        traverseContexts(root, (node, path) => {
            if (nodeSet.has(node)) {
                if (!commonPath) {
                    commonPath = path
                } else {
                    let len = Math.min(path.length, commonPath.length)
                    for (let i = 0; i < len; i++) {
                        if (path[i] !== commonPath[i]) {
                            // paths are different at index i, so truncate here
                            len = i
                            break
                        }
                    }
                    commonPath.length = len
                }
            }
        })
        return commonPath && commonPath.length > 0 ? commonPath[commonPath.length - 1] : null
    }

    export function countReferences(node: Nodes.Node, root: Nodes.Context): number {
        const visited = new Set<any>()
        let count = 0
        const traverse = (value: any): any => {
            if (value === node) {
                ++count
            }
            if (typeof value === "object") {
                if (value === null) {
                    return
                } else if (visited.has(value)) {
                    return
                }
                visited.add(value)
                for (const key of Object.keys(value)) {
                    traverse(value[key])
                }
            }
        }
        traverse(root)
        return count
    }

    export function deleteNodesAndReferences(nodes: Node[], root: Nodes.Context) {
        const deletedNodes = new Set<Node>()
        const modifiedNodes = new Set<Node>()
        const visited = new Set<Node>()

        const traverseGatherDeleted = (node: Node): void => {
            if (visited.has(node)) return
            visited.add(node)
            deletedNodes.add(node)
            if (isContext(node) && !isReferenceOnlyContext(node)) {
                node.nodes.forEach(traverseGatherDeleted)
            }
        }

        const traverseRemoveReferences = (value: any, curNode: Node): any => {
            if (typeof value === "object") {
                if (value === null) {
                    return
                } else if (visited.has(value) || deletedNodes.has(value)) {
                    return
                }
                visited.add(value)
                if (isNode(value)) {
                    curNode = value
                }
                if (Array.isArray(value)) {
                    let i = 0
                    while (i < value.length) {
                        const elem = value[i]
                        if (deletedNodes.has(elem)) {
                            value.splice(i, 1) //TODO: remove from array vs set as null/undefined?
                            modifiedNodes.add(curNode)
                        } else {
                            ++i
                        }
                    }
                }
                for (const key of Object.keys(value)) {
                    const elem = value[key]
                    if (deletedNodes.has(elem)) {
                        value[key] = null //TODO: null or undefined?
                        modifiedNodes.add(curNode)
                    } else {
                        traverseRemoveReferences(value[key], curNode)
                    }
                }
            }
        }

        for (const nodeToRemove of nodes) {
            if (visited.has(nodeToRemove)) continue
            traverseGatherDeleted(nodeToRemove)
        }

        visited.clear()

        traverseRemoveReferences(root, root as Node)

        console.log("deletedNodes", deletedNodes)
        console.log("modifiedNodes", modifiedNodes)

        return {deletedNodes: Array.from(deletedNodes), modifiedNodes: Array.from(modifiedNodes)}
    }

    export function isReferenceOnlyContext(context: Nodes.Context): boolean {
        return !Nodes.SOLE_OWNER_FIELDS[context.type]?.includes("nodes")
    }

    export function cloneNode<T extends Node>(node: T): T {
        const traverse = (value: any): any => {
            if (typeof value === "object" && value != null) {
                if (Array.isArray(value)) {
                    return value.map(traverse)
                } else if (isNode(value)) {
                    return value
                } else {
                    return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, traverse(v)]))
                }
            } else {
                return value
            }
        }
        return traverse(node)
    }

    export function copySubgraphStructure(nodes: Node[]): Node[] {
        const fixupValueMap = new Map<any, any>() // map from original object to newly created copy
        const fixupList: [any, string | number, any][] = []

        const traverse = (parent: any, keyInParent: string | number, value: any, deepCopyNodes: boolean): any => {
            if (typeof value === "object") {
                if (value === null) {
                    return value
                }
                const existing = fixupValueMap.get(value)
                if (existing) {
                    return existing
                }
                let valueCopy: any
                if (Array.isArray(value)) {
                    valueCopy = []
                    for (let idx = 0; idx < value.length; idx++) {
                        const elemValue = value[idx]
                        valueCopy.push(traverse(value, idx, elemValue, deepCopyNodes))
                    }
                } else if (isNode(value)) {
                    if (!deepCopyNodes) {
                        fixupList.push([parent, keyInParent, value])
                        return value
                    }
                    valueCopy = {}
                    const fieldsWithOwnedNodes = Nodes.SOLE_OWNER_FIELDS[value.type]
                    for (const [key, propValue] of Object.entries(value)) {
                        if (key === "id") {
                            valueCopy[key] = uuid4()
                        } else if (key.startsWith("$")) {
                            continue
                        } else if (fieldsWithOwnedNodes && fieldsWithOwnedNodes.includes(key)) {
                            valueCopy[key] = traverse(value, key, propValue, true) // Any nodes reachable from this field are owned by this node, so make a deep copy
                        } else {
                            valueCopy[key] = traverse(value, key, propValue, false) // This field contains only references, so only deep copy up to the node references
                        }
                    }
                } else {
                    valueCopy = {}
                    for (const key of Object.keys(value)) {
                        valueCopy[key] = traverse(value, key, value[key], deepCopyNodes)
                    }
                }
                fixupValueMap.set(value, valueCopy)
                return valueCopy
            } else {
                return value
            }
        }

        const retNodes: Node[] = []
        for (const node of nodes) {
            retNodes.push(traverse(null, null as any, node, true))
        }

        for (const [parent, key, value] of fixupList) {
            const valueCopy = fixupValueMap.get(value)
            if (valueCopy) {
                parent[key] = valueCopy
            }
        }

        return retNodes
    }

    export function findSceneProperties(context: Nodes.Context): Nodes.SceneProperties {
        return context.nodes.find((x) => x.type === "sceneProperties") as Nodes.SceneProperties
    }
}

export const NodeDescriptors = {
    overlayMaterialColor: {
        name: "Overlay Material Color",
        type: "overlayMaterialColor",
        fields: [
            {key: "material", type: "node", name: "Material", filter: NodeUtils.resolvesToMaterial},
            {key: "overlay", type: "node", name: "Overlay image", filter: NodeUtils.resolvesToImage},
            {
                key: "size",
                type: "tuple",
                fields: [
                    {type: "numberLike", name: "Width", precision: 0.01},
                    {type: "numberLike", name: "Height", precision: 0.01},
                ],
            },
        ],
    },
}
