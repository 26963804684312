import {Component} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {ConfigGroup} from "@cm/template-nodes"
import {isValidExternalId} from "@cm/template-nodes"

@Component({
    selector: "cm-config-group-inspector",
    standalone: true,
    templateUrl: "./config-group-inspector.component.html",
    styleUrl: "./config-group-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class ConfigGroupInspectorComponent extends BaseInspectorComponent<ConfigGroup> {
    isValidExternalId = isValidExternalId
}
