import {ThreeAnnotationObject} from "@app/template-editor/helpers/three-annotation"
import {getNextThreeObjectPart} from "@app/template-editor/helpers/three-object"
import {Three as THREE} from "@cm/material-nodes/three"

const _vector = new THREE.Vector3()
const _viewMatrix = new THREE.Matrix4()
const _viewProjectionMatrix = new THREE.Matrix4()
const _a = new THREE.Vector3()
const _b = new THREE.Vector3()

export class ThreeAnnotationRenderer {
    private _width = 0
    private _height = 0
    private _widthHalf = 0
    private _heightHalf = 0
    private distanceCache = new WeakMap<ThreeAnnotationObject, number>()
    private raycaster = new THREE.Raycaster()

    domElement: HTMLElement

    constructor(
        parameters: {
            element?: HTMLElement
        } = {},
    ) {
        const domElement = parameters.element !== undefined ? parameters.element : document.createElement("div")
        domElement.style.overflow = "hidden"
        this.domElement = domElement
    }

    getSize() {
        return {
            width: this._width,
            height: this._height,
        }
    }

    render(scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
        if (scene.matrixWorldAutoUpdate === true) scene.updateMatrixWorld()
        if (camera.parent === null && camera.matrixWorldAutoUpdate === true) camera.updateMatrixWorld()

        _viewMatrix.copy(camera.matrixWorldInverse)
        _viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, _viewMatrix)

        while (this.domElement.firstChild) {
            this.domElement.removeChild(this.domElement.firstChild)
        }

        this.renderObject(scene, scene, camera)
        this.zOrder(scene, camera)
    }

    setSize(width: number, height: number) {
        this._width = width
        this._height = height

        this._widthHalf = this._width / 2
        this._heightHalf = this._height / 2

        this.domElement.style.width = width + "px"
        this.domElement.style.height = height + "px"
    }

    private opacityForObject(object: ThreeAnnotationObject, scene: THREE.Scene, camera: THREE.PerspectiveCamera): number {
        if (!object.isConcealable()) return 1.0

        _a.setFromMatrixPosition(object.matrixWorld)
        _b.setFromMatrixPosition(camera.matrixWorld)
        const cameraToObject = _a.sub(_b)
        const distanceToCamera = cameraToObject.length()
        const dir = cameraToObject.normalize()

        this.raycaster.set(_b, dir)

        const firstIntersection = this.raycaster.intersectObjects(scene.children, true).find((intersection) => {
            if (!intersection.object.visible) return false
            if (intersection.distance < camera.near || intersection.distance > camera.far) return false

            const {object} = intersection
            const threeObjectPart = getNextThreeObjectPart(object)
            if (threeObjectPart) return true

            return false
        })

        if (!firstIntersection || firstIntersection.distance > distanceToCamera) return 1.0

        return 0.2
    }

    private renderObject(object: THREE.Object3D, scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
        if (object instanceof ThreeAnnotationObject) {
            _vector.setFromMatrixPosition(object.matrixWorld)
            _vector.applyMatrix4(_viewProjectionMatrix)

            const visible =
                _vector.x >= -1 &&
                _vector.x <= 1 &&
                _vector.y >= -1 &&
                _vector.y <= 1 &&
                _vector.z >= -1 &&
                _vector.z <= 1 &&
                object.layers.test(camera.layers) === true

            const element = object.getElement(camera)

            element.style.display = visible === true ? "" : "none"

            if (visible === true) {
                const x = _vector.x * this._widthHalf + this._widthHalf
                const y = -_vector.y * this._heightHalf + this._heightHalf

                element.style.transform = "translate(" + -50 + "%," + -50 + "%)" + "translate(" + x + "px," + y + "px)"
                element.style.opacity = `${this.opacityForObject(object, scene, camera)}`

                if (element.parentNode !== this.domElement) {
                    this.domElement.appendChild(element)
                }
            }

            this.distanceCache.set(object, this.getDistanceToSquared(camera, object))
        }

        for (let i = 0, l = object.children.length; i < l; i++) {
            if (object.visible) this.renderObject(object.children[i], scene, camera)
        }
    }

    private getDistanceToSquared(object1: THREE.Object3D, object2: THREE.Object3D) {
        _a.setFromMatrixPosition(object1.matrixWorld)
        _b.setFromMatrixPosition(object2.matrixWorld)

        return _a.distanceToSquared(_b)
    }

    private filterAndFlatten(scene: THREE.Scene) {
        const result: ThreeAnnotationObject[] = []

        scene.traverse(function (object) {
            if (object instanceof ThreeAnnotationObject) result.push(object)
        })

        return result
    }

    private zOrder(scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
        const sorted = this.filterAndFlatten(scene).sort((a, b) => {
            if (a.renderOrder !== b.renderOrder) {
                return b.renderOrder - a.renderOrder
            }

            const distanceA = this.distanceCache.get(a)
            const distanceB = this.distanceCache.get(b)

            if (distanceA === undefined) return 1
            if (distanceB === undefined) return -1

            return distanceA - distanceB
        })

        const zMax = sorted.length

        for (let i = 0, l = sorted.length; i < l; i++) {
            sorted[i].getElement(camera).style.zIndex = `${zMax - i}`
        }
    }
}
