import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const andDescriptor = {
    input: TD.inlet(TD.Array(TD.Primitive<boolean>())),
    output: TD.outlet(TD.Primitive<boolean>()),
}

export class And implements NodeClassImpl<typeof andDescriptor, typeof And> {
    static descriptor = andDescriptor
    static uniqueName = "And"
    input!: Inlet<boolean[]>
    output!: Outlet<boolean>

    run() {
        if (this.input === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        for (const x of this.input) {
            if (!x) {
                this.output.emitIfChanged(false)
                return
            }
        }
        this.output.emitIfChanged(true)
    }
}
