import {Component, inject, Input, OnInit, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ActionPanelRowComponent} from "@common/components/menu"
import {ActionPanelComponent} from "@common/components/menu/action-menu/action-panel/action-panel.component"
import {AutoTilingState, AutoTilingViewMode, OperatorAutoTiling} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import * as Tiling from "app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling.service"
import {HintComponent} from "@app/common/components/hint/hint.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-auto-tiling-panel",
    templateUrl: "./auto-tiling-panel.component.html",
    styleUrls: ["./auto-tiling-panel.component.scss"],
    standalone: true,
    imports: [
        HintComponent,
        ButtonComponent,
        InspectorSectionComponent,
        MatTooltipModule,
        ActionPanelRowComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
    ],
})
export class AutoTilingPanelComponent implements OnInit {
    @ViewChild("panel", {static: true}) panel!: ActionPanelComponent

    permission = inject(PermissionsService)
    $can = this.permission.$to

    @Input() operator!: OperatorAutoTiling
    @Input() disabled = false

    protected ViewMode = AutoTilingViewMode

    ngOnInit(): void {}

    show(): void {
        this.panel.show()
    }

    hide(): void {
        this.panel.hide()
    }

    get isEdited(): boolean {
        return this.operator.edited
    }

    get canChangeAutoTilingSetup(): boolean {
        return this.operator.canChangeAutoTilingSetup
    }

    get viewMode(): AutoTilingViewMode {
        return this.operator.viewMode
    }

    set viewMode(value: AutoTilingViewMode) {
        this.operator.viewMode = value
    }

    get isInProgress(): boolean {
        return this.operator.autoTilingState === AutoTilingState.InProgress
    }

    get isComplete(): boolean {
        return this.operator.autoTilingState === AutoTilingState.Complete
    }

    // get tilingParams(): Omit<Tiling.Params, "targetGroupId"> {
    //     return this.operator.tilingParams
    // }

    get processingMode(): Tiling.ProcessingMode {
        return this.operator.tilingParams.processingMode
    }

    set processingMode(value: Tiling.ProcessingMode) {
        this.operator.tilingParams.processingMode = value
        this.operator.invalidateTilingResult()
    }

    get featureWeighting(): Tiling.FeatureWeighting {
        return this.operator.tilingParams.featureWeighting
    }

    set featureWeighting(value: Tiling.FeatureWeighting) {
        this.operator.tilingParams.featureWeighting = value
        this.operator.invalidateTilingResult()
    }

    get blendingBorder(): number {
        return this.operator.tilingParams.blendingBorder
    }

    set blendingBorder(value: number) {
        this.operator.tilingParams.blendingBorder = value
        this.operator.invalidateTilingResult()
    }

    get maxBlendingRadius(): number {
        return this.operator.tilingParams.maxBlendingRadius
    }

    set maxBlendingRadius(value: number) {
        this.operator.tilingParams.maxBlendingRadius = value
        this.operator.invalidateTilingResult()
    }

    get gradientCorrection(): boolean {
        return this.operator.tilingParams.gradientCorrection
    }

    set gradientCorrection(value: boolean) {
        this.operator.tilingParams.gradientCorrection = value
        this.operator.invalidateTilingResult()
    }

    get filterMode(): Tiling.FilterMode {
        return this.operator.tilingParams.filterMode
    }

    set filterMode(value: Tiling.FilterMode) {
        this.operator.tilingParams.filterMode = value
        this.operator.invalidateTilingResult()
    }

    get filterRadius(): number {
        return this.operator.tilingParams.filterRadius
    }

    set filterRadius(value: number) {
        this.operator.tilingParams.filterRadius = value
        this.operator.invalidateTilingResult()
    }

    get patternType(): Tiling.PatternType {
        return this.operator.tilingParams.patternType
    }

    set patternType(value: Tiling.PatternType) {
        this.operator.tilingParams.patternType = value
        this.operator.invalidateTilingResult()
    }

    get edgeAlignmentWindowSize(): number {
        return this.operator.tilingParams.edgeAlignmentWindowSize
    }

    set edgeAlignmentWindowSize(value: number) {
        this.operator.tilingParams.edgeAlignmentWindowSize = value
        this.operator.invalidateTilingResult()
    }

    get edgeAlignmentSmoothingIterations(): number {
        return this.operator.tilingParams.edgeAlignmentSmoothingIterations
    }

    set edgeAlignmentSmoothingIterations(value: number) {
        this.operator.tilingParams.edgeAlignmentSmoothingIterations = value
        this.operator.invalidateTilingResult()
    }

    get alignIndividualMaps(): boolean {
        return this.operator.tilingParams.alignIndividualMaps
    }

    set alignIndividualMaps(value: boolean) {
        this.operator.tilingParams.alignIndividualMaps = value
        this.operator.invalidateTilingResult()
    }

    get tilingCutoutActive(): boolean {
        return this.operator.isTilingCutoutActive
    }

    cutoutClick() {
        this.operator.toggleCutoutActive()
    }

    // addTilingHintClick() {
    //     this.operator.addTilingHint()
    // }

    // clearTilingHintsClick() {
    //     this.operator.clearTilingHints()
    // }

    // startTilingDetectionClick() {
    //     this.operator.startTilingDetection()
    // }

    // startTilingAlignmentClick() {
    //     this.operator.startTilingAlignment()
    // }
}
