import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {z} from "zod"
import {vec3} from "#material-nodes/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"

const ReturnTypeSchema = z.object({tangent: materialSlots})
const InputTypeSchema = z.object({})
const ParametersTypeSchema = z.object({
    axis: z.enum(["X", "Y", "Z"]).optional(),
    directionType: z.enum(["UV_MAP"]).optional(),
    tangent: vec3.optional(),
    uvMapIndex: z.number().optional(),
})
export class Tangent extends (DeclareMaterialNode(
    {
        returns: z.object({tangent: materialSlots}),
        inputs: z.object({}),
        parameters: z.object({
            axis: z.enum(["X", "Y", "Z"]).optional(),
            directionType: z.enum(["UV_MAP"]).optional(),
            tangent: vec3.optional(),
            uvMapIndex: z.number().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            //TODO: use THREE.BufferGeometryUtils.computeTangents when loading mesh?
            return {tangent: THREENodes.attribute("tangent", "vec3")}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
