import * as paper from "paper"
import {Vector2, Vector2Like} from "@cm/math"
import {EventEmitter} from "@angular/core"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {TilingControlPointType} from "@app/textures/texture-editor/texture-edit-nodes"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export type PointVizItemType = TilingControlPointType | "snap"

export class PointVizItem extends CanvasBaseToolboxItemBase {
    readonly dragging = new EventEmitter<paper.ToolEvent>()
    readonly dragFinished = new EventEmitter<void>()

    constructor(
        parent: CanvasBaseToolboxItemBase,
        private properties: {
            type: PointVizItemType
            radius: number
            color: string
            movable: boolean
            radiusInScreenSpace?: boolean // if true, the radius is in screen space, otherwise in world space; default is true
        },
    ) {
        super(parent)

        this.viewChange.subscribe(() => this.onViewChange())
        this.selectedChange.subscribe(() => this.onSelectionChange())

        this.createCircle()
    }

    override remove() {
        super.remove()
        this._group?.removeChildren()
        this._group?.remove()
    }

    get type(): PointVizItemType {
        return this.properties.type
    }

    set color(value: string) {
        if (this.properties.color === value) {
            return
        }
        this.properties.color = value
        this.createCircle()
    }

    get color(): string {
        return this.properties.color
    }

    private updateCircles() {
        if (!this._group || !this._circle) {
            throw Error("Position circle not created")
        }
        const color = this.selected ? COLOR_SELECTED : this.properties.color
        this._circle.strokeColor = new paper.Color(color)
        this._circle.fillColor = new paper.Color(color)
        this._circle.fillColor.alpha = 0.1
        this._group.position.set(this._position.x, this._position.y)
    }

    get position(): Vector2 {
        return this._position
    }

    set position(value: Vector2Like) {
        this.setPosition(value)
    }

    setPosition(value: Vector2Like) {
        if (this._position.equals(value)) {
            return
        }
        this._position.setFromVector2Like(value)
        this.updateCircles()
    }

    get lastTouchedTimeStamp(): number {
        return this._lastTouchedTimeStamp
    }

    override hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        if (this.properties.movable && this._group?.hitTest(point, {stroke: true, fill: true, tolerance: 2})) {
            return this
        }
        return null
    }

    override onMouseDown(_event: paper.ToolEvent): boolean {
        if (!this.properties.movable) {
            return false
        }
        this._dragPosition = this.position
        this.markTouched()
        return true
    }

    override onMouseUp(event: paper.ToolEvent): boolean {
        if (this._dragPosition) {
            this._dragPosition = undefined
            this.dragFinished.emit()
            return true
        }
        return super.onMouseUp(event)
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        if (this.selected && this._dragPosition) {
            this.dragging.emit(event)
            return false
        }
        return super.onMouseDrag(event)
    }

    markTouched() {
        this._lastTouchedTimeStamp = Date.now()
    }

    private updateZoomDependent() {
        this.createCircle()
    }

    private onViewChange() {
        this.updateZoomDependent()
    }

    private onSelectionChange() {
        this.updateCircles()
    }

    private createCircle() {
        this._group?.removeChildren()
        this._group?.remove()

        this.beginPaperCreation()

        const radiusInSceenSpace = this.properties.radiusInScreenSpace ?? true
        const radiusScale = radiusInSceenSpace ? window.devicePixelRatio / this.zoomLevel : 1
        const outerCircle = new paper.Path.Circle(this._position, this.properties.radius * radiusScale)
        outerCircle.strokeColor = new paper.Color("black")
        outerCircle.strokeWidth = (3 * window.devicePixelRatio) / this.zoomLevel
        this._circle = new paper.Path.Circle(this._position, this.properties.radius * radiusScale)
        this._circle.strokeWidth = (2 * window.devicePixelRatio) / this.zoomLevel
        this._group = new paper.Group([outerCircle, this._circle])

        this.updateCircles()
    }

    private _dragPosition?: Vector2
    private _position = new Vector2(0, 0)
    private _group?: paper.Group
    private _circle?: paper.Path.Circle
    private _lastTouchedTimeStamp = 0
}

const COLOR_SELECTED = "white"
