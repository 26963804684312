import {OperatorExecutionMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context"
import {
    ImageDescriptor,
    ImageRef,
    ImageRefId,
    makeImageRef,
    ManagedImageRef,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {DrawableImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"

export abstract class ImageOpContextBase {
    constructor(readonly mode: OperatorExecutionMode) {}

    abstract createDataObjectImageRef(dataObjectId: string): Promise<ManagedImageRef>

    createDrawableImage(drawableImageRef: DrawableImageRef, descriptor: ImageDescriptor): ImageRef {
        const imageRef = makeImageRef("drawable", this.nextImageRefId++, descriptor, "createDrawableImage")
        const newInfo: DrawableImageInfo = {imageRefId: imageRef.id, drawableImageRef, descriptor}
        this.drawableImageInfoByImageRefId.set(imageRef.id, newInfo)
        return imageRef
    }

    protected fetchUniqueImageRefId(): ImageRefId {
        return this.nextImageRefId++
    }

    protected getDrawableImageInfo(imageRefId: ImageRefId): DrawableImageInfo {
        const info = this.drawableImageInfoByImageRefId.get(imageRefId)
        if (!info) {
            throw new Error(`Drawable image ${imageRefId} not found`)
        }
        return info
    }

    private nextImageRefId = 1
    private drawableImageInfoByImageRefId = new Map<ImageRefId, DrawableImageInfo>()
}

export type DrawableImageInfo = {imageRefId: ImageRefId; drawableImageRef: DrawableImageRef; descriptor: ImageDescriptor}
