import {Vector2Like} from "#math/vector2"

export type Size2Like = {width: number; height: number}

export function isSize2Like(v: unknown): v is Size2Like {
    return typeof v == "object" && v != null && "width" in v && "height" in v
}

export class Size2 {
    constructor(
        public width = 0,
        public height = 0,
    ) {}

    static zero = new Size2(0, 0)
    static one = new Size2(1, 1)

    set(width: number, height: number): void {
        this.width = width
        this.height = height
    }

    setFromVector2Like(v: Size2Like): void {
        this.width = v.width
        this.height = v.height
    }

    toArray(): [number, number] {
        return [this.width, this.height]
    }

    static fromArray(a: number[]) {
        return new this(a[0], a[1])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    static fromSize2Like(v: Size2Like) {
        return new this(v.width, v.height)
    }

    static fromVector2Like(v: Vector2Like) {
        return new this(v.x, v.y)
    }

    equals(other: Size2Like) {
        return this.width == other.width && this.height == other.height
    }

    clone(): Size2 {
        return new Size2(this.width, this.height)
    }

    static min(a: Size2Like, b: Size2Like): Size2 {
        return new Size2(Math.min(a.width, b.width), Math.min(a.height, b.height))
    }

    minInPlace(v: Size2Like): Size2 {
        this.width = Math.min(this.width, v.width)
        this.height = Math.min(this.height, v.height)
        return this
    }

    static max(a: Size2Like, b: Size2Like): Size2 {
        return new Size2(Math.max(a.width, b.width), Math.max(a.height, b.height))
    }

    maxInPlace(v: Size2Like): Size2 {
        this.width = Math.max(this.width, v.width)
        this.height = Math.max(this.height, v.height)
        return this
    }

    dot(v: Size2): number {
        return this.width * v.width + this.height * v.height
    }

    norm(): number {
        return Math.sqrt(this.dot(this))
    }

    add(b: Size2Like): Size2 {
        return new Size2(this.width + b.width, this.height + b.height)
    }

    addInPlace(b: Size2Like): Size2 {
        this.width += b.width
        this.height += b.height
        return this
    }

    sub(b: Size2Like): Size2 {
        return new Size2(this.width - b.width, this.height - b.height)
    }

    subInPlace(b: Size2Like): Size2 {
        this.width -= b.width
        this.height -= b.height
        return this
    }

    static mul(a: Size2Like, b: Size2Like | number): Size2 {
        if (typeof b == "number") {
            return new Size2(a.width * b, a.height * b)
        } else {
            return new Size2(a.width * b.width, a.height * b.height)
        }
    }

    mul(s: Size2Like | number): Size2 {
        return Size2.mul(this, s)
    }

    mulInPlace(s: Size2Like | number): Size2 {
        if (typeof s == "number") {
            this.width *= s
            this.height *= s
            return this
        } else {
            this.width *= s.width
            this.height *= s.height
            return this
        }
    }

    div(s: number): Size2 {
        return new Size2(this.width / s, this.height / s)
    }

    divInPlace(s: number): Size2 {
        this.width /= s
        this.height /= s
        return this
    }

    floor(): Size2 {
        return this.clone().floorInPlace()
    }

    floorInPlace(): Size2 {
        this.width = Math.floor(this.width)
        this.height = Math.floor(this.height)
        return this
    }

    ceil(): Size2 {
        return this.clone().ceilInPlace()
    }

    ceilInPlace(): Size2 {
        this.width = Math.ceil(this.width)
        this.height = Math.ceil(this.height)
        return this
    }
}
