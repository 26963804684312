import {Vector2, Vector2Like} from "@cm/math"
import {CurveInterpolatorBase} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-interpolator-base"

export class LinearInterpolator extends CurveInterpolatorBase {
    constructor(
        private points: Vector2[],
        private tValues: number[],
    ) {
        super()
        if (points.length !== tValues.length) {
            throw new Error("Points and tValues must have the same length.")
        }
    }

    evaluate(t: number): Vector2 {
        const n = this.points.length - 1

        if (t <= this.tValues[0]) {
            // extrapolate
            const t0 = this.tValues[0]
            const t1 = this.tValues[1]
            const interval = t1 - t0
            if (interval === 0) {
                return this.points[0]
            }
            const u = (t - t0) / interval
            return this.points[0].mul(1 - u).add(this.points[1].mul(u))
        } else if (t >= this.tValues[n]) {
            // extrapolate
            const t0 = this.tValues[n - 1]
            const t1 = this.tValues[n]
            const interval = t1 - t0
            if (interval === 0) {
                return this.points[n]
            }
            const u = (t - t0) / interval
            return this.points[n - 1].mul(1 - u).add(this.points[n].mul(u))
        }

        for (let i = 0; i < n; i++) {
            if (t <= this.tValues[i + 1]) {
                const t0 = this.tValues[i]
                const t1 = this.tValues[i + 1]
                const interval = t1 - t0
                if (interval === 0) {
                    return this.points[i]
                }
                const u = (t - t0) / interval
                return this.points[i].mul(1 - u).add(this.points[i + 1].mul(u))
            }
        }

        throw new Error("This should never happen.")
    }

    solveForT(position: Vector2Like) {
        const pos = Vector2.fromVector2Like(position)
        let minDistance = Number.POSITIVE_INFINITY
        let bestT = 0
        for (let i = 0; i < this.points.length - 1; i++) {
            const p0 = this.points[i]
            const p1 = this.points[i + 1]
            const t0 = this.tValues[i]
            const t1 = this.tValues[i + 1]
            const result = this.solveForTInSegment(pos, p0, p1, t0, t1)
            if (result.distance < minDistance) {
                minDistance = result.distance
                bestT = result.t
            }
        }
        return bestT
    }

    private solveForTInSegment(position: Vector2, p0: Vector2, p1: Vector2, t0: number, t1: number): {t: number; distance: number} {
        const segmentDelta = p1.sub(p0)
        const segmentLength = segmentDelta.norm()
        // const segmentDirNormalized = segmentDir.div(segmentLength)
        // const segmentDirPerp = segmentDirNormalized.perp()
        const localPosition = position.sub(p0)
        const localT = localPosition.dot(segmentDelta) / (segmentLength * segmentLength)
        const posOnSegment = p0.add(segmentDelta.mul(Math.max(0, Math.min(localT))))
        const t = t0 + (t1 - t0) * localT
        const distance = Vector2.distance(position, posOnSegment)
        return {t, distance}
    }
}
