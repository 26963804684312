import {DecimalPipe} from "@angular/common"
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core"
import {maxCurveControlPoints} from "@cm/material-nodes"
import {computeRgbCurveSampledFunction} from "@cm/material-nodes/utils"
import {clamp} from "@legacy/helpers/utils"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {CurveParameterType, CurveType, GRAPH_HEIGHT, GRAPH_WIDTH, RgbCurve, RgbCurvesInputs, RgbCurvesOutputs, RgbPoint} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ParameterEvent, ParameterValue} from "@node-editor/models/events"
import {Subject, takeUntil, throttleTime} from "rxjs"

@Component({
    selector: "cm-rgb-curves-node",
    templateUrl: "./rgb-curves-node.component.html",
    styleUrls: ["./rgb-curves-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent, DecimalPipe],
})
export class RgbCurvesNodeComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("nodeBase", {static: true}) nodeBase!: MaterialNodeBase
    @ViewChild("curvesContainer", {static: false}) curvesContainer!: ElementRef
    outputs = RgbCurvesOutputs
    inputs = RgbCurvesInputs
    typeInfo = RgbCurvesNodeType
    isDown = false
    selectedPoint: RgbPoint | undefined
    rgbCurves: Record<CurveType, RgbCurve> = {
        r: {points: [new RgbPoint(0, GRAPH_HEIGHT), new RgbPoint(GRAPH_WIDTH, 0)], selector: "rCurve", blenderIndex: 0},
        g: {points: [new RgbPoint(0, GRAPH_HEIGHT), new RgbPoint(GRAPH_WIDTH, 0)], selector: "gCurve", blenderIndex: 1},
        b: {points: [new RgbPoint(0, GRAPH_HEIGHT), new RgbPoint(GRAPH_WIDTH, 0)], selector: "bCurve", blenderIndex: 2},
        rgb: {points: [new RgbPoint(0, GRAPH_HEIGHT), new RgbPoint(GRAPH_WIDTH, 0)], selector: "rgbCurve", blenderIndex: 3},
    }
    selectedCurve: RgbCurve = this.rgbCurves.rgb
    uniqueId: number = Math.random()
    private unsubscribe = new Subject<void>()
    private pointMove: Subject<ParameterEvent<any>> = new Subject()

    /*TODO: This is a temporary hack to get all required parameters into the node. This requires a nice solution, similar to the
    inputs (see this node) or settings (see math node) of the node. Also, we should check if MaterialEditorComponent.addNode is
    the right place for adding the parameters. The control points are currently stored twice in two different variables.
    Also note how the parameters are handled in this.updateCurveParams().*/
    initMissingParams(): void {
        const hasAllParameters = this.nodeBase.node.parameters.some(
            (parameter: ParameterValue) => parameter.name === "internal.mapping.curves[3].points[0].location",
        )

        if (!hasAllParameters) {
            // const mappingTable = Array(256)
            //     .fill(0)
            //     .map(() => Array(3).fill(0))

            this.nodeBase.node.parameters.push({name: "internal.color", type: "color", value: [0, 0, 0]})
            this.nodeBase.node.parameters.push({name: "internal.label", type: "string", value: ""})

            //The mapping table below is created by the blender addon. It might not be necessary to add it here.
            //https://github.com/colormass/production-tools/blob/00b669a79201fac6593adc55fa8927cab1859f71/blender/colormass_nodegraph.py#L102
            //this.nodeBase.node.parameters.push({name: "internal.mapping.cycles_mapping_table", type: "vector_array", value: mappingTable})

            this.nodeBase.node.parameters.push({name: "internal.mapping.black_level", type: "color", value: [0, 0, 0]})
            this.nodeBase.node.parameters.push({name: "internal.mapping.clip_max_x", type: "float", value: 1})
            this.nodeBase.node.parameters.push({name: "internal.mapping.clip_max_y", type: "float", value: 1})
            this.nodeBase.node.parameters.push({name: "internal.mapping.clip_min_x", type: "float", value: 0})
            this.nodeBase.node.parameters.push({name: "internal.mapping.clip_min_y", type: "float", value: 0})
            this.updateCurveParams() //this writes the control points to the parameters. The position matters and must not be changed.
            this.nodeBase.node.parameters.push({name: "internal.mapping.extend", type: "string", value: "EXTRAPOLATED"})
            this.nodeBase.node.parameters.push({name: "internal.mapping.tone", type: "string", value: "STANDARD"})
            this.nodeBase.node.parameters.push({name: "internal.mapping.use_clip", type: "bool", value: true})
            this.nodeBase.node.parameters.push({name: "internal.mapping.white_level", type: "color", value: [1, 1, 1]})
        }
    }

    ngOnInit() {
        this.initMissingParams()
        this.parseCurveParams()
        this.pointMove.pipe(takeUntil(this.unsubscribe), throttleTime(200)).subscribe((parameterChange) => this.onParameterChanged(parameterChange))
    }

    ngAfterViewInit() {
        this.updateLine(this.rgbCurves.rgb) //updateLine directly draws to a dom element, thus must be executed after the view is fully initialized
        this.updateLine(this.rgbCurves.r)
        this.updateLine(this.rgbCurves.g)
        this.updateLine(this.rgbCurves.b)
    }

    onMouseDown(event: MouseEvent, point: RgbPoint, circle: HTMLElement): void {
        this.selectedPoint = point
        point.svgElement = circle
        this.isDown = true
        event.stopPropagation()
    }

    onMouseUp(event: MouseEvent) {
        if (this.isDown && this.selectedPoint) {
            const pointIndex = this.selectedCurve.points.indexOf(this.selectedPoint)
            const parameterId = this.getCurveParameterId(this.selectedCurve.blenderIndex, pointIndex, "location")
            this.nodeBase.emitParameterChange({
                node: this.nodeBase.node,
                type: "add",
                parameter: {id: parameterId, value: this.selectedPoint.toBlenderCoordinates(), type: "vector"},
            })
        }
        this.isDown = false
    }

    parseCurveParams(): void {
        this.rgbCurves.rgb.points = []
        this.rgbCurves.r.points = []
        this.rgbCurves.g.points = []
        this.rgbCurves.b.points = []
        for (const [type, curve] of Object.entries(this.rgbCurves)) {
            let pointIndex = 0
            let parameter = this.getCurveParameter(curve.blenderIndex, pointIndex, "location")
            while (parameter) {
                if (type === "rgb") {
                    this.rgbCurves.rgb.points.push(RgbPoint.fromBlenderCoordinates(parameter[0], parameter[1]))
                } else if (type === "r") {
                    this.rgbCurves.r.points.push(RgbPoint.fromBlenderCoordinates(parameter[0], parameter[1]))
                } else if (type === "g") {
                    this.rgbCurves.g.points.push(RgbPoint.fromBlenderCoordinates(parameter[0], parameter[1]))
                } else if (type === "b") {
                    this.rgbCurves.b.points.push(RgbPoint.fromBlenderCoordinates(parameter[0], parameter[1]))
                } else {
                    throw Error(`Unknown curve type: ${type}.`)
                }
                parameter = this.getCurveParameter(curve.blenderIndex, ++pointIndex, "location")
            }
        }
    }

    updateCurveParams(): void {
        // TODO: This is a hacky way to remove all the parameters relating to the curve. This is easier than trying to find out which points already exist
        //  and update the parameter list one by one.
        for (let i = this.nodeBase.node.parameters.length - 1; i >= 0; i--) {
            const param = this.nodeBase.node.parameters[i]
            if (param.name.indexOf("internal.mapping.curves[") !== -1) {
                this.nodeBase.node.parameters.splice(i, 1)
            }
        }

        /* TODO: For platform-created RGB curves to work correctly in Blender, the parameter order matters (see initMissingParams() for order).
        For a node created in Blender, there are 4 parameters *after* the actual curve points. In order to be consistent, the parameters
        are thus inserted at the correct position into the parameter array in the following. This is *very* ugly and must be replaced with a proper
        solution. But since the component already modifies the parameter array directly, it's probably acceptable as short-term solution. */
        const index = this.nodeBase.node.parameters.findIndex((param: any) => param.name === "internal.mapping.extend")
        const numElementsToKeep = index >= 0 ? this.nodeBase.node.parameters.length - index : 0

        let parameterId
        for (const [_type, curve] of Object.entries(this.rgbCurves)) {
            curve.points.forEach((value, index) => {
                parameterId = this.getCurveParameterId(curve.blenderIndex, index, "location")
                this.nodeBase.node.parameters.splice(this.nodeBase.node.parameters.length - numElementsToKeep, 0, {
                    name: parameterId,
                    type: "vector",
                    value: value.toBlenderCoordinates(),
                })
                //this.nodeBase.setParameter(parameterId, value.toBlenderCoordinates(), "vector")

                // TODO: At the moment only the AUTO handle type is handled.
                parameterId = this.getCurveParameterId(curve.blenderIndex, index, "handle_type")
                this.nodeBase.node.parameters.splice(this.nodeBase.node.parameters.length - numElementsToKeep, 0, {
                    name: parameterId,
                    type: "string",
                    value: "AUTO",
                })
                //this.nodeBase.setParameter(parameterId, "AUTO", "string")
            })
        }
    }

    getCurveParameter(blenderCurveIndex: number, pointIndex: number, type: CurveParameterType): [number, number] {
        const parameterId = this.getCurveParameterId(blenderCurveIndex, pointIndex, type)
        return this.nodeBase.getParameter(parameterId)
    }

    private getCurveParameterId(blenderCurveIndex: number, pointIndex: number, type: CurveParameterType): string {
        return `internal.mapping.curves[${blenderCurveIndex}].points[${pointIndex}].${type}`
    }

    selectCurve(curve: RgbCurve) {
        this.selectedCurve = curve
        this.updateLine(curve)
        this.selectedPoint = undefined
    }

    addPoint(event: MouseEvent) {
        if (this.selectedCurve.points.length + 1 > maxCurveControlPoints) return
        const newPoint = new RgbPoint(event.offsetX, event.offsetY)
        this.selectedCurve.points.push(newPoint)
        this.selectedPoint = newPoint
        this.updateLine()

        this.updateCurveParams()

        const pointIndex = this.selectedCurve.points.indexOf(newPoint)
        const parameterId = this.getCurveParameterId(this.selectedCurve.blenderIndex, pointIndex, "location")

        this.nodeBase.emitParameterChange({
            node: this.nodeBase.node,
            type: "add",
            parameter: {id: parameterId, value: newPoint.toBlenderCoordinates(), type: "vector"},
        })
    }

    removePoint() {
        if (this.selectedCurve.points.length == 2) return

        const circleIndexToRemove = this.selectedCurve.points.findIndex((point) => point === this.selectedPoint)
        this.selectedCurve.points.splice(circleIndexToRemove, 1)
        this.selectedPoint = undefined
        this.updateLine()

        this.updateCurveParams()

        const parameterId = this.getCurveParameterId(this.selectedCurve.blenderIndex, circleIndexToRemove, "location")
        this.nodeBase.emitParameterChange({
            node: this.nodeBase.node,
            type: "delete",
            parameter: {id: parameterId, type: "vector"},
        })
    }

    toWellDefinedX(x: number): number {
        const occupiedX = this.selectedCurve.points.find((point) => point !== this.selectedPoint && point.x === x)

        if (!occupiedX) return x
        else {
            const stepSize = 0.1
            if (x < GRAPH_WIDTH / 2) {
                for (let i = x + stepSize; i <= GRAPH_WIDTH; i += stepSize) {
                    const occupied = this.selectedCurve.points.find((point) => point.x === i)
                    if (!occupied) return i
                }
            } else {
                for (let i = x - stepSize; i >= 0; i -= stepSize) {
                    const occupied = this.selectedCurve.points.find((point) => point.x === i)
                    if (!occupied) return i
                }
            }

            console.error("No unoccupied x value found")

            return x
        }
    }

    onMouseMove(event: MouseEvent) {
        if (!this.isDown || !this.selectedPoint) return

        event.preventDefault()
        event.stopPropagation()

        const squareElement = this.curvesContainer.nativeElement
        const squareRect = squareElement.getBoundingClientRect()
        const scale = GRAPH_WIDTH / squareRect.width

        this.selectedPoint.x = this.toWellDefinedX(clamp((event.clientX - squareRect.left) * scale, 0, GRAPH_WIDTH))
        this.selectedPoint.y = clamp((event.clientY - squareRect.top) * scale, 0, GRAPH_HEIGHT)

        this.selectedPoint.updateSVGPosition()
        this.updateLine()

        const pointIndex = this.selectedCurve.points.indexOf(this.selectedPoint)
        const parameterId = this.getCurveParameterId(this.selectedCurve.blenderIndex, pointIndex, "location")
        this.pointMove.next({
            node: this.nodeBase.node,
            type: "update",
            parameter: {id: parameterId, value: this.selectedPoint.toBlenderCoordinates(), type: "vector"},
        })
    }

    private onParameterChanged(parameterChange: ParameterEvent<any>): void {
        this.nodeBase.emitParameterChange(parameterChange)
    }

    private updateLine(curve?: RgbCurve) {
        if (!curve) {
            curve = this.selectedCurve
        }
        curve.points.sort((a, b) => a.x - b.x) // make sure the points are sorted by x coordinate
        const curveT = curve.selector + this.uniqueId
        const line = document.getElementById(curveT)?.getElementsByClassName("cm-curve")
        let lineText
        const sampledFunction = computeRgbCurveSampledFunction(curve.points)
        for (let x = 0; x <= GRAPH_WIDTH; x++) {
            const y = sampledFunction.evaluate(x)
            if (x == 0) {
                lineText = "M "
            } else {
                lineText = lineText + " L "
            }
            lineText = lineText + x + " " + y
        }
        if (lineText && line) {
            line[0].setAttribute("d", lineText)
        }
        // Quadratic Curve
        // const quadtraticCurve = document.getElementById(curveT)?.getElementsByClassName("cm-quadratic-curve")
        // let quadtraticCurveText
        // for (let i = 0; i < points.length; i++) {
        // if (i == 0) {
        //     quadtraticCurveText =
        //         "M " +
        //         points[i].x +
        //         " " +
        //         points[i].y +
        //         " Q " +
        //         (points[i].x + points[i + 1].x / 3) +
        //         " " +
        //         (points[i].y + points[i + 1].y / 3) +
        //         " " +
        //         points[i + 1].x +
        //         " " +
        //         points[i + 1].y
        // } else if (i !== 1) {
        //     quadtraticCurveText = quadtraticCurveText + " T " + points[i].x + " " + points[i].y
        // }
        // }
        // if (quadtraticCurveText && quadtraticCurve) {
        // quadtraticCurve[0]?.setAttribute("d", quadtraticCurveText)
        // }
        //
        // Catmull Curve
        // const catmullCurve = document.getElementById(curveT)?.getElementsByClassName("cm-catmull-curve")
        // let catmullText = "M 0 100 "
        // for (let i = 0, iLen = points.length; iLen - 2 > i; i += 2) {
        //     const p = []
        //     if (0 == i) {
        //         p.push({x: points[i]?.x, y: points[i + 1]?.y})
        //         p.push({x: points[i]?.x, y: points[i + 1]?.y})
        //         p.push({x: points[i + 2]?.x, y: points[i + 3]?.y})
        //         p.push({x: points[i + 4]?.x, y: points[i + 5]?.y})
        //     } else if (iLen - 4 == i) {
        //         p.push({x: points[i - 2]?.x, y: points[i - 1]?.y})
        //         p.push({x: points[i]?.x, y: points[i + 1]?.y})
        //         p.push({x: points[i + 2]?.x, y: points[i + 3]?.y})
        //         p.push({x: points[i + 2]?.x, y: points[i + 3]?.y})
        //     } else {
        //         p.push({x: points[i - 2]?.x, y: points[i - 1]?.y})
        //         p.push({x: points[i]?.x, y: points[i + 1]?.y})
        //         p.push({x: points[i + 2]?.x, y: points[i + 3]?.y})
        //         p.push({x: points[i + 4]?.x, y: points[i + 5]?.y})
        //     }
        //     const bp = []
        //     bp.push({x: p[1]?.x, y: p[1]?.y})
        //     bp.push({x: (-p[0]?.x + 6 * p[1]?.x + p[2]?.x) / 6, y: (-p[0]?.y + 6 * p[1]?.y + p[2]?.y) / 6})
        //     bp.push({x: (p[1]?.x + 6 * p[2]?.x - p[3]?.x) / 6, y: (p[1]?.y + 6 * p[2]?.y - p[3]?.y) / 6})
        //     bp.push({x: p[2]?.x, y: p[2]?.y})
        //     catmullText += "C " + bp[1]?.x + " " + bp[1].y + ", " + bp[2].x + " " + bp[2].y + ", " + bp[3].x + " " + bp[3].y + " "
        // }
        // if (catmullText && catmullCurve) {
        //     catmullCurve[0]?.setAttribute("d", catmullText)
        // }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}

export const RgbCurvesNodeType: MaterialNodeType<typeof RgbCurvesNodeComponent> = {
    id: "rgbCurves",
    label: "RGB Curves",
    color: "#8d802f",
    name: "ShaderNodeRGBCurve",
    inputs: [RgbCurvesInputs.fac, RgbCurvesInputs.color],
    outputs: [RgbCurvesOutputs.color],
    component: RgbCurvesNodeComponent,
}
